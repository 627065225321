export const listeannees = [];
for (let i = 2022; i > 1950; i = i - 1) {
  listeannees.push({ code: i, intitule: `${i}` });
}

export const listesecteurs = [
  { code: "Public", intitule: "Public" },
  { code: "Privé", intitule: "Privé" },
  { code: "N/A", intitule: "Non renseigné"}
];

export const listesexes = [
  { code: "1", intitule: "Masculin" },
  { code: "2", intitule: "Féminin" }
];

export const listeSpecialites = [
  { short: "CINEMA", med: "CINE_AUDIO", long: "CINEMA-AUDIOVISUEL ", date: "01/01/2021"},
  { short: "CIRQUE", med: "ART_CIRQUE", long: "ARTS DU CIRQUE ", date: "01/01/2021"},
  { short: "DANSE", med: "DANSE", long: "DANSE ", date: "01/01/2021"},
  { short: "MUSIQUE", med: "MUSIQUE", long: "MUSIQUE", date: "01/01/2021"},
  { short: "ART_PLA", med: "ART_PLASTI", long: "ARTS PLASTIQUES ", date: "01/01/2021"},
  { short: "THEATRE", med: "THEATRE", long: "THEATRE", date: "01/01/2021"},
  { short: "BIO_ECO", med: "BIOL_ECOLO", long: "BIOLOGIE-ECOLOGIE (lycées agricoles seulement) ", date: "01/01/2021"},
  { short: "HIS_ART", med: "HIST_ARTS", long: "HISTOIRE DES ARTS ", date: "01/01/2021"},
  { short: "HIST_GE", med: "HIST_GEOG", long: "HISTOIRE-GEOGRAPHIE, GEOPOLITIQUE ET SCIENCES POLITIQUES ", date: "01/01/2021"},
  { short: "LIT_GRE", med: "LIT_LCA_GR", long: "LITTERATURE ET LANGUES ET CULTURES DE L'ANTIQUITE - GREC ", date: "01/01/2021"},
  { short: "LIT_LAT", med: "LIT_LCA_LA", long: "LITTERATURE ET LANGUES ET CULTURES DE L'ANTIQUITE - LATIN ", date: "01/01/2021"},
  { short: "LIT_PHI", med: "LITT_PHILO", long: "HUMANITES, LITTÉRATURE ET PHILOSOPHIE ", date: "01/01/2021"},
  { short: "LLCER", med: "LLCER", long: "LANGUES, LITTÉRATURE ET CULTURES ETRANGERES ET REGIONALES ", date: "01/01/2021"},
  { short: "MATHS", med: "MATHEMATIQ", long: "MATHEMATIQUES", date: "01/01/2021"},
  { short: "NUMERIQ", med: "NUMER_INFO", long: "NUMERIQUE ET SCIENCES INFORMATIQUES ", date: "01/01/2021"},
  { short: "PHY_CHI", med: "PHYS_CHIMI", long: "PHYSIQUE-CHIMIE ", date: "01/01/2021"},
  { short: "SC_INGE", med: "SC_INGE_PH", long: "SCIENCES DE L'INGENIEUR ET SCIENCES PHYSIQUES ", date: "01/01/2021"},
  { short: "SES", med: "SC_ECO_SOC", long: "SCIENCES ECONOMIQUES ET SOCIALES", date: "01/01/2021"},
  { short: "SVT", med: "SC_VIE_TER", long: "SCIENCES DE LA VIE ET DE LA TERRE ", date: "01/01/2021"},
];

export const listepcs = [
  { code: "10", intitule: "Agriculteur exploitant" },
  { code: "21", intitule: "Artisan" },
  { code: "22", intitule: "Commerçant" },
  { code: "23", intitule: "Chef d'entreprise 10 salariés ou plus" },
  { code: "31", intitule: "Profession libérale" },
  { code: "33", intitule: "Cadre fonction publique" },
  { code: "34", intitule: "Professeur/Profession scientifique" },
  { code: "37", intitule: "Cadre admin./Commercial d'entreprise" },
  { code: "42", intitule: "Professeur des écoles/Instituteur" },
  { code: "43", intitule: "Intermédiaire santé et social" },
  { code: "46", intitule: "Intermédiaire admin. commerc. entreprise" },
  { code: "47", intitule: "Technicien" },
  { code: "52", intitule: "Employé civil/Agent fonction publique" },
  { code: "54", intitule: "Employé admin. d'entreprise" },
  { code: "55", intitule: "Employé de commerce" },
  { code: "71", intitule: "Retraité agriculteur exploitant" },
  { code: "72", intitule: "Retraité artisan-commerçant-chef entrepr" },
  { code: "53", intitule: "Policier/Militaire" },
  { code: "56", intitule: "Services directs aux particuliers" },
  { code: "69", intitule: "Ouvrier agricole" },
  { code: "81", intitule: "Chômeur n'ayant jamais travaillé" },
  { code: "35", intitule: "Profession information-arts-spectacles" },
  { code: "38", intitule: "Ingénieur/Cadre technique d'entreprise" },
  { code: "44", intitule: "Clergé/Religieux" },
  { code: "45", intitule: "Intermédiaire admin. fonction publique" },
  { code: "48", intitule: "Contremaître/Agent de maîtrise" },
  { code: "63", intitule: "Ouvrier qualifié dans l'artisanat" },
  { code: "64", intitule: "Chauffeur" },
  { code: "65", intitule: "Ouvrier qualifié manut. magasin. transp." },
  { code: "67", intitule: "Ouvrier non qualifié dans l'industrie" },
  { code: "68", intitule: "Ouvrier non qualifié dans l'artisanat" },
  { code: "74", intitule: "Retraité cadre" },
  { code: "75", intitule: "Retraité profession intermédiaire" },
  { code: "77", intitule: "Retraité employé" },
  { code: "78", intitule: "Retraité ouvrier" },
  { code: "83", intitule: "Militaire du contingent" },
  { code: "84", intitule: "Elève/Etudiant" },
  { code: "85", intitule: "Sans profession -60 ans (hors retraité)" },
  { code: "86", intitule: "Sans profession +60 ans (hors retraité)" },
  { code: "62", intitule: "Ouvrier qualifié dans l'industrie" }
];

export const listepays = [
  {
    code: "432",
    intitule: "Territoires des Etats-unis en Amérique (Porto Rico ...)",
    europe: "N"
  },
  { code: "502", intitule: "Nouvelle-Zélande", europe: "N" },
  { code: "515", intitule: "Marshall", europe: "N" },
  { code: "129", intitule: "Vatican", europe: "N" },
  { code: "208", intitule: "Turquie", europe: "N" },
  { code: "241", intitule: "Laos", europe: "N" },
  { code: "250", intitule: "Oman", europe: "N" },
  { code: "309", intitule: "Tanzanie", europe: "N" },
  { code: "331", intitule: "Burkina Faso", europe: "N" },
  { code: "340", intitule: "Rwanda", europe: "N" },
  { code: "351", intitule: "Tunisie", europe: "N" },
  { code: "398", intitule: "Seychelles", europe: "N" },
  { code: "410", intitule: "Haiti", europe: "N" },
  { code: "424", intitule: "Venezuela", europe: "N" },
  { code: "439", intitule: "Sainte-Lucie", europe: "N" },
  { code: "516", intitule: "Micronésie", europe: "N" },
  { code: "131", intitule: "Belgique", europe: "O" },
  { code: "261", intitule: "Palestine", europe: "N" },
  { code: "335", intitule: "Mali", europe: "N" },
  { code: "517", intitule: "Palaos", europe: "N" },
  { code: "107", intitule: "Lettonie", europe: "O" },
  { code: "148", intitule: "Biélorussie", europe: "N" },
  { code: "116", intitule: "Tchèque (République tchèque)", europe: "O" },
  { code: "118", intitule: "Bosnie-Herzégovine", europe: "N" },
  { code: "252", intitule: "Arménie", europe: "N" },
  { code: "257", intitule: "Kirghizistan", europe: "N" },
  {
    code: "425",
    intitule: "Territoires du Royaume-uni aux Antilles",
    europe: "N"
  },
  { code: "420", intitule: "Equateur", europe: "N" },
  { code: "405", intitule: "Mexique", europe: "N" },
  { code: "508", intitule: "Fidji", europe: "N" },
  { code: "417", intitule: "Chili", europe: "N" },
  { code: "418", intitule: "Bolivie", europe: "N" },
  { code: "419", intitule: "Colombie", europe: "N" },
  { code: "422", intitule: "Pérou", europe: "N" },
  { code: "426", intitule: "Jamaïque", europe: "N" },
  {
    code: "427",
    intitule: "Territoires du Royaume-uni dans l'Atlantique sud",
    europe: "N"
  },
  { code: "428", intitule: "Guyana", europe: "N" },
  { code: "429", intitule: "Belize", europe: "N" },
  { code: "430", intitule: "Groenland", europe: "N" },
  { code: "434", intitule: "Barbade", europe: "N" },
  { code: "436", intitule: "Bahamas", europe: "N" },
  { code: "437", intitule: "Suriname", europe: "N" },
  { code: "438", intitule: "Dominique", europe: "N" },
  { code: "441", intitule: "Antigua-et-Barbuda", europe: "N" },
  {
    code: "312",
    intitule: "Congo ( République démocratique du Congo)",
    europe: "N"
  },
  { code: "314", intitule: "Guinée équatoriale", europe: "N" },
  { code: "315", intitule: "Ethiopie", europe: "N" },
  { code: "317", intitule: "Erythrée", europe: "N" },
  { code: "321", intitule: "Burundi", europe: "N" },
  { code: "322", intitule: "Cameroun", europe: "N" },
  { code: "326", intitule: "Côte d'Ivoire", europe: "N" },
  { code: "327", intitule: "Bénin", europe: "N" },
  { code: "328", intitule: "Gabon", europe: "N" },
  { code: "329", intitule: "Ghana", europe: "N" },
  { code: "330", intitule: "Guinée", europe: "N" },
  { code: "332", intitule: "Kenya", europe: "N" },
  { code: "333", intitule: "Madagascar", europe: "N" },
  { code: "336", intitule: "Mauritanie", europe: "N" },
  { code: "339", intitule: "Ouganda", europe: "N" },
  { code: "341", intitule: "Sénégal", europe: "N" },
  { code: "343", intitule: "Soudan", europe: "N" },
  { code: "344", intitule: "Tchad", europe: "N" },
  { code: "345", intitule: "Togo", europe: "N" },
  { code: "346", intitule: "Zambie", europe: "N" },
  { code: "348", intitule: "Lesotho", europe: "N" },
  { code: "350", intitule: "Maroc", europe: "N" },
  { code: "390", intitule: "Maurice ", europe: "N" },
  { code: "391", intitule: "Swaziland", europe: "N" },
  { code: "393", intitule: "Mozambique", europe: "N" },
  { code: "394", intitule: "Sao Tomé-et-Principe", europe: "N" },
  { code: "397", intitule: "Comores", europe: "N" },
  { code: "399", intitule: "Djibouti", europe: "N" },
  { code: "212", intitule: "Afghanistan", europe: "N" },
  { code: "215", intitule: "Népal", europe: "N" },
  { code: "216", intitule: "Chine", europe: "N" },
  { code: "217", intitule: "Japon", europe: "N" },
  { code: "219", intitule: "Thailande", europe: "N" },
  { code: "220", intitule: "Philippines", europe: "N" },
  { code: "224", intitule: "Birmanie", europe: "N" },
  { code: "226", intitule: "Singapour", europe: "N" },
  { code: "227", intitule: "Malaisie", europe: "N" },
  { code: "229", intitule: "Maldives", europe: "N" },
  { code: "234", intitule: "Cambodge", europe: "N" },
  { code: "235", intitule: "Sri Lanka", europe: "N" },
  { code: "238", intitule: "Corée du Nord", europe: "N" },
  { code: "239", intitule: "Corée du Sud", europe: "N" },
  { code: "242", intitule: "Mongolie", europe: "N" },
  { code: "246", intitule: "Bangladesh", europe: "N" },
  { code: "248", intitule: "Qatar", europe: "N" },
  { code: "249", intitule: "Bahrein", europe: "N" },
  { code: "253", intitule: "Azerbaidjan", europe: "N" },
  { code: "255", intitule: "Géorgie", europe: "N" },
  { code: "256", intitule: "Kazakhstan", europe: "N" },
  { code: "301", intitule: "Egypte", europe: "N" },
  { code: "302", intitule: "Liberia", europe: "N" },
  { code: "303", intitule: "Afrique du Sud", europe: "N" },
  { code: "304", intitule: "Gambie", europe: "N" },
  { code: "501", intitule: "Australie", europe: "N" },
  {
    code: "505",
    intitule: "Territoires des Etats-unis en Océanie",
    europe: "N"
  },
  { code: "507", intitule: "Nauru", europe: "N" },
  { code: "510", intitule: "Papouasie-Nouvelle-Guinée", europe: "N" },
  { code: "512", intitule: "Salomon", europe: "N" },
  { code: "313", intitule: "Provinces espagnoles d'Afrique", europe: "N" },
  { code: "412", intitule: "Nicaragua", europe: "N" },
  { code: "102", intitule: "Islande", europe: "N" },
  { code: "110", intitule: "Autriche", europe: "O" },
  { code: "128", intitule: "Saint-Marin", europe: "N" },
  { code: "151", intitule: "Moldavie", europe: "N" },
  { code: "206", intitule: "Syrie", europe: "N" },
  { code: "214", intitule: "Bhoutan", europe: "N" },
  { code: "225", intitule: "Brunei", europe: "N" },
  { code: "243", intitule: "Viet Nam", europe: "N" },
  { code: "236", intitule: "Taiwan", europe: "N" },
  { code: "258", intitule: "Ouzbekistan", europe: "N" },
  { code: "316", intitule: "Lybie", europe: "N" },
  { code: "334", intitule: "Malawi", europe: "N" },
  { code: "414", intitule: "Salvador", europe: "N" },
  { code: "506", intitule: "Samoa occidentales", europe: "N" },
  { code: "513", intitule: "Kiribati", europe: "N" },
  { code: "511", intitule: "Tuvalu", europe: "N" },
  { code: "352", intitule: "Algérie", europe: "N" },
  { code: "139", intitule: "Portugal", europe: "O" },
  { code: "223", intitule: "Inde", europe: "N" },
  { code: "254", intitule: "Chypre", europe: "O" },
  { code: "310", intitule: "Zimbabwe", europe: "N" },
  { code: "318", intitule: "Somalie", europe: "N" },
  { code: "337", intitule: "Niger", europe: "N" },
  { code: "347", intitule: "Botswana", europe: "N" },
  { code: "392", intitule: "Guiné-Bissau", europe: "N" },
  { code: "395", intitule: "Angola", europe: "N" },
  { code: "396", intitule: "Cap-vert", europe: "N" },
  { code: "435", intitule: "Grenade", europe: "N" },
  { code: "440", intitule: "Saint-Vincent-et-Les Grenadines", europe: "N" },
  { code: "133", intitule: "Gibraltar", europe: "N" },
  { code: "306", intitule: "Sainte-Hélène", europe: "N" },
  { code: "103", intitule: "Norvège", europe: "N" },
  { code: "111", intitule: "Bulgarie", europe: "O" },
  { code: "119", intitule: "Croatie", europe: "O" },
  { code: "132", intitule: "Royaume-Uni", europe: "O" },
  { code: "137", intitule: "Luxembourg", europe: "O" },
  { code: "213", intitule: "Pakistan", europe: "N" },
  { code: "222", intitule: "Jordanie", europe: "N" },
  { code: "231", intitule: "Indonésie", europe: "N" },
  { code: "240", intitule: "Koweit", europe: "N" },
  { code: "247", intitule: "Emirats Arabes Unis", europe: "N" },
  { code: "259", intitule: "Tadjikistan", europe: "N" },
  { code: "260", intitule: "Turkmenistan", europe: "N" },
  { code: "311", intitule: "Namibie", europe: "N" },
  { code: "324", intitule: "Congo ( République du Congo)", europe: "N" },
  { code: "338", intitule: "Nigeria", europe: "N" },
  { code: "342", intitule: "Sierra Leone", europe: "N" },
  { code: "401", intitule: "Canada", europe: "N" },
  { code: "413", intitule: "Panama", europe: "N" },
  { code: "421", intitule: "Paraguay", europe: "N" },
  { code: "433", intitule: "Trinité-et-Tobago", europe: "N" },
  { code: "509", intitule: "Tonga", europe: "N" },
  { code: "319", intitule: "Açores et Madère", europe: "N" },
  { code: "503", intitule: "Pitcairn (Ile de Pitcairn)", europe: "N" },
  { code: "106", intitule: "Estonie", europe: "O" },
  { code: "117", intitule: "Slovaquie", europe: "O" },
  { code: "144", intitule: "Malte", europe: "O" },
  { code: "145", intitule: "Slovénie", europe: "O" },
  { code: "262", intitule: "Timor oriental", europe: "N" },
  { code: "19A", intitule: "Européen de l'UE", europe: "O" },
  { code: "19B", intitule: "Européen hors UE", europe: "N" },
  { code: "$", intitule: "Non renseigné", europe: "N" },
  { code: "423", intitule: "Uruguay", europe: "N" },
  { code: "442", intitule: "Saint-Christophe-et-Nieves", europe: "N" },
  { code: "514", intitule: "Vanuatu", europe: "N" },
  { code: "#", intitule: "Non recensé", europe: "N" },
  { code: "323", intitule: "Centrafrique", europe: "N" },
  { code: "114", intitule: "Roumanie", europe: "O" },
  { code: "157", intitule: "Kosovo (République du Kosovo)", europe: "N" },
  { code: "443", intitule: "Bonaire, Saint Eustache et Saba", europe: "N" },
  { code: "444", intitule: "Curaçao", europe: "N" },
  { code: "445", intitule: "Saint-Martin (partie néerlandaise)", europe: "N" },
  { code: "349", intitule: "Soudan du Sud", europe: "N" },
  { code: "100", intitule: "France", europe: "O" },
  { code: "101", intitule: "Danemark", europe: "O" },
  { code: "104", intitule: "Suède", europe: "O" },
  { code: "105", intitule: "Finlande", europe: "O" },
  { code: "108", intitule: "Lituanie", europe: "O" },
  { code: "109", intitule: "Allemagne", europe: "O" },
  { code: "112", intitule: "Hongrie", europe: "O" },
  { code: "113", intitule: "Liechtenstein", europe: "N" },
  { code: "120", intitule: "Monténégro", europe: "N" },
  { code: "121", intitule: "Serbie et Monténégro", europe: "N" },
  { code: "122", intitule: "Pologne", europe: "O" },
  { code: "123", intitule: "Russie", europe: "N" },
  { code: "125", intitule: "Albanie", europe: "N" },
  { code: "126", intitule: "Grèce", europe: "O" },
  { code: "127", intitule: "Italie", europe: "O" },
  { code: "130", intitule: "Andorre", europe: "N" },
  { code: "134", intitule: "Espagne", europe: "O" },
  { code: "135", intitule: "Pays-Bas", europe: "O" },
  { code: "136", intitule: "Irlande", europe: "O" },
  { code: "138", intitule: "Monaco", europe: "N" },
  { code: "140", intitule: "Suisse", europe: "N" },
  { code: "155", intitule: "Ukraine", europe: "N" },
  { code: "156", intitule: "Macédoine", europe: "N" },
  { code: "201", intitule: "Arabie saoudite", europe: "N" },
  { code: "203", intitule: "Iraq", europe: "N" },
  { code: "204", intitule: "Iran", europe: "N" },
  { code: "205", intitule: "Liban", europe: "N" },
  { code: "207", intitule: "Israel", europe: "N" },
  { code: "404", intitule: "Etats-Unis d'Amérique", europe: "N" },
  { code: "406", intitule: "Costa Rica", europe: "N" },
  { code: "407", intitule: "Cuba", europe: "N" },
  { code: "409", intitule: "Guatemala", europe: "N" },
  { code: "411", intitule: "Honduras", europe: "N" },
  { code: "415", intitule: "Argentine", europe: "N" },
  { code: "416", intitule: "Brésil", europe: "N" },
  {
    code: "308",
    intitule: "Territoires britanniques de l'océan indien",
    europe: "N"
  },
  { code: "251", intitule: "Yémen", europe: "N" },
  { code: "389", intitule: "Sahara Occidental", europe: "N" },
  { code: "408", intitule: "République dominicaine", europe: "N" }
];

export const listeniveaux = [
    {code:"01", intitule: "Année 1 - Semestre 1"},
    {code:"02", intitule: "Année 1 - Semestre 2"},
    {code:"03", intitule: "Année 2 - Semestre 1"},
    {code:"04", intitule: "Année 2 - Semestre 2"},
    {code:"05", intitule: "Année 3 - Semestre 1"},
    {code:"06", intitule: "Année 3 - Semestre 2"},
    {code:"07", intitule: "Année 4 - Semestre 1"},
    {code:"08", intitule: "Année 4 - Semestre 2"},
    {code:"09", intitule: "Année 5 - Semestre 1"},
    {code:"10", intitule: "Année 5 - Semestre 2"},
];

export const listeregimes = [
  { code: "12", intitule: "CONTRAT APPRENTISSAGE" },
  { code: "10", intitule: "FORMATION INITIALE HORS APPRENTISSAGE" },
  { code: "11", intitule: "REPRISE ETUDES NON FINANCEE SANS CONV" },
  { code: "22", intitule: "CONTRAT DE PROFESSIONNALISATION" },
  { code: "20", intitule: "FORMATION CONTINUE HORS CONTRAT PROF" }
];

export const listeenseignementsdistance = [
  { code: "0", intitule: "SANS OBJET" },
  { code: "4", intitule: "ENSEIGT A DISTANCE SUIVI DEPUIS ETRANGER" },
  { code: "3", intitule: "ENSEIGT A DISTANCE SUIVI DEPUIS France" }
];

export const listeregimesscolaires = [
{ code: "Demi-pensionnaire",intitule:"Demi-pensionnaire"},
{ code:"Externe",intitule:"Externe"},
{code:"Interne",intitule:"Interne"}
];

export const listesituations = [
  { code: "I", intitule: "ECOLE D'ART, CULTURE, ARCHITECTURE" },
  { code: "N", intitule: "MISE A NIVEAU, PREPA ETUDES SUP" },
  { code: "U", intitule: "REPRISE D'ETUDE APRES ETAB FRANCAIS" },
  { code: "W", intitule: "REPRISE D'ETUDE APRES ETAB ETRANGER" },
  { code: "Q", intitule: "ETAB ETRANGER ENS SECONDAIRE" },
  { code: "R", intitule: "ETAB ETRANGER ENS SUPERIEUR" },
  { code: "A", intitule: "ENSEIGNEMENT SECONDAIRE (Y COMP CORRESP)" },
  { code: "B", intitule: "BTS ET ASSIMILES" },
  { code: "C", intitule: "IUT" },
  { code: "D", intitule: "CPGE (NON INSCRITS EN UNIVERSITE)" },
  { code: "E", intitule: "ECOLE OU FORMATION INGENIEURS" },
  { code: "H", intitule: "UNIVERSITE (HORS IUT, IUFM, INGEN UNIV)" },
  { code: "S", intitule: "AUTRE ETABLISSEMENT OU CURSUS EN France" },
  { code: "T", intitule: "PRISE D'ETUDE DIFFEREE" },
  { code: "G", intitule: "ENSEIGNT SUPERIEUR PAR CORRESPONDANCE" },
  { code: "J", intitule: "ECOLE DE MANAGEMENT" },
  { code: "K", intitule: "AUTRE ETABLISSEMENT SISE" },
  { code: "M", intitule: "ECOLE SUP PROFESSORAT EDUCATION" }
];

export const listediplomes = [
  { code: "4", intitule: "DIP FORM APPROF DE SANTE GRADE MASTER" },
  { code: "3", intitule: "DIP FORM GENERALE DE SANTE GRADE LICENCE" },
  { code: "1", intitule: "DIPLOME ETAB ETRANGER SECONDAIRE" },
  { code: "2", intitule: "DIPLOME ETAB ETRANGER SUPERIEUR" },
  { code: "W", intitule: "DIPLOME VISE ECOLE DE MANAGEMENT" },
  { code: "B", intitule: "BTS" },
  { code: "A", intitule: "BACCALAUREAT" },
  { code: "C", intitule: "DUT" },
  { code: "D", intitule: "ATTEST DELIVREE SUITE AU CURSUS EN CPGE" },
  { code: "E", intitule: "DIP D'INGENIEUR (UNIVERSITAIRE OU NON)" },
  { code: "F", intitule: "DIP UNIV D'ENTREE EN 1ER CYCLE (DAEU...)" },
  { code: "L", intitule: "DIP DU SECTEUR PARAMEDICAL ET SOCIAL" },
  { code: "I", intitule: "DEUG" },
  { code: "K", intitule: "ATTESTATION FIN 1ERE ANNEE SANTE - PACES" },
  { code: "M", intitule: "AUTRES DIPLOMES UNIV 1ER CYCLE HORS DUT" },
  { code: "N", intitule: "LICENCE" },
  { code: "Q", intitule: "MAITRISE" },
  { code: "R", intitule: "AUTRES DIPLOMES UNIV 2EME CYCLE HORS ING" },
  { code: "U", intitule: "DIPLOMES DE 3EME CYCLE HORS INGENIEUR" },
  { code: "Y", intitule: "AUTRE DIPLOME SUPERIEUR" },
  { code: "Z", intitule: "AUCUN DIPLOME SUPERIEUR" },
  { code: "V", intitule: "DIPLOME FIN 2ND CYCLE MEDECINE-PHARMACIE" }
];

export const listeprepas = [
  { code: "11101", intitule: "MATHS PHYSIQUE ET SCIENCES DE L'INGENIEUR" },
  { code: "11103", intitule: "PHYSIQUE CHIMIE ET SCIENCES DE L'INGENIEUR" },
  {
    code: "11105",
    intitule: "PHYSIQUE TECHNOLOGIE ET SCIENCES DE L'INGENIEUR"
  },
  {
    code: "11109",
    intitule: "BIOLOGIE CHIMIE PHYSIQUE ET SCIENCES DE LA TERRE"
  },
  { code: "11110", intitule: "TECHNOLOGIE ET SCIENCES INDUSTRIELLES" },
  { code: "11111", intitule: "TECHNOLOGIE PHYSIQUE CHIMIE" },
  { code: "11112", intitule: "TECHNOLOGIE ET BIOLOGIE" },
  { code: "11114", intitule: "ENS CACHAN SECTION C" },
  { code: "11115", intitule: "ECOLE NATIONALE VETERINAIRE" },
  { code: "11116", intitule: "MARINE MARCHANDE" },
  { code: "12101", intitule: "ECONOMIQUES ET COMMERCIALES : SCIENTIFIQUE" },
  { code: "12102", intitule: "ECONOMIQUES ET COMMERCIALES : ECONOMIQUE" },
  { code: "12103", intitule: "ECONOMIQUES ET COMMERCIALES : TECHNOLOGIQUE" },
  { code: "12104", intitule: "ENS CACHAN SECTION D1 EN 2 ANS - 1ERE ANNEE" },
  { code: "12105", intitule: "ENS CACHAN SECTION D2 EN 2 ANS - 1ERE ANNEE" },
  { code: "13101", intitule: "LETTRES" },
  { code: "13103", intitule: "LETTRES ET SCIENCES SOCIALES" },
  { code: "13104", intitule: "ECOLE NATIONALE DES CHARTES" },
  { code: "13105", intitule: "ECOLE SAINT CYR" },
  { code: "11201", intitule: "MATHS ET PHYSIQUE" },
  { code: "11202", intitule: "MATHS ET PHYSIQUE*" },
  { code: "11203", intitule: "PHYSIQUE ET CHIMIE" },
  { code: "11204", intitule: "PHYSIQUE ET CHIMIE*" },
  { code: "11205", intitule: "PHYSIQUE ET SCIENCES DE L'INGENIEUR" },
  { code: "11206", intitule: "PHYSIQUE ET SCIENCES DE L'INGENIEUR*" },
  { code: "11207", intitule: "PHYSIQUE ET TECHNOLOGIE" },
  { code: "11208", intitule: "PHYSIQUE ET TECHNOLOGIE*" },
  {
    code: "11209",
    intitule: "BIOLOGIE, CHIMIE, PHYSIQUE ET SCIENCES DE LA TERRE"
  },
  { code: "11210", intitule: "TECHNOLOGIE ET SCIENCES INDUSTRIELLES" },
  { code: "11211", intitule: "TECHNOLOGIE, PHYSIQUE E CHIMIE" },
  { code: "11212", intitule: "TECHNOLOGIE ET BIOLOGIE" },
  {
    code: "11213",
    intitule: "TECHNOLOGIE INDUSTRIELLE POUR TECHNICIENS SUPERIEURS"
  },
  { code: "11214", intitule: "ENS CACHAN SECTION C" },
  { code: "11215", intitule: "AGRICOLES POST BTSA / BTS / DUT" },
  { code: "12201", intitule: "ECONOMIQUES ET COMMERCIALES : SCIENTIFIQUE" },
  { code: "12202", intitule: "ECONOMIQUES ET COMMERCIALES : ECONOMIQUE" },
  { code: "12203", intitule: "ECONOMIQUES ET COMMERCIALES : TECHNOLOGIQUE" },
  { code: "12204", intitule: "ENS CACHAN SECTION D1 EN 2 ANS - 2NDE ANNEE" },
  { code: "12205", intitule: "ENS CACHAN SECTION D2 EN 2 ANS - 2NDE ANNEE" },
  { code: "12206", intitule: "ENS CACHAN OPTION D1 EN 1 AN" },
  { code: "12207", intitule: "ENS CACHAN OPTION D2 EN 1 AN" },
  { code: "13201", intitule: "ENS LETTRES" },
  { code: "13202", intitule: "ENS FONTENAY-SAINT CLOUD" },
  { code: "13203", intitule: "LETTRES ET SCIENCES SOCIALES" },
  { code: "13204", intitule: "ECOLE NATIONALE DES CHARTES" },
  { code: "13205", intitule: "ECOLE SAINT-CYR" },
  {
    code: "11216",
    intitule: "ADAPTATION TECHNICIENS SUPERIEURS INGENIERIE INDUSTRIELLE"
  },
  { code: "11217", intitule: "ADAPTATION TECHNICIENS SUPERIEURS GENIE CIVIL" },
  {
    code: "11218",
    intitule: "ADAPTATION TECHNICIENS SUPERIEURS METIERS DE LA CHIMIE"
  },
  { code: "11219", intitule: "ADAPTATION TECHNICIENS SUPERIEURS BIOLOGIE" },
  {
    code: "12208",
    intitule: "ADAPTATION TECHNICIENS SUPERIEURS ECONOMIE-GESTION"
  },
  { code: "12209", intitule: "ENS RENNES OPTION D1 EN 1 AN" }
];

export const listebacs = [
          {
            code: "NBGE",
            intitule: "NOUVEAU BAC GENERAL",
            type: "Bac"
          },
         {
           code: "TD2A",
           intitule: "SC & TECHNOL DU DESIGN ET DES ARTS APPL",
           type: "Bac"
         },
         {
           code: "TI2D",
           intitule: "SC & TECHNOL INDUSTRIE ET DEVEL DURABLE",
           type: "Bac"
         },
         {
           code: "STAV",
           intitule: "SC ET TECHNOLOGIES AGRONOMIE ET VIVANT",
           type: "Bac"
         },
         {
           code: "ST2S",
           intitule: "SC ET TECHNOLOGIES SANTE ET SOCIAL",
           type: "Bac"
         },
         { code: "2", intitule: "0002-BAC EUROPEEN", type: "Bac" },
         {
           code: "STG",
           intitule: "SCIENCES ET TECHNIQUES DE GESTION",
           type: "Bac"
         },
         { code: "A", intitule: "A-PHILOSOPHIE-LETTRES", type: "Bac" },
         { code: "A1", intitule: "A1-LETTRES-SCIENCES", type: "Bac" },
         { code: "A2", intitule: "A2-LETTRES-LANGUES", type: "Bac" },
         { code: "A3", intitule: "A3-LETTRES-ARTS PLASTIQUES", type: "Bac" },
         {
           code: "C",
           intitule: "C-MATHEMATIQUES ET SCIENCES PHYSIQUES  .",
           type: "Bac"
         },
         {
           code: "D",
           intitule: "MATHEMATIQUES ET SCIENCES DE LA NATURE",
           type: "Bac"
         },
         {
           code: "DP",
           intitule: "D'-SCIENCES AGRONOMIQUES ET TECHNIQUES",
           type: "Bac"
         },
         { code: "E", intitule: "MATHEMATIQUES ET TECHNIQUES", type: "Bac" },
         { code: "1", intitule: "0001-BAC INTERNATIONAL", type: "Bac" },
         { code: "F1", intitule: "F1-CONSTRUCTION MECANIQUE", type: "Bac" },
         { code: "F2", intitule: "F2-ELECTRONIQUE", type: "Bac" },
         { code: "F3", intitule: "F3-ELECTROTECHNIQUE", type: "Bac" },
         { code: "F4", intitule: "F4-GENIE CIVIL", type: "Bac" },
         { code: "F7", intitule: "F7-BIOLOGIE OPTION BIOCHIMIE", type: "Bac" },
         { code: "F8", intitule: "F8-SCIENCES MEDICO-SOCIALES", type: "Bac" },
         {
           code: "F9",
           intitule: "F9-EQUIPEMENT TECHNIQUE-BATIMENT",
           type: "Bac"
         },
         {
           code: "F10A",
           intitule: "F10A-MICROTECHNIQUE OPTION APPAREILLAGE ",
           type: "Bac"
         },
         {
           code: "F10B",
           intitule: "F10B-MICROTECHNIQUE OPTION OPTIQUE      ",
           type: "Bac"
         },
         { code: "F11P", intitule: "TMD-DANSE", type: "Bac" },
         { code: "F", intitule: "F-SPECIALITE NON PRECISEE", type: "Bac" },
         { code: "G1", intitule: "G1-TECHNIQUES ADMNISTRATIVES", type: "Bac" },
         {
           code: "G2",
           intitule: "G2-TECHNIQUES QUANTITATIVES DE GESTION",
           type: "Bac"
         },
         { code: "G3", intitule: "G3-TECHNIQUES COMMERCIALES", type: "Bac" },
         { code: "G", intitule: "G-SPECIALITE NON PRECISEE", type: "Bac" },
         { code: "H", intitule: "H-TECHNIQUES INFORMATIQUES", type: "Bac" },
         {
           code: "21",
           intitule: "0021-BACS PROFESSIONNELS INDUSTRIELS",
           type: "Bac"
         },
         {
           code: "22",
           intitule: "0022-BACS PROFESSIONNELS TERTIAIRES",
           type: "Bac"
         },
         {
           code: "31",
           intitule: "0031-TITRE ETRANGER ADMIS EN EQUIVALENCE",
           type: "Equivalence"
         },
         {
           code: "32",
           intitule: "0032-TITRE FRANCAIS ADMIS EN DISPENSE",
           type: "Equivalence"
         },
         {
           code: "33",
           intitule: "0033-ESEU A OU D.A.E.U. A",
           type: "Equivalence"
         },
         {
           code: "34",
           intitule: "0034-ESEU B OU D.A.E.U. B",
           type: "Equivalence"
         },
         {
           code: "35",
           intitule: "0035-PROMOTION SOCIALE",
           type: "Equivalence"
         },
         {
           code: "36",
           intitule: "0036-VALIDATION ETUDES,EXPERIENCES PROF.",
           type: "Equivalence"
         },
         { code: "37", intitule: "0037-AUTRE CAS", type: "Equivalence" },
         { code: "S", intitule: "SCIENTIFIQUE", type: "Bac" },
         { code: "F7P", intitule: "BIOLOGIE OPTION BIOLOGIE", type: "Bac" },
         { code: "L", intitule: "LITTERAIRE", type: "Bac" },
         { code: "ES", intitule: "ECONOMIQUE ET SOCIALE", type: "Bac" },
         {
           code: "STI",
           intitule: "SCIENCES ET TECHNIQUES INDUSTRIELLES",
           type: "Bac"
         },
         {
           code: "STT",
           intitule: "SCIENCES ET TECHNOLOGIES TERTIAIRES",
           type: "Bac"
         },
         {
           code: "STL",
           intitule: "SCIENCES ET TECHNOLOGIES DE LABORATOIRE",
           type: "Bac"
         },
         { code: "SMS", intitule: "SCIENCES MEDICO-SOCIALES", type: "Bac" },
         {
           code: "STPA",
           intitule: "SC. ET TECH. DU PRODUIT AGRO-ALIMENTAIRE",
           type: "Bac"
         },
         {
           code: "STAE",
           intitule: "SC. ET TECH. AGRONOMIE ENVIRONNEMENT",
           type: "Bac"
         },
         {
           code: "30",
           intitule: "0030-CAPACITE DE DROIT",
           type: "Equivalence"
         },
         { code: "B", intitule: "B-ECONOMIQUE ET SOCIAL", type: "Bac" },
         { code: "F5", intitule: "F5-PHYSIQUE", type: "Bac" },
         { code: "F6", intitule: "F6-CHIMIE", type: "Bac" },
         {
           code: "F10",
           intitule: "F10-MICROTECHNIQUE(AVANT 1984)",
           type: "Bac"
         },
         { code: "F11", intitule: "TMD-MUSIQUE", type: "Bac" },
         { code: "F12", intitule: "F12-ARTS APPLIQUES", type: "Bac" },
         {
           code: "23",
           intitule: "0023-BACS PROFESSIONNELS AGRICOLES",
           type: "Bac"
         },
         { code: "A1-85", intitule: "A1 LATIN-GREC", type: "Bac" },
         { code: "A2-85", intitule: "A2 LATIN-LANGUES", type: "Bac" },
         { code: "A3-85", intitule: "A3 LATIN-MATHEMATIQUES", type: "Bac" },
         { code: "A6-85", intitule: "A6 EDUCATION MUSICALE", type: "Bac" },
         { code: "A7-85", intitule: "A7 ARTS PLASTIQUES", type: "Bac" },
         { code: "A4", intitule: "A4 LANGUES-MATHEMATIQUES", type: "Bac" },
         { code: "A5", intitule: "A5 LANGUES", type: "Bac" },
         {
           code: "STMG",
           intitule: "SC & TECHNOL MANAGEMENT ET GESTION",
           type: "Bac"
         },
         { code: "HOT", intitule: "HOTELLERIE", type: "Bac" },
         {
           code: "STHR",
           intitule: "SC. ET TECH. HOTELLERIE ET RESTAURATION",
           type: "Bac"
         },
         { code: "40", intitule: "DISPOSITIF BAC -3/+3", type: "Bac" },
         {
           code: "38",
           intitule: "0038-BREVET DES METIERS D'ART",
           type: "Equivalence"
         }
       ];

export const listedepartements = [
  { code:"0", intitule:"- Indéterminé -"},
  { code: "978", intitule: "SAINT-MARTIN" },
  { code: "977", intitule: "SAINT-BARTHELEMY" },
  { code: "22", intitule: "COTES D'ARMOR" },
  { code: "29", intitule: "FINISTERE" },
  { code: "35", intitule: "ILLE-ET-VILAINE" },
  { code: "49", intitule: "MAINE-ET-LOIRE" },
  { code: "72", intitule: "SARTHE" },
  { code: "53", intitule: "MAYENNE" },
  { code: "85", intitule: "VENDEE" },
  { code: "37", intitule: "INDRE-ET-LOIRE" },
  { code: "28", intitule: "EURE-ET-LOIR" },
  { code: "41", intitule: "LOIR-ET-CHER" },
  { code: "36", intitule: "INDRE" },
  { code: "45", intitule: "LOIRET" },
  { code: "18", intitule: "CHER" },
  { code: "83", intitule: "VAR" },
  { code: "75", intitule: "PARIS" },
  { code: "84", intitule: "VAUCLUSE" },
  { code: "13", intitule: "BOUCHES-DU-RHONE" },
  { code: "976", intitule: "MAYOTTE" },
  { code: "5", intitule: "HAUTES-ALPES" },
  { code: "4", intitule: "ALPES-DE-HTE-PROVENCE" },
  { code: "6", intitule: "ALPES-MARITIMES" },
  { code: "94", intitule: "VAL-DE-MARNE" },
  { code: "77", intitule: "SEINE-ET-MARNE" },
  { code: "91", intitule: "ESSONNE" },
  { code: "92", intitule: "HAUTS-DE-SEINE" },
  { code: "95", intitule: "VAL-D'OISE" },
  { code: "78", intitule: "YVELINES" },
  { code: "971", intitule: "GUADELOUPE" },
  { code: "973", intitule: "GUYANE" },
  { code: "02A", intitule: "CORSE-DU-SUD" },
  { code: "02B", intitule: "HAUTE-CORSE" },
  { code: "974", intitule: "LA REUNION" },
  { code: "975", intitule: "ST-PIERRE-ET-MIQUELON" },
  { code: "988", intitule: "NOUVELLE CALEDONIE" },
  { code: "44", intitule: "LOIRE-ATLANTIQUE" },
  { code: "56", intitule: "MORBIHAN" },
  { code: "93", intitule: "SEINE-SAINT-DENIS" },
  { code: "972", intitule: "MARTINIQUE" },
  { code: "987", intitule: "POLYNESIE FRANCAISE" },
  { code: "986", intitule: "WALLIS ET FUTUNA" },
  { code: "130", intitule: "ANDORRE" },
  { code: "99", intitule: "ETRANGER" },
  { code: "999", intitule: "ETRANGER" },
  { code: "990", intitule: "AUTRE PAYS" },
  { code: "989", intitule: "ILE DE CLIPPERTON" },
  { code: "17", intitule: "CHARENTE-MARITIME" },
  { code: "70", intitule: "HAUTE-SAONE" },
  { code: "24", intitule: "DORDOGNE" },
  { code: "87", intitule: "HAUTE-VIENNE" },
  { code: "984", intitule: "TERRES AUSTRALES, ANTARCT. FRANCAISES" },
  { code: "23", intitule: "CREUSE" },
  { code: "19", intitule: "CORREZE" },
  { code: "90", intitule: "TERRITOIRE DE BELFORT" },
  { code: "25", intitule: "DOUBS" },
  { code: "16", intitule: "CHARENTE" },
  { code: "79", intitule: "DEUX-SEVRES" },
  { code: "68", intitule: "HAUT-RHIN" },
  { code: "67", intitule: "BAS-RHIN" },
  { code: "81", intitule: "TARN" },
  { code: "12", intitule: "AVEYRON" },
  { code: "32", intitule: "GERS" },
  { code: "82", intitule: "TARN-ET-GARONNE" },
  { code: "46", intitule: "LOT" },
  { code: "31", intitule: "HAUTE-GARONNE" },
  { code: "65", intitule: "HAUTES-PYRENEES" },
  { code: "9", intitule: "ARIEGE" },
  { code: "8", intitule: "ARDENNES" },
  { code: "52", intitule: "HAUTE-MARNE" },
  { code: "10", intitule: "AUBE" },
  { code: "51", intitule: "MARNE" },
  { code: "2", intitule: "AISNE" },
  { code: "60", intitule: "OISE" },
  { code: "80", intitule: "SOMME" },
  { code: "76", intitule: "SEINE MARITIME" },
  { code: "39", intitule: "JURA" },
  { code: "40", intitule: "LANDES" },
  { code: "47", intitule: "LOT-ET-GARONNE" },
  { code: "64", intitule: "PYRENEES-ATLANTIQUES" },
  { code: "33", intitule: "GIRONDE" },
  { code: "50", intitule: "MANCHE" },
  { code: "14", intitule: "CALVADOS" },
  { code: "61", intitule: "ORNE" },
  { code: "15", intitule: "CANTAL" },
  { code: "3", intitule: "ALLIER" },
  { code: "43", intitule: "HAUTE-LOIRE" },
  { code: "63", intitule: "PUY-DE-DOME" },
  { code: "89", intitule: "YONNE" },
  { code: "58", intitule: "NIEVRE" },
  { code: "71", intitule: "SAONE-ET-LOIRE" },
  { code: "73", intitule: "SAVOIE" },
  { code: "74", intitule: "HAUTE SAVOIE" },
  { code: "26", intitule: "DROME" },
  { code: "38", intitule: "ISERE" },
  { code: "7", intitule: "ARDECHE" },
  { code: "59", intitule: "NORD" },
  { code: "62", intitule: "PAS-DE-CALAIS" },
  { code: "1", intitule: "AIN" },
  { code: "69", intitule: "RHONE" },
  { code: "42", intitule: "LOIRE" },
  { code: "66", intitule: "PYRENEES-ORIENTALES" },
  { code: "34", intitule: "HERAULT" },
  { code: "11", intitule: "AUDE" },
  { code: "54", intitule: "MEURTHE-ET-MOSELLE" },
  { code: "55", intitule: "MEUSE" },
  { code: "86", intitule: "VIENNE" },
  { code: "30", intitule: "GARD" },
  { code: "48", intitule: "LOZERE" },
  { code: "57", intitule: "MOSELLE" },
  { code: "88", intitule: "VOSGES" },
  { code: "27", intitule: "EURE" },
  { code: "21", intitule: "COTE D'OR" }
];

export const listecommunes = [
{ code : "1001", intitule : "L ABERGEMENT CLEMENCIAT", cp : "01400" },
{ code : "1002", intitule : "L ABERGEMENT DE VAREY", cp : "01640" },
{ code : "1004", intitule : "AMBERIEU EN BUGEY", cp : "01500" },
{ code : "1005", intitule : "AMBERIEUX EN DOMBES", cp : "01330" },
{ code : "1006", intitule : "AMBLEON", cp : "01300" },
{ code : "1007", intitule : "AMBRONAY", cp : "01500" },
{ code : "1008", intitule : "AMBUTRIX", cp : "01500" },
{ code : "1009", intitule : "ANDERT ET CONDON", cp : "01300" },
{ code : "1010", intitule : "ANGLEFORT", cp : "01350" },
{ code : "1011", intitule : "APREMONT", cp : "01100" },
{ code : "1012", intitule : "ARANC", cp : "01110" },
{ code : "1013", intitule : "ARANDAS", cp : "01230" },
{ code : "1014", intitule : "ARBENT", cp : "01100" },
{ code : "1015", intitule : "ARBOYS EN BUGEY", cp : "01300" },
{ code : "1015", intitule : "ARBOYS EN BUGEY", cp : "01300" },
{ code : "1016", intitule : "ARBIGNY", cp : "01190" },
{ code : "1017", intitule : "ARGIS", cp : "01230" },
{ code : "1019", intitule : "ARMIX", cp : "01510" },
{ code : "1021", intitule : "ARS SUR FORMANS", cp : "01480" },
{ code : "1022", intitule : "ARTEMARE", cp : "01510" },
{ code : "1023", intitule : "ASNIERES SUR SAONE", cp : "01570" },
{ code : "1024", intitule : "ATTIGNAT", cp : "01340" },
{ code : "1025", intitule : "BAGE LA VILLE", cp : "01380" },
{ code : "1026", intitule : "BAGE LE CHATEL", cp : "01380" },
{ code : "1027", intitule : "BALAN", cp : "01360" },
{ code : "1028", intitule : "BANEINS", cp : "01990" },
{ code : "1029", intitule : "BEAUPONT", cp : "01270" },
{ code : "1030", intitule : "BEAUREGARD", cp : "01480" },
{ code : "1031", intitule : "BELLIGNAT", cp : "01100" },
{ code : "1032", intitule : "BELIGNEUX", cp : "01360" },
{ code : "1032", intitule : "BELIGNEUX", cp : "01360" },
{ code : "1032", intitule : "BELIGNEUX", cp : "01360" },
{ code : "1032", intitule : "BELIGNEUX", cp : "01360" },
{ code : "1033", intitule : "BELLEGARDE SUR VALSERINE", cp : "01200" },
{ code : "1034", intitule : "BELLEY", cp : "01300" },
{ code : "1035", intitule : "BELLEYDOUX", cp : "01130" },
{ code : "1036", intitule : "BELMONT LUTHEZIEU", cp : "01260" },
{ code : "1036", intitule : "BELMONT LUTHEZIEU", cp : "01260" },
{ code : "1037", intitule : "BENONCES", cp : "01470" },
{ code : "1038", intitule : "BENY", cp : "01370" },
{ code : "1039", intitule : "BEON", cp : "01350" },
{ code : "1040", intitule : "BEREZIAT", cp : "01340" },
{ code : "1041", intitule : "BETTANT", cp : "01500" },
{ code : "1042", intitule : "BEY", cp : "01290" },
{ code : "1043", intitule : "BEYNOST", cp : "01700" },
{ code : "1044", intitule : "BILLIAT", cp : "01200" },
{ code : "1045", intitule : "BIRIEUX", cp : "01330" },
{ code : "1046", intitule : "BIZIAT", cp : "01290" },
{ code : "1047", intitule : "BLYES", cp : "01150" },
{ code : "1049", intitule : "LA BOISSE", cp : "01120" },
{ code : "1050", intitule : "BOISSEY", cp : "01380" },
{ code : "1050", intitule : "BOISSEY", cp : "01190" },
{ code : "1051", intitule : "BOLOZON", cp : "01450" },
{ code : "1052", intitule : "BOULIGNEUX", cp : "01330" },
{ code : "1053", intitule : "BOURG EN BRESSE", cp : "01000" },
{ code : "1053", intitule : "BOURG EN BRESSE", cp : "01000" },
{ code : "1054", intitule : "BOURG ST CHRISTOPHE", cp : "01800" },
{ code : "1054", intitule : "BOURG ST CHRISTOPHE", cp : "01800" },
{ code : "1056", intitule : "BOYEUX ST JEROME", cp : "01640" },
{ code : "1057", intitule : "BOZ", cp : "01190" },
{ code : "1058", intitule : "BREGNIER CORDON", cp : "01300" },
{ code : "1059", intitule : "BRENAZ", cp : "01260" },
{ code : "1060", intitule : "BRENOD", cp : "01110" },
{ code : "1061", intitule : "BRENS", cp : "01300" },
{ code : "1062", intitule : "BRESSOLLES", cp : "01360" },
{ code : "1062", intitule : "BRESSOLLES", cp : "01360" },
{ code : "1063", intitule : "BRION", cp : "01460" },
{ code : "1064", intitule : "BRIORD", cp : "01470" },
{ code : "1065", intitule : "BUELLAS", cp : "01310" },
{ code : "1065", intitule : "BUELLAS", cp : "01310" },
{ code : "1066", intitule : "LA BURBANCHE", cp : "01510" },
{ code : "1067", intitule : "CEIGNES", cp : "01430" },
{ code : "1068", intitule : "CERDON", cp : "01450" },
{ code : "1069", intitule : "CERTINES", cp : "01240" },
{ code : "1071", intitule : "CESSY", cp : "01170" },
{ code : "1072", intitule : "CEYZERIAT", cp : "01250" },
{ code : "1073", intitule : "CEYZERIEU", cp : "01350" },
{ code : "1074", intitule : "CHALAMONT", cp : "01320" },
{ code : "1075", intitule : "CHALEINS", cp : "01480" },
{ code : "1076", intitule : "CHALEY", cp : "01230" },
{ code : "1077", intitule : "CHALLES LA MONTAGNE", cp : "01450" },
{ code : "1078", intitule : "CHALLEX", cp : "01630" },
{ code : "1079", intitule : "CHAMPAGNE EN VALROMEY", cp : "01260" },
{ code : "1079", intitule : "CHAMPAGNE EN VALROMEY", cp : "01260" },
{ code : "1079", intitule : "CHAMPAGNE EN VALROMEY", cp : "01260" },
{ code : "1080", intitule : "CHAMPDOR CORCELLES", cp : "01110" },
{ code : "1080", intitule : "CHAMPDOR CORCELLES", cp : "01110" },
{ code : "1081", intitule : "CHAMPFROMIER", cp : "01410" },
{ code : "1082", intitule : "CHANAY", cp : "01420" },
{ code : "1083", intitule : "CHANEINS", cp : "01990" },
{ code : "1084", intitule : "CHANOZ CHATENAY", cp : "01400" },
{ code : "1085", intitule : "LA CHAPELLE DU CHATELARD", cp : "01240" },
{ code : "1087", intitule : "CHARIX", cp : "01130" },
{ code : "1088", intitule : "CHARNOZ SUR AIN", cp : "01800" },
{ code : "1089", intitule : "CHATEAU GAILLARD", cp : "01500" },
{ code : "1090", intitule : "CHATENAY", cp : "01320" },
{ code : "1091", intitule : "CHATILLON EN MICHAILLE", cp : "01200" },
{ code : "1091", intitule : "CHATILLON EN MICHAILLE", cp : "01200" },
{ code : "1091", intitule : "CHATILLON EN MICHAILLE", cp : "01200" },
{ code : "1092", intitule : "CHATILLON LA PALUD", cp : "01320" },
{ code : "1093", intitule : "CHATILLON SUR CHALARONNE", cp : "01400" },
{ code : "1094", intitule : "CHAVANNES SUR REYSSOUZE", cp : "01190" },
{ code : "1095", intitule : "CHAVANNES SUR SURAN", cp : "01250" },
{ code : "1096", intitule : "CHAVEYRIAT", cp : "01660" },
{ code : "1097", intitule : "CHAVORNAY", cp : "01510" },
{ code : "1098", intitule : "CHAZEY BONS", cp : "01300" },
{ code : "1099", intitule : "CHAZEY SUR AIN", cp : "01150" },
{ code : "1100", intitule : "CHEIGNIEU LA BALME", cp : "01510" },
{ code : "1101", intitule : "CHEVILLARD", cp : "01430" },
{ code : "1102", intitule : "CHEVROUX", cp : "01190" },
{ code : "1103", intitule : "CHEVRY", cp : "01170" },
{ code : "1104", intitule : "CHEZERY FORENS", cp : "01200" },
{ code : "1104", intitule : "CHEZERY FORENS", cp : "01410" },
{ code : "1105", intitule : "CIVRIEUX", cp : "01390" },
{ code : "1106", intitule : "CIZE", cp : "01250" },
{ code : "1107", intitule : "CLEYZIEU", cp : "01230" },
{ code : "1108", intitule : "COLIGNY", cp : "01270" },
{ code : "1109", intitule : "COLLONGES", cp : "01550" },
{ code : "1110", intitule : "COLOMIEU", cp : "01300" },
{ code : "1111", intitule : "CONAND", cp : "01230" },
{ code : "1112", intitule : "CONDAMINE", cp : "01430" },
{ code : "1113", intitule : "CONDEISSIAT", cp : "01400" },
{ code : "1114", intitule : "CONFORT", cp : "01200" },
{ code : "1115", intitule : "CONFRANCON", cp : "01310" },
{ code : "1116", intitule : "CONTREVOZ", cp : "01300" },
{ code : "1117", intitule : "CONZIEU", cp : "01300" },
{ code : "1118", intitule : "CORBONOD", cp : "01420" },
{ code : "1121", intitule : "CORLIER", cp : "01110" },
{ code : "1122", intitule : "CORMARANCHE EN BUGEY", cp : "01110" },
{ code : "1123", intitule : "CORMORANCHE SUR SAONE", cp : "01290" },
{ code : "1124", intitule : "CORMOZ", cp : "01560" },
{ code : "1125", intitule : "CORVEISSIAT", cp : "01250" },
{ code : "1127", intitule : "COURMANGOUX", cp : "01370" },
{ code : "1128", intitule : "COURTES", cp : "01560" },
{ code : "1129", intitule : "CRANS", cp : "01320" },
{ code : "1130", intitule : "CRAS SUR REYSSOUZE", cp : "01340" },
{ code : "1133", intitule : "CRESSIN ROCHEFORT", cp : "01350" },
{ code : "1134", intitule : "CROTTET", cp : "01750" },
{ code : "1134", intitule : "CROTTET", cp : "01290" },
{ code : "1135", intitule : "CROZET", cp : "01170" },
{ code : "1136", intitule : "CRUZILLES LES MEPILLAT", cp : "01290" },
{ code : "1138", intitule : "CULOZ", cp : "01350" },
{ code : "1139", intitule : "CURCIAT DONGALON", cp : "01560" },
{ code : "1140", intitule : "CURTAFOND", cp : "01310" },
{ code : "1141", intitule : "CUZIEU", cp : "01300" },
{ code : "1142", intitule : "DAGNEUX", cp : "01120" },
{ code : "1143", intitule : "DIVONNE LES BAINS", cp : "01220" },
{ code : "1144", intitule : "DOMMARTIN", cp : "01380" },
{ code : "1145", intitule : "DOMPIERRE SUR VEYLE", cp : "01240" },
{ code : "1146", intitule : "DOMPIERRE SUR CHALARONNE", cp : "01400" },
{ code : "1147", intitule : "DOMSURE", cp : "01270" },
{ code : "1148", intitule : "DORTAN", cp : "01590" },
{ code : "1149", intitule : "DOUVRES", cp : "01500" },
{ code : "1150", intitule : "DROM", cp : "01250" },
{ code : "1151", intitule : "DRUILLAT", cp : "01160" },
{ code : "1152", intitule : "ECHALLON", cp : "01130" },
{ code : "1153", intitule : "ECHENEVEX", cp : "01170" },
{ code : "1154", intitule : "ETREZ", cp : "01340" },
{ code : "1155", intitule : "EVOSGES", cp : "01230" },
{ code : "1156", intitule : "FARAMANS", cp : "01800" },
{ code : "1157", intitule : "FAREINS", cp : "01480" },
{ code : "1158", intitule : "FARGES", cp : "01550" },
{ code : "1159", intitule : "FEILLENS", cp : "01570" },
{ code : "1160", intitule : "FERNEY VOLTAIRE", cp : "01210" },
{ code : "1162", intitule : "FLAXIEU", cp : "01350" },
{ code : "1163", intitule : "FOISSIAT", cp : "01340" },
{ code : "1165", intitule : "FRANCHELEINS", cp : "01090" },
{ code : "1165", intitule : "FRANCHELEINS", cp : "01090" },
{ code : "1165", intitule : "FRANCHELEINS", cp : "01090" },
{ code : "1166", intitule : "FRANS", cp : "01480" },
{ code : "1167", intitule : "GARNERANS", cp : "01140" },
{ code : "1169", intitule : "GENOUILLEUX", cp : "01090" },
{ code : "1170", intitule : "BEARD GEOVREISSIAT", cp : "01460" },
{ code : "1171", intitule : "GEOVREISSET", cp : "01100" },
{ code : "1172", intitule : "GERMAGNAT", cp : "01250" },
{ code : "1173", intitule : "GEX", cp : "01170" },
{ code : "1174", intitule : "GIRON", cp : "01130" },
{ code : "1175", intitule : "GORREVOD", cp : "01190" },
{ code : "1177", intitule : "GRAND CORENT", cp : "01250" },
{ code : "1179", intitule : "GRIEGES", cp : "01290" },
{ code : "1180", intitule : "GRILLY", cp : "01220" },
{ code : "1181", intitule : "GROISSIAT", cp : "01100" },
{ code : "1183", intitule : "GUEREINS", cp : "01090" },
{ code : "1184", intitule : "HAUTECOURT ROMANECHE", cp : "01250" },
{ code : "1184", intitule : "HAUTECOURT ROMANECHE", cp : "01250" },
{ code : "1185", intitule : "HAUTEVILLE LOMPNES", cp : "01110" },
{ code : "1186", intitule : "HOSTIAZ", cp : "01110" },
{ code : "1187", intitule : "HAUT VALROMEY", cp : "01260" },
{ code : "1187", intitule : "HAUT VALROMEY", cp : "01260" },
{ code : "1187", intitule : "HAUT VALROMEY", cp : "01260" },
{ code : "1187", intitule : "HAUT VALROMEY", cp : "01260" },
{ code : "1188", intitule : "ILLIAT", cp : "01140" },
{ code : "1189", intitule : "INJOUX GENISSIAT", cp : "01200" },
{ code : "1189", intitule : "INJOUX GENISSIAT", cp : "01200" },
{ code : "1190", intitule : "INNIMOND", cp : "01680" },
{ code : "1191", intitule : "IZENAVE", cp : "01430" },
{ code : "1192", intitule : "IZERNORE", cp : "01580" },
{ code : "1193", intitule : "IZIEU", cp : "01300" },
{ code : "1194", intitule : "JASSANS RIOTTIER", cp : "01480" },
{ code : "1195", intitule : "JASSERON", cp : "01250" },
{ code : "1196", intitule : "JAYAT", cp : "01340" },
{ code : "1197", intitule : "JOURNANS", cp : "01250" },
{ code : "1198", intitule : "JOYEUX", cp : "01800" },
{ code : "1199", intitule : "JUJURIEUX", cp : "01640" },
{ code : "1200", intitule : "LABALME", cp : "01450" },
{ code : "1202", intitule : "LAGNIEU", cp : "01150" },
{ code : "1203", intitule : "LAIZ", cp : "01290" },
{ code : "1204", intitule : "LE POIZAT LALLEYRIAT", cp : "01130" },
{ code : "1204", intitule : "LE POIZAT LALLEYRIAT", cp : "01130" },
{ code : "1205", intitule : "LANCRANS", cp : "01200" },
{ code : "1206", intitule : "LANTENAY", cp : "01430" },
{ code : "1207", intitule : "LAPEYROUSE", cp : "01330" },
{ code : "1208", intitule : "LAVOURS", cp : "01350" },
{ code : "1209", intitule : "LEAZ", cp : "01200" },
{ code : "1210", intitule : "LELEX", cp : "01410" },
{ code : "1211", intitule : "LENT", cp : "01240" },
{ code : "1212", intitule : "LESCHEROUX", cp : "01560" },
{ code : "1213", intitule : "LEYMENT", cp : "01150" },
{ code : "1214", intitule : "LEYSSARD", cp : "01450" },
{ code : "1215", intitule : "LHOPITAL", cp : "01420" },
{ code : "1216", intitule : "LHUIS", cp : "01680" },
{ code : "1218", intitule : "LOCHIEU", cp : "01260" },
{ code : "1219", intitule : "LOMPNAS", cp : "01680" },
{ code : "1221", intitule : "LOMPNIEU", cp : "01260" },
{ code : "1224", intitule : "LOYETTES", cp : "01360" },
{ code : "1225", intitule : "LURCY", cp : "01090" },
{ code : "1227", intitule : "MAGNIEU", cp : "01300" },
{ code : "1228", intitule : "MAILLAT", cp : "01430" },
{ code : "1229", intitule : "MALAFRETAZ", cp : "01340" },
{ code : "1230", intitule : "MANTENAY MONTLIN", cp : "01560" },
{ code : "1231", intitule : "MANZIAT", cp : "01570" },
{ code : "1232", intitule : "MARBOZ", cp : "01851" },
{ code : "1233", intitule : "MARCHAMP", cp : "01680" },
{ code : "1234", intitule : "MARIGNIEU", cp : "01300" },
{ code : "1235", intitule : "MARLIEUX", cp : "01240" },
{ code : "1236", intitule : "MARSONNAS", cp : "01340" },
{ code : "1237", intitule : "MARTIGNAT", cp : "01100" },
{ code : "1238", intitule : "MASSIEUX", cp : "01600" },
{ code : "1239", intitule : "MASSIGNIEU DE RIVES", cp : "01300" },
{ code : "1240", intitule : "MATAFELON GRANGES", cp : "01580" },
{ code : "1240", intitule : "MATAFELON GRANGES", cp : "01580" },
{ code : "1241", intitule : "MEILLONNAS", cp : "01370" },
{ code : "1242", intitule : "MERIGNAT", cp : "01450" },
{ code : "1243", intitule : "MESSIMY SUR SAONE", cp : "01480" },
{ code : "1244", intitule : "MEXIMIEUX", cp : "01800" },
{ code : "1245", intitule : "BOHAS MEYRIAT RIGNAT", cp : "01250" },
{ code : "1245", intitule : "BOHAS MEYRIAT RIGNAT", cp : "01250" },
{ code : "1245", intitule : "BOHAS MEYRIAT RIGNAT", cp : "01250" },
{ code : "1246", intitule : "MEZERIAT", cp : "01660" },
{ code : "1247", intitule : "MIJOUX", cp : "01170" },
{ code : "1247", intitule : "MIJOUX", cp : "01410" },
{ code : "1248", intitule : "MIONNAY", cp : "01390" },
{ code : "1249", intitule : "MIRIBEL", cp : "01700" },
{ code : "1249", intitule : "MIRIBEL", cp : "01700" },
{ code : "1249", intitule : "MIRIBEL", cp : "01700" },
{ code : "1250", intitule : "MISERIEUX", cp : "01600" },
{ code : "1252", intitule : "MOGNENEINS", cp : "01140" },
{ code : "1254", intitule : "MONTAGNAT", cp : "01250" },
{ code : "1255", intitule : "MONTAGNIEU", cp : "01470" },
{ code : "1257", intitule : "MONTANGES", cp : "01200" },
{ code : "1258", intitule : "MONTCEAUX", cp : "01090" },
{ code : "1259", intitule : "MONTCET", cp : "01310" },
{ code : "1260", intitule : "LE MONTELLIER", cp : "01800" },
{ code : "1261", intitule : "MONTHIEUX", cp : "01390" },
{ code : "1262", intitule : "MONTLUEL", cp : "01120" },
{ code : "1262", intitule : "MONTLUEL", cp : "01120" },
{ code : "1262", intitule : "MONTLUEL", cp : "01120" },
{ code : "1263", intitule : "MONTMERLE SUR SAONE", cp : "01090" },
{ code : "1264", intitule : "MONTRACOL", cp : "01310" },
{ code : "1265", intitule : "MONTREAL LA CLUSE", cp : "01460" },
{ code : "1266", intitule : "MONTREVEL EN BRESSE", cp : "01340" },
{ code : "1267", intitule : "NURIEUX VOLOGNAT", cp : "01460" },
{ code : "1267", intitule : "NURIEUX VOLOGNAT", cp : "01460" },
{ code : "1268", intitule : "MURS ET GELIGNIEUX", cp : "01300" },
{ code : "1269", intitule : "NANTUA", cp : "01460" },
{ code : "1269", intitule : "NANTUA", cp : "01130" },
{ code : "1272", intitule : "NEUVILLE LES DAMES", cp : "01400" },
{ code : "1273", intitule : "NEUVILLE SUR AIN", cp : "01160" },
{ code : "1274", intitule : "LES NEYROLLES", cp : "01130" },
{ code : "1275", intitule : "NEYRON", cp : "01700" },
{ code : "1276", intitule : "NIEVROZ", cp : "01120" },
{ code : "1277", intitule : "NIVOLLET MONTGRIFFON", cp : "01230" },
{ code : "1279", intitule : "ONCIEU", cp : "01230" },
{ code : "1280", intitule : "ORDONNAZ", cp : "01510" },
{ code : "1281", intitule : "ORNEX", cp : "01210" },
{ code : "1282", intitule : "OUTRIAZ", cp : "01430" },
{ code : "1283", intitule : "OYONNAX", cp : "01100" },
{ code : "1283", intitule : "OYONNAX", cp : "01100" },
{ code : "1283", intitule : "OYONNAX", cp : "01100" },
{ code : "1284", intitule : "OZAN", cp : "01190" },
{ code : "1285", intitule : "PARCIEUX", cp : "01600" },
{ code : "1286", intitule : "PARVES ET NATTAGES", cp : "01300" },
{ code : "1286", intitule : "PARVES ET NATTAGES", cp : "01300" },
{ code : "1288", intitule : "PERON", cp : "01630" },
{ code : "1289", intitule : "PERONNAS", cp : "01960" },
{ code : "1290", intitule : "PEROUGES", cp : "01800" },
{ code : "1291", intitule : "PERREX", cp : "01540" },
{ code : "1293", intitule : "PEYRIAT", cp : "01430" },
{ code : "1294", intitule : "PEYRIEU", cp : "01300" },
{ code : "1295", intitule : "PEYZIEUX SUR SAONE", cp : "01140" },
{ code : "1296", intitule : "PIRAJOUX", cp : "01270" },
{ code : "1297", intitule : "PIZAY", cp : "01120" },
{ code : "1298", intitule : "PLAGNE", cp : "01130" },
{ code : "1299", intitule : "LE PLANTAY", cp : "01330" },
{ code : "1301", intitule : "POLLIAT", cp : "01310" },
{ code : "1302", intitule : "POLLIEU", cp : "01350" },
{ code : "1303", intitule : "PONCIN", cp : "01450" },
{ code : "1304", intitule : "PONT D AIN", cp : "01160" },
{ code : "1305", intitule : "PONT DE VAUX", cp : "01190" },
{ code : "1306", intitule : "PONT DE VEYLE", cp : "01290" },
{ code : "1307", intitule : "PORT", cp : "01460" },
{ code : "1308", intitule : "POUGNY", cp : "01550" },
{ code : "1309", intitule : "POUILLAT", cp : "01250" },
{ code : "1310", intitule : "PREMEYZEL", cp : "01300" },
{ code : "1311", intitule : "PREMILLIEU", cp : "01110" },
{ code : "1313", intitule : "PREVESSIN MOENS", cp : "01280" },
{ code : "1313", intitule : "PREVESSIN MOENS", cp : "01280" },
{ code : "1314", intitule : "PRIAY", cp : "01160" },
{ code : "1316", intitule : "PUGIEU", cp : "01510" },
{ code : "1317", intitule : "RAMASSE", cp : "01250" },
{ code : "1318", intitule : "RANCE", cp : "01390" },
{ code : "1319", intitule : "RELEVANT", cp : "01990" },
{ code : "1320", intitule : "REPLONGES", cp : "01750" },
{ code : "1321", intitule : "REVONNAS", cp : "01250" },
{ code : "1322", intitule : "REYRIEUX", cp : "01600" },
{ code : "1323", intitule : "REYSSOUZE", cp : "01190" },
{ code : "1325", intitule : "RIGNIEUX LE FRANC", cp : "01800" },
{ code : "1328", intitule : "ROMANS", cp : "01400" },
{ code : "1329", intitule : "ROSSILLON", cp : "01510" },
{ code : "1330", intitule : "RUFFIEU", cp : "01260" },
{ code : "1331", intitule : "ST ALBAN", cp : "01450" },
{ code : "1332", intitule : "ST ANDRE DE BAGE", cp : "01380" },
{ code : "1333", intitule : "ST ANDRE DE CORCY", cp : "01390" },
{ code : "1334", intitule : "ST ANDRE D HUIRIAT", cp : "01290" },
{ code : "1335", intitule : "ST ANDRE LE BOUCHOUX", cp : "01240" },
{ code : "1336", intitule : "ST ANDRE SUR VIEUX JONC", cp : "01960" },
{ code : "1337", intitule : "ST BENIGNE", cp : "01190" },
{ code : "1338", intitule : "GROSLEE ST BENOIT", cp : "01680" },
{ code : "1338", intitule : "GROSLEE ST BENOIT", cp : "01300" },
{ code : "1339", intitule : "ST BERNARD", cp : "01600" },
{ code : "1341", intitule : "ST CHAMP", cp : "01300" },
{ code : "1342", intitule : "STE CROIX", cp : "01120" },
{ code : "1343", intitule : "ST CYR SUR MENTHON", cp : "01380" },
{ code : "1344", intitule : "ST DENIS LES BOURG", cp : "01000" },
{ code : "1345", intitule : "ST DENIS EN BUGEY", cp : "01500" },
{ code : "1346", intitule : "ST DIDIER D AUSSIAT", cp : "01340" },
{ code : "1347", intitule : "ST DIDIER DE FORMANS", cp : "01600" },
{ code : "1348", intitule : "ST DIDIER SUR CHALARONNE", cp : "01140" },
{ code : "1349", intitule : "ST ELOI", cp : "01800" },
{ code : "1350", intitule : "ST ETIENNE DU BOIS", cp : "01370" },
{ code : "1351", intitule : "ST ETIENNE SUR CHALARONNE", cp : "01140" },
{ code : "1352", intitule : "ST ETIENNE SUR REYSSOUZE", cp : "01190" },
{ code : "1353", intitule : "STE EUPHEMIE", cp : "01600" },
{ code : "1354", intitule : "ST GENIS POUILLY", cp : "01630" },
{ code : "1355", intitule : "ST GENIS SUR MENTHON", cp : "01380" },
{ code : "1356", intitule : "ST GEORGES SUR RENON", cp : "01400" },
{ code : "1357", intitule : "ST GERMAIN DE JOUX", cp : "01130" },
{ code : "1358", intitule : "ST GERMAIN LES PAROISSES", cp : "01300" },
{ code : "1359", intitule : "ST GERMAIN SUR RENON", cp : "01240" },
{ code : "1360", intitule : "ST JEAN DE GONVILLE", cp : "01630" },
{ code : "1361", intitule : "ST JEAN DE NIOST", cp : "01800" },
{ code : "1362", intitule : "ST JEAN DE THURIGNEUX", cp : "01390" },
{ code : "1363", intitule : "ST JEAN LE VIEUX", cp : "01640" },
{ code : "1364", intitule : "ST JEAN SUR REYSSOUZE", cp : "01560" },
{ code : "1365", intitule : "ST JEAN SUR VEYLE", cp : "01290" },
{ code : "1366", intitule : "STE JULIE", cp : "01150" },
{ code : "1367", intitule : "ST JULIEN SUR REYSSOUZE", cp : "01560" },
{ code : "1368", intitule : "ST JULIEN SUR VEYLE", cp : "01540" },
{ code : "1369", intitule : "ST JUST", cp : "01250" },
{ code : "1370", intitule : "ST LAURENT SUR SAONE", cp : "01750" },
{ code : "1371", intitule : "ST MARCEL", cp : "01390" },
{ code : "1372", intitule : "ST MARTIN DE BAVEL", cp : "01510" },
{ code : "1373", intitule : "ST MARTIN DU FRENE", cp : "01430" },
{ code : "1374", intitule : "ST MARTIN DU MONT", cp : "01160" },
{ code : "1375", intitule : "ST MARTIN LE CHATEL", cp : "01310" },
{ code : "1376", intitule : "ST MAURICE DE BEYNOST", cp : "01700" },
{ code : "1378", intitule : "ST MAURICE DE GOURDANS", cp : "01800" },
{ code : "1379", intitule : "ST MAURICE DE REMENS", cp : "01500" },
{ code : "1380", intitule : "ST NIZIER LE BOUCHOUX", cp : "01560" },
{ code : "1381", intitule : "ST NIZIER LE DESERT", cp : "01320" },
{ code : "1382", intitule : "STE OLIVE", cp : "01330" },
{ code : "1383", intitule : "ST PAUL DE VARAX", cp : "01240" },
{ code : "1384", intitule : "ST RAMBERT EN BUGEY", cp : "01230" },
{ code : "1385", intitule : "ST REMY", cp : "01310" },
{ code : "1386", intitule : "ST SORLIN EN BUGEY", cp : "01150" },
{ code : "1387", intitule : "ST SULPICE", cp : "01340" },
{ code : "1388", intitule : "ST TRIVIER DE COURTES", cp : "01560" },
{ code : "1389", intitule : "ST TRIVIER SUR MOIGNANS", cp : "01990" },
{ code : "1390", intitule : "ST VULBAS", cp : "01150" },
{ code : "1391", intitule : "SALAVRE", cp : "01270" },
{ code : "1392", intitule : "SAMOGNAT", cp : "01580" },
{ code : "1393", intitule : "SANDRANS", cp : "01400" },
{ code : "1396", intitule : "SAULT BRENAZ", cp : "01150" },
{ code : "1397", intitule : "SAUVERNY", cp : "01220" },
{ code : "1398", intitule : "SAVIGNEUX", cp : "01480" },
{ code : "1399", intitule : "SEGNY", cp : "01170" },
{ code : "1400", intitule : "SEILLONNAZ", cp : "01470" },
{ code : "1401", intitule : "SERGY", cp : "01630" },
{ code : "1402", intitule : "SERMOYER", cp : "01190" },
{ code : "1403", intitule : "SERRIERES DE BRIORD", cp : "01470" },
{ code : "1404", intitule : "SERRIERES SUR AIN", cp : "01450" },
{ code : "1405", intitule : "SERVAS", cp : "01960" },
{ code : "1406", intitule : "SERVIGNAT", cp : "01560" },
{ code : "1407", intitule : "SEYSSEL", cp : "01420" },
{ code : "1408", intitule : "SIMANDRE SUR SURAN", cp : "01250" },
{ code : "1410", intitule : "SONTHONNAX LA MONTAGNE", cp : "01580" },
{ code : "1410", intitule : "SONTHONNAX LA MONTAGNE", cp : "01580" },
{ code : "1411", intitule : "SOUCLIN", cp : "01150" },
{ code : "1412", intitule : "SULIGNAT", cp : "01400" },
{ code : "1413", intitule : "SURJOUX", cp : "01420" },
{ code : "1414", intitule : "SUTRIEU", cp : "01260" },
{ code : "1414", intitule : "SUTRIEU", cp : "01260" },
{ code : "1414", intitule : "SUTRIEU", cp : "01260" },
{ code : "1415", intitule : "TALISSIEU", cp : "01510" },
{ code : "1416", intitule : "TENAY", cp : "01230" },
{ code : "1417", intitule : "THEZILLIEU", cp : "01110" },
{ code : "1418", intitule : "THIL", cp : "01120" },
{ code : "1419", intitule : "THOIRY", cp : "01710" },
{ code : "1420", intitule : "THOISSEY", cp : "01140" },
{ code : "1421", intitule : "TORCIEU", cp : "01230" },
{ code : "1422", intitule : "TOSSIAT", cp : "01250" },
{ code : "1423", intitule : "TOUSSIEUX", cp : "01600" },
{ code : "1424", intitule : "TRAMOYES", cp : "01390" },
{ code : "1425", intitule : "LA TRANCLIERE", cp : "01160" },
{ code : "1426", intitule : "VAL REVERMONT", cp : "01370" },
{ code : "1426", intitule : "VAL REVERMONT", cp : "01370" },
{ code : "1426", intitule : "VAL REVERMONT", cp : "01370" },
{ code : "1427", intitule : "TREVOUX", cp : "01600" },
{ code : "1428", intitule : "VALEINS", cp : "01140" },
{ code : "1429", intitule : "VANDEINS", cp : "01660" },
{ code : "1430", intitule : "VARAMBON", cp : "01160" },
{ code : "1431", intitule : "VAUX EN BUGEY", cp : "01150" },
{ code : "1432", intitule : "VERJON", cp : "01270" },
{ code : "1433", intitule : "VERNOUX", cp : "01560" },
{ code : "1434", intitule : "VERSAILLEUX", cp : "01330" },
{ code : "1435", intitule : "VERSONNEX", cp : "01210" },
{ code : "1436", intitule : "VESANCY", cp : "01170" },
{ code : "1437", intitule : "VESCOURS", cp : "01560" },
{ code : "1439", intitule : "VESINES", cp : "01570" },
{ code : "1441", intitule : "VIEU D IZENAVE", cp : "01430" },
{ code : "1442", intitule : "VIEU", cp : "01260" },
{ code : "1443", intitule : "VILLARS LES DOMBES", cp : "01330" },
{ code : "1444", intitule : "VILLEBOIS", cp : "01150" },
{ code : "1445", intitule : "VILLEMOTIER", cp : "01270" },
{ code : "1446", intitule : "VILLENEUVE", cp : "01480" },
{ code : "1447", intitule : "VILLEREVERSURE", cp : "01250" },
{ code : "1448", intitule : "VILLES", cp : "01200" },
{ code : "1449", intitule : "VILLETTE SUR AIN", cp : "01320" },
{ code : "1450", intitule : "VILLIEU LOYES MOLLON", cp : "01800" },
{ code : "1450", intitule : "VILLIEU LOYES MOLLON", cp : "01800" },
{ code : "1450", intitule : "VILLIEU LOYES MOLLON", cp : "01800" },
{ code : "1451", intitule : "VIRIAT", cp : "01440" },
{ code : "1452", intitule : "VIRIEU LE GRAND", cp : "01510" },
{ code : "1453", intitule : "VIRIEU LE PETIT", cp : "01260" },
{ code : "1454", intitule : "VIRIGNIN", cp : "01300" },
{ code : "1456", intitule : "VONGNES", cp : "01350" },
{ code : "1457", intitule : "VONNAS", cp : "01540" },
{ code : "2001", intitule : "ABBECOURT", cp : "02300" },
{ code : "2002", intitule : "ACHERY", cp : "02800" },
{ code : "2003", intitule : "ACY", cp : "02200" },
{ code : "2004", intitule : "AGNICOURT ET SECHELLES", cp : "02340" },
{ code : "2005", intitule : "AGUILCOURT", cp : "02190" },
{ code : "2006", intitule : "AISONVILLE ET BERNOVILLE", cp : "02110" },
{ code : "2007", intitule : "AIZELLES", cp : "02820" },
{ code : "2008", intitule : "AIZY JOUY", cp : "02370" },
{ code : "2008", intitule : "AIZY JOUY", cp : "02370" },
{ code : "2009", intitule : "ALAINCOURT", cp : "02240" },
{ code : "2010", intitule : "ALLEMANT", cp : "02320" },
{ code : "2011", intitule : "AMBLENY", cp : "02290" },
{ code : "2012", intitule : "AMBRIEF", cp : "02200" },
{ code : "2013", intitule : "AMIFONTAINE", cp : "02190" },
{ code : "2014", intitule : "AMIGNY ROUY", cp : "02700" },
{ code : "2015", intitule : "ANCIENVILLE", cp : "02600" },
{ code : "2016", intitule : "ANDELAIN", cp : "02800" },
{ code : "2017", intitule : "ANGUILCOURT LE SART", cp : "02800" },
{ code : "2018", intitule : "ANIZY LE CHATEAU", cp : "02320" },
{ code : "2019", intitule : "ANNOIS", cp : "02480" },
{ code : "2020", intitule : "ANY MARTIN RIEUX", cp : "02500" },
{ code : "2021", intitule : "ARCHON", cp : "02360" },
{ code : "2022", intitule : "ARCY STE RESTITUE", cp : "02130" },
{ code : "2022", intitule : "ARCY STE RESTITUE", cp : "02130" },
{ code : "2023", intitule : "ARMENTIERES SUR OURCQ", cp : "02210" },
{ code : "2024", intitule : "ARRANCY", cp : "02860" },
{ code : "2025", intitule : "ARTEMPS", cp : "02480" },
{ code : "2027", intitule : "ASSIS SUR SERRE", cp : "02270" },
{ code : "2028", intitule : "ATHIES SOUS LAON", cp : "02840" },
{ code : "2029", intitule : "ATTILLY", cp : "02490" },
{ code : "2030", intitule : "AUBENCHEUL AUX BOIS", cp : "02420" },
{ code : "2031", intitule : "AUBENTON", cp : "02500" },
{ code : "2032", intitule : "AUBIGNY AUX KAISNES", cp : "02590" },
{ code : "2033", intitule : "AUBIGNY EN LAONNOIS", cp : "02820" },
{ code : "2034", intitule : "AUDIGNICOURT", cp : "02300" },
{ code : "2035", intitule : "AUDIGNY", cp : "02120" },
{ code : "2036", intitule : "AUGY", cp : "02220" },
{ code : "2037", intitule : "AULNOIS SOUS LAON", cp : "02000" },
{ code : "2038", intitule : "LES AUTELS", cp : "02360" },
{ code : "2039", intitule : "AUTREMENCOURT", cp : "02250" },
{ code : "2040", intitule : "AUTREPPES", cp : "02580" },
{ code : "2041", intitule : "AUTREVILLE", cp : "02300" },
{ code : "2042", intitule : "AZY SUR MARNE", cp : "02400" },
{ code : "2043", intitule : "BAGNEUX", cp : "02290" },
{ code : "2044", intitule : "BANCIGNY", cp : "02140" },
{ code : "2046", intitule : "BARENTON BUGNY", cp : "02000" },
{ code : "2047", intitule : "BARENTON CEL", cp : "02000" },
{ code : "2048", intitule : "BARENTON SUR SERRE", cp : "02270" },
{ code : "2049", intitule : "BARISIS AUX BOIS", cp : "02700" },
{ code : "2050", intitule : "BARZY EN THIERACHE", cp : "02170" },
{ code : "2051", intitule : "BARZY SUR MARNE", cp : "02850" },
{ code : "2052", intitule : "BASSOLES AULERS", cp : "02380" },
{ code : "2053", intitule : "VALLEES EN CHAMPAGNE", cp : "02330" },
{ code : "2053", intitule : "VALLEES EN CHAMPAGNE", cp : "02330" },
{ code : "2053", intitule : "VALLEES EN CHAMPAGNE", cp : "02330" },
{ code : "2054", intitule : "BAZOCHES SUR VESLES", cp : "02220" },
{ code : "2055", intitule : "BEAUME", cp : "02500" },
{ code : "2056", intitule : "BEAUMONT EN BEINE", cp : "02300" },
{ code : "2057", intitule : "BEAUREVOIR", cp : "02110" },
{ code : "2058", intitule : "BEAURIEUX", cp : "02160" },
{ code : "2059", intitule : "BEAUTOR", cp : "02800" },
{ code : "2060", intitule : "BEAUVOIS EN VERMANDOIS", cp : "02590" },
{ code : "2061", intitule : "BECQUIGNY", cp : "02110" },
{ code : "2062", intitule : "BELLEAU", cp : "02400" },
{ code : "2063", intitule : "BELLENGLISE", cp : "02420" },
{ code : "2064", intitule : "BELLEU", cp : "02200" },
{ code : "2065", intitule : "BELLICOURT", cp : "02420" },
{ code : "2066", intitule : "BENAY", cp : "02440" },
{ code : "2067", intitule : "BERGUES SUR SAMBRE", cp : "02450" },
{ code : "2068", intitule : "BERLANCOURT", cp : "02250" },
{ code : "2069", intitule : "BERLISE", cp : "02340" },
{ code : "2070", intitule : "BERNOT", cp : "02120" },
{ code : "2071", intitule : "BERNY RIVIERE", cp : "02290" },
{ code : "2072", intitule : "BERRIEUX", cp : "02820" },
{ code : "2073", intitule : "BERRY AU BAC", cp : "02190" },
{ code : "2074", intitule : "BERTAUCOURT EPOURDON", cp : "02800" },
{ code : "2075", intitule : "BERTHENICOURT", cp : "02240" },
{ code : "2076", intitule : "BERTRICOURT", cp : "02190" },
{ code : "2077", intitule : "BERZY LE SEC", cp : "02200" },
{ code : "2078", intitule : "BESME", cp : "02300" },
{ code : "2079", intitule : "BESMONT", cp : "02500" },
{ code : "2080", intitule : "BESNY ET LOIZY", cp : "02870" },
{ code : "2081", intitule : "BETHANCOURT EN VAUX", cp : "02300" },
{ code : "2082", intitule : "BEUGNEUX", cp : "02210" },
{ code : "2083", intitule : "BEUVARDES", cp : "02130" },
{ code : "2084", intitule : "BEZU LE GUERY", cp : "02310" },
{ code : "2085", intitule : "BEZU ST GERMAIN", cp : "02400" },
{ code : "2086", intitule : "BICHANCOURT", cp : "02300" },
{ code : "2087", intitule : "BIEUXY", cp : "02290" },
{ code : "2088", intitule : "BIEVRES", cp : "02860" },
{ code : "2089", intitule : "BILLY SUR AISNE", cp : "02200" },
{ code : "2090", intitule : "BILLY SUR OURCQ", cp : "02210" },
{ code : "2091", intitule : "BLANZY LES FISMES", cp : "02160" },
{ code : "2093", intitule : "BLERANCOURT", cp : "02300" },
{ code : "2094", intitule : "BLESMES", cp : "02400" },
{ code : "2095", intitule : "BOHAIN EN VERMANDOIS", cp : "02110" },
{ code : "2096", intitule : "BOIS LES PARGNY", cp : "02270" },
{ code : "2097", intitule : "BONCOURT", cp : "02350" },
{ code : "2098", intitule : "BONNEIL", cp : "02400" },
{ code : "2099", intitule : "BONNESVALYN", cp : "02400" },
{ code : "2100", intitule : "BONY", cp : "02420" },
{ code : "2101", intitule : "BOSMONT SUR SERRE", cp : "02250" },
{ code : "2102", intitule : "BOUCONVILLE VAUCLAIR", cp : "02860" },
{ code : "2103", intitule : "BOUE", cp : "02450" },
{ code : "2104", intitule : "BOUFFIGNEREUX", cp : "02160" },
{ code : "2105", intitule : "BOURESCHES", cp : "02400" },
{ code : "2106", intitule : "BOURG ET COMIN", cp : "02160" },
{ code : "2107", intitule : "BOURGUIGNON SOUS COUCY", cp : "02300" },
{ code : "2108", intitule : "BOURGUIGNON SOUS MONTBAVIN", cp : "02000" },
{ code : "2109", intitule : "LA BOUTEILLE", cp : "02140" },
{ code : "2110", intitule : "BRAINE", cp : "02220" },
{ code : "2111", intitule : "BRANCOURT EN LAONNOIS", cp : "02320" },
{ code : "2112", intitule : "BRANCOURT LE GRAND", cp : "02110" },
{ code : "2114", intitule : "BRASLES", cp : "02400" },
{ code : "2115", intitule : "BRAYE EN LAONNOIS", cp : "02000" },
{ code : "2116", intitule : "BRAYE EN THIERACHE", cp : "02140" },
{ code : "2117", intitule : "BRAY ST CHRISTOPHE", cp : "02480" },
{ code : "2118", intitule : "BRAYE", cp : "02880" },
{ code : "2119", intitule : "BRECY", cp : "02210" },
{ code : "2120", intitule : "BRENELLE", cp : "02220" },
{ code : "2121", intitule : "BRENY", cp : "02210" },
{ code : "2122", intitule : "BRIE", cp : "02870" },
{ code : "2123", intitule : "BRISSAY CHOIGNY", cp : "02240" },
{ code : "2124", intitule : "BRISSY HAMEGICOURT", cp : "02240" },
{ code : "2125", intitule : "BRUMETZ", cp : "02810" },
{ code : "2126", intitule : "BRUNEHAMEL", cp : "02360" },
{ code : "2127", intitule : "BRUYERES SUR FERE", cp : "02130" },
{ code : "2128", intitule : "BRUYERES ET MONTBERAULT", cp : "02860" },
{ code : "2129", intitule : "BRUYS", cp : "02220" },
{ code : "2130", intitule : "BUCILLY", cp : "02500" },
{ code : "2131", intitule : "BUCY LE LONG", cp : "02880" },
{ code : "2132", intitule : "BUCY LES CERNY", cp : "02870" },
{ code : "2133", intitule : "BUCY LES PIERREPONT", cp : "02350" },
{ code : "2134", intitule : "BUIRE", cp : "02500" },
{ code : "2135", intitule : "BUIRONFOSSE", cp : "02620" },
{ code : "2136", intitule : "BURELLES", cp : "02140" },
{ code : "2137", intitule : "BUSSIARES", cp : "02810" },
{ code : "2138", intitule : "BUZANCY", cp : "02200" },
{ code : "2139", intitule : "CAILLOUEL CREPIGNY", cp : "02300" },
{ code : "2140", intitule : "CAMELIN", cp : "02300" },
{ code : "2140", intitule : "CAMELIN", cp : "02300" },
{ code : "2141", intitule : "LA CAPELLE", cp : "02260" },
{ code : "2142", intitule : "CASTRES", cp : "02680" },
{ code : "2143", intitule : "LE CATELET", cp : "02420" },
{ code : "2144", intitule : "CAULAINCOURT", cp : "02490" },
{ code : "2145", intitule : "CAUMONT", cp : "02300" },
{ code : "2146", intitule : "CELLES LES CONDE", cp : "02330" },
{ code : "2148", intitule : "CELLES SUR AISNE", cp : "02370" },
{ code : "2149", intitule : "CERIZY", cp : "02240" },
{ code : "2150", intitule : "CERNY EN LAONNOIS", cp : "02860" },
{ code : "2151", intitule : "CERNY LES BUCY", cp : "02870" },
{ code : "2152", intitule : "CERSEUIL", cp : "02220" },
{ code : "2153", intitule : "CESSIERES", cp : "02320" },
{ code : "2154", intitule : "CHACRISE", cp : "02200" },
{ code : "2155", intitule : "CHAILLEVOIS", cp : "02000" },
{ code : "2156", intitule : "CHALANDRY", cp : "02270" },
{ code : "2157", intitule : "CHAMBRY", cp : "02000" },
{ code : "2158", intitule : "CHAMOUILLE", cp : "02860" },
{ code : "2159", intitule : "CHAMPS", cp : "02670" },
{ code : "2160", intitule : "CHAOURSE", cp : "02340" },
{ code : "2162", intitule : "LA CHAPELLE SUR CHEZY", cp : "02570" },
{ code : "2163", intitule : "CHARLY SUR MARNE", cp : "02310" },
{ code : "2164", intitule : "LE CHARMEL", cp : "02850" },
{ code : "2165", intitule : "CHARMES", cp : "02800" },
{ code : "2166", intitule : "CHARTEVES", cp : "02400" },
{ code : "2167", intitule : "CHASSEMY", cp : "02370" },
{ code : "2168", intitule : "CHATEAU THIERRY", cp : "02400" },
{ code : "2169", intitule : "CHATILLON LES SONS", cp : "02270" },
{ code : "2170", intitule : "CHATILLON SUR OISE", cp : "02240" },
{ code : "2171", intitule : "CHAUDARDES", cp : "02160" },
{ code : "2172", intitule : "CHAUDUN", cp : "02200" },
{ code : "2173", intitule : "CHAUNY", cp : "02300" },
{ code : "2174", intitule : "CHAVIGNON", cp : "02000" },
{ code : "2175", intitule : "CHAVIGNY", cp : "02880" },
{ code : "2176", intitule : "CHAVONNE", cp : "02370" },
{ code : "2177", intitule : "CHERET", cp : "02860" },
{ code : "2178", intitule : "CHERMIZY AILLES", cp : "02860" },
{ code : "2179", intitule : "CHERY CHARTREUVE", cp : "02220" },
{ code : "2180", intitule : "CHERY LES POUILLY", cp : "02000" },
{ code : "2181", intitule : "CHERY LES ROZOY", cp : "02360" },
{ code : "2182", intitule : "CHEVENNES", cp : "02250" },
{ code : "2183", intitule : "CHEVREGNY", cp : "02000" },
{ code : "2184", intitule : "CHEVRESIS MONCEAU", cp : "02270" },
{ code : "2185", intitule : "CHEZY EN ORXOIS", cp : "02810" },
{ code : "2186", intitule : "CHEZY SUR MARNE", cp : "02570" },
{ code : "2187", intitule : "CHIERRY", cp : "02400" },
{ code : "2188", intitule : "CHIGNY", cp : "02120" },
{ code : "2189", intitule : "CHIVRES EN LAONNOIS", cp : "02350" },
{ code : "2190", intitule : "CHIVRES VAL", cp : "02880" },
{ code : "2191", intitule : "CHIVY LES ETOUVELLES", cp : "02000" },
{ code : "2192", intitule : "CHOUY", cp : "02210" },
{ code : "2193", intitule : "CIERGES", cp : "02130" },
{ code : "2194", intitule : "CILLY", cp : "02250" },
{ code : "2195", intitule : "CIRY SALSOGNE", cp : "02220" },
{ code : "2196", intitule : "CLACY ET THIERRET", cp : "02000" },
{ code : "2197", intitule : "CLAIRFONTAINE", cp : "02260" },
{ code : "2198", intitule : "CLAMECY", cp : "02880" },
{ code : "2199", intitule : "CLASTRES", cp : "02440" },
{ code : "2200", intitule : "CLERMONT LES FERMES", cp : "02340" },
{ code : "2201", intitule : "COEUVRES ET VALSERY", cp : "02600" },
{ code : "2203", intitule : "COINCY", cp : "02210" },
{ code : "2204", intitule : "COINGT", cp : "02360" },
{ code : "2205", intitule : "COLLIGIS CRANDELAIN", cp : "02860" },
{ code : "2206", intitule : "COLONFAY", cp : "02120" },
{ code : "2207", intitule : "COMMENCHON", cp : "02300" },
{ code : "2208", intitule : "CONCEVREUX", cp : "02160" },
{ code : "2209", intitule : "CONDE EN BRIE", cp : "02330" },
{ code : "2210", intitule : "CONDE SUR AISNE", cp : "02370" },
{ code : "2211", intitule : "CONDE SUR SUIPPE", cp : "02190" },
{ code : "2212", intitule : "CONDREN", cp : "02700" },
{ code : "2213", intitule : "CONNIGIS", cp : "02330" },
{ code : "2214", intitule : "CONTESCOURT", cp : "02680" },
{ code : "2215", intitule : "CORBENY", cp : "02820" },
{ code : "2216", intitule : "CORCY", cp : "02600" },
{ code : "2217", intitule : "COUCY LE CHATEAU AUFFRIQUE", cp : "02380" },
{ code : "2218", intitule : "COUCY LES EPPES", cp : "02840" },
{ code : "2219", intitule : "COUCY LA VILLE", cp : "02380" },
{ code : "2220", intitule : "COULONGES COHAN", cp : "02130" },
{ code : "2220", intitule : "COULONGES COHAN", cp : "02130" },
{ code : "2221", intitule : "COUPRU", cp : "02310" },
{ code : "2222", intitule : "COURBES", cp : "02800" },
{ code : "2223", intitule : "COURBOIN", cp : "02330" },
{ code : "2224", intitule : "COURCELLES SUR VESLE", cp : "02220" },
{ code : "2225", intitule : "COURCHAMPS", cp : "02810" },
{ code : "2226", intitule : "COURMELLES", cp : "02200" },
{ code : "2227", intitule : "COURMONT", cp : "02130" },
{ code : "2228", intitule : "COURTEMONT VARENNES", cp : "02850" },
{ code : "2229", intitule : "COURTRIZY ET FUSSIGNY", cp : "02820" },
{ code : "2230", intitule : "COUVRELLES", cp : "02220" },
{ code : "2231", intitule : "COUVRON ET AUMENCOURT", cp : "02270" },
{ code : "2232", intitule : "COYOLLES", cp : "02600" },
{ code : "2233", intitule : "CRAMAILLE", cp : "02130" },
{ code : "2234", intitule : "CRAONNE", cp : "02160" },
{ code : "2235", intitule : "CRAONNELLE", cp : "02160" },
{ code : "2236", intitule : "CRECY AU MONT", cp : "02380" },
{ code : "2237", intitule : "CRECY SUR SERRE", cp : "02270" },
{ code : "2238", intitule : "CREPY", cp : "02870" },
{ code : "2239", intitule : "CREZANCY", cp : "02650" },
{ code : "2240", intitule : "CROIX FONSOMME", cp : "02110" },
{ code : "2241", intitule : "LA CROIX SUR OURCQ", cp : "02210" },
{ code : "2242", intitule : "CROUTTES SUR MARNE", cp : "02310" },
{ code : "2243", intitule : "CROUY", cp : "02880" },
{ code : "2244", intitule : "CRUPILLY", cp : "02120" },
{ code : "2245", intitule : "CUFFIES", cp : "02880" },
{ code : "2246", intitule : "CUGNY", cp : "02480" },
{ code : "2248", intitule : "CUIRIEUX", cp : "02350" },
{ code : "2249", intitule : "CUIRY HOUSSE", cp : "02220" },
{ code : "2250", intitule : "CUIRY LES CHAUDARDES", cp : "02160" },
{ code : "2251", intitule : "CUIRY LES IVIERS", cp : "02360" },
{ code : "2252", intitule : "CUISSY ET GENY", cp : "02160" },
{ code : "2253", intitule : "CUISY EN ALMONT", cp : "02200" },
{ code : "2254", intitule : "CUTRY", cp : "02600" },
{ code : "2255", intitule : "CYS LA COMMUNE", cp : "02220" },
{ code : "2256", intitule : "DAGNY LAMBERCY", cp : "02140" },
{ code : "2257", intitule : "DALLON", cp : "02680" },
{ code : "2258", intitule : "DAMMARD", cp : "02470" },
{ code : "2259", intitule : "DAMPLEUX", cp : "02600" },
{ code : "2260", intitule : "DANIZY", cp : "02800" },
{ code : "2261", intitule : "DERCY", cp : "02270" },
{ code : "2262", intitule : "DEUILLET", cp : "02700" },
{ code : "2263", intitule : "DHUIZEL", cp : "02220" },
{ code : "2264", intitule : "DIZY LE GROS", cp : "02340" },
{ code : "2265", intitule : "DOHIS", cp : "02360" },
{ code : "2266", intitule : "DOLIGNON", cp : "02360" },
{ code : "2267", intitule : "DOMMIERS", cp : "02600" },
{ code : "2268", intitule : "DOMPTIN", cp : "02310" },
{ code : "2269", intitule : "DORENGT", cp : "02450" },
{ code : "2270", intitule : "DOUCHY", cp : "02590" },
{ code : "2271", intitule : "DRAVEGNY", cp : "02130" },
{ code : "2272", intitule : "DROIZY", cp : "02210" },
{ code : "2273", intitule : "DURY", cp : "02480" },
{ code : "2274", intitule : "EBOULEAU", cp : "02350" },
{ code : "2275", intitule : "EFFRY", cp : "02500" },
{ code : "2276", intitule : "ENGLANCOURT", cp : "02260" },
{ code : "2277", intitule : "EPAGNY", cp : "02290" },
{ code : "2278", intitule : "EPARCY", cp : "02500" },
{ code : "2279", intitule : "EPAUX BEZU", cp : "02400" },
{ code : "2280", intitule : "EPIEDS", cp : "02400" },
{ code : "2281", intitule : "L EPINE AUX BOIS", cp : "02540" },
{ code : "2282", intitule : "EPPES", cp : "02840" },
{ code : "2283", intitule : "ERLON", cp : "02250" },
{ code : "2284", intitule : "ERLOY", cp : "02260" },
{ code : "2286", intitule : "ESQUEHERIES", cp : "02170" },
{ code : "2287", intitule : "ESSIGNY LE GRAND", cp : "02690" },
{ code : "2288", intitule : "ESSIGNY LE PETIT", cp : "02100" },
{ code : "2289", intitule : "ESSISES", cp : "02570" },
{ code : "2290", intitule : "ESSOMES SUR MARNE", cp : "02400" },
{ code : "2291", intitule : "ESTREES", cp : "02420" },
{ code : "2292", intitule : "ETAMPES SUR MARNE", cp : "02400" },
{ code : "2293", intitule : "ETAVES ET BOCQUIAUX", cp : "02110" },
{ code : "2294", intitule : "ETOUVELLES", cp : "02000" },
{ code : "2295", intitule : "ETREAUPONT", cp : "02580" },
{ code : "2296", intitule : "ETREILLERS", cp : "02590" },
{ code : "2297", intitule : "ETREPILLY", cp : "02400" },
{ code : "2298", intitule : "ETREUX", cp : "02510" },
{ code : "2299", intitule : "EVERGNICOURT", cp : "02190" },
{ code : "2301", intitule : "FAUCOUCOURT", cp : "02320" },
{ code : "2302", intitule : "FAVEROLLES", cp : "02600" },
{ code : "2303", intitule : "FAYET", cp : "02100" },
{ code : "2304", intitule : "LA FERE", cp : "02800" },
{ code : "2305", intitule : "FERE EN TARDENOIS", cp : "02130" },
{ code : "2306", intitule : "LA FERTE CHEVRESIS", cp : "02270" },
{ code : "2307", intitule : "LA FERTE MILON", cp : "02460" },
{ code : "2307", intitule : "LA FERTE MILON", cp : "02460" },
{ code : "2308", intitule : "FESMY LE SART", cp : "02450" },
{ code : "2308", intitule : "FESMY LE SART", cp : "02450" },
{ code : "2309", intitule : "FESTIEUX", cp : "02840" },
{ code : "2310", intitule : "FIEULAINE", cp : "02110" },
{ code : "2311", intitule : "FILAIN", cp : "02000" },
{ code : "2312", intitule : "LA FLAMENGRIE", cp : "02260" },
{ code : "2313", intitule : "FLAVIGNY LE GRAND ET BEAURAIN", cp : "02120" },
{ code : "2315", intitule : "FLAVY LE MARTEL", cp : "02520" },
{ code : "2316", intitule : "FLEURY", cp : "02600" },
{ code : "2317", intitule : "FLUQUIERES", cp : "02590" },
{ code : "2318", intitule : "FOLEMBRAY", cp : "02670" },
{ code : "2319", intitule : "FONSOMME", cp : "02110" },
{ code : "2320", intitule : "FONTAINE LES CLERCS", cp : "02680" },
{ code : "2321", intitule : "FONTAINE LES VERVINS", cp : "02140" },
{ code : "2322", intitule : "FONTAINE NOTRE DAME", cp : "02110" },
{ code : "2323", intitule : "FONTAINE UTERTE", cp : "02110" },
{ code : "2324", intitule : "FONTENELLE", cp : "02170" },
{ code : "2326", intitule : "FONTENOY", cp : "02290" },
{ code : "2327", intitule : "FORESTE", cp : "02590" },
{ code : "2328", intitule : "FOSSOY", cp : "02650" },
{ code : "2329", intitule : "FOURDRAIN", cp : "02870" },
{ code : "2330", intitule : "FRANCILLY SELENCY", cp : "02760" },
{ code : "2331", intitule : "FRANQUEVILLE", cp : "02140" },
{ code : "2332", intitule : "FRESNES EN TARDENOIS", cp : "02130" },
{ code : "2333", intitule : "FRESNES", cp : "02380" },
{ code : "2334", intitule : "FRESNOY LE GRAND", cp : "02230" },
{ code : "2335", intitule : "FRESSANCOURT", cp : "02800" },
{ code : "2336", intitule : "FRIERES FAILLOUEL", cp : "02700" },
{ code : "2337", intitule : "FROIDESTREES", cp : "02260" },
{ code : "2338", intitule : "FROIDMONT COHARTILLE", cp : "02270" },
{ code : "2339", intitule : "GANDELU", cp : "02810" },
{ code : "2340", intitule : "GAUCHY", cp : "02430" },
{ code : "2341", intitule : "GERCY", cp : "02140" },
{ code : "2342", intitule : "GERGNY", cp : "02260" },
{ code : "2343", intitule : "GERMAINE", cp : "02590" },
{ code : "2344", intitule : "GERNICOURT", cp : "02160" },
{ code : "2345", intitule : "GIBERCOURT", cp : "02440" },
{ code : "2346", intitule : "GIZY", cp : "02350" },
{ code : "2347", intitule : "GLAND", cp : "02400" },
{ code : "2349", intitule : "GOUDELANCOURT LES BERRIEUX", cp : "02820" },
{ code : "2350", intitule : "GOUDELANCOURT LES PIERREPONT", cp : "02350" },
{ code : "2351", intitule : "GOUSSANCOURT", cp : "02130" },
{ code : "2352", intitule : "GOUY", cp : "02420" },
{ code : "2353", intitule : "GRANDLUP ET FAY", cp : "02350" },
{ code : "2354", intitule : "GRANDRIEUX", cp : "02360" },
{ code : "2355", intitule : "GRICOURT", cp : "02100" },
{ code : "2356", intitule : "GRISOLLES", cp : "02210" },
{ code : "2357", intitule : "GRONARD", cp : "02140" },
{ code : "2358", intitule : "GROUGIS", cp : "02110" },
{ code : "2359", intitule : "GRUGIES", cp : "02680" },
{ code : "2360", intitule : "GUIGNICOURT", cp : "02190" },
{ code : "2361", intitule : "GUISE", cp : "02120" },
{ code : "2362", intitule : "GUIVRY", cp : "02300" },
{ code : "2363", intitule : "GUNY", cp : "02300" },
{ code : "2364", intitule : "GUYENCOURT", cp : "02160" },
{ code : "2366", intitule : "HANNAPES", cp : "02510" },
{ code : "2367", intitule : "HAPPENCOURT", cp : "02480" },
{ code : "2368", intitule : "HARAMONT", cp : "02600" },
{ code : "2369", intitule : "HARCIGNY", cp : "02140" },
{ code : "2370", intitule : "HARGICOURT", cp : "02420" },
{ code : "2371", intitule : "HARLY", cp : "02100" },
{ code : "2372", intitule : "HARTENNES ET TAUX", cp : "02210" },
{ code : "2373", intitule : "HARY", cp : "02140" },
{ code : "2374", intitule : "LEHAUCOURT", cp : "02420" },
{ code : "2375", intitule : "HAUTEVESNES", cp : "02810" },
{ code : "2376", intitule : "HAUTEVILLE", cp : "02120" },
{ code : "2377", intitule : "HAUTION", cp : "02140" },
{ code : "2378", intitule : "LA HERIE", cp : "02500" },
{ code : "2379", intitule : "LE HERIE LA VIEVILLE", cp : "02120" },
{ code : "2380", intitule : "HINACOURT", cp : "02440" },
{ code : "2381", intitule : "HIRSON", cp : "02500" },
{ code : "2382", intitule : "HOLNON", cp : "02760" },
{ code : "2383", intitule : "HOMBLIERES", cp : "02720" },
{ code : "2384", intitule : "HOURY", cp : "02140" },
{ code : "2385", intitule : "HOUSSET", cp : "02250" },
{ code : "2386", intitule : "IRON", cp : "02510" },
{ code : "2387", intitule : "ITANCOURT", cp : "02240" },
{ code : "2388", intitule : "IVIERS", cp : "02360" },
{ code : "2389", intitule : "JAULGONNE", cp : "02850" },
{ code : "2390", intitule : "JEANCOURT", cp : "02490" },
{ code : "2391", intitule : "JEANTES", cp : "02140" },
{ code : "2392", intitule : "JONCOURT", cp : "02420" },
{ code : "2393", intitule : "JOUAIGNES", cp : "02220" },
{ code : "2395", intitule : "JUMENCOURT", cp : "02380" },
{ code : "2396", intitule : "JUMIGNY", cp : "02160" },
{ code : "2397", intitule : "JUSSY", cp : "02480" },
{ code : "2398", intitule : "JUVIGNY", cp : "02880" },
{ code : "2399", intitule : "JUVINCOURT ET DAMARY", cp : "02190" },
{ code : "2400", intitule : "LAFFAUX", cp : "02880" },
{ code : "2401", intitule : "LAIGNY", cp : "02140" },
{ code : "2402", intitule : "LANCHY", cp : "02590" },
{ code : "2403", intitule : "LANDIFAY ET BERTAIGNEMONT", cp : "02120" },
{ code : "2404", intitule : "LANDOUZY LA COUR", cp : "02140" },
{ code : "2405", intitule : "LANDOUZY LA VILLE", cp : "02140" },
{ code : "2406", intitule : "LANDRICOURT", cp : "02380" },
{ code : "2407", intitule : "LANISCOURT", cp : "02000" },
{ code : "2408", intitule : "LAON", cp : "02000" },
{ code : "2409", intitule : "LAPPION", cp : "02150" },
{ code : "2410", intitule : "LARGNY SUR AUTOMNE", cp : "02600" },
{ code : "2411", intitule : "LATILLY", cp : "02210" },
{ code : "2412", intitule : "LAUNOY", cp : "02210" },
{ code : "2413", intitule : "LAVAL EN LAONNOIS", cp : "02860" },
{ code : "2414", intitule : "LAVAQUERESSE", cp : "02450" },
{ code : "2415", intitule : "LAVERSINE", cp : "02600" },
{ code : "2416", intitule : "LEME", cp : "02140" },
{ code : "2417", intitule : "LEMPIRE", cp : "02420" },
{ code : "2418", intitule : "LERZY", cp : "02260" },
{ code : "2419", intitule : "LESCHELLE", cp : "02170" },
{ code : "2420", intitule : "LESDINS", cp : "02100" },
{ code : "2421", intitule : "LESGES", cp : "02220" },
{ code : "2422", intitule : "LESQUIELLES ST GERMAIN", cp : "02120" },
{ code : "2423", intitule : "LEUILLY SOUS COUCY", cp : "02380" },
{ code : "2424", intitule : "LEURY", cp : "02880" },
{ code : "2425", intitule : "LEUZE", cp : "02500" },
{ code : "2426", intitule : "LEVERGIES", cp : "02420" },
{ code : "2427", intitule : "LHUYS", cp : "02220" },
{ code : "2428", intitule : "LICY CLIGNON", cp : "02810" },
{ code : "2429", intitule : "LIERVAL", cp : "02860" },
{ code : "2430", intitule : "LIESSE NOTRE DAME", cp : "02350" },
{ code : "2431", intitule : "LIEZ", cp : "02700" },
{ code : "2432", intitule : "LIME", cp : "02220" },
{ code : "2433", intitule : "LISLET", cp : "02340" },
{ code : "2434", intitule : "LIZY", cp : "02320" },
{ code : "2435", intitule : "LOGNY LES AUBENTON", cp : "02500" },
{ code : "2438", intitule : "LONGPONT", cp : "02600" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2439", intitule : "LES SEPTVALLONS", cp : "02160" },
{ code : "2440", intitule : "LOR", cp : "02190" },
{ code : "2441", intitule : "LOUATRE", cp : "02600" },
{ code : "2442", intitule : "LOUPEIGNE", cp : "02130" },
{ code : "2443", intitule : "LUCY LE BOCAGE", cp : "02400" },
{ code : "2444", intitule : "LUGNY", cp : "02140" },
{ code : "2445", intitule : "LUZOIR", cp : "02500" },
{ code : "2446", intitule : "LY FONTAINE", cp : "02440" },
{ code : "2447", intitule : "MAAST ET VIOLAINE", cp : "02220" },
{ code : "2448", intitule : "MACHECOURT", cp : "02350" },
{ code : "2449", intitule : "MACOGNY", cp : "02470" },
{ code : "2450", intitule : "MACQUIGNY", cp : "02120" },
{ code : "2451", intitule : "MAGNY LA FOSSE", cp : "02420" },
{ code : "2452", intitule : "MAISSEMY", cp : "02490" },
{ code : "2453", intitule : "MAIZY", cp : "02160" },
{ code : "2454", intitule : "LA MALMAISON", cp : "02190" },
{ code : "2455", intitule : "MALZY", cp : "02120" },
{ code : "2456", intitule : "MANICAMP", cp : "02300" },
{ code : "2457", intitule : "MARCHAIS", cp : "02350" },
{ code : "2458", intitule : "DHUYS ET MORIN EN BRIE", cp : "02540" },
{ code : "2458", intitule : "DHUYS ET MORIN EN BRIE", cp : "02540" },
{ code : "2458", intitule : "DHUYS ET MORIN EN BRIE", cp : "02330" },
{ code : "2458", intitule : "DHUYS ET MORIN EN BRIE", cp : "02540" },
{ code : "2459", intitule : "MARCY", cp : "02720" },
{ code : "2460", intitule : "MARCY SOUS MARLE", cp : "02250" },
{ code : "2461", intitule : "MAREST DAMPCOURT", cp : "02300" },
{ code : "2462", intitule : "MAREUIL EN DOLE", cp : "02130" },
{ code : "2463", intitule : "MARFONTAINE", cp : "02140" },
{ code : "2464", intitule : "MARGIVAL", cp : "02880" },
{ code : "2465", intitule : "MARIGNY EN ORXOIS", cp : "02810" },
{ code : "2466", intitule : "MARIZY STE GENEVIEVE", cp : "02470" },
{ code : "2467", intitule : "MARIZY ST MARD", cp : "02470" },
{ code : "2468", intitule : "MARLE", cp : "02250" },
{ code : "2469", intitule : "MARLY GOMONT", cp : "02120" },
{ code : "2470", intitule : "MARTIGNY", cp : "02500" },
{ code : "2471", intitule : "MARTIGNY COURPIERRE", cp : "02860" },
{ code : "2472", intitule : "MAUREGNY EN HAYE", cp : "02820" },
{ code : "2473", intitule : "MAYOT", cp : "02800" },
{ code : "2474", intitule : "MENNESSIS", cp : "02700" },
{ code : "2475", intitule : "MENNEVILLE", cp : "02190" },
{ code : "2476", intitule : "MENNEVRET", cp : "02630" },
{ code : "2477", intitule : "MERCIN ET VAUX", cp : "02200" },
{ code : "2478", intitule : "MERLIEUX ET FOUQUEROLLES", cp : "02000" },
{ code : "2480", intitule : "MESBRECOURT RICHECOURT", cp : "02270" },
{ code : "2481", intitule : "MESNIL ST LAURENT", cp : "02720" },
{ code : "2482", intitule : "MEURIVAL", cp : "02160" },
{ code : "2483", intitule : "MEZIERES SUR OISE", cp : "02240" },
{ code : "2484", intitule : "MEZY MOULINS", cp : "02650" },
{ code : "2485", intitule : "MISSY AUX BOIS", cp : "02200" },
{ code : "2486", intitule : "MISSY LES PIERREPONT", cp : "02350" },
{ code : "2487", intitule : "MISSY SUR AISNE", cp : "02880" },
{ code : "2488", intitule : "MOLAIN", cp : "02110" },
{ code : "2489", intitule : "MOLINCHART", cp : "02000" },
{ code : "2490", intitule : "MONAMPTEUIL", cp : "02000" },
{ code : "2491", intitule : "MONCEAU LE NEUF ET FAUCOUZY", cp : "02270" },
{ code : "2492", intitule : "MONCEAU LES LEUPS", cp : "02270" },
{ code : "2493", intitule : "MONCEAU LE WAAST", cp : "02840" },
{ code : "2494", intitule : "MONCEAU SUR OISE", cp : "02120" },
{ code : "2495", intitule : "MONDREPUIS", cp : "02500" },
{ code : "2496", intitule : "MONNES", cp : "02470" },
{ code : "2497", intitule : "MONS EN LAONNOIS", cp : "02000" },
{ code : "2498", intitule : "MONTAIGU", cp : "02820" },
{ code : "2499", intitule : "MONTBAVIN", cp : "02000" },
{ code : "2500", intitule : "MONTBREHAIN", cp : "02110" },
{ code : "2501", intitule : "MONTCHALONS", cp : "02860" },
{ code : "2502", intitule : "MONTCORNET", cp : "02340" },
{ code : "2503", intitule : "MONT D ORIGNY", cp : "02390" },
{ code : "2504", intitule : "MONTESCOURT LIZEROLLES", cp : "02440" },
{ code : "2505", intitule : "MONTFAUCON", cp : "02540" },
{ code : "2506", intitule : "MONTGOBERT", cp : "02600" },
{ code : "2507", intitule : "MONTGRU ST HILAIRE", cp : "02210" },
{ code : "2508", intitule : "MONTHENAULT", cp : "02860" },
{ code : "2509", intitule : "MONTHIERS", cp : "02400" },
{ code : "2510", intitule : "MONTHUREL", cp : "02330" },
{ code : "2511", intitule : "MONTIGNY EN ARROUAISE", cp : "02110" },
{ code : "2512", intitule : "MONTIGNY L ALLIER", cp : "02810" },
{ code : "2513", intitule : "MONTIGNY LE FRANC", cp : "02250" },
{ code : "2514", intitule : "MONTIGNY LENGRAIN", cp : "02290" },
{ code : "2515", intitule : "MONTIGNY LES CONDE", cp : "02330" },
{ code : "2516", intitule : "MONTIGNY SOUS MARLE", cp : "02250" },
{ code : "2517", intitule : "MONTIGNY SUR CRECY", cp : "02270" },
{ code : "2518", intitule : "MONTLEVON", cp : "02330" },
{ code : "2519", intitule : "MONTLOUE", cp : "02340" },
{ code : "2520", intitule : "MONT NOTRE DAME", cp : "02220" },
{ code : "2521", intitule : "MONTREUIL AUX LIONS", cp : "02310" },
{ code : "2522", intitule : "MONT ST JEAN", cp : "02360" },
{ code : "2523", intitule : "MONT ST MARTIN", cp : "02220" },
{ code : "2524", intitule : "MONT ST PERE", cp : "02400" },
{ code : "2525", intitule : "MORCOURT", cp : "02100" },
{ code : "2526", intitule : "MORGNY EN THIERACHE", cp : "02360" },
{ code : "2527", intitule : "MORSAIN", cp : "02290" },
{ code : "2528", intitule : "MORTEFONTAINE", cp : "02600" },
{ code : "2529", intitule : "MORTIERS", cp : "02270" },
{ code : "2530", intitule : "MOULINS", cp : "02160" },
{ code : "2531", intitule : "MOUSSY VERNEUIL", cp : "02160" },
{ code : "2532", intitule : "MOY DE L AISNE", cp : "02610" },
{ code : "2533", intitule : "MURET ET CROUTTES", cp : "02210" },
{ code : "2534", intitule : "MUSCOURT", cp : "02160" },
{ code : "2535", intitule : "NAMPCELLES LA COUR", cp : "02140" },
{ code : "2536", intitule : "NAMPTEUIL SOUS MURET", cp : "02200" },
{ code : "2537", intitule : "NANTEUIL LA FOSSE", cp : "02880" },
{ code : "2538", intitule : "NANTEUIL NOTRE DAME", cp : "02210" },
{ code : "2539", intitule : "NAUROY", cp : "02420" },
{ code : "2540", intitule : "NESLES LA MONTAGNE", cp : "02400" },
{ code : "2541", intitule : "NEUFCHATEL SUR AISNE", cp : "02190" },
{ code : "2542", intitule : "NEUFLIEUX", cp : "02300" },
{ code : "2543", intitule : "NEUILLY ST FRONT", cp : "02470" },
{ code : "2544", intitule : "NEUVE MAISON", cp : "02500" },
{ code : "2545", intitule : "LA NEUVILLE BOSMONT", cp : "02250" },
{ code : "2546", intitule : "LA NEUVILLE EN BEINE", cp : "02300" },
{ code : "2547", intitule : "LA NEUVILLE HOUSSET", cp : "02250" },
{ code : "2548", intitule : "LA NEUVILLE LES DORENGT", cp : "02450" },
{ code : "2549", intitule : "NEUVILLE ST AMAND", cp : "02100" },
{ code : "2550", intitule : "NEUVILLE SUR AILETTE", cp : "02860" },
{ code : "2551", intitule : "NEUVILLE SUR MARGIVAL", cp : "02880" },
{ code : "2552", intitule : "NEUVILLETTE", cp : "02390" },
{ code : "2553", intitule : "NIZY LE COMTE", cp : "02150" },
{ code : "2554", intitule : "NOGENTEL", cp : "02400" },
{ code : "2555", intitule : "NOGENT L ARTAUD", cp : "02310" },
{ code : "2556", intitule : "NOIRCOURT", cp : "02340" },
{ code : "2557", intitule : "NOROY SUR OURCQ", cp : "02600" },
{ code : "2558", intitule : "LE NOUVION EN THIERACHE", cp : "02170" },
{ code : "2559", intitule : "NOUVION ET CATILLON", cp : "02270" },
{ code : "2559", intitule : "NOUVION ET CATILLON", cp : "02270" },
{ code : "2560", intitule : "NOUVION LE COMTE", cp : "02800" },
{ code : "2561", intitule : "NOUVION LE VINEUX", cp : "02860" },
{ code : "2562", intitule : "NOUVRON VINGRE", cp : "02290" },
{ code : "2563", intitule : "NOYALES", cp : "02120" },
{ code : "2564", intitule : "NOYANT ET ACONIN", cp : "02200" },
{ code : "2565", intitule : "OEUILLY", cp : "02160" },
{ code : "2566", intitule : "OGNES", cp : "02300" },
{ code : "2567", intitule : "OHIS", cp : "02500" },
{ code : "2568", intitule : "OIGNY EN VALOIS", cp : "02600" },
{ code : "2569", intitule : "OISY", cp : "02450" },
{ code : "2570", intitule : "OLLEZY", cp : "02480" },
{ code : "2571", intitule : "OMISSY", cp : "02100" },
{ code : "2572", intitule : "ORAINVILLE", cp : "02190" },
{ code : "2573", intitule : "ORGEVAL", cp : "02860" },
{ code : "2574", intitule : "ORIGNY EN THIERACHE", cp : "02550" },
{ code : "2575", intitule : "ORIGNY STE BENOITE", cp : "02390" },
{ code : "2576", intitule : "OSLY COURTIL", cp : "02290" },
{ code : "2577", intitule : "OSTEL", cp : "02370" },
{ code : "2578", intitule : "OULCHES LA VALLEE FOULON", cp : "02160" },
{ code : "2579", intitule : "OULCHY LA VILLE", cp : "02210" },
{ code : "2580", intitule : "OULCHY LE CHATEAU", cp : "02210" },
{ code : "2580", intitule : "OULCHY LE CHATEAU", cp : "02210" },
{ code : "2581", intitule : "PAARS", cp : "02220" },
{ code : "2582", intitule : "PAISSY", cp : "02160" },
{ code : "2583", intitule : "PANCY COURTECON", cp : "02860" },
{ code : "2584", intitule : "PAPLEUX", cp : "02260" },
{ code : "2585", intitule : "PARCY ET TIGNY", cp : "02210" },
{ code : "2586", intitule : "PARFONDEVAL", cp : "02360" },
{ code : "2587", intitule : "PARFONDRU", cp : "02840" },
{ code : "2588", intitule : "PARGNAN", cp : "02160" },
{ code : "2589", intitule : "PARGNY FILAIN", cp : "02000" },
{ code : "2590", intitule : "PARGNY LA DHUYS", cp : "02330" },
{ code : "2591", intitule : "PARGNY LES BOIS", cp : "02270" },
{ code : "2592", intitule : "PARPEVILLE", cp : "02240" },
{ code : "2593", intitule : "PASLY", cp : "02200" },
{ code : "2594", intitule : "PASSY EN VALOIS", cp : "02470" },
{ code : "2595", intitule : "PASSY SUR MARNE", cp : "02850" },
{ code : "2596", intitule : "PAVANT", cp : "02310" },
{ code : "2598", intitule : "PERNANT", cp : "02200" },
{ code : "2599", intitule : "PIERREMANDE", cp : "02300" },
{ code : "2600", intitule : "PIERREPONT", cp : "02350" },
{ code : "2601", intitule : "PIGNICOURT", cp : "02190" },
{ code : "2602", intitule : "PINON", cp : "02320" },
{ code : "2604", intitule : "PITHON", cp : "02480" },
{ code : "2605", intitule : "PLEINE SELVE", cp : "02240" },
{ code : "2606", intitule : "LE PLESSIER HULEU", cp : "02210" },
{ code : "2607", intitule : "PLOISY", cp : "02200" },
{ code : "2608", intitule : "PLOMION", cp : "02140" },
{ code : "2609", intitule : "PLOYART ET VAURSEINE", cp : "02860" },
{ code : "2610", intitule : "POMMIERS", cp : "02200" },
{ code : "2612", intitule : "PONT ARCY", cp : "02160" },
{ code : "2613", intitule : "PONTAVERT", cp : "02160" },
{ code : "2614", intitule : "PONTRU", cp : "02490" },
{ code : "2615", intitule : "PONTRUET", cp : "02490" },
{ code : "2616", intitule : "PONT ST MARD", cp : "02380" },
{ code : "2617", intitule : "POUILLY SUR SERRE", cp : "02270" },
{ code : "2618", intitule : "PREMONT", cp : "02110" },
{ code : "2619", intitule : "PREMONTRE", cp : "02320" },
{ code : "2620", intitule : "PRESLES ET BOVES", cp : "02370" },
{ code : "2621", intitule : "PRESLES ET THIERNY", cp : "02860" },
{ code : "2622", intitule : "PRIEZ", cp : "02470" },
{ code : "2623", intitule : "PRISCES", cp : "02140" },
{ code : "2624", intitule : "PROISY", cp : "02120" },
{ code : "2625", intitule : "PROIX", cp : "02120" },
{ code : "2626", intitule : "PROUVAIS", cp : "02190" },
{ code : "2627", intitule : "PROVISEUX ET PLESNOY", cp : "02190" },
{ code : "2628", intitule : "PUISEUX EN RETZ", cp : "02600" },
{ code : "2629", intitule : "PUISIEUX ET CLANLIEU", cp : "02120" },
{ code : "2631", intitule : "QUIERZY", cp : "02300" },
{ code : "2632", intitule : "QUINCY BASSE", cp : "02380" },
{ code : "2633", intitule : "QUINCY SOUS LE MONT", cp : "02220" },
{ code : "2634", intitule : "RAILLIMONT", cp : "02360" },
{ code : "2635", intitule : "RAMICOURT", cp : "02110" },
{ code : "2636", intitule : "REGNY", cp : "02240" },
{ code : "2637", intitule : "REMAUCOURT", cp : "02100" },
{ code : "2638", intitule : "REMIES", cp : "02270" },
{ code : "2639", intitule : "REMIGNY", cp : "02440" },
{ code : "2640", intitule : "RENANSART", cp : "02240" },
{ code : "2641", intitule : "RENNEVAL", cp : "02340" },
{ code : "2642", intitule : "RESIGNY", cp : "02360" },
{ code : "2643", intitule : "RESSONS LE LONG", cp : "02290" },
{ code : "2644", intitule : "RETHEUIL", cp : "02600" },
{ code : "2645", intitule : "REUILLY SAUVIGNY", cp : "02850" },
{ code : "2647", intitule : "RIBEAUVILLE", cp : "02110" },
{ code : "2648", intitule : "RIBEMONT", cp : "02240" },
{ code : "2649", intitule : "ROCOURT ST MARTIN", cp : "02210" },
{ code : "2650", intitule : "ROCQUIGNY", cp : "02260" },
{ code : "2651", intitule : "ROGECOURT", cp : "02800" },
{ code : "2652", intitule : "ROGNY", cp : "02140" },
{ code : "2653", intitule : "ROMENY SUR MARNE", cp : "02310" },
{ code : "2654", intitule : "ROMERY", cp : "02120" },
{ code : "2655", intitule : "RONCHERES", cp : "02130" },
{ code : "2656", intitule : "ROUCY", cp : "02160" },
{ code : "2657", intitule : "ROUGERIES", cp : "02140" },
{ code : "2658", intitule : "ROUPY", cp : "02590" },
{ code : "2659", intitule : "ROUVROY", cp : "02100" },
{ code : "2660", intitule : "ROUVROY SUR SERRE", cp : "02360" },
{ code : "2661", intitule : "ROYAUCOURT ET CHAILVET", cp : "02000" },
{ code : "2662", intitule : "ROZET ST ALBIN", cp : "02210" },
{ code : "2663", intitule : "ROZIERES SUR CRISE", cp : "02200" },
{ code : "2664", intitule : "ROZOY BELLEVALLE", cp : "02540" },
{ code : "2665", intitule : "GRAND ROZOY", cp : "02210" },
{ code : "2666", intitule : "ROZOY SUR SERRE", cp : "02360" },
{ code : "2667", intitule : "SACONIN ET BREUIL", cp : "02200" },
{ code : "2668", intitule : "SAINS RICHAUMONT", cp : "02120" },
{ code : "2670", intitule : "ST ALGIS", cp : "02260" },
{ code : "2671", intitule : "ST AUBIN", cp : "02300" },
{ code : "2672", intitule : "ST BANDRY", cp : "02290" },
{ code : "2673", intitule : "ST CHRISTOPHE A BERRY", cp : "02290" },
{ code : "2674", intitule : "ST CLEMENT", cp : "02360" },
{ code : "2675", intitule : "STE CROIX", cp : "02820" },
{ code : "2676", intitule : "ST ERME OUTRE ET RAMECOURT", cp : "02820" },
{ code : "2677", intitule : "ST EUGENE", cp : "02330" },
{ code : "2678", intitule : "STE GENEVIEVE", cp : "02340" },
{ code : "2679", intitule : "ST GENGOULPH", cp : "02810" },
{ code : "2680", intitule : "ST GOBAIN", cp : "02410" },
{ code : "2681", intitule : "ST GOBERT", cp : "02140" },
{ code : "2682", intitule : "ST MARD", cp : "02220" },
{ code : "2683", intitule : "ST MARTIN RIVIERE", cp : "02110" },
{ code : "2684", intitule : "ST MICHEL", cp : "02830" },
{ code : "2685", intitule : "ST NICOLAS AUX BOIS", cp : "02410" },
{ code : "2686", intitule : "ST PAUL AUX BOIS", cp : "02300" },
{ code : "2687", intitule : "ST PIERRE AIGLE", cp : "02600" },
{ code : "2688", intitule : "ST PIERRE LES FRANQUEVILLE", cp : "02140" },
{ code : "2689", intitule : "ST PIERREMONT", cp : "02250" },
{ code : "2690", intitule : "STE PREUVE", cp : "02350" },
{ code : "2691", intitule : "ST QUENTIN", cp : "02100" },
{ code : "2693", intitule : "ST REMY BLANZY", cp : "02210" },
{ code : "2694", intitule : "ST SIMON", cp : "02640" },
{ code : "2695", intitule : "ST THIBAUT", cp : "02220" },
{ code : "2696", intitule : "ST THOMAS", cp : "02820" },
{ code : "2697", intitule : "SAMOUSSY", cp : "02840" },
{ code : "2698", intitule : "SANCY LES CHEMINOTS", cp : "02880" },
{ code : "2699", intitule : "SAPONAY", cp : "02130" },
{ code : "2701", intitule : "SAULCHERY", cp : "02310" },
{ code : "2702", intitule : "SAVY", cp : "02590" },
{ code : "2703", intitule : "SEBONCOURT", cp : "02110" },
{ code : "2704", intitule : "SELENS", cp : "02300" },
{ code : "2705", intitule : "LA SELVE", cp : "02150" },
{ code : "2706", intitule : "SEPTMONTS", cp : "02200" },
{ code : "2707", intitule : "SEPTVAUX", cp : "02410" },
{ code : "2708", intitule : "SEQUEHART", cp : "02420" },
{ code : "2709", intitule : "SERAIN", cp : "02110" },
{ code : "2710", intitule : "SERAUCOURT LE GRAND", cp : "02790" },
{ code : "2711", intitule : "SERCHES", cp : "02220" },
{ code : "2712", intitule : "SERGY", cp : "02130" },
{ code : "2713", intitule : "SERINGES ET NESLES", cp : "02130" },
{ code : "2714", intitule : "SERMOISE", cp : "02220" },
{ code : "2715", intitule : "SERVAL", cp : "02160" },
{ code : "2716", intitule : "SERVAIS", cp : "02700" },
{ code : "2717", intitule : "SERY LES MEZIERES", cp : "02240" },
{ code : "2718", intitule : "SILLY LA POTERIE", cp : "02460" },
{ code : "2719", intitule : "SINCENY", cp : "02300" },
{ code : "2720", intitule : "SISSONNE", cp : "02150" },
{ code : "2721", intitule : "SISSY", cp : "02240" },
{ code : "2722", intitule : "SOISSONS", cp : "02200" },
{ code : "2723", intitule : "SOIZE", cp : "02340" },
{ code : "2724", intitule : "SOMMELANS", cp : "02470" },
{ code : "2725", intitule : "SOMMERON", cp : "02260" },
{ code : "2726", intitule : "SOMMETTE EAUCOURT", cp : "02480" },
{ code : "2727", intitule : "SONS ET RONCHERES", cp : "02270" },
{ code : "2728", intitule : "SORBAIS", cp : "02580" },
{ code : "2729", intitule : "SOUCY", cp : "02600" },
{ code : "2730", intitule : "SOUPIR", cp : "02160" },
{ code : "2731", intitule : "LE SOURD", cp : "02140" },
{ code : "2732", intitule : "SURFONTAINE", cp : "02240" },
{ code : "2733", intitule : "SUZY", cp : "02320" },
{ code : "2734", intitule : "TAILLEFONTAINE", cp : "02600" },
{ code : "2735", intitule : "TANNIERES", cp : "02220" },
{ code : "2736", intitule : "TARTIERS", cp : "02290" },
{ code : "2737", intitule : "TAVAUX ET PONTSERICOURT", cp : "02250" },
{ code : "2738", intitule : "TERGNIER", cp : "02700" },
{ code : "2738", intitule : "TERGNIER", cp : "02700" },
{ code : "2738", intitule : "TERGNIER", cp : "02700" },
{ code : "2738", intitule : "TERGNIER", cp : "02700" },
{ code : "2739", intitule : "TERNY SORNY", cp : "02880" },
{ code : "2740", intitule : "THENAILLES", cp : "02140" },
{ code : "2741", intitule : "THENELLES", cp : "02390" },
{ code : "2742", intitule : "THIERNU", cp : "02250" },
{ code : "2743", intitule : "LE THUEL", cp : "02340" },
{ code : "2744", intitule : "TORCY EN VALOIS", cp : "02810" },
{ code : "2745", intitule : "TOULIS ET ATTENCOURT", cp : "02250" },
{ code : "2746", intitule : "TRAVECY", cp : "02800" },
{ code : "2747", intitule : "TREFCON", cp : "02490" },
{ code : "2748", intitule : "TRELOU SUR MARNE", cp : "02850" },
{ code : "2749", intitule : "TROESNES", cp : "02460" },
{ code : "2750", intitule : "TROSLY LOIRE", cp : "02300" },
{ code : "2751", intitule : "TRUCY", cp : "02860" },
{ code : "2752", intitule : "TUGNY ET PONT", cp : "02640" },
{ code : "2753", intitule : "TUPIGNY", cp : "02120" },
{ code : "2754", intitule : "UGNY LE GAY", cp : "02300" },
{ code : "2755", intitule : "URCEL", cp : "02000" },
{ code : "2756", intitule : "URVILLERS", cp : "02690" },
{ code : "2757", intitule : "VADENCOURT", cp : "02120" },
{ code : "2757", intitule : "VADENCOURT", cp : "02120" },
{ code : "2758", intitule : "VAILLY SUR AISNE", cp : "02370" },
{ code : "2759", intitule : "LA VALLEE AU BLE", cp : "02140" },
{ code : "2760", intitule : "LA VALLEE MULATRE", cp : "02110" },
{ code : "2761", intitule : "VARISCOURT", cp : "02190" },
{ code : "2762", intitule : "VASSENS", cp : "02290" },
{ code : "2763", intitule : "VASSENY", cp : "02220" },
{ code : "2764", intitule : "VASSOGNE", cp : "02160" },
{ code : "2765", intitule : "VAUCELLES ET BEFFECOURT", cp : "02000" },
{ code : "2766", intitule : "VAUDESSON", cp : "02320" },
{ code : "2767", intitule : "VAUXREZIS", cp : "02200" },
{ code : "2768", intitule : "VAUXAILLON", cp : "02320" },
{ code : "2769", intitule : "VAUX ANDIGNY", cp : "02110" },
{ code : "2770", intitule : "VAUXBUIN", cp : "02200" },
{ code : "2772", intitule : "VAUX EN VERMANDOIS", cp : "02590" },
{ code : "2773", intitule : "VAUXTIN", cp : "02220" },
{ code : "2774", intitule : "VENDELLES", cp : "02490" },
{ code : "2775", intitule : "VENDEUIL", cp : "02800" },
{ code : "2776", intitule : "VENDHUILE", cp : "02420" },
{ code : "2777", intitule : "VENDIERES", cp : "02540" },
{ code : "2778", intitule : "VENDRESSE BEAULNE", cp : "02160" },
{ code : "2779", intitule : "VENEROLLES", cp : "02510" },
{ code : "2780", intitule : "VENIZEL", cp : "02200" },
{ code : "2781", intitule : "VERDILLY", cp : "02400" },
{ code : "2782", intitule : "LE VERGUIER", cp : "02490" },
{ code : "2783", intitule : "GRAND VERLY", cp : "02120" },
{ code : "2784", intitule : "PETIT VERLY", cp : "02630" },
{ code : "2785", intitule : "VERMAND", cp : "02490" },
{ code : "2786", intitule : "VERNEUIL SOUS COUCY", cp : "02380" },
{ code : "2787", intitule : "VERNEUIL SUR SERRE", cp : "02000" },
{ code : "2788", intitule : "VERSIGNY", cp : "02800" },
{ code : "2789", intitule : "VERVINS", cp : "02140" },
{ code : "2790", intitule : "VESLES ET CAUMONT", cp : "02350" },
{ code : "2791", intitule : "VESLUD", cp : "02840" },
{ code : "2792", intitule : "VEUILLY LA POTERIE", cp : "02810" },
{ code : "2793", intitule : "VEZAPONIN", cp : "02290" },
{ code : "2794", intitule : "VEZILLY", cp : "02130" },
{ code : "2795", intitule : "VIC SUR AISNE", cp : "02290" },
{ code : "2796", intitule : "VICHEL NANTEUIL", cp : "02210" },
{ code : "2797", intitule : "VIEL ARCY", cp : "02160" },
{ code : "2798", intitule : "VIELS MAISONS", cp : "02540" },
{ code : "2799", intitule : "VIERZY", cp : "02210" },
{ code : "2800", intitule : "VIFFORT", cp : "02540" },
{ code : "2801", intitule : "VIGNEUX HOCQUET", cp : "02340" },
{ code : "2802", intitule : "LA VILLE AUX BOIS LES DIZY", cp : "02340" },
{ code : "2803", intitule : "LA VILLE AUX BOIS LES PONTAVERT", cp : "02160" },
{ code : "2804", intitule : "VILLEMONTOIRE", cp : "02210" },
{ code : "2805", intitule : "VILLENEUVE ST GERMAIN", cp : "02200" },
{ code : "2806", intitule : "VILLENEUVE SUR FERE", cp : "02130" },
{ code : "2807", intitule : "VILLEQUIER AUMONT", cp : "02300" },
{ code : "2808", intitule : "VILLERET", cp : "02420" },
{ code : "2809", intitule : "VILLERS AGRON AIGUIZY", cp : "02130" },
{ code : "2810", intitule : "VILLERS COTTERETS", cp : "02600" },
{ code : "2810", intitule : "VILLERS COTTERETS", cp : "02600" },
{ code : "2812", intitule : "VILLERS HELON", cp : "02600" },
{ code : "2813", intitule : "VILLERS LE SEC", cp : "02240" },
{ code : "2814", intitule : "VILLERS LES GUISE", cp : "02120" },
{ code : "2815", intitule : "VILLERS ST CHRISTOPHE", cp : "02590" },
{ code : "2816", intitule : "VILLERS SUR FERE", cp : "02130" },
{ code : "2817", intitule : "VILLE SAVOYE", cp : "02220" },
{ code : "2818", intitule : "VILLIERS ST DENIS", cp : "02310" },
{ code : "2819", intitule : "VINCY REUIL ET MAGNY", cp : "02340" },
{ code : "2820", intitule : "VIRY NOUREUIL", cp : "02300" },
{ code : "2821", intitule : "VIVAISE", cp : "02870" },
{ code : "2822", intitule : "VIVIERES", cp : "02600" },
{ code : "2823", intitule : "VOHARIES", cp : "02140" },
{ code : "2824", intitule : "VORGES", cp : "02860" },
{ code : "2826", intitule : "VOULPAIX", cp : "02140" },
{ code : "2827", intitule : "VOYENNE", cp : "02250" },
{ code : "2828", intitule : "VREGNY", cp : "02880" },
{ code : "2829", intitule : "VUILLERY", cp : "02880" },
{ code : "2830", intitule : "WASSIGNY", cp : "02630" },
{ code : "2831", intitule : "WATIGNY", cp : "02830" },
{ code : "2832", intitule : "WIEGE FATY", cp : "02120" },
{ code : "2833", intitule : "WIMY", cp : "02500" },
{ code : "2834", intitule : "WISSIGNICOURT", cp : "02320" },
{ code : "3001", intitule : "ABREST", cp : "03200" },
{ code : "3002", intitule : "AGONGES", cp : "03210" },
{ code : "3003", intitule : "AINAY LE CHATEAU", cp : "03360" },
{ code : "3004", intitule : "ANDELAROCHE", cp : "03120" },
{ code : "3005", intitule : "ARCHIGNAT", cp : "03380" },
{ code : "3006", intitule : "ARFEUILLES", cp : "03120" },
{ code : "3007", intitule : "ARPHEUILLES ST PRIEST", cp : "03420" },
{ code : "3008", intitule : "ARRONNES", cp : "03250" },
{ code : "3009", intitule : "AUBIGNY", cp : "03460" },
{ code : "3010", intitule : "AUDES", cp : "03190" },
{ code : "3011", intitule : "AUROUER", cp : "03460" },
{ code : "3012", intitule : "AUTRY ISSARDS", cp : "03210" },
{ code : "3013", intitule : "AVERMES", cp : "03000" },
{ code : "3014", intitule : "AVRILLY", cp : "03130" },
{ code : "3015", intitule : "BAGNEUX", cp : "03460" },
{ code : "3016", intitule : "BARBERIER", cp : "03140" },
{ code : "3017", intitule : "BARRAIS BUSSOLLES", cp : "03120" },
{ code : "3018", intitule : "BAYET", cp : "03500" },
{ code : "3019", intitule : "BEAULON", cp : "03230" },
{ code : "3020", intitule : "BEAUNE D ALLIER", cp : "03390" },
{ code : "3021", intitule : "BEGUES", cp : "03800" },
{ code : "3022", intitule : "BELLENAVES", cp : "03330" },
{ code : "3023", intitule : "BELLERIVE SUR ALLIER", cp : "03700" },
{ code : "3024", intitule : "BERT", cp : "03130" },
{ code : "3025", intitule : "BESSAY SUR ALLIER", cp : "03340" },
{ code : "3026", intitule : "BESSON", cp : "03210" },
{ code : "3027", intitule : "BEZENET", cp : "03170" },
{ code : "3028", intitule : "BILLEZOIS", cp : "03120" },
{ code : "3029", intitule : "BILLY", cp : "03260" },
{ code : "3030", intitule : "BIOZAT", cp : "03800" },
{ code : "3031", intitule : "BIZENEUILLE", cp : "03170" },
{ code : "3032", intitule : "BLOMARD", cp : "03390" },
{ code : "3033", intitule : "BOST", cp : "03300" },
{ code : "3034", intitule : "BOUCE", cp : "03150" },
{ code : "3035", intitule : "LE BOUCHAUD", cp : "03130" },
{ code : "3036", intitule : "BOURBON L ARCHAMBAULT", cp : "03160" },
{ code : "3037", intitule : "BRAIZE", cp : "03360" },
{ code : "3038", intitule : "BRANSAT", cp : "03500" },
{ code : "3039", intitule : "BRESNAY", cp : "03210" },
{ code : "3040", intitule : "BRESSOLLES", cp : "03000" },
{ code : "3041", intitule : "LE BRETHON", cp : "03350" },
{ code : "3042", intitule : "LE BREUIL", cp : "03120" },
{ code : "3043", intitule : "BROUT VERNET", cp : "03110" },
{ code : "3044", intitule : "BRUGHEAS", cp : "03700" },
{ code : "3045", intitule : "BUSSET", cp : "03270" },
{ code : "3046", intitule : "BUXIERES LES MINES", cp : "03440" },
{ code : "3047", intitule : "LA CELLE", cp : "03600" },
{ code : "3048", intitule : "CERILLY", cp : "03350" },
{ code : "3049", intitule : "CESSET", cp : "03500" },
{ code : "3050", intitule : "LA CHABANNE", cp : "03250" },
{ code : "3051", intitule : "CHAMBERAT", cp : "03370" },
{ code : "3052", intitule : "CHAMBLET", cp : "03170" },
{ code : "3053", intitule : "CHANTELLE", cp : "03140" },
{ code : "3054", intitule : "CHAPEAU", cp : "03340" },
{ code : "3055", intitule : "LA CHAPELAUDE", cp : "03380" },
{ code : "3056", intitule : "LA CHAPELLE", cp : "03300" },
{ code : "3057", intitule : "LA CHAPELLE AUX CHASSES", cp : "03230" },
{ code : "3058", intitule : "CHAPPES", cp : "03390" },
{ code : "3059", intitule : "CHAREIL CINTRAT", cp : "03140" },
{ code : "3060", intitule : "CHARMEIL", cp : "03110" },
{ code : "3061", intitule : "CHARMES", cp : "03800" },
{ code : "3062", intitule : "CHARROUX", cp : "03140" },
{ code : "3063", intitule : "CHASSENARD", cp : "03510" },
{ code : "3064", intitule : "CHATEAU SUR ALLIER", cp : "03320" },
{ code : "3065", intitule : "CHATEL DE NEUVRE", cp : "03500" },
{ code : "3066", intitule : "CHATEL MONTAGNE", cp : "03250" },
{ code : "3067", intitule : "CHATELPERRON", cp : "03220" },
{ code : "3068", intitule : "CHATELUS", cp : "03120" },
{ code : "3069", intitule : "CHATILLON", cp : "03210" },
{ code : "3070", intitule : "CHAVENON", cp : "03440" },
{ code : "3071", intitule : "CHAVROCHES", cp : "03220" },
{ code : "3072", intitule : "CHAZEMAIS", cp : "03370" },
{ code : "3073", intitule : "CHEMILLY", cp : "03210" },
{ code : "3074", intitule : "CHEVAGNES", cp : "03230" },
{ code : "3075", intitule : "CHEZELLE", cp : "03140" },
{ code : "3076", intitule : "CHEZY", cp : "03230" },
{ code : "3077", intitule : "CHIRAT L EGLISE", cp : "03330" },
{ code : "3078", intitule : "CHOUVIGNY", cp : "03450" },
{ code : "3079", intitule : "CINDRE", cp : "03220" },
{ code : "3080", intitule : "COGNAT LYONNE", cp : "03110" },
{ code : "3081", intitule : "COLOMBIER", cp : "03600" },
{ code : "3082", intitule : "COMMENTRY", cp : "03600" },
{ code : "3083", intitule : "CONTIGNY", cp : "03500" },
{ code : "3084", intitule : "COSNE D ALLIER", cp : "03430" },
{ code : "3085", intitule : "COULANDON", cp : "03000" },
{ code : "3086", intitule : "COULANGES", cp : "03470" },
{ code : "3087", intitule : "COULEUVRE", cp : "03320" },
{ code : "3088", intitule : "COURCAIS", cp : "03370" },
{ code : "3089", intitule : "COUTANSOUZE", cp : "03330" },
{ code : "3090", intitule : "COUZON", cp : "03160" },
{ code : "3091", intitule : "CRECHY", cp : "03150" },
{ code : "3092", intitule : "CRESSANGES", cp : "03240" },
{ code : "3093", intitule : "CREUZIER LE NEUF", cp : "03300" },
{ code : "3094", intitule : "CREUZIER LE VIEUX", cp : "03300" },
{ code : "3095", intitule : "CUSSET", cp : "03300" },
{ code : "3096", intitule : "DENEUILLE LES CHANTELLE", cp : "03140" },
{ code : "3097", intitule : "DENEUILLE LES MINES", cp : "03170" },
{ code : "3098", intitule : "DESERTINES", cp : "03630" },
{ code : "3099", intitule : "DEUX CHAISES", cp : "03240" },
{ code : "3100", intitule : "DIOU", cp : "03290" },
{ code : "3101", intitule : "DOMERAT", cp : "03410" },
{ code : "3102", intitule : "DOMPIERRE SUR BESBRE", cp : "03290" },
{ code : "3103", intitule : "LE DONJON", cp : "03130" },
{ code : "3104", intitule : "DOYET", cp : "03170" },
{ code : "3105", intitule : "DROITURIER", cp : "03120" },
{ code : "3106", intitule : "DURDAT LAREQUILLE", cp : "03310" },
{ code : "3107", intitule : "EBREUIL", cp : "03450" },
{ code : "3108", intitule : "ECHASSIERES", cp : "03330" },
{ code : "3109", intitule : "ESCUROLLES", cp : "03110" },
{ code : "3110", intitule : "ESPINASSE VOZELLE", cp : "03110" },
{ code : "3111", intitule : "ESTIVAREILLES", cp : "03190" },
{ code : "3112", intitule : "ETROUSSAT", cp : "03140" },
{ code : "3113", intitule : "FERRIERES SUR SICHON", cp : "03250" },
{ code : "3114", intitule : "LA FERTE HAUTERIVE", cp : "03340" },
{ code : "3115", intitule : "FLEURIEL", cp : "03140" },
{ code : "3116", intitule : "FOURILLES", cp : "03140" },
{ code : "3117", intitule : "FRANCHESSE", cp : "03160" },
{ code : "3118", intitule : "GANNAT", cp : "03800" },
{ code : "3119", intitule : "GANNAY SUR LOIRE", cp : "03230" },
{ code : "3120", intitule : "GARNAT SUR ENGIEVRE", cp : "03230" },
{ code : "3121", intitule : "GENNETINES", cp : "03400" },
{ code : "3122", intitule : "GIPCY", cp : "03210" },
{ code : "3124", intitule : "GOUISE", cp : "03340" },
{ code : "3125", intitule : "LA GUILLERMIE", cp : "03250" },
{ code : "3126", intitule : "HAUTERIVE", cp : "03270" },
{ code : "3127", intitule : "HERISSON", cp : "03190" },
{ code : "3128", intitule : "HURIEL", cp : "03380" },
{ code : "3129", intitule : "HYDS", cp : "03600" },
{ code : "3130", intitule : "ISLE ET BARDAIS", cp : "03360" },
{ code : "3131", intitule : "ISSERPENT", cp : "03120" },
{ code : "3132", intitule : "JALIGNY SUR BESBRE", cp : "03220" },
{ code : "3133", intitule : "JENZAT", cp : "03800" },
{ code : "3134", intitule : "LAFELINE", cp : "03500" },
{ code : "3135", intitule : "LALIZOLLE", cp : "03450" },
{ code : "3136", intitule : "LAMAIDS", cp : "03380" },
{ code : "3137", intitule : "LANGY", cp : "03150" },
{ code : "3138", intitule : "LAPALISSE", cp : "03120" },
{ code : "3139", intitule : "LAPRUGNE", cp : "03250" },
{ code : "3140", intitule : "LAVAULT STE ANNE", cp : "03100" },
{ code : "3141", intitule : "LAVOINE", cp : "03250" },
{ code : "3142", intitule : "LENAX", cp : "03130" },
{ code : "3143", intitule : "LETELON", cp : "03360" },
{ code : "3144", intitule : "LIERNOLLES", cp : "03130" },
{ code : "3145", intitule : "LIGNEROLLES", cp : "03410" },
{ code : "3146", intitule : "LIMOISE", cp : "03320" },
{ code : "3147", intitule : "LODDES", cp : "03130" },
{ code : "3148", intitule : "LORIGES", cp : "03500" },
{ code : "3149", intitule : "LOUCHY MONTFAND", cp : "03500" },
{ code : "3150", intitule : "LOUROUX BOURBONNAIS", cp : "03350" },
{ code : "3151", intitule : "LOUROUX DE BEAUNE", cp : "03600" },
{ code : "3152", intitule : "LOUROUX DE BOUBLE", cp : "03330" },
{ code : "3154", intitule : "LUNEAU", cp : "03130" },
{ code : "3155", intitule : "LURCY LEVIS", cp : "03320" },
{ code : "3156", intitule : "LUSIGNY", cp : "03230" },
{ code : "3157", intitule : "MAGNET", cp : "03260" },
{ code : "3158", intitule : "HAUT BOCAGE", cp : "03190" },
{ code : "3158", intitule : "HAUT BOCAGE", cp : "03190" },
{ code : "3158", intitule : "HAUT BOCAGE", cp : "03190" },
{ code : "3159", intitule : "MALICORNE", cp : "03600" },
{ code : "3160", intitule : "MARCENAT", cp : "03260" },
{ code : "3161", intitule : "MARCILLAT EN COMBRAILLE", cp : "03420" },
{ code : "3162", intitule : "MARIGNY", cp : "03210" },
{ code : "3163", intitule : "MARIOL", cp : "03270" },
{ code : "3164", intitule : "LE MAYET D ECOLE", cp : "03800" },
{ code : "3165", intitule : "LE MAYET DE MONTAGNE", cp : "03250" },
{ code : "3166", intitule : "MAZERIER", cp : "03800" },
{ code : "3167", intitule : "MAZIRAT", cp : "03420" },
{ code : "3168", intitule : "MEAULNE", cp : "03360" },
{ code : "3169", intitule : "MEILLARD", cp : "03500" },
{ code : "3170", intitule : "MEILLERS", cp : "03210" },
{ code : "3171", intitule : "MERCY", cp : "03340" },
{ code : "3172", intitule : "MESPLES", cp : "03370" },
{ code : "3173", intitule : "MOLINET", cp : "03510" },
{ code : "3174", intitule : "MOLLES", cp : "03300" },
{ code : "3175", intitule : "MONESTIER", cp : "03140" },
{ code : "3176", intitule : "MONETAY SUR ALLIER", cp : "03500" },
{ code : "3177", intitule : "MONETAY SUR LOIRE", cp : "03470" },
{ code : "3178", intitule : "MONTAIGUET EN FOREZ", cp : "03130" },
{ code : "3179", intitule : "MONTAIGU LE BLIN", cp : "03150" },
{ code : "3180", intitule : "MONTBEUGNY", cp : "03340" },
{ code : "3181", intitule : "MONTCOMBROUX LES MINES", cp : "03130" },
{ code : "3182", intitule : "MONTEIGNET SUR L ANDELOT", cp : "03800" },
{ code : "3183", intitule : "LE MONTET", cp : "03240" },
{ code : "3184", intitule : "MONTILLY", cp : "03000" },
{ code : "3185", intitule : "MONTLUCON", cp : "03100" },
{ code : "3186", intitule : "MONTMARAULT", cp : "03390" },
{ code : "3187", intitule : "MONTOLDRE", cp : "03150" },
{ code : "3188", intitule : "MONTORD", cp : "03500" },
{ code : "3189", intitule : "MONTVICQ", cp : "03170" },
{ code : "3190", intitule : "MOULINS", cp : "03000" },
{ code : "3191", intitule : "MURAT", cp : "03390" },
{ code : "3192", intitule : "NADES", cp : "03450" },
{ code : "3193", intitule : "NASSIGNY", cp : "03190" },
{ code : "3194", intitule : "NAVES", cp : "03330" },
{ code : "3195", intitule : "NERIS LES BAINS", cp : "03310" },
{ code : "3196", intitule : "NEUILLY EN DONJON", cp : "03130" },
{ code : "3197", intitule : "NEUILLY LE REAL", cp : "03340" },
{ code : "3198", intitule : "NEURE", cp : "03320" },
{ code : "3200", intitule : "NEUVY", cp : "03000" },
{ code : "3201", intitule : "NIZEROLLES", cp : "03250" },
{ code : "3202", intitule : "NOYANT D ALLIER", cp : "03210" },
{ code : "3203", intitule : "PARAY LE FRESIL", cp : "03230" },
{ code : "3204", intitule : "PARAY SOUS BRIAILLES", cp : "03500" },
{ code : "3205", intitule : "PERIGNY", cp : "03120" },
{ code : "3206", intitule : "LA PETITE MARCHE", cp : "03420" },
{ code : "3207", intitule : "PIERREFITTE SUR LOIRE", cp : "03470" },
{ code : "3208", intitule : "LE PIN", cp : "03130" },
{ code : "3209", intitule : "POEZAT", cp : "03800" },
{ code : "3210", intitule : "POUZY MESANGY", cp : "03320" },
{ code : "3211", intitule : "PREMILHAT", cp : "03410" },
{ code : "3212", intitule : "QUINSSAINES", cp : "03380" },
{ code : "3213", intitule : "REUGNY", cp : "03190" },
{ code : "3214", intitule : "ROCLES", cp : "03240" },
{ code : "3215", intitule : "RONGERES", cp : "03150" },
{ code : "3216", intitule : "RONNET", cp : "03420" },
{ code : "3217", intitule : "ST ANGEL", cp : "03170" },
{ code : "3218", intitule : "ST AUBIN LE MONIAL", cp : "03160" },
{ code : "3219", intitule : "ST BONNET DE FOUR", cp : "03390" },
{ code : "3220", intitule : "ST BONNET DE ROCHEFORT", cp : "03800" },
{ code : "3221", intitule : "ST BONNET TRONCAIS", cp : "03360" },
{ code : "3222", intitule : "ST CAPRAIS", cp : "03190" },
{ code : "3223", intitule : "ST CHRISTOPHE", cp : "03120" },
{ code : "3224", intitule : "ST CLEMENT", cp : "03250" },
{ code : "3225", intitule : "ST DESIRE", cp : "03370" },
{ code : "3226", intitule : "ST DIDIER EN DONJON", cp : "03130" },
{ code : "3227", intitule : "ST DIDIER LA FORET", cp : "03110" },
{ code : "3228", intitule : "ST ELOY D ALLIER", cp : "03370" },
{ code : "3229", intitule : "ST ENNEMOND", cp : "03400" },
{ code : "3230", intitule : "ST ETIENNE DE VICQ", cp : "03300" },
{ code : "3231", intitule : "ST FARGEOL", cp : "03420" },
{ code : "3232", intitule : "ST FELIX", cp : "03260" },
{ code : "3233", intitule : "ST GENEST", cp : "03310" },
{ code : "3234", intitule : "ST GERAND DE VAUX", cp : "03340" },
{ code : "3235", intitule : "ST GERAND LE PUY", cp : "03150" },
{ code : "3236", intitule : "ST GERMAIN DES FOSSES", cp : "03260" },
{ code : "3237", intitule : "ST GERMAIN DE SALLES", cp : "03140" },
{ code : "3238", intitule : "ST HILAIRE", cp : "03440" },
{ code : "3239", intitule : "ST LEGER SUR VOUZANCE", cp : "03130" },
{ code : "3240", intitule : "ST LEON", cp : "03220" },
{ code : "3241", intitule : "ST LEOPARDIN D AUGY", cp : "03160" },
{ code : "3242", intitule : "ST LOUP", cp : "03150" },
{ code : "3243", intitule : "ST MARCEL EN MURAT", cp : "03390" },
{ code : "3244", intitule : "ST MARCEL EN MARCILLAT", cp : "03420" },
{ code : "3245", intitule : "ST MARTIN DES LAIS", cp : "03230" },
{ code : "3246", intitule : "ST MARTINIEN", cp : "03380" },
{ code : "3247", intitule : "ST MENOUX", cp : "03210" },
{ code : "3248", intitule : "ST NICOLAS DES BIEFS", cp : "03250" },
{ code : "3249", intitule : "ST PALAIS", cp : "03370" },
{ code : "3250", intitule : "ST PIERRE LAVAL", cp : "42620" },
{ code : "3251", intitule : "ST PLAISIR", cp : "03160" },
{ code : "3252", intitule : "ST PONT", cp : "03110" },
{ code : "3253", intitule : "ST POURCAIN SUR BESBRE", cp : "03290" },
{ code : "3254", intitule : "ST POURCAIN SUR SIOULE", cp : "03500" },
{ code : "3255", intitule : "ST PRIEST D ANDELOT", cp : "03800" },
{ code : "3256", intitule : "ST PRIEST EN MURAT", cp : "03390" },
{ code : "3257", intitule : "ST PRIX", cp : "03120" },
{ code : "3258", intitule : "ST REMY EN ROLLAT", cp : "03110" },
{ code : "3259", intitule : "ST SAUVIER", cp : "03370" },
{ code : "3260", intitule : "ST SORNIN", cp : "03240" },
{ code : "3261", intitule : "STE THERENCE", cp : "03420" },
{ code : "3262", intitule : "ST VICTOR", cp : "03410" },
{ code : "3263", intitule : "ST VOIR", cp : "03220" },
{ code : "3264", intitule : "ST YORRE", cp : "03270" },
{ code : "3265", intitule : "SALIGNY SUR ROUDON", cp : "03470" },
{ code : "3266", intitule : "SANSSAT", cp : "03150" },
{ code : "3267", intitule : "SAULCET", cp : "03500" },
{ code : "3268", intitule : "SAULZET", cp : "03800" },
{ code : "3269", intitule : "SAUVAGNY", cp : "03430" },
{ code : "3270", intitule : "SAZERET", cp : "03390" },
{ code : "3271", intitule : "SERBANNES", cp : "03700" },
{ code : "3272", intitule : "SERVILLY", cp : "03120" },
{ code : "3273", intitule : "SEUILLET", cp : "03260" },
{ code : "3274", intitule : "SORBIER", cp : "03220" },
{ code : "3275", intitule : "SOUVIGNY", cp : "03210" },
{ code : "3276", intitule : "SUSSAT", cp : "03450" },
{ code : "3277", intitule : "TARGET", cp : "03140" },
{ code : "3278", intitule : "TAXAT SENAT", cp : "03140" },
{ code : "3279", intitule : "TEILLET ARGENTY", cp : "03410" },
{ code : "3280", intitule : "TERJAT", cp : "03420" },
{ code : "3281", intitule : "LE THEIL", cp : "03240" },
{ code : "3282", intitule : "THENEUILLE", cp : "03350" },
{ code : "3283", intitule : "THIEL SUR ACOLIN", cp : "03230" },
{ code : "3284", intitule : "THIONNE", cp : "03220" },
{ code : "3285", intitule : "TORTEZAIS", cp : "03430" },
{ code : "3286", intitule : "TOULON SUR ALLIER", cp : "03400" },
{ code : "3287", intitule : "TREBAN", cp : "03240" },
{ code : "3288", intitule : "TREIGNAT", cp : "03380" },
{ code : "3289", intitule : "TRETEAU", cp : "03220" },
{ code : "3290", intitule : "TREVOL", cp : "03460" },
{ code : "3291", intitule : "TREZELLES", cp : "03220" },
{ code : "3292", intitule : "TRONGET", cp : "03240" },
{ code : "3293", intitule : "URCAY", cp : "03360" },
{ code : "3294", intitule : "USSEL D ALLIER", cp : "03140" },
{ code : "3295", intitule : "VALIGNAT", cp : "03330" },
{ code : "3296", intitule : "VALIGNY", cp : "03360" },
{ code : "3297", intitule : "VALLON EN SULLY", cp : "03190" },
{ code : "3298", intitule : "VARENNES SUR ALLIER", cp : "03150" },
{ code : "3299", intitule : "VARENNES SUR TECHE", cp : "03220" },
{ code : "3300", intitule : "VAUMAS", cp : "03220" },
{ code : "3301", intitule : "VAUX", cp : "03190" },
{ code : "3302", intitule : "VEAUCE", cp : "03450" },
{ code : "3303", intitule : "VENAS", cp : "03190" },
{ code : "3304", intitule : "VENDAT", cp : "03110" },
{ code : "3305", intitule : "VERNEIX", cp : "03190" },
{ code : "3306", intitule : "LE VERNET", cp : "03200" },
{ code : "3307", intitule : "VERNEUIL EN BOURBONNAIS", cp : "03500" },
{ code : "3308", intitule : "VERNUSSE", cp : "03390" },
{ code : "3309", intitule : "LE VEURDRE", cp : "03320" },
{ code : "3310", intitule : "VICHY", cp : "03200" },
{ code : "3311", intitule : "VICQ", cp : "03450" },
{ code : "3312", intitule : "VIEURE", cp : "03430" },
{ code : "3313", intitule : "LE VILHAIN", cp : "03350" },
{ code : "3314", intitule : "VILLEBRET", cp : "03310" },
{ code : "3315", intitule : "VILLEFRANCHE D ALLIER", cp : "03430" },
{ code : "3315", intitule : "VILLEFRANCHE D ALLIER", cp : "03430" },
{ code : "3316", intitule : "VILLENEUVE SUR ALLIER", cp : "03460" },
{ code : "3317", intitule : "VIPLAIX", cp : "03370" },
{ code : "3318", intitule : "VITRAY", cp : "03360" },
{ code : "3319", intitule : "VOUSSAC", cp : "03140" },
{ code : "3320", intitule : "YGRANDE", cp : "03160" },
{ code : "3321", intitule : "YZEURE", cp : "03400" },
{ code : "4001", intitule : "AIGLUN", cp : "04510" },
{ code : "4004", intitule : "ALLEMAGNE EN PROVENCE", cp : "04500" },
{ code : "4005", intitule : "ALLONS", cp : "04170" },
{ code : "4006", intitule : "ALLOS", cp : "04260" },
{ code : "4006", intitule : "ALLOS", cp : "04260" },
{ code : "4007", intitule : "ANGLES", cp : "04170" },
{ code : "4008", intitule : "ANNOT", cp : "04240" },
{ code : "4009", intitule : "ARCHAIL", cp : "04420" },
{ code : "4012", intitule : "AUBENAS LES ALPES", cp : "04110" },
{ code : "4013", intitule : "AUBIGNOSC", cp : "04200" },
{ code : "4016", intitule : "AUTHON", cp : "04200" },
{ code : "4017", intitule : "AUZET", cp : "04140" },
{ code : "4018", intitule : "BANON", cp : "04150" },
{ code : "4019", intitule : "BARCELONNETTE", cp : "04400" },
{ code : "4020", intitule : "BARLES", cp : "04140" },
{ code : "4021", intitule : "BARRAS", cp : "04380" },
{ code : "4022", intitule : "BARREME", cp : "04330" },
{ code : "4023", intitule : "BAYONS", cp : "04250" },
{ code : "4023", intitule : "BAYONS", cp : "04250" },
{ code : "4023", intitule : "BAYONS", cp : "04250" },
{ code : "4023", intitule : "BAYONS", cp : "04250" },
{ code : "4024", intitule : "BEAUJEU", cp : "04420" },
{ code : "4025", intitule : "BEAUVEZER", cp : "04370" },
{ code : "4026", intitule : "BELLAFFAIRE", cp : "04250" },
{ code : "4027", intitule : "BEVONS", cp : "04200" },
{ code : "4028", intitule : "BEYNES", cp : "04270" },
{ code : "4030", intitule : "BLIEUX", cp : "04330" },
{ code : "4031", intitule : "BRAS D ASSE", cp : "04270" },
{ code : "4032", intitule : "BRAUX", cp : "04240" },
{ code : "4033", intitule : "LA BREOLE", cp : "04340" },
{ code : "4034", intitule : "LA BRILLANNE", cp : "04700" },
{ code : "4035", intitule : "BRUNET", cp : "04210" },
{ code : "4036", intitule : "LE BRUSQUET", cp : "04420" },
{ code : "4037", intitule : "LE CAIRE", cp : "04250" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4039", intitule : "CASTELLANE", cp : "04120" },
{ code : "4040", intitule : "LE CASTELLARD MELAN", cp : "04380" },
{ code : "4040", intitule : "LE CASTELLARD MELAN", cp : "04380" },
{ code : "4041", intitule : "LE CASTELLET", cp : "04700" },
{ code : "4042", intitule : "CASTELLET LES SAUSSES", cp : "04320" },
{ code : "4043", intitule : "VAL DE CHALVAGNE", cp : "04320" },
{ code : "4043", intitule : "VAL DE CHALVAGNE", cp : "04320" },
{ code : "4043", intitule : "VAL DE CHALVAGNE", cp : "04320" },
{ code : "4045", intitule : "CERESTE", cp : "04280" },
{ code : "4046", intitule : "LE CHAFFAUT ST JURSON", cp : "04510" },
{ code : "4046", intitule : "LE CHAFFAUT ST JURSON", cp : "04510" },
{ code : "4047", intitule : "CHAMPTERCIER", cp : "04660" },
{ code : "4049", intitule : "CHATEAU ARNOUX ST AUBAN", cp : "04160" },
{ code : "4049", intitule : "CHATEAU ARNOUX ST AUBAN", cp : "04600" },
{ code : "4050", intitule : "CHATEAUFORT", cp : "04250" },
{ code : "4051", intitule : "CHATEAUNEUF MIRAVAIL", cp : "04200" },
{ code : "4053", intitule : "CHATEAUNEUF VAL ST DONAT", cp : "04200" },
{ code : "4054", intitule : "CHATEAUREDON", cp : "04270" },
{ code : "4055", intitule : "CHAUDON NORANTE", cp : "04330" },
{ code : "4057", intitule : "CLAMENSANE", cp : "04250" },
{ code : "4058", intitule : "CLARET", cp : "05110" },
{ code : "4059", intitule : "CLUMANC", cp : "04330" },
{ code : "4061", intitule : "COLMARS", cp : "04370" },
{ code : "4062", intitule : "LA CONDAMINE CHATELARD", cp : "04530" },
{ code : "4062", intitule : "LA CONDAMINE CHATELARD", cp : "04530" },
{ code : "4063", intitule : "CORBIERES", cp : "04220" },
{ code : "4065", intitule : "CRUIS", cp : "04230" },
{ code : "4066", intitule : "CURBANS", cp : "05110" },
{ code : "4067", intitule : "CUREL", cp : "04200" },
{ code : "4068", intitule : "DAUPHIN", cp : "04300" },
{ code : "4069", intitule : "DEMANDOLX", cp : "04120" },
{ code : "4070", intitule : "DIGNE LES BAINS", cp : "04000" },
{ code : "4070", intitule : "DIGNE LES BAINS", cp : "04000" },
{ code : "4072", intitule : "DRAIX", cp : "04420" },
{ code : "4073", intitule : "ENCHASTRAYES", cp : "04400" },
{ code : "4073", intitule : "ENCHASTRAYES", cp : "04400" },
{ code : "4074", intitule : "ENTRAGES", cp : "04270" },
{ code : "4074", intitule : "ENTRAGES", cp : "04000" },
{ code : "4075", intitule : "ENTREPIERRES", cp : "04200" },
{ code : "4075", intitule : "ENTREPIERRES", cp : "04200" },
{ code : "4075", intitule : "ENTREPIERRES", cp : "04200" },
{ code : "4076", intitule : "ENTREVAUX", cp : "04320" },
{ code : "4077", intitule : "ENTREVENNES", cp : "04700" },
{ code : "4079", intitule : "L ESCALE", cp : "04160" },
{ code : "4081", intitule : "ESPARRON DE VERDON", cp : "04800" },
{ code : "4081", intitule : "ESPARRON DE VERDON", cp : "04800" },
{ code : "4084", intitule : "ESTOUBLON", cp : "04270" },
{ code : "4084", intitule : "ESTOUBLON", cp : "04270" },
{ code : "4085", intitule : "FAUCON DU CAIRE", cp : "04250" },
{ code : "4086", intitule : "FAUCON DE BARCELONNETTE", cp : "04400" },
{ code : "4087", intitule : "FONTIENNE", cp : "04230" },
{ code : "4088", intitule : "FORCALQUIER", cp : "04300" },
{ code : "4090", intitule : "LE FUGERET", cp : "04240" },
{ code : "4091", intitule : "GANAGOBIE", cp : "04310" },
{ code : "4092", intitule : "LA GARDE", cp : "04120" },
{ code : "4093", intitule : "GIGORS", cp : "04250" },
{ code : "4094", intitule : "GREOUX LES BAINS", cp : "04800" },
{ code : "4095", intitule : "L HOSPITALET", cp : "04150" },
{ code : "4096", intitule : "JAUSIERS", cp : "04850" },
{ code : "4097", intitule : "LA JAVIE", cp : "04000" },
{ code : "4097", intitule : "LA JAVIE", cp : "04420" },
{ code : "4099", intitule : "LAMBRUISSE", cp : "04170" },
{ code : "4101", intitule : "LARDIERS", cp : "04230" },
{ code : "4102", intitule : "LE LAUZET UBAYE", cp : "04340" },
{ code : "4104", intitule : "LIMANS", cp : "04300" },
{ code : "4106", intitule : "LURS", cp : "04700" },
{ code : "4107", intitule : "MAJASTRES", cp : "04270" },
{ code : "4108", intitule : "MALIJAI", cp : "04350" },
{ code : "4108", intitule : "MALIJAI", cp : "04510" },
{ code : "4109", intitule : "MALLEFOUGASSE AUGES", cp : "04230" },
{ code : "4110", intitule : "MALLEMOISSON", cp : "04510" },
{ code : "4111", intitule : "MANE", cp : "04300" },
{ code : "4112", intitule : "MANOSQUE", cp : "04100" },
{ code : "4113", intitule : "MARCOUX", cp : "04420" },
{ code : "4115", intitule : "MEAILLES", cp : "04240" },
{ code : "4116", intitule : "LES MEES", cp : "04190" },
{ code : "4118", intitule : "MELVE", cp : "04250" },
{ code : "4120", intitule : "VAL D ORONAYE", cp : "04530" },
{ code : "4120", intitule : "VAL D ORONAYE", cp : "04530" },
{ code : "4121", intitule : "MEZEL", cp : "04270" },
{ code : "4122", intitule : "MIRABEAU", cp : "04510" },
{ code : "4123", intitule : "MISON", cp : "04200" },
{ code : "4124", intitule : "MONTAGNAC MONTPEZAT", cp : "04500" },
{ code : "4124", intitule : "MONTAGNAC MONTPEZAT", cp : "04500" },
{ code : "4126", intitule : "MONTCLAR", cp : "04140" },
{ code : "4127", intitule : "MONTFORT", cp : "04600" },
{ code : "4128", intitule : "MONTFURON", cp : "04110" },
{ code : "4129", intitule : "MONTJUSTIN", cp : "04110" },
{ code : "4130", intitule : "MONTLAUX", cp : "04230" },
{ code : "4132", intitule : "MONTSALIER", cp : "04150" },
{ code : "4133", intitule : "MORIEZ", cp : "04170" },
{ code : "4134", intitule : "LA MOTTE DU CAIRE", cp : "04250" },
{ code : "4135", intitule : "MOUSTIERS STE MARIE", cp : "04360" },
{ code : "4136", intitule : "LA MURE ARGENS", cp : "04170" },
{ code : "4136", intitule : "LA MURE ARGENS", cp : "04170" },
{ code : "4137", intitule : "NIBLES", cp : "04250" },
{ code : "4138", intitule : "NIOZELLES", cp : "04300" },
{ code : "4139", intitule : "NOYERS SUR JABRON", cp : "04200" },
{ code : "4140", intitule : "LES OMERGUES", cp : "04200" },
{ code : "4141", intitule : "ONGLES", cp : "04230" },
{ code : "4142", intitule : "OPPEDETTE", cp : "04110" },
{ code : "4143", intitule : "ORAISON", cp : "04700" },
{ code : "4144", intitule : "LA PALUD SUR VERDON", cp : "04120" },
{ code : "4144", intitule : "LA PALUD SUR VERDON", cp : "04120" },
{ code : "4145", intitule : "PEIPIN", cp : "04200" },
{ code : "4145", intitule : "PEIPIN", cp : "04200" },
{ code : "4148", intitule : "PEYROULES", cp : "04120" },
{ code : "4149", intitule : "PEYRUIS", cp : "04310" },
{ code : "4150", intitule : "PIEGUT", cp : "05130" },
{ code : "4151", intitule : "PIERRERUE", cp : "04300" },
{ code : "4152", intitule : "PIERREVERT", cp : "04860" },
{ code : "4154", intitule : "PONTIS", cp : "05160" },
{ code : "4155", intitule : "PRADS HAUTE BLEONE", cp : "04420" },
{ code : "4155", intitule : "PRADS HAUTE BLEONE", cp : "04420" },
{ code : "4155", intitule : "PRADS HAUTE BLEONE", cp : "04420" },
{ code : "4156", intitule : "PUIMICHEL", cp : "04700" },
{ code : "4157", intitule : "PUIMOISSON", cp : "04410" },
{ code : "4158", intitule : "QUINSON", cp : "04500" },
{ code : "4159", intitule : "REDORTIERS", cp : "04150" },
{ code : "4160", intitule : "REILLANNE", cp : "04110" },
{ code : "4161", intitule : "MEOLANS REVEL", cp : "04340" },
{ code : "4161", intitule : "MEOLANS REVEL", cp : "04340" },
{ code : "4161", intitule : "MEOLANS REVEL", cp : "04340" },
{ code : "4162", intitule : "REVEST DES BROUSSES", cp : "04150" },
{ code : "4163", intitule : "REVEST DU BION", cp : "04150" },
{ code : "4164", intitule : "REVEST ST MARTIN", cp : "04230" },
{ code : "4166", intitule : "RIEZ", cp : "04500" },
{ code : "4167", intitule : "LA ROBINE SUR GALABRE", cp : "04000" },
{ code : "4167", intitule : "LA ROBINE SUR GALABRE", cp : "04000" },
{ code : "4167", intitule : "LA ROBINE SUR GALABRE", cp : "04000" },
{ code : "4167", intitule : "LA ROBINE SUR GALABRE", cp : "04000" },
{ code : "4169", intitule : "LA ROCHEGIRON", cp : "04150" },
{ code : "4170", intitule : "LA ROCHETTE", cp : "06260" },
{ code : "4171", intitule : "ROUGON", cp : "04120" },
{ code : "4172", intitule : "ROUMOULES", cp : "04500" },
{ code : "4173", intitule : "ST ANDRE LES ALPES", cp : "04170" },
{ code : "4174", intitule : "ST BENOIT", cp : "04240" },
{ code : "4175", intitule : "STE CROIX A LAUZE", cp : "04110" },
{ code : "4176", intitule : "STE CROIX DU VERDON", cp : "04500" },
{ code : "4177", intitule : "HAUTES DUYES", cp : "04380" },
{ code : "4177", intitule : "HAUTES DUYES", cp : "04380" },
{ code : "4178", intitule : "ST ETIENNE LES ORGUES", cp : "04230" },
{ code : "4179", intitule : "ST GENIEZ", cp : "04200" },
{ code : "4180", intitule : "ST JACQUES", cp : "04330" },
{ code : "4181", intitule : "ST JEANNET", cp : "04270" },
{ code : "4182", intitule : "ST JULIEN D ASSE", cp : "04270" },
{ code : "4183", intitule : "ST JULIEN DU VERDON", cp : "04170" },
{ code : "4184", intitule : "ST JURS", cp : "04410" },
{ code : "4186", intitule : "ST LAURENT DU VERDON", cp : "04500" },
{ code : "4187", intitule : "ST LIONS", cp : "04330" },
{ code : "4188", intitule : "ST MAIME", cp : "04300" },
{ code : "4189", intitule : "ST MARTIN DE BROMES", cp : "04800" },
{ code : "4190", intitule : "ST MARTIN LES EAUX", cp : "04300" },
{ code : "4191", intitule : "ST MARTIN LES SEYNE", cp : "04140" },
{ code : "4192", intitule : "ST MICHEL L OBSERVATOIRE", cp : "04870" },
{ code : "4192", intitule : "ST MICHEL L OBSERVATOIRE", cp : "04870" },
{ code : "4193", intitule : "ST PAUL SUR UBAYE", cp : "04530" },
{ code : "4194", intitule : "ST PIERRE", cp : "06260" },
{ code : "4195", intitule : "ST PONS", cp : "04400" },
{ code : "4197", intitule : "STE TULLE", cp : "04220" },
{ code : "4198", intitule : "ST VINCENT LES FORTS", cp : "04340" },
{ code : "4199", intitule : "ST VINCENT SUR JABRON", cp : "04200" },
{ code : "4200", intitule : "SALIGNAC", cp : "04290" },
{ code : "4201", intitule : "SAUMANE", cp : "04150" },
{ code : "4202", intitule : "SAUSSES", cp : "04320" },
{ code : "4203", intitule : "SELONNET", cp : "04140" },
{ code : "4204", intitule : "SENEZ", cp : "04270" },
{ code : "4204", intitule : "SENEZ", cp : "04330" },
{ code : "4205", intitule : "SEYNE", cp : "04140" },
{ code : "4206", intitule : "SIGONCE", cp : "04300" },
{ code : "4207", intitule : "SIGOYER", cp : "04200" },
{ code : "4208", intitule : "SIMIANE LA ROTONDE", cp : "04150" },
{ code : "4208", intitule : "SIMIANE LA ROTONDE", cp : "04150" },
{ code : "4208", intitule : "SIMIANE LA ROTONDE", cp : "04150" },
{ code : "4209", intitule : "SISTERON", cp : "04200" },
{ code : "4210", intitule : "SOLEILHAS", cp : "04120" },
{ code : "4211", intitule : "SOURRIBES", cp : "04290" },
{ code : "4214", intitule : "TARTONNE", cp : "04330" },
{ code : "4216", intitule : "THEZE", cp : "04200" },
{ code : "4217", intitule : "THOARD", cp : "04380" },
{ code : "4217", intitule : "THOARD", cp : "04380" },
{ code : "4218", intitule : "THORAME BASSE", cp : "04170" },
{ code : "4219", intitule : "THORAME HAUTE", cp : "04170" },
{ code : "4219", intitule : "THORAME HAUTE", cp : "04170" },
{ code : "4220", intitule : "LES THUILES", cp : "04400" },
{ code : "4222", intitule : "TURRIERS", cp : "04250" },
{ code : "4224", intitule : "UBRAYE", cp : "04240" },
{ code : "4226", intitule : "UVERNET FOURS", cp : "04400" },
{ code : "4226", intitule : "UVERNET FOURS", cp : "04400" },
{ code : "4226", intitule : "UVERNET FOURS", cp : "04400" },
{ code : "4227", intitule : "VACHERES", cp : "04110" },
{ code : "4228", intitule : "VALAVOIRE", cp : "04250" },
{ code : "4229", intitule : "VALBELLE", cp : "04200" },
{ code : "4230", intitule : "VALENSOLE", cp : "04210" },
{ code : "4231", intitule : "VALERNES", cp : "04200" },
{ code : "4233", intitule : "VAUMEILH", cp : "04200" },
{ code : "4234", intitule : "VENTEROL", cp : "05130" },
{ code : "4235", intitule : "VERDACHES", cp : "04140" },
{ code : "4236", intitule : "VERGONS", cp : "04170" },
{ code : "4237", intitule : "LE VERNET", cp : "04140" },
{ code : "4240", intitule : "VILLARS COLMARS", cp : "04370" },
{ code : "4241", intitule : "VILLEMUS", cp : "04110" },
{ code : "4242", intitule : "VILLENEUVE", cp : "04180" },
{ code : "4244", intitule : "VOLONNE", cp : "04290" },
{ code : "4245", intitule : "VOLX", cp : "04130" },
{ code : "5001", intitule : "ABRIES", cp : "05460" },
{ code : "5003", intitule : "AIGUILLES", cp : "05470" },
{ code : "5004", intitule : "ANCELLE", cp : "05260" },
{ code : "5006", intitule : "L ARGENTIERE LA BESSEE", cp : "05120" },
{ code : "5007", intitule : "ARVIEUX", cp : "05350" },
{ code : "5008", intitule : "ASPREMONT", cp : "05140" },
{ code : "5009", intitule : "ASPRES LES CORPS", cp : "05800" },
{ code : "5010", intitule : "ASPRES SUR BUECH", cp : "05140" },
{ code : "5011", intitule : "AVANCON", cp : "05230" },
{ code : "5012", intitule : "BARATIER", cp : "05200" },
{ code : "5013", intitule : "BARCILLONNETTE", cp : "05110" },
{ code : "5014", intitule : "BARRET SUR MEOUGE", cp : "05300" },
{ code : "5016", intitule : "LA BATIE MONTSALEON", cp : "05700" },
{ code : "5017", intitule : "LA BATIE NEUVE", cp : "05230" },
{ code : "5018", intitule : "LA BATIE VIEILLE", cp : "05000" },
{ code : "5019", intitule : "LA BEAUME", cp : "05140" },
{ code : "5021", intitule : "LE BERSAC", cp : "05700" },
{ code : "5022", intitule : "BREZIERS", cp : "05190" },
{ code : "5023", intitule : "BRIANCON", cp : "05100" },
{ code : "5024", intitule : "BRUIS", cp : "05150" },
{ code : "5025", intitule : "BUISSARD", cp : "05500" },
{ code : "5026", intitule : "CEILLAC", cp : "05600" },
{ code : "5027", intitule : "CERVIERES", cp : "05100" },
{ code : "5028", intitule : "CHABESTAN", cp : "05400" },
{ code : "5029", intitule : "CHABOTTES", cp : "05260" },
{ code : "5031", intitule : "CHAMPCELLA", cp : "05310" },
{ code : "5032", intitule : "CHAMPOLEON", cp : "05260" },
{ code : "5033", intitule : "CHANOUSSE", cp : "05700" },
{ code : "5035", intitule : "CHATEAUNEUF D OZE", cp : "05400" },
{ code : "5036", intitule : "CHATEAUROUX LES ALPES", cp : "05380" },
{ code : "5037", intitule : "CHATEAUVIEUX", cp : "05000" },
{ code : "5038", intitule : "CHATEAU VILLE VIEILLE", cp : "05350" },
{ code : "5038", intitule : "CHATEAU VILLE VIEILLE", cp : "05350" },
{ code : "5039", intitule : "CHAUFFAYER", cp : "05800" },
{ code : "5040", intitule : "CHORGES", cp : "05230" },
{ code : "5043", intitule : "LES COSTES", cp : "05500" },
{ code : "5044", intitule : "CREVOUX", cp : "05200" },
{ code : "5045", intitule : "CROTS", cp : "05200" },
{ code : "5046", intitule : "EMBRUN", cp : "05200" },
{ code : "5047", intitule : "EOURRES", cp : "05300" },
{ code : "5048", intitule : "L EPINE", cp : "05700" },
{ code : "5049", intitule : "ESPARRON", cp : "05110" },
{ code : "5050", intitule : "ESPINASSES", cp : "05190" },
{ code : "5051", intitule : "ETOILE ST CYRICE", cp : "05700" },
{ code : "5052", intitule : "EYGLIERS", cp : "05600" },
{ code : "5053", intitule : "GARDE COLOMBE", cp : "05300" },
{ code : "5053", intitule : "GARDE COLOMBE", cp : "05300" },
{ code : "5053", intitule : "GARDE COLOMBE", cp : "05300" },
{ code : "5054", intitule : "LA FARE EN CHAMPSAUR", cp : "05500" },
{ code : "5055", intitule : "LA FAURIE", cp : "05140" },
{ code : "5056", intitule : "FOREST ST JULIEN", cp : "05260" },
{ code : "5057", intitule : "FOUILLOUSE", cp : "05130" },
{ code : "5058", intitule : "FREISSINIERES", cp : "05310" },
{ code : "5059", intitule : "LA FREISSINOUSE", cp : "05000" },
{ code : "5060", intitule : "FURMEYER", cp : "05400" },
{ code : "5061", intitule : "GAP", cp : "05000" },
{ code : "5061", intitule : "GAP", cp : "05000" },
{ code : "5062", intitule : "LE GLAIZIL", cp : "05800" },
{ code : "5063", intitule : "LA GRAVE", cp : "05320" },
{ code : "5064", intitule : "LA CHAPELLE EN VALGAUDEMAR", cp : "05800" },
{ code : "5065", intitule : "GUILLESTRE", cp : "05600" },
{ code : "5066", intitule : "LA HAUTE BEAUME", cp : "05140" },
{ code : "5068", intitule : "JARJAYES", cp : "05130" },
{ code : "5070", intitule : "LARAGNE MONTEGLIN", cp : "05300" },
{ code : "5071", intitule : "LARDIER ET VALENCA", cp : "05110" },
{ code : "5072", intitule : "LAYE", cp : "05500" },
{ code : "5073", intitule : "LAZER", cp : "05300" },
{ code : "5074", intitule : "LETTRET", cp : "05130" },
{ code : "5075", intitule : "MANTEYER", cp : "05400" },
{ code : "5076", intitule : "MEREUIL", cp : "05700" },
{ code : "5077", intitule : "MOLINES EN QUEYRAS", cp : "05350" },
{ code : "5078", intitule : "MONETIER ALLEMONT", cp : "05110" },
{ code : "5079", intitule : "LE MONETIER LES BAINS", cp : "05220" },
{ code : "5080", intitule : "MONTBRAND", cp : "05140" },
{ code : "5081", intitule : "MONTCLUS", cp : "05700" },
{ code : "5082", intitule : "MONT DAUPHIN", cp : "05600" },
{ code : "5084", intitule : "MONTGARDIN", cp : "05230" },
{ code : "5085", intitule : "MONTGENEVRE", cp : "05100" },
{ code : "5086", intitule : "MONTJAY", cp : "05150" },
{ code : "5087", intitule : "MONTMAUR", cp : "05400" },
{ code : "5088", intitule : "MONTMORIN", cp : "05150" },
{ code : "5089", intitule : "MONTROND", cp : "05700" },
{ code : "5090", intitule : "LA MOTTE EN CHAMPSAUR", cp : "05500" },
{ code : "5091", intitule : "MOYDANS", cp : "05150" },
{ code : "5092", intitule : "NEFFES", cp : "05000" },
{ code : "5093", intitule : "NEVACHE", cp : "05100" },
{ code : "5094", intitule : "NOSSAGE ET BENEVENT", cp : "05700" },
{ code : "5095", intitule : "LE NOYER", cp : "05500" },
{ code : "5096", intitule : "ORCIERES", cp : "05170" },
{ code : "5096", intitule : "ORCIERES", cp : "05170" },
{ code : "5097", intitule : "ORPIERRE", cp : "05700" },
{ code : "5098", intitule : "LES ORRES", cp : "05200" },
{ code : "5099", intitule : "OZE", cp : "05400" },
{ code : "5100", intitule : "PELLEAUTIER", cp : "05000" },
{ code : "5101", intitule : "PELVOUX", cp : "05340" },
{ code : "5101", intitule : "PELVOUX", cp : "05340" },
{ code : "5102", intitule : "LA PIARRE", cp : "05700" },
{ code : "5103", intitule : "LE POET", cp : "05300" },
{ code : "5104", intitule : "POLIGNY", cp : "05500" },
{ code : "5106", intitule : "PRUNIERES", cp : "05230" },
{ code : "5107", intitule : "PUY ST ANDRE", cp : "05100" },
{ code : "5108", intitule : "PUY ST EUSEBE", cp : "05200" },
{ code : "5109", intitule : "PUY ST PIERRE", cp : "05100" },
{ code : "5110", intitule : "PUY ST VINCENT", cp : "05290" },
{ code : "5111", intitule : "PUY SANIERES", cp : "05200" },
{ code : "5112", intitule : "RABOU", cp : "05400" },
{ code : "5113", intitule : "RAMBAUD", cp : "05000" },
{ code : "5114", intitule : "REALLON", cp : "05160" },
{ code : "5115", intitule : "REMOLLON", cp : "05190" },
{ code : "5116", intitule : "REOTIER", cp : "05600" },
{ code : "5117", intitule : "RIBEYRET", cp : "05150" },
{ code : "5118", intitule : "VAL BUECH MEOUGE", cp : "05300" },
{ code : "5118", intitule : "VAL BUECH MEOUGE", cp : "05300" },
{ code : "5118", intitule : "VAL BUECH MEOUGE", cp : "05300" },
{ code : "5119", intitule : "RISOUL", cp : "05600" },
{ code : "5120", intitule : "RISTOLAS", cp : "05460" },
{ code : "5121", intitule : "ROCHEBRUNE", cp : "05190" },
{ code : "5122", intitule : "LA ROCHE DE RAME", cp : "05310" },
{ code : "5123", intitule : "LA ROCHE DES ARNAUDS", cp : "05400" },
{ code : "5124", intitule : "LA ROCHETTE", cp : "05000" },
{ code : "5126", intitule : "ROSANS", cp : "05150" },
{ code : "5127", intitule : "ROUSSET", cp : "05190" },
{ code : "5128", intitule : "ST ANDRE D EMBRUN", cp : "05200" },
{ code : "5129", intitule : "ST ANDRE DE ROSANS", cp : "05150" },
{ code : "5130", intitule : "ST APOLLINAIRE", cp : "05160" },
{ code : "5131", intitule : "ST AUBAN D OZE", cp : "05400" },
{ code : "5132", intitule : "ST BONNET EN CHAMPSAUR", cp : "05500" },
{ code : "5132", intitule : "ST BONNET EN CHAMPSAUR", cp : "05500" },
{ code : "5132", intitule : "ST BONNET EN CHAMPSAUR", cp : "05500" },
{ code : "5133", intitule : "ST CHAFFREY", cp : "05330" },
{ code : "5134", intitule : "ST CLEMENT SUR DURANCE", cp : "05600" },
{ code : "5135", intitule : "STE COLOMBE", cp : "05700" },
{ code : "5136", intitule : "ST CREPIN", cp : "05600" },
{ code : "5139", intitule : "LE DEVOLUY", cp : "05250" },
{ code : "5139", intitule : "LE DEVOLUY", cp : "05250" },
{ code : "5139", intitule : "LE DEVOLUY", cp : "05250" },
{ code : "5139", intitule : "LE DEVOLUY", cp : "05250" },
{ code : "5139", intitule : "LE DEVOLUY", cp : "05250" },
{ code : "5140", intitule : "ST ETIENNE LE LAUS", cp : "05130" },
{ code : "5141", intitule : "ST EUSEBE EN CHAMPSAUR", cp : "05500" },
{ code : "5142", intitule : "ST FIRMIN", cp : "05800" },
{ code : "5144", intitule : "ST JACQUES EN VALGODEMARD", cp : "05800" },
{ code : "5145", intitule : "ST JEAN ST NICOLAS", cp : "05260" },
{ code : "5145", intitule : "ST JEAN ST NICOLAS", cp : "05260" },
{ code : "5146", intitule : "ST JULIEN EN BEAUCHENE", cp : "05140" },
{ code : "5147", intitule : "ST JULIEN EN CHAMPSAUR", cp : "05500" },
{ code : "5148", intitule : "ST LAURENT DU CROS", cp : "05500" },
{ code : "5149", intitule : "ST LEGER LES MELEZES", cp : "05260" },
{ code : "5150", intitule : "STE MARIE", cp : "05150" },
{ code : "5151", intitule : "ST MARTIN DE QUEYRIERES", cp : "05120" },
{ code : "5152", intitule : "ST MAURICE EN VALGODEMARD", cp : "05800" },
{ code : "5153", intitule : "ST MICHEL DE CHAILLOL", cp : "05260" },
{ code : "5154", intitule : "ST PIERRE D ARGENCON", cp : "05140" },
{ code : "5155", intitule : "ST PIERRE AVEZ", cp : "05300" },
{ code : "5156", intitule : "ST SAUVEUR", cp : "05200" },
{ code : "5157", intitule : "ST VERAN", cp : "05350" },
{ code : "5158", intitule : "LE SAIX", cp : "05400" },
{ code : "5159", intitule : "SALEON", cp : "05300" },
{ code : "5160", intitule : "SALERANS", cp : "05300" },
{ code : "5161", intitule : "LA SALLE LES ALPES", cp : "05240" },
{ code : "5162", intitule : "LA SAULCE", cp : "05110" },
{ code : "5163", intitule : "LE SAUZE DU LAC", cp : "05160" },
{ code : "5164", intitule : "SAVINES LE LAC", cp : "05160" },
{ code : "5165", intitule : "SAVOURNON", cp : "05700" },
{ code : "5166", intitule : "SERRES", cp : "05700" },
{ code : "5167", intitule : "SIGOTTIER", cp : "05700" },
{ code : "5168", intitule : "SIGOYER", cp : "05130" },
{ code : "5169", intitule : "SORBIERS", cp : "05150" },
{ code : "5170", intitule : "TALLARD", cp : "05130" },
{ code : "5171", intitule : "THEUS", cp : "05190" },
{ code : "5172", intitule : "TRESCLEOUX", cp : "05700" },
{ code : "5173", intitule : "UPAIX", cp : "05300" },
{ code : "5174", intitule : "VAL DES PRES", cp : "05100" },
{ code : "5175", intitule : "VALLOUISE", cp : "05290" },
{ code : "5176", intitule : "VALSERRES", cp : "05130" },
{ code : "5177", intitule : "VARS", cp : "05560" },
{ code : "5178", intitule : "VENTAVON", cp : "05300" },
{ code : "5179", intitule : "VEYNES", cp : "05400" },
{ code : "5180", intitule : "LES VIGNEAUX", cp : "05120" },
{ code : "5181", intitule : "VILLAR D ARENE", cp : "05480" },
{ code : "5182", intitule : "VILLAR LOUBIERE", cp : "05800" },
{ code : "5183", intitule : "VILLAR ST PANCRACE", cp : "05100" },
{ code : "5184", intitule : "VITROLLES", cp : "05110" },
{ code : "6001", intitule : "AIGLUN", cp : "06910" },
{ code : "6002", intitule : "AMIRAT", cp : "06910" },
{ code : "6003", intitule : "ANDON", cp : "06750" },
{ code : "6003", intitule : "ANDON", cp : "06750" },
{ code : "6004", intitule : "ANTIBES", cp : "06160" },
{ code : "6004", intitule : "ANTIBES", cp : "06600" },
{ code : "6004", intitule : "ANTIBES", cp : "06600" },
{ code : "6004", intitule : "ANTIBES", cp : "06160" },
{ code : "6005", intitule : "ASCROS", cp : "06260" },
{ code : "6006", intitule : "ASPREMONT", cp : "06790" },
{ code : "6007", intitule : "AURIBEAU SUR SIAGNE", cp : "06810" },
{ code : "6008", intitule : "AUVARE", cp : "06260" },
{ code : "6009", intitule : "BAIROLS", cp : "06420" },
{ code : "6010", intitule : "LE BAR SUR LOUP", cp : "06620" },
{ code : "6011", intitule : "BEAULIEU SUR MER", cp : "06310" },
{ code : "6012", intitule : "BEAUSOLEIL", cp : "06240" },
{ code : "6013", intitule : "BELVEDERE", cp : "06450" },
{ code : "6014", intitule : "BENDEJUN", cp : "06390" },
{ code : "6015", intitule : "BERRE LES ALPES", cp : "06390" },
{ code : "6016", intitule : "BEUIL", cp : "06470" },
{ code : "6017", intitule : "BEZAUDUN LES ALPES", cp : "06510" },
{ code : "6018", intitule : "BIOT", cp : "06410" },
{ code : "6019", intitule : "BLAUSASC", cp : "06440" },
{ code : "6020", intitule : "LA BOLLENE VESUBIE", cp : "06450" },
{ code : "6021", intitule : "BONSON", cp : "06830" },
{ code : "6022", intitule : "BOUYON", cp : "06510" },
{ code : "6023", intitule : "BREIL SUR ROYA", cp : "06540" },
{ code : "6024", intitule : "BRIANCONNET", cp : "06850" },
{ code : "6025", intitule : "LE BROC", cp : "06510" },
{ code : "6026", intitule : "CABRIS", cp : "06530" },
{ code : "6027", intitule : "CAGNES SUR MER", cp : "06800" },
{ code : "6027", intitule : "CAGNES SUR MER", cp : "06800" },
{ code : "6028", intitule : "CAILLE", cp : "06750" },
{ code : "6029", intitule : "CANNES", cp : "06150" },
{ code : "6029", intitule : "CANNES", cp : "06400" },
{ code : "6030", intitule : "LE CANNET", cp : "06110" },
{ code : "6030", intitule : "LE CANNET", cp : "06110" },
{ code : "6031", intitule : "CANTARON", cp : "06340" },
{ code : "6032", intitule : "CAP D AIL", cp : "06320" },
{ code : "6033", intitule : "CARROS", cp : "06510" },
{ code : "6034", intitule : "CASTAGNIERS", cp : "06670" },
{ code : "6035", intitule : "CASTELLAR", cp : "06500" },
{ code : "6036", intitule : "CASTILLON", cp : "06500" },
{ code : "6037", intitule : "CAUSSOLS", cp : "06460" },
{ code : "6038", intitule : "CHATEAUNEUF GRASSE", cp : "06740" },
{ code : "6039", intitule : "CHATEAUNEUF VILLEVIEILLE", cp : "06390" },
{ code : "6040", intitule : "CHATEAUNEUF D ENTRAUNES", cp : "06470" },
{ code : "6041", intitule : "CIPIERES", cp : "06620" },
{ code : "6042", intitule : "CLANS", cp : "06420" },
{ code : "6043", intitule : "COARAZE", cp : "06390" },
{ code : "6044", intitule : "LA COLLE SUR LOUP", cp : "06480" },
{ code : "6045", intitule : "COLLONGUES", cp : "06910" },
{ code : "6046", intitule : "COLOMARS", cp : "06670" },
{ code : "6047", intitule : "CONSEGUDES", cp : "06510" },
{ code : "6048", intitule : "CONTES", cp : "06390" },
{ code : "6049", intitule : "COURMES", cp : "06620" },
{ code : "6050", intitule : "COURSEGOULES", cp : "06140" },
{ code : "6051", intitule : "LA CROIX SUR ROUDOULE", cp : "06260" },
{ code : "6052", intitule : "CUEBRIS", cp : "06910" },
{ code : "6053", intitule : "DALUIS", cp : "06470" },
{ code : "6054", intitule : "DRAP", cp : "06340" },
{ code : "6055", intitule : "DURANUS", cp : "06670" },
{ code : "6056", intitule : "ENTRAUNES", cp : "06470" },
{ code : "6056", intitule : "ENTRAUNES", cp : "06470" },
{ code : "6057", intitule : "L ESCARENE", cp : "06440" },
{ code : "6058", intitule : "ESCRAGNOLLES", cp : "06460" },
{ code : "6059", intitule : "EZE", cp : "06360" },
{ code : "6060", intitule : "FALICON", cp : "06950" },
{ code : "6061", intitule : "LES FERRES", cp : "06510" },
{ code : "6062", intitule : "FONTAN", cp : "06540" },
{ code : "6063", intitule : "GARS", cp : "06850" },
{ code : "6064", intitule : "GATTIERES", cp : "06510" },
{ code : "6065", intitule : "LA GAUDE", cp : "06610" },
{ code : "6066", intitule : "GILETTE", cp : "06830" },
{ code : "6067", intitule : "GORBIO", cp : "06500" },
{ code : "6068", intitule : "GOURDON", cp : "06620" },
{ code : "6069", intitule : "GRASSE", cp : "06130" },
{ code : "6069", intitule : "GRASSE", cp : "06130" },
{ code : "6069", intitule : "GRASSE", cp : "06520" },
{ code : "6069", intitule : "GRASSE", cp : "06130" },
{ code : "6070", intitule : "GREOLIERES", cp : "06620" },
{ code : "6071", intitule : "GUILLAUMES", cp : "06470" },
{ code : "6072", intitule : "ILONSE", cp : "06420" },
{ code : "6073", intitule : "ISOLA", cp : "06420" },
{ code : "6074", intitule : "LANTOSQUE", cp : "06450" },
{ code : "6075", intitule : "LEVENS", cp : "06670" },
{ code : "6075", intitule : "LEVENS", cp : "06670" },
{ code : "6076", intitule : "LIEUCHE", cp : "06260" },
{ code : "6077", intitule : "LUCERAM", cp : "06440" },
{ code : "6077", intitule : "LUCERAM", cp : "06440" },
{ code : "6078", intitule : "MALAUSSENE", cp : "06710" },
{ code : "6079", intitule : "MANDELIEU LA NAPOULE", cp : "06210" },
{ code : "6079", intitule : "MANDELIEU LA NAPOULE", cp : "06210" },
{ code : "6080", intitule : "MARIE", cp : "06420" },
{ code : "6081", intitule : "LE MAS", cp : "06910" },
{ code : "6082", intitule : "MASSOINS", cp : "06710" },
{ code : "6083", intitule : "MENTON", cp : "06500" },
{ code : "6084", intitule : "MOUANS SARTOUX", cp : "06370" },
{ code : "6085", intitule : "MOUGINS", cp : "06250" },
{ code : "6086", intitule : "MOULINET", cp : "06380" },
{ code : "6087", intitule : "LES MUJOULS", cp : "06910" },
{ code : "6088", intitule : "NICE", cp : "06200" },
{ code : "6088", intitule : "NICE", cp : "06000" },
{ code : "6088", intitule : "NICE", cp : "06200" },
{ code : "6088", intitule : "NICE", cp : "06200" },
{ code : "6088", intitule : "NICE", cp : "06300" },
{ code : "6088", intitule : "NICE", cp : "06100" },
{ code : "6089", intitule : "OPIO", cp : "06650" },
{ code : "6090", intitule : "PEGOMAS", cp : "06580" },
{ code : "6091", intitule : "PEILLE", cp : "06440" },
{ code : "6091", intitule : "PEILLE", cp : "06440" },
{ code : "6092", intitule : "PEILLON", cp : "06440" },
{ code : "6093", intitule : "LA PENNE", cp : "06260" },
{ code : "6094", intitule : "PEONE", cp : "06470" },
{ code : "6094", intitule : "PEONE", cp : "06470" },
{ code : "6095", intitule : "PEYMEINADE", cp : "06530" },
{ code : "6096", intitule : "PIERLAS", cp : "06260" },
{ code : "6097", intitule : "PIERREFEU", cp : "06910" },
{ code : "6098", intitule : "PUGET ROSTANG", cp : "06260" },
{ code : "6099", intitule : "PUGET THENIERS", cp : "06260" },
{ code : "6100", intitule : "REVEST LES ROCHES", cp : "06830" },
{ code : "6101", intitule : "RIGAUD", cp : "06260" },
{ code : "6102", intitule : "RIMPLAS", cp : "06420" },
{ code : "6103", intitule : "ROQUEBILLIERE", cp : "06450" },
{ code : "6104", intitule : "ROQUEBRUNE CAP MARTIN", cp : "06190" },
{ code : "6104", intitule : "ROQUEBRUNE CAP MARTIN", cp : "06190" },
{ code : "6105", intitule : "ROQUEFORT LES PINS", cp : "06330" },
{ code : "6106", intitule : "ROQUESTERON", cp : "06910" },
{ code : "6107", intitule : "LA ROQUE EN PROVENCE", cp : "06910" },
{ code : "6108", intitule : "LA ROQUETTE SUR SIAGNE", cp : "06550" },
{ code : "6109", intitule : "LA ROQUETTE SUR VAR", cp : "06670" },
{ code : "6110", intitule : "ROUBION", cp : "06420" },
{ code : "6111", intitule : "ROURE", cp : "06420" },
{ code : "6112", intitule : "LE ROURET", cp : "06650" },
{ code : "6113", intitule : "STE AGNES", cp : "06500" },
{ code : "6114", intitule : "ST ANDRE DE LA ROCHE", cp : "06730" },
{ code : "6115", intitule : "ST ANTONIN", cp : "06260" },
{ code : "6116", intitule : "ST AUBAN", cp : "06850" },
{ code : "6117", intitule : "ST BLAISE", cp : "06670" },
{ code : "6118", intitule : "ST CEZAIRE SUR SIAGNE", cp : "06530" },
{ code : "6119", intitule : "ST DALMAS LE SELVAGE", cp : "06660" },
{ code : "6120", intitule : "ST ETIENNE DE TINEE", cp : "06660" },
{ code : "6120", intitule : "ST ETIENNE DE TINEE", cp : "06660" },
{ code : "6121", intitule : "ST JEAN CAP FERRAT", cp : "06230" },
{ code : "6122", intitule : "ST JEANNET", cp : "06640" },
{ code : "6123", intitule : "ST LAURENT DU VAR", cp : "06700" },
{ code : "6124", intitule : "ST LEGER", cp : "06260" },
{ code : "6125", intitule : "ST MARTIN D ENTRAUNES", cp : "06470" },
{ code : "6126", intitule : "ST MARTIN DU VAR", cp : "06670" },
{ code : "6127", intitule : "ST MARTIN VESUBIE", cp : "06450" },
{ code : "6127", intitule : "ST MARTIN VESUBIE", cp : "06450" },
{ code : "6128", intitule : "ST PAUL DE VENCE", cp : "06570" },
{ code : "6129", intitule : "ST SAUVEUR SUR TINEE", cp : "06420" },
{ code : "6130", intitule : "ST VALLIER DE THIEY", cp : "06460" },
{ code : "6131", intitule : "SALLAGRIFFON", cp : "06910" },
{ code : "6132", intitule : "SAORGE", cp : "06540" },
{ code : "6133", intitule : "SAUZE", cp : "06470" },
{ code : "6134", intitule : "SERANON", cp : "06750" },
{ code : "6134", intitule : "SERANON", cp : "06750" },
{ code : "6135", intitule : "SIGALE", cp : "06910" },
{ code : "6136", intitule : "SOSPEL", cp : "06380" },
{ code : "6137", intitule : "SPERACEDES", cp : "06530" },
{ code : "6138", intitule : "THEOULE SUR MER", cp : "06590" },
{ code : "6138", intitule : "THEOULE SUR MER", cp : "06590" },
{ code : "6139", intitule : "THIERY", cp : "06710" },
{ code : "6140", intitule : "LE TIGNET", cp : "06530" },
{ code : "6141", intitule : "TOUDON", cp : "06830" },
{ code : "6142", intitule : "TOUET DE L ESCARENE", cp : "06440" },
{ code : "6143", intitule : "TOUET SUR VAR", cp : "06710" },
{ code : "6144", intitule : "LA TOUR", cp : "06420" },
{ code : "6145", intitule : "TOURETTE DU CHATEAU", cp : "06830" },
{ code : "6146", intitule : "TOURNEFORT", cp : "06420" },
{ code : "6147", intitule : "TOURRETTE LEVENS", cp : "06690" },
{ code : "6148", intitule : "TOURRETTES SUR LOUP", cp : "06140" },
{ code : "6149", intitule : "LA TRINITE", cp : "06340" },
{ code : "6150", intitule : "LA TURBIE", cp : "06320" },
{ code : "6151", intitule : "UTELLE", cp : "06450" },
{ code : "6151", intitule : "UTELLE", cp : "06450" },
{ code : "6152", intitule : "VALBONNE", cp : "06560" },
{ code : "6152", intitule : "VALBONNE", cp : "06560" },
{ code : "6153", intitule : "VALDEBLORE", cp : "06420" },
{ code : "6154", intitule : "VALDEROURE", cp : "06750" },
{ code : "6155", intitule : "VALLAURIS", cp : "06220" },
{ code : "6155", intitule : "VALLAURIS", cp : "06220" },
{ code : "6156", intitule : "VENANSON", cp : "06450" },
{ code : "6157", intitule : "VENCE", cp : "06140" },
{ code : "6158", intitule : "VILLARS SUR VAR", cp : "06710" },
{ code : "6159", intitule : "VILLEFRANCHE SUR MER", cp : "06230" },
{ code : "6160", intitule : "VILLENEUVE D ENTRAUNES", cp : "06470" },
{ code : "6161", intitule : "VILLENEUVE LOUBET", cp : "06270" },
{ code : "6162", intitule : "LA BRIGUE", cp : "06430" },
{ code : "6163", intitule : "TENDE", cp : "06430" },
{ code : "6163", intitule : "TENDE", cp : "06430" },
{ code : "7001", intitule : "ACCONS", cp : "07160" },
{ code : "7002", intitule : "AILHON", cp : "07200" },
{ code : "7003", intitule : "AIZAC", cp : "07530" },
{ code : "7004", intitule : "AJOUX", cp : "07000" },
{ code : "7005", intitule : "ALBA LA ROMAINE", cp : "07400" },
{ code : "7006", intitule : "ALBON D ARDECHE", cp : "07190" },
{ code : "7007", intitule : "ALBOUSSIERE", cp : "07440" },
{ code : "7008", intitule : "ALISSAS", cp : "07210" },
{ code : "7009", intitule : "ANDANCE", cp : "07340" },
{ code : "7010", intitule : "ANNONAY", cp : "07100" },
{ code : "7011", intitule : "ANTRAIGUES SUR VOLANE", cp : "07530" },
{ code : "7012", intitule : "ARCENS", cp : "07310" },
{ code : "7013", intitule : "ARDOIX", cp : "07290" },
{ code : "7014", intitule : "ARLEBOSC", cp : "07410" },
{ code : "7015", intitule : "ARRAS SUR RHONE", cp : "07370" },
{ code : "7016", intitule : "ASPERJOC", cp : "07600" },
{ code : "7017", intitule : "LES ASSIONS", cp : "07140" },
{ code : "7018", intitule : "ASTET", cp : "07330" },
{ code : "7019", intitule : "AUBENAS", cp : "07200" },
{ code : "7020", intitule : "AUBIGNAS", cp : "07400" },
{ code : "7022", intitule : "BAIX", cp : "07210" },
{ code : "7023", intitule : "BALAZUC", cp : "07120" },
{ code : "7024", intitule : "BANNE", cp : "07460" },
{ code : "7025", intitule : "BARNAS", cp : "07330" },
{ code : "7026", intitule : "LE BEAGE", cp : "07630" },
{ code : "7027", intitule : "BEAUCHASTEL", cp : "07800" },
{ code : "7028", intitule : "BEAULIEU", cp : "07460" },
{ code : "7029", intitule : "BEAUMONT", cp : "07110" },
{ code : "7030", intitule : "BEAUVENE", cp : "07190" },
{ code : "7031", intitule : "BERRIAS ET CASTELJAU", cp : "07460" },
{ code : "7031", intitule : "BERRIAS ET CASTELJAU", cp : "07460" },
{ code : "7032", intitule : "BERZEME", cp : "07580" },
{ code : "7033", intitule : "BESSAS", cp : "07150" },
{ code : "7034", intitule : "BIDON", cp : "07700" },
{ code : "7035", intitule : "BOFFRES", cp : "07440" },
{ code : "7036", intitule : "BOGY", cp : "07340" },
{ code : "7037", intitule : "BOREE", cp : "07310" },
{ code : "7038", intitule : "BORNE", cp : "07590" },
{ code : "7039", intitule : "BOZAS", cp : "07410" },
{ code : "7040", intitule : "BOUCIEU LE ROI", cp : "07270" },
{ code : "7041", intitule : "BOULIEU LES ANNONAY", cp : "07100" },
{ code : "7042", intitule : "BOURG ST ANDEOL", cp : "07700" },
{ code : "7044", intitule : "BROSSAINC", cp : "07340" },
{ code : "7045", intitule : "BURZET", cp : "07450" },
{ code : "7047", intitule : "CELLIER DU LUC", cp : "07590" },
{ code : "7048", intitule : "CHALENCON", cp : "07240" },
{ code : "7049", intitule : "LE CHAMBON", cp : "07160" },
{ code : "7050", intitule : "CHAMBONAS", cp : "07140" },
{ code : "7051", intitule : "CHAMPAGNE", cp : "07340" },
{ code : "7052", intitule : "CHAMPIS", cp : "07440" },
{ code : "7053", intitule : "CHANDOLAS", cp : "07230" },
{ code : "7054", intitule : "CHANEAC", cp : "07310" },
{ code : "7055", intitule : "CHARMES SUR RHONE", cp : "07800" },
{ code : "7056", intitule : "CHARNAS", cp : "07340" },
{ code : "7058", intitule : "CHASSIERS", cp : "07110" },
{ code : "7059", intitule : "CHATEAUBOURG", cp : "07130" },
{ code : "7060", intitule : "CHATEAUNEUF DE VERNOUX", cp : "07240" },
{ code : "7061", intitule : "CHAUZON", cp : "07120" },
{ code : "7062", intitule : "CHAZEAUX", cp : "07110" },
{ code : "7063", intitule : "CHEMINAS", cp : "07300" },
{ code : "7064", intitule : "LE CHEYLARD", cp : "07160" },
{ code : "7065", intitule : "CHIROLS", cp : "07380" },
{ code : "7066", intitule : "CHOMERAC", cp : "07210" },
{ code : "7067", intitule : "COLOMBIER LE CARDINAL", cp : "07430" },
{ code : "7068", intitule : "COLOMBIER LE JEUNE", cp : "07270" },
{ code : "7069", intitule : "COLOMBIER LE VIEUX", cp : "07410" },
{ code : "7070", intitule : "CORNAS", cp : "07130" },
{ code : "7071", intitule : "COUCOURON", cp : "07470" },
{ code : "7072", intitule : "COUX", cp : "07000" },
{ code : "7073", intitule : "LE CRESTET", cp : "07270" },
{ code : "7074", intitule : "CREYSSEILLES", cp : "07000" },
{ code : "7075", intitule : "CROS DE GEORAND", cp : "07630" },
{ code : "7075", intitule : "CROS DE GEORAND", cp : "07510" },
{ code : "7076", intitule : "CRUAS", cp : "07350" },
{ code : "7077", intitule : "DARBRES", cp : "07170" },
{ code : "7078", intitule : "DAVEZIEUX", cp : "07430" },
{ code : "7079", intitule : "DESAIGNES", cp : "07570" },
{ code : "7080", intitule : "DEVESSET", cp : "07320" },
{ code : "7081", intitule : "DOMPNAC", cp : "07260" },
{ code : "7082", intitule : "DORNAS", cp : "07160" },
{ code : "7083", intitule : "DUNIERE SUR EYRIEUX", cp : "07360" },
{ code : "7084", intitule : "ECLASSAN", cp : "07370" },
{ code : "7085", intitule : "EMPURANY", cp : "07270" },
{ code : "7086", intitule : "ETABLES", cp : "07300" },
{ code : "7087", intitule : "FABRAS", cp : "07380" },
{ code : "7088", intitule : "FAUGERES", cp : "07230" },
{ code : "7089", intitule : "FELINES", cp : "07340" },
{ code : "7090", intitule : "FLAVIAC", cp : "07000" },
{ code : "7091", intitule : "FONS", cp : "07200" },
{ code : "7092", intitule : "FREYSSENET", cp : "07000" },
{ code : "7093", intitule : "GENESTELLE", cp : "07530" },
{ code : "7094", intitule : "GILHAC ET BRUZAC", cp : "07800" },
{ code : "7095", intitule : "GILHOC SUR ORMEZE", cp : "07270" },
{ code : "7096", intitule : "GLUIRAS", cp : "07190" },
{ code : "7097", intitule : "GLUN", cp : "07300" },
{ code : "7098", intitule : "GOURDON", cp : "07000" },
{ code : "7099", intitule : "GRAS", cp : "07700" },
{ code : "7100", intitule : "GRAVIERES", cp : "07140" },
{ code : "7101", intitule : "GROSPIERRES", cp : "07120" },
{ code : "7102", intitule : "GUILHERAND GRANGES", cp : "07500" },
{ code : "7103", intitule : "INTRES", cp : "07320" },
{ code : "7103", intitule : "INTRES", cp : "07310" },
{ code : "7104", intitule : "ISSAMOULENC", cp : "07190" },
{ code : "7105", intitule : "ISSANLAS", cp : "07660" },
{ code : "7105", intitule : "ISSANLAS", cp : "07510" },
{ code : "7106", intitule : "ISSARLES", cp : "07470" },
{ code : "7107", intitule : "JAUJAC", cp : "07380" },
{ code : "7108", intitule : "JAUNAC", cp : "07160" },
{ code : "7109", intitule : "JOANNAS", cp : "07110" },
{ code : "7110", intitule : "JOYEUSE", cp : "07260" },
{ code : "7111", intitule : "JUVINAS", cp : "07600" },
{ code : "7112", intitule : "LABASTIDE SUR BESORGUES", cp : "07600" },
{ code : "7113", intitule : "LABASTIDE DE VIRAC", cp : "07150" },
{ code : "7114", intitule : "LABATIE D ANDAURE", cp : "07570" },
{ code : "7115", intitule : "LABEAUME", cp : "07120" },
{ code : "7116", intitule : "LABEGUDE", cp : "07200" },
{ code : "7117", intitule : "LABLACHERE", cp : "07230" },
{ code : "7118", intitule : "LABOULE", cp : "07110" },
{ code : "7119", intitule : "LE LAC D ISSARLES", cp : "07470" },
{ code : "7120", intitule : "LACHAMP RAPHAEL", cp : "07530" },
{ code : "7121", intitule : "LACHAPELLE GRAILLOUSE", cp : "07470" },
{ code : "7122", intitule : "LACHAPELLE SOUS AUBENAS", cp : "07200" },
{ code : "7123", intitule : "LACHAPELLE SOUS CHANEAC", cp : "07310" },
{ code : "7124", intitule : "LAFARRE", cp : "07520" },
{ code : "7126", intitule : "LAGORCE", cp : "07150" },
{ code : "7127", intitule : "LALEVADE D ARDECHE", cp : "07380" },
{ code : "7128", intitule : "LALOUVESC", cp : "07520" },
{ code : "7129", intitule : "LAMASTRE", cp : "07270" },
{ code : "7130", intitule : "LANARCE", cp : "07660" },
{ code : "7131", intitule : "LANAS", cp : "07200" },
{ code : "7132", intitule : "LARGENTIERE", cp : "07110" },
{ code : "7133", intitule : "LARNAS", cp : "07220" },
{ code : "7134", intitule : "LAURAC EN VIVARAIS", cp : "07110" },
{ code : "7135", intitule : "LAVAL D AURELLE", cp : "07590" },
{ code : "7136", intitule : "LAVEYRUNE", cp : "48250" },
{ code : "7137", intitule : "LAVILLATTE", cp : "07660" },
{ code : "7138", intitule : "LAVILLEDIEU", cp : "07170" },
{ code : "7139", intitule : "LAVIOLLE", cp : "07530" },
{ code : "7140", intitule : "LEMPS", cp : "07610" },
{ code : "7141", intitule : "LENTILLERES", cp : "07200" },
{ code : "7142", intitule : "LESPERON", cp : "07660" },
{ code : "7143", intitule : "LIMONY", cp : "07340" },
{ code : "7144", intitule : "LOUBARESSE", cp : "07110" },
{ code : "7145", intitule : "LUSSAS", cp : "07170" },
{ code : "7146", intitule : "LYAS", cp : "07000" },
{ code : "7147", intitule : "MALARCE SUR LA THINES", cp : "07140" },
{ code : "7147", intitule : "MALARCE SUR LA THINES", cp : "07140" },
{ code : "7147", intitule : "MALARCE SUR LA THINES", cp : "07140" },
{ code : "7148", intitule : "MALBOSC", cp : "07140" },
{ code : "7149", intitule : "MARCOLS LES EAUX", cp : "07190" },
{ code : "7150", intitule : "MARIAC", cp : "07160" },
{ code : "7151", intitule : "MARS", cp : "07320" },
{ code : "7152", intitule : "MAUVES", cp : "07300" },
{ code : "7153", intitule : "MAYRES", cp : "07330" },
{ code : "7154", intitule : "MAZAN L ABBAYE", cp : "07510" },
{ code : "7155", intitule : "MERCUER", cp : "07200" },
{ code : "7156", intitule : "MEYRAS", cp : "07380" },
{ code : "7157", intitule : "MEYSSE", cp : "07400" },
{ code : "7158", intitule : "MEZILHAC", cp : "07530" },
{ code : "7159", intitule : "MIRABEL", cp : "07170" },
{ code : "7160", intitule : "MONESTIER", cp : "07690" },
{ code : "7161", intitule : "MONTPEZAT SOUS BAUZON", cp : "07560" },
{ code : "7162", intitule : "MONTREAL", cp : "07110" },
{ code : "7163", intitule : "MONTSELGUES", cp : "07140" },
{ code : "7165", intitule : "NONIERES", cp : "07160" },
{ code : "7166", intitule : "NOZIERES", cp : "07270" },
{ code : "7167", intitule : "LES OLLIERES SUR EYRIEUX", cp : "07360" },
{ code : "7168", intitule : "ORGNAC L AVEN", cp : "07150" },
{ code : "7169", intitule : "OZON", cp : "07370" },
{ code : "7170", intitule : "PAILHARES", cp : "07410" },
{ code : "7171", intitule : "PAYZAC", cp : "07230" },
{ code : "7172", intitule : "PEAUGRES", cp : "07340" },
{ code : "7173", intitule : "PEREYRES", cp : "07450" },
{ code : "7174", intitule : "PEYRAUD", cp : "07340" },
{ code : "7175", intitule : "LE PLAGNAL", cp : "07590" },
{ code : "7176", intitule : "PLANZOLLES", cp : "07230" },
{ code : "7177", intitule : "PLATS", cp : "07300" },
{ code : "7178", intitule : "PONT DE LABEAUME", cp : "07380" },
{ code : "7179", intitule : "POURCHERES", cp : "07000" },
{ code : "7181", intitule : "LE POUZIN", cp : "07250" },
{ code : "7182", intitule : "PRADES", cp : "07380" },
{ code : "7183", intitule : "PRADONS", cp : "07120" },
{ code : "7184", intitule : "PRANLES", cp : "07000" },
{ code : "7185", intitule : "PREAUX", cp : "07290" },
{ code : "7186", intitule : "PRIVAS", cp : "07000" },
{ code : "7187", intitule : "PRUNET", cp : "07110" },
{ code : "7188", intitule : "QUINTENAS", cp : "07290" },
{ code : "7189", intitule : "RIBES", cp : "07260" },
{ code : "7190", intitule : "ROCHECOLOMBE", cp : "07200" },
{ code : "7191", intitule : "ROCHEMAURE", cp : "07400" },
{ code : "7192", intitule : "ROCHEPAULE", cp : "07320" },
{ code : "7193", intitule : "ROCHER", cp : "07110" },
{ code : "7194", intitule : "ROCHESSAUVE", cp : "07210" },
{ code : "7195", intitule : "LA ROCHETTE", cp : "07310" },
{ code : "7196", intitule : "ROCLES", cp : "07110" },
{ code : "7197", intitule : "ROIFFIEUX", cp : "07100" },
{ code : "7198", intitule : "ROMPON", cp : "07250" },
{ code : "7198", intitule : "ROMPON", cp : "07800" },
{ code : "7199", intitule : "ROSIERES", cp : "07260" },
{ code : "7200", intitule : "LE ROUX", cp : "07560" },
{ code : "7201", intitule : "RUOMS", cp : "07120" },
{ code : "7202", intitule : "SABLIERES", cp : "07260" },
{ code : "7203", intitule : "SAGNES ET GOUDOULET", cp : "07450" },
{ code : "7204", intitule : "ST AGREVE", cp : "07320" },
{ code : "7204", intitule : "ST AGREVE", cp : "07320" },
{ code : "7205", intitule : "ST ALBAN D AY", cp : "07790" },
{ code : "7206", intitule : "ST ALBAN EN MONTAGNE", cp : "07590" },
{ code : "7207", intitule : "ST ALBAN AURIOLLES", cp : "07120" },
{ code : "7207", intitule : "ST ALBAN AURIOLLES", cp : "07120" },
{ code : "7208", intitule : "ST ANDEOL DE BERG", cp : "07170" },
{ code : "7209", intitule : "ST ANDEOL DE FOURCHADES", cp : "07160" },
{ code : "7210", intitule : "ST ANDEOL DE VALS", cp : "07600" },
{ code : "7211", intitule : "ST ANDRE DE CRUZIERES", cp : "07460" },
{ code : "7212", intitule : "ST ANDRE EN VIVARAIS", cp : "07690" },
{ code : "7213", intitule : "ST ANDRE LACHAMP", cp : "07230" },
{ code : "7214", intitule : "ST APOLLINAIRE DE RIAS", cp : "07240" },
{ code : "7215", intitule : "ST BARTHELEMY LE MEIL", cp : "07160" },
{ code : "7216", intitule : "ST BARTHELEMY GROZON", cp : "07270" },
{ code : "7217", intitule : "ST BARTHELEMY LE PLAIN", cp : "07300" },
{ code : "7218", intitule : "ST BASILE", cp : "07270" },
{ code : "7219", intitule : "ST BAUZILE", cp : "07210" },
{ code : "7220", intitule : "ST CHRISTOL", cp : "07160" },
{ code : "7221", intitule : "ST CIERGE LA SERRE", cp : "07800" },
{ code : "7222", intitule : "ST CIERGE SOUS LE CHEYLARD", cp : "07160" },
{ code : "7223", intitule : "ST CIRGUES DE PRADES", cp : "07380" },
{ code : "7224", intitule : "ST CIRGUES EN MONTAGNE", cp : "07510" },
{ code : "7225", intitule : "ST CLAIR", cp : "07430" },
{ code : "7226", intitule : "ST CLEMENT", cp : "07310" },
{ code : "7227", intitule : "ST CYR", cp : "07430" },
{ code : "7228", intitule : "ST DESIRAT", cp : "07340" },
{ code : "7229", intitule : "ST DIDIER SOUS AUBENAS", cp : "07200" },
{ code : "7230", intitule : "ST ETIENNE DE BOULOGNE", cp : "07200" },
{ code : "7231", intitule : "ST ETIENNE DE FONTBELLON", cp : "07200" },
{ code : "7232", intitule : "ST ETIENNE DE LUGDARES", cp : "07590" },
{ code : "7233", intitule : "ST ETIENNE DE SERRE", cp : "07190" },
{ code : "7234", intitule : "ST ETIENNE DE VALOUX", cp : "07340" },
{ code : "7235", intitule : "STE EULALIE", cp : "07510" },
{ code : "7236", intitule : "ST FELICIEN", cp : "07410" },
{ code : "7237", intitule : "ST FORTUNAT SUR EYRIEUX", cp : "07360" },
{ code : "7238", intitule : "ST GENEST DE BEAUZON", cp : "07230" },
{ code : "7239", intitule : "ST GENEST LACHAMP", cp : "07190" },
{ code : "7239", intitule : "ST GENEST LACHAMP", cp : "07160" },
{ code : "7240", intitule : "ST GEORGES LES BAINS", cp : "07800" },
{ code : "7241", intitule : "ST GERMAIN", cp : "07170" },
{ code : "7242", intitule : "ST GINEIS EN COIRON", cp : "07580" },
{ code : "7243", intitule : "ST JACQUES D ATTICIEUX", cp : "07340" },
{ code : "7244", intitule : "ST JEAN CHAMBRE", cp : "07240" },
{ code : "7245", intitule : "ST JEAN DE MUZOLS", cp : "07300" },
{ code : "7247", intitule : "ST JEAN LE CENTENIER", cp : "07580" },
{ code : "7248", intitule : "ST JEAN ROURE", cp : "07160" },
{ code : "7249", intitule : "ST JEURE D ANDAURE", cp : "07320" },
{ code : "7250", intitule : "ST JEURE D AY", cp : "07290" },
{ code : "7251", intitule : "ST JOSEPH DES BANCS", cp : "07530" },
{ code : "7252", intitule : "ST JULIEN BOUTIERES", cp : "07310" },
{ code : "7253", intitule : "ST JULIEN DU GUA", cp : "07190" },
{ code : "7254", intitule : "ST JULIEN DU SERRE", cp : "07200" },
{ code : "7255", intitule : "ST JULIEN EN ST ALBAN", cp : "07000" },
{ code : "7256", intitule : "ST JULIEN LABROUSSE", cp : "07160" },
{ code : "7257", intitule : "ST JULIEN LE ROUX", cp : "07240" },
{ code : "7258", intitule : "ST JULIEN VOCANCE", cp : "07690" },
{ code : "7259", intitule : "ST JUST D ARDECHE", cp : "07700" },
{ code : "7260", intitule : "ST LAGER BRESSAC", cp : "07210" },
{ code : "7261", intitule : "ST LAURENT DU PAPE", cp : "07800" },
{ code : "7262", intitule : "ST LAURENT LES BAINS", cp : "07590" },
{ code : "7263", intitule : "ST LAURENT SOUS COIRON", cp : "07170" },
{ code : "7264", intitule : "ST MARCEL D ARDECHE", cp : "07700" },
{ code : "7265", intitule : "ST MARCEL LES ANNONAY", cp : "07100" },
{ code : "7266", intitule : "STE MARGUERITE LAFIGERE", cp : "07140" },
{ code : "7267", intitule : "ST MARTIAL", cp : "07310" },
{ code : "7268", intitule : "ST MARTIN D ARDECHE", cp : "07700" },
{ code : "7269", intitule : "ST MARTIN DE VALAMAS", cp : "07310" },
{ code : "7270", intitule : "ST MARTIN SUR LAVEZON", cp : "07400" },
{ code : "7270", intitule : "ST MARTIN SUR LAVEZON", cp : "07400" },
{ code : "7272", intitule : "ST MAURICE D ARDECHE", cp : "07200" },
{ code : "7273", intitule : "ST MAURICE D IBIE", cp : "07170" },
{ code : "7274", intitule : "ST MAURICE EN CHALENCON", cp : "07190" },
{ code : "7275", intitule : "ST MELANY", cp : "07260" },
{ code : "7276", intitule : "ST MICHEL D AURANCE", cp : "07160" },
{ code : "7277", intitule : "ST MICHEL DE BOULOGNE", cp : "07200" },
{ code : "7278", intitule : "ST MICHEL DE CHABRILLANOUX", cp : "07360" },
{ code : "7279", intitule : "ST MONTAN", cp : "07220" },
{ code : "7280", intitule : "ST PAUL LE JEUNE", cp : "07460" },
{ code : "7281", intitule : "ST PERAY", cp : "07130" },
{ code : "7282", intitule : "ST PIERRE DE COLOMBIER", cp : "07450" },
{ code : "7283", intitule : "ST PIERRE LA ROCHE", cp : "07400" },
{ code : "7284", intitule : "ST PIERRE ST JEAN", cp : "07140" },
{ code : "7284", intitule : "ST PIERRE ST JEAN", cp : "07140" },
{ code : "7285", intitule : "ST PIERRE SUR DOUX", cp : "07520" },
{ code : "7286", intitule : "ST PIERREVILLE", cp : "07190" },
{ code : "7287", intitule : "ST PONS", cp : "07580" },
{ code : "7288", intitule : "ST PRIEST", cp : "07000" },
{ code : "7289", intitule : "ST PRIVAT", cp : "07200" },
{ code : "7290", intitule : "ST PRIX", cp : "07270" },
{ code : "7291", intitule : "ST REMEZE", cp : "07700" },
{ code : "7292", intitule : "ST ROMAIN D AY", cp : "07290" },
{ code : "7293", intitule : "ST ROMAIN DE LERPS", cp : "07130" },
{ code : "7294", intitule : "ST SAUVEUR DE CRUZIERES", cp : "07460" },
{ code : "7295", intitule : "ST SAUVEUR DE MONTAGUT", cp : "07190" },
{ code : "7296", intitule : "ST SERNIN", cp : "07200" },
{ code : "7297", intitule : "ST SYLVESTRE", cp : "07440" },
{ code : "7298", intitule : "ST SYMPHORIEN SOUS CHOMERAC", cp : "07210" },
{ code : "7299", intitule : "ST SYMPHORIEN DE MAHUN", cp : "07290" },
{ code : "7300", intitule : "ST THOME", cp : "07220" },
{ code : "7301", intitule : "ST VICTOR", cp : "07410" },
{ code : "7302", intitule : "ST VINCENT DE BARRES", cp : "07210" },
{ code : "7303", intitule : "ST VINCENT DE DURFORT", cp : "07360" },
{ code : "7304", intitule : "SALAVAS", cp : "07150" },
{ code : "7305", intitule : "LES SALELLES", cp : "07140" },
{ code : "7306", intitule : "SAMPZON", cp : "07120" },
{ code : "7307", intitule : "SANILHAC", cp : "07110" },
{ code : "7308", intitule : "SARRAS", cp : "07370" },
{ code : "7309", intitule : "SATILLIEU", cp : "07290" },
{ code : "7310", intitule : "SAVAS", cp : "07430" },
{ code : "7311", intitule : "SCEAUTRES", cp : "07400" },
{ code : "7312", intitule : "SECHERAS", cp : "07610" },
{ code : "7313", intitule : "SERRIERES", cp : "07340" },
{ code : "7314", intitule : "SILHAC", cp : "07240" },
{ code : "7315", intitule : "LA SOUCHE", cp : "07380" },
{ code : "7316", intitule : "SOYONS", cp : "07130" },
{ code : "7317", intitule : "TALENCIEUX", cp : "07340" },
{ code : "7318", intitule : "TAURIERS", cp : "07110" },
{ code : "7319", intitule : "LE TEIL", cp : "07400" },
{ code : "7321", intitule : "THORRENC", cp : "07340" },
{ code : "7322", intitule : "THUEYTS", cp : "07330" },
{ code : "7323", intitule : "TOULAUD", cp : "07130" },
{ code : "7324", intitule : "TOURNON SUR RHONE", cp : "07300" },
{ code : "7325", intitule : "UCEL", cp : "07200" },
{ code : "7326", intitule : "USCLADES ET RIEUTORD", cp : "07510" },
{ code : "7327", intitule : "UZER", cp : "07110" },
{ code : "7328", intitule : "VAGNAS", cp : "07150" },
{ code : "7329", intitule : "VALGORGE", cp : "07110" },
{ code : "7330", intitule : "VALLON PONT D ARC", cp : "07150" },
{ code : "7331", intitule : "VALS LES BAINS", cp : "07600" },
{ code : "7332", intitule : "VALVIGNERES", cp : "07400" },
{ code : "7333", intitule : "VANOSC", cp : "07690" },
{ code : "7334", intitule : "LES VANS", cp : "07140" },
{ code : "7334", intitule : "LES VANS", cp : "07140" },
{ code : "7334", intitule : "LES VANS", cp : "07140" },
{ code : "7334", intitule : "LES VANS", cp : "07140" },
{ code : "7335", intitule : "VAUDEVANT", cp : "07410" },
{ code : "7336", intitule : "VERNON", cp : "07260" },
{ code : "7337", intitule : "VERNOSC LES ANNONAY", cp : "07430" },
{ code : "7338", intitule : "VERNOUX EN VIVARAIS", cp : "07240" },
{ code : "7339", intitule : "VESSEAUX", cp : "07200" },
{ code : "7340", intitule : "VEYRAS", cp : "07000" },
{ code : "7341", intitule : "VILLENEUVE DE BERG", cp : "07170" },
{ code : "7342", intitule : "VILLEVOCANCE", cp : "07690" },
{ code : "7343", intitule : "VINEZAC", cp : "07110" },
{ code : "7344", intitule : "VINZIEUX", cp : "07340" },
{ code : "7345", intitule : "VION", cp : "07610" },
{ code : "7346", intitule : "VIVIERS", cp : "07220" },
{ code : "7347", intitule : "VOCANCE", cp : "07690" },
{ code : "7348", intitule : "VOGUE", cp : "07200" },
{ code : "7349", intitule : "LA VOULTE SUR RHONE", cp : "07800" },
{ code : "8001", intitule : "ACY ROMANCE", cp : "08300" },
{ code : "8003", intitule : "AIGLEMONT", cp : "08090" },
{ code : "8004", intitule : "AIRE", cp : "08190" },
{ code : "8005", intitule : "ALINCOURT", cp : "08310" },
{ code : "8006", intitule : "ALLAND HUY ET SAUSSEUIL", cp : "08130" },
{ code : "8008", intitule : "AMAGNE", cp : "08300" },
{ code : "8010", intitule : "AMBLY FLEURY", cp : "08130" },
{ code : "8011", intitule : "ANCHAMPS", cp : "08500" },
{ code : "8013", intitule : "ANGECOURT", cp : "08450" },
{ code : "8014", intitule : "ANNELLES", cp : "08310" },
{ code : "8015", intitule : "ANTHENY", cp : "08260" },
{ code : "8016", intitule : "AOUSTE", cp : "08290" },
{ code : "8017", intitule : "APREMONT", cp : "08250" },
{ code : "8018", intitule : "ARDEUIL ET MONTFAUXELLES", cp : "08400" },
{ code : "8019", intitule : "LES GRANDES ARMOISES", cp : "08390" },
{ code : "8020", intitule : "LES PETITES ARMOISES", cp : "08390" },
{ code : "8021", intitule : "ARNICOURT", cp : "08300" },
{ code : "8022", intitule : "ARREUX", cp : "08090" },
{ code : "8023", intitule : "ARTAISE LE VIVIER", cp : "08390" },
{ code : "8024", intitule : "ASFELD", cp : "08190" },
{ code : "8025", intitule : "ATTIGNY", cp : "08130" },
{ code : "8026", intitule : "AUBIGNY LES POTHEES", cp : "08150" },
{ code : "8027", intitule : "AUBONCOURT VAUZELLES", cp : "08270" },
{ code : "8028", intitule : "AUBRIVES", cp : "08320" },
{ code : "8029", intitule : "AUFLANCE", cp : "08370" },
{ code : "8030", intitule : "AUGE", cp : "08380" },
{ code : "8031", intitule : "AURE", cp : "08400" },
{ code : "8032", intitule : "AUSSONCE", cp : "08310" },
{ code : "8033", intitule : "AUTHE", cp : "08240" },
{ code : "8034", intitule : "AUTRECOURT ET POURRON", cp : "08210" },
{ code : "8035", intitule : "AUTRUCHE", cp : "08240" },
{ code : "8036", intitule : "AUTRY", cp : "08250" },
{ code : "8037", intitule : "AUVILLERS LES FORGES", cp : "08260" },
{ code : "8037", intitule : "AUVILLERS LES FORGES", cp : "08260" },
{ code : "8038", intitule : "AVANCON", cp : "08300" },
{ code : "8039", intitule : "AVAUX", cp : "08190" },
{ code : "8040", intitule : "LES AYVELLES", cp : "08000" },
{ code : "8041", intitule : "BAALONS", cp : "08430" },
{ code : "8042", intitule : "BALAIVES ET BUTZ", cp : "08160" },
{ code : "8043", intitule : "BALAN", cp : "08200" },
{ code : "8044", intitule : "BALHAM", cp : "08190" },
{ code : "8045", intitule : "BALLAY", cp : "08400" },
{ code : "8046", intitule : "BANOGNE RECOUVRANCE", cp : "08220" },
{ code : "8047", intitule : "BARBAISE", cp : "08430" },
{ code : "8048", intitule : "BARBY", cp : "08300" },
{ code : "8049", intitule : "BAR LES BUZANCY", cp : "08240" },
{ code : "8052", intitule : "BAYONVILLE", cp : "08240" },
{ code : "8053", intitule : "BAZEILLES", cp : "08140" },
{ code : "8055", intitule : "BEAUMONT EN ARGONNE", cp : "08210" },
{ code : "8056", intitule : "BEFFU ET LE MORTHOMME", cp : "08250" },
{ code : "8057", intitule : "BELLEVILLE ET CHATILLON SUR BAR", cp : "08240" },
{ code : "8057", intitule : "BELLEVILLE ET CHATILLON SUR BAR", cp : "08240" },
{ code : "8058", intitule : "BELVAL", cp : "08090" },
{ code : "8059", intitule : "BELVAL BOIS DES DAMES", cp : "08240" },
{ code : "8060", intitule : "BERGNICOURT", cp : "08300" },
{ code : "8061", intitule : "LA BERLIERE", cp : "08240" },
{ code : "8062", intitule : "BERTONCOURT", cp : "08300" },
{ code : "8063", intitule : "LA BESACE", cp : "08450" },
{ code : "8064", intitule : "BIERMES", cp : "08300" },
{ code : "8065", intitule : "BIEVRES", cp : "08370" },
{ code : "8066", intitule : "BIGNICOURT", cp : "08310" },
{ code : "8067", intitule : "BLAGNY", cp : "08110" },
{ code : "8069", intitule : "BLANCHEFOSSE ET BAY", cp : "08290" },
{ code : "8069", intitule : "BLANCHEFOSSE ET BAY", cp : "08290" },
{ code : "8070", intitule : "BLANZY LA SALONNAISE", cp : "08190" },
{ code : "8071", intitule : "BLOMBAY", cp : "08260" },
{ code : "8072", intitule : "BOSSEVAL ET BRIANCOURT", cp : "08350" },
{ code : "8073", intitule : "BOSSUS LES RUMIGNY", cp : "08290" },
{ code : "8074", intitule : "BOUCONVILLE", cp : "08250" },
{ code : "8075", intitule : "BOULT AUX BOIS", cp : "08240" },
{ code : "8076", intitule : "BOULZICOURT", cp : "08410" },
{ code : "8077", intitule : "BOURCQ", cp : "08400" },
{ code : "8078", intitule : "BOURG FIDELE", cp : "08230" },
{ code : "8079", intitule : "BOUTANCOURT", cp : "08160" },
{ code : "8080", intitule : "BOUVELLEMONT", cp : "08430" },
{ code : "8081", intitule : "BOGNY SUR MEUSE", cp : "08120" },
{ code : "8082", intitule : "BRECY BRIERES", cp : "08400" },
{ code : "8083", intitule : "BREVILLY", cp : "08140" },
{ code : "8084", intitule : "BRIENNE SUR AISNE", cp : "08190" },
{ code : "8085", intitule : "BRIEULLES SUR BAR", cp : "08240" },
{ code : "8086", intitule : "BRIQUENAY", cp : "08240" },
{ code : "8087", intitule : "BROGNON", cp : "08380" },
{ code : "8088", intitule : "BULSON", cp : "08450" },
{ code : "8089", intitule : "BUZANCY", cp : "08240" },
{ code : "8089", intitule : "BUZANCY", cp : "08240" },
{ code : "8090", intitule : "CARIGNAN", cp : "08110" },
{ code : "8090", intitule : "CARIGNAN", cp : "08110" },
{ code : "8092", intitule : "CAUROY", cp : "08310" },
{ code : "8094", intitule : "CERNION", cp : "08260" },
{ code : "8095", intitule : "CHAGNY", cp : "08430" },
{ code : "8096", intitule : "CHALANDRY ELAIRE", cp : "08160" },
{ code : "8097", intitule : "CHALLERANGE", cp : "08400" },
{ code : "8098", intitule : "CHAMPIGNEULLE", cp : "08250" },
{ code : "8099", intitule : "CHAMPIGNEUL SUR VENCE", cp : "08430" },
{ code : "8100", intitule : "CHAMPLIN", cp : "08260" },
{ code : "8101", intitule : "LA CHAPELLE", cp : "08200" },
{ code : "8102", intitule : "CHAPPES", cp : "08220" },
{ code : "8103", intitule : "CHARBOGNE", cp : "08130" },
{ code : "8104", intitule : "CHARDENY", cp : "08400" },
{ code : "8105", intitule : "CHARLEVILLE MEZIERES", cp : "08000" },
{ code : "8106", intitule : "CHARNOIS", cp : "08600" },
{ code : "8107", intitule : "CHATEAU PORCIEN", cp : "08360" },
{ code : "8109", intitule : "CHATEL CHEHERY", cp : "08250" },
{ code : "8110", intitule : "LE CHATELET SUR SORMONNE", cp : "08150" },
{ code : "8111", intitule : "LE CHATELET SUR RETOURNE", cp : "08300" },
{ code : "8113", intitule : "CHAUMONT PORCIEN", cp : "08220" },
{ code : "8113", intitule : "CHAUMONT PORCIEN", cp : "08220" },
{ code : "8113", intitule : "CHAUMONT PORCIEN", cp : "08220" },
{ code : "8115", intitule : "CHEMERY CHEHERY", cp : "08450" },
{ code : "8115", intitule : "CHEMERY CHEHERY", cp : "08450" },
{ code : "8115", intitule : "CHEMERY CHEHERY", cp : "08450" },
{ code : "8115", intitule : "CHEMERY CHEHERY", cp : "08350" },
{ code : "8116", intitule : "BAIRON ET SES ENVIRONS", cp : "08390" },
{ code : "8116", intitule : "BAIRON ET SES ENVIRONS", cp : "08400" },
{ code : "8116", intitule : "BAIRON ET SES ENVIRONS", cp : "08390" },
{ code : "8117", intitule : "CHESNOIS AUBONCOURT", cp : "08270" },
{ code : "8119", intitule : "CHEVEUGES", cp : "08350" },
{ code : "8120", intitule : "CHEVIERES", cp : "08250" },
{ code : "8121", intitule : "CHILLY", cp : "08260" },
{ code : "8122", intitule : "CHOOZ", cp : "08600" },
{ code : "8123", intitule : "CHUFFILLY ROCHE", cp : "08130" },
{ code : "8124", intitule : "CLAVY WARBY", cp : "08460" },
{ code : "8125", intitule : "CLIRON", cp : "08090" },
{ code : "8126", intitule : "CONDE LES HERPY", cp : "08360" },
{ code : "8128", intitule : "CONDE LES AUTRY", cp : "08250" },
{ code : "8130", intitule : "CONTREUVE", cp : "08400" },
{ code : "8131", intitule : "CORNAY", cp : "08250" },
{ code : "8132", intitule : "CORNY MACHEROMENIL", cp : "08270" },
{ code : "8133", intitule : "COUCY", cp : "08300" },
{ code : "8134", intitule : "COULOMMES ET MARQUENY", cp : "08130" },
{ code : "8135", intitule : "LA CROIX AUX BOIS", cp : "08400" },
{ code : "8136", intitule : "DAIGNY", cp : "08140" },
{ code : "8137", intitule : "DAMOUZY", cp : "08090" },
{ code : "8138", intitule : "LES DEUX VILLES", cp : "08110" },
{ code : "8139", intitule : "DEVILLE", cp : "08800" },
{ code : "8140", intitule : "DOM LE MESNIL", cp : "08160" },
{ code : "8140", intitule : "DOM LE MESNIL", cp : "08160" },
{ code : "8141", intitule : "DOMMERY", cp : "08460" },
{ code : "8142", intitule : "DONCHERY", cp : "08350" },
{ code : "8143", intitule : "DOUMELY BEGNY", cp : "08220" },
{ code : "8144", intitule : "DOUX", cp : "08300" },
{ code : "8145", intitule : "DOUZY", cp : "08140" },
{ code : "8145", intitule : "DOUZY", cp : "08140" },
{ code : "8146", intitule : "DRAIZE", cp : "08220" },
{ code : "8147", intitule : "DRICOURT", cp : "08310" },
{ code : "8148", intitule : "L ECAILLE", cp : "08300" },
{ code : "8149", intitule : "L ECHELLE", cp : "08150" },
{ code : "8150", intitule : "ECLY", cp : "08300" },
{ code : "8151", intitule : "ECORDAL", cp : "08130" },
{ code : "8152", intitule : "ELAN", cp : "08160" },
{ code : "8153", intitule : "ESCOMBRES ET LE CHESNOIS", cp : "08110" },
{ code : "8154", intitule : "ESTREBAY", cp : "08260" },
{ code : "8155", intitule : "ETALLE", cp : "08260" },
{ code : "8156", intitule : "ETEIGNIERES", cp : "08260" },
{ code : "8158", intitule : "ETREPIGNY", cp : "08160" },
{ code : "8159", intitule : "EUILLY ET LOMBUT", cp : "08210" },
{ code : "8160", intitule : "EVIGNY", cp : "08090" },
{ code : "8161", intitule : "EXERMONT", cp : "08250" },
{ code : "8162", intitule : "FAGNON", cp : "08090" },
{ code : "8163", intitule : "FAISSAULT", cp : "08270" },
{ code : "8164", intitule : "FALAISE", cp : "08400" },
{ code : "8165", intitule : "FAUX", cp : "08270" },
{ code : "8166", intitule : "FEPIN", cp : "08170" },
{ code : "8167", intitule : "LA FEREE", cp : "08290" },
{ code : "8168", intitule : "LA FERTE SUR CHIERS", cp : "08370" },
{ code : "8169", intitule : "FLAIGNES HAVYS", cp : "08260" },
{ code : "8169", intitule : "FLAIGNES HAVYS", cp : "08260" },
{ code : "8170", intitule : "FLEIGNEUX", cp : "08200" },
{ code : "8171", intitule : "FLEVILLE", cp : "08250" },
{ code : "8172", intitule : "FLIGNY", cp : "08380" },
{ code : "8173", intitule : "FLIZE", cp : "08160" },
{ code : "8174", intitule : "FLOING", cp : "08200" },
{ code : "8175", intitule : "FOISCHES", cp : "08600" },
{ code : "8176", intitule : "FOSSE", cp : "08240" },
{ code : "8178", intitule : "FRAILLICOURT", cp : "08220" },
{ code : "8179", intitule : "FRANCHEVAL", cp : "08140" },
{ code : "8180", intitule : "LA FRANCHEVILLE", cp : "08000" },
{ code : "8182", intitule : "LE FRETY", cp : "08290" },
{ code : "8183", intitule : "FROMELENNES", cp : "08600" },
{ code : "8183", intitule : "FROMELENNES", cp : "08600" },
{ code : "8184", intitule : "FROMY", cp : "08370" },
{ code : "8185", intitule : "FUMAY", cp : "08170" },
{ code : "8186", intitule : "GERMONT", cp : "08240" },
{ code : "8187", intitule : "GERNELLE", cp : "08440" },
{ code : "8188", intitule : "GESPUNSART", cp : "08700" },
{ code : "8189", intitule : "GIRONDELLE", cp : "08260" },
{ code : "8189", intitule : "GIRONDELLE", cp : "08260" },
{ code : "8190", intitule : "GIVET", cp : "08600" },
{ code : "8191", intitule : "GIVONNE", cp : "08200" },
{ code : "8192", intitule : "GIVRON", cp : "08220" },
{ code : "8193", intitule : "GIVRY", cp : "08130" },
{ code : "8194", intitule : "GLAIRE", cp : "08200" },
{ code : "8195", intitule : "GOMONT", cp : "08190" },
{ code : "8196", intitule : "GRANDCHAMP", cp : "08270" },
{ code : "8197", intitule : "GRANDHAM", cp : "08250" },
{ code : "8198", intitule : "GRANDPRE", cp : "08250" },
{ code : "8198", intitule : "GRANDPRE", cp : "08250" },
{ code : "8199", intitule : "LA GRANDVILLE", cp : "08700" },
{ code : "8200", intitule : "GRIVY LOISY", cp : "08400" },
{ code : "8201", intitule : "GRUYERES", cp : "08430" },
{ code : "8202", intitule : "GUE D HOSSUS", cp : "08230" },
{ code : "8203", intitule : "GUIGNICOURT SUR VENCE", cp : "08430" },
{ code : "8204", intitule : "GUINCOURT", cp : "08130" },
{ code : "8205", intitule : "HAGNICOURT", cp : "08430" },
{ code : "8206", intitule : "HAM LES MOINES", cp : "08090" },
{ code : "8207", intitule : "HAM SUR MEUSE", cp : "08600" },
{ code : "8208", intitule : "HANNAPPES", cp : "08290" },
{ code : "8209", intitule : "HANNOGNE ST MARTIN", cp : "08160" },
{ code : "8210", intitule : "HANNOGNE ST REMY", cp : "08220" },
{ code : "8211", intitule : "HARAUCOURT", cp : "08450" },
{ code : "8212", intitule : "HARCY", cp : "08150" },
{ code : "8214", intitule : "HARGNIES", cp : "08170" },
{ code : "8215", intitule : "HARRICOURT", cp : "08240" },
{ code : "8216", intitule : "HAUDRECY", cp : "08090" },
{ code : "8217", intitule : "HAULME", cp : "08800" },
{ code : "8218", intitule : "LES HAUTES RIVIERES", cp : "08800" },
{ code : "8218", intitule : "LES HAUTES RIVIERES", cp : "08800" },
{ code : "8219", intitule : "HAUTEVILLE", cp : "08300" },
{ code : "8220", intitule : "HAUVINE", cp : "08310" },
{ code : "8222", intitule : "HAYBES", cp : "08170" },
{ code : "8223", intitule : "HERBEUVAL", cp : "08370" },
{ code : "8225", intitule : "HERPY L ARLESIENNE", cp : "08360" },
{ code : "8226", intitule : "HIERGES", cp : "08320" },
{ code : "8228", intitule : "LA HORGNE", cp : "08430" },
{ code : "8229", intitule : "HOUDILCOURT", cp : "08190" },
{ code : "8230", intitule : "HOULDIZY", cp : "08090" },
{ code : "8232", intitule : "ILLY", cp : "08200" },
{ code : "8233", intitule : "IMECOURT", cp : "08240" },
{ code : "8234", intitule : "INAUMONT", cp : "08300" },
{ code : "8235", intitule : "ISSANCOURT ET RUMEL", cp : "08440" },
{ code : "8236", intitule : "JANDUN", cp : "08430" },
{ code : "8237", intitule : "JOIGNY SUR MEUSE", cp : "08700" },
{ code : "8238", intitule : "JONVAL", cp : "08130" },
{ code : "8239", intitule : "JUNIVILLE", cp : "08310" },
{ code : "8240", intitule : "JUSTINE HERBIGNY", cp : "08270" },
{ code : "8242", intitule : "LAIFOUR", cp : "08800" },
{ code : "8242", intitule : "LAIFOUR", cp : "08800" },
{ code : "8243", intitule : "LALOBBE", cp : "08460" },
{ code : "8244", intitule : "LAMETZ", cp : "08130" },
{ code : "8245", intitule : "LANCON", cp : "08250" },
{ code : "8246", intitule : "LANDRES ET ST GEORGES", cp : "08240" },
{ code : "8247", intitule : "LANDRICHAMPS", cp : "08600" },
{ code : "8248", intitule : "LAUNOIS SUR VENCE", cp : "08430" },
{ code : "8249", intitule : "LAVAL MORENCY", cp : "08150" },
{ code : "8250", intitule : "LEFFINCOURT", cp : "08310" },
{ code : "8251", intitule : "LEPRON LES VALLEES", cp : "08150" },
{ code : "8252", intitule : "LETANNE", cp : "08210" },
{ code : "8254", intitule : "LIART", cp : "08290" },
{ code : "8255", intitule : "LINAY", cp : "08110" },
{ code : "8256", intitule : "LIRY", cp : "08400" },
{ code : "8257", intitule : "LOGNY BOGNY", cp : "08150" },
{ code : "8259", intitule : "LONGWE", cp : "08400" },
{ code : "8260", intitule : "LONNY", cp : "08150" },
{ code : "8262", intitule : "LUCQUY", cp : "08300" },
{ code : "8263", intitule : "LUMES", cp : "08440" },
{ code : "8264", intitule : "MACHAULT", cp : "08310" },
{ code : "8268", intitule : "MAISONCELLE ET VILLERS", cp : "08450" },
{ code : "8269", intitule : "MALANDRY", cp : "08370" },
{ code : "8271", intitule : "MANRE", cp : "08400" },
{ code : "8272", intitule : "MARANWEZ", cp : "08460" },
{ code : "8273", intitule : "MARBY", cp : "08260" },
{ code : "8274", intitule : "MARCQ", cp : "08250" },
{ code : "8275", intitule : "MARGNY", cp : "08370" },
{ code : "8276", intitule : "MARGUT", cp : "08370" },
{ code : "8277", intitule : "MARLEMONT", cp : "08290" },
{ code : "8278", intitule : "MARQUIGNY", cp : "08390" },
{ code : "8279", intitule : "MARS SOUS BOURCQ", cp : "08400" },
{ code : "8280", intitule : "MARVAUX VIEUX", cp : "08400" },
{ code : "8281", intitule : "MATTON ET CLEMENCY", cp : "08110" },
{ code : "8282", intitule : "MAUBERT FONTAINE", cp : "08260" },
{ code : "8283", intitule : "MAZERNY", cp : "08430" },
{ code : "8284", intitule : "LES MAZURES", cp : "08500" },
{ code : "8286", intitule : "MENIL ANNELLES", cp : "08310" },
{ code : "8287", intitule : "MENIL LEPINOIS", cp : "08310" },
{ code : "8288", intitule : "MESMONT", cp : "08270" },
{ code : "8289", intitule : "MESSINCOURT", cp : "08110" },
{ code : "8291", intitule : "MOGUES", cp : "08110" },
{ code : "8293", intitule : "MOIRY", cp : "08370" },
{ code : "8294", intitule : "LA MONCELLE", cp : "08140" },
{ code : "8295", intitule : "MONDIGNY", cp : "08430" },
{ code : "8296", intitule : "MONTCHEUTIN", cp : "08250" },
{ code : "8297", intitule : "MONTCORNET", cp : "08090" },
{ code : "8298", intitule : "MONTCY NOTRE DAME", cp : "08090" },
{ code : "8300", intitule : "LE MONT DIEU", cp : "08390" },
{ code : "8301", intitule : "MONTGON", cp : "08390" },
{ code : "8302", intitule : "MONTHERME", cp : "08800" },
{ code : "8302", intitule : "MONTHERME", cp : "08800" },
{ code : "8302", intitule : "MONTHERME", cp : "08800" },
{ code : "8303", intitule : "MONTHOIS", cp : "08400" },
{ code : "8304", intitule : "MONTIGNY SUR MEUSE", cp : "08170" },
{ code : "8305", intitule : "MONTIGNY SUR VENCE", cp : "08430" },
{ code : "8306", intitule : "MONT LAURENT", cp : "08130" },
{ code : "8307", intitule : "MONTMEILLANT", cp : "08220" },
{ code : "8308", intitule : "MONT ST MARTIN", cp : "08400" },
{ code : "8309", intitule : "MONT ST REMY", cp : "08310" },
{ code : "8310", intitule : "MOURON", cp : "08250" },
{ code : "8311", intitule : "MOUZON", cp : "08210" },
{ code : "8311", intitule : "MOUZON", cp : "08210" },
{ code : "8312", intitule : "MURTIN ET BOGNY", cp : "08150" },
{ code : "8313", intitule : "NANTEUIL SUR AISNE", cp : "08300" },
{ code : "8314", intitule : "NEUFLIZE", cp : "08300" },
{ code : "8315", intitule : "NEUFMAISON", cp : "08460" },
{ code : "8316", intitule : "NEUFMANIL", cp : "08700" },
{ code : "8317", intitule : "LA NEUVILLE A MAIRE", cp : "08450" },
{ code : "8318", intitule : "LA NEUVILLE AUX JOUTES", cp : "08380" },
{ code : "8319", intitule : "NEUVILLE LEZ BEAULIEU", cp : "08380" },
{ code : "8319", intitule : "NEUVILLE LEZ BEAULIEU", cp : "08380" },
{ code : "8320", intitule : "LA NEUVILLE EN TOURNE A FUY", cp : "08310" },
{ code : "8321", intitule : "NEUVILLE DAY", cp : "08130" },
{ code : "8322", intitule : "NEUVILLE LES THIS", cp : "08090" },
{ code : "8323", intitule : "LA NEUVILLE LES WASIGNY", cp : "08270" },
{ code : "8324", intitule : "NEUVIZY", cp : "08430" },
{ code : "8325", intitule : "NOIRVAL", cp : "08400" },
{ code : "8326", intitule : "NOUART", cp : "08240" },
{ code : "8326", intitule : "NOUART", cp : "08240" },
{ code : "8327", intitule : "NOUVION SUR MEUSE", cp : "08160" },
{ code : "8328", intitule : "NOUZONVILLE", cp : "08700" },
{ code : "8328", intitule : "NOUZONVILLE", cp : "08700" },
{ code : "8329", intitule : "NOVION PORCIEN", cp : "08270" },
{ code : "8329", intitule : "NOVION PORCIEN", cp : "08270" },
{ code : "8330", intitule : "NOVY CHEVRIERES", cp : "08300" },
{ code : "8331", intitule : "NOYERS PONT MAUGIS", cp : "08350" },
{ code : "8332", intitule : "OCHES", cp : "08240" },
{ code : "8333", intitule : "OLIZY PRIMAT", cp : "08250" },
{ code : "8333", intitule : "OLIZY PRIMAT", cp : "08250" },
{ code : "8334", intitule : "OMICOURT", cp : "08450" },
{ code : "8335", intitule : "OMONT", cp : "08430" },
{ code : "8336", intitule : "OSNES", cp : "08110" },
{ code : "8338", intitule : "PAUVRES", cp : "08310" },
{ code : "8339", intitule : "PERTHES", cp : "08300" },
{ code : "8340", intitule : "POILCOURT SYDNEY", cp : "08190" },
{ code : "8341", intitule : "POIX TERRON", cp : "08430" },
{ code : "8342", intitule : "POURU AUX BOIS", cp : "08140" },
{ code : "8343", intitule : "POURU ST REMY", cp : "08140" },
{ code : "8344", intitule : "PREZ", cp : "08290" },
{ code : "8344", intitule : "PREZ", cp : "08290" },
{ code : "8346", intitule : "PRIX LES MEZIERES", cp : "08000" },
{ code : "8347", intitule : "PUILLY ET CHARBEAUX", cp : "08370" },
{ code : "8348", intitule : "PUISEUX", cp : "08270" },
{ code : "8349", intitule : "PURE", cp : "08110" },
{ code : "8350", intitule : "QUATRE CHAMPS", cp : "08400" },
{ code : "8351", intitule : "QUILLY", cp : "08400" },
{ code : "8352", intitule : "RAILLICOURT", cp : "08430" },
{ code : "8353", intitule : "RANCENNES", cp : "08600" },
{ code : "8354", intitule : "RAUCOURT ET FLABA", cp : "08450" },
{ code : "8355", intitule : "REGNIOWEZ", cp : "08230" },
{ code : "8356", intitule : "REMAUCOURT", cp : "08220" },
{ code : "8357", intitule : "REMILLY AILLICOURT", cp : "08450" },
{ code : "8358", intitule : "REMILLY LES POTHEES", cp : "08150" },
{ code : "8360", intitule : "RENNEVILLE", cp : "08220" },
{ code : "8361", intitule : "RENWEZ", cp : "08150" },
{ code : "8362", intitule : "RETHEL", cp : "08300" },
{ code : "8362", intitule : "RETHEL", cp : "08300" },
{ code : "8363", intitule : "REVIN", cp : "08500" },
{ code : "8364", intitule : "RILLY SUR AISNE", cp : "08130" },
{ code : "8365", intitule : "RIMOGNE", cp : "08150" },
{ code : "8366", intitule : "ROCQUIGNY", cp : "08220" },
{ code : "8366", intitule : "ROCQUIGNY", cp : "08220" },
{ code : "8366", intitule : "ROCQUIGNY", cp : "08220" },
{ code : "8366", intitule : "ROCQUIGNY", cp : "08220" },
{ code : "8367", intitule : "ROCROI", cp : "08230" },
{ code : "8367", intitule : "ROCROI", cp : "08230" },
{ code : "8367", intitule : "ROCROI", cp : "08230" },
{ code : "8368", intitule : "ROIZY", cp : "08190" },
{ code : "8369", intitule : "LA ROMAGNE", cp : "08220" },
{ code : "8370", intitule : "ROUVROY SUR AUDRY", cp : "08150" },
{ code : "8370", intitule : "ROUVROY SUR AUDRY", cp : "08150" },
{ code : "8371", intitule : "RUBECOURT ET LAMECOURT", cp : "08140" },
{ code : "8372", intitule : "RUBIGNY", cp : "08220" },
{ code : "8373", intitule : "RUMIGNY", cp : "08290" },
{ code : "8374", intitule : "LA SABOTTERIE", cp : "08130" },
{ code : "8375", intitule : "SACHY", cp : "08110" },
{ code : "8376", intitule : "SAILLY", cp : "08110" },
{ code : "8377", intitule : "ST AIGNAN", cp : "08350" },
{ code : "8378", intitule : "ST CLEMENT A ARNES", cp : "08310" },
{ code : "8379", intitule : "ST ETIENNE A ARNES", cp : "08310" },
{ code : "8380", intitule : "ST FERGEUX", cp : "08360" },
{ code : "8380", intitule : "ST FERGEUX", cp : "08360" },
{ code : "8381", intitule : "ST GERMAINMONT", cp : "08190" },
{ code : "8382", intitule : "ST JEAN AUX BOIS", cp : "08220" },
{ code : "8383", intitule : "ST JUVIN", cp : "08250" },
{ code : "8384", intitule : "ST LAMBERT ET MONT DE JEUX", cp : "08130" },
{ code : "8385", intitule : "ST LAURENT", cp : "08090" },
{ code : "8386", intitule : "ST LOUP EN CHAMPAGNE", cp : "08300" },
{ code : "8387", intitule : "ST LOUP TERRIER", cp : "08130" },
{ code : "8388", intitule : "ST MARCEAU", cp : "08160" },
{ code : "8389", intitule : "ST MARCEL", cp : "08460" },
{ code : "8390", intitule : "STE MARIE", cp : "08400" },
{ code : "8391", intitule : "ST MENGES", cp : "08200" },
{ code : "8392", intitule : "ST MOREL", cp : "08400" },
{ code : "8393", intitule : "ST PIERRE A ARNES", cp : "08310" },
{ code : "8394", intitule : "ST PIERREMONT", cp : "08240" },
{ code : "8395", intitule : "ST PIERRE SUR VENCE", cp : "08430" },
{ code : "8396", intitule : "ST QUENTIN LE PETIT", cp : "08220" },
{ code : "8397", intitule : "ST REMY LE PETIT", cp : "08300" },
{ code : "8398", intitule : "STE VAUBOURG", cp : "08130" },
{ code : "8399", intitule : "SAPOGNE SUR MARCHE", cp : "08370" },
{ code : "8400", intitule : "SAPOGNE ET FEUCHERES", cp : "08160" },
{ code : "8401", intitule : "SAULCES CHAMPENOISES", cp : "08130" },
{ code : "8402", intitule : "SAULCES MONCLIN", cp : "08270" },
{ code : "8403", intitule : "SAULT LES RETHEL", cp : "08300" },
{ code : "8404", intitule : "SAULT ST REMY", cp : "08190" },
{ code : "8405", intitule : "SAUVILLE", cp : "08390" },
{ code : "8406", intitule : "SAVIGNY SUR AISNE", cp : "08400" },
{ code : "8407", intitule : "SECHAULT", cp : "08250" },
{ code : "8408", intitule : "SECHEVAL", cp : "08150" },
{ code : "8409", intitule : "SEDAN", cp : "08200" },
{ code : "8410", intitule : "SEMIDE", cp : "08400" },
{ code : "8411", intitule : "SEMUY", cp : "08130" },
{ code : "8412", intitule : "SENUC", cp : "08250" },
{ code : "8413", intitule : "SERAINCOURT", cp : "08220" },
{ code : "8413", intitule : "SERAINCOURT", cp : "08220" },
{ code : "8415", intitule : "SERY", cp : "08270" },
{ code : "8416", intitule : "SEUIL", cp : "08300" },
{ code : "8417", intitule : "SEVIGNY LA FORET", cp : "08230" },
{ code : "8418", intitule : "SEVIGNY WALEPPE", cp : "08220" },
{ code : "8419", intitule : "SIGNY L ABBAYE", cp : "08460" },
{ code : "8419", intitule : "SIGNY L ABBAYE", cp : "08460" },
{ code : "8420", intitule : "SIGNY LE PETIT", cp : "08380" },
{ code : "8420", intitule : "SIGNY LE PETIT", cp : "08380" },
{ code : "8421", intitule : "SIGNY MONTLIBERT", cp : "08370" },
{ code : "8422", intitule : "SINGLY", cp : "08430" },
{ code : "8424", intitule : "SOMMAUTHE", cp : "08240" },
{ code : "8425", intitule : "SOMMERANCE", cp : "08250" },
{ code : "8426", intitule : "SON", cp : "08300" },
{ code : "8427", intitule : "SORBON", cp : "08300" },
{ code : "8428", intitule : "SORCY BAUTHEMONT", cp : "08270" },
{ code : "8429", intitule : "SORMONNE", cp : "08150" },
{ code : "8430", intitule : "STONNE", cp : "08390" },
{ code : "8431", intitule : "SUGNY", cp : "08400" },
{ code : "8432", intitule : "SURY", cp : "08090" },
{ code : "8433", intitule : "SUZANNE", cp : "08130" },
{ code : "8434", intitule : "SY", cp : "08390" },
{ code : "8435", intitule : "TAGNON", cp : "08300" },
{ code : "8436", intitule : "TAILLETTE", cp : "08230" },
{ code : "8437", intitule : "TAILLY", cp : "08240" },
{ code : "8437", intitule : "TAILLY", cp : "08240" },
{ code : "8437", intitule : "TAILLY", cp : "08240" },
{ code : "8437", intitule : "TAILLY", cp : "08240" },
{ code : "8438", intitule : "TAIZY", cp : "08360" },
{ code : "8439", intitule : "TANNAY", cp : "08390" },
{ code : "8440", intitule : "TARZY", cp : "08380" },
{ code : "8443", intitule : "TERRON SUR AISNE", cp : "08400" },
{ code : "8444", intitule : "TETAIGNE", cp : "08110" },
{ code : "8445", intitule : "THELONNE", cp : "08350" },
{ code : "8446", intitule : "THENORGUES", cp : "08240" },
{ code : "8448", intitule : "THILAY", cp : "08800" },
{ code : "8448", intitule : "THILAY", cp : "08800" },
{ code : "8449", intitule : "THIN LE MOUTIER", cp : "08460" },
{ code : "8450", intitule : "THIS", cp : "08090" },
{ code : "8451", intitule : "LE THOUR", cp : "08190" },
{ code : "8452", intitule : "THUGNY TRUGNY", cp : "08300" },
{ code : "8453", intitule : "TOGES", cp : "08400" },
{ code : "8454", intitule : "TOULIGNY", cp : "08430" },
{ code : "8455", intitule : "TOURCELLES CHAUMONT", cp : "08400" },
{ code : "8456", intitule : "TOURNAVAUX", cp : "08800" },
{ code : "8457", intitule : "TOURNES", cp : "08090" },
{ code : "8458", intitule : "TOURTERON", cp : "08130" },
{ code : "8459", intitule : "TREMBLOIS LES CARIGNAN", cp : "08110" },
{ code : "8460", intitule : "TREMBLOIS LES ROCROI", cp : "08150" },
{ code : "8461", intitule : "VANDY", cp : "08400" },
{ code : "8462", intitule : "VAUX CHAMPAGNE", cp : "08130" },
{ code : "8463", intitule : "VAUX EN DIEULET", cp : "08240" },
{ code : "8464", intitule : "VAUX LES MOURON", cp : "08250" },
{ code : "8465", intitule : "VAUX LES RUBIGNY", cp : "08220" },
{ code : "8466", intitule : "VAUX LES MOUZON", cp : "08210" },
{ code : "8467", intitule : "VAUX MONTREUIL", cp : "08270" },
{ code : "8468", intitule : "VAUX VILLAINE", cp : "08150" },
{ code : "8469", intitule : "VENDRESSE", cp : "08160" },
{ code : "8469", intitule : "VENDRESSE", cp : "08160" },
{ code : "8470", intitule : "VERPEL", cp : "08240" },
{ code : "8471", intitule : "VERRIERES", cp : "08390" },
{ code : "8472", intitule : "VIEL ST REMY", cp : "08270" },
{ code : "8472", intitule : "VIEL ST REMY", cp : "08270" },
{ code : "8473", intitule : "VIEUX LES ASFELD", cp : "08190" },
{ code : "8475", intitule : "VILLERS CERNAY", cp : "08140" },
{ code : "8476", intitule : "VILLERS DEVANT LE THOUR", cp : "08190" },
{ code : "8477", intitule : "VILLERS DEVANT MOUZON", cp : "08210" },
{ code : "8478", intitule : "VILLERS LE TILLEUL", cp : "08430" },
{ code : "8479", intitule : "VILLERS LE TOURNEUR", cp : "08430" },
{ code : "8480", intitule : "VILLERS SEMEUSE", cp : "08000" },
{ code : "8481", intitule : "VILLERS SUR BAR", cp : "08350" },
{ code : "8482", intitule : "VILLERS SUR LE MONT", cp : "08430" },
{ code : "8483", intitule : "VILLE SUR LUMES", cp : "08440" },
{ code : "8484", intitule : "VILLE SUR RETOURNE", cp : "08310" },
{ code : "8485", intitule : "VILLY", cp : "08370" },
{ code : "8486", intitule : "VIREUX MOLHAIN", cp : "08320" },
{ code : "8487", intitule : "VIREUX WALLERAND", cp : "08320" },
{ code : "8488", intitule : "VIVIER AU COURT", cp : "08440" },
{ code : "8489", intitule : "VONCQ", cp : "08400" },
{ code : "8490", intitule : "VOUZIERS", cp : "08400" },
{ code : "8490", intitule : "VOUZIERS", cp : "08400" },
{ code : "8491", intitule : "VRIGNE AUX BOIS", cp : "08330" },
{ code : "8492", intitule : "VRIGNE MEUSE", cp : "08350" },
{ code : "8493", intitule : "VRIZY", cp : "08400" },
{ code : "8494", intitule : "WADELINCOURT", cp : "08200" },
{ code : "8496", intitule : "WAGNON", cp : "08270" },
{ code : "8497", intitule : "WARCQ", cp : "08000" },
{ code : "8498", intitule : "WARNECOURT", cp : "08090" },
{ code : "8499", intitule : "WASIGNY", cp : "08270" },
{ code : "8500", intitule : "WIGNICOURT", cp : "08270" },
{ code : "8501", intitule : "WILLIERS", cp : "08110" },
{ code : "8502", intitule : "YONCQ", cp : "08210" },
{ code : "8503", intitule : "YVERNAUMONT", cp : "08430" },
{ code : "9001", intitule : "AIGUES JUNTES", cp : "09240" },
{ code : "9002", intitule : "AIGUES VIVES", cp : "09600" },
{ code : "9003", intitule : "L AIGUILLON", cp : "09300" },
{ code : "9004", intitule : "ALBIES", cp : "09310" },
{ code : "9005", intitule : "ALEU", cp : "09320" },
{ code : "9006", intitule : "ALLIAT", cp : "09400" },
{ code : "9007", intitule : "ALLIERES", cp : "09240" },
{ code : "9008", intitule : "ALOS", cp : "09200" },
{ code : "9009", intitule : "ALZEN", cp : "09240" },
{ code : "9011", intitule : "ANTRAS", cp : "09800" },
{ code : "9012", intitule : "APPY", cp : "09250" },
{ code : "9013", intitule : "ARABAUX", cp : "09000" },
{ code : "9014", intitule : "ARGEIN", cp : "09800" },
{ code : "9015", intitule : "ARIGNAC", cp : "09400" },
{ code : "9016", intitule : "ARNAVE", cp : "09400" },
{ code : "9017", intitule : "ARRIEN EN BETHMALE", cp : "09800" },
{ code : "9018", intitule : "ARROUT", cp : "09800" },
{ code : "9019", intitule : "ARTIGAT", cp : "09130" },
{ code : "9020", intitule : "ARTIGUES", cp : "09460" },
{ code : "9021", intitule : "ARTIX", cp : "09120" },
{ code : "9022", intitule : "ARVIGNA", cp : "09100" },
{ code : "9023", intitule : "ASCOU", cp : "09110" },
{ code : "9024", intitule : "ASTON", cp : "09310" },
{ code : "9025", intitule : "AUCAZEIN", cp : "09800" },
{ code : "9026", intitule : "AUDRESSEIN", cp : "09800" },
{ code : "9027", intitule : "AUGIREIN", cp : "09800" },
{ code : "9028", intitule : "AULOS", cp : "09310" },
{ code : "9029", intitule : "AULUS LES BAINS", cp : "09140" },
{ code : "9030", intitule : "AUZAT", cp : "09220" },
{ code : "9031", intitule : "AXIAT", cp : "09250" },
{ code : "9032", intitule : "AX LES THERMES", cp : "09110" },
{ code : "9033", intitule : "BAGERT", cp : "09230" },
{ code : "9034", intitule : "BALACET", cp : "09800" },
{ code : "9035", intitule : "BALAGUERES", cp : "09800" },
{ code : "9035", intitule : "BALAGUERES", cp : "09800" },
{ code : "9037", intitule : "BARJAC", cp : "09230" },
{ code : "9038", intitule : "LA BASTIDE DE BESPLAS", cp : "09350" },
{ code : "9039", intitule : "LA BASTIDE DE BOUSIGNAC", cp : "09500" },
{ code : "9040", intitule : "LA BASTIDE DE LORDAT", cp : "09700" },
{ code : "9041", intitule : "LA BASTIDE DU SALAT", cp : "09160" },
{ code : "9042", intitule : "LA BASTIDE DE SEROU", cp : "09240" },
{ code : "9043", intitule : "LA BASTIDE SUR L HERS", cp : "09600" },
{ code : "9044", intitule : "BAULOU", cp : "09000" },
{ code : "9045", intitule : "BEDEILHAC ET AYNAT", cp : "09400" },
{ code : "9046", intitule : "BEDEILLE", cp : "09230" },
{ code : "9047", intitule : "BELESTA", cp : "09300" },
{ code : "9048", intitule : "BELLOC", cp : "09600" },
{ code : "9049", intitule : "BENAC", cp : "09000" },
{ code : "9050", intitule : "BENAGUES", cp : "09100" },
{ code : "9051", intitule : "BENAIX", cp : "09300" },
{ code : "9052", intitule : "BESSET", cp : "09500" },
{ code : "9053", intitule : "BESTIAC", cp : "09250" },
{ code : "9054", intitule : "BETCHAT", cp : "09160" },
{ code : "9055", intitule : "BETHMALE", cp : "09800" },
{ code : "9056", intitule : "BEZAC", cp : "09100" },
{ code : "9057", intitule : "BIERT", cp : "09320" },
{ code : "9058", intitule : "BOMPAS", cp : "09400" },
{ code : "9059", intitule : "BONAC IRAZEIN", cp : "09800" },
{ code : "9060", intitule : "BONNAC", cp : "09100" },
{ code : "9061", intitule : "LES BORDES SUR ARIZE", cp : "09350" },
{ code : "9062", intitule : "LES BORDES SUR LEZ", cp : "09800" },
{ code : "9063", intitule : "LE BOSC", cp : "09000" },
{ code : "9064", intitule : "BOUAN", cp : "09310" },
{ code : "9065", intitule : "BOUSSENAC", cp : "09320" },
{ code : "9066", intitule : "BRASSAC", cp : "09000" },
{ code : "9067", intitule : "BRIE", cp : "09700" },
{ code : "9068", intitule : "BURRET", cp : "09000" },
{ code : "9069", intitule : "BUZAN", cp : "09800" },
{ code : "9070", intitule : "LES CABANNES", cp : "09310" },
{ code : "9071", intitule : "CADARCET", cp : "09240" },
{ code : "9072", intitule : "CALZAN", cp : "09120" },
{ code : "9073", intitule : "CAMARADE", cp : "09290" },
{ code : "9074", intitule : "CAMON", cp : "09500" },
{ code : "9075", intitule : "CAMPAGNE SUR ARIZE", cp : "09350" },
{ code : "9076", intitule : "CANTE", cp : "09700" },
{ code : "9077", intitule : "CAPOULET ET JUNAC", cp : "09400" },
{ code : "9078", intitule : "CARCANIERES", cp : "09460" },
{ code : "9079", intitule : "CARLA BAYLE", cp : "09130" },
{ code : "9080", intitule : "CARLA DE ROQUEFORT", cp : "09300" },
{ code : "9081", intitule : "LE CARLARET", cp : "09100" },
{ code : "9082", intitule : "CASTELNAU DURBAN", cp : "09420" },
{ code : "9083", intitule : "CASTERAS", cp : "09130" },
{ code : "9084", intitule : "CASTEX", cp : "09350" },
{ code : "9085", intitule : "CASTILLON EN COUSERANS", cp : "09800" },
{ code : "9086", intitule : "CAUMONT", cp : "09160" },
{ code : "9087", intitule : "CAUSSOU", cp : "09250" },
{ code : "9088", intitule : "CAYCHAX", cp : "09250" },
{ code : "9089", intitule : "CAZALS DES BAYLES", cp : "09500" },
{ code : "9090", intitule : "CAZAUX", cp : "09120" },
{ code : "9091", intitule : "CAZAVET", cp : "09160" },
{ code : "9092", intitule : "CAZENAVE SERRES ET ALLENS", cp : "09400" },
{ code : "9093", intitule : "CELLES", cp : "09000" },
{ code : "9094", intitule : "CERIZOLS", cp : "09230" },
{ code : "9095", intitule : "CESCAU", cp : "09800" },
{ code : "9096", intitule : "CHATEAU VERDUN", cp : "09310" },
{ code : "9097", intitule : "CLERMONT", cp : "09420" },
{ code : "9098", intitule : "CONTRAZY", cp : "09230" },
{ code : "9099", intitule : "COS", cp : "09000" },
{ code : "9100", intitule : "COUFLENS", cp : "09140" },
{ code : "9100", intitule : "COUFLENS", cp : "09140" },
{ code : "9101", intitule : "COUSSA", cp : "09120" },
{ code : "9102", intitule : "COUTENS", cp : "09500" },
{ code : "9103", intitule : "CRAMPAGNA", cp : "09120" },
{ code : "9104", intitule : "DALOU", cp : "09120" },
{ code : "9105", intitule : "DAUMAZAN SUR ARIZE", cp : "09350" },
{ code : "9106", intitule : "DREUILHE", cp : "09300" },
{ code : "9107", intitule : "DUN", cp : "09600" },
{ code : "9107", intitule : "DUN", cp : "09600" },
{ code : "9107", intitule : "DUN", cp : "09600" },
{ code : "9107", intitule : "DUN", cp : "09600" },
{ code : "9108", intitule : "DURBAN SUR ARIZE", cp : "09240" },
{ code : "9109", intitule : "DURFORT", cp : "09130" },
{ code : "9110", intitule : "ENCOURTIECH", cp : "09200" },
{ code : "9111", intitule : "ENGOMER", cp : "09800" },
{ code : "9113", intitule : "ERCE", cp : "09140" },
{ code : "9114", intitule : "ERP", cp : "09200" },
{ code : "9115", intitule : "ESCLAGNE", cp : "09600" },
{ code : "9116", intitule : "ESCOSSE", cp : "09100" },
{ code : "9117", intitule : "ESPLAS", cp : "09700" },
{ code : "9118", intitule : "ESPLAS DE SEROU", cp : "09420" },
{ code : "9119", intitule : "EYCHEIL", cp : "09200" },
{ code : "9120", intitule : "FABAS", cp : "09230" },
{ code : "9121", intitule : "FERRIERES SUR ARIEGE", cp : "09000" },
{ code : "9122", intitule : "FOIX", cp : "09000" },
{ code : "9123", intitule : "FORNEX", cp : "09350" },
{ code : "9124", intitule : "LE FOSSAT", cp : "09130" },
{ code : "9125", intitule : "FOUGAX ET BARRINEUF", cp : "09300" },
{ code : "9126", intitule : "FREYCHENET", cp : "09300" },
{ code : "9127", intitule : "GABRE", cp : "09290" },
{ code : "9128", intitule : "GAJAN", cp : "09190" },
{ code : "9129", intitule : "GALEY", cp : "09800" },
{ code : "9130", intitule : "GANAC", cp : "09000" },
{ code : "9131", intitule : "GARANOU", cp : "09250" },
{ code : "9132", intitule : "GAUDIES", cp : "09700" },
{ code : "9133", intitule : "GENAT", cp : "09400" },
{ code : "9134", intitule : "GESTIES", cp : "09220" },
{ code : "9135", intitule : "GOULIER", cp : "09220" },
{ code : "9136", intitule : "GOURBIT", cp : "09400" },
{ code : "9137", intitule : "GUDAS", cp : "09120" },
{ code : "9138", intitule : "L HERM", cp : "09000" },
{ code : "9139", intitule : "L HOSPITALET PRES L ANDORRE", cp : "09390" },
{ code : "9140", intitule : "IGNAUX", cp : "09110" },
{ code : "9141", intitule : "ILLARTEIN", cp : "09800" },
{ code : "9142", intitule : "ILHAT", cp : "09300" },
{ code : "9143", intitule : "ILLIER ET LARAMADE", cp : "09220" },
{ code : "9145", intitule : "LES ISSARDS", cp : "09100" },
{ code : "9146", intitule : "JUSTINIAC", cp : "09700" },
{ code : "9147", intitule : "LABATUT", cp : "09700" },
{ code : "9148", intitule : "LACAVE", cp : "09160" },
{ code : "9149", intitule : "LACOURT", cp : "09200" },
{ code : "9150", intitule : "LAGARDE", cp : "09500" },
{ code : "9151", intitule : "LANOUX", cp : "09130" },
{ code : "9152", intitule : "LAPEGE", cp : "09400" },
{ code : "9153", intitule : "LAPENNE", cp : "09500" },
{ code : "9154", intitule : "LARBONT", cp : "09240" },
{ code : "9155", intitule : "LARCAT", cp : "09310" },
{ code : "9156", intitule : "LARNAT", cp : "09310" },
{ code : "9157", intitule : "LAROQUE D OLMES", cp : "09600" },
{ code : "9158", intitule : "LASSERRE", cp : "09230" },
{ code : "9159", intitule : "LASSUR", cp : "09310" },
{ code : "9160", intitule : "LAVELANET", cp : "09300" },
{ code : "9161", intitule : "LERAN", cp : "09600" },
{ code : "9162", intitule : "LERCOUL", cp : "09220" },
{ code : "9163", intitule : "LESCOUSSE", cp : "09100" },
{ code : "9164", intitule : "LESCURE", cp : "09420" },
{ code : "9165", intitule : "LESPARROU", cp : "09300" },
{ code : "9166", intitule : "LEYCHERT", cp : "09300" },
{ code : "9167", intitule : "LEZAT SUR LEZE", cp : "09210" },
{ code : "9168", intitule : "LIEURAC", cp : "09300" },
{ code : "9169", intitule : "LIMBRASSAC", cp : "09600" },
{ code : "9170", intitule : "LISSAC", cp : "09700" },
{ code : "9171", intitule : "LORDAT", cp : "09250" },
{ code : "9172", intitule : "LOUBAUT", cp : "09350" },
{ code : "9173", intitule : "LOUBENS", cp : "09120" },
{ code : "9174", intitule : "LOUBIERES", cp : "09000" },
{ code : "9175", intitule : "LUDIES", cp : "09100" },
{ code : "9176", intitule : "LUZENAC", cp : "09250" },
{ code : "9177", intitule : "MADIERE", cp : "09100" },
{ code : "9178", intitule : "MALEGOUDE", cp : "09500" },
{ code : "9179", intitule : "MALLEON", cp : "09120" },
{ code : "9180", intitule : "MANSES", cp : "09500" },
{ code : "9181", intitule : "LE MAS D AZIL", cp : "09290" },
{ code : "9182", intitule : "MASSAT", cp : "09320" },
{ code : "9183", intitule : "MAUVEZIN DE PRAT", cp : "09160" },
{ code : "9184", intitule : "MAUVEZIN DE STE CROIX", cp : "09230" },
{ code : "9185", intitule : "MAZERES", cp : "09270" },
{ code : "9186", intitule : "MERAS", cp : "09350" },
{ code : "9187", intitule : "MERCENAC", cp : "09160" },
{ code : "9188", intitule : "MERCUS GARRABET", cp : "09400" },
{ code : "9188", intitule : "MERCUS GARRABET", cp : "09400" },
{ code : "9189", intitule : "MERENS LES VALS", cp : "09110" },
{ code : "9190", intitule : "MERIGON", cp : "09230" },
{ code : "9192", intitule : "MIGLOS", cp : "09400" },
{ code : "9193", intitule : "MIJANES", cp : "09460" },
{ code : "9194", intitule : "MIREPOIX", cp : "09500" },
{ code : "9195", intitule : "MONESPLE", cp : "09130" },
{ code : "9196", intitule : "MONTAGAGNE", cp : "09240" },
{ code : "9197", intitule : "MONTAILLOU", cp : "09110" },
{ code : "9198", intitule : "MONTARDIT", cp : "09230" },
{ code : "9199", intitule : "MONTAUT", cp : "09700" },
{ code : "9200", intitule : "MONTBEL", cp : "09600" },
{ code : "9201", intitule : "MONTEGUT EN COUSERANS", cp : "09200" },
{ code : "9202", intitule : "MONTEGUT PLANTAUREL", cp : "09120" },
{ code : "9203", intitule : "MONTELS", cp : "09240" },
{ code : "9204", intitule : "MONTESQUIEU AVANTES", cp : "09200" },
{ code : "9205", intitule : "MONTFA", cp : "09350" },
{ code : "9206", intitule : "MONTFERRIER", cp : "09300" },
{ code : "9207", intitule : "MONTGAILLARD", cp : "09330" },
{ code : "9208", intitule : "MONTGAUCH", cp : "09160" },
{ code : "9209", intitule : "MONTJOIE EN COUSERANS", cp : "09200" },
{ code : "9209", intitule : "MONTJOIE EN COUSERANS", cp : "09200" },
{ code : "9210", intitule : "MONTOULIEU", cp : "09000" },
{ code : "9211", intitule : "MONTSEGUR", cp : "09300" },
{ code : "9212", intitule : "MONTSERON", cp : "09240" },
{ code : "9213", intitule : "MOULIN NEUF", cp : "09500" },
{ code : "9214", intitule : "MOULIS", cp : "09200" },
{ code : "9215", intitule : "NALZEN", cp : "09300" },
{ code : "9216", intitule : "NESCUS", cp : "09240" },
{ code : "9217", intitule : "NIAUX", cp : "09400" },
{ code : "9218", intitule : "ORGEIX", cp : "09110" },
{ code : "9219", intitule : "ORGIBET", cp : "09800" },
{ code : "9220", intitule : "ORLU", cp : "09110" },
{ code : "9221", intitule : "ORNOLAC USSAT LES BAINS", cp : "09400" },
{ code : "9222", intitule : "ORUS", cp : "09220" },
{ code : "9223", intitule : "OUST", cp : "09140" },
{ code : "9224", intitule : "PAILHES", cp : "09130" },
{ code : "9225", intitule : "PAMIERS", cp : "09100" },
{ code : "9226", intitule : "PECH", cp : "09310" },
{ code : "9227", intitule : "PEREILLE", cp : "09300" },
{ code : "9228", intitule : "PERLES ET CASTELET", cp : "09110" },
{ code : "9229", intitule : "LE PEYRAT", cp : "09600" },
{ code : "9230", intitule : "LE PLA", cp : "09460" },
{ code : "9231", intitule : "LE PORT", cp : "09320" },
{ code : "9232", intitule : "PRADES", cp : "09110" },
{ code : "9233", intitule : "PRADETTES", cp : "09600" },
{ code : "9234", intitule : "PRADIERES", cp : "09000" },
{ code : "9235", intitule : "PRAT BONREPAUX", cp : "09160" },
{ code : "9236", intitule : "PRAYOLS", cp : "09000" },
{ code : "9237", intitule : "LE PUCH", cp : "09460" },
{ code : "9238", intitule : "LES PUJOLS", cp : "09100" },
{ code : "9239", intitule : "QUERIGUT", cp : "09460" },
{ code : "9240", intitule : "QUIE", cp : "09400" },
{ code : "9241", intitule : "RABAT LES TROIS SEIGNEURS", cp : "09400" },
{ code : "9242", intitule : "RAISSAC", cp : "09300" },
{ code : "9243", intitule : "REGAT", cp : "09600" },
{ code : "9244", intitule : "RIEUCROS", cp : "09500" },
{ code : "9245", intitule : "RIEUX DE PELLEPORT", cp : "09120" },
{ code : "9246", intitule : "RIMONT", cp : "09420" },
{ code : "9247", intitule : "RIVERENERT", cp : "09200" },
{ code : "9249", intitule : "ROQUEFIXADE", cp : "09300" },
{ code : "9250", intitule : "ROQUEFORT LES CASCADES", cp : "09300" },
{ code : "9251", intitule : "ROUMENGOUX", cp : "09500" },
{ code : "9252", intitule : "ROUZE", cp : "09460" },
{ code : "9253", intitule : "SABARAT", cp : "09350" },
{ code : "9254", intitule : "ST AMADOU", cp : "09100" },
{ code : "9255", intitule : "ST AMANS", cp : "09100" },
{ code : "9256", intitule : "ST BAUZEIL", cp : "09120" },
{ code : "9257", intitule : "STE CROIX VOLVESTRE", cp : "09230" },
{ code : "9258", intitule : "ST FELIX DE RIEUTORD", cp : "09120" },
{ code : "9259", intitule : "ST FELIX DE TOURNEGAT", cp : "09500" },
{ code : "9260", intitule : "STE FOI", cp : "09500" },
{ code : "9261", intitule : "ST GIRONS", cp : "09200" },
{ code : "9262", intitule : "ST JEAN D AIGUES VIVES", cp : "09300" },
{ code : "9263", intitule : "ST JEAN DU CASTILLONNAIS", cp : "09800" },
{ code : "9264", intitule : "ST JEAN DE VERGES", cp : "09000" },
{ code : "9264", intitule : "ST JEAN DE VERGES", cp : "09000" },
{ code : "9265", intitule : "ST JEAN DU FALGA", cp : "09100" },
{ code : "9266", intitule : "ST JULIEN DE GRAS CAPOU", cp : "09500" },
{ code : "9267", intitule : "ST LARY", cp : "09800" },
{ code : "9268", intitule : "ST LIZIER", cp : "09190" },
{ code : "9269", intitule : "ST MARTIN DE CARALP", cp : "09000" },
{ code : "9270", intitule : "ST MARTIN D OYDES", cp : "09100" },
{ code : "9271", intitule : "ST MICHEL", cp : "09100" },
{ code : "9272", intitule : "ST PAUL DE JARRAT", cp : "09000" },
{ code : "9273", intitule : "ST PIERRE DE RIVIERE", cp : "09000" },
{ code : "9274", intitule : "ST QUENTIN LA TOUR", cp : "09500" },
{ code : "9275", intitule : "ST QUIRC", cp : "09700" },
{ code : "9276", intitule : "ST VICTOR ROUZAUD", cp : "09100" },
{ code : "9277", intitule : "ST YBARS", cp : "09210" },
{ code : "9279", intitule : "SALSEIN", cp : "09800" },
{ code : "9280", intitule : "SAURAT", cp : "09400" },
{ code : "9280", intitule : "SAURAT", cp : "09400" },
{ code : "9281", intitule : "SAUTEL", cp : "09300" },
{ code : "9282", intitule : "SAVERDUN", cp : "09700" },
{ code : "9283", intitule : "SAVIGNAC LES ORMEAUX", cp : "09110" },
{ code : "9284", intitule : "SEGURA", cp : "09120" },
{ code : "9285", intitule : "SEIX", cp : "09140" },
{ code : "9286", intitule : "SEM", cp : "09220" },
{ code : "9287", intitule : "SENCONAC", cp : "09250" },
{ code : "9289", intitule : "LORP SENTARAILLE", cp : "09190" },
{ code : "9290", intitule : "SENTEIN", cp : "09800" },
{ code : "9291", intitule : "SENTENAC D OUST", cp : "09140" },
{ code : "9292", intitule : "SENTENAC DE SEROU", cp : "09240" },
{ code : "9293", intitule : "SERRES SUR ARGET", cp : "09000" },
{ code : "9294", intitule : "SIEURAS", cp : "09130" },
{ code : "9295", intitule : "SIGUER", cp : "09220" },
{ code : "9296", intitule : "SINSAT", cp : "09310" },
{ code : "9297", intitule : "SOR", cp : "09800" },
{ code : "9298", intitule : "SORGEAT", cp : "09110" },
{ code : "9299", intitule : "SOUEIX ROGALLE", cp : "09140" },
{ code : "9299", intitule : "SOUEIX ROGALLE", cp : "09140" },
{ code : "9300", intitule : "SOULA", cp : "09000" },
{ code : "9301", intitule : "SOULAN", cp : "09320" },
{ code : "9302", intitule : "SUC ET SENTENAC", cp : "09220" },
{ code : "9303", intitule : "SURBA", cp : "09400" },
{ code : "9304", intitule : "SUZAN", cp : "09240" },
{ code : "9305", intitule : "TABRE", cp : "09600" },
{ code : "9306", intitule : "TARASCON SUR ARIEGE", cp : "09400" },
{ code : "9306", intitule : "TARASCON SUR ARIEGE", cp : "09400" },
{ code : "9307", intitule : "TAURIGNAN CASTET", cp : "09160" },
{ code : "9308", intitule : "TAURIGNAN VIEUX", cp : "09190" },
{ code : "9309", intitule : "TEILHET", cp : "09500" },
{ code : "9310", intitule : "THOUARS SUR ARIZE", cp : "09350" },
{ code : "9311", intitule : "TIGNAC", cp : "09110" },
{ code : "9312", intitule : "LA TOUR DU CRIEU", cp : "09100" },
{ code : "9313", intitule : "TOURTOUSE", cp : "09230" },
{ code : "9314", intitule : "TOURTROL", cp : "09500" },
{ code : "9315", intitule : "TREMOULET", cp : "09700" },
{ code : "9316", intitule : "TROYE D ARIEGE", cp : "09500" },
{ code : "9317", intitule : "UCHENTEIN", cp : "09800" },
{ code : "9318", intitule : "UNAC", cp : "09250" },
{ code : "9319", intitule : "UNZENT", cp : "09100" },
{ code : "9320", intitule : "URS", cp : "09310" },
{ code : "9321", intitule : "USSAT", cp : "09400" },
{ code : "9322", intitule : "USTOU", cp : "09140" },
{ code : "9322", intitule : "USTOU", cp : "09140" },
{ code : "9323", intitule : "VALS", cp : "09500" },
{ code : "9324", intitule : "VARILHES", cp : "09120" },
{ code : "9325", intitule : "VAYCHIS", cp : "09110" },
{ code : "9326", intitule : "VEBRE", cp : "09310" },
{ code : "9327", intitule : "VENTENAC", cp : "09120" },
{ code : "9328", intitule : "VERDUN", cp : "09310" },
{ code : "9329", intitule : "VERNAJOUL", cp : "09000" },
{ code : "9330", intitule : "VERNAUX", cp : "09250" },
{ code : "9331", intitule : "LE VERNET", cp : "09700" },
{ code : "9332", intitule : "VERNIOLLE", cp : "09340" },
{ code : "9334", intitule : "VICDESSOS", cp : "09220" },
{ code : "9335", intitule : "VILLENEUVE", cp : "09800" },
{ code : "9336", intitule : "VILLENEUVE D OLMES", cp : "09300" },
{ code : "9338", intitule : "VILLENEUVE DU LATOU", cp : "09130" },
{ code : "9339", intitule : "VILLENEUVE DU PAREAGE", cp : "09100" },
{ code : "9340", intitule : "VIRA", cp : "09120" },
{ code : "9341", intitule : "VIVIES", cp : "09500" },
{ code : "9342", intitule : "STE SUZANNE", cp : "09130" },
{ code : "10002", intitule : "AILLEVILLE", cp : "10200" },
{ code : "10003", intitule : "AIX VILLEMAUR PALIS", cp : "10190" },
{ code : "10003", intitule : "AIX VILLEMAUR PALIS", cp : "10160" },
{ code : "10003", intitule : "AIX VILLEMAUR PALIS", cp : "10190" },
{ code : "10004", intitule : "ALLIBAUDIERES", cp : "10700" },
{ code : "10005", intitule : "AMANCE", cp : "10140" },
{ code : "10006", intitule : "ARCIS SUR AUBE", cp : "10700" },
{ code : "10007", intitule : "ARCONVILLE", cp : "10200" },
{ code : "10008", intitule : "ARGANCON", cp : "10140" },
{ code : "10009", intitule : "ARRELLES", cp : "10340" },
{ code : "10010", intitule : "ARREMBECOURT", cp : "10330" },
{ code : "10011", intitule : "ARRENTIERES", cp : "10200" },
{ code : "10012", intitule : "ARSONVAL", cp : "10200" },
{ code : "10013", intitule : "ASSENAY", cp : "10320" },
{ code : "10014", intitule : "ASSENCIERES", cp : "10220" },
{ code : "10015", intitule : "AUBETERRE", cp : "10150" },
{ code : "10017", intitule : "AULNAY", cp : "10240" },
{ code : "10018", intitule : "AUXON", cp : "10130" },
{ code : "10019", intitule : "VAL D AUZON", cp : "10220" },
{ code : "10019", intitule : "VAL D AUZON", cp : "10220" },
{ code : "10019", intitule : "VAL D AUZON", cp : "10220" },
{ code : "10020", intitule : "AVANT LES MARCILLY", cp : "10400" },
{ code : "10021", intitule : "AVANT LES RAMERUPT", cp : "10240" },
{ code : "10022", intitule : "AVIREY LINGEY", cp : "10340" },
{ code : "10023", intitule : "AVON LA PEZE", cp : "10290" },
{ code : "10024", intitule : "AVREUIL", cp : "10130" },
{ code : "10025", intitule : "BAGNEUX LA FOSSE", cp : "10340" },
{ code : "10026", intitule : "BAILLY LE FRANC", cp : "10330" },
{ code : "10027", intitule : "BALIGNICOURT", cp : "10330" },
{ code : "10028", intitule : "BALNOT LA GRANGE", cp : "10210" },
{ code : "10029", intitule : "BALNOT SUR LAIGNES", cp : "10110" },
{ code : "10030", intitule : "BARBEREY ST SULPICE", cp : "10600" },
{ code : "10031", intitule : "BARBUISE", cp : "10400" },
{ code : "10031", intitule : "BARBUISE", cp : "10400" },
{ code : "10032", intitule : "BAROVILLE", cp : "10200" },
{ code : "10033", intitule : "BAR SUR AUBE", cp : "10200" },
{ code : "10034", intitule : "BAR SUR SEINE", cp : "10110" },
{ code : "10035", intitule : "BAYEL", cp : "10310" },
{ code : "10037", intitule : "BERCENAY EN OTHE", cp : "10190" },
{ code : "10038", intitule : "BERCENAY LE HAYER", cp : "10290" },
{ code : "10039", intitule : "BERGERES", cp : "10200" },
{ code : "10040", intitule : "BERNON", cp : "10130" },
{ code : "10041", intitule : "BERTIGNOLLES", cp : "10110" },
{ code : "10042", intitule : "BERULLE", cp : "10160" },
{ code : "10043", intitule : "BESSY", cp : "10170" },
{ code : "10044", intitule : "BETIGNICOURT", cp : "10500" },
{ code : "10045", intitule : "BEUREY", cp : "10140" },
{ code : "10046", intitule : "BLAINCOURT SUR AUBE", cp : "10500" },
{ code : "10047", intitule : "BLIGNICOURT", cp : "10500" },
{ code : "10048", intitule : "BLIGNY", cp : "10200" },
{ code : "10049", intitule : "LES BORDES AUMONT", cp : "10800" },
{ code : "10050", intitule : "BOSSANCOURT", cp : "10140" },
{ code : "10051", intitule : "BOUILLY", cp : "10320" },
{ code : "10052", intitule : "BOULAGES", cp : "10380" },
{ code : "10053", intitule : "BOURANTON", cp : "10270" },
{ code : "10054", intitule : "BOURDENAY", cp : "10290" },
{ code : "10055", intitule : "BOURGUIGNONS", cp : "10110" },
{ code : "10056", intitule : "BOUY LUXEMBOURG", cp : "10220" },
{ code : "10057", intitule : "BOUY SUR ORVIN", cp : "10400" },
{ code : "10058", intitule : "BRAGELOGNE BEAUVOIR", cp : "10340" },
{ code : "10058", intitule : "BRAGELOGNE BEAUVOIR", cp : "10340" },
{ code : "10059", intitule : "BRAUX", cp : "10500" },
{ code : "10060", intitule : "BREVIANDES", cp : "10450" },
{ code : "10060", intitule : "BREVIANDES", cp : "10450" },
{ code : "10061", intitule : "BREVONNES", cp : "10220" },
{ code : "10062", intitule : "BRIEL SUR BARSE", cp : "10140" },
{ code : "10063", intitule : "BRIENNE LA VIEILLE", cp : "10500" },
{ code : "10064", intitule : "BRIENNE LE CHATEAU", cp : "10500" },
{ code : "10065", intitule : "BRILLECOURT", cp : "10240" },
{ code : "10066", intitule : "BUCEY EN OTHE", cp : "10190" },
{ code : "10067", intitule : "BUCHERES", cp : "10800" },
{ code : "10067", intitule : "BUCHERES", cp : "10800" },
{ code : "10068", intitule : "BUXEUIL", cp : "10110" },
{ code : "10069", intitule : "BUXIERES SUR ARCE", cp : "10110" },
{ code : "10070", intitule : "CELLES SUR OURCE", cp : "10110" },
{ code : "10071", intitule : "CHACENAY", cp : "10110" },
{ code : "10072", intitule : "LA CHAISE", cp : "10500" },
{ code : "10073", intitule : "CHALETTE SUR VOIRE", cp : "10500" },
{ code : "10074", intitule : "CHAMOY", cp : "10130" },
{ code : "10075", intitule : "CHAMPFLEURY", cp : "10700" },
{ code : "10076", intitule : "CHAMPIGNOL LEZ MONDEVILLE", cp : "10200" },
{ code : "10077", intitule : "CHAMPIGNY SUR AUBE", cp : "10700" },
{ code : "10078", intitule : "CHAMP SUR BARSE", cp : "10140" },
{ code : "10079", intitule : "CHANNES", cp : "10340" },
{ code : "10080", intitule : "CHAOURCE", cp : "10210" },
{ code : "10081", intitule : "LA CHAPELLE ST LUC", cp : "10600" },
{ code : "10082", intitule : "CHAPELLE VALLON", cp : "10700" },
{ code : "10083", intitule : "CHAPPES", cp : "10260" },
{ code : "10084", intitule : "CHARMONT SOUS BARBUISE", cp : "10150" },
{ code : "10084", intitule : "CHARMONT SOUS BARBUISE", cp : "10150" },
{ code : "10085", intitule : "CHARMOY", cp : "10290" },
{ code : "10086", intitule : "CHARNY LE BACHOT", cp : "10380" },
{ code : "10087", intitule : "CHASEREY", cp : "10210" },
{ code : "10089", intitule : "CHATRES", cp : "10510" },
{ code : "10090", intitule : "CHAUCHIGNY", cp : "10170" },
{ code : "10091", intitule : "CHAUDREY", cp : "10240" },
{ code : "10092", intitule : "CHAUFFOUR LES BAILLY", cp : "10110" },
{ code : "10093", intitule : "CHAUMESNIL", cp : "10500" },
{ code : "10094", intitule : "CHAVANGES", cp : "10330" },
{ code : "10095", intitule : "LE CHENE", cp : "10700" },
{ code : "10096", intitule : "CHENNEGY", cp : "10190" },
{ code : "10097", intitule : "CHERVEY", cp : "10110" },
{ code : "10098", intitule : "CHESLEY", cp : "10210" },
{ code : "10099", intitule : "CHESSY LES PRES", cp : "10130" },
{ code : "10100", intitule : "CLEREY", cp : "10390" },
{ code : "10101", intitule : "COCLOIS", cp : "10240" },
{ code : "10102", intitule : "COLOMBE LA FOSSE", cp : "10200" },
{ code : "10103", intitule : "COLOMBE LE SEC", cp : "10200" },
{ code : "10104", intitule : "CORMOST", cp : "10800" },
{ code : "10105", intitule : "COURCELLES SUR VOIRE", cp : "10500" },
{ code : "10106", intitule : "COURCEROY", cp : "10400" },
{ code : "10107", intitule : "COURSAN EN OTHE", cp : "10130" },
{ code : "10108", intitule : "COURTAOULT", cp : "10130" },
{ code : "10109", intitule : "COURTENOT", cp : "10260" },
{ code : "10110", intitule : "COURTERANGES", cp : "10270" },
{ code : "10111", intitule : "COURTERON", cp : "10250" },
{ code : "10112", intitule : "COUSSEGREY", cp : "10210" },
{ code : "10113", intitule : "COUVIGNON", cp : "10200" },
{ code : "10113", intitule : "COUVIGNON", cp : "10200" },
{ code : "10114", intitule : "CRANCEY", cp : "10100" },
{ code : "10115", intitule : "CRENEY PRES TROYES", cp : "10150" },
{ code : "10115", intitule : "CRENEY PRES TROYES", cp : "10150" },
{ code : "10116", intitule : "CRESANTIGNES", cp : "10320" },
{ code : "10117", intitule : "CRESPY LE NEUF", cp : "10500" },
{ code : "10118", intitule : "LES CROUTES", cp : "10130" },
{ code : "10119", intitule : "CUNFIN", cp : "10360" },
{ code : "10120", intitule : "CUSSANGY", cp : "10210" },
{ code : "10121", intitule : "DAMPIERRE", cp : "10240" },
{ code : "10122", intitule : "DAVREY", cp : "10130" },
{ code : "10123", intitule : "DIENVILLE", cp : "10500" },
{ code : "10124", intitule : "DIERREY ST JULIEN", cp : "10190" },
{ code : "10125", intitule : "DIERREY ST PIERRE", cp : "10190" },
{ code : "10126", intitule : "DOLANCOURT", cp : "10200" },
{ code : "10127", intitule : "DOMMARTIN LE COQ", cp : "10240" },
{ code : "10128", intitule : "DONNEMENT", cp : "10330" },
{ code : "10129", intitule : "DOSCHES", cp : "10220" },
{ code : "10130", intitule : "DOSNON", cp : "10700" },
{ code : "10131", intitule : "DROUPT ST BASLE", cp : "10170" },
{ code : "10132", intitule : "DROUPT STE MARIE", cp : "10170" },
{ code : "10133", intitule : "EAUX PUISEAUX", cp : "10130" },
{ code : "10134", intitule : "ECHEMINES", cp : "10350" },
{ code : "10135", intitule : "ECLANCE", cp : "10200" },
{ code : "10136", intitule : "EGUILLY SOUS BOIS", cp : "10110" },
{ code : "10137", intitule : "ENGENTE", cp : "10200" },
{ code : "10138", intitule : "EPAGNE", cp : "10500" },
{ code : "10139", intitule : "EPOTHEMONT", cp : "10500" },
{ code : "10140", intitule : "ERVY LE CHATEL", cp : "10130" },
{ code : "10141", intitule : "ESSOYES", cp : "10360" },
{ code : "10142", intitule : "ESTISSAC", cp : "10190" },
{ code : "10142", intitule : "ESTISSAC", cp : "10190" },
{ code : "10143", intitule : "ETOURVY", cp : "10210" },
{ code : "10144", intitule : "ETRELLES SUR AUBE", cp : "10170" },
{ code : "10145", intitule : "FAUX VILLECERF", cp : "10290" },
{ code : "10146", intitule : "FAY LES MARCILLY", cp : "10290" },
{ code : "10147", intitule : "FAYS LA CHAPELLE", cp : "10320" },
{ code : "10148", intitule : "FERREUX QUINCEY", cp : "10400" },
{ code : "10148", intitule : "FERREUX QUINCEY", cp : "10400" },
{ code : "10149", intitule : "FEUGES", cp : "10150" },
{ code : "10150", intitule : "FONTAINE", cp : "10200" },
{ code : "10151", intitule : "FONTAINE LES GRES", cp : "10280" },
{ code : "10153", intitule : "FONTAINE MACON", cp : "10400" },
{ code : "10154", intitule : "FONTENAY DE BOSSERY", cp : "10400" },
{ code : "10155", intitule : "FONTETTE", cp : "10360" },
{ code : "10156", intitule : "FONTVANNES", cp : "10190" },
{ code : "10157", intitule : "LA FOSSE CORDUAN", cp : "10100" },
{ code : "10158", intitule : "FOUCHERES", cp : "10260" },
{ code : "10159", intitule : "FRALIGNES", cp : "10110" },
{ code : "10160", intitule : "FRAVAUX", cp : "10200" },
{ code : "10161", intitule : "FRESNAY", cp : "10200" },
{ code : "10162", intitule : "FRESNOY LE CHATEAU", cp : "10270" },
{ code : "10163", intitule : "FULIGNY", cp : "10200" },
{ code : "10164", intitule : "GELANNES", cp : "10100" },
{ code : "10165", intitule : "GERAUDOT", cp : "10220" },
{ code : "10166", intitule : "LES GRANDES CHAPELLES", cp : "10170" },
{ code : "10167", intitule : "GRANDVILLE", cp : "10700" },
{ code : "10168", intitule : "LES GRANGES", cp : "10210" },
{ code : "10169", intitule : "GUMERY", cp : "10400" },
{ code : "10170", intitule : "GYE SUR SEINE", cp : "10250" },
{ code : "10171", intitule : "HAMPIGNY", cp : "10500" },
{ code : "10172", intitule : "HERBISSE", cp : "10700" },
{ code : "10173", intitule : "ISLE AUMONT", cp : "10800" },
{ code : "10174", intitule : "ISLE AUBIGNY", cp : "10240" },
{ code : "10175", intitule : "JASSEINES", cp : "10330" },
{ code : "10176", intitule : "JAUCOURT", cp : "10200" },
{ code : "10177", intitule : "JAVERNANT", cp : "10320" },
{ code : "10178", intitule : "JESSAINS", cp : "10140" },
{ code : "10179", intitule : "JEUGNY", cp : "10320" },
{ code : "10180", intitule : "JONCREUIL", cp : "10330" },
{ code : "10181", intitule : "JULLY SUR SARCE", cp : "10260" },
{ code : "10182", intitule : "JUVANCOURT", cp : "10310" },
{ code : "10183", intitule : "JUVANZE", cp : "10140" },
{ code : "10184", intitule : "JUZANVIGNY", cp : "10500" },
{ code : "10185", intitule : "LAGESSE", cp : "10210" },
{ code : "10186", intitule : "LAINES AUX BOIS", cp : "10120" },
{ code : "10187", intitule : "LANDREVILLE", cp : "10110" },
{ code : "10188", intitule : "LANTAGES", cp : "10210" },
{ code : "10189", intitule : "LASSICOURT", cp : "10500" },
{ code : "10190", intitule : "LAUBRESSEL", cp : "10270" },
{ code : "10191", intitule : "LAVAU", cp : "10150" },
{ code : "10191", intitule : "LAVAU", cp : "10150" },
{ code : "10192", intitule : "LENTILLES", cp : "10330" },
{ code : "10193", intitule : "LESMONT", cp : "10500" },
{ code : "10194", intitule : "LEVIGNY", cp : "10200" },
{ code : "10195", intitule : "LHUITRE", cp : "10700" },
{ code : "10196", intitule : "LIGNIERES", cp : "10130" },
{ code : "10197", intitule : "LIGNOL LE CHATEAU", cp : "10200" },
{ code : "10198", intitule : "LIREY", cp : "10320" },
{ code : "10199", intitule : "LOCHES SUR OURCE", cp : "10110" },
{ code : "10200", intitule : "LA LOGE AUX CHEVRES", cp : "10140" },
{ code : "10201", intitule : "LA LOGE POMBLIN", cp : "10210" },
{ code : "10202", intitule : "LES LOGES MARGUERON", cp : "10210" },
{ code : "10203", intitule : "LONGCHAMP SUR AUJON", cp : "10310" },
{ code : "10204", intitule : "LONGEVILLE SUR MOGNE", cp : "10320" },
{ code : "10205", intitule : "LONGPRE LE SEC", cp : "10140" },
{ code : "10206", intitule : "LONGSOLS", cp : "10240" },
{ code : "10207", intitule : "LONGUEVILLE SUR AUBE", cp : "10170" },
{ code : "10208", intitule : "LA LOUPTIERE THENARD", cp : "10400" },
{ code : "10209", intitule : "LUSIGNY SUR BARSE", cp : "10270" },
{ code : "10210", intitule : "LUYERES", cp : "10150" },
{ code : "10211", intitule : "MACEY", cp : "10300" },
{ code : "10211", intitule : "MACEY", cp : "10300" },
{ code : "10212", intitule : "MACHY", cp : "10320" },
{ code : "10213", intitule : "MAGNANT", cp : "10110" },
{ code : "10214", intitule : "MAGNICOURT", cp : "10240" },
{ code : "10215", intitule : "MAGNY FOUCHARD", cp : "10140" },
{ code : "10216", intitule : "MAILLY LE CAMP", cp : "10230" },
{ code : "10217", intitule : "MAISON DES CHAMPS", cp : "10140" },
{ code : "10218", intitule : "MAISONS LES CHAOURCE", cp : "10210" },
{ code : "10219", intitule : "MAISONS LES SOULAINES", cp : "10200" },
{ code : "10220", intitule : "MAIZIERES LA GRANDE PAROISSE", cp : "10510" },
{ code : "10220", intitule : "MAIZIERES LA GRANDE PAROISSE", cp : "10510" },
{ code : "10221", intitule : "MAIZIERES LES BRIENNE", cp : "10500" },
{ code : "10222", intitule : "MARAYE EN OTHE", cp : "10160" },
{ code : "10223", intitule : "MARCILLY LE HAYER", cp : "10290" },
{ code : "10224", intitule : "MARIGNY LE CHATEL", cp : "10350" },
{ code : "10225", intitule : "MARNAY SUR SEINE", cp : "10400" },
{ code : "10226", intitule : "MAROLLES LES BAILLY", cp : "10110" },
{ code : "10227", intitule : "MAROLLES SOUS LIGNIERES", cp : "10130" },
{ code : "10228", intitule : "MATHAUX", cp : "10500" },
{ code : "10229", intitule : "MAUPAS", cp : "10320" },
{ code : "10230", intitule : "MERGEY", cp : "10600" },
{ code : "10231", intitule : "LE MERIOT", cp : "10400" },
{ code : "10232", intitule : "MERREY SUR ARCE", cp : "10110" },
{ code : "10233", intitule : "MERY SUR SEINE", cp : "10170" },
{ code : "10234", intitule : "MESGRIGNY", cp : "10170" },
{ code : "10235", intitule : "MESNIL LA COMTESSE", cp : "10700" },
{ code : "10236", intitule : "MESNIL LETTRE", cp : "10240" },
{ code : "10237", intitule : "MESNIL ST LOUP", cp : "10190" },
{ code : "10238", intitule : "MESNIL ST PERE", cp : "10140" },
{ code : "10239", intitule : "MESNIL SELLIERES", cp : "10220" },
{ code : "10240", intitule : "MESSON", cp : "10190" },
{ code : "10241", intitule : "METZ ROBERT", cp : "10210" },
{ code : "10242", intitule : "MEURVILLE", cp : "10200" },
{ code : "10243", intitule : "MOLINS SUR AUBE", cp : "10500" },
{ code : "10245", intitule : "MONTAULIN", cp : "10270" },
{ code : "10245", intitule : "MONTAULIN", cp : "10270" },
{ code : "10246", intitule : "MONTCEAUX LES VAUDES", cp : "10260" },
{ code : "10247", intitule : "MONTFEY", cp : "10130" },
{ code : "10248", intitule : "MONTGUEUX", cp : "10300" },
{ code : "10249", intitule : "MONTIERAMEY", cp : "10270" },
{ code : "10250", intitule : "MONTIER EN L ISLE", cp : "10200" },
{ code : "10251", intitule : "MONTIGNY LES MONTS", cp : "10130" },
{ code : "10252", intitule : "MONTMARTIN LE HAUT", cp : "10140" },
{ code : "10253", intitule : "MONTMORENCY BEAUFORT", cp : "10330" },
{ code : "10254", intitule : "MONTPOTHIER", cp : "10400" },
{ code : "10255", intitule : "MONTREUIL SUR BARSE", cp : "10270" },
{ code : "10256", intitule : "MONTSUZAIN", cp : "10150" },
{ code : "10257", intitule : "MOREMBERT", cp : "10240" },
{ code : "10258", intitule : "MORVILLIERS", cp : "10500" },
{ code : "10259", intitule : "LA MOTTE TILLY", cp : "10400" },
{ code : "10260", intitule : "MOUSSEY", cp : "10800" },
{ code : "10261", intitule : "MUSSY SUR SEINE", cp : "10250" },
{ code : "10262", intitule : "NEUVILLE SUR SEINE", cp : "10250" },
{ code : "10263", intitule : "NEUVILLE SUR VANNE", cp : "10190" },
{ code : "10264", intitule : "NOE LES MALLETS", cp : "10360" },
{ code : "10265", intitule : "LES NOES PRES TROYES", cp : "10420" },
{ code : "10266", intitule : "NOGENT EN OTHE", cp : "10160" },
{ code : "10267", intitule : "NOGENT SUR AUBE", cp : "10240" },
{ code : "10268", intitule : "NOGENT SUR SEINE", cp : "10400" },
{ code : "10269", intitule : "NOZAY", cp : "10700" },
{ code : "10270", intitule : "ONJON", cp : "10220" },
{ code : "10271", intitule : "ORIGNY LE SEC", cp : "10510" },
{ code : "10272", intitule : "ORMES", cp : "10700" },
{ code : "10273", intitule : "ORTILLON", cp : "10700" },
{ code : "10274", intitule : "ORVILLIERS ST JULIEN", cp : "10170" },
{ code : "10275", intitule : "OSSEY LES TROIS MAISONS", cp : "10100" },
{ code : "10276", intitule : "PAISY COSDON", cp : "10160" },
{ code : "10278", intitule : "PARGUES", cp : "10210" },
{ code : "10279", intitule : "PARS LES CHAVANGES", cp : "10330" },
{ code : "10280", intitule : "PARS LES ROMILLY", cp : "10100" },
{ code : "10281", intitule : "LE PAVILLON STE JULIE", cp : "10350" },
{ code : "10282", intitule : "PAYNS", cp : "10600" },
{ code : "10283", intitule : "PEL ET DER", cp : "10500" },
{ code : "10284", intitule : "PERIGNY LA ROSE", cp : "10400" },
{ code : "10285", intitule : "PERTHES LES BRIENNE", cp : "10500" },
{ code : "10286", intitule : "PETIT MESNIL", cp : "10500" },
{ code : "10287", intitule : "PINEY", cp : "10220" },
{ code : "10288", intitule : "PLAINES ST LANGE", cp : "10250" },
{ code : "10289", intitule : "PLANCY L ABBAYE", cp : "10380" },
{ code : "10289", intitule : "PLANCY L ABBAYE", cp : "10380" },
{ code : "10290", intitule : "PLANTY", cp : "10160" },
{ code : "10291", intitule : "PLESSIS BARBUISE", cp : "10400" },
{ code : "10293", intitule : "POIVRES", cp : "10700" },
{ code : "10294", intitule : "POLIGNY", cp : "10110" },
{ code : "10295", intitule : "POLISOT", cp : "10110" },
{ code : "10296", intitule : "POLISY", cp : "10110" },
{ code : "10297", intitule : "PONT STE MARIE", cp : "10150" },
{ code : "10298", intitule : "PONT SUR SEINE", cp : "10400" },
{ code : "10299", intitule : "POUAN LES VALLEES", cp : "10700" },
{ code : "10300", intitule : "POUGY", cp : "10240" },
{ code : "10301", intitule : "POUY SUR VANNES", cp : "10290" },
{ code : "10302", intitule : "PRASLIN", cp : "10210" },
{ code : "10303", intitule : "PRECY NOTRE DAME", cp : "10500" },
{ code : "10304", intitule : "PRECY ST MARTIN", cp : "10500" },
{ code : "10305", intitule : "PREMIERFAIT", cp : "10170" },
{ code : "10306", intitule : "PROVERVILLE", cp : "10200" },
{ code : "10307", intitule : "PRUGNY", cp : "10190" },
{ code : "10308", intitule : "PRUNAY BELLEVILLE", cp : "10350" },
{ code : "10309", intitule : "PRUSY", cp : "10210" },
{ code : "10310", intitule : "PUITS ET NUISEMENT", cp : "10140" },
{ code : "10312", intitule : "RACINES", cp : "10130" },
{ code : "10313", intitule : "RADONVILLIERS", cp : "10500" },
{ code : "10314", intitule : "RAMERUPT", cp : "10240" },
{ code : "10314", intitule : "RAMERUPT", cp : "10240" },
{ code : "10315", intitule : "RANCES", cp : "10500" },
{ code : "10316", intitule : "RHEGES", cp : "10170" },
{ code : "10317", intitule : "LES RICEYS", cp : "10340" },
{ code : "10318", intitule : "RIGNY LA NONNEUSE", cp : "10290" },
{ code : "10319", intitule : "RIGNY LE FERRON", cp : "10160" },
{ code : "10320", intitule : "RILLY STE SYRE", cp : "10280" },
{ code : "10321", intitule : "LA RIVIERE DE CORPS", cp : "10440" },
{ code : "10323", intitule : "ROMILLY SUR SEINE", cp : "10100" },
{ code : "10324", intitule : "RONCENAY", cp : "10320" },
{ code : "10325", intitule : "ROSIERES PRES TROYES", cp : "10430" },
{ code : "10325", intitule : "ROSIERES PRES TROYES", cp : "10430" },
{ code : "10326", intitule : "ROSNAY L HOPITAL", cp : "10500" },
{ code : "10327", intitule : "LA ROTHIERE", cp : "10500" },
{ code : "10328", intitule : "ROUILLY SACEY", cp : "10220" },
{ code : "10329", intitule : "ROUILLY ST LOUP", cp : "10800" },
{ code : "10329", intitule : "ROUILLY ST LOUP", cp : "10800" },
{ code : "10330", intitule : "ROUVRES LES VIGNES", cp : "10200" },
{ code : "10331", intitule : "RUMILLY LES VAUDES", cp : "10260" },
{ code : "10332", intitule : "RUVIGNY", cp : "10410" },
{ code : "10333", intitule : "ST ANDRE LES VERGERS", cp : "10120" },
{ code : "10334", intitule : "ST AUBIN", cp : "10400" },
{ code : "10335", intitule : "ST BENOIST SUR VANNE", cp : "10160" },
{ code : "10336", intitule : "ST BENOIT SUR SEINE", cp : "10180" },
{ code : "10337", intitule : "ST CHRISTOPHE DODINICOURT", cp : "10500" },
{ code : "10338", intitule : "ST ETIENNE SOUS BARBUISE", cp : "10700" },
{ code : "10339", intitule : "ST FLAVY", cp : "10350" },
{ code : "10340", intitule : "ST GERMAIN", cp : "10120" },
{ code : "10340", intitule : "ST GERMAIN", cp : "10120" },
{ code : "10340", intitule : "ST GERMAIN", cp : "10120" },
{ code : "10341", intitule : "ST HILAIRE SOUS ROMILLY", cp : "10100" },
{ code : "10342", intitule : "ST JEAN DE BONNEVAL", cp : "10320" },
{ code : "10343", intitule : "ST JULIEN LES VILLAS", cp : "10800" },
{ code : "10344", intitule : "ST LEGER PRES TROYES", cp : "10800" },
{ code : "10345", intitule : "ST LEGER SOUS BRIENNE", cp : "10500" },
{ code : "10346", intitule : "ST LEGER SOUS MARGERIE", cp : "10330" },
{ code : "10347", intitule : "ST LOUP DE BUFFIGNY", cp : "10100" },
{ code : "10348", intitule : "ST LUPIEN", cp : "10350" },
{ code : "10349", intitule : "ST LYE", cp : "10180" },
{ code : "10349", intitule : "ST LYE", cp : "10180" },
{ code : "10350", intitule : "ST MARDS EN OTHE", cp : "10160" },
{ code : "10351", intitule : "ST MARTIN DE BOSSENAY", cp : "10100" },
{ code : "10352", intitule : "STE MAURE", cp : "10150" },
{ code : "10352", intitule : "STE MAURE", cp : "10150" },
{ code : "10353", intitule : "ST MESMIN", cp : "10280" },
{ code : "10354", intitule : "ST NABORD SUR AUBE", cp : "10700" },
{ code : "10355", intitule : "ST NICOLAS LA CHAPELLE", cp : "10400" },
{ code : "10356", intitule : "ST OULPH", cp : "10170" },
{ code : "10357", intitule : "ST PARRES AUX TERTRES", cp : "10410" },
{ code : "10358", intitule : "ST PARRES LES VAUDES", cp : "10260" },
{ code : "10359", intitule : "ST PHAL", cp : "10130" },
{ code : "10360", intitule : "ST POUANGE", cp : "10120" },
{ code : "10361", intitule : "ST REMY SOUS BARBUISE", cp : "10700" },
{ code : "10362", intitule : "STE SAVINE", cp : "10300" },
{ code : "10363", intitule : "ST THIBAULT", cp : "10800" },
{ code : "10364", intitule : "ST USAGE", cp : "10360" },
{ code : "10365", intitule : "SALON", cp : "10700" },
{ code : "10366", intitule : "SAULCY", cp : "10200" },
{ code : "10367", intitule : "LA SAULSOTTE", cp : "10400" },
{ code : "10368", intitule : "SAVIERES", cp : "10600" },
{ code : "10369", intitule : "SEMOINE", cp : "10700" },
{ code : "10370", intitule : "SOLIGNY LES ETANGS", cp : "10400" },
{ code : "10371", intitule : "SOMMEVAL", cp : "10320" },
{ code : "10372", intitule : "SOULAINES DHUYS", cp : "10200" },
{ code : "10373", intitule : "SOULIGNY", cp : "10320" },
{ code : "10374", intitule : "SPOY", cp : "10200" },
{ code : "10375", intitule : "THENNELIERES", cp : "10410" },
{ code : "10376", intitule : "THIEFFRAIN", cp : "10140" },
{ code : "10377", intitule : "THIL", cp : "10200" },
{ code : "10378", intitule : "THORS", cp : "10200" },
{ code : "10379", intitule : "TORCY LE GRAND", cp : "10700" },
{ code : "10380", intitule : "TORCY LE PETIT", cp : "10700" },
{ code : "10381", intitule : "TORVILLIERS", cp : "10440" },
{ code : "10382", intitule : "TRAINEL", cp : "10400" },
{ code : "10383", intitule : "TRANCAULT", cp : "10290" },
{ code : "10384", intitule : "TRANNES", cp : "10140" },
{ code : "10386", intitule : "TROUANS", cp : "10700" },
{ code : "10386", intitule : "TROUANS", cp : "10700" },
{ code : "10387", intitule : "TROYES", cp : "10000" },
{ code : "10388", intitule : "TURGY", cp : "10210" },
{ code : "10389", intitule : "UNIENVILLE", cp : "10140" },
{ code : "10390", intitule : "URVILLE", cp : "10200" },
{ code : "10391", intitule : "VAILLY", cp : "10150" },
{ code : "10392", intitule : "VALLANT ST GEORGES", cp : "10170" },
{ code : "10393", intitule : "VALLENTIGNY", cp : "10500" },
{ code : "10394", intitule : "VALLIERES", cp : "10210" },
{ code : "10395", intitule : "VANLAY", cp : "10210" },
{ code : "10396", intitule : "VAUCHASSIS", cp : "10190" },
{ code : "10397", intitule : "VAUCHONVILLIERS", cp : "10140" },
{ code : "10398", intitule : "VAUCOGNE", cp : "10240" },
{ code : "10399", intitule : "VAUDES", cp : "10260" },
{ code : "10400", intitule : "VAUPOISSON", cp : "10700" },
{ code : "10401", intitule : "VENDEUVRE SUR BARSE", cp : "10140" },
{ code : "10402", intitule : "LA VENDUE MIGNOT", cp : "10800" },
{ code : "10403", intitule : "VERNONVILLIERS", cp : "10200" },
{ code : "10404", intitule : "VERPILLIERES SUR OURCE", cp : "10360" },
{ code : "10405", intitule : "VERRICOURT", cp : "10240" },
{ code : "10406", intitule : "VERRIERES", cp : "10390" },
{ code : "10408", intitule : "VIAPRES LE PETIT", cp : "10380" },
{ code : "10409", intitule : "VILLACERF", cp : "10600" },
{ code : "10410", intitule : "VILLADIN", cp : "10290" },
{ code : "10411", intitule : "LA VILLE AUX BOIS", cp : "10500" },
{ code : "10412", intitule : "VILLECHETIF", cp : "10410" },
{ code : "10412", intitule : "VILLECHETIF", cp : "10410" },
{ code : "10414", intitule : "VILLELOUP", cp : "10350" },
{ code : "10416", intitule : "VILLEMEREUIL", cp : "10800" },
{ code : "10417", intitule : "VILLEMOIRON EN OTHE", cp : "10160" },
{ code : "10418", intitule : "VILLEMORIEN", cp : "10110" },
{ code : "10419", intitule : "VILLEMOYENNE", cp : "10260" },
{ code : "10420", intitule : "VILLENAUXE LA GRANDE", cp : "10370" },
{ code : "10420", intitule : "VILLENAUXE LA GRANDE", cp : "10370" },
{ code : "10421", intitule : "LA VILLENEUVE AU CHATELOT", cp : "10400" },
{ code : "10422", intitule : "VILLENEUVE AU CHEMIN", cp : "10130" },
{ code : "10423", intitule : "LA VILLENEUVE AU CHENE", cp : "10140" },
{ code : "10424", intitule : "VILLERET", cp : "10330" },
{ code : "10425", intitule : "VILLERY", cp : "10320" },
{ code : "10426", intitule : "VILLE SOUS LA FERTE", cp : "10310" },
{ code : "10426", intitule : "VILLE SOUS LA FERTE", cp : "10310" },
{ code : "10427", intitule : "VILLE SUR ARCE", cp : "10110" },
{ code : "10428", intitule : "VILLE SUR TERRE", cp : "10200" },
{ code : "10429", intitule : "VILLETTE SUR AUBE", cp : "10700" },
{ code : "10430", intitule : "VILLIERS HERBISSE", cp : "10700" },
{ code : "10431", intitule : "VILLIERS LE BOIS", cp : "10210" },
{ code : "10432", intitule : "VILLIERS SOUS PRASLIN", cp : "10210" },
{ code : "10433", intitule : "VILLY EN TRODES", cp : "10140" },
{ code : "10434", intitule : "VILLY LE BOIS", cp : "10800" },
{ code : "10435", intitule : "VILLY LE MARECHAL", cp : "10800" },
{ code : "10436", intitule : "VINETS", cp : "10700" },
{ code : "10437", intitule : "VIREY SOUS BAR", cp : "10260" },
{ code : "10438", intitule : "VITRY LE CROISE", cp : "10110" },
{ code : "10439", intitule : "VIVIERS SUR ARTAUT", cp : "10110" },
{ code : "10440", intitule : "VOIGNY", cp : "10200" },
{ code : "10441", intitule : "VOSNON", cp : "10130" },
{ code : "10442", intitule : "VOUE", cp : "10150" },
{ code : "10443", intitule : "VOUGREY", cp : "10210" },
{ code : "10444", intitule : "VULAINES", cp : "10160" },
{ code : "10445", intitule : "YEVRES LE PETIT", cp : "10500" },
{ code : "11001", intitule : "AIGUES VIVES", cp : "11800" },
{ code : "11002", intitule : "AIROUX", cp : "11320" },
{ code : "11003", intitule : "AJAC", cp : "11300" },
{ code : "11004", intitule : "ALAIGNE", cp : "11240" },
{ code : "11005", intitule : "ALAIRAC", cp : "11290" },
{ code : "11006", intitule : "ALBAS", cp : "11360" },
{ code : "11007", intitule : "ALBIERES", cp : "11330" },
{ code : "11008", intitule : "ALET LES BAINS", cp : "11580" },
{ code : "11009", intitule : "ALZONNE", cp : "11170" },
{ code : "11010", intitule : "ANTUGNAC", cp : "11190" },
{ code : "11011", intitule : "ARAGON", cp : "11600" },
{ code : "11012", intitule : "ARGELIERS", cp : "11120" },
{ code : "11013", intitule : "ARGENS MINERVOIS", cp : "11200" },
{ code : "11014", intitule : "ARMISSAN", cp : "11110" },
{ code : "11015", intitule : "ARQUES", cp : "11190" },
{ code : "11016", intitule : "ARQUETTES EN VAL", cp : "11220" },
{ code : "11017", intitule : "ARTIGUES", cp : "11140" },
{ code : "11018", intitule : "ARZENS", cp : "11290" },
{ code : "11019", intitule : "AUNAT", cp : "11140" },
{ code : "11020", intitule : "AURIAC", cp : "11330" },
{ code : "11021", intitule : "AXAT", cp : "11140" },
{ code : "11022", intitule : "AZILLE", cp : "11700" },
{ code : "11023", intitule : "BADENS", cp : "11800" },
{ code : "11024", intitule : "BAGES", cp : "11100" },
{ code : "11025", intitule : "BAGNOLES", cp : "11600" },
{ code : "11026", intitule : "BARAIGNE", cp : "11410" },
{ code : "11027", intitule : "BARBAIRA", cp : "11800" },
{ code : "11028", intitule : "BELCAIRE", cp : "11340" },
{ code : "11029", intitule : "BELCASTEL ET BUC", cp : "11580" },
{ code : "11030", intitule : "BELFLOU", cp : "11410" },
{ code : "11031", intitule : "BELFORT SUR REBENTY", cp : "11140" },
{ code : "11032", intitule : "BELLEGARDE DU RAZES", cp : "11240" },
{ code : "11033", intitule : "BELPECH", cp : "11420" },
{ code : "11034", intitule : "BELVEZE DU RAZES", cp : "11240" },
{ code : "11035", intitule : "BELVIANES ET CAVIRAC", cp : "11500" },
{ code : "11036", intitule : "BELVIS", cp : "11340" },
{ code : "11037", intitule : "BERRIAC", cp : "11000" },
{ code : "11038", intitule : "BESSEDE DE SAULT", cp : "11140" },
{ code : "11039", intitule : "LA BEZOLE", cp : "11300" },
{ code : "11040", intitule : "BIZANET", cp : "11200" },
{ code : "11041", intitule : "BIZE MINERVOIS", cp : "11120" },
{ code : "11042", intitule : "BLOMAC", cp : "11700" },
{ code : "11043", intitule : "BOUILHONNAC", cp : "11800" },
{ code : "11044", intitule : "BOUISSE", cp : "11330" },
{ code : "11045", intitule : "BOURIEGE", cp : "11300" },
{ code : "11046", intitule : "BOURIGEOLE", cp : "11300" },
{ code : "11047", intitule : "LE BOUSQUET", cp : "11140" },
{ code : "11048", intitule : "BOUTENAC", cp : "11200" },
{ code : "11049", intitule : "BRAM", cp : "11150" },
{ code : "11051", intitule : "BREZILHAC", cp : "11270" },
{ code : "11052", intitule : "BROUSSES ET VILLARET", cp : "11390" },
{ code : "11053", intitule : "BRUGAIROLLES", cp : "11300" },
{ code : "11054", intitule : "LES BRUNELS", cp : "11400" },
{ code : "11055", intitule : "BUGARACH", cp : "11190" },
{ code : "11056", intitule : "CABRESPINE", cp : "11160" },
{ code : "11057", intitule : "CAHUZAC", cp : "11420" },
{ code : "11058", intitule : "CAILHAU", cp : "11240" },
{ code : "11059", intitule : "CAILHAVEL", cp : "11240" },
{ code : "11060", intitule : "CAILLA", cp : "11140" },
{ code : "11061", intitule : "CAMBIEURE", cp : "11240" },
{ code : "11062", intitule : "CAMPAGNA DE SAULT", cp : "11140" },
{ code : "11063", intitule : "CAMPAGNE SUR AUDE", cp : "11260" },
{ code : "11064", intitule : "CAMPLONG D AUDE", cp : "11200" },
{ code : "11065", intitule : "CAMPS SUR L AGLY", cp : "11190" },
{ code : "11066", intitule : "CAMURAC", cp : "11340" },
{ code : "11067", intitule : "CANET", cp : "11200" },
{ code : "11068", intitule : "CAPENDU", cp : "11700" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11069", intitule : "CARCASSONNE", cp : "11000" },
{ code : "11070", intitule : "CARLIPA", cp : "11170" },
{ code : "11071", intitule : "CASCASTEL DES CORBIERES", cp : "11360" },
{ code : "11072", intitule : "LA CASSAIGNE", cp : "11270" },
{ code : "11073", intitule : "CASSAIGNES", cp : "11190" },
{ code : "11074", intitule : "LES CASSES", cp : "11320" },
{ code : "11075", intitule : "CASTANS", cp : "11160" },
{ code : "11076", intitule : "CASTELNAUDARY", cp : "11400" },
{ code : "11077", intitule : "CASTELNAU D AUDE", cp : "11700" },
{ code : "11078", intitule : "CASTELRENG", cp : "11300" },
{ code : "11079", intitule : "CAUDEBRONDE", cp : "11390" },
{ code : "11080", intitule : "VAL DE LAMBRONNE", cp : "11230" },
{ code : "11080", intitule : "VAL DE LAMBRONNE", cp : "11230" },
{ code : "11081", intitule : "CAUNES MINERVOIS", cp : "11160" },
{ code : "11082", intitule : "CAUNETTE SUR LAUQUET", cp : "11250" },
{ code : "11083", intitule : "CAUNETTES EN VAL", cp : "11220" },
{ code : "11084", intitule : "CAUX ET SAUZENS", cp : "11170" },
{ code : "11085", intitule : "CAVANAC", cp : "11570" },
{ code : "11086", intitule : "CAVES", cp : "11510" },
{ code : "11087", intitule : "CAZALRENOUX", cp : "11270" },
{ code : "11088", intitule : "CAZILHAC", cp : "11570" },
{ code : "11089", intitule : "CENNE MONESTIES", cp : "11170" },
{ code : "11090", intitule : "CEPIE", cp : "11300" },
{ code : "11091", intitule : "CHALABRE", cp : "11230" },
{ code : "11092", intitule : "CITOU", cp : "11160" },
{ code : "11093", intitule : "LE CLAT", cp : "11140" },
{ code : "11094", intitule : "CLERMONT SUR LAUQUET", cp : "11250" },
{ code : "11095", intitule : "COMIGNE", cp : "11700" },
{ code : "11096", intitule : "COMUS", cp : "11340" },
{ code : "11097", intitule : "CONILHAC DE LA MONTAGNE", cp : "11190" },
{ code : "11098", intitule : "CONILHAC CORBIERES", cp : "11200" },
{ code : "11099", intitule : "CONQUES SUR ORBIEL", cp : "11600" },
{ code : "11100", intitule : "CORBIERES", cp : "11230" },
{ code : "11101", intitule : "COUDONS", cp : "11500" },
{ code : "11102", intitule : "COUFFOULENS", cp : "11250" },
{ code : "11103", intitule : "COUIZA", cp : "11190" },
{ code : "11104", intitule : "COUNOZOULS", cp : "11140" },
{ code : "11105", intitule : "COURNANEL", cp : "11300" },
{ code : "11106", intitule : "COURSAN", cp : "11110" },
{ code : "11107", intitule : "COURTAULY", cp : "11230" },
{ code : "11108", intitule : "LA COURTETE", cp : "11240" },
{ code : "11109", intitule : "COUSTAUSSA", cp : "11190" },
{ code : "11110", intitule : "COUSTOUGE", cp : "11220" },
{ code : "11111", intitule : "CRUSCADES", cp : "11200" },
{ code : "11112", intitule : "CUBIERES SUR CINOBLE", cp : "11190" },
{ code : "11113", intitule : "CUCUGNAN", cp : "11350" },
{ code : "11114", intitule : "CUMIES", cp : "11410" },
{ code : "11115", intitule : "CUXAC CABARDES", cp : "11390" },
{ code : "11116", intitule : "CUXAC D AUDE", cp : "11590" },
{ code : "11117", intitule : "DAVEJEAN", cp : "11330" },
{ code : "11118", intitule : "DERNACUEILLETTE", cp : "11330" },
{ code : "11119", intitule : "LA DIGNE D AMONT", cp : "11300" },
{ code : "11120", intitule : "LA DIGNE D AVAL", cp : "11300" },
{ code : "11121", intitule : "DONAZAC", cp : "11240" },
{ code : "11122", intitule : "DOUZENS", cp : "11700" },
{ code : "11123", intitule : "DUILHAC SOUS PEYREPERTUSE", cp : "11350" },
{ code : "11124", intitule : "DURBAN CORBIERES", cp : "11360" },
{ code : "11125", intitule : "EMBRES ET CASTELMAURE", cp : "11360" },
{ code : "11126", intitule : "ESCALES", cp : "11200" },
{ code : "11127", intitule : "ESCOULOUBRE", cp : "11140" },
{ code : "11128", intitule : "ESCUEILLENS ET ST JUST", cp : "11240" },
{ code : "11128", intitule : "ESCUEILLENS ET ST JUST", cp : "11240" },
{ code : "11129", intitule : "ESPERAZA", cp : "11260" },
{ code : "11130", intitule : "ESPEZEL", cp : "11340" },
{ code : "11131", intitule : "FA", cp : "11260" },
{ code : "11132", intitule : "FABREZAN", cp : "11200" },
{ code : "11133", intitule : "FAJAC EN VAL", cp : "11220" },
{ code : "11134", intitule : "FAJAC LA RELENQUE", cp : "11410" },
{ code : "11135", intitule : "LA FAJOLLE", cp : "11140" },
{ code : "11136", intitule : "FANJEAUX", cp : "11270" },
{ code : "11137", intitule : "FELINES TERMENES", cp : "11330" },
{ code : "11138", intitule : "FENDEILLE", cp : "11400" },
{ code : "11139", intitule : "FENOUILLET DU RAZES", cp : "11240" },
{ code : "11140", intitule : "FERRALS LES CORBIERES", cp : "11200" },
{ code : "11141", intitule : "FERRAN", cp : "11240" },
{ code : "11142", intitule : "FESTES ET ST ANDRE", cp : "11300" },
{ code : "11143", intitule : "FEUILLA", cp : "11510" },
{ code : "11144", intitule : "FITOU", cp : "11510" },
{ code : "11144", intitule : "FITOU", cp : "11510" },
{ code : "11145", intitule : "FLEURY", cp : "11560" },
{ code : "11145", intitule : "FLEURY", cp : "11560" },
{ code : "11146", intitule : "FLOURE", cp : "11800" },
{ code : "11147", intitule : "FONTANES DE SAULT", cp : "11140" },
{ code : "11148", intitule : "FONTCOUVERTE", cp : "11700" },
{ code : "11149", intitule : "FONTERS DU RAZES", cp : "11400" },
{ code : "11150", intitule : "FONTIERS CABARDES", cp : "11390" },
{ code : "11151", intitule : "FONTIES D AUDE", cp : "11800" },
{ code : "11152", intitule : "FONTJONCOUSE", cp : "11360" },
{ code : "11153", intitule : "LA FORCE", cp : "11270" },
{ code : "11154", intitule : "FOURNES CABARDES", cp : "11600" },
{ code : "11155", intitule : "FOURTOU", cp : "11190" },
{ code : "11156", intitule : "FRAISSE CABARDES", cp : "11600" },
{ code : "11157", intitule : "FRAISSE DES CORBIERES", cp : "11360" },
{ code : "11158", intitule : "GAJA ET VILLEDIEU", cp : "11300" },
{ code : "11159", intitule : "GAJA LA SELVE", cp : "11270" },
{ code : "11160", intitule : "GALINAGUES", cp : "11140" },
{ code : "11161", intitule : "GARDIE", cp : "11250" },
{ code : "11162", intitule : "GENERVILLE", cp : "11270" },
{ code : "11163", intitule : "GINCLA", cp : "11140" },
{ code : "11164", intitule : "GINESTAS", cp : "11120" },
{ code : "11165", intitule : "GINOLES", cp : "11500" },
{ code : "11166", intitule : "GOURVIEILLE", cp : "11410" },
{ code : "11167", intitule : "GRAMAZIE", cp : "11240" },
{ code : "11168", intitule : "GRANES", cp : "11500" },
{ code : "11169", intitule : "GREFFEIL", cp : "11250" },
{ code : "11170", intitule : "GRUISSAN", cp : "11430" },
{ code : "11170", intitule : "GRUISSAN", cp : "11430" },
{ code : "11172", intitule : "HOMPS", cp : "11200" },
{ code : "11173", intitule : "HOUNOUX", cp : "11240" },
{ code : "11174", intitule : "LES ILHES", cp : "11380" },
{ code : "11175", intitule : "ISSEL", cp : "11400" },
{ code : "11176", intitule : "JONQUIERES", cp : "11220" },
{ code : "11177", intitule : "JOUCOU", cp : "11140" },
{ code : "11178", intitule : "LABASTIDE D ANJOU", cp : "11320" },
{ code : "11179", intitule : "LABASTIDE EN VAL", cp : "11220" },
{ code : "11180", intitule : "LABASTIDE ESPARBAIRENQUE", cp : "11380" },
{ code : "11181", intitule : "LABECEDE LAURAGAIS", cp : "11400" },
{ code : "11182", intitule : "LACOMBE", cp : "11310" },
{ code : "11183", intitule : "LADERN SUR LAUQUET", cp : "11250" },
{ code : "11184", intitule : "LAFAGE", cp : "11420" },
{ code : "11185", intitule : "LAGRASSE", cp : "11220" },
{ code : "11186", intitule : "LAIRIERE", cp : "11330" },
{ code : "11187", intitule : "LANET", cp : "11330" },
{ code : "11188", intitule : "LA PALME", cp : "11480" },
{ code : "11189", intitule : "LAPRADE", cp : "11390" },
{ code : "11190", intitule : "LA REDORTE", cp : "11700" },
{ code : "11191", intitule : "LAROQUE DE FA", cp : "11330" },
{ code : "11192", intitule : "LASBORDES", cp : "11400" },
{ code : "11193", intitule : "LASSERRE DE PROUILLE", cp : "11270" },
{ code : "11194", intitule : "LASTOURS", cp : "11600" },
{ code : "11195", intitule : "LAURABUC", cp : "11400" },
{ code : "11196", intitule : "LAURAC", cp : "11270" },
{ code : "11197", intitule : "LAURAGUEL", cp : "11300" },
{ code : "11198", intitule : "LAURE MINERVOIS", cp : "11800" },
{ code : "11199", intitule : "LAVALETTE", cp : "11290" },
{ code : "11200", intitule : "LESPINASSIERE", cp : "11160" },
{ code : "11201", intitule : "LEUC", cp : "11250" },
{ code : "11202", intitule : "LEUCATE", cp : "11370" },
{ code : "11202", intitule : "LEUCATE", cp : "11370" },
{ code : "11202", intitule : "LEUCATE", cp : "11370" },
{ code : "11203", intitule : "LEZIGNAN CORBIERES", cp : "11200" },
{ code : "11204", intitule : "LIGNAIROLLES", cp : "11240" },
{ code : "11205", intitule : "LIMOUSIS", cp : "11600" },
{ code : "11206", intitule : "LIMOUX", cp : "11300" },
{ code : "11207", intitule : "LOUPIA", cp : "11300" },
{ code : "11208", intitule : "LA LOUVIERE LAURAGAIS", cp : "11410" },
{ code : "11209", intitule : "LUC SUR AUDE", cp : "11190" },
{ code : "11210", intitule : "LUC SUR ORBIEU", cp : "11200" },
{ code : "11211", intitule : "MAGRIE", cp : "11300" },
{ code : "11212", intitule : "MAILHAC", cp : "11120" },
{ code : "11213", intitule : "MAISONS", cp : "11330" },
{ code : "11214", intitule : "MALRAS", cp : "11300" },
{ code : "11215", intitule : "MALVES EN MINERVOIS", cp : "11600" },
{ code : "11216", intitule : "MALVIES", cp : "11300" },
{ code : "11217", intitule : "MARCORIGNAN", cp : "11120" },
{ code : "11218", intitule : "MARQUEIN", cp : "11410" },
{ code : "11219", intitule : "MARSA", cp : "11140" },
{ code : "11220", intitule : "MARSEILLETTE", cp : "11800" },
{ code : "11221", intitule : "LES MARTYS", cp : "11390" },
{ code : "11222", intitule : "MAS CABARDES", cp : "11380" },
{ code : "11223", intitule : "MAS DES COURS", cp : "11570" },
{ code : "11224", intitule : "MASSAC", cp : "11330" },
{ code : "11225", intitule : "MAS SAINTES PUELLES", cp : "11400" },
{ code : "11226", intitule : "MAYREVILLE", cp : "11420" },
{ code : "11227", intitule : "MAYRONNES", cp : "11220" },
{ code : "11228", intitule : "MAZEROLLES DU RAZES", cp : "11240" },
{ code : "11229", intitule : "MAZUBY", cp : "11140" },
{ code : "11230", intitule : "MERIAL", cp : "11140" },
{ code : "11231", intitule : "MEZERVILLE", cp : "11410" },
{ code : "11232", intitule : "MIRAVAL CABARDES", cp : "11380" },
{ code : "11233", intitule : "MIREPEISSET", cp : "11120" },
{ code : "11234", intitule : "MIREVAL LAURAGAIS", cp : "11400" },
{ code : "11235", intitule : "MISSEGRE", cp : "11580" },
{ code : "11236", intitule : "MOLANDIER", cp : "11420" },
{ code : "11238", intitule : "MOLLEVILLE", cp : "11410" },
{ code : "11239", intitule : "MONTAURIOL", cp : "11410" },
{ code : "11240", intitule : "MONTAZELS", cp : "11190" },
{ code : "11241", intitule : "MONTBRUN DES CORBIERES", cp : "11700" },
{ code : "11242", intitule : "MONTCLAR", cp : "11250" },
{ code : "11243", intitule : "MONTFERRAND", cp : "11320" },
{ code : "11244", intitule : "MONTFORT SUR BOULZANE", cp : "11140" },
{ code : "11245", intitule : "MONTGAILLARD", cp : "11330" },
{ code : "11246", intitule : "MONTGRADAIL", cp : "11240" },
{ code : "11247", intitule : "MONTHAUT", cp : "11240" },
{ code : "11248", intitule : "MONTIRAT", cp : "11800" },
{ code : "11249", intitule : "MONTJARDIN", cp : "11230" },
{ code : "11250", intitule : "MONTJOI", cp : "11330" },
{ code : "11251", intitule : "MONTLAUR", cp : "11220" },
{ code : "11252", intitule : "MONTMAUR", cp : "11320" },
{ code : "11253", intitule : "MONTOLIEU", cp : "11170" },
{ code : "11254", intitule : "MONTREAL", cp : "11290" },
{ code : "11255", intitule : "MONTREDON DES CORBIERES", cp : "11100" },
{ code : "11256", intitule : "MONTSERET", cp : "11200" },
{ code : "11257", intitule : "MONZE", cp : "11800" },
{ code : "11258", intitule : "MOUSSAN", cp : "11120" },
{ code : "11259", intitule : "MOUSSOULENS", cp : "11170" },
{ code : "11260", intitule : "MOUTHOUMET", cp : "11330" },
{ code : "11261", intitule : "MOUX", cp : "11700" },
{ code : "11262", intitule : "NARBONNE", cp : "11100" },
{ code : "11262", intitule : "NARBONNE", cp : "11100" },
{ code : "11263", intitule : "NEBIAS", cp : "11500" },
{ code : "11264", intitule : "NEVIAN", cp : "11200" },
{ code : "11265", intitule : "NIORT DE SAULT", cp : "11140" },
{ code : "11266", intitule : "PORT LA NOUVELLE", cp : "11210" },
{ code : "11267", intitule : "ORNAISONS", cp : "11200" },
{ code : "11268", intitule : "ORSANS", cp : "11270" },
{ code : "11269", intitule : "OUVEILLAN", cp : "11590" },
{ code : "11270", intitule : "PADERN", cp : "11350" },
{ code : "11271", intitule : "PALAIRAC", cp : "11330" },
{ code : "11272", intitule : "PALAJA", cp : "11570" },
{ code : "11273", intitule : "PARAZA", cp : "11200" },
{ code : "11274", intitule : "PAULIGNE", cp : "11300" },
{ code : "11275", intitule : "PAYRA SUR L HERS", cp : "11410" },
{ code : "11276", intitule : "PAZIOLS", cp : "11350" },
{ code : "11277", intitule : "PECHARIC ET LE PY", cp : "11420" },
{ code : "11278", intitule : "PECH LUNA", cp : "11420" },
{ code : "11279", intitule : "PENNAUTIER", cp : "11610" },
{ code : "11280", intitule : "PEPIEUX", cp : "11700" },
{ code : "11281", intitule : "PEXIORA", cp : "11150" },
{ code : "11282", intitule : "PEYREFITTE DU RAZES", cp : "11230" },
{ code : "11283", intitule : "PEYREFITTE SUR L HERS", cp : "11420" },
{ code : "11284", intitule : "PEYRENS", cp : "11400" },
{ code : "11285", intitule : "PEYRIAC DE MER", cp : "11440" },
{ code : "11286", intitule : "PEYRIAC MINERVOIS", cp : "11160" },
{ code : "11287", intitule : "PEYROLLES", cp : "11190" },
{ code : "11288", intitule : "PEZENS", cp : "11170" },
{ code : "11289", intitule : "PIEUSSE", cp : "11300" },
{ code : "11290", intitule : "PLAIGNE", cp : "11420" },
{ code : "11291", intitule : "PLAVILLA", cp : "11270" },
{ code : "11292", intitule : "LA POMAREDE", cp : "11400" },
{ code : "11293", intitule : "POMAS", cp : "11250" },
{ code : "11294", intitule : "POMY", cp : "11300" },
{ code : "11295", intitule : "PORTEL DES CORBIERES", cp : "11490" },
{ code : "11296", intitule : "POUZOLS MINERVOIS", cp : "11120" },
{ code : "11297", intitule : "PRADELLES CABARDES", cp : "11380" },
{ code : "11298", intitule : "PRADELLES EN VAL", cp : "11220" },
{ code : "11299", intitule : "PREIXAN", cp : "11250" },
{ code : "11300", intitule : "PUGINIER", cp : "11400" },
{ code : "11301", intitule : "PUICHERIC", cp : "11700" },
{ code : "11302", intitule : "PUILAURENS", cp : "11140" },
{ code : "11302", intitule : "PUILAURENS", cp : "11140" },
{ code : "11303", intitule : "PUIVERT", cp : "11230" },
{ code : "11304", intitule : "QUILLAN", cp : "11500" },
{ code : "11304", intitule : "QUILLAN", cp : "11500" },
{ code : "11305", intitule : "QUINTILLAN", cp : "11360" },
{ code : "11306", intitule : "QUIRBAJOU", cp : "11500" },
{ code : "11307", intitule : "RAISSAC D AUDE", cp : "11200" },
{ code : "11308", intitule : "RAISSAC SUR LAMPY", cp : "11170" },
{ code : "11309", intitule : "RENNES LE CHATEAU", cp : "11190" },
{ code : "11310", intitule : "RENNES LES BAINS", cp : "11190" },
{ code : "11311", intitule : "RIBAUTE", cp : "11220" },
{ code : "11312", intitule : "RIBOUISSE", cp : "11270" },
{ code : "11313", intitule : "RICAUD", cp : "11400" },
{ code : "11314", intitule : "RIEUX EN VAL", cp : "11220" },
{ code : "11315", intitule : "RIEUX MINERVOIS", cp : "11160" },
{ code : "11316", intitule : "RIVEL", cp : "11230" },
{ code : "11317", intitule : "RODOME", cp : "11140" },
{ code : "11318", intitule : "ROQUECOURBE MINERVOIS", cp : "11700" },
{ code : "11319", intitule : "ROQUEFERE", cp : "11380" },
{ code : "11320", intitule : "ROQUEFEUIL", cp : "11340" },
{ code : "11321", intitule : "ROQUEFORT DE SAULT", cp : "11140" },
{ code : "11322", intitule : "ROQUEFORT DES CORBIERES", cp : "11540" },
{ code : "11323", intitule : "ROQUETAILLADE", cp : "11300" },
{ code : "11324", intitule : "ROUBIA", cp : "11200" },
{ code : "11325", intitule : "ROUFFIAC D AUDE", cp : "11250" },
{ code : "11326", intitule : "ROUFFIAC DES CORBIERES", cp : "11350" },
{ code : "11327", intitule : "ROULLENS", cp : "11290" },
{ code : "11328", intitule : "ROUTIER", cp : "11240" },
{ code : "11329", intitule : "ROUVENAC", cp : "11260" },
{ code : "11330", intitule : "RUSTIQUES", cp : "11800" },
{ code : "11331", intitule : "ST AMANS", cp : "11270" },
{ code : "11332", intitule : "ST ANDRE DE ROQUELONGUE", cp : "11200" },
{ code : "11333", intitule : "ST BENOIT", cp : "11230" },
{ code : "11334", intitule : "STE CAMELLE", cp : "11410" },
{ code : "11335", intitule : "STE COLOMBE SUR GUETTE", cp : "11140" },
{ code : "11336", intitule : "STE COLOMBE SUR L HERS", cp : "11230" },
{ code : "11337", intitule : "ST COUAT D AUDE", cp : "11700" },
{ code : "11338", intitule : "ST COUAT DU RAZES", cp : "11300" },
{ code : "11339", intitule : "ST DENIS", cp : "11310" },
{ code : "11340", intitule : "STE EULALIE", cp : "11170" },
{ code : "11341", intitule : "ST FERRIOL", cp : "11500" },
{ code : "11342", intitule : "ST FRICHOUX", cp : "11800" },
{ code : "11343", intitule : "ST GAUDERIC", cp : "11270" },
{ code : "11344", intitule : "ST HILAIRE", cp : "11250" },
{ code : "11345", intitule : "ST JEAN DE BARROU", cp : "11360" },
{ code : "11346", intitule : "ST JEAN DE PARACOL", cp : "11260" },
{ code : "11347", intitule : "ST JULIA DE BEC", cp : "11500" },
{ code : "11348", intitule : "ST JULIEN DE BRIOLA", cp : "11270" },
{ code : "11350", intitule : "ST JUST ET LE BEZU", cp : "11500" },
{ code : "11351", intitule : "ST LAURENT DE LA CABRERISSE", cp : "11220" },
{ code : "11352", intitule : "ST LOUIS ET PARAHOU", cp : "11500" },
{ code : "11353", intitule : "ST MARCEL SUR AUDE", cp : "11120" },
{ code : "11354", intitule : "ST MARTIN DES PUITS", cp : "11220" },
{ code : "11355", intitule : "ST MARTIN DE VILLEREGLAN", cp : "11300" },
{ code : "11356", intitule : "ST MARTIN LALANDE", cp : "11400" },
{ code : "11357", intitule : "ST MARTIN LE VIEIL", cp : "11170" },
{ code : "11358", intitule : "ST MARTIN LYS", cp : "11500" },
{ code : "11359", intitule : "ST MICHEL DE LANES", cp : "11410" },
{ code : "11360", intitule : "ST NAZAIRE D AUDE", cp : "11120" },
{ code : "11361", intitule : "ST PAPOUL", cp : "11400" },
{ code : "11362", intitule : "ST PAULET", cp : "11320" },
{ code : "11363", intitule : "ST PIERRE DES CHAMPS", cp : "11220" },
{ code : "11364", intitule : "ST POLYCARPE", cp : "11300" },
{ code : "11365", intitule : "ST SERNIN", cp : "11420" },
{ code : "11366", intitule : "STE VALIERE", cp : "11120" },
{ code : "11367", intitule : "SAISSAC", cp : "11310" },
{ code : "11368", intitule : "SALLELES CABARDES", cp : "11600" },
{ code : "11369", intitule : "SALLELES D AUDE", cp : "11590" },
{ code : "11370", intitule : "SALLES D AUDE", cp : "11110" },
{ code : "11371", intitule : "SALLES SUR L HERS", cp : "11410" },
{ code : "11372", intitule : "SALSIGNE", cp : "11600" },
{ code : "11373", intitule : "SALVEZINES", cp : "11140" },
{ code : "11374", intitule : "SALZA", cp : "11330" },
{ code : "11375", intitule : "SEIGNALENS", cp : "11240" },
{ code : "11376", intitule : "LA SERPENT", cp : "11190" },
{ code : "11377", intitule : "SERRES", cp : "11190" },
{ code : "11378", intitule : "SERVIES EN VAL", cp : "11220" },
{ code : "11379", intitule : "SIGEAN", cp : "11130" },
{ code : "11380", intitule : "SONNAC SUR L HERS", cp : "11230" },
{ code : "11381", intitule : "SOUGRAIGNE", cp : "11190" },
{ code : "11382", intitule : "SOUILHANELS", cp : "11400" },
{ code : "11383", intitule : "SOUILHE", cp : "11400" },
{ code : "11384", intitule : "SOULATGE", cp : "11330" },
{ code : "11385", intitule : "SOUPEX", cp : "11320" },
{ code : "11386", intitule : "TALAIRAN", cp : "11220" },
{ code : "11387", intitule : "TAURIZE", cp : "11220" },
{ code : "11388", intitule : "TERMES", cp : "11330" },
{ code : "11389", intitule : "TERROLES", cp : "11580" },
{ code : "11390", intitule : "THEZAN DES CORBIERES", cp : "11200" },
{ code : "11391", intitule : "LA TOURETTE CABARDES", cp : "11380" },
{ code : "11392", intitule : "TOURNISSAN", cp : "11220" },
{ code : "11393", intitule : "TOUROUZELLE", cp : "11200" },
{ code : "11394", intitule : "TOURREILLES", cp : "11300" },
{ code : "11395", intitule : "TRASSANEL", cp : "11160" },
{ code : "11396", intitule : "TRAUSSE", cp : "11160" },
{ code : "11397", intitule : "TREBES", cp : "11800" },
{ code : "11398", intitule : "TREILLES", cp : "11510" },
{ code : "11399", intitule : "TREVILLE", cp : "11400" },
{ code : "11400", intitule : "TREZIERS", cp : "11230" },
{ code : "11401", intitule : "TUCHAN", cp : "11350" },
{ code : "11402", intitule : "VALMIGERE", cp : "11580" },
{ code : "11404", intitule : "VENTENAC CABARDES", cp : "11610" },
{ code : "11405", intitule : "VENTENAC EN MINERVOIS", cp : "11120" },
{ code : "11406", intitule : "VERAZA", cp : "11580" },
{ code : "11407", intitule : "VERDUN EN LAURAGAIS", cp : "11400" },
{ code : "11408", intitule : "VERZEILLE", cp : "11250" },
{ code : "11409", intitule : "VIGNEVIEILLE", cp : "11330" },
{ code : "11410", intitule : "VILLALIER", cp : "11600" },
{ code : "11411", intitule : "VILLANIERE", cp : "11600" },
{ code : "11412", intitule : "VILLARDEBELLE", cp : "11580" },
{ code : "11413", intitule : "VILLARDONNEL", cp : "11600" },
{ code : "11414", intitule : "VILLAR EN VAL", cp : "11220" },
{ code : "11415", intitule : "VILLAR ST ANSELME", cp : "11250" },
{ code : "11416", intitule : "VILLARZEL CABARDES", cp : "11600" },
{ code : "11417", intitule : "VILLARZEL DU RAZES", cp : "11300" },
{ code : "11418", intitule : "VILLASAVARY", cp : "11150" },
{ code : "11419", intitule : "VILLAUTOU", cp : "11420" },
{ code : "11420", intitule : "VILLEBAZY", cp : "11250" },
{ code : "11421", intitule : "VILLEDAIGNE", cp : "11200" },
{ code : "11422", intitule : "VILLEDUBERT", cp : "11800" },
{ code : "11423", intitule : "VILLEFLOURE", cp : "11570" },
{ code : "11424", intitule : "VILLEFORT", cp : "11230" },
{ code : "11425", intitule : "VILLEGAILHENC", cp : "11600" },
{ code : "11426", intitule : "VILLEGLY", cp : "11600" },
{ code : "11427", intitule : "VILLELONGUE D AUDE", cp : "11300" },
{ code : "11428", intitule : "VILLEMAGNE", cp : "11310" },
{ code : "11429", intitule : "VILLEMOUSTAUSSOU", cp : "11620" },
{ code : "11430", intitule : "VILLENEUVE LA COMPTAL", cp : "11400" },
{ code : "11431", intitule : "VILLENEUVE LES CORBIERES", cp : "11360" },
{ code : "11432", intitule : "VILLENEUVE LES MONTREAL", cp : "11290" },
{ code : "11433", intitule : "VILLENEUVE MINERVOIS", cp : "11160" },
{ code : "11434", intitule : "VILLEPINTE", cp : "11150" },
{ code : "11435", intitule : "VILLEROUGE TERMENES", cp : "11330" },
{ code : "11436", intitule : "VILLESEQUE DES CORBIERES", cp : "11360" },
{ code : "11437", intitule : "VILLESEQUELANDE", cp : "11170" },
{ code : "11438", intitule : "VILLESISCLE", cp : "11150" },
{ code : "11439", intitule : "VILLESPY", cp : "11170" },
{ code : "11440", intitule : "VILLETRITOULS", cp : "11220" },
{ code : "11441", intitule : "VINASSAN", cp : "11110" },
{ code : "12001", intitule : "AGEN D AVEYRON", cp : "12630" },
{ code : "12002", intitule : "AGUESSAC", cp : "12520" },
{ code : "12003", intitule : "LES ALBRES", cp : "12220" },
{ code : "12004", intitule : "ALMONT LES JUNIES", cp : "12300" },
{ code : "12006", intitule : "ALRANCE", cp : "12430" },
{ code : "12007", intitule : "AMBEYRAC", cp : "12260" },
{ code : "12008", intitule : "ANGLARS ST FELIX", cp : "12390" },
{ code : "12009", intitule : "ARNAC SUR DOURDOU", cp : "12360" },
{ code : "12010", intitule : "ARQUES", cp : "12290" },
{ code : "12011", intitule : "ARVIEU", cp : "12120" },
{ code : "12012", intitule : "ASPRIERES", cp : "12700" },
{ code : "12013", intitule : "AUBIN", cp : "12110" },
{ code : "12013", intitule : "AUBIN", cp : "12110" },
{ code : "12013", intitule : "AUBIN", cp : "12110" },
{ code : "12015", intitule : "AURIAC LAGAST", cp : "12120" },
{ code : "12016", intitule : "AUZITS", cp : "12390" },
{ code : "12017", intitule : "AYSSENES", cp : "12430" },
{ code : "12018", intitule : "BALAGUIER D OLT", cp : "12260" },
{ code : "12019", intitule : "BALAGUIER SUR RANCE", cp : "12380" },
{ code : "12020", intitule : "BALSAC", cp : "12510" },
{ code : "12021", intitule : "LE BAS SEGALA", cp : "12200" },
{ code : "12021", intitule : "LE BAS SEGALA", cp : "12200" },
{ code : "12021", intitule : "LE BAS SEGALA", cp : "12240" },
{ code : "12022", intitule : "LA BASTIDE PRADINES", cp : "12490" },
{ code : "12023", intitule : "LA BASTIDE SOLAGES", cp : "12550" },
{ code : "12024", intitule : "BELCASTEL", cp : "12390" },
{ code : "12025", intitule : "BELMONT SUR RANCE", cp : "12370" },
{ code : "12026", intitule : "BERTHOLENE", cp : "12310" },
{ code : "12027", intitule : "BESSUEJOULS", cp : "12500" },
{ code : "12028", intitule : "BOISSE PENCHOT", cp : "12300" },
{ code : "12029", intitule : "BOR ET BAR", cp : "12270" },
{ code : "12030", intitule : "BOUILLAC", cp : "12300" },
{ code : "12031", intitule : "BOURNAZEL", cp : "12390" },
{ code : "12032", intitule : "BOUSSAC", cp : "12160" },
{ code : "12033", intitule : "BOZOULS", cp : "12340" },
{ code : "12034", intitule : "BRANDONNET", cp : "12350" },
{ code : "12035", intitule : "BRASC", cp : "12550" },
{ code : "12036", intitule : "BROMMAT", cp : "12600" },
{ code : "12037", intitule : "BROQUIES", cp : "12480" },
{ code : "12038", intitule : "BROUSSE LE CHATEAU", cp : "12480" },
{ code : "12039", intitule : "BRUSQUE", cp : "12360" },
{ code : "12041", intitule : "CABANES", cp : "12800" },
{ code : "12042", intitule : "CALMELS ET LE VIALA", cp : "12400" },
{ code : "12043", intitule : "CALMONT", cp : "12450" },
{ code : "12043", intitule : "CALMONT", cp : "12450" },
{ code : "12044", intitule : "CAMARES", cp : "12360" },
{ code : "12045", intitule : "CAMBOULAZET", cp : "12160" },
{ code : "12046", intitule : "CAMJAC", cp : "12800" },
{ code : "12047", intitule : "CAMPAGNAC", cp : "12560" },
{ code : "12048", intitule : "CAMPOURIEZ", cp : "12140" },
{ code : "12048", intitule : "CAMPOURIEZ", cp : "12460" },
{ code : "12048", intitule : "CAMPOURIEZ", cp : "12140" },
{ code : "12049", intitule : "CAMPUAC", cp : "12580" },
{ code : "12050", intitule : "CANET DE SALARS", cp : "12290" },
{ code : "12051", intitule : "CANTOIN", cp : "12420" },
{ code : "12052", intitule : "CAPDENAC GARE", cp : "12700" },
{ code : "12053", intitule : "LA CAPELLE BALAGUIER", cp : "12260" },
{ code : "12054", intitule : "LA CAPELLE BLEYS", cp : "12240" },
{ code : "12055", intitule : "LA CAPELLE BONANCE", cp : "12130" },
{ code : "12056", intitule : "BARAQUEVILLE", cp : "12160" },
{ code : "12056", intitule : "BARAQUEVILLE", cp : "12160" },
{ code : "12057", intitule : "CASSAGNES BEGONHES", cp : "12120" },
{ code : "12058", intitule : "CASSUEJOULS", cp : "12210" },
{ code : "12059", intitule : "CASTANET", cp : "12240" },
{ code : "12060", intitule : "CASTELMARY", cp : "12800" },
{ code : "12061", intitule : "CASTELNAU DE MANDAILLES", cp : "12500" },
{ code : "12062", intitule : "CASTELNAU PEGAYROLS", cp : "12620" },
{ code : "12063", intitule : "LA CAVALERIE", cp : "12230" },
{ code : "12064", intitule : "LE CAYROL", cp : "12500" },
{ code : "12065", intitule : "CENTRES", cp : "12120" },
{ code : "12066", intitule : "CLAIRVAUX D AVEYRON", cp : "12330" },
{ code : "12067", intitule : "LE CLAPIER", cp : "12540" },
{ code : "12068", intitule : "COLOMBIES", cp : "12240" },
{ code : "12069", intitule : "COMBRET", cp : "12370" },
{ code : "12070", intitule : "COMPEYRE", cp : "12520" },
{ code : "12071", intitule : "COMPOLIBAT", cp : "12350" },
{ code : "12072", intitule : "COMPREGNAC", cp : "12100" },
{ code : "12073", intitule : "COMPS LA GRAND VILLE", cp : "12120" },
{ code : "12074", intitule : "CONDOM D AUBRAC", cp : "12470" },
{ code : "12075", intitule : "CONNAC", cp : "12170" },
{ code : "12076", intitule : "CONQUES EN ROUERGUE", cp : "12320" },
{ code : "12076", intitule : "CONQUES EN ROUERGUE", cp : "12320" },
{ code : "12076", intitule : "CONQUES EN ROUERGUE", cp : "12320" },
{ code : "12076", intitule : "CONQUES EN ROUERGUE", cp : "12320" },
{ code : "12077", intitule : "CORNUS", cp : "12540" },
{ code : "12078", intitule : "LES COSTES GOZON", cp : "12400" },
{ code : "12079", intitule : "COUBISOU", cp : "12190" },
{ code : "12080", intitule : "COUPIAC", cp : "12550" },
{ code : "12082", intitule : "LA COUVERTOIRADE", cp : "12230" },
{ code : "12083", intitule : "CRANSAC", cp : "12110" },
{ code : "12084", intitule : "CREISSELS", cp : "12100" },
{ code : "12085", intitule : "CRESPIN", cp : "12800" },
{ code : "12086", intitule : "LA CRESSE", cp : "12640" },
{ code : "12088", intitule : "CURIERES", cp : "12210" },
{ code : "12089", intitule : "DECAZEVILLE", cp : "12300" },
{ code : "12090", intitule : "DRUELLE", cp : "12510" },
{ code : "12090", intitule : "DRUELLE", cp : "12000" },
{ code : "12090", intitule : "DRUELLE", cp : "12000" },
{ code : "12091", intitule : "DRULHE", cp : "12350" },
{ code : "12092", intitule : "DURENQUE", cp : "12170" },
{ code : "12093", intitule : "LE FEL", cp : "12140" },
{ code : "12094", intitule : "ENTRAYGUES SUR TRUYERE", cp : "12140" },
{ code : "12095", intitule : "ESCANDOLIERES", cp : "12390" },
{ code : "12096", intitule : "ESPALION", cp : "12500" },
{ code : "12097", intitule : "ESPEYRAC", cp : "12140" },
{ code : "12098", intitule : "ESTAING", cp : "12190" },
{ code : "12099", intitule : "FAYET", cp : "12360" },
{ code : "12100", intitule : "FIRMI", cp : "12300" },
{ code : "12101", intitule : "FLAGNAC", cp : "12300" },
{ code : "12102", intitule : "FLAVIN", cp : "12450" },
{ code : "12103", intitule : "FLORENTIN LA CAPELLE", cp : "12140" },
{ code : "12104", intitule : "FOISSAC", cp : "12260" },
{ code : "12105", intitule : "LA FOUILLADE", cp : "12270" },
{ code : "12106", intitule : "GABRIAC", cp : "12340" },
{ code : "12107", intitule : "GAILLAC D AVEYRON", cp : "12310" },
{ code : "12108", intitule : "GALGAN", cp : "12220" },
{ code : "12109", intitule : "GISSAC", cp : "12360" },
{ code : "12110", intitule : "GOLINHAC", cp : "12140" },
{ code : "12111", intitule : "GOUTRENS", cp : "12390" },
{ code : "12113", intitule : "GRAMOND", cp : "12160" },
{ code : "12115", intitule : "L HOSPITALET DU LARZAC", cp : "12230" },
{ code : "12116", intitule : "HUPARLAC", cp : "12460" },
{ code : "12118", intitule : "LACROIX BARREZ", cp : "12600" },
{ code : "12119", intitule : "LAGUIOLE", cp : "12210" },
{ code : "12120", intitule : "LAISSAC SEVERAC L EGLISE", cp : "12310" },
{ code : "12120", intitule : "LAISSAC SEVERAC L EGLISE", cp : "12310" },
{ code : "12121", intitule : "LANUEJOULS", cp : "12350" },
{ code : "12122", intitule : "LAPANOUSE DE CERNON", cp : "12230" },
{ code : "12124", intitule : "LASSOUTS", cp : "12500" },
{ code : "12125", intitule : "LAVAL ROQUECEZIERE", cp : "12380" },
{ code : "12127", intitule : "LEDERGUES", cp : "12170" },
{ code : "12128", intitule : "LESCURE JAOUL", cp : "12440" },
{ code : "12129", intitule : "LESTRADE ET THOUELS", cp : "12430" },
{ code : "12130", intitule : "LIVINHAC LE HAUT", cp : "12300" },
{ code : "12131", intitule : "LA LOUBIERE", cp : "12740" },
{ code : "12133", intitule : "LUC LA PRIMAUBE", cp : "12450" },
{ code : "12133", intitule : "LUC LA PRIMAUBE", cp : "12450" },
{ code : "12134", intitule : "LUGAN", cp : "12220" },
{ code : "12135", intitule : "LUNAC", cp : "12270" },
{ code : "12136", intitule : "MALEVILLE", cp : "12350" },
{ code : "12137", intitule : "MANHAC", cp : "12160" },
{ code : "12138", intitule : "MARCILLAC VALLON", cp : "12330" },
{ code : "12139", intitule : "MARNHAGUES ET LATOUR", cp : "12540" },
{ code : "12140", intitule : "MARTIEL", cp : "12200" },
{ code : "12141", intitule : "MARTRIN", cp : "12550" },
{ code : "12142", intitule : "MAYRAN", cp : "12390" },
{ code : "12143", intitule : "MELAGUES", cp : "12360" },
{ code : "12144", intitule : "MELJAC", cp : "12120" },
{ code : "12145", intitule : "MILLAU", cp : "12100" },
{ code : "12146", intitule : "LE MONASTERE", cp : "12000" },
{ code : "12147", intitule : "MONTAGNOL", cp : "12360" },
{ code : "12147", intitule : "MONTAGNOL", cp : "12360" },
{ code : "12148", intitule : "MONTBAZENS", cp : "12220" },
{ code : "12149", intitule : "MONTCLAR", cp : "12550" },
{ code : "12150", intitule : "MONTEILS", cp : "12200" },
{ code : "12151", intitule : "MONTEZIC", cp : "12460" },
{ code : "12152", intitule : "MONTFRANC", cp : "12380" },
{ code : "12153", intitule : "MONTJAUX", cp : "12490" },
{ code : "12154", intitule : "MONTLAUR", cp : "12400" },
{ code : "12155", intitule : "FONDAMENTE", cp : "12540" },
{ code : "12156", intitule : "MONTPEYROUX", cp : "12210" },
{ code : "12157", intitule : "MONTROZIER", cp : "12630" },
{ code : "12157", intitule : "MONTROZIER", cp : "12630" },
{ code : "12158", intitule : "MONTSALES", cp : "12260" },
{ code : "12159", intitule : "MORLHON LE HAUT", cp : "12200" },
{ code : "12160", intitule : "MOSTUEJOULS", cp : "12720" },
{ code : "12161", intitule : "MOURET", cp : "12330" },
{ code : "12162", intitule : "MOYRAZES", cp : "12160" },
{ code : "12163", intitule : "MURASSON", cp : "12370" },
{ code : "12164", intitule : "MUR DE BARREZ", cp : "12600" },
{ code : "12165", intitule : "MURET LE CHATEAU", cp : "12330" },
{ code : "12166", intitule : "MUROLS", cp : "12600" },
{ code : "12167", intitule : "NAJAC", cp : "12270" },
{ code : "12168", intitule : "NANT", cp : "12230" },
{ code : "12169", intitule : "NAUCELLE", cp : "12800" },
{ code : "12170", intitule : "NAUSSAC", cp : "12700" },
{ code : "12171", intitule : "NAUVIALE", cp : "12330" },
{ code : "12172", intitule : "LE NAYRAC", cp : "12190" },
{ code : "12174", intitule : "OLEMPS", cp : "12510" },
{ code : "12175", intitule : "OLS ET RINHODES", cp : "12260" },
{ code : "12176", intitule : "ONET LE CHATEAU", cp : "12850" },
{ code : "12176", intitule : "ONET LE CHATEAU", cp : "12850" },
{ code : "12176", intitule : "ONET LE CHATEAU", cp : "12000" },
{ code : "12177", intitule : "PALMAS D AVEYRON", cp : "12340" },
{ code : "12177", intitule : "PALMAS D AVEYRON", cp : "12310" },
{ code : "12177", intitule : "PALMAS D AVEYRON", cp : "12310" },
{ code : "12178", intitule : "PAULHE", cp : "12520" },
{ code : "12179", intitule : "PEUX ET COUFFOULEUX", cp : "12360" },
{ code : "12180", intitule : "PEYRELEAU", cp : "12720" },
{ code : "12181", intitule : "PEYRUSSE LE ROC", cp : "12220" },
{ code : "12182", intitule : "PIERREFICHE", cp : "12130" },
{ code : "12183", intitule : "PLAISANCE", cp : "12550" },
{ code : "12184", intitule : "POMAYROLS", cp : "12130" },
{ code : "12185", intitule : "PONT DE SALARS", cp : "12290" },
{ code : "12186", intitule : "POUSTHOMY", cp : "12380" },
{ code : "12187", intitule : "PRADES D AUBRAC", cp : "12470" },
{ code : "12188", intitule : "PRADES SALARS", cp : "12290" },
{ code : "12189", intitule : "PRADINAS", cp : "12240" },
{ code : "12190", intitule : "PREVINQUIERES", cp : "12350" },
{ code : "12191", intitule : "PRIVEZAC", cp : "12350" },
{ code : "12192", intitule : "MOUNES PROHENCOUX", cp : "12370" },
{ code : "12193", intitule : "PRUINES", cp : "12320" },
{ code : "12194", intitule : "QUINS", cp : "12800" },
{ code : "12194", intitule : "QUINS", cp : "12800" },
{ code : "12195", intitule : "REBOURGUIL", cp : "12400" },
{ code : "12197", intitule : "REQUISTA", cp : "12170" },
{ code : "12198", intitule : "RIEUPEYROUX", cp : "12240" },
{ code : "12199", intitule : "RIGNAC", cp : "12390" },
{ code : "12200", intitule : "RIVIERE SUR TARN", cp : "12640" },
{ code : "12200", intitule : "RIVIERE SUR TARN", cp : "12640" },
{ code : "12201", intitule : "RODELLE", cp : "12340" },
{ code : "12202", intitule : "RODEZ", cp : "12000" },
{ code : "12203", intitule : "ROQUEFORT SUR SOULZON", cp : "12250" },
{ code : "12204", intitule : "LA ROQUE STE MARGUERITE", cp : "12100" },
{ code : "12205", intitule : "LA ROUQUETTE", cp : "12200" },
{ code : "12206", intitule : "ROUSSENNAC", cp : "12220" },
{ code : "12207", intitule : "RULLAC ST CIRQ", cp : "12120" },
{ code : "12208", intitule : "ST AFFRIQUE", cp : "12400" },
{ code : "12209", intitule : "ST AMANS DES COTS", cp : "12460" },
{ code : "12210", intitule : "ST ANDRE DE NAJAC", cp : "12270" },
{ code : "12211", intitule : "ST ANDRE DE VEZINES", cp : "12720" },
{ code : "12212", intitule : "ST BEAULIZE", cp : "12540" },
{ code : "12213", intitule : "ST BEAUZELY", cp : "12620" },
{ code : "12214", intitule : "ST CHELY D AUBRAC", cp : "12470" },
{ code : "12214", intitule : "ST CHELY D AUBRAC", cp : "12470" },
{ code : "12215", intitule : "ST CHRISTOPHE VALLON", cp : "12330" },
{ code : "12216", intitule : "ST COME D OLT", cp : "12500" },
{ code : "12217", intitule : "STE CROIX", cp : "12260" },
{ code : "12219", intitule : "STE EULALIE D OLT", cp : "12130" },
{ code : "12220", intitule : "STE EULALIE DE CERNON", cp : "12230" },
{ code : "12221", intitule : "ST FELIX DE LUNEL", cp : "12320" },
{ code : "12222", intitule : "ST FELIX DE SORGUES", cp : "12400" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12210" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12210" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12210" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12420" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12420" },
{ code : "12223", intitule : "ARGENCES EN AUBRAC", cp : "12420" },
{ code : "12224", intitule : "ST GENIEZ D OLT ET D AUBRAC", cp : "12130" },
{ code : "12224", intitule : "ST GENIEZ D OLT ET D AUBRAC", cp : "12130" },
{ code : "12225", intitule : "ST GEORGES DE LUZENCON", cp : "12100" },
{ code : "12226", intitule : "ST HIPPOLYTE", cp : "12140" },
{ code : "12226", intitule : "ST HIPPOLYTE", cp : "12140" },
{ code : "12227", intitule : "ST IGEST", cp : "12260" },
{ code : "12228", intitule : "ST IZAIRE", cp : "12480" },
{ code : "12229", intitule : "ST JEAN D ALCAPIES", cp : "12250" },
{ code : "12230", intitule : "ST JEAN DELNOUS", cp : "12170" },
{ code : "12231", intitule : "ST JEAN DU BRUEL", cp : "12230" },
{ code : "12232", intitule : "ST JEAN ET ST PAUL", cp : "12250" },
{ code : "12233", intitule : "ST JUERY", cp : "12550" },
{ code : "12234", intitule : "STE JULIETTE SUR VIAUR", cp : "12120" },
{ code : "12235", intitule : "ST JUST SUR VIAUR", cp : "12170" },
{ code : "12235", intitule : "ST JUST SUR VIAUR", cp : "12800" },
{ code : "12236", intitule : "ST LAURENT DE LEVEZOU", cp : "12620" },
{ code : "12237", intitule : "ST LAURENT D OLT", cp : "12560" },
{ code : "12238", intitule : "ST LEONS", cp : "12780" },
{ code : "12239", intitule : "ST MARTIN DE LENNE", cp : "12130" },
{ code : "12240", intitule : "ST PARTHEM", cp : "12300" },
{ code : "12241", intitule : "STE RADEGONDE", cp : "12850" },
{ code : "12242", intitule : "ST REMY", cp : "12200" },
{ code : "12243", intitule : "ST ROME DE CERNON", cp : "12490" },
{ code : "12244", intitule : "ST ROME DE TARN", cp : "12490" },
{ code : "12246", intitule : "ST SANTIN", cp : "12300" },
{ code : "12247", intitule : "ST SATURNIN DE LENNE", cp : "12560" },
{ code : "12248", intitule : "ST SERNIN SUR RANCE", cp : "12380" },
{ code : "12249", intitule : "ST SEVER DU MOUSTIER", cp : "12370" },
{ code : "12250", intitule : "ST SYMPHORIEN DE THENIERES", cp : "12460" },
{ code : "12250", intitule : "ST SYMPHORIEN DE THENIERES", cp : "12460" },
{ code : "12251", intitule : "ST VICTOR ET MELVIEU", cp : "12400" },
{ code : "12251", intitule : "ST VICTOR ET MELVIEU", cp : "12400" },
{ code : "12252", intitule : "SALLES COURBATIES", cp : "12260" },
{ code : "12253", intitule : "SALLES CURAN", cp : "12410" },
{ code : "12254", intitule : "SALLES LA SOURCE", cp : "12330" },
{ code : "12255", intitule : "SALMIECH", cp : "12120" },
{ code : "12256", intitule : "SALVAGNAC CAJARC", cp : "12260" },
{ code : "12257", intitule : "CAUSSE ET DIEGE", cp : "12700" },
{ code : "12257", intitule : "CAUSSE ET DIEGE", cp : "12700" },
{ code : "12257", intitule : "CAUSSE ET DIEGE", cp : "12700" },
{ code : "12258", intitule : "LA SALVETAT PEYRALES", cp : "12440" },
{ code : "12259", intitule : "SANVENSA", cp : "12200" },
{ code : "12260", intitule : "SAUCLIERES", cp : "12230" },
{ code : "12261", intitule : "SAUJAC", cp : "12260" },
{ code : "12262", intitule : "SAUVETERRE DE ROUERGUE", cp : "12800" },
{ code : "12263", intitule : "SAVIGNAC", cp : "12200" },
{ code : "12264", intitule : "SEBAZAC CONCOURES", cp : "12740" },
{ code : "12265", intitule : "SEBRAZAC", cp : "12190" },
{ code : "12266", intitule : "SEGUR", cp : "12290" },
{ code : "12267", intitule : "LA SELVE", cp : "12170" },
{ code : "12268", intitule : "SENERGUES", cp : "12320" },
{ code : "12269", intitule : "LA SERRE", cp : "12380" },
{ code : "12270", intitule : "SEVERAC D AVEYRON", cp : "12150" },
{ code : "12270", intitule : "SEVERAC D AVEYRON", cp : "12150" },
{ code : "12270", intitule : "SEVERAC D AVEYRON", cp : "12150" },
{ code : "12270", intitule : "SEVERAC D AVEYRON", cp : "12150" },
{ code : "12270", intitule : "SEVERAC D AVEYRON", cp : "12150" },
{ code : "12272", intitule : "SONNAC", cp : "12700" },
{ code : "12273", intitule : "SOULAGES BONNEVAL", cp : "12210" },
{ code : "12274", intitule : "SYLVANES", cp : "12360" },
{ code : "12275", intitule : "TAURIAC DE CAMARES", cp : "12360" },
{ code : "12276", intitule : "TAURIAC DE NAUCELLE", cp : "12800" },
{ code : "12277", intitule : "TAUSSAC", cp : "12600" },
{ code : "12278", intitule : "TAYRAC", cp : "12440" },
{ code : "12280", intitule : "THERONDELS", cp : "12600" },
{ code : "12281", intitule : "TOULONJAC", cp : "12200" },
{ code : "12282", intitule : "TOURNEMIRE", cp : "12250" },
{ code : "12283", intitule : "TREMOUILLES", cp : "12290" },
{ code : "12284", intitule : "LE TRUEL", cp : "12430" },
{ code : "12286", intitule : "VABRES L ABBAYE", cp : "12400" },
{ code : "12287", intitule : "VAILHOURLES", cp : "12200" },
{ code : "12288", intitule : "VALADY", cp : "12330" },
{ code : "12288", intitule : "VALADY", cp : "12330" },
{ code : "12289", intitule : "VALZERGUES", cp : "12220" },
{ code : "12290", intitule : "VAUREILLES", cp : "12220" },
{ code : "12291", intitule : "VERRIERES", cp : "12520" },
{ code : "12292", intitule : "VERSOLS ET LAPEYRE", cp : "12400" },
{ code : "12293", intitule : "VEYREAU", cp : "12720" },
{ code : "12294", intitule : "VEZINS DE LEVEZOU", cp : "12780" },
{ code : "12295", intitule : "VIALA DU PAS DE JAUX", cp : "12250" },
{ code : "12296", intitule : "VIALA DU TARN", cp : "12490" },
{ code : "12297", intitule : "LE VIBAL", cp : "12290" },
{ code : "12298", intitule : "VILLECOMTAL", cp : "12580" },
{ code : "12299", intitule : "VILLEFRANCHE DE PANAT", cp : "12430" },
{ code : "12300", intitule : "VILLEFRANCHE DE ROUERGUE", cp : "12200" },
{ code : "12301", intitule : "VILLENEUVE", cp : "12260" },
{ code : "12303", intitule : "VIMENET", cp : "12310" },
{ code : "12305", intitule : "VIVIEZ", cp : "12110" },
{ code : "12307", intitule : "CURAN", cp : "12410" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13540" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13290" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13080" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13100" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13090" },
{ code : "13001", intitule : "AIX EN PROVENCE", cp : "13100" },
{ code : "13002", intitule : "ALLAUCH", cp : "13190" },
{ code : "13002", intitule : "ALLAUCH", cp : "13190" },
{ code : "13003", intitule : "ALLEINS", cp : "13980" },
{ code : "13003", intitule : "ALLEINS", cp : "13980" },
{ code : "13004", intitule : "ARLES", cp : "13129" },
{ code : "13004", intitule : "ARLES", cp : "13280" },
{ code : "13004", intitule : "ARLES", cp : "13123" },
{ code : "13004", intitule : "ARLES", cp : "13200" },
{ code : "13004", intitule : "ARLES", cp : "13104" },
{ code : "13004", intitule : "ARLES", cp : "13280" },
{ code : "13004", intitule : "ARLES", cp : "13200" },
{ code : "13005", intitule : "AUBAGNE", cp : "13400" },
{ code : "13005", intitule : "AUBAGNE", cp : "13400" },
{ code : "13006", intitule : "AUREILLE", cp : "13930" },
{ code : "13007", intitule : "AURIOL", cp : "13390" },
{ code : "13008", intitule : "AURONS", cp : "13121" },
{ code : "13009", intitule : "LA BARBEN", cp : "13330" },
{ code : "13010", intitule : "BARBENTANE", cp : "13570" },
{ code : "13011", intitule : "LES BAUX DE PROVENCE", cp : "13520" },
{ code : "13012", intitule : "BEAURECUEIL", cp : "13100" },
{ code : "13013", intitule : "BELCODENE", cp : "13720" },
{ code : "13013", intitule : "BELCODENE", cp : "13720" },
{ code : "13014", intitule : "BERRE L ETANG", cp : "13130" },
{ code : "13015", intitule : "BOUC BEL AIR", cp : "13320" },
{ code : "13016", intitule : "LA BOUILLADISSE", cp : "13720" },
{ code : "13016", intitule : "LA BOUILLADISSE", cp : "13720" },
{ code : "13016", intitule : "LA BOUILLADISSE", cp : "13720" },
{ code : "13016", intitule : "LA BOUILLADISSE", cp : "13720" },
{ code : "13017", intitule : "BOULBON", cp : "13150" },
{ code : "13018", intitule : "CABANNES", cp : "13440" },
{ code : "13019", intitule : "CABRIES", cp : "13480" },
{ code : "13019", intitule : "CABRIES", cp : "13480" },
{ code : "13020", intitule : "CADOLIVE", cp : "13950" },
{ code : "13021", intitule : "CARRY LE ROUET", cp : "13620" },
{ code : "13021", intitule : "CARRY LE ROUET", cp : "13620" },
{ code : "13022", intitule : "CASSIS", cp : "13260" },
{ code : "13023", intitule : "CEYRESTE", cp : "13600" },
{ code : "13024", intitule : "CHARLEVAL", cp : "13350" },
{ code : "13025", intitule : "CHATEAUNEUF LE ROUGE", cp : "13790" },
{ code : "13026", intitule : "CHATEAUNEUF LES MARTIGUES", cp : "13220" },
{ code : "13026", intitule : "CHATEAUNEUF LES MARTIGUES", cp : "13220" },
{ code : "13027", intitule : "CHATEAURENARD", cp : "13160" },
{ code : "13028", intitule : "LA CIOTAT", cp : "13600" },
{ code : "13029", intitule : "CORNILLON CONFOUX", cp : "13250" },
{ code : "13030", intitule : "CUGES LES PINS", cp : "13780" },
{ code : "13031", intitule : "LA DESTROUSSE", cp : "13112" },
{ code : "13032", intitule : "EGUILLES", cp : "13510" },
{ code : "13033", intitule : "ENSUES LA REDONNE", cp : "13820" },
{ code : "13033", intitule : "ENSUES LA REDONNE", cp : "13820" },
{ code : "13034", intitule : "EYGALIERES", cp : "13810" },
{ code : "13035", intitule : "EYGUIERES", cp : "13430" },
{ code : "13036", intitule : "EYRAGUES", cp : "13630" },
{ code : "13037", intitule : "LA FARE LES OLIVIERS", cp : "13580" },
{ code : "13038", intitule : "FONTVIEILLE", cp : "13990" },
{ code : "13039", intitule : "FOS SUR MER", cp : "13270" },
{ code : "13040", intitule : "FUVEAU", cp : "13710" },
{ code : "13040", intitule : "FUVEAU", cp : "13710" },
{ code : "13040", intitule : "FUVEAU", cp : "13710" },
{ code : "13041", intitule : "GARDANNE", cp : "13120" },
{ code : "13041", intitule : "GARDANNE", cp : "13120" },
{ code : "13041", intitule : "GARDANNE", cp : "13120" },
{ code : "13042", intitule : "GEMENOS", cp : "13420" },
{ code : "13043", intitule : "GIGNAC LA NERTHE", cp : "13180" },
{ code : "13043", intitule : "GIGNAC LA NERTHE", cp : "13180" },
{ code : "13044", intitule : "GRANS", cp : "13450" },
{ code : "13045", intitule : "GRAVESON", cp : "13690" },
{ code : "13046", intitule : "GREASQUE", cp : "13850" },
{ code : "13047", intitule : "ISTRES", cp : "13118" },
{ code : "13047", intitule : "ISTRES", cp : "13800" },
{ code : "13048", intitule : "JOUQUES", cp : "13490" },
{ code : "13049", intitule : "LAMANON", cp : "13113" },
{ code : "13050", intitule : "LAMBESC", cp : "13410" },
{ code : "13051", intitule : "LANCON PROVENCE", cp : "13680" },
{ code : "13052", intitule : "MAILLANE", cp : "13910" },
{ code : "13053", intitule : "MALLEMORT", cp : "13370" },
{ code : "13053", intitule : "MALLEMORT", cp : "13370" },
{ code : "13054", intitule : "MARIGNANE", cp : "13700" },
{ code : "13056", intitule : "MARTIGUES", cp : "13500" },
{ code : "13056", intitule : "MARTIGUES", cp : "13117" },
{ code : "13056", intitule : "MARTIGUES", cp : "13500" },
{ code : "13056", intitule : "MARTIGUES", cp : "13500" },
{ code : "13056", intitule : "MARTIGUES", cp : "13500" },
{ code : "13057", intitule : "MAS BLANC DES ALPILLES", cp : "13103" },
{ code : "13058", intitule : "MAUSSANE LES ALPILLES", cp : "13520" },
{ code : "13059", intitule : "MEYRARGUES", cp : "13650" },
{ code : "13060", intitule : "MEYREUIL", cp : "13590" },
{ code : "13061", intitule : "ST PIERRE DE MEZOARGUES", cp : "13150" },
{ code : "13062", intitule : "MIMET", cp : "13105" },
{ code : "13063", intitule : "MIRAMAS", cp : "13140" },
{ code : "13064", intitule : "MOLLEGES", cp : "13940" },
{ code : "13065", intitule : "MOURIES", cp : "13890" },
{ code : "13066", intitule : "NOVES", cp : "13550" },
{ code : "13066", intitule : "NOVES", cp : "13550" },
{ code : "13067", intitule : "ORGON", cp : "13660" },
{ code : "13068", intitule : "PARADOU", cp : "13520" },
{ code : "13069", intitule : "PELISSANNE", cp : "13330" },
{ code : "13070", intitule : "LA PENNE SUR HUVEAUNE", cp : "13821" },
{ code : "13070", intitule : "LA PENNE SUR HUVEAUNE", cp : "13821" },
{ code : "13071", intitule : "LES PENNES MIRABEAU", cp : "13170" },
{ code : "13071", intitule : "LES PENNES MIRABEAU", cp : "13170" },
{ code : "13071", intitule : "LES PENNES MIRABEAU", cp : "13170" },
{ code : "13072", intitule : "PEYNIER", cp : "13790" },
{ code : "13072", intitule : "PEYNIER", cp : "13790" },
{ code : "13073", intitule : "PEYPIN", cp : "13124" },
{ code : "13074", intitule : "PEYROLLES EN PROVENCE", cp : "13860" },
{ code : "13075", intitule : "PLAN DE CUQUES", cp : "13380" },
{ code : "13076", intitule : "PLAN D ORGON", cp : "13750" },
{ code : "13077", intitule : "PORT DE BOUC", cp : "13110" },
{ code : "13078", intitule : "PORT ST LOUIS DU RHONE", cp : "13230" },
{ code : "13079", intitule : "PUYLOUBIER", cp : "13114" },
{ code : "13079", intitule : "PUYLOUBIER", cp : "13114" },
{ code : "13080", intitule : "LE PUY STE REPARADE", cp : "13610" },
{ code : "13081", intitule : "ROGNAC", cp : "13340" },
{ code : "13082", intitule : "ROGNES", cp : "13840" },
{ code : "13083", intitule : "ROGNONAS", cp : "13870" },
{ code : "13084", intitule : "LA ROQUE D ANTHERON", cp : "13640" },
{ code : "13085", intitule : "ROQUEFORT LA BEDOULE", cp : "13830" },
{ code : "13085", intitule : "ROQUEFORT LA BEDOULE", cp : "13830" },
{ code : "13086", intitule : "ROQUEVAIRE", cp : "13360" },
{ code : "13086", intitule : "ROQUEVAIRE", cp : "13360" },
{ code : "13086", intitule : "ROQUEVAIRE", cp : "13360" },
{ code : "13087", intitule : "ROUSSET", cp : "13790" },
{ code : "13088", intitule : "LE ROVE", cp : "13740" },
{ code : "13088", intitule : "LE ROVE", cp : "13740" },
{ code : "13088", intitule : "LE ROVE", cp : "13740" },
{ code : "13089", intitule : "ST ANDIOL", cp : "13670" },
{ code : "13090", intitule : "ST ANTONIN SUR BAYON", cp : "13100" },
{ code : "13091", intitule : "ST CANNAT", cp : "13760" },
{ code : "13092", intitule : "ST CHAMAS", cp : "13250" },
{ code : "13093", intitule : "ST ESTEVE JANSON", cp : "13610" },
{ code : "13094", intitule : "ST ETIENNE DU GRES", cp : "13103" },
{ code : "13095", intitule : "ST MARC JAUMEGARDE", cp : "13100" },
{ code : "13096", intitule : "SAINTES MARIES DE LA MER", cp : "13460" },
{ code : "13097", intitule : "ST MARTIN DE CRAU", cp : "13310" },
{ code : "13098", intitule : "ST MITRE LES REMPARTS", cp : "13920" },
{ code : "13099", intitule : "ST PAUL LES DURANCE", cp : "13115" },
{ code : "13100", intitule : "ST REMY DE PROVENCE", cp : "13210" },
{ code : "13101", intitule : "ST SAVOURNIN", cp : "13119" },
{ code : "13102", intitule : "ST VICTORET", cp : "13700" },
{ code : "13102", intitule : "ST VICTORET", cp : "13730" },
{ code : "13103", intitule : "SALON DE PROVENCE", cp : "13300" },
{ code : "13104", intitule : "SAUSSET LES PINS", cp : "13960" },
{ code : "13105", intitule : "SENAS", cp : "13560" },
{ code : "13106", intitule : "SEPTEMES LES VALLONS", cp : "13240" },
{ code : "13106", intitule : "SEPTEMES LES VALLONS", cp : "13240" },
{ code : "13107", intitule : "SIMIANE COLLONGUE", cp : "13109" },
{ code : "13108", intitule : "TARASCON", cp : "13150" },
{ code : "13109", intitule : "LE THOLONET", cp : "13100" },
{ code : "13110", intitule : "TRETS", cp : "13530" },
{ code : "13110", intitule : "TRETS", cp : "13530" },
{ code : "13111", intitule : "VAUVENARGUES", cp : "13126" },
{ code : "13112", intitule : "VELAUX", cp : "13880" },
{ code : "13113", intitule : "VENELLES", cp : "13770" },
{ code : "13114", intitule : "VENTABREN", cp : "13122" },
{ code : "13114", intitule : "VENTABREN", cp : "13122" },
{ code : "13115", intitule : "VERNEGUES", cp : "13116" },
{ code : "13116", intitule : "VERQUIERES", cp : "13670" },
{ code : "13117", intitule : "VITROLLES", cp : "13127" },
{ code : "13118", intitule : "COUDOUX", cp : "13111" },
{ code : "13119", intitule : "CARNOUX EN PROVENCE", cp : "13470" },
{ code : "13201", intitule : "MARSEILLE 01", cp : "13001" },
{ code : "13202", intitule : "MARSEILLE 02", cp : "13002" },
{ code : "13203", intitule : "MARSEILLE 03", cp : "13003" },
{ code : "13204", intitule : "MARSEILLE 04", cp : "13004" },
{ code : "13205", intitule : "MARSEILLE 05", cp : "13005" },
{ code : "13206", intitule : "MARSEILLE 06", cp : "13006" },
{ code : "13207", intitule : "MARSEILLE 07", cp : "13007" },
{ code : "13208", intitule : "MARSEILLE 08", cp : "13008" },
{ code : "13208", intitule : "MARSEILLE 08", cp : "13008" },
{ code : "13208", intitule : "MARSEILLE 08", cp : "13008" },
{ code : "13209", intitule : "MARSEILLE 09", cp : "13009" },
{ code : "13209", intitule : "MARSEILLE 09", cp : "13009" },
{ code : "13209", intitule : "MARSEILLE 09", cp : "13009" },
{ code : "13210", intitule : "MARSEILLE 10", cp : "13010" },
{ code : "13211", intitule : "MARSEILLE 11", cp : "13011" },
{ code : "13211", intitule : "MARSEILLE 11", cp : "13011" },
{ code : "13212", intitule : "MARSEILLE 12", cp : "13012" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13213", intitule : "MARSEILLE 13", cp : "13013" },
{ code : "13214", intitule : "MARSEILLE 14", cp : "13014" },
{ code : "13215", intitule : "MARSEILLE 15", cp : "13015" },
{ code : "13216", intitule : "MARSEILLE 16", cp : "13016" },
{ code : "13216", intitule : "MARSEILLE 16", cp : "13016" },
{ code : "14001", intitule : "ABLON", cp : "14600" },
{ code : "14002", intitule : "ACQUEVILLE", cp : "14220" },
{ code : "14003", intitule : "AGY", cp : "14400" },
{ code : "14004", intitule : "AIGNERVILLE", cp : "14710" },
{ code : "14005", intitule : "AIRAN", cp : "14370" },
{ code : "14006", intitule : "AMAYE SUR ORNE", cp : "14210" },
{ code : "14007", intitule : "AMAYE SUR SEULLES", cp : "14310" },
{ code : "14008", intitule : "AMBLIE", cp : "14480" },
{ code : "14009", intitule : "AMFREVILLE", cp : "14860" },
{ code : "14011", intitule : "ANCTOVILLE", cp : "14240" },
{ code : "14011", intitule : "ANCTOVILLE", cp : "14240" },
{ code : "14011", intitule : "ANCTOVILLE", cp : "14240" },
{ code : "14011", intitule : "ANCTOVILLE", cp : "14240" },
{ code : "14012", intitule : "ANGERVILLE", cp : "14430" },
{ code : "14013", intitule : "ANGOVILLE", cp : "14220" },
{ code : "14014", intitule : "COLOMBY ANGUERNY", cp : "14610" },
{ code : "14014", intitule : "COLOMBY ANGUERNY", cp : "14610" },
{ code : "14015", intitule : "ANISY", cp : "14610" },
{ code : "14016", intitule : "ANNEBAULT", cp : "14430" },
{ code : "14019", intitule : "ARGANCHY", cp : "14400" },
{ code : "14020", intitule : "ARGENCES", cp : "14370" },
{ code : "14021", intitule : "ARROMANCHES LES BAINS", cp : "14117" },
{ code : "14022", intitule : "ASNELLES", cp : "14960" },
{ code : "14023", intitule : "ASNIERES EN BESSIN", cp : "14710" },
{ code : "14024", intitule : "AUBERVILLE", cp : "14640" },
{ code : "14025", intitule : "AUBIGNY", cp : "14700" },
{ code : "14026", intitule : "AUDRIEU", cp : "14250" },
{ code : "14027", intitule : "AUNAY SUR ODON", cp : "14260" },
{ code : "14030", intitule : "AUTHIE", cp : "14280" },
{ code : "14031", intitule : "LES AUTHIEUX PAPION", cp : "14140" },
{ code : "14032", intitule : "LES AUTHIEUX SUR CALONNE", cp : "14130" },
{ code : "14033", intitule : "AUVILLARS", cp : "14340" },
{ code : "14034", intitule : "AVENAY", cp : "14210" },
{ code : "14035", intitule : "BALLEROY SUR DROME", cp : "14490" },
{ code : "14035", intitule : "BALLEROY SUR DROME", cp : "14490" },
{ code : "14036", intitule : "BANNEVILLE LA CAMPAGNE", cp : "14940" },
{ code : "14037", intitule : "MALHERBE SUR AJON", cp : "14260" },
{ code : "14037", intitule : "MALHERBE SUR AJON", cp : "14260" },
{ code : "14038", intitule : "BANVILLE", cp : "14480" },
{ code : "14039", intitule : "BARBERY", cp : "14220" },
{ code : "14040", intitule : "BARBEVILLE", cp : "14400" },
{ code : "14041", intitule : "BARNEVILLE LA BERTRAN", cp : "14600" },
{ code : "14042", intitule : "BARON SUR ODON", cp : "14210" },
{ code : "14043", intitule : "BAROU EN AUGE", cp : "14620" },
{ code : "14044", intitule : "BASLY", cp : "14610" },
{ code : "14045", intitule : "BASSENEVILLE", cp : "14670" },
{ code : "14046", intitule : "BAVENT", cp : "14860" },
{ code : "14046", intitule : "BAVENT", cp : "14860" },
{ code : "14047", intitule : "BAYEUX", cp : "14400" },
{ code : "14049", intitule : "BAZENVILLE", cp : "14480" },
{ code : "14050", intitule : "LA BAZOQUE", cp : "14490" },
{ code : "14053", intitule : "BEAUMAIS", cp : "14620" },
{ code : "14054", intitule : "BEAUMESNIL", cp : "14380" },
{ code : "14055", intitule : "BEAUMONT EN AUGE", cp : "14950" },
{ code : "14056", intitule : "BAUQUAY", cp : "14260" },
{ code : "14057", intitule : "BELLENGREVILLE", cp : "14370" },
{ code : "14059", intitule : "BENERVILLE SUR MER", cp : "14910" },
{ code : "14060", intitule : "BENOUVILLE", cp : "14970" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14260" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14061", intitule : "SOULEUVRE EN BOCAGE", cp : "14350" },
{ code : "14062", intitule : "BENY SUR MER", cp : "14440" },
{ code : "14063", intitule : "BERNESQ", cp : "14710" },
{ code : "14064", intitule : "BERNIERES D AILLY", cp : "14170" },
{ code : "14066", intitule : "BERNIERES SUR MER", cp : "14990" },
{ code : "14068", intitule : "BIEVILLE BEUVILLE", cp : "14112" },
{ code : "14068", intitule : "BIEVILLE BEUVILLE", cp : "14112" },
{ code : "14069", intitule : "BEUVILLERS", cp : "14100" },
{ code : "14070", intitule : "BEUVRON EN AUGE", cp : "14430" },
{ code : "14073", intitule : "LA BIGNE", cp : "14260" },
{ code : "14074", intitule : "BILLY", cp : "14370" },
{ code : "14075", intitule : "BISSIERES", cp : "14370" },
{ code : "14076", intitule : "BLAINVILLE SUR ORNE", cp : "14550" },
{ code : "14077", intitule : "BLANGY LE CHATEAU", cp : "14130" },
{ code : "14078", intitule : "BLAY", cp : "14400" },
{ code : "14079", intitule : "BLONVILLE SUR MER", cp : "14910" },
{ code : "14080", intitule : "LE BO", cp : "14690" },
{ code : "14081", intitule : "BOISSEY", cp : "14170" },
{ code : "14082", intitule : "LA BOISSIERE", cp : "14340" },
{ code : "14083", intitule : "BONNEBOSQ", cp : "14340" },
{ code : "14084", intitule : "BONNEMAISON", cp : "14260" },
{ code : "14085", intitule : "BONNEVILLE LA LOUVET", cp : "14130" },
{ code : "14086", intitule : "BONNEVILLE SUR TOUQUES", cp : "14800" },
{ code : "14087", intitule : "BONNOEIL", cp : "14700" },
{ code : "14088", intitule : "BONS TASSILLY", cp : "14420" },
{ code : "14089", intitule : "BOUGY", cp : "14210" },
{ code : "14090", intitule : "BOULON", cp : "14220" },
{ code : "14091", intitule : "BOURGEAUVILLE", cp : "14430" },
{ code : "14092", intitule : "BOURGUEBUS", cp : "14540" },
{ code : "14093", intitule : "BRANVILLE", cp : "14430" },
{ code : "14096", intitule : "BREMOY", cp : "14260" },
{ code : "14097", intitule : "BRETTEVILLE LE RABET", cp : "14190" },
{ code : "14098", intitule : "BRETTEVILLE L ORGUEILLEUSE", cp : "14740" },
{ code : "14099", intitule : "BRETTEVILLE SUR DIVES", cp : "14170" },
{ code : "14100", intitule : "BRETTEVILLE SUR LAIZE", cp : "14680" },
{ code : "14101", intitule : "BRETTEVILLE SUR ODON", cp : "14760" },
{ code : "14102", intitule : "LE BREUIL EN AUGE", cp : "14130" },
{ code : "14103", intitule : "LE BREUIL EN BESSIN", cp : "14330" },
{ code : "14104", intitule : "LE BREVEDENT", cp : "14130" },
{ code : "14106", intitule : "BREVILLE LES MONTS", cp : "14860" },
{ code : "14107", intitule : "BRICQUEVILLE", cp : "14710" },
{ code : "14109", intitule : "BROUAY", cp : "14250" },
{ code : "14110", intitule : "BRUCOURT", cp : "14160" },
{ code : "14111", intitule : "BUCEELS", cp : "14250" },
{ code : "14116", intitule : "LE BU SUR ROUVRES", cp : "14190" },
{ code : "14117", intitule : "CABOURG", cp : "14390" },
{ code : "14118", intitule : "CAEN", cp : "14000" },
{ code : "14119", intitule : "CAGNY", cp : "14630" },
{ code : "14120", intitule : "CAHAGNES", cp : "14240" },
{ code : "14121", intitule : "CAHAGNOLLES", cp : "14490" },
{ code : "14122", intitule : "LA CAINE", cp : "14210" },
{ code : "14123", intitule : "CAIRON", cp : "14610" },
{ code : "14124", intitule : "LA CAMBE", cp : "14230" },
{ code : "14125", intitule : "CAMBES EN PLAINE", cp : "14610" },
{ code : "14126", intitule : "CAMBREMER", cp : "14340" },
{ code : "14126", intitule : "CAMBREMER", cp : "14340" },
{ code : "14126", intitule : "CAMBREMER", cp : "14340" },
{ code : "14126", intitule : "CAMBREMER", cp : "14340" },
{ code : "14127", intitule : "CAMPAGNOLLES", cp : "14500" },
{ code : "14128", intitule : "CAMPANDRE VALCONGRAIN", cp : "14260" },
{ code : "14130", intitule : "CAMPIGNY", cp : "14490" },
{ code : "14131", intitule : "CANAPVILLE", cp : "14800" },
{ code : "14132", intitule : "CANCHY", cp : "14230" },
{ code : "14134", intitule : "CANTELOUP", cp : "14370" },
{ code : "14135", intitule : "CARCAGNY", cp : "14740" },
{ code : "14136", intitule : "CARDONVILLE", cp : "14230" },
{ code : "14137", intitule : "CARPIQUET", cp : "14650" },
{ code : "14138", intitule : "CARTIGNY L EPINAY", cp : "14330" },
{ code : "14140", intitule : "CASTILLON", cp : "14490" },
{ code : "14141", intitule : "CASTILLON EN AUGE", cp : "14140" },
{ code : "14142", intitule : "CASTILLY", cp : "14330" },
{ code : "14143", intitule : "CAUMONT L EVENTE", cp : "14240" },
{ code : "14145", intitule : "CAUVICOURT", cp : "14190" },
{ code : "14146", intitule : "CAUVILLE", cp : "14770" },
{ code : "14147", intitule : "CERNAY", cp : "14290" },
{ code : "14149", intitule : "CESNY AUX VIGNES", cp : "14270" },
{ code : "14150", intitule : "CESNY BOIS HALBOUT", cp : "14220" },
{ code : "14151", intitule : "CHAMP DU BOULT", cp : "14380" },
{ code : "14157", intitule : "CHEUX", cp : "14210" },
{ code : "14158", intitule : "CHICHEBOVILLE", cp : "14370" },
{ code : "14159", intitule : "CHOUAIN", cp : "14250" },
{ code : "14160", intitule : "CINTHEAUX", cp : "14680" },
{ code : "14161", intitule : "CLARBEC", cp : "14130" },
{ code : "14162", intitule : "CLECY", cp : "14570" },
{ code : "14163", intitule : "CLEVILLE", cp : "14370" },
{ code : "14164", intitule : "CLINCHAMPS SUR ORNE", cp : "14320" },
{ code : "14165", intitule : "COLLEVILLE SUR MER", cp : "14710" },
{ code : "14166", intitule : "COLLEVILLE MONTGOMERY", cp : "14880" },
{ code : "14167", intitule : "COLOMBELLES", cp : "14460" },
{ code : "14168", intitule : "COLOMBIERES", cp : "14710" },
{ code : "14169", intitule : "COLOMBIERS SUR SEULLES", cp : "14480" },
{ code : "14171", intitule : "COMBRAY", cp : "14220" },
{ code : "14172", intitule : "COMMES", cp : "14520" },
{ code : "14173", intitule : "CONDE SUR IFS", cp : "14270" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14110" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14770" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14770" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14110" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14110" },
{ code : "14174", intitule : "CONDE EN NORMANDIE", cp : "14770" },
{ code : "14175", intitule : "CONDE SUR SEULLES", cp : "14400" },
{ code : "14176", intitule : "CONTEVILLE", cp : "14540" },
{ code : "14177", intitule : "COQUAINVILLIERS", cp : "14130" },
{ code : "14179", intitule : "CORDEBUGLE", cp : "14100" },
{ code : "14180", intitule : "CORDEY", cp : "14700" },
{ code : "14181", intitule : "CORMELLES LE ROYAL", cp : "14123" },
{ code : "14182", intitule : "CORMOLAIN", cp : "14240" },
{ code : "14183", intitule : "COSSESSEVILLE", cp : "14690" },
{ code : "14184", intitule : "COTTUN", cp : "14400" },
{ code : "14185", intitule : "COUDRAY RABUT", cp : "14130" },
{ code : "14186", intitule : "COULOMBS", cp : "14480" },
{ code : "14189", intitule : "COUPESARTE", cp : "14140" },
{ code : "14190", intitule : "COURCY", cp : "14170" },
{ code : "14191", intitule : "COURSEULLES SUR MER", cp : "14470" },
{ code : "14192", intitule : "COURSON", cp : "14380" },
{ code : "14193", intitule : "COURTONNE LA MEURDRAC", cp : "14100" },
{ code : "14194", intitule : "COURTONNE LES DEUX EGLISES", cp : "14290" },
{ code : "14194", intitule : "COURTONNE LES DEUX EGLISES", cp : "14290" },
{ code : "14195", intitule : "COURVAUDON", cp : "14260" },
{ code : "14196", intitule : "CREPON", cp : "14480" },
{ code : "14197", intitule : "CRESSERONS", cp : "14440" },
{ code : "14198", intitule : "CRESSEVEUILLE", cp : "14430" },
{ code : "14200", intitule : "CREULLY", cp : "14480" },
{ code : "14201", intitule : "CREVECOEUR EN AUGE", cp : "14340" },
{ code : "14202", intitule : "CRICQUEBOEUF", cp : "14113" },
{ code : "14203", intitule : "CRICQUEVILLE EN AUGE", cp : "14430" },
{ code : "14204", intitule : "CRICQUEVILLE EN BESSIN", cp : "14450" },
{ code : "14205", intitule : "CRISTOT", cp : "14250" },
{ code : "14206", intitule : "CROCY", cp : "14620" },
{ code : "14207", intitule : "CROISILLES", cp : "14220" },
{ code : "14208", intitule : "CROISSANVILLE", cp : "14370" },
{ code : "14209", intitule : "CROUAY", cp : "14400" },
{ code : "14211", intitule : "CULEY LE PATRY", cp : "14220" },
{ code : "14212", intitule : "CULLY", cp : "14480" },
{ code : "14214", intitule : "CUSSY", cp : "14400" },
{ code : "14215", intitule : "CUVERVILLE", cp : "14840" },
{ code : "14216", intitule : "DAMBLAINVILLE", cp : "14620" },
{ code : "14217", intitule : "DAMPIERRE", cp : "14350" },
{ code : "14218", intitule : "DANESTAL", cp : "14430" },
{ code : "14219", intitule : "DANVOU LA FERRIERE", cp : "14770" },
{ code : "14219", intitule : "DANVOU LA FERRIERE", cp : "14770" },
{ code : "14220", intitule : "DEAUVILLE", cp : "14800" },
{ code : "14221", intitule : "DEMOUVILLE", cp : "14840" },
{ code : "14223", intitule : "LE DETROIT", cp : "14690" },
{ code : "14224", intitule : "DEUX JUMEAUX", cp : "14230" },
{ code : "14225", intitule : "DIVES SUR MER", cp : "14160" },
{ code : "14226", intitule : "DONNAY", cp : "14220" },
{ code : "14227", intitule : "DOUVILLE EN AUGE", cp : "14430" },
{ code : "14228", intitule : "DOUVRES LA DELIVRANDE", cp : "14440" },
{ code : "14228", intitule : "DOUVRES LA DELIVRANDE", cp : "14440" },
{ code : "14229", intitule : "DOZULE", cp : "14430" },
{ code : "14230", intitule : "DRUBEC", cp : "14130" },
{ code : "14231", intitule : "BEAUFOUR DRUVAL", cp : "14340" },
{ code : "14231", intitule : "BEAUFOUR DRUVAL", cp : "14340" },
{ code : "14231", intitule : "BEAUFOUR DRUVAL", cp : "14340" },
{ code : "14232", intitule : "DUCY STE MARGUERITE", cp : "14250" },
{ code : "14235", intitule : "ECRAMMEVILLE", cp : "14710" },
{ code : "14236", intitule : "ELLON", cp : "14250" },
{ code : "14237", intitule : "EMIEVILLE", cp : "14630" },
{ code : "14238", intitule : "ENGLESQUEVILLE EN AUGE", cp : "14800" },
{ code : "14239", intitule : "ENGLESQUEVILLE LA PERCEE", cp : "14710" },
{ code : "14240", intitule : "EPANEY", cp : "14170" },
{ code : "14241", intitule : "EPINAY SUR ODON", cp : "14310" },
{ code : "14242", intitule : "EPRON", cp : "14610" },
{ code : "14243", intitule : "EQUEMAUVILLE", cp : "14600" },
{ code : "14244", intitule : "ERAINES", cp : "14700" },
{ code : "14245", intitule : "ERNES", cp : "14270" },
{ code : "14246", intitule : "ESCOVILLE", cp : "14850" },
{ code : "14248", intitule : "ESPINS", cp : "14220" },
{ code : "14249", intitule : "ESQUAY NOTRE DAME", cp : "14210" },
{ code : "14250", intitule : "ESQUAY SUR SEULLES", cp : "14400" },
{ code : "14251", intitule : "ESSON", cp : "14220" },
{ code : "14252", intitule : "ESTREES LA CAMPAGNE", cp : "14190" },
{ code : "14254", intitule : "ETERVILLE", cp : "14930" },
{ code : "14256", intitule : "ETREHAM", cp : "14400" },
{ code : "14257", intitule : "EVRECY", cp : "14210" },
{ code : "14258", intitule : "FALAISE", cp : "14700" },
{ code : "14260", intitule : "FAUGUERNON", cp : "14100" },
{ code : "14261", intitule : "LE FAULQ", cp : "14130" },
{ code : "14266", intitule : "FEUGUEROLLES BULLY", cp : "14320" },
{ code : "14266", intitule : "FEUGUEROLLES BULLY", cp : "14320" },
{ code : "14268", intitule : "FIERVILLE BRAY", cp : "14190" },
{ code : "14268", intitule : "FIERVILLE BRAY", cp : "14190" },
{ code : "14269", intitule : "FIERVILLE LES PARCS", cp : "14130" },
{ code : "14270", intitule : "FIRFOL", cp : "14100" },
{ code : "14271", intitule : "FLEURY SUR ORNE", cp : "14123" },
{ code : "14272", intitule : "LA FOLIE", cp : "14710" },
{ code : "14273", intitule : "LA FOLLETIERE ABENON", cp : "14290" },
{ code : "14274", intitule : "FONTAINE ETOUPEFOUR", cp : "14790" },
{ code : "14275", intitule : "FONTAINE HENRY", cp : "14610" },
{ code : "14276", intitule : "FONTAINE LE PIN", cp : "14190" },
{ code : "14277", intitule : "FONTENAY LE MARMION", cp : "14320" },
{ code : "14278", intitule : "FONTENAY LE PESNEL", cp : "14250" },
{ code : "14279", intitule : "FONTENERMONT", cp : "14380" },
{ code : "14280", intitule : "FORMENTIN", cp : "14340" },
{ code : "14281", intitule : "FORMIGNY", cp : "14710" },
{ code : "14282", intitule : "FOULOGNES", cp : "14240" },
{ code : "14283", intitule : "FOURCHES", cp : "14620" },
{ code : "14284", intitule : "FOURNEAUX LE VAL", cp : "14700" },
{ code : "14285", intitule : "LE FOURNET", cp : "14340" },
{ code : "14286", intitule : "FOURNEVILLE", cp : "14600" },
{ code : "14287", intitule : "FRENOUVILLE", cp : "14630" },
{ code : "14288", intitule : "LE FRESNE CAMILLY", cp : "14480" },
{ code : "14289", intitule : "FRESNE LA MERE", cp : "14700" },
{ code : "14290", intitule : "FRESNEY LE PUCEUX", cp : "14680" },
{ code : "14291", intitule : "FRESNEY LE VIEUX", cp : "14220" },
{ code : "14293", intitule : "FUMICHON", cp : "14590" },
{ code : "14294", intitule : "GARCELLES SECQUEVILLE", cp : "14540" },
{ code : "14296", intitule : "LE GAST", cp : "14380" },
{ code : "14297", intitule : "GAVRUS", cp : "14210" },
{ code : "14298", intitule : "GEFOSSE FONTENAY", cp : "14230" },
{ code : "14299", intitule : "GENNEVILLE", cp : "14600" },
{ code : "14300", intitule : "GERROTS", cp : "14430" },
{ code : "14301", intitule : "GIBERVILLE", cp : "14730" },
{ code : "14302", intitule : "GLANVILLE", cp : "14950" },
{ code : "14303", intitule : "GLOS", cp : "14100" },
{ code : "14304", intitule : "GONNEVILLE SUR HONFLEUR", cp : "14600" },
{ code : "14305", intitule : "GONNEVILLE SUR MER", cp : "14510" },
{ code : "14306", intitule : "GONNEVILLE EN AUGE", cp : "14810" },
{ code : "14307", intitule : "GOUPILLIERES", cp : "14210" },
{ code : "14308", intitule : "GOUSTRANVILLE", cp : "14430" },
{ code : "14309", intitule : "GOUVIX", cp : "14680" },
{ code : "14310", intitule : "GRAINVILLE LANGANNERIE", cp : "14190" },
{ code : "14311", intitule : "GRAINVILLE SUR ODON", cp : "14210" },
{ code : "14312", intitule : "GRANDCAMP MAISY", cp : "14450" },
{ code : "14312", intitule : "GRANDCAMP MAISY", cp : "14450" },
{ code : "14313", intitule : "GRANDCHAMP LE CHATEAU", cp : "14140" },
{ code : "14316", intitule : "GRANGUES", cp : "14160" },
{ code : "14318", intitule : "GRAYE SUR MER", cp : "14470" },
{ code : "14319", intitule : "GRENTHEVILLE", cp : "14540" },
{ code : "14320", intitule : "GRIMBOSQ", cp : "14220" },
{ code : "14322", intitule : "GUERON", cp : "14400" },
{ code : "14325", intitule : "HERMANVILLE SUR MER", cp : "14880" },
{ code : "14326", intitule : "HERMIVAL LES VAUX", cp : "14100" },
{ code : "14327", intitule : "HEROUVILLE ST CLAIR", cp : "14200" },
{ code : "14328", intitule : "HEROUVILLETTE", cp : "14850" },
{ code : "14329", intitule : "HEULAND", cp : "14430" },
{ code : "14331", intitule : "HIEVILLE", cp : "14170" },
{ code : "14332", intitule : "LA HOGUETTE", cp : "14700" },
{ code : "14333", intitule : "HONFLEUR", cp : "14600" },
{ code : "14333", intitule : "HONFLEUR", cp : "14600" },
{ code : "14334", intitule : "L HOTELLERIE", cp : "14100" },
{ code : "14335", intitule : "HOTOT EN AUGE", cp : "14430" },
{ code : "14335", intitule : "HOTOT EN AUGE", cp : "14430" },
{ code : "14335", intitule : "HOTOT EN AUGE", cp : "14430" },
{ code : "14336", intitule : "HOTTOT LES BAGUES", cp : "14250" },
{ code : "14337", intitule : "LA HOUBLONNIERE", cp : "14340" },
{ code : "14338", intitule : "HOULGATE", cp : "14510" },
{ code : "14339", intitule : "HUBERT FOLIE", cp : "14540" },
{ code : "14341", intitule : "IFS", cp : "14123" },
{ code : "14342", intitule : "ISIGNY SUR MER", cp : "14230" },
{ code : "14343", intitule : "LES ISLES BARDEL", cp : "14690" },
{ code : "14344", intitule : "JANVILLE", cp : "14670" },
{ code : "14345", intitule : "JORT", cp : "14170" },
{ code : "14346", intitule : "JUAYE MONDAYE", cp : "14250" },
{ code : "14347", intitule : "JURQUES", cp : "14260" },
{ code : "14348", intitule : "JUVIGNY SUR SEULLES", cp : "14250" },
{ code : "14349", intitule : "LAIZE LA VILLE", cp : "14320" },
{ code : "14350", intitule : "LA LANDE SUR DROME", cp : "14240" },
{ code : "14352", intitule : "LANDELLES ET COUPIGNY", cp : "14380" },
{ code : "14352", intitule : "LANDELLES ET COUPIGNY", cp : "14380" },
{ code : "14353", intitule : "LANDES SUR AJON", cp : "14310" },
{ code : "14354", intitule : "LANGRUNE SUR MER", cp : "14830" },
{ code : "14355", intitule : "LANTHEUIL", cp : "14480" },
{ code : "14357", intitule : "LASSY", cp : "14770" },
{ code : "14358", intitule : "LEAUPARTIE", cp : "14340" },
{ code : "14359", intitule : "LECAUDE", cp : "14140" },
{ code : "14360", intitule : "LEFFARD", cp : "14700" },
{ code : "14362", intitule : "LESSARD ET LE CHENE", cp : "14140" },
{ code : "14364", intitule : "LINGEVRES", cp : "14250" },
{ code : "14365", intitule : "LION SUR MER", cp : "14780" },
{ code : "14366", intitule : "LISIEUX", cp : "14100" },
{ code : "14367", intitule : "LISON", cp : "14330" },
{ code : "14368", intitule : "LISORES", cp : "14140" },
{ code : "14369", intitule : "LITTEAU", cp : "14490" },
{ code : "14370", intitule : "LE MOLAY LITTRY", cp : "14330" },
{ code : "14370", intitule : "LE MOLAY LITTRY", cp : "14330" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14290" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14290" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14290" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14290" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14371", intitule : "LIVAROT PAYS D AUGE", cp : "14140" },
{ code : "14372", intitule : "LIVRY", cp : "14240" },
{ code : "14372", intitule : "LIVRY", cp : "14240" },
{ code : "14373", intitule : "LE LOCHEUR", cp : "14210" },
{ code : "14374", intitule : "LES LOGES", cp : "14240" },
{ code : "14375", intitule : "LES LOGES SAULCES", cp : "14700" },
{ code : "14376", intitule : "LONGRAYE", cp : "14250" },
{ code : "14377", intitule : "LONGUES SUR MER", cp : "14400" },
{ code : "14378", intitule : "LONGUEVILLE", cp : "14230" },
{ code : "14379", intitule : "LONGVILLERS", cp : "14310" },
{ code : "14380", intitule : "LOUCELLES", cp : "14250" },
{ code : "14381", intitule : "LOUVAGNY", cp : "14170" },
{ code : "14382", intitule : "LOUVIERES", cp : "14710" },
{ code : "14383", intitule : "LOUVIGNY", cp : "14111" },
{ code : "14384", intitule : "LUC SUR MER", cp : "14530" },
{ code : "14385", intitule : "MAGNY EN BESSIN", cp : "14400" },
{ code : "14386", intitule : "MAGNY LA CAMPAGNE", cp : "14270" },
{ code : "14387", intitule : "MAGNY LE FREULE", cp : "14270" },
{ code : "14389", intitule : "MAISONCELLES PELVEY", cp : "14310" },
{ code : "14390", intitule : "MAISONCELLES SUR AJON", cp : "14210" },
{ code : "14391", intitule : "MAISONS", cp : "14400" },
{ code : "14393", intitule : "MAIZET", cp : "14210" },
{ code : "14394", intitule : "MAIZIERES", cp : "14190" },
{ code : "14396", intitule : "MALTOT", cp : "14930" },
{ code : "14397", intitule : "MANDEVILLE EN BESSIN", cp : "14710" },
{ code : "14398", intitule : "MANERBE", cp : "14340" },
{ code : "14399", intitule : "MANNEVILLE LA PIPARD", cp : "14130" },
{ code : "14400", intitule : "LE MANOIR", cp : "14400" },
{ code : "14401", intitule : "MANVIEUX", cp : "14117" },
{ code : "14402", intitule : "LE MARAIS LA CHAPELLE", cp : "14620" },
{ code : "14403", intitule : "MAROLLES", cp : "14100" },
{ code : "14404", intitule : "MARTAINVILLE", cp : "14220" },
{ code : "14405", intitule : "MARTIGNY SUR L ANTE", cp : "14700" },
{ code : "14406", intitule : "MARTRAGNY", cp : "14740" },
{ code : "14407", intitule : "MATHIEU", cp : "14920" },
{ code : "14408", intitule : "MAY SUR ORNE", cp : "14320" },
{ code : "14409", intitule : "MERVILLE FRANCEVILLE PLAGE", cp : "14810" },
{ code : "14410", intitule : "MERY CORBON", cp : "14370" },
{ code : "14411", intitule : "MESLAY", cp : "14220" },
{ code : "14412", intitule : "LE MESNIL AU GRAIN", cp : "14260" },
{ code : "14413", intitule : "LE MESNIL AUZOUF", cp : "14260" },
{ code : "14415", intitule : "LE MESNIL BENOIST", cp : "14380" },
{ code : "14416", intitule : "LE MESNIL CAUSSOIS", cp : "14380" },
{ code : "14417", intitule : "MESNIL CLINCHAMPS", cp : "14380" },
{ code : "14419", intitule : "LE MESNIL EUDES", cp : "14100" },
{ code : "14421", intitule : "LE MESNIL GUILLAUME", cp : "14100" },
{ code : "14422", intitule : "LE MESNIL MAUGER", cp : "14270" },
{ code : "14422", intitule : "LE MESNIL MAUGER", cp : "14270" },
{ code : "14422", intitule : "LE MESNIL MAUGER", cp : "14270" },
{ code : "14422", intitule : "LE MESNIL MAUGER", cp : "14270" },
{ code : "14423", intitule : "LE MESNIL PATRY", cp : "14740" },
{ code : "14424", intitule : "LE MESNIL ROBERT", cp : "14380" },
{ code : "14425", intitule : "LE MESNIL SIMON", cp : "14140" },
{ code : "14426", intitule : "LE MESNIL SUR BLANGY", cp : "14130" },
{ code : "14427", intitule : "LE MESNIL VILLEMENT", cp : "14690" },
{ code : "14430", intitule : "MEUVAINES", cp : "14960" },
{ code : "14431", intitule : "MEZIDON CANON", cp : "14270" },
{ code : "14431", intitule : "MEZIDON CANON", cp : "14270" },
{ code : "14433", intitule : "MITTOIS", cp : "14170" },
{ code : "14435", intitule : "LES MONCEAUX", cp : "14100" },
{ code : "14436", intitule : "MONCEAUX EN BESSIN", cp : "14400" },
{ code : "14437", intitule : "MONDEVILLE", cp : "14120" },
{ code : "14438", intitule : "MONDRAINVILLE", cp : "14210" },
{ code : "14439", intitule : "MONFREVILLE", cp : "14230" },
{ code : "14444", intitule : "MONTEILLE", cp : "14270" },
{ code : "14445", intitule : "MONTFIQUET", cp : "14490" },
{ code : "14446", intitule : "MONTIGNY", cp : "14210" },
{ code : "14448", intitule : "MONTREUIL EN AUGE", cp : "14340" },
{ code : "14449", intitule : "MONTS EN BESSIN", cp : "14310" },
{ code : "14450", intitule : "MONTVIETTE", cp : "14140" },
{ code : "14452", intitule : "MORTEAUX COULIBOEUF", cp : "14620" },
{ code : "14453", intitule : "MOSLES", cp : "14400" },
{ code : "14454", intitule : "MOUEN", cp : "14790" },
{ code : "14455", intitule : "MOULINES", cp : "14220" },
{ code : "14456", intitule : "MOULT", cp : "14370" },
{ code : "14457", intitule : "LES MOUTIERS EN AUGE", cp : "14620" },
{ code : "14458", intitule : "LES MOUTIERS EN CINGLAIS", cp : "14220" },
{ code : "14460", intitule : "MOYAUX", cp : "14590" },
{ code : "14461", intitule : "MUTRECY", cp : "14220" },
{ code : "14462", intitule : "NEUILLY LA FORET", cp : "14230" },
{ code : "14465", intitule : "NONANT", cp : "14400" },
{ code : "14466", intitule : "NOROLLES", cp : "14100" },
{ code : "14467", intitule : "NORON L ABBAYE", cp : "14700" },
{ code : "14468", intitule : "NORON LA POTERIE", cp : "14490" },
{ code : "14469", intitule : "NORREY EN AUGE", cp : "14620" },
{ code : "14473", intitule : "NOTRE DAME DE LIVAYE", cp : "14340" },
{ code : "14474", intitule : "NOTRE DAME D ESTREES CORBON", cp : "14340" },
{ code : "14474", intitule : "NOTRE DAME D ESTREES CORBON", cp : "14340" },
{ code : "14475", intitule : "NOYERS MISSY", cp : "14210" },
{ code : "14475", intitule : "NOYERS MISSY", cp : "14210" },
{ code : "14476", intitule : "OLENDON", cp : "14170" },
{ code : "14477", intitule : "ONDEFONTAINE", cp : "14260" },
{ code : "14478", intitule : "ORBEC", cp : "14290" },
{ code : "14480", intitule : "OSMANVILLE", cp : "14230" },
{ code : "14481", intitule : "LES OUBEAUX", cp : "14230" },
{ code : "14482", intitule : "OUEZY", cp : "14270" },
{ code : "14483", intitule : "OUFFIERES", cp : "14220" },
{ code : "14484", intitule : "OUILLY DU HOULEY", cp : "14590" },
{ code : "14486", intitule : "OUILLY LE TESSON", cp : "14190" },
{ code : "14487", intitule : "OUILLY LE VICOMTE", cp : "14100" },
{ code : "14488", intitule : "OUISTREHAM", cp : "14150" },
{ code : "14489", intitule : "OUVILLE LA BIEN TOURNEE", cp : "14170" },
{ code : "14491", intitule : "PARFOURU SUR ODON", cp : "14310" },
{ code : "14492", intitule : "PENNEDEPIE", cp : "14600" },
{ code : "14493", intitule : "PERCY EN AUGE", cp : "14270" },
{ code : "14494", intitule : "PERIERS EN AUGE", cp : "14160" },
{ code : "14495", intitule : "PERIERS SUR LE DAN", cp : "14112" },
{ code : "14496", intitule : "PERIGNY", cp : "14770" },
{ code : "14497", intitule : "PERRIERES", cp : "14170" },
{ code : "14498", intitule : "PERTHEVILLE NERS", cp : "14700" },
{ code : "14499", intitule : "PETIVILLE", cp : "14390" },
{ code : "14500", intitule : "PIERREFITTE EN AUGE", cp : "14130" },
{ code : "14501", intitule : "PIERREFITTE EN CINGLAIS", cp : "14690" },
{ code : "14502", intitule : "PIERREPONT", cp : "14690" },
{ code : "14504", intitule : "LE PIN", cp : "14590" },
{ code : "14505", intitule : "PLACY", cp : "14220" },
{ code : "14506", intitule : "PLANQUERY", cp : "14490" },
{ code : "14508", intitule : "LE PLESSIS GRIMOULT", cp : "14770" },
{ code : "14509", intitule : "PLUMETOT", cp : "14440" },
{ code : "14510", intitule : "LA POMMERAYE", cp : "14690" },
{ code : "14511", intitule : "PONT BELLANGER", cp : "14380" },
{ code : "14512", intitule : "PONTECOULANT", cp : "14110" },
{ code : "14513", intitule : "PONT FARCY", cp : "14380" },
{ code : "14513", intitule : "PONT FARCY", cp : "14380" },
{ code : "14514", intitule : "PONT L EVEQUE", cp : "14130" },
{ code : "14515", intitule : "PORT EN BESSIN HUPPAIN", cp : "14520" },
{ code : "14515", intitule : "PORT EN BESSIN HUPPAIN", cp : "14520" },
{ code : "14516", intitule : "POTIGNY", cp : "14420" },
{ code : "14517", intitule : "POUSSY LA CAMPAGNE", cp : "14540" },
{ code : "14519", intitule : "PREAUX BOCAGE", cp : "14210" },
{ code : "14520", intitule : "LE PRE D AUGE", cp : "14340" },
{ code : "14522", intitule : "PRETREVILLE", cp : "14140" },
{ code : "14524", intitule : "PUTOT EN AUGE", cp : "14430" },
{ code : "14525", intitule : "PUTOT EN BESSIN", cp : "14740" },
{ code : "14527", intitule : "BIEVILLE QUETIEVILLE", cp : "14270" },
{ code : "14527", intitule : "BIEVILLE QUETIEVILLE", cp : "14270" },
{ code : "14527", intitule : "BIEVILLE QUETIEVILLE", cp : "14270" },
{ code : "14528", intitule : "QUETTEVILLE", cp : "14130" },
{ code : "14529", intitule : "RANCHY", cp : "14400" },
{ code : "14530", intitule : "RANVILLE", cp : "14860" },
{ code : "14531", intitule : "RAPILLY", cp : "14690" },
{ code : "14533", intitule : "REPENTIGNY", cp : "14340" },
{ code : "14534", intitule : "REUX", cp : "14130" },
{ code : "14535", intitule : "REVIERS", cp : "14470" },
{ code : "14536", intitule : "LA RIVIERE ST SAUVEUR", cp : "14600" },
{ code : "14538", intitule : "ROCQUANCOURT", cp : "14540" },
{ code : "14540", intitule : "ROCQUES", cp : "14100" },
{ code : "14541", intitule : "LA ROQUE BAIGNARD", cp : "14340" },
{ code : "14542", intitule : "ROSEL", cp : "14740" },
{ code : "14543", intitule : "ROTS", cp : "14740" },
{ code : "14543", intitule : "ROTS", cp : "14740" },
{ code : "14543", intitule : "ROTS", cp : "14980" },
{ code : "14544", intitule : "ROUCAMPS", cp : "14260" },
{ code : "14546", intitule : "ROUVRES", cp : "14190" },
{ code : "14547", intitule : "RUBERCY", cp : "14710" },
{ code : "14548", intitule : "RUCQUEVILLE", cp : "14480" },
{ code : "14550", intitule : "RUMESNIL", cp : "14340" },
{ code : "14551", intitule : "RUSSY", cp : "14710" },
{ code : "14552", intitule : "RYES", cp : "14400" },
{ code : "14554", intitule : "ST AIGNAN DE CRAMESNIL", cp : "14540" },
{ code : "14555", intitule : "ST ANDRE D HEBERTOT", cp : "14130" },
{ code : "14556", intitule : "ST ANDRE SUR ORNE", cp : "14320" },
{ code : "14557", intitule : "ST ARNOULT", cp : "14800" },
{ code : "14558", intitule : "ST AUBIN D ARQUENAY", cp : "14970" },
{ code : "14559", intitule : "ST AUBIN DES BOIS", cp : "14380" },
{ code : "14562", intitule : "ST AUBIN SUR MER", cp : "14750" },
{ code : "14563", intitule : "ST BENOIT D HEBERTOT", cp : "14130" },
{ code : "14565", intitule : "ST COME DE FRESNE", cp : "14960" },
{ code : "14566", intitule : "ST CONTEST", cp : "14280" },
{ code : "14568", intitule : "STE CROIX GRAND TONNE", cp : "14740" },
{ code : "14569", intitule : "STE CROIX SUR MER", cp : "14480" },
{ code : "14570", intitule : "VALORBIQUET", cp : "14290" },
{ code : "14570", intitule : "VALORBIQUET", cp : "14290" },
{ code : "14570", intitule : "VALORBIQUET", cp : "14290" },
{ code : "14570", intitule : "VALORBIQUET", cp : "14290" },
{ code : "14570", intitule : "VALORBIQUET", cp : "14290" },
{ code : "14571", intitule : "ST DENIS DE MAILLOC", cp : "14100" },
{ code : "14572", intitule : "ST DENIS DE MERE", cp : "14110" },
{ code : "14574", intitule : "ST DESIR", cp : "14100" },
{ code : "14575", intitule : "ST ETIENNE LA THILLAYE", cp : "14950" },
{ code : "14576", intitule : "VAL DE VIE", cp : "14140" },
{ code : "14576", intitule : "VAL DE VIE", cp : "14140" },
{ code : "14576", intitule : "VAL DE VIE", cp : "14140" },
{ code : "14576", intitule : "VAL DE VIE", cp : "14140" },
{ code : "14577", intitule : "ST GABRIEL BRECY", cp : "14480" },
{ code : "14578", intitule : "ST GATIEN DES BOIS", cp : "14130" },
{ code : "14579", intitule : "SEULLINE", cp : "14260" },
{ code : "14579", intitule : "SEULLINE", cp : "14310" },
{ code : "14580", intitule : "ST GEORGES EN AUGE", cp : "14140" },
{ code : "14581", intitule : "ST GERMAIN D ECTOT", cp : "14240" },
{ code : "14582", intitule : "ST GERMAIN DE LIVET", cp : "14100" },
{ code : "14586", intitule : "ST GERMAIN DU PERT", cp : "14230" },
{ code : "14587", intitule : "ST GERMAIN LA BLANCHE HERBE", cp : "14280" },
{ code : "14588", intitule : "ST GERMAIN LANGOT", cp : "14700" },
{ code : "14589", intitule : "ST GERMAIN LE VASSON", cp : "14190" },
{ code : "14590", intitule : "STE HONORINE DE DUCY", cp : "14240" },
{ code : "14591", intitule : "STE HONORINE DES PERTES", cp : "14520" },
{ code : "14592", intitule : "STE HONORINE DU FAY", cp : "14210" },
{ code : "14593", intitule : "ST HYMER", cp : "14130" },
{ code : "14595", intitule : "ST JEAN DE LIVET", cp : "14100" },
{ code : "14596", intitule : "ST JEAN DES ESSARTIERS", cp : "14350" },
{ code : "14597", intitule : "ST JEAN LE BLANC", cp : "14770" },
{ code : "14598", intitule : "ST JOUIN", cp : "14430" },
{ code : "14600", intitule : "ST JULIEN LE FAUCON", cp : "14140" },
{ code : "14601", intitule : "ST JULIEN SUR CALONNE", cp : "14130" },
{ code : "14602", intitule : "ST LAMBERT", cp : "14570" },
{ code : "14603", intitule : "ST LAURENT DE CONDEL", cp : "14220" },
{ code : "14604", intitule : "ST LAURENT DU MONT", cp : "14340" },
{ code : "14605", intitule : "ST LAURENT SUR MER", cp : "14710" },
{ code : "14606", intitule : "ST LEGER DUBOSQ", cp : "14430" },
{ code : "14607", intitule : "ST LOUET SUR SEULLES", cp : "14310" },
{ code : "14608", intitule : "ST LOUP DE FRIBOIS", cp : "14340" },
{ code : "14609", intitule : "ST LOUP HORS", cp : "14400" },
{ code : "14610", intitule : "ST MANVIEU NORREY", cp : "14740" },
{ code : "14610", intitule : "ST MANVIEU NORREY", cp : "14740" },
{ code : "14611", intitule : "ST MANVIEU BOCAGE", cp : "14380" },
{ code : "14613", intitule : "ST MARCOUF", cp : "14330" },
{ code : "14614", intitule : "STE MARGUERITE D ELLE", cp : "14330" },
{ code : "14616", intitule : "STE MARGUERITE DE VIETTE", cp : "14140" },
{ code : "14619", intitule : "STE MARIE OUTRE L EAU", cp : "14380" },
{ code : "14620", intitule : "ST MARTIN AUX CHARTRAINS", cp : "14130" },
{ code : "14621", intitule : "ST MARTIN DE BIENFAITE LA CRESSONNIERE", cp : "14290" },
{ code : "14621", intitule : "ST MARTIN DE BIENFAITE LA CRESSONNIERE", cp : "14290" },
{ code : "14622", intitule : "ST MARTIN DE BLAGNY", cp : "14710" },
{ code : "14623", intitule : "ST MARTIN DE FONTENAY", cp : "14320" },
{ code : "14625", intitule : "ST MARTIN DE LA LIEUE", cp : "14100" },
{ code : "14626", intitule : "ST MARTIN DE MAILLOC", cp : "14100" },
{ code : "14627", intitule : "ST MARTIN DE MIEUX", cp : "14700" },
{ code : "14630", intitule : "ST MARTIN DES ENTREES", cp : "14400" },
{ code : "14635", intitule : "ST OMER", cp : "14220" },
{ code : "14637", intitule : "ST OUEN DU MESNIL OGER", cp : "14670" },
{ code : "14639", intitule : "ST OUEN LE PIN", cp : "14340" },
{ code : "14640", intitule : "ST PAIR", cp : "14670" },
{ code : "14643", intitule : "ST PAUL DU VERNAY", cp : "14490" },
{ code : "14644", intitule : "ST PHILBERT DES CHAMPS", cp : "14130" },
{ code : "14645", intitule : "ST PIERRE AZIF", cp : "14950" },
{ code : "14646", intitule : "ST PIERRE CANIVET", cp : "14700" },
{ code : "14648", intitule : "ST PIERRE DES IFS", cp : "14100" },
{ code : "14649", intitule : "ST PIERRE DU BU", cp : "14700" },
{ code : "14650", intitule : "ST PIERRE DU FRESNE", cp : "14260" },
{ code : "14651", intitule : "ST PIERRE DU JONQUET", cp : "14670" },
{ code : "14652", intitule : "ST PIERRE DU MONT", cp : "14450" },
{ code : "14654", intitule : "ST PIERRE SUR DIVES", cp : "14170" },
{ code : "14656", intitule : "ST REMY", cp : "14570" },
{ code : "14657", intitule : "ST SAMSON", cp : "14670" },
{ code : "14658", intitule : "ST SEVER CALVADOS", cp : "14380" },
{ code : "14659", intitule : "ST SYLVAIN", cp : "14190" },
{ code : "14660", intitule : "ST VAAST EN AUGE", cp : "14640" },
{ code : "14661", intitule : "ST VAAST SUR SEULLES", cp : "14250" },
{ code : "14662", intitule : "ST VIGOR DES MEZERETS", cp : "14770" },
{ code : "14663", intitule : "ST VIGOR LE GRAND", cp : "14400" },
{ code : "14664", intitule : "SALLEN", cp : "14240" },
{ code : "14665", intitule : "SALLENELLES", cp : "14121" },
{ code : "14666", intitule : "SANNERVILLE", cp : "14940" },
{ code : "14667", intitule : "SAON", cp : "14330" },
{ code : "14668", intitule : "SAONNET", cp : "14330" },
{ code : "14669", intitule : "SASSY", cp : "14170" },
{ code : "14671", intitule : "SEPT FRERES", cp : "14380" },
{ code : "14672", intitule : "SEPT VENTS", cp : "14240" },
{ code : "14674", intitule : "SOIGNOLLES", cp : "14190" },
{ code : "14675", intitule : "SOLIERS", cp : "14540" },
{ code : "14676", intitule : "SOMMERVIEU", cp : "14400" },
{ code : "14677", intitule : "SOULANGY", cp : "14700" },
{ code : "14678", intitule : "SOUMONT ST QUENTIN", cp : "14420" },
{ code : "14679", intitule : "SUBLES", cp : "14400" },
{ code : "14680", intitule : "SULLY", cp : "14400" },
{ code : "14681", intitule : "SURRAIN", cp : "14710" },
{ code : "14682", intitule : "SURVILLE", cp : "14130" },
{ code : "14684", intitule : "TESSEL", cp : "14250" },
{ code : "14685", intitule : "THAON", cp : "14610" },
{ code : "14687", intitule : "LE THEIL EN AUGE", cp : "14130" },
{ code : "14688", intitule : "THIEVILLE", cp : "14170" },
{ code : "14689", intitule : "LE HOM", cp : "14220" },
{ code : "14689", intitule : "LE HOM", cp : "14220" },
{ code : "14689", intitule : "LE HOM", cp : "14220" },
{ code : "14689", intitule : "LE HOM", cp : "14220" },
{ code : "14689", intitule : "LE HOM", cp : "14220" },
{ code : "14690", intitule : "TIERCEVILLE", cp : "14480" },
{ code : "14691", intitule : "TILLY LA CAMPAGNE", cp : "14540" },
{ code : "14692", intitule : "TILLY SUR SEULLES", cp : "14250" },
{ code : "14694", intitule : "LE TORQUESNE", cp : "14130" },
{ code : "14695", intitule : "TORTEVAL QUESNAY", cp : "14240" },
{ code : "14695", intitule : "TORTEVAL QUESNAY", cp : "14240" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14697", intitule : "L OUDON", cp : "14170" },
{ code : "14698", intitule : "TOUFFREVILLE", cp : "14940" },
{ code : "14699", intitule : "TOUQUES", cp : "14800" },
{ code : "14700", intitule : "TOUR EN BESSIN", cp : "14400" },
{ code : "14701", intitule : "TOURGEVILLE", cp : "14800" },
{ code : "14702", intitule : "TOURNAY SUR ODON", cp : "14310" },
{ code : "14703", intitule : "TOURNEBU", cp : "14220" },
{ code : "14705", intitule : "TOURNIERES", cp : "14330" },
{ code : "14706", intitule : "TOURVILLE EN AUGE", cp : "14130" },
{ code : "14707", intitule : "TOURVILLE SUR ODON", cp : "14210" },
{ code : "14708", intitule : "TRACY BOCAGE", cp : "14310" },
{ code : "14709", intitule : "TRACY SUR MER", cp : "14117" },
{ code : "14710", intitule : "TREPREL", cp : "14690" },
{ code : "14711", intitule : "TREVIERES", cp : "14710" },
{ code : "14712", intitule : "TROARN", cp : "14670" },
{ code : "14712", intitule : "TROARN", cp : "14670" },
{ code : "14713", intitule : "TROIS MONTS", cp : "14210" },
{ code : "14714", intitule : "LE TRONQUAY", cp : "14490" },
{ code : "14715", intitule : "TROUVILLE SUR MER", cp : "14360" },
{ code : "14716", intitule : "TRUNGY", cp : "14490" },
{ code : "14719", intitule : "URVILLE", cp : "14190" },
{ code : "14720", intitule : "USSY", cp : "14420" },
{ code : "14721", intitule : "VACOGNES NEUILLY", cp : "14210" },
{ code : "14721", intitule : "VACOGNES NEUILLY", cp : "14210" },
{ code : "14722", intitule : "LA VACQUERIE", cp : "14240" },
{ code : "14723", intitule : "VALSEME", cp : "14340" },
{ code : "14724", intitule : "VARAVILLE", cp : "14390" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14350" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14350" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14350" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14726", intitule : "VALDALLIERE", cp : "14410" },
{ code : "14728", intitule : "VAUCELLES", cp : "14400" },
{ code : "14729", intitule : "VAUDELOGES", cp : "14170" },
{ code : "14731", intitule : "VAUVILLE", cp : "14800" },
{ code : "14732", intitule : "VAUX SUR AURE", cp : "14400" },
{ code : "14733", intitule : "VAUX SUR SEULLES", cp : "14400" },
{ code : "14734", intitule : "VENDES", cp : "14250" },
{ code : "14735", intitule : "VENDEUVRE", cp : "14170" },
{ code : "14735", intitule : "VENDEUVRE", cp : "14170" },
{ code : "14735", intitule : "VENDEUVRE", cp : "14170" },
{ code : "14737", intitule : "VERSAINVILLE", cp : "14700" },
{ code : "14738", intitule : "VERSON", cp : "14790" },
{ code : "14739", intitule : "VER SUR MER", cp : "14114" },
{ code : "14740", intitule : "LA VESPIERE FRIARDEL", cp : "14290" },
{ code : "14740", intitule : "LA VESPIERE FRIARDEL", cp : "14290" },
{ code : "14741", intitule : "LE VEY", cp : "14570" },
{ code : "14742", intitule : "VICQUES", cp : "14170" },
{ code : "14743", intitule : "VICTOT PONTFOL", cp : "14430" },
{ code : "14744", intitule : "VIENNE EN BESSIN", cp : "14400" },
{ code : "14745", intitule : "VIERVILLE SUR MER", cp : "14710" },
{ code : "14747", intitule : "VIEUX", cp : "14930" },
{ code : "14748", intitule : "VIEUX BOURG", cp : "14130" },
{ code : "14749", intitule : "VIEUX FUME", cp : "14270" },
{ code : "14750", intitule : "VIEUX PONT EN AUGE", cp : "14140" },
{ code : "14751", intitule : "VIGNATS", cp : "14700" },
{ code : "14752", intitule : "VILLERS BOCAGE", cp : "14310" },
{ code : "14753", intitule : "VILLERS CANIVET", cp : "14420" },
{ code : "14754", intitule : "VILLERS SUR MER", cp : "14640" },
{ code : "14755", intitule : "VILLERVILLE", cp : "14113" },
{ code : "14756", intitule : "LA VILLETTE", cp : "14570" },
{ code : "14757", intitule : "VILLIERS LE SEC", cp : "14480" },
{ code : "14758", intitule : "VILLONS LES BUISSONS", cp : "14610" },
{ code : "14759", intitule : "VILLY LEZ FALAISE", cp : "14700" },
{ code : "14760", intitule : "VILLY BOCAGE", cp : "14310" },
{ code : "14761", intitule : "VIMONT", cp : "14370" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14762", intitule : "VIRE NORMANDIE", cp : "14500" },
{ code : "14763", intitule : "VOUILLY", cp : "14230" },
{ code : "14764", intitule : "PONT D OUILLY", cp : "14690" },
{ code : "15001", intitule : "ALLANCHE", cp : "15160" },
{ code : "15002", intitule : "ALLEUZE", cp : "15100" },
{ code : "15003", intitule : "ALLY", cp : "15700" },
{ code : "15003", intitule : "ALLY", cp : "15700" },
{ code : "15004", intitule : "ANDELAT", cp : "15100" },
{ code : "15005", intitule : "ANGLARDS DE ST FLOUR", cp : "15100" },
{ code : "15006", intitule : "ANGLARDS DE SALERS", cp : "15380" },
{ code : "15007", intitule : "ANTERRIEUX", cp : "15110" },
{ code : "15008", intitule : "ANTIGNAC", cp : "15240" },
{ code : "15009", intitule : "APCHON", cp : "15400" },
{ code : "15010", intitule : "ARCHES", cp : "15200" },
{ code : "15011", intitule : "ARNAC", cp : "15150" },
{ code : "15012", intitule : "ARPAJON SUR CERE", cp : "15130" },
{ code : "15013", intitule : "AURIAC L EGLISE", cp : "15500" },
{ code : "15014", intitule : "AURILLAC", cp : "15000" },
{ code : "15015", intitule : "AUZERS", cp : "15240" },
{ code : "15016", intitule : "AYRENS", cp : "15250" },
{ code : "15017", intitule : "BADAILHAC", cp : "15800" },
{ code : "15018", intitule : "BARRIAC LES BOSQUETS", cp : "15700" },
{ code : "15019", intitule : "BASSIGNAC", cp : "15240" },
{ code : "15020", intitule : "BEAULIEU", cp : "15270" },
{ code : "15021", intitule : "BOISSET", cp : "15600" },
{ code : "15022", intitule : "BONNAC", cp : "15500" },
{ code : "15024", intitule : "BRAGEAC", cp : "15700" },
{ code : "15025", intitule : "ALBEPIERRE BREDONS", cp : "15300" },
{ code : "15026", intitule : "BREZONS", cp : "15230" },
{ code : "15027", intitule : "CALVINET", cp : "15340" },
{ code : "15028", intitule : "CARLAT", cp : "15130" },
{ code : "15029", intitule : "CASSANIOUZE", cp : "15340" },
{ code : "15030", intitule : "CAYROLS", cp : "15290" },
{ code : "15031", intitule : "CELLES", cp : "15170" },
{ code : "15032", intitule : "CELOUX", cp : "15500" },
{ code : "15033", intitule : "CEZENS", cp : "15230" },
{ code : "15034", intitule : "CHALIERS", cp : "15320" },
{ code : "15035", intitule : "CHALINARGUES", cp : "15170" },
{ code : "15036", intitule : "CHALVIGNAC", cp : "15200" },
{ code : "15037", intitule : "CHAMPAGNAC", cp : "15350" },
{ code : "15038", intitule : "CHAMPS SUR TARENTAINE MARCHAL", cp : "15270" },
{ code : "15038", intitule : "CHAMPS SUR TARENTAINE MARCHAL", cp : "15270" },
{ code : "15040", intitule : "CHANTERELLE", cp : "15190" },
{ code : "15041", intitule : "LA CHAPELLE D ALAGNON", cp : "15300" },
{ code : "15042", intitule : "LA CHAPELLE LAURENT", cp : "15500" },
{ code : "15043", intitule : "CHARMENSAC", cp : "15500" },
{ code : "15044", intitule : "CHASTEL SUR MURAT", cp : "15300" },
{ code : "15045", intitule : "CHAUDES AIGUES", cp : "15110" },
{ code : "15046", intitule : "CHAUSSENAC", cp : "15700" },
{ code : "15047", intitule : "CHAVAGNAC", cp : "15300" },
{ code : "15048", intitule : "CHAZELLES", cp : "15500" },
{ code : "15049", intitule : "CHEYLADE", cp : "15400" },
{ code : "15050", intitule : "LE CLAUX", cp : "15400" },
{ code : "15051", intitule : "CLAVIERES", cp : "15320" },
{ code : "15052", intitule : "COLLANDRES", cp : "15400" },
{ code : "15053", intitule : "COLTINES", cp : "15170" },
{ code : "15054", intitule : "CONDAT", cp : "15190" },
{ code : "15055", intitule : "COREN", cp : "15100" },
{ code : "15056", intitule : "CRANDELLES", cp : "15250" },
{ code : "15057", intitule : "CROS DE MONTVERT", cp : "15150" },
{ code : "15058", intitule : "CROS DE RONESQUE", cp : "15130" },
{ code : "15059", intitule : "CUSSAC", cp : "15430" },
{ code : "15060", intitule : "DEUX VERGES", cp : "15110" },
{ code : "15061", intitule : "DIENNE", cp : "15300" },
{ code : "15063", intitule : "DRUGEAC", cp : "15140" },
{ code : "15064", intitule : "ESCORAILLES", cp : "15700" },
{ code : "15065", intitule : "ESPINASSE", cp : "15110" },
{ code : "15066", intitule : "LE FALGOUX", cp : "15380" },
{ code : "15067", intitule : "LE FAU", cp : "15140" },
{ code : "15069", intitule : "FERRIERES ST MARY", cp : "15170" },
{ code : "15070", intitule : "FONTANGES", cp : "15140" },
{ code : "15072", intitule : "FREIX ANGLARDS", cp : "15310" },
{ code : "15073", intitule : "FRIDEFONT", cp : "15110" },
{ code : "15074", intitule : "GIOU DE MAMOU", cp : "15130" },
{ code : "15075", intitule : "GIRGOLS", cp : "15310" },
{ code : "15076", intitule : "GLENAT", cp : "15150" },
{ code : "15077", intitule : "GOURDIEGES", cp : "15230" },
{ code : "15078", intitule : "JABRUN", cp : "15110" },
{ code : "15079", intitule : "JALEYRAC", cp : "15200" },
{ code : "15080", intitule : "JOURSAC", cp : "15170" },
{ code : "15081", intitule : "JOU SOUS MONJOU", cp : "15800" },
{ code : "15082", intitule : "JUNHAC", cp : "15120" },
{ code : "15083", intitule : "JUSSAC", cp : "15250" },
{ code : "15084", intitule : "LABESSERETTE", cp : "15120" },
{ code : "15085", intitule : "LABROUSSE", cp : "15130" },
{ code : "15086", intitule : "LACAPELLE BARRES", cp : "15230" },
{ code : "15087", intitule : "LACAPELLE DEL FRAISSE", cp : "15120" },
{ code : "15088", intitule : "LACAPELLE VIESCAMP", cp : "15150" },
{ code : "15089", intitule : "LADINHAC", cp : "15120" },
{ code : "15090", intitule : "LAFEUILLADE EN VEZIE", cp : "15130" },
{ code : "15091", intitule : "LANDEYRAT", cp : "15160" },
{ code : "15092", intitule : "LANOBRE", cp : "15270" },
{ code : "15092", intitule : "LANOBRE", cp : "15270" },
{ code : "15093", intitule : "LAPEYRUGUE", cp : "15120" },
{ code : "15094", intitule : "LAROQUEBROU", cp : "15150" },
{ code : "15095", intitule : "LAROQUEVIEILLE", cp : "15250" },
{ code : "15096", intitule : "LASCELLE", cp : "15590" },
{ code : "15097", intitule : "LASTIC", cp : "15500" },
{ code : "15098", intitule : "LAURIE", cp : "15500" },
{ code : "15099", intitule : "LAVASTRIE", cp : "15260" },
{ code : "15100", intitule : "LAVEISSENET", cp : "15300" },
{ code : "15101", intitule : "LAVEISSIERE", cp : "15300" },
{ code : "15101", intitule : "LAVEISSIERE", cp : "15300" },
{ code : "15101", intitule : "LAVEISSIERE", cp : "15300" },
{ code : "15102", intitule : "LAVIGERIE", cp : "15300" },
{ code : "15103", intitule : "LEUCAMP", cp : "15120" },
{ code : "15104", intitule : "LEYNHAC", cp : "15600" },
{ code : "15105", intitule : "LEYVAUX", cp : "43450" },
{ code : "15106", intitule : "LIEUTADES", cp : "15110" },
{ code : "15107", intitule : "LORCIERES", cp : "15320" },
{ code : "15108", intitule : "VAL D ARCOMIE", cp : "15320" },
{ code : "15108", intitule : "VAL D ARCOMIE", cp : "15320" },
{ code : "15108", intitule : "VAL D ARCOMIE", cp : "15320" },
{ code : "15108", intitule : "VAL D ARCOMIE", cp : "15320" },
{ code : "15108", intitule : "VAL D ARCOMIE", cp : "15320" },
{ code : "15110", intitule : "LUGARDE", cp : "15190" },
{ code : "15111", intitule : "MADIC", cp : "15210" },
{ code : "15112", intitule : "MALBO", cp : "15230" },
{ code : "15113", intitule : "MANDAILLES ST JULIEN", cp : "15590" },
{ code : "15113", intitule : "MANDAILLES ST JULIEN", cp : "15590" },
{ code : "15114", intitule : "MARCENAT", cp : "15190" },
{ code : "15116", intitule : "MARCHASTEL", cp : "15400" },
{ code : "15117", intitule : "MARCOLES", cp : "15220" },
{ code : "15118", intitule : "MARMANHAC", cp : "15250" },
{ code : "15119", intitule : "MASSIAC", cp : "15500" },
{ code : "15120", intitule : "MAURIAC", cp : "15200" },
{ code : "15121", intitule : "MAURINES", cp : "15110" },
{ code : "15122", intitule : "MAURS", cp : "15600" },
{ code : "15123", intitule : "MEALLET", cp : "15200" },
{ code : "15124", intitule : "MENET", cp : "15400" },
{ code : "15125", intitule : "MENTIERES", cp : "15100" },
{ code : "15126", intitule : "MOLEDES", cp : "15500" },
{ code : "15127", intitule : "MOLOMPIZE", cp : "15500" },
{ code : "15128", intitule : "LA MONSELIE", cp : "15240" },
{ code : "15129", intitule : "MONTBOUDIF", cp : "15190" },
{ code : "15130", intitule : "MONTCHAMP", cp : "15100" },
{ code : "15131", intitule : "LE MONTEIL", cp : "15240" },
{ code : "15132", intitule : "MONTGRELEIX", cp : "15190" },
{ code : "15133", intitule : "MONTMURAT", cp : "15600" },
{ code : "15134", intitule : "MONTSALVY", cp : "15120" },
{ code : "15135", intitule : "MONTVERT", cp : "15150" },
{ code : "15136", intitule : "MOURJOU", cp : "15340" },
{ code : "15137", intitule : "MOUSSAGES", cp : "15380" },
{ code : "15138", intitule : "MURAT", cp : "15300" },
{ code : "15139", intitule : "NARNHAC", cp : "15230" },
{ code : "15140", intitule : "NAUCELLES", cp : "15250" },
{ code : "15141", intitule : "NEUSSARGUES MOISSAC", cp : "15170" },
{ code : "15142", intitule : "NEUVEGLISE", cp : "15260" },
{ code : "15143", intitule : "NIEUDAN", cp : "15150" },
{ code : "15144", intitule : "OMPS", cp : "15290" },
{ code : "15145", intitule : "ORADOUR", cp : "15260" },
{ code : "15146", intitule : "PAILHEROLS", cp : "15800" },
{ code : "15147", intitule : "PARLAN", cp : "15290" },
{ code : "15148", intitule : "PAULHAC", cp : "15430" },
{ code : "15149", intitule : "PAULHENC", cp : "15230" },
{ code : "15151", intitule : "PEYRUSSE", cp : "15170" },
{ code : "15152", intitule : "PIERREFORT", cp : "15230" },
{ code : "15153", intitule : "PLEAUX", cp : "15700" },
{ code : "15153", intitule : "PLEAUX", cp : "15700" },
{ code : "15153", intitule : "PLEAUX", cp : "15700" },
{ code : "15153", intitule : "PLEAUX", cp : "15700" },
{ code : "15154", intitule : "POLMINHAC", cp : "15800" },
{ code : "15155", intitule : "PRADIERS", cp : "15160" },
{ code : "15156", intitule : "PRUNET", cp : "15130" },
{ code : "15157", intitule : "QUEZAC", cp : "15600" },
{ code : "15158", intitule : "RAGEADE", cp : "15500" },
{ code : "15159", intitule : "RAULHAC", cp : "15800" },
{ code : "15160", intitule : "REILHAC", cp : "15250" },
{ code : "15161", intitule : "REZENTIERES", cp : "15170" },
{ code : "15162", intitule : "RIOM ES MONTAGNES", cp : "15400" },
{ code : "15163", intitule : "ROANNES ST MARY", cp : "15220" },
{ code : "15164", intitule : "ROFFIAC", cp : "15100" },
{ code : "15165", intitule : "ROUFFIAC", cp : "15150" },
{ code : "15166", intitule : "ROUMEGOUX", cp : "15290" },
{ code : "15167", intitule : "ROUZIERS", cp : "15600" },
{ code : "15168", intitule : "RUYNES EN MARGERIDE", cp : "15320" },
{ code : "15169", intitule : "SAIGNES", cp : "15240" },
{ code : "15170", intitule : "ST AMANDIN", cp : "15190" },
{ code : "15171", intitule : "STE ANASTASIE", cp : "15170" },
{ code : "15172", intitule : "ST ANTOINE", cp : "15220" },
{ code : "15173", intitule : "ST BONNET DE CONDAT", cp : "15190" },
{ code : "15174", intitule : "ST BONNET DE SALERS", cp : "15140" },
{ code : "15175", intitule : "ST CERNIN", cp : "15310" },
{ code : "15176", intitule : "ST CHAMANT", cp : "15140" },
{ code : "15178", intitule : "ST CIRGUES DE JORDANNE", cp : "15590" },
{ code : "15179", intitule : "ST CIRGUES DE MALBERT", cp : "15140" },
{ code : "15180", intitule : "ST CLEMENT", cp : "15800" },
{ code : "15181", intitule : "ST CONSTANT FOURNOULES", cp : "15600" },
{ code : "15181", intitule : "ST CONSTANT FOURNOULES", cp : "15600" },
{ code : "15182", intitule : "ST ETIENNE CANTALES", cp : "15150" },
{ code : "15183", intitule : "ST ETIENNE DE CARLAT", cp : "15130" },
{ code : "15184", intitule : "ST ETIENNE DE MAURS", cp : "15600" },
{ code : "15185", intitule : "ST ETIENNE DE CHOMEIL", cp : "15400" },
{ code : "15186", intitule : "STE EULALIE", cp : "15140" },
{ code : "15187", intitule : "ST FLOUR", cp : "15100" },
{ code : "15188", intitule : "ST GEORGES", cp : "15100" },
{ code : "15189", intitule : "ST GERONS", cp : "15150" },
{ code : "15190", intitule : "ST HIPPOLYTE", cp : "15400" },
{ code : "15191", intitule : "ST ILLIDE", cp : "15310" },
{ code : "15192", intitule : "ST JACQUES DES BLATS", cp : "15800" },
{ code : "15194", intitule : "ST JULIEN DE TOURSAC", cp : "15600" },
{ code : "15196", intitule : "ST MAMET LA SALVETAT", cp : "15220" },
{ code : "15198", intitule : "STE MARIE", cp : "15230" },
{ code : "15199", intitule : "ST MARTIAL", cp : "15110" },
{ code : "15200", intitule : "ST MARTIN CANTALES", cp : "15140" },
{ code : "15201", intitule : "ST MARTIN SOUS VIGOUROUX", cp : "15230" },
{ code : "15202", intitule : "ST MARTIN VALMEROUX", cp : "15140" },
{ code : "15202", intitule : "ST MARTIN VALMEROUX", cp : "15140" },
{ code : "15203", intitule : "ST MARY LE PLAIN", cp : "15500" },
{ code : "15204", intitule : "ST PAUL DES LANDES", cp : "15250" },
{ code : "15205", intitule : "ST PAUL DE SALERS", cp : "15140" },
{ code : "15206", intitule : "ST PIERRE", cp : "15350" },
{ code : "15207", intitule : "ST PONCY", cp : "15500" },
{ code : "15208", intitule : "ST PROJET DE SALERS", cp : "15140" },
{ code : "15209", intitule : "ST REMY DE CHAUDES AIGUES", cp : "15110" },
{ code : "15211", intitule : "ST SANTIN CANTALES", cp : "15150" },
{ code : "15212", intitule : "ST SANTIN DE MAURS", cp : "15600" },
{ code : "15213", intitule : "ST SATURNIN", cp : "15190" },
{ code : "15214", intitule : "ST SAURY", cp : "15290" },
{ code : "15215", intitule : "ST SIMON", cp : "15130" },
{ code : "15216", intitule : "ST URCIZE", cp : "15110" },
{ code : "15217", intitule : "ST VICTOR", cp : "15150" },
{ code : "15218", intitule : "ST VINCENT DE SALERS", cp : "15380" },
{ code : "15219", intitule : "SALERS", cp : "15140" },
{ code : "15220", intitule : "SALINS", cp : "15200" },
{ code : "15221", intitule : "SANSAC DE MARMIESSE", cp : "15130" },
{ code : "15222", intitule : "SANSAC VEINAZES", cp : "15120" },
{ code : "15223", intitule : "SAUVAT", cp : "15240" },
{ code : "15224", intitule : "LA SEGALASSIERE", cp : "15290" },
{ code : "15225", intitule : "SEGUR LES VILLAS", cp : "15300" },
{ code : "15226", intitule : "SENEZERGUES", cp : "15340" },
{ code : "15227", intitule : "SERIERS", cp : "15100" },
{ code : "15228", intitule : "SIRAN", cp : "15150" },
{ code : "15229", intitule : "SOULAGES", cp : "15100" },
{ code : "15230", intitule : "SOURNIAC", cp : "15200" },
{ code : "15231", intitule : "TALIZAT", cp : "15170" },
{ code : "15232", intitule : "TANAVELLE", cp : "15100" },
{ code : "15233", intitule : "TEISSIERES DE CORNET", cp : "15250" },
{ code : "15234", intitule : "TEISSIERES LES BOULIES", cp : "15130" },
{ code : "15235", intitule : "LES TERNES", cp : "15100" },
{ code : "15236", intitule : "THIEZAC", cp : "15800" },
{ code : "15237", intitule : "TIVIERS", cp : "15100" },
{ code : "15238", intitule : "TOURNEMIRE", cp : "15310" },
{ code : "15240", intitule : "TREMOUILLE", cp : "15270" },
{ code : "15241", intitule : "LA TRINITAT", cp : "15110" },
{ code : "15242", intitule : "LE TRIOULOU", cp : "15600" },
{ code : "15243", intitule : "TRIZAC", cp : "15400" },
{ code : "15244", intitule : "USSEL", cp : "15300" },
{ code : "15245", intitule : "VABRES", cp : "15100" },
{ code : "15246", intitule : "VALETTE", cp : "15400" },
{ code : "15247", intitule : "VALJOUZE", cp : "15170" },
{ code : "15248", intitule : "VALUEJOLS", cp : "15300" },
{ code : "15249", intitule : "LE VAULMIER", cp : "15380" },
{ code : "15250", intitule : "VEBRET", cp : "15240" },
{ code : "15251", intitule : "VEDRINES ST LOUP", cp : "15100" },
{ code : "15252", intitule : "VELZIC", cp : "15590" },
{ code : "15253", intitule : "VERNOLS", cp : "15160" },
{ code : "15254", intitule : "VEYRIERES", cp : "15350" },
{ code : "15255", intitule : "VEZAC", cp : "15130" },
{ code : "15256", intitule : "VEZE", cp : "15160" },
{ code : "15257", intitule : "VEZELS ROUSSY", cp : "15130" },
{ code : "15258", intitule : "VIC SUR CERE", cp : "15800" },
{ code : "15259", intitule : "VIEILLESPESSE", cp : "15500" },
{ code : "15260", intitule : "VIEILLEVIE", cp : "15120" },
{ code : "15261", intitule : "LE VIGEAN", cp : "15200" },
{ code : "15262", intitule : "VILLEDIEU", cp : "15100" },
{ code : "15263", intitule : "VIRARGUES", cp : "15300" },
{ code : "15264", intitule : "VITRAC", cp : "15220" },
{ code : "15265", intitule : "YDES", cp : "15210" },
{ code : "15266", intitule : "YOLET", cp : "15130" },
{ code : "15267", intitule : "YTRAC", cp : "15130" },
{ code : "15267", intitule : "YTRAC", cp : "15000" },
{ code : "15268", intitule : "LE ROUGET PERS", cp : "15290" },
{ code : "15268", intitule : "LE ROUGET PERS", cp : "15290" },
{ code : "15269", intitule : "BESSE", cp : "15140" },
{ code : "16001", intitule : "ABZAC", cp : "16500" },
{ code : "16002", intitule : "LES ADJOTS", cp : "16700" },
{ code : "16003", intitule : "AGRIS", cp : "16110" },
{ code : "16004", intitule : "AIGNES ET PUYPEROUX", cp : "16190" },
{ code : "16005", intitule : "AIGRE", cp : "16140" },
{ code : "16007", intitule : "ALLOUE", cp : "16490" },
{ code : "16008", intitule : "AMBERAC", cp : "16140" },
{ code : "16009", intitule : "AMBERNAC", cp : "16490" },
{ code : "16010", intitule : "AMBLEVILLE", cp : "16300" },
{ code : "16011", intitule : "ANAIS", cp : "16560" },
{ code : "16012", intitule : "ANGEAC CHAMPAGNE", cp : "16130" },
{ code : "16013", intitule : "ANGEAC CHARENTE", cp : "16120" },
{ code : "16014", intitule : "ANGEDUC", cp : "16300" },
{ code : "16015", intitule : "ANGOULEME", cp : "16000" },
{ code : "16016", intitule : "ANSAC SUR VIENNE", cp : "16500" },
{ code : "16017", intitule : "ANVILLE", cp : "16170" },
{ code : "16018", intitule : "ARS", cp : "16130" },
{ code : "16019", intitule : "ASNIERES SUR NOUERE", cp : "16290" },
{ code : "16020", intitule : "AUBETERRE SUR DRONNE", cp : "16390" },
{ code : "16023", intitule : "AUNAC", cp : "16460" },
{ code : "16024", intitule : "AUSSAC VADALLE", cp : "16560" },
{ code : "16025", intitule : "BAIGNES STE RADEGONDE", cp : "16360" },
{ code : "16026", intitule : "BALZAC", cp : "16430" },
{ code : "16027", intitule : "BARBEZIERES", cp : "16140" },
{ code : "16028", intitule : "BARBEZIEUX ST HILAIRE", cp : "16300" },
{ code : "16028", intitule : "BARBEZIEUX ST HILAIRE", cp : "16300" },
{ code : "16029", intitule : "BARDENAC", cp : "16210" },
{ code : "16030", intitule : "BARRET", cp : "16300" },
{ code : "16031", intitule : "BARRO", cp : "16700" },
{ code : "16032", intitule : "BASSAC", cp : "16120" },
{ code : "16033", intitule : "BAYERS", cp : "16460" },
{ code : "16034", intitule : "BAZAC", cp : "16210" },
{ code : "16035", intitule : "BEAULIEU SUR SONNETTE", cp : "16450" },
{ code : "16036", intitule : "BECHERESSE", cp : "16250" },
{ code : "16037", intitule : "BELLON", cp : "16210" },
{ code : "16038", intitule : "BENEST", cp : "16350" },
{ code : "16039", intitule : "BERNAC", cp : "16700" },
{ code : "16040", intitule : "BERNEUIL", cp : "16480" },
{ code : "16041", intitule : "BESSAC", cp : "16250" },
{ code : "16042", intitule : "BESSE", cp : "16140" },
{ code : "16044", intitule : "BIOUSSAC", cp : "16700" },
{ code : "16045", intitule : "BIRAC", cp : "16120" },
{ code : "16046", intitule : "BLANZAC PORCHERESSE", cp : "16250" },
{ code : "16046", intitule : "BLANZAC PORCHERESSE", cp : "16250" },
{ code : "16047", intitule : "BLANZAGUET ST CYBARD", cp : "16320" },
{ code : "16048", intitule : "BOISBRETEAU", cp : "16480" },
{ code : "16049", intitule : "BONNES", cp : "16390" },
{ code : "16050", intitule : "BONNEUIL", cp : "16120" },
{ code : "16051", intitule : "BONNEVILLE", cp : "16170" },
{ code : "16052", intitule : "BORS DE MONTMOREAU", cp : "16190" },
{ code : "16053", intitule : "BORS DE BAIGNES", cp : "16360" },
{ code : "16054", intitule : "LE BOUCHAGE", cp : "16350" },
{ code : "16055", intitule : "BOUEX", cp : "16410" },
{ code : "16056", intitule : "BOURG CHARENTE", cp : "16200" },
{ code : "16057", intitule : "BOUTEVILLE", cp : "16120" },
{ code : "16058", intitule : "BOUTIERS ST TROJAN", cp : "16100" },
{ code : "16059", intitule : "BRETTES", cp : "16240" },
{ code : "16060", intitule : "BREVILLE", cp : "16370" },
{ code : "16061", intitule : "BRIE", cp : "16590" },
{ code : "16062", intitule : "BRIE SOUS BARBEZIEUX", cp : "16300" },
{ code : "16063", intitule : "BRIE SOUS CHALAIS", cp : "16210" },
{ code : "16064", intitule : "BRIGUEUIL", cp : "16420" },
{ code : "16065", intitule : "BRILLAC", cp : "16500" },
{ code : "16066", intitule : "BROSSAC", cp : "16480" },
{ code : "16067", intitule : "BUNZAC", cp : "16110" },
{ code : "16068", intitule : "CELLEFROUIN", cp : "16260" },
{ code : "16069", intitule : "CELLETTES", cp : "16230" },
{ code : "16070", intitule : "CHABANAIS", cp : "16150" },
{ code : "16071", intitule : "CHABRAC", cp : "16150" },
{ code : "16072", intitule : "CHADURIE", cp : "16250" },
{ code : "16073", intitule : "CHALAIS", cp : "16210" },
{ code : "16073", intitule : "CHALAIS", cp : "16210" },
{ code : "16073", intitule : "CHALAIS", cp : "16210" },
{ code : "16074", intitule : "CHALLIGNAC", cp : "16300" },
{ code : "16075", intitule : "CHAMPAGNE VIGNY", cp : "16250" },
{ code : "16076", intitule : "CHAMPAGNE MOUTON", cp : "16350" },
{ code : "16077", intitule : "CHAMPMILLON", cp : "16290" },
{ code : "16078", intitule : "CHAMPNIERS", cp : "16430" },
{ code : "16079", intitule : "CHANTILLAC", cp : "16360" },
{ code : "16081", intitule : "LA CHAPELLE", cp : "16140" },
{ code : "16082", intitule : "BOISNE LA TUDE", cp : "16320" },
{ code : "16082", intitule : "BOISNE LA TUDE", cp : "16320" },
{ code : "16082", intitule : "BOISNE LA TUDE", cp : "16320" },
{ code : "16083", intitule : "CHARME", cp : "16140" },
{ code : "16084", intitule : "CHARRAS", cp : "16380" },
{ code : "16085", intitule : "CHASSENEUIL SUR BONNIEURE", cp : "16260" },
{ code : "16086", intitule : "CHASSENON", cp : "16150" },
{ code : "16087", intitule : "CHASSIECQ", cp : "16350" },
{ code : "16088", intitule : "CHASSORS", cp : "16200" },
{ code : "16089", intitule : "CHATEAUBERNARD", cp : "16100" },
{ code : "16090", intitule : "CHATEAUNEUF SUR CHARENTE", cp : "16120" },
{ code : "16091", intitule : "CHATIGNAC", cp : "16480" },
{ code : "16093", intitule : "CHAZELLES", cp : "16380" },
{ code : "16094", intitule : "CHENOMMET", cp : "16460" },
{ code : "16095", intitule : "CHENON", cp : "16460" },
{ code : "16096", intitule : "CHERVES CHATELARS", cp : "16310" },
{ code : "16097", intitule : "CHERVES RICHEMONT", cp : "16370" },
{ code : "16097", intitule : "CHERVES RICHEMONT", cp : "16370" },
{ code : "16098", intitule : "LA CHEVRERIE", cp : "16240" },
{ code : "16099", intitule : "CHILLAC", cp : "16480" },
{ code : "16100", intitule : "CHIRAC", cp : "16150" },
{ code : "16101", intitule : "CLAIX", cp : "16440" },
{ code : "16102", intitule : "COGNAC", cp : "16100" },
{ code : "16103", intitule : "COMBIERS", cp : "16320" },
{ code : "16104", intitule : "CONDAC", cp : "16700" },
{ code : "16105", intitule : "CONDEON", cp : "16360" },
{ code : "16106", intitule : "CONFOLENS", cp : "16500" },
{ code : "16106", intitule : "CONFOLENS", cp : "16500" },
{ code : "16107", intitule : "COULGENS", cp : "16560" },
{ code : "16108", intitule : "COULONGES", cp : "16330" },
{ code : "16109", intitule : "COURBILLAC", cp : "16200" },
{ code : "16110", intitule : "COURCOME", cp : "16240" },
{ code : "16111", intitule : "COURGEAC", cp : "16190" },
{ code : "16112", intitule : "COURLAC", cp : "16210" },
{ code : "16113", intitule : "LA COURONNE", cp : "16400" },
{ code : "16114", intitule : "COUTURE", cp : "16460" },
{ code : "16115", intitule : "CRESSAC ST GENIS", cp : "16250" },
{ code : "16115", intitule : "CRESSAC ST GENIS", cp : "16250" },
{ code : "16116", intitule : "CRITEUIL LA MAGDELEINE", cp : "16300" },
{ code : "16117", intitule : "CURAC", cp : "16210" },
{ code : "16118", intitule : "DEVIAT", cp : "16190" },
{ code : "16119", intitule : "DIGNAC", cp : "16410" },
{ code : "16120", intitule : "DIRAC", cp : "16410" },
{ code : "16121", intitule : "DOUZAT", cp : "16290" },
{ code : "16122", intitule : "EBREON", cp : "16140" },
{ code : "16123", intitule : "ECHALLAT", cp : "16170" },
{ code : "16124", intitule : "ECURAS", cp : "16220" },
{ code : "16125", intitule : "EDON", cp : "16320" },
{ code : "16127", intitule : "EMPURE", cp : "16240" },
{ code : "16128", intitule : "EPENEDE", cp : "16490" },
{ code : "16129", intitule : "ERAVILLE", cp : "16120" },
{ code : "16130", intitule : "LES ESSARDS", cp : "16210" },
{ code : "16131", intitule : "ESSE", cp : "16500" },
{ code : "16132", intitule : "ETAGNAC", cp : "16150" },
{ code : "16133", intitule : "ETRIAC", cp : "16250" },
{ code : "16134", intitule : "EXIDEUIL", cp : "16150" },
{ code : "16135", intitule : "EYMOUTHIERS", cp : "16220" },
{ code : "16136", intitule : "LA FAYE", cp : "16700" },
{ code : "16137", intitule : "FEUILLADE", cp : "16380" },
{ code : "16138", intitule : "FLEAC", cp : "16730" },
{ code : "16139", intitule : "FLEURAC", cp : "16200" },
{ code : "16140", intitule : "FONTCLAIREAU", cp : "16230" },
{ code : "16141", intitule : "FONTENILLE", cp : "16230" },
{ code : "16142", intitule : "LA FORET DE TESSE", cp : "16240" },
{ code : "16143", intitule : "FOUQUEBRUNE", cp : "16410" },
{ code : "16144", intitule : "FOUQUEURE", cp : "16140" },
{ code : "16145", intitule : "FOUSSIGNAC", cp : "16200" },
{ code : "16146", intitule : "GARAT", cp : "16410" },
{ code : "16147", intitule : "GARDES LE PONTAROUX", cp : "16320" },
{ code : "16148", intitule : "GENAC BIGNAC", cp : "16170" },
{ code : "16148", intitule : "GENAC BIGNAC", cp : "16170" },
{ code : "16149", intitule : "GENOUILLAC", cp : "16270" },
{ code : "16150", intitule : "GENSAC LA PALLUE", cp : "16130" },
{ code : "16151", intitule : "GENTE", cp : "16130" },
{ code : "16152", intitule : "GIMEUX", cp : "16130" },
{ code : "16153", intitule : "GONDEVILLE", cp : "16200" },
{ code : "16154", intitule : "GOND PONTOUVRE", cp : "16160" },
{ code : "16155", intitule : "LES GOURS", cp : "16140" },
{ code : "16156", intitule : "GOURVILLE", cp : "16170" },
{ code : "16157", intitule : "LE GRAND MADIEU", cp : "16450" },
{ code : "16158", intitule : "GRASSAC", cp : "16380" },
{ code : "16160", intitule : "GUIMPS", cp : "16300" },
{ code : "16161", intitule : "GUIZENGEARD", cp : "16480" },
{ code : "16162", intitule : "GURAT", cp : "16320" },
{ code : "16163", intitule : "HIERSAC", cp : "16290" },
{ code : "16164", intitule : "HIESSE", cp : "16490" },
{ code : "16165", intitule : "HOULETTE", cp : "16200" },
{ code : "16166", intitule : "L ISLE D ESPAGNAC", cp : "16340" },
{ code : "16167", intitule : "JARNAC", cp : "16200" },
{ code : "16168", intitule : "JAULDES", cp : "16560" },
{ code : "16169", intitule : "JAVREZAC", cp : "16100" },
{ code : "16170", intitule : "JUIGNAC", cp : "16190" },
{ code : "16171", intitule : "JUILLAC LE COQ", cp : "16130" },
{ code : "16173", intitule : "JUILLE", cp : "16230" },
{ code : "16174", intitule : "JULIENNE", cp : "16200" },
{ code : "16175", intitule : "VAL DES VIGNES", cp : "16250" },
{ code : "16175", intitule : "VAL DES VIGNES", cp : "16250" },
{ code : "16175", intitule : "VAL DES VIGNES", cp : "16250" },
{ code : "16175", intitule : "VAL DES VIGNES", cp : "16250" },
{ code : "16176", intitule : "LACHAISE", cp : "16300" },
{ code : "16177", intitule : "LADIVILLE", cp : "16120" },
{ code : "16178", intitule : "LAGARDE SUR LE NE", cp : "16300" },
{ code : "16180", intitule : "LAPRADE", cp : "16390" },
{ code : "16181", intitule : "LESSAC", cp : "16500" },
{ code : "16182", intitule : "LESTERPS", cp : "16420" },
{ code : "16183", intitule : "LESIGNAC DURAND", cp : "16310" },
{ code : "16184", intitule : "LICHERES", cp : "16460" },
{ code : "16185", intitule : "LIGNE", cp : "16140" },
{ code : "16186", intitule : "LIGNIERES SONNEVILLE", cp : "16130" },
{ code : "16187", intitule : "LINARS", cp : "16730" },
{ code : "16188", intitule : "LE LINDOIS", cp : "16310" },
{ code : "16189", intitule : "LONDIGNY", cp : "16700" },
{ code : "16190", intitule : "LONGRE", cp : "16240" },
{ code : "16191", intitule : "LONNES", cp : "16230" },
{ code : "16192", intitule : "ROUMAZIERES LOUBERT", cp : "16270" },
{ code : "16192", intitule : "ROUMAZIERES LOUBERT", cp : "16270" },
{ code : "16193", intitule : "LOUZAC ST ANDRE", cp : "16100" },
{ code : "16193", intitule : "LOUZAC ST ANDRE", cp : "16100" },
{ code : "16194", intitule : "LUPSAULT", cp : "16140" },
{ code : "16195", intitule : "LUSSAC", cp : "16450" },
{ code : "16196", intitule : "LUXE", cp : "16230" },
{ code : "16197", intitule : "LA MAGDELEINE", cp : "16240" },
{ code : "16198", intitule : "MAGNAC LAVALETTE VILLARS", cp : "16320" },
{ code : "16199", intitule : "MAGNAC SUR TOUVRE", cp : "16600" },
{ code : "16200", intitule : "MAINE DE BOIXE", cp : "16230" },
{ code : "16202", intitule : "MAINXE", cp : "16200" },
{ code : "16203", intitule : "MAINZAC", cp : "16380" },
{ code : "16204", intitule : "MALAVILLE", cp : "16120" },
{ code : "16205", intitule : "MANOT", cp : "16500" },
{ code : "16206", intitule : "MANSLE", cp : "16230" },
{ code : "16207", intitule : "MARCILLAC LANVILLE", cp : "16140" },
{ code : "16208", intitule : "MAREUIL", cp : "16170" },
{ code : "16209", intitule : "MARILLAC LE FRANC", cp : "16110" },
{ code : "16210", intitule : "MARSAC", cp : "16570" },
{ code : "16211", intitule : "MARTHON", cp : "16380" },
{ code : "16212", intitule : "MASSIGNAC", cp : "16310" },
{ code : "16213", intitule : "MAZEROLLES", cp : "16310" },
{ code : "16214", intitule : "MAZIERES", cp : "16270" },
{ code : "16215", intitule : "MEDILLAC", cp : "16210" },
{ code : "16216", intitule : "MERIGNAC", cp : "16200" },
{ code : "16217", intitule : "MERPINS", cp : "16100" },
{ code : "16218", intitule : "MESNAC", cp : "16370" },
{ code : "16220", intitule : "LES METAIRIES", cp : "16200" },
{ code : "16221", intitule : "MONS", cp : "16140" },
{ code : "16222", intitule : "MONTBOYER", cp : "16620" },
{ code : "16223", intitule : "MONTBRON", cp : "16220" },
{ code : "16224", intitule : "MONTMERAC", cp : "16300" },
{ code : "16224", intitule : "MONTMERAC", cp : "16300" },
{ code : "16225", intitule : "MONTEMBOEUF", cp : "16310" },
{ code : "16226", intitule : "MONTIGNAC CHARENTE", cp : "16330" },
{ code : "16227", intitule : "MONTIGNAC LE COQ", cp : "16390" },
{ code : "16228", intitule : "MONTIGNE", cp : "16170" },
{ code : "16229", intitule : "MONTJEAN", cp : "16240" },
{ code : "16230", intitule : "MONTMOREAU ST CYBARD", cp : "16190" },
{ code : "16231", intitule : "MONTROLLET", cp : "16420" },
{ code : "16232", intitule : "MORNAC", cp : "16600" },
{ code : "16233", intitule : "MOSNAC", cp : "16120" },
{ code : "16234", intitule : "MOULIDARS", cp : "16290" },
{ code : "16236", intitule : "MOUTHIERS SUR BOEME", cp : "16440" },
{ code : "16237", intitule : "MOUTON", cp : "16460" },
{ code : "16238", intitule : "MOUTONNEAU", cp : "16460" },
{ code : "16239", intitule : "MOUZON", cp : "16310" },
{ code : "16240", intitule : "NABINAUD", cp : "16390" },
{ code : "16241", intitule : "NANCLARS", cp : "16230" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16242", intitule : "NANTEUIL EN VALLEE", cp : "16700" },
{ code : "16243", intitule : "NERCILLAC", cp : "16200" },
{ code : "16244", intitule : "NERSAC", cp : "16440" },
{ code : "16245", intitule : "NIEUIL", cp : "16270" },
{ code : "16246", intitule : "NONAC", cp : "16190" },
{ code : "16247", intitule : "NONAVILLE", cp : "16120" },
{ code : "16248", intitule : "ORADOUR", cp : "16140" },
{ code : "16249", intitule : "ORADOUR FANAIS", cp : "16500" },
{ code : "16250", intitule : "ORGEDEUIL", cp : "16220" },
{ code : "16251", intitule : "ORIOLLES", cp : "16480" },
{ code : "16252", intitule : "ORIVAL", cp : "16210" },
{ code : "16253", intitule : "PAIZAY NAUDOUIN EMBOURIE", cp : "16240" },
{ code : "16253", intitule : "PAIZAY NAUDOUIN EMBOURIE", cp : "16240" },
{ code : "16254", intitule : "PALLUAUD", cp : "16390" },
{ code : "16255", intitule : "PARZAC", cp : "16450" },
{ code : "16256", intitule : "PASSIRAC", cp : "16480" },
{ code : "16258", intitule : "PERIGNAC", cp : "16250" },
{ code : "16259", intitule : "LA PERUSE", cp : "16270" },
{ code : "16260", intitule : "PILLAC", cp : "16390" },
{ code : "16261", intitule : "LES PINS", cp : "16260" },
{ code : "16263", intitule : "PLASSAC ROUFFIAC", cp : "16250" },
{ code : "16264", intitule : "PLEUVILLE", cp : "16490" },
{ code : "16267", intitule : "POULLIGNAC", cp : "16190" },
{ code : "16268", intitule : "POURSAC", cp : "16700" },
{ code : "16269", intitule : "PRANZAC", cp : "16110" },
{ code : "16270", intitule : "PRESSIGNAC", cp : "16150" },
{ code : "16271", intitule : "PUYMOYEN", cp : "16400" },
{ code : "16272", intitule : "PUYREAUX", cp : "16230" },
{ code : "16273", intitule : "RAIX", cp : "16240" },
{ code : "16274", intitule : "RANCOGNE", cp : "16110" },
{ code : "16275", intitule : "RANVILLE BREUILLAUD", cp : "16140" },
{ code : "16276", intitule : "REIGNAC", cp : "16360" },
{ code : "16277", intitule : "REPARSAC", cp : "16200" },
{ code : "16279", intitule : "RIOUX MARTIN", cp : "16210" },
{ code : "16280", intitule : "RIVIERES", cp : "16110" },
{ code : "16281", intitule : "LA ROCHEFOUCAULD", cp : "16110" },
{ code : "16282", intitule : "LA ROCHETTE", cp : "16110" },
{ code : "16283", intitule : "RONSENAC", cp : "16320" },
{ code : "16284", intitule : "ROUFFIAC", cp : "16210" },
{ code : "16285", intitule : "ROUGNAC", cp : "16320" },
{ code : "16286", intitule : "ROUILLAC", cp : "16170" },
{ code : "16286", intitule : "ROUILLAC", cp : "16170" },
{ code : "16286", intitule : "ROUILLAC", cp : "16170" },
{ code : "16287", intitule : "ROULLET ST ESTEPHE", cp : "16440" },
{ code : "16287", intitule : "ROULLET ST ESTEPHE", cp : "16440" },
{ code : "16289", intitule : "ROUSSINES", cp : "16310" },
{ code : "16290", intitule : "ROUZEDE", cp : "16220" },
{ code : "16291", intitule : "RUELLE SUR TOUVRE", cp : "16600" },
{ code : "16292", intitule : "RUFFEC", cp : "16700" },
{ code : "16293", intitule : "ST ADJUTORY", cp : "16310" },
{ code : "16294", intitule : "ST AMANT DE MONTMOREAU", cp : "16190" },
{ code : "16295", intitule : "ST AMANT DE BOIXE", cp : "16330" },
{ code : "16296", intitule : "ST AMANT DE BONNIEURE", cp : "16230" },
{ code : "16297", intitule : "GRAVES ST AMANT", cp : "16120" },
{ code : "16297", intitule : "GRAVES ST AMANT", cp : "16120" },
{ code : "16298", intitule : "ST AMANT DE NOUERE", cp : "16170" },
{ code : "16300", intitule : "ST ANGEAU", cp : "16230" },
{ code : "16301", intitule : "ST AULAIS LA CHAPELLE", cp : "16300" },
{ code : "16302", intitule : "ST AVIT", cp : "16210" },
{ code : "16303", intitule : "ST BONNET", cp : "16300" },
{ code : "16304", intitule : "ST BRICE", cp : "16100" },
{ code : "16306", intitule : "ST CHRISTOPHE", cp : "16420" },
{ code : "16307", intitule : "ST CIERS SUR BONNIEURE", cp : "16230" },
{ code : "16308", intitule : "ST CLAUD", cp : "16450" },
{ code : "16309", intitule : "STE COLOMBE", cp : "16230" },
{ code : "16310", intitule : "ST COUTANT", cp : "16350" },
{ code : "16312", intitule : "ST CYBARDEAUX", cp : "16170" },
{ code : "16314", intitule : "ST EUTROPE", cp : "16190" },
{ code : "16315", intitule : "ST FELIX", cp : "16480" },
{ code : "16316", intitule : "ST FORT SUR LE NE", cp : "16130" },
{ code : "16317", intitule : "ST FRAIGNE", cp : "16140" },
{ code : "16318", intitule : "ST FRONT", cp : "16460" },
{ code : "16320", intitule : "ST GENIS D HIERSAC", cp : "16570" },
{ code : "16321", intitule : "ST GEORGES", cp : "16700" },
{ code : "16323", intitule : "ST GERMAIN DE MONTBRON", cp : "16380" },
{ code : "16325", intitule : "ST GOURSON", cp : "16700" },
{ code : "16326", intitule : "ST GROUX", cp : "16230" },
{ code : "16328", intitule : "ST LAURENT DE BELZAGOT", cp : "16190" },
{ code : "16329", intitule : "ST LAURENT DE CERIS", cp : "16450" },
{ code : "16330", intitule : "ST LAURENT DE COGNAC", cp : "16100" },
{ code : "16331", intitule : "ST LAURENT DES COMBES", cp : "16480" },
{ code : "16332", intitule : "ST LEGER", cp : "16250" },
{ code : "16334", intitule : "ST MARTIAL", cp : "16190" },
{ code : "16335", intitule : "ST MARTIN DU CLOCHER", cp : "16700" },
{ code : "16336", intitule : "ST MARY", cp : "16260" },
{ code : "16337", intitule : "ST MAURICE DES LIONS", cp : "16500" },
{ code : "16338", intitule : "ST MEDARD", cp : "16300" },
{ code : "16339", intitule : "AUGE ST MEDARD", cp : "16170" },
{ code : "16339", intitule : "AUGE ST MEDARD", cp : "16170" },
{ code : "16339", intitule : "AUGE ST MEDARD", cp : "16170" },
{ code : "16340", intitule : "ST MEME LES CARRIERES", cp : "16720" },
{ code : "16341", intitule : "ST MICHEL", cp : "16470" },
{ code : "16342", intitule : "ST PALAIS DU NE", cp : "16300" },
{ code : "16343", intitule : "ST PREUIL", cp : "16130" },
{ code : "16344", intitule : "ST PROJET ST CONSTANT", cp : "16110" },
{ code : "16345", intitule : "ST QUENTIN SUR CHARENTE", cp : "16150" },
{ code : "16346", intitule : "ST QUENTIN DE CHALAIS", cp : "16210" },
{ code : "16347", intitule : "ST ROMAIN", cp : "16210" },
{ code : "16348", intitule : "ST SATURNIN", cp : "16290" },
{ code : "16349", intitule : "STE SEVERE", cp : "16200" },
{ code : "16350", intitule : "ST SEVERIN", cp : "16390" },
{ code : "16351", intitule : "ST SIMEUX", cp : "16120" },
{ code : "16352", intitule : "ST SIMON", cp : "16120" },
{ code : "16353", intitule : "ST SORNIN", cp : "16220" },
{ code : "16354", intitule : "STE SOULINE", cp : "16480" },
{ code : "16355", intitule : "ST SULPICE DE COGNAC", cp : "16370" },
{ code : "16356", intitule : "ST SULPICE DE RUFFEC", cp : "16460" },
{ code : "16357", intitule : "ST VALLIER", cp : "16480" },
{ code : "16358", intitule : "ST YRIEIX SUR CHARENTE", cp : "16710" },
{ code : "16359", intitule : "SALLES D ANGLES", cp : "16130" },
{ code : "16360", intitule : "SALLES DE BARBEZIEUX", cp : "16300" },
{ code : "16361", intitule : "SALLES DE VILLEFAGNAN", cp : "16700" },
{ code : "16362", intitule : "SALLES LAVALETTE", cp : "16190" },
{ code : "16363", intitule : "SAULGOND", cp : "16420" },
{ code : "16364", intitule : "SAUVAGNAC", cp : "16310" },
{ code : "16365", intitule : "SAUVIGNAC", cp : "16480" },
{ code : "16366", intitule : "SEGONZAC", cp : "16130" },
{ code : "16368", intitule : "SERS", cp : "16410" },
{ code : "16369", intitule : "SIGOGNE", cp : "16200" },
{ code : "16370", intitule : "SIREUIL", cp : "16440" },
{ code : "16372", intitule : "SOUFFRIGNAC", cp : "16380" },
{ code : "16373", intitule : "SOUVIGNE", cp : "16240" },
{ code : "16374", intitule : "SOYAUX", cp : "16800" },
{ code : "16375", intitule : "SUAUX", cp : "16260" },
{ code : "16376", intitule : "SURIS", cp : "16270" },
{ code : "16377", intitule : "LA TACHE", cp : "16260" },
{ code : "16378", intitule : "TAIZE AIZIE", cp : "16700" },
{ code : "16379", intitule : "TAPONNAT FLEURIGNAC", cp : "16110" },
{ code : "16380", intitule : "LE TATRE", cp : "16360" },
{ code : "16381", intitule : "THEIL RABIER", cp : "16240" },
{ code : "16382", intitule : "TORSAC", cp : "16410" },
{ code : "16383", intitule : "TOURRIERS", cp : "16560" },
{ code : "16384", intitule : "TOUVERAC", cp : "16360" },
{ code : "16385", intitule : "TOUVRE", cp : "16600" },
{ code : "16386", intitule : "TOUZAC", cp : "16120" },
{ code : "16387", intitule : "TRIAC LAUTRAIT", cp : "16200" },
{ code : "16387", intitule : "TRIAC LAUTRAIT", cp : "16200" },
{ code : "16388", intitule : "TROIS PALIS", cp : "16730" },
{ code : "16389", intitule : "TURGON", cp : "16350" },
{ code : "16390", intitule : "TUSSON", cp : "16140" },
{ code : "16391", intitule : "TUZIE", cp : "16700" },
{ code : "16392", intitule : "VALENCE", cp : "16460" },
{ code : "16393", intitule : "VARS", cp : "16330" },
{ code : "16394", intitule : "VAUX LAVALETTE", cp : "16320" },
{ code : "16395", intitule : "VAUX ROUILLAC", cp : "16170" },
{ code : "16396", intitule : "VENTOUSE", cp : "16460" },
{ code : "16397", intitule : "VERDILLE", cp : "16140" },
{ code : "16398", intitule : "VERNEUIL", cp : "16310" },
{ code : "16399", intitule : "VERRIERES", cp : "16130" },
{ code : "16400", intitule : "VERTEUIL SUR CHARENTE", cp : "16510" },
{ code : "16401", intitule : "VERVANT", cp : "16330" },
{ code : "16402", intitule : "VIBRAC", cp : "16120" },
{ code : "16403", intitule : "LE VIEUX CERIER", cp : "16350" },
{ code : "16404", intitule : "VIEUX RUFFEC", cp : "16350" },
{ code : "16405", intitule : "VIGNOLLES", cp : "16300" },
{ code : "16406", intitule : "VILHONNEUR", cp : "16220" },
{ code : "16408", intitule : "VILLEBOIS LAVALETTE", cp : "16320" },
{ code : "16409", intitule : "VILLEFAGNAN", cp : "16240" },
{ code : "16410", intitule : "VILLEGATS", cp : "16700" },
{ code : "16411", intitule : "VILLEJESUS", cp : "16140" },
{ code : "16412", intitule : "VILLEJOUBERT", cp : "16560" },
{ code : "16413", intitule : "VILLIERS LE ROUX", cp : "16240" },
{ code : "16414", intitule : "VILLOGNON", cp : "16230" },
{ code : "16415", intitule : "VINDELLE", cp : "16430" },
{ code : "16416", intitule : "VITRAC ST VINCENT", cp : "16310" },
{ code : "16417", intitule : "VIVILLE", cp : "16120" },
{ code : "16418", intitule : "VOEUIL ET GIGET", cp : "16400" },
{ code : "16419", intitule : "VOUHARTE", cp : "16330" },
{ code : "16420", intitule : "VOULGEZAC", cp : "16250" },
{ code : "16421", intitule : "VOUTHON", cp : "16220" },
{ code : "16422", intitule : "VOUZAN", cp : "16410" },
{ code : "16423", intitule : "XAMBES", cp : "16330" },
{ code : "16424", intitule : "YVIERS", cp : "16210" },
{ code : "16425", intitule : "YVRAC ET MALLEYRAND", cp : "16110" },
{ code : "17002", intitule : "AGUDELLE", cp : "17500" },
{ code : "17003", intitule : "AIGREFEUILLE D AUNIS", cp : "17290" },
{ code : "17004", intitule : "ILE D AIX", cp : "17123" },
{ code : "17005", intitule : "ALLAS BOCAGE", cp : "17150" },
{ code : "17006", intitule : "ALLAS CHAMPAGNE", cp : "17500" },
{ code : "17007", intitule : "ANAIS", cp : "17540" },
{ code : "17008", intitule : "ANDILLY", cp : "17230" },
{ code : "17008", intitule : "ANDILLY", cp : "17230" },
{ code : "17009", intitule : "ANGLIERS", cp : "17540" },
{ code : "17010", intitule : "ANGOULINS", cp : "17690" },
{ code : "17011", intitule : "ANNEPONT", cp : "17350" },
{ code : "17012", intitule : "ANNEZAY", cp : "17380" },
{ code : "17013", intitule : "ANTEZANT LA CHAPELLE", cp : "17400" },
{ code : "17013", intitule : "ANTEZANT LA CHAPELLE", cp : "17400" },
{ code : "17015", intitule : "ARCES", cp : "17120" },
{ code : "17016", intitule : "ARCHIAC", cp : "17520" },
{ code : "17017", intitule : "ARCHINGEAY", cp : "17380" },
{ code : "17018", intitule : "ARDILLIERES", cp : "17290" },
{ code : "17019", intitule : "ARS EN RE", cp : "17590" },
{ code : "17020", intitule : "ARTHENAC", cp : "17520" },
{ code : "17021", intitule : "ARVERT", cp : "17530" },
{ code : "17022", intitule : "ASNIERES LA GIRAUD", cp : "17400" },
{ code : "17023", intitule : "AUJAC", cp : "17770" },
{ code : "17024", intitule : "AULNAY", cp : "17470" },
{ code : "17024", intitule : "AULNAY", cp : "17470" },
{ code : "17025", intitule : "AUMAGNE", cp : "17770" },
{ code : "17025", intitule : "AUMAGNE", cp : "17770" },
{ code : "17026", intitule : "AUTHON EBEON", cp : "17770" },
{ code : "17026", intitule : "AUTHON EBEON", cp : "17770" },
{ code : "17027", intitule : "AVY", cp : "17800" },
{ code : "17028", intitule : "AYTRE", cp : "17440" },
{ code : "17029", intitule : "BAGNIZEAU", cp : "17160" },
{ code : "17030", intitule : "BALANZAC", cp : "17600" },
{ code : "17031", intitule : "BALLANS", cp : "17160" },
{ code : "17032", intitule : "BALLON", cp : "17290" },
{ code : "17033", intitule : "LA BARDE", cp : "17360" },
{ code : "17034", intitule : "BARZAN", cp : "17120" },
{ code : "17035", intitule : "BAZAUGES", cp : "17490" },
{ code : "17036", intitule : "BEAUGEAY", cp : "17620" },
{ code : "17037", intitule : "BEAUVAIS SUR MATHA", cp : "17490" },
{ code : "17038", intitule : "BEDENAC", cp : "17210" },
{ code : "17039", intitule : "BELLUIRE", cp : "17800" },
{ code : "17041", intitule : "BENON", cp : "17170" },
{ code : "17042", intitule : "BERCLOUX", cp : "17770" },
{ code : "17043", intitule : "BERNAY ST MARTIN", cp : "17330" },
{ code : "17043", intitule : "BERNAY ST MARTIN", cp : "17330" },
{ code : "17044", intitule : "BERNEUIL", cp : "17460" },
{ code : "17045", intitule : "BEURLAY", cp : "17250" },
{ code : "17046", intitule : "BIGNAY", cp : "17400" },
{ code : "17047", intitule : "BIRON", cp : "17800" },
{ code : "17048", intitule : "BLANZAC LES MATHA", cp : "17160" },
{ code : "17049", intitule : "BLANZAY SUR BOUTONNE", cp : "17470" },
{ code : "17050", intitule : "BOIS", cp : "17240" },
{ code : "17051", intitule : "LE BOIS PLAGE EN RE", cp : "17580" },
{ code : "17052", intitule : "BOISREDON", cp : "17150" },
{ code : "17053", intitule : "BORDS", cp : "17430" },
{ code : "17054", intitule : "BORESSE ET MARTRON", cp : "17270" },
{ code : "17055", intitule : "BOSCAMNANT", cp : "17360" },
{ code : "17056", intitule : "BOUGNEAU", cp : "17800" },
{ code : "17057", intitule : "BOUHET", cp : "17540" },
{ code : "17058", intitule : "BOURCEFRANC LE CHAPUS", cp : "17560" },
{ code : "17059", intitule : "BOURGNEUF", cp : "17220" },
{ code : "17060", intitule : "BOUTENAC TOUVENT", cp : "17120" },
{ code : "17061", intitule : "BRAN", cp : "17210" },
{ code : "17062", intitule : "BRESDON", cp : "17490" },
{ code : "17063", intitule : "BREUIL LA REORTE", cp : "17700" },
{ code : "17064", intitule : "BREUILLET", cp : "17920" },
{ code : "17065", intitule : "BREUIL MAGNE", cp : "17870" },
{ code : "17066", intitule : "BRIE SOUS ARCHIAC", cp : "17520" },
{ code : "17067", intitule : "BRIE SOUS MATHA", cp : "17160" },
{ code : "17068", intitule : "BRIE SOUS MORTAGNE", cp : "17120" },
{ code : "17069", intitule : "BRIVES SUR CHARENTE", cp : "17800" },
{ code : "17070", intitule : "BRIZAMBOURG", cp : "17770" },
{ code : "17071", intitule : "LA BROUSSE", cp : "17160" },
{ code : "17072", intitule : "BURIE", cp : "17770" },
{ code : "17073", intitule : "BUSSAC SUR CHARENTE", cp : "17100" },
{ code : "17074", intitule : "BUSSAC FORET", cp : "17210" },
{ code : "17075", intitule : "CABARIOT", cp : "17430" },
{ code : "17076", intitule : "CELLES", cp : "17520" },
{ code : "17077", intitule : "CERCOUX", cp : "17270" },
{ code : "17078", intitule : "CHADENAC", cp : "17800" },
{ code : "17079", intitule : "CHAILLEVETTE", cp : "17890" },
{ code : "17080", intitule : "CHAMBON", cp : "17290" },
{ code : "17081", intitule : "CHAMOUILLAC", cp : "17130" },
{ code : "17082", intitule : "CHAMPAGNAC", cp : "17500" },
{ code : "17083", intitule : "CHAMPAGNE", cp : "17620" },
{ code : "17084", intitule : "CHAMPAGNOLLES", cp : "17240" },
{ code : "17085", intitule : "CHAMPDOLENT", cp : "17430" },
{ code : "17086", intitule : "CHANIERS", cp : "17610" },
{ code : "17087", intitule : "CHANTEMERLE SUR LA SOIE", cp : "17380" },
{ code : "17089", intitule : "LA CHAPELLE DES POTS", cp : "17100" },
{ code : "17091", intitule : "CHARRON", cp : "17230" },
{ code : "17091", intitule : "CHARRON", cp : "17230" },
{ code : "17092", intitule : "CHARTUZAC", cp : "17130" },
{ code : "17093", intitule : "LE CHATEAU D OLERON", cp : "17480" },
{ code : "17093", intitule : "LE CHATEAU D OLERON", cp : "17480" },
{ code : "17093", intitule : "LE CHATEAU D OLERON", cp : "17480" },
{ code : "17093", intitule : "LE CHATEAU D OLERON", cp : "17480" },
{ code : "17094", intitule : "CHATELAILLON PLAGE", cp : "17340" },
{ code : "17095", intitule : "CHATENET", cp : "17210" },
{ code : "17096", intitule : "CHAUNAC", cp : "17130" },
{ code : "17097", intitule : "LE CHAY", cp : "17600" },
{ code : "17098", intitule : "CHENAC ST SEURIN D UZET", cp : "17120" },
{ code : "17099", intitule : "CHEPNIERS", cp : "17210" },
{ code : "17100", intitule : "CHERAC", cp : "17610" },
{ code : "17101", intitule : "CHERBONNIERES", cp : "17470" },
{ code : "17102", intitule : "CHERMIGNAC", cp : "17460" },
{ code : "17103", intitule : "CHERVETTES", cp : "17380" },
{ code : "17104", intitule : "CHEVANCEAUX", cp : "17210" },
{ code : "17105", intitule : "CHIVES", cp : "17510" },
{ code : "17106", intitule : "CIERZAC", cp : "17520" },
{ code : "17107", intitule : "CIRE D AUNIS", cp : "17290" },
{ code : "17108", intitule : "CLAM", cp : "17500" },
{ code : "17109", intitule : "CLAVETTE", cp : "17220" },
{ code : "17110", intitule : "CLERAC", cp : "17270" },
{ code : "17111", intitule : "CLION", cp : "17240" },
{ code : "17112", intitule : "LA CLISSE", cp : "17600" },
{ code : "17113", intitule : "LA CLOTTE", cp : "17360" },
{ code : "17114", intitule : "COIVERT", cp : "17330" },
{ code : "17115", intitule : "COLOMBIERS", cp : "17460" },
{ code : "17116", intitule : "CONSAC", cp : "17150" },
{ code : "17117", intitule : "CONTRE", cp : "17470" },
{ code : "17118", intitule : "CORIGNAC", cp : "17130" },
{ code : "17119", intitule : "CORME ECLUSE", cp : "17600" },
{ code : "17120", intitule : "CORME ROYAL", cp : "17600" },
{ code : "17121", intitule : "LA COUARDE SUR MER", cp : "17670" },
{ code : "17122", intitule : "COULONGES", cp : "17800" },
{ code : "17124", intitule : "COURANT", cp : "17330" },
{ code : "17125", intitule : "COURCELLES", cp : "17400" },
{ code : "17126", intitule : "COURCERAC", cp : "17160" },
{ code : "17127", intitule : "COURCON", cp : "17170" },
{ code : "17128", intitule : "COURCOURY", cp : "17100" },
{ code : "17129", intitule : "COURPIGNAC", cp : "17130" },
{ code : "17130", intitule : "COUX", cp : "17130" },
{ code : "17131", intitule : "COZES", cp : "17120" },
{ code : "17132", intitule : "CRAMCHABAN", cp : "17170" },
{ code : "17133", intitule : "CRAVANS", cp : "17260" },
{ code : "17134", intitule : "CRAZANNES", cp : "17350" },
{ code : "17135", intitule : "CRESSE", cp : "17160" },
{ code : "17136", intitule : "CROIX CHAPEAU", cp : "17220" },
{ code : "17137", intitule : "LA CROIX COMTESSE", cp : "17330" },
{ code : "17138", intitule : "DAMPIERRE SUR BOUTONNE", cp : "17470" },
{ code : "17139", intitule : "DOEUIL SUR LE MIGNON", cp : "17330" },
{ code : "17140", intitule : "DOLUS D OLERON", cp : "17550" },
{ code : "17141", intitule : "DOMPIERRE SUR CHARENTE", cp : "17610" },
{ code : "17142", intitule : "DOMPIERRE SUR MER", cp : "17139" },
{ code : "17142", intitule : "DOMPIERRE SUR MER", cp : "17139" },
{ code : "17143", intitule : "LE DOUHET", cp : "17100" },
{ code : "17145", intitule : "ECHEBRUNE", cp : "17800" },
{ code : "17146", intitule : "ECHILLAIS", cp : "17620" },
{ code : "17146", intitule : "ECHILLAIS", cp : "17620" },
{ code : "17147", intitule : "ECOYEUX", cp : "17770" },
{ code : "17148", intitule : "ECURAT", cp : "17810" },
{ code : "17149", intitule : "LES EDUTS", cp : "17510" },
{ code : "17150", intitule : "LES EGLISES D ARGENTEUIL", cp : "17400" },
{ code : "17151", intitule : "L EGUILLE", cp : "17600" },
{ code : "17152", intitule : "EPARGNES", cp : "17120" },
{ code : "17153", intitule : "ESNANDES", cp : "17137" },
{ code : "17154", intitule : "LES ESSARDS", cp : "17250" },
{ code : "17155", intitule : "ETAULES", cp : "17750" },
{ code : "17156", intitule : "EXPIREMONT", cp : "17130" },
{ code : "17157", intitule : "FENIOUX", cp : "17350" },
{ code : "17158", intitule : "FERRIERES", cp : "17170" },
{ code : "17159", intitule : "FLEAC SUR SEUGNE", cp : "17800" },
{ code : "17160", intitule : "FLOIRAC", cp : "17120" },
{ code : "17161", intitule : "LA FLOTTE", cp : "17630" },
{ code : "17162", intitule : "FONTAINE CHALENDRAY", cp : "17510" },
{ code : "17163", intitule : "FONTAINES D OZILLAC", cp : "17500" },
{ code : "17164", intitule : "FONTCOUVERTE", cp : "17100" },
{ code : "17165", intitule : "FONTENET", cp : "17400" },
{ code : "17166", intitule : "FORGES", cp : "17290" },
{ code : "17166", intitule : "FORGES", cp : "17290" },
{ code : "17167", intitule : "LE FOUILLOUX", cp : "17270" },
{ code : "17168", intitule : "FOURAS", cp : "17450" },
{ code : "17169", intitule : "LA FREDIERE", cp : "17770" },
{ code : "17171", intitule : "GEAY", cp : "17250" },
{ code : "17172", intitule : "GEMOZAC", cp : "17260" },
{ code : "17173", intitule : "LA GENETOUZE", cp : "17360" },
{ code : "17174", intitule : "GENOUILLE", cp : "17430" },
{ code : "17175", intitule : "GERMIGNAC", cp : "17520" },
{ code : "17176", intitule : "GIBOURNE", cp : "17160" },
{ code : "17177", intitule : "LE GICQ", cp : "17160" },
{ code : "17178", intitule : "GIVREZAC", cp : "17260" },
{ code : "17179", intitule : "LES GONDS", cp : "17100" },
{ code : "17180", intitule : "GOURVILLETTE", cp : "17490" },
{ code : "17181", intitule : "GRANDJEAN", cp : "17350" },
{ code : "17182", intitule : "LA GREVE SUR MIGNON", cp : "17170" },
{ code : "17183", intitule : "GREZAC", cp : "17120" },
{ code : "17184", intitule : "LA GRIPPERIE ST SYMPHORIEN", cp : "17620" },
{ code : "17185", intitule : "LE GUA", cp : "17600" },
{ code : "17186", intitule : "LE GUE D ALLERE", cp : "17540" },
{ code : "17187", intitule : "GUITINIERES", cp : "17500" },
{ code : "17188", intitule : "HAIMPS", cp : "17160" },
{ code : "17189", intitule : "HIERS BROUAGE", cp : "17320" },
{ code : "17189", intitule : "HIERS BROUAGE", cp : "17320" },
{ code : "17190", intitule : "L HOUMEAU", cp : "17137" },
{ code : "17191", intitule : "LA JARD", cp : "17460" },
{ code : "17192", intitule : "JARNAC CHAMPAGNE", cp : "17520" },
{ code : "17193", intitule : "LA JARNE", cp : "17220" },
{ code : "17194", intitule : "LA JARRIE", cp : "17220" },
{ code : "17195", intitule : "LA JARRIE AUDOUIN", cp : "17330" },
{ code : "17196", intitule : "JAZENNES", cp : "17260" },
{ code : "17197", intitule : "JONZAC", cp : "17500" },
{ code : "17198", intitule : "JUICQ", cp : "17770" },
{ code : "17199", intitule : "JUSSAS", cp : "17130" },
{ code : "17200", intitule : "LAGORD", cp : "17140" },
{ code : "17201", intitule : "LA LAIGNE", cp : "17170" },
{ code : "17202", intitule : "LANDES", cp : "17380" },
{ code : "17203", intitule : "LANDRAIS", cp : "17290" },
{ code : "17204", intitule : "LEOVILLE", cp : "17500" },
{ code : "17205", intitule : "LOIRE LES MARAIS", cp : "17870" },
{ code : "17206", intitule : "LOIRE SUR NIE", cp : "17470" },
{ code : "17207", intitule : "LOIX", cp : "17111" },
{ code : "17208", intitule : "LONGEVES", cp : "17230" },
{ code : "17209", intitule : "LONZAC", cp : "17520" },
{ code : "17210", intitule : "LORIGNAC", cp : "17240" },
{ code : "17211", intitule : "LOULAY", cp : "17330" },
{ code : "17212", intitule : "LOUZIGNAC", cp : "17160" },
{ code : "17213", intitule : "LOZAY", cp : "17330" },
{ code : "17214", intitule : "LUCHAT", cp : "17600" },
{ code : "17215", intitule : "LUSSAC", cp : "17500" },
{ code : "17216", intitule : "LUSSANT", cp : "17430" },
{ code : "17217", intitule : "MACQUEVILLE", cp : "17490" },
{ code : "17218", intitule : "MARANS", cp : "17230" },
{ code : "17219", intitule : "MARENNES", cp : "17320" },
{ code : "17220", intitule : "MARIGNAC", cp : "17800" },
{ code : "17221", intitule : "MARSAIS", cp : "17700" },
{ code : "17221", intitule : "MARSAIS", cp : "17700" },
{ code : "17222", intitule : "MARSILLY", cp : "17137" },
{ code : "17223", intitule : "MASSAC", cp : "17490" },
{ code : "17224", intitule : "MATHA", cp : "17160" },
{ code : "17225", intitule : "LES MATHES", cp : "17570" },
{ code : "17226", intitule : "MAZERAY", cp : "17400" },
{ code : "17227", intitule : "MAZEROLLES", cp : "17800" },
{ code : "17228", intitule : "MEDIS", cp : "17600" },
{ code : "17229", intitule : "MERIGNAC", cp : "17210" },
{ code : "17230", intitule : "MESCHERS SUR GIRONDE", cp : "17132" },
{ code : "17231", intitule : "MESSAC", cp : "17130" },
{ code : "17232", intitule : "MEURSAC", cp : "17120" },
{ code : "17233", intitule : "MEUX", cp : "17500" },
{ code : "17234", intitule : "MIGRE", cp : "17330" },
{ code : "17235", intitule : "MIGRON", cp : "17770" },
{ code : "17236", intitule : "MIRAMBEAU", cp : "17150" },
{ code : "17236", intitule : "MIRAMBEAU", cp : "17150" },
{ code : "17237", intitule : "MOEZE", cp : "17780" },
{ code : "17239", intitule : "MONS", cp : "17160" },
{ code : "17240", intitule : "MONTENDRE", cp : "17130" },
{ code : "17240", intitule : "MONTENDRE", cp : "17130" },
{ code : "17240", intitule : "MONTENDRE", cp : "17130" },
{ code : "17241", intitule : "MONTGUYON", cp : "17270" },
{ code : "17242", intitule : "MONTILS", cp : "17800" },
{ code : "17243", intitule : "MONTLIEU LA GARDE", cp : "17210" },
{ code : "17244", intitule : "MONTPELLIER DE MEDILLAN", cp : "17260" },
{ code : "17245", intitule : "MONTROY", cp : "17220" },
{ code : "17246", intitule : "MORAGNE", cp : "17430" },
{ code : "17247", intitule : "MORNAC SUR SEUDRE", cp : "17113" },
{ code : "17248", intitule : "MORTAGNE SUR GIRONDE", cp : "17120" },
{ code : "17249", intitule : "MORTIERS", cp : "17500" },
{ code : "17250", intitule : "MOSNAC", cp : "17240" },
{ code : "17252", intitule : "LE MUNG", cp : "17350" },
{ code : "17253", intitule : "MURON", cp : "17430" },
{ code : "17254", intitule : "NACHAMPS", cp : "17380" },
{ code : "17255", intitule : "NANCRAS", cp : "17600" },
{ code : "17256", intitule : "NANTILLE", cp : "17770" },
{ code : "17257", intitule : "NERE", cp : "17510" },
{ code : "17258", intitule : "NEUILLAC", cp : "17520" },
{ code : "17259", intitule : "NEULLES", cp : "17500" },
{ code : "17260", intitule : "NEUVICQ", cp : "17270" },
{ code : "17261", intitule : "NEUVICQ LE CHATEAU", cp : "17490" },
{ code : "17262", intitule : "NIEUL LES SAINTES", cp : "17810" },
{ code : "17263", intitule : "NIEUL LE VIROUIL", cp : "17150" },
{ code : "17264", intitule : "NIEUL SUR MER", cp : "17137" },
{ code : "17264", intitule : "NIEUL SUR MER", cp : "17137" },
{ code : "17265", intitule : "NIEULLE SUR SEUDRE", cp : "17600" },
{ code : "17266", intitule : "LES NOUILLERS", cp : "17380" },
{ code : "17267", intitule : "NUAILLE D AUNIS", cp : "17540" },
{ code : "17268", intitule : "NUAILLE SUR BOUTONNE", cp : "17470" },
{ code : "17269", intitule : "ORIGNOLLES", cp : "17210" },
{ code : "17270", intitule : "OZILLAC", cp : "17500" },
{ code : "17271", intitule : "PAILLE", cp : "17470" },
{ code : "17272", intitule : "PERE", cp : "17700" },
{ code : "17273", intitule : "PERIGNAC", cp : "17800" },
{ code : "17274", intitule : "PERIGNY", cp : "17180" },
{ code : "17275", intitule : "PESSINES", cp : "17810" },
{ code : "17276", intitule : "LE PIN", cp : "17210" },
{ code : "17277", intitule : "ESSOUVERT", cp : "17400" },
{ code : "17277", intitule : "ESSOUVERT", cp : "17400" },
{ code : "17278", intitule : "PISANY", cp : "17600" },
{ code : "17279", intitule : "PLASSAC", cp : "17240" },
{ code : "17280", intitule : "PLASSAY", cp : "17250" },
{ code : "17281", intitule : "POLIGNAC", cp : "17210" },
{ code : "17282", intitule : "POMMIERS MOULONS", cp : "17130" },
{ code : "17282", intitule : "POMMIERS MOULONS", cp : "17130" },
{ code : "17283", intitule : "PONS", cp : "17800" },
{ code : "17284", intitule : "PONT L ABBE D ARNOULT", cp : "17250" },
{ code : "17285", intitule : "PORT D ENVAUX", cp : "17350" },
{ code : "17286", intitule : "LES PORTES EN RE", cp : "17880" },
{ code : "17287", intitule : "POUILLAC", cp : "17210" },
{ code : "17288", intitule : "POURSAY GARNAUD", cp : "17400" },
{ code : "17289", intitule : "PREGUILLAC", cp : "17460" },
{ code : "17290", intitule : "PRIGNAC", cp : "17160" },
{ code : "17291", intitule : "PUILBOREAU", cp : "17138" },
{ code : "17292", intitule : "PUY DU LAC", cp : "17380" },
{ code : "17293", intitule : "PUYRAVAULT", cp : "17700" },
{ code : "17294", intitule : "PUYROLLAND", cp : "17380" },
{ code : "17295", intitule : "REAUX SUR TREFLE", cp : "17500" },
{ code : "17295", intitule : "REAUX SUR TREFLE", cp : "17500" },
{ code : "17295", intitule : "REAUX SUR TREFLE", cp : "17500" },
{ code : "17296", intitule : "RETAUD", cp : "17460" },
{ code : "17297", intitule : "RIVEDOUX PLAGE", cp : "17940" },
{ code : "17298", intitule : "RIOUX", cp : "17460" },
{ code : "17299", intitule : "ROCHEFORT", cp : "17300" },
{ code : "17300", intitule : "LA ROCHELLE", cp : "17000" },
{ code : "17300", intitule : "LA ROCHELLE", cp : "17000" },
{ code : "17300", intitule : "LA ROCHELLE", cp : "17000" },
{ code : "17301", intitule : "ROMAZIERES", cp : "17510" },
{ code : "17302", intitule : "ROMEGOUX", cp : "17250" },
{ code : "17303", intitule : "LA RONDE", cp : "17170" },
{ code : "17304", intitule : "ROUFFIAC", cp : "17800" },
{ code : "17305", intitule : "ROUFFIGNAC", cp : "17130" },
{ code : "17306", intitule : "ROYAN", cp : "17200" },
{ code : "17307", intitule : "SABLONCEAUX", cp : "17600" },
{ code : "17308", intitule : "ST AGNANT", cp : "17620" },
{ code : "17309", intitule : "ST AIGULIN", cp : "17360" },
{ code : "17310", intitule : "ST ANDRE DE LIDON", cp : "17260" },
{ code : "17311", intitule : "ST AUGUSTIN", cp : "17570" },
{ code : "17312", intitule : "ST BONNET SUR GIRONDE", cp : "17150" },
{ code : "17313", intitule : "ST BRIS DES BOIS", cp : "17770" },
{ code : "17314", intitule : "ST CESAIRE", cp : "17770" },
{ code : "17315", intitule : "ST CHRISTOPHE", cp : "17220" },
{ code : "17316", intitule : "ST CIERS CHAMPAGNE", cp : "17520" },
{ code : "17317", intitule : "ST CIERS DU TAILLON", cp : "17240" },
{ code : "17318", intitule : "ST CLEMENT DES BALEINES", cp : "17590" },
{ code : "17318", intitule : "ST CLEMENT DES BALEINES", cp : "17590" },
{ code : "17319", intitule : "STE COLOMBE", cp : "17210" },
{ code : "17320", intitule : "ST COUTANT LE GRAND", cp : "17430" },
{ code : "17321", intitule : "ST CREPIN", cp : "17380" },
{ code : "17322", intitule : "ST CYR DU DORET", cp : "17170" },
{ code : "17323", intitule : "ST DENIS D OLERON", cp : "17650" },
{ code : "17324", intitule : "ST DIZANT DU BOIS", cp : "17150" },
{ code : "17325", intitule : "ST DIZANT DU GUA", cp : "17240" },
{ code : "17326", intitule : "ST EUGENE", cp : "17520" },
{ code : "17327", intitule : "ST FELIX", cp : "17330" },
{ code : "17328", intitule : "ST FORT SUR GIRONDE", cp : "17240" },
{ code : "17329", intitule : "ST FROULT", cp : "17780" },
{ code : "17330", intitule : "STE GEMME", cp : "17250" },
{ code : "17331", intitule : "ST GENIS DE SAINTONGE", cp : "17240" },
{ code : "17332", intitule : "ST GEORGES ANTIGNAC", cp : "17240" },
{ code : "17332", intitule : "ST GEORGES ANTIGNAC", cp : "17240" },
{ code : "17333", intitule : "ST GEORGES DE DIDONNE", cp : "17110" },
{ code : "17334", intitule : "ST GEORGES DE LONGUEPIERRE", cp : "17470" },
{ code : "17335", intitule : "ST GEORGES DES AGOUTS", cp : "17150" },
{ code : "17336", intitule : "ST GEORGES DES COTEAUX", cp : "17810" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17337", intitule : "ST GEORGES D OLERON", cp : "17190" },
{ code : "17338", intitule : "ST GEORGES DU BOIS", cp : "17700" },
{ code : "17339", intitule : "ST GERMAIN DE LUSIGNAN", cp : "17500" },
{ code : "17340", intitule : "ST GERMAIN DE MARENCENNES", cp : "17700" },
{ code : "17341", intitule : "ST GERMAIN DE VIBRAC", cp : "17500" },
{ code : "17342", intitule : "ST GERMAIN DU SEUDRE", cp : "17240" },
{ code : "17343", intitule : "ST GREGOIRE D ARDENNES", cp : "17240" },
{ code : "17344", intitule : "ST HILAIRE DE VILLEFRANCHE", cp : "17770" },
{ code : "17345", intitule : "ST HILAIRE DU BOIS", cp : "17500" },
{ code : "17346", intitule : "ST HIPPOLYTE", cp : "17430" },
{ code : "17347", intitule : "ST JEAN D ANGELY", cp : "17400" },
{ code : "17348", intitule : "ST JEAN D ANGLE", cp : "17620" },
{ code : "17349", intitule : "ST JEAN DE LIVERSAY", cp : "17170" },
{ code : "17350", intitule : "ST JULIEN DE L ESCAP", cp : "17400" },
{ code : "17351", intitule : "ST JUST LUZAC", cp : "17320" },
{ code : "17351", intitule : "ST JUST LUZAC", cp : "17320" },
{ code : "17352", intitule : "ST LAURENT DE LA BARRIERE", cp : "17380" },
{ code : "17353", intitule : "ST LAURENT DE LA PREE", cp : "17450" },
{ code : "17354", intitule : "ST LEGER", cp : "17800" },
{ code : "17355", intitule : "STE LHEURINE", cp : "17520" },
{ code : "17356", intitule : "ST LOUP", cp : "17380" },
{ code : "17357", intitule : "ST MAIGRIN", cp : "17520" },
{ code : "17358", intitule : "ST MANDE SUR BREDOIRE", cp : "17470" },
{ code : "17359", intitule : "ST MARD", cp : "17700" },
{ code : "17360", intitule : "STE MARIE DE RE", cp : "17740" },
{ code : "17361", intitule : "ST MARTIAL", cp : "17330" },
{ code : "17362", intitule : "ST MARTIAL DE MIRAMBEAU", cp : "17150" },
{ code : "17363", intitule : "ST MARTIAL DE VITATERNE", cp : "17500" },
{ code : "17364", intitule : "ST MARTIAL SUR NE", cp : "17520" },
{ code : "17365", intitule : "ST MARTIN D ARY", cp : "17270" },
{ code : "17366", intitule : "ST MARTIN DE COUX", cp : "17360" },
{ code : "17367", intitule : "ST MARTIN DE JUILLERS", cp : "17400" },
{ code : "17369", intitule : "ST MARTIN DE RE", cp : "17410" },
{ code : "17372", intitule : "ST MEDARD", cp : "17500" },
{ code : "17373", intitule : "ST MEDARD D AUNIS", cp : "17220" },
{ code : "17374", intitule : "STE MEME", cp : "17770" },
{ code : "17375", intitule : "ST NAZAIRE SUR CHARENTE", cp : "17780" },
{ code : "17376", intitule : "ST OUEN D AUNIS", cp : "17230" },
{ code : "17377", intitule : "ST OUEN LA THENE", cp : "17490" },
{ code : "17378", intitule : "ST PALAIS DE NEGRIGNAC", cp : "17210" },
{ code : "17379", intitule : "ST PALAIS DE PHIOLIN", cp : "17800" },
{ code : "17380", intitule : "ST PALAIS SUR MER", cp : "17420" },
{ code : "17380", intitule : "ST PALAIS SUR MER", cp : "17420" },
{ code : "17381", intitule : "ST PARDOULT", cp : "17400" },
{ code : "17382", intitule : "ST PIERRE D AMILLY", cp : "17700" },
{ code : "17383", intitule : "ST PIERRE DE JUILLERS", cp : "17400" },
{ code : "17384", intitule : "ST PIERRE DE L ISLE", cp : "17330" },
{ code : "17385", intitule : "ST PIERRE D OLERON", cp : "17310" },
{ code : "17385", intitule : "ST PIERRE D OLERON", cp : "17310" },
{ code : "17386", intitule : "ST PIERRE DU PALAIS", cp : "17270" },
{ code : "17387", intitule : "ST PORCHAIRE", cp : "17250" },
{ code : "17388", intitule : "ST QUANTIN DE RANCANNE", cp : "17800" },
{ code : "17389", intitule : "STE RADEGONDE", cp : "17250" },
{ code : "17390", intitule : "STE RAMEE", cp : "17240" },
{ code : "17391", intitule : "ST ROGATIEN", cp : "17220" },
{ code : "17392", intitule : "ST ROMAIN SUR GIRONDE", cp : "17240" },
{ code : "17393", intitule : "ST ROMAIN DE BENET", cp : "17600" },
{ code : "17394", intitule : "ST SATURNIN DU BOIS", cp : "17700" },
{ code : "17395", intitule : "ST SAUVANT", cp : "17610" },
{ code : "17396", intitule : "ST SAUVEUR D AUNIS", cp : "17540" },
{ code : "17397", intitule : "ST SAVINIEN", cp : "17350" },
{ code : "17397", intitule : "ST SAVINIEN", cp : "17350" },
{ code : "17397", intitule : "ST SAVINIEN", cp : "17350" },
{ code : "17398", intitule : "ST SEURIN DE PALENNE", cp : "17800" },
{ code : "17400", intitule : "ST SEVER DE SAINTONGE", cp : "17800" },
{ code : "17401", intitule : "ST SEVERIN SUR BOUTONNE", cp : "17330" },
{ code : "17402", intitule : "ST SIGISMOND DE CLERMONT", cp : "17240" },
{ code : "17403", intitule : "ST SIMON DE BORDES", cp : "17500" },
{ code : "17404", intitule : "ST SIMON DE PELLOUAILLE", cp : "17260" },
{ code : "17405", intitule : "ST SORLIN DE CONAC", cp : "17150" },
{ code : "17406", intitule : "ST SORNIN", cp : "17600" },
{ code : "17407", intitule : "STE SOULLE", cp : "17220" },
{ code : "17407", intitule : "STE SOULLE", cp : "17220" },
{ code : "17408", intitule : "ST SULPICE D ARNOULT", cp : "17250" },
{ code : "17409", intitule : "ST SULPICE DE ROYAN", cp : "17200" },
{ code : "17410", intitule : "ST THOMAS DE CONAC", cp : "17150" },
{ code : "17411", intitule : "ST TROJAN LES BAINS", cp : "17370" },
{ code : "17412", intitule : "ST VAIZE", cp : "17100" },
{ code : "17413", intitule : "ST VIVIEN", cp : "17220" },
{ code : "17414", intitule : "ST XANDRE", cp : "17138" },
{ code : "17415", intitule : "SAINTES", cp : "17100" },
{ code : "17416", intitule : "SALEIGNES", cp : "17510" },
{ code : "17417", intitule : "SALIGNAC DE MIRAMBEAU", cp : "17130" },
{ code : "17418", intitule : "SALIGNAC SUR CHARENTE", cp : "17800" },
{ code : "17420", intitule : "SALLES SUR MER", cp : "17220" },
{ code : "17421", intitule : "SAUJON", cp : "17600" },
{ code : "17422", intitule : "SEIGNE", cp : "17510" },
{ code : "17423", intitule : "SEMILLAC", cp : "17150" },
{ code : "17424", intitule : "SEMOUSSAC", cp : "17150" },
{ code : "17425", intitule : "SEMUSSAC", cp : "17120" },
{ code : "17426", intitule : "LE SEURE", cp : "17770" },
{ code : "17427", intitule : "SIECQ", cp : "17490" },
{ code : "17428", intitule : "SONNAC", cp : "17160" },
{ code : "17429", intitule : "SOUBISE", cp : "17780" },
{ code : "17430", intitule : "SOUBRAN", cp : "17150" },
{ code : "17431", intitule : "SOULIGNONNE", cp : "17250" },
{ code : "17432", intitule : "SOUMERAS", cp : "17130" },
{ code : "17433", intitule : "SOUSMOULINS", cp : "17130" },
{ code : "17434", intitule : "SURGERES", cp : "17700" },
{ code : "17435", intitule : "TAILLANT", cp : "17350" },
{ code : "17436", intitule : "TAILLEBOURG", cp : "17350" },
{ code : "17437", intitule : "TALMONT SUR GIRONDE", cp : "17120" },
{ code : "17438", intitule : "TANZAC", cp : "17260" },
{ code : "17439", intitule : "TAUGON", cp : "17170" },
{ code : "17440", intitule : "TERNANT", cp : "17400" },
{ code : "17441", intitule : "TESSON", cp : "17460" },
{ code : "17442", intitule : "THAIMS", cp : "17120" },
{ code : "17443", intitule : "THAIRE", cp : "17290" },
{ code : "17444", intitule : "THENAC", cp : "17460" },
{ code : "17445", intitule : "THEZAC", cp : "17600" },
{ code : "17446", intitule : "THORS", cp : "17160" },
{ code : "17447", intitule : "LE THOU", cp : "17290" },
{ code : "17448", intitule : "TONNAY BOUTONNE", cp : "17380" },
{ code : "17449", intitule : "TONNAY CHARENTE", cp : "17430" },
{ code : "17450", intitule : "TORXE", cp : "17380" },
{ code : "17451", intitule : "LES TOUCHES DE PERIGNY", cp : "17160" },
{ code : "17452", intitule : "LA TREMBLADE", cp : "17390" },
{ code : "17452", intitule : "LA TREMBLADE", cp : "17390" },
{ code : "17453", intitule : "TRIZAY", cp : "17250" },
{ code : "17454", intitule : "TUGERAS ST MAURICE", cp : "17130" },
{ code : "17454", intitule : "TUGERAS ST MAURICE", cp : "17130" },
{ code : "17455", intitule : "LA VALLEE", cp : "17250" },
{ code : "17457", intitule : "VANDRE", cp : "17700" },
{ code : "17458", intitule : "VANZAC", cp : "17500" },
{ code : "17459", intitule : "VARAIZE", cp : "17400" },
{ code : "17460", intitule : "VARZAY", cp : "17460" },
{ code : "17461", intitule : "VAUX SUR MER", cp : "17640" },
{ code : "17462", intitule : "VENERAND", cp : "17100" },
{ code : "17463", intitule : "VERGEROUX", cp : "17300" },
{ code : "17464", intitule : "VERGNE", cp : "17330" },
{ code : "17465", intitule : "LA VERGNE", cp : "17400" },
{ code : "17466", intitule : "VERINES", cp : "17540" },
{ code : "17467", intitule : "VERVANT", cp : "17400" },
{ code : "17468", intitule : "VIBRAC", cp : "17130" },
{ code : "17469", intitule : "VILLARS EN PONS", cp : "17260" },
{ code : "17470", intitule : "VILLARS LES BOIS", cp : "17770" },
{ code : "17471", intitule : "LA VILLEDIEU", cp : "17470" },
{ code : "17472", intitule : "VILLEDOUX", cp : "17230" },
{ code : "17473", intitule : "VILLEMORIN", cp : "17470" },
{ code : "17474", intitule : "VILLENEUVE LA COMTESSE", cp : "17330" },
{ code : "17474", intitule : "VILLENEUVE LA COMTESSE", cp : "17330" },
{ code : "17476", intitule : "VILLEXAVIER", cp : "17500" },
{ code : "17477", intitule : "VILLIERS COUTURE", cp : "17510" },
{ code : "17478", intitule : "VINAX", cp : "17510" },
{ code : "17479", intitule : "VIROLLET", cp : "17260" },
{ code : "17480", intitule : "VIRSON", cp : "17290" },
{ code : "17481", intitule : "VOISSAY", cp : "17400" },
{ code : "17482", intitule : "VOUHE", cp : "17700" },
{ code : "17483", intitule : "YVES", cp : "17340" },
{ code : "17483", intitule : "YVES", cp : "17340" },
{ code : "17484", intitule : "PORT DES BARQUES", cp : "17730" },
{ code : "17485", intitule : "LE GRAND VILLAGE PLAGE", cp : "17370" },
{ code : "17486", intitule : "LA BREE LES BAINS", cp : "17840" },
{ code : "18001", intitule : "ACHERES", cp : "18250" },
{ code : "18002", intitule : "AINAY LE VIEIL", cp : "18200" },
{ code : "18003", intitule : "LES AIX D ANGILLON", cp : "18220" },
{ code : "18004", intitule : "ALLOGNY", cp : "18110" },
{ code : "18005", intitule : "ALLOUIS", cp : "18500" },
{ code : "18006", intitule : "ANNOIX", cp : "18340" },
{ code : "18007", intitule : "APREMONT SUR ALLIER", cp : "18150" },
{ code : "18008", intitule : "ARCAY", cp : "18340" },
{ code : "18009", intitule : "ARCOMPS", cp : "18200" },
{ code : "18009", intitule : "ARCOMPS", cp : "18200" },
{ code : "18010", intitule : "ARDENAIS", cp : "18170" },
{ code : "18011", intitule : "ARGENT SUR SAULDRE", cp : "18410" },
{ code : "18012", intitule : "ARGENVIERES", cp : "18140" },
{ code : "18013", intitule : "ARPHEUILLES", cp : "18200" },
{ code : "18014", intitule : "ASSIGNY", cp : "18260" },
{ code : "18015", intitule : "AUBIGNY SUR NERE", cp : "18700" },
{ code : "18016", intitule : "AUBINGES", cp : "18220" },
{ code : "18017", intitule : "AUGY SUR AUBOIS", cp : "18600" },
{ code : "18018", intitule : "AVORD", cp : "18520" },
{ code : "18019", intitule : "AZY", cp : "18220" },
{ code : "18020", intitule : "BANNAY", cp : "18300" },
{ code : "18021", intitule : "BANNEGON", cp : "18210" },
{ code : "18022", intitule : "BARLIEU", cp : "18260" },
{ code : "18023", intitule : "BAUGY", cp : "18800" },
{ code : "18024", intitule : "BEDDES", cp : "18370" },
{ code : "18025", intitule : "BEFFES", cp : "18320" },
{ code : "18026", intitule : "BELLEVILLE SUR LOIRE", cp : "18240" },
{ code : "18027", intitule : "BENGY SUR CRAON", cp : "18520" },
{ code : "18028", intitule : "BERRY BOUY", cp : "18500" },
{ code : "18029", intitule : "BESSAIS LE FROMENTAL", cp : "18210" },
{ code : "18030", intitule : "BLANCAFORT", cp : "18410" },
{ code : "18031", intitule : "BLET", cp : "18350" },
{ code : "18032", intitule : "BOULLERET", cp : "18240" },
{ code : "18033", intitule : "BOURGES", cp : "18000" },
{ code : "18034", intitule : "BOUZAIS", cp : "18200" },
{ code : "18035", intitule : "BRECY", cp : "18220" },
{ code : "18036", intitule : "BRINAY", cp : "18120" },
{ code : "18037", intitule : "BRINON SUR SAULDRE", cp : "18410" },
{ code : "18038", intitule : "BRUERE ALLICHAMPS", cp : "18200" },
{ code : "18039", intitule : "BUE", cp : "18300" },
{ code : "18040", intitule : "BUSSY", cp : "18130" },
{ code : "18041", intitule : "LA CELETTE", cp : "18360" },
{ code : "18042", intitule : "LA CELLE", cp : "18200" },
{ code : "18043", intitule : "LA CELLE CONDE", cp : "18160" },
{ code : "18044", intitule : "CERBOIS", cp : "18120" },
{ code : "18045", intitule : "CHALIVOY MILON", cp : "18130" },
{ code : "18046", intitule : "CHAMBON", cp : "18190" },
{ code : "18047", intitule : "LA CHAPELLE D ANGILLON", cp : "18380" },
{ code : "18048", intitule : "LA CHAPELLE HUGON", cp : "18150" },
{ code : "18049", intitule : "LA CHAPELLE MONTLINARD", cp : "18140" },
{ code : "18050", intitule : "LA CHAPELLE ST URSIN", cp : "18570" },
{ code : "18051", intitule : "LA CHAPELOTTE", cp : "18250" },
{ code : "18052", intitule : "CHARENTON DU CHER", cp : "18210" },
{ code : "18053", intitule : "CHARENTONNAY", cp : "18140" },
{ code : "18054", intitule : "CHARLY", cp : "18350" },
{ code : "18055", intitule : "CHAROST", cp : "18290" },
{ code : "18056", intitule : "CHASSY", cp : "18800" },
{ code : "18056", intitule : "CHASSY", cp : "18800" },
{ code : "18057", intitule : "CHATEAUMEILLANT", cp : "18370" },
{ code : "18058", intitule : "CHATEAUNEUF SUR CHER", cp : "18190" },
{ code : "18059", intitule : "LE CHATELET", cp : "18170" },
{ code : "18060", intitule : "CHAUMONT", cp : "18350" },
{ code : "18061", intitule : "CHAUMOUX MARCILLY", cp : "18140" },
{ code : "18062", intitule : "LE CHAUTAY", cp : "18150" },
{ code : "18063", intitule : "CHAVANNES", cp : "18190" },
{ code : "18064", intitule : "CHERY", cp : "18120" },
{ code : "18065", intitule : "CHEZAL BENOIT", cp : "18160" },
{ code : "18066", intitule : "CIVRAY", cp : "18290" },
{ code : "18067", intitule : "CLEMONT", cp : "18410" },
{ code : "18068", intitule : "COGNY", cp : "18130" },
{ code : "18069", intitule : "COLOMBIERS", cp : "18200" },
{ code : "18070", intitule : "CONCRESSAULT", cp : "18260" },
{ code : "18071", intitule : "CONTRES", cp : "18130" },
{ code : "18072", intitule : "CORNUSSE", cp : "18350" },
{ code : "18073", intitule : "CORQUOY", cp : "18190" },
{ code : "18074", intitule : "COUARGUES", cp : "18300" },
{ code : "18075", intitule : "COURS LES BARRES", cp : "18320" },
{ code : "18076", intitule : "COUST", cp : "18210" },
{ code : "18077", intitule : "COUY", cp : "18140" },
{ code : "18078", intitule : "CREZANCAY SUR CHER", cp : "18190" },
{ code : "18079", intitule : "CREZANCY EN SANCERRE", cp : "18300" },
{ code : "18080", intitule : "CROISY", cp : "18350" },
{ code : "18081", intitule : "CROSSES", cp : "18340" },
{ code : "18082", intitule : "CUFFY", cp : "18150" },
{ code : "18083", intitule : "CULAN", cp : "18270" },
{ code : "18084", intitule : "DAMPIERRE EN CROT", cp : "18260" },
{ code : "18085", intitule : "DAMPIERRE EN GRACAY", cp : "18310" },
{ code : "18086", intitule : "DREVANT", cp : "18200" },
{ code : "18087", intitule : "DUN SUR AURON", cp : "18130" },
{ code : "18088", intitule : "ENNORDRES", cp : "18380" },
{ code : "18089", intitule : "EPINEUIL LE FLEURIEL", cp : "18360" },
{ code : "18090", intitule : "ETRECHY", cp : "18800" },
{ code : "18091", intitule : "FARGES ALLICHAMPS", cp : "18200" },
{ code : "18092", intitule : "FARGES EN SEPTAINE", cp : "18800" },
{ code : "18093", intitule : "FAVERDINES", cp : "18360" },
{ code : "18094", intitule : "FEUX", cp : "18300" },
{ code : "18095", intitule : "FLAVIGNY", cp : "18350" },
{ code : "18096", intitule : "FOECY", cp : "18500" },
{ code : "18097", intitule : "FUSSY", cp : "18110" },
{ code : "18098", intitule : "GARDEFORT", cp : "18300" },
{ code : "18099", intitule : "GARIGNY", cp : "18140" },
{ code : "18100", intitule : "GENOUILLY", cp : "18310" },
{ code : "18101", intitule : "GERMIGNY L EXEMPT", cp : "18150" },
{ code : "18102", intitule : "GIVARDON", cp : "18600" },
{ code : "18103", intitule : "GRACAY", cp : "18310" },
{ code : "18104", intitule : "GROISES", cp : "18140" },
{ code : "18105", intitule : "GRON", cp : "18800" },
{ code : "18106", intitule : "GROSSOUVRE", cp : "18600" },
{ code : "18107", intitule : "LA GROUTTE", cp : "18200" },
{ code : "18108", intitule : "LA GUERCHE SUR L AUBOIS", cp : "18150" },
{ code : "18109", intitule : "HENRICHEMONT", cp : "18250" },
{ code : "18110", intitule : "HERRY", cp : "18140" },
{ code : "18111", intitule : "HUMBLIGNY", cp : "18250" },
{ code : "18112", intitule : "IDS ST ROCH", cp : "18170" },
{ code : "18113", intitule : "IGNOL", cp : "18350" },
{ code : "18114", intitule : "INEUIL", cp : "18160" },
{ code : "18115", intitule : "IVOY LE PRE", cp : "18380" },
{ code : "18116", intitule : "JALOGNES", cp : "18300" },
{ code : "18117", intitule : "JARS", cp : "18260" },
{ code : "18118", intitule : "JOUET SUR L AUBOIS", cp : "18320" },
{ code : "18119", intitule : "JUSSY CHAMPAGNE", cp : "18130" },
{ code : "18120", intitule : "JUSSY LE CHAUDRIER", cp : "18140" },
{ code : "18121", intitule : "LANTAN", cp : "18130" },
{ code : "18122", intitule : "LAPAN", cp : "18340" },
{ code : "18123", intitule : "LAVERDINES", cp : "18800" },
{ code : "18124", intitule : "LAZENAY", cp : "18120" },
{ code : "18125", intitule : "LERE", cp : "18240" },
{ code : "18126", intitule : "LEVET", cp : "18340" },
{ code : "18127", intitule : "LIGNIERES", cp : "18160" },
{ code : "18128", intitule : "LIMEUX", cp : "18120" },
{ code : "18129", intitule : "LISSAY LOCHY", cp : "18340" },
{ code : "18130", intitule : "LOYE SUR ARNON", cp : "18170" },
{ code : "18131", intitule : "LUGNY BOURBONNAIS", cp : "18350" },
{ code : "18132", intitule : "LUGNY CHAMPAGNE", cp : "18140" },
{ code : "18133", intitule : "LUNERY", cp : "18400" },
{ code : "18134", intitule : "LURY SUR ARNON", cp : "18120" },
{ code : "18135", intitule : "MAISONNAIS", cp : "18170" },
{ code : "18136", intitule : "MARCAIS", cp : "18170" },
{ code : "18137", intitule : "MAREUIL SUR ARNON", cp : "18290" },
{ code : "18138", intitule : "MARMAGNE", cp : "18500" },
{ code : "18139", intitule : "MARSEILLES LES AUBIGNY", cp : "18320" },
{ code : "18140", intitule : "MASSAY", cp : "18120" },
{ code : "18141", intitule : "MEHUN SUR YEVRE", cp : "18500" },
{ code : "18142", intitule : "MEILLANT", cp : "18200" },
{ code : "18143", intitule : "MENETOU COUTURE", cp : "18320" },
{ code : "18144", intitule : "MENETOU RATEL", cp : "18300" },
{ code : "18145", intitule : "MENETOU SALON", cp : "18510" },
{ code : "18146", intitule : "MENETREOL SOUS SANCERRE", cp : "18300" },
{ code : "18147", intitule : "MENETREOL SUR SAULDRE", cp : "18700" },
{ code : "18148", intitule : "MEREAU", cp : "18120" },
{ code : "18149", intitule : "MERY ES BOIS", cp : "18380" },
{ code : "18150", intitule : "MERY SUR CHER", cp : "18100" },
{ code : "18151", intitule : "MONTIGNY", cp : "18250" },
{ code : "18152", intitule : "MONTLOUIS", cp : "18160" },
{ code : "18153", intitule : "MORLAC", cp : "18170" },
{ code : "18154", intitule : "MORNAY BERRY", cp : "18350" },
{ code : "18155", intitule : "MORNAY SUR ALLIER", cp : "18600" },
{ code : "18156", intitule : "MOROGUES", cp : "18220" },
{ code : "18157", intitule : "MORTHOMIERS", cp : "18570" },
{ code : "18158", intitule : "MOULINS SUR YEVRE", cp : "18390" },
{ code : "18159", intitule : "NANCAY", cp : "18330" },
{ code : "18160", intitule : "NERONDES", cp : "18350" },
{ code : "18161", intitule : "NEUILLY EN DUN", cp : "18600" },
{ code : "18162", intitule : "NEUILLY EN SANCERRE", cp : "18250" },
{ code : "18163", intitule : "NEUVY DEUX CLOCHERS", cp : "18250" },
{ code : "18164", intitule : "NEUVY LE BARROIS", cp : "18600" },
{ code : "18165", intitule : "NEUVY SUR BARANGEON", cp : "18330" },
{ code : "18166", intitule : "NOHANT EN GOUT", cp : "18390" },
{ code : "18167", intitule : "NOHANT EN GRACAY", cp : "18310" },
{ code : "18168", intitule : "LE NOYER", cp : "18260" },
{ code : "18169", intitule : "NOZIERES", cp : "18200" },
{ code : "18170", intitule : "OIZON", cp : "18700" },
{ code : "18171", intitule : "ORCENAIS", cp : "18200" },
{ code : "18172", intitule : "ORVAL", cp : "18200" },
{ code : "18173", intitule : "OSMERY", cp : "18130" },
{ code : "18174", intitule : "OSMOY", cp : "18390" },
{ code : "18175", intitule : "OUROUER LES BOURDELINS", cp : "18350" },
{ code : "18176", intitule : "PARASSY", cp : "18220" },
{ code : "18177", intitule : "PARNAY", cp : "18130" },
{ code : "18178", intitule : "LA PERCHE", cp : "18200" },
{ code : "18179", intitule : "PIGNY", cp : "18110" },
{ code : "18180", intitule : "PLAIMPIED GIVAUDINS", cp : "18340" },
{ code : "18181", intitule : "PLOU", cp : "18290" },
{ code : "18182", intitule : "POISIEUX", cp : "18290" },
{ code : "18183", intitule : "LE PONDY", cp : "18210" },
{ code : "18184", intitule : "PRECY", cp : "18140" },
{ code : "18185", intitule : "PRESLY", cp : "18380" },
{ code : "18186", intitule : "PREUILLY", cp : "18120" },
{ code : "18187", intitule : "PREVERANGES", cp : "18370" },
{ code : "18188", intitule : "PRIMELLES", cp : "18400" },
{ code : "18189", intitule : "QUANTILLY", cp : "18110" },
{ code : "18190", intitule : "QUINCY", cp : "18120" },
{ code : "18191", intitule : "RAYMOND", cp : "18130" },
{ code : "18192", intitule : "REIGNY", cp : "18270" },
{ code : "18193", intitule : "REZAY", cp : "18170" },
{ code : "18194", intitule : "RIANS", cp : "18220" },
{ code : "18195", intitule : "SAGONNE", cp : "18600" },
{ code : "18196", intitule : "ST AIGNAN DES NOYERS", cp : "18600" },
{ code : "18197", intitule : "ST AMAND MONTROND", cp : "18200" },
{ code : "18198", intitule : "ST AMBROIX", cp : "18290" },
{ code : "18199", intitule : "ST BAUDEL", cp : "18160" },
{ code : "18200", intitule : "ST BOUIZE", cp : "18300" },
{ code : "18201", intitule : "ST CAPRAIS", cp : "18400" },
{ code : "18202", intitule : "ST CEOLS", cp : "18220" },
{ code : "18203", intitule : "ST CHRISTOPHE LE CHAUDRY", cp : "18270" },
{ code : "18204", intitule : "ST DENIS DE PALIN", cp : "18130" },
{ code : "18205", intitule : "ST DOULCHARD", cp : "18230" },
{ code : "18206", intitule : "ST ELOY DE GY", cp : "18110" },
{ code : "18207", intitule : "ST FLORENT SUR CHER", cp : "18400" },
{ code : "18208", intitule : "STE GEMME EN SANCERROIS", cp : "18240" },
{ code : "18209", intitule : "ST GEORGES DE POISIEUX", cp : "18200" },
{ code : "18210", intitule : "ST GEORGES SUR LA PREE", cp : "18100" },
{ code : "18211", intitule : "ST GEORGES SUR MOULON", cp : "18110" },
{ code : "18212", intitule : "ST GERMAIN DES BOIS", cp : "18340" },
{ code : "18213", intitule : "ST GERMAIN DU PUY", cp : "18390" },
{ code : "18214", intitule : "ST HILAIRE DE COURT", cp : "18100" },
{ code : "18215", intitule : "ST HILAIRE DE GONDILLY", cp : "18320" },
{ code : "18215", intitule : "ST HILAIRE DE GONDILLY", cp : "18350" },
{ code : "18216", intitule : "ST HILAIRE EN LIGNIERES", cp : "18160" },
{ code : "18217", intitule : "ST JEANVRIN", cp : "18370" },
{ code : "18218", intitule : "ST JUST", cp : "18340" },
{ code : "18219", intitule : "ST LAURENT", cp : "18330" },
{ code : "18220", intitule : "ST LEGER LE PETIT", cp : "18140" },
{ code : "18221", intitule : "ST LOUP DES CHAUMES", cp : "18190" },
{ code : "18222", intitule : "STE LUNAISE", cp : "18340" },
{ code : "18223", intitule : "ST MARTIN D AUXIGNY", cp : "18110" },
{ code : "18224", intitule : "ST MARTIN DES CHAMPS", cp : "18140" },
{ code : "18225", intitule : "ST MAUR", cp : "18270" },
{ code : "18226", intitule : "ST MICHEL DE VOLANGIS", cp : "18390" },
{ code : "18227", intitule : "STE MONTAINE", cp : "18700" },
{ code : "18228", intitule : "ST OUTRILLE", cp : "18310" },
{ code : "18229", intitule : "ST PALAIS", cp : "18110" },
{ code : "18230", intitule : "ST PIERRE LES BOIS", cp : "18170" },
{ code : "18231", intitule : "ST PIERRE LES ETIEUX", cp : "18210" },
{ code : "18232", intitule : "ST PRIEST LA MARCHE", cp : "18370" },
{ code : "18233", intitule : "ST SATUR", cp : "18300" },
{ code : "18234", intitule : "ST SATURNIN", cp : "18370" },
{ code : "18235", intitule : "STE SOLANGE", cp : "18220" },
{ code : "18236", intitule : "ST SYMPHORIEN", cp : "18190" },
{ code : "18237", intitule : "STE THORETTE", cp : "18500" },
{ code : "18238", intitule : "ST VITTE", cp : "18360" },
{ code : "18239", intitule : "SALIGNY LE VIF", cp : "18800" },
{ code : "18240", intitule : "SANCERGUES", cp : "18140" },
{ code : "18241", intitule : "SANCERRE", cp : "18300" },
{ code : "18242", intitule : "SANCOINS", cp : "18600" },
{ code : "18243", intitule : "SANTRANGES", cp : "18240" },
{ code : "18244", intitule : "SAUGY", cp : "18290" },
{ code : "18245", intitule : "SAULZAIS LE POTIER", cp : "18360" },
{ code : "18246", intitule : "SAVIGNY EN SANCERRE", cp : "18240" },
{ code : "18247", intitule : "SAVIGNY EN SEPTAINE", cp : "18390" },
{ code : "18248", intitule : "SENNECAY", cp : "18340" },
{ code : "18249", intitule : "SENS BEAUJEU", cp : "18300" },
{ code : "18250", intitule : "SERRUELLES", cp : "18190" },
{ code : "18251", intitule : "SEVRY", cp : "18140" },
{ code : "18252", intitule : "SIDIAILLES", cp : "18270" },
{ code : "18253", intitule : "SOULANGIS", cp : "18220" },
{ code : "18254", intitule : "SOYE EN SEPTAINE", cp : "18340" },
{ code : "18255", intitule : "LE SUBDRAY", cp : "18570" },
{ code : "18256", intitule : "SUBLIGNY", cp : "18260" },
{ code : "18257", intitule : "SURY PRES LERE", cp : "18240" },
{ code : "18258", intitule : "SURY EN VAUX", cp : "18300" },
{ code : "18259", intitule : "SURY ES BOIS", cp : "18260" },
{ code : "18260", intitule : "TENDRON", cp : "18350" },
{ code : "18261", intitule : "THAUMIERS", cp : "18210" },
{ code : "18262", intitule : "THAUVENAY", cp : "18300" },
{ code : "18263", intitule : "THENIOUX", cp : "18100" },
{ code : "18264", intitule : "THOU", cp : "18260" },
{ code : "18265", intitule : "TORTERON", cp : "18320" },
{ code : "18266", intitule : "TOUCHAY", cp : "18160" },
{ code : "18267", intitule : "TROUY", cp : "18570" },
{ code : "18268", intitule : "UZAY LE VENON", cp : "18190" },
{ code : "18269", intitule : "VAILLY SUR SAULDRE", cp : "18260" },
{ code : "18270", intitule : "VALLENAY", cp : "18190" },
{ code : "18270", intitule : "VALLENAY", cp : "18190" },
{ code : "18271", intitule : "VASSELAY", cp : "18110" },
{ code : "18272", intitule : "VEAUGUES", cp : "18300" },
{ code : "18273", intitule : "VENESMES", cp : "18190" },
{ code : "18274", intitule : "VERDIGNY", cp : "18300" },
{ code : "18275", intitule : "VEREAUX", cp : "18600" },
{ code : "18276", intitule : "VERNAIS", cp : "18210" },
{ code : "18277", intitule : "VERNEUIL", cp : "18210" },
{ code : "18278", intitule : "VESDUN", cp : "18360" },
{ code : "18279", intitule : "VIERZON", cp : "18100" },
{ code : "18280", intitule : "VIGNOUX SOUS LES AIX", cp : "18110" },
{ code : "18281", intitule : "VIGNOUX SUR BARANGEON", cp : "18500" },
{ code : "18282", intitule : "VILLABON", cp : "18800" },
{ code : "18283", intitule : "VILLECELIN", cp : "18160" },
{ code : "18284", intitule : "VILLEGENON", cp : "18260" },
{ code : "18285", intitule : "VILLENEUVE SUR CHER", cp : "18400" },
{ code : "18286", intitule : "VILLEQUIERS", cp : "18800" },
{ code : "18287", intitule : "VINON", cp : "18300" },
{ code : "18288", intitule : "VORLY", cp : "18340" },
{ code : "18289", intitule : "VORNAY", cp : "18130" },
{ code : "18290", intitule : "VOUZERON", cp : "18330" },
{ code : "19001", intitule : "AFFIEUX", cp : "19260" },
{ code : "19002", intitule : "AIX", cp : "19200" },
{ code : "19003", intitule : "ALBIGNAC", cp : "19190" },
{ code : "19004", intitule : "ALBUSSAC", cp : "19380" },
{ code : "19005", intitule : "ALLASSAC", cp : "19240" },
{ code : "19006", intitule : "ALLEYRAT", cp : "19200" },
{ code : "19007", intitule : "ALTILLAC", cp : "19120" },
{ code : "19008", intitule : "AMBRUGEAT", cp : "19250" },
{ code : "19009", intitule : "LES ANGLES SUR CORREZE", cp : "19000" },
{ code : "19010", intitule : "ARGENTAT", cp : "19400" },
{ code : "19011", intitule : "ARNAC POMPADOUR", cp : "19230" },
{ code : "19012", intitule : "ASTAILLAC", cp : "19120" },
{ code : "19013", intitule : "AUBAZINES", cp : "19190" },
{ code : "19014", intitule : "AURIAC", cp : "19220" },
{ code : "19015", intitule : "AYEN", cp : "19310" },
{ code : "19016", intitule : "BAR", cp : "19800" },
{ code : "19017", intitule : "BASSIGNAC LE BAS", cp : "19430" },
{ code : "19018", intitule : "BASSIGNAC LE HAUT", cp : "19220" },
{ code : "19019", intitule : "BEAULIEU SUR DORDOGNE", cp : "19120" },
{ code : "19020", intitule : "BEAUMONT", cp : "19390" },
{ code : "19021", intitule : "BELLECHASSAGNE", cp : "19290" },
{ code : "19022", intitule : "BENAYES", cp : "19510" },
{ code : "19023", intitule : "BEYNAT", cp : "19190" },
{ code : "19024", intitule : "BEYSSAC", cp : "19230" },
{ code : "19025", intitule : "BEYSSENAC", cp : "19230" },
{ code : "19026", intitule : "BILHAC", cp : "19120" },
{ code : "19027", intitule : "BONNEFOND", cp : "19170" },
{ code : "19028", intitule : "BORT LES ORGUES", cp : "19110" },
{ code : "19029", intitule : "BRANCEILLES", cp : "19500" },
{ code : "19030", intitule : "BRIGNAC LA PLAINE", cp : "19310" },
{ code : "19031", intitule : "BRIVE LA GAILLARDE", cp : "19100" },
{ code : "19032", intitule : "BRIVEZAC", cp : "19120" },
{ code : "19033", intitule : "BUGEAT", cp : "19170" },
{ code : "19034", intitule : "CAMPS ST MATHURIN LEOBAZEL", cp : "19430" },
{ code : "19034", intitule : "CAMPS ST MATHURIN LEOBAZEL", cp : "19430" },
{ code : "19035", intitule : "CHABRIGNAC", cp : "19350" },
{ code : "19036", intitule : "CHAMBERET", cp : "19370" },
{ code : "19037", intitule : "CHAMBOULIVE", cp : "19450" },
{ code : "19038", intitule : "CHAMEYRAT", cp : "19330" },
{ code : "19039", intitule : "CHAMPAGNAC LA NOAILLE", cp : "19320" },
{ code : "19040", intitule : "CHAMPAGNAC LA PRUNE", cp : "19320" },
{ code : "19041", intitule : "CHANAC LES MINES", cp : "19150" },
{ code : "19042", intitule : "CHANTEIX", cp : "19330" },
{ code : "19043", intitule : "LA CHAPELLE AUX BROCS", cp : "19360" },
{ code : "19044", intitule : "LA CHAPELLE AUX SAINTS", cp : "19120" },
{ code : "19045", intitule : "LA CHAPELLE ST GERAUD", cp : "19430" },
{ code : "19046", intitule : "CHAPELLE SPINASSE", cp : "19300" },
{ code : "19047", intitule : "CHARTRIER FERRIERE", cp : "19600" },
{ code : "19048", intitule : "LE CHASTANG", cp : "19190" },
{ code : "19049", intitule : "CHASTEAUX", cp : "19600" },
{ code : "19050", intitule : "CHAUFFOUR SUR VELL", cp : "19500" },
{ code : "19051", intitule : "CHAUMEIL", cp : "19390" },
{ code : "19052", intitule : "CHAVANAC", cp : "19290" },
{ code : "19053", intitule : "CHAVEROCHE", cp : "19200" },
{ code : "19054", intitule : "CHENAILLER MASCHEIX", cp : "19120" },
{ code : "19055", intitule : "CHIRAC BELLEVUE", cp : "19160" },
{ code : "19056", intitule : "CLERGOUX", cp : "19320" },
{ code : "19057", intitule : "COLLONGES LA ROUGE", cp : "19500" },
{ code : "19058", intitule : "COMBRESSOL", cp : "19250" },
{ code : "19059", intitule : "CONCEZE", cp : "19350" },
{ code : "19060", intitule : "CONDAT SUR GANAVEIX", cp : "19140" },
{ code : "19061", intitule : "CORNIL", cp : "19150" },
{ code : "19062", intitule : "CORREZE", cp : "19800" },
{ code : "19063", intitule : "COSNAC", cp : "19360" },
{ code : "19064", intitule : "COUFFY SUR SARSONNE", cp : "19340" },
{ code : "19065", intitule : "COURTEIX", cp : "19340" },
{ code : "19066", intitule : "CUBLAC", cp : "19520" },
{ code : "19067", intitule : "CUREMONTE", cp : "19500" },
{ code : "19068", intitule : "DAMPNIAT", cp : "19360" },
{ code : "19069", intitule : "DARAZAC", cp : "19220" },
{ code : "19070", intitule : "DARNETS", cp : "19300" },
{ code : "19071", intitule : "DAVIGNAC", cp : "19250" },
{ code : "19072", intitule : "DONZENAC", cp : "19270" },
{ code : "19073", intitule : "EGLETONS", cp : "19300" },
{ code : "19074", intitule : "L EGLISE AUX BOIS", cp : "19170" },
{ code : "19075", intitule : "ESPAGNAC", cp : "19150" },
{ code : "19076", intitule : "ESPARTIGNAC", cp : "19140" },
{ code : "19077", intitule : "ESTIVALS", cp : "19600" },
{ code : "19078", intitule : "ESTIVAUX", cp : "19410" },
{ code : "19079", intitule : "EYBURIE", cp : "19140" },
{ code : "19080", intitule : "EYGURANDE", cp : "19340" },
{ code : "19081", intitule : "EYREIN", cp : "19800" },
{ code : "19082", intitule : "FAVARS", cp : "19330" },
{ code : "19083", intitule : "FEYT", cp : "19340" },
{ code : "19084", intitule : "FORGES", cp : "19380" },
{ code : "19085", intitule : "GIMEL LES CASCADES", cp : "19800" },
{ code : "19086", intitule : "GOULLES", cp : "19430" },
{ code : "19087", intitule : "GOURDON MURAT", cp : "19170" },
{ code : "19088", intitule : "GRANDSAIGNE", cp : "19300" },
{ code : "19089", intitule : "GROS CHASTANG", cp : "19320" },
{ code : "19090", intitule : "GUMOND", cp : "19320" },
{ code : "19091", intitule : "HAUTEFAGE", cp : "19400" },
{ code : "19092", intitule : "LE JARDIN", cp : "19300" },
{ code : "19093", intitule : "JUGEALS NAZARETH", cp : "19500" },
{ code : "19094", intitule : "JUILLAC", cp : "19350" },
{ code : "19095", intitule : "LACELLE", cp : "19170" },
{ code : "19096", intitule : "LADIGNAC SUR RONDELLES", cp : "19150" },
{ code : "19097", intitule : "LAFAGE SUR SOMBRE", cp : "19320" },
{ code : "19098", intitule : "LAGARDE ENVAL", cp : "19150" },
{ code : "19099", intitule : "LAGLEYGEOLLE", cp : "19500" },
{ code : "19100", intitule : "LAGRAULIERE", cp : "19700" },
{ code : "19101", intitule : "LAGUENNE", cp : "19150" },
{ code : "19102", intitule : "LAMAZIERE BASSE", cp : "19160" },
{ code : "19103", intitule : "LAMAZIERE HAUTE", cp : "19340" },
{ code : "19104", intitule : "LAMONGERIE", cp : "19510" },
{ code : "19105", intitule : "LANTEUIL", cp : "19190" },
{ code : "19106", intitule : "LAPLEAU", cp : "19550" },
{ code : "19107", intitule : "LARCHE", cp : "19600" },
{ code : "19108", intitule : "LAROCHE PRES FEYT", cp : "19340" },
{ code : "19109", intitule : "LASCAUX", cp : "19130" },
{ code : "19110", intitule : "LATRONCHE", cp : "19160" },
{ code : "19111", intitule : "LAVAL SUR LUZEGE", cp : "19550" },
{ code : "19112", intitule : "LESTARDS", cp : "19170" },
{ code : "19113", intitule : "LIGINIAC", cp : "19160" },
{ code : "19114", intitule : "LIGNAREIX", cp : "19200" },
{ code : "19115", intitule : "LIGNEYRAC", cp : "19500" },
{ code : "19116", intitule : "LIOURDRES", cp : "19120" },
{ code : "19117", intitule : "LISSAC SUR COUZE", cp : "19600" },
{ code : "19118", intitule : "LE LONZAC", cp : "19470" },
{ code : "19119", intitule : "LOSTANGES", cp : "19500" },
{ code : "19120", intitule : "LOUIGNAC", cp : "19310" },
{ code : "19121", intitule : "LUBERSAC", cp : "19210" },
{ code : "19122", intitule : "MADRANGES", cp : "19470" },
{ code : "19123", intitule : "MALEMORT", cp : "19360" },
{ code : "19123", intitule : "MALEMORT", cp : "19360" },
{ code : "19124", intitule : "MANSAC", cp : "19520" },
{ code : "19125", intitule : "MARCILLAC LA CROISILLE", cp : "19320" },
{ code : "19126", intitule : "MARCILLAC LA CROZE", cp : "19500" },
{ code : "19127", intitule : "MARC LA TOUR", cp : "19150" },
{ code : "19128", intitule : "MARGERIDES", cp : "19200" },
{ code : "19129", intitule : "MASSERET", cp : "19510" },
{ code : "19130", intitule : "MAUSSAC", cp : "19250" },
{ code : "19131", intitule : "MEILHARDS", cp : "19510" },
{ code : "19132", intitule : "MENOIRE", cp : "19190" },
{ code : "19133", intitule : "MERCOEUR", cp : "19430" },
{ code : "19134", intitule : "MERLINES", cp : "19340" },
{ code : "19135", intitule : "MESTES", cp : "19200" },
{ code : "19136", intitule : "MEYMAC", cp : "19250" },
{ code : "19137", intitule : "MEYRIGNAC L EGLISE", cp : "19800" },
{ code : "19138", intitule : "MEYSSAC", cp : "19500" },
{ code : "19139", intitule : "MILLEVACHES", cp : "19290" },
{ code : "19140", intitule : "MONCEAUX SUR DORDOGNE", cp : "19400" },
{ code : "19141", intitule : "MONESTIER MERLINES", cp : "19340" },
{ code : "19142", intitule : "MONESTIER PORT DIEU", cp : "19110" },
{ code : "19143", intitule : "MONTAIGNAC ST HIPPOLYTE", cp : "19300" },
{ code : "19144", intitule : "MONTGIBAUD", cp : "19210" },
{ code : "19145", intitule : "MOUSTIER VENTADOUR", cp : "19300" },
{ code : "19146", intitule : "NAVES", cp : "19460" },
{ code : "19147", intitule : "NESPOULS", cp : "19600" },
{ code : "19148", intitule : "NEUVIC", cp : "19160" },
{ code : "19149", intitule : "NEUVILLE", cp : "19380" },
{ code : "19150", intitule : "NOAILHAC", cp : "19500" },
{ code : "19151", intitule : "NOAILLES", cp : "19600" },
{ code : "19152", intitule : "NONARDS", cp : "19120" },
{ code : "19153", intitule : "OBJAT", cp : "19130" },
{ code : "19154", intitule : "ORGNAC SUR VEZERE", cp : "19410" },
{ code : "19155", intitule : "ORLIAC DE BAR", cp : "19390" },
{ code : "19156", intitule : "PALAZINGES", cp : "19190" },
{ code : "19157", intitule : "PALISSE", cp : "19160" },
{ code : "19158", intitule : "PANDRIGNES", cp : "19150" },
{ code : "19159", intitule : "PERET BEL AIR", cp : "19300" },
{ code : "19160", intitule : "PEROLS SUR VEZERE", cp : "19170" },
{ code : "19161", intitule : "PERPEZAC LE BLANC", cp : "19310" },
{ code : "19162", intitule : "PERPEZAC LE NOIR", cp : "19410" },
{ code : "19163", intitule : "LE PESCHER", cp : "19190" },
{ code : "19164", intitule : "PEYRELEVADE", cp : "19290" },
{ code : "19165", intitule : "PEYRISSAC", cp : "19260" },
{ code : "19166", intitule : "PIERREFITTE", cp : "19450" },
{ code : "19167", intitule : "CONFOLENT PORT DIEU", cp : "19200" },
{ code : "19168", intitule : "PRADINES", cp : "19170" },
{ code : "19169", intitule : "PUY D ARNAC", cp : "19120" },
{ code : "19170", intitule : "QUEYSSAC LES VIGNES", cp : "19120" },
{ code : "19171", intitule : "REYGADE", cp : "19430" },
{ code : "19172", intitule : "RILHAC TREIGNAC", cp : "19260" },
{ code : "19173", intitule : "RILHAC XAINTRIE", cp : "19220" },
{ code : "19174", intitule : "LA ROCHE CANILLAC", cp : "19320" },
{ code : "19175", intitule : "ROCHE LE PEYROUX", cp : "19160" },
{ code : "19176", intitule : "ROSIERS D EGLETONS", cp : "19300" },
{ code : "19177", intitule : "ROSIERS DE JUILLAC", cp : "19350" },
{ code : "19178", intitule : "SADROC", cp : "19270" },
{ code : "19179", intitule : "SAILLAC", cp : "19500" },
{ code : "19180", intitule : "ST ANGEL", cp : "19200" },
{ code : "19181", intitule : "ST AUGUSTIN", cp : "19390" },
{ code : "19182", intitule : "ST AULAIRE", cp : "19130" },
{ code : "19183", intitule : "ST BAZILE DE LA ROCHE", cp : "19320" },
{ code : "19184", intitule : "ST BAZILE DE MEYSSAC", cp : "19500" },
{ code : "19185", intitule : "ST BONNET AVALOUZE", cp : "19150" },
{ code : "19186", intitule : "ST BONNET ELVERT", cp : "19380" },
{ code : "19187", intitule : "ST BONNET LA RIVIERE", cp : "19130" },
{ code : "19188", intitule : "ST BONNET L ENFANTIER", cp : "19410" },
{ code : "19189", intitule : "ST BONNET LES TOURS DE MERLE", cp : "19430" },
{ code : "19190", intitule : "ST BONNET PRES BORT", cp : "19200" },
{ code : "19191", intitule : "ST CERNIN DE LARCHE", cp : "19600" },
{ code : "19192", intitule : "ST CHAMANT", cp : "19380" },
{ code : "19193", intitule : "ST CIRGUES LA LOUTRE", cp : "19220" },
{ code : "19194", intitule : "ST CLEMENT", cp : "19700" },
{ code : "19195", intitule : "ST CYPRIEN", cp : "19130" },
{ code : "19196", intitule : "ST CYR LA ROCHE", cp : "19130" },
{ code : "19198", intitule : "ST ELOY LES TUILERIES", cp : "19210" },
{ code : "19199", intitule : "ST ETIENNE AUX CLOS", cp : "19200" },
{ code : "19200", intitule : "ST ETIENNE LA GENESTE", cp : "19160" },
{ code : "19201", intitule : "ST EXUPERY LES ROCHES", cp : "19200" },
{ code : "19202", intitule : "STE FEREOLE", cp : "19270" },
{ code : "19203", intitule : "STE FORTUNADE", cp : "19490" },
{ code : "19204", intitule : "ST FREJOUX", cp : "19200" },
{ code : "19205", intitule : "ST GENIEZ O MERLE", cp : "19220" },
{ code : "19206", intitule : "ST GERMAIN LAVOLPS", cp : "19290" },
{ code : "19207", intitule : "ST GERMAIN LES VERGNES", cp : "19330" },
{ code : "19208", intitule : "ST HILAIRE FOISSAC", cp : "19550" },
{ code : "19209", intitule : "ST HILAIRE LES COURBES", cp : "19170" },
{ code : "19210", intitule : "ST HILAIRE LUC", cp : "19160" },
{ code : "19211", intitule : "ST HILAIRE PEYROUX", cp : "19560" },
{ code : "19212", intitule : "ST HILAIRE TAURIEUX", cp : "19400" },
{ code : "19213", intitule : "ST JAL", cp : "19700" },
{ code : "19214", intitule : "ST JULIEN AUX BOIS", cp : "19220" },
{ code : "19215", intitule : "ST JULIEN LE PELERIN", cp : "19430" },
{ code : "19216", intitule : "ST JULIEN LE VENDOMOIS", cp : "19210" },
{ code : "19217", intitule : "ST JULIEN MAUMONT", cp : "19500" },
{ code : "19218", intitule : "ST JULIEN PRES BORT", cp : "19110" },
{ code : "19219", intitule : "STE MARIE LAPANOUZE", cp : "19160" },
{ code : "19220", intitule : "ST MARTIAL DE GIMEL", cp : "19150" },
{ code : "19221", intitule : "ST MARTIAL ENTRAYGUES", cp : "19400" },
{ code : "19222", intitule : "ST MARTIN LA MEANNE", cp : "19320" },
{ code : "19223", intitule : "ST MARTIN SEPERT", cp : "19210" },
{ code : "19225", intitule : "ST MERD DE LAPLEAU", cp : "19320" },
{ code : "19226", intitule : "ST MERD LES OUSSINES", cp : "19170" },
{ code : "19227", intitule : "ST MEXANT", cp : "19330" },
{ code : "19228", intitule : "ST PANTALEON DE LAPLEAU", cp : "19160" },
{ code : "19229", intitule : "ST PANTALEON DE LARCHE", cp : "19600" },
{ code : "19230", intitule : "ST PARDOUX CORBIER", cp : "19210" },
{ code : "19231", intitule : "ST PARDOUX LA CROISILLE", cp : "19320" },
{ code : "19232", intitule : "ST PARDOUX LE NEUF", cp : "19200" },
{ code : "19233", intitule : "ST PARDOUX LE VIEUX", cp : "19200" },
{ code : "19234", intitule : "ST PARDOUX L ORTIGIER", cp : "19270" },
{ code : "19235", intitule : "ST PAUL", cp : "19150" },
{ code : "19236", intitule : "ST PRIEST DE GIMEL", cp : "19800" },
{ code : "19237", intitule : "ST PRIVAT", cp : "19220" },
{ code : "19238", intitule : "ST REMY", cp : "19290" },
{ code : "19239", intitule : "ST ROBERT", cp : "19310" },
{ code : "19240", intitule : "ST SALVADOUR", cp : "19700" },
{ code : "19241", intitule : "ST SETIERS", cp : "19290" },
{ code : "19242", intitule : "ST SOLVE", cp : "19130" },
{ code : "19243", intitule : "ST SORNIN LAVOLPS", cp : "19230" },
{ code : "19244", intitule : "ST SULPICE LES BOIS", cp : "19250" },
{ code : "19245", intitule : "ST SYLVAIN", cp : "19380" },
{ code : "19246", intitule : "ST VIANCE", cp : "19240" },
{ code : "19247", intitule : "ST VICTOUR", cp : "19200" },
{ code : "19248", intitule : "ST YBARD", cp : "19140" },
{ code : "19249", intitule : "ST YRIEIX LE DEJALAT", cp : "19300" },
{ code : "19250", intitule : "SALON LA TOUR", cp : "19510" },
{ code : "19251", intitule : "SARRAN", cp : "19800" },
{ code : "19252", intitule : "SARROUX", cp : "19110" },
{ code : "19253", intitule : "SEGONZAC", cp : "19310" },
{ code : "19254", intitule : "SEGUR LE CHATEAU", cp : "19230" },
{ code : "19255", intitule : "SEILHAC", cp : "19700" },
{ code : "19256", intitule : "SERANDON", cp : "19160" },
{ code : "19257", intitule : "SERILHAC", cp : "19190" },
{ code : "19258", intitule : "SERVIERES LE CHATEAU", cp : "19220" },
{ code : "19259", intitule : "SEXCLES", cp : "19430" },
{ code : "19260", intitule : "SIONIAC", cp : "19120" },
{ code : "19261", intitule : "SORNAC", cp : "19290" },
{ code : "19262", intitule : "SOUDAINE LAVINADIERE", cp : "19370" },
{ code : "19263", intitule : "SOUDEILLES", cp : "19300" },
{ code : "19264", intitule : "SOURSAC", cp : "19550" },
{ code : "19265", intitule : "TARNAC", cp : "19170" },
{ code : "19266", intitule : "THALAMY", cp : "19200" },
{ code : "19268", intitule : "TOY VIAM", cp : "19170" },
{ code : "19269", intitule : "TREIGNAC", cp : "19260" },
{ code : "19270", intitule : "TROCHE", cp : "19230" },
{ code : "19271", intitule : "TUDEILS", cp : "19120" },
{ code : "19272", intitule : "TULLE", cp : "19000" },
{ code : "19273", intitule : "TURENNE", cp : "19500" },
{ code : "19274", intitule : "USSAC", cp : "19270" },
{ code : "19275", intitule : "USSEL", cp : "19200" },
{ code : "19275", intitule : "USSEL", cp : "19200" },
{ code : "19275", intitule : "USSEL", cp : "19200" },
{ code : "19276", intitule : "UZERCHE", cp : "19140" },
{ code : "19277", intitule : "VALIERGUES", cp : "19200" },
{ code : "19278", intitule : "VARETZ", cp : "19240" },
{ code : "19279", intitule : "VARS SUR ROSEIX", cp : "19130" },
{ code : "19280", intitule : "VEGENNES", cp : "19120" },
{ code : "19281", intitule : "VEIX", cp : "19260" },
{ code : "19283", intitule : "VEYRIERES", cp : "19200" },
{ code : "19284", intitule : "VIAM", cp : "19170" },
{ code : "19285", intitule : "VIGEOIS", cp : "19410" },
{ code : "19286", intitule : "VIGNOLS", cp : "19130" },
{ code : "19287", intitule : "VITRAC SUR MONTANE", cp : "19800" },
{ code : "19288", intitule : "VOUTEZAC", cp : "19130" },
{ code : "19289", intitule : "YSSANDON", cp : "19310" },
{ code : "21001", intitule : "AGENCOURT", cp : "21700" },
{ code : "21002", intitule : "AGEY", cp : "21410" },
{ code : "21003", intitule : "AHUY", cp : "21121" },
{ code : "21004", intitule : "AIGNAY LE DUC", cp : "21510" },
{ code : "21005", intitule : "AISEREY", cp : "21110" },
{ code : "21006", intitule : "AISEY SUR SEINE", cp : "21400" },
{ code : "21007", intitule : "AISY SOUS THIL", cp : "21390" },
{ code : "21008", intitule : "ALISE STE REINE", cp : "21150" },
{ code : "21009", intitule : "ALLEREY", cp : "21230" },
{ code : "21010", intitule : "ALOXE CORTON", cp : "21420" },
{ code : "21011", intitule : "AMPILLY LES BORDES", cp : "21450" },
{ code : "21012", intitule : "AMPILLY LE SEC", cp : "21400" },
{ code : "21013", intitule : "ANCEY", cp : "21410" },
{ code : "21014", intitule : "ANTHEUIL", cp : "21360" },
{ code : "21015", intitule : "ANTIGNY LA VILLE", cp : "21230" },
{ code : "21016", intitule : "ARCEAU", cp : "21310" },
{ code : "21017", intitule : "ARCENANT", cp : "21700" },
{ code : "21018", intitule : "ARCEY", cp : "21410" },
{ code : "21020", intitule : "ARCONCEY", cp : "21320" },
{ code : "21021", intitule : "ARC SUR TILLE", cp : "21560" },
{ code : "21022", intitule : "ARGILLY", cp : "21700" },
{ code : "21023", intitule : "ARNAY LE DUC", cp : "21230" },
{ code : "21024", intitule : "ARNAY SOUS VITTEAUX", cp : "21350" },
{ code : "21025", intitule : "ARRANS", cp : "21500" },
{ code : "21026", intitule : "ASNIERES EN MONTAGNE", cp : "21500" },
{ code : "21027", intitule : "ASNIERES LES DIJON", cp : "21380" },
{ code : "21028", intitule : "ATHEE", cp : "21130" },
{ code : "21029", intitule : "ATHIE", cp : "21500" },
{ code : "21030", intitule : "AUBAINE", cp : "21360" },
{ code : "21031", intitule : "AUBIGNY EN PLAINE", cp : "21170" },
{ code : "21032", intitule : "AUBIGNY LA RONCE", cp : "21340" },
{ code : "21033", intitule : "AUBIGNY LES SOMBERNON", cp : "21540" },
{ code : "21034", intitule : "AUTRICOURT", cp : "21570" },
{ code : "21035", intitule : "AUVILLARS SUR SAONE", cp : "21250" },
{ code : "21036", intitule : "AUXANT", cp : "21360" },
{ code : "21037", intitule : "AUXEY DURESSES", cp : "21190" },
{ code : "21038", intitule : "AUXONNE", cp : "21130" },
{ code : "21039", intitule : "AVELANGES", cp : "21120" },
{ code : "21040", intitule : "AVOSNES", cp : "21350" },
{ code : "21041", intitule : "AVOT", cp : "21580" },
{ code : "21042", intitule : "BAGNOT", cp : "21700" },
{ code : "21043", intitule : "BAIGNEUX LES JUIFS", cp : "21450" },
{ code : "21044", intitule : "BALOT", cp : "21330" },
{ code : "21045", intitule : "BARBIREY SUR OUCHE", cp : "21410" },
{ code : "21046", intitule : "BARD LE REGULIER", cp : "21430" },
{ code : "21047", intitule : "BARD LES EPOISSES", cp : "21460" },
{ code : "21048", intitule : "BARGES", cp : "21910" },
{ code : "21049", intitule : "BARJON", cp : "21580" },
{ code : "21050", intitule : "BAUBIGNY", cp : "21340" },
{ code : "21051", intitule : "BAULME LA ROCHE", cp : "21410" },
{ code : "21052", intitule : "BEAULIEU", cp : "21510" },
{ code : "21053", intitule : "BEAUMONT SUR VINGEANNE", cp : "21310" },
{ code : "21054", intitule : "BEAUNE", cp : "21200" },
{ code : "21055", intitule : "BEAUNOTTE", cp : "21510" },
{ code : "21056", intitule : "BEIRE LE CHATEL", cp : "21310" },
{ code : "21057", intitule : "BEIRE LE FORT", cp : "21110" },
{ code : "21058", intitule : "BELAN SUR OURCE", cp : "21570" },
{ code : "21059", intitule : "BELLEFOND", cp : "21490" },
{ code : "21060", intitule : "BELLENEUVE", cp : "21310" },
{ code : "21061", intitule : "BELLENOD SUR SEINE", cp : "21510" },
{ code : "21062", intitule : "BELLENOT SOUS POUILLY", cp : "21320" },
{ code : "21063", intitule : "BENEUVRE", cp : "21290" },
{ code : "21064", intitule : "BENOISEY", cp : "21500" },
{ code : "21065", intitule : "BESSEY EN CHAUME", cp : "21360" },
{ code : "21066", intitule : "BESSEY LA COUR", cp : "21360" },
{ code : "21067", intitule : "BESSEY LES CITEAUX", cp : "21110" },
{ code : "21068", intitule : "BEUREY BAUGUAY", cp : "21320" },
{ code : "21069", intitule : "BEURIZOT", cp : "21350" },
{ code : "21070", intitule : "BEVY", cp : "21220" },
{ code : "21071", intitule : "BEZE", cp : "21310" },
{ code : "21072", intitule : "BEZOUOTTE", cp : "21310" },
{ code : "21073", intitule : "BIERRE LES SEMUR", cp : "21390" },
{ code : "21074", intitule : "BILLEY", cp : "21130" },
{ code : "21075", intitule : "BILLY LES CHANCEAUX", cp : "21450" },
{ code : "21076", intitule : "BINGES", cp : "21270" },
{ code : "21077", intitule : "BISSEY LA COTE", cp : "21520" },
{ code : "21078", intitule : "BISSEY LA PIERRE", cp : "21330" },
{ code : "21079", intitule : "BLAGNY SUR VINGEANNE", cp : "21310" },
{ code : "21080", intitule : "BLAISY BAS", cp : "21540" },
{ code : "21081", intitule : "BLAISY HAUT", cp : "21540" },
{ code : "21082", intitule : "BLANCEY", cp : "21320" },
{ code : "21083", intitule : "BLANOT", cp : "21430" },
{ code : "21084", intitule : "SOURCE SEINE", cp : "21690" },
{ code : "21084", intitule : "SOURCE SEINE", cp : "21690" },
{ code : "21085", intitule : "BLIGNY LE SEC", cp : "21440" },
{ code : "21086", intitule : "BLIGNY LES BEAUNE", cp : "21200" },
{ code : "21087", intitule : "BLIGNY SUR OUCHE", cp : "21360" },
{ code : "21088", intitule : "BONCOURT LE BOIS", cp : "21700" },
{ code : "21089", intitule : "BONNENCONTRE", cp : "21250" },
{ code : "21090", intitule : "BOUDREVILLE", cp : "21520" },
{ code : "21091", intitule : "BOUHEY", cp : "21360" },
{ code : "21092", intitule : "BOUILLAND", cp : "21420" },
{ code : "21093", intitule : "BOUIX", cp : "21330" },
{ code : "21094", intitule : "BOURBERAIN", cp : "21610" },
{ code : "21095", intitule : "BOUSSELANGE", cp : "21250" },
{ code : "21096", intitule : "BOUSSENOIS", cp : "21260" },
{ code : "21097", intitule : "BOUSSEY", cp : "21350" },
{ code : "21098", intitule : "BOUX SOUS SALMAISE", cp : "21690" },
{ code : "21099", intitule : "BOUZE LES BEAUNE", cp : "21200" },
{ code : "21100", intitule : "BRAIN", cp : "21350" },
{ code : "21101", intitule : "BRAUX", cp : "21390" },
{ code : "21102", intitule : "BRAZEY EN MORVAN", cp : "21430" },
{ code : "21103", intitule : "BRAZEY EN PLAINE", cp : "21470" },
{ code : "21104", intitule : "BREMUR ET VAUROIS", cp : "21400" },
{ code : "21105", intitule : "BRESSEY SUR TILLE", cp : "21560" },
{ code : "21106", intitule : "BRETENIERE", cp : "21110" },
{ code : "21107", intitule : "BRETIGNY", cp : "21490" },
{ code : "21108", intitule : "BRIANNY", cp : "21390" },
{ code : "21109", intitule : "BRION SUR OURCE", cp : "21570" },
{ code : "21110", intitule : "BROCHON", cp : "21220" },
{ code : "21111", intitule : "BROGNON", cp : "21490" },
{ code : "21112", intitule : "BROIN", cp : "21250" },
{ code : "21113", intitule : "BROINDON", cp : "21220" },
{ code : "21114", intitule : "BUFFON", cp : "21500" },
{ code : "21115", intitule : "BUNCEY", cp : "21400" },
{ code : "21116", intitule : "BURE LES TEMPLIERS", cp : "21290" },
{ code : "21117", intitule : "BUSSEAUT", cp : "21510" },
{ code : "21118", intitule : "BUSSEROTTE ET MONTENAILLE", cp : "21580" },
{ code : "21119", intitule : "BUSSIERES", cp : "21580" },
{ code : "21120", intitule : "LA BUSSIERE SUR OUCHE", cp : "21360" },
{ code : "21121", intitule : "BUSSY LA PESLE", cp : "21540" },
{ code : "21122", intitule : "BUSSY LE GRAND", cp : "21150" },
{ code : "21123", intitule : "BUXEROLLES", cp : "21290" },
{ code : "21124", intitule : "CENSEREY", cp : "21430" },
{ code : "21125", intitule : "CERILLY", cp : "21330" },
{ code : "21126", intitule : "CESSEY SUR TILLE", cp : "21110" },
{ code : "21127", intitule : "CHAIGNAY", cp : "21120" },
{ code : "21128", intitule : "CHAILLY SUR ARMANCON", cp : "21320" },
{ code : "21129", intitule : "CHAMBAIN", cp : "21290" },
{ code : "21130", intitule : "CHAMBEIRE", cp : "21110" },
{ code : "21131", intitule : "CHAMBLANC", cp : "21250" },
{ code : "21132", intitule : "CHAMBOEUF", cp : "21220" },
{ code : "21133", intitule : "CHAMBOLLE MUSIGNY", cp : "21220" },
{ code : "21134", intitule : "CHAMESSON", cp : "21400" },
{ code : "21135", intitule : "CHAMPAGNE SUR VINGEANNE", cp : "21310" },
{ code : "21136", intitule : "CHAMPAGNY", cp : "21440" },
{ code : "21137", intitule : "CHAMP D OISEAU", cp : "21500" },
{ code : "21138", intitule : "CHAMPDOTRE", cp : "21130" },
{ code : "21139", intitule : "CHAMPEAU EN MORVAN", cp : "21210" },
{ code : "21140", intitule : "CHAMPIGNOLLES", cp : "21230" },
{ code : "21141", intitule : "CHAMPRENAULT", cp : "21690" },
{ code : "21142", intitule : "CHANCEAUX", cp : "21440" },
{ code : "21143", intitule : "CHANNAY", cp : "21330" },
{ code : "21144", intitule : "CHARENCEY", cp : "21690" },
{ code : "21145", intitule : "CHARIGNY", cp : "21140" },
{ code : "21146", intitule : "CHARMES", cp : "21310" },
{ code : "21147", intitule : "CHARNY", cp : "21350" },
{ code : "21148", intitule : "CHARREY SUR SAONE", cp : "21170" },
{ code : "21149", intitule : "CHARREY SUR SEINE", cp : "21400" },
{ code : "21150", intitule : "CHASSAGNE MONTRACHET", cp : "21190" },
{ code : "21151", intitule : "CHASSEY", cp : "21150" },
{ code : "21152", intitule : "CHATEAUNEUF", cp : "21320" },
{ code : "21153", intitule : "CHATELLENOT", cp : "21320" },
{ code : "21154", intitule : "CHATILLON SUR SEINE", cp : "21400" },
{ code : "21155", intitule : "CHAUDENAY LA VILLE", cp : "21360" },
{ code : "21156", intitule : "CHAUDENAY LE CHATEAU", cp : "21360" },
{ code : "21157", intitule : "CHAUGEY", cp : "21290" },
{ code : "21158", intitule : "CHAUME ET COURCHAMP", cp : "21610" },
{ code : "21158", intitule : "CHAUME ET COURCHAMP", cp : "21610" },
{ code : "21159", intitule : "LA CHAUME", cp : "21520" },
{ code : "21160", intitule : "CHAUME LES BAIGNEUX", cp : "21450" },
{ code : "21161", intitule : "CHAUMONT LE BOIS", cp : "21400" },
{ code : "21162", intitule : "CHAUX", cp : "21700" },
{ code : "21163", intitule : "CHAZEUIL", cp : "21260" },
{ code : "21164", intitule : "CHAZILLY", cp : "21320" },
{ code : "21165", intitule : "CHEMIN D AISEY", cp : "21400" },
{ code : "21166", intitule : "CHENOVE", cp : "21300" },
{ code : "21167", intitule : "CHEUGE", cp : "21310" },
{ code : "21168", intitule : "CHEVANNAY", cp : "21540" },
{ code : "21169", intitule : "CHEVANNES", cp : "21220" },
{ code : "21170", intitule : "CHEVIGNY EN VALIERE", cp : "21200" },
{ code : "21171", intitule : "CHEVIGNY ST SAUVEUR", cp : "21800" },
{ code : "21172", intitule : "CHIVRES", cp : "21820" },
{ code : "21173", intitule : "CHOREY LES BEAUNE", cp : "21200" },
{ code : "21175", intitule : "CIREY LES PONTAILLER", cp : "21270" },
{ code : "21176", intitule : "CIVRY EN MONTAGNE", cp : "21320" },
{ code : "21177", intitule : "CLAMEREY", cp : "21390" },
{ code : "21178", intitule : "CLEMENCEY", cp : "21220" },
{ code : "21179", intitule : "CLENAY", cp : "21490" },
{ code : "21180", intitule : "CLERY", cp : "21270" },
{ code : "21181", intitule : "CLOMOT", cp : "21230" },
{ code : "21182", intitule : "COLLONGES LES BEVY", cp : "21220" },
{ code : "21183", intitule : "COLLONGES LES PREMIERES", cp : "21110" },
{ code : "21184", intitule : "COLOMBIER", cp : "21360" },
{ code : "21185", intitule : "COMBERTAULT", cp : "21200" },
{ code : "21186", intitule : "COMBLANCHIEN", cp : "21700" },
{ code : "21187", intitule : "COMMARIN", cp : "21320" },
{ code : "21189", intitule : "CORBERON", cp : "21250" },
{ code : "21190", intitule : "CORCELLES LES ARTS", cp : "21190" },
{ code : "21191", intitule : "CORCELLES LES CITEAUX", cp : "21910" },
{ code : "21192", intitule : "CORCELLES LES MONTS", cp : "21160" },
{ code : "21193", intitule : "CORGENGOUX", cp : "21250" },
{ code : "21194", intitule : "CORGOLOIN", cp : "21700" },
{ code : "21195", intitule : "CORMOT LE GRAND", cp : "21340" },
{ code : "21196", intitule : "CORPEAU", cp : "21190" },
{ code : "21197", intitule : "CORPOYER LA CHAPELLE", cp : "21150" },
{ code : "21198", intitule : "CORROMBLES", cp : "21460" },
{ code : "21199", intitule : "CORSAINT", cp : "21460" },
{ code : "21200", intitule : "COUCHEY", cp : "21160" },
{ code : "21201", intitule : "COULMIER LE SEC", cp : "21400" },
{ code : "21202", intitule : "COURBAN", cp : "21520" },
{ code : "21203", intitule : "COURCELLES FREMOY", cp : "21460" },
{ code : "21204", intitule : "COURCELLES LES MONTBARD", cp : "21500" },
{ code : "21205", intitule : "COURCELLES LES SEMUR", cp : "21140" },
{ code : "21207", intitule : "COURLON", cp : "21580" },
{ code : "21208", intitule : "COURTIVRON", cp : "21120" },
{ code : "21209", intitule : "COUTERNON", cp : "21560" },
{ code : "21210", intitule : "CREANCEY", cp : "21320" },
{ code : "21211", intitule : "CRECEY SUR TILLE", cp : "21120" },
{ code : "21212", intitule : "CREPAND", cp : "21500" },
{ code : "21213", intitule : "CRIMOLOIS", cp : "21800" },
{ code : "21214", intitule : "CRUGEY", cp : "21360" },
{ code : "21215", intitule : "CUISEREY", cp : "21310" },
{ code : "21216", intitule : "CULETRE", cp : "21230" },
{ code : "21217", intitule : "CURLEY", cp : "21220" },
{ code : "21218", intitule : "CURTIL ST SEINE", cp : "21380" },
{ code : "21219", intitule : "CURTIL VERGY", cp : "21220" },
{ code : "21220", intitule : "CUSSEY LES FORGES", cp : "21580" },
{ code : "21221", intitule : "CUSSY LA COLONNE", cp : "21360" },
{ code : "21222", intitule : "CUSSY LE CHATEL", cp : "21230" },
{ code : "21223", intitule : "DAIX", cp : "21121" },
{ code : "21224", intitule : "DAMPIERRE EN MONTAGNE", cp : "21350" },
{ code : "21225", intitule : "DAMPIERRE ET FLEE", cp : "21310" },
{ code : "21226", intitule : "DARCEY", cp : "21150" },
{ code : "21227", intitule : "DAROIS", cp : "21121" },
{ code : "21228", intitule : "DETAIN ET BRUANT", cp : "21220" },
{ code : "21229", intitule : "DIANCEY", cp : "21430" },
{ code : "21230", intitule : "DIENAY", cp : "21120" },
{ code : "21231", intitule : "DIJON", cp : "21000" },
{ code : "21232", intitule : "DOMPIERRE EN MORVAN", cp : "21390" },
{ code : "21233", intitule : "DRAMBON", cp : "21270" },
{ code : "21234", intitule : "DREE", cp : "21540" },
{ code : "21235", intitule : "DUESME", cp : "21510" },
{ code : "21236", intitule : "EBATY", cp : "21190" },
{ code : "21237", intitule : "ECHALOT", cp : "21510" },
{ code : "21238", intitule : "ECHANNAY", cp : "21540" },
{ code : "21239", intitule : "ECHENON", cp : "21170" },
{ code : "21240", intitule : "ECHEVANNES", cp : "21120" },
{ code : "21241", intitule : "ECHEVRONNE", cp : "21420" },
{ code : "21242", intitule : "ECHIGEY", cp : "21110" },
{ code : "21243", intitule : "ECUTIGNY", cp : "21360" },
{ code : "21244", intitule : "EGUILLY", cp : "21320" },
{ code : "21245", intitule : "EPAGNY", cp : "21380" },
{ code : "21246", intitule : "EPERNAY SOUS GEVREY", cp : "21220" },
{ code : "21247", intitule : "EPOISSES", cp : "21460" },
{ code : "21248", intitule : "ERINGES", cp : "21500" },
{ code : "21249", intitule : "ESBARRES", cp : "21170" },
{ code : "21250", intitule : "ESSAROIS", cp : "21290" },
{ code : "21251", intitule : "ESSEY", cp : "21320" },
{ code : "21252", intitule : "ETAIS", cp : "21500" },
{ code : "21253", intitule : "ETALANTE", cp : "21510" },
{ code : "21254", intitule : "L ETANG VERGY", cp : "21220" },
{ code : "21255", intitule : "ETAULES", cp : "21121" },
{ code : "21256", intitule : "ETEVAUX", cp : "21270" },
{ code : "21257", intitule : "ETORMAY", cp : "21450" },
{ code : "21258", intitule : "ETROCHEY", cp : "21400" },
{ code : "21259", intitule : "FAIN LES MONTBARD", cp : "21500" },
{ code : "21260", intitule : "FAIN LES MOUTIERS", cp : "21500" },
{ code : "21261", intitule : "FAUVERNEY", cp : "21110" },
{ code : "21262", intitule : "FAVEROLLES LES LUCEY", cp : "21290" },
{ code : "21263", intitule : "FENAY", cp : "21600" },
{ code : "21264", intitule : "LE FETE", cp : "21230" },
{ code : "21265", intitule : "FIXIN", cp : "21220" },
{ code : "21266", intitule : "FLACEY", cp : "21490" },
{ code : "21267", intitule : "FLAGEY ECHEZEAUX", cp : "21640" },
{ code : "21268", intitule : "FLAGEY LES AUXONNE", cp : "21130" },
{ code : "21269", intitule : "FLAMMERANS", cp : "21130" },
{ code : "21270", intitule : "FLAVIGNEROT", cp : "21160" },
{ code : "21271", intitule : "FLAVIGNY SUR OZERAIN", cp : "21150" },
{ code : "21272", intitule : "FLEE", cp : "21140" },
{ code : "21273", intitule : "FLEUREY SUR OUCHE", cp : "21410" },
{ code : "21274", intitule : "FOISSY", cp : "21230" },
{ code : "21275", intitule : "FONCEGRIVE", cp : "21260" },
{ code : "21276", intitule : "FONTAINES EN DUESMOIS", cp : "21450" },
{ code : "21277", intitule : "FONTAINE FRANCAISE", cp : "21610" },
{ code : "21278", intitule : "FONTAINE LES DIJON", cp : "21121" },
{ code : "21279", intitule : "FONTAINES LES SECHES", cp : "21330" },
{ code : "21280", intitule : "FONTANGY", cp : "21390" },
{ code : "21281", intitule : "FONTENELLE", cp : "21610" },
{ code : "21282", intitule : "FORLEANS", cp : "21460" },
{ code : "21283", intitule : "FRAIGNOT ET VESVROTTE", cp : "21580" },
{ code : "21284", intitule : "FRANCHEVILLE", cp : "21440" },
{ code : "21285", intitule : "FRANXAULT", cp : "21170" },
{ code : "21286", intitule : "FRENOIS", cp : "21120" },
{ code : "21287", intitule : "FRESNES", cp : "21500" },
{ code : "21288", intitule : "FROLOIS", cp : "21150" },
{ code : "21289", intitule : "FUSSEY", cp : "21700" },
{ code : "21290", intitule : "GEMEAUX", cp : "21120" },
{ code : "21291", intitule : "GENAY", cp : "21140" },
{ code : "21292", intitule : "GENLIS", cp : "21110" },
{ code : "21293", intitule : "GERGUEIL", cp : "21410" },
{ code : "21294", intitule : "GERLAND", cp : "21700" },
{ code : "21295", intitule : "GEVREY CHAMBERTIN", cp : "21220" },
{ code : "21296", intitule : "GEVROLLES", cp : "21520" },
{ code : "21297", intitule : "GILLY LES CITEAUX", cp : "21640" },
{ code : "21298", intitule : "GISSEY LE VIEIL", cp : "21350" },
{ code : "21299", intitule : "GISSEY SOUS FLAVIGNY", cp : "21150" },
{ code : "21300", intitule : "GISSEY SUR OUCHE", cp : "21410" },
{ code : "21301", intitule : "GLANON", cp : "21250" },
{ code : "21302", intitule : "GOMMEVILLE", cp : "21400" },
{ code : "21303", intitule : "LES GOULLES", cp : "21520" },
{ code : "21304", intitule : "GRANCEY LE CHATEAU NEUVELLE", cp : "21580" },
{ code : "21304", intitule : "GRANCEY LE CHATEAU NEUVELLE", cp : "21580" },
{ code : "21305", intitule : "GRANCEY SUR OURCE", cp : "21570" },
{ code : "21306", intitule : "GRENANT LES SOMBERNON", cp : "21540" },
{ code : "21307", intitule : "GRESIGNY STE REINE", cp : "21150" },
{ code : "21308", intitule : "GRIGNON", cp : "21150" },
{ code : "21309", intitule : "GRISELLES", cp : "21330" },
{ code : "21310", intitule : "GROSBOIS EN MONTAGNE", cp : "21540" },
{ code : "21311", intitule : "GROSBOIS LES TICHEY", cp : "21250" },
{ code : "21312", intitule : "GURGY LA VILLE", cp : "21290" },
{ code : "21313", intitule : "GURGY LE CHATEAU", cp : "21290" },
{ code : "21314", intitule : "HAUTEROCHE", cp : "21150" },
{ code : "21315", intitule : "HAUTEVILLE LES DIJON", cp : "21121" },
{ code : "21316", intitule : "HEUILLEY SUR SAONE", cp : "21270" },
{ code : "21317", intitule : "IS SUR TILLE", cp : "21120" },
{ code : "21319", intitule : "IZEURE", cp : "21110" },
{ code : "21320", intitule : "IZIER", cp : "21110" },
{ code : "21321", intitule : "JAILLY LES MOULINS", cp : "21150" },
{ code : "21322", intitule : "JALLANGES", cp : "21250" },
{ code : "21323", intitule : "JANCIGNY", cp : "21310" },
{ code : "21324", intitule : "JEUX LES BARD", cp : "21460" },
{ code : "21325", intitule : "JOUEY", cp : "21230" },
{ code : "21326", intitule : "JOURS LES BAIGNEUX", cp : "21450" },
{ code : "21327", intitule : "VAL MONT", cp : "21340" },
{ code : "21327", intitule : "VAL MONT", cp : "21340" },
{ code : "21328", intitule : "JUILLENAY", cp : "21210" },
{ code : "21329", intitule : "JUILLY", cp : "21140" },
{ code : "21330", intitule : "LABERGEMENT FOIGNEY", cp : "21110" },
{ code : "21331", intitule : "LABERGEMENT LES AUXONNE", cp : "21130" },
{ code : "21332", intitule : "LABERGEMENT LES SEURRE", cp : "21820" },
{ code : "21333", intitule : "LABRUYERE", cp : "21250" },
{ code : "21334", intitule : "LACANCHE", cp : "21230" },
{ code : "21335", intitule : "LACOUR D ARCENAY", cp : "21210" },
{ code : "21336", intitule : "LAIGNES", cp : "21330" },
{ code : "21337", intitule : "LAMARCHE SUR SAONE", cp : "21760" },
{ code : "21338", intitule : "LAMARGELLE", cp : "21440" },
{ code : "21339", intitule : "LANTENAY", cp : "21370" },
{ code : "21340", intitule : "LANTHES", cp : "21250" },
{ code : "21341", intitule : "LANTILLY", cp : "21140" },
{ code : "21342", intitule : "LAPERRIERE SUR SAONE", cp : "21170" },
{ code : "21343", intitule : "LARREY", cp : "21330" },
{ code : "21344", intitule : "LECHATELET", cp : "21250" },
{ code : "21345", intitule : "LERY", cp : "21440" },
{ code : "21346", intitule : "LEUGLAY", cp : "21290" },
{ code : "21347", intitule : "LEVERNOIS", cp : "21200" },
{ code : "21348", intitule : "LICEY SUR VINGEANNE", cp : "21610" },
{ code : "21349", intitule : "LIERNAIS", cp : "21430" },
{ code : "21350", intitule : "LIGNEROLLES", cp : "21520" },
{ code : "21351", intitule : "LONGCHAMP", cp : "21110" },
{ code : "21352", intitule : "LONGEAULT", cp : "21110" },
{ code : "21353", intitule : "LONGECOURT EN PLAINE", cp : "21110" },
{ code : "21354", intitule : "LONGECOURT LES CULETRE", cp : "21230" },
{ code : "21355", intitule : "LONGVIC", cp : "21600" },
{ code : "21356", intitule : "LOSNE", cp : "21170" },
{ code : "21357", intitule : "LOUESME", cp : "21520" },
{ code : "21358", intitule : "LUCENAY LE DUC", cp : "21150" },
{ code : "21359", intitule : "LUCEY", cp : "21290" },
{ code : "21360", intitule : "LUSIGNY SUR OUCHE", cp : "21360" },
{ code : "21361", intitule : "LUX", cp : "21120" },
{ code : "21362", intitule : "MACONGE", cp : "21320" },
{ code : "21363", intitule : "MAGNIEN", cp : "21230" },
{ code : "21364", intitule : "MAGNY LAMBERT", cp : "21450" },
{ code : "21365", intitule : "MAGNY LA VILLE", cp : "21140" },
{ code : "21366", intitule : "MAGNY LES AUBIGNY", cp : "21170" },
{ code : "21367", intitule : "MAGNY MONTARLOT", cp : "21130" },
{ code : "21368", intitule : "MAGNY LES VILLERS", cp : "21700" },
{ code : "21369", intitule : "MAGNY ST MEDARD", cp : "21310" },
{ code : "21370", intitule : "MAGNY SUR TILLE", cp : "21110" },
{ code : "21371", intitule : "LES MAILLYS", cp : "21130" },
{ code : "21372", intitule : "MAISEY LE DUC", cp : "21400" },
{ code : "21373", intitule : "MALAIN", cp : "21410" },
{ code : "21374", intitule : "MALIGNY", cp : "21230" },
{ code : "21375", intitule : "MANLAY", cp : "21430" },
{ code : "21376", intitule : "MARANDEUIL", cp : "21270" },
{ code : "21377", intitule : "MARCELLOIS", cp : "21350" },
{ code : "21378", intitule : "MARCENAY", cp : "21330" },
{ code : "21379", intitule : "MARCHESEUIL", cp : "21430" },
{ code : "21380", intitule : "MARCIGNY SOUS THIL", cp : "21390" },
{ code : "21381", intitule : "MARCILLY ET DRACY", cp : "21350" },
{ code : "21382", intitule : "MARCILLY OGNY", cp : "21320" },
{ code : "21383", intitule : "MARCILLY SUR TILLE", cp : "21120" },
{ code : "21384", intitule : "MAREY LES FUSSEY", cp : "21700" },
{ code : "21385", intitule : "MAREY SUR TILLE", cp : "21120" },
{ code : "21386", intitule : "MARIGNY LE CAHOUET", cp : "21150" },
{ code : "21387", intitule : "MARIGNY LES REULLEE", cp : "21200" },
{ code : "21388", intitule : "MARLIENS", cp : "21110" },
{ code : "21389", intitule : "MARMAGNE", cp : "21500" },
{ code : "21390", intitule : "MARSANNAY LA COTE", cp : "21160" },
{ code : "21391", intitule : "MARSANNAY LE BOIS", cp : "21380" },
{ code : "21392", intitule : "MARTROIS", cp : "21320" },
{ code : "21393", intitule : "MASSINGY", cp : "21400" },
{ code : "21394", intitule : "MASSINGY LES SEMUR", cp : "21140" },
{ code : "21395", intitule : "MASSINGY LES VITTEAUX", cp : "21350" },
{ code : "21396", intitule : "MAUVILLY", cp : "21510" },
{ code : "21397", intitule : "MAVILLY MANDELOT", cp : "21190" },
{ code : "21398", intitule : "MAXILLY SUR SAONE", cp : "21270" },
{ code : "21399", intitule : "MEILLY SUR ROUVRES", cp : "21320" },
{ code : "21400", intitule : "LE MEIX", cp : "21580" },
{ code : "21401", intitule : "MELOISEY", cp : "21190" },
{ code : "21402", intitule : "MENESBLE", cp : "21290" },
{ code : "21403", intitule : "MENESSAIRE", cp : "21430" },
{ code : "21404", intitule : "MENETREUX LE PITOIS", cp : "21150" },
{ code : "21405", intitule : "MERCEUIL", cp : "21190" },
{ code : "21406", intitule : "MESMONT", cp : "21540" },
{ code : "21407", intitule : "MESSANGES", cp : "21220" },
{ code : "21408", intitule : "MESSIGNY ET VANTOUX", cp : "21380" },
{ code : "21408", intitule : "MESSIGNY ET VANTOUX", cp : "21380" },
{ code : "21409", intitule : "MEUILLEY", cp : "21700" },
{ code : "21410", intitule : "MEULSON", cp : "21510" },
{ code : "21411", intitule : "MEURSANGES", cp : "21200" },
{ code : "21412", intitule : "MEURSAULT", cp : "21190" },
{ code : "21413", intitule : "MILLERY", cp : "21140" },
{ code : "21414", intitule : "MIMEURE", cp : "21230" },
{ code : "21415", intitule : "MINOT", cp : "21510" },
{ code : "21416", intitule : "MIREBEAU SUR BEZE", cp : "21310" },
{ code : "21417", intitule : "MISSERY", cp : "21210" },
{ code : "21418", intitule : "MOITRON", cp : "21510" },
{ code : "21419", intitule : "MOLESME", cp : "21330" },
{ code : "21420", intitule : "MOLINOT", cp : "21340" },
{ code : "21421", intitule : "MOLOY", cp : "21120" },
{ code : "21422", intitule : "MOLPHEY", cp : "21210" },
{ code : "21423", intitule : "MONTAGNY LES BEAUNE", cp : "21200" },
{ code : "21424", intitule : "MONTAGNY LES SEURRE", cp : "21250" },
{ code : "21425", intitule : "MONTBARD", cp : "21500" },
{ code : "21426", intitule : "MONTBERTHAULT", cp : "21460" },
{ code : "21427", intitule : "MONTCEAU ET ECHARNANT", cp : "21360" },
{ code : "21428", intitule : "MONTHELIE", cp : "21190" },
{ code : "21429", intitule : "MONTIGNY MONTFORT", cp : "21500" },
{ code : "21430", intitule : "MONTIGNY ST BARTHELEMY", cp : "21390" },
{ code : "21431", intitule : "MONTIGNY SUR ARMANCON", cp : "21140" },
{ code : "21432", intitule : "MONTIGNY SUR AUBE", cp : "21520" },
{ code : "21433", intitule : "MONTIGNY MORNAY VILLENEUVE VINGEANNE", cp : "21610" },
{ code : "21433", intitule : "MONTIGNY MORNAY VILLENEUVE VINGEANNE", cp : "21610" },
{ code : "21433", intitule : "MONTIGNY MORNAY VILLENEUVE VINGEANNE", cp : "21610" },
{ code : "21434", intitule : "MONTLAY EN AUXOIS", cp : "21210" },
{ code : "21435", intitule : "MONTLIOT ET COURCELLES", cp : "21400" },
{ code : "21436", intitule : "MONTMAIN", cp : "21250" },
{ code : "21437", intitule : "MONTMANCON", cp : "21270" },
{ code : "21438", intitule : "MONTMOYEN", cp : "21290" },
{ code : "21439", intitule : "MONTOILLOT", cp : "21540" },
{ code : "21440", intitule : "MONTOT", cp : "21170" },
{ code : "21441", intitule : "MONT ST JEAN", cp : "21320" },
{ code : "21442", intitule : "MOREY ST DENIS", cp : "21220" },
{ code : "21444", intitule : "MOSSON", cp : "21400" },
{ code : "21445", intitule : "LA MOTTE TERNANT", cp : "21210" },
{ code : "21446", intitule : "MOUTIERS ST JEAN", cp : "21500" },
{ code : "21447", intitule : "MUSIGNY", cp : "21230" },
{ code : "21448", intitule : "MUSSY LA FOSSE", cp : "21150" },
{ code : "21449", intitule : "NAN SOUS THIL", cp : "21390" },
{ code : "21450", intitule : "NANTOUX", cp : "21190" },
{ code : "21451", intitule : "NESLE ET MASSOULT", cp : "21330" },
{ code : "21452", intitule : "NEUILLY LES DIJON", cp : "21800" },
{ code : "21454", intitule : "NICEY", cp : "21330" },
{ code : "21455", intitule : "NOD SUR SEINE", cp : "21400" },
{ code : "21456", intitule : "NOGENT LES MONTBARD", cp : "21500" },
{ code : "21457", intitule : "NOIDAN", cp : "21390" },
{ code : "21458", intitule : "NOIRON SOUS GEVREY", cp : "21910" },
{ code : "21459", intitule : "NOIRON SUR BEZE", cp : "21310" },
{ code : "21460", intitule : "NOIRON SUR SEINE", cp : "21400" },
{ code : "21461", intitule : "NOLAY", cp : "21340" },
{ code : "21461", intitule : "NOLAY", cp : "21340" },
{ code : "21462", intitule : "NORGES LA VILLE", cp : "21490" },
{ code : "21463", intitule : "NORMIER", cp : "21390" },
{ code : "21464", intitule : "NUITS ST GEORGES", cp : "21700" },
{ code : "21465", intitule : "OBTREE", cp : "21400" },
{ code : "21466", intitule : "OIGNY", cp : "21450" },
{ code : "21467", intitule : "OISILLY", cp : "21310" },
{ code : "21468", intitule : "ORAIN", cp : "21610" },
{ code : "21469", intitule : "ORGEUX", cp : "21490" },
{ code : "21470", intitule : "ORIGNY", cp : "21510" },
{ code : "21471", intitule : "ORRET", cp : "21450" },
{ code : "21472", intitule : "ORVILLE", cp : "21260" },
{ code : "21473", intitule : "OUGES", cp : "21600" },
{ code : "21474", intitule : "PAGNY LA VILLE", cp : "21250" },
{ code : "21475", intitule : "PAGNY LE CHATEAU", cp : "21250" },
{ code : "21476", intitule : "PAINBLANC", cp : "21360" },
{ code : "21477", intitule : "PANGES", cp : "21540" },
{ code : "21478", intitule : "PASQUES", cp : "21370" },
{ code : "21479", intitule : "PELLEREY", cp : "21440" },
{ code : "21480", intitule : "PERNAND VERGELESSES", cp : "21420" },
{ code : "21481", intitule : "PERRIGNY LES DIJON", cp : "21160" },
{ code : "21482", intitule : "PERRIGNY SUR L OGNON", cp : "21270" },
{ code : "21483", intitule : "PICHANGES", cp : "21120" },
{ code : "21484", intitule : "PLANAY", cp : "21500" },
{ code : "21485", intitule : "PLOMBIERES LES DIJON", cp : "21370" },
{ code : "21486", intitule : "PLUVAULT", cp : "21110" },
{ code : "21487", intitule : "PLUVET", cp : "21110" },
{ code : "21488", intitule : "POINCON LES LARREY", cp : "21330" },
{ code : "21489", intitule : "POISEUL LA GRANGE", cp : "21440" },
{ code : "21490", intitule : "POISEUL LA VILLE ET LAPERRIERE", cp : "21450" },
{ code : "21491", intitule : "POISEUL LES SAULX", cp : "21120" },
{ code : "21492", intitule : "POMMARD", cp : "21630" },
{ code : "21493", intitule : "PONCEY LES ATHEE", cp : "21130" },
{ code : "21494", intitule : "PONCEY SUR L IGNON", cp : "21440" },
{ code : "21495", intitule : "PONT", cp : "21130" },
{ code : "21496", intitule : "PONTAILLER SUR SAONE", cp : "21270" },
{ code : "21497", intitule : "PONT ET MASSENE", cp : "21140" },
{ code : "21498", intitule : "POSANGES", cp : "21350" },
{ code : "21499", intitule : "POTHIERES", cp : "21400" },
{ code : "21500", intitule : "POUILLENAY", cp : "21150" },
{ code : "21501", intitule : "POUILLY EN AUXOIS", cp : "21320" },
{ code : "21502", intitule : "POUILLY SUR SAONE", cp : "21250" },
{ code : "21503", intitule : "POUILLY SUR VINGEANNE", cp : "21610" },
{ code : "21504", intitule : "PRALON", cp : "21410" },
{ code : "21505", intitule : "PRECY SOUS THIL", cp : "21390" },
{ code : "21506", intitule : "PREMEAUX PRISSEY", cp : "21700" },
{ code : "21506", intitule : "PREMEAUX PRISSEY", cp : "21700" },
{ code : "21507", intitule : "PREMIERES", cp : "21110" },
{ code : "21508", intitule : "PRENOIS", cp : "21370" },
{ code : "21510", intitule : "PRUSLY SUR OURCE", cp : "21400" },
{ code : "21511", intitule : "PUITS", cp : "21400" },
{ code : "21512", intitule : "PULIGNY MONTRACHET", cp : "21190" },
{ code : "21513", intitule : "QUEMIGNY POISOT", cp : "21220" },
{ code : "21514", intitule : "QUEMIGNY SUR SEINE", cp : "21510" },
{ code : "21515", intitule : "QUETIGNY", cp : "21800" },
{ code : "21516", intitule : "QUINCEROT", cp : "21500" },
{ code : "21517", intitule : "QUINCEY", cp : "21700" },
{ code : "21518", intitule : "QUINCY LE VICOMTE", cp : "21500" },
{ code : "21519", intitule : "RECEY SUR OURCE", cp : "21290" },
{ code : "21520", intitule : "REMILLY EN MONTAGNE", cp : "21540" },
{ code : "21521", intitule : "REMILLY SUR TILLE", cp : "21560" },
{ code : "21522", intitule : "RENEVE", cp : "21310" },
{ code : "21523", intitule : "REULLE VERGY", cp : "21220" },
{ code : "21524", intitule : "RIEL LES EAUX", cp : "21570" },
{ code : "21525", intitule : "LA ROCHE EN BRENIL", cp : "21530" },
{ code : "21526", intitule : "ROCHEFORT SUR BREVON", cp : "21510" },
{ code : "21527", intitule : "LA ROCHEPOT", cp : "21340" },
{ code : "21528", intitule : "LA ROCHE VANNEAU", cp : "21150" },
{ code : "21529", intitule : "ROILLY", cp : "21390" },
{ code : "21530", intitule : "ROUGEMONT", cp : "21500" },
{ code : "21531", intitule : "ROUVRAY", cp : "21530" },
{ code : "21532", intitule : "ROUVRES EN PLAINE", cp : "21110" },
{ code : "21533", intitule : "ROUVRES SOUS MEILLY", cp : "21320" },
{ code : "21534", intitule : "RUFFEY LES BEAUNE", cp : "21200" },
{ code : "21535", intitule : "RUFFEY LES ECHIREY", cp : "21490" },
{ code : "21536", intitule : "SACQUENAY", cp : "21260" },
{ code : "21537", intitule : "SAFFRES", cp : "21350" },
{ code : "21538", intitule : "ST ANDEUX", cp : "21530" },
{ code : "21539", intitule : "ST ANTHOT", cp : "21540" },
{ code : "21540", intitule : "ST APOLLINAIRE", cp : "21850" },
{ code : "21541", intitule : "ST AUBIN", cp : "21190" },
{ code : "21542", intitule : "ST BERNARD", cp : "21700" },
{ code : "21543", intitule : "ST BROING LES MOINES", cp : "21290" },
{ code : "21544", intitule : "STE COLOMBE EN AUXOIS", cp : "21350" },
{ code : "21545", intitule : "STE COLOMBE SUR SEINE", cp : "21400" },
{ code : "21546", intitule : "ST DIDIER", cp : "21210" },
{ code : "21547", intitule : "ST EUPHRONE", cp : "21140" },
{ code : "21548", intitule : "ST GERMAIN DE MODEON", cp : "21530" },
{ code : "21549", intitule : "ST GERMAIN LE ROCHEUX", cp : "21510" },
{ code : "21550", intitule : "ST GERMAIN LES SENAILLY", cp : "21500" },
{ code : "21552", intitule : "ST HELIER", cp : "21690" },
{ code : "21553", intitule : "ST JEAN DE BOEUF", cp : "21410" },
{ code : "21554", intitule : "ST JEAN DE LOSNE", cp : "21170" },
{ code : "21555", intitule : "ST JULIEN", cp : "21490" },
{ code : "21556", intitule : "ST LEGER TRIEY", cp : "21270" },
{ code : "21557", intitule : "ST MARC SUR SEINE", cp : "21450" },
{ code : "21558", intitule : "STE MARIE LA BLANCHE", cp : "21200" },
{ code : "21559", intitule : "STE MARIE SUR OUCHE", cp : "21410" },
{ code : "21559", intitule : "STE MARIE SUR OUCHE", cp : "21410" },
{ code : "21560", intitule : "ST MARTIN DE LA MER", cp : "21210" },
{ code : "21561", intitule : "ST MARTIN DU MONT", cp : "21440" },
{ code : "21562", intitule : "ST MAURICE SUR VINGEANNE", cp : "21610" },
{ code : "21563", intitule : "ST MESMIN", cp : "21540" },
{ code : "21564", intitule : "ST NICOLAS LES CITEAUX", cp : "21700" },
{ code : "21565", intitule : "ST PHILIBERT", cp : "21220" },
{ code : "21566", intitule : "ST PIERRE EN VAUX", cp : "21230" },
{ code : "21567", intitule : "ST PRIX LES ARNAY", cp : "21230" },
{ code : "21568", intitule : "ST REMY", cp : "21500" },
{ code : "21569", intitule : "ST ROMAIN", cp : "21190" },
{ code : "21570", intitule : "STE SABINE", cp : "21320" },
{ code : "21571", intitule : "ST SAUVEUR", cp : "21270" },
{ code : "21572", intitule : "ST SEINE EN BACHE", cp : "21130" },
{ code : "21573", intitule : "ST SEINE L ABBAYE", cp : "21440" },
{ code : "21574", intitule : "ST SEINE SUR VINGEANNE", cp : "21610" },
{ code : "21575", intitule : "ST SYMPHORIEN SUR SAONE", cp : "21170" },
{ code : "21576", intitule : "ST THIBAULT", cp : "21350" },
{ code : "21577", intitule : "ST USAGE", cp : "21170" },
{ code : "21578", intitule : "ST VICTOR SUR OUCHE", cp : "21410" },
{ code : "21579", intitule : "SALIVES", cp : "21580" },
{ code : "21580", intitule : "SALMAISE", cp : "21690" },
{ code : "21581", intitule : "SAMEREY", cp : "21170" },
{ code : "21582", intitule : "SANTENAY", cp : "21590" },
{ code : "21583", intitule : "SANTOSSE", cp : "21340" },
{ code : "21584", intitule : "SAULIEU", cp : "21210" },
{ code : "21585", intitule : "SAULON LA CHAPELLE", cp : "21910" },
{ code : "21586", intitule : "SAULON LA RUE", cp : "21910" },
{ code : "21587", intitule : "SAULX LE DUC", cp : "21120" },
{ code : "21588", intitule : "SAUSSEY", cp : "21360" },
{ code : "21589", intitule : "SAUSSY", cp : "21380" },
{ code : "21590", intitule : "SAVIGNY LES BEAUNE", cp : "21420" },
{ code : "21591", intitule : "SAVIGNY LE SEC", cp : "21380" },
{ code : "21592", intitule : "SAVIGNY SOUS MALAIN", cp : "21540" },
{ code : "21593", intitule : "SAVILLY", cp : "21430" },
{ code : "21594", intitule : "SAVOISY", cp : "21500" },
{ code : "21595", intitule : "SAVOLLES", cp : "21310" },
{ code : "21596", intitule : "SAVOUGES", cp : "21910" },
{ code : "21597", intitule : "SEGROIS", cp : "21220" },
{ code : "21598", intitule : "SEIGNY", cp : "21150" },
{ code : "21599", intitule : "SELONGEY", cp : "21260" },
{ code : "21600", intitule : "SEMAREY", cp : "21320" },
{ code : "21601", intitule : "SEMEZANGES", cp : "21220" },
{ code : "21602", intitule : "SEMOND", cp : "21450" },
{ code : "21603", intitule : "SEMUR EN AUXOIS", cp : "21140" },
{ code : "21604", intitule : "SENAILLY", cp : "21500" },
{ code : "21605", intitule : "SENNECEY LES DIJON", cp : "21800" },
{ code : "21606", intitule : "LADOIX SERRIGNY", cp : "21550" },
{ code : "21607", intitule : "SEURRE", cp : "21250" },
{ code : "21608", intitule : "SINCEY LES ROUVRAY", cp : "21530" },
{ code : "21609", intitule : "SOIRANS", cp : "21110" },
{ code : "21610", intitule : "SOISSONS SUR NACEY", cp : "21270" },
{ code : "21611", intitule : "SOMBERNON", cp : "21540" },
{ code : "21612", intitule : "SOUHEY", cp : "21140" },
{ code : "21613", intitule : "SOUSSEY SUR BRIONNE", cp : "21350" },
{ code : "21614", intitule : "SPOY", cp : "21120" },
{ code : "21615", intitule : "SUSSEY", cp : "21430" },
{ code : "21615", intitule : "SUSSEY", cp : "21430" },
{ code : "21616", intitule : "TAILLY", cp : "21190" },
{ code : "21617", intitule : "TALANT", cp : "21240" },
{ code : "21618", intitule : "TALMAY", cp : "21270" },
{ code : "21619", intitule : "TANAY", cp : "21310" },
{ code : "21620", intitule : "TARSUL", cp : "21120" },
{ code : "21621", intitule : "TART L ABBAYE", cp : "21110" },
{ code : "21622", intitule : "TART LE BAS", cp : "21110" },
{ code : "21623", intitule : "TART LE HAUT", cp : "21110" },
{ code : "21624", intitule : "TELLECEY", cp : "21270" },
{ code : "21625", intitule : "TERNANT", cp : "21220" },
{ code : "21626", intitule : "TERREFONDREE", cp : "21290" },
{ code : "21627", intitule : "THENISSEY", cp : "21150" },
{ code : "21628", intitule : "THOIRES", cp : "21570" },
{ code : "21629", intitule : "THOISY LA BERCHERE", cp : "21210" },
{ code : "21630", intitule : "THOISY LE DESERT", cp : "21320" },
{ code : "21631", intitule : "THOMIREY", cp : "21360" },
{ code : "21632", intitule : "THOREY EN PLAINE", cp : "21110" },
{ code : "21633", intitule : "THOREY SOUS CHARNY", cp : "21350" },
{ code : "21634", intitule : "THOREY SUR OUCHE", cp : "21360" },
{ code : "21635", intitule : "THOSTE", cp : "21460" },
{ code : "21636", intitule : "THURY", cp : "21340" },
{ code : "21637", intitule : "TICHEY", cp : "21250" },
{ code : "21638", intitule : "TIL CHATEL", cp : "21120" },
{ code : "21639", intitule : "TILLENAY", cp : "21130" },
{ code : "21640", intitule : "TORCY ET POULIGNY", cp : "21460" },
{ code : "21641", intitule : "TOUILLON", cp : "21500" },
{ code : "21642", intitule : "TOUTRY", cp : "21460" },
{ code : "21643", intitule : "TRECLUN", cp : "21130" },
{ code : "21644", intitule : "TROCHERES", cp : "21310" },
{ code : "21645", intitule : "TROUHANS", cp : "21170" },
{ code : "21646", intitule : "TROUHAUT", cp : "21440" },
{ code : "21647", intitule : "TRUGNY", cp : "21250" },
{ code : "21648", intitule : "TURCEY", cp : "21540" },
{ code : "21649", intitule : "UNCEY LE FRANC", cp : "21350" },
{ code : "21650", intitule : "URCY", cp : "21220" },
{ code : "21651", intitule : "VAL SUZON", cp : "21121" },
{ code : "21652", intitule : "VANDENESSE EN AUXOIS", cp : "21320" },
{ code : "21653", intitule : "VANNAIRE", cp : "21400" },
{ code : "21655", intitule : "VANVEY", cp : "21400" },
{ code : "21656", intitule : "VARANGES", cp : "21110" },
{ code : "21657", intitule : "VAROIS ET CHAIGNOT", cp : "21490" },
{ code : "21658", intitule : "VAUCHIGNON", cp : "21340" },
{ code : "21659", intitule : "VAUX SAULES", cp : "21440" },
{ code : "21660", intitule : "VEILLY", cp : "21360" },
{ code : "21661", intitule : "VELARS SUR OUCHE", cp : "21370" },
{ code : "21662", intitule : "VELOGNY", cp : "21350" },
{ code : "21663", intitule : "VENAREY LES LAUMES", cp : "21150" },
{ code : "21663", intitule : "VENAREY LES LAUMES", cp : "21150" },
{ code : "21664", intitule : "VERDONNET", cp : "21330" },
{ code : "21665", intitule : "VERNOIS LES VESVRES", cp : "21260" },
{ code : "21666", intitule : "VERNOT", cp : "21120" },
{ code : "21667", intitule : "VERONNES", cp : "21260" },
{ code : "21667", intitule : "VERONNES", cp : "21260" },
{ code : "21669", intitule : "VERREY SOUS DREE", cp : "21540" },
{ code : "21670", intitule : "VERREY SOUS SALMAISE", cp : "21690" },
{ code : "21671", intitule : "VERTAULT", cp : "21330" },
{ code : "21672", intitule : "VESVRES", cp : "21350" },
{ code : "21673", intitule : "VEUVEY SUR OUCHE", cp : "21360" },
{ code : "21674", intitule : "VEUXHAULLES SUR AUBE", cp : "21520" },
{ code : "21675", intitule : "VIANGES", cp : "21430" },
{ code : "21676", intitule : "VIC DE CHASSENAY", cp : "21140" },
{ code : "21677", intitule : "VIC DES PRES", cp : "21360" },
{ code : "21678", intitule : "VIC SOUS THIL", cp : "21390" },
{ code : "21679", intitule : "VIEILMOULIN", cp : "21540" },
{ code : "21680", intitule : "VIELVERGE", cp : "21270" },
{ code : "21681", intitule : "VIEUX CHATEAU", cp : "21460" },
{ code : "21682", intitule : "VIEVIGNE", cp : "21310" },
{ code : "21683", intitule : "VIEVY", cp : "21230" },
{ code : "21684", intitule : "VIGNOLES", cp : "21200" },
{ code : "21685", intitule : "VILLAINES EN DUESMOIS", cp : "21450" },
{ code : "21686", intitule : "VILLAINES LES PREVOTES", cp : "21500" },
{ code : "21687", intitule : "VILLARGOIX", cp : "21210" },
{ code : "21688", intitule : "VILLARS FONTAINE", cp : "21700" },
{ code : "21689", intitule : "VILLARS ET VILLENOTTE", cp : "21140" },
{ code : "21690", intitule : "VILLEBERNY", cp : "21350" },
{ code : "21691", intitule : "VILLEBICHOT", cp : "21700" },
{ code : "21692", intitule : "VILLECOMTE", cp : "21120" },
{ code : "21693", intitule : "VILLEDIEU", cp : "21330" },
{ code : "21694", intitule : "VILLEFERRY", cp : "21350" },
{ code : "21695", intitule : "LA VILLENEUVE LES CONVERS", cp : "21450" },
{ code : "21696", intitule : "VILLENEUVE SOUS CHARIGNY", cp : "21140" },
{ code : "21698", intitule : "VILLERS LA FAYE", cp : "21700" },
{ code : "21699", intitule : "VILLERS LES POTS", cp : "21130" },
{ code : "21700", intitule : "VILLERS PATRAS", cp : "21400" },
{ code : "21701", intitule : "VILLERS ROTIN", cp : "21130" },
{ code : "21702", intitule : "VILLEY SUR TILLE", cp : "21120" },
{ code : "21703", intitule : "VILLIERS EN MORVAN", cp : "21430" },
{ code : "21704", intitule : "VILLIERS LE DUC", cp : "21400" },
{ code : "21705", intitule : "VILLOTTE ST SEINE", cp : "21690" },
{ code : "21706", intitule : "VILLOTTE SUR OURCE", cp : "21400" },
{ code : "21707", intitule : "VILLY EN AUXOIS", cp : "21350" },
{ code : "21708", intitule : "VILLY LE MOUTIER", cp : "21250" },
{ code : "21709", intitule : "VISERNY", cp : "21500" },
{ code : "21710", intitule : "VITTEAUX", cp : "21350" },
{ code : "21711", intitule : "VIX", cp : "21400" },
{ code : "21712", intitule : "VOLNAY", cp : "21190" },
{ code : "21713", intitule : "VONGES", cp : "21270" },
{ code : "21714", intitule : "VOSNE ROMANEE", cp : "21700" },
{ code : "21715", intitule : "VOUDENAY", cp : "21230" },
{ code : "21716", intitule : "VOUGEOT", cp : "21640" },
{ code : "21717", intitule : "VOULAINES LES TEMPLIERS", cp : "21290" },
{ code : "22001", intitule : "ALLINEUC", cp : "22460" },
{ code : "22002", intitule : "ANDEL", cp : "22400" },
{ code : "22003", intitule : "AUCALEUC", cp : "22100" },
{ code : "22004", intitule : "BEGARD", cp : "22140" },
{ code : "22004", intitule : "BEGARD", cp : "22140" },
{ code : "22004", intitule : "BEGARD", cp : "22140" },
{ code : "22004", intitule : "BEGARD", cp : "22140" },
{ code : "22005", intitule : "BELLE ISLE EN TERRE", cp : "22810" },
{ code : "22005", intitule : "BELLE ISLE EN TERRE", cp : "22810" },
{ code : "22006", intitule : "BERHET", cp : "22140" },
{ code : "22008", intitule : "BOBITAL", cp : "22100" },
{ code : "22009", intitule : "LE BODEO", cp : "22320" },
{ code : "22011", intitule : "BOQUEHO", cp : "22170" },
{ code : "22012", intitule : "LA BOUILLIE", cp : "22240" },
{ code : "22013", intitule : "BOURBRIAC", cp : "22390" },
{ code : "22014", intitule : "BOURSEUL", cp : "22130" },
{ code : "22015", intitule : "BREHAND", cp : "22510" },
{ code : "22016", intitule : "ILE DE BREHAT", cp : "22870" },
{ code : "22018", intitule : "BRELIDY", cp : "22140" },
{ code : "22019", intitule : "BRINGOLO", cp : "22170" },
{ code : "22020", intitule : "BROONS", cp : "22250" },
{ code : "22021", intitule : "BRUSVILY", cp : "22100" },
{ code : "22023", intitule : "BULAT PESTIVIEN", cp : "22160" },
{ code : "22024", intitule : "CALANHEL", cp : "22160" },
{ code : "22025", intitule : "CALLAC", cp : "22160" },
{ code : "22026", intitule : "CALORGUEN", cp : "22100" },
{ code : "22027", intitule : "LE CAMBOUT", cp : "22210" },
{ code : "22028", intitule : "CAMLEZ", cp : "22450" },
{ code : "22029", intitule : "CANIHUEL", cp : "22480" },
{ code : "22030", intitule : "CAOUENNEC LANVEZEAC", cp : "22300" },
{ code : "22030", intitule : "CAOUENNEC LANVEZEAC", cp : "22300" },
{ code : "22031", intitule : "CARNOET", cp : "22160" },
{ code : "22032", intitule : "CAULNES", cp : "22350" },
{ code : "22033", intitule : "CAUREL", cp : "22530" },
{ code : "22034", intitule : "CAVAN", cp : "22140" },
{ code : "22035", intitule : "LES CHAMPS GERAUX", cp : "22630" },
{ code : "22036", intitule : "LA CHAPELLE BLANCHE", cp : "22350" },
{ code : "22037", intitule : "LA CHAPELLE NEUVE", cp : "22160" },
{ code : "22038", intitule : "CHATELAUDREN", cp : "22170" },
{ code : "22039", intitule : "LA CHEZE", cp : "22210" },
{ code : "22040", intitule : "COADOUT", cp : "22970" },
{ code : "22041", intitule : "COATASCORN", cp : "22140" },
{ code : "22042", intitule : "COATREVEN", cp : "22450" },
{ code : "22043", intitule : "COETLOGON", cp : "22210" },
{ code : "22044", intitule : "COETMIEUX", cp : "22400" },
{ code : "22045", intitule : "COHINIAC", cp : "22800" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22046", intitule : "LE MENE", cp : "22330" },
{ code : "22047", intitule : "CORLAY", cp : "22320" },
{ code : "22048", intitule : "CORSEUL", cp : "22130" },
{ code : "22049", intitule : "CREHEN", cp : "22130" },
{ code : "22050", intitule : "DINAN", cp : "22100" },
{ code : "22052", intitule : "DUAULT", cp : "22160" },
{ code : "22053", intitule : "EREAC", cp : "22250" },
{ code : "22054", intitule : "ERQUY", cp : "22430" },
{ code : "22054", intitule : "ERQUY", cp : "22430" },
{ code : "22054", intitule : "ERQUY", cp : "22430" },
{ code : "22055", intitule : "BINIC ETABLES SUR MER", cp : "22680" },
{ code : "22055", intitule : "BINIC ETABLES SUR MER", cp : "22520" },
{ code : "22056", intitule : "EVRAN", cp : "22630" },
{ code : "22057", intitule : "LE FAOUET", cp : "22290" },
{ code : "22059", intitule : "LE FOEIL", cp : "22800" },
{ code : "22060", intitule : "GAUSSON", cp : "22150" },
{ code : "22061", intitule : "GLOMEL", cp : "22110" },
{ code : "22062", intitule : "GOMENE", cp : "22230" },
{ code : "22063", intitule : "GOMMENEC H", cp : "22290" },
{ code : "22064", intitule : "GOUAREC", cp : "22570" },
{ code : "22065", intitule : "GOUDELIN", cp : "22290" },
{ code : "22067", intitule : "GRACES", cp : "22200" },
{ code : "22067", intitule : "GRACES", cp : "22200" },
{ code : "22068", intitule : "GRACE UZEL", cp : "22460" },
{ code : "22069", intitule : "GUENROC", cp : "22350" },
{ code : "22070", intitule : "GUINGAMP", cp : "22200" },
{ code : "22071", intitule : "GUITTE", cp : "22350" },
{ code : "22072", intitule : "GURUNHUEL", cp : "22390" },
{ code : "22073", intitule : "LA HARMOYE", cp : "22320" },
{ code : "22074", intitule : "LE HAUT CORLAY", cp : "22320" },
{ code : "22075", intitule : "HEMONSTOIR", cp : "22600" },
{ code : "22076", intitule : "HENANBIHEN", cp : "22550" },
{ code : "22077", intitule : "HENANSAL", cp : "22400" },
{ code : "22078", intitule : "HENGOAT", cp : "22450" },
{ code : "22079", intitule : "HENON", cp : "22150" },
{ code : "22081", intitule : "HILLION", cp : "22120" },
{ code : "22081", intitule : "HILLION", cp : "22120" },
{ code : "22082", intitule : "LE HINGLE", cp : "22100" },
{ code : "22083", intitule : "ILLIFAUT", cp : "22230" },
{ code : "22084", intitule : "JUGON LES LACS COMMUNE NOUVELLE", cp : "22270" },
{ code : "22084", intitule : "JUGON LES LACS COMMUNE NOUVELLE", cp : "22270" },
{ code : "22084", intitule : "JUGON LES LACS COMMUNE NOUVELLE", cp : "22270" },
{ code : "22084", intitule : "JUGON LES LACS COMMUNE NOUVELLE", cp : "22270" },
{ code : "22085", intitule : "KERBORS", cp : "22610" },
{ code : "22086", intitule : "KERFOT", cp : "22500" },
{ code : "22087", intitule : "KERGRIST MOELOU", cp : "22110" },
{ code : "22088", intitule : "KERIEN", cp : "22480" },
{ code : "22090", intitule : "KERMARIA SULARD", cp : "22450" },
{ code : "22091", intitule : "KERMOROC H", cp : "22140" },
{ code : "22092", intitule : "KERPERT", cp : "22480" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22093", intitule : "LAMBALLE", cp : "22400" },
{ code : "22094", intitule : "LANCIEUX", cp : "22770" },
{ code : "22095", intitule : "LANDEBAERON", cp : "22140" },
{ code : "22096", intitule : "LANDEBIA", cp : "22130" },
{ code : "22097", intitule : "LA LANDEC", cp : "22980" },
{ code : "22098", intitule : "LANDEHEN", cp : "22400" },
{ code : "22099", intitule : "LANFAINS", cp : "22800" },
{ code : "22100", intitule : "LANGAST", cp : "22150" },
{ code : "22101", intitule : "LANGOAT", cp : "22450" },
{ code : "22103", intitule : "LANGROLAY SUR RANCE", cp : "22490" },
{ code : "22104", intitule : "LANGUEDIAS", cp : "22980" },
{ code : "22105", intitule : "LANGUENAN", cp : "22130" },
{ code : "22106", intitule : "LANGUEUX", cp : "22360" },
{ code : "22106", intitule : "LANGUEUX", cp : "22360" },
{ code : "22107", intitule : "LANISCAT", cp : "22570" },
{ code : "22108", intitule : "LANLEFF", cp : "22290" },
{ code : "22109", intitule : "LANLOUP", cp : "22580" },
{ code : "22110", intitule : "LANMERIN", cp : "22300" },
{ code : "22111", intitule : "LANMODEZ", cp : "22610" },
{ code : "22112", intitule : "LANNEBERT", cp : "22290" },
{ code : "22113", intitule : "LANNION", cp : "22300" },
{ code : "22113", intitule : "LANNION", cp : "22300" },
{ code : "22114", intitule : "LANRELAS", cp : "22250" },
{ code : "22115", intitule : "LANRIVAIN", cp : "22480" },
{ code : "22116", intitule : "LANRODEC", cp : "22170" },
{ code : "22117", intitule : "LANTIC", cp : "22410" },
{ code : "22117", intitule : "LANTIC", cp : "22410" },
{ code : "22118", intitule : "LANVALLAY", cp : "22100" },
{ code : "22118", intitule : "LANVALLAY", cp : "22100" },
{ code : "22118", intitule : "LANVALLAY", cp : "22100" },
{ code : "22119", intitule : "LANVELLEC", cp : "22420" },
{ code : "22121", intitule : "LANVOLLON", cp : "22290" },
{ code : "22122", intitule : "LAURENAN", cp : "22230" },
{ code : "22123", intitule : "LEHON", cp : "22100" },
{ code : "22124", intitule : "LESCOUET GOUAREC", cp : "22570" },
{ code : "22126", intitule : "LE LESLAY", cp : "22800" },
{ code : "22127", intitule : "LEZARDRIEUX", cp : "22740" },
{ code : "22127", intitule : "LEZARDRIEUX", cp : "22740" },
{ code : "22128", intitule : "LOCARN", cp : "22340" },
{ code : "22129", intitule : "LOC ENVEL", cp : "22810" },
{ code : "22131", intitule : "LOGUIVY PLOUGRAS", cp : "22780" },
{ code : "22132", intitule : "LOHUEC", cp : "22160" },
{ code : "22133", intitule : "LOSCOUET SUR MEU", cp : "22230" },
{ code : "22134", intitule : "LOUANNEC", cp : "22700" },
{ code : "22135", intitule : "LOUARGAT", cp : "22540" },
{ code : "22135", intitule : "LOUARGAT", cp : "22540" },
{ code : "22136", intitule : "LOUDEAC", cp : "22600" },
{ code : "22137", intitule : "MAEL CARHAIX", cp : "22340" },
{ code : "22138", intitule : "MAEL PESTIVIEN", cp : "22160" },
{ code : "22139", intitule : "MAGOAR", cp : "22480" },
{ code : "22140", intitule : "LA MALHOURE", cp : "22640" },
{ code : "22141", intitule : "MANTALLOT", cp : "22450" },
{ code : "22143", intitule : "MATIGNON", cp : "22550" },
{ code : "22144", intitule : "LA MEAUGON", cp : "22440" },
{ code : "22145", intitule : "MEGRIT", cp : "22270" },
{ code : "22146", intitule : "MELLIONNEC", cp : "22110" },
{ code : "22147", intitule : "MERDRIGNAC", cp : "22230" },
{ code : "22148", intitule : "MERILLAC", cp : "22230" },
{ code : "22149", intitule : "MERLEAC", cp : "22460" },
{ code : "22150", intitule : "LE MERZER", cp : "22200" },
{ code : "22152", intitule : "MINIHY TREGUIER", cp : "22220" },
{ code : "22153", intitule : "MONCONTOUR", cp : "22510" },
{ code : "22154", intitule : "MORIEUX", cp : "22400" },
{ code : "22155", intitule : "LA MOTTE", cp : "22600" },
{ code : "22156", intitule : "MOUSTERU", cp : "22200" },
{ code : "22157", intitule : "LE MOUSTOIR", cp : "22340" },
{ code : "22158", intitule : "MUR DE BRETAGNE", cp : "22530" },
{ code : "22160", intitule : "NOYAL", cp : "22400" },
{ code : "22161", intitule : "PABU", cp : "22200" },
{ code : "22162", intitule : "PAIMPOL", cp : "22500" },
{ code : "22163", intitule : "PAULE", cp : "22340" },
{ code : "22164", intitule : "PEDERNEC", cp : "22540" },
{ code : "22164", intitule : "PEDERNEC", cp : "22540" },
{ code : "22165", intitule : "PENGUILY", cp : "22510" },
{ code : "22166", intitule : "PENVENAN", cp : "22710" },
{ code : "22166", intitule : "PENVENAN", cp : "22710" },
{ code : "22166", intitule : "PENVENAN", cp : "22710" },
{ code : "22167", intitule : "PERRET", cp : "22570" },
{ code : "22168", intitule : "PERROS GUIREC", cp : "22700" },
{ code : "22168", intitule : "PERROS GUIREC", cp : "22700" },
{ code : "22168", intitule : "PERROS GUIREC", cp : "22700" },
{ code : "22169", intitule : "PEUMERIT QUINTIN", cp : "22480" },
{ code : "22170", intitule : "PLAINE HAUTE", cp : "22800" },
{ code : "22171", intitule : "PLAINTEL", cp : "22940" },
{ code : "22172", intitule : "PLANCOET", cp : "22130" },
{ code : "22173", intitule : "PLANGUENOUAL", cp : "22400" },
{ code : "22173", intitule : "PLANGUENOUAL", cp : "22400" },
{ code : "22174", intitule : "PLEBOULLE", cp : "22550" },
{ code : "22175", intitule : "PLEDELIAC", cp : "22270" },
{ code : "22176", intitule : "PLEDRAN", cp : "22960" },
{ code : "22177", intitule : "PLEGUIEN", cp : "22290" },
{ code : "22178", intitule : "PLEHEDEL", cp : "22290" },
{ code : "22179", intitule : "FREHEL", cp : "22240" },
{ code : "22179", intitule : "FREHEL", cp : "22240" },
{ code : "22179", intitule : "FREHEL", cp : "22240" },
{ code : "22180", intitule : "PLELAN LE PETIT", cp : "22980" },
{ code : "22181", intitule : "PLELAUFF", cp : "22570" },
{ code : "22182", intitule : "PLELO", cp : "22170" },
{ code : "22182", intitule : "PLELO", cp : "22170" },
{ code : "22183", intitule : "LES MOULINS", cp : "22210" },
{ code : "22183", intitule : "LES MOULINS", cp : "22210" },
{ code : "22184", intitule : "PLEMY", cp : "22150" },
{ code : "22185", intitule : "PLENEE JUGON", cp : "22640" },
{ code : "22186", intitule : "PLENEUF VAL ANDRE", cp : "22370" },
{ code : "22187", intitule : "PLERIN", cp : "22190" },
{ code : "22187", intitule : "PLERIN", cp : "22190" },
{ code : "22187", intitule : "PLERIN", cp : "22190" },
{ code : "22188", intitule : "PLERNEUF", cp : "22170" },
{ code : "22189", intitule : "PLESIDY", cp : "22720" },
{ code : "22190", intitule : "PLESLIN TRIGAVOU", cp : "22490" },
{ code : "22190", intitule : "PLESLIN TRIGAVOU", cp : "22490" },
{ code : "22192", intitule : "PLESSIX BALISSON", cp : "22650" },
{ code : "22193", intitule : "PLESTAN", cp : "22640" },
{ code : "22194", intitule : "PLESTIN LES GREVES", cp : "22310" },
{ code : "22194", intitule : "PLESTIN LES GREVES", cp : "22310" },
{ code : "22195", intitule : "PLEUBIAN", cp : "22610" },
{ code : "22195", intitule : "PLEUBIAN", cp : "22610" },
{ code : "22196", intitule : "PLEUDANIEL", cp : "22740" },
{ code : "22197", intitule : "PLEUDIHEN SUR RANCE", cp : "22690" },
{ code : "22198", intitule : "PLEUMEUR BODOU", cp : "22560" },
{ code : "22198", intitule : "PLEUMEUR BODOU", cp : "22560" },
{ code : "22198", intitule : "PLEUMEUR BODOU", cp : "22560" },
{ code : "22199", intitule : "PLEUMEUR GAUTIER", cp : "22740" },
{ code : "22200", intitule : "PLEVEN", cp : "22130" },
{ code : "22201", intitule : "PLEVENON", cp : "22240" },
{ code : "22202", intitule : "PLEVIN", cp : "22340" },
{ code : "22203", intitule : "PLOEUC L HERMITAGE", cp : "22150" },
{ code : "22203", intitule : "PLOEUC L HERMITAGE", cp : "22150" },
{ code : "22204", intitule : "PLOEZAL", cp : "22260" },
{ code : "22205", intitule : "PLOREC SUR ARGUENON", cp : "22130" },
{ code : "22206", intitule : "PLOUAGAT", cp : "22170" },
{ code : "22207", intitule : "PLOUARET", cp : "22420" },
{ code : "22208", intitule : "PLOUASNE", cp : "22830" },
{ code : "22209", intitule : "PLOUBALAY", cp : "22650" },
{ code : "22210", intitule : "PLOUBAZLANEC", cp : "22620" },
{ code : "22210", intitule : "PLOUBAZLANEC", cp : "22620" },
{ code : "22210", intitule : "PLOUBAZLANEC", cp : "22620" },
{ code : "22211", intitule : "PLOUBEZRE", cp : "22300" },
{ code : "22212", intitule : "PLOUEC DU TRIEUX", cp : "22260" },
{ code : "22213", intitule : "PLOUER SUR RANCE", cp : "22490" },
{ code : "22214", intitule : "PLOUEZEC", cp : "22470" },
{ code : "22214", intitule : "PLOUEZEC", cp : "22470" },
{ code : "22215", intitule : "PLOUFRAGAN", cp : "22440" },
{ code : "22215", intitule : "PLOUFRAGAN", cp : "22440" },
{ code : "22216", intitule : "PLOUGONVER", cp : "22810" },
{ code : "22217", intitule : "PLOUGRAS", cp : "22780" },
{ code : "22218", intitule : "PLOUGRESCANT", cp : "22820" },
{ code : "22219", intitule : "PLOUGUENAST", cp : "22150" },
{ code : "22220", intitule : "PLOUGUERNEVEL", cp : "22110" },
{ code : "22221", intitule : "PLOUGUIEL", cp : "22220" },
{ code : "22222", intitule : "PLOUHA", cp : "22580" },
{ code : "22222", intitule : "PLOUHA", cp : "22580" },
{ code : "22223", intitule : "PLOUISY", cp : "22200" },
{ code : "22224", intitule : "PLOULEC H", cp : "22300" },
{ code : "22224", intitule : "PLOULEC H", cp : "22300" },
{ code : "22225", intitule : "PLOUMAGOAR", cp : "22970" },
{ code : "22226", intitule : "PLOUMILLIAU", cp : "22300" },
{ code : "22227", intitule : "PLOUNERIN", cp : "22780" },
{ code : "22228", intitule : "PLOUNEVEZ MOEDEC", cp : "22810" },
{ code : "22229", intitule : "PLOUNEVEZ QUINTIN", cp : "22110" },
{ code : "22231", intitule : "PLOURAC H", cp : "22160" },
{ code : "22232", intitule : "PLOURHAN", cp : "22410" },
{ code : "22233", intitule : "PLOURIVO", cp : "22860" },
{ code : "22234", intitule : "PLOUVARA", cp : "22170" },
{ code : "22235", intitule : "PLOUZELAMBRE", cp : "22420" },
{ code : "22236", intitule : "PLUDUAL", cp : "22290" },
{ code : "22237", intitule : "PLUDUNO", cp : "22130" },
{ code : "22238", intitule : "PLUFUR", cp : "22310" },
{ code : "22239", intitule : "PLUMAUDAN", cp : "22350" },
{ code : "22240", intitule : "PLUMAUGAT", cp : "22250" },
{ code : "22241", intitule : "PLUMIEUX", cp : "22210" },
{ code : "22242", intitule : "PLURIEN", cp : "22240" },
{ code : "22243", intitule : "PLUSQUELLEC", cp : "22160" },
{ code : "22244", intitule : "PLUSSULIEN", cp : "22320" },
{ code : "22245", intitule : "PLUZUNET", cp : "22140" },
{ code : "22246", intitule : "POMMERET", cp : "22120" },
{ code : "22247", intitule : "POMMERIT JAUDY", cp : "22450" },
{ code : "22248", intitule : "POMMERIT LE VICOMTE", cp : "22200" },
{ code : "22249", intitule : "PONT MELVEZ", cp : "22390" },
{ code : "22250", intitule : "PONTRIEUX", cp : "22260" },
{ code : "22251", intitule : "PORDIC", cp : "22590" },
{ code : "22251", intitule : "PORDIC", cp : "22590" },
{ code : "22251", intitule : "PORDIC", cp : "22590" },
{ code : "22253", intitule : "POULDOURAN", cp : "22450" },
{ code : "22254", intitule : "PRAT", cp : "22140" },
{ code : "22255", intitule : "LA PRENESSAYE", cp : "22210" },
{ code : "22256", intitule : "QUEMPER GUEZENNEC", cp : "22260" },
{ code : "22257", intitule : "QUEMPERVEN", cp : "22450" },
{ code : "22258", intitule : "QUESSOY", cp : "22120" },
{ code : "22258", intitule : "QUESSOY", cp : "22120" },
{ code : "22259", intitule : "QUEVERT", cp : "22100" },
{ code : "22259", intitule : "QUEVERT", cp : "22100" },
{ code : "22260", intitule : "LE QUILLIO", cp : "22460" },
{ code : "22261", intitule : "QUINTENIC", cp : "22400" },
{ code : "22262", intitule : "QUINTIN", cp : "22800" },
{ code : "22263", intitule : "LE QUIOU", cp : "22630" },
{ code : "22264", intitule : "LA ROCHE DERRIEN", cp : "22450" },
{ code : "22265", intitule : "ROSPEZ", cp : "22300" },
{ code : "22266", intitule : "ROSTRENEN", cp : "22110" },
{ code : "22266", intitule : "ROSTRENEN", cp : "22110" },
{ code : "22267", intitule : "ROUILLAC", cp : "22250" },
{ code : "22268", intitule : "RUCA", cp : "22550" },
{ code : "22269", intitule : "RUNAN", cp : "22260" },
{ code : "22271", intitule : "ST ADRIEN", cp : "22390" },
{ code : "22272", intitule : "ST AGATHON", cp : "22200" },
{ code : "22273", intitule : "ST ALBAN", cp : "22400" },
{ code : "22274", intitule : "ST ANDRE DES EAUX", cp : "22630" },
{ code : "22275", intitule : "ST BARNABE", cp : "22600" },
{ code : "22276", intitule : "ST BIHY", cp : "22800" },
{ code : "22277", intitule : "ST BRANDAN", cp : "22800" },
{ code : "22278", intitule : "ST BRIEUC", cp : "22000" },
{ code : "22279", intitule : "ST CARADEC", cp : "22600" },
{ code : "22280", intitule : "ST CARNE", cp : "22100" },
{ code : "22281", intitule : "ST CARREUC", cp : "22150" },
{ code : "22282", intitule : "ST CAST LE GUILDO", cp : "22380" },
{ code : "22282", intitule : "ST CAST LE GUILDO", cp : "22380" },
{ code : "22283", intitule : "ST CLET", cp : "22260" },
{ code : "22284", intitule : "ST CONNAN", cp : "22480" },
{ code : "22285", intitule : "ST CONNEC", cp : "22530" },
{ code : "22286", intitule : "ST DENOUAL", cp : "22400" },
{ code : "22287", intitule : "ST DONAN", cp : "22800" },
{ code : "22288", intitule : "ST ETIENNE DU GUE DE L ISLE", cp : "22210" },
{ code : "22289", intitule : "ST FIACRE", cp : "22720" },
{ code : "22290", intitule : "ST GELVEN", cp : "22570" },
{ code : "22291", intitule : "ST GILDAS", cp : "22800" },
{ code : "22293", intitule : "ST GILLES LES BOIS", cp : "22290" },
{ code : "22294", intitule : "ST GILLES PLIGEAUX", cp : "22480" },
{ code : "22295", intitule : "ST GILLES VIEUX MARCHE", cp : "22530" },
{ code : "22296", intitule : "ST GLEN", cp : "22510" },
{ code : "22298", intitule : "ST GUEN", cp : "22530" },
{ code : "22299", intitule : "ST HELEN", cp : "22100" },
{ code : "22300", intitule : "ST HERVE", cp : "22460" },
{ code : "22302", intitule : "ST JACUT DE LA MER", cp : "22750" },
{ code : "22304", intitule : "ST JEAN KERDANIEL", cp : "22170" },
{ code : "22305", intitule : "ST JOUAN DE L ISLE", cp : "22350" },
{ code : "22306", intitule : "ST JUDOCE", cp : "22630" },
{ code : "22307", intitule : "ST JULIEN", cp : "22940" },
{ code : "22308", intitule : "ST JUVAT", cp : "22630" },
{ code : "22309", intitule : "ST LAUNEUC", cp : "22230" },
{ code : "22310", intitule : "ST LAURENT", cp : "22140" },
{ code : "22311", intitule : "ST LORMEL", cp : "22130" },
{ code : "22312", intitule : "ST MADEN", cp : "22350" },
{ code : "22313", intitule : "ST MARTIN DES PRES", cp : "22320" },
{ code : "22314", intitule : "ST MAUDAN", cp : "22600" },
{ code : "22315", intitule : "ST MAUDEZ", cp : "22980" },
{ code : "22316", intitule : "ST MAYEUX", cp : "22320" },
{ code : "22317", intitule : "ST MELOIR DES BOIS", cp : "22980" },
{ code : "22318", intitule : "ST MICHEL DE PLELAN", cp : "22980" },
{ code : "22319", intitule : "ST MICHEL EN GREVE", cp : "22300" },
{ code : "22320", intitule : "ST NICODEME", cp : "22160" },
{ code : "22321", intitule : "ST NICOLAS DU PELEM", cp : "22480" },
{ code : "22322", intitule : "ST PEVER", cp : "22720" },
{ code : "22323", intitule : "ST POTAN", cp : "22550" },
{ code : "22324", intitule : "ST QUAY PERROS", cp : "22700" },
{ code : "22325", intitule : "ST QUAY PORTRIEUX", cp : "22410" },
{ code : "22325", intitule : "ST QUAY PORTRIEUX", cp : "22410" },
{ code : "22326", intitule : "ST RIEUL", cp : "22270" },
{ code : "22327", intitule : "ST SAMSON SUR RANCE", cp : "22100" },
{ code : "22327", intitule : "ST SAMSON SUR RANCE", cp : "22100" },
{ code : "22328", intitule : "ST SERVAIS", cp : "22160" },
{ code : "22330", intitule : "ST THELO", cp : "22460" },
{ code : "22331", intitule : "STE TREPHINE", cp : "22480" },
{ code : "22332", intitule : "ST TRIMOEL", cp : "22510" },
{ code : "22333", intitule : "ST VRAN", cp : "22230" },
{ code : "22334", intitule : "ST IGEAUX", cp : "22570" },
{ code : "22335", intitule : "SENVEN LEHART", cp : "22720" },
{ code : "22337", intitule : "SEVIGNAC", cp : "22250" },
{ code : "22338", intitule : "SQUIFFIEC", cp : "22200" },
{ code : "22339", intitule : "TADEN", cp : "22100" },
{ code : "22340", intitule : "TONQUEDEC", cp : "22140" },
{ code : "22341", intitule : "TRAMAIN", cp : "22640" },
{ code : "22342", intitule : "TREBEDAN", cp : "22980" },
{ code : "22343", intitule : "TREBEURDEN", cp : "22560" },
{ code : "22344", intitule : "TREBRIVAN", cp : "22340" },
{ code : "22345", intitule : "TREBRY", cp : "22510" },
{ code : "22346", intitule : "TREDANIEL", cp : "22510" },
{ code : "22347", intitule : "TREDARZEC", cp : "22220" },
{ code : "22348", intitule : "TREDIAS", cp : "22250" },
{ code : "22349", intitule : "TREDREZ LOCQUEMEAU", cp : "22300" },
{ code : "22349", intitule : "TREDREZ LOCQUEMEAU", cp : "22300" },
{ code : "22350", intitule : "TREDUDER", cp : "22310" },
{ code : "22351", intitule : "TREFFRIN", cp : "22340" },
{ code : "22352", intitule : "TREFUMEL", cp : "22630" },
{ code : "22353", intitule : "TREGASTEL", cp : "22730" },
{ code : "22354", intitule : "TREGLAMUS", cp : "22540" },
{ code : "22356", intitule : "TREGOMEUR", cp : "22590" },
{ code : "22357", intitule : "TREGON", cp : "22650" },
{ code : "22358", intitule : "TREGONNEAU", cp : "22200" },
{ code : "22359", intitule : "TREGROM", cp : "22420" },
{ code : "22360", intitule : "TREGUEUX", cp : "22950" },
{ code : "22360", intitule : "TREGUEUX", cp : "22950" },
{ code : "22361", intitule : "TREGUIDEL", cp : "22290" },
{ code : "22362", intitule : "TREGUIER", cp : "22220" },
{ code : "22363", intitule : "TRELEVERN", cp : "22660" },
{ code : "22364", intitule : "TRELIVAN", cp : "22100" },
{ code : "22365", intitule : "TREMARGAT", cp : "22110" },
{ code : "22366", intitule : "TREMEL", cp : "22310" },
{ code : "22368", intitule : "TREMEREUC", cp : "22490" },
{ code : "22369", intitule : "TREMEUR", cp : "22250" },
{ code : "22370", intitule : "TREMEVEN", cp : "22290" },
{ code : "22371", intitule : "TREMOREL", cp : "22230" },
{ code : "22372", intitule : "TREMUSON", cp : "22440" },
{ code : "22373", intitule : "TREOGAN", cp : "22340" },
{ code : "22375", intitule : "TRESSIGNAUX", cp : "22290" },
{ code : "22376", intitule : "TREVE", cp : "22600" },
{ code : "22377", intitule : "TREVENEUC", cp : "22410" },
{ code : "22378", intitule : "TREVEREC", cp : "22290" },
{ code : "22379", intitule : "TREVOU TREGUIGNEC", cp : "22660" },
{ code : "22380", intitule : "TREVRON", cp : "22100" },
{ code : "22381", intitule : "TREZENY", cp : "22450" },
{ code : "22383", intitule : "TROGUERY", cp : "22450" },
{ code : "22384", intitule : "UZEL", cp : "22460" },
{ code : "22385", intitule : "LA VICOMTE SUR RANCE", cp : "22690" },
{ code : "22386", intitule : "LE VIEUX BOURG", cp : "22800" },
{ code : "22387", intitule : "LE VIEUX MARCHE", cp : "22420" },
{ code : "22388", intitule : "VILDE GUINGALAN", cp : "22980" },
{ code : "22389", intitule : "YFFINIAC", cp : "22120" },
{ code : "22390", intitule : "YVIAS", cp : "22930" },
{ code : "22391", intitule : "YVIGNAC LA TOUR", cp : "22350" },
{ code : "23001", intitule : "AHUN", cp : "23150" },
{ code : "23002", intitule : "AJAIN", cp : "23380" },
{ code : "23003", intitule : "ALLEYRAT", cp : "23200" },
{ code : "23004", intitule : "ANZEME", cp : "23000" },
{ code : "23005", intitule : "ARFEUILLE CHATAIN", cp : "23700" },
{ code : "23006", intitule : "ARRENES", cp : "23210" },
{ code : "23007", intitule : "ARS", cp : "23480" },
{ code : "23008", intitule : "AUBUSSON", cp : "23200" },
{ code : "23009", intitule : "AUGE", cp : "23170" },
{ code : "23010", intitule : "AUGERES", cp : "23210" },
{ code : "23011", intitule : "AULON", cp : "23210" },
{ code : "23012", intitule : "AURIAT", cp : "23400" },
{ code : "23013", intitule : "AUZANCES", cp : "23700" },
{ code : "23014", intitule : "AZAT CHATENET", cp : "23210" },
{ code : "23015", intitule : "AZERABLES", cp : "23160" },
{ code : "23016", intitule : "BANIZE", cp : "23120" },
{ code : "23017", intitule : "BASVILLE", cp : "23260" },
{ code : "23018", intitule : "BAZELAT", cp : "23160" },
{ code : "23019", intitule : "BEISSAT", cp : "23260" },
{ code : "23020", intitule : "BELLEGARDE EN MARCHE", cp : "23190" },
{ code : "23021", intitule : "BENEVENT L ABBAYE", cp : "23210" },
{ code : "23022", intitule : "BETETE", cp : "23270" },
{ code : "23023", intitule : "BLAUDEIX", cp : "23140" },
{ code : "23024", intitule : "BLESSAC", cp : "23200" },
{ code : "23025", intitule : "BONNAT", cp : "23220" },
{ code : "23026", intitule : "BORD ST GEORGES", cp : "23230" },
{ code : "23027", intitule : "BOSMOREAU LES MINES", cp : "23400" },
{ code : "23028", intitule : "BOSROGER", cp : "23200" },
{ code : "23029", intitule : "LE BOURG D HEM", cp : "23220" },
{ code : "23030", intitule : "BOURGANEUF", cp : "23400" },
{ code : "23031", intitule : "BOUSSAC", cp : "23600" },
{ code : "23032", intitule : "BOUSSAC BOURG", cp : "23600" },
{ code : "23033", intitule : "LA BRIONNE", cp : "23000" },
{ code : "23034", intitule : "BROUSSE", cp : "23700" },
{ code : "23035", intitule : "BUDELIERE", cp : "23170" },
{ code : "23036", intitule : "BUSSIERE DUNOISE", cp : "23320" },
{ code : "23037", intitule : "BUSSIERE NOUVELLE", cp : "23700" },
{ code : "23038", intitule : "BUSSIERE ST GEORGES", cp : "23600" },
{ code : "23039", intitule : "LA CELLE DUNOISE", cp : "23800" },
{ code : "23040", intitule : "LA CELLE SOUS GOUZON", cp : "23230" },
{ code : "23041", intitule : "LA CELLETTE", cp : "23350" },
{ code : "23042", intitule : "CEYROUX", cp : "23210" },
{ code : "23043", intitule : "CHAMBERAUD", cp : "23480" },
{ code : "23044", intitule : "CHAMBON STE CROIX", cp : "23220" },
{ code : "23045", intitule : "CHAMBON SUR VOUEIZE", cp : "23170" },
{ code : "23046", intitule : "CHAMBONCHARD", cp : "23110" },
{ code : "23047", intitule : "CHAMBORAND", cp : "23240" },
{ code : "23048", intitule : "CHAMPAGNAT", cp : "23190" },
{ code : "23049", intitule : "CHAMPSANGLARD", cp : "23220" },
{ code : "23050", intitule : "LA CHAPELLE BALOUE", cp : "23160" },
{ code : "23051", intitule : "LA CHAPELLE ST MARTIAL", cp : "23250" },
{ code : "23052", intitule : "LA CHAPELLE TAILLEFERT", cp : "23000" },
{ code : "23053", intitule : "CHARD", cp : "23700" },
{ code : "23054", intitule : "CHARRON", cp : "23700" },
{ code : "23055", intitule : "CHATELARD", cp : "23700" },
{ code : "23056", intitule : "CHATELUS LE MARCHEIX", cp : "23430" },
{ code : "23057", intitule : "CHATELUS MALVALEIX", cp : "23270" },
{ code : "23058", intitule : "LE CHAUCHET", cp : "23130" },
{ code : "23059", intitule : "LA CHAUSSADE", cp : "23200" },
{ code : "23060", intitule : "CHAVANAT", cp : "23250" },
{ code : "23061", intitule : "CHENERAILLES", cp : "23130" },
{ code : "23062", intitule : "CHENIERS", cp : "23220" },
{ code : "23063", intitule : "CLAIRAVAUX", cp : "23500" },
{ code : "23064", intitule : "CLUGNAT", cp : "23270" },
{ code : "23065", intitule : "COLONDANNES", cp : "23800" },
{ code : "23066", intitule : "LE COMPAS", cp : "23700" },
{ code : "23067", intitule : "LA COURTINE", cp : "23100" },
{ code : "23068", intitule : "CRESSAT", cp : "23140" },
{ code : "23069", intitule : "CROCQ", cp : "23260" },
{ code : "23070", intitule : "CROZANT", cp : "23160" },
{ code : "23071", intitule : "CROZE", cp : "23500" },
{ code : "23072", intitule : "DOMEYROT", cp : "23140" },
{ code : "23073", intitule : "DONTREIX", cp : "23700" },
{ code : "23074", intitule : "LE DONZEIL", cp : "23480" },
{ code : "23075", intitule : "DUN LE PALESTEL", cp : "23800" },
{ code : "23076", intitule : "EVAUX LES BAINS", cp : "23110" },
{ code : "23077", intitule : "FAUX LA MONTAGNE", cp : "23340" },
{ code : "23078", intitule : "FAUX MAZURAS", cp : "23400" },
{ code : "23079", intitule : "FELLETIN", cp : "23500" },
{ code : "23080", intitule : "FENIERS", cp : "23100" },
{ code : "23081", intitule : "FLAYAT", cp : "23260" },
{ code : "23082", intitule : "FLEURAT", cp : "23320" },
{ code : "23083", intitule : "FONTANIERES", cp : "23110" },
{ code : "23084", intitule : "LA FORET DU TEMPLE", cp : "23360" },
{ code : "23086", intitule : "FRANSECHES", cp : "23480" },
{ code : "23087", intitule : "FRESSELINES", cp : "23450" },
{ code : "23088", intitule : "GARTEMPE", cp : "23320" },
{ code : "23089", intitule : "GENOUILLAC", cp : "23350" },
{ code : "23090", intitule : "GENTIOUX PIGEROLLES", cp : "23340" },
{ code : "23090", intitule : "GENTIOUX PIGEROLLES", cp : "23340" },
{ code : "23091", intitule : "GIOUX", cp : "23500" },
{ code : "23092", intitule : "GLENIC", cp : "23380" },
{ code : "23093", intitule : "GOUZON", cp : "23230" },
{ code : "23093", intitule : "GOUZON", cp : "23230" },
{ code : "23093", intitule : "GOUZON", cp : "23230" },
{ code : "23095", intitule : "LE GRAND BOURG", cp : "23240" },
{ code : "23096", intitule : "GUERET", cp : "23000" },
{ code : "23097", intitule : "ISSOUDUN LETRIEIX", cp : "23130" },
{ code : "23098", intitule : "JALESCHES", cp : "23270" },
{ code : "23099", intitule : "JANAILLAT", cp : "23250" },
{ code : "23100", intitule : "JARNAGES", cp : "23140" },
{ code : "23101", intitule : "JOUILLAT", cp : "23220" },
{ code : "23102", intitule : "LADAPEYRE", cp : "23270" },
{ code : "23103", intitule : "LAFAT", cp : "23800" },
{ code : "23104", intitule : "LAVAUFRANCHE", cp : "23600" },
{ code : "23105", intitule : "LAVAVEIX LES MINES", cp : "23150" },
{ code : "23106", intitule : "LEPAUD", cp : "23170" },
{ code : "23107", intitule : "LEPINAS", cp : "23150" },
{ code : "23108", intitule : "LEYRAT", cp : "23600" },
{ code : "23109", intitule : "LINARD", cp : "23220" },
{ code : "23110", intitule : "LIOUX LES MONGES", cp : "23700" },
{ code : "23111", intitule : "LIZIERES", cp : "23240" },
{ code : "23112", intitule : "LOURDOUEIX ST PIERRE", cp : "23360" },
{ code : "23112", intitule : "LOURDOUEIX ST PIERRE", cp : "23360" },
{ code : "23113", intitule : "LUPERSAT", cp : "23190" },
{ code : "23114", intitule : "LUSSAT", cp : "23170" },
{ code : "23115", intitule : "MAGNAT L ETRANGE", cp : "23260" },
{ code : "23116", intitule : "MAINSAT", cp : "23700" },
{ code : "23117", intitule : "MAISON FEYNE", cp : "23800" },
{ code : "23118", intitule : "MAISONNISSES", cp : "23150" },
{ code : "23119", intitule : "MALLERET", cp : "23260" },
{ code : "23120", intitule : "MALLERET BOUSSAC", cp : "23600" },
{ code : "23121", intitule : "MALVAL", cp : "23220" },
{ code : "23122", intitule : "MANSAT LA COURRIERE", cp : "23400" },
{ code : "23123", intitule : "LES MARS", cp : "23700" },
{ code : "23124", intitule : "MARSAC", cp : "23210" },
{ code : "23125", intitule : "LE MAS D ARTIGE", cp : "23100" },
{ code : "23126", intitule : "MASBARAUD MERIGNAT", cp : "23400" },
{ code : "23127", intitule : "MAUTES", cp : "23190" },
{ code : "23128", intitule : "MAZEIRAT", cp : "23150" },
{ code : "23129", intitule : "LA MAZIERE AUX BONS HOMMES", cp : "23260" },
{ code : "23130", intitule : "MEASNES", cp : "23360" },
{ code : "23131", intitule : "MERINCHAL", cp : "23420" },
{ code : "23132", intitule : "MONTAIGUT LE BLANC", cp : "23320" },
{ code : "23133", intitule : "MONTBOUCHER", cp : "23400" },
{ code : "23134", intitule : "LE MONTEIL AU VICOMTE", cp : "23460" },
{ code : "23136", intitule : "MORTROUX", cp : "23220" },
{ code : "23137", intitule : "MOURIOUX VIEILLEVILLE", cp : "23210" },
{ code : "23138", intitule : "MOUTIER D AHUN", cp : "23150" },
{ code : "23139", intitule : "MOUTIER MALCARD", cp : "23220" },
{ code : "23140", intitule : "MOUTIER ROZEILLE", cp : "23200" },
{ code : "23141", intitule : "NAILLAT", cp : "23800" },
{ code : "23142", intitule : "NEOUX", cp : "23200" },
{ code : "23143", intitule : "NOTH", cp : "23300" },
{ code : "23144", intitule : "LA NOUAILLE", cp : "23500" },
{ code : "23145", intitule : "NOUHANT", cp : "23170" },
{ code : "23146", intitule : "NOUZERINES", cp : "23600" },
{ code : "23147", intitule : "NOUZEROLLES", cp : "23360" },
{ code : "23148", intitule : "NOUZIERS", cp : "23350" },
{ code : "23149", intitule : "PARSAC RIMONDEIX", cp : "23140" },
{ code : "23149", intitule : "PARSAC RIMONDEIX", cp : "23140" },
{ code : "23150", intitule : "PEYRABOUT", cp : "23000" },
{ code : "23151", intitule : "PEYRAT LA NONIERE", cp : "23130" },
{ code : "23152", intitule : "PIERREFITTE", cp : "23130" },
{ code : "23154", intitule : "PIONNAT", cp : "23140" },
{ code : "23155", intitule : "PONTARION", cp : "23250" },
{ code : "23156", intitule : "PONTCHARRAUD", cp : "23260" },
{ code : "23157", intitule : "LA POUGE", cp : "23250" },
{ code : "23158", intitule : "POUSSANGES", cp : "23500" },
{ code : "23159", intitule : "PUY MALSIGNAT", cp : "23130" },
{ code : "23160", intitule : "RETERRE", cp : "23110" },
{ code : "23162", intitule : "ROCHES", cp : "23270" },
{ code : "23164", intitule : "ROUGNAT", cp : "23700" },
{ code : "23165", intitule : "ROYERE DE VASSIVIERE", cp : "23460" },
{ code : "23166", intitule : "SAGNAT", cp : "23800" },
{ code : "23167", intitule : "SANNAT", cp : "23110" },
{ code : "23168", intitule : "SARDENT", cp : "23250" },
{ code : "23169", intitule : "LA SAUNIERE", cp : "23000" },
{ code : "23170", intitule : "SAVENNES", cp : "23000" },
{ code : "23171", intitule : "SERMUR", cp : "23700" },
{ code : "23172", intitule : "LA SERRE BUSSIERE VIEILLE", cp : "23190" },
{ code : "23173", intitule : "SOUBREBOST", cp : "23250" },
{ code : "23174", intitule : "SOUMANS", cp : "23600" },
{ code : "23175", intitule : "SOUS PARSAT", cp : "23150" },
{ code : "23176", intitule : "LA SOUTERRAINE", cp : "23300" },
{ code : "23177", intitule : "ST AGNANT DE VERSILLAT", cp : "23300" },
{ code : "23178", intitule : "ST AGNANT PRES CROCQ", cp : "23260" },
{ code : "23179", intitule : "ST ALPINIEN", cp : "23200" },
{ code : "23180", intitule : "ST AMAND", cp : "23200" },
{ code : "23181", intitule : "ST AMAND JARTOUDEIX", cp : "23400" },
{ code : "23182", intitule : "ST AVIT DE TARDES", cp : "23200" },
{ code : "23183", intitule : "ST AVIT LE PAUVRE", cp : "23480" },
{ code : "23184", intitule : "ST BARD", cp : "23260" },
{ code : "23185", intitule : "ST CHABRAIS", cp : "23130" },
{ code : "23186", intitule : "ST CHRISTOPHE", cp : "23000" },
{ code : "23187", intitule : "ST DIZIER LA TOUR", cp : "23130" },
{ code : "23188", intitule : "ST DIZIER LES DOMAINES", cp : "23270" },
{ code : "23189", intitule : "ST DIZIER LEYRENNE", cp : "23400" },
{ code : "23190", intitule : "ST DOMET", cp : "23190" },
{ code : "23191", intitule : "ST ELOI", cp : "23000" },
{ code : "23192", intitule : "ST ETIENNE DE FURSAC", cp : "23290" },
{ code : "23193", intitule : "STE FEYRE", cp : "23000" },
{ code : "23194", intitule : "STE FEYRE LA MONTAGNE", cp : "23500" },
{ code : "23195", intitule : "ST FIEL", cp : "23000" },
{ code : "23196", intitule : "ST FRION", cp : "23500" },
{ code : "23197", intitule : "ST GEORGES LA POUGE", cp : "23250" },
{ code : "23198", intitule : "ST GEORGES NIGREMONT", cp : "23500" },
{ code : "23199", intitule : "ST GERMAIN BEAUPRE", cp : "23160" },
{ code : "23199", intitule : "ST GERMAIN BEAUPRE", cp : "23160" },
{ code : "23200", intitule : "ST GOUSSAUD", cp : "23430" },
{ code : "23201", intitule : "ST HILAIRE LA PLAINE", cp : "23150" },
{ code : "23202", intitule : "ST HILAIRE LE CHATEAU", cp : "23250" },
{ code : "23203", intitule : "ST JULIEN LA GENETE", cp : "23110" },
{ code : "23204", intitule : "ST JULIEN LE CHATEL", cp : "23130" },
{ code : "23205", intitule : "ST JUNIEN LA BREGERE", cp : "23400" },
{ code : "23206", intitule : "ST LAURENT", cp : "23000" },
{ code : "23207", intitule : "ST LEGER BRIDEREIX", cp : "23300" },
{ code : "23208", intitule : "ST LEGER LE GUERETOIS", cp : "23000" },
{ code : "23209", intitule : "ST LOUP", cp : "23130" },
{ code : "23210", intitule : "ST MAIXANT", cp : "23200" },
{ code : "23211", intitule : "ST MARC A FRONGIER", cp : "23200" },
{ code : "23212", intitule : "ST MARC A LOUBAUD", cp : "23460" },
{ code : "23213", intitule : "ST MARIEN", cp : "23600" },
{ code : "23214", intitule : "ST MARTIAL LE MONT", cp : "23150" },
{ code : "23215", intitule : "ST MARTIAL LE VIEUX", cp : "23100" },
{ code : "23216", intitule : "ST MARTIN CHATEAU", cp : "23460" },
{ code : "23217", intitule : "ST MARTIN STE CATHERINE", cp : "23430" },
{ code : "23217", intitule : "ST MARTIN STE CATHERINE", cp : "23430" },
{ code : "23218", intitule : "ST MAURICE PRES CROCQ", cp : "23260" },
{ code : "23219", intitule : "ST MAURICE LA SOUTERRAINE", cp : "23300" },
{ code : "23219", intitule : "ST MAURICE LA SOUTERRAINE", cp : "23300" },
{ code : "23220", intitule : "ST MEDARD LA ROCHETTE", cp : "23200" },
{ code : "23220", intitule : "ST MEDARD LA ROCHETTE", cp : "23200" },
{ code : "23220", intitule : "ST MEDARD LA ROCHETTE", cp : "23200" },
{ code : "23221", intitule : "ST MERD LA BREUILLE", cp : "23100" },
{ code : "23222", intitule : "ST MICHEL DE VEISSE", cp : "23480" },
{ code : "23223", intitule : "ST MOREIL", cp : "23400" },
{ code : "23224", intitule : "ST ORADOUX DE CHIROUZE", cp : "23100" },
{ code : "23225", intitule : "ST ORADOUX PRES CROCQ", cp : "23260" },
{ code : "23226", intitule : "ST PARDOUX D ARNET", cp : "23260" },
{ code : "23227", intitule : "ST PARDOUX MORTEROLLES", cp : "23400" },
{ code : "23228", intitule : "ST PARDOUX LE NEUF", cp : "23200" },
{ code : "23229", intitule : "ST PARDOUX LES CARDS", cp : "23150" },
{ code : "23230", intitule : "ST PIERRE CHERIGNAT", cp : "23430" },
{ code : "23231", intitule : "ST PIERRE DE FURSAC", cp : "23290" },
{ code : "23232", intitule : "ST PIERRE BELLEVUE", cp : "23460" },
{ code : "23232", intitule : "ST PIERRE BELLEVUE", cp : "23460" },
{ code : "23233", intitule : "ST PIERRE LE BOST", cp : "23600" },
{ code : "23234", intitule : "ST PRIEST", cp : "23110" },
{ code : "23235", intitule : "ST PRIEST LA FEUILLE", cp : "23300" },
{ code : "23236", intitule : "ST PRIEST LA PLAINE", cp : "23240" },
{ code : "23237", intitule : "ST PRIEST PALUS", cp : "23400" },
{ code : "23238", intitule : "ST QUENTIN LA CHABANNE", cp : "23500" },
{ code : "23239", intitule : "ST SEBASTIEN", cp : "23160" },
{ code : "23240", intitule : "ST SILVAIN BAS LE ROC", cp : "23600" },
{ code : "23241", intitule : "ST SILVAIN BELLEGARDE", cp : "23190" },
{ code : "23242", intitule : "ST SILVAIN MONTAIGUT", cp : "23320" },
{ code : "23243", intitule : "ST SILVAIN SOUS TOULX", cp : "23140" },
{ code : "23244", intitule : "ST SULPICE LE DUNOIS", cp : "23800" },
{ code : "23245", intitule : "ST SULPICE LE GUERETOIS", cp : "23000" },
{ code : "23246", intitule : "ST SULPICE LES CHAMPS", cp : "23480" },
{ code : "23247", intitule : "ST VAURY", cp : "23320" },
{ code : "23248", intitule : "ST VICTOR EN MARCHE", cp : "23000" },
{ code : "23249", intitule : "ST YRIEIX LA MONTAGNE", cp : "23460" },
{ code : "23250", intitule : "ST YRIEIX LES BOIS", cp : "23150" },
{ code : "23251", intitule : "TARDES", cp : "23170" },
{ code : "23252", intitule : "TERCILLAT", cp : "23350" },
{ code : "23253", intitule : "THAURON", cp : "23250" },
{ code : "23254", intitule : "TOULX STE CROIX", cp : "23600" },
{ code : "23255", intitule : "TROIS FONDS", cp : "23230" },
{ code : "23257", intitule : "VALLIERE", cp : "23120" },
{ code : "23258", intitule : "VAREILLES", cp : "23300" },
{ code : "23259", intitule : "VERNEIGES", cp : "23170" },
{ code : "23260", intitule : "VIDAILLAT", cp : "23250" },
{ code : "23261", intitule : "VIERSAT", cp : "23170" },
{ code : "23262", intitule : "VIGEVILLE", cp : "23140" },
{ code : "23263", intitule : "VILLARD", cp : "23800" },
{ code : "23264", intitule : "LA VILLEDIEU", cp : "23340" },
{ code : "23265", intitule : "LA VILLENEUVE", cp : "23260" },
{ code : "23266", intitule : "LA VILLETELLE", cp : "23260" },
{ code : "24001", intitule : "ABJAT SUR BANDIAT", cp : "24300" },
{ code : "24002", intitule : "AGONAC", cp : "24460" },
{ code : "24004", intitule : "AJAT", cp : "24210" },
{ code : "24005", intitule : "ALLES SUR DORDOGNE", cp : "24480" },
{ code : "24006", intitule : "ALLAS LES MINES", cp : "24220" },
{ code : "24007", intitule : "ALLEMANS", cp : "24600" },
{ code : "24008", intitule : "ANGOISSE", cp : "24270" },
{ code : "24009", intitule : "ANLHIAC", cp : "24160" },
{ code : "24010", intitule : "ANNESSE ET BEAULIEU", cp : "24430" },
{ code : "24011", intitule : "ANTONNE ET TRIGONANT", cp : "24420" },
{ code : "24012", intitule : "ARCHIGNAC", cp : "24590" },
{ code : "24014", intitule : "AUBAS", cp : "24290" },
{ code : "24015", intitule : "AUDRIX", cp : "24260" },
{ code : "24016", intitule : "AUGIGNAC", cp : "24300" },
{ code : "24018", intitule : "AURIAC DU PERIGORD", cp : "24290" },
{ code : "24019", intitule : "AZERAT", cp : "24210" },
{ code : "24020", intitule : "LA BACHELLERIE", cp : "24210" },
{ code : "24021", intitule : "BADEFOLS D ANS", cp : "24390" },
{ code : "24022", intitule : "BADEFOLS SUR DORDOGNE", cp : "24150" },
{ code : "24023", intitule : "BANEUIL", cp : "24150" },
{ code : "24024", intitule : "BARDOU", cp : "24560" },
{ code : "24025", intitule : "BARS", cp : "24210" },
{ code : "24026", intitule : "BASSILLAC", cp : "24330" },
{ code : "24027", intitule : "BAYAC", cp : "24150" },
{ code : "24028", intitule : "BEAUMONTOIS EN PERIGORD", cp : "24440" },
{ code : "24028", intitule : "BEAUMONTOIS EN PERIGORD", cp : "24440" },
{ code : "24028", intitule : "BEAUMONTOIS EN PERIGORD", cp : "24440" },
{ code : "24028", intitule : "BEAUMONTOIS EN PERIGORD", cp : "24440" },
{ code : "24028", intitule : "BEAUMONTOIS EN PERIGORD", cp : "24440" },
{ code : "24029", intitule : "BEAUPOUYET", cp : "24400" },
{ code : "24030", intitule : "BEAUREGARD DE TERRASSON", cp : "24120" },
{ code : "24031", intitule : "BEAUREGARD ET BASSAC", cp : "24140" },
{ code : "24032", intitule : "BEAURONNE", cp : "24400" },
{ code : "24033", intitule : "BEAUSSAC", cp : "24340" },
{ code : "24034", intitule : "BELEYMAS", cp : "24140" },
{ code : "24035", intitule : "PAYS DE BELVES", cp : "24170" },
{ code : "24035", intitule : "PAYS DE BELVES", cp : "24170" },
{ code : "24035", intitule : "PAYS DE BELVES", cp : "24170" },
{ code : "24036", intitule : "BERBIGUIERES", cp : "24220" },
{ code : "24037", intitule : "BERGERAC", cp : "24100" },
{ code : "24038", intitule : "BERTRIC BUREE", cp : "24320" },
{ code : "24039", intitule : "BESSE", cp : "24550" },
{ code : "24040", intitule : "BEYNAC ET CAZENAC", cp : "24220" },
{ code : "24041", intitule : "BEZENAC", cp : "24220" },
{ code : "24042", intitule : "BIRAS", cp : "24310" },
{ code : "24043", intitule : "BIRON", cp : "24540" },
{ code : "24044", intitule : "BLIS ET BORN", cp : "24330" },
{ code : "24045", intitule : "BOISSE", cp : "24560" },
{ code : "24046", intitule : "BOISSEUILH", cp : "24390" },
{ code : "24047", intitule : "LA BOISSIERE D ANS", cp : "24640" },
{ code : "24048", intitule : "BONNEVILLE ET ST AVIT DE FUMADIERES", cp : "24230" },
{ code : "24050", intitule : "BORREZE", cp : "24590" },
{ code : "24051", intitule : "BOSSET", cp : "24130" },
{ code : "24052", intitule : "BOUILLAC", cp : "24480" },
{ code : "24053", intitule : "BOULAZAC ISLE MANOIRE", cp : "24330" },
{ code : "24053", intitule : "BOULAZAC ISLE MANOIRE", cp : "24750" },
{ code : "24053", intitule : "BOULAZAC ISLE MANOIRE", cp : "24750" },
{ code : "24054", intitule : "BOUNIAGUES", cp : "24560" },
{ code : "24055", intitule : "BOURDEILLES", cp : "24310" },
{ code : "24056", intitule : "LE BOURDEIX", cp : "24300" },
{ code : "24057", intitule : "BOURG DES MAISONS", cp : "24320" },
{ code : "24058", intitule : "BOURG DU BOST", cp : "24600" },
{ code : "24059", intitule : "BOURGNAC", cp : "24400" },
{ code : "24060", intitule : "BOURNIQUEL", cp : "24150" },
{ code : "24061", intitule : "BOURROU", cp : "24110" },
{ code : "24062", intitule : "BOUTEILLES ST SEBASTIEN", cp : "24320" },
{ code : "24063", intitule : "BOUZIC", cp : "24250" },
{ code : "24064", intitule : "BRANTOME EN PERIGORD", cp : "24310" },
{ code : "24064", intitule : "BRANTOME EN PERIGORD", cp : "24310" },
{ code : "24065", intitule : "BREUILH", cp : "24380" },
{ code : "24066", intitule : "BROUCHAUD", cp : "24210" },
{ code : "24067", intitule : "LE BUGUE", cp : "24260" },
{ code : "24068", intitule : "LE BUISSON DE CADOUIN", cp : "24480" },
{ code : "24068", intitule : "LE BUISSON DE CADOUIN", cp : "24480" },
{ code : "24068", intitule : "LE BUISSON DE CADOUIN", cp : "24480" },
{ code : "24069", intitule : "BUSSAC", cp : "24350" },
{ code : "24070", intitule : "BUSSEROLLES", cp : "24360" },
{ code : "24071", intitule : "BUSSIERE BADIL", cp : "24360" },
{ code : "24073", intitule : "CALES", cp : "24150" },
{ code : "24074", intitule : "CALVIAC EN PERIGORD", cp : "24370" },
{ code : "24075", intitule : "CAMPAGNAC LES QUERCY", cp : "24550" },
{ code : "24076", intitule : "CAMPAGNE", cp : "24260" },
{ code : "24077", intitule : "CAMPSEGRET", cp : "24140" },
{ code : "24079", intitule : "CANTILLAC", cp : "24530" },
{ code : "24080", intitule : "CAPDROT", cp : "24540" },
{ code : "24081", intitule : "CARLUX", cp : "24370" },
{ code : "24082", intitule : "CARSAC AILLAC", cp : "24200" },
{ code : "24083", intitule : "CARSAC DE GURSON", cp : "24610" },
{ code : "24084", intitule : "CARVES", cp : "24170" },
{ code : "24085", intitule : "LA CASSAGNE", cp : "24120" },
{ code : "24086", intitule : "CASTELNAUD LA CHAPELLE", cp : "24250" },
{ code : "24086", intitule : "CASTELNAUD LA CHAPELLE", cp : "24250" },
{ code : "24087", intitule : "CASTELS", cp : "24220" },
{ code : "24088", intitule : "CAUSE DE CLERANS", cp : "24150" },
{ code : "24089", intitule : "CAZOULES", cp : "24370" },
{ code : "24090", intitule : "CELLES", cp : "24600" },
{ code : "24091", intitule : "CENAC ET ST JULIEN", cp : "24250" },
{ code : "24092", intitule : "CENDRIEUX", cp : "24380" },
{ code : "24093", intitule : "CERCLES", cp : "24320" },
{ code : "24094", intitule : "CHALAGNAC", cp : "24380" },
{ code : "24095", intitule : "CHALAIS", cp : "24800" },
{ code : "24096", intitule : "CHAMPAGNAC DE BELAIR", cp : "24530" },
{ code : "24097", intitule : "CHAMPAGNE ET FONTAINE", cp : "24320" },
{ code : "24098", intitule : "CHAMPCEVINEL", cp : "24750" },
{ code : "24099", intitule : "CHAMPEAUX ET LA CHAPELLE POMMIER", cp : "24340" },
{ code : "24100", intitule : "CHAMPNIERS ET REILHAC", cp : "24360" },
{ code : "24101", intitule : "CHAMPS ROMAIN", cp : "24470" },
{ code : "24102", intitule : "CHANCELADE", cp : "24650" },
{ code : "24103", intitule : "LE CHANGE", cp : "24640" },
{ code : "24104", intitule : "CHANTERAC", cp : "24190" },
{ code : "24105", intitule : "CHAPDEUIL", cp : "24320" },
{ code : "24106", intitule : "LA CHAPELLE AUBAREIL", cp : "24290" },
{ code : "24107", intitule : "LA CHAPELLE FAUCHER", cp : "24530" },
{ code : "24108", intitule : "LA CHAPELLE GONAGUET", cp : "24350" },
{ code : "24109", intitule : "LA CHAPELLE GRESIGNAC", cp : "24320" },
{ code : "24110", intitule : "LA CHAPELLE MONTABOURLET", cp : "24320" },
{ code : "24111", intitule : "LA CHAPELLE MONTMOREAU", cp : "24300" },
{ code : "24113", intitule : "LA CHAPELLE ST JEAN", cp : "24390" },
{ code : "24114", intitule : "CHASSAIGNES", cp : "24600" },
{ code : "24115", intitule : "CHATEAU L EVEQUE", cp : "24460" },
{ code : "24116", intitule : "CHATRES", cp : "24120" },
{ code : "24117", intitule : "CHAVAGNAC", cp : "24120" },
{ code : "24119", intitule : "CHERVAL", cp : "24320" },
{ code : "24120", intitule : "CHERVEIX CUBAS", cp : "24390" },
{ code : "24121", intitule : "CHOURGNAC", cp : "24640" },
{ code : "24122", intitule : "CLADECH", cp : "24170" },
{ code : "24123", intitule : "CLERMONT DE BEAUREGARD", cp : "24140" },
{ code : "24124", intitule : "CLERMONT D EXCIDEUIL", cp : "24160" },
{ code : "24126", intitule : "COLOMBIER", cp : "24560" },
{ code : "24127", intitule : "COLY", cp : "24120" },
{ code : "24128", intitule : "COMBERANCHE ET EPELUCHE", cp : "24600" },
{ code : "24129", intitule : "CONDAT SUR TRINCOU", cp : "24530" },
{ code : "24130", intitule : "CONDAT SUR VEZERE", cp : "24570" },
{ code : "24131", intitule : "CONNEZAC", cp : "24300" },
{ code : "24132", intitule : "CONNE DE LABARDE", cp : "24560" },
{ code : "24133", intitule : "LA COQUILLE", cp : "24450" },
{ code : "24134", intitule : "CORGNAC SUR L ISLE", cp : "24800" },
{ code : "24135", intitule : "CORNILLE", cp : "24750" },
{ code : "24136", intitule : "COUBJOURS", cp : "24390" },
{ code : "24137", intitule : "COULAURES", cp : "24420" },
{ code : "24138", intitule : "COULOUNIEIX CHAMIERS", cp : "24660" },
{ code : "24139", intitule : "COURSAC", cp : "24430" },
{ code : "24140", intitule : "COURS DE PILE", cp : "24520" },
{ code : "24141", intitule : "COUTURES", cp : "24320" },
{ code : "24142", intitule : "COUX ET BIGAROQUE MOUZENS", cp : "24220" },
{ code : "24142", intitule : "COUX ET BIGAROQUE MOUZENS", cp : "24220" },
{ code : "24143", intitule : "COUZE ET ST FRONT", cp : "24150" },
{ code : "24144", intitule : "CREYSSAC", cp : "24350" },
{ code : "24145", intitule : "CREYSSE", cp : "24100" },
{ code : "24146", intitule : "CREYSSENSAC ET PISSOT", cp : "24380" },
{ code : "24147", intitule : "CUBJAC", cp : "24640" },
{ code : "24148", intitule : "CUNEGES", cp : "24240" },
{ code : "24150", intitule : "DAGLAN", cp : "24250" },
{ code : "24151", intitule : "DOISSAT", cp : "24170" },
{ code : "24152", intitule : "DOMME", cp : "24250" },
{ code : "24153", intitule : "LA DORNAC", cp : "24120" },
{ code : "24154", intitule : "DOUCHAPT", cp : "24350" },
{ code : "24155", intitule : "DOUVILLE", cp : "24140" },
{ code : "24156", intitule : "LA DOUZE", cp : "24330" },
{ code : "24157", intitule : "DOUZILLAC", cp : "24190" },
{ code : "24158", intitule : "DUSSAC", cp : "24270" },
{ code : "24159", intitule : "ECHOURGNAC", cp : "24410" },
{ code : "24160", intitule : "EGLISE NEUVE DE VERGT", cp : "24380" },
{ code : "24161", intitule : "EGLISE NEUVE D ISSAC", cp : "24400" },
{ code : "24162", intitule : "ESCOIRE", cp : "24420" },
{ code : "24163", intitule : "ETOUARS", cp : "24360" },
{ code : "24164", intitule : "EXCIDEUIL", cp : "24160" },
{ code : "24165", intitule : "EYGURANDE ET GARDEDEUIL", cp : "24700" },
{ code : "24166", intitule : "EYLIAC", cp : "24330" },
{ code : "24167", intitule : "EYMET", cp : "24500" },
{ code : "24168", intitule : "PLAISANCE", cp : "24560" },
{ code : "24168", intitule : "PLAISANCE", cp : "24560" },
{ code : "24168", intitule : "PLAISANCE", cp : "24560" },
{ code : "24170", intitule : "EYVIRAT", cp : "24460" },
{ code : "24171", intitule : "EYZERAC", cp : "24800" },
{ code : "24172", intitule : "LES EYZIES DE TAYAC SIREUIL", cp : "24620" },
{ code : "24172", intitule : "LES EYZIES DE TAYAC SIREUIL", cp : "24620" },
{ code : "24174", intitule : "FANLAC", cp : "24290" },
{ code : "24175", intitule : "LES FARGES", cp : "24290" },
{ code : "24176", intitule : "FAURILLES", cp : "24560" },
{ code : "24177", intitule : "FAUX", cp : "24560" },
{ code : "24178", intitule : "FESTALEMPS", cp : "24410" },
{ code : "24179", intitule : "LA FEUILLADE", cp : "24120" },
{ code : "24180", intitule : "FIRBEIX", cp : "24450" },
{ code : "24181", intitule : "FLAUGEAC", cp : "24240" },
{ code : "24182", intitule : "LE FLEIX", cp : "24130" },
{ code : "24183", intitule : "FLEURAC", cp : "24580" },
{ code : "24184", intitule : "FLORIMONT GAUMIER", cp : "24250" },
{ code : "24186", intitule : "FONROQUE", cp : "24500" },
{ code : "24188", intitule : "FOSSEMAGNE", cp : "24210" },
{ code : "24189", intitule : "FOUGUEYROLLES", cp : "33220" },
{ code : "24190", intitule : "FOULEIX", cp : "24380" },
{ code : "24191", intitule : "FRAISSE", cp : "24130" },
{ code : "24192", intitule : "GABILLOU", cp : "24210" },
{ code : "24193", intitule : "GAGEAC ET ROUILLAC", cp : "24240" },
{ code : "24194", intitule : "GARDONNE", cp : "24680" },
{ code : "24195", intitule : "GAUGEAC", cp : "24540" },
{ code : "24196", intitule : "GENIS", cp : "24160" },
{ code : "24197", intitule : "GINESTET", cp : "24130" },
{ code : "24198", intitule : "LA GONTERIE BOULOUNEIX", cp : "24310" },
{ code : "24199", intitule : "GOUT ROSSIGNOL", cp : "24320" },
{ code : "24200", intitule : "GRAND BRASSAC", cp : "24350" },
{ code : "24202", intitule : "GRANGES D ANS", cp : "24390" },
{ code : "24203", intitule : "LES GRAULGES", cp : "24340" },
{ code : "24204", intitule : "GREZES", cp : "24120" },
{ code : "24205", intitule : "GRIGNOLS", cp : "24110" },
{ code : "24206", intitule : "GRIVES", cp : "24170" },
{ code : "24207", intitule : "GROLEJAC", cp : "24250" },
{ code : "24208", intitule : "GRUN BORDAS", cp : "24380" },
{ code : "24209", intitule : "HAUTEFAYE", cp : "24300" },
{ code : "24210", intitule : "HAUTEFORT", cp : "24390" },
{ code : "24211", intitule : "ISSAC", cp : "24400" },
{ code : "24212", intitule : "ISSIGEAC", cp : "24560" },
{ code : "24213", intitule : "JAURE", cp : "24140" },
{ code : "24214", intitule : "JAVERLHAC ET LA CHAPELLE ST ROBERT", cp : "24300" },
{ code : "24215", intitule : "JAYAC", cp : "24590" },
{ code : "24216", intitule : "LA JEMAYE", cp : "24410" },
{ code : "24217", intitule : "JOURNIAC", cp : "24260" },
{ code : "24218", intitule : "JUMILHAC LE GRAND", cp : "24630" },
{ code : "24220", intitule : "LACROPTE", cp : "24380" },
{ code : "24221", intitule : "RUDEAU LADOSSE", cp : "24340" },
{ code : "24222", intitule : "LA FORCE", cp : "24130" },
{ code : "24223", intitule : "LALINDE", cp : "24150" },
{ code : "24224", intitule : "LAMONZIE MONTASTRUC", cp : "24520" },
{ code : "24225", intitule : "LAMONZIE ST MARTIN", cp : "24680" },
{ code : "24226", intitule : "LAMOTHE MONTRAVEL", cp : "24230" },
{ code : "24227", intitule : "LANOUAILLE", cp : "24270" },
{ code : "24228", intitule : "LANQUAIS", cp : "24150" },
{ code : "24229", intitule : "LE LARDIN ST LAZARE", cp : "24570" },
{ code : "24230", intitule : "LARZAC", cp : "24170" },
{ code : "24231", intitule : "LAVALADE", cp : "24540" },
{ code : "24232", intitule : "LAVAUR", cp : "24550" },
{ code : "24233", intitule : "LAVEYSSIERE", cp : "24130" },
{ code : "24234", intitule : "LES LECHES", cp : "24400" },
{ code : "24235", intitule : "LEGUILLAC DE CERCLES", cp : "24340" },
{ code : "24236", intitule : "LEGUILLAC DE L AUCHE", cp : "24110" },
{ code : "24237", intitule : "LEMBRAS", cp : "24100" },
{ code : "24238", intitule : "LEMPZOURS", cp : "24800" },
{ code : "24240", intitule : "LIMEUIL", cp : "24510" },
{ code : "24241", intitule : "LIMEYRAT", cp : "24210" },
{ code : "24242", intitule : "LIORAC SUR LOUYRE", cp : "24520" },
{ code : "24243", intitule : "LISLE", cp : "24350" },
{ code : "24244", intitule : "LOLME", cp : "24540" },
{ code : "24245", intitule : "LOUBEJAC", cp : "24550" },
{ code : "24246", intitule : "LUNAS", cp : "24130" },
{ code : "24247", intitule : "LUSIGNAC", cp : "24320" },
{ code : "24248", intitule : "LUSSAS ET NONTRONNEAU", cp : "24300" },
{ code : "24249", intitule : "MANAURIE", cp : "24620" },
{ code : "24251", intitule : "MANZAC SUR VERN", cp : "24110" },
{ code : "24252", intitule : "MARCILLAC ST QUENTIN", cp : "24200" },
{ code : "24253", intitule : "MAREUIL", cp : "24340" },
{ code : "24254", intitule : "MARNAC", cp : "24220" },
{ code : "24255", intitule : "MARQUAY", cp : "24620" },
{ code : "24256", intitule : "MARSAC SUR L ISLE", cp : "24430" },
{ code : "24257", intitule : "MARSALES", cp : "24540" },
{ code : "24258", intitule : "MARSANEIX", cp : "24750" },
{ code : "24259", intitule : "MAURENS", cp : "24140" },
{ code : "24260", intitule : "MAUZAC ET GRAND CASTANG", cp : "24150" },
{ code : "24260", intitule : "MAUZAC ET GRAND CASTANG", cp : "24150" },
{ code : "24261", intitule : "MAUZENS ET MIREMONT", cp : "24260" },
{ code : "24262", intitule : "MAYAC", cp : "24420" },
{ code : "24263", intitule : "MAZEYROLLES", cp : "24550" },
{ code : "24264", intitule : "MENESPLET", cp : "24700" },
{ code : "24266", intitule : "MENSIGNAC", cp : "24350" },
{ code : "24267", intitule : "MESCOULES", cp : "24240" },
{ code : "24268", intitule : "MEYRALS", cp : "24220" },
{ code : "24269", intitule : "MIALET", cp : "24450" },
{ code : "24270", intitule : "MILHAC D AUBEROCHE", cp : "24330" },
{ code : "24271", intitule : "MILHAC DE NONTRON", cp : "24470" },
{ code : "24272", intitule : "MINZAC", cp : "24610" },
{ code : "24273", intitule : "MOLIERES", cp : "24480" },
{ code : "24274", intitule : "MONBAZILLAC", cp : "24240" },
{ code : "24276", intitule : "MONESTIER", cp : "24240" },
{ code : "24277", intitule : "MONFAUCON", cp : "24130" },
{ code : "24278", intitule : "MONMADALES", cp : "24560" },
{ code : "24279", intitule : "MONMARVES", cp : "24560" },
{ code : "24280", intitule : "MONPAZIER", cp : "24540" },
{ code : "24281", intitule : "MONSAC", cp : "24440" },
{ code : "24282", intitule : "MONSAGUEL", cp : "24560" },
{ code : "24283", intitule : "MONSEC", cp : "24340" },
{ code : "24284", intitule : "MONTAGNAC D AUBEROCHE", cp : "24210" },
{ code : "24285", intitule : "MONTAGNAC LA CREMPSE", cp : "24140" },
{ code : "24286", intitule : "MONTAGRIER", cp : "24350" },
{ code : "24287", intitule : "MONTAUT", cp : "24560" },
{ code : "24288", intitule : "MONTAZEAU", cp : "24230" },
{ code : "24289", intitule : "MONTCARET", cp : "24230" },
{ code : "24290", intitule : "MONTFERRAND DU PERIGORD", cp : "24440" },
{ code : "24291", intitule : "MONTIGNAC", cp : "24290" },
{ code : "24292", intitule : "MONTPEYROUX", cp : "24610" },
{ code : "24293", intitule : "MONPLAISANT", cp : "24170" },
{ code : "24294", intitule : "MONTPON MENESTEROL", cp : "24700" },
{ code : "24295", intitule : "MONTREM", cp : "24110" },
{ code : "24296", intitule : "MOULEYDIER", cp : "24520" },
{ code : "24297", intitule : "MOULIN NEUF", cp : "24700" },
{ code : "24299", intitule : "MUSSIDAN", cp : "24400" },
{ code : "24300", intitule : "NABIRAT", cp : "24250" },
{ code : "24301", intitule : "NADAILLAC", cp : "24590" },
{ code : "24302", intitule : "NAILHAC", cp : "24390" },
{ code : "24303", intitule : "NANTEUIL AURIAC DE BOURZAC", cp : "24320" },
{ code : "24303", intitule : "NANTEUIL AURIAC DE BOURZAC", cp : "24320" },
{ code : "24304", intitule : "NANTHEUIL", cp : "24800" },
{ code : "24305", intitule : "NANTHIAT", cp : "24800" },
{ code : "24306", intitule : "NASTRINGUES", cp : "24230" },
{ code : "24307", intitule : "NAUSSANNES", cp : "24440" },
{ code : "24308", intitule : "NEGRONDES", cp : "24460" },
{ code : "24309", intitule : "NEUVIC", cp : "24190" },
{ code : "24311", intitule : "NONTRON", cp : "24300" },
{ code : "24312", intitule : "NOTRE DAME DE SANILHAC", cp : "24660" },
{ code : "24313", intitule : "ORLIAC", cp : "24170" },
{ code : "24314", intitule : "ORLIAGUET", cp : "24370" },
{ code : "24316", intitule : "PARCOUL CHENAUD", cp : "24410" },
{ code : "24316", intitule : "PARCOUL CHENAUD", cp : "24410" },
{ code : "24317", intitule : "PAULIN", cp : "24590" },
{ code : "24318", intitule : "PAUNAT", cp : "24510" },
{ code : "24319", intitule : "PAUSSAC ET ST VIVIEN", cp : "24310" },
{ code : "24320", intitule : "PAYZAC", cp : "24270" },
{ code : "24321", intitule : "PAZAYAC", cp : "24120" },
{ code : "24322", intitule : "PERIGUEUX", cp : "24000" },
{ code : "24323", intitule : "PETIT BERSAC", cp : "24600" },
{ code : "24324", intitule : "PEYRIGNAC", cp : "24210" },
{ code : "24325", intitule : "PEYRILLAC ET MILLAC", cp : "24370" },
{ code : "24326", intitule : "PEYZAC LE MOUSTIER", cp : "24620" },
{ code : "24327", intitule : "PEZULS", cp : "24510" },
{ code : "24328", intitule : "PIEGUT PLUVIERS", cp : "24360" },
{ code : "24329", intitule : "LE PIZOU", cp : "24700" },
{ code : "24330", intitule : "PLAZAC", cp : "24580" },
{ code : "24331", intitule : "POMPORT", cp : "24240" },
{ code : "24333", intitule : "PONTEYRAUD", cp : "24410" },
{ code : "24334", intitule : "PONTOURS", cp : "24150" },
{ code : "24335", intitule : "PORT STE FOY ET PONCHAPT", cp : "33220" },
{ code : "24336", intitule : "PRATS DE CARLUX", cp : "24370" },
{ code : "24337", intitule : "PRATS DU PERIGORD", cp : "24550" },
{ code : "24338", intitule : "PRESSIGNAC VICQ", cp : "24150" },
{ code : "24339", intitule : "PREYSSAC D EXCIDEUIL", cp : "24160" },
{ code : "24340", intitule : "PRIGONRIEUX", cp : "24130" },
{ code : "24341", intitule : "PROISSANS", cp : "24200" },
{ code : "24344", intitule : "PUYRENIER", cp : "24340" },
{ code : "24345", intitule : "QUEYSSAC", cp : "24140" },
{ code : "24346", intitule : "QUINSAC", cp : "24530" },
{ code : "24347", intitule : "RAMPIEUX", cp : "24440" },
{ code : "24348", intitule : "RAZAC D EYMET", cp : "24500" },
{ code : "24349", intitule : "RAZAC DE SAUSSIGNAC", cp : "24240" },
{ code : "24350", intitule : "RAZAC SUR L ISLE", cp : "24430" },
{ code : "24351", intitule : "RIBAGNAC", cp : "24240" },
{ code : "24352", intitule : "RIBERAC", cp : "24600" },
{ code : "24353", intitule : "LA ROCHEBEAUCOURT ET ARGENTINE", cp : "24340" },
{ code : "24354", intitule : "LA ROCHE CHALAIS", cp : "24490" },
{ code : "24354", intitule : "LA ROCHE CHALAIS", cp : "24490" },
{ code : "24354", intitule : "LA ROCHE CHALAIS", cp : "24490" },
{ code : "24355", intitule : "LA ROQUE GAGEAC", cp : "24250" },
{ code : "24356", intitule : "ROUFFIGNAC ST CERNIN DE REILHAC", cp : "24580" },
{ code : "24356", intitule : "ROUFFIGNAC ST CERNIN DE REILHAC", cp : "24580" },
{ code : "24357", intitule : "ROUFFIGNAC DE SIGOULES", cp : "24240" },
{ code : "24359", intitule : "SADILLAC", cp : "24500" },
{ code : "24360", intitule : "SAGELAT", cp : "24170" },
{ code : "24361", intitule : "ST AGNE", cp : "24520" },
{ code : "24362", intitule : "STE ALVERE ST LAURENT LES BATONS", cp : "24510" },
{ code : "24362", intitule : "STE ALVERE ST LAURENT LES BATONS", cp : "24510" },
{ code : "24364", intitule : "ST AMAND DE COLY", cp : "24290" },
{ code : "24365", intitule : "ST AMAND DE VERGT", cp : "24380" },
{ code : "24366", intitule : "ST ANDRE D ALLAS", cp : "24200" },
{ code : "24367", intitule : "ST ANDRE DE DOUBLE", cp : "24190" },
{ code : "24368", intitule : "ST ANTOINE CUMOND", cp : "24410" },
{ code : "24369", intitule : "ST ANTOINE D AUBEROCHE", cp : "24330" },
{ code : "24370", intitule : "ST ANTOINE DE BREUILH", cp : "24230" },
{ code : "24371", intitule : "ST AQUILIN", cp : "24110" },
{ code : "24372", intitule : "ST ASTIER", cp : "24110" },
{ code : "24373", intitule : "ST AUBIN DE CADELECH", cp : "24500" },
{ code : "24374", intitule : "ST AUBIN DE LANQUAIS", cp : "24560" },
{ code : "24375", intitule : "ST AUBIN DE NABIRAT", cp : "24250" },
{ code : "24376", intitule : "ST AULAYE PUYMANGOU", cp : "24410" },
{ code : "24376", intitule : "ST AULAYE PUYMANGOU", cp : "24410" },
{ code : "24377", intitule : "ST AVIT DE VIALARD", cp : "24260" },
{ code : "24378", intitule : "ST AVIT RIVIERE", cp : "24540" },
{ code : "24379", intitule : "ST AVIT SENIEUR", cp : "24440" },
{ code : "24380", intitule : "ST BARTHELEMY DE BELLEGARDE", cp : "24700" },
{ code : "24381", intitule : "ST BARTHELEMY DE BUSSIERE", cp : "24360" },
{ code : "24382", intitule : "ST CAPRAISE DE LALINDE", cp : "24150" },
{ code : "24383", intitule : "ST CAPRAISE D EYMET", cp : "24500" },
{ code : "24384", intitule : "ST CASSIEN", cp : "24540" },
{ code : "24385", intitule : "ST CERNIN DE LABARDE", cp : "24560" },
{ code : "24386", intitule : "ST CERNIN DE L HERM", cp : "24550" },
{ code : "24388", intitule : "ST CHAMASSY", cp : "24260" },
{ code : "24389", intitule : "ST CIRQ", cp : "24260" },
{ code : "24390", intitule : "ST CREPIN D AUBEROCHE", cp : "24330" },
{ code : "24391", intitule : "ST CREPIN DE RICHEMONT", cp : "24310" },
{ code : "24392", intitule : "ST CREPIN ET CARLUCET", cp : "24590" },
{ code : "24393", intitule : "STE CROIX", cp : "24440" },
{ code : "24394", intitule : "STE CROIX DE MAREUIL", cp : "24340" },
{ code : "24395", intitule : "ST CYBRANET", cp : "24250" },
{ code : "24396", intitule : "ST CYPRIEN", cp : "24220" },
{ code : "24397", intitule : "ST CYR LES CHAMPAGNES", cp : "24270" },
{ code : "24398", intitule : "ST ESTEPHE", cp : "24360" },
{ code : "24399", intitule : "ST ETIENNE DE PUYCORBIER", cp : "24400" },
{ code : "24401", intitule : "STE EULALIE D ANS", cp : "24640" },
{ code : "24402", intitule : "STE EULALIE D EYMET", cp : "24500" },
{ code : "24403", intitule : "ST FELIX DE BOURDEILLES", cp : "24340" },
{ code : "24404", intitule : "ST FELIX DE REILLAC ET MORTEMART", cp : "24260" },
{ code : "24405", intitule : "ST FELIX DE VILLADEIX", cp : "24510" },
{ code : "24406", intitule : "STE FOY DE BELVES", cp : "24170" },
{ code : "24407", intitule : "STE FOY DE LONGAS", cp : "24510" },
{ code : "24408", intitule : "ST FRONT D ALEMPS", cp : "24460" },
{ code : "24409", intitule : "ST FRONT DE PRADOUX", cp : "24400" },
{ code : "24410", intitule : "ST FRONT LA RIVIERE", cp : "24300" },
{ code : "24411", intitule : "ST FRONT SUR NIZONNE", cp : "24300" },
{ code : "24412", intitule : "ST GENIES", cp : "24590" },
{ code : "24413", intitule : "ST GEORGES BLANCANEIX", cp : "24130" },
{ code : "24414", intitule : "ST GEORGES DE MONTCLARD", cp : "24140" },
{ code : "24415", intitule : "ST GERAUD DE CORPS", cp : "24700" },
{ code : "24416", intitule : "ST GERMAIN DE BELVES", cp : "24170" },
{ code : "24417", intitule : "ST GERMAIN DES PRES", cp : "24160" },
{ code : "24418", intitule : "ST GERMAIN DU SALEMBRE", cp : "24190" },
{ code : "24419", intitule : "ST GERMAIN ET MONS", cp : "24520" },
{ code : "24420", intitule : "ST GERY", cp : "24400" },
{ code : "24421", intitule : "ST GEYRAC", cp : "24330" },
{ code : "24422", intitule : "ST HILAIRE D ESTISSAC", cp : "24140" },
{ code : "24423", intitule : "STE INNOCENCE", cp : "24500" },
{ code : "24424", intitule : "ST JEAN D ATAUX", cp : "24190" },
{ code : "24425", intitule : "ST JEAN DE COLE", cp : "24800" },
{ code : "24426", intitule : "ST JEAN D ESTISSAC", cp : "24140" },
{ code : "24427", intitule : "ST JEAN D EYRAUD", cp : "24140" },
{ code : "24428", intitule : "ST JORY DE CHALAIS", cp : "24800" },
{ code : "24429", intitule : "ST JORY LAS BLOUX", cp : "24160" },
{ code : "24431", intitule : "ST JULIEN DE CREMPSE", cp : "24140" },
{ code : "24432", intitule : "ST JULIEN DE LAMPON", cp : "24370" },
{ code : "24433", intitule : "ST JULIEN D EYMET", cp : "24500" },
{ code : "24434", intitule : "ST JUST", cp : "24320" },
{ code : "24436", intitule : "ST LAURENT DES HOMMES", cp : "24400" },
{ code : "24437", intitule : "ST LAURENT DES VIGNES", cp : "24100" },
{ code : "24438", intitule : "ST LAURENT LA VALLEE", cp : "24170" },
{ code : "24441", intitule : "ST LEON D ISSIGEAC", cp : "24560" },
{ code : "24442", intitule : "ST LEON SUR L ISLE", cp : "24110" },
{ code : "24443", intitule : "ST LEON SUR VEZERE", cp : "24290" },
{ code : "24444", intitule : "ST LOUIS EN L ISLE", cp : "24400" },
{ code : "24445", intitule : "ST MARCEL DU PERIGORD", cp : "24510" },
{ code : "24446", intitule : "ST MARCORY", cp : "24540" },
{ code : "24447", intitule : "STE MARIE DE CHIGNAC", cp : "24330" },
{ code : "24448", intitule : "ST MARTIAL D ALBAREDE", cp : "24160" },
{ code : "24449", intitule : "ST MARTIAL D ARTENSET", cp : "24700" },
{ code : "24450", intitule : "ST MARTIAL DE NABIRAT", cp : "24250" },
{ code : "24451", intitule : "ST MARTIAL DE VALETTE", cp : "24300" },
{ code : "24452", intitule : "ST MARTIAL VIVEYROL", cp : "24320" },
{ code : "24453", intitule : "ST MARTIN DE FRESSENGEAS", cp : "24800" },
{ code : "24454", intitule : "ST MARTIN DE GURSON", cp : "24610" },
{ code : "24455", intitule : "ST MARTIN DE RIBERAC", cp : "24600" },
{ code : "24456", intitule : "ST MARTIN DES COMBES", cp : "24140" },
{ code : "24457", intitule : "ST MARTIN L ASTIER", cp : "24400" },
{ code : "24458", intitule : "ST MARTIN LE PIN", cp : "24300" },
{ code : "24459", intitule : "ST MAIME DE PEREYROL", cp : "24380" },
{ code : "24460", intitule : "ST MEARD DE DRONE", cp : "24600" },
{ code : "24461", intitule : "ST MEARD DE GURCON", cp : "24610" },
{ code : "24462", intitule : "ST MEDARD DE MUSSIDAN", cp : "24400" },
{ code : "24463", intitule : "ST MEDARD D EXCIDEUIL", cp : "24160" },
{ code : "24464", intitule : "ST MESMIN", cp : "24270" },
{ code : "24465", intitule : "ST MICHEL DE DOUBLE", cp : "24400" },
{ code : "24466", intitule : "ST MICHEL DE MONTAIGNE", cp : "24230" },
{ code : "24468", intitule : "ST MICHEL DE VILLADEIX", cp : "24380" },
{ code : "24470", intitule : "STE MONDANE", cp : "24370" },
{ code : "24471", intitule : "STE NATHALENE", cp : "24200" },
{ code : "24472", intitule : "ST NEXANS", cp : "24520" },
{ code : "24473", intitule : "STE ORSE", cp : "24210" },
{ code : "24474", intitule : "ST PANCRACE", cp : "24530" },
{ code : "24475", intitule : "ST PANTALY D ANS", cp : "24640" },
{ code : "24476", intitule : "ST PANTALY D EXCIDEUIL", cp : "24160" },
{ code : "24477", intitule : "ST PARDOUX DE DRONE", cp : "24600" },
{ code : "24478", intitule : "ST PARDOUX ET VIELVIC", cp : "24170" },
{ code : "24479", intitule : "ST PARDOUX LA RIVIERE", cp : "24470" },
{ code : "24480", intitule : "ST PAUL DE SERRE", cp : "24380" },
{ code : "24481", intitule : "ST PAUL LA ROCHE", cp : "24800" },
{ code : "24482", intitule : "ST PAUL LIZONNE", cp : "24320" },
{ code : "24483", intitule : "ST PERDOUX", cp : "24560" },
{ code : "24484", intitule : "ST PIERRE DE CHIGNAC", cp : "24330" },
{ code : "24485", intitule : "ST PIERRE DE COLE", cp : "24800" },
{ code : "24486", intitule : "ST PIERRE DE FRUGIE", cp : "24450" },
{ code : "24487", intitule : "ST PIERRE D EYRAUD", cp : "24130" },
{ code : "24488", intitule : "ST POMPONT", cp : "24170" },
{ code : "24489", intitule : "ST PRIEST LES FOUGERES", cp : "24450" },
{ code : "24490", intitule : "ST PRIVAT DES PRES", cp : "24410" },
{ code : "24491", intitule : "ST RABIER", cp : "24210" },
{ code : "24492", intitule : "STE RADEGONDE", cp : "24560" },
{ code : "24493", intitule : "ST RAPHAEL", cp : "24160" },
{ code : "24494", intitule : "ST REMY", cp : "24700" },
{ code : "24495", intitule : "ST ROMAIN DE MONPAZIER", cp : "24540" },
{ code : "24496", intitule : "ST ROMAIN ET ST CLEMENT", cp : "24800" },
{ code : "24498", intitule : "ST SAUD LACOUSSIERE", cp : "24470" },
{ code : "24499", intitule : "ST SAUVEUR", cp : "24520" },
{ code : "24500", intitule : "ST SAUVEUR LALANDE", cp : "24700" },
{ code : "24501", intitule : "ST SEURIN DE PRATS", cp : "24230" },
{ code : "24502", intitule : "ST SEVERIN D ESTISSAC", cp : "24190" },
{ code : "24503", intitule : "ST SULPICE DE MAREUIL", cp : "24340" },
{ code : "24504", intitule : "ST SULPICE DE ROUMAGNAC", cp : "24600" },
{ code : "24505", intitule : "ST SULPICE D EXCIDEUIL", cp : "24800" },
{ code : "24507", intitule : "STE TRIE", cp : "24160" },
{ code : "24508", intitule : "ST VICTOR", cp : "24350" },
{ code : "24509", intitule : "ST VINCENT DE CONNEZAC", cp : "24190" },
{ code : "24510", intitule : "ST VINCENT DE COSSE", cp : "24220" },
{ code : "24511", intitule : "ST VINCENT JALMOUTIERS", cp : "24410" },
{ code : "24512", intitule : "ST VINCENT LE PALUEL", cp : "24200" },
{ code : "24513", intitule : "ST VINCENT SUR L ISLE", cp : "24420" },
{ code : "24514", intitule : "ST VIVIEN", cp : "24230" },
{ code : "24515", intitule : "SALAGNAC", cp : "24160" },
{ code : "24515", intitule : "SALAGNAC", cp : "24160" },
{ code : "24516", intitule : "SALIGNAC EYVIGUES", cp : "24590" },
{ code : "24517", intitule : "SALLES DE BELVES", cp : "24170" },
{ code : "24518", intitule : "SALON", cp : "24380" },
{ code : "24519", intitule : "SARLANDE", cp : "24270" },
{ code : "24520", intitule : "SARLAT LA CANEDA", cp : "24200" },
{ code : "24521", intitule : "SARLIAC SUR L ISLE", cp : "24420" },
{ code : "24522", intitule : "SARRAZAC", cp : "24800" },
{ code : "24523", intitule : "SAUSSIGNAC", cp : "24240" },
{ code : "24524", intitule : "SAVIGNAC DE MIREMONT", cp : "24260" },
{ code : "24525", intitule : "SAVIGNAC DE NONTRON", cp : "24300" },
{ code : "24526", intitule : "SAVIGNAC LEDRIER", cp : "24270" },
{ code : "24527", intitule : "SAVIGNAC LES EGLISES", cp : "24420" },
{ code : "24528", intitule : "SCEAU ST ANGEL", cp : "24300" },
{ code : "24529", intitule : "SEGONZAC", cp : "24600" },
{ code : "24530", intitule : "SENCENAC PUY DE FOURCHES", cp : "24310" },
{ code : "24531", intitule : "SERGEAC", cp : "24290" },
{ code : "24532", intitule : "SERRES ET MONTGUYARD", cp : "24500" },
{ code : "24533", intitule : "SERVANCHES", cp : "24410" },
{ code : "24534", intitule : "SIGOULES", cp : "24240" },
{ code : "24535", intitule : "SIMEYROLS", cp : "24370" },
{ code : "24536", intitule : "SINGLEYRAC", cp : "24500" },
{ code : "24537", intitule : "SIORAC DE RIBERAC", cp : "24600" },
{ code : "24538", intitule : "SIORAC EN PERIGORD", cp : "24170" },
{ code : "24540", intitule : "SORGES ET LIGUEUX EN PERIGORD", cp : "24420" },
{ code : "24540", intitule : "SORGES ET LIGUEUX EN PERIGORD", cp : "24460" },
{ code : "24541", intitule : "SOUDAT", cp : "24360" },
{ code : "24542", intitule : "SOULAURES", cp : "24540" },
{ code : "24543", intitule : "SOURZAC", cp : "24400" },
{ code : "24544", intitule : "TAMNIES", cp : "24620" },
{ code : "24545", intitule : "TEILLOTS", cp : "24390" },
{ code : "24546", intitule : "TEMPLE LAGUYON", cp : "24390" },
{ code : "24547", intitule : "TERRASSON LAVILLEDIEU", cp : "24120" },
{ code : "24548", intitule : "TEYJAT", cp : "24300" },
{ code : "24549", intitule : "THENAC", cp : "24240" },
{ code : "24549", intitule : "THENAC", cp : "24240" },
{ code : "24549", intitule : "THENAC", cp : "24240" },
{ code : "24550", intitule : "THENON", cp : "24210" },
{ code : "24551", intitule : "THIVIERS", cp : "24800" },
{ code : "24552", intitule : "THONAC", cp : "24290" },
{ code : "24553", intitule : "TOCANE ST APRE", cp : "24350" },
{ code : "24554", intitule : "LA TOUR BLANCHE", cp : "24320" },
{ code : "24555", intitule : "TOURTOIRAC", cp : "24390" },
{ code : "24557", intitule : "TRELISSAC", cp : "24750" },
{ code : "24558", intitule : "TREMOLAT", cp : "24510" },
{ code : "24559", intitule : "TURSAC", cp : "24620" },
{ code : "24560", intitule : "URVAL", cp : "24480" },
{ code : "24561", intitule : "VALEUIL", cp : "24310" },
{ code : "24562", intitule : "VALLEREUIL", cp : "24190" },
{ code : "24563", intitule : "VALOJOULX", cp : "24290" },
{ code : "24564", intitule : "VANXAINS", cp : "24600" },
{ code : "24565", intitule : "VARAIGNES", cp : "24360" },
{ code : "24566", intitule : "VARENNES", cp : "24150" },
{ code : "24567", intitule : "VAUNAC", cp : "24800" },
{ code : "24568", intitule : "VELINES", cp : "24230" },
{ code : "24569", intitule : "VENDOIRE", cp : "24320" },
{ code : "24570", intitule : "VERDON", cp : "24520" },
{ code : "24571", intitule : "VERGT", cp : "24380" },
{ code : "24572", intitule : "VERGT DE BIRON", cp : "24540" },
{ code : "24573", intitule : "VERTEILLAC", cp : "24320" },
{ code : "24574", intitule : "VEYRIGNAC", cp : "24370" },
{ code : "24575", intitule : "VEYRINES DE DOMME", cp : "24250" },
{ code : "24576", intitule : "VEYRINES DE VERGT", cp : "24380" },
{ code : "24577", intitule : "VEZAC", cp : "24220" },
{ code : "24579", intitule : "VIEUX MAREUIL", cp : "24340" },
{ code : "24580", intitule : "VILLAC", cp : "24120" },
{ code : "24581", intitule : "VILLAMBLARD", cp : "24140" },
{ code : "24582", intitule : "VILLARS", cp : "24530" },
{ code : "24584", intitule : "VILLEFRANCHE DE LONCHAT", cp : "24610" },
{ code : "24585", intitule : "VILLEFRANCHE DU PERIGORD", cp : "24550" },
{ code : "24586", intitule : "VILLETOUREIX", cp : "24600" },
{ code : "24587", intitule : "VITRAC", cp : "24200" },
{ code : "25001", intitule : "ABBANS DESSOUS", cp : "25320" },
{ code : "25002", intitule : "ABBANS DESSUS", cp : "25440" },
{ code : "25003", intitule : "ABBENANS", cp : "25340" },
{ code : "25004", intitule : "ABBEVILLERS", cp : "25310" },
{ code : "25005", intitule : "ACCOLANS", cp : "25250" },
{ code : "25006", intitule : "ADAM LES PASSAVANT", cp : "25360" },
{ code : "25007", intitule : "ADAM LES VERCEL", cp : "25530" },
{ code : "25008", intitule : "AIBRE", cp : "25750" },
{ code : "25009", intitule : "AISSEY", cp : "25360" },
{ code : "25011", intitule : "ALLENJOIE", cp : "25490" },
{ code : "25012", intitule : "LES ALLIES", cp : "25300" },
{ code : "25013", intitule : "ALLONDANS", cp : "25550" },
{ code : "25014", intitule : "AMAGNEY", cp : "25220" },
{ code : "25015", intitule : "AMANCEY", cp : "25330" },
{ code : "25016", intitule : "AMATHAY VESIGNEUX", cp : "25330" },
{ code : "25017", intitule : "AMONDANS", cp : "25330" },
{ code : "25018", intitule : "ANTEUIL", cp : "25340" },
{ code : "25018", intitule : "ANTEUIL", cp : "25340" },
{ code : "25018", intitule : "ANTEUIL", cp : "25340" },
{ code : "25019", intitule : "APPENANS", cp : "25250" },
{ code : "25020", intitule : "ARBOUANS", cp : "25400" },
{ code : "25021", intitule : "ARC ET SENANS", cp : "25610" },
{ code : "25022", intitule : "ARCEY", cp : "25750" },
{ code : "25024", intitule : "ARCON", cp : "25300" },
{ code : "25025", intitule : "ARC SOUS CICON", cp : "25520" },
{ code : "25026", intitule : "ARC SOUS MONTENOT", cp : "25270" },
{ code : "25027", intitule : "ARGUEL", cp : "25720" },
{ code : "25029", intitule : "AUBONNE", cp : "25520" },
{ code : "25030", intitule : "AUDEUX", cp : "25170" },
{ code : "25031", intitule : "AUDINCOURT", cp : "25400" },
{ code : "25032", intitule : "AUTECHAUX", cp : "25110" },
{ code : "25033", intitule : "AUTECHAUX ROIDE", cp : "25150" },
{ code : "25035", intitule : "LES AUXONS", cp : "25870" },
{ code : "25035", intitule : "LES AUXONS", cp : "25870" },
{ code : "25036", intitule : "AVANNE AVENEY", cp : "25720" },
{ code : "25036", intitule : "AVANNE AVENEY", cp : "25720" },
{ code : "25038", intitule : "AVILLEY", cp : "25680" },
{ code : "25039", intitule : "AVOUDREY", cp : "25690" },
{ code : "25040", intitule : "BADEVEL", cp : "25490" },
{ code : "25041", intitule : "BANNANS", cp : "25560" },
{ code : "25042", intitule : "LE BARBOUX", cp : "25210" },
{ code : "25043", intitule : "BART", cp : "25420" },
{ code : "25044", intitule : "BARTHERANS", cp : "25440" },
{ code : "25045", intitule : "BATTENANS LES MINES", cp : "25640" },
{ code : "25046", intitule : "BATTENANS VARIN", cp : "25380" },
{ code : "25047", intitule : "BAUME LES DAMES", cp : "25110" },
{ code : "25047", intitule : "BAUME LES DAMES", cp : "25110" },
{ code : "25048", intitule : "BAVANS", cp : "25550" },
{ code : "25049", intitule : "BELFAYS", cp : "25470" },
{ code : "25050", intitule : "LE BELIEU", cp : "25500" },
{ code : "25051", intitule : "BELLEHERBE", cp : "25380" },
{ code : "25051", intitule : "BELLEHERBE", cp : "25380" },
{ code : "25052", intitule : "BELMONT", cp : "25530" },
{ code : "25053", intitule : "BELVOIR", cp : "25430" },
{ code : "25054", intitule : "BERCHE", cp : "25420" },
{ code : "25055", intitule : "BERTHELANGE", cp : "25410" },
{ code : "25056", intitule : "BESANCON", cp : "25000" },
{ code : "25057", intitule : "BETHONCOURT", cp : "25200" },
{ code : "25058", intitule : "BEURE", cp : "25720" },
{ code : "25059", intitule : "BEUTAL", cp : "25250" },
{ code : "25060", intitule : "BIANS LES USIERS", cp : "25520" },
{ code : "25061", intitule : "BIEF", cp : "25190" },
{ code : "25062", intitule : "LE BIZOT", cp : "25210" },
{ code : "25063", intitule : "BLAMONT", cp : "25310" },
{ code : "25065", intitule : "BLARIANS", cp : "25640" },
{ code : "25066", intitule : "BLUSSANGEAUX", cp : "25250" },
{ code : "25067", intitule : "BLUSSANS", cp : "25250" },
{ code : "25070", intitule : "BOLANDOZ", cp : "25330" },
{ code : "25071", intitule : "BONDEVAL", cp : "25230" },
{ code : "25072", intitule : "BONNAL", cp : "25680" },
{ code : "25073", intitule : "BONNAY", cp : "25870" },
{ code : "25074", intitule : "BONNETAGE", cp : "25210" },
{ code : "25075", intitule : "BONNEVAUX", cp : "25560" },
{ code : "25077", intitule : "LA BOSSE", cp : "25210" },
{ code : "25078", intitule : "BOUCLANS", cp : "25360" },
{ code : "25079", intitule : "BOUJAILLES", cp : "25560" },
{ code : "25082", intitule : "BOURGUIGNON", cp : "25150" },
{ code : "25083", intitule : "BOURNOIS", cp : "25250" },
{ code : "25084", intitule : "BOUSSIERES", cp : "25320" },
{ code : "25085", intitule : "BOUVERANS", cp : "25560" },
{ code : "25086", intitule : "BRAILLANS", cp : "25640" },
{ code : "25087", intitule : "BRANNE", cp : "25340" },
{ code : "25088", intitule : "BRECONCHAUX", cp : "25640" },
{ code : "25089", intitule : "BREMONDANS", cp : "25530" },
{ code : "25090", intitule : "BRERES", cp : "25440" },
{ code : "25091", intitule : "LES BRESEUX", cp : "25120" },
{ code : "25092", intitule : "LA BRETENIERE", cp : "25640" },
{ code : "25093", intitule : "BRETIGNEY", cp : "25250" },
{ code : "25094", intitule : "BRETIGNEY NOTRE DAME", cp : "25110" },
{ code : "25095", intitule : "BRETONVILLERS", cp : "25380" },
{ code : "25096", intitule : "BREY ET MAISON DU BOIS", cp : "25240" },
{ code : "25097", intitule : "BROGNARD", cp : "25600" },
{ code : "25098", intitule : "BUFFARD", cp : "25440" },
{ code : "25099", intitule : "BUGNY", cp : "25520" },
{ code : "25100", intitule : "BULLE", cp : "25560" },
{ code : "25101", intitule : "BURGILLE", cp : "25170" },
{ code : "25101", intitule : "BURGILLE", cp : "25170" },
{ code : "25101", intitule : "BURGILLE", cp : "25170" },
{ code : "25102", intitule : "BURNEVILLERS", cp : "25470" },
{ code : "25103", intitule : "BUSY", cp : "25320" },
{ code : "25104", intitule : "BY", cp : "25440" },
{ code : "25105", intitule : "BYANS SUR DOUBS", cp : "25320" },
{ code : "25106", intitule : "CADEMENE", cp : "25290" },
{ code : "25107", intitule : "CENDREY", cp : "25640" },
{ code : "25108", intitule : "CERNAY L EGLISE", cp : "25120" },
{ code : "25109", intitule : "CESSEY", cp : "25440" },
{ code : "25110", intitule : "CHAFFOIS", cp : "25300" },
{ code : "25111", intitule : "CHALEZE", cp : "25220" },
{ code : "25112", intitule : "CHALEZEULE", cp : "25220" },
{ code : "25113", intitule : "CHAMESEY", cp : "25380" },
{ code : "25114", intitule : "CHAMESOL", cp : "25190" },
{ code : "25115", intitule : "CHAMPAGNEY", cp : "25170" },
{ code : "25116", intitule : "CHAMPLIVE", cp : "25360" },
{ code : "25117", intitule : "CHAMPOUX", cp : "25640" },
{ code : "25119", intitule : "CHAMPVANS LES MOULINS", cp : "25170" },
{ code : "25120", intitule : "CHANTRANS", cp : "25330" },
{ code : "25121", intitule : "CHAPELLE DES BOIS", cp : "25240" },
{ code : "25122", intitule : "CHAPELLE D HUIN", cp : "25270" },
{ code : "25123", intitule : "CHARBONNIERES LES SAPINS", cp : "25620" },
{ code : "25124", intitule : "CHARMAUVILLERS", cp : "25470" },
{ code : "25125", intitule : "CHARMOILLE", cp : "25380" },
{ code : "25126", intitule : "CHARNAY", cp : "25440" },
{ code : "25127", intitule : "CHARQUEMONT", cp : "25140" },
{ code : "25127", intitule : "CHARQUEMONT", cp : "25140" },
{ code : "25129", intitule : "CHASSAGNE ST DENIS", cp : "25290" },
{ code : "25130", intitule : "CHATEAUVIEUX LES FOSSES", cp : "25840" },
{ code : "25131", intitule : "CHATELBLANC", cp : "25240" },
{ code : "25132", intitule : "CHATILLON GUYOTTE", cp : "25640" },
{ code : "25133", intitule : "CHATILLON LE DUC", cp : "25870" },
{ code : "25134", intitule : "CHATILLON SUR LISON", cp : "25440" },
{ code : "25136", intitule : "CHAUCENNE", cp : "25170" },
{ code : "25137", intitule : "CHAUDEFONTAINE", cp : "25640" },
{ code : "25138", intitule : "LES TERRES DE CHAUX", cp : "25190" },
{ code : "25139", intitule : "LA CHAUX", cp : "25650" },
{ code : "25140", intitule : "CHAUX LES CLERVAL", cp : "25340" },
{ code : "25141", intitule : "CHAUX LES PASSAVANT", cp : "25530" },
{ code : "25142", intitule : "CHAUX NEUVE", cp : "25240" },
{ code : "25143", intitule : "CHAY", cp : "25440" },
{ code : "25145", intitule : "CHAZOT", cp : "25430" },
{ code : "25147", intitule : "CHEMAUDIN", cp : "25320" },
{ code : "25148", intitule : "LA CHENALOTTE", cp : "25500" },
{ code : "25149", intitule : "CHENECEY BUILLON", cp : "25440" },
{ code : "25150", intitule : "CHEVIGNEY SUR L OGNON", cp : "25170" },
{ code : "25151", intitule : "CHEVIGNEY LES VERCEL", cp : "25530" },
{ code : "25152", intitule : "LA CHEVILLOTTE", cp : "25620" },
{ code : "25153", intitule : "CHEVROZ", cp : "25870" },
{ code : "25154", intitule : "CHOUZELOT", cp : "25440" },
{ code : "25155", intitule : "CLERON", cp : "25330" },
{ code : "25156", intitule : "CLERVAL", cp : "25340" },
{ code : "25157", intitule : "LA CLUSE ET MIJOUX", cp : "25300" },
{ code : "25159", intitule : "COLOMBIER FONTAINE", cp : "25260" },
{ code : "25160", intitule : "LES COMBES", cp : "25500" },
{ code : "25161", intitule : "CONSOLATION MAISONNETTES", cp : "25390" },
{ code : "25162", intitule : "CORCELLES FERRIERES", cp : "25410" },
{ code : "25163", intitule : "CORCELLE MIESLOT", cp : "25640" },
{ code : "25164", intitule : "CORCONDRAY", cp : "25410" },
{ code : "25166", intitule : "COTEBRUNE", cp : "25360" },
{ code : "25170", intitule : "COURCELLES LES MONTBELIARD", cp : "25420" },
{ code : "25171", intitule : "COURCELLES", cp : "25440" },
{ code : "25172", intitule : "COURCHAPON", cp : "25170" },
{ code : "25173", intitule : "COUR ST MAURICE", cp : "25380" },
{ code : "25174", intitule : "COURTEFONTAINE", cp : "25470" },
{ code : "25175", intitule : "COURTETAIN ET SALANS", cp : "25530" },
{ code : "25176", intitule : "COURVIERES", cp : "25560" },
{ code : "25177", intitule : "CROSEY LE GRAND", cp : "25340" },
{ code : "25178", intitule : "CROSEY LE PETIT", cp : "25340" },
{ code : "25179", intitule : "LE CROUZET", cp : "25240" },
{ code : "25180", intitule : "CROUZET MIGETTE", cp : "25270" },
{ code : "25181", intitule : "CUBRIAL", cp : "25680" },
{ code : "25182", intitule : "CUBRY", cp : "25680" },
{ code : "25183", intitule : "CUSANCE", cp : "25110" },
{ code : "25184", intitule : "CUSE ET ADRISANS", cp : "25680" },
{ code : "25185", intitule : "CUSSEY SUR LISON", cp : "25440" },
{ code : "25186", intitule : "CUSSEY SUR L OGNON", cp : "25870" },
{ code : "25187", intitule : "DAMBELIN", cp : "25150" },
{ code : "25187", intitule : "DAMBELIN", cp : "25150" },
{ code : "25188", intitule : "DAMBENOIS", cp : "25600" },
{ code : "25189", intitule : "DAMMARTIN LES TEMPLIERS", cp : "25110" },
{ code : "25190", intitule : "DAMPIERRE LES BOIS", cp : "25490" },
{ code : "25191", intitule : "DAMPIERRE SUR LE DOUBS", cp : "25420" },
{ code : "25192", intitule : "DAMPJOUX", cp : "25190" },
{ code : "25193", intitule : "DAMPRICHARD", cp : "25450" },
{ code : "25194", intitule : "DANNEMARIE", cp : "25310" },
{ code : "25195", intitule : "DANNEMARIE SUR CRETE", cp : "25410" },
{ code : "25196", intitule : "DASLE", cp : "25230" },
{ code : "25197", intitule : "DELUZ", cp : "25960" },
{ code : "25198", intitule : "DESANDANS", cp : "25750" },
{ code : "25199", intitule : "DESERVILLERS", cp : "25330" },
{ code : "25200", intitule : "DEVECEY", cp : "25870" },
{ code : "25201", intitule : "DOMMARTIN", cp : "25300" },
{ code : "25202", intitule : "DOMPIERRE LES TILLEULS", cp : "25560" },
{ code : "25203", intitule : "DOMPREL", cp : "25510" },
{ code : "25204", intitule : "DOUBS", cp : "25300" },
{ code : "25207", intitule : "DUNG", cp : "25550" },
{ code : "25208", intitule : "DURNES", cp : "25580" },
{ code : "25209", intitule : "ECHAY", cp : "25440" },
{ code : "25210", intitule : "ECHENANS", cp : "25550" },
{ code : "25211", intitule : "ECHEVANNES", cp : "25580" },
{ code : "25212", intitule : "ECOLE VALENTIN", cp : "25480" },
{ code : "25212", intitule : "ECOLE VALENTIN", cp : "25480" },
{ code : "25213", intitule : "LES ECORCES", cp : "25140" },
{ code : "25214", intitule : "ECOT", cp : "25150" },
{ code : "25215", intitule : "L ECOUVOTTE", cp : "25640" },
{ code : "25216", intitule : "ECURCEY", cp : "25150" },
{ code : "25217", intitule : "EMAGNY", cp : "25170" },
{ code : "25218", intitule : "EPENOUSE", cp : "25530" },
{ code : "25219", intitule : "EPENOY", cp : "25800" },
{ code : "25220", intitule : "EPEUGNEY", cp : "25290" },
{ code : "25221", intitule : "ESNANS", cp : "25110" },
{ code : "25222", intitule : "ETALANS", cp : "25580" },
{ code : "25223", intitule : "ETERNOZ", cp : "25330" },
{ code : "25223", intitule : "ETERNOZ", cp : "25330" },
{ code : "25223", intitule : "ETERNOZ", cp : "25330" },
{ code : "25223", intitule : "ETERNOZ", cp : "25330" },
{ code : "25223", intitule : "ETERNOZ", cp : "25330" },
{ code : "25224", intitule : "ETOUVANS", cp : "25260" },
{ code : "25225", intitule : "ETRABONNE", cp : "25170" },
{ code : "25226", intitule : "ETRAPPE", cp : "25250" },
{ code : "25227", intitule : "ETRAY", cp : "25800" },
{ code : "25228", intitule : "ETUPES", cp : "25460" },
{ code : "25229", intitule : "EVILLERS", cp : "25520" },
{ code : "25230", intitule : "EXINCOURT", cp : "25400" },
{ code : "25231", intitule : "EYSSON", cp : "25530" },
{ code : "25232", intitule : "FAIMBE", cp : "25250" },
{ code : "25233", intitule : "FALLERANS", cp : "25580" },
{ code : "25234", intitule : "FERRIERES LE LAC", cp : "25470" },
{ code : "25235", intitule : "FERRIERES LES BOIS", cp : "25410" },
{ code : "25236", intitule : "FERTANS", cp : "25330" },
{ code : "25237", intitule : "FESCHES LE CHATEL", cp : "25490" },
{ code : "25238", intitule : "FESSEVILLERS", cp : "25470" },
{ code : "25239", intitule : "FEULE", cp : "25190" },
{ code : "25240", intitule : "LES FINS", cp : "25500" },
{ code : "25241", intitule : "FLAGEY", cp : "25330" },
{ code : "25242", intitule : "FLAGEY RIGNEY", cp : "25640" },
{ code : "25243", intitule : "FLANGEBOUCHE", cp : "25390" },
{ code : "25244", intitule : "FLEUREY", cp : "25190" },
{ code : "25245", intitule : "FONTAIN", cp : "25660" },
{ code : "25246", intitule : "FONTAINE LES CLERVAL", cp : "25340" },
{ code : "25247", intitule : "FONTENELLE MONTBY", cp : "25340" },
{ code : "25248", intitule : "LES FONTENELLES", cp : "25210" },
{ code : "25249", intitule : "FONTENOTTE", cp : "25110" },
{ code : "25250", intitule : "FOUCHERANS", cp : "25620" },
{ code : "25251", intitule : "FOURBANNE", cp : "25110" },
{ code : "25252", intitule : "FOURCATIER ET MAISON NEUVE", cp : "25370" },
{ code : "25253", intitule : "FOURG", cp : "25440" },
{ code : "25254", intitule : "LES FOURGS", cp : "25300" },
{ code : "25255", intitule : "FOURNET BLANCHEROCHE", cp : "25140" },
{ code : "25256", intitule : "FRAMBOUHANS", cp : "25140" },
{ code : "25257", intitule : "FRANEY", cp : "25170" },
{ code : "25258", intitule : "FRANOIS", cp : "25770" },
{ code : "25259", intitule : "FRASNE", cp : "25560" },
{ code : "25261", intitule : "FROIDEVAUX", cp : "25190" },
{ code : "25262", intitule : "FUANS", cp : "25390" },
{ code : "25263", intitule : "GELLIN", cp : "25240" },
{ code : "25264", intitule : "GEMONVAL", cp : "25250" },
{ code : "25265", intitule : "GENEUILLE", cp : "25870" },
{ code : "25266", intitule : "GENEY", cp : "25250" },
{ code : "25267", intitule : "GENNES", cp : "25660" },
{ code : "25268", intitule : "GERMEFONTAINE", cp : "25510" },
{ code : "25269", intitule : "GERMONDANS", cp : "25640" },
{ code : "25270", intitule : "GEVRESIN", cp : "25270" },
{ code : "25271", intitule : "GILLEY", cp : "25650" },
{ code : "25273", intitule : "GLAMONDANS", cp : "25360" },
{ code : "25274", intitule : "GLAY", cp : "25310" },
{ code : "25275", intitule : "GLERE", cp : "25190" },
{ code : "25275", intitule : "GLERE", cp : "25190" },
{ code : "25275", intitule : "GLERE", cp : "25190" },
{ code : "25276", intitule : "GONDENANS MONTBY", cp : "25340" },
{ code : "25277", intitule : "GONDENANS LES MOULINS", cp : "25680" },
{ code : "25278", intitule : "GONSANS", cp : "25360" },
{ code : "25279", intitule : "GOUHELANS", cp : "25680" },
{ code : "25280", intitule : "GOUMOIS", cp : "25470" },
{ code : "25281", intitule : "GOUX LES DAMBELIN", cp : "25150" },
{ code : "25282", intitule : "GOUX LES USIERS", cp : "25520" },
{ code : "25283", intitule : "GOUX SOUS LANDET", cp : "25440" },
{ code : "25284", intitule : "GRAND CHARMONT", cp : "25200" },
{ code : "25285", intitule : "GRAND COMBE CHATELEU", cp : "25570" },
{ code : "25286", intitule : "GRAND COMBE DES BOIS", cp : "25210" },
{ code : "25287", intitule : "GRANDFONTAINE", cp : "25320" },
{ code : "25288", intitule : "FOURNETS LUISANS", cp : "25390" },
{ code : "25288", intitule : "FOURNETS LUISANS", cp : "25390" },
{ code : "25289", intitule : "GRANDFONTAINE SUR CREUSE", cp : "25510" },
{ code : "25290", intitule : "LA GRANGE", cp : "25380" },
{ code : "25293", intitule : "GRANGES NARBOZ", cp : "25300" },
{ code : "25295", intitule : "LES GRANGETTES", cp : "25160" },
{ code : "25296", intitule : "LES GRAS", cp : "25790" },
{ code : "25297", intitule : "LE GRATTERIS", cp : "25620" },
{ code : "25298", intitule : "GROSBOIS", cp : "25110" },
{ code : "25299", intitule : "GUILLON LES BAINS", cp : "25110" },
{ code : "25300", intitule : "GUYANS DURNES", cp : "25580" },
{ code : "25301", intitule : "GUYANS VENNES", cp : "25390" },
{ code : "25303", intitule : "HAUTERIVE LA FRESSE", cp : "25650" },
{ code : "25304", intitule : "HERIMONCOURT", cp : "25310" },
{ code : "25305", intitule : "L HOPITAL DU GROSBOIS", cp : "25620" },
{ code : "25306", intitule : "L HOPITAL ST LIEFFROY", cp : "25340" },
{ code : "25307", intitule : "LES HOPITAUX NEUFS", cp : "25370" },
{ code : "25308", intitule : "LES HOPITAUX VIEUX", cp : "25370" },
{ code : "25309", intitule : "HOUTAUD", cp : "25300" },
{ code : "25310", intitule : "HUANNE MONTMARTIN", cp : "25680" },
{ code : "25311", intitule : "HYEMONDANS", cp : "25250" },
{ code : "25312", intitule : "HYEVRE MAGNY", cp : "25110" },
{ code : "25313", intitule : "HYEVRE PAROISSE", cp : "25110" },
{ code : "25313", intitule : "HYEVRE PAROISSE", cp : "25110" },
{ code : "25314", intitule : "INDEVILLERS", cp : "25470" },
{ code : "25315", intitule : "L ISLE SUR LE DOUBS", cp : "25250" },
{ code : "25316", intitule : "ISSANS", cp : "25550" },
{ code : "25317", intitule : "JALLERANGE", cp : "25170" },
{ code : "25318", intitule : "JOUGNE", cp : "25370" },
{ code : "25319", intitule : "LABERGEMENT DU NAVOIS", cp : "25270" },
{ code : "25320", intitule : "LABERGEMENT STE MARIE", cp : "25160" },
{ code : "25320", intitule : "LABERGEMENT STE MARIE", cp : "25160" },
{ code : "25321", intitule : "VILLERS LE LAC", cp : "25130" },
{ code : "25322", intitule : "LAIRE", cp : "25550" },
{ code : "25323", intitule : "LAISSEY", cp : "25820" },
{ code : "25324", intitule : "LANANS", cp : "25360" },
{ code : "25325", intitule : "LANDRESSE", cp : "25530" },
{ code : "25326", intitule : "LANTENNE VERTIERE", cp : "25170" },
{ code : "25327", intitule : "LANTHENANS", cp : "25250" },
{ code : "25328", intitule : "LARNOD", cp : "25720" },
{ code : "25329", intitule : "LAVAL LE PRIEURE", cp : "25210" },
{ code : "25330", intitule : "LAVANS QUINGEY", cp : "25440" },
{ code : "25331", intitule : "LAVANS VUILLAFANS", cp : "25580" },
{ code : "25332", intitule : "LAVERNAY", cp : "25170" },
{ code : "25333", intitule : "LAVIRON", cp : "25510" },
{ code : "25334", intitule : "LEVIER", cp : "25270" },
{ code : "25334", intitule : "LEVIER", cp : "25270" },
{ code : "25335", intitule : "LIEBVILLERS", cp : "25190" },
{ code : "25336", intitule : "LIESLE", cp : "25440" },
{ code : "25338", intitule : "LIZINE", cp : "25330" },
{ code : "25339", intitule : "LODS", cp : "25930" },
{ code : "25340", intitule : "LOMBARD", cp : "25440" },
{ code : "25341", intitule : "LOMONT SUR CRETE", cp : "25110" },
{ code : "25342", intitule : "LONGECHAUX", cp : "25690" },
{ code : "25343", intitule : "LONGEMAISON", cp : "25690" },
{ code : "25344", intitule : "LONGEVELLE LES RUSSEY", cp : "25380" },
{ code : "25345", intitule : "LONGEVELLE SUR DOUBS", cp : "25260" },
{ code : "25346", intitule : "LONGEVILLE", cp : "25330" },
{ code : "25347", intitule : "LA LONGEVILLE", cp : "25650" },
{ code : "25348", intitule : "LONGEVILLES MONT D OR", cp : "25370" },
{ code : "25349", intitule : "LORAY", cp : "25390" },
{ code : "25350", intitule : "LOUGRES", cp : "25260" },
{ code : "25351", intitule : "LE LUHIER", cp : "25210" },
{ code : "25354", intitule : "LUXIOL", cp : "25110" },
{ code : "25355", intitule : "MAGNY CHATELARD", cp : "25360" },
{ code : "25356", intitule : "MAICHE", cp : "25120" },
{ code : "25357", intitule : "MAISONS DU BOIS LIEVREMONT", cp : "25650" },
{ code : "25357", intitule : "MAISONS DU BOIS LIEVREMONT", cp : "25650" },
{ code : "25359", intitule : "MALANS", cp : "25330" },
{ code : "25360", intitule : "MALBRANS", cp : "25620" },
{ code : "25361", intitule : "MALBUISSON", cp : "25160" },
{ code : "25362", intitule : "MALPAS", cp : "25160" },
{ code : "25364", intitule : "MAMIROLLE", cp : "25620" },
{ code : "25365", intitule : "MANCENANS", cp : "25250" },
{ code : "25366", intitule : "MANCENANS LIZERNE", cp : "25120" },
{ code : "25367", intitule : "MANDEURE", cp : "25350" },
{ code : "25368", intitule : "MARCHAUX", cp : "25640" },
{ code : "25369", intitule : "MARVELISE", cp : "25250" },
{ code : "25370", intitule : "MATHAY", cp : "25700" },
{ code : "25371", intitule : "MAZEROLLES LE SALIN", cp : "25170" },
{ code : "25372", intitule : "MEDIERE", cp : "25250" },
{ code : "25373", intitule : "LE MEMONT", cp : "25210" },
{ code : "25374", intitule : "MERCEY LE GRAND", cp : "25410" },
{ code : "25374", intitule : "MERCEY LE GRAND", cp : "25410" },
{ code : "25375", intitule : "MEREY SOUS MONTROND", cp : "25660" },
{ code : "25376", intitule : "MEREY VIEILLEY", cp : "25870" },
{ code : "25377", intitule : "MESANDANS", cp : "25680" },
{ code : "25378", intitule : "MESLIERES", cp : "25310" },
{ code : "25379", intitule : "MESMAY", cp : "25440" },
{ code : "25380", intitule : "METABIEF", cp : "25370" },
{ code : "25381", intitule : "MISEREY SALINES", cp : "25480" },
{ code : "25382", intitule : "MONCEY", cp : "25870" },
{ code : "25383", intitule : "MONCLEY", cp : "25170" },
{ code : "25384", intitule : "MONDON", cp : "25680" },
{ code : "25385", intitule : "MONTAGNEY SERVIGNEY", cp : "25680" },
{ code : "25385", intitule : "MONTAGNEY SERVIGNEY", cp : "25680" },
{ code : "25386", intitule : "MONTANCY", cp : "25190" },
{ code : "25387", intitule : "MONTANDON", cp : "25190" },
{ code : "25388", intitule : "MONTBELIARD", cp : "25200" },
{ code : "25389", intitule : "MONTBELIARDOT", cp : "25210" },
{ code : "25390", intitule : "MONTBENOIT", cp : "25650" },
{ code : "25391", intitule : "MONT DE LAVAL", cp : "25210" },
{ code : "25392", intitule : "MONT DE VOUGNEY", cp : "25120" },
{ code : "25392", intitule : "MONT DE VOUGNEY", cp : "25120" },
{ code : "25393", intitule : "MONTECHEROUX", cp : "25190" },
{ code : "25394", intitule : "MONTENOIS", cp : "25260" },
{ code : "25395", intitule : "MONTFAUCON", cp : "25660" },
{ code : "25397", intitule : "MONTFERRAND LE CHATEAU", cp : "25320" },
{ code : "25398", intitule : "MONTFLOVIN", cp : "25650" },
{ code : "25399", intitule : "MONTFORT", cp : "25440" },
{ code : "25400", intitule : "MONTGESOYE", cp : "25111" },
{ code : "25401", intitule : "MONTIVERNAGE", cp : "25110" },
{ code : "25402", intitule : "MONTJOIE LE CHATEAU", cp : "25190" },
{ code : "25403", intitule : "MONTLEBON", cp : "25500" },
{ code : "25404", intitule : "MONTMAHOUX", cp : "25270" },
{ code : "25405", intitule : "MONTPERREUX", cp : "25160" },
{ code : "25406", intitule : "MONTROND LE CHATEAU", cp : "25660" },
{ code : "25408", intitule : "MONTUSSAINT", cp : "25680" },
{ code : "25410", intitule : "MORRE", cp : "25660" },
{ code : "25411", intitule : "MORTEAU", cp : "25500" },
{ code : "25413", intitule : "MOUTHE", cp : "25240" },
{ code : "25414", intitule : "LE MOUTHEROT", cp : "25170" },
{ code : "25415", intitule : "MOUTHIER HAUTE PIERRE", cp : "25920" },
{ code : "25416", intitule : "MYON", cp : "25440" },
{ code : "25417", intitule : "NAISEY LES GRANGES", cp : "25360" },
{ code : "25417", intitule : "NAISEY LES GRANGES", cp : "25360" },
{ code : "25418", intitule : "NANCRAY", cp : "25360" },
{ code : "25419", intitule : "NANS", cp : "25680" },
{ code : "25420", intitule : "NANS SOUS STE ANNE", cp : "25330" },
{ code : "25421", intitule : "NARBIEF", cp : "25210" },
{ code : "25422", intitule : "NEUCHATEL URTIERE", cp : "25150" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25424", intitule : "LES PREMIERS SAPINS", cp : "25580" },
{ code : "25425", intitule : "NOEL CERNEUX", cp : "25500" },
{ code : "25426", intitule : "NOIREFONTAINE", cp : "25190" },
{ code : "25427", intitule : "NOIRONTE", cp : "25170" },
{ code : "25428", intitule : "NOMMAY", cp : "25600" },
{ code : "25429", intitule : "NOVILLARS", cp : "25220" },
{ code : "25430", intitule : "OLLANS", cp : "25640" },
{ code : "25431", intitule : "ONANS", cp : "25250" },
{ code : "25432", intitule : "ORCHAMPS VENNES", cp : "25390" },
{ code : "25433", intitule : "ORGEANS BLANCHEFONTAINE", cp : "25120" },
{ code : "25433", intitule : "ORGEANS BLANCHEFONTAINE", cp : "25120" },
{ code : "25434", intitule : "ORNANS", cp : "25620" },
{ code : "25434", intitule : "ORNANS", cp : "25290" },
{ code : "25435", intitule : "ORSANS", cp : "25530" },
{ code : "25436", intitule : "ORVE", cp : "25430" },
{ code : "25437", intitule : "OSSE", cp : "25360" },
{ code : "25438", intitule : "OSSELLE ROUTELLE", cp : "25320" },
{ code : "25438", intitule : "OSSELLE ROUTELLE", cp : "25410" },
{ code : "25439", intitule : "OUGNEY DOUVOT", cp : "25640" },
{ code : "25440", intitule : "OUHANS", cp : "25520" },
{ code : "25441", intitule : "OUVANS", cp : "25530" },
{ code : "25442", intitule : "OYE ET PALLET", cp : "25160" },
{ code : "25443", intitule : "PALANTINE", cp : "25440" },
{ code : "25444", intitule : "PALISE", cp : "25870" },
{ code : "25445", intitule : "PAROY", cp : "25440" },
{ code : "25446", intitule : "PASSAVANT", cp : "25360" },
{ code : "25447", intitule : "PASSONFONTAINE", cp : "25690" },
{ code : "25448", intitule : "PELOUSEY", cp : "25170" },
{ code : "25449", intitule : "PESEUX", cp : "25190" },
{ code : "25450", intitule : "PESSANS", cp : "25440" },
{ code : "25451", intitule : "PETITE CHAUX", cp : "25240" },
{ code : "25452", intitule : "PIERREFONTAINE LES BLAMONT", cp : "25310" },
{ code : "25453", intitule : "PIERREFONTAINE LES VARANS", cp : "25510" },
{ code : "25454", intitule : "PIREY", cp : "25480" },
{ code : "25455", intitule : "PLACEY", cp : "25170" },
{ code : "25456", intitule : "PLAIMBOIS DU MIROIR", cp : "25210" },
{ code : "25457", intitule : "PLAIMBOIS VENNES", cp : "25390" },
{ code : "25458", intitule : "LES PLAINS ET GRANDS ESSARTS", cp : "25470" },
{ code : "25459", intitule : "LA PLANEE", cp : "25160" },
{ code : "25460", intitule : "POINTVILLERS", cp : "25440" },
{ code : "25461", intitule : "POMPIERRE SUR DOUBS", cp : "25340" },
{ code : "25462", intitule : "PONTARLIER", cp : "25300" },
{ code : "25463", intitule : "PONT DE ROIDE VERMONDANS", cp : "25150" },
{ code : "25463", intitule : "PONT DE ROIDE VERMONDANS", cp : "25150" },
{ code : "25464", intitule : "LES PONTETS", cp : "25240" },
{ code : "25465", intitule : "PONT LES MOULINS", cp : "25110" },
{ code : "25466", intitule : "POUILLEY FRANCAIS", cp : "25410" },
{ code : "25467", intitule : "POUILLEY LES VIGNES", cp : "25115" },
{ code : "25468", intitule : "POULIGNEY LUSANS", cp : "25640" },
{ code : "25468", intitule : "POULIGNEY LUSANS", cp : "25640" },
{ code : "25469", intitule : "PRESENTEVILLERS", cp : "25550" },
{ code : "25470", intitule : "LA PRETIERE", cp : "25250" },
{ code : "25471", intitule : "PROVENCHERE", cp : "25380" },
{ code : "25472", intitule : "PUESSANS", cp : "25680" },
{ code : "25473", intitule : "PUGEY", cp : "25720" },
{ code : "25474", intitule : "LE PUY", cp : "25640" },
{ code : "25475", intitule : "QUINGEY", cp : "25440" },
{ code : "25476", intitule : "RAHON", cp : "25430" },
{ code : "25477", intitule : "RANCENAY", cp : "25320" },
{ code : "25478", intitule : "RANDEVILLERS", cp : "25430" },
{ code : "25479", intitule : "RANG", cp : "25250" },
{ code : "25481", intitule : "RAYNANS", cp : "25550" },
{ code : "25482", intitule : "RECOLOGNE", cp : "25170" },
{ code : "25483", intitule : "RECULFOZ", cp : "25240" },
{ code : "25485", intitule : "REMONDANS VAIVRE", cp : "25150" },
{ code : "25485", intitule : "REMONDANS VAIVRE", cp : "25150" },
{ code : "25486", intitule : "REMORAY BOUJEONS", cp : "25160" },
{ code : "25486", intitule : "REMORAY BOUJEONS", cp : "25160" },
{ code : "25487", intitule : "RENEDALE", cp : "25520" },
{ code : "25488", intitule : "RENNES SUR LOUE", cp : "25440" },
{ code : "25489", intitule : "REUGNEY", cp : "25330" },
{ code : "25490", intitule : "RIGNEY", cp : "25640" },
{ code : "25491", intitule : "RIGNOSOT", cp : "25640" },
{ code : "25492", intitule : "RILLANS", cp : "25110" },
{ code : "25493", intitule : "LA RIVIERE DRUGEON", cp : "25560" },
{ code : "25494", intitule : "ROCHEJEAN", cp : "25370" },
{ code : "25495", intitule : "ROCHE LEZ BEAUPRE", cp : "25220" },
{ code : "25496", intitule : "ROCHE LES CLERVAL", cp : "25340" },
{ code : "25497", intitule : "ROCHES LES BLAMONT", cp : "25310" },
{ code : "25498", intitule : "ROGNON", cp : "25680" },
{ code : "25499", intitule : "ROMAIN", cp : "25680" },
{ code : "25500", intitule : "RONCHAUX", cp : "25440" },
{ code : "25501", intitule : "RONDEFONTAINE", cp : "25240" },
{ code : "25502", intitule : "ROSET FLUANS", cp : "25410" },
{ code : "25503", intitule : "ROSIERES SUR BARBECHE", cp : "25190" },
{ code : "25504", intitule : "ROSUREUX", cp : "25380" },
{ code : "25505", intitule : "ROUGEMONT", cp : "25680" },
{ code : "25505", intitule : "ROUGEMONT", cp : "25680" },
{ code : "25505", intitule : "ROUGEMONT", cp : "25680" },
{ code : "25505", intitule : "ROUGEMONT", cp : "25680" },
{ code : "25506", intitule : "ROUGEMONTOT", cp : "25640" },
{ code : "25507", intitule : "ROUHE", cp : "25440" },
{ code : "25508", intitule : "ROULANS", cp : "25640" },
{ code : "25510", intitule : "RUFFEY LE CHATEAU", cp : "25170" },
{ code : "25511", intitule : "RUREY", cp : "25290" },
{ code : "25512", intitule : "LE RUSSEY", cp : "25210" },
{ code : "25513", intitule : "STE ANNE", cp : "25270" },
{ code : "25514", intitule : "ST ANTOINE", cp : "25370" },
{ code : "25515", intitule : "STE COLOMBE", cp : "25300" },
{ code : "25516", intitule : "ST GEORGES ARMONT", cp : "25340" },
{ code : "25517", intitule : "ST GORGON MAIN", cp : "25520" },
{ code : "25518", intitule : "ST HILAIRE", cp : "25640" },
{ code : "25519", intitule : "ST HIPPOLYTE", cp : "25190" },
{ code : "25519", intitule : "ST HIPPOLYTE", cp : "25190" },
{ code : "25520", intitule : "ST JUAN", cp : "25360" },
{ code : "25521", intitule : "ST JULIEN LES MONTBELIARD", cp : "25550" },
{ code : "25522", intitule : "ST JULIEN LES RUSSEY", cp : "25210" },
{ code : "25523", intitule : "STE MARIE", cp : "25113" },
{ code : "25524", intitule : "ST MAURICE COLOMBIER", cp : "25260" },
{ code : "25524", intitule : "ST MAURICE COLOMBIER", cp : "25260" },
{ code : "25525", intitule : "ST POINT LAC", cp : "25160" },
{ code : "25526", intitule : "STE SUZANNE", cp : "25630" },
{ code : "25527", intitule : "ST VIT", cp : "25410" },
{ code : "25527", intitule : "ST VIT", cp : "25410" },
{ code : "25528", intitule : "SAMSON", cp : "25440" },
{ code : "25529", intitule : "SANCEY", cp : "25430" },
{ code : "25529", intitule : "SANCEY", cp : "25430" },
{ code : "25531", intitule : "SANTOCHE", cp : "25340" },
{ code : "25532", intitule : "SAONE", cp : "25660" },
{ code : "25533", intitule : "SARAZ", cp : "25330" },
{ code : "25534", intitule : "SARRAGEOIS", cp : "25240" },
{ code : "25535", intitule : "SAULES", cp : "25580" },
{ code : "25536", intitule : "SAUVAGNEY", cp : "25170" },
{ code : "25537", intitule : "SCEY MAISIERES", cp : "25290" },
{ code : "25537", intitule : "SCEY MAISIERES", cp : "25290" },
{ code : "25538", intitule : "SECHIN", cp : "25110" },
{ code : "25539", intitule : "SELONCOURT", cp : "25230" },
{ code : "25540", intitule : "SEMONDANS", cp : "25750" },
{ code : "25541", intitule : "SEPTFONTAINES", cp : "25270" },
{ code : "25542", intitule : "SERRE LES SAPINS", cp : "25770" },
{ code : "25544", intitule : "SERVIN", cp : "25430" },
{ code : "25545", intitule : "SILLEY AMANCEY", cp : "25330" },
{ code : "25546", intitule : "SILLEY BLEFOND", cp : "25110" },
{ code : "25547", intitule : "SOCHAUX", cp : "25600" },
{ code : "25548", intitule : "SOLEMONT", cp : "25190" },
{ code : "25549", intitule : "SOMBACOUR", cp : "25520" },
{ code : "25550", intitule : "LA SOMMETTE", cp : "25510" },
{ code : "25551", intitule : "SOULCE CERNAY", cp : "25190" },
{ code : "25552", intitule : "SOURANS", cp : "25250" },
{ code : "25553", intitule : "SOYE", cp : "25250" },
{ code : "25554", intitule : "SURMONT", cp : "25380" },
{ code : "25555", intitule : "TAILLECOURT", cp : "25400" },
{ code : "25556", intitule : "TALLANS", cp : "25680" },
{ code : "25557", intitule : "TALLENAY", cp : "25870" },
{ code : "25558", intitule : "TARCENAY", cp : "25620" },
{ code : "25559", intitule : "THIEBOUHANS", cp : "25470" },
{ code : "25560", intitule : "THISE", cp : "25220" },
{ code : "25561", intitule : "THORAISE", cp : "25320" },
{ code : "25562", intitule : "THULAY", cp : "25310" },
{ code : "25563", intitule : "THUREY LE MONT", cp : "25870" },
{ code : "25564", intitule : "TORPES", cp : "25320" },
{ code : "25565", intitule : "TOUILLON ET LOUTELET", cp : "25370" },
{ code : "25566", intitule : "LA TOUR DE SCAY", cp : "25640" },
{ code : "25567", intitule : "TOURNANS", cp : "25680" },
{ code : "25569", intitule : "TREPOT", cp : "25620" },
{ code : "25570", intitule : "TRESSANDANS", cp : "25680" },
{ code : "25571", intitule : "TREVILLERS", cp : "25470" },
{ code : "25572", intitule : "TROUVANS", cp : "25680" },
{ code : "25573", intitule : "URTIERE", cp : "25470" },
{ code : "25574", intitule : "UZELLE", cp : "25340" },
{ code : "25575", intitule : "VAIRE ARCIER", cp : "25220" },
{ code : "25575", intitule : "VAIRE ARCIER", cp : "25220" },
{ code : "25576", intitule : "VAIRE LE PETIT", cp : "25220" },
{ code : "25578", intitule : "VALDAHON", cp : "25800" },
{ code : "25579", intitule : "VAL DE ROULANS", cp : "25640" },
{ code : "25580", intitule : "VALENTIGNEY", cp : "25700" },
{ code : "25582", intitule : "VALLEROY", cp : "25870" },
{ code : "25583", intitule : "VALONNE", cp : "25190" },
{ code : "25584", intitule : "VALOREILLE", cp : "25190" },
{ code : "25586", intitule : "VANDONCOURT", cp : "25230" },
{ code : "25587", intitule : "VAUCHAMPS", cp : "25360" },
{ code : "25588", intitule : "VAUCLUSE", cp : "25380" },
{ code : "25589", intitule : "VAUCLUSOTTE", cp : "25380" },
{ code : "25590", intitule : "VAUDRIVILLERS", cp : "25360" },
{ code : "25591", intitule : "VAUFREY", cp : "25190" },
{ code : "25592", intitule : "VAUX ET CHANTEGRUE", cp : "25160" },
{ code : "25593", intitule : "VAUX LES PRES", cp : "25770" },
{ code : "25594", intitule : "VELESMES ESSARTS", cp : "25410" },
{ code : "25595", intitule : "VELLEROT LES BELVOIR", cp : "25430" },
{ code : "25596", intitule : "VELLEROT LES VERCEL", cp : "25530" },
{ code : "25597", intitule : "VELLEVANS", cp : "25430" },
{ code : "25598", intitule : "VENISE", cp : "25870" },
{ code : "25599", intitule : "VENNANS", cp : "25640" },
{ code : "25600", intitule : "VENNES", cp : "25390" },
{ code : "25601", intitule : "VERCEL VILLEDIEU LE CAMP", cp : "25530" },
{ code : "25602", intitule : "VERGRANNE", cp : "25110" },
{ code : "25604", intitule : "VERNE", cp : "25110" },
{ code : "25605", intitule : "VERNIERFONTAINE", cp : "25580" },
{ code : "25607", intitule : "VERNOIS LES BELVOIR", cp : "25430" },
{ code : "25608", intitule : "LE VERNOY", cp : "25750" },
{ code : "25609", intitule : "VERRIERES DE JOUX", cp : "25300" },
{ code : "25610", intitule : "VERRIERES DU GROSBOIS", cp : "25580" },
{ code : "25611", intitule : "LA VEZE", cp : "25660" },
{ code : "25612", intitule : "VIEILLEY", cp : "25870" },
{ code : "25613", intitule : "VIETHOREY", cp : "25340" },
{ code : "25614", intitule : "VIEUX CHARMONT", cp : "25600" },
{ code : "25615", intitule : "VILLARS LES BLAMONT", cp : "25310" },
{ code : "25616", intitule : "VILLARS ST GEORGES", cp : "25410" },
{ code : "25617", intitule : "VILLARS SOUS DAMPJOUX", cp : "25190" },
{ code : "25618", intitule : "VILLARS SOUS ECOT", cp : "25150" },
{ code : "25619", intitule : "LES VILLEDIEU", cp : "25240" },
{ code : "25620", intitule : "VILLE DU PONT", cp : "25650" },
{ code : "25621", intitule : "VILLENEUVE D AMONT", cp : "25270" },
{ code : "25622", intitule : "VILLERS BUZON", cp : "25170" },
{ code : "25623", intitule : "VILLERS CHIEF", cp : "25530" },
{ code : "25624", intitule : "VILLERS GRELOT", cp : "25640" },
{ code : "25625", intitule : "VILLERS LA COMBE", cp : "25510" },
{ code : "25626", intitule : "VILLERS ST MARTIN", cp : "25110" },
{ code : "25627", intitule : "VILLERS SOUS CHALAMONT", cp : "25270" },
{ code : "25628", intitule : "VILLERS SOUS MONTROND", cp : "25620" },
{ code : "25629", intitule : "VOILLANS", cp : "25110" },
{ code : "25630", intitule : "VOIRES", cp : "25580" },
{ code : "25631", intitule : "VORGES LES PINS", cp : "25320" },
{ code : "25632", intitule : "VOUJEAUCOURT", cp : "25420" },
{ code : "25633", intitule : "VUILLAFANS", cp : "25840" },
{ code : "25634", intitule : "VUILLECIN", cp : "25300" },
{ code : "25635", intitule : "VYT LES BELVOIR", cp : "25430" },
{ code : "26001", intitule : "SOLAURE EN DIOIS", cp : "26150" },
{ code : "26001", intitule : "SOLAURE EN DIOIS", cp : "26150" },
{ code : "26002", intitule : "ALBON", cp : "26140" },
{ code : "26003", intitule : "ALEYRAC", cp : "26770" },
{ code : "26004", intitule : "ALIXAN", cp : "26300" },
{ code : "26005", intitule : "ALLAN", cp : "26780" },
{ code : "26006", intitule : "ALLEX", cp : "26400" },
{ code : "26007", intitule : "AMBONIL", cp : "26800" },
{ code : "26008", intitule : "ANCONE", cp : "26200" },
{ code : "26009", intitule : "ANDANCETTE", cp : "26140" },
{ code : "26009", intitule : "ANDANCETTE", cp : "26140" },
{ code : "26010", intitule : "ANNEYRON", cp : "26140" },
{ code : "26011", intitule : "AOUSTE SUR SYE", cp : "26400" },
{ code : "26012", intitule : "ARNAYON", cp : "26470" },
{ code : "26013", intitule : "ARPAVON", cp : "26110" },
{ code : "26014", intitule : "ARTHEMONAY", cp : "26260" },
{ code : "26015", intitule : "AUBENASSON", cp : "26340" },
{ code : "26016", intitule : "AUBRES", cp : "26110" },
{ code : "26017", intitule : "AUCELON", cp : "26340" },
{ code : "26018", intitule : "AULAN", cp : "26570" },
{ code : "26019", intitule : "AUREL", cp : "26340" },
{ code : "26020", intitule : "LA REPARA AURIPLES", cp : "26400" },
{ code : "26021", intitule : "AUTICHAMP", cp : "26400" },
{ code : "26022", intitule : "BALLONS", cp : "26560" },
{ code : "26023", intitule : "BARBIERES", cp : "26300" },
{ code : "26024", intitule : "BARCELONNE", cp : "26120" },
{ code : "26025", intitule : "BARNAVE", cp : "26310" },
{ code : "26026", intitule : "BARRET DE LIOURE", cp : "26570" },
{ code : "26027", intitule : "BARSAC", cp : "26150" },
{ code : "26028", intitule : "BATHERNAY", cp : "26260" },
{ code : "26030", intitule : "LA BATIE DES FONDS", cp : "26310" },
{ code : "26031", intitule : "LA BATIE ROLLAND", cp : "26160" },
{ code : "26032", intitule : "LA BAUME CORNILLANE", cp : "26120" },
{ code : "26033", intitule : "LA BAUME DE TRANSIT", cp : "26790" },
{ code : "26034", intitule : "LA BAUME D HOSTUN", cp : "26730" },
{ code : "26035", intitule : "BEAUFORT SUR GERVANNE", cp : "26400" },
{ code : "26036", intitule : "BEAUMONT EN DIOIS", cp : "26310" },
{ code : "26037", intitule : "BEAUMONT LES VALENCE", cp : "26760" },
{ code : "26038", intitule : "BEAUMONT MONTEUX", cp : "26600" },
{ code : "26039", intitule : "BEAUREGARD BARET", cp : "26300" },
{ code : "26040", intitule : "BEAURIERES", cp : "26310" },
{ code : "26041", intitule : "BEAUSEMBLANT", cp : "26240" },
{ code : "26042", intitule : "BEAUVALLON", cp : "26800" },
{ code : "26043", intitule : "BEAUVOISIN", cp : "26170" },
{ code : "26045", intitule : "LA BEGUDE DE MAZENC", cp : "26160" },
{ code : "26046", intitule : "BELLECOMBE TARENDOL", cp : "26110" },
{ code : "26047", intitule : "BELLEGARDE EN DIOIS", cp : "26470" },
{ code : "26048", intitule : "BENIVAY OLLON", cp : "26170" },
{ code : "26049", intitule : "BESAYES", cp : "26300" },
{ code : "26050", intitule : "BESIGNAN", cp : "26110" },
{ code : "26051", intitule : "BEZAUDUN SUR BINE", cp : "26460" },
{ code : "26052", intitule : "BONLIEU SUR ROUBION", cp : "26160" },
{ code : "26054", intitule : "BOUCHET", cp : "26790" },
{ code : "26055", intitule : "BOULC", cp : "26410" },
{ code : "26055", intitule : "BOULC", cp : "26410" },
{ code : "26055", intitule : "BOULC", cp : "26410" },
{ code : "26056", intitule : "BOURDEAUX", cp : "26460" },
{ code : "26057", intitule : "BOURG DE PEAGE", cp : "26300" },
{ code : "26058", intitule : "BOURG LES VALENCE", cp : "26500" },
{ code : "26059", intitule : "BOUVANTE", cp : "26190" },
{ code : "26060", intitule : "BOUVIERES", cp : "26460" },
{ code : "26061", intitule : "BREN", cp : "26260" },
{ code : "26062", intitule : "BRETTE", cp : "26340" },
{ code : "26063", intitule : "BUIS LES BARONNIES", cp : "26170" },
{ code : "26064", intitule : "CHABEUIL", cp : "26120" },
{ code : "26064", intitule : "CHABEUIL", cp : "26120" },
{ code : "26065", intitule : "CHABRILLAN", cp : "26400" },
{ code : "26066", intitule : "LE CHAFFAL", cp : "26190" },
{ code : "26066", intitule : "LE CHAFFAL", cp : "26190" },
{ code : "26067", intitule : "CHALANCON", cp : "26470" },
{ code : "26068", intitule : "LE CHALON", cp : "26350" },
{ code : "26069", intitule : "CHAMALOC", cp : "26150" },
{ code : "26070", intitule : "CHAMARET", cp : "26230" },
{ code : "26071", intitule : "CHANOS CURSON", cp : "26600" },
{ code : "26072", intitule : "CHANTEMERLE LES BLES", cp : "26600" },
{ code : "26073", intitule : "CHANTEMERLE LES GRIGNAN", cp : "26230" },
{ code : "26074", intitule : "LA CHAPELLE EN VERCORS", cp : "26420" },
{ code : "26074", intitule : "LA CHAPELLE EN VERCORS", cp : "26420" },
{ code : "26075", intitule : "LA CHARCE", cp : "26470" },
{ code : "26076", intitule : "CHARENS", cp : "26310" },
{ code : "26077", intitule : "CHARMES SUR L HERBASSE", cp : "26260" },
{ code : "26078", intitule : "CHAROLS", cp : "26450" },
{ code : "26079", intitule : "CHARPEY", cp : "26300" },
{ code : "26079", intitule : "CHARPEY", cp : "26300" },
{ code : "26080", intitule : "CHASTEL ARNAUD", cp : "26340" },
{ code : "26081", intitule : "CHATEAUDOUBLE", cp : "26120" },
{ code : "26082", intitule : "CHATEAUNEUF DE BORDETTE", cp : "26110" },
{ code : "26083", intitule : "CHATEAUNEUF DE GALAURE", cp : "26330" },
{ code : "26084", intitule : "CHATEAUNEUF SUR ISERE", cp : "26300" },
{ code : "26085", intitule : "CHATEAUNEUF DU RHONE", cp : "26780" },
{ code : "26086", intitule : "CHATILLON EN DIOIS", cp : "26410" },
{ code : "26087", intitule : "CHATILLON ST JEAN", cp : "26750" },
{ code : "26088", intitule : "CHATUZANGE LE GOUBET", cp : "26300" },
{ code : "26088", intitule : "CHATUZANGE LE GOUBET", cp : "26300" },
{ code : "26089", intitule : "CHAUDEBONNE", cp : "26110" },
{ code : "26090", intitule : "LA CHAUDIERE", cp : "26340" },
{ code : "26091", intitule : "CHAUVAC LAUX MONTAUX", cp : "26510" },
{ code : "26091", intitule : "CHAUVAC LAUX MONTAUX", cp : "26510" },
{ code : "26092", intitule : "CHAVANNES", cp : "26260" },
{ code : "26093", intitule : "CLANSAYES", cp : "26130" },
{ code : "26094", intitule : "CLAVEYSON", cp : "26240" },
{ code : "26095", intitule : "CLEON D ANDRAN", cp : "26450" },
{ code : "26096", intitule : "CLERIEUX", cp : "26260" },
{ code : "26097", intitule : "CLIOUSCLAT", cp : "26270" },
{ code : "26098", intitule : "COBONNE", cp : "26400" },
{ code : "26099", intitule : "COLONZELLE", cp : "26230" },
{ code : "26100", intitule : "COMBOVIN", cp : "26120" },
{ code : "26101", intitule : "COMPS", cp : "26220" },
{ code : "26102", intitule : "CONDILLAC", cp : "26740" },
{ code : "26103", intitule : "CONDORCET", cp : "26110" },
{ code : "26104", intitule : "CORNILLAC", cp : "26510" },
{ code : "26105", intitule : "CORNILLON SUR L OULE", cp : "26510" },
{ code : "26106", intitule : "LA COUCOURDE", cp : "26740" },
{ code : "26106", intitule : "LA COUCOURDE", cp : "26740" },
{ code : "26107", intitule : "CREPOL", cp : "26350" },
{ code : "26108", intitule : "CREST", cp : "26400" },
{ code : "26110", intitule : "CROZES HERMITAGE", cp : "26600" },
{ code : "26111", intitule : "CRUPIES", cp : "26460" },
{ code : "26112", intitule : "CURNIER", cp : "26110" },
{ code : "26113", intitule : "DIE", cp : "26150" },
{ code : "26114", intitule : "DIEULEFIT", cp : "26220" },
{ code : "26115", intitule : "DIVAJEU", cp : "26400" },
{ code : "26116", intitule : "DONZERE", cp : "26290" },
{ code : "26117", intitule : "ECHEVIS", cp : "26190" },
{ code : "26118", intitule : "EPINOUZE", cp : "26210" },
{ code : "26119", intitule : "EROME", cp : "26600" },
{ code : "26121", intitule : "ESPELUCHE", cp : "26780" },
{ code : "26122", intitule : "ESPENEL", cp : "26340" },
{ code : "26123", intitule : "ESTABLET", cp : "26470" },
{ code : "26124", intitule : "ETOILE SUR RHONE", cp : "26800" },
{ code : "26124", intitule : "ETOILE SUR RHONE", cp : "26800" },
{ code : "26125", intitule : "EURRE", cp : "26400" },
{ code : "26126", intitule : "EYGALAYES", cp : "26560" },
{ code : "26127", intitule : "EYGALIERS", cp : "26170" },
{ code : "26128", intitule : "EYGLUY ESCOULIN", cp : "26400" },
{ code : "26129", intitule : "EYMEUX", cp : "26730" },
{ code : "26129", intitule : "EYMEUX", cp : "26730" },
{ code : "26130", intitule : "EYROLES", cp : "26110" },
{ code : "26131", intitule : "EYZAHUT", cp : "26160" },
{ code : "26133", intitule : "FAY LE CLOS", cp : "26240" },
{ code : "26134", intitule : "FELINES SUR RIMANDOULE", cp : "26160" },
{ code : "26135", intitule : "FERRASSIERES", cp : "26570" },
{ code : "26136", intitule : "VAL MARAVEL", cp : "26310" },
{ code : "26136", intitule : "VAL MARAVEL", cp : "26310" },
{ code : "26136", intitule : "VAL MARAVEL", cp : "26310" },
{ code : "26137", intitule : "FRANCILLON SUR ROUBION", cp : "26400" },
{ code : "26138", intitule : "LA GARDE ADHEMAR", cp : "26700" },
{ code : "26139", intitule : "GENISSIEUX", cp : "26750" },
{ code : "26140", intitule : "GEYSSANS", cp : "26750" },
{ code : "26141", intitule : "GIGORS ET LOZERON", cp : "26400" },
{ code : "26142", intitule : "GLANDAGE", cp : "26410" },
{ code : "26143", intitule : "LE GRAND SERRE", cp : "26530" },
{ code : "26144", intitule : "GRANE", cp : "26400" },
{ code : "26145", intitule : "LES GRANGES GONTARDES", cp : "26290" },
{ code : "26146", intitule : "GRIGNAN", cp : "26230" },
{ code : "26147", intitule : "GUMIANE", cp : "26470" },
{ code : "26148", intitule : "HAUTERIVES", cp : "26390" },
{ code : "26149", intitule : "HOSTUN", cp : "26730" },
{ code : "26150", intitule : "IZON LA BRUISSE", cp : "26560" },
{ code : "26152", intitule : "JONCHERES", cp : "26310" },
{ code : "26153", intitule : "LABOREL", cp : "26560" },
{ code : "26154", intitule : "LACHAU", cp : "26560" },
{ code : "26155", intitule : "LAPEYROUSE MORNAY", cp : "26210" },
{ code : "26156", intitule : "LARNAGE", cp : "26600" },
{ code : "26157", intitule : "LA LAUPIE", cp : "26740" },
{ code : "26159", intitule : "LAVAL D AIX", cp : "26150" },
{ code : "26160", intitule : "LAVEYRON", cp : "26240" },
{ code : "26161", intitule : "LEMPS", cp : "26510" },
{ code : "26162", intitule : "LENS LESTANG", cp : "26210" },
{ code : "26163", intitule : "LEONCEL", cp : "26190" },
{ code : "26164", intitule : "LESCHES EN DIOIS", cp : "26310" },
{ code : "26165", intitule : "LIVRON SUR DROME", cp : "26250" },
{ code : "26165", intitule : "LIVRON SUR DROME", cp : "26250" },
{ code : "26166", intitule : "LORIOL SUR DROME", cp : "26270" },
{ code : "26167", intitule : "LUC EN DIOIS", cp : "26310" },
{ code : "26168", intitule : "LUS LA CROIX HAUTE", cp : "26620" },
{ code : "26169", intitule : "MALATAVERNE", cp : "26780" },
{ code : "26170", intitule : "MALISSARD", cp : "26120" },
{ code : "26171", intitule : "MANAS", cp : "26160" },
{ code : "26172", intitule : "MANTHES", cp : "26210" },
{ code : "26173", intitule : "MARCHES", cp : "26300" },
{ code : "26174", intitule : "MARGES", cp : "26260" },
{ code : "26175", intitule : "MARIGNAC EN DIOIS", cp : "26150" },
{ code : "26176", intitule : "MARSANNE", cp : "26740" },
{ code : "26177", intitule : "MARSAZ", cp : "26260" },
{ code : "26178", intitule : "MENGLON", cp : "26410" },
{ code : "26179", intitule : "MERCUROL VEAUNES", cp : "26600" },
{ code : "26179", intitule : "MERCUROL VEAUNES", cp : "26600" },
{ code : "26180", intitule : "MERINDOL LES OLIVIERS", cp : "26170" },
{ code : "26181", intitule : "MEVOUILLON", cp : "26560" },
{ code : "26182", intitule : "MIRABEL AUX BARONNIES", cp : "26110" },
{ code : "26183", intitule : "MIRABEL ET BLACONS", cp : "26400" },
{ code : "26184", intitule : "MIRIBEL", cp : "26350" },
{ code : "26185", intitule : "MIRMANDE", cp : "26270" },
{ code : "26186", intitule : "MISCON", cp : "26310" },
{ code : "26188", intitule : "MOLLANS SUR OUVEZE", cp : "26170" },
{ code : "26189", intitule : "MONTAUBAN SUR L OUVEZE", cp : "26170" },
{ code : "26190", intitule : "MONTAULIEU", cp : "26110" },
{ code : "26191", intitule : "MONTBOUCHER SUR JABRON", cp : "26740" },
{ code : "26192", intitule : "MONTBRISON SUR LEZ", cp : "26770" },
{ code : "26193", intitule : "MONTBRUN LES BAINS", cp : "26570" },
{ code : "26194", intitule : "MONTCHENU", cp : "26350" },
{ code : "26195", intitule : "MONTCLAR SUR GERVANNE", cp : "26400" },
{ code : "26196", intitule : "MONTELEGER", cp : "26760" },
{ code : "26197", intitule : "MONTELIER", cp : "26120" },
{ code : "26197", intitule : "MONTELIER", cp : "26120" },
{ code : "26198", intitule : "MONTELIMAR", cp : "26200" },
{ code : "26199", intitule : "MONTFERRAND LA FARE", cp : "26510" },
{ code : "26200", intitule : "MONTFROC", cp : "26560" },
{ code : "26201", intitule : "MONTGUERS", cp : "26170" },
{ code : "26202", intitule : "MONTJOUX", cp : "26220" },
{ code : "26203", intitule : "MONTJOYER", cp : "26230" },
{ code : "26204", intitule : "MONTLAUR EN DIOIS", cp : "26310" },
{ code : "26205", intitule : "MONTMAUR EN DIOIS", cp : "26150" },
{ code : "26206", intitule : "MONTMEYRAN", cp : "26120" },
{ code : "26207", intitule : "MONTMIRAL", cp : "26750" },
{ code : "26208", intitule : "MONTOISON", cp : "26800" },
{ code : "26209", intitule : "MONTREAL LES SOURCES", cp : "26510" },
{ code : "26210", intitule : "MONTRIGAUD", cp : "26350" },
{ code : "26211", intitule : "MONTSEGUR SUR LAUZON", cp : "26130" },
{ code : "26212", intitule : "MONTVENDRE", cp : "26120" },
{ code : "26213", intitule : "MORAS EN VALLOIRE", cp : "26210" },
{ code : "26214", intitule : "MORNANS", cp : "26460" },
{ code : "26215", intitule : "LA MOTTE CHALANCON", cp : "26470" },
{ code : "26216", intitule : "LA MOTTE DE GALAURE", cp : "26240" },
{ code : "26217", intitule : "LA MOTTE FANJAS", cp : "26190" },
{ code : "26218", intitule : "MOURS ST EUSEBE", cp : "26540" },
{ code : "26219", intitule : "MUREILS", cp : "26240" },
{ code : "26220", intitule : "NYONS", cp : "26110" },
{ code : "26221", intitule : "OMBLEZE", cp : "26400" },
{ code : "26222", intitule : "ORCINAS", cp : "26220" },
{ code : "26223", intitule : "ORIOL EN ROYANS", cp : "26190" },
{ code : "26224", intitule : "OURCHES", cp : "26120" },
{ code : "26225", intitule : "PARNANS", cp : "26750" },
{ code : "26226", intitule : "LE PEGUE", cp : "26770" },
{ code : "26227", intitule : "PELONNE", cp : "26510" },
{ code : "26228", intitule : "PENNES LE SEC", cp : "26340" },
{ code : "26229", intitule : "LA PENNE SUR L OUVEZE", cp : "26170" },
{ code : "26231", intitule : "PEYRINS", cp : "26380" },
{ code : "26232", intitule : "PEYRUS", cp : "26120" },
{ code : "26233", intitule : "PIEGON", cp : "26110" },
{ code : "26234", intitule : "PIEGROS LA CLASTRE", cp : "26400" },
{ code : "26235", intitule : "PIERRELATTE", cp : "26700" },
{ code : "26236", intitule : "PIERRELONGUE", cp : "26170" },
{ code : "26238", intitule : "LES PILLES", cp : "26110" },
{ code : "26239", intitule : "PLAISIANS", cp : "26170" },
{ code : "26240", intitule : "PLAN DE BAIX", cp : "26400" },
{ code : "26241", intitule : "LE POET CELARD", cp : "26460" },
{ code : "26242", intitule : "LE POET EN PERCIP", cp : "26170" },
{ code : "26243", intitule : "LE POET LAVAL", cp : "26160" },
{ code : "26244", intitule : "LE POET SIGILLAT", cp : "26110" },
{ code : "26245", intitule : "POMMEROL", cp : "26470" },
{ code : "26246", intitule : "PONET ET ST AUBAN", cp : "26150" },
{ code : "26247", intitule : "PONSAS", cp : "26240" },
{ code : "26248", intitule : "PONTAIX", cp : "26150" },
{ code : "26249", intitule : "PONT DE BARRET", cp : "26160" },
{ code : "26250", intitule : "PONT DE L ISERE", cp : "26600" },
{ code : "26251", intitule : "PORTES EN VALDAINE", cp : "26160" },
{ code : "26252", intitule : "PORTES LES VALENCE", cp : "26800" },
{ code : "26253", intitule : "POYOLS", cp : "26310" },
{ code : "26254", intitule : "PRADELLE", cp : "26340" },
{ code : "26255", intitule : "LES PRES", cp : "26310" },
{ code : "26256", intitule : "PROPIAC", cp : "26170" },
{ code : "26257", intitule : "PUYGIRON", cp : "26160" },
{ code : "26258", intitule : "PUY ST MARTIN", cp : "26450" },
{ code : "26259", intitule : "RATIERES", cp : "26330" },
{ code : "26261", intitule : "REAUVILLE", cp : "26230" },
{ code : "26262", intitule : "RECOUBEAU JANSAC", cp : "26310" },
{ code : "26262", intitule : "RECOUBEAU JANSAC", cp : "26310" },
{ code : "26263", intitule : "REILHANETTE", cp : "26570" },
{ code : "26264", intitule : "REMUZAT", cp : "26510" },
{ code : "26266", intitule : "RIMON ET SAVEL", cp : "26340" },
{ code : "26267", intitule : "RIOMS", cp : "26170" },
{ code : "26268", intitule : "ROCHEBAUDIN", cp : "26160" },
{ code : "26269", intitule : "ROCHEBRUNE", cp : "26110" },
{ code : "26270", intitule : "ROCHECHINARD", cp : "26190" },
{ code : "26271", intitule : "LA ROCHE DE GLUN", cp : "26600" },
{ code : "26272", intitule : "ROCHEFORT EN VALDAINE", cp : "26160" },
{ code : "26273", intitule : "ROCHEFORT SAMSON", cp : "26300" },
{ code : "26274", intitule : "ROCHEFOURCHAT", cp : "26340" },
{ code : "26275", intitule : "ROCHEGUDE", cp : "26790" },
{ code : "26276", intitule : "ROCHE ST SECRET BECONNE", cp : "26770" },
{ code : "26276", intitule : "ROCHE ST SECRET BECONNE", cp : "26770" },
{ code : "26277", intitule : "LA ROCHE SUR GRANE", cp : "26400" },
{ code : "26278", intitule : "LA ROCHE SUR LE BUIS", cp : "26170" },
{ code : "26279", intitule : "LA ROCHETTE DU BUIS", cp : "26170" },
{ code : "26281", intitule : "ROMANS SUR ISERE", cp : "26100" },
{ code : "26282", intitule : "ROMEYER", cp : "26150" },
{ code : "26283", intitule : "ROTTIER", cp : "26470" },
{ code : "26284", intitule : "ROUSSAS", cp : "26230" },
{ code : "26285", intitule : "ROUSSET LES VIGNES", cp : "26770" },
{ code : "26286", intitule : "ROUSSIEUX", cp : "26510" },
{ code : "26287", intitule : "ROYNAC", cp : "26450" },
{ code : "26288", intitule : "SAHUNE", cp : "26510" },
{ code : "26289", intitule : "SAILLANS", cp : "26340" },
{ code : "26290", intitule : "ST AGNAN EN VERCORS", cp : "26420" },
{ code : "26290", intitule : "ST AGNAN EN VERCORS", cp : "26420" },
{ code : "26290", intitule : "ST AGNAN EN VERCORS", cp : "26420" },
{ code : "26291", intitule : "ST ANDEOL", cp : "26150" },
{ code : "26292", intitule : "ST AUBAN SUR L OUVEZE", cp : "26170" },
{ code : "26293", intitule : "ST AVIT", cp : "26330" },
{ code : "26294", intitule : "ST BARDOUX", cp : "26260" },
{ code : "26295", intitule : "ST BARTHELEMY DE VALS", cp : "26240" },
{ code : "26296", intitule : "ST BENOIT EN DIOIS", cp : "26340" },
{ code : "26297", intitule : "ST BONNET DE VALCLERIEUX", cp : "26350" },
{ code : "26298", intitule : "ST CHRISTOPHE ET LE LARIS", cp : "26350" },
{ code : "26299", intitule : "STE CROIX", cp : "26150" },
{ code : "26300", intitule : "ST DIZIER EN DIOIS", cp : "26310" },
{ code : "26301", intitule : "ST DONAT SUR L HERBASSE", cp : "26260" },
{ code : "26302", intitule : "STE EULALIE EN ROYANS", cp : "26190" },
{ code : "26303", intitule : "STE EUPHEMIE SUR OUVEZE", cp : "26170" },
{ code : "26304", intitule : "ST FERREOL TRENTE PAS", cp : "26110" },
{ code : "26305", intitule : "ST GERVAIS SUR ROUBION", cp : "26160" },
{ code : "26306", intitule : "STE JALLE", cp : "26110" },
{ code : "26307", intitule : "ST JEAN EN ROYANS", cp : "26190" },
{ code : "26308", intitule : "ST JULIEN EN QUINT", cp : "26150" },
{ code : "26309", intitule : "ST JULIEN EN VERCORS", cp : "26420" },
{ code : "26310", intitule : "ST LAURENT D ONAY", cp : "26350" },
{ code : "26311", intitule : "ST LAURENT EN ROYANS", cp : "26190" },
{ code : "26312", intitule : "ST MARCEL LES SAUZET", cp : "26740" },
{ code : "26313", intitule : "ST MARCEL LES VALENCE", cp : "26320" },
{ code : "26314", intitule : "ST MARTIN D AOUT", cp : "26330" },
{ code : "26315", intitule : "ST MARTIN EN VERCORS", cp : "26420" },
{ code : "26316", intitule : "ST MARTIN LE COLONEL", cp : "26190" },
{ code : "26317", intitule : "ST MAURICE SUR EYGUES", cp : "26110" },
{ code : "26318", intitule : "ST MAY", cp : "26510" },
{ code : "26319", intitule : "ST MICHEL SUR SAVASSE", cp : "26750" },
{ code : "26320", intitule : "ST NAZAIRE EN ROYANS", cp : "26190" },
{ code : "26321", intitule : "ST NAZAIRE LE DESERT", cp : "26340" },
{ code : "26322", intitule : "ST PANTALEON LES VIGNES", cp : "26770" },
{ code : "26323", intitule : "ST PAUL LES ROMANS", cp : "26750" },
{ code : "26324", intitule : "ST PAUL TROIS CHATEAUX", cp : "26130" },
{ code : "26325", intitule : "ST RAMBERT D ALBON", cp : "26140" },
{ code : "26326", intitule : "ST RESTITUT", cp : "26130" },
{ code : "26327", intitule : "ST ROMAN", cp : "26410" },
{ code : "26328", intitule : "ST SAUVEUR EN DIOIS", cp : "26340" },
{ code : "26329", intitule : "ST SAUVEUR GOUVERNET", cp : "26110" },
{ code : "26330", intitule : "ST SORLIN EN VALLOIRE", cp : "26210" },
{ code : "26331", intitule : "ST THOMAS EN ROYANS", cp : "26190" },
{ code : "26332", intitule : "ST UZE", cp : "26240" },
{ code : "26333", intitule : "ST VALLIER", cp : "26240" },
{ code : "26334", intitule : "SALETTES", cp : "26160" },
{ code : "26335", intitule : "SALLES SOUS BOIS", cp : "26770" },
{ code : "26336", intitule : "SAOU", cp : "26400" },
{ code : "26337", intitule : "SAULCE SUR RHONE", cp : "26270" },
{ code : "26337", intitule : "SAULCE SUR RHONE", cp : "26270" },
{ code : "26338", intitule : "SAUZET", cp : "26740" },
{ code : "26339", intitule : "SAVASSE", cp : "26740" },
{ code : "26339", intitule : "SAVASSE", cp : "26740" },
{ code : "26340", intitule : "SEDERON", cp : "26560" },
{ code : "26341", intitule : "SERVES SUR RHONE", cp : "26600" },
{ code : "26342", intitule : "SOLERIEUX", cp : "26130" },
{ code : "26343", intitule : "SOUSPIERRE", cp : "26160" },
{ code : "26344", intitule : "SOYANS", cp : "26400" },
{ code : "26345", intitule : "SUZE LA ROUSSE", cp : "26790" },
{ code : "26346", intitule : "SUZE", cp : "26400" },
{ code : "26347", intitule : "TAIN L HERMITAGE", cp : "26600" },
{ code : "26348", intitule : "TAULIGNAN", cp : "26770" },
{ code : "26349", intitule : "TERSANNE", cp : "26390" },
{ code : "26350", intitule : "TEYSSIERES", cp : "26220" },
{ code : "26351", intitule : "LES TONILS", cp : "26460" },
{ code : "26352", intitule : "LA TOUCHE", cp : "26160" },
{ code : "26353", intitule : "LES TOURRETTES", cp : "26740" },
{ code : "26354", intitule : "TRESCHENU CREYERS", cp : "26410" },
{ code : "26354", intitule : "TRESCHENU CREYERS", cp : "26410" },
{ code : "26355", intitule : "TRIORS", cp : "26750" },
{ code : "26356", intitule : "TRUINAS", cp : "26460" },
{ code : "26357", intitule : "TULETTE", cp : "26790" },
{ code : "26358", intitule : "UPIE", cp : "26120" },
{ code : "26359", intitule : "VACHERES EN QUINT", cp : "26150" },
{ code : "26360", intitule : "VALAURIE", cp : "26230" },
{ code : "26361", intitule : "VALDROME", cp : "26310" },
{ code : "26362", intitule : "VALENCE", cp : "26000" },
{ code : "26363", intitule : "VALOUSE", cp : "26110" },
{ code : "26364", intitule : "VASSIEUX EN VERCORS", cp : "26420" },
{ code : "26365", intitule : "VAUNAVEYS LA ROCHETTE", cp : "26400" },
{ code : "26365", intitule : "VAUNAVEYS LA ROCHETTE", cp : "26400" },
{ code : "26367", intitule : "VENTEROL", cp : "26110" },
{ code : "26368", intitule : "VERCHENY", cp : "26340" },
{ code : "26369", intitule : "VERCLAUSE", cp : "26510" },
{ code : "26370", intitule : "VERCOIRAN", cp : "26170" },
{ code : "26371", intitule : "VERONNE", cp : "26340" },
{ code : "26372", intitule : "VERS SUR MEOUGE", cp : "26560" },
{ code : "26373", intitule : "VESC", cp : "26220" },
{ code : "26374", intitule : "VILLEBOIS LES PINS", cp : "05700" },
{ code : "26375", intitule : "VILLEFRANCHE LE CHATEAU", cp : "26560" },
{ code : "26376", intitule : "VILLEPERDRIX", cp : "26510" },
{ code : "26377", intitule : "VINSOBRES", cp : "26110" },
{ code : "26378", intitule : "VOLVENT", cp : "26470" },
{ code : "26379", intitule : "GRANGES LES BEAUMONT", cp : "26600" },
{ code : "26380", intitule : "GERVANS", cp : "26600" },
{ code : "26381", intitule : "JAILLANS", cp : "26300" },
{ code : "26382", intitule : "ST VINCENT LA COMMANDERIE", cp : "26300" },
{ code : "27001", intitule : "ACLOU", cp : "27800" },
{ code : "27002", intitule : "ACON", cp : "27570" },
{ code : "27003", intitule : "ACQUIGNY", cp : "27400" },
{ code : "27003", intitule : "ACQUIGNY", cp : "27400" },
{ code : "27004", intitule : "AIGLEVILLE", cp : "27120" },
{ code : "27005", intitule : "AILLY", cp : "27600" },
{ code : "27006", intitule : "AIZIER", cp : "27500" },
{ code : "27008", intitule : "ALIZAY", cp : "27460" },
{ code : "27009", intitule : "AMBENAY", cp : "27250" },
{ code : "27010", intitule : "AMECOURT", cp : "27140" },
{ code : "27011", intitule : "AMFREVILLE ST AMAND", cp : "27370" },
{ code : "27011", intitule : "AMFREVILLE ST AMAND", cp : "27370" },
{ code : "27012", intitule : "AMFREVILLE LES CHAMPS", cp : "27380" },
{ code : "27013", intitule : "AMFREVILLE SOUS LES MONTS", cp : "27380" },
{ code : "27014", intitule : "AMFREVILLE SUR ITON", cp : "27400" },
{ code : "27015", intitule : "ANDE", cp : "27430" },
{ code : "27016", intitule : "LES ANDELYS", cp : "27700" },
{ code : "27017", intitule : "ANGERVILLE LA CAMPAGNE", cp : "27930" },
{ code : "27018", intitule : "APPEVILLE ANNEBAULT", cp : "27290" },
{ code : "27019", intitule : "ARMENTIERES SUR AVRE", cp : "27820" },
{ code : "27020", intitule : "ARNIERES SUR ITON", cp : "27180" },
{ code : "27021", intitule : "ASNIERES", cp : "27260" },
{ code : "27022", intitule : "LE VAL D HAZEY", cp : "27600" },
{ code : "27022", intitule : "LE VAL D HAZEY", cp : "27600" },
{ code : "27022", intitule : "LE VAL D HAZEY", cp : "27940" },
{ code : "27023", intitule : "AULNAY SUR ITON", cp : "27180" },
{ code : "27025", intitule : "AUTHEUIL AUTHOUILLET", cp : "27490" },
{ code : "27025", intitule : "AUTHEUIL AUTHOUILLET", cp : "27490" },
{ code : "27026", intitule : "AUTHEVERNES", cp : "27420" },
{ code : "27027", intitule : "LES AUTHIEUX", cp : "27220" },
{ code : "27028", intitule : "AUTHOU", cp : "27290" },
{ code : "27031", intitule : "AVIRON", cp : "27930" },
{ code : "27032", intitule : "CHAMBOIS", cp : "27240" },
{ code : "27032", intitule : "CHAMBOIS", cp : "27240" },
{ code : "27032", intitule : "CHAMBOIS", cp : "27240" },
{ code : "27033", intitule : "BACQUEPUIS", cp : "27930" },
{ code : "27034", intitule : "BACQUEVILLE", cp : "27440" },
{ code : "27035", intitule : "BAILLEUL LA VALLEE", cp : "27260" },
{ code : "27036", intitule : "BALINES", cp : "27130" },
{ code : "27037", intitule : "BARC", cp : "27170" },
{ code : "27038", intitule : "LES BARILS", cp : "27130" },
{ code : "27039", intitule : "BARNEVILLE SUR SEINE", cp : "27310" },
{ code : "27040", intitule : "BARQUET", cp : "27170" },
{ code : "27042", intitule : "BARVILLE", cp : "27230" },
{ code : "27043", intitule : "LES BAUX DE BRETEUIL", cp : "27160" },
{ code : "27044", intitule : "LES BAUX STE CROIX", cp : "27180" },
{ code : "27045", intitule : "BAZINCOURT SUR EPTE", cp : "27140" },
{ code : "27046", intitule : "BAZOQUES", cp : "27230" },
{ code : "27047", intitule : "BEAUBRAY", cp : "27190" },
{ code : "27048", intitule : "BEAUFICEL EN LYONS", cp : "27480" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27270" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27330" },
{ code : "27049", intitule : "MESNIL EN OUCHE", cp : "27410" },
{ code : "27050", intitule : "BEAUMONTEL", cp : "27170" },
{ code : "27051", intitule : "BEAUMONT LE ROGER", cp : "27170" },
{ code : "27052", intitule : "LE BEC HELLOUIN", cp : "27800" },
{ code : "27053", intitule : "LE BEC THOMAS", cp : "27370" },
{ code : "27054", intitule : "BEMECOURT", cp : "27160" },
{ code : "27055", intitule : "BERENGEVILLE LA CAMPAGNE", cp : "27110" },
{ code : "27056", intitule : "BERNAY", cp : "27300" },
{ code : "27057", intitule : "BERNIENVILLE", cp : "27180" },
{ code : "27058", intitule : "BERNIERES SUR SEINE", cp : "27700" },
{ code : "27059", intitule : "BERNOUVILLE", cp : "27660" },
{ code : "27061", intitule : "BERTHOUVILLE", cp : "27800" },
{ code : "27062", intitule : "BERVILLE EN ROUMOIS", cp : "27520" },
{ code : "27063", intitule : "BERVILLE LA CAMPAGNE", cp : "27170" },
{ code : "27064", intitule : "BERVILLE SUR MER", cp : "27210" },
{ code : "27065", intitule : "BEUZEVILLE", cp : "27210" },
{ code : "27066", intitule : "BEZU LA FORET", cp : "27480" },
{ code : "27067", intitule : "BEZU ST ELOI", cp : "27660" },
{ code : "27068", intitule : "BOIS ANZERAY", cp : "27330" },
{ code : "27069", intitule : "BOIS ARNAULT", cp : "27250" },
{ code : "27070", intitule : "BOISEMONT", cp : "27150" },
{ code : "27071", intitule : "LE BOIS HELLAIN", cp : "27260" },
{ code : "27072", intitule : "BOIS JEROME ST OUEN", cp : "27620" },
{ code : "27073", intitule : "BOIS LE ROI", cp : "27220" },
{ code : "27074", intitule : "BOISNEY", cp : "27800" },
{ code : "27075", intitule : "BOIS NORMAND PRES LYRE", cp : "27330" },
{ code : "27076", intitule : "BOISSET LES PREVANCHES", cp : "27120" },
{ code : "27077", intitule : "BOISSEY LE CHATEL", cp : "27520" },
{ code : "27078", intitule : "LA BOISSIERE", cp : "27220" },
{ code : "27079", intitule : "BOISSY LAMBERVILLE", cp : "27300" },
{ code : "27081", intitule : "BONCOURT", cp : "27120" },
{ code : "27082", intitule : "LA BONNEVILLE SUR ITON", cp : "27190" },
{ code : "27083", intitule : "BONNEVILLE APTOT", cp : "27290" },
{ code : "27085", intitule : "FLANCOURT CRESCY EN ROUMOIS", cp : "27310" },
{ code : "27085", intitule : "FLANCOURT CRESCY EN ROUMOIS", cp : "27310" },
{ code : "27085", intitule : "FLANCOURT CRESCY EN ROUMOIS", cp : "27310" },
{ code : "27089", intitule : "BOSC RENOULT EN ROUMOIS", cp : "27520" },
{ code : "27090", intitule : "LE BOSC ROGER EN ROUMOIS", cp : "27670" },
{ code : "27091", intitule : "BOSGOUET", cp : "27310" },
{ code : "27092", intitule : "BOSGUERARD DE MARCOUVILLE", cp : "27520" },
{ code : "27093", intitule : "BOSNORMAND", cp : "27670" },
{ code : "27094", intitule : "BOSQUENTIN", cp : "27480" },
{ code : "27095", intitule : "BOSROBERT", cp : "27800" },
{ code : "27096", intitule : "LES BOTTEREAUX", cp : "27250" },
{ code : "27096", intitule : "LES BOTTEREAUX", cp : "27250" },
{ code : "27096", intitule : "LES BOTTEREAUX", cp : "27250" },
{ code : "27097", intitule : "BOUAFLES", cp : "27700" },
{ code : "27098", intitule : "BOUCHEVILLIERS", cp : "27150" },
{ code : "27099", intitule : "LE BOULAY MORIN", cp : "27930" },
{ code : "27100", intitule : "BOULLEVILLE", cp : "27210" },
{ code : "27100", intitule : "BOULLEVILLE", cp : "27210" },
{ code : "27101", intitule : "BOUQUELON", cp : "27500" },
{ code : "27102", intitule : "BOUQUETOT", cp : "27310" },
{ code : "27103", intitule : "BOURG ACHARD", cp : "27310" },
{ code : "27104", intitule : "BOURG BEAUDOUIN", cp : "27380" },
{ code : "27105", intitule : "GRAND BOURGTHEROULDE", cp : "27520" },
{ code : "27105", intitule : "GRAND BOURGTHEROULDE", cp : "27520" },
{ code : "27105", intitule : "GRAND BOURGTHEROULDE", cp : "27520" },
{ code : "27105", intitule : "GRAND BOURGTHEROULDE", cp : "27520" },
{ code : "27105", intitule : "GRAND BOURGTHEROULDE", cp : "27520" },
{ code : "27106", intitule : "BOURNAINVILLE FAVEROLLES", cp : "27230" },
{ code : "27106", intitule : "BOURNAINVILLE FAVEROLLES", cp : "27230" },
{ code : "27106", intitule : "BOURNAINVILLE FAVEROLLES", cp : "27230" },
{ code : "27107", intitule : "BOURNEVILLE STE CROIX", cp : "27500" },
{ code : "27107", intitule : "BOURNEVILLE STE CROIX", cp : "27500" },
{ code : "27108", intitule : "BOURTH", cp : "27580" },
{ code : "27109", intitule : "BRAY", cp : "27170" },
{ code : "27110", intitule : "BRESTOT", cp : "27350" },
{ code : "27111", intitule : "BRETAGNOLLES", cp : "27220" },
{ code : "27112", intitule : "BRETEUIL", cp : "27160" },
{ code : "27112", intitule : "BRETEUIL", cp : "27160" },
{ code : "27112", intitule : "BRETEUIL", cp : "27160" },
{ code : "27113", intitule : "BRETIGNY", cp : "27800" },
{ code : "27114", intitule : "BREUILPONT", cp : "27640" },
{ code : "27115", intitule : "BREUX SUR AVRE", cp : "27570" },
{ code : "27116", intitule : "BRIONNE", cp : "27800" },
{ code : "27117", intitule : "BROGLIE", cp : "27270" },
{ code : "27118", intitule : "BROSVILLE", cp : "27930" },
{ code : "27119", intitule : "BUEIL", cp : "27730" },
{ code : "27120", intitule : "BUREY", cp : "27190" },
{ code : "27123", intitule : "CAILLOUET ORGEVILLE", cp : "27120" },
{ code : "27124", intitule : "CAILLY SUR EURE", cp : "27490" },
{ code : "27125", intitule : "CALLEVILLE", cp : "27800" },
{ code : "27126", intitule : "CAMPIGNY", cp : "27500" },
{ code : "27127", intitule : "CANAPPEVILLE", cp : "27400" },
{ code : "27129", intitule : "CAORCHES ST NICOLAS", cp : "27300" },
{ code : "27129", intitule : "CAORCHES ST NICOLAS", cp : "27300" },
{ code : "27130", intitule : "CAPELLE LES GRANDS", cp : "27270" },
{ code : "27131", intitule : "CARSIX", cp : "27300" },
{ code : "27132", intitule : "CAUGE", cp : "27180" },
{ code : "27133", intitule : "CAUMONT", cp : "27310" },
{ code : "27134", intitule : "CAUVERVILLE EN ROUMOIS", cp : "27350" },
{ code : "27135", intitule : "CESSEVILLE", cp : "27110" },
{ code : "27136", intitule : "CHAIGNES", cp : "27120" },
{ code : "27137", intitule : "CHAISE DIEU DU THEIL", cp : "27580" },
{ code : "27138", intitule : "CHAMBLAC", cp : "27270" },
{ code : "27138", intitule : "CHAMBLAC", cp : "27270" },
{ code : "27139", intitule : "CHAMBORD", cp : "27250" },
{ code : "27140", intitule : "CHAMBRAY", cp : "27120" },
{ code : "27141", intitule : "CHAMP DOLENT", cp : "27190" },
{ code : "27142", intitule : "CHAMPENARD", cp : "27600" },
{ code : "27143", intitule : "CHAMPIGNOLLES", cp : "27330" },
{ code : "27144", intitule : "CHAMPIGNY LA FUTELAYE", cp : "27220" },
{ code : "27146", intitule : "LA CHAPELLE BAYVEL", cp : "27260" },
{ code : "27147", intitule : "LA CHAPELLE DU BOIS DES FAULX", cp : "27930" },
{ code : "27148", intitule : "LA CHAPELLE GAUTHIER", cp : "27270" },
{ code : "27149", intitule : "LA CHAPELLE HARENG", cp : "27230" },
{ code : "27150", intitule : "LA CHAPELLE REANVILLE", cp : "27950" },
{ code : "27151", intitule : "CHARLEVAL", cp : "27380" },
{ code : "27152", intitule : "CHATEAU SUR EPTE", cp : "27420" },
{ code : "27153", intitule : "CHAUVINCOURT PROVEMONT", cp : "27150" },
{ code : "27153", intitule : "CHAUVINCOURT PROVEMONT", cp : "27150" },
{ code : "27154", intitule : "CHAVIGNY BAILLEUL", cp : "27220" },
{ code : "27155", intitule : "CHENNEBRUN", cp : "27820" },
{ code : "27156", intitule : "CHERONVILLIERS", cp : "27250" },
{ code : "27157", intitule : "MARBOIS", cp : "27160" },
{ code : "27157", intitule : "MARBOIS", cp : "27240" },
{ code : "27157", intitule : "MARBOIS", cp : "27160" },
{ code : "27157", intitule : "MARBOIS", cp : "27240" },
{ code : "27158", intitule : "CIERREY", cp : "27930" },
{ code : "27161", intitule : "CLAVILLE", cp : "27180" },
{ code : "27162", intitule : "COLLANDRES QUINCARNON", cp : "27190" },
{ code : "27163", intitule : "COLLETOT", cp : "27500" },
{ code : "27164", intitule : "COMBON", cp : "27170" },
{ code : "27165", intitule : "CONCHES EN OUCHE", cp : "27190" },
{ code : "27167", intitule : "CONDE SUR RISLE", cp : "27290" },
{ code : "27168", intitule : "CONNELLES", cp : "27430" },
{ code : "27169", intitule : "CONTEVILLE", cp : "27210" },
{ code : "27170", intitule : "CORMEILLES", cp : "27260" },
{ code : "27171", intitule : "LE CORMIER", cp : "27120" },
{ code : "27173", intitule : "CORNEVILLE LA FOUQUETIERE", cp : "27300" },
{ code : "27174", intitule : "CORNEVILLE SUR RISLE", cp : "27500" },
{ code : "27175", intitule : "CORNY", cp : "27700" },
{ code : "27176", intitule : "COUDRAY", cp : "27150" },
{ code : "27177", intitule : "COUDRES", cp : "27220" },
{ code : "27179", intitule : "COURBEPINE", cp : "27300" },
{ code : "27180", intitule : "COURCELLES SUR SEINE", cp : "27940" },
{ code : "27181", intitule : "COURDEMANCHE", cp : "27320" },
{ code : "27182", intitule : "COURTEILLES", cp : "27130" },
{ code : "27183", intitule : "LA COUTURE BOUSSEY", cp : "27750" },
{ code : "27184", intitule : "CRASVILLE", cp : "27400" },
{ code : "27185", intitule : "CRESTOT", cp : "27110" },
{ code : "27187", intitule : "CRIQUEBEUF LA CAMPAGNE", cp : "27110" },
{ code : "27188", intitule : "CRIQUEBEUF SUR SEINE", cp : "27340" },
{ code : "27189", intitule : "LA CROISILLE", cp : "27190" },
{ code : "27190", intitule : "CROISY SUR EURE", cp : "27120" },
{ code : "27191", intitule : "CLEF VALLEE D EURE", cp : "27490" },
{ code : "27191", intitule : "CLEF VALLEE D EURE", cp : "27490" },
{ code : "27191", intitule : "CLEF VALLEE D EURE", cp : "27490" },
{ code : "27192", intitule : "CROSVILLE LA VIEILLE", cp : "27110" },
{ code : "27193", intitule : "CROTH", cp : "27530" },
{ code : "27194", intitule : "CUVERVILLE", cp : "27700" },
{ code : "27196", intitule : "LES DAMPS", cp : "27340" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27160" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27198", intitule : "MESNILS SUR ITON", cp : "27240" },
{ code : "27199", intitule : "DANGU", cp : "27720" },
{ code : "27200", intitule : "DARDEZ", cp : "27930" },
{ code : "27201", intitule : "DAUBEUF LA CAMPAGNE", cp : "27110" },
{ code : "27202", intitule : "DAUBEUF PRES VATTEVILLE", cp : "27430" },
{ code : "27203", intitule : "DOUAINS", cp : "27120" },
{ code : "27204", intitule : "DOUDEAUVILLE EN VEXIN", cp : "27150" },
{ code : "27205", intitule : "DOUVILLE SUR ANDELLE", cp : "27380" },
{ code : "27206", intitule : "DROISY", cp : "27320" },
{ code : "27206", intitule : "DROISY", cp : "27320" },
{ code : "27207", intitule : "DRUCOURT", cp : "27230" },
{ code : "27208", intitule : "DURANVILLE", cp : "27230" },
{ code : "27209", intitule : "ECAQUELON", cp : "27290" },
{ code : "27210", intitule : "ECARDENVILLE LA CAMPAGNE", cp : "27170" },
{ code : "27212", intitule : "ECAUVILLE", cp : "27110" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27510" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27510" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27420" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27510" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27510" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27510" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27420" },
{ code : "27213", intitule : "VEXIN SUR EPTE", cp : "27630" },
{ code : "27214", intitule : "ECOUIS", cp : "27440" },
{ code : "27215", intitule : "ECQUETOT", cp : "27110" },
{ code : "27216", intitule : "EMALLEVILLE", cp : "27930" },
{ code : "27217", intitule : "EMANVILLE", cp : "27190" },
{ code : "27218", intitule : "EPAIGNES", cp : "27260" },
{ code : "27219", intitule : "EPEGARD", cp : "27110" },
{ code : "27220", intitule : "EPIEDS", cp : "27730" },
{ code : "27222", intitule : "EPREVILLE EN LIEUVIN", cp : "27560" },
{ code : "27224", intitule : "EPREVILLE PRES LE NEUBOURG", cp : "27110" },
{ code : "27226", intitule : "ETREPAGNY", cp : "27150" },
{ code : "27227", intitule : "ETREVILLE", cp : "27350" },
{ code : "27228", intitule : "ETURQUERAYE", cp : "27350" },
{ code : "27229", intitule : "EVREUX", cp : "27000" },
{ code : "27229", intitule : "EVREUX", cp : "27000" },
{ code : "27230", intitule : "EZY SUR EURE", cp : "27530" },
{ code : "27231", intitule : "FAINS", cp : "27120" },
{ code : "27232", intitule : "FARCEAUX", cp : "27150" },
{ code : "27233", intitule : "FATOUVILLE GRESTAIN", cp : "27210" },
{ code : "27234", intitule : "FAUVILLE", cp : "27930" },
{ code : "27235", intitule : "FAVEROLLES LA CAMPAGNE", cp : "27190" },
{ code : "27237", intitule : "LE FAVRIL", cp : "27230" },
{ code : "27238", intitule : "FERRIERES HAUT CLOCHER", cp : "27190" },
{ code : "27239", intitule : "FERRIERES ST HILAIRE", cp : "27270" },
{ code : "27240", intitule : "LA FERRIERE SUR RISLE", cp : "27760" },
{ code : "27241", intitule : "FEUGUEROLLES", cp : "27110" },
{ code : "27242", intitule : "LE FIDELAIRE", cp : "27190" },
{ code : "27243", intitule : "FIQUEFLEUR EQUAINVILLE", cp : "27210" },
{ code : "27245", intitule : "FLEURY LA FORET", cp : "27480" },
{ code : "27246", intitule : "FLEURY SUR ANDELLE", cp : "27380" },
{ code : "27247", intitule : "FLIPOU", cp : "27380" },
{ code : "27248", intitule : "FOLLEVILLE", cp : "27230" },
{ code : "27249", intitule : "FONTAINE BELLENGER", cp : "27600" },
{ code : "27251", intitule : "FONTAINE L ABBE", cp : "27470" },
{ code : "27252", intitule : "FONTAINE LA LOUVET", cp : "27230" },
{ code : "27253", intitule : "FONTAINE LA SORET", cp : "27550" },
{ code : "27254", intitule : "FONTAINE SOUS JOUY", cp : "27120" },
{ code : "27256", intitule : "LA FORET DU PARC", cp : "27220" },
{ code : "27258", intitule : "FORT MOVILLE", cp : "27210" },
{ code : "27259", intitule : "FOUCRAINVILLE", cp : "27220" },
{ code : "27260", intitule : "FOULBEC", cp : "27210" },
{ code : "27261", intitule : "FOUQUEVILLE", cp : "27370" },
{ code : "27263", intitule : "FOURMETOT", cp : "27500" },
{ code : "27265", intitule : "FRANCHEVILLE", cp : "27160" },
{ code : "27266", intitule : "FRANQUEVILLE", cp : "27800" },
{ code : "27267", intitule : "FRENEUSE SUR RISLE", cp : "27290" },
{ code : "27268", intitule : "LE FRESNE", cp : "27190" },
{ code : "27269", intitule : "FRESNE CAUVERVILLE", cp : "27260" },
{ code : "27270", intitule : "FRESNE L ARCHEVEQUE", cp : "27700" },
{ code : "27271", intitule : "FRESNEY", cp : "27220" },
{ code : "27273", intitule : "GADENCOURT", cp : "27120" },
{ code : "27274", intitule : "GAILLARDBOIS CRESSENVILLE", cp : "27440" },
{ code : "27275", intitule : "GAILLON", cp : "27600" },
{ code : "27276", intitule : "GAMACHES EN VEXIN", cp : "27150" },
{ code : "27277", intitule : "LA BARONNIE", cp : "27220" },
{ code : "27277", intitule : "LA BARONNIE", cp : "27220" },
{ code : "27278", intitule : "GARENNES SUR EURE", cp : "27780" },
{ code : "27279", intitule : "GASNY", cp : "27620" },
{ code : "27280", intitule : "GAUCIEL", cp : "27930" },
{ code : "27281", intitule : "GAUDREVILLE LA RIVIERE", cp : "27190" },
{ code : "27282", intitule : "GAUVILLE LA CAMPAGNE", cp : "27930" },
{ code : "27284", intitule : "GISORS", cp : "27140" },
{ code : "27285", intitule : "GIVERNY", cp : "27620" },
{ code : "27286", intitule : "GIVERVILLE", cp : "27560" },
{ code : "27287", intitule : "GLISOLLES", cp : "27190" },
{ code : "27288", intitule : "GLOS SUR RISLE", cp : "27290" },
{ code : "27289", intitule : "LA GOULAFRIERE", cp : "27390" },
{ code : "27290", intitule : "GOUPILLIERES", cp : "27170" },
{ code : "27291", intitule : "GOURNAY LE GUERIN", cp : "27580" },
{ code : "27294", intitule : "GRAINVILLE", cp : "27380" },
{ code : "27295", intitule : "GRAND CAMP", cp : "27270" },
{ code : "27297", intitule : "GRANDVILLIERS", cp : "27240" },
{ code : "27297", intitule : "GRANDVILLIERS", cp : "27240" },
{ code : "27298", intitule : "GRAVERON SEMERVILLE", cp : "27110" },
{ code : "27299", intitule : "GRAVIGNY", cp : "27930" },
{ code : "27300", intitule : "GROSLEY SUR RISLE", cp : "27170" },
{ code : "27301", intitule : "GROSSOEUVRE", cp : "27220" },
{ code : "27302", intitule : "LE BOSC DU THEIL", cp : "27370" },
{ code : "27302", intitule : "LE BOSC DU THEIL", cp : "27370" },
{ code : "27304", intitule : "GUERNY", cp : "27720" },
{ code : "27306", intitule : "GUICHAINVILLE", cp : "27930" },
{ code : "27307", intitule : "GUISENIERS", cp : "27700" },
{ code : "27309", intitule : "L HABIT", cp : "27220" },
{ code : "27310", intitule : "HACQUEVILLE", cp : "27150" },
{ code : "27311", intitule : "HARCOURT", cp : "27800" },
{ code : "27312", intitule : "HARDENCOURT COCHEREL", cp : "27120" },
{ code : "27313", intitule : "LA HARENGERE", cp : "27370" },
{ code : "27315", intitule : "HARQUENCY", cp : "27700" },
{ code : "27316", intitule : "HAUVILLE", cp : "27350" },
{ code : "27317", intitule : "LA HAYE AUBREE", cp : "27350" },
{ code : "27318", intitule : "LA HAYE DE CALLEVILLE", cp : "27800" },
{ code : "27319", intitule : "LA HAYE DE ROUTOT", cp : "27350" },
{ code : "27320", intitule : "LA HAYE DU THEIL", cp : "27370" },
{ code : "27321", intitule : "LA HAYE LE COMTE", cp : "27400" },
{ code : "27322", intitule : "LA HAYE MALHERBE", cp : "27400" },
{ code : "27323", intitule : "LA HAYE ST SYLVESTRE", cp : "27330" },
{ code : "27324", intitule : "HEBECOURT", cp : "27150" },
{ code : "27325", intitule : "HECMANVILLE", cp : "27800" },
{ code : "27326", intitule : "HECOURT", cp : "27120" },
{ code : "27327", intitule : "HECTOMARE", cp : "27110" },
{ code : "27329", intitule : "HENNEZIS", cp : "27700" },
{ code : "27330", intitule : "HERQUEVILLE", cp : "27430" },
{ code : "27331", intitule : "HEUBECOURT HARICOURT", cp : "27630" },
{ code : "27331", intitule : "HEUBECOURT HARICOURT", cp : "27630" },
{ code : "27331", intitule : "HEUBECOURT HARICOURT", cp : "27630" },
{ code : "27332", intitule : "HEUDEBOUVILLE", cp : "27400" },
{ code : "27332", intitule : "HEUDEBOUVILLE", cp : "27600" },
{ code : "27333", intitule : "HEUDICOURT", cp : "27860" },
{ code : "27334", intitule : "HEUDREVILLE EN LIEUVIN", cp : "27230" },
{ code : "27335", intitule : "HEUDREVILLE SUR EURE", cp : "27400" },
{ code : "27336", intitule : "LA HEUNIERE", cp : "27950" },
{ code : "27337", intitule : "HEUQUEVILLE", cp : "27700" },
{ code : "27338", intitule : "LES HOGUES", cp : "27910" },
{ code : "27339", intitule : "HONDOUVILLE", cp : "27400" },
{ code : "27340", intitule : "HONGUEMARE GUENOUVILLE", cp : "27310" },
{ code : "27341", intitule : "L HOSMES", cp : "27570" },
{ code : "27342", intitule : "HOUETTEVILLE", cp : "27400" },
{ code : "27343", intitule : "HOULBEC COCHEREL", cp : "27120" },
{ code : "27344", intitule : "HOULBEC PRES LE GROS THEIL", cp : "27370" },
{ code : "27345", intitule : "LA HOUSSAYE", cp : "27410" },
{ code : "27346", intitule : "HOUVILLE EN VEXIN", cp : "27440" },
{ code : "27347", intitule : "HUEST", cp : "27930" },
{ code : "27348", intitule : "IGOVILLE", cp : "27460" },
{ code : "27349", intitule : "ILLEVILLE SUR MONTFORT", cp : "27290" },
{ code : "27350", intitule : "ILLIERS L EVEQUE", cp : "27770" },
{ code : "27351", intitule : "INCARVILLE", cp : "27400" },
{ code : "27353", intitule : "IRREVILLE", cp : "27930" },
{ code : "27354", intitule : "IVILLE", cp : "27110" },
{ code : "27355", intitule : "IVRY LA BATAILLE", cp : "27540" },
{ code : "27358", intitule : "JOUY SUR EURE", cp : "27120" },
{ code : "27359", intitule : "JUIGNETTES", cp : "27250" },
{ code : "27360", intitule : "JUMELLES", cp : "27220" },
{ code : "27361", intitule : "LA LANDE ST LEGER", cp : "27210" },
{ code : "27361", intitule : "LA LANDE ST LEGER", cp : "27210" },
{ code : "27363", intitule : "LE LANDIN", cp : "27350" },
{ code : "27364", intitule : "LAUNAY", cp : "27470" },
{ code : "27365", intitule : "LERY", cp : "27690" },
{ code : "27366", intitule : "LETTEGUIVES", cp : "27910" },
{ code : "27367", intitule : "LIEUREY", cp : "27560" },
{ code : "27368", intitule : "LIGNEROLLES", cp : "27220" },
{ code : "27369", intitule : "LILLY", cp : "27480" },
{ code : "27370", intitule : "LISORS", cp : "27440" },
{ code : "27371", intitule : "LIVET SUR AUTHOU", cp : "27800" },
{ code : "27372", intitule : "LONGCHAMPS", cp : "27150" },
{ code : "27373", intitule : "LORLEAU", cp : "27480" },
{ code : "27374", intitule : "LOUVERSEY", cp : "27190" },
{ code : "27375", intitule : "LOUVIERS", cp : "27400" },
{ code : "27376", intitule : "LOUYE", cp : "27650" },
{ code : "27377", intitule : "LYONS LA FORET", cp : "27480" },
{ code : "27378", intitule : "LA MADELEINE DE NONANCOURT", cp : "27320" },
{ code : "27379", intitule : "MAINNEVILLE", cp : "27150" },
{ code : "27380", intitule : "MALLEVILLE SUR LE BEC", cp : "27800" },
{ code : "27381", intitule : "MALOUY", cp : "27300" },
{ code : "27382", intitule : "MANDEVILLE", cp : "27370" },
{ code : "27383", intitule : "MANDRES", cp : "27130" },
{ code : "27384", intitule : "MANNEVILLE LA RAOULT", cp : "27210" },
{ code : "27385", intitule : "MANNEVILLE SUR RISLE", cp : "27500" },
{ code : "27386", intitule : "LE MANOIR", cp : "27460" },
{ code : "27388", intitule : "MARAIS VERNIER", cp : "27680" },
{ code : "27389", intitule : "MARBEUF", cp : "27110" },
{ code : "27390", intitule : "MARCILLY LA CAMPAGNE", cp : "27320" },
{ code : "27391", intitule : "MARCILLY SUR EURE", cp : "27810" },
{ code : "27392", intitule : "MARTAGNY", cp : "27150" },
{ code : "27393", intitule : "MARTAINVILLE", cp : "27210" },
{ code : "27394", intitule : "MARTOT", cp : "27340" },
{ code : "27395", intitule : "MELICOURT", cp : "27390" },
{ code : "27396", intitule : "MENESQUEVILLE", cp : "27850" },
{ code : "27397", intitule : "MENILLES", cp : "27120" },
{ code : "27398", intitule : "MENNEVAL", cp : "27300" },
{ code : "27399", intitule : "MERCEY", cp : "27950" },
{ code : "27400", intitule : "MEREY", cp : "27640" },
{ code : "27401", intitule : "LE MESNIL FUGUET", cp : "27930" },
{ code : "27402", intitule : "LE MESNIL HARDRAY", cp : "27190" },
{ code : "27403", intitule : "LE MESNIL JOURDAIN", cp : "27400" },
{ code : "27404", intitule : "MESNIL ROUSSET", cp : "27390" },
{ code : "27405", intitule : "MESNIL SOUS VIENNE", cp : "27150" },
{ code : "27406", intitule : "MESNIL SUR L ESTREE", cp : "27650" },
{ code : "27407", intitule : "MESNIL VERCLIVES", cp : "27440" },
{ code : "27408", intitule : "MEZIERES EN VEXIN", cp : "27510" },
{ code : "27410", intitule : "MISEREY", cp : "27930" },
{ code : "27411", intitule : "MOISVILLE", cp : "27320" },
{ code : "27412", intitule : "MONTAURE", cp : "27400" },
{ code : "27413", intitule : "MONTFORT SUR RISLE", cp : "27290" },
{ code : "27414", intitule : "MONTREUIL L ARGILLE", cp : "27390" },
{ code : "27414", intitule : "MONTREUIL L ARGILLE", cp : "27390" },
{ code : "27415", intitule : "MORAINVILLE JOUVEAUX", cp : "27260" },
{ code : "27415", intitule : "MORAINVILLE JOUVEAUX", cp : "27260" },
{ code : "27416", intitule : "BUIS SUR DAMVILLE", cp : "27240" },
{ code : "27416", intitule : "BUIS SUR DAMVILLE", cp : "27240" },
{ code : "27416", intitule : "BUIS SUR DAMVILLE", cp : "27240" },
{ code : "27417", intitule : "MORGNY", cp : "27150" },
{ code : "27418", intitule : "MORSAN", cp : "27800" },
{ code : "27419", intitule : "MOUETTES", cp : "27220" },
{ code : "27420", intitule : "MOUFLAINES", cp : "27420" },
{ code : "27421", intitule : "MOUSSEAUX NEUVILLE", cp : "27220" },
{ code : "27422", intitule : "MUIDS", cp : "27430" },
{ code : "27423", intitule : "MUZY", cp : "27650" },
{ code : "27424", intitule : "NAGEL SEEZ MESNIL", cp : "27190" },
{ code : "27425", intitule : "NASSANDRES", cp : "27550" },
{ code : "27426", intitule : "NEAUFLES ST MARTIN", cp : "27830" },
{ code : "27427", intitule : "NEAUFLES AUVERGNY", cp : "27250" },
{ code : "27427", intitule : "NEAUFLES AUVERGNY", cp : "27250" },
{ code : "27427", intitule : "NEAUFLES AUVERGNY", cp : "27250" },
{ code : "27428", intitule : "LE NEUBOURG", cp : "27110" },
{ code : "27429", intitule : "NEUILLY", cp : "27730" },
{ code : "27430", intitule : "LA NEUVE GRANGE", cp : "27150" },
{ code : "27431", intitule : "LA NEUVE LYRE", cp : "27330" },
{ code : "27432", intitule : "LA NEUVILLE DU BOSC", cp : "27890" },
{ code : "27433", intitule : "NEUVILLE SUR AUTHOU", cp : "27800" },
{ code : "27434", intitule : "NOARDS", cp : "27560" },
{ code : "27435", intitule : "LA NOE POULAIN", cp : "27560" },
{ code : "27436", intitule : "NOGENT LE SEC", cp : "27190" },
{ code : "27437", intitule : "NOJEON EN VEXIN", cp : "27150" },
{ code : "27438", intitule : "NONANCOURT", cp : "27320" },
{ code : "27439", intitule : "NORMANVILLE", cp : "27930" },
{ code : "27440", intitule : "NOTRE DAME DE L ISLE", cp : "27940" },
{ code : "27441", intitule : "NOTRE DAME D EPINE", cp : "27800" },
{ code : "27442", intitule : "NOTRE DAME DU HAMEL", cp : "27390" },
{ code : "27444", intitule : "LE NOYER EN OUCHE", cp : "27410" },
{ code : "27445", intitule : "NOYERS", cp : "27720" },
{ code : "27446", intitule : "ORMES", cp : "27190" },
{ code : "27447", intitule : "ORVAUX", cp : "27190" },
{ code : "27448", intitule : "PACY SUR EURE", cp : "27120" },
{ code : "27451", intitule : "PARVILLE", cp : "27180" },
{ code : "27452", intitule : "PERRIERS LA CAMPAGNE", cp : "27170" },
{ code : "27453", intitule : "PERRIERS SUR ANDELLE", cp : "27910" },
{ code : "27454", intitule : "PERRUEL", cp : "27910" },
{ code : "27455", intitule : "PIENCOURT", cp : "27230" },
{ code : "27456", intitule : "PINTERVILLE", cp : "27400" },
{ code : "27457", intitule : "PISEUX", cp : "27130" },
{ code : "27458", intitule : "PITRES", cp : "27590" },
{ code : "27459", intitule : "LES PLACES", cp : "27230" },
{ code : "27460", intitule : "PLAINVILLE", cp : "27300" },
{ code : "27462", intitule : "LE PLANQUAY", cp : "27230" },
{ code : "27463", intitule : "PLASNES", cp : "27300" },
{ code : "27464", intitule : "LE PLESSIS GROHAN", cp : "27180" },
{ code : "27465", intitule : "LE PLESSIS HEBERT", cp : "27120" },
{ code : "27466", intitule : "LE PLESSIS STE OPPORTUNE", cp : "27170" },
{ code : "27467", intitule : "PONT AUDEMER", cp : "27500" },
{ code : "27467", intitule : "PONT AUDEMER", cp : "27500" },
{ code : "27468", intitule : "PONT AUTHOU", cp : "27290" },
{ code : "27469", intitule : "PONT DE L ARCHE", cp : "27340" },
{ code : "27470", intitule : "PONT ST PIERRE", cp : "27360" },
{ code : "27471", intitule : "PORTE JOIE", cp : "27430" },
{ code : "27472", intitule : "PORTES", cp : "27190" },
{ code : "27473", intitule : "PORT MORT", cp : "27940" },
{ code : "27474", intitule : "POSES", cp : "27740" },
{ code : "27475", intitule : "LA POTERIE MATHIEU", cp : "27560" },
{ code : "27476", intitule : "LES PREAUX", cp : "27500" },
{ code : "27477", intitule : "PRESSAGNY L ORGUEILLEUX", cp : "27510" },
{ code : "27478", intitule : "PREY", cp : "27220" },
{ code : "27480", intitule : "PUCHAY", cp : "27150" },
{ code : "27481", intitule : "PULLAY", cp : "27130" },
{ code : "27482", intitule : "LA PYLE", cp : "27370" },
{ code : "27483", intitule : "QUATREMARE", cp : "27400" },
{ code : "27485", intitule : "QUILLEBEUF SUR SEINE", cp : "27680" },
{ code : "27486", intitule : "QUITTEBEUF", cp : "27110" },
{ code : "27487", intitule : "RADEPONT", cp : "27380" },
{ code : "27488", intitule : "RENNEVILLE", cp : "27910" },
{ code : "27489", intitule : "REUILLY", cp : "27930" },
{ code : "27490", intitule : "RICHEVILLE", cp : "27420" },
{ code : "27491", intitule : "ROMAN", cp : "27240" },
{ code : "27492", intitule : "ROMILLY LA PUTHENAYE", cp : "27170" },
{ code : "27493", intitule : "ROMILLY SUR ANDELLE", cp : "27610" },
{ code : "27495", intitule : "LA ROQUETTE", cp : "27700" },
{ code : "27496", intitule : "ROSAY SUR LIEURE", cp : "27790" },
{ code : "27497", intitule : "ROUGEMONTIERS", cp : "27350" },
{ code : "27498", intitule : "ROUGE PERRIERS", cp : "27110" },
{ code : "27500", intitule : "ROUTOT", cp : "27350" },
{ code : "27501", intitule : "ROUVRAY", cp : "27120" },
{ code : "27502", intitule : "RUGLES", cp : "27250" },
{ code : "27504", intitule : "SACQUENVILLE", cp : "27930" },
{ code : "27505", intitule : "ST AGNAN DE CERNIERES", cp : "27390" },
{ code : "27507", intitule : "ST ANDRE DE L EURE", cp : "27220" },
{ code : "27508", intitule : "ST ANTONIN DE SOMMAIRE", cp : "27250" },
{ code : "27510", intitule : "ST AQUILIN DE PACY", cp : "27120" },
{ code : "27511", intitule : "ST AUBIN D ECROSVILLE", cp : "27110" },
{ code : "27512", intitule : "ST AUBIN DE SCELLON", cp : "27230" },
{ code : "27514", intitule : "ST AUBIN DU THENNEY", cp : "27270" },
{ code : "27516", intitule : "ST AUBIN LE VERTUEUX", cp : "27300" },
{ code : "27517", intitule : "ST AUBIN SUR GAILLON", cp : "27600" },
{ code : "27518", intitule : "ST AUBIN SUR QUILLEBEUF", cp : "27680" },
{ code : "27520", intitule : "ST BENOIT DES OMBRES", cp : "27450" },
{ code : "27521", intitule : "ST CHRISTOPHE SUR AVRE", cp : "27820" },
{ code : "27522", intitule : "ST CHRISTOPHE SUR CONDE", cp : "27450" },
{ code : "27523", intitule : "ST CLAIR D ARCEY", cp : "27300" },
{ code : "27524", intitule : "STE COLOMBE LA COMMANDERIE", cp : "27110" },
{ code : "27525", intitule : "STE COLOMBE PRES VERNON", cp : "27950" },
{ code : "27527", intitule : "ST CYR DE SALERNE", cp : "27800" },
{ code : "27528", intitule : "LE VAUDREUIL", cp : "27100" },
{ code : "27529", intitule : "ST CYR LA CAMPAGNE", cp : "27370" },
{ code : "27530", intitule : "ST DENIS D AUGERONS", cp : "27390" },
{ code : "27531", intitule : "ST DENIS DES MONTS", cp : "27520" },
{ code : "27533", intitule : "ST DENIS LE FERMENT", cp : "27140" },
{ code : "27533", intitule : "ST DENIS LE FERMENT", cp : "27140" },
{ code : "27534", intitule : "ST DIDIER DES BOIS", cp : "27370" },
{ code : "27535", intitule : "ST ELIER", cp : "27190" },
{ code : "27536", intitule : "ST ELOI DE FOURQUES", cp : "27800" },
{ code : "27537", intitule : "ST ETIENNE DU VAUVRAY", cp : "27430" },
{ code : "27538", intitule : "ST ETIENNE L ALLIER", cp : "27450" },
{ code : "27539", intitule : "ST ETIENNE SOUS BAILLEUL", cp : "27920" },
{ code : "27540", intitule : "STE GENEVIEVE LES GASNY", cp : "27620" },
{ code : "27541", intitule : "ST GEORGES DU MESNIL", cp : "27560" },
{ code : "27542", intitule : "ST GEORGES DU VIEVRE", cp : "27450" },
{ code : "27543", intitule : "ST GEORGES MOTEL", cp : "27710" },
{ code : "27544", intitule : "ST GERMAIN DE FRESNEY", cp : "27220" },
{ code : "27545", intitule : "ST GERMAIN DE PASQUIER", cp : "27370" },
{ code : "27546", intitule : "ST GERMAIN DES ANGLES", cp : "27930" },
{ code : "27547", intitule : "ST GERMAIN LA CAMPAGNE", cp : "27230" },
{ code : "27548", intitule : "ST GERMAIN SUR AVRE", cp : "27320" },
{ code : "27549", intitule : "ST GERMAIN VILLAGE", cp : "27500" },
{ code : "27550", intitule : "ST GREGOIRE DU VIEVRE", cp : "27450" },
{ code : "27551", intitule : "ST JEAN DE LA LEQUERAYE", cp : "27560" },
{ code : "27552", intitule : "ST JEAN DU THENNEY", cp : "27270" },
{ code : "27553", intitule : "ST JULIEN DE LA LIEGUE", cp : "27600" },
{ code : "27554", intitule : "ST JUST", cp : "27950" },
{ code : "27555", intitule : "ST LAURENT DES BOIS", cp : "27220" },
{ code : "27556", intitule : "ST LAURENT DU TENCEMENT", cp : "27390" },
{ code : "27557", intitule : "ST LEGER DE ROTES", cp : "27300" },
{ code : "27558", intitule : "ST LEGER DU GENNETEY", cp : "27520" },
{ code : "27560", intitule : "ST LUC", cp : "27930" },
{ code : "27561", intitule : "ST MACLOU", cp : "27210" },
{ code : "27562", intitule : "ST MARCEL", cp : "27950" },
{ code : "27563", intitule : "ST MARDS DE BLACARVILLE", cp : "27500" },
{ code : "27564", intitule : "ST MARDS DE FRESNE", cp : "27230" },
{ code : "27565", intitule : "LE LESME", cp : "27160" },
{ code : "27565", intitule : "LE LESME", cp : "27160" },
{ code : "27567", intitule : "STE MARIE DE VATIMESNIL", cp : "27150" },
{ code : "27568", intitule : "STE MARTHE", cp : "27190" },
{ code : "27569", intitule : "ST MARTIN DU TILLEUL", cp : "27300" },
{ code : "27570", intitule : "ST MARTIN LA CAMPAGNE", cp : "27930" },
{ code : "27571", intitule : "ST MARTIN ST FIRMIN", cp : "27450" },
{ code : "27572", intitule : "ST MESLIN DU BOSC", cp : "27370" },
{ code : "27576", intitule : "STE OPPORTUNE DU BOSC", cp : "27110" },
{ code : "27577", intitule : "STE OPPORTUNE LA MARE", cp : "27680" },
{ code : "27578", intitule : "STE MARIE D ATTEZ", cp : "27160" },
{ code : "27578", intitule : "STE MARIE D ATTEZ", cp : "27160" },
{ code : "27578", intitule : "STE MARIE D ATTEZ", cp : "27160" },
{ code : "27579", intitule : "ST OUEN DE PONTCHEUIL", cp : "27370" },
{ code : "27580", intitule : "ST OUEN DE THOUBERVILLE", cp : "27310" },
{ code : "27581", intitule : "ST OUEN DES CHAMPS", cp : "27680" },
{ code : "27582", intitule : "ST OUEN DU TILLEUL", cp : "27670" },
{ code : "27584", intitule : "ST PAUL DE FOURQUES", cp : "27800" },
{ code : "27586", intitule : "ST PHILBERT SUR BOISSEY", cp : "27520" },
{ code : "27587", intitule : "ST PHILBERT SUR RISLE", cp : "27290" },
{ code : "27588", intitule : "ST PIERRE D AUTILS", cp : "27950" },
{ code : "27589", intitule : "ST PIERRE DE BAILLEUL", cp : "27920" },
{ code : "27590", intitule : "ST PIERRE DE CERNIERES", cp : "27390" },
{ code : "27591", intitule : "ST PIERRE DE CORMEILLES", cp : "27260" },
{ code : "27592", intitule : "ST PIERRE DE SALERNE", cp : "27800" },
{ code : "27593", intitule : "ST PIERRE DES FLEURS", cp : "27370" },
{ code : "27594", intitule : "ST PIERRE DES IFS", cp : "27450" },
{ code : "27595", intitule : "ST PIERRE DU BOSGUERARD", cp : "27370" },
{ code : "27597", intitule : "ST PIERRE DU VAL", cp : "27210" },
{ code : "27598", intitule : "ST PIERRE DU VAUVRAY", cp : "27430" },
{ code : "27599", intitule : "ST PIERRE LA GARENNE", cp : "27600" },
{ code : "27600", intitule : "ST QUENTIN DES ISLES", cp : "27270" },
{ code : "27601", intitule : "ST SAMSON DE LA ROQUE", cp : "27680" },
{ code : "27602", intitule : "ST SEBASTIEN DE MORSENT", cp : "27180" },
{ code : "27603", intitule : "ST SIMEON", cp : "27560" },
{ code : "27604", intitule : "ST SULPICE DE GRIMBOUVILLE", cp : "27210" },
{ code : "27605", intitule : "ST SYLVESTRE DE CORMEILLES", cp : "27260" },
{ code : "27606", intitule : "ST SYMPHORIEN", cp : "27500" },
{ code : "27607", intitule : "ST THURIEN", cp : "27680" },
{ code : "27608", intitule : "ST VICTOR DE CHRETIENVILLE", cp : "27300" },
{ code : "27609", intitule : "ST VICTOR D EPINE", cp : "27800" },
{ code : "27610", intitule : "ST VICTOR SUR AVRE", cp : "27130" },
{ code : "27611", intitule : "ST VIGOR", cp : "27930" },
{ code : "27612", intitule : "ST VINCENT DES BOIS", cp : "27950" },
{ code : "27613", intitule : "ST VINCENT DU BOULAY", cp : "27230" },
{ code : "27614", intitule : "SANCOURT", cp : "27150" },
{ code : "27615", intitule : "SASSEY", cp : "27930" },
{ code : "27616", intitule : "LA SAUSSAYE", cp : "27370" },
{ code : "27617", intitule : "SAUSSAY LA CAMPAGNE", cp : "27150" },
{ code : "27618", intitule : "SEBECOURT", cp : "27190" },
{ code : "27620", intitule : "SELLES", cp : "27500" },
{ code : "27621", intitule : "SEREZ", cp : "27220" },
{ code : "27622", intitule : "SERQUIGNY", cp : "27470" },
{ code : "27623", intitule : "SURTAUVILLE", cp : "27400" },
{ code : "27624", intitule : "SURVILLE", cp : "27400" },
{ code : "27625", intitule : "SUZAY", cp : "27420" },
{ code : "27626", intitule : "THEILLEMENT", cp : "27520" },
{ code : "27627", intitule : "LE THEIL NOLENT", cp : "27230" },
{ code : "27629", intitule : "THIBERVILLE", cp : "27230" },
{ code : "27630", intitule : "THIBOUVILLE", cp : "27800" },
{ code : "27631", intitule : "THIERVILLE", cp : "27290" },
{ code : "27632", intitule : "LE THIL", cp : "27150" },
{ code : "27633", intitule : "LES THILLIERS EN VEXIN", cp : "27420" },
{ code : "27635", intitule : "LE THUIT", cp : "27700" },
{ code : "27638", intitule : "LE THUIT DE L OISON", cp : "27370" },
{ code : "27638", intitule : "LE THUIT DE L OISON", cp : "27370" },
{ code : "27638", intitule : "LE THUIT DE L OISON", cp : "27370" },
{ code : "27640", intitule : "TILLEUL DAME AGNES", cp : "27170" },
{ code : "27641", intitule : "LE TILLEUL LAMBERT", cp : "27110" },
{ code : "27642", intitule : "LE TILLEUL OTHON", cp : "27170" },
{ code : "27643", intitule : "TILLIERES SUR AVRE", cp : "27570" },
{ code : "27644", intitule : "TILLY", cp : "27510" },
{ code : "27645", intitule : "TOCQUEVILLE", cp : "27500" },
{ code : "27646", intitule : "LE TORPT", cp : "27210" },
{ code : "27647", intitule : "TOSNY", cp : "27700" },
{ code : "27648", intitule : "TOSTES", cp : "27340" },
{ code : "27649", intitule : "TOUFFREVILLE", cp : "27440" },
{ code : "27650", intitule : "TOURNEDOS BOIS HUBERT", cp : "27180" },
{ code : "27651", intitule : "TOURNEDOS SUR SEINE", cp : "27100" },
{ code : "27652", intitule : "TOURNEVILLE", cp : "27930" },
{ code : "27654", intitule : "TOURVILLE LA CAMPAGNE", cp : "27370" },
{ code : "27655", intitule : "TOURVILLE SUR PONT AUDEMER", cp : "27500" },
{ code : "27656", intitule : "TOUTAINVILLE", cp : "27500" },
{ code : "27657", intitule : "TOUVILLE", cp : "27290" },
{ code : "27658", intitule : "LE TREMBLAY OMONVILLE", cp : "27110" },
{ code : "27659", intitule : "LA TRINITE", cp : "27930" },
{ code : "27660", intitule : "LA TRINITE DE REVILLE", cp : "27270" },
{ code : "27661", intitule : "LA TRINITE DE THOUBERVILLE", cp : "27310" },
{ code : "27662", intitule : "TRIQUEVILLE", cp : "27500" },
{ code : "27663", intitule : "LE TRONCQ", cp : "27110" },
{ code : "27664", intitule : "LE TRONQUAY", cp : "27480" },
{ code : "27665", intitule : "TROUVILLE LA HAULE", cp : "27680" },
{ code : "27666", intitule : "LA VACHERIE", cp : "27400" },
{ code : "27667", intitule : "VALAILLES", cp : "27300" },
{ code : "27668", intitule : "LE VAL DAVID", cp : "27120" },
{ code : "27669", intitule : "VALLETOT", cp : "27350" },
{ code : "27670", intitule : "VANDRIMARE", cp : "27380" },
{ code : "27671", intitule : "VANNECROCQ", cp : "27210" },
{ code : "27672", intitule : "VASCOEUIL", cp : "27910" },
{ code : "27673", intitule : "VATTEVILLE", cp : "27430" },
{ code : "27674", intitule : "VAUX SUR EURE", cp : "27120" },
{ code : "27676", intitule : "VENABLES", cp : "27940" },
{ code : "27677", intitule : "VENON", cp : "27110" },
{ code : "27678", intitule : "LES VENTES", cp : "27180" },
{ code : "27679", intitule : "VERNEUIL SUR AVRE", cp : "27130" },
{ code : "27680", intitule : "VERNEUSSES", cp : "27390" },
{ code : "27681", intitule : "VERNON", cp : "27200" },
{ code : "27682", intitule : "VESLY", cp : "27870" },
{ code : "27683", intitule : "VEZILLON", cp : "27700" },
{ code : "27684", intitule : "LE VIEIL EVREUX", cp : "27930" },
{ code : "27685", intitule : "LA VIEILLE LYRE", cp : "27330" },
{ code : "27686", intitule : "VIEUX PORT", cp : "27680" },
{ code : "27689", intitule : "VILLEGATS", cp : "27120" },
{ code : "27690", intitule : "VILLERS EN VEXIN", cp : "27420" },
{ code : "27691", intitule : "VILLERS SUR LE ROULE", cp : "27940" },
{ code : "27692", intitule : "VILLETTES", cp : "27110" },
{ code : "27693", intitule : "SYLVAINS LES MOULINS", cp : "27240" },
{ code : "27693", intitule : "SYLVAINS LES MOULINS", cp : "27240" },
{ code : "27693", intitule : "SYLVAINS LES MOULINS", cp : "27240" },
{ code : "27694", intitule : "VILLEZ SOUS BAILLEUL", cp : "27950" },
{ code : "27695", intitule : "VILLEZ SUR LE NEUBOURG", cp : "27110" },
{ code : "27696", intitule : "VILLIERS EN DESOEUVRE", cp : "27640" },
{ code : "27697", intitule : "VIRONVAY", cp : "27400" },
{ code : "27698", intitule : "VITOT", cp : "27110" },
{ code : "27699", intitule : "VOISCREVILLE", cp : "27520" },
{ code : "27700", intitule : "VRAIVILLE", cp : "27370" },
{ code : "27701", intitule : "VAL DE REUIL", cp : "27100" },
{ code : "28001", intitule : "ABONDANT", cp : "28410" },
{ code : "28002", intitule : "ALLAINES MERVILLIERS", cp : "28310" },
{ code : "28002", intitule : "ALLAINES MERVILLIERS", cp : "28310" },
{ code : "28003", intitule : "ALLAINVILLE", cp : "28500" },
{ code : "28004", intitule : "ALLONNES", cp : "28150" },
{ code : "28005", intitule : "ALLUYES", cp : "28800" },
{ code : "28006", intitule : "AMILLY", cp : "28300" },
{ code : "28007", intitule : "ANET", cp : "28260" },
{ code : "28008", intitule : "ARDELLES", cp : "28170" },
{ code : "28009", intitule : "ARDELU", cp : "28700" },
{ code : "28010", intitule : "ARGENVILLIERS", cp : "28480" },
{ code : "28012", intitule : "ARROU", cp : "28290" },
{ code : "28013", intitule : "AUNAY SOUS AUNEAU", cp : "28700" },
{ code : "28014", intitule : "AUNAY SOUS CRECY", cp : "28500" },
{ code : "28015", intitule : "AUNEAU BLEURY ST SYMPHORIEN", cp : "28700" },
{ code : "28015", intitule : "AUNEAU BLEURY ST SYMPHORIEN", cp : "28700" },
{ code : "28015", intitule : "AUNEAU BLEURY ST SYMPHORIEN", cp : "28700" },
{ code : "28016", intitule : "LES AUTELS VILLEVILLON", cp : "28330" },
{ code : "28017", intitule : "AUTHEUIL", cp : "28220" },
{ code : "28018", intitule : "AUTHON DU PERCHE", cp : "28330" },
{ code : "28019", intitule : "BAIGNEAUX", cp : "28140" },
{ code : "28021", intitule : "BAILLEAU LE PIN", cp : "28120" },
{ code : "28022", intitule : "BAILLEAU L EVEQUE", cp : "28300" },
{ code : "28023", intitule : "BAILLEAU ARMENONVILLE", cp : "28320" },
{ code : "28023", intitule : "BAILLEAU ARMENONVILLE", cp : "28320" },
{ code : "28024", intitule : "BARJOUVILLE", cp : "28630" },
{ code : "28025", intitule : "BARMAINVILLE", cp : "28310" },
{ code : "28026", intitule : "BAUDREVILLE", cp : "28310" },
{ code : "28027", intitule : "LA BAZOCHE GOUET", cp : "28330" },
{ code : "28028", intitule : "BAZOCHES EN DUNOIS", cp : "28140" },
{ code : "28029", intitule : "BAZOCHES LES HAUTES", cp : "28140" },
{ code : "28030", intitule : "BEAUCHE", cp : "28270" },
{ code : "28031", intitule : "BEAUMONT LES AUTELS", cp : "28480" },
{ code : "28032", intitule : "BEAUVILLIERS", cp : "28150" },
{ code : "28033", intitule : "BELHOMERT GUEHOUVILLE", cp : "28240" },
{ code : "28034", intitule : "BERCHERES ST GERMAIN", cp : "28300" },
{ code : "28034", intitule : "BERCHERES ST GERMAIN", cp : "28300" },
{ code : "28035", intitule : "BERCHERES LES PIERRES", cp : "28630" },
{ code : "28036", intitule : "BERCHERES SUR VESGRE", cp : "28260" },
{ code : "28037", intitule : "BEROU LA MULOTIERE", cp : "28270" },
{ code : "28038", intitule : "BETHONVILLIERS", cp : "28330" },
{ code : "28039", intitule : "BEVILLE LE COMTE", cp : "28700" },
{ code : "28040", intitule : "BILLANCELLES", cp : "28190" },
{ code : "28041", intitule : "BLANDAINVILLE", cp : "28120" },
{ code : "28044", intitule : "BOISGASSON", cp : "28220" },
{ code : "28045", intitule : "BOISSY EN DROUAIS", cp : "28500" },
{ code : "28046", intitule : "BOISSY LES PERCHE", cp : "28340" },
{ code : "28047", intitule : "BOISVILLE LA ST PERE", cp : "28150" },
{ code : "28048", intitule : "LA BOURDINIERE ST LOUP", cp : "28360" },
{ code : "28048", intitule : "LA BOURDINIERE ST LOUP", cp : "28360" },
{ code : "28049", intitule : "BONCE", cp : "28150" },
{ code : "28050", intitule : "BONCOURT", cp : "28260" },
{ code : "28051", intitule : "BONNEVAL", cp : "28800" },
{ code : "28052", intitule : "BOUGLAINVAL", cp : "28130" },
{ code : "28053", intitule : "LE BOULLAY LES DEUX EGLISES", cp : "28170" },
{ code : "28054", intitule : "LE BOULLAY MIVOYE", cp : "28210" },
{ code : "28055", intitule : "LE BOULLAY THIERRY", cp : "28210" },
{ code : "28056", intitule : "BOUTIGNY PROUAIS", cp : "28410" },
{ code : "28056", intitule : "BOUTIGNY PROUAIS", cp : "28410" },
{ code : "28057", intitule : "BOUVILLE", cp : "28800" },
{ code : "28058", intitule : "BRECHAMPS", cp : "28210" },
{ code : "28059", intitule : "BREZOLLES", cp : "28270" },
{ code : "28060", intitule : "BRICONVILLE", cp : "28300" },
{ code : "28061", intitule : "BROU", cp : "28160" },
{ code : "28062", intitule : "BROUE", cp : "28410" },
{ code : "28063", intitule : "BRUNELLES", cp : "28400" },
{ code : "28064", intitule : "BU", cp : "28410" },
{ code : "28065", intitule : "BULLAINVILLE", cp : "28800" },
{ code : "28066", intitule : "BULLOU", cp : "28160" },
{ code : "28067", intitule : "CERNAY", cp : "28120" },
{ code : "28068", intitule : "CHALLET", cp : "28300" },
{ code : "28070", intitule : "CHAMPHOL", cp : "28300" },
{ code : "28071", intitule : "CHAMPROND EN GATINE", cp : "28240" },
{ code : "28072", intitule : "CHAMPROND EN PERCHET", cp : "28400" },
{ code : "28073", intitule : "CHAMPSERU", cp : "28700" },
{ code : "28074", intitule : "LA CHAPELLE D AUNAINVILLE", cp : "28700" },
{ code : "28075", intitule : "LA CHAPELLE DU NOYER", cp : "28200" },
{ code : "28076", intitule : "LA CHAPELLE FORAINVILLIERS", cp : "28500" },
{ code : "28077", intitule : "LA CHAPELLE FORTIN", cp : "28340" },
{ code : "28078", intitule : "CHAPELLE GUILLAUME", cp : "28330" },
{ code : "28079", intitule : "CHAPELLE ROYALE", cp : "28290" },
{ code : "28080", intitule : "CHARBONNIERES", cp : "28330" },
{ code : "28081", intitule : "CHARONVILLE", cp : "28120" },
{ code : "28082", intitule : "CHARPONT", cp : "28500" },
{ code : "28083", intitule : "CHARRAY", cp : "28220" },
{ code : "28084", intitule : "CHARTAINVILLIERS", cp : "28130" },
{ code : "28085", intitule : "CHARTRES", cp : "28000" },
{ code : "28086", intitule : "CHASSANT", cp : "28480" },
{ code : "28087", intitule : "CHATAINCOURT", cp : "28270" },
{ code : "28088", intitule : "CHATEAUDUN", cp : "28200" },
{ code : "28089", intitule : "CHATEAUNEUF EN THYMERAIS", cp : "28170" },
{ code : "28090", intitule : "LES CHATELETS", cp : "28270" },
{ code : "28091", intitule : "LES CHATELLIERS NOTRE DAME", cp : "28120" },
{ code : "28092", intitule : "CHATENAY", cp : "28700" },
{ code : "28093", intitule : "CHATILLON EN DUNOIS", cp : "28290" },
{ code : "28094", intitule : "CHAUDON", cp : "28210" },
{ code : "28095", intitule : "CHAUFFOURS", cp : "28120" },
{ code : "28096", intitule : "LA CHAUSSEE D IVRY", cp : "28260" },
{ code : "28098", intitule : "CHERISY", cp : "28500" },
{ code : "28099", intitule : "CHUISNES", cp : "28190" },
{ code : "28100", intitule : "CINTRAY", cp : "28300" },
{ code : "28101", intitule : "CIVRY", cp : "28200" },
{ code : "28102", intitule : "CLEVILLIERS", cp : "28300" },
{ code : "28103", intitule : "CLOYES SUR LE LOIR", cp : "28220" },
{ code : "28104", intitule : "COLTAINVILLE", cp : "28300" },
{ code : "28105", intitule : "COMBRES", cp : "28480" },
{ code : "28106", intitule : "CONIE MOLITARD", cp : "28200" },
{ code : "28107", intitule : "CORANCEZ", cp : "28630" },
{ code : "28108", intitule : "CORMAINVILLE", cp : "28140" },
{ code : "28109", intitule : "LES CORVEES LES YYS", cp : "28240" },
{ code : "28110", intitule : "LE COUDRAY", cp : "28630" },
{ code : "28111", intitule : "COUDRAY AU PERCHE", cp : "28330" },
{ code : "28112", intitule : "COUDRECEAU", cp : "28400" },
{ code : "28113", intitule : "COULOMBS", cp : "28210" },
{ code : "28114", intitule : "COURBEHAYE", cp : "28140" },
{ code : "28115", intitule : "COURTALAIN", cp : "28290" },
{ code : "28116", intitule : "COURVILLE SUR EURE", cp : "28190" },
{ code : "28117", intitule : "CRECY COUVE", cp : "28500" },
{ code : "28118", intitule : "CROISILLES", cp : "28210" },
{ code : "28119", intitule : "LA CROIX DU PERCHE", cp : "28480" },
{ code : "28120", intitule : "CRUCEY VILLAGES", cp : "28270" },
{ code : "28120", intitule : "CRUCEY VILLAGES", cp : "28270" },
{ code : "28120", intitule : "CRUCEY VILLAGES", cp : "28270" },
{ code : "28121", intitule : "DAMBRON", cp : "28140" },
{ code : "28122", intitule : "DAMMARIE", cp : "28360" },
{ code : "28123", intitule : "DAMPIERRE SOUS BROU", cp : "28160" },
{ code : "28124", intitule : "DAMPIERRE SUR AVRE", cp : "28350" },
{ code : "28126", intitule : "DANCY", cp : "28800" },
{ code : "28127", intitule : "DANGEAU", cp : "28160" },
{ code : "28128", intitule : "DANGERS", cp : "28190" },
{ code : "28129", intitule : "DENONVILLE", cp : "28700" },
{ code : "28130", intitule : "DIGNY", cp : "28250" },
{ code : "28132", intitule : "DONNEMAIN ST MAMES", cp : "28200" },
{ code : "28133", intitule : "DOUY", cp : "28220" },
{ code : "28134", intitule : "DREUX", cp : "28100" },
{ code : "28135", intitule : "DROUE SUR DROUETTE", cp : "28230" },
{ code : "28136", intitule : "ECLUZELLES", cp : "28500" },
{ code : "28137", intitule : "ECROSNES", cp : "28320" },
{ code : "28139", intitule : "EPEAUTROLLES", cp : "28120" },
{ code : "28140", intitule : "EPERNON", cp : "28230" },
{ code : "28141", intitule : "ERMENONVILLE LA GRANDE", cp : "28120" },
{ code : "28142", intitule : "ERMENONVILLE LA PETITE", cp : "28120" },
{ code : "28143", intitule : "ESCORPAIN", cp : "28270" },
{ code : "28144", intitule : "LES ETILLEUX", cp : "28330" },
{ code : "28146", intitule : "FAVEROLLES", cp : "28210" },
{ code : "28147", intitule : "FAVIERES", cp : "28170" },
{ code : "28148", intitule : "LE FAVRIL", cp : "28190" },
{ code : "28149", intitule : "LA FERTE VIDAME", cp : "28340" },
{ code : "28150", intitule : "LA FERTE VILLENEUIL", cp : "28220" },
{ code : "28151", intitule : "FESSANVILLIERS MATTANVILLIERS", cp : "28270" },
{ code : "28153", intitule : "FLACEY", cp : "28800" },
{ code : "28154", intitule : "FONTAINE LA GUYON", cp : "28190" },
{ code : "28155", intitule : "FONTAINE LES RIBOUTS", cp : "28170" },
{ code : "28156", intitule : "FONTAINE SIMON", cp : "28240" },
{ code : "28157", intitule : "FONTENAY SUR CONIE", cp : "28140" },
{ code : "28158", intitule : "FONTENAY SUR EURE", cp : "28630" },
{ code : "28159", intitule : "LA FRAMBOISIERE", cp : "28250" },
{ code : "28160", intitule : "FRANCOURVILLE", cp : "28700" },
{ code : "28161", intitule : "FRAZE", cp : "28160" },
{ code : "28162", intitule : "FRESNAY LE COMTE", cp : "28360" },
{ code : "28163", intitule : "FRESNAY LE GILMERT", cp : "28300" },
{ code : "28164", intitule : "FRESNAY L EVEQUE", cp : "28310" },
{ code : "28165", intitule : "FRETIGNY", cp : "28480" },
{ code : "28166", intitule : "FRIAIZE", cp : "28240" },
{ code : "28167", intitule : "FRUNCE", cp : "28190" },
{ code : "28168", intitule : "GALLARDON", cp : "28320" },
{ code : "28168", intitule : "GALLARDON", cp : "28320" },
{ code : "28169", intitule : "GARANCIERES EN BEAUCE", cp : "28700" },
{ code : "28170", intitule : "GARANCIERES EN DROUAIS", cp : "28500" },
{ code : "28171", intitule : "GARNAY", cp : "28500" },
{ code : "28172", intitule : "GAS", cp : "28320" },
{ code : "28173", intitule : "GASVILLE OISEME", cp : "28300" },
{ code : "28175", intitule : "LA GAUDAINE", cp : "28400" },
{ code : "28176", intitule : "LE GAULT ST DENIS", cp : "28800" },
{ code : "28177", intitule : "GELLAINVILLE", cp : "28630" },
{ code : "28178", intitule : "GERMAINVILLE", cp : "28500" },
{ code : "28180", intitule : "GILLES", cp : "28260" },
{ code : "28182", intitule : "GOHORY", cp : "28160" },
{ code : "28183", intitule : "GOMMERVILLE", cp : "28700" },
{ code : "28183", intitule : "GOMMERVILLE", cp : "28310" },
{ code : "28183", intitule : "GOMMERVILLE", cp : "28310" },
{ code : "28184", intitule : "GOUILLONS", cp : "28310" },
{ code : "28185", intitule : "GOUSSAINVILLE", cp : "28410" },
{ code : "28185", intitule : "GOUSSAINVILLE", cp : "28410" },
{ code : "28187", intitule : "GUAINVILLE", cp : "28260" },
{ code : "28188", intitule : "LE GUE DE LONGROI", cp : "28700" },
{ code : "28189", intitule : "GUILLEVILLE", cp : "28310" },
{ code : "28190", intitule : "GUILLONVILLE", cp : "28140" },
{ code : "28191", intitule : "HANCHES", cp : "28130" },
{ code : "28192", intitule : "HAPPONVILLIERS", cp : "28480" },
{ code : "28193", intitule : "HAVELU", cp : "28410" },
{ code : "28194", intitule : "HOUVILLE LA BRANCHE", cp : "28700" },
{ code : "28195", intitule : "HOUX", cp : "28130" },
{ code : "28196", intitule : "ILLIERS COMBRAY", cp : "28120" },
{ code : "28197", intitule : "INTREVILLE", cp : "28310" },
{ code : "28198", intitule : "JALLANS", cp : "28200" },
{ code : "28199", intitule : "JANVILLE", cp : "28310" },
{ code : "28200", intitule : "JAUDRAIS", cp : "28250" },
{ code : "28201", intitule : "JOUY", cp : "28300" },
{ code : "28202", intitule : "LAMBLORE", cp : "28340" },
{ code : "28203", intitule : "LANDELLES", cp : "28190" },
{ code : "28204", intitule : "LANGEY", cp : "28220" },
{ code : "28205", intitule : "LANNERAY", cp : "28200" },
{ code : "28206", intitule : "LAONS", cp : "28270" },
{ code : "28207", intitule : "LETHUIN", cp : "28700" },
{ code : "28208", intitule : "LEVAINVILLE", cp : "28700" },
{ code : "28209", intitule : "LEVES", cp : "28300" },
{ code : "28210", intitule : "LEVESVILLE LA CHENARD", cp : "28310" },
{ code : "28211", intitule : "LOGRON", cp : "28200" },
{ code : "28212", intitule : "LOIGNY LA BATAILLE", cp : "28140" },
{ code : "28213", intitule : "LORMAYE", cp : "28210" },
{ code : "28214", intitule : "LA LOUPE", cp : "28240" },
{ code : "28215", intitule : "LOUVILLE LA CHENARD", cp : "28150" },
{ code : "28216", intitule : "LOUVILLIERS EN DROUAIS", cp : "28500" },
{ code : "28217", intitule : "LOUVILLIERS LES PERCHE", cp : "28250" },
{ code : "28218", intitule : "LUCE", cp : "28110" },
{ code : "28219", intitule : "LUIGNY", cp : "28480" },
{ code : "28220", intitule : "LUISANT", cp : "28600" },
{ code : "28221", intitule : "LUMEAU", cp : "28140" },
{ code : "28222", intitule : "LUPLANTE", cp : "28360" },
{ code : "28223", intitule : "LURAY", cp : "28500" },
{ code : "28224", intitule : "LUTZ EN DUNOIS", cp : "28200" },
{ code : "28225", intitule : "MAGNY", cp : "28120" },
{ code : "28226", intitule : "MAILLEBOIS", cp : "28170" },
{ code : "28226", intitule : "MAILLEBOIS", cp : "28170" },
{ code : "28226", intitule : "MAILLEBOIS", cp : "28170" },
{ code : "28227", intitule : "MAINTENON", cp : "28130" },
{ code : "28229", intitule : "MAINVILLIERS", cp : "28300" },
{ code : "28230", intitule : "MAISONS", cp : "28700" },
{ code : "28231", intitule : "LA MANCELIERE", cp : "28270" },
{ code : "28232", intitule : "MANOU", cp : "28240" },
{ code : "28233", intitule : "MARBOUE", cp : "28200" },
{ code : "28234", intitule : "MARCHEVILLE", cp : "28120" },
{ code : "28235", intitule : "MARCHEZAIS", cp : "28410" },
{ code : "28236", intitule : "MARGON", cp : "28400" },
{ code : "28237", intitule : "MAROLLES LES BUIS", cp : "28400" },
{ code : "28239", intitule : "MARVILLE MOUTIERS BRULE", cp : "28500" },
{ code : "28240", intitule : "MEAUCE", cp : "28240" },
{ code : "28241", intitule : "LE MEE", cp : "28220" },
{ code : "28242", intitule : "MEREGLISE", cp : "28120" },
{ code : "28243", intitule : "MEROUVILLE", cp : "28310" },
{ code : "28245", intitule : "MESLAY LE GRENET", cp : "28120" },
{ code : "28246", intitule : "MESLAY LE VIDAME", cp : "28360" },
{ code : "28247", intitule : "LE MESNIL SIMON", cp : "28260" },
{ code : "28248", intitule : "LE MESNIL THOMAS", cp : "28250" },
{ code : "28249", intitule : "MEVOISINS", cp : "28130" },
{ code : "28250", intitule : "MEZIERES AU PERCHE", cp : "28160" },
{ code : "28251", intitule : "MEZIERES EN DROUAIS", cp : "28500" },
{ code : "28252", intitule : "MIERMAIGNE", cp : "28480" },
{ code : "28253", intitule : "MIGNIERES", cp : "28630" },
{ code : "28254", intitule : "MITTAINVILLIERS VERIGNY", cp : "28190" },
{ code : "28254", intitule : "MITTAINVILLIERS VERIGNY", cp : "28190" },
{ code : "28255", intitule : "MOINVILLE LA JEULIN", cp : "28700" },
{ code : "28256", intitule : "MOLEANS", cp : "28200" },
{ code : "28257", intitule : "MONDONVILLE ST JEAN", cp : "28700" },
{ code : "28259", intitule : "MONTBOISSIER", cp : "28800" },
{ code : "28260", intitule : "MONTHARVILLE", cp : "28800" },
{ code : "28261", intitule : "MONTIGNY LE CHARTIF", cp : "28120" },
{ code : "28262", intitule : "MONTIGNY LE GANNELON", cp : "28220" },
{ code : "28263", intitule : "MONTIGNY SUR AVRE", cp : "28270" },
{ code : "28264", intitule : "MONTIREAU", cp : "28240" },
{ code : "28265", intitule : "MONTLANDON", cp : "28240" },
{ code : "28267", intitule : "MONTREUIL", cp : "28500" },
{ code : "28268", intitule : "MORAINVILLE", cp : "28700" },
{ code : "28269", intitule : "MORANCEZ", cp : "28630" },
{ code : "28270", intitule : "MORIERS", cp : "28800" },
{ code : "28271", intitule : "MORVILLIERS", cp : "28340" },
{ code : "28272", intitule : "MOTTEREAU", cp : "28160" },
{ code : "28273", intitule : "MOULHARD", cp : "28160" },
{ code : "28274", intitule : "MOUTIERS", cp : "28150" },
{ code : "28275", intitule : "NERON", cp : "28210" },
{ code : "28276", intitule : "NEUVY EN BEAUCE", cp : "28310" },
{ code : "28277", intitule : "NEUVY EN DUNOIS", cp : "28800" },
{ code : "28278", intitule : "NOGENT LE PHAYE", cp : "28630" },
{ code : "28279", intitule : "NOGENT LE ROI", cp : "28210" },
{ code : "28279", intitule : "NOGENT LE ROI", cp : "28210" },
{ code : "28280", intitule : "NOGENT LE ROTROU", cp : "28400" },
{ code : "28281", intitule : "NOGENT SUR EURE", cp : "28120" },
{ code : "28282", intitule : "NONVILLIERS GRANDHOUX", cp : "28120" },
{ code : "28283", intitule : "NOTTONVILLE", cp : "28140" },
{ code : "28284", intitule : "OINVILLE ST LIPHARD", cp : "28310" },
{ code : "28285", intitule : "OINVILLE SOUS AUNEAU", cp : "28700" },
{ code : "28286", intitule : "OLLE", cp : "28120" },
{ code : "28287", intitule : "ORGERES EN BEAUCE", cp : "28140" },
{ code : "28289", intitule : "ORMOY", cp : "28210" },
{ code : "28290", intitule : "ORROUER", cp : "28190" },
{ code : "28291", intitule : "OUARVILLE", cp : "28150" },
{ code : "28292", intitule : "OUERRE", cp : "28500" },
{ code : "28293", intitule : "OULINS", cp : "28260" },
{ code : "28294", intitule : "OYSONVILLE", cp : "28700" },
{ code : "28295", intitule : "OZOIR LE BREUIL", cp : "28200" },
{ code : "28296", intitule : "PERONVILLE", cp : "28140" },
{ code : "28298", intitule : "PIERRES", cp : "28130" },
{ code : "28299", intitule : "LES PINTHIERES", cp : "28210" },
{ code : "28300", intitule : "POINVILLE", cp : "28310" },
{ code : "28301", intitule : "POISVILLIERS", cp : "28300" },
{ code : "28302", intitule : "PONTGOUIN", cp : "28190" },
{ code : "28303", intitule : "POUPRY", cp : "28140" },
{ code : "28304", intitule : "PRASVILLE", cp : "28150" },
{ code : "28305", intitule : "PRE ST EVROULT", cp : "28800" },
{ code : "28306", intitule : "PRE ST MARTIN", cp : "28800" },
{ code : "28308", intitule : "PRUDEMANCHE", cp : "28270" },
{ code : "28309", intitule : "PRUNAY LE GILLON", cp : "28360" },
{ code : "28310", intitule : "LA PUISAYE", cp : "28250" },
{ code : "28311", intitule : "LE PUISET", cp : "28310" },
{ code : "28312", intitule : "PUISEUX", cp : "28170" },
{ code : "28313", intitule : "RECLAINVILLE", cp : "28150" },
{ code : "28314", intitule : "LES RESSUINTES", cp : "28340" },
{ code : "28315", intitule : "REVERCOURT", cp : "28270" },
{ code : "28316", intitule : "ROHAIRE", cp : "28340" },
{ code : "28317", intitule : "ROINVILLE", cp : "28700" },
{ code : "28318", intitule : "ROMILLY SUR AIGRE", cp : "28220" },
{ code : "28319", intitule : "ROUVRAY ST DENIS", cp : "28310" },
{ code : "28321", intitule : "ROUVRES", cp : "28260" },
{ code : "28322", intitule : "RUEIL LA GADELIERE", cp : "28270" },
{ code : "28323", intitule : "ST ANGE ET TORCAY", cp : "28170" },
{ code : "28324", intitule : "ST ARNOULT DES BOIS", cp : "28190" },
{ code : "28325", intitule : "ST AUBIN DES BOIS", cp : "28300" },
{ code : "28326", intitule : "ST AVIT LES GUESPIERES", cp : "28120" },
{ code : "28327", intitule : "ST BOMER", cp : "28330" },
{ code : "28329", intitule : "ST CHRISTOPHE", cp : "28200" },
{ code : "28330", intitule : "ST CLOUD EN DUNOIS", cp : "28200" },
{ code : "28331", intitule : "ST DENIS D AUTHOU", cp : "28480" },
{ code : "28332", intitule : "STE GEMME MORONVAL", cp : "28500" },
{ code : "28333", intitule : "ST DENIS DES PUITS", cp : "28240" },
{ code : "28334", intitule : "ST DENIS LES PONTS", cp : "28200" },
{ code : "28335", intitule : "ST ELIPH", cp : "28240" },
{ code : "28336", intitule : "ST EMAN", cp : "28120" },
{ code : "28337", intitule : "ST GEORGES SUR EURE", cp : "28190" },
{ code : "28339", intitule : "ST GERMAIN LE GAILLARD", cp : "28190" },
{ code : "28340", intitule : "ST HILAIRE SUR YERRE", cp : "28220" },
{ code : "28341", intitule : "ST JEAN DE REBERVILLIERS", cp : "28170" },
{ code : "28342", intitule : "ST JEAN PIERRE FIXTE", cp : "28400" },
{ code : "28343", intitule : "ST LAURENT LA GATINE", cp : "28210" },
{ code : "28344", intitule : "ST LEGER DES AUBEES", cp : "28700" },
{ code : "28346", intitule : "ST LUBIN DE CRAVANT", cp : "28270" },
{ code : "28347", intitule : "ST LUBIN DE LA HAYE", cp : "28410" },
{ code : "28348", intitule : "ST LUBIN DES JONCHERETS", cp : "28350" },
{ code : "28349", intitule : "ST LUCIEN", cp : "28210" },
{ code : "28350", intitule : "ST LUPERCE", cp : "28190" },
{ code : "28351", intitule : "ST MAIXME HAUTERIVE", cp : "28170" },
{ code : "28352", intitule : "ST MARTIN DE NIGELLES", cp : "28130" },
{ code : "28353", intitule : "ST MAUR SUR LE LOIR", cp : "28800" },
{ code : "28354", intitule : "ST MAURICE ST GERMAIN", cp : "28240" },
{ code : "28355", intitule : "ST OUEN MARCHEFROY", cp : "28260" },
{ code : "28356", intitule : "ST PELLERIN", cp : "28290" },
{ code : "28357", intitule : "ST PIAT", cp : "28130" },
{ code : "28358", intitule : "ST PREST", cp : "28300" },
{ code : "28359", intitule : "ST REMY SUR AVRE", cp : "28380" },
{ code : "28360", intitule : "ST SAUVEUR MARVILLE", cp : "28170" },
{ code : "28360", intitule : "ST SAUVEUR MARVILLE", cp : "28170" },
{ code : "28362", intitule : "ST VICTOR DE BUTHON", cp : "28240" },
{ code : "28363", intitule : "SAINVILLE", cp : "28700" },
{ code : "28364", intitule : "SANCHEVILLE", cp : "28800" },
{ code : "28365", intitule : "SANDARVILLE", cp : "28120" },
{ code : "28366", intitule : "SANTEUIL", cp : "28700" },
{ code : "28367", intitule : "SANTILLY", cp : "28310" },
{ code : "28368", intitule : "LA SAUCELLE", cp : "28250" },
{ code : "28369", intitule : "SAULNIERES", cp : "28500" },
{ code : "28370", intitule : "SAUMERAY", cp : "28800" },
{ code : "28371", intitule : "SAUSSAY", cp : "28260" },
{ code : "28372", intitule : "SENANTES", cp : "28210" },
{ code : "28373", intitule : "SENONCHES", cp : "28250" },
{ code : "28373", intitule : "SENONCHES", cp : "28250" },
{ code : "28373", intitule : "SENONCHES", cp : "28250" },
{ code : "28374", intitule : "SERAZEREUX", cp : "28170" },
{ code : "28375", intitule : "SERVILLE", cp : "28410" },
{ code : "28376", intitule : "SOIZE", cp : "28330" },
{ code : "28377", intitule : "SOREL MOUSSEL", cp : "28260" },
{ code : "28378", intitule : "SOUANCE AU PERCHE", cp : "28400" },
{ code : "28379", intitule : "SOULAIRES", cp : "28130" },
{ code : "28380", intitule : "SOURS", cp : "28630" },
{ code : "28382", intitule : "TERMINIERS", cp : "28140" },
{ code : "28383", intitule : "THEUVILLE", cp : "28360" },
{ code : "28383", intitule : "THEUVILLE", cp : "28150" },
{ code : "28385", intitule : "LE THIEULIN", cp : "28240" },
{ code : "28386", intitule : "THIMERT GATELLES", cp : "28170" },
{ code : "28386", intitule : "THIMERT GATELLES", cp : "28170" },
{ code : "28387", intitule : "THIRON GARDAIS", cp : "28480" },
{ code : "28388", intitule : "THIVARS", cp : "28630" },
{ code : "28389", intitule : "THIVILLE", cp : "28200" },
{ code : "28390", intitule : "TILLAY LE PENEUX", cp : "28140" },
{ code : "28391", intitule : "TOURY", cp : "28310" },
{ code : "28392", intitule : "TRANCRAINVILLE", cp : "28310" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28393", intitule : "TREMBLAY LES VILLAGES", cp : "28170" },
{ code : "28394", intitule : "TREON", cp : "28500" },
{ code : "28395", intitule : "TRIZAY COUTRETOT ST SERGE", cp : "28400" },
{ code : "28396", intitule : "TRIZAY LES BONNEVAL", cp : "28800" },
{ code : "28397", intitule : "UMPEAU", cp : "28700" },
{ code : "28398", intitule : "UNVERRE", cp : "28160" },
{ code : "28400", intitule : "VARIZE", cp : "28140" },
{ code : "28401", intitule : "VAUPILLON", cp : "28240" },
{ code : "28403", intitule : "VER LES CHARTRES", cp : "28630" },
{ code : "28404", intitule : "VERNOUILLET", cp : "28500" },
{ code : "28405", intitule : "VERT EN DROUAIS", cp : "28500" },
{ code : "28406", intitule : "EOLE EN BEAUCE", cp : "28150" },
{ code : "28406", intitule : "EOLE EN BEAUCE", cp : "28140" },
{ code : "28406", intitule : "EOLE EN BEAUCE", cp : "28150" },
{ code : "28406", intitule : "EOLE EN BEAUCE", cp : "28150" },
{ code : "28407", intitule : "VICHERES", cp : "28480" },
{ code : "28408", intitule : "VIERVILLE", cp : "28700" },
{ code : "28409", intitule : "VIEUVICQ", cp : "28120" },
{ code : "28410", intitule : "VILLAMPUY", cp : "28200" },
{ code : "28411", intitule : "VILLARS", cp : "28150" },
{ code : "28412", intitule : "VILLEAU", cp : "28150" },
{ code : "28414", intitule : "VILLEBON", cp : "28190" },
{ code : "28415", intitule : "VILLEMEUX SUR EURE", cp : "28210" },
{ code : "28417", intitule : "VILLIERS LE MORHIER", cp : "28130" },
{ code : "28418", intitule : "VILLIERS ST ORIEN", cp : "28800" },
{ code : "28419", intitule : "VITRAY EN BEAUCE", cp : "28360" },
{ code : "28421", intitule : "VOISE", cp : "28700" },
{ code : "28422", intitule : "LES VILLAGES VOVEENS", cp : "28150" },
{ code : "28422", intitule : "LES VILLAGES VOVEENS", cp : "28150" },
{ code : "28422", intitule : "LES VILLAGES VOVEENS", cp : "28150" },
{ code : "28422", intitule : "LES VILLAGES VOVEENS", cp : "28150" },
{ code : "28423", intitule : "YERMENONVILLE", cp : "28130" },
{ code : "28424", intitule : "YEVRES", cp : "28160" },
{ code : "28425", intitule : "YMERAY", cp : "28320" },
{ code : "28426", intitule : "YMONVILLE", cp : "28150" },
{ code : "29001", intitule : "ARGOL", cp : "29560" },
{ code : "29002", intitule : "ARZANO", cp : "29300" },
{ code : "29003", intitule : "AUDIERNE", cp : "29770" },
{ code : "29003", intitule : "AUDIERNE", cp : "29770" },
{ code : "29004", intitule : "BANNALEC", cp : "29380" },
{ code : "29005", intitule : "BAYE", cp : "29300" },
{ code : "29006", intitule : "BENODET", cp : "29950" },
{ code : "29007", intitule : "BERRIEN", cp : "29690" },
{ code : "29008", intitule : "BEUZEC CAP SIZUN", cp : "29790" },
{ code : "29010", intitule : "BODILIS", cp : "29400" },
{ code : "29011", intitule : "BOHARS", cp : "29820" },
{ code : "29012", intitule : "BOLAZEC", cp : "29640" },
{ code : "29013", intitule : "BOTMEUR", cp : "29690" },
{ code : "29014", intitule : "BOTSORHEL", cp : "29650" },
{ code : "29015", intitule : "BOURG BLANC", cp : "29860" },
{ code : "29016", intitule : "BRASPARTS", cp : "29190" },
{ code : "29017", intitule : "BRELES", cp : "29810" },
{ code : "29018", intitule : "BRENNILIS", cp : "29690" },
{ code : "29019", intitule : "BREST", cp : "29200" },
{ code : "29020", intitule : "BRIEC", cp : "29510" },
{ code : "29021", intitule : "BRIGNOGAN PLAGE", cp : "29890" },
{ code : "29022", intitule : "CAMARET SUR MER", cp : "29570" },
{ code : "29023", intitule : "CARANTEC", cp : "29660" },
{ code : "29024", intitule : "CARHAIX PLOUGUER", cp : "29270" },
{ code : "29025", intitule : "CAST", cp : "29150" },
{ code : "29026", intitule : "CHATEAULIN", cp : "29150" },
{ code : "29027", intitule : "CHATEAUNEUF DU FAOU", cp : "29520" },
{ code : "29028", intitule : "CLEDEN CAP SIZUN", cp : "29770" },
{ code : "29029", intitule : "CLEDEN POHER", cp : "29270" },
{ code : "29030", intitule : "CLEDER", cp : "29233" },
{ code : "29031", intitule : "CLOHARS CARNOET", cp : "29360" },
{ code : "29031", intitule : "CLOHARS CARNOET", cp : "29360" },
{ code : "29032", intitule : "CLOHARS FOUESNANT", cp : "29950" },
{ code : "29033", intitule : "LE CLOITRE PLEYBEN", cp : "29190" },
{ code : "29034", intitule : "LE CLOITRE ST THEGONNEC", cp : "29410" },
{ code : "29035", intitule : "COAT MEAL", cp : "29870" },
{ code : "29036", intitule : "COLLOREC", cp : "29530" },
{ code : "29037", intitule : "COMBRIT", cp : "29120" },
{ code : "29038", intitule : "COMMANA", cp : "29450" },
{ code : "29039", intitule : "CONCARNEAU", cp : "29900" },
{ code : "29040", intitule : "LE CONQUET", cp : "29217" },
{ code : "29041", intitule : "CORAY", cp : "29370" },
{ code : "29042", intitule : "CROZON", cp : "29160" },
{ code : "29042", intitule : "CROZON", cp : "29160" },
{ code : "29042", intitule : "CROZON", cp : "29160" },
{ code : "29043", intitule : "DAOULAS", cp : "29460" },
{ code : "29044", intitule : "DINEAULT", cp : "29150" },
{ code : "29045", intitule : "DIRINON", cp : "29460" },
{ code : "29046", intitule : "DOUARNENEZ", cp : "29100" },
{ code : "29046", intitule : "DOUARNENEZ", cp : "29100" },
{ code : "29047", intitule : "LE DRENNEC", cp : "29860" },
{ code : "29048", intitule : "EDERN", cp : "29510" },
{ code : "29049", intitule : "ELLIANT", cp : "29370" },
{ code : "29051", intitule : "ERGUE GABERIC", cp : "29500" },
{ code : "29053", intitule : "LE FAOU", cp : "29590" },
{ code : "29054", intitule : "LA FEUILLEE", cp : "29690" },
{ code : "29055", intitule : "LE FOLGOET", cp : "29260" },
{ code : "29056", intitule : "LA FOREST LANDERNEAU", cp : "29800" },
{ code : "29057", intitule : "LA FORET FOUESNANT", cp : "29940" },
{ code : "29058", intitule : "FOUESNANT", cp : "29170" },
{ code : "29058", intitule : "FOUESNANT", cp : "29170" },
{ code : "29059", intitule : "GARLAN", cp : "29610" },
{ code : "29060", intitule : "GOUESNACH", cp : "29950" },
{ code : "29061", intitule : "GOUESNOU", cp : "29850" },
{ code : "29062", intitule : "GOUEZEC", cp : "29190" },
{ code : "29063", intitule : "GOULIEN", cp : "29770" },
{ code : "29064", intitule : "GOULVEN", cp : "29890" },
{ code : "29065", intitule : "GOURLIZON", cp : "29710" },
{ code : "29066", intitule : "GUENGAT", cp : "29180" },
{ code : "29067", intitule : "GUERLESQUIN", cp : "29650" },
{ code : "29068", intitule : "GUICLAN", cp : "29410" },
{ code : "29069", intitule : "GUILERS", cp : "29820" },
{ code : "29070", intitule : "GUILER SUR GOYEN", cp : "29710" },
{ code : "29071", intitule : "GUILLIGOMARC H", cp : "29300" },
{ code : "29072", intitule : "GUILVINEC", cp : "29730" },
{ code : "29073", intitule : "GUIMAEC", cp : "29620" },
{ code : "29074", intitule : "GUIMILIAU", cp : "29400" },
{ code : "29075", intitule : "GUIPAVAS", cp : "29490" },
{ code : "29076", intitule : "GUIPRONVEL", cp : "29290" },
{ code : "29077", intitule : "GUISSENY", cp : "29880" },
{ code : "29078", intitule : "HANVEC", cp : "29460" },
{ code : "29079", intitule : "HENVIC", cp : "29670" },
{ code : "29080", intitule : "HOPITAL CAMFROUT", cp : "29460" },
{ code : "29081", intitule : "HUELGOAT", cp : "29690" },
{ code : "29082", intitule : "ILE DE BATZ", cp : "29253" },
{ code : "29083", intitule : "ILE DE SEIN", cp : "29990" },
{ code : "29084", intitule : "ILE MOLENE", cp : "29259" },
{ code : "29085", intitule : "ILE TUDY", cp : "29980" },
{ code : "29086", intitule : "IRVILLAC", cp : "29460" },
{ code : "29087", intitule : "LE JUCH", cp : "29100" },
{ code : "29089", intitule : "KERGLOFF", cp : "29270" },
{ code : "29090", intitule : "KERLAZ", cp : "29100" },
{ code : "29091", intitule : "KERLOUAN", cp : "29890" },
{ code : "29093", intitule : "KERNILIS", cp : "29260" },
{ code : "29094", intitule : "KERNOUES", cp : "29260" },
{ code : "29095", intitule : "KERSAINT PLABENNEC", cp : "29860" },
{ code : "29097", intitule : "LAMPAUL GUIMILIAU", cp : "29400" },
{ code : "29098", intitule : "LAMPAUL PLOUARZEL", cp : "29810" },
{ code : "29099", intitule : "LAMPAUL PLOUDALMEZEAU", cp : "29830" },
{ code : "29100", intitule : "LANARVILY", cp : "29260" },
{ code : "29101", intitule : "LANDEDA", cp : "29870" },
{ code : "29101", intitule : "LANDEDA", cp : "29870" },
{ code : "29102", intitule : "LANDELEAU", cp : "29530" },
{ code : "29103", intitule : "LANDERNEAU", cp : "29800" },
{ code : "29104", intitule : "LANDEVENNEC", cp : "29560" },
{ code : "29105", intitule : "LANDIVISIAU", cp : "29400" },
{ code : "29106", intitule : "LANDREVARZEC", cp : "29510" },
{ code : "29107", intitule : "LANDUDAL", cp : "29510" },
{ code : "29108", intitule : "LANDUDEC", cp : "29710" },
{ code : "29109", intitule : "LANDUNVEZ", cp : "29840" },
{ code : "29110", intitule : "LANGOLEN", cp : "29510" },
{ code : "29111", intitule : "LANHOUARNEAU", cp : "29430" },
{ code : "29112", intitule : "LANILDUT", cp : "29840" },
{ code : "29113", intitule : "LANMEUR", cp : "29620" },
{ code : "29114", intitule : "LANNEANOU", cp : "29640" },
{ code : "29115", intitule : "LANNEDERN", cp : "29190" },
{ code : "29116", intitule : "LANNEUFFRET", cp : "29400" },
{ code : "29117", intitule : "LANNILIS", cp : "29870" },
{ code : "29119", intitule : "LANRIVOARE", cp : "29290" },
{ code : "29120", intitule : "LANVEOC", cp : "29160" },
{ code : "29122", intitule : "LAZ", cp : "29520" },
{ code : "29123", intitule : "LENNON", cp : "29190" },
{ code : "29124", intitule : "LESNEVEN", cp : "29260" },
{ code : "29125", intitule : "LEUHAN", cp : "29390" },
{ code : "29126", intitule : "LOC BREVALAIRE", cp : "29260" },
{ code : "29128", intitule : "LOC EGUINER", cp : "29400" },
{ code : "29129", intitule : "LOCMARIA BERRIEN", cp : "29690" },
{ code : "29130", intitule : "LOCMARIA PLOUZANE", cp : "29280" },
{ code : "29131", intitule : "LOCMELAR", cp : "29400" },
{ code : "29132", intitule : "LOCQUENOLE", cp : "29670" },
{ code : "29133", intitule : "LOCQUIREC", cp : "29241" },
{ code : "29134", intitule : "LOCRONAN", cp : "29180" },
{ code : "29135", intitule : "LOCTUDY", cp : "29750" },
{ code : "29136", intitule : "LOCUNOLE", cp : "29310" },
{ code : "29137", intitule : "LOGONNA DAOULAS", cp : "29460" },
{ code : "29139", intitule : "LOPEREC", cp : "29590" },
{ code : "29140", intitule : "LOPERHET", cp : "29470" },
{ code : "29141", intitule : "LOQUEFFRET", cp : "29530" },
{ code : "29142", intitule : "LOTHEY", cp : "29190" },
{ code : "29143", intitule : "MAHALON", cp : "29790" },
{ code : "29144", intitule : "LA MARTYRE", cp : "29800" },
{ code : "29145", intitule : "CONFORT MEILARS", cp : "29790" },
{ code : "29146", intitule : "MELGVEN", cp : "29140" },
{ code : "29147", intitule : "MELLAC", cp : "29300" },
{ code : "29148", intitule : "MESPAUL", cp : "29420" },
{ code : "29149", intitule : "MILIZAC", cp : "29290" },
{ code : "29150", intitule : "MOELAN SUR MER", cp : "29350" },
{ code : "29150", intitule : "MOELAN SUR MER", cp : "29350" },
{ code : "29151", intitule : "MORLAIX", cp : "29600" },
{ code : "29152", intitule : "MOTREFF", cp : "29270" },
{ code : "29153", intitule : "NEVEZ", cp : "29920" },
{ code : "29153", intitule : "NEVEZ", cp : "29920" },
{ code : "29155", intitule : "OUESSANT", cp : "29242" },
{ code : "29156", intitule : "PENCRAN", cp : "29800" },
{ code : "29158", intitule : "PENMARCH", cp : "29760" },
{ code : "29158", intitule : "PENMARCH", cp : "29760" },
{ code : "29159", intitule : "PEUMERIT", cp : "29710" },
{ code : "29160", intitule : "PLABENNEC", cp : "29860" },
{ code : "29161", intitule : "PLEUVEN", cp : "29170" },
{ code : "29162", intitule : "PLEYBEN", cp : "29190" },
{ code : "29163", intitule : "PLEYBER CHRIST", cp : "29410" },
{ code : "29165", intitule : "PLOBANNALEC LESCONIL", cp : "29740" },
{ code : "29165", intitule : "PLOBANNALEC LESCONIL", cp : "29740" },
{ code : "29166", intitule : "PLOEVEN", cp : "29550" },
{ code : "29167", intitule : "PLOGASTEL ST GERMAIN", cp : "29710" },
{ code : "29168", intitule : "PLOGOFF", cp : "29770" },
{ code : "29169", intitule : "PLOGONNEC", cp : "29180" },
{ code : "29170", intitule : "PLOMELIN", cp : "29700" },
{ code : "29171", intitule : "PLOMEUR", cp : "29120" },
{ code : "29172", intitule : "PLOMODIERN", cp : "29550" },
{ code : "29173", intitule : "PLONEIS", cp : "29710" },
{ code : "29174", intitule : "PLONEOUR LANVERN", cp : "29720" },
{ code : "29175", intitule : "PLONEVEZ DU FAOU", cp : "29530" },
{ code : "29176", intitule : "PLONEVEZ PORZAY", cp : "29550" },
{ code : "29177", intitule : "PLOUARZEL", cp : "29810" },
{ code : "29178", intitule : "PLOUDALMEZEAU", cp : "29830" },
{ code : "29178", intitule : "PLOUDALMEZEAU", cp : "29830" },
{ code : "29179", intitule : "PLOUDANIEL", cp : "29260" },
{ code : "29180", intitule : "PLOUDIRY", cp : "29800" },
{ code : "29181", intitule : "PLOUEDERN", cp : "29800" },
{ code : "29182", intitule : "PLOUEGAT GUERAND", cp : "29620" },
{ code : "29183", intitule : "PLOUEGAT MOYSAN", cp : "29650" },
{ code : "29184", intitule : "PLOUENAN", cp : "29420" },
{ code : "29185", intitule : "PLOUESCAT", cp : "29430" },
{ code : "29186", intitule : "PLOUEZOC H", cp : "29252" },
{ code : "29187", intitule : "PLOUGAR", cp : "29440" },
{ code : "29188", intitule : "PLOUGASNOU", cp : "29630" },
{ code : "29188", intitule : "PLOUGASNOU", cp : "29630" },
{ code : "29189", intitule : "PLOUGASTEL DAOULAS", cp : "29470" },
{ code : "29190", intitule : "PLOUGONVELIN", cp : "29217" },
{ code : "29190", intitule : "PLOUGONVELIN", cp : "29217" },
{ code : "29191", intitule : "PLOUGONVEN", cp : "29640" },
{ code : "29192", intitule : "PLOUGOULM", cp : "29250" },
{ code : "29193", intitule : "PLOUGOURVEST", cp : "29400" },
{ code : "29195", intitule : "PLOUGUERNEAU", cp : "29880" },
{ code : "29196", intitule : "PLOUGUIN", cp : "29830" },
{ code : "29197", intitule : "PLOUHINEC", cp : "29780" },
{ code : "29198", intitule : "PLOUIDER", cp : "29260" },
{ code : "29199", intitule : "PLOUIGNEAU", cp : "29610" },
{ code : "29201", intitule : "PLOUMOGUER", cp : "29810" },
{ code : "29202", intitule : "PLOUNEOUR MENEZ", cp : "29410" },
{ code : "29203", intitule : "PLOUNEOUR TREZ", cp : "29890" },
{ code : "29204", intitule : "PLOUNEVENTER", cp : "29400" },
{ code : "29205", intitule : "PLOUNEVEZEL", cp : "29270" },
{ code : "29206", intitule : "PLOUNEVEZ LOCHRIST", cp : "29430" },
{ code : "29207", intitule : "PLOURIN LES MORLAIX", cp : "29600" },
{ code : "29208", intitule : "PLOURIN", cp : "29830" },
{ code : "29209", intitule : "PLOUVIEN", cp : "29860" },
{ code : "29210", intitule : "PLOUVORN", cp : "29420" },
{ code : "29211", intitule : "PLOUYE", cp : "29690" },
{ code : "29212", intitule : "PLOUZANE", cp : "29280" },
{ code : "29213", intitule : "PLOUZEVEDE", cp : "29440" },
{ code : "29214", intitule : "PLOVAN", cp : "29720" },
{ code : "29215", intitule : "PLOZEVET", cp : "29710" },
{ code : "29216", intitule : "PLUGUFFAN", cp : "29700" },
{ code : "29217", intitule : "PONT AVEN", cp : "29930" },
{ code : "29218", intitule : "PONT CROIX", cp : "29790" },
{ code : "29219", intitule : "LE PONTHOU", cp : "29650" },
{ code : "29220", intitule : "PONT L ABBE", cp : "29120" },
{ code : "29221", intitule : "PORSPODER", cp : "29840" },
{ code : "29221", intitule : "PORSPODER", cp : "29840" },
{ code : "29222", intitule : "PORT LAUNAY", cp : "29150" },
{ code : "29224", intitule : "POULDERGAT", cp : "29100" },
{ code : "29225", intitule : "POULDREUZIC", cp : "29710" },
{ code : "29226", intitule : "POULLAN SUR MER", cp : "29100" },
{ code : "29227", intitule : "POULLAOUEN", cp : "29246" },
{ code : "29228", intitule : "PRIMELIN", cp : "29770" },
{ code : "29229", intitule : "QUEMENEVEN", cp : "29180" },
{ code : "29230", intitule : "QUERRIEN", cp : "29310" },
{ code : "29232", intitule : "QUIMPER", cp : "29000" },
{ code : "29233", intitule : "QUIMPERLE", cp : "29300" },
{ code : "29234", intitule : "REDENE", cp : "29300" },
{ code : "29235", intitule : "LE RELECQ KERHUON", cp : "29480" },
{ code : "29236", intitule : "RIEC SUR BELON", cp : "29340" },
{ code : "29237", intitule : "LA ROCHE MAURICE", cp : "29800" },
{ code : "29238", intitule : "ROSCANVEL", cp : "29570" },
{ code : "29239", intitule : "ROSCOFF", cp : "29680" },
{ code : "29240", intitule : "ROSNOEN", cp : "29590" },
{ code : "29241", intitule : "ROSPORDEN", cp : "29140" },
{ code : "29241", intitule : "ROSPORDEN", cp : "29140" },
{ code : "29243", intitule : "ST COULITZ", cp : "29150" },
{ code : "29244", intitule : "ST DERRIEN", cp : "29440" },
{ code : "29245", intitule : "ST DIVY", cp : "29800" },
{ code : "29246", intitule : "ST ELOY", cp : "29460" },
{ code : "29247", intitule : "ST EVARZEC", cp : "29170" },
{ code : "29248", intitule : "ST FREGANT", cp : "29260" },
{ code : "29249", intitule : "ST GOAZEC", cp : "29520" },
{ code : "29250", intitule : "ST HERNIN", cp : "29270" },
{ code : "29251", intitule : "ST JEAN DU DOIGT", cp : "29630" },
{ code : "29252", intitule : "ST JEAN TROLIMON", cp : "29120" },
{ code : "29254", intitule : "ST MARTIN DES CHAMPS", cp : "29600" },
{ code : "29255", intitule : "ST MEEN", cp : "29260" },
{ code : "29256", intitule : "ST NIC", cp : "29550" },
{ code : "29257", intitule : "ST PABU", cp : "29830" },
{ code : "29259", intitule : "ST POL DE LEON", cp : "29250" },
{ code : "29260", intitule : "ST RENAN", cp : "29290" },
{ code : "29261", intitule : "ST RIVOAL", cp : "29190" },
{ code : "29262", intitule : "ST SAUVEUR", cp : "29400" },
{ code : "29263", intitule : "ST SEGAL", cp : "29590" },
{ code : "29264", intitule : "ST SERVAIS", cp : "29400" },
{ code : "29265", intitule : "STE SEVE", cp : "29600" },
{ code : "29266", intitule : "ST THEGONNEC LOC EGUINER", cp : "29410" },
{ code : "29266", intitule : "ST THEGONNEC LOC EGUINER", cp : "29410" },
{ code : "29267", intitule : "ST THOIS", cp : "29520" },
{ code : "29268", intitule : "ST THONAN", cp : "29800" },
{ code : "29269", intitule : "ST THURIEN", cp : "29380" },
{ code : "29270", intitule : "ST URBAIN", cp : "29800" },
{ code : "29271", intitule : "ST VOUGAY", cp : "29440" },
{ code : "29272", intitule : "ST YVI", cp : "29140" },
{ code : "29273", intitule : "SANTEC", cp : "29250" },
{ code : "29274", intitule : "SCAER", cp : "29390" },
{ code : "29275", intitule : "SCRIGNAC", cp : "29640" },
{ code : "29276", intitule : "SIBIRIL", cp : "29250" },
{ code : "29277", intitule : "SIZUN", cp : "29450" },
{ code : "29278", intitule : "SPEZET", cp : "29540" },
{ code : "29279", intitule : "TAULE", cp : "29670" },
{ code : "29280", intitule : "TELGRUC SUR MER", cp : "29560" },
{ code : "29281", intitule : "TOURCH", cp : "29140" },
{ code : "29282", intitule : "TREBABU", cp : "29217" },
{ code : "29284", intitule : "TREFFIAGAT", cp : "29730" },
{ code : "29285", intitule : "TREFLAOUENAN", cp : "29440" },
{ code : "29286", intitule : "TREFLEVENEZ", cp : "29800" },
{ code : "29287", intitule : "TREFLEZ", cp : "29430" },
{ code : "29288", intitule : "TREGARANTEC", cp : "29260" },
{ code : "29289", intitule : "TREGARVAN", cp : "29560" },
{ code : "29290", intitule : "TREGLONOU", cp : "29870" },
{ code : "29291", intitule : "TREGOUREZ", cp : "29970" },
{ code : "29292", intitule : "TREGUENNEC", cp : "29720" },
{ code : "29293", intitule : "TREGUNC", cp : "29910" },
{ code : "29294", intitule : "LE TREHOU", cp : "29450" },
{ code : "29295", intitule : "TREMAOUEZAN", cp : "29800" },
{ code : "29296", intitule : "TREMEOC", cp : "29120" },
{ code : "29297", intitule : "TREMEVEN", cp : "29300" },
{ code : "29298", intitule : "TREOGAT", cp : "29720" },
{ code : "29299", intitule : "TREOUERGAT", cp : "29290" },
{ code : "29300", intitule : "LE TREVOUX", cp : "29380" },
{ code : "29301", intitule : "TREZILIDE", cp : "29440" },
{ code : "29302", intitule : "PONT DE BUIS LES QUIMERCH", cp : "29590" },
{ code : "29302", intitule : "PONT DE BUIS LES QUIMERCH", cp : "29590" },
{ code : "30001", intitule : "AIGALIERS", cp : "30700" },
{ code : "30002", intitule : "AIGREMONT", cp : "30350" },
{ code : "30003", intitule : "AIGUES MORTES", cp : "30220" },
{ code : "30004", intitule : "AIGUES VIVES", cp : "30670" },
{ code : "30005", intitule : "AIGUEZE", cp : "30760" },
{ code : "30006", intitule : "AIMARGUES", cp : "30470" },
{ code : "30007", intitule : "ALES", cp : "30100" },
{ code : "30008", intitule : "ALLEGRE LES FUMADES", cp : "30500" },
{ code : "30009", intitule : "ALZON", cp : "30770" },
{ code : "30010", intitule : "ANDUZE", cp : "30140" },
{ code : "30011", intitule : "LES ANGLES", cp : "30133" },
{ code : "30012", intitule : "ARAMON", cp : "30390" },
{ code : "30013", intitule : "ARGILLIERS", cp : "30210" },
{ code : "30014", intitule : "ARPAILLARGUES ET AUREILLAC", cp : "30700" },
{ code : "30015", intitule : "ARPHY", cp : "30120" },
{ code : "30016", intitule : "ARRE", cp : "30120" },
{ code : "30017", intitule : "ARRIGAS", cp : "30770" },
{ code : "30018", intitule : "ASPERES", cp : "30250" },
{ code : "30019", intitule : "AUBAIS", cp : "30250" },
{ code : "30020", intitule : "AUBORD", cp : "30620" },
{ code : "30021", intitule : "AUBUSSARGUES", cp : "30190" },
{ code : "30022", intitule : "AUJAC", cp : "30450" },
{ code : "30023", intitule : "AUJARGUES", cp : "30250" },
{ code : "30024", intitule : "AULAS", cp : "30120" },
{ code : "30025", intitule : "AUMESSAS", cp : "30770" },
{ code : "30026", intitule : "AVEZE", cp : "30120" },
{ code : "30027", intitule : "BAGARD", cp : "30140" },
{ code : "30028", intitule : "BAGNOLS SUR CEZE", cp : "30200" },
{ code : "30029", intitule : "BARJAC", cp : "30430" },
{ code : "30030", intitule : "BARON", cp : "30700" },
{ code : "30031", intitule : "LA BASTIDE D ENGRAS", cp : "30330" },
{ code : "30032", intitule : "BEAUCAIRE", cp : "30300" },
{ code : "30033", intitule : "BEAUVOISIN", cp : "30640" },
{ code : "30033", intitule : "BEAUVOISIN", cp : "30640" },
{ code : "30034", intitule : "BELLEGARDE", cp : "30127" },
{ code : "30035", intitule : "BELVEZET", cp : "30580" },
{ code : "30036", intitule : "BERNIS", cp : "30620" },
{ code : "30037", intitule : "BESSEGES", cp : "30160" },
{ code : "30037", intitule : "BESSEGES", cp : "30160" },
{ code : "30038", intitule : "BEZ ET ESPARON", cp : "30120" },
{ code : "30039", intitule : "BEZOUCE", cp : "30320" },
{ code : "30040", intitule : "BLANDAS", cp : "30770" },
{ code : "30041", intitule : "BLAUZAC", cp : "30700" },
{ code : "30042", intitule : "BOISSET ET GAUJAC", cp : "30140" },
{ code : "30043", intitule : "BOISSIERES", cp : "30114" },
{ code : "30044", intitule : "BONNEVAUX", cp : "30450" },
{ code : "30045", intitule : "BORDEZAC", cp : "30160" },
{ code : "30046", intitule : "BOUCOIRAN ET NOZIERES", cp : "30190" },
{ code : "30047", intitule : "BOUILLARGUES", cp : "30230" },
{ code : "30048", intitule : "BOUQUET", cp : "30580" },
{ code : "30049", intitule : "BOURDIC", cp : "30190" },
{ code : "30050", intitule : "BRAGASSARGUES", cp : "30260" },
{ code : "30051", intitule : "BRANOUX LES TAILLADES", cp : "30110" },
{ code : "30052", intitule : "BREAU ET SALAGOSSE", cp : "30120" },
{ code : "30053", intitule : "BRIGNON", cp : "30190" },
{ code : "30054", intitule : "BROUZET LES QUISSAC", cp : "30260" },
{ code : "30055", intitule : "BROUZET LES ALES", cp : "30580" },
{ code : "30056", intitule : "LA BRUGUIERE", cp : "30580" },
{ code : "30057", intitule : "CABRIERES", cp : "30210" },
{ code : "30058", intitule : "LA CADIERE ET CAMBO", cp : "30170" },
{ code : "30059", intitule : "LE CAILAR", cp : "30740" },
{ code : "30060", intitule : "CAISSARGUES", cp : "30132" },
{ code : "30061", intitule : "LA CALMETTE", cp : "30190" },
{ code : "30062", intitule : "CALVISSON", cp : "30420" },
{ code : "30064", intitule : "CAMPESTRE ET LUC", cp : "30770" },
{ code : "30065", intitule : "CANAULES ET ARGENTIERES", cp : "30350" },
{ code : "30066", intitule : "CANNES ET CLAIRAN", cp : "30260" },
{ code : "30067", intitule : "LA CAPELLE ET MASMOLENE", cp : "30700" },
{ code : "30068", intitule : "CARDET", cp : "30350" },
{ code : "30069", intitule : "CARNAS", cp : "30260" },
{ code : "30070", intitule : "CARSAN", cp : "30130" },
{ code : "30071", intitule : "CASSAGNOLES", cp : "30350" },
{ code : "30072", intitule : "CASTELNAU VALENCE", cp : "30190" },
{ code : "30073", intitule : "CASTILLON DU GARD", cp : "30210" },
{ code : "30074", intitule : "CAUSSE BEGON", cp : "30750" },
{ code : "30075", intitule : "CAVEIRAC", cp : "30820" },
{ code : "30076", intitule : "CAVILLARGUES", cp : "30330" },
{ code : "30077", intitule : "CENDRAS", cp : "30480" },
{ code : "30079", intitule : "CHAMBON", cp : "30450" },
{ code : "30080", intitule : "CHAMBORIGAUD", cp : "30530" },
{ code : "30081", intitule : "CHUSCLAN", cp : "30200" },
{ code : "30081", intitule : "CHUSCLAN", cp : "30200" },
{ code : "30082", intitule : "CLARENSAC", cp : "30870" },
{ code : "30083", intitule : "CODOGNAN", cp : "30920" },
{ code : "30084", intitule : "CODOLET", cp : "30200" },
{ code : "30085", intitule : "COLLIAS", cp : "30210" },
{ code : "30086", intitule : "COLLORGUES", cp : "30190" },
{ code : "30087", intitule : "COLOGNAC", cp : "30460" },
{ code : "30088", intitule : "COMBAS", cp : "30250" },
{ code : "30089", intitule : "COMPS", cp : "30300" },
{ code : "30090", intitule : "CONCOULES", cp : "30450" },
{ code : "30091", intitule : "CONGENIES", cp : "30111" },
{ code : "30092", intitule : "CONNAUX", cp : "30330" },
{ code : "30093", intitule : "CONQUEYRAC", cp : "30170" },
{ code : "30094", intitule : "CORBES", cp : "30140" },
{ code : "30095", intitule : "CORCONNE", cp : "30260" },
{ code : "30096", intitule : "CORNILLON", cp : "30630" },
{ code : "30097", intitule : "COURRY", cp : "30500" },
{ code : "30098", intitule : "CRESPIAN", cp : "30260" },
{ code : "30099", intitule : "CROS", cp : "30170" },
{ code : "30100", intitule : "CRUVIERS LASCOURS", cp : "30360" },
{ code : "30101", intitule : "DEAUX", cp : "30360" },
{ code : "30102", intitule : "DIONS", cp : "30190" },
{ code : "30103", intitule : "DOMAZAN", cp : "30390" },
{ code : "30104", intitule : "DOMESSARGUES", cp : "30350" },
{ code : "30105", intitule : "DOURBIES", cp : "30750" },
{ code : "30106", intitule : "DURFORT ET ST MARTIN DE SOSSENAC", cp : "30170" },
{ code : "30107", intitule : "ESTEZARGUES", cp : "30390" },
{ code : "30108", intitule : "L ESTRECHURE", cp : "30124" },
{ code : "30109", intitule : "EUZET", cp : "30360" },
{ code : "30110", intitule : "FLAUX", cp : "30700" },
{ code : "30111", intitule : "FOISSAC", cp : "30700" },
{ code : "30112", intitule : "FONS", cp : "30730" },
{ code : "30113", intitule : "FONS SUR LUSSAN", cp : "30580" },
{ code : "30114", intitule : "FONTANES", cp : "30250" },
{ code : "30115", intitule : "FONTARECHES", cp : "30580" },
{ code : "30116", intitule : "FOURNES", cp : "30210" },
{ code : "30117", intitule : "FOURQUES", cp : "30300" },
{ code : "30119", intitule : "FRESSAC", cp : "30170" },
{ code : "30120", intitule : "GAGNIERES", cp : "30160" },
{ code : "30121", intitule : "GAILHAN", cp : "30260" },
{ code : "30122", intitule : "GAJAN", cp : "30730" },
{ code : "30123", intitule : "GALLARGUES LE MONTUEUX", cp : "30660" },
{ code : "30124", intitule : "LE GARN", cp : "30760" },
{ code : "30125", intitule : "GARONS", cp : "30128" },
{ code : "30126", intitule : "GARRIGUES STE EULALIE", cp : "30190" },
{ code : "30127", intitule : "GAUJAC", cp : "30330" },
{ code : "30128", intitule : "GENERAC", cp : "30510" },
{ code : "30129", intitule : "GENERARGUES", cp : "30140" },
{ code : "30130", intitule : "GENOLHAC", cp : "30450" },
{ code : "30130", intitule : "GENOLHAC", cp : "30450" },
{ code : "30131", intitule : "GOUDARGUES", cp : "30630" },
{ code : "30132", intitule : "LA GRAND COMBE", cp : "30110" },
{ code : "30132", intitule : "LA GRAND COMBE", cp : "30110" },
{ code : "30133", intitule : "LE GRAU DU ROI", cp : "30240" },
{ code : "30133", intitule : "LE GRAU DU ROI", cp : "30240" },
{ code : "30134", intitule : "ISSIRAC", cp : "30760" },
{ code : "30135", intitule : "JONQUIERES ST VINCENT", cp : "30300" },
{ code : "30136", intitule : "JUNAS", cp : "30250" },
{ code : "30137", intitule : "LAMELOUZE", cp : "30110" },
{ code : "30138", intitule : "LANGLADE", cp : "30980" },
{ code : "30139", intitule : "LANUEJOLS", cp : "30750" },
{ code : "30140", intitule : "LASALLE", cp : "30460" },
{ code : "30141", intitule : "LAUDUN L ARDOISE", cp : "30290" },
{ code : "30141", intitule : "LAUDUN L ARDOISE", cp : "30290" },
{ code : "30142", intitule : "LAVAL PRADEL", cp : "30110" },
{ code : "30143", intitule : "LAVAL ST ROMAN", cp : "30760" },
{ code : "30144", intitule : "LECQUES", cp : "30250" },
{ code : "30145", intitule : "LEDENON", cp : "30210" },
{ code : "30146", intitule : "LEDIGNAN", cp : "30350" },
{ code : "30147", intitule : "LEZAN", cp : "30350" },
{ code : "30148", intitule : "LIOUC", cp : "30260" },
{ code : "30149", intitule : "LIRAC", cp : "30126" },
{ code : "30150", intitule : "LOGRIAN FLORIAN", cp : "30610" },
{ code : "30151", intitule : "LUSSAN", cp : "30580" },
{ code : "30152", intitule : "LES MAGES", cp : "30960" },
{ code : "30153", intitule : "MALONS ET ELZE", cp : "30450" },
{ code : "30154", intitule : "MANDAGOUT", cp : "30120" },
{ code : "30155", intitule : "MANDUEL", cp : "30129" },
{ code : "30156", intitule : "MARGUERITTES", cp : "30320" },
{ code : "30157", intitule : "MARS", cp : "30120" },
{ code : "30158", intitule : "MARTIGNARGUES", cp : "30360" },
{ code : "30159", intitule : "LE MARTINET", cp : "30960" },
{ code : "30160", intitule : "MARUEJOLS LES GARDON", cp : "30350" },
{ code : "30161", intitule : "MASSANES", cp : "30350" },
{ code : "30162", intitule : "MASSILLARGUES ATTUECH", cp : "30140" },
{ code : "30163", intitule : "MAURESSARGUES", cp : "30350" },
{ code : "30164", intitule : "MEJANNES LE CLAP", cp : "30430" },
{ code : "30165", intitule : "MEJANNES LES ALES", cp : "30340" },
{ code : "30166", intitule : "MEYNES", cp : "30840" },
{ code : "30167", intitule : "MEYRANNES", cp : "30410" },
{ code : "30168", intitule : "MIALET", cp : "30140" },
{ code : "30169", intitule : "MILHAUD", cp : "30540" },
{ code : "30170", intitule : "MOLIERES CAVAILLAC", cp : "30120" },
{ code : "30171", intitule : "MOLIERES SUR CEZE", cp : "30410" },
{ code : "30172", intitule : "MONOBLET", cp : "30170" },
{ code : "30173", intitule : "MONS", cp : "30340" },
{ code : "30174", intitule : "MONTAREN ET ST MEDIERS", cp : "30700" },
{ code : "30175", intitule : "MONTCLUS", cp : "30630" },
{ code : "30176", intitule : "MONTDARDIER", cp : "30120" },
{ code : "30177", intitule : "MONTEILS", cp : "30360" },
{ code : "30178", intitule : "MONTFAUCON", cp : "30150" },
{ code : "30179", intitule : "MONTFRIN", cp : "30490" },
{ code : "30180", intitule : "MONTIGNARGUES", cp : "30190" },
{ code : "30181", intitule : "MONTMIRAT", cp : "30260" },
{ code : "30182", intitule : "MONTPEZAT", cp : "30730" },
{ code : "30183", intitule : "MOULEZAN", cp : "30350" },
{ code : "30184", intitule : "MOUSSAC", cp : "30190" },
{ code : "30185", intitule : "MUS", cp : "30121" },
{ code : "30186", intitule : "NAGES ET SOLORGUES", cp : "30114" },
{ code : "30187", intitule : "NAVACELLES", cp : "30580" },
{ code : "30188", intitule : "NERS", cp : "30360" },
{ code : "30189", intitule : "NIMES", cp : "30000" },
{ code : "30189", intitule : "NIMES", cp : "30900" },
{ code : "30189", intitule : "NIMES", cp : "30900" },
{ code : "30190", intitule : "NOTRE DAME DE LA ROUVIERE", cp : "30570" },
{ code : "30191", intitule : "ORSAN", cp : "30200" },
{ code : "30192", intitule : "ORTHOUX SERIGNAC QUILHAN", cp : "30260" },
{ code : "30193", intitule : "PARIGNARGUES", cp : "30730" },
{ code : "30194", intitule : "PEYREMALE", cp : "30160" },
{ code : "30195", intitule : "PEYROLLES", cp : "30124" },
{ code : "30196", intitule : "LE PIN", cp : "30330" },
{ code : "30197", intitule : "LES PLANS", cp : "30340" },
{ code : "30198", intitule : "LES PLANTIERS", cp : "30122" },
{ code : "30199", intitule : "POMMIERS", cp : "30120" },
{ code : "30200", intitule : "POMPIGNAN", cp : "30170" },
{ code : "30201", intitule : "PONTEILS ET BRESIS", cp : "30450" },
{ code : "30202", intitule : "PONT ST ESPRIT", cp : "30130" },
{ code : "30203", intitule : "PORTES", cp : "30530" },
{ code : "30204", intitule : "POTELIERES", cp : "30500" },
{ code : "30205", intitule : "POUGNADORESSE", cp : "30330" },
{ code : "30206", intitule : "POULX", cp : "30320" },
{ code : "30207", intitule : "POUZILHAC", cp : "30210" },
{ code : "30208", intitule : "PUECHREDON", cp : "30610" },
{ code : "30209", intitule : "PUJAUT", cp : "30131" },
{ code : "30210", intitule : "QUISSAC", cp : "30260" },
{ code : "30211", intitule : "REDESSAN", cp : "30129" },
{ code : "30212", intitule : "REMOULINS", cp : "30210" },
{ code : "30213", intitule : "REVENS", cp : "30750" },
{ code : "30214", intitule : "RIBAUTE LES TAVERNES", cp : "30720" },
{ code : "30215", intitule : "RIVIERES", cp : "30430" },
{ code : "30216", intitule : "ROBIAC ROCHESSADOULE", cp : "30160" },
{ code : "30217", intitule : "ROCHEFORT DU GARD", cp : "30650" },
{ code : "30218", intitule : "ROCHEGUDE", cp : "30430" },
{ code : "30219", intitule : "ROGUES", cp : "30120" },
{ code : "30220", intitule : "ROQUEDUR", cp : "30440" },
{ code : "30221", intitule : "ROQUEMAURE", cp : "30150" },
{ code : "30222", intitule : "LA ROQUE SUR CEZE", cp : "30200" },
{ code : "30223", intitule : "ROUSSON", cp : "30340" },
{ code : "30224", intitule : "LA ROUVIERE", cp : "30190" },
{ code : "30225", intitule : "SABRAN", cp : "30200" },
{ code : "30226", intitule : "ST ALEXANDRE", cp : "30130" },
{ code : "30227", intitule : "ST AMBROIX", cp : "30500" },
{ code : "30228", intitule : "STE ANASTASIE", cp : "30190" },
{ code : "30229", intitule : "ST ANDRE DE MAJENCOULES", cp : "30570" },
{ code : "30230", intitule : "ST ANDRE DE ROQUEPERTUIS", cp : "30630" },
{ code : "30231", intitule : "ST ANDRE DE VALBORGNE", cp : "30940" },
{ code : "30232", intitule : "ST ANDRE D OLERARGUES", cp : "30330" },
{ code : "30233", intitule : "ST BAUZELY", cp : "30730" },
{ code : "30234", intitule : "ST BENEZET", cp : "30350" },
{ code : "30235", intitule : "ST BONNET DU GARD", cp : "30210" },
{ code : "30236", intitule : "ST BONNET DE SALENDRINQUE", cp : "30460" },
{ code : "30237", intitule : "ST BRES", cp : "30500" },
{ code : "30238", intitule : "ST BRESSON", cp : "30440" },
{ code : "30239", intitule : "STE CECILE D ANDORGE", cp : "30110" },
{ code : "30240", intitule : "ST CESAIRE DE GAUZIGNAN", cp : "30360" },
{ code : "30241", intitule : "ST CHAPTES", cp : "30190" },
{ code : "30242", intitule : "ST CHRISTOL DE RODIERES", cp : "30760" },
{ code : "30243", intitule : "ST CHRISTOL LES ALES", cp : "30380" },
{ code : "30244", intitule : "ST CLEMENT", cp : "30260" },
{ code : "30245", intitule : "ST COME ET MARUEJOLS", cp : "30870" },
{ code : "30246", intitule : "STE CROIX DE CADERLE", cp : "30460" },
{ code : "30247", intitule : "ST DENIS", cp : "30500" },
{ code : "30248", intitule : "ST DEZERY", cp : "30190" },
{ code : "30249", intitule : "ST DIONISY", cp : "30980" },
{ code : "30250", intitule : "ST ETIENNE DE L OLM", cp : "30360" },
{ code : "30251", intitule : "ST ETIENNE DES SORTS", cp : "30200" },
{ code : "30252", intitule : "ST FELIX DE PALLIERES", cp : "30140" },
{ code : "30253", intitule : "ST FLORENT SUR AUZONNET", cp : "30960" },
{ code : "30254", intitule : "ST GENIES DE COMOLAS", cp : "30150" },
{ code : "30255", intitule : "ST GENIES DE MALGOIRES", cp : "30190" },
{ code : "30256", intitule : "ST GERVAIS", cp : "30200" },
{ code : "30257", intitule : "ST GERVASY", cp : "30320" },
{ code : "30258", intitule : "ST GILLES", cp : "30800" },
{ code : "30259", intitule : "ST HILAIRE DE BRETHMAS", cp : "30560" },
{ code : "30260", intitule : "ST HILAIRE D OZILHAN", cp : "30210" },
{ code : "30261", intitule : "ST HIPPOLYTE DE CATON", cp : "30360" },
{ code : "30262", intitule : "ST HIPPOLYTE DE MONTAIGU", cp : "30700" },
{ code : "30263", intitule : "ST HIPPOLYTE DU FORT", cp : "30170" },
{ code : "30264", intitule : "ST JEAN DE CEYRARGUES", cp : "30360" },
{ code : "30265", intitule : "ST JEAN DE CRIEULON", cp : "30610" },
{ code : "30266", intitule : "ST JEAN DE MARUEJOLS ET AVEJAN", cp : "30430" },
{ code : "30267", intitule : "ST JEAN DE SERRES", cp : "30350" },
{ code : "30268", intitule : "ST JEAN DE VALERISCLE", cp : "30960" },
{ code : "30269", intitule : "ST JEAN DU GARD", cp : "30270" },
{ code : "30270", intitule : "ST JEAN DU PIN", cp : "30140" },
{ code : "30271", intitule : "ST JULIEN DE CASSAGNAS", cp : "30500" },
{ code : "30272", intitule : "ST JULIEN DE LA NEF", cp : "30440" },
{ code : "30273", intitule : "ST JULIEN DE PEYROLAS", cp : "30760" },
{ code : "30274", intitule : "ST JULIEN LES ROSIERS", cp : "30340" },
{ code : "30275", intitule : "ST JUST ET VACQUIERES", cp : "30580" },
{ code : "30276", intitule : "ST LAURENT D AIGOUZE", cp : "30220" },
{ code : "30277", intitule : "ST LAURENT DE CARNOLS", cp : "30200" },
{ code : "30278", intitule : "ST LAURENT DES ARBRES", cp : "30126" },
{ code : "30279", intitule : "ST LAURENT LA VERNEDE", cp : "30330" },
{ code : "30280", intitule : "ST LAURENT LE MINIER", cp : "30440" },
{ code : "30281", intitule : "ST MAMERT DU GARD", cp : "30730" },
{ code : "30282", intitule : "ST MARCEL DE CAREIRET", cp : "30330" },
{ code : "30283", intitule : "ST MARTIAL", cp : "30440" },
{ code : "30284", intitule : "ST MARTIN DE VALGALGUES", cp : "30520" },
{ code : "30285", intitule : "ST MAURICE DE CAZEVIEILLE", cp : "30360" },
{ code : "30286", intitule : "ST MAXIMIN", cp : "30700" },
{ code : "30287", intitule : "ST MICHEL D EUZET", cp : "30200" },
{ code : "30288", intitule : "ST NAZAIRE", cp : "30200" },
{ code : "30289", intitule : "ST NAZAIRE DES GARDIES", cp : "30610" },
{ code : "30290", intitule : "ST PAULET DE CAISSON", cp : "30130" },
{ code : "30291", intitule : "ST PAUL LA COSTE", cp : "30480" },
{ code : "30292", intitule : "ST PONS LA CALM", cp : "30330" },
{ code : "30293", intitule : "ST PRIVAT DE CHAMPCLOS", cp : "30430" },
{ code : "30294", intitule : "ST PRIVAT DES VIEUX", cp : "30340" },
{ code : "30295", intitule : "ST QUENTIN LA POTERIE", cp : "30700" },
{ code : "30296", intitule : "ST ROMAN DE CODIERES", cp : "30440" },
{ code : "30297", intitule : "ST SAUVEUR CAMPRIEU", cp : "30750" },
{ code : "30297", intitule : "ST SAUVEUR CAMPRIEU", cp : "30750" },
{ code : "30298", intitule : "ST SEBASTIEN D AIGREFEUILLE", cp : "30140" },
{ code : "30299", intitule : "ST SIFFRET", cp : "30700" },
{ code : "30300", intitule : "ST THEODORIT", cp : "30260" },
{ code : "30301", intitule : "ST VICTOR DES OULES", cp : "30700" },
{ code : "30302", intitule : "ST VICTOR LA COSTE", cp : "30290" },
{ code : "30303", intitule : "ST VICTOR DE MALCAP", cp : "30500" },
{ code : "30304", intitule : "SALAZAC", cp : "30760" },
{ code : "30305", intitule : "SALINDRES", cp : "30340" },
{ code : "30306", intitule : "SALINELLES", cp : "30250" },
{ code : "30307", intitule : "LES SALLES DU GARDON", cp : "30110" },
{ code : "30308", intitule : "SANILHAC SAGRIES", cp : "30700" },
{ code : "30309", intitule : "SARDAN", cp : "30260" },
{ code : "30310", intitule : "SAUMANE", cp : "30125" },
{ code : "30311", intitule : "SAUVE", cp : "30610" },
{ code : "30312", intitule : "SAUVETERRE", cp : "30150" },
{ code : "30313", intitule : "SAUZET", cp : "30190" },
{ code : "30314", intitule : "SAVIGNARGUES", cp : "30350" },
{ code : "30315", intitule : "SAZE", cp : "30650" },
{ code : "30316", intitule : "SENECHAS", cp : "30450" },
{ code : "30317", intitule : "SERNHAC", cp : "30210" },
{ code : "30318", intitule : "SERVAS", cp : "30340" },
{ code : "30319", intitule : "SERVIERS ET LABAUME", cp : "30700" },
{ code : "30320", intitule : "SEYNES", cp : "30580" },
{ code : "30321", intitule : "SOMMIERES", cp : "30250" },
{ code : "30322", intitule : "SOUDORGUES", cp : "30460" },
{ code : "30323", intitule : "SOUSTELLE", cp : "30110" },
{ code : "30324", intitule : "SOUVIGNARGUES", cp : "30250" },
{ code : "30325", intitule : "SUMENE", cp : "30440" },
{ code : "30325", intitule : "SUMENE", cp : "30440" },
{ code : "30326", intitule : "TAVEL", cp : "30126" },
{ code : "30327", intitule : "THARAUX", cp : "30430" },
{ code : "30328", intitule : "THEZIERS", cp : "30390" },
{ code : "30329", intitule : "THOIRAS", cp : "30140" },
{ code : "30330", intitule : "TORNAC", cp : "30140" },
{ code : "30331", intitule : "TRESQUES", cp : "30330" },
{ code : "30332", intitule : "TREVES", cp : "30750" },
{ code : "30333", intitule : "UCHAUD", cp : "30620" },
{ code : "30334", intitule : "UZES", cp : "30700" },
{ code : "30335", intitule : "VABRES", cp : "30460" },
{ code : "30336", intitule : "VALLABREGUES", cp : "30300" },
{ code : "30337", intitule : "VALLABRIX", cp : "30700" },
{ code : "30338", intitule : "VALLERARGUES", cp : "30580" },
{ code : "30339", intitule : "VALLERAUGUE", cp : "30570" },
{ code : "30339", intitule : "VALLERAUGUE", cp : "30570" },
{ code : "30339", intitule : "VALLERAUGUE", cp : "30570" },
{ code : "30340", intitule : "VALLIGUIERES", cp : "30210" },
{ code : "30341", intitule : "VAUVERT", cp : "30600" },
{ code : "30341", intitule : "VAUVERT", cp : "30600" },
{ code : "30341", intitule : "VAUVERT", cp : "30600" },
{ code : "30341", intitule : "VAUVERT", cp : "30600" },
{ code : "30342", intitule : "VENEJAN", cp : "30200" },
{ code : "30343", intitule : "VERFEUIL", cp : "30630" },
{ code : "30344", intitule : "VERGEZE", cp : "30310" },
{ code : "30345", intitule : "LA VERNAREDE", cp : "30530" },
{ code : "30346", intitule : "VERS PONT DU GARD", cp : "30210" },
{ code : "30347", intitule : "VESTRIC ET CANDIAC", cp : "30600" },
{ code : "30348", intitule : "VEZENOBRES", cp : "30360" },
{ code : "30349", intitule : "VIC LE FESQ", cp : "30260" },
{ code : "30350", intitule : "LE VIGAN", cp : "30120" },
{ code : "30351", intitule : "VILLENEUVE LES AVIGNON", cp : "30400" },
{ code : "30352", intitule : "VILLEVIEILLE", cp : "30250" },
{ code : "30353", intitule : "VISSEC", cp : "30770" },
{ code : "30354", intitule : "MONTAGNAC", cp : "30350" },
{ code : "30355", intitule : "ST PAUL LES FONTS", cp : "30330" },
{ code : "30356", intitule : "RODILHAN", cp : "30230" },
{ code : "31001", intitule : "AGASSAC", cp : "31230" },
{ code : "31002", intitule : "AIGNES", cp : "31550" },
{ code : "31003", intitule : "AIGREFEUILLE", cp : "31280" },
{ code : "31004", intitule : "AYGUESVIVES", cp : "31450" },
{ code : "31005", intitule : "ALAN", cp : "31420" },
{ code : "31006", intitule : "ALBIAC", cp : "31460" },
{ code : "31007", intitule : "AMBAX", cp : "31230" },
{ code : "31008", intitule : "ANAN", cp : "31230" },
{ code : "31009", intitule : "ANTICHAN DE FRONTIGNES", cp : "31510" },
{ code : "31010", intitule : "ANTIGNAC", cp : "31110" },
{ code : "31011", intitule : "ARBAS", cp : "31160" },
{ code : "31012", intitule : "ARBON", cp : "31160" },
{ code : "31013", intitule : "ARDIEGE", cp : "31210" },
{ code : "31014", intitule : "ARGUENOS", cp : "31160" },
{ code : "31015", intitule : "ARGUT DESSOUS", cp : "31440" },
{ code : "31017", intitule : "ARLOS", cp : "31440" },
{ code : "31018", intitule : "ARNAUD GUILHEM", cp : "31360" },
{ code : "31019", intitule : "ARTIGUE", cp : "31110" },
{ code : "31020", intitule : "ASPET", cp : "31160" },
{ code : "31021", intitule : "ASPRET SARRAT", cp : "31800" },
{ code : "31022", intitule : "AUCAMVILLE", cp : "31140" },
{ code : "31023", intitule : "AULON", cp : "31420" },
{ code : "31024", intitule : "AURAGNE", cp : "31190" },
{ code : "31025", intitule : "AUREVILLE", cp : "31320" },
{ code : "31026", intitule : "AURIAC SUR VENDINELLE", cp : "31460" },
{ code : "31027", intitule : "AURIBAIL", cp : "31190" },
{ code : "31028", intitule : "AURIGNAC", cp : "31420" },
{ code : "31029", intitule : "AURIN", cp : "31570" },
{ code : "31030", intitule : "AUSSEING", cp : "31260" },
{ code : "31031", intitule : "AUSSON", cp : "31210" },
{ code : "31032", intitule : "AUSSONNE", cp : "31840" },
{ code : "31033", intitule : "AUTERIVE", cp : "31190" },
{ code : "31034", intitule : "AUZAS", cp : "31360" },
{ code : "31035", intitule : "AUZEVILLE TOLOSANE", cp : "31320" },
{ code : "31036", intitule : "AUZIELLE", cp : "31650" },
{ code : "31037", intitule : "AVIGNONET LAURAGAIS", cp : "31290" },
{ code : "31038", intitule : "AZAS", cp : "31380" },
{ code : "31039", intitule : "BACHAS", cp : "31420" },
{ code : "31040", intitule : "BACHOS", cp : "31440" },
{ code : "31041", intitule : "BAGIRY", cp : "31510" },
{ code : "31042", intitule : "BAGNERES DE LUCHON", cp : "31110" },
{ code : "31042", intitule : "BAGNERES DE LUCHON", cp : "31110" },
{ code : "31043", intitule : "BALESTA", cp : "31580" },
{ code : "31044", intitule : "BALMA", cp : "31130" },
{ code : "31045", intitule : "BARBAZAN", cp : "31510" },
{ code : "31046", intitule : "BAREN", cp : "31440" },
{ code : "31047", intitule : "BAX", cp : "31310" },
{ code : "31048", intitule : "BAZIEGE", cp : "31450" },
{ code : "31049", intitule : "BAZUS", cp : "31380" },
{ code : "31050", intitule : "BEAUCHALOT", cp : "31360" },
{ code : "31051", intitule : "BEAUFORT", cp : "31370" },
{ code : "31052", intitule : "BEAUMONT SUR LEZE", cp : "31870" },
{ code : "31053", intitule : "BEAUPUY", cp : "31850" },
{ code : "31054", intitule : "BEAUTEVILLE", cp : "31290" },
{ code : "31055", intitule : "BEAUVILLE", cp : "31460" },
{ code : "31056", intitule : "BEAUZELLE", cp : "31700" },
{ code : "31057", intitule : "BELBERAUD", cp : "31450" },
{ code : "31058", intitule : "BELBEZE DE LAURAGAIS", cp : "31450" },
{ code : "31059", intitule : "BELBEZE EN COMMINGES", cp : "31260" },
{ code : "31060", intitule : "BELESTA EN LAURAGAIS", cp : "31540" },
{ code : "31061", intitule : "BELLEGARDE STE MARIE", cp : "31530" },
{ code : "31062", intitule : "BELLESSERRE", cp : "31480" },
{ code : "31063", intitule : "BENQUE", cp : "31420" },
{ code : "31064", intitule : "BENQUE DESSOUS ET DESSUS", cp : "31110" },
{ code : "31065", intitule : "BERAT", cp : "31370" },
{ code : "31066", intitule : "BESSIERES", cp : "31660" },
{ code : "31067", intitule : "BEZINS GARRAUX", cp : "31440" },
{ code : "31068", intitule : "BILLIERE", cp : "31110" },
{ code : "31069", intitule : "BLAGNAC", cp : "31700" },
{ code : "31070", intitule : "BLAJAN", cp : "31350" },
{ code : "31071", intitule : "BOIS DE LA PIERRE", cp : "31390" },
{ code : "31072", intitule : "BOISSEDE", cp : "31230" },
{ code : "31073", intitule : "BONDIGOUX", cp : "31340" },
{ code : "31074", intitule : "BONREPOS RIQUET", cp : "31590" },
{ code : "31075", intitule : "BONREPOS SUR AUSSONNELLE", cp : "31470" },
{ code : "31076", intitule : "BORDES DE RIVIERE", cp : "31210" },
{ code : "31077", intitule : "LE BORN", cp : "31340" },
{ code : "31078", intitule : "BOUDRAC", cp : "31580" },
{ code : "31079", intitule : "BOULOC", cp : "31620" },
{ code : "31080", intitule : "BOULOGNE SUR GESSE", cp : "31350" },
{ code : "31081", intitule : "BOURG D OUEIL", cp : "31110" },
{ code : "31082", intitule : "BOURG ST BERNARD", cp : "31570" },
{ code : "31083", intitule : "BOUSSAN", cp : "31420" },
{ code : "31084", intitule : "BOUSSENS", cp : "31360" },
{ code : "31085", intitule : "BOUTX", cp : "31160" },
{ code : "31085", intitule : "BOUTX", cp : "31440" },
{ code : "31085", intitule : "BOUTX", cp : "31160" },
{ code : "31085", intitule : "BOUTX", cp : "31440" },
{ code : "31086", intitule : "BOUZIN", cp : "31420" },
{ code : "31087", intitule : "BRAGAYRAC", cp : "31470" },
{ code : "31088", intitule : "BRAX", cp : "31490" },
{ code : "31089", intitule : "BRETX", cp : "31530" },
{ code : "31090", intitule : "BRIGNEMONT", cp : "31480" },
{ code : "31091", intitule : "BRUGUIERES", cp : "31150" },
{ code : "31092", intitule : "BURGALAYS", cp : "31440" },
{ code : "31093", intitule : "LE BURGAUD", cp : "31330" },
{ code : "31094", intitule : "BUZET SUR TARN", cp : "31660" },
{ code : "31095", intitule : "CABANAC CAZAUX", cp : "31160" },
{ code : "31096", intitule : "CABANAC SEGUENVILLE", cp : "31480" },
{ code : "31097", intitule : "LE CABANIAL", cp : "31460" },
{ code : "31098", intitule : "CADOURS", cp : "31480" },
{ code : "31099", intitule : "CAIGNAC", cp : "31560" },
{ code : "31100", intitule : "CALMONT", cp : "31560" },
{ code : "31101", intitule : "CAMBERNARD", cp : "31470" },
{ code : "31102", intitule : "CAMBIAC", cp : "31460" },
{ code : "31103", intitule : "CANENS", cp : "31310" },
{ code : "31104", intitule : "CAPENS", cp : "31410" },
{ code : "31105", intitule : "CARAGOUDES", cp : "31460" },
{ code : "31106", intitule : "CARAMAN", cp : "31460" },
{ code : "31107", intitule : "CARBONNE", cp : "31390" },
{ code : "31108", intitule : "CARDEILHAC", cp : "31350" },
{ code : "31109", intitule : "CASSAGNABERE TOURNAS", cp : "31420" },
{ code : "31110", intitule : "CASSAGNE", cp : "31260" },
{ code : "31111", intitule : "CASTAGNAC", cp : "31310" },
{ code : "31112", intitule : "CASTAGNEDE", cp : "31260" },
{ code : "31113", intitule : "CASTANET TOLOSAN", cp : "31320" },
{ code : "31114", intitule : "CASTELBIAGUE", cp : "31160" },
{ code : "31115", intitule : "CASTELGAILLARD", cp : "31230" },
{ code : "31116", intitule : "CASTELGINEST", cp : "31780" },
{ code : "31117", intitule : "CASTELMAUROU", cp : "31180" },
{ code : "31118", intitule : "CASTELNAU D ESTRETEFONDS", cp : "31620" },
{ code : "31119", intitule : "CASTELNAU PICAMPEAU", cp : "31430" },
{ code : "31120", intitule : "LE CASTERA", cp : "31530" },
{ code : "31121", intitule : "CASTERA VIGNOLES", cp : "31350" },
{ code : "31122", intitule : "CASTIES LABRANDE", cp : "31430" },
{ code : "31123", intitule : "CASTILLON DE LARBOUST", cp : "31110" },
{ code : "31124", intitule : "CASTILLON DE ST MARTORY", cp : "31360" },
{ code : "31125", intitule : "CATHERVIELLE", cp : "31110" },
{ code : "31126", intitule : "CAUBIAC", cp : "31480" },
{ code : "31127", intitule : "CAUBOUS", cp : "31110" },
{ code : "31128", intitule : "CAUJAC", cp : "31190" },
{ code : "31129", intitule : "CAZARILH LASPENES", cp : "31110" },
{ code : "31130", intitule : "CAZARIL TAMBOURES", cp : "31580" },
{ code : "31131", intitule : "CAZAUNOUS", cp : "31160" },
{ code : "31132", intitule : "CAZAUX LAYRISSE", cp : "31440" },
{ code : "31133", intitule : "CAZEAUX DE LARBOUST", cp : "31110" },
{ code : "31134", intitule : "CAZENEUVE MONTAUT", cp : "31420" },
{ code : "31135", intitule : "CAZERES", cp : "31220" },
{ code : "31136", intitule : "CEPET", cp : "31620" },
{ code : "31137", intitule : "CESSALES", cp : "31290" },
{ code : "31138", intitule : "CHARLAS", cp : "31350" },
{ code : "31139", intitule : "CHAUM", cp : "31440" },
{ code : "31140", intitule : "CHEIN DESSUS", cp : "31160" },
{ code : "31141", intitule : "CIADOUX", cp : "31350" },
{ code : "31142", intitule : "CIER DE LUCHON", cp : "31110" },
{ code : "31143", intitule : "CIER DE RIVIERE", cp : "31510" },
{ code : "31144", intitule : "CIERP GAUD", cp : "31440" },
{ code : "31144", intitule : "CIERP GAUD", cp : "31440" },
{ code : "31145", intitule : "CINTEGABELLE", cp : "31550" },
{ code : "31146", intitule : "CIRES", cp : "31110" },
{ code : "31147", intitule : "CLARAC", cp : "31210" },
{ code : "31148", intitule : "CLERMONT LE FORT", cp : "31810" },
{ code : "31149", intitule : "COLOMIERS", cp : "31770" },
{ code : "31150", intitule : "CORNEBARRIEU", cp : "31700" },
{ code : "31151", intitule : "CORRONSAC", cp : "31450" },
{ code : "31152", intitule : "COUEILLES", cp : "31230" },
{ code : "31153", intitule : "COULADERE", cp : "31220" },
{ code : "31155", intitule : "COURET", cp : "31160" },
{ code : "31156", intitule : "COX", cp : "31480" },
{ code : "31157", intitule : "CUGNAUX", cp : "31270" },
{ code : "31158", intitule : "CUGURON", cp : "31210" },
{ code : "31159", intitule : "LE CUING", cp : "31210" },
{ code : "31160", intitule : "DAUX", cp : "31700" },
{ code : "31161", intitule : "DEYME", cp : "31450" },
{ code : "31162", intitule : "DONNEVILLE", cp : "31450" },
{ code : "31163", intitule : "DREMIL LAFAGE", cp : "31280" },
{ code : "31164", intitule : "DRUDAS", cp : "31480" },
{ code : "31165", intitule : "EAUNES", cp : "31600" },
{ code : "31166", intitule : "EMPEAUX", cp : "31470" },
{ code : "31167", intitule : "ENCAUSSE LES THERMES", cp : "31160" },
{ code : "31168", intitule : "EOUX", cp : "31420" },
{ code : "31169", intitule : "ESCALQUENS", cp : "31750" },
{ code : "31170", intitule : "ESCANECRABE", cp : "31350" },
{ code : "31171", intitule : "ESPANES", cp : "31450" },
{ code : "31172", intitule : "ESPARRON", cp : "31420" },
{ code : "31173", intitule : "ESPERCE", cp : "31190" },
{ code : "31174", intitule : "ESTADENS", cp : "31160" },
{ code : "31175", intitule : "ESTANCARBON", cp : "31800" },
{ code : "31176", intitule : "ESTENOS", cp : "31440" },
{ code : "31177", intitule : "EUP", cp : "31440" },
{ code : "31178", intitule : "FABAS", cp : "31230" },
{ code : "31179", intitule : "LE FAGET", cp : "31460" },
{ code : "31180", intitule : "FALGA", cp : "31540" },
{ code : "31181", intitule : "LE FAUGA", cp : "31410" },
{ code : "31182", intitule : "FENOUILLET", cp : "31150" },
{ code : "31183", intitule : "FIGAROL", cp : "31260" },
{ code : "31184", intitule : "FLOURENS", cp : "31130" },
{ code : "31185", intitule : "FOLCARDE", cp : "31290" },
{ code : "31186", intitule : "FONBEAUZARD", cp : "31140" },
{ code : "31187", intitule : "FONSORBES", cp : "31470" },
{ code : "31188", intitule : "FONTENILLES", cp : "31470" },
{ code : "31189", intitule : "FORGUES", cp : "31370" },
{ code : "31190", intitule : "FOS", cp : "31440" },
{ code : "31191", intitule : "FOUGARON", cp : "31160" },
{ code : "31192", intitule : "FOURQUEVAUX", cp : "31450" },
{ code : "31193", intitule : "LE FOUSSERET", cp : "31430" },
{ code : "31194", intitule : "FRANCARVILLE", cp : "31460" },
{ code : "31195", intitule : "FRANCAZAL", cp : "31260" },
{ code : "31196", intitule : "FRANCON", cp : "31420" },
{ code : "31197", intitule : "FRANQUEVIELLE", cp : "31210" },
{ code : "31198", intitule : "LE FRECHET", cp : "31360" },
{ code : "31199", intitule : "FRONSAC", cp : "31440" },
{ code : "31200", intitule : "FRONTIGNAN DE COMMINGES", cp : "31510" },
{ code : "31201", intitule : "FRONTIGNAN SAVES", cp : "31230" },
{ code : "31202", intitule : "FRONTON", cp : "31620" },
{ code : "31203", intitule : "FROUZINS", cp : "31270" },
{ code : "31204", intitule : "FUSTIGNAC", cp : "31430" },
{ code : "31205", intitule : "GAGNAC SUR GARONNE", cp : "31150" },
{ code : "31206", intitule : "GAILLAC TOULZA", cp : "31550" },
{ code : "31207", intitule : "GALIE", cp : "31510" },
{ code : "31208", intitule : "GANTIES", cp : "31160" },
{ code : "31209", intitule : "GARAC", cp : "31480" },
{ code : "31210", intitule : "GARDOUCH", cp : "31290" },
{ code : "31211", intitule : "GARGAS", cp : "31620" },
{ code : "31212", intitule : "GARIDECH", cp : "31380" },
{ code : "31213", intitule : "GARIN", cp : "31110" },
{ code : "31215", intitule : "GAURE", cp : "31590" },
{ code : "31216", intitule : "GEMIL", cp : "31380" },
{ code : "31217", intitule : "GENOS", cp : "31510" },
{ code : "31218", intitule : "GENSAC DE BOULOGNE", cp : "31350" },
{ code : "31219", intitule : "GENSAC SUR GARONNE", cp : "31310" },
{ code : "31220", intitule : "GIBEL", cp : "31560" },
{ code : "31221", intitule : "GOUAUX DE LARBOUST", cp : "31110" },
{ code : "31222", intitule : "GOUAUX DE LUCHON", cp : "31110" },
{ code : "31223", intitule : "GOUDEX", cp : "31230" },
{ code : "31224", intitule : "GOURDAN POLIGNAN", cp : "31210" },
{ code : "31225", intitule : "GOUTEVERNISSE", cp : "31310" },
{ code : "31226", intitule : "GOUZENS", cp : "31310" },
{ code : "31227", intitule : "GOYRANS", cp : "31120" },
{ code : "31228", intitule : "GRAGNAGUE", cp : "31380" },
{ code : "31229", intitule : "GRATENS", cp : "31430" },
{ code : "31230", intitule : "GRATENTOUR", cp : "31150" },
{ code : "31231", intitule : "GRAZAC", cp : "31190" },
{ code : "31232", intitule : "GRENADE", cp : "31330" },
{ code : "31233", intitule : "GREPIAC", cp : "31190" },
{ code : "31234", intitule : "LE GRES", cp : "31480" },
{ code : "31235", intitule : "GURAN", cp : "31440" },
{ code : "31236", intitule : "HERRAN", cp : "31160" },
{ code : "31237", intitule : "HIS", cp : "31260" },
{ code : "31238", intitule : "HUOS", cp : "31210" },
{ code : "31239", intitule : "L ISLE EN DODON", cp : "31230" },
{ code : "31240", intitule : "ISSUS", cp : "31450" },
{ code : "31241", intitule : "IZAUT DE L HOTEL", cp : "31160" },
{ code : "31242", intitule : "JURVIELLE", cp : "31110" },
{ code : "31243", intitule : "JUZES", cp : "31540" },
{ code : "31244", intitule : "JUZET DE LUCHON", cp : "31110" },
{ code : "31245", intitule : "JUZET D IZAUT", cp : "31160" },
{ code : "31246", intitule : "LABARTHE INARD", cp : "31800" },
{ code : "31247", intitule : "LABARTHE RIVIERE", cp : "31800" },
{ code : "31248", intitule : "LABARTHE SUR LEZE", cp : "31860" },
{ code : "31249", intitule : "LABASTIDE BEAUVOIR", cp : "31450" },
{ code : "31250", intitule : "LABASTIDE CLERMONT", cp : "31370" },
{ code : "31251", intitule : "LABASTIDE PAUMES", cp : "31230" },
{ code : "31252", intitule : "LABASTIDE ST SERNIN", cp : "31620" },
{ code : "31253", intitule : "LABASTIDETTE", cp : "31600" },
{ code : "31254", intitule : "LABEGE", cp : "31670" },
{ code : "31255", intitule : "LABROQUERE", cp : "31510" },
{ code : "31256", intitule : "LABRUYERE DORSA", cp : "31190" },
{ code : "31258", intitule : "LACAUGNE", cp : "31390" },
{ code : "31259", intitule : "LACROIX FALGARDE", cp : "31120" },
{ code : "31260", intitule : "LAFFITE TOUPIERE", cp : "31360" },
{ code : "31261", intitule : "LAFITTE VIGORDANE", cp : "31390" },
{ code : "31262", intitule : "LAGARDE", cp : "31290" },
{ code : "31263", intitule : "LAGARDELLE SUR LEZE", cp : "31870" },
{ code : "31264", intitule : "LAGRACE DIEU", cp : "31190" },
{ code : "31265", intitule : "LAGRAULET ST NICOLAS", cp : "31480" },
{ code : "31266", intitule : "LAHAGE", cp : "31370" },
{ code : "31267", intitule : "LAHITERE", cp : "31310" },
{ code : "31268", intitule : "LALOURET LAFFITEAU", cp : "31800" },
{ code : "31269", intitule : "LAMASQUERE", cp : "31600" },
{ code : "31270", intitule : "LANDORTHE", cp : "31800" },
{ code : "31271", intitule : "LANTA", cp : "31570" },
{ code : "31272", intitule : "LAPEYRERE", cp : "31310" },
{ code : "31273", intitule : "LAPEYROUSE FOSSAT", cp : "31180" },
{ code : "31274", intitule : "LARCAN", cp : "31800" },
{ code : "31275", intitule : "LAREOLE", cp : "31480" },
{ code : "31276", intitule : "LARROQUE", cp : "31580" },
{ code : "31277", intitule : "LASSERRE", cp : "31530" },
{ code : "31278", intitule : "LATOUE", cp : "31800" },
{ code : "31279", intitule : "LATOUR", cp : "31310" },
{ code : "31280", intitule : "LATRAPE", cp : "31310" },
{ code : "31281", intitule : "LAUNAC", cp : "31330" },
{ code : "31282", intitule : "LAUNAGUET", cp : "31140" },
{ code : "31283", intitule : "LAUTIGNAC", cp : "31370" },
{ code : "31284", intitule : "LAUZERVILLE", cp : "31650" },
{ code : "31285", intitule : "LAVALETTE", cp : "31590" },
{ code : "31286", intitule : "LAVELANET DE COMMINGES", cp : "31220" },
{ code : "31287", intitule : "LAVERNOSE LACASSE", cp : "31410" },
{ code : "31288", intitule : "LAYRAC SUR TARN", cp : "31340" },
{ code : "31289", intitule : "LECUSSAN", cp : "31580" },
{ code : "31290", intitule : "LEGE", cp : "31440" },
{ code : "31291", intitule : "LEGUEVIN", cp : "31490" },
{ code : "31292", intitule : "LESCUNS", cp : "31220" },
{ code : "31293", intitule : "LESPINASSE", cp : "31150" },
{ code : "31294", intitule : "LESPITEAU", cp : "31160" },
{ code : "31295", intitule : "LESPUGUE", cp : "31350" },
{ code : "31296", intitule : "LESTELLE DE ST MARTORY", cp : "31360" },
{ code : "31297", intitule : "LEVIGNAC", cp : "31530" },
{ code : "31298", intitule : "LEZ", cp : "31440" },
{ code : "31299", intitule : "LHERM", cp : "31600" },
{ code : "31300", intitule : "LIEOUX", cp : "31800" },
{ code : "31301", intitule : "LILHAC", cp : "31230" },
{ code : "31302", intitule : "LODES", cp : "31800" },
{ code : "31303", intitule : "LONGAGES", cp : "31410" },
{ code : "31304", intitule : "LOUBENS LAURAGAIS", cp : "31460" },
{ code : "31305", intitule : "LOUDET", cp : "31580" },
{ code : "31306", intitule : "LOURDE", cp : "31510" },
{ code : "31307", intitule : "LUNAX", cp : "31350" },
{ code : "31308", intitule : "LUSCAN", cp : "31510" },
{ code : "31309", intitule : "LUSSAN ADEILHAC", cp : "31430" },
{ code : "31310", intitule : "LUX", cp : "31290" },
{ code : "31311", intitule : "LA MAGDELAINE SUR TARN", cp : "31340" },
{ code : "31312", intitule : "MAILHOLAS", cp : "31310" },
{ code : "31313", intitule : "MALVEZIE", cp : "31510" },
{ code : "31314", intitule : "MANCIOUX", cp : "31360" },
{ code : "31315", intitule : "MANE", cp : "31260" },
{ code : "31316", intitule : "MARIGNAC", cp : "31440" },
{ code : "31317", intitule : "MARIGNAC LASCLARES", cp : "31430" },
{ code : "31318", intitule : "MARIGNAC LASPEYRES", cp : "31220" },
{ code : "31319", intitule : "MARLIAC", cp : "31550" },
{ code : "31320", intitule : "MARQUEFAVE", cp : "31390" },
{ code : "31321", intitule : "MARSOULAS", cp : "31260" },
{ code : "31322", intitule : "MARTISSERRE", cp : "31230" },
{ code : "31323", intitule : "MARTRES DE RIVIERE", cp : "31210" },
{ code : "31324", intitule : "MARTRES TOLOSANE", cp : "31220" },
{ code : "31325", intitule : "MASCARVILLE", cp : "31460" },
{ code : "31326", intitule : "MASSABRAC", cp : "31310" },
{ code : "31327", intitule : "MAURAN", cp : "31220" },
{ code : "31328", intitule : "MAUREMONT", cp : "31290" },
{ code : "31329", intitule : "MAURENS", cp : "31540" },
{ code : "31330", intitule : "MAURESSAC", cp : "31190" },
{ code : "31331", intitule : "MAUREVILLE", cp : "31460" },
{ code : "31332", intitule : "MAUVAISIN", cp : "31190" },
{ code : "31333", intitule : "MAUVEZIN", cp : "31230" },
{ code : "31334", intitule : "MAUZAC", cp : "31410" },
{ code : "31335", intitule : "MAYREGNE", cp : "31110" },
{ code : "31336", intitule : "MAZERES SUR SALAT", cp : "31260" },
{ code : "31337", intitule : "MELLES", cp : "31440" },
{ code : "31338", intitule : "MENVILLE", cp : "31530" },
{ code : "31339", intitule : "MERENVIELLE", cp : "31530" },
{ code : "31340", intitule : "MERVILLA", cp : "31320" },
{ code : "31341", intitule : "MERVILLE", cp : "31330" },
{ code : "31342", intitule : "MILHAS", cp : "31160" },
{ code : "31343", intitule : "MIRAMBEAU", cp : "31230" },
{ code : "31344", intitule : "MIRAMONT DE COMMINGES", cp : "31800" },
{ code : "31345", intitule : "MIREMONT", cp : "31190" },
{ code : "31346", intitule : "MIREPOIX SUR TARN", cp : "31340" },
{ code : "31347", intitule : "MOLAS", cp : "31230" },
{ code : "31348", intitule : "MONCAUP", cp : "31160" },
{ code : "31349", intitule : "MONDAVEZAN", cp : "31220" },
{ code : "31350", intitule : "MONDILHAN", cp : "31350" },
{ code : "31351", intitule : "MONDONVILLE", cp : "31700" },
{ code : "31352", intitule : "MONDOUZIL", cp : "31850" },
{ code : "31353", intitule : "MONES", cp : "31370" },
{ code : "31354", intitule : "MONESTROL", cp : "31560" },
{ code : "31355", intitule : "MONS", cp : "31280" },
{ code : "31356", intitule : "MONTAIGUT SUR SAVE", cp : "31530" },
{ code : "31357", intitule : "MONTASTRUC DE SALIES", cp : "31160" },
{ code : "31358", intitule : "MONTASTRUC LA CONSEILLERE", cp : "31380" },
{ code : "31359", intitule : "MONTASTRUC SAVES", cp : "31370" },
{ code : "31360", intitule : "MONTAUBAN DE LUCHON", cp : "31110" },
{ code : "31361", intitule : "MONTAUT", cp : "31410" },
{ code : "31362", intitule : "MONTBERAUD", cp : "31220" },
{ code : "31363", intitule : "MONTBERNARD", cp : "31230" },
{ code : "31364", intitule : "MONTBERON", cp : "31140" },
{ code : "31365", intitule : "MONTBRUN BOCAGE", cp : "31310" },
{ code : "31366", intitule : "MONTBRUN LAURAGAIS", cp : "31450" },
{ code : "31367", intitule : "MONTCLAR DE COMMINGES", cp : "31220" },
{ code : "31368", intitule : "MONTCLAR LAURAGAIS", cp : "31290" },
{ code : "31369", intitule : "MONT DE GALIE", cp : "31510" },
{ code : "31370", intitule : "MONTEGUT BOURJAC", cp : "31430" },
{ code : "31371", intitule : "MONTEGUT LAURAGAIS", cp : "31540" },
{ code : "31372", intitule : "MONTESPAN", cp : "31260" },
{ code : "31373", intitule : "MONTESQUIEU GUITTAUT", cp : "31230" },
{ code : "31374", intitule : "MONTESQUIEU LAURAGAIS", cp : "31450" },
{ code : "31375", intitule : "MONTESQUIEU VOLVESTRE", cp : "31310" },
{ code : "31376", intitule : "MONTGAILLARD DE SALIES", cp : "31260" },
{ code : "31377", intitule : "MONTGAILLARD LAURAGAIS", cp : "31290" },
{ code : "31378", intitule : "MONTGAILLARD SUR SAVE", cp : "31350" },
{ code : "31379", intitule : "MONTGAZIN", cp : "31410" },
{ code : "31380", intitule : "MONTGEARD", cp : "31560" },
{ code : "31381", intitule : "MONTGISCARD", cp : "31450" },
{ code : "31382", intitule : "MONTGRAS", cp : "31370" },
{ code : "31383", intitule : "MONTJOIRE", cp : "31380" },
{ code : "31384", intitule : "MONTLAUR", cp : "31450" },
{ code : "31385", intitule : "MONTMAURIN", cp : "31350" },
{ code : "31386", intitule : "MONTOULIEU ST BERNARD", cp : "31420" },
{ code : "31387", intitule : "MONTOUSSIN", cp : "31430" },
{ code : "31388", intitule : "MONTPITOL", cp : "31380" },
{ code : "31389", intitule : "MONTRABE", cp : "31850" },
{ code : "31390", intitule : "MONTREJEAU", cp : "31210" },
{ code : "31391", intitule : "MONTSAUNES", cp : "31260" },
{ code : "31392", intitule : "MOURVILLES BASSES", cp : "31460" },
{ code : "31393", intitule : "MOURVILLES HAUTES", cp : "31540" },
{ code : "31394", intitule : "MOUSTAJON", cp : "31110" },
{ code : "31395", intitule : "MURET", cp : "31600" },
{ code : "31396", intitule : "NAILLOUX", cp : "31560" },
{ code : "31397", intitule : "NENIGAN", cp : "31350" },
{ code : "31398", intitule : "NIZAN GESSE", cp : "31350" },
{ code : "31399", intitule : "NOE", cp : "31410" },
{ code : "31400", intitule : "NOGARET", cp : "31540" },
{ code : "31401", intitule : "NOUEILLES", cp : "31450" },
{ code : "31402", intitule : "ODARS", cp : "31450" },
{ code : "31403", intitule : "ONDES", cp : "31330" },
{ code : "31404", intitule : "OO", cp : "31110" },
{ code : "31405", intitule : "ORE", cp : "31510" },
{ code : "31406", intitule : "PALAMINY", cp : "31220" },
{ code : "31407", intitule : "PAULHAC", cp : "31380" },
{ code : "31408", intitule : "PAYSSOUS", cp : "31510" },
{ code : "31409", intitule : "PECHABOU", cp : "31320" },
{ code : "31410", intitule : "PECHBONNIEU", cp : "31140" },
{ code : "31411", intitule : "PECHBUSQUE", cp : "31320" },
{ code : "31412", intitule : "PEGUILHAN", cp : "31350" },
{ code : "31413", intitule : "PELLEPORT", cp : "31480" },
{ code : "31414", intitule : "PEYRISSAS", cp : "31420" },
{ code : "31415", intitule : "PEYROUZET", cp : "31420" },
{ code : "31416", intitule : "PEYSSIES", cp : "31390" },
{ code : "31417", intitule : "PIBRAC", cp : "31820" },
{ code : "31418", intitule : "PIN BALMA", cp : "31130" },
{ code : "31419", intitule : "LE PIN MURELET", cp : "31370" },
{ code : "31420", intitule : "PINSAGUEL", cp : "31120" },
{ code : "31421", intitule : "PINS JUSTARET", cp : "31860" },
{ code : "31422", intitule : "PLAGNE", cp : "31220" },
{ code : "31423", intitule : "PLAGNOLE", cp : "31370" },
{ code : "31424", intitule : "PLAISANCE DU TOUCH", cp : "31830" },
{ code : "31425", intitule : "LE PLAN", cp : "31220" },
{ code : "31426", intitule : "POINTIS DE RIVIERE", cp : "31210" },
{ code : "31427", intitule : "POINTIS INARD", cp : "31800" },
{ code : "31428", intitule : "POLASTRON", cp : "31430" },
{ code : "31429", intitule : "POMPERTUZAT", cp : "31450" },
{ code : "31430", intitule : "PONLAT TAILLEBOURG", cp : "31210" },
{ code : "31431", intitule : "PORTET D ASPET", cp : "31160" },
{ code : "31432", intitule : "PORTET DE LUCHON", cp : "31110" },
{ code : "31433", intitule : "PORTET SUR GARONNE", cp : "31120" },
{ code : "31434", intitule : "POUBEAU", cp : "31110" },
{ code : "31435", intitule : "POUCHARRAMET", cp : "31370" },
{ code : "31436", intitule : "POUY DE TOUGES", cp : "31430" },
{ code : "31437", intitule : "POUZE", cp : "31450" },
{ code : "31438", intitule : "PRADERE LES BOURGUETS", cp : "31530" },
{ code : "31439", intitule : "PRESERVILLE", cp : "31570" },
{ code : "31440", intitule : "PROUPIARY", cp : "31360" },
{ code : "31441", intitule : "PRUNET", cp : "31460" },
{ code : "31442", intitule : "PUYDANIEL", cp : "31190" },
{ code : "31443", intitule : "PUYMAURIN", cp : "31230" },
{ code : "31444", intitule : "PUYSSEGUR", cp : "31480" },
{ code : "31445", intitule : "QUINT FONSEGRIVES", cp : "31130" },
{ code : "31446", intitule : "RAMONVILLE ST AGNE", cp : "31520" },
{ code : "31447", intitule : "RAZECUEILLE", cp : "31160" },
{ code : "31448", intitule : "REBIGUE", cp : "31320" },
{ code : "31449", intitule : "REGADES", cp : "31800" },
{ code : "31450", intitule : "RENNEVILLE", cp : "31290" },
{ code : "31451", intitule : "REVEL", cp : "31250" },
{ code : "31451", intitule : "REVEL", cp : "31250" },
{ code : "31452", intitule : "RIEUCAZE", cp : "31800" },
{ code : "31453", intitule : "RIEUMAJOU", cp : "31290" },
{ code : "31454", intitule : "RIEUMES", cp : "31370" },
{ code : "31455", intitule : "RIEUX VOLVESTRE", cp : "31310" },
{ code : "31456", intitule : "RIOLAS", cp : "31230" },
{ code : "31457", intitule : "ROQUEFORT SUR GARONNE", cp : "31360" },
{ code : "31458", intitule : "ROQUES", cp : "31120" },
{ code : "31459", intitule : "ROQUESERIERE", cp : "31380" },
{ code : "31460", intitule : "ROQUETTES", cp : "31120" },
{ code : "31461", intitule : "ROUEDE", cp : "31160" },
{ code : "31462", intitule : "ROUFFIAC TOLOSAN", cp : "31180" },
{ code : "31463", intitule : "ROUMENS", cp : "31540" },
{ code : "31464", intitule : "SABONNERES", cp : "31370" },
{ code : "31465", intitule : "SACCOURVIELLE", cp : "31110" },
{ code : "31466", intitule : "SAIGUEDE", cp : "31470" },
{ code : "31467", intitule : "ST ALBAN", cp : "31140" },
{ code : "31468", intitule : "ST ANDRE", cp : "31420" },
{ code : "31469", intitule : "ST ARAILLE", cp : "31430" },
{ code : "31470", intitule : "ST AVENTIN", cp : "31110" },
{ code : "31471", intitule : "ST BEAT", cp : "31440" },
{ code : "31472", intitule : "ST BERTRAND DE COMMINGES", cp : "31510" },
{ code : "31473", intitule : "ST CEZERT", cp : "31330" },
{ code : "31474", intitule : "ST CHRISTAUD", cp : "31310" },
{ code : "31475", intitule : "ST CLAR DE RIVIERE", cp : "31600" },
{ code : "31476", intitule : "ST ELIX LE CHATEAU", cp : "31430" },
{ code : "31477", intitule : "ST ELIX SEGLAN", cp : "31420" },
{ code : "31478", intitule : "ST FELIX LAURAGAIS", cp : "31540" },
{ code : "31479", intitule : "ST FERREOL DE COMMINGES", cp : "31350" },
{ code : "31480", intitule : "STE FOY D AIGREFEUILLE", cp : "31570" },
{ code : "31481", intitule : "STE FOY DE PEYROLIERES", cp : "31470" },
{ code : "31482", intitule : "ST FRAJOU", cp : "31230" },
{ code : "31483", intitule : "ST GAUDENS", cp : "31800" },
{ code : "31484", intitule : "ST GENIES BELLEVUE", cp : "31180" },
{ code : "31485", intitule : "ST GERMIER", cp : "31290" },
{ code : "31486", intitule : "ST HILAIRE", cp : "31410" },
{ code : "31487", intitule : "ST IGNAN", cp : "31800" },
{ code : "31488", intitule : "ST JEAN", cp : "31240" },
{ code : "31489", intitule : "ST JEAN LHERM", cp : "31380" },
{ code : "31490", intitule : "ST JORY", cp : "31790" },
{ code : "31491", intitule : "ST JULIA", cp : "31540" },
{ code : "31492", intitule : "ST JULIEN SUR GARONNE", cp : "31220" },
{ code : "31493", intitule : "ST LARY BOUJEAN", cp : "31350" },
{ code : "31494", intitule : "ST LAURENT", cp : "31230" },
{ code : "31495", intitule : "ST LEON", cp : "31560" },
{ code : "31496", intitule : "STE LIVRADE", cp : "31530" },
{ code : "31497", intitule : "ST LOUP CAMMAS", cp : "31140" },
{ code : "31498", intitule : "ST LOUP EN COMMINGES", cp : "31350" },
{ code : "31499", intitule : "ST LYS", cp : "31470" },
{ code : "31500", intitule : "ST MAMET", cp : "31110" },
{ code : "31501", intitule : "ST MARCEL PAULEL", cp : "31590" },
{ code : "31502", intitule : "ST MARCET", cp : "31800" },
{ code : "31503", intitule : "ST MARTORY", cp : "31360" },
{ code : "31504", intitule : "ST MEDARD", cp : "31360" },
{ code : "31505", intitule : "ST MICHEL", cp : "31220" },
{ code : "31506", intitule : "ST ORENS DE GAMEVILLE", cp : "31650" },
{ code : "31507", intitule : "ST PAUL SUR SAVE", cp : "31530" },
{ code : "31508", intitule : "ST PAUL D OUEIL", cp : "31110" },
{ code : "31509", intitule : "ST PE D ARDET", cp : "31510" },
{ code : "31510", intitule : "ST PE DELBOSC", cp : "31350" },
{ code : "31511", intitule : "ST PIERRE", cp : "31590" },
{ code : "31512", intitule : "ST PIERRE DE LAGES", cp : "31570" },
{ code : "31513", intitule : "ST PLANCARD", cp : "31580" },
{ code : "31514", intitule : "ST ROME", cp : "31290" },
{ code : "31515", intitule : "ST RUSTICE", cp : "31620" },
{ code : "31516", intitule : "ST SAUVEUR", cp : "31790" },
{ code : "31517", intitule : "ST SULPICE SUR LEZE", cp : "31410" },
{ code : "31518", intitule : "ST THOMAS", cp : "31470" },
{ code : "31519", intitule : "ST VINCENT", cp : "31290" },
{ code : "31520", intitule : "SAJAS", cp : "31370" },
{ code : "31521", intitule : "SALEICH", cp : "31260" },
{ code : "31522", intitule : "SALERM", cp : "31230" },
{ code : "31523", intitule : "SALIES DU SALAT", cp : "31260" },
{ code : "31524", intitule : "SALLES ET PRATVIEL", cp : "31110" },
{ code : "31525", intitule : "SALLES SUR GARONNE", cp : "31390" },
{ code : "31526", intitule : "LA SALVETAT ST GILLES", cp : "31880" },
{ code : "31527", intitule : "LA SALVETAT LAURAGAIS", cp : "31460" },
{ code : "31528", intitule : "SAMAN", cp : "31350" },
{ code : "31529", intitule : "SAMOUILLAN", cp : "31420" },
{ code : "31530", intitule : "SANA", cp : "31220" },
{ code : "31531", intitule : "SARRECAVE", cp : "31350" },
{ code : "31532", intitule : "SARREMEZAN", cp : "31350" },
{ code : "31533", intitule : "SAUBENS", cp : "31600" },
{ code : "31534", intitule : "SAUSSENS", cp : "31460" },
{ code : "31535", intitule : "SAUVETERRE DE COMMINGES", cp : "31510" },
{ code : "31536", intitule : "SAUX ET POMAREDE", cp : "31800" },
{ code : "31537", intitule : "SAVARTHES", cp : "31800" },
{ code : "31538", intitule : "SAVERES", cp : "31370" },
{ code : "31539", intitule : "SEDEILHAC", cp : "31580" },
{ code : "31540", intitule : "SEGREVILLE", cp : "31460" },
{ code : "31541", intitule : "SEILH", cp : "31840" },
{ code : "31542", intitule : "SEILHAN", cp : "31510" },
{ code : "31543", intitule : "SENARENS", cp : "31430" },
{ code : "31544", intitule : "SENGOUAGNET", cp : "31160" },
{ code : "31545", intitule : "SEPX", cp : "31360" },
{ code : "31546", intitule : "SEYRE", cp : "31560" },
{ code : "31547", intitule : "SEYSSES", cp : "31600" },
{ code : "31548", intitule : "SIGNAC", cp : "31440" },
{ code : "31549", intitule : "SODE", cp : "31110" },
{ code : "31550", intitule : "SOUEICH", cp : "31160" },
{ code : "31551", intitule : "TARABEL", cp : "31570" },
{ code : "31552", intitule : "TERREBASSE", cp : "31420" },
{ code : "31553", intitule : "THIL", cp : "31530" },
{ code : "31554", intitule : "TOUILLE", cp : "31260" },
{ code : "31555", intitule : "TOULOUSE", cp : "31300" },
{ code : "31555", intitule : "TOULOUSE", cp : "31500" },
{ code : "31555", intitule : "TOULOUSE", cp : "31200" },
{ code : "31555", intitule : "TOULOUSE", cp : "31400" },
{ code : "31555", intitule : "TOULOUSE", cp : "31000" },
{ code : "31555", intitule : "TOULOUSE", cp : "31100" },
{ code : "31556", intitule : "LES TOURREILLES", cp : "31210" },
{ code : "31557", intitule : "TOURNEFEUILLE", cp : "31170" },
{ code : "31558", intitule : "TOUTENS", cp : "31460" },
{ code : "31559", intitule : "TREBONS DE LUCHON", cp : "31110" },
{ code : "31560", intitule : "TREBONS SUR LA GRASSE", cp : "31290" },
{ code : "31561", intitule : "L UNION", cp : "31240" },
{ code : "31562", intitule : "URAU", cp : "31260" },
{ code : "31563", intitule : "VACQUIERS", cp : "31340" },
{ code : "31564", intitule : "VALCABRERE", cp : "31510" },
{ code : "31565", intitule : "VALENTINE", cp : "31800" },
{ code : "31566", intitule : "VALLEGUE", cp : "31290" },
{ code : "31567", intitule : "VALLESVILLES", cp : "31570" },
{ code : "31568", intitule : "VARENNES", cp : "31450" },
{ code : "31569", intitule : "VAUDREUILLE", cp : "31250" },
{ code : "31570", intitule : "VAUX", cp : "31540" },
{ code : "31571", intitule : "VENDINE", cp : "31460" },
{ code : "31572", intitule : "VENERQUE", cp : "31810" },
{ code : "31573", intitule : "VERFEIL", cp : "31590" },
{ code : "31574", intitule : "VERNET", cp : "31810" },
{ code : "31575", intitule : "VIEILLE TOULOUSE", cp : "31320" },
{ code : "31576", intitule : "VIEILLEVIGNE", cp : "31290" },
{ code : "31577", intitule : "VIGNAUX", cp : "31480" },
{ code : "31578", intitule : "VIGOULET AUZIL", cp : "31320" },
{ code : "31579", intitule : "VILLARIES", cp : "31380" },
{ code : "31580", intitule : "VILLATE", cp : "31860" },
{ code : "31581", intitule : "VILLAUDRIC", cp : "31620" },
{ code : "31582", intitule : "VILLEFRANCHE DE LAURAGAIS", cp : "31290" },
{ code : "31583", intitule : "VILLEMATIER", cp : "31340" },
{ code : "31584", intitule : "VILLEMUR SUR TARN", cp : "31340" },
{ code : "31585", intitule : "VILLENEUVE DE RIVIERE", cp : "31800" },
{ code : "31586", intitule : "VILLENEUVE LECUSSAN", cp : "31580" },
{ code : "31587", intitule : "VILLENEUVE LES BOULOC", cp : "31620" },
{ code : "31588", intitule : "VILLENEUVE TOLOSANE", cp : "31270" },
{ code : "31589", intitule : "VILLENOUVELLE", cp : "31290" },
{ code : "31590", intitule : "BINOS", cp : "31440" },
{ code : "31591", intitule : "ESCOULIS", cp : "31260" },
{ code : "31592", intitule : "LARRA", cp : "31330" },
{ code : "31593", intitule : "CAZAC", cp : "31230" },
{ code : "32001", intitule : "AIGNAN", cp : "32290" },
{ code : "32002", intitule : "ANSAN", cp : "32270" },
{ code : "32003", intitule : "ANTRAS", cp : "32360" },
{ code : "32004", intitule : "ARBLADE LE BAS", cp : "32720" },
{ code : "32005", intitule : "ARBLADE LE HAUT", cp : "32110" },
{ code : "32007", intitule : "ARDIZAS", cp : "32430" },
{ code : "32008", intitule : "ARMENTIEUX", cp : "32230" },
{ code : "32009", intitule : "ARMOUS ET CAU", cp : "32230" },
{ code : "32010", intitule : "ARROUEDE", cp : "32140" },
{ code : "32012", intitule : "AUBIET", cp : "32270" },
{ code : "32013", intitule : "AUCH", cp : "32000" },
{ code : "32014", intitule : "AUGNAX", cp : "32120" },
{ code : "32015", intitule : "AUJAN MOURNEDE", cp : "32300" },
{ code : "32016", intitule : "AURADE", cp : "32600" },
{ code : "32017", intitule : "AURENSAN", cp : "32400" },
{ code : "32018", intitule : "AURIMONT", cp : "32450" },
{ code : "32019", intitule : "AUTERIVE", cp : "32550" },
{ code : "32020", intitule : "AUX AUSSAT", cp : "32170" },
{ code : "32021", intitule : "AVENSAC", cp : "32120" },
{ code : "32022", intitule : "AVERON BERGELLE", cp : "32290" },
{ code : "32023", intitule : "AVEZAN", cp : "32380" },
{ code : "32024", intitule : "AYGUETINTE", cp : "32410" },
{ code : "32025", intitule : "AYZIEU", cp : "32800" },
{ code : "32026", intitule : "BAJONNETTE", cp : "32120" },
{ code : "32027", intitule : "BARCELONNE DU GERS", cp : "32720" },
{ code : "32028", intitule : "BARCUGNAN", cp : "32170" },
{ code : "32029", intitule : "BARRAN", cp : "32350" },
{ code : "32030", intitule : "BARS", cp : "32300" },
{ code : "32031", intitule : "BASCOUS", cp : "32190" },
{ code : "32032", intitule : "BASSOUES", cp : "32320" },
{ code : "32033", intitule : "BAZIAN", cp : "32320" },
{ code : "32034", intitule : "BAZUGUES", cp : "32170" },
{ code : "32035", intitule : "BEAUCAIRE", cp : "32410" },
{ code : "32036", intitule : "BEAUMARCHES", cp : "32160" },
{ code : "32037", intitule : "BEAUMONT", cp : "32100" },
{ code : "32038", intitule : "BEAUPUY", cp : "32600" },
{ code : "32039", intitule : "BECCAS", cp : "32730" },
{ code : "32040", intitule : "BEDECHAN", cp : "32450" },
{ code : "32041", intitule : "BELLEGARDE", cp : "32140" },
{ code : "32042", intitule : "BELLOC ST CLAMENS", cp : "32300" },
{ code : "32043", intitule : "BELMONT", cp : "32190" },
{ code : "32044", intitule : "BERAUT", cp : "32100" },
{ code : "32045", intitule : "BERDOUES", cp : "32300" },
{ code : "32046", intitule : "BERNEDE", cp : "32400" },
{ code : "32047", intitule : "BERRAC", cp : "32480" },
{ code : "32048", intitule : "BETCAVE AGUIN", cp : "32420" },
{ code : "32049", intitule : "BETOUS", cp : "32110" },
{ code : "32050", intitule : "BETPLAN", cp : "32730" },
{ code : "32051", intitule : "BEZERIL", cp : "32130" },
{ code : "32052", intitule : "BEZOLLES", cp : "32310" },
{ code : "32053", intitule : "BEZUES BAJON", cp : "32140" },
{ code : "32054", intitule : "BIRAN", cp : "32350" },
{ code : "32055", intitule : "BIVES", cp : "32380" },
{ code : "32056", intitule : "BLANQUEFORT", cp : "32270" },
{ code : "32057", intitule : "BLAZIERT", cp : "32100" },
{ code : "32058", intitule : "BLOUSSON SERIAN", cp : "32230" },
{ code : "32059", intitule : "BONAS", cp : "32410" },
{ code : "32060", intitule : "BOUCAGNERES", cp : "32550" },
{ code : "32061", intitule : "BOULAUR", cp : "32450" },
{ code : "32062", intitule : "BOURROUILLAN", cp : "32370" },
{ code : "32063", intitule : "BOUZON GELLENAVE", cp : "32290" },
{ code : "32064", intitule : "BRETAGNE D ARMAGNAC", cp : "32800" },
{ code : "32065", intitule : "LE BROUILH MONBERT", cp : "32350" },
{ code : "32065", intitule : "LE BROUILH MONBERT", cp : "32350" },
{ code : "32066", intitule : "BRUGNENS", cp : "32500" },
{ code : "32067", intitule : "CABAS LOUMASSES", cp : "32140" },
{ code : "32068", intitule : "CADEILHAN", cp : "32380" },
{ code : "32069", intitule : "CADEILLAN", cp : "32220" },
{ code : "32070", intitule : "CAHUZAC SUR ADOUR", cp : "32400" },
{ code : "32071", intitule : "CAILLAVET", cp : "32190" },
{ code : "32072", intitule : "CALLIAN", cp : "32190" },
{ code : "32073", intitule : "CAMPAGNE D ARMAGNAC", cp : "32800" },
{ code : "32074", intitule : "CANNET", cp : "32400" },
{ code : "32075", intitule : "CASSAIGNE", cp : "32100" },
{ code : "32076", intitule : "CASTELNAU BARBARENS", cp : "32450" },
{ code : "32077", intitule : "CASTELNAU D ANGLES", cp : "32320" },
{ code : "32078", intitule : "CASTELNAU D ARBIEU", cp : "32500" },
{ code : "32079", intitule : "CASTELNAU D AUZAN LABARRERE", cp : "32250" },
{ code : "32079", intitule : "CASTELNAU D AUZAN LABARRERE", cp : "32440" },
{ code : "32080", intitule : "CASTELNAU SUR L AUVIGNON", cp : "32100" },
{ code : "32081", intitule : "CASTELNAVET", cp : "32290" },
{ code : "32082", intitule : "CASTERA LECTOUROIS", cp : "32700" },
{ code : "32083", intitule : "CASTERA VERDUZAN", cp : "32410" },
{ code : "32084", intitule : "CASTERON", cp : "32380" },
{ code : "32085", intitule : "CASTET ARROUY", cp : "32340" },
{ code : "32086", intitule : "CASTEX", cp : "32170" },
{ code : "32087", intitule : "CASTEX D ARMAGNAC", cp : "32240" },
{ code : "32088", intitule : "CASTILLON DEBATS", cp : "32190" },
{ code : "32089", intitule : "CASTILLON MASSAS", cp : "32360" },
{ code : "32090", intitule : "CASTILLON SAVES", cp : "32490" },
{ code : "32091", intitule : "CASTIN", cp : "32810" },
{ code : "32092", intitule : "CATONVIELLE", cp : "32200" },
{ code : "32093", intitule : "CAUMONT", cp : "32400" },
{ code : "32094", intitule : "CAUPENNE D ARMAGNAC", cp : "32110" },
{ code : "32095", intitule : "CAUSSENS", cp : "32100" },
{ code : "32096", intitule : "CAZAUBON", cp : "32150" },
{ code : "32096", intitule : "CAZAUBON", cp : "32150" },
{ code : "32097", intitule : "CAZAUX D ANGLES", cp : "32190" },
{ code : "32098", intitule : "CAZAUX SAVES", cp : "32130" },
{ code : "32099", intitule : "CAZAUX VILLECOMTAL", cp : "32230" },
{ code : "32100", intitule : "CAZENEUVE", cp : "32800" },
{ code : "32101", intitule : "CERAN", cp : "32500" },
{ code : "32102", intitule : "CEZAN", cp : "32410" },
{ code : "32103", intitule : "CHELAN", cp : "32140" },
{ code : "32104", intitule : "CLERMONT POUYGUILLES", cp : "32300" },
{ code : "32105", intitule : "CLERMONT SAVES", cp : "32600" },
{ code : "32106", intitule : "COLOGNE", cp : "32430" },
{ code : "32107", intitule : "CONDOM", cp : "32100" },
{ code : "32108", intitule : "CORNEILLAN", cp : "32400" },
{ code : "32109", intitule : "COULOUME MONDEBAT", cp : "32160" },
{ code : "32110", intitule : "COURRENSAN", cp : "32330" },
{ code : "32111", intitule : "COURTIES", cp : "32230" },
{ code : "32112", intitule : "CRASTES", cp : "32270" },
{ code : "32113", intitule : "CRAVENCERES", cp : "32110" },
{ code : "32114", intitule : "CUELAS", cp : "32300" },
{ code : "32115", intitule : "DEMU", cp : "32190" },
{ code : "32116", intitule : "DUFFORT", cp : "32170" },
{ code : "32117", intitule : "DURAN", cp : "32810" },
{ code : "32118", intitule : "DURBAN", cp : "32260" },
{ code : "32119", intitule : "EAUZE", cp : "32800" },
{ code : "32120", intitule : "ENCAUSSE", cp : "32430" },
{ code : "32121", intitule : "ENDOUFIELLE", cp : "32600" },
{ code : "32122", intitule : "ESCLASSAN LABASTIDE", cp : "32140" },
{ code : "32123", intitule : "ESCORNEBOEUF", cp : "32200" },
{ code : "32124", intitule : "ESPAON", cp : "32220" },
{ code : "32125", intitule : "ESPAS", cp : "32370" },
{ code : "32126", intitule : "ESTAMPES", cp : "32170" },
{ code : "32127", intitule : "ESTANG", cp : "32240" },
{ code : "32128", intitule : "ESTIPOUY", cp : "32300" },
{ code : "32129", intitule : "ESTRAMIAC", cp : "32380" },
{ code : "32130", intitule : "FAGET ABBATIAL", cp : "32450" },
{ code : "32131", intitule : "FLAMARENS", cp : "32340" },
{ code : "32132", intitule : "FLEURANCE", cp : "32500" },
{ code : "32133", intitule : "FOURCES", cp : "32250" },
{ code : "32134", intitule : "FREGOUVILLE", cp : "32490" },
{ code : "32135", intitule : "FUSTEROUAU", cp : "32400" },
{ code : "32136", intitule : "GALIAX", cp : "32160" },
{ code : "32138", intitule : "GARRAVET", cp : "32220" },
{ code : "32139", intitule : "GAUDONVILLE", cp : "32380" },
{ code : "32140", intitule : "GAUJAC", cp : "32220" },
{ code : "32141", intitule : "GAUJAN", cp : "32420" },
{ code : "32142", intitule : "GAVARRET SUR AULOUSTE", cp : "32390" },
{ code : "32143", intitule : "GAZAUPOUY", cp : "32480" },
{ code : "32144", intitule : "GAZAX ET BACCARISSE", cp : "32230" },
{ code : "32145", intitule : "GEE RIVIERE", cp : "32720" },
{ code : "32146", intitule : "GIMBREDE", cp : "32340" },
{ code : "32147", intitule : "GIMONT", cp : "32200" },
{ code : "32148", intitule : "GISCARO", cp : "32200" },
{ code : "32149", intitule : "GONDRIN", cp : "32330" },
{ code : "32150", intitule : "GOUTZ", cp : "32500" },
{ code : "32151", intitule : "GOUX", cp : "32400" },
{ code : "32152", intitule : "HAGET", cp : "32730" },
{ code : "32153", intitule : "HAULIES", cp : "32550" },
{ code : "32154", intitule : "HOMPS", cp : "32120" },
{ code : "32155", intitule : "LE HOUGA", cp : "32460" },
{ code : "32156", intitule : "IDRAC RESPAILLES", cp : "32300" },
{ code : "32157", intitule : "L ISLE ARNE", cp : "32270" },
{ code : "32158", intitule : "L ISLE BOUZON", cp : "32380" },
{ code : "32159", intitule : "L ISLE DE NOE", cp : "32300" },
{ code : "32160", intitule : "L ISLE JOURDAIN", cp : "32600" },
{ code : "32161", intitule : "IZOTGES", cp : "32400" },
{ code : "32162", intitule : "JEGUN", cp : "32360" },
{ code : "32163", intitule : "JU BELLOC", cp : "32160" },
{ code : "32164", intitule : "JUILLAC", cp : "32230" },
{ code : "32165", intitule : "JUILLES", cp : "32200" },
{ code : "32166", intitule : "JUSTIAN", cp : "32190" },
{ code : "32167", intitule : "LAAS", cp : "32170" },
{ code : "32169", intitule : "LABARTHE", cp : "32260" },
{ code : "32170", intitule : "LABARTHETE", cp : "32400" },
{ code : "32171", intitule : "LABASTIDE SAVES", cp : "32130" },
{ code : "32172", intitule : "LABEJAN", cp : "32300" },
{ code : "32173", intitule : "LABRIHE", cp : "32120" },
{ code : "32174", intitule : "LADEVEZE RIVIERE", cp : "32230" },
{ code : "32175", intitule : "LADEVEZE VILLE", cp : "32230" },
{ code : "32176", intitule : "LAGARDE", cp : "32700" },
{ code : "32177", intitule : "LAGARDE HACHAN", cp : "32300" },
{ code : "32178", intitule : "LAGARDERE", cp : "32310" },
{ code : "32180", intitule : "LAGRAULET DU GERS", cp : "32330" },
{ code : "32181", intitule : "LAGUIAN MAZOUS", cp : "32170" },
{ code : "32182", intitule : "LAHAS", cp : "32130" },
{ code : "32183", intitule : "LAHITTE", cp : "32810" },
{ code : "32184", intitule : "LALANNE", cp : "32500" },
{ code : "32185", intitule : "LALANNE ARQUE", cp : "32140" },
{ code : "32186", intitule : "LAMAGUERE", cp : "32260" },
{ code : "32187", intitule : "LAMAZERE", cp : "32300" },
{ code : "32188", intitule : "LAMOTHE GOAS", cp : "32500" },
{ code : "32189", intitule : "LANNEMAIGNAN", cp : "32240" },
{ code : "32190", intitule : "LANNEPAX", cp : "32190" },
{ code : "32191", intitule : "LANNE SOUBIRAN", cp : "32110" },
{ code : "32192", intitule : "LANNUX", cp : "32400" },
{ code : "32193", intitule : "LAREE", cp : "32150" },
{ code : "32194", intitule : "LARRESSINGLE", cp : "32100" },
{ code : "32195", intitule : "LARROQUE ENGALIN", cp : "32480" },
{ code : "32196", intitule : "LARROQUE ST SERNIN", cp : "32410" },
{ code : "32197", intitule : "LARROQUE SUR L OSSE", cp : "32100" },
{ code : "32198", intitule : "LARTIGUE", cp : "32450" },
{ code : "32199", intitule : "LASSERADE", cp : "32160" },
{ code : "32200", intitule : "LASSERAN", cp : "32550" },
{ code : "32201", intitule : "LASSEUBE PROPRE", cp : "32550" },
{ code : "32202", intitule : "LAUJUZAN", cp : "32110" },
{ code : "32203", intitule : "LAURAET", cp : "32330" },
{ code : "32204", intitule : "LAVARDENS", cp : "32360" },
{ code : "32205", intitule : "LAVERAET", cp : "32230" },
{ code : "32206", intitule : "LAYMONT", cp : "32220" },
{ code : "32207", intitule : "LEBOULIN", cp : "32810" },
{ code : "32208", intitule : "LECTOURE", cp : "32700" },
{ code : "32209", intitule : "LELIN LAPUJOLLE", cp : "32400" },
{ code : "32210", intitule : "LIAS", cp : "32600" },
{ code : "32211", intitule : "LIAS D ARMAGNAC", cp : "32240" },
{ code : "32212", intitule : "LIGARDES", cp : "32480" },
{ code : "32213", intitule : "LOMBEZ", cp : "32220" },
{ code : "32214", intitule : "LOUBEDAT", cp : "32110" },
{ code : "32215", intitule : "LOUBERSAN", cp : "32300" },
{ code : "32216", intitule : "LOURTIES MONBRUN", cp : "32140" },
{ code : "32217", intitule : "LOUSLITGES", cp : "32230" },
{ code : "32218", intitule : "LOUSSOUS DEBAT", cp : "32290" },
{ code : "32219", intitule : "LUPIAC", cp : "32290" },
{ code : "32220", intitule : "LUPPE VIOLLES", cp : "32110" },
{ code : "32221", intitule : "LUSSAN", cp : "32270" },
{ code : "32222", intitule : "MAGNAN", cp : "32110" },
{ code : "32223", intitule : "MAGNAS", cp : "32380" },
{ code : "32224", intitule : "MAIGNAUT TAUZIA", cp : "32310" },
{ code : "32225", intitule : "MALABAT", cp : "32730" },
{ code : "32226", intitule : "MANAS BASTANOUS", cp : "32170" },
{ code : "32227", intitule : "MANCIET", cp : "32370" },
{ code : "32228", intitule : "MANENT MONTANE", cp : "32140" },
{ code : "32229", intitule : "MANSEMPUY", cp : "32120" },
{ code : "32230", intitule : "MANSENCOME", cp : "32310" },
{ code : "32231", intitule : "MARAMBAT", cp : "32190" },
{ code : "32232", intitule : "MARAVAT", cp : "32120" },
{ code : "32233", intitule : "MARCIAC", cp : "32230" },
{ code : "32234", intitule : "MARESTAING", cp : "32490" },
{ code : "32235", intitule : "MARGOUET MEYMES", cp : "32290" },
{ code : "32236", intitule : "MARGUESTAU", cp : "32150" },
{ code : "32237", intitule : "MARSAN", cp : "32270" },
{ code : "32238", intitule : "MARSEILLAN", cp : "32170" },
{ code : "32239", intitule : "MARSOLAN", cp : "32700" },
{ code : "32240", intitule : "MASCARAS", cp : "32230" },
{ code : "32241", intitule : "MAS D AUVIGNON", cp : "32700" },
{ code : "32242", intitule : "MASSEUBE", cp : "32140" },
{ code : "32243", intitule : "MAULEON D ARMAGNAC", cp : "32240" },
{ code : "32244", intitule : "MAULICHERES", cp : "32400" },
{ code : "32245", intitule : "MAUMUSSON LAGUIAN", cp : "32400" },
{ code : "32246", intitule : "MAUPAS", cp : "32240" },
{ code : "32247", intitule : "MAURENS", cp : "32200" },
{ code : "32248", intitule : "MAUROUX", cp : "32380" },
{ code : "32249", intitule : "MAUVEZIN", cp : "32120" },
{ code : "32250", intitule : "MEILHAN", cp : "32420" },
{ code : "32251", intitule : "MERENS", cp : "32360" },
{ code : "32252", intitule : "MIELAN", cp : "32170" },
{ code : "32253", intitule : "MIRADOUX", cp : "32340" },
{ code : "32254", intitule : "MIRAMONT D ASTARAC", cp : "32300" },
{ code : "32255", intitule : "MIRAMONT LATOUR", cp : "32390" },
{ code : "32256", intitule : "MIRANDE", cp : "32300" },
{ code : "32257", intitule : "MIRANNES", cp : "32350" },
{ code : "32258", intitule : "MIREPOIX", cp : "32390" },
{ code : "32260", intitule : "MONBARDON", cp : "32420" },
{ code : "32261", intitule : "MONBLANC", cp : "32130" },
{ code : "32262", intitule : "MONBRUN", cp : "32600" },
{ code : "32263", intitule : "MONCASSIN", cp : "32300" },
{ code : "32264", intitule : "MONCLAR", cp : "32150" },
{ code : "32265", intitule : "MONCLAR SUR LOSSE", cp : "32300" },
{ code : "32266", intitule : "MONCORNEIL GRAZAN", cp : "32260" },
{ code : "32267", intitule : "MONFERRAN PLAVES", cp : "32260" },
{ code : "32268", intitule : "MONFERRAN SAVES", cp : "32490" },
{ code : "32269", intitule : "MONFORT", cp : "32120" },
{ code : "32270", intitule : "MONGAUSY", cp : "32220" },
{ code : "32271", intitule : "MONGUILHEM", cp : "32240" },
{ code : "32272", intitule : "MONLAUR BERNET", cp : "32140" },
{ code : "32273", intitule : "MONLEZUN", cp : "32230" },
{ code : "32274", intitule : "MONLEZUN D ARMAGNAC", cp : "32240" },
{ code : "32275", intitule : "MONPARDIAC", cp : "32170" },
{ code : "32276", intitule : "MONTADET", cp : "32220" },
{ code : "32277", intitule : "MONTAMAT", cp : "32220" },
{ code : "32278", intitule : "MONTAUT", cp : "32300" },
{ code : "32279", intitule : "MONTAUT LES CRENEAUX", cp : "32810" },
{ code : "32280", intitule : "MONT D ASTARAC", cp : "32140" },
{ code : "32281", intitule : "MONT DE MARRAST", cp : "32170" },
{ code : "32282", intitule : "MONTEGUT", cp : "32550" },
{ code : "32283", intitule : "MONTEGUT ARROS", cp : "32730" },
{ code : "32284", intitule : "MONTEGUT SAVES", cp : "32220" },
{ code : "32285", intitule : "MONTESQUIOU", cp : "32320" },
{ code : "32286", intitule : "MONTESTRUC SUR GERS", cp : "32390" },
{ code : "32287", intitule : "MONTIES", cp : "32420" },
{ code : "32288", intitule : "MONTIRON", cp : "32200" },
{ code : "32289", intitule : "MONTPEZAT", cp : "32220" },
{ code : "32290", intitule : "MONTREAL", cp : "32250" },
{ code : "32291", intitule : "MORMES", cp : "32240" },
{ code : "32292", intitule : "MOUCHAN", cp : "32330" },
{ code : "32293", intitule : "MOUCHES", cp : "32300" },
{ code : "32294", intitule : "MOUREDE", cp : "32190" },
{ code : "32295", intitule : "NIZAS", cp : "32130" },
{ code : "32296", intitule : "NOGARO", cp : "32110" },
{ code : "32297", intitule : "NOILHAN", cp : "32130" },
{ code : "32298", intitule : "NOUGAROULET", cp : "32270" },
{ code : "32299", intitule : "NOULENS", cp : "32800" },
{ code : "32300", intitule : "ORBESSAN", cp : "32260" },
{ code : "32301", intitule : "ORDAN LARROQUE", cp : "32350" },
{ code : "32302", intitule : "ORNEZAN", cp : "32260" },
{ code : "32303", intitule : "PALLANNE", cp : "32230" },
{ code : "32304", intitule : "PANASSAC", cp : "32140" },
{ code : "32305", intitule : "PANJAS", cp : "32110" },
{ code : "32306", intitule : "PAUILHAC", cp : "32500" },
{ code : "32307", intitule : "PAVIE", cp : "32550" },
{ code : "32308", intitule : "PEBEES", cp : "32130" },
{ code : "32309", intitule : "PELLEFIGUE", cp : "32420" },
{ code : "32310", intitule : "PERCHEDE", cp : "32460" },
{ code : "32311", intitule : "PERGAIN TAILLAC", cp : "32700" },
{ code : "32312", intitule : "PESSAN", cp : "32550" },
{ code : "32313", intitule : "PESSOULENS", cp : "32380" },
{ code : "32314", intitule : "PEYRECAVE", cp : "32340" },
{ code : "32315", intitule : "PEYRUSSE GRANDE", cp : "32320" },
{ code : "32316", intitule : "PEYRUSSE MASSAS", cp : "32360" },
{ code : "32317", intitule : "PEYRUSSE VIEILLE", cp : "32230" },
{ code : "32318", intitule : "PIS", cp : "32500" },
{ code : "32319", intitule : "PLAISANCE", cp : "32160" },
{ code : "32320", intitule : "PLIEUX", cp : "32340" },
{ code : "32321", intitule : "POLASTRON", cp : "32130" },
{ code : "32322", intitule : "POMPIAC", cp : "32130" },
{ code : "32323", intitule : "PONSAMPERE", cp : "32300" },
{ code : "32324", intitule : "PONSAN SOUBIRAN", cp : "32300" },
{ code : "32325", intitule : "POUYDRAGUIN", cp : "32290" },
{ code : "32326", intitule : "POUYLEBON", cp : "32320" },
{ code : "32327", intitule : "POUY LOUBRIN", cp : "32260" },
{ code : "32328", intitule : "POUY ROQUELAURE", cp : "32480" },
{ code : "32329", intitule : "PRECHAC", cp : "32390" },
{ code : "32330", intitule : "PRECHAC SUR ADOUR", cp : "32160" },
{ code : "32331", intitule : "PREIGNAN", cp : "32810" },
{ code : "32332", intitule : "PRENERON", cp : "32190" },
{ code : "32333", intitule : "PROJAN", cp : "32400" },
{ code : "32334", intitule : "PUJAUDRAN", cp : "32600" },
{ code : "32335", intitule : "PUYCASQUIER", cp : "32120" },
{ code : "32336", intitule : "PUYLAUSIC", cp : "32220" },
{ code : "32337", intitule : "PUYSEGUR", cp : "32390" },
{ code : "32338", intitule : "RAMOUZENS", cp : "32800" },
{ code : "32339", intitule : "RAZENGUES", cp : "32600" },
{ code : "32340", intitule : "REANS", cp : "32800" },
{ code : "32341", intitule : "REJAUMONT", cp : "32390" },
{ code : "32342", intitule : "RICOURT", cp : "32230" },
{ code : "32343", intitule : "RIGUEPEU", cp : "32320" },
{ code : "32344", intitule : "RISCLE", cp : "32400" },
{ code : "32345", intitule : "LA ROMIEU", cp : "32480" },
{ code : "32346", intitule : "ROQUEBRUNE", cp : "32190" },
{ code : "32347", intitule : "ROQUEFORT", cp : "32390" },
{ code : "32348", intitule : "ROQUELAURE", cp : "32810" },
{ code : "32349", intitule : "ROQUELAURE ST AUBIN", cp : "32430" },
{ code : "32350", intitule : "ROQUEPINE", cp : "32100" },
{ code : "32351", intitule : "ROQUES", cp : "32310" },
{ code : "32352", intitule : "ROZES", cp : "32190" },
{ code : "32353", intitule : "SABAILLAN", cp : "32420" },
{ code : "32354", intitule : "SABAZAN", cp : "32290" },
{ code : "32355", intitule : "SADEILLAN", cp : "32170" },
{ code : "32356", intitule : "ST ANDRE", cp : "32200" },
{ code : "32357", intitule : "STE ANNE", cp : "32430" },
{ code : "32358", intitule : "ST ANTOINE", cp : "32340" },
{ code : "32359", intitule : "ST ANTONIN", cp : "32120" },
{ code : "32360", intitule : "ST ARAILLES", cp : "32350" },
{ code : "32361", intitule : "ST ARROMAN", cp : "32300" },
{ code : "32362", intitule : "ST AUNIX LENGROS", cp : "32160" },
{ code : "32363", intitule : "STE AURENCE CAZAUX", cp : "32300" },
{ code : "32364", intitule : "ST AVIT FRANDAT", cp : "32700" },
{ code : "32365", intitule : "ST BLANCARD", cp : "32140" },
{ code : "32366", intitule : "ST BRES", cp : "32120" },
{ code : "32367", intitule : "ST CHRISTAUD", cp : "32320" },
{ code : "32368", intitule : "STE CHRISTIE", cp : "32390" },
{ code : "32369", intitule : "STE CHRISTIE D ARMAGNAC", cp : "32370" },
{ code : "32370", intitule : "ST CLAR", cp : "32380" },
{ code : "32371", intitule : "ST CREAC", cp : "32380" },
{ code : "32372", intitule : "ST CRICQ", cp : "32430" },
{ code : "32373", intitule : "STE DODE", cp : "32170" },
{ code : "32374", intitule : "ST ELIX", cp : "32450" },
{ code : "32375", intitule : "ST ELIX THEUX", cp : "32300" },
{ code : "32376", intitule : "STE GEMME", cp : "32120" },
{ code : "32377", intitule : "ST GEORGES", cp : "32430" },
{ code : "32378", intitule : "ST GERME", cp : "32400" },
{ code : "32379", intitule : "ST GERMIER", cp : "32200" },
{ code : "32380", intitule : "ST GRIEDE", cp : "32110" },
{ code : "32381", intitule : "ST JEAN LE COMTAL", cp : "32550" },
{ code : "32382", intitule : "ST JEAN POUTGE", cp : "32190" },
{ code : "32383", intitule : "ST JUSTIN", cp : "32230" },
{ code : "32384", intitule : "ST LARY", cp : "32360" },
{ code : "32385", intitule : "ST LEONARD", cp : "32380" },
{ code : "32386", intitule : "ST LIZIER DU PLANTE", cp : "32220" },
{ code : "32387", intitule : "ST LOUBE", cp : "32220" },
{ code : "32388", intitule : "STE MARIE", cp : "32200" },
{ code : "32389", intitule : "ST MARTIN", cp : "32300" },
{ code : "32390", intitule : "ST MARTIN D ARMAGNAC", cp : "32110" },
{ code : "32391", intitule : "ST MARTIN DE GOYNE", cp : "32480" },
{ code : "32392", intitule : "ST MARTIN GIMOIS", cp : "32450" },
{ code : "32393", intitule : "ST MAUR", cp : "32300" },
{ code : "32394", intitule : "ST MEDARD", cp : "32300" },
{ code : "32395", intitule : "STE MERE", cp : "32700" },
{ code : "32396", intitule : "ST MEZARD", cp : "32700" },
{ code : "32397", intitule : "ST MICHEL", cp : "32300" },
{ code : "32398", intitule : "ST MONT", cp : "32400" },
{ code : "32399", intitule : "ST ORENS", cp : "32120" },
{ code : "32400", intitule : "ST ORENS POUY PETIT", cp : "32100" },
{ code : "32401", intitule : "ST OST", cp : "32300" },
{ code : "32402", intitule : "ST PAUL DE BAISE", cp : "32190" },
{ code : "32403", intitule : "ST PIERRE D AUBEZIES", cp : "32290" },
{ code : "32404", intitule : "ST PUY", cp : "32310" },
{ code : "32405", intitule : "STE RADEGONDE", cp : "32500" },
{ code : "32406", intitule : "ST SAUVY", cp : "32270" },
{ code : "32407", intitule : "ST SOULAN", cp : "32220" },
{ code : "32408", intitule : "SALLES D ARMAGNAC", cp : "32370" },
{ code : "32409", intitule : "SAMARAN", cp : "32140" },
{ code : "32410", intitule : "SAMATAN", cp : "32130" },
{ code : "32411", intitule : "SANSAN", cp : "32260" },
{ code : "32412", intitule : "SARAMON", cp : "32450" },
{ code : "32413", intitule : "SARCOS", cp : "32420" },
{ code : "32414", intitule : "SARRAGACHIES", cp : "32400" },
{ code : "32415", intitule : "SARRAGUZAN", cp : "32170" },
{ code : "32416", intitule : "SARRANT", cp : "32120" },
{ code : "32417", intitule : "LA SAUVETAT", cp : "32500" },
{ code : "32418", intitule : "SAUVETERRE", cp : "32220" },
{ code : "32419", intitule : "SAUVIAC", cp : "32300" },
{ code : "32420", intitule : "SAUVIMONT", cp : "32220" },
{ code : "32421", intitule : "SAVIGNAC MONA", cp : "32130" },
{ code : "32422", intitule : "SCIEURAC ET FLOURES", cp : "32230" },
{ code : "32423", intitule : "SEAILLES", cp : "32190" },
{ code : "32424", intitule : "SEGOS", cp : "32400" },
{ code : "32425", intitule : "SEGOUFIELLE", cp : "32600" },
{ code : "32426", intitule : "SEISSAN", cp : "32260" },
{ code : "32426", intitule : "SEISSAN", cp : "32260" },
{ code : "32427", intitule : "SEMBOUES", cp : "32230" },
{ code : "32428", intitule : "SEMEZIES CACHAN", cp : "32450" },
{ code : "32429", intitule : "SEMPESSERRE", cp : "32700" },
{ code : "32430", intitule : "SERE", cp : "32140" },
{ code : "32431", intitule : "SEREMPUY", cp : "32120" },
{ code : "32432", intitule : "SEYSSES SAVES", cp : "32130" },
{ code : "32433", intitule : "SIMORRE", cp : "32420" },
{ code : "32434", intitule : "SION", cp : "32110" },
{ code : "32435", intitule : "SIRAC", cp : "32430" },
{ code : "32436", intitule : "SOLOMIAC", cp : "32120" },
{ code : "32437", intitule : "SORBETS", cp : "32110" },
{ code : "32438", intitule : "TACHOIRES", cp : "32260" },
{ code : "32439", intitule : "TARSAC", cp : "32400" },
{ code : "32440", intitule : "TASQUE", cp : "32160" },
{ code : "32441", intitule : "TAYBOSC", cp : "32120" },
{ code : "32442", intitule : "TERRAUBE", cp : "32700" },
{ code : "32443", intitule : "TERMES D ARMAGNAC", cp : "32400" },
{ code : "32444", intitule : "THOUX", cp : "32430" },
{ code : "32445", intitule : "TIESTE URAGNOUX", cp : "32160" },
{ code : "32446", intitule : "TILLAC", cp : "32170" },
{ code : "32447", intitule : "TIRENT PONTEJAC", cp : "32450" },
{ code : "32448", intitule : "TOUGET", cp : "32430" },
{ code : "32449", intitule : "TOUJOUSE", cp : "32240" },
{ code : "32450", intitule : "TOURDUN", cp : "32230" },
{ code : "32451", intitule : "TOURNAN", cp : "32420" },
{ code : "32452", intitule : "TOURNECOUPE", cp : "32380" },
{ code : "32453", intitule : "TOURRENQUETS", cp : "32390" },
{ code : "32454", intitule : "TRAVERSERES", cp : "32450" },
{ code : "32455", intitule : "TRONCENS", cp : "32230" },
{ code : "32456", intitule : "TUDELLE", cp : "32190" },
{ code : "32457", intitule : "URDENS", cp : "32500" },
{ code : "32458", intitule : "URGOSSE", cp : "32110" },
{ code : "32459", intitule : "VALENCE SUR BAISE", cp : "32310" },
{ code : "32460", intitule : "VERGOIGNAN", cp : "32720" },
{ code : "32461", intitule : "VERLUS", cp : "32400" },
{ code : "32462", intitule : "VIC FEZENSAC", cp : "32190" },
{ code : "32462", intitule : "VIC FEZENSAC", cp : "32190" },
{ code : "32463", intitule : "VIELLA", cp : "32400" },
{ code : "32464", intitule : "VILLECOMTAL SUR ARROS", cp : "32730" },
{ code : "32465", intitule : "VILLEFRANCHE", cp : "32420" },
{ code : "32466", intitule : "VIOZAN", cp : "32300" },
{ code : "32467", intitule : "ST CAPRAIS", cp : "32200" },
{ code : "32468", intitule : "AUSSOS", cp : "32140" },
{ code : "33001", intitule : "ABZAC", cp : "33230" },
{ code : "33002", intitule : "AILLAS", cp : "33124" },
{ code : "33003", intitule : "AMBARES ET LAGRAVE", cp : "33440" },
{ code : "33004", intitule : "AMBES", cp : "33810" },
{ code : "33005", intitule : "ANDERNOS LES BAINS", cp : "33510" },
{ code : "33006", intitule : "ANGLADE", cp : "33390" },
{ code : "33007", intitule : "ARBANATS", cp : "33640" },
{ code : "33008", intitule : "ARBIS", cp : "33760" },
{ code : "33009", intitule : "ARCACHON", cp : "33120" },
{ code : "33010", intitule : "ARCINS", cp : "33460" },
{ code : "33011", intitule : "ARES", cp : "33740" },
{ code : "33012", intitule : "ARSAC", cp : "33460" },
{ code : "33013", intitule : "ARTIGUES PRES BORDEAUX", cp : "33370" },
{ code : "33014", intitule : "LES ARTIGUES DE LUSSAC", cp : "33570" },
{ code : "33015", intitule : "ARVEYRES", cp : "33500" },
{ code : "33016", intitule : "ASQUES", cp : "33240" },
{ code : "33017", intitule : "AUBIAC", cp : "33430" },
{ code : "33018", intitule : "VAL DE VIRVEE", cp : "33240" },
{ code : "33018", intitule : "VAL DE VIRVEE", cp : "33240" },
{ code : "33018", intitule : "VAL DE VIRVEE", cp : "33240" },
{ code : "33019", intitule : "AUDENGE", cp : "33980" },
{ code : "33020", intitule : "AURIOLLES", cp : "33790" },
{ code : "33021", intitule : "AUROS", cp : "33124" },
{ code : "33022", intitule : "AVENSAN", cp : "33480" },
{ code : "33023", intitule : "AYGUEMORTE LES GRAVES", cp : "33640" },
{ code : "33024", intitule : "BAGAS", cp : "33190" },
{ code : "33025", intitule : "BAIGNEAUX", cp : "33760" },
{ code : "33026", intitule : "BALIZAC", cp : "33730" },
{ code : "33027", intitule : "BARIE", cp : "33190" },
{ code : "33028", intitule : "BARON", cp : "33750" },
{ code : "33029", intitule : "LE BARP", cp : "33114" },
{ code : "33030", intitule : "BARSAC", cp : "33720" },
{ code : "33031", intitule : "BASSANNE", cp : "33190" },
{ code : "33032", intitule : "BASSENS", cp : "33530" },
{ code : "33033", intitule : "BAURECH", cp : "33880" },
{ code : "33034", intitule : "BAYAS", cp : "33230" },
{ code : "33035", intitule : "BAYON SUR GIRONDE", cp : "33710" },
{ code : "33036", intitule : "BAZAS", cp : "33430" },
{ code : "33037", intitule : "BEAUTIRAN", cp : "33640" },
{ code : "33038", intitule : "BEGADAN", cp : "33340" },
{ code : "33039", intitule : "BEGLES", cp : "33130" },
{ code : "33040", intitule : "BEGUEY", cp : "33410" },
{ code : "33042", intitule : "BELIN BELIET", cp : "33830" },
{ code : "33042", intitule : "BELIN BELIET", cp : "33830" },
{ code : "33043", intitule : "BELLEBAT", cp : "33760" },
{ code : "33044", intitule : "BELLEFOND", cp : "33760" },
{ code : "33045", intitule : "BELVES DE CASTILLON", cp : "33350" },
{ code : "33046", intitule : "BERNOS BEAULAC", cp : "33430" },
{ code : "33047", intitule : "BERSON", cp : "33390" },
{ code : "33048", intitule : "BERTHEZ", cp : "33124" },
{ code : "33049", intitule : "BEYCHAC ET CAILLAU", cp : "33750" },
{ code : "33050", intitule : "BIEUJAC", cp : "33210" },
{ code : "33051", intitule : "BIGANOS", cp : "33380" },
{ code : "33051", intitule : "BIGANOS", cp : "33380" },
{ code : "33052", intitule : "LES BILLAUX", cp : "33500" },
{ code : "33053", intitule : "BIRAC", cp : "33430" },
{ code : "33054", intitule : "BLAIGNAC", cp : "33190" },
{ code : "33055", intitule : "BLAIGNAN", cp : "33340" },
{ code : "33056", intitule : "BLANQUEFORT", cp : "33290" },
{ code : "33057", intitule : "BLASIMON", cp : "33540" },
{ code : "33058", intitule : "BLAYE", cp : "33390" },
{ code : "33059", intitule : "BLESIGNAC", cp : "33670" },
{ code : "33060", intitule : "BOMMES", cp : "33210" },
{ code : "33061", intitule : "BONNETAN", cp : "33370" },
{ code : "33062", intitule : "BONZAC", cp : "33910" },
{ code : "33063", intitule : "BORDEAUX", cp : "33000" },
{ code : "33063", intitule : "BORDEAUX", cp : "33300" },
{ code : "33063", intitule : "BORDEAUX", cp : "33800" },
{ code : "33063", intitule : "BORDEAUX", cp : "33100" },
{ code : "33063", intitule : "BORDEAUX", cp : "33200" },
{ code : "33064", intitule : "BOSSUGAN", cp : "33350" },
{ code : "33065", intitule : "BOULIAC", cp : "33270" },
{ code : "33066", intitule : "BOURDELLES", cp : "33190" },
{ code : "33067", intitule : "BOURG", cp : "33710" },
{ code : "33068", intitule : "BOURIDEYS", cp : "33113" },
{ code : "33069", intitule : "LE BOUSCAT", cp : "33110" },
{ code : "33070", intitule : "BRACH", cp : "33480" },
{ code : "33071", intitule : "BRANNE", cp : "33420" },
{ code : "33072", intitule : "BRANNENS", cp : "33124" },
{ code : "33073", intitule : "BRAUD ET ST LOUIS", cp : "33820" },
{ code : "33074", intitule : "BROUQUEYRAN", cp : "33124" },
{ code : "33075", intitule : "BRUGES", cp : "33520" },
{ code : "33076", intitule : "BUDOS", cp : "33720" },
{ code : "33077", intitule : "CABANAC ET VILLAGRAINS", cp : "33650" },
{ code : "33078", intitule : "CABARA", cp : "33420" },
{ code : "33079", intitule : "CADARSAC", cp : "33750" },
{ code : "33080", intitule : "CADAUJAC", cp : "33140" },
{ code : "33081", intitule : "CADILLAC", cp : "33410" },
{ code : "33082", intitule : "CADILLAC EN FRONSADAIS", cp : "33240" },
{ code : "33083", intitule : "CAMARSAC", cp : "33750" },
{ code : "33084", intitule : "CAMBES", cp : "33880" },
{ code : "33085", intitule : "CAMBLANES ET MEYNAC", cp : "33360" },
{ code : "33086", intitule : "CAMIAC ET ST DENIS", cp : "33420" },
{ code : "33087", intitule : "CAMIRAN", cp : "33190" },
{ code : "33088", intitule : "CAMPS SUR L ISLE", cp : "33660" },
{ code : "33089", intitule : "CAMPUGNAN", cp : "33390" },
{ code : "33090", intitule : "CANEJAN", cp : "33610" },
{ code : "33091", intitule : "CANTENAC", cp : "33460" },
{ code : "33092", intitule : "CANTOIS", cp : "33760" },
{ code : "33093", intitule : "CAPIAN", cp : "33550" },
{ code : "33094", intitule : "CAPLONG", cp : "33220" },
{ code : "33095", intitule : "CAPTIEUX", cp : "33840" },
{ code : "33096", intitule : "CARBON BLANC", cp : "33560" },
{ code : "33097", intitule : "CARCANS", cp : "33121" },
{ code : "33097", intitule : "CARCANS", cp : "33121" },
{ code : "33098", intitule : "CARDAN", cp : "33410" },
{ code : "33099", intitule : "CARIGNAN DE BORDEAUX", cp : "33360" },
{ code : "33100", intitule : "CARS", cp : "33390" },
{ code : "33101", intitule : "CARTELEGUE", cp : "33390" },
{ code : "33102", intitule : "CASSEUIL", cp : "33190" },
{ code : "33103", intitule : "CASTELMORON D ALBRET", cp : "33540" },
{ code : "33104", intitule : "CASTELNAU DE MEDOC", cp : "33480" },
{ code : "33105", intitule : "CASTELVIEL", cp : "33540" },
{ code : "33106", intitule : "CASTETS EN DORTHE", cp : "33210" },
{ code : "33107", intitule : "CASTILLON DE CASTETS", cp : "33210" },
{ code : "33108", intitule : "CASTILLON LA BATAILLE", cp : "33350" },
{ code : "33109", intitule : "CASTRES GIRONDE", cp : "33640" },
{ code : "33111", intitule : "CAUDROT", cp : "33490" },
{ code : "33112", intitule : "CAUMONT", cp : "33540" },
{ code : "33113", intitule : "CAUVIGNAC", cp : "33690" },
{ code : "33114", intitule : "CAVIGNAC", cp : "33620" },
{ code : "33115", intitule : "CAZALIS", cp : "33113" },
{ code : "33116", intitule : "CAZATS", cp : "33430" },
{ code : "33117", intitule : "CAZAUGITAT", cp : "33790" },
{ code : "33118", intitule : "CENAC", cp : "33360" },
{ code : "33119", intitule : "CENON", cp : "33150" },
{ code : "33120", intitule : "CERONS", cp : "33720" },
{ code : "33121", intitule : "CESSAC", cp : "33760" },
{ code : "33122", intitule : "CESTAS", cp : "33610" },
{ code : "33122", intitule : "CESTAS", cp : "33610" },
{ code : "33123", intitule : "CEZAC", cp : "33620" },
{ code : "33124", intitule : "CHAMADELLE", cp : "33230" },
{ code : "33125", intitule : "CISSAC MEDOC", cp : "33250" },
{ code : "33126", intitule : "CIVRAC DE BLAYE", cp : "33920" },
{ code : "33127", intitule : "CIVRAC SUR DORDOGNE", cp : "33350" },
{ code : "33128", intitule : "CIVRAC EN MEDOC", cp : "33340" },
{ code : "33129", intitule : "CLEYRAC", cp : "33540" },
{ code : "33130", intitule : "COIMERES", cp : "33210" },
{ code : "33131", intitule : "COIRAC", cp : "33540" },
{ code : "33132", intitule : "COMPS", cp : "33710" },
{ code : "33133", intitule : "COUBEYRAC", cp : "33890" },
{ code : "33134", intitule : "COUQUEQUES", cp : "33340" },
{ code : "33135", intitule : "COURPIAC", cp : "33760" },
{ code : "33136", intitule : "COURS DE MONSEGUR", cp : "33580" },
{ code : "33137", intitule : "COURS LES BAINS", cp : "33690" },
{ code : "33138", intitule : "COUTRAS", cp : "33230" },
{ code : "33139", intitule : "COUTURES", cp : "33580" },
{ code : "33140", intitule : "CREON", cp : "33670" },
{ code : "33141", intitule : "CROIGNON", cp : "33750" },
{ code : "33142", intitule : "CUBNEZAIS", cp : "33620" },
{ code : "33143", intitule : "CUBZAC LES PONTS", cp : "33240" },
{ code : "33144", intitule : "CUDOS", cp : "33430" },
{ code : "33145", intitule : "CURSAN", cp : "33670" },
{ code : "33146", intitule : "CUSSAC FORT MEDOC", cp : "33460" },
{ code : "33147", intitule : "DAIGNAC", cp : "33420" },
{ code : "33148", intitule : "DARDENAC", cp : "33420" },
{ code : "33149", intitule : "DAUBEZE", cp : "33540" },
{ code : "33150", intitule : "DIEULIVOL", cp : "33580" },
{ code : "33151", intitule : "DONNEZAC", cp : "33860" },
{ code : "33152", intitule : "DONZAC", cp : "33410" },
{ code : "33153", intitule : "DOULEZON", cp : "33350" },
{ code : "33154", intitule : "LES EGLISOTTES ET CHALAURES", cp : "33230" },
{ code : "33155", intitule : "ESCAUDES", cp : "33840" },
{ code : "33156", intitule : "ESCOUSSANS", cp : "33760" },
{ code : "33157", intitule : "ESPIET", cp : "33420" },
{ code : "33158", intitule : "LES ESSEINTES", cp : "33190" },
{ code : "33159", intitule : "ETAULIERS", cp : "33820" },
{ code : "33160", intitule : "EYNESSE", cp : "33220" },
{ code : "33161", intitule : "EYRANS", cp : "33390" },
{ code : "33162", intitule : "EYSINES", cp : "33320" },
{ code : "33163", intitule : "FALEYRAS", cp : "33760" },
{ code : "33164", intitule : "FARGUES", cp : "33210" },
{ code : "33165", intitule : "FARGUES ST HILAIRE", cp : "33370" },
{ code : "33166", intitule : "LE FIEU", cp : "33230" },
{ code : "33167", intitule : "FLOIRAC", cp : "33270" },
{ code : "33168", intitule : "FLAUJAGUES", cp : "33350" },
{ code : "33169", intitule : "FLOUDES", cp : "33190" },
{ code : "33170", intitule : "FONTET", cp : "33190" },
{ code : "33171", intitule : "FOSSES ET BALEYSSAC", cp : "33190" },
{ code : "33172", intitule : "FOURS", cp : "33390" },
{ code : "33173", intitule : "FRANCS", cp : "33570" },
{ code : "33174", intitule : "FRONSAC", cp : "33126" },
{ code : "33175", intitule : "FRONTENAC", cp : "33760" },
{ code : "33176", intitule : "GABARNAC", cp : "33410" },
{ code : "33177", intitule : "GAILLAN EN MEDOC", cp : "33340" },
{ code : "33178", intitule : "GAJAC", cp : "33430" },
{ code : "33179", intitule : "GALGON", cp : "33133" },
{ code : "33180", intitule : "GANS", cp : "33430" },
{ code : "33181", intitule : "GARDEGAN ET TOURTIRAC", cp : "33350" },
{ code : "33182", intitule : "GAURIAC", cp : "33710" },
{ code : "33183", intitule : "GAURIAGUET", cp : "33240" },
{ code : "33184", intitule : "GENERAC", cp : "33920" },
{ code : "33185", intitule : "GENISSAC", cp : "33420" },
{ code : "33186", intitule : "GENSAC", cp : "33890" },
{ code : "33187", intitule : "GIRONDE SUR DROPT", cp : "33190" },
{ code : "33188", intitule : "GISCOS", cp : "33840" },
{ code : "33189", intitule : "GORNAC", cp : "33540" },
{ code : "33190", intitule : "GOUALADE", cp : "33840" },
{ code : "33191", intitule : "GOURS", cp : "33660" },
{ code : "33192", intitule : "GRADIGNAN", cp : "33170" },
{ code : "33193", intitule : "GRAYAN ET L HOPITAL", cp : "33590" },
{ code : "33194", intitule : "GREZILLAC", cp : "33420" },
{ code : "33195", intitule : "GRIGNOLS", cp : "33690" },
{ code : "33196", intitule : "GUILLAC", cp : "33420" },
{ code : "33197", intitule : "GUILLOS", cp : "33720" },
{ code : "33198", intitule : "GUITRES", cp : "33230" },
{ code : "33199", intitule : "GUJAN MESTRAS", cp : "33470" },
{ code : "33200", intitule : "LE HAILLAN", cp : "33185" },
{ code : "33201", intitule : "HAUX", cp : "33550" },
{ code : "33202", intitule : "HOSTENS", cp : "33125" },
{ code : "33203", intitule : "HOURTIN", cp : "33990" },
{ code : "33204", intitule : "HURE", cp : "33190" },
{ code : "33205", intitule : "ILLATS", cp : "33720" },
{ code : "33206", intitule : "ISLE ST GEORGES", cp : "33640" },
{ code : "33207", intitule : "IZON", cp : "33450" },
{ code : "33208", intitule : "JAU DIGNAC ET LOIRAC", cp : "33590" },
{ code : "33209", intitule : "JUGAZAN", cp : "33420" },
{ code : "33210", intitule : "JUILLAC", cp : "33890" },
{ code : "33211", intitule : "LABARDE", cp : "33460" },
{ code : "33212", intitule : "LABESCAU", cp : "33690" },
{ code : "33213", intitule : "LA BREDE", cp : "33650" },
{ code : "33214", intitule : "LACANAU", cp : "33680" },
{ code : "33214", intitule : "LACANAU", cp : "33680" },
{ code : "33215", intitule : "LADAUX", cp : "33760" },
{ code : "33216", intitule : "LADOS", cp : "33124" },
{ code : "33218", intitule : "LAGORCE", cp : "33230" },
{ code : "33219", intitule : "LA LANDE DE FRONSAC", cp : "33240" },
{ code : "33220", intitule : "LAMARQUE", cp : "33460" },
{ code : "33221", intitule : "LAMOTHE LANDERRON", cp : "33190" },
{ code : "33222", intitule : "LALANDE DE POMEROL", cp : "33500" },
{ code : "33223", intitule : "LANDERROUAT", cp : "33790" },
{ code : "33224", intitule : "LANDERROUET SUR SEGUR", cp : "33540" },
{ code : "33225", intitule : "LANDIRAS", cp : "33720" },
{ code : "33226", intitule : "LANGOIRAN", cp : "33550" },
{ code : "33227", intitule : "LANGON", cp : "33210" },
{ code : "33228", intitule : "LANSAC", cp : "33710" },
{ code : "33229", intitule : "LANTON", cp : "33138" },
{ code : "33229", intitule : "LANTON", cp : "33138" },
{ code : "33230", intitule : "LAPOUYADE", cp : "33620" },
{ code : "33231", intitule : "LAROQUE", cp : "33410" },
{ code : "33232", intitule : "LARTIGUE", cp : "33840" },
{ code : "33233", intitule : "LARUSCADE", cp : "33620" },
{ code : "33234", intitule : "LATRESNE", cp : "33360" },
{ code : "33235", intitule : "LAVAZAN", cp : "33690" },
{ code : "33236", intitule : "LEGE CAP FERRET", cp : "33970" },
{ code : "33236", intitule : "LEGE CAP FERRET", cp : "33950" },
{ code : "33236", intitule : "LEGE CAP FERRET", cp : "33970" },
{ code : "33236", intitule : "LEGE CAP FERRET", cp : "33950" },
{ code : "33237", intitule : "LEOGEATS", cp : "33210" },
{ code : "33238", intitule : "LEOGNAN", cp : "33850" },
{ code : "33239", intitule : "LERM ET MUSSET", cp : "33840" },
{ code : "33240", intitule : "LESPARRE MEDOC", cp : "33340" },
{ code : "33241", intitule : "LESTIAC SUR GARONNE", cp : "33550" },
{ code : "33242", intitule : "LES LEVES ET THOUMEYRAGUES", cp : "33220" },
{ code : "33243", intitule : "LIBOURNE", cp : "33500" },
{ code : "33244", intitule : "LIGNAN DE BAZAS", cp : "33430" },
{ code : "33245", intitule : "LIGNAN DE BORDEAUX", cp : "33360" },
{ code : "33246", intitule : "LIGUEUX", cp : "33220" },
{ code : "33247", intitule : "LISTRAC DE DUREZE", cp : "33790" },
{ code : "33248", intitule : "LISTRAC MEDOC", cp : "33480" },
{ code : "33249", intitule : "LORMONT", cp : "33310" },
{ code : "33250", intitule : "LOUBENS", cp : "33190" },
{ code : "33251", intitule : "LOUCHATS", cp : "33125" },
{ code : "33252", intitule : "LOUPES", cp : "33370" },
{ code : "33253", intitule : "LOUPIAC", cp : "33410" },
{ code : "33254", intitule : "LOUPIAC DE LA REOLE", cp : "33190" },
{ code : "33255", intitule : "LUCMAU", cp : "33840" },
{ code : "33256", intitule : "LUDON MEDOC", cp : "33290" },
{ code : "33257", intitule : "LUGAIGNAC", cp : "33420" },
{ code : "33258", intitule : "LUGASSON", cp : "33760" },
{ code : "33259", intitule : "LUGON ET L ILE DU CARNAY", cp : "33240" },
{ code : "33260", intitule : "LUGOS", cp : "33830" },
{ code : "33261", intitule : "LUSSAC", cp : "33570" },
{ code : "33262", intitule : "MACAU", cp : "33460" },
{ code : "33263", intitule : "MADIRAC", cp : "33670" },
{ code : "33264", intitule : "MARANSIN", cp : "33230" },
{ code : "33266", intitule : "MARCENAIS", cp : "33620" },
{ code : "33267", intitule : "MARCILLAC", cp : "33860" },
{ code : "33268", intitule : "MARGAUX", cp : "33460" },
{ code : "33269", intitule : "MARGUERON", cp : "33220" },
{ code : "33270", intitule : "MARIMBAULT", cp : "33430" },
{ code : "33271", intitule : "MARIONS", cp : "33690" },
{ code : "33272", intitule : "MARSAS", cp : "33620" },
{ code : "33273", intitule : "MARTIGNAS SUR JALLE", cp : "33127" },
{ code : "33274", intitule : "MARTILLAC", cp : "33650" },
{ code : "33275", intitule : "MARTRES", cp : "33760" },
{ code : "33276", intitule : "MASSEILLES", cp : "33690" },
{ code : "33277", intitule : "MASSUGAS", cp : "33790" },
{ code : "33278", intitule : "MAURIAC", cp : "33540" },
{ code : "33279", intitule : "MAZERES", cp : "33210" },
{ code : "33280", intitule : "MAZION", cp : "33390" },
{ code : "33281", intitule : "MERIGNAC", cp : "33700" },
{ code : "33282", intitule : "MERIGNAS", cp : "33350" },
{ code : "33283", intitule : "MESTERRIEUX", cp : "33540" },
{ code : "33284", intitule : "MIOS", cp : "33380" },
{ code : "33284", intitule : "MIOS", cp : "33380" },
{ code : "33285", intitule : "MOMBRIER", cp : "33710" },
{ code : "33287", intitule : "MONGAUZY", cp : "33190" },
{ code : "33288", intitule : "MONPRIMBLANC", cp : "33410" },
{ code : "33289", intitule : "MONSEGUR", cp : "33580" },
{ code : "33290", intitule : "MONTAGNE", cp : "33570" },
{ code : "33290", intitule : "MONTAGNE", cp : "33570" },
{ code : "33290", intitule : "MONTAGNE", cp : "33570" },
{ code : "33291", intitule : "MONTAGOUDIN", cp : "33190" },
{ code : "33292", intitule : "MONTIGNAC", cp : "33760" },
{ code : "33293", intitule : "MONTUSSAN", cp : "33450" },
{ code : "33294", intitule : "MORIZES", cp : "33190" },
{ code : "33295", intitule : "MOUILLAC", cp : "33240" },
{ code : "33296", intitule : "MOULIETS ET VILLEMARTIN", cp : "33350" },
{ code : "33297", intitule : "MOULIS EN MEDOC", cp : "33480" },
{ code : "33298", intitule : "MOULON", cp : "33420" },
{ code : "33299", intitule : "MOURENS", cp : "33410" },
{ code : "33300", intitule : "NAUJAC SUR MER", cp : "33990" },
{ code : "33301", intitule : "NAUJAN ET POSTIAC", cp : "33420" },
{ code : "33302", intitule : "NEAC", cp : "33500" },
{ code : "33303", intitule : "NERIGEAN", cp : "33750" },
{ code : "33304", intitule : "NEUFFONS", cp : "33580" },
{ code : "33305", intitule : "LE NIZAN", cp : "33430" },
{ code : "33306", intitule : "NOAILLAC", cp : "33190" },
{ code : "33307", intitule : "NOAILLAN", cp : "33730" },
{ code : "33308", intitule : "OMET", cp : "33410" },
{ code : "33309", intitule : "ORDONNAC", cp : "33340" },
{ code : "33310", intitule : "ORIGNE", cp : "33113" },
{ code : "33311", intitule : "PAILLET", cp : "33550" },
{ code : "33312", intitule : "PAREMPUYRE", cp : "33290" },
{ code : "33314", intitule : "PAUILLAC", cp : "33250" },
{ code : "33315", intitule : "LES PEINTURES", cp : "33230" },
{ code : "33316", intitule : "PELLEGRUE", cp : "33790" },
{ code : "33317", intitule : "PERISSAC", cp : "33240" },
{ code : "33318", intitule : "PESSAC", cp : "33600" },
{ code : "33319", intitule : "PESSAC SUR DORDOGNE", cp : "33890" },
{ code : "33320", intitule : "PETIT PALAIS ET CORNEMPS", cp : "33570" },
{ code : "33321", intitule : "PEUJARD", cp : "33240" },
{ code : "33322", intitule : "LE PIAN MEDOC", cp : "33290" },
{ code : "33323", intitule : "LE PIAN SUR GARONNE", cp : "33490" },
{ code : "33324", intitule : "PINEUILH", cp : "33220" },
{ code : "33325", intitule : "PLASSAC", cp : "33390" },
{ code : "33326", intitule : "PLEINE SELVE", cp : "33820" },
{ code : "33327", intitule : "PODENSAC", cp : "33720" },
{ code : "33328", intitule : "POMEROL", cp : "33500" },
{ code : "33329", intitule : "POMPEJAC", cp : "33730" },
{ code : "33330", intitule : "POMPIGNAC", cp : "33370" },
{ code : "33331", intitule : "PONDAURAT", cp : "33190" },
{ code : "33332", intitule : "PORCHERES", cp : "33660" },
{ code : "33333", intitule : "LE PORGE", cp : "33680" },
{ code : "33334", intitule : "PORTETS", cp : "33640" },
{ code : "33335", intitule : "LE POUT", cp : "33670" },
{ code : "33336", intitule : "PRECHAC", cp : "33730" },
{ code : "33337", intitule : "PREIGNAC", cp : "33210" },
{ code : "33338", intitule : "PRIGNAC EN MEDOC", cp : "33340" },
{ code : "33339", intitule : "PRIGNAC ET MARCAMPS", cp : "33710" },
{ code : "33341", intitule : "PUGNAC", cp : "33710" },
{ code : "33341", intitule : "PUGNAC", cp : "33710" },
{ code : "33342", intitule : "PUISSEGUIN", cp : "33570" },
{ code : "33342", intitule : "PUISSEGUIN", cp : "33570" },
{ code : "33343", intitule : "PUJOLS SUR CIRON", cp : "33210" },
{ code : "33344", intitule : "PUJOLS", cp : "33350" },
{ code : "33345", intitule : "LE PUY", cp : "33580" },
{ code : "33346", intitule : "PUYBARBAN", cp : "33190" },
{ code : "33347", intitule : "PUYNORMAND", cp : "33660" },
{ code : "33348", intitule : "QUEYRAC", cp : "33340" },
{ code : "33349", intitule : "QUINSAC", cp : "33360" },
{ code : "33350", intitule : "RAUZAN", cp : "33420" },
{ code : "33351", intitule : "REIGNAC", cp : "33860" },
{ code : "33352", intitule : "LA REOLE", cp : "33190" },
{ code : "33353", intitule : "RIMONS", cp : "33580" },
{ code : "33354", intitule : "RIOCAUD", cp : "33220" },
{ code : "33355", intitule : "RIONS", cp : "33410" },
{ code : "33356", intitule : "LA RIVIERE", cp : "33126" },
{ code : "33357", intitule : "ROAILLAN", cp : "33210" },
{ code : "33358", intitule : "ROMAGNE", cp : "33760" },
{ code : "33359", intitule : "ROQUEBRUNE", cp : "33580" },
{ code : "33360", intitule : "LA ROQUILLE", cp : "33220" },
{ code : "33361", intitule : "RUCH", cp : "33350" },
{ code : "33362", intitule : "SABLONS", cp : "33910" },
{ code : "33363", intitule : "SADIRAC", cp : "33670" },
{ code : "33364", intitule : "SAILLANS", cp : "33141" },
{ code : "33365", intitule : "ST AIGNAN", cp : "33126" },
{ code : "33366", intitule : "ST ANDRE DE CUBZAC", cp : "33240" },
{ code : "33367", intitule : "ST ANDRE DU BOIS", cp : "33490" },
{ code : "33369", intitule : "ST ANDRE ET APPELLES", cp : "33220" },
{ code : "33370", intitule : "ST ANDRONY", cp : "33390" },
{ code : "33372", intitule : "ST ANTOINE DU QUEYRET", cp : "33790" },
{ code : "33373", intitule : "ST ANTOINE SUR L ISLE", cp : "33660" },
{ code : "33374", intitule : "ST AUBIN DE BLAYE", cp : "33820" },
{ code : "33375", intitule : "ST AUBIN DE BRANNE", cp : "33420" },
{ code : "33376", intitule : "ST AUBIN DE MEDOC", cp : "33160" },
{ code : "33377", intitule : "ST AVIT DE SOULEGE", cp : "33220" },
{ code : "33378", intitule : "ST AVIT ST NAZAIRE", cp : "33220" },
{ code : "33378", intitule : "ST AVIT ST NAZAIRE", cp : "33220" },
{ code : "33379", intitule : "ST BRICE", cp : "33540" },
{ code : "33380", intitule : "ST CAPRAIS DE BLAYE", cp : "33820" },
{ code : "33381", intitule : "ST CAPRAIS DE BORDEAUX", cp : "33880" },
{ code : "33382", intitule : "ST CHRISTOLY DE BLAYE", cp : "33920" },
{ code : "33383", intitule : "ST CHRISTOLY MEDOC", cp : "33340" },
{ code : "33384", intitule : "ST CHRISTOPHE DES BARDES", cp : "33330" },
{ code : "33385", intitule : "ST CHRISTOPHE DE DOUBLE", cp : "33230" },
{ code : "33386", intitule : "ST CIBARD", cp : "33570" },
{ code : "33387", intitule : "ST CIERS D ABZAC", cp : "33910" },
{ code : "33388", intitule : "ST CIERS DE CANESSE", cp : "33710" },
{ code : "33389", intitule : "ST CIERS SUR GIRONDE", cp : "33820" },
{ code : "33390", intitule : "STE COLOMBE", cp : "33350" },
{ code : "33391", intitule : "ST COME", cp : "33430" },
{ code : "33392", intitule : "STE CROIX DU MONT", cp : "33410" },
{ code : "33393", intitule : "ST DENIS DE PILE", cp : "33910" },
{ code : "33394", intitule : "ST EMILION", cp : "33330" },
{ code : "33395", intitule : "ST ESTEPHE", cp : "33180" },
{ code : "33396", intitule : "ST ETIENNE DE LISSE", cp : "33330" },
{ code : "33397", intitule : "STE EULALIE", cp : "33560" },
{ code : "33398", intitule : "ST EXUPERY", cp : "33190" },
{ code : "33399", intitule : "ST FELIX DE FONCAUDE", cp : "33540" },
{ code : "33400", intitule : "ST FERME", cp : "33580" },
{ code : "33401", intitule : "STE FLORENCE", cp : "33350" },
{ code : "33402", intitule : "STE FOY LA GRANDE", cp : "33220" },
{ code : "33403", intitule : "STE FOY LA LONGUE", cp : "33490" },
{ code : "33404", intitule : "STE GEMME", cp : "33580" },
{ code : "33405", intitule : "ST GENES DE BLAYE", cp : "33390" },
{ code : "33406", intitule : "ST GENES DE CASTILLON", cp : "33350" },
{ code : "33407", intitule : "ST GENES DE FRONSAC", cp : "33240" },
{ code : "33408", intitule : "ST GENES DE LOMBAUD", cp : "33670" },
{ code : "33409", intitule : "ST GENIS DU BOIS", cp : "33760" },
{ code : "33411", intitule : "ST GERMAIN DE GRAVE", cp : "33490" },
{ code : "33412", intitule : "ST GERMAIN D ESTEUIL", cp : "33340" },
{ code : "33413", intitule : "ST GERMAIN DU PUCH", cp : "33750" },
{ code : "33414", intitule : "ST GERMAIN DE LA RIVIERE", cp : "33240" },
{ code : "33415", intitule : "ST GERVAIS", cp : "33240" },
{ code : "33416", intitule : "ST GIRONS D AIGUEVIVES", cp : "33920" },
{ code : "33417", intitule : "STE HELENE", cp : "33480" },
{ code : "33418", intitule : "ST HILAIRE DE LA NOAILLE", cp : "33190" },
{ code : "33419", intitule : "ST HILAIRE DU BOIS", cp : "33540" },
{ code : "33420", intitule : "ST HIPPOLYTE", cp : "33330" },
{ code : "33421", intitule : "ST JEAN DE BLAIGNAC", cp : "33420" },
{ code : "33422", intitule : "ST JEAN D ILLAC", cp : "33127" },
{ code : "33423", intitule : "ST JULIEN BEYCHEVELLE", cp : "33250" },
{ code : "33424", intitule : "ST LAURENT MEDOC", cp : "33112" },
{ code : "33425", intitule : "ST LAURENT D ARCE", cp : "33240" },
{ code : "33426", intitule : "ST LAURENT DES COMBES", cp : "33330" },
{ code : "33427", intitule : "ST LAURENT DU BOIS", cp : "33540" },
{ code : "33428", intitule : "ST LAURENT DU PLAN", cp : "33190" },
{ code : "33429", intitule : "ST LEGER DE BALSON", cp : "33113" },
{ code : "33431", intitule : "ST LEON", cp : "33670" },
{ code : "33432", intitule : "ST LOUBERT", cp : "33210" },
{ code : "33433", intitule : "ST LOUBES", cp : "33450" },
{ code : "33434", intitule : "ST LOUIS DE MONTFERRAND", cp : "33440" },
{ code : "33435", intitule : "ST MACAIRE", cp : "33490" },
{ code : "33436", intitule : "ST MAGNE", cp : "33125" },
{ code : "33437", intitule : "ST MAGNE DE CASTILLON", cp : "33350" },
{ code : "33438", intitule : "ST MAIXANT", cp : "33490" },
{ code : "33439", intitule : "ST MARIENS", cp : "33620" },
{ code : "33440", intitule : "ST MARTIAL", cp : "33490" },
{ code : "33441", intitule : "ST MARTIN LACAUSSADE", cp : "33390" },
{ code : "33442", intitule : "ST MARTIN DE LAYE", cp : "33910" },
{ code : "33443", intitule : "ST MARTIN DE LERM", cp : "33540" },
{ code : "33444", intitule : "ST MARTIN DE SESCAS", cp : "33490" },
{ code : "33445", intitule : "ST MARTIN DU BOIS", cp : "33910" },
{ code : "33446", intitule : "ST MARTIN DU PUY", cp : "33540" },
{ code : "33447", intitule : "ST MEDARD DE GUIZIERES", cp : "33230" },
{ code : "33448", intitule : "ST MEDARD D EYRANS", cp : "33650" },
{ code : "33449", intitule : "ST MEDARD EN JALLES", cp : "33160" },
{ code : "33450", intitule : "ST MICHEL DE CASTELNAU", cp : "33840" },
{ code : "33451", intitule : "ST MICHEL DE FRONSAC", cp : "33126" },
{ code : "33452", intitule : "ST MICHEL DE RIEUFRET", cp : "33720" },
{ code : "33453", intitule : "ST MICHEL DE LAPUJADE", cp : "33190" },
{ code : "33454", intitule : "ST MORILLON", cp : "33650" },
{ code : "33456", intitule : "ST PALAIS", cp : "33820" },
{ code : "33457", intitule : "ST PARDON DE CONQUES", cp : "33210" },
{ code : "33458", intitule : "ST PAUL", cp : "33390" },
{ code : "33459", intitule : "ST PEY D ARMENS", cp : "33330" },
{ code : "33460", intitule : "ST PEY DE CASTETS", cp : "33350" },
{ code : "33461", intitule : "ST PHILIPPE D AIGUILLE", cp : "33350" },
{ code : "33462", intitule : "ST PHILIPPE DU SEIGNAL", cp : "33220" },
{ code : "33463", intitule : "ST PIERRE D AURILLAC", cp : "33490" },
{ code : "33464", intitule : "ST PIERRE DE BAT", cp : "33760" },
{ code : "33465", intitule : "ST PIERRE DE MONS", cp : "33210" },
{ code : "33466", intitule : "ST QUENTIN DE BARON", cp : "33750" },
{ code : "33467", intitule : "ST QUENTIN DE CAPLONG", cp : "33220" },
{ code : "33468", intitule : "STE RADEGONDE", cp : "33350" },
{ code : "33470", intitule : "ST ROMAIN LA VIRVEE", cp : "33240" },
{ code : "33471", intitule : "ST SAUVEUR", cp : "33250" },
{ code : "33472", intitule : "ST SAUVEUR DE PUYNORMAND", cp : "33660" },
{ code : "33473", intitule : "ST SAVIN", cp : "33920" },
{ code : "33474", intitule : "ST SELVE", cp : "33650" },
{ code : "33475", intitule : "ST SEURIN DE BOURG", cp : "33710" },
{ code : "33476", intitule : "ST SEURIN DE CADOURNE", cp : "33180" },
{ code : "33477", intitule : "ST SEURIN DE CURSAC", cp : "33390" },
{ code : "33478", intitule : "ST SEURIN SUR L ISLE", cp : "33660" },
{ code : "33479", intitule : "ST SEVE", cp : "33190" },
{ code : "33480", intitule : "ST SULPICE DE FALEYRENS", cp : "33330" },
{ code : "33481", intitule : "ST SULPICE DE GUILLERAGUES", cp : "33580" },
{ code : "33482", intitule : "ST SULPICE DE POMMIERS", cp : "33540" },
{ code : "33483", intitule : "ST SULPICE ET CAMEYRAC", cp : "33450" },
{ code : "33484", intitule : "ST SYMPHORIEN", cp : "33113" },
{ code : "33485", intitule : "STE TERRE", cp : "33350" },
{ code : "33486", intitule : "ST TROJAN", cp : "33710" },
{ code : "33487", intitule : "ST VINCENT DE PAUL", cp : "33440" },
{ code : "33488", intitule : "ST VINCENT DE PERTIGNAS", cp : "33420" },
{ code : "33489", intitule : "ST VIVIEN DE BLAYE", cp : "33920" },
{ code : "33490", intitule : "ST VIVIEN DE MEDOC", cp : "33590" },
{ code : "33491", intitule : "ST VIVIEN DE MONSEGUR", cp : "33580" },
{ code : "33492", intitule : "ST YZAN DE SOUDIAC", cp : "33920" },
{ code : "33493", intitule : "ST YZANS DE MEDOC", cp : "33340" },
{ code : "33494", intitule : "SALAUNES", cp : "33160" },
{ code : "33496", intitule : "SALLEBOEUF", cp : "33370" },
{ code : "33498", intitule : "SALLES", cp : "33770" },
{ code : "33499", intitule : "LES SALLES DE CASTILLON", cp : "33350" },
{ code : "33500", intitule : "SAMONAC", cp : "33710" },
{ code : "33501", intitule : "SAUCATS", cp : "33650" },
{ code : "33502", intitule : "SAUGON", cp : "33920" },
{ code : "33503", intitule : "SAUMOS", cp : "33680" },
{ code : "33504", intitule : "SAUTERNES", cp : "33210" },
{ code : "33505", intitule : "LA SAUVE", cp : "33670" },
{ code : "33506", intitule : "SAUVETERRE DE GUYENNE", cp : "33540" },
{ code : "33507", intitule : "SAUVIAC", cp : "33430" },
{ code : "33508", intitule : "SAVIGNAC", cp : "33124" },
{ code : "33509", intitule : "SAVIGNAC DE L ISLE", cp : "33910" },
{ code : "33510", intitule : "SEMENS", cp : "33490" },
{ code : "33511", intitule : "SENDETS", cp : "33690" },
{ code : "33512", intitule : "SIGALENS", cp : "33690" },
{ code : "33513", intitule : "SILLAS", cp : "33690" },
{ code : "33514", intitule : "SOULAC SUR MER", cp : "33780" },
{ code : "33515", intitule : "SOULIGNAC", cp : "33760" },
{ code : "33516", intitule : "SOUSSAC", cp : "33790" },
{ code : "33517", intitule : "SOUSSANS", cp : "33460" },
{ code : "33518", intitule : "TABANAC", cp : "33550" },
{ code : "33519", intitule : "LE TAILLAN MEDOC", cp : "33320" },
{ code : "33520", intitule : "TAILLECAVAT", cp : "33580" },
{ code : "33521", intitule : "TALAIS", cp : "33590" },
{ code : "33522", intitule : "TALENCE", cp : "33400" },
{ code : "33523", intitule : "TARGON", cp : "33760" },
{ code : "33524", intitule : "TARNES", cp : "33240" },
{ code : "33525", intitule : "TAURIAC", cp : "33710" },
{ code : "33526", intitule : "TAYAC", cp : "33570" },
{ code : "33527", intitule : "LE TEICH", cp : "33470" },
{ code : "33528", intitule : "LE TEMPLE", cp : "33680" },
{ code : "33529", intitule : "LA TESTE DE BUCH", cp : "33115" },
{ code : "33529", intitule : "LA TESTE DE BUCH", cp : "33115" },
{ code : "33529", intitule : "LA TESTE DE BUCH", cp : "33260" },
{ code : "33529", intitule : "LA TESTE DE BUCH", cp : "33260" },
{ code : "33530", intitule : "TEUILLAC", cp : "33710" },
{ code : "33531", intitule : "TIZAC DE CURTON", cp : "33420" },
{ code : "33532", intitule : "TIZAC DE LAPOUYADE", cp : "33620" },
{ code : "33533", intitule : "TOULENNE", cp : "33210" },
{ code : "33534", intitule : "LE TOURNE", cp : "33550" },
{ code : "33535", intitule : "TRESSES", cp : "33370" },
{ code : "33536", intitule : "LE TUZAN", cp : "33125" },
{ code : "33537", intitule : "UZESTE", cp : "33730" },
{ code : "33538", intitule : "VALEYRAC", cp : "33340" },
{ code : "33539", intitule : "VAYRES", cp : "33870" },
{ code : "33540", intitule : "VENDAYS MONTALIVET", cp : "33930" },
{ code : "33541", intitule : "VENSAC", cp : "33590" },
{ code : "33542", intitule : "VERAC", cp : "33240" },
{ code : "33543", intitule : "VERDELAIS", cp : "33490" },
{ code : "33544", intitule : "LE VERDON SUR MER", cp : "33123" },
{ code : "33545", intitule : "VERTHEUIL", cp : "33180" },
{ code : "33546", intitule : "VIGNONET", cp : "33330" },
{ code : "33547", intitule : "VILLANDRAUT", cp : "33730" },
{ code : "33548", intitule : "VILLEGOUGE", cp : "33141" },
{ code : "33549", intitule : "VILLENAVE DE RIONS", cp : "33550" },
{ code : "33550", intitule : "VILLENAVE D ORNON", cp : "33140" },
{ code : "33550", intitule : "VILLENAVE D ORNON", cp : "33140" },
{ code : "33551", intitule : "VILLENEUVE", cp : "33710" },
{ code : "33552", intitule : "VIRELADE", cp : "33720" },
{ code : "33553", intitule : "VIRSAC", cp : "33240" },
{ code : "33554", intitule : "YVRAC", cp : "33370" },
{ code : "33555", intitule : "MARCHEPRIME", cp : "33380" },
{ code : "34001", intitule : "ABEILHAN", cp : "34290" },
{ code : "34002", intitule : "ADISSAN", cp : "34230" },
{ code : "34003", intitule : "AGDE", cp : "34300" },
{ code : "34003", intitule : "AGDE", cp : "34300" },
{ code : "34004", intitule : "AGEL", cp : "34210" },
{ code : "34005", intitule : "AGONES", cp : "34190" },
{ code : "34006", intitule : "AIGNE", cp : "34210" },
{ code : "34007", intitule : "AIGUES VIVES", cp : "34210" },
{ code : "34008", intitule : "LES AIRES", cp : "34600" },
{ code : "34009", intitule : "ALIGNAN DU VENT", cp : "34290" },
{ code : "34010", intitule : "ANIANE", cp : "34150" },
{ code : "34011", intitule : "ARBORAS", cp : "34150" },
{ code : "34012", intitule : "ARGELLIERS", cp : "34380" },
{ code : "34013", intitule : "ASPIRAN", cp : "34800" },
{ code : "34014", intitule : "ASSAS", cp : "34820" },
{ code : "34015", intitule : "ASSIGNAN", cp : "34360" },
{ code : "34016", intitule : "AUMELAS", cp : "34230" },
{ code : "34017", intitule : "AUMES", cp : "34530" },
{ code : "34018", intitule : "AUTIGNAC", cp : "34480" },
{ code : "34019", intitule : "AVENE", cp : "34260" },
{ code : "34020", intitule : "AZILLANET", cp : "34210" },
{ code : "34021", intitule : "BABEAU BOULDOUX", cp : "34360" },
{ code : "34022", intitule : "BAILLARGUES", cp : "34670" },
{ code : "34023", intitule : "BALARUC LES BAINS", cp : "34540" },
{ code : "34024", intitule : "BALARUC LE VIEUX", cp : "34540" },
{ code : "34025", intitule : "BASSAN", cp : "34290" },
{ code : "34026", intitule : "BEAUFORT", cp : "34210" },
{ code : "34027", intitule : "BEAULIEU", cp : "34160" },
{ code : "34028", intitule : "BEDARIEUX", cp : "34600" },
{ code : "34029", intitule : "BELARGA", cp : "34230" },
{ code : "34030", intitule : "BERLOU", cp : "34360" },
{ code : "34031", intitule : "BESSAN", cp : "34550" },
{ code : "34032", intitule : "BEZIERS", cp : "34500" },
{ code : "34033", intitule : "BOISSERON", cp : "34160" },
{ code : "34034", intitule : "BOISSET", cp : "34220" },
{ code : "34035", intitule : "LA BOISSIERE", cp : "34150" },
{ code : "34036", intitule : "LE BOSC", cp : "34700" },
{ code : "34037", intitule : "BOUJAN SUR LIBRON", cp : "34760" },
{ code : "34038", intitule : "LE BOUSQUET D ORB", cp : "34260" },
{ code : "34039", intitule : "BOUZIGUES", cp : "34140" },
{ code : "34040", intitule : "BRENAS", cp : "34650" },
{ code : "34041", intitule : "BRIGNAC", cp : "34800" },
{ code : "34042", intitule : "BRISSAC", cp : "34190" },
{ code : "34043", intitule : "BUZIGNARGUES", cp : "34160" },
{ code : "34044", intitule : "CABREROLLES", cp : "34480" },
{ code : "34045", intitule : "CABRIERES", cp : "34800" },
{ code : "34046", intitule : "CAMBON ET SALVERGUES", cp : "34330" },
{ code : "34047", intitule : "CAMPAGNAN", cp : "34230" },
{ code : "34048", intitule : "CAMPAGNE", cp : "34160" },
{ code : "34049", intitule : "CAMPLONG", cp : "34260" },
{ code : "34050", intitule : "CANDILLARGUES", cp : "34130" },
{ code : "34051", intitule : "CANET", cp : "34800" },
{ code : "34052", intitule : "CAPESTANG", cp : "34310" },
{ code : "34053", intitule : "CARLENCAS ET LEVAS", cp : "34600" },
{ code : "34054", intitule : "CASSAGNOLES", cp : "34210" },
{ code : "34055", intitule : "CASTANET LE HAUT", cp : "34610" },
{ code : "34056", intitule : "CASTELNAU DE GUERS", cp : "34120" },
{ code : "34057", intitule : "CASTELNAU LE LEZ", cp : "34170" },
{ code : "34058", intitule : "CASTRIES", cp : "34160" },
{ code : "34059", intitule : "LA CAUNETTE", cp : "34210" },
{ code : "34060", intitule : "CAUSSE DE LA SELLE", cp : "34380" },
{ code : "34061", intitule : "CAUSSES ET VEYRAN", cp : "34490" },
{ code : "34062", intitule : "CAUSSINIOJOULS", cp : "34600" },
{ code : "34063", intitule : "CAUX", cp : "34720" },
{ code : "34064", intitule : "LE CAYLAR", cp : "34520" },
{ code : "34065", intitule : "CAZEDARNES", cp : "34460" },
{ code : "34066", intitule : "CAZEVIEILLE", cp : "34270" },
{ code : "34067", intitule : "CAZILHAC", cp : "34190" },
{ code : "34068", intitule : "CAZOULS D HERAULT", cp : "34120" },
{ code : "34069", intitule : "CAZOULS LES BEZIERS", cp : "34370" },
{ code : "34070", intitule : "CEBAZAN", cp : "34360" },
{ code : "34071", intitule : "CEILHES ET ROCOZELS", cp : "34260" },
{ code : "34072", intitule : "CELLES", cp : "34700" },
{ code : "34073", intitule : "CERS", cp : "34420" },
{ code : "34074", intitule : "CESSENON SUR ORB", cp : "34460" },
{ code : "34075", intitule : "CESSERAS", cp : "34210" },
{ code : "34076", intitule : "CEYRAS", cp : "34800" },
{ code : "34077", intitule : "CLAPIERS", cp : "34830" },
{ code : "34078", intitule : "CLARET", cp : "34270" },
{ code : "34079", intitule : "CLERMONT L HERAULT", cp : "34800" },
{ code : "34080", intitule : "COLOMBIERES SUR ORB", cp : "34390" },
{ code : "34081", intitule : "COLOMBIERS", cp : "34440" },
{ code : "34082", intitule : "COMBAILLAUX", cp : "34980" },
{ code : "34083", intitule : "COMBES", cp : "34240" },
{ code : "34084", intitule : "CORNEILHAN", cp : "34490" },
{ code : "34085", intitule : "COULOBRES", cp : "34290" },
{ code : "34086", intitule : "COURNIOU", cp : "34220" },
{ code : "34087", intitule : "COURNONSEC", cp : "34660" },
{ code : "34088", intitule : "COURNONTERRAL", cp : "34660" },
{ code : "34089", intitule : "CREISSAN", cp : "34370" },
{ code : "34090", intitule : "LE CRES", cp : "34920" },
{ code : "34091", intitule : "LE CROS", cp : "34520" },
{ code : "34092", intitule : "CRUZY", cp : "34310" },
{ code : "34093", intitule : "DIO ET VALQUIERES", cp : "34650" },
{ code : "34094", intitule : "ESPONDEILHAN", cp : "34290" },
{ code : "34095", intitule : "FABREGUES", cp : "34690" },
{ code : "34096", intitule : "FAUGERES", cp : "34600" },
{ code : "34097", intitule : "FELINES MINERVOIS", cp : "34210" },
{ code : "34098", intitule : "FERRALS LES MONTAGNES", cp : "34210" },
{ code : "34099", intitule : "FERRIERES LES VERRERIES", cp : "34190" },
{ code : "34100", intitule : "FERRIERES POUSSAROU", cp : "34360" },
{ code : "34101", intitule : "FLORENSAC", cp : "34510" },
{ code : "34102", intitule : "FONTANES", cp : "34270" },
{ code : "34103", intitule : "FONTES", cp : "34320" },
{ code : "34104", intitule : "FOS", cp : "34320" },
{ code : "34105", intitule : "FOUZILHON", cp : "34480" },
{ code : "34106", intitule : "FOZIERES", cp : "34700" },
{ code : "34107", intitule : "FRAISSE SUR AGOUT", cp : "34330" },
{ code : "34108", intitule : "FRONTIGNAN", cp : "34110" },
{ code : "34108", intitule : "FRONTIGNAN", cp : "34110" },
{ code : "34109", intitule : "GABIAN", cp : "34320" },
{ code : "34110", intitule : "GALARGUES", cp : "34160" },
{ code : "34111", intitule : "GANGES", cp : "34190" },
{ code : "34112", intitule : "GARRIGUES", cp : "34160" },
{ code : "34113", intitule : "GIGEAN", cp : "34770" },
{ code : "34114", intitule : "GIGNAC", cp : "34150" },
{ code : "34115", intitule : "GORNIES", cp : "34190" },
{ code : "34116", intitule : "GRABELS", cp : "34790" },
{ code : "34117", intitule : "GRAISSESSAC", cp : "34260" },
{ code : "34118", intitule : "GUZARGUES", cp : "34820" },
{ code : "34119", intitule : "HEREPIAN", cp : "34600" },
{ code : "34120", intitule : "JACOU", cp : "34830" },
{ code : "34121", intitule : "JONCELS", cp : "34650" },
{ code : "34122", intitule : "JONQUIERES", cp : "34725" },
{ code : "34123", intitule : "JUVIGNAC", cp : "34990" },
{ code : "34124", intitule : "LACOSTE", cp : "34800" },
{ code : "34125", intitule : "LAGAMAS", cp : "34150" },
{ code : "34126", intitule : "LAMALOU LES BAINS", cp : "34240" },
{ code : "34127", intitule : "LANSARGUES", cp : "34130" },
{ code : "34128", intitule : "LAROQUE", cp : "34190" },
{ code : "34129", intitule : "LATTES", cp : "34970" },
{ code : "34130", intitule : "LAURENS", cp : "34480" },
{ code : "34131", intitule : "LAURET", cp : "34270" },
{ code : "34132", intitule : "LAUROUX", cp : "34700" },
{ code : "34133", intitule : "LAVALETTE", cp : "34700" },
{ code : "34134", intitule : "LAVERUNE", cp : "34880" },
{ code : "34135", intitule : "LESPIGNAN", cp : "34710" },
{ code : "34136", intitule : "LEZIGNAN LA CEBE", cp : "34120" },
{ code : "34137", intitule : "LIAUSSON", cp : "34800" },
{ code : "34138", intitule : "LIEURAN CABRIERES", cp : "34800" },
{ code : "34139", intitule : "LIEURAN LES BEZIERS", cp : "34290" },
{ code : "34140", intitule : "LIGNAN SUR ORB", cp : "34490" },
{ code : "34141", intitule : "LA LIVINIERE", cp : "34210" },
{ code : "34142", intitule : "LODEVE", cp : "34700" },
{ code : "34143", intitule : "LOUPIAN", cp : "34140" },
{ code : "34144", intitule : "LUNAS", cp : "34650" },
{ code : "34145", intitule : "LUNEL", cp : "34400" },
{ code : "34146", intitule : "LUNEL VIEL", cp : "34400" },
{ code : "34147", intitule : "MAGALAS", cp : "34480" },
{ code : "34148", intitule : "MARAUSSAN", cp : "34370" },
{ code : "34149", intitule : "MARGON", cp : "34320" },
{ code : "34150", intitule : "MARSEILLAN", cp : "34340" },
{ code : "34150", intitule : "MARSEILLAN", cp : "34340" },
{ code : "34151", intitule : "MARSILLARGUES", cp : "34590" },
{ code : "34152", intitule : "MAS DE LONDRES", cp : "34380" },
{ code : "34153", intitule : "LES MATELLES", cp : "34270" },
{ code : "34154", intitule : "MAUGUIO", cp : "34130" },
{ code : "34154", intitule : "MAUGUIO", cp : "34280" },
{ code : "34155", intitule : "MAUREILHAN", cp : "34370" },
{ code : "34156", intitule : "MERIFONS", cp : "34800" },
{ code : "34157", intitule : "MEZE", cp : "34140" },
{ code : "34158", intitule : "MINERVE", cp : "34210" },
{ code : "34159", intitule : "MIREVAL", cp : "34110" },
{ code : "34160", intitule : "MONS", cp : "34390" },
{ code : "34161", intitule : "MONTADY", cp : "34310" },
{ code : "34162", intitule : "MONTAGNAC", cp : "34530" },
{ code : "34163", intitule : "MONTARNAUD", cp : "34570" },
{ code : "34164", intitule : "MONTAUD", cp : "34160" },
{ code : "34165", intitule : "MONTBAZIN", cp : "34560" },
{ code : "34166", intitule : "MONTBLANC", cp : "34290" },
{ code : "34167", intitule : "MONTELS", cp : "34310" },
{ code : "34168", intitule : "MONTESQUIEU", cp : "34320" },
{ code : "34169", intitule : "MONTFERRIER SUR LEZ", cp : "34980" },
{ code : "34170", intitule : "MONTOULIERS", cp : "34310" },
{ code : "34171", intitule : "MONTOULIEU", cp : "34190" },
{ code : "34172", intitule : "MONTPELLIER", cp : "34070" },
{ code : "34172", intitule : "MONTPELLIER", cp : "34000" },
{ code : "34172", intitule : "MONTPELLIER", cp : "34080" },
{ code : "34172", intitule : "MONTPELLIER", cp : "34090" },
{ code : "34173", intitule : "MONTPEYROUX", cp : "34150" },
{ code : "34174", intitule : "MOULES ET BAUCELS", cp : "34190" },
{ code : "34175", intitule : "MOUREZE", cp : "34800" },
{ code : "34176", intitule : "MUDAISON", cp : "34130" },
{ code : "34177", intitule : "MURLES", cp : "34980" },
{ code : "34178", intitule : "MURVIEL LES BEZIERS", cp : "34490" },
{ code : "34179", intitule : "MURVIEL LES MONTPELLIER", cp : "34570" },
{ code : "34180", intitule : "NEBIAN", cp : "34800" },
{ code : "34181", intitule : "NEFFIES", cp : "34320" },
{ code : "34182", intitule : "NEZIGNAN L EVEQUE", cp : "34120" },
{ code : "34183", intitule : "NISSAN LEZ ENSERUNE", cp : "34440" },
{ code : "34184", intitule : "NIZAS", cp : "34320" },
{ code : "34185", intitule : "NOTRE DAME DE LONDRES", cp : "34380" },
{ code : "34186", intitule : "OCTON", cp : "34800" },
{ code : "34187", intitule : "OLARGUES", cp : "34390" },
{ code : "34188", intitule : "OLMET ET VILLECUN", cp : "34700" },
{ code : "34189", intitule : "OLONZAC", cp : "34210" },
{ code : "34190", intitule : "OUPIA", cp : "34210" },
{ code : "34191", intitule : "PAILHES", cp : "34490" },
{ code : "34192", intitule : "PALAVAS LES FLOTS", cp : "34250" },
{ code : "34193", intitule : "PARDAILHAN", cp : "34360" },
{ code : "34194", intitule : "PAULHAN", cp : "34230" },
{ code : "34195", intitule : "PEGAIROLLES DE BUEGES", cp : "34380" },
{ code : "34196", intitule : "PEGAIROLLES DE L ESCALETTE", cp : "34700" },
{ code : "34197", intitule : "PERET", cp : "34800" },
{ code : "34198", intitule : "PEROLS", cp : "34470" },
{ code : "34199", intitule : "PEZENAS", cp : "34120" },
{ code : "34200", intitule : "PEZENES LES MINES", cp : "34600" },
{ code : "34201", intitule : "PIERRERUE", cp : "34360" },
{ code : "34202", intitule : "PIGNAN", cp : "34570" },
{ code : "34203", intitule : "PINET", cp : "34850" },
{ code : "34204", intitule : "PLAISSAN", cp : "34230" },
{ code : "34205", intitule : "LES PLANS", cp : "34700" },
{ code : "34206", intitule : "POILHES", cp : "34310" },
{ code : "34207", intitule : "POMEROLS", cp : "34810" },
{ code : "34208", intitule : "POPIAN", cp : "34230" },
{ code : "34209", intitule : "PORTIRAGNES", cp : "34420" },
{ code : "34210", intitule : "LE POUGET", cp : "34230" },
{ code : "34211", intitule : "LE POUJOL SUR ORB", cp : "34600" },
{ code : "34212", intitule : "POUJOLS", cp : "34700" },
{ code : "34213", intitule : "POUSSAN", cp : "34560" },
{ code : "34214", intitule : "POUZOLLES", cp : "34480" },
{ code : "34215", intitule : "POUZOLS", cp : "34230" },
{ code : "34216", intitule : "LE PRADAL", cp : "34600" },
{ code : "34217", intitule : "PRADES LE LEZ", cp : "34730" },
{ code : "34218", intitule : "PRADES SUR VERNAZOBRE", cp : "34360" },
{ code : "34219", intitule : "PREMIAN", cp : "34390" },
{ code : "34220", intitule : "LE PUECH", cp : "34700" },
{ code : "34221", intitule : "PUECHABON", cp : "34150" },
{ code : "34222", intitule : "PUILACHER", cp : "34230" },
{ code : "34223", intitule : "PUIMISSON", cp : "34480" },
{ code : "34224", intitule : "PUISSALICON", cp : "34480" },
{ code : "34225", intitule : "PUISSERGUIER", cp : "34620" },
{ code : "34226", intitule : "QUARANTE", cp : "34310" },
{ code : "34227", intitule : "RESTINCLIERES", cp : "34160" },
{ code : "34228", intitule : "RIEUSSEC", cp : "34220" },
{ code : "34229", intitule : "RIOLS", cp : "34220" },
{ code : "34230", intitule : "LES RIVES", cp : "34520" },
{ code : "34231", intitule : "ROMIGUIERES", cp : "34650" },
{ code : "34232", intitule : "ROQUEBRUN", cp : "34460" },
{ code : "34233", intitule : "ROQUEREDONDE", cp : "34650" },
{ code : "34234", intitule : "ROQUESSELS", cp : "34320" },
{ code : "34235", intitule : "ROSIS", cp : "34610" },
{ code : "34236", intitule : "ROUET", cp : "34380" },
{ code : "34237", intitule : "ROUJAN", cp : "34320" },
{ code : "34238", intitule : "ST ANDRE DE BUEGES", cp : "34190" },
{ code : "34239", intitule : "ST ANDRE DE SANGONIS", cp : "34725" },
{ code : "34240", intitule : "ST AUNES", cp : "34130" },
{ code : "34241", intitule : "ST BAUZILLE DE LA SYLVE", cp : "34230" },
{ code : "34242", intitule : "ST BAUZILLE DE MONTMEL", cp : "34160" },
{ code : "34243", intitule : "ST BAUZILLE DE PUTOIS", cp : "34190" },
{ code : "34244", intitule : "ST BRES", cp : "34670" },
{ code : "34245", intitule : "ST CHINIAN", cp : "34360" },
{ code : "34246", intitule : "ST CHRISTOL", cp : "34400" },
{ code : "34247", intitule : "ST CLEMENT DE RIVIERE", cp : "34980" },
{ code : "34248", intitule : "STE CROIX DE QUINTILLARGUES", cp : "34270" },
{ code : "34249", intitule : "ST DREZERY", cp : "34160" },
{ code : "34250", intitule : "ST ETIENNE D ALBAGNAN", cp : "34390" },
{ code : "34251", intitule : "ST ETIENNE DE GOURGAS", cp : "34700" },
{ code : "34252", intitule : "ST ETIENNE ESTRECHOUX", cp : "34260" },
{ code : "34253", intitule : "ST FELIX DE L HERAS", cp : "34520" },
{ code : "34254", intitule : "ST FELIX DE LODEZ", cp : "34725" },
{ code : "34255", intitule : "ST GELY DU FESC", cp : "34980" },
{ code : "34256", intitule : "ST GENIES DES MOURGUES", cp : "34160" },
{ code : "34257", intitule : "ST GENIES DE VARENSAL", cp : "34610" },
{ code : "34257", intitule : "ST GENIES DE VARENSAL", cp : "34610" },
{ code : "34258", intitule : "ST GENIES DE FONTEDIT", cp : "34480" },
{ code : "34259", intitule : "ST GEORGES D ORQUES", cp : "34680" },
{ code : "34260", intitule : "ST GERVAIS SUR MARE", cp : "34610" },
{ code : "34261", intitule : "ST GUILHEM LE DESERT", cp : "34150" },
{ code : "34262", intitule : "ST GUIRAUD", cp : "34725" },
{ code : "34263", intitule : "ST HILAIRE DE BEAUVOIR", cp : "34160" },
{ code : "34264", intitule : "ST JEAN DE BUEGES", cp : "34380" },
{ code : "34265", intitule : "ST JEAN DE CORNIES", cp : "34160" },
{ code : "34266", intitule : "ST JEAN DE CUCULLES", cp : "34270" },
{ code : "34267", intitule : "ST JEAN DE FOS", cp : "34150" },
{ code : "34268", intitule : "ST JEAN DE LA BLAQUIERE", cp : "34700" },
{ code : "34269", intitule : "ST JEAN DE MINERVOIS", cp : "34360" },
{ code : "34270", intitule : "ST JEAN DE VEDAS", cp : "34430" },
{ code : "34271", intitule : "ST JULIEN", cp : "34390" },
{ code : "34272", intitule : "ST JUST", cp : "34400" },
{ code : "34273", intitule : "ST MARTIN DE L ARCON", cp : "34390" },
{ code : "34274", intitule : "ST MARTIN DE LONDRES", cp : "34380" },
{ code : "34276", intitule : "ST MATHIEU DE TREVIERS", cp : "34270" },
{ code : "34277", intitule : "ST MAURICE NAVACELLES", cp : "34190" },
{ code : "34277", intitule : "ST MAURICE NAVACELLES", cp : "34520" },
{ code : "34278", intitule : "ST MICHEL", cp : "34520" },
{ code : "34279", intitule : "ST NAZAIRE DE LADAREZ", cp : "34490" },
{ code : "34280", intitule : "ST NAZAIRE DE PEZAN", cp : "34400" },
{ code : "34281", intitule : "ST PARGOIRE", cp : "34230" },
{ code : "34282", intitule : "ST PAUL ET VALMALLE", cp : "34570" },
{ code : "34283", intitule : "ST PIERRE DE LA FAGE", cp : "34520" },
{ code : "34284", intitule : "ST PONS DE THOMIERES", cp : "34220" },
{ code : "34285", intitule : "ST PONS DE MAUCHIENS", cp : "34230" },
{ code : "34286", intitule : "ST PRIVAT", cp : "34700" },
{ code : "34287", intitule : "ST SATURNIN DE LUCIAN", cp : "34725" },
{ code : "34288", intitule : "ST SERIES", cp : "34400" },
{ code : "34289", intitule : "ST THIBERY", cp : "34630" },
{ code : "34290", intitule : "ST VINCENT DE BARBEYRARGUES", cp : "34730" },
{ code : "34291", intitule : "ST VINCENT D OLARGUES", cp : "34390" },
{ code : "34292", intitule : "SALASC", cp : "34800" },
{ code : "34293", intitule : "LA SALVETAT SUR AGOUT", cp : "34330" },
{ code : "34294", intitule : "SATURARGUES", cp : "34400" },
{ code : "34295", intitule : "SAUSSAN", cp : "34570" },
{ code : "34296", intitule : "SAUSSINES", cp : "34160" },
{ code : "34297", intitule : "SAUTEYRARGUES", cp : "34270" },
{ code : "34298", intitule : "SAUVIAN", cp : "34410" },
{ code : "34299", intitule : "SERIGNAN", cp : "34410" },
{ code : "34300", intitule : "SERVIAN", cp : "34290" },
{ code : "34301", intitule : "SETE", cp : "34200" },
{ code : "34302", intitule : "SIRAN", cp : "34210" },
{ code : "34303", intitule : "SORBS", cp : "34520" },
{ code : "34304", intitule : "SOUBES", cp : "34700" },
{ code : "34305", intitule : "LE SOULIE", cp : "34330" },
{ code : "34306", intitule : "SOUMONT", cp : "34700" },
{ code : "34307", intitule : "SUSSARGUES", cp : "34160" },
{ code : "34308", intitule : "TAUSSAC LA BILLIERE", cp : "34600" },
{ code : "34309", intitule : "TEYRAN", cp : "34820" },
{ code : "34310", intitule : "THEZAN LES BEZIERS", cp : "34490" },
{ code : "34311", intitule : "TOURBES", cp : "34120" },
{ code : "34312", intitule : "LA TOUR SUR ORB", cp : "34260" },
{ code : "34313", intitule : "TRESSAN", cp : "34230" },
{ code : "34314", intitule : "LE TRIADOU", cp : "34270" },
{ code : "34315", intitule : "USCLAS D HERAULT", cp : "34230" },
{ code : "34316", intitule : "USCLAS DU BOSC", cp : "34700" },
{ code : "34317", intitule : "LA VACQUERIE ET ST MARTIN DE CASTRIES", cp : "34520" },
{ code : "34318", intitule : "VACQUIERES", cp : "34270" },
{ code : "34319", intitule : "VAILHAN", cp : "34320" },
{ code : "34320", intitule : "VAILHAUQUES", cp : "34570" },
{ code : "34321", intitule : "VALERGUES", cp : "34130" },
{ code : "34322", intitule : "VALFLAUNES", cp : "34270" },
{ code : "34323", intitule : "VALMASCLE", cp : "34800" },
{ code : "34324", intitule : "VALRAS PLAGE", cp : "34350" },
{ code : "34325", intitule : "VALROS", cp : "34290" },
{ code : "34326", intitule : "VELIEUX", cp : "34220" },
{ code : "34327", intitule : "VENDARGUES", cp : "34740" },
{ code : "34328", intitule : "VENDEMIAN", cp : "34230" },
{ code : "34329", intitule : "VENDRES", cp : "34350" },
{ code : "34330", intitule : "VERARGUES", cp : "34400" },
{ code : "34331", intitule : "VERRERIES DE MOUSSANS", cp : "34220" },
{ code : "34332", intitule : "VIAS", cp : "34450" },
{ code : "34333", intitule : "VIC LA GARDIOLE", cp : "34110" },
{ code : "34334", intitule : "VIEUSSAN", cp : "34390" },
{ code : "34335", intitule : "VILLEMAGNE L ARGENTIERE", cp : "34600" },
{ code : "34336", intitule : "VILLENEUVE LES BEZIERS", cp : "34500" },
{ code : "34336", intitule : "VILLENEUVE LES BEZIERS", cp : "34420" },
{ code : "34337", intitule : "VILLENEUVE LES MAGUELONE", cp : "34750" },
{ code : "34338", intitule : "VILLENEUVETTE", cp : "34800" },
{ code : "34339", intitule : "VILLESPASSANS", cp : "34360" },
{ code : "34340", intitule : "VILLETELLE", cp : "34400" },
{ code : "34341", intitule : "VILLEVEYRAC", cp : "34560" },
{ code : "34342", intitule : "VIOLS EN LAVAL", cp : "34380" },
{ code : "34343", intitule : "VIOLS LE FORT", cp : "34380" },
{ code : "34344", intitule : "LA GRANDE MOTTE", cp : "34280" },
{ code : "35001", intitule : "ACIGNE", cp : "35690" },
{ code : "35002", intitule : "AMANLIS", cp : "35150" },
{ code : "35003", intitule : "ANDOUILLE NEUVILLE", cp : "35250" },
{ code : "35004", intitule : "ANTRAIN", cp : "35560" },
{ code : "35005", intitule : "ARBRISSEL", cp : "35130" },
{ code : "35006", intitule : "ARGENTRE DU PLESSIS", cp : "35370" },
{ code : "35007", intitule : "AUBIGNE", cp : "35250" },
{ code : "35008", intitule : "AVAILLES SUR SEICHE", cp : "35130" },
{ code : "35009", intitule : "BAGUER MORVAN", cp : "35120" },
{ code : "35010", intitule : "BAGUER PICAN", cp : "35120" },
{ code : "35011", intitule : "BAILLE", cp : "35460" },
{ code : "35012", intitule : "BAIN DE BRETAGNE", cp : "35470" },
{ code : "35013", intitule : "BAINS SUR OUST", cp : "35600" },
{ code : "35014", intitule : "BAIS", cp : "35680" },
{ code : "35015", intitule : "BALAZE", cp : "35500" },
{ code : "35016", intitule : "BAULON", cp : "35580" },
{ code : "35017", intitule : "LA BAUSSAINE", cp : "35190" },
{ code : "35018", intitule : "LA BAZOUGE DU DESERT", cp : "35420" },
{ code : "35019", intitule : "BAZOUGES LA PEROUSE", cp : "35560" },
{ code : "35021", intitule : "BEAUCE", cp : "35133" },
{ code : "35022", intitule : "BECHEREL", cp : "35190" },
{ code : "35023", intitule : "BEDEE", cp : "35137" },
{ code : "35024", intitule : "BETTON", cp : "35830" },
{ code : "35025", intitule : "BILLE", cp : "35133" },
{ code : "35026", intitule : "BLERUAIS", cp : "35750" },
{ code : "35027", intitule : "BOISGERVILLY", cp : "35360" },
{ code : "35028", intitule : "BOISTRUDAN", cp : "35150" },
{ code : "35029", intitule : "BONNEMAIN", cp : "35270" },
{ code : "35030", intitule : "LA BOSSE DE BRETAGNE", cp : "35320" },
{ code : "35031", intitule : "LA BOUEXIERE", cp : "35340" },
{ code : "35032", intitule : "BOURGBARRE", cp : "35230" },
{ code : "35033", intitule : "BOURG DES COMPTES", cp : "35890" },
{ code : "35034", intitule : "LA BOUSSAC", cp : "35120" },
{ code : "35035", intitule : "BOVEL", cp : "35330" },
{ code : "35037", intitule : "BREAL SOUS MONTFORT", cp : "35310" },
{ code : "35038", intitule : "BREAL SOUS VITRE", cp : "35370" },
{ code : "35039", intitule : "BRECE", cp : "35530" },
{ code : "35040", intitule : "BRETEIL", cp : "35160" },
{ code : "35041", intitule : "BRIE", cp : "35150" },
{ code : "35042", intitule : "BRIELLES", cp : "35370" },
{ code : "35044", intitule : "BROUALAN", cp : "35120" },
{ code : "35045", intitule : "BRUC SUR AFF", cp : "35550" },
{ code : "35046", intitule : "LES BRULAIS", cp : "35330" },
{ code : "35047", intitule : "BRUZ", cp : "35170" },
{ code : "35047", intitule : "BRUZ", cp : "35170" },
{ code : "35048", intitule : "CAMPEL", cp : "35330" },
{ code : "35049", intitule : "CANCALE", cp : "35260" },
{ code : "35050", intitule : "CARDROC", cp : "35190" },
{ code : "35051", intitule : "CESSON SEVIGNE", cp : "35510" },
{ code : "35052", intitule : "CHAMPEAUX", cp : "35500" },
{ code : "35053", intitule : "CHANCE", cp : "35680" },
{ code : "35054", intitule : "CHANTELOUP", cp : "35150" },
{ code : "35055", intitule : "CHANTEPIE", cp : "35135" },
{ code : "35056", intitule : "LA CHAPELLE AUX FILTZMEENS", cp : "35190" },
{ code : "35057", intitule : "LA CHAPELLE BOUEXIC", cp : "35330" },
{ code : "35058", intitule : "LA CHAPELLE CHAUSSEE", cp : "35630" },
{ code : "35059", intitule : "LA CHAPELLE DES FOUGERETZ", cp : "35520" },
{ code : "35060", intitule : "LA CHAPELLE DU LOU DU LAC", cp : "35360" },
{ code : "35060", intitule : "LA CHAPELLE DU LOU DU LAC", cp : "35360" },
{ code : "35061", intitule : "LA CHAPELLE ERBREE", cp : "35500" },
{ code : "35062", intitule : "LA CHAPELLE JANSON", cp : "35133" },
{ code : "35063", intitule : "LA CHAPELLE ST AUBERT", cp : "35140" },
{ code : "35064", intitule : "LA CHAPELLE DE BRAIN", cp : "35660" },
{ code : "35064", intitule : "LA CHAPELLE DE BRAIN", cp : "35660" },
{ code : "35065", intitule : "LA CHAPELLE THOUARAULT", cp : "35590" },
{ code : "35066", intitule : "CHARTRES DE BRETAGNE", cp : "35131" },
{ code : "35067", intitule : "CHASNE SUR ILLET", cp : "35250" },
{ code : "35068", intitule : "CHATEAUBOURG", cp : "35220" },
{ code : "35068", intitule : "CHATEAUBOURG", cp : "35220" },
{ code : "35068", intitule : "CHATEAUBOURG", cp : "35220" },
{ code : "35069", intitule : "CHATEAUGIRON", cp : "35410" },
{ code : "35070", intitule : "CHATEAUNEUF D ILLE ET VILAINE", cp : "35430" },
{ code : "35071", intitule : "LE CHATELLIER", cp : "35133" },
{ code : "35072", intitule : "CHATILLON EN VENDELAIS", cp : "35210" },
{ code : "35075", intitule : "CHAUVIGNE", cp : "35490" },
{ code : "35076", intitule : "CHAVAGNE", cp : "35310" },
{ code : "35077", intitule : "CHELUN", cp : "35640" },
{ code : "35078", intitule : "CHERRUEIX", cp : "35120" },
{ code : "35079", intitule : "CHEVAIGNE", cp : "35250" },
{ code : "35080", intitule : "CINTRE", cp : "35310" },
{ code : "35081", intitule : "CLAYES", cp : "35590" },
{ code : "35082", intitule : "COESMES", cp : "35134" },
{ code : "35083", intitule : "COGLES", cp : "35460" },
{ code : "35084", intitule : "COMBLESSAC", cp : "35330" },
{ code : "35085", intitule : "COMBOURG", cp : "35270" },
{ code : "35086", intitule : "COMBOURTILLE", cp : "35210" },
{ code : "35087", intitule : "CORNILLE", cp : "35500" },
{ code : "35088", intitule : "CORPS NUDS", cp : "35150" },
{ code : "35089", intitule : "LA COUYERE", cp : "35320" },
{ code : "35090", intitule : "CREVIN", cp : "35320" },
{ code : "35091", intitule : "LE CROUAIS", cp : "35290" },
{ code : "35092", intitule : "CUGUEN", cp : "35270" },
{ code : "35093", intitule : "DINARD", cp : "35800" },
{ code : "35094", intitule : "DINGE", cp : "35440" },
{ code : "35095", intitule : "DOL DE BRETAGNE", cp : "35120" },
{ code : "35096", intitule : "DOMAGNE", cp : "35113" },
{ code : "35096", intitule : "DOMAGNE", cp : "35113" },
{ code : "35097", intitule : "DOMALAIN", cp : "35680" },
{ code : "35098", intitule : "LA DOMINELAIS", cp : "35390" },
{ code : "35099", intitule : "DOMLOUP", cp : "35410" },
{ code : "35100", intitule : "DOMPIERRE DU CHEMIN", cp : "35210" },
{ code : "35101", intitule : "DOURDAIN", cp : "35450" },
{ code : "35102", intitule : "DROUGES", cp : "35130" },
{ code : "35103", intitule : "EANCE", cp : "35640" },
{ code : "35104", intitule : "EPINIAC", cp : "35120" },
{ code : "35105", intitule : "ERBREE", cp : "35500" },
{ code : "35106", intitule : "ERCE EN LAMEE", cp : "35620" },
{ code : "35107", intitule : "ERCE PRES LIFFRE", cp : "35340" },
{ code : "35108", intitule : "ESSE", cp : "35150" },
{ code : "35109", intitule : "ETRELLES", cp : "35370" },
{ code : "35110", intitule : "FEINS", cp : "35440" },
{ code : "35111", intitule : "LE FERRE", cp : "35420" },
{ code : "35112", intitule : "FLEURIGNE", cp : "35133" },
{ code : "35113", intitule : "LA FONTENELLE", cp : "35560" },
{ code : "35114", intitule : "FORGES LA FORET", cp : "35640" },
{ code : "35115", intitule : "FOUGERES", cp : "35300" },
{ code : "35116", intitule : "LA FRESNAIS", cp : "35111" },
{ code : "35117", intitule : "GAEL", cp : "35290" },
{ code : "35118", intitule : "GAHARD", cp : "35490" },
{ code : "35119", intitule : "GENNES SUR SEICHE", cp : "35370" },
{ code : "35120", intitule : "GEVEZE", cp : "35850" },
{ code : "35121", intitule : "GOSNE", cp : "35140" },
{ code : "35122", intitule : "LA GOUESNIERE", cp : "35350" },
{ code : "35123", intitule : "GOVEN", cp : "35580" },
{ code : "35124", intitule : "GRAND FOUGERAY", cp : "35390" },
{ code : "35125", intitule : "LA GUERCHE DE BRETAGNE", cp : "35130" },
{ code : "35126", intitule : "GUICHEN", cp : "35580" },
{ code : "35126", intitule : "GUICHEN", cp : "35580" },
{ code : "35127", intitule : "GUIGNEN", cp : "35580" },
{ code : "35128", intitule : "GUIPEL", cp : "35440" },
{ code : "35130", intitule : "HEDE BAZOUGES", cp : "35630" },
{ code : "35130", intitule : "HEDE BAZOUGES", cp : "35630" },
{ code : "35131", intitule : "L HERMITAGE", cp : "35590" },
{ code : "35132", intitule : "HIREL", cp : "35120" },
{ code : "35132", intitule : "HIREL", cp : "35120" },
{ code : "35133", intitule : "IFFENDIC", cp : "35750" },
{ code : "35134", intitule : "LES IFFS", cp : "35630" },
{ code : "35135", intitule : "IRODOUER", cp : "35850" },
{ code : "35136", intitule : "JANZE", cp : "35150" },
{ code : "35137", intitule : "JAVENE", cp : "35133" },
{ code : "35138", intitule : "LAIGNELET", cp : "35133" },
{ code : "35139", intitule : "LAILLE", cp : "35890" },
{ code : "35140", intitule : "LALLEU", cp : "35320" },
{ code : "35141", intitule : "LANDAVRAN", cp : "35450" },
{ code : "35142", intitule : "LANDEAN", cp : "35133" },
{ code : "35143", intitule : "LANDUJAN", cp : "35360" },
{ code : "35144", intitule : "LANGAN", cp : "35850" },
{ code : "35145", intitule : "LANGON", cp : "35660" },
{ code : "35146", intitule : "LANGOUET", cp : "35630" },
{ code : "35147", intitule : "LANHELIN", cp : "35720" },
{ code : "35148", intitule : "LANRIGAN", cp : "35270" },
{ code : "35149", intitule : "LASSY", cp : "35580" },
{ code : "35150", intitule : "LECOUSSE", cp : "35133" },
{ code : "35151", intitule : "LIEURON", cp : "35550" },
{ code : "35152", intitule : "LIFFRE", cp : "35340" },
{ code : "35153", intitule : "LILLEMER", cp : "35111" },
{ code : "35154", intitule : "LIVRE SUR CHANGEON", cp : "35450" },
{ code : "35155", intitule : "LOHEAC", cp : "35550" },
{ code : "35156", intitule : "LONGAULNAY", cp : "35190" },
{ code : "35157", intitule : "LE LOROUX", cp : "35133" },
{ code : "35159", intitule : "LOURMAIS", cp : "35270" },
{ code : "35160", intitule : "LOUTEHEL", cp : "35330" },
{ code : "35161", intitule : "LOUVIGNE DE BAIS", cp : "35680" },
{ code : "35162", intitule : "LOUVIGNE DU DESERT", cp : "35420" },
{ code : "35163", intitule : "LUITRE", cp : "35133" },
{ code : "35164", intitule : "MARCILLE RAOUL", cp : "35560" },
{ code : "35165", intitule : "MARCILLE ROBERT", cp : "35240" },
{ code : "35166", intitule : "MARPIRE", cp : "35220" },
{ code : "35167", intitule : "MARTIGNE FERCHAUD", cp : "35640" },
{ code : "35168", intitule : "MAURE DE BRETAGNE", cp : "35330" },
{ code : "35169", intitule : "MAXENT", cp : "35380" },
{ code : "35170", intitule : "MECE", cp : "35450" },
{ code : "35171", intitule : "MEDREAC", cp : "35360" },
{ code : "35172", intitule : "MEILLAC", cp : "35270" },
{ code : "35173", intitule : "MELESSE", cp : "35520" },
{ code : "35174", intitule : "MELLE", cp : "35420" },
{ code : "35175", intitule : "MERNEL", cp : "35330" },
{ code : "35176", intitule : "GUIPRY MESSAC", cp : "35480" },
{ code : "35176", intitule : "GUIPRY MESSAC", cp : "35480" },
{ code : "35177", intitule : "LA MEZIERE", cp : "35520" },
{ code : "35178", intitule : "MEZIERES SUR COUESNON", cp : "35140" },
{ code : "35179", intitule : "MINIAC MORVAN", cp : "35540" },
{ code : "35179", intitule : "MINIAC MORVAN", cp : "35540" },
{ code : "35180", intitule : "MINIAC SOUS BECHEREL", cp : "35190" },
{ code : "35181", intitule : "LE MINIHIC SUR RANCE", cp : "35870" },
{ code : "35183", intitule : "MONDEVERT", cp : "35370" },
{ code : "35184", intitule : "MONTAUBAN DE BRETAGNE", cp : "35360" },
{ code : "35185", intitule : "MONTAUTOUR", cp : "35210" },
{ code : "35186", intitule : "MONT DOL", cp : "35120" },
{ code : "35187", intitule : "MONTERFIL", cp : "35160" },
{ code : "35188", intitule : "MONTFORT SUR MEU", cp : "35160" },
{ code : "35189", intitule : "MONTGERMONT", cp : "35760" },
{ code : "35190", intitule : "MONTHAULT", cp : "35420" },
{ code : "35191", intitule : "MONTOURS", cp : "35460" },
{ code : "35192", intitule : "MONTREUIL DES LANDES", cp : "35210" },
{ code : "35193", intitule : "MONTREUIL LE GAST", cp : "35520" },
{ code : "35194", intitule : "MONTREUIL SOUS PEROUSE", cp : "35500" },
{ code : "35195", intitule : "MONTREUIL SUR ILLE", cp : "35440" },
{ code : "35196", intitule : "MORDELLES", cp : "35310" },
{ code : "35197", intitule : "MOUAZE", cp : "35250" },
{ code : "35198", intitule : "MOULINS", cp : "35680" },
{ code : "35199", intitule : "MOUSSE", cp : "35130" },
{ code : "35200", intitule : "MOUTIERS", cp : "35130" },
{ code : "35201", intitule : "MUEL", cp : "35290" },
{ code : "35202", intitule : "LA NOE BLANCHE", cp : "35470" },
{ code : "35203", intitule : "LA NOUAYE", cp : "35137" },
{ code : "35204", intitule : "NOUVOITOU", cp : "35410" },
{ code : "35205", intitule : "NOYAL SOUS BAZOUGES", cp : "35560" },
{ code : "35206", intitule : "NOYAL CHATILLON SUR SEICHE", cp : "35230" },
{ code : "35206", intitule : "NOYAL CHATILLON SUR SEICHE", cp : "35230" },
{ code : "35207", intitule : "NOYAL SUR VILAINE", cp : "35530" },
{ code : "35208", intitule : "ORGERES", cp : "35230" },
{ code : "35209", intitule : "OSSE", cp : "35410" },
{ code : "35210", intitule : "PACE", cp : "35740" },
{ code : "35211", intitule : "PAIMPONT", cp : "35380" },
{ code : "35212", intitule : "PANCE", cp : "35320" },
{ code : "35214", intitule : "PARCE", cp : "35210" },
{ code : "35215", intitule : "PARIGNE", cp : "35133" },
{ code : "35216", intitule : "PARTHENAY DE BRETAGNE", cp : "35850" },
{ code : "35217", intitule : "LE PERTRE", cp : "35370" },
{ code : "35218", intitule : "LE PETIT FOUGERAY", cp : "35320" },
{ code : "35219", intitule : "PIPRIAC", cp : "35550" },
{ code : "35220", intitule : "PIRE SUR SEICHE", cp : "35150" },
{ code : "35221", intitule : "PLECHATEL", cp : "35470" },
{ code : "35221", intitule : "PLECHATEL", cp : "35470" },
{ code : "35222", intitule : "PLEINE FOUGERES", cp : "35610" },
{ code : "35223", intitule : "PLELAN LE GRAND", cp : "35380" },
{ code : "35224", intitule : "PLERGUER", cp : "35540" },
{ code : "35225", intitule : "PLESDER", cp : "35720" },
{ code : "35226", intitule : "PLEUGUENEUC", cp : "35720" },
{ code : "35227", intitule : "PLEUMELEUC", cp : "35137" },
{ code : "35228", intitule : "PLEURTUIT", cp : "35730" },
{ code : "35229", intitule : "POCE LES BOIS", cp : "35500" },
{ code : "35230", intitule : "POILLEY", cp : "35420" },
{ code : "35231", intitule : "POLIGNE", cp : "35320" },
{ code : "35232", intitule : "PRINCE", cp : "35210" },
{ code : "35233", intitule : "QUEBRIAC", cp : "35190" },
{ code : "35234", intitule : "QUEDILLAC", cp : "35290" },
{ code : "35235", intitule : "RANNEE", cp : "35130" },
{ code : "35236", intitule : "REDON", cp : "35600" },
{ code : "35237", intitule : "RENAC", cp : "35660" },
{ code : "35238", intitule : "RENNES", cp : "35700" },
{ code : "35238", intitule : "RENNES", cp : "35000" },
{ code : "35238", intitule : "RENNES", cp : "35200" },
{ code : "35239", intitule : "RETIERS", cp : "35240" },
{ code : "35240", intitule : "LE RHEU", cp : "35650" },
{ code : "35240", intitule : "LE RHEU", cp : "35650" },
{ code : "35241", intitule : "LA RICHARDAIS", cp : "35780" },
{ code : "35242", intitule : "RIMOU", cp : "35560" },
{ code : "35243", intitule : "ROMAGNE", cp : "35133" },
{ code : "35244", intitule : "ROMAZY", cp : "35490" },
{ code : "35245", intitule : "ROMILLE", cp : "35850" },
{ code : "35246", intitule : "ROZ LANDRIEUX", cp : "35120" },
{ code : "35247", intitule : "ROZ SUR COUESNON", cp : "35610" },
{ code : "35248", intitule : "SAINS", cp : "35610" },
{ code : "35249", intitule : "STE ANNE SUR VILAINE", cp : "35390" },
{ code : "35250", intitule : "ST ARMEL", cp : "35230" },
{ code : "35251", intitule : "ST AUBIN D AUBIGNE", cp : "35250" },
{ code : "35252", intitule : "ST AUBIN DES LANDES", cp : "35500" },
{ code : "35253", intitule : "ST AUBIN DU CORMIER", cp : "35140" },
{ code : "35254", intitule : "ST AUBIN DU PAVAIL", cp : "35410" },
{ code : "35255", intitule : "ST BENOIT DES ONDES", cp : "35114" },
{ code : "35256", intitule : "ST BRIAC SUR MER", cp : "35800" },
{ code : "35257", intitule : "ST BRICE EN COGLES", cp : "35460" },
{ code : "35258", intitule : "ST BRIEUC DES IFFS", cp : "35630" },
{ code : "35259", intitule : "ST BROLADRE", cp : "35120" },
{ code : "35260", intitule : "ST CHRISTOPHE DES BOIS", cp : "35210" },
{ code : "35261", intitule : "ST CHRISTOPHE DE VALAINS", cp : "35140" },
{ code : "35262", intitule : "STE COLOMBE", cp : "35134" },
{ code : "35263", intitule : "ST COULOMB", cp : "35350" },
{ code : "35264", intitule : "ST DIDIER", cp : "35220" },
{ code : "35265", intitule : "ST DOMINEUC", cp : "35190" },
{ code : "35266", intitule : "ST ERBLON", cp : "35230" },
{ code : "35267", intitule : "ST ETIENNE EN COGLES", cp : "35460" },
{ code : "35268", intitule : "ST GANTON", cp : "35550" },
{ code : "35269", intitule : "ST GEORGES DE CHESNE", cp : "35140" },
{ code : "35270", intitule : "ST GEORGES DE GREHAIGNE", cp : "35610" },
{ code : "35271", intitule : "ST GEORGES DE REINTEMBAULT", cp : "35420" },
{ code : "35272", intitule : "ST GERMAIN DU PINEL", cp : "35370" },
{ code : "35273", intitule : "ST GERMAIN EN COGLES", cp : "35133" },
{ code : "35274", intitule : "ST GERMAIN SUR ILLE", cp : "35250" },
{ code : "35275", intitule : "ST GILLES", cp : "35590" },
{ code : "35276", intitule : "ST GONDRAN", cp : "35630" },
{ code : "35277", intitule : "ST GONLAY", cp : "35750" },
{ code : "35278", intitule : "ST GREGOIRE", cp : "35760" },
{ code : "35279", intitule : "ST GUINOUX", cp : "35430" },
{ code : "35280", intitule : "ST HILAIRE DES LANDES", cp : "35140" },
{ code : "35281", intitule : "ST JACQUES DE LA LANDE", cp : "35136" },
{ code : "35282", intitule : "ST JEAN SUR COUESNON", cp : "35140" },
{ code : "35283", intitule : "ST JEAN SUR VILAINE", cp : "35220" },
{ code : "35284", intitule : "ST JOUAN DES GUERETS", cp : "35430" },
{ code : "35285", intitule : "ST JUST", cp : "35550" },
{ code : "35286", intitule : "ST LEGER DES PRES", cp : "35270" },
{ code : "35287", intitule : "ST LUNAIRE", cp : "35800" },
{ code : "35288", intitule : "ST MALO", cp : "35400" },
{ code : "35288", intitule : "ST MALO", cp : "35400" },
{ code : "35288", intitule : "ST MALO", cp : "35400" },
{ code : "35288", intitule : "ST MALO", cp : "35400" },
{ code : "35288", intitule : "ST MALO", cp : "35400" },
{ code : "35289", intitule : "ST MALO DE PHILY", cp : "35480" },
{ code : "35290", intitule : "ST MALON SUR MEL", cp : "35750" },
{ code : "35291", intitule : "ST MARCAN", cp : "35120" },
{ code : "35292", intitule : "ST MARC LE BLANC", cp : "35460" },
{ code : "35293", intitule : "ST MARC SUR COUESNON", cp : "35140" },
{ code : "35294", intitule : "STE MARIE", cp : "35600" },
{ code : "35295", intitule : "ST MAUGAN", cp : "35750" },
{ code : "35296", intitule : "ST MEDARD SUR ILLE", cp : "35250" },
{ code : "35297", intitule : "ST MEEN LE GRAND", cp : "35290" },
{ code : "35299", intitule : "ST MELOIR DES ONDES", cp : "35350" },
{ code : "35300", intitule : "ST M HERVE", cp : "35500" },
{ code : "35301", intitule : "ST M HERVON", cp : "35360" },
{ code : "35302", intitule : "ST ONEN LA CHAPELLE", cp : "35290" },
{ code : "35303", intitule : "ST OUEN LA ROUERIE", cp : "35460" },
{ code : "35304", intitule : "ST OUEN DES ALLEUX", cp : "35140" },
{ code : "35305", intitule : "ST PERAN", cp : "35380" },
{ code : "35306", intitule : "ST PERE", cp : "35430" },
{ code : "35307", intitule : "ST PERN", cp : "35190" },
{ code : "35308", intitule : "ST PIERRE DE PLESGUEN", cp : "35720" },
{ code : "35309", intitule : "ST REMY DU PLAIN", cp : "35560" },
{ code : "35310", intitule : "ST SAUVEUR DES LANDES", cp : "35133" },
{ code : "35311", intitule : "ST SEGLIN", cp : "35330" },
{ code : "35312", intitule : "ST SENOUX", cp : "35580" },
{ code : "35314", intitule : "ST SULIAC", cp : "35430" },
{ code : "35315", intitule : "ST SULPICE LA FORET", cp : "35250" },
{ code : "35316", intitule : "ST SULPICE DES LANDES", cp : "35390" },
{ code : "35317", intitule : "ST SYMPHORIEN", cp : "35630" },
{ code : "35318", intitule : "ST THUAL", cp : "35190" },
{ code : "35319", intitule : "ST THURIAL", cp : "35310" },
{ code : "35320", intitule : "ST UNIAC", cp : "35360" },
{ code : "35321", intitule : "SAULNIERES", cp : "35320" },
{ code : "35322", intitule : "LE SEL DE BRETAGNE", cp : "35320" },
{ code : "35323", intitule : "LA SELLE EN COGLES", cp : "35460" },
{ code : "35324", intitule : "LA SELLE EN LUITRE", cp : "35133" },
{ code : "35325", intitule : "LA SELLE GUERCHAISE", cp : "35130" },
{ code : "35326", intitule : "SENS DE BRETAGNE", cp : "35490" },
{ code : "35327", intitule : "SERVON SUR VILAINE", cp : "35530" },
{ code : "35328", intitule : "SIXT SUR AFF", cp : "35550" },
{ code : "35329", intitule : "SOUGEAL", cp : "35610" },
{ code : "35330", intitule : "TAILLIS", cp : "35500" },
{ code : "35331", intitule : "TALENSAC", cp : "35160" },
{ code : "35332", intitule : "TEILLAY", cp : "35620" },
{ code : "35333", intitule : "LE THEIL DE BRETAGNE", cp : "35240" },
{ code : "35334", intitule : "THORIGNE FOUILLARD", cp : "35235" },
{ code : "35335", intitule : "THOURIE", cp : "35134" },
{ code : "35336", intitule : "LE TIERCENT", cp : "35460" },
{ code : "35337", intitule : "TINTENIAC", cp : "35190" },
{ code : "35338", intitule : "TORCE", cp : "35370" },
{ code : "35339", intitule : "TRANS LA FORET", cp : "35610" },
{ code : "35340", intitule : "TREFFENDEL", cp : "35380" },
{ code : "35341", intitule : "TREMBLAY", cp : "35460" },
{ code : "35342", intitule : "TREMEHEUC", cp : "35270" },
{ code : "35343", intitule : "TRESBOEUF", cp : "35320" },
{ code : "35344", intitule : "TRESSE", cp : "35720" },
{ code : "35345", intitule : "TREVERIEN", cp : "35190" },
{ code : "35346", intitule : "TRIMER", cp : "35190" },
{ code : "35347", intitule : "VAL D IZE", cp : "35450" },
{ code : "35348", intitule : "VENDEL", cp : "35140" },
{ code : "35350", intitule : "VERGEAL", cp : "35680" },
{ code : "35351", intitule : "LE VERGER", cp : "35160" },
{ code : "35352", intitule : "VERN SUR SEICHE", cp : "35770" },
{ code : "35353", intitule : "VEZIN LE COQUET", cp : "35132" },
{ code : "35354", intitule : "VIEUX VIEL", cp : "35610" },
{ code : "35355", intitule : "VIEUX VY SUR COUESNON", cp : "35490" },
{ code : "35356", intitule : "VIGNOC", cp : "35630" },
{ code : "35357", intitule : "VILLAMEE", cp : "35420" },
{ code : "35358", intitule : "LA VILLE ES NONAIS", cp : "35430" },
{ code : "35359", intitule : "VISSEICHE", cp : "35130" },
{ code : "35360", intitule : "VITRE", cp : "35500" },
{ code : "35361", intitule : "LE VIVIER SUR MER", cp : "35960" },
{ code : "35362", intitule : "LE TRONCHET", cp : "35540" },
{ code : "35363", intitule : "PONT PEAN", cp : "35131" },
{ code : "36001", intitule : "AIGURANDE", cp : "36140" },
{ code : "36002", intitule : "AIZE", cp : "36150" },
{ code : "36003", intitule : "AMBRAULT", cp : "36120" },
{ code : "36004", intitule : "ANJOUIN", cp : "36210" },
{ code : "36005", intitule : "ARDENTES", cp : "36120" },
{ code : "36006", intitule : "ARGENTON SUR CREUSE", cp : "36200" },
{ code : "36007", intitule : "ARGY", cp : "36500" },
{ code : "36008", intitule : "ARPHEUILLES", cp : "36700" },
{ code : "36009", intitule : "ARTHON", cp : "36330" },
{ code : "36010", intitule : "AZAY LE FERRON", cp : "36290" },
{ code : "36011", intitule : "BAGNEUX", cp : "36210" },
{ code : "36012", intitule : "BARAIZE", cp : "36270" },
{ code : "36013", intitule : "BAUDRES", cp : "36110" },
{ code : "36014", intitule : "BAZAIGES", cp : "36270" },
{ code : "36015", intitule : "BEAULIEU", cp : "36310" },
{ code : "36016", intitule : "BELABRE", cp : "36370" },
{ code : "36017", intitule : "LA BERTHENOUX", cp : "36400" },
{ code : "36018", intitule : "LE BLANC", cp : "36300" },
{ code : "36019", intitule : "BOMMIERS", cp : "36120" },
{ code : "36020", intitule : "BONNEUIL", cp : "36310" },
{ code : "36021", intitule : "LES BORDES", cp : "36100" },
{ code : "36022", intitule : "BOUESSE", cp : "36200" },
{ code : "36023", intitule : "BOUGES LE CHATEAU", cp : "36110" },
{ code : "36024", intitule : "BRETAGNE", cp : "36110" },
{ code : "36025", intitule : "BRIANTES", cp : "36400" },
{ code : "36026", intitule : "BRION", cp : "36110" },
{ code : "36027", intitule : "BRIVES", cp : "36100" },
{ code : "36028", intitule : "LA BUXERETTE", cp : "36140" },
{ code : "36029", intitule : "BUXEUIL", cp : "36150" },
{ code : "36030", intitule : "BUXIERES D AILLAC", cp : "36230" },
{ code : "36031", intitule : "BUZANCAIS", cp : "36500" },
{ code : "36032", intitule : "CEAULMONT", cp : "36200" },
{ code : "36033", intitule : "CELON", cp : "36200" },
{ code : "36034", intitule : "CHABRIS", cp : "36210" },
{ code : "36035", intitule : "CHAILLAC", cp : "36310" },
{ code : "36036", intitule : "CHALAIS", cp : "36370" },
{ code : "36037", intitule : "LA CHAMPENOISE", cp : "36100" },
{ code : "36038", intitule : "CHAMPILLET", cp : "36160" },
{ code : "36040", intitule : "LA CHAPELLE ORTHEMALE", cp : "36500" },
{ code : "36041", intitule : "LA CHAPELLE ST LAURIAN", cp : "36150" },
{ code : "36042", intitule : "CHASSENEUIL", cp : "36800" },
{ code : "36043", intitule : "CHASSIGNOLLES", cp : "36400" },
{ code : "36044", intitule : "CHATEAUROUX", cp : "36000" },
{ code : "36045", intitule : "CHATILLON SUR INDRE", cp : "36700" },
{ code : "36046", intitule : "LA CHATRE", cp : "36400" },
{ code : "36047", intitule : "LA CHATRE LANGLIN", cp : "36170" },
{ code : "36048", intitule : "CHAVIN", cp : "36200" },
{ code : "36049", intitule : "CHAZELET", cp : "36170" },
{ code : "36050", intitule : "CHEZELLES", cp : "36500" },
{ code : "36051", intitule : "CHITRAY", cp : "36800" },
{ code : "36052", intitule : "CHOUDAY", cp : "36100" },
{ code : "36053", intitule : "CIRON", cp : "36300" },
{ code : "36053", intitule : "CIRON", cp : "36300" },
{ code : "36054", intitule : "CLERE DU BOIS", cp : "36700" },
{ code : "36055", intitule : "CLION", cp : "36700" },
{ code : "36056", intitule : "CLUIS", cp : "36340" },
{ code : "36057", intitule : "COINGS", cp : "36130" },
{ code : "36058", intitule : "CONCREMIERS", cp : "36300" },
{ code : "36059", intitule : "CONDE", cp : "36100" },
{ code : "36060", intitule : "CREVANT", cp : "36140" },
{ code : "36061", intitule : "CROZON SUR VAUVRE", cp : "36140" },
{ code : "36062", intitule : "CUZION", cp : "36190" },
{ code : "36063", intitule : "DEOLS", cp : "36130" },
{ code : "36064", intitule : "DIORS", cp : "36130" },
{ code : "36065", intitule : "DIOU", cp : "36260" },
{ code : "36066", intitule : "DOUADIC", cp : "36300" },
{ code : "36067", intitule : "DUNET", cp : "36310" },
{ code : "36068", intitule : "DUN LE POELIER", cp : "36210" },
{ code : "36069", intitule : "ECUEILLE", cp : "36240" },
{ code : "36070", intitule : "EGUZON CHANTOME", cp : "36270" },
{ code : "36070", intitule : "EGUZON CHANTOME", cp : "36270" },
{ code : "36071", intitule : "ETRECHET", cp : "36120" },
{ code : "36072", intitule : "FAVEROLLES", cp : "36360" },
{ code : "36073", intitule : "FEUSINES", cp : "36160" },
{ code : "36074", intitule : "FLERE LA RIVIERE", cp : "36700" },
{ code : "36075", intitule : "FONTENAY", cp : "36150" },
{ code : "36076", intitule : "FONTGOMBAULT", cp : "36220" },
{ code : "36077", intitule : "FONTGUENAND", cp : "36600" },
{ code : "36078", intitule : "FOUGEROLLES", cp : "36230" },
{ code : "36079", intitule : "FRANCILLON", cp : "36110" },
{ code : "36080", intitule : "FREDILLE", cp : "36180" },
{ code : "36081", intitule : "GARGILESSE DAMPIERRE", cp : "36190" },
{ code : "36082", intitule : "GEHEE", cp : "36240" },
{ code : "36083", intitule : "GIROUX", cp : "36150" },
{ code : "36084", intitule : "GOURNAY", cp : "36230" },
{ code : "36085", intitule : "GUILLY", cp : "36150" },
{ code : "36086", intitule : "HEUGNES", cp : "36180" },
{ code : "36087", intitule : "INGRANDES", cp : "36300" },
{ code : "36088", intitule : "ISSOUDUN", cp : "36100" },
{ code : "36089", intitule : "JEU LES BOIS", cp : "36120" },
{ code : "36090", intitule : "JEU MALOCHES", cp : "36240" },
{ code : "36091", intitule : "LACS", cp : "36400" },
{ code : "36092", intitule : "LANGE", cp : "36600" },
{ code : "36093", intitule : "LEVROUX", cp : "36110" },
{ code : "36093", intitule : "LEVROUX", cp : "36110" },
{ code : "36094", intitule : "LIGNAC", cp : "36370" },
{ code : "36095", intitule : "LIGNEROLLES", cp : "36160" },
{ code : "36096", intitule : "LINGE", cp : "36220" },
{ code : "36097", intitule : "LINIEZ", cp : "36150" },
{ code : "36098", intitule : "LIZERAY", cp : "36100" },
{ code : "36099", intitule : "LOURDOUEIX ST MICHEL", cp : "36140" },
{ code : "36100", intitule : "LOUROUER ST LAURENT", cp : "36400" },
{ code : "36101", intitule : "LUANT", cp : "36350" },
{ code : "36102", intitule : "LUCAY LE LIBRE", cp : "36150" },
{ code : "36103", intitule : "LUCAY LE MALE", cp : "36360" },
{ code : "36104", intitule : "LURAIS", cp : "36220" },
{ code : "36105", intitule : "LUREUIL", cp : "36220" },
{ code : "36106", intitule : "LUZERET", cp : "36800" },
{ code : "36107", intitule : "LYE", cp : "36600" },
{ code : "36108", intitule : "LYS ST GEORGES", cp : "36230" },
{ code : "36109", intitule : "LE MAGNY", cp : "36400" },
{ code : "36110", intitule : "MAILLET", cp : "36340" },
{ code : "36111", intitule : "MALICORNAY", cp : "36340" },
{ code : "36112", intitule : "MARON", cp : "36120" },
{ code : "36113", intitule : "MARTIZAY", cp : "36220" },
{ code : "36114", intitule : "MAUVIERES", cp : "36370" },
{ code : "36115", intitule : "MENETOU SUR NAHON", cp : "36210" },
{ code : "36116", intitule : "MENETREOLS SOUS VATAN", cp : "36150" },
{ code : "36117", intitule : "LE MENOUX", cp : "36200" },
{ code : "36118", intitule : "MEOBECQ", cp : "36500" },
{ code : "36119", intitule : "MERIGNY", cp : "36220" },
{ code : "36120", intitule : "MERS SUR INDRE", cp : "36230" },
{ code : "36121", intitule : "MEUNET PLANCHES", cp : "36100" },
{ code : "36122", intitule : "MEUNET SUR VATAN", cp : "36150" },
{ code : "36123", intitule : "MEZIERES EN BRENNE", cp : "36290" },
{ code : "36124", intitule : "MIGNE", cp : "36800" },
{ code : "36125", intitule : "MIGNY", cp : "36260" },
{ code : "36126", intitule : "MONTCHEVRIER", cp : "36140" },
{ code : "36127", intitule : "MONTGIVRAY", cp : "36400" },
{ code : "36128", intitule : "MONTIERCHAUME", cp : "36130" },
{ code : "36129", intitule : "MONTIPOURET", cp : "36230" },
{ code : "36130", intitule : "MONTLEVICQ", cp : "36400" },
{ code : "36131", intitule : "MOSNAY", cp : "36200" },
{ code : "36132", intitule : "LA MOTTE FEUILLY", cp : "36160" },
{ code : "36133", intitule : "MOUHERS", cp : "36340" },
{ code : "36134", intitule : "MOUHET", cp : "36170" },
{ code : "36135", intitule : "MOULINS SUR CEPHONS", cp : "36110" },
{ code : "36136", intitule : "MURS", cp : "36700" },
{ code : "36137", intitule : "NEONS SUR CREUSE", cp : "36220" },
{ code : "36138", intitule : "NERET", cp : "36400" },
{ code : "36139", intitule : "NEUILLAY LES BOIS", cp : "36500" },
{ code : "36140", intitule : "NEUVY PAILLOUX", cp : "36100" },
{ code : "36141", intitule : "NEUVY ST SEPULCHRE", cp : "36230" },
{ code : "36142", intitule : "NIHERNE", cp : "36250" },
{ code : "36143", intitule : "NOHANT VIC", cp : "36400" },
{ code : "36144", intitule : "NURET LE FERRON", cp : "36800" },
{ code : "36145", intitule : "OBTERRE", cp : "36290" },
{ code : "36146", intitule : "ORSENNES", cp : "36190" },
{ code : "36147", intitule : "ORVILLE", cp : "36210" },
{ code : "36148", intitule : "OULCHES", cp : "36800" },
{ code : "36149", intitule : "PALLUAU SUR INDRE", cp : "36500" },
{ code : "36150", intitule : "PARNAC", cp : "36170" },
{ code : "36152", intitule : "PAUDY", cp : "36260" },
{ code : "36153", intitule : "PAULNAY", cp : "36290" },
{ code : "36154", intitule : "LE PECHEREAU", cp : "36200" },
{ code : "36155", intitule : "PELLEVOISIN", cp : "36180" },
{ code : "36156", intitule : "PERASSAY", cp : "36160" },
{ code : "36157", intitule : "LA PEROUILLE", cp : "36350" },
{ code : "36158", intitule : "BADECON LE PIN", cp : "36200" },
{ code : "36159", intitule : "LE POINCONNET", cp : "36330" },
{ code : "36160", intitule : "POMMIERS", cp : "36190" },
{ code : "36161", intitule : "LE PONT CHRETIEN CHABENET", cp : "36800" },
{ code : "36162", intitule : "POULAINES", cp : "36210" },
{ code : "36163", intitule : "POULIGNY NOTRE DAME", cp : "36160" },
{ code : "36164", intitule : "POULIGNY ST MARTIN", cp : "36160" },
{ code : "36165", intitule : "POULIGNY ST PIERRE", cp : "36300" },
{ code : "36166", intitule : "PREAUX", cp : "36240" },
{ code : "36167", intitule : "PREUILLY LA VILLE", cp : "36220" },
{ code : "36168", intitule : "PRISSAC", cp : "36370" },
{ code : "36169", intitule : "PRUNIERS", cp : "36120" },
{ code : "36170", intitule : "REBOURSIN", cp : "36150" },
{ code : "36171", intitule : "REUILLY", cp : "36260" },
{ code : "36172", intitule : "RIVARENNES", cp : "36800" },
{ code : "36173", intitule : "ROSNAY", cp : "36300" },
{ code : "36174", intitule : "ROUSSINES", cp : "36170" },
{ code : "36175", intitule : "ROUVRES LES BOIS", cp : "36110" },
{ code : "36176", intitule : "RUFFEC", cp : "36300" },
{ code : "36177", intitule : "SACIERGES ST MARTIN", cp : "36170" },
{ code : "36178", intitule : "ST AIGNY", cp : "36300" },
{ code : "36179", intitule : "ST AOUSTRILLE", cp : "36100" },
{ code : "36180", intitule : "ST AOUT", cp : "36120" },
{ code : "36181", intitule : "ST AUBIN", cp : "36100" },
{ code : "36182", intitule : "ST BENOIT DU SAULT", cp : "36170" },
{ code : "36184", intitule : "ST CHARTIER", cp : "36400" },
{ code : "36185", intitule : "ST CHRISTOPHE EN BAZELLE", cp : "36210" },
{ code : "36186", intitule : "ST CHRISTOPHE EN BOUCHERIE", cp : "36400" },
{ code : "36187", intitule : "ST CIVRAN", cp : "36170" },
{ code : "36188", intitule : "ST CYRAN DU JAMBOT", cp : "36700" },
{ code : "36189", intitule : "ST DENIS DE JOUHET", cp : "36230" },
{ code : "36190", intitule : "STE FAUSTE", cp : "36100" },
{ code : "36191", intitule : "ST FLORENTIN", cp : "36150" },
{ code : "36192", intitule : "ST GAULTIER", cp : "36800" },
{ code : "36193", intitule : "STE GEMME", cp : "36500" },
{ code : "36194", intitule : "ST GENOU", cp : "36500" },
{ code : "36195", intitule : "ST GEORGES SUR ARNON", cp : "36100" },
{ code : "36196", intitule : "ST GILLES", cp : "36170" },
{ code : "36197", intitule : "ST HILAIRE SUR BENAIZE", cp : "36370" },
{ code : "36198", intitule : "ST LACTENCIN", cp : "36500" },
{ code : "36199", intitule : "STE LIZAIGNE", cp : "36260" },
{ code : "36200", intitule : "ST MARCEL", cp : "36200" },
{ code : "36202", intitule : "ST MAUR", cp : "36250" },
{ code : "36202", intitule : "ST MAUR", cp : "36250" },
{ code : "36203", intitule : "ST MEDARD", cp : "36700" },
{ code : "36204", intitule : "ST MICHEL EN BRENNE", cp : "36290" },
{ code : "36205", intitule : "ST PIERRE DE JARDS", cp : "36260" },
{ code : "36206", intitule : "ST PIERRE DE LAMPS", cp : "36110" },
{ code : "36207", intitule : "ST PLANTAIRE", cp : "36190" },
{ code : "36208", intitule : "STE SEVERE SUR INDRE", cp : "36160" },
{ code : "36209", intitule : "ST VALENTIN", cp : "36100" },
{ code : "36210", intitule : "SARZAY", cp : "36230" },
{ code : "36211", intitule : "SASSIERGES ST GERMAIN", cp : "36120" },
{ code : "36212", intitule : "SAULNAY", cp : "36290" },
{ code : "36213", intitule : "SAUZELLES", cp : "36220" },
{ code : "36214", intitule : "SAZERAY", cp : "36160" },
{ code : "36215", intitule : "SEGRY", cp : "36100" },
{ code : "36216", intitule : "SELLES SUR NAHON", cp : "36180" },
{ code : "36217", intitule : "SEMBLECAY", cp : "36210" },
{ code : "36218", intitule : "SOUGE", cp : "36500" },
{ code : "36219", intitule : "TENDU", cp : "36200" },
{ code : "36220", intitule : "THENAY", cp : "36800" },
{ code : "36221", intitule : "THEVET ST JULIEN", cp : "36400" },
{ code : "36222", intitule : "THIZAY", cp : "36100" },
{ code : "36223", intitule : "TILLY", cp : "36310" },
{ code : "36224", intitule : "TOURNON ST MARTIN", cp : "36220" },
{ code : "36225", intitule : "LE TRANGER", cp : "36700" },
{ code : "36226", intitule : "TRANZAULT", cp : "36230" },
{ code : "36227", intitule : "URCIERS", cp : "36160" },
{ code : "36228", intitule : "VALENCAY", cp : "36600" },
{ code : "36229", intitule : "VAL FOUZON", cp : "36210" },
{ code : "36229", intitule : "VAL FOUZON", cp : "36210" },
{ code : "36229", intitule : "VAL FOUZON", cp : "36210" },
{ code : "36230", intitule : "VATAN", cp : "36150" },
{ code : "36231", intitule : "VELLES", cp : "36330" },
{ code : "36232", intitule : "VENDOEUVRES", cp : "36500" },
{ code : "36233", intitule : "LA VERNELLE", cp : "36600" },
{ code : "36234", intitule : "VERNEUIL SUR IGNERAIE", cp : "36400" },
{ code : "36235", intitule : "VEUIL", cp : "36600" },
{ code : "36236", intitule : "VICQ EXEMPLET", cp : "36400" },
{ code : "36237", intitule : "VICQ SUR NAHON", cp : "36600" },
{ code : "36238", intitule : "VIGOULANT", cp : "36160" },
{ code : "36239", intitule : "VIGOUX", cp : "36170" },
{ code : "36240", intitule : "VIJON", cp : "36160" },
{ code : "36241", intitule : "VILLEDIEU SUR INDRE", cp : "36320" },
{ code : "36242", intitule : "VILLEGONGIS", cp : "36110" },
{ code : "36243", intitule : "VILLEGOUIN", cp : "36500" },
{ code : "36244", intitule : "VILLENTROIS", cp : "36600" },
{ code : "36246", intitule : "VILLIERS", cp : "36290" },
{ code : "36247", intitule : "VINEUIL", cp : "36110" },
{ code : "36248", intitule : "VOUILLON", cp : "36100" },
{ code : "37001", intitule : "ABILLY", cp : "37160" },
{ code : "37002", intitule : "AMBILLOU", cp : "37340" },
{ code : "37003", intitule : "AMBOISE", cp : "37400" },
{ code : "37004", intitule : "ANCHE", cp : "37500" },
{ code : "37005", intitule : "ANTOGNY LE TILLAC", cp : "37800" },
{ code : "37006", intitule : "ARTANNES SUR INDRE", cp : "37260" },
{ code : "37007", intitule : "ASSAY", cp : "37120" },
{ code : "37008", intitule : "ATHEE SUR CHER", cp : "37270" },
{ code : "37009", intitule : "AUTRECHE", cp : "37110" },
{ code : "37010", intitule : "AUZOUER EN TOURAINE", cp : "37110" },
{ code : "37011", intitule : "AVOINE", cp : "37420" },
{ code : "37012", intitule : "AVON LES ROCHES", cp : "37220" },
{ code : "37013", intitule : "AVRILLE LES PONCEAUX", cp : "37340" },
{ code : "37014", intitule : "AZAY LE RIDEAU", cp : "37190" },
{ code : "37015", intitule : "AZAY SUR CHER", cp : "37270" },
{ code : "37016", intitule : "AZAY SUR INDRE", cp : "37310" },
{ code : "37018", intitule : "BALLAN MIRE", cp : "37510" },
{ code : "37019", intitule : "BARROU", cp : "37350" },
{ code : "37020", intitule : "BEAULIEU LES LOCHES", cp : "37600" },
{ code : "37021", intitule : "BEAUMONT LA RONCE", cp : "37360" },
{ code : "37022", intitule : "BEAUMONT EN VERON", cp : "37420" },
{ code : "37023", intitule : "BEAUMONT VILLAGE", cp : "37460" },
{ code : "37024", intitule : "BENAIS", cp : "37140" },
{ code : "37025", intitule : "BERTHENAY", cp : "37510" },
{ code : "37026", intitule : "BETZ LE CHATEAU", cp : "37600" },
{ code : "37027", intitule : "BLERE", cp : "37150" },
{ code : "37028", intitule : "BOSSAY SUR CLAISE", cp : "37290" },
{ code : "37029", intitule : "BOSSEE", cp : "37240" },
{ code : "37030", intitule : "LE BOULAY", cp : "37110" },
{ code : "37031", intitule : "BOURGUEIL", cp : "37140" },
{ code : "37032", intitule : "BOURNAN", cp : "37240" },
{ code : "37033", intitule : "BOUSSAY", cp : "37290" },
{ code : "37034", intitule : "BRASLOU", cp : "37120" },
{ code : "37035", intitule : "BRAYE SOUS FAYE", cp : "37120" },
{ code : "37036", intitule : "BRAYE SUR MAULNE", cp : "37330" },
{ code : "37037", intitule : "BRECHES", cp : "37330" },
{ code : "37038", intitule : "BREHEMONT", cp : "37130" },
{ code : "37039", intitule : "BRIDORE", cp : "37600" },
{ code : "37040", intitule : "BRIZAY", cp : "37220" },
{ code : "37041", intitule : "BUEIL EN TOURAINE", cp : "37370" },
{ code : "37042", intitule : "CANDES ST MARTIN", cp : "37500" },
{ code : "37043", intitule : "CANGEY", cp : "37530" },
{ code : "37044", intitule : "LA CELLE GUENAND", cp : "37350" },
{ code : "37045", intitule : "LA CELLE ST AVANT", cp : "37160" },
{ code : "37046", intitule : "CERE LA RONDE", cp : "37460" },
{ code : "37047", intitule : "CERELLES", cp : "37390" },
{ code : "37048", intitule : "CHAMBON", cp : "37290" },
{ code : "37049", intitule : "CHAMBOURG SUR INDRE", cp : "37310" },
{ code : "37050", intitule : "CHAMBRAY LES TOURS", cp : "37170" },
{ code : "37051", intitule : "CHAMPIGNY SUR VEUDE", cp : "37120" },
{ code : "37052", intitule : "CHANCAY", cp : "37210" },
{ code : "37053", intitule : "CHANCEAUX PRES LOCHES", cp : "37600" },
{ code : "37054", intitule : "CHANCEAUX SUR CHOISILLE", cp : "37390" },
{ code : "37055", intitule : "CHANNAY SUR LATHAN", cp : "37330" },
{ code : "37056", intitule : "LA CHAPELLE AUX NAUX", cp : "37130" },
{ code : "37057", intitule : "LA CHAPELLE BLANCHE ST MARTIN", cp : "37240" },
{ code : "37058", intitule : "LA CHAPELLE SUR LOIRE", cp : "37140" },
{ code : "37059", intitule : "CHARENTILLY", cp : "37390" },
{ code : "37060", intitule : "CHARGE", cp : "37530" },
{ code : "37061", intitule : "CHARNIZAY", cp : "37290" },
{ code : "37062", intitule : "CHATEAU LA VALLIERE", cp : "37330" },
{ code : "37063", intitule : "CHATEAU RENAULT", cp : "37110" },
{ code : "37064", intitule : "CHAUMUSSAY", cp : "37350" },
{ code : "37065", intitule : "CHAVEIGNES", cp : "37120" },
{ code : "37066", intitule : "CHEDIGNY", cp : "37310" },
{ code : "37067", intitule : "CHEILLE", cp : "37190" },
{ code : "37068", intitule : "CHEMILLE SUR DEME", cp : "37370" },
{ code : "37069", intitule : "CHEMILLE SUR INDROIS", cp : "37460" },
{ code : "37070", intitule : "CHENONCEAUX", cp : "37150" },
{ code : "37071", intitule : "CHEZELLES", cp : "37220" },
{ code : "37072", intitule : "CHINON", cp : "37500" },
{ code : "37073", intitule : "CHISSEAUX", cp : "37150" },
{ code : "37074", intitule : "CHOUZE SUR LOIRE", cp : "37140" },
{ code : "37075", intitule : "CIGOGNE", cp : "37310" },
{ code : "37076", intitule : "CINAIS", cp : "37500" },
{ code : "37077", intitule : "CINQ MARS LA PILE", cp : "37130" },
{ code : "37078", intitule : "CIRAN", cp : "37240" },
{ code : "37079", intitule : "CIVRAY DE TOURAINE", cp : "37150" },
{ code : "37080", intitule : "CIVRAY SUR ESVES", cp : "37160" },
{ code : "37081", intitule : "CLERE LES PINS", cp : "37340" },
{ code : "37082", intitule : "CONTINVOIR", cp : "37340" },
{ code : "37083", intitule : "CORMERY", cp : "37320" },
{ code : "37084", intitule : "COUESMES", cp : "37330" },
{ code : "37085", intitule : "COURCAY", cp : "37310" },
{ code : "37086", intitule : "COURCELLES DE TOURAINE", cp : "37330" },
{ code : "37087", intitule : "COURCOUE", cp : "37120" },
{ code : "37088", intitule : "COUZIERS", cp : "37500" },
{ code : "37089", intitule : "CRAVANT LES COTEAUX", cp : "37500" },
{ code : "37090", intitule : "CRISSAY SUR MANSE", cp : "37220" },
{ code : "37091", intitule : "LA CROIX EN TOURAINE", cp : "37150" },
{ code : "37092", intitule : "CROTELLES", cp : "37380" },
{ code : "37093", intitule : "CROUZILLES", cp : "37220" },
{ code : "37094", intitule : "CUSSAY", cp : "37240" },
{ code : "37095", intitule : "DAME MARIE LES BOIS", cp : "37110" },
{ code : "37096", intitule : "DIERRE", cp : "37150" },
{ code : "37097", intitule : "DOLUS LE SEC", cp : "37310" },
{ code : "37098", intitule : "DRACHE", cp : "37800" },
{ code : "37099", intitule : "DRUYE", cp : "37190" },
{ code : "37100", intitule : "EPEIGNE LES BOIS", cp : "37150" },
{ code : "37101", intitule : "EPEIGNE SUR DEME", cp : "37370" },
{ code : "37102", intitule : "LES ESSARDS", cp : "37130" },
{ code : "37103", intitule : "ESVES LE MOUTIER", cp : "37240" },
{ code : "37104", intitule : "ESVRES", cp : "37320" },
{ code : "37105", intitule : "FAYE LA VINEUSE", cp : "37120" },
{ code : "37106", intitule : "LA FERRIERE", cp : "37110" },
{ code : "37107", intitule : "FERRIERE LARCON", cp : "37350" },
{ code : "37108", intitule : "FERRIERE SUR BEAULIEU", cp : "37600" },
{ code : "37109", intitule : "FONDETTES", cp : "37230" },
{ code : "37110", intitule : "FRANCUEIL", cp : "37150" },
{ code : "37111", intitule : "GENILLE", cp : "37460" },
{ code : "37112", intitule : "GIZEUX", cp : "37340" },
{ code : "37113", intitule : "LE GRAND PRESSIGNY", cp : "37350" },
{ code : "37114", intitule : "LA GUERCHE", cp : "37350" },
{ code : "37115", intitule : "DESCARTES", cp : "37160" },
{ code : "37116", intitule : "LES HERMITES", cp : "37110" },
{ code : "37117", intitule : "HOMMES", cp : "37340" },
{ code : "37118", intitule : "HUISMES", cp : "37420" },
{ code : "37119", intitule : "L ILE BOUCHARD", cp : "37220" },
{ code : "37120", intitule : "INGRANDES DE TOURAINE", cp : "37140" },
{ code : "37121", intitule : "JAULNAY", cp : "37120" },
{ code : "37122", intitule : "JOUE LES TOURS", cp : "37300" },
{ code : "37123", intitule : "LANGEAIS", cp : "37130" },
{ code : "37124", intitule : "LARCAY", cp : "37270" },
{ code : "37125", intitule : "LEMERE", cp : "37120" },
{ code : "37126", intitule : "LERNE", cp : "37500" },
{ code : "37127", intitule : "LE LIEGE", cp : "37460" },
{ code : "37128", intitule : "LIGNIERES DE TOURAINE", cp : "37130" },
{ code : "37129", intitule : "LIGRE", cp : "37500" },
{ code : "37130", intitule : "LIGUEIL", cp : "37240" },
{ code : "37131", intitule : "LIMERAY", cp : "37530" },
{ code : "37132", intitule : "LOCHES", cp : "37600" },
{ code : "37133", intitule : "LOCHE SUR INDROIS", cp : "37460" },
{ code : "37134", intitule : "LOUANS", cp : "37320" },
{ code : "37135", intitule : "LOUESTAULT", cp : "37370" },
{ code : "37136", intitule : "LE LOUROUX", cp : "37240" },
{ code : "37137", intitule : "LUBLE", cp : "37330" },
{ code : "37138", intitule : "LUSSAULT SUR LOIRE", cp : "37400" },
{ code : "37139", intitule : "LUYNES", cp : "37230" },
{ code : "37140", intitule : "LUZE", cp : "37120" },
{ code : "37141", intitule : "LUZILLE", cp : "37150" },
{ code : "37142", intitule : "MAILLE", cp : "37800" },
{ code : "37143", intitule : "MANTHELAN", cp : "37240" },
{ code : "37144", intitule : "MARCAY", cp : "37500" },
{ code : "37145", intitule : "MARCE SUR ESVES", cp : "37160" },
{ code : "37146", intitule : "MARCILLY SUR MAULNE", cp : "37330" },
{ code : "37147", intitule : "MARCILLY SUR VIENNE", cp : "37800" },
{ code : "37148", intitule : "MARIGNY MARMANDE", cp : "37120" },
{ code : "37149", intitule : "MARRAY", cp : "37370" },
{ code : "37150", intitule : "MAZIERES DE TOURAINE", cp : "37130" },
{ code : "37151", intitule : "LA MEMBROLLE SUR CHOISILLE", cp : "37390" },
{ code : "37152", intitule : "METTRAY", cp : "37390" },
{ code : "37153", intitule : "MONNAIE", cp : "37380" },
{ code : "37154", intitule : "MONTBAZON", cp : "37250" },
{ code : "37155", intitule : "MONTHODON", cp : "37110" },
{ code : "37156", intitule : "MONTLOUIS SUR LOIRE", cp : "37270" },
{ code : "37157", intitule : "MONTRESOR", cp : "37460" },
{ code : "37158", intitule : "MONTREUIL EN TOURAINE", cp : "37530" },
{ code : "37159", intitule : "MONTS", cp : "37260" },
{ code : "37160", intitule : "MORAND", cp : "37110" },
{ code : "37161", intitule : "MOSNES", cp : "37530" },
{ code : "37162", intitule : "MOUZAY", cp : "37600" },
{ code : "37163", intitule : "NAZELLES NEGRON", cp : "37530" },
{ code : "37165", intitule : "NEUIL", cp : "37190" },
{ code : "37166", intitule : "NEUILLE LE LIERRE", cp : "37380" },
{ code : "37167", intitule : "NEUILLE PONT PIERRE", cp : "37360" },
{ code : "37168", intitule : "NEUILLY LE BRIGNON", cp : "37160" },
{ code : "37169", intitule : "NEUVILLE SUR BRENNE", cp : "37110" },
{ code : "37170", intitule : "NEUVY LE ROI", cp : "37370" },
{ code : "37171", intitule : "NOIZAY", cp : "37210" },
{ code : "37172", intitule : "NOTRE DAME D OE", cp : "37390" },
{ code : "37173", intitule : "NOUANS LES FONTAINES", cp : "37460" },
{ code : "37174", intitule : "NOUATRE", cp : "37800" },
{ code : "37175", intitule : "NOUZILLY", cp : "37380" },
{ code : "37176", intitule : "NOYANT DE TOURAINE", cp : "37800" },
{ code : "37177", intitule : "ORBIGNY", cp : "37460" },
{ code : "37178", intitule : "PANZOULT", cp : "37220" },
{ code : "37179", intitule : "PARCAY MESLAY", cp : "37210" },
{ code : "37180", intitule : "PARCAY SUR VIENNE", cp : "37220" },
{ code : "37181", intitule : "PAULMY", cp : "37350" },
{ code : "37182", intitule : "PERNAY", cp : "37230" },
{ code : "37183", intitule : "PERRUSSON", cp : "37600" },
{ code : "37184", intitule : "LE PETIT PRESSIGNY", cp : "37350" },
{ code : "37185", intitule : "POCE SUR CISSE", cp : "37530" },
{ code : "37186", intitule : "PONT DE RUAN", cp : "37260" },
{ code : "37187", intitule : "PORTS", cp : "37800" },
{ code : "37188", intitule : "POUZAY", cp : "37800" },
{ code : "37189", intitule : "PREUILLY SUR CLAISE", cp : "37290" },
{ code : "37190", intitule : "PUSSIGNY", cp : "37800" },
{ code : "37191", intitule : "RAZINES", cp : "37120" },
{ code : "37192", intitule : "REIGNAC SUR INDRE", cp : "37310" },
{ code : "37193", intitule : "RESTIGNE", cp : "37140" },
{ code : "37194", intitule : "REUGNY", cp : "37380" },
{ code : "37195", intitule : "LA RICHE", cp : "37520" },
{ code : "37196", intitule : "RICHELIEU", cp : "37120" },
{ code : "37197", intitule : "RIGNY USSE", cp : "37420" },
{ code : "37198", intitule : "RILLE", cp : "37340" },
{ code : "37199", intitule : "RILLY SUR VIENNE", cp : "37220" },
{ code : "37200", intitule : "RIVARENNES", cp : "37190" },
{ code : "37201", intitule : "RIVIERE", cp : "37500" },
{ code : "37202", intitule : "LA ROCHE CLERMAULT", cp : "37500" },
{ code : "37203", intitule : "ROCHECORBON", cp : "37210" },
{ code : "37204", intitule : "ROUZIERS DE TOURAINE", cp : "37360" },
{ code : "37205", intitule : "SACHE", cp : "37190" },
{ code : "37206", intitule : "ST ANTOINE DU ROCHER", cp : "37360" },
{ code : "37207", intitule : "ST AUBIN LE DEPEINT", cp : "37370" },
{ code : "37208", intitule : "ST AVERTIN", cp : "37550" },
{ code : "37209", intitule : "ST BAULD", cp : "37310" },
{ code : "37210", intitule : "ST BENOIT LA FORET", cp : "37500" },
{ code : "37211", intitule : "ST BRANCHS", cp : "37320" },
{ code : "37212", intitule : "STE CATHERINE DE FIERBOIS", cp : "37800" },
{ code : "37213", intitule : "ST CHRISTOPHE SUR LE NAIS", cp : "37370" },
{ code : "37214", intitule : "ST CYR SUR LOIRE", cp : "37540" },
{ code : "37216", intitule : "ST EPAIN", cp : "37800" },
{ code : "37217", intitule : "ST ETIENNE DE CHIGNY", cp : "37230" },
{ code : "37218", intitule : "ST FLOVIER", cp : "37600" },
{ code : "37219", intitule : "ST GENOUPH", cp : "37510" },
{ code : "37220", intitule : "ST GERMAIN SUR VIENNE", cp : "37500" },
{ code : "37221", intitule : "ST HIPPOLYTE", cp : "37600" },
{ code : "37222", intitule : "ST JEAN ST GERMAIN", cp : "37600" },
{ code : "37223", intitule : "ST LAURENT DE LIN", cp : "37330" },
{ code : "37224", intitule : "ST LAURENT EN GATINES", cp : "37380" },
{ code : "37225", intitule : "ST MARTIN LE BEAU", cp : "37270" },
{ code : "37226", intitule : "STE MAURE DE TOURAINE", cp : "37800" },
{ code : "37227", intitule : "ST MICHEL SUR LOIRE", cp : "37130" },
{ code : "37228", intitule : "ST NICOLAS DE BOURGUEIL", cp : "37140" },
{ code : "37229", intitule : "ST NICOLAS DES MOTETS", cp : "37110" },
{ code : "37230", intitule : "ST OUEN LES VIGNES", cp : "37530" },
{ code : "37231", intitule : "ST PATERNE RACAN", cp : "37370" },
{ code : "37232", intitule : "ST PATRICE", cp : "37130" },
{ code : "37233", intitule : "ST PIERRE DES CORPS", cp : "37700" },
{ code : "37234", intitule : "ST QUENTIN SUR INDROIS", cp : "37310" },
{ code : "37236", intitule : "ST REGLE", cp : "37530" },
{ code : "37237", intitule : "ST ROCH", cp : "37390" },
{ code : "37238", intitule : "ST SENOCH", cp : "37600" },
{ code : "37240", intitule : "SAUNAY", cp : "37110" },
{ code : "37241", intitule : "SAVIGNE SUR LATHAN", cp : "37340" },
{ code : "37242", intitule : "SAVIGNY EN VERON", cp : "37420" },
{ code : "37243", intitule : "SAVONNIERES", cp : "37510" },
{ code : "37244", intitule : "SAZILLY", cp : "37220" },
{ code : "37245", intitule : "SEMBLANCAY", cp : "37360" },
{ code : "37246", intitule : "SENNEVIERES", cp : "37600" },
{ code : "37247", intitule : "SEPMES", cp : "37800" },
{ code : "37248", intitule : "SEUILLY", cp : "37500" },
{ code : "37249", intitule : "SONZAY", cp : "37360" },
{ code : "37250", intitule : "SORIGNY", cp : "37250" },
{ code : "37251", intitule : "SOUVIGNE", cp : "37330" },
{ code : "37252", intitule : "SOUVIGNY DE TOURAINE", cp : "37530" },
{ code : "37253", intitule : "SUBLAINES", cp : "37310" },
{ code : "37254", intitule : "TAUXIGNY", cp : "37310" },
{ code : "37255", intitule : "TAVANT", cp : "37220" },
{ code : "37256", intitule : "THENEUIL", cp : "37220" },
{ code : "37257", intitule : "THILOUZE", cp : "37260" },
{ code : "37258", intitule : "THIZAY", cp : "37500" },
{ code : "37259", intitule : "TOURNON ST PIERRE", cp : "37290" },
{ code : "37260", intitule : "LA TOUR ST GELIN", cp : "37120" },
{ code : "37261", intitule : "TOURS", cp : "37000" },
{ code : "37261", intitule : "TOURS", cp : "37100" },
{ code : "37261", intitule : "TOURS", cp : "37200" },
{ code : "37262", intitule : "TROGUES", cp : "37220" },
{ code : "37263", intitule : "TRUYES", cp : "37320" },
{ code : "37264", intitule : "VALLERES", cp : "37190" },
{ code : "37265", intitule : "VARENNES", cp : "37600" },
{ code : "37266", intitule : "VEIGNE", cp : "37250" },
{ code : "37267", intitule : "VERETZ", cp : "37270" },
{ code : "37268", intitule : "VERNEUIL LE CHATEAU", cp : "37120" },
{ code : "37269", intitule : "VERNEUIL SUR INDRE", cp : "37600" },
{ code : "37270", intitule : "VERNOU SUR BRENNE", cp : "37210" },
{ code : "37271", intitule : "VILLAINES LES ROCHERS", cp : "37190" },
{ code : "37272", intitule : "VILLANDRY", cp : "37510" },
{ code : "37273", intitule : "LA VILLE AUX DAMES", cp : "37700" },
{ code : "37274", intitule : "VILLEBOURG", cp : "37370" },
{ code : "37275", intitule : "VILLEDOMAIN", cp : "37460" },
{ code : "37276", intitule : "VILLEDOMER", cp : "37110" },
{ code : "37277", intitule : "VILLELOIN COULANGE", cp : "37460" },
{ code : "37278", intitule : "VILLEPERDUE", cp : "37260" },
{ code : "37279", intitule : "VILLIERS AU BOUIN", cp : "37330" },
{ code : "37280", intitule : "VOU", cp : "37240" },
{ code : "37281", intitule : "VOUVRAY", cp : "37210" },
{ code : "37282", intitule : "YZEURES SUR CREUSE", cp : "37290" },
{ code : "38001", intitule : "LES ABRETS EN DAUPHINE", cp : "38490" },
{ code : "38001", intitule : "LES ABRETS EN DAUPHINE", cp : "38490" },
{ code : "38001", intitule : "LES ABRETS EN DAUPHINE", cp : "38490" },
{ code : "38002", intitule : "LES ADRETS", cp : "38190" },
{ code : "38002", intitule : "LES ADRETS", cp : "38190" },
{ code : "38003", intitule : "AGNIN", cp : "38150" },
{ code : "38004", intitule : "L ALBENC", cp : "38470" },
{ code : "38005", intitule : "ALLEMOND", cp : "38114" },
{ code : "38006", intitule : "ALLEVARD", cp : "38580" },
{ code : "38008", intitule : "AMBEL", cp : "38970" },
{ code : "38009", intitule : "ANJOU", cp : "38150" },
{ code : "38010", intitule : "ANNOISIN CHATELANS", cp : "38460" },
{ code : "38011", intitule : "ANTHON", cp : "38280" },
{ code : "38012", intitule : "AOSTE", cp : "38490" },
{ code : "38013", intitule : "APPRIEU", cp : "38140" },
{ code : "38013", intitule : "APPRIEU", cp : "38140" },
{ code : "38014", intitule : "ARANDON", cp : "38510" },
{ code : "38015", intitule : "ARTAS", cp : "38440" },
{ code : "38016", intitule : "ARZAY", cp : "38260" },
{ code : "38017", intitule : "ASSIEU", cp : "38150" },
{ code : "38018", intitule : "AUBERIVES EN ROYANS", cp : "38680" },
{ code : "38019", intitule : "AUBERIVES SUR VAREZE", cp : "38550" },
{ code : "38020", intitule : "AURIS", cp : "38142" },
{ code : "38022", intitule : "LES AVENIERES VEYRINS THUELLIN", cp : "38630" },
{ code : "38022", intitule : "LES AVENIERES VEYRINS THUELLIN", cp : "38630" },
{ code : "38022", intitule : "LES AVENIERES VEYRINS THUELLIN", cp : "38630" },
{ code : "38023", intitule : "AVIGNONET", cp : "38650" },
{ code : "38025", intitule : "BALBINS", cp : "38260" },
{ code : "38026", intitule : "LA BALME LES GROTTES", cp : "38390" },
{ code : "38027", intitule : "BARRAUX", cp : "38530" },
{ code : "38029", intitule : "LA BATIE MONTGASCON", cp : "38110" },
{ code : "38030", intitule : "BEAUCROISSANT", cp : "38140" },
{ code : "38031", intitule : "BEAUFIN", cp : "38970" },
{ code : "38032", intitule : "BEAUFORT", cp : "38270" },
{ code : "38033", intitule : "BEAULIEU", cp : "38470" },
{ code : "38034", intitule : "BEAUREPAIRE", cp : "38270" },
{ code : "38035", intitule : "BEAUVOIR DE MARC", cp : "38440" },
{ code : "38036", intitule : "BEAUVOIR EN ROYANS", cp : "38160" },
{ code : "38037", intitule : "BELLEGARDE POUSSIEU", cp : "38270" },
{ code : "38038", intitule : "BELMONT", cp : "38690" },
{ code : "38039", intitule : "BERNIN", cp : "38190" },
{ code : "38040", intitule : "BESSE", cp : "38142" },
{ code : "38041", intitule : "BESSINS", cp : "38160" },
{ code : "38042", intitule : "BEVENAIS", cp : "38690" },
{ code : "38043", intitule : "BILIEU", cp : "38850" },
{ code : "38044", intitule : "BIOL", cp : "38690" },
{ code : "38045", intitule : "BIVIERS", cp : "38330" },
{ code : "38046", intitule : "BIZONNES", cp : "38690" },
{ code : "38047", intitule : "BLANDIN", cp : "38730" },
{ code : "38048", intitule : "BONNEFAMILLE", cp : "38090" },
{ code : "38049", intitule : "BOSSIEU", cp : "38260" },
{ code : "38050", intitule : "LE BOUCHAGE", cp : "38510" },
{ code : "38051", intitule : "BOUGE CHAMBALUD", cp : "38150" },
{ code : "38052", intitule : "LE BOURG D OISANS", cp : "38520" },
{ code : "38053", intitule : "BOURGOIN JALLIEU", cp : "38300" },
{ code : "38053", intitule : "BOURGOIN JALLIEU", cp : "38300" },
{ code : "38054", intitule : "BOUVESSE QUIRIEU", cp : "38390" },
{ code : "38055", intitule : "BRANGUES", cp : "38510" },
{ code : "38056", intitule : "BRESSIEUX", cp : "38870" },
{ code : "38057", intitule : "BRESSON", cp : "38320" },
{ code : "38058", intitule : "BREZINS", cp : "38590" },
{ code : "38059", intitule : "BRIE ET ANGONNES", cp : "38320" },
{ code : "38060", intitule : "BRION", cp : "38590" },
{ code : "38061", intitule : "LA BUISSE", cp : "38500" },
{ code : "38062", intitule : "LA BUISSIERE", cp : "38530" },
{ code : "38063", intitule : "BURCIN", cp : "38690" },
{ code : "38064", intitule : "CESSIEU", cp : "38110" },
{ code : "38065", intitule : "CHABONS", cp : "38690" },
{ code : "38066", intitule : "CHALON", cp : "38122" },
{ code : "38067", intitule : "CHAMAGNIEU", cp : "38460" },
{ code : "38067", intitule : "CHAMAGNIEU", cp : "38460" },
{ code : "38068", intitule : "CHAMPAGNIER", cp : "38800" },
{ code : "38069", intitule : "CHAMPIER", cp : "38260" },
{ code : "38070", intitule : "LE CHAMP PRES FROGES", cp : "38190" },
{ code : "38071", intitule : "CHAMP SUR DRAC", cp : "38560" },
{ code : "38072", intitule : "CHANAS", cp : "38150" },
{ code : "38073", intitule : "CHANTELOUVE", cp : "38740" },
{ code : "38074", intitule : "CHANTESSE", cp : "38470" },
{ code : "38075", intitule : "CHAPAREILLAN", cp : "38530" },
{ code : "38076", intitule : "LA CHAPELLE DE LA TOUR", cp : "38110" },
{ code : "38077", intitule : "LA CHAPELLE DE SURIEU", cp : "38150" },
{ code : "38078", intitule : "LA CHAPELLE DU BARD", cp : "38580" },
{ code : "38080", intitule : "CHARANCIEU", cp : "38490" },
{ code : "38081", intitule : "CHARANTONNAY", cp : "38790" },
{ code : "38082", intitule : "CHARAVINES", cp : "38850" },
{ code : "38083", intitule : "CHARETTE", cp : "38390" },
{ code : "38084", intitule : "CHARNECLES", cp : "38140" },
{ code : "38085", intitule : "CHARVIEU CHAVAGNEUX", cp : "38230" },
{ code : "38085", intitule : "CHARVIEU CHAVAGNEUX", cp : "38230" },
{ code : "38086", intitule : "CHASSELAY", cp : "38470" },
{ code : "38087", intitule : "CHASSE SUR RHONE", cp : "38670" },
{ code : "38089", intitule : "CHASSIGNIEU", cp : "38730" },
{ code : "38090", intitule : "CHATEAU BERNARD", cp : "38650" },
{ code : "38091", intitule : "CHATEAUVILAIN", cp : "38300" },
{ code : "38092", intitule : "CHATELUS", cp : "38680" },
{ code : "38093", intitule : "CHATENAY", cp : "38980" },
{ code : "38094", intitule : "CHATONNAY", cp : "38440" },
{ code : "38095", intitule : "CHATTE", cp : "38160" },
{ code : "38097", intitule : "CHAVANOZ", cp : "38230" },
{ code : "38098", intitule : "CHELIEU", cp : "38730" },
{ code : "38099", intitule : "CHEVRIERES", cp : "38160" },
{ code : "38100", intitule : "LE CHEYLAS", cp : "38570" },
{ code : "38101", intitule : "CHEYSSIEU", cp : "38550" },
{ code : "38102", intitule : "CHEZENEUVE", cp : "38300" },
{ code : "38103", intitule : "CHICHILIANNE", cp : "38930" },
{ code : "38104", intitule : "CHIMILIN", cp : "38490" },
{ code : "38105", intitule : "CHIRENS", cp : "38850" },
{ code : "38106", intitule : "CHOLONGE", cp : "38220" },
{ code : "38107", intitule : "CHONAS L AMBALLAN", cp : "38121" },
{ code : "38108", intitule : "CHORANCHE", cp : "38680" },
{ code : "38109", intitule : "CHOZEAU", cp : "38460" },
{ code : "38110", intitule : "CHUZELLES", cp : "38200" },
{ code : "38111", intitule : "CLAIX", cp : "38640" },
{ code : "38112", intitule : "CLAVANS EN HAUT OISANS", cp : "38142" },
{ code : "38113", intitule : "CLELLES", cp : "38930" },
{ code : "38114", intitule : "CLONAS SUR VAREZE", cp : "38550" },
{ code : "38115", intitule : "ST MARTIN DE LA CLUZE", cp : "38650" },
{ code : "38116", intitule : "COGNET", cp : "38350" },
{ code : "38117", intitule : "COGNIN LES GORGES", cp : "38470" },
{ code : "38118", intitule : "COLOMBE", cp : "38690" },
{ code : "38120", intitule : "LA COMBE DE LANCEY", cp : "38190" },
{ code : "38121", intitule : "COMMELLE", cp : "38260" },
{ code : "38124", intitule : "CORBELIN", cp : "38630" },
{ code : "38125", intitule : "CORDEAC", cp : "38710" },
{ code : "38126", intitule : "CORENC", cp : "38700" },
{ code : "38127", intitule : "CORNILLON EN TRIEVES", cp : "38710" },
{ code : "38128", intitule : "CORPS", cp : "38970" },
{ code : "38129", intitule : "CORRENCON EN VERCORS", cp : "38250" },
{ code : "38130", intitule : "LA COTE ST ANDRE", cp : "38260" },
{ code : "38131", intitule : "LES COTES D AREY", cp : "38138" },
{ code : "38132", intitule : "LES COTES DE CORPS", cp : "38970" },
{ code : "38133", intitule : "COUBLEVIE", cp : "38500" },
{ code : "38134", intitule : "COUR ET BUIS", cp : "38122" },
{ code : "38135", intitule : "COURTENAY", cp : "38510" },
{ code : "38136", intitule : "CRACHIER", cp : "38300" },
{ code : "38137", intitule : "CRAS", cp : "38210" },
{ code : "38138", intitule : "CREMIEU", cp : "38460" },
{ code : "38139", intitule : "CREYS MEPIEU", cp : "38510" },
{ code : "38139", intitule : "CREYS MEPIEU", cp : "38510" },
{ code : "38139", intitule : "CREYS MEPIEU", cp : "38510" },
{ code : "38140", intitule : "CROLLES", cp : "38920" },
{ code : "38141", intitule : "CULIN", cp : "38300" },
{ code : "38144", intitule : "DIEMOZ", cp : "38790" },
{ code : "38146", intitule : "DIZIMIEU", cp : "38460" },
{ code : "38147", intitule : "DOISSIN", cp : "38730" },
{ code : "38148", intitule : "DOLOMIEU", cp : "38110" },
{ code : "38149", intitule : "DOMARIN", cp : "38300" },
{ code : "38150", intitule : "DOMENE", cp : "38420" },
{ code : "38151", intitule : "ECHIROLLES", cp : "38130" },
{ code : "38152", intitule : "ECLOSE BADINIERES", cp : "38300" },
{ code : "38152", intitule : "ECLOSE BADINIERES", cp : "38300" },
{ code : "38153", intitule : "ENGINS", cp : "38360" },
{ code : "38154", intitule : "ENTRAIGUES", cp : "38740" },
{ code : "38155", intitule : "ENTRE DEUX GUIERS", cp : "38380" },
{ code : "38156", intitule : "LES EPARRES", cp : "38300" },
{ code : "38157", intitule : "ESTRABLIN", cp : "38780" },
{ code : "38158", intitule : "EYBENS", cp : "38320" },
{ code : "38159", intitule : "EYDOCHE", cp : "38690" },
{ code : "38160", intitule : "EYZIN PINET", cp : "38780" },
{ code : "38161", intitule : "FARAMANS", cp : "38260" },
{ code : "38162", intitule : "FAVERGES DE LA TOUR", cp : "38110" },
{ code : "38163", intitule : "LA FERRIERE", cp : "38580" },
{ code : "38163", intitule : "LA FERRIERE", cp : "38580" },
{ code : "38166", intitule : "LA FLACHERE", cp : "38530" },
{ code : "38167", intitule : "FLACHERES", cp : "38690" },
{ code : "38169", intitule : "FONTAINE", cp : "38600" },
{ code : "38170", intitule : "FONTANIL CORNILLON", cp : "38120" },
{ code : "38171", intitule : "LA FORTERESSE", cp : "38590" },
{ code : "38172", intitule : "FOUR", cp : "38080" },
{ code : "38173", intitule : "LE FRENEY D OISANS", cp : "38142" },
{ code : "38174", intitule : "LA FRETTE", cp : "38260" },
{ code : "38175", intitule : "FROGES", cp : "38190" },
{ code : "38176", intitule : "FRONTONAS", cp : "38290" },
{ code : "38176", intitule : "FRONTONAS", cp : "38290" },
{ code : "38177", intitule : "LA GARDE", cp : "38520" },
{ code : "38179", intitule : "GIERES", cp : "38610" },
{ code : "38180", intitule : "GILLONNAY", cp : "38260" },
{ code : "38181", intitule : "GONCELIN", cp : "38570" },
{ code : "38182", intitule : "LE GRAND LEMPS", cp : "38690" },
{ code : "38183", intitule : "GRANIEU", cp : "38490" },
{ code : "38184", intitule : "GRENAY", cp : "38540" },
{ code : "38185", intitule : "GRENOBLE", cp : "38100" },
{ code : "38185", intitule : "GRENOBLE", cp : "38000" },
{ code : "38186", intitule : "GRESSE EN VERCORS", cp : "38650" },
{ code : "38187", intitule : "LE GUA", cp : "38450" },
{ code : "38188", intitule : "HERBEYS", cp : "38320" },
{ code : "38189", intitule : "HEYRIEUX", cp : "38540" },
{ code : "38190", intitule : "HIERES SUR AMBY", cp : "38118" },
{ code : "38191", intitule : "HUEZ", cp : "38750" },
{ code : "38191", intitule : "HUEZ", cp : "38750" },
{ code : "38192", intitule : "HURTIERES", cp : "38570" },
{ code : "38193", intitule : "L ISLE D ABEAU", cp : "38080" },
{ code : "38194", intitule : "IZEAUX", cp : "38140" },
{ code : "38195", intitule : "IZERON", cp : "38160" },
{ code : "38197", intitule : "JANNEYRIAS", cp : "38280" },
{ code : "38198", intitule : "JARCIEU", cp : "38270" },
{ code : "38199", intitule : "JARDIN", cp : "38200" },
{ code : "38200", intitule : "JARRIE", cp : "38560" },
{ code : "38203", intitule : "LAFFREY", cp : "38220" },
{ code : "38204", intitule : "LALLEY", cp : "38930" },
{ code : "38205", intitule : "LANS EN VERCORS", cp : "38250" },
{ code : "38206", intitule : "LAVAL", cp : "38190" },
{ code : "38207", intitule : "LAVALDENS", cp : "38350" },
{ code : "38208", intitule : "LAVARS", cp : "38710" },
{ code : "38209", intitule : "LENTIOL", cp : "38270" },
{ code : "38210", intitule : "LEYRIEU", cp : "38460" },
{ code : "38211", intitule : "LIEUDIEU", cp : "38440" },
{ code : "38212", intitule : "LIVET ET GAVET", cp : "38220" },
{ code : "38212", intitule : "LIVET ET GAVET", cp : "38220" },
{ code : "38212", intitule : "LIVET ET GAVET", cp : "38220" },
{ code : "38212", intitule : "LIVET ET GAVET", cp : "38220" },
{ code : "38213", intitule : "LONGECHENAL", cp : "38690" },
{ code : "38214", intitule : "LUMBIN", cp : "38660" },
{ code : "38215", intitule : "LUZINAY", cp : "38200" },
{ code : "38216", intitule : "MALLEVAL EN VERCORS", cp : "38470" },
{ code : "38217", intitule : "MARCIEU", cp : "38350" },
{ code : "38218", intitule : "MARCILLOLES", cp : "38260" },
{ code : "38219", intitule : "MARCOLLIN", cp : "38270" },
{ code : "38221", intitule : "MARNANS", cp : "38980" },
{ code : "38222", intitule : "MASSIEU", cp : "38620" },
{ code : "38223", intitule : "MAUBEC", cp : "38300" },
{ code : "38224", intitule : "MAYRES SAVEL", cp : "38350" },
{ code : "38225", intitule : "AUTRANS MEAUDRE EN VERCORS", cp : "38880" },
{ code : "38225", intitule : "AUTRANS MEAUDRE EN VERCORS", cp : "38112" },
{ code : "38226", intitule : "MENS", cp : "38710" },
{ code : "38226", intitule : "MENS", cp : "38710" },
{ code : "38228", intitule : "MERLAS", cp : "38620" },
{ code : "38229", intitule : "MEYLAN", cp : "38240" },
{ code : "38230", intitule : "MEYRIE", cp : "38300" },
{ code : "38231", intitule : "MEYRIEU LES ETANGS", cp : "38440" },
{ code : "38232", intitule : "MEYSSIEZ", cp : "38440" },
{ code : "38235", intitule : "MIRIBEL LANCHATRE", cp : "38450" },
{ code : "38236", intitule : "MIRIBEL LES ECHELLES", cp : "38380" },
{ code : "38237", intitule : "MIZOEN", cp : "38142" },
{ code : "38238", intitule : "MOIDIEU DETOURBE", cp : "38440" },
{ code : "38239", intitule : "MOIRANS", cp : "38430" },
{ code : "38240", intitule : "MOISSIEU SUR DOLON", cp : "38270" },
{ code : "38241", intitule : "MONESTIER D AMBEL", cp : "38970" },
{ code : "38242", intitule : "MONESTIER DE CLERMONT", cp : "38650" },
{ code : "38243", intitule : "LE MONESTIER DU PERCY", cp : "38930" },
{ code : "38244", intitule : "MONSTEROUX MILIEU", cp : "38122" },
{ code : "38245", intitule : "MONTAGNE", cp : "38160" },
{ code : "38246", intitule : "MONTAGNIEU", cp : "38110" },
{ code : "38247", intitule : "MONTALIEU VERCIEU", cp : "38390" },
{ code : "38248", intitule : "MONTAUD", cp : "38210" },
{ code : "38249", intitule : "MONTBONNOT ST MARTIN", cp : "38330" },
{ code : "38250", intitule : "MONTCARRA", cp : "38890" },
{ code : "38252", intitule : "MONTCHABOUD", cp : "38220" },
{ code : "38253", intitule : "MONT DE LANS", cp : "38860" },
{ code : "38253", intitule : "MONT DE LANS", cp : "38860" },
{ code : "38254", intitule : "MONTEYNARD", cp : "38770" },
{ code : "38255", intitule : "MONTFALCON", cp : "38940" },
{ code : "38256", intitule : "MONTFERRAT", cp : "38620" },
{ code : "38257", intitule : "MONTREVEL", cp : "38690" },
{ code : "38258", intitule : "MONT ST MARTIN", cp : "38120" },
{ code : "38259", intitule : "MONTSEVEROUX", cp : "38122" },
{ code : "38260", intitule : "MORAS", cp : "38460" },
{ code : "38261", intitule : "MORESTEL", cp : "38510" },
{ code : "38263", intitule : "MORETTE", cp : "38210" },
{ code : "38264", intitule : "LA MORTE", cp : "38350" },
{ code : "38265", intitule : "LA MOTTE D AVEILLANS", cp : "38770" },
{ code : "38266", intitule : "LA MOTTE ST MARTIN", cp : "38770" },
{ code : "38267", intitule : "MOTTIER", cp : "38260" },
{ code : "38268", intitule : "LE MOUTARET", cp : "38580" },
{ code : "38269", intitule : "LA MURE", cp : "38350" },
{ code : "38270", intitule : "LA MURETTE", cp : "38140" },
{ code : "38271", intitule : "MURIANETTE", cp : "38420" },
{ code : "38272", intitule : "MURINAIS", cp : "38160" },
{ code : "38273", intitule : "NANTES EN RATIER", cp : "38350" },
{ code : "38274", intitule : "NANTOIN", cp : "38260" },
{ code : "38275", intitule : "SERRE NERPOL", cp : "38470" },
{ code : "38276", intitule : "NIVOLAS VERMELLE", cp : "38300" },
{ code : "38277", intitule : "NOTRE DAME DE COMMIERS", cp : "38450" },
{ code : "38278", intitule : "NOTRE DAME DE L OSIER", cp : "38470" },
{ code : "38279", intitule : "NOTRE DAME DE MESAGE", cp : "38220" },
{ code : "38280", intitule : "NOTRE DAME DE VAULX", cp : "38144" },
{ code : "38281", intitule : "NOYAREY", cp : "38360" },
{ code : "38282", intitule : "OPTEVOZ", cp : "38460" },
{ code : "38283", intitule : "ORIS EN RATTIER", cp : "38350" },
{ code : "38284", intitule : "ORNACIEUX", cp : "38260" },
{ code : "38285", intitule : "ORNON", cp : "38520" },
{ code : "38286", intitule : "OULLES", cp : "38520" },
{ code : "38287", intitule : "OYEU", cp : "38690" },
{ code : "38288", intitule : "OYTIER ST OBLAS", cp : "38780" },
{ code : "38289", intitule : "OZ", cp : "38114" },
{ code : "38290", intitule : "PACT", cp : "38270" },
{ code : "38291", intitule : "PAJAY", cp : "38260" },
{ code : "38292", intitule : "PALADRU", cp : "38850" },
{ code : "38293", intitule : "PANISSAGE", cp : "38730" },
{ code : "38294", intitule : "PANOSSAS", cp : "38460" },
{ code : "38295", intitule : "PARMILIEU", cp : "38390" },
{ code : "38296", intitule : "LE PASSAGE", cp : "38490" },
{ code : "38297", intitule : "PASSINS", cp : "38510" },
{ code : "38298", intitule : "LE PEAGE DE ROUSSILLON", cp : "38550" },
{ code : "38299", intitule : "PELLAFOL", cp : "38970" },
{ code : "38300", intitule : "PENOL", cp : "38260" },
{ code : "38301", intitule : "PERCY", cp : "38930" },
{ code : "38302", intitule : "LE PERIER", cp : "38740" },
{ code : "38303", intitule : "LA PIERRE", cp : "38570" },
{ code : "38304", intitule : "PIERRE CHATEL", cp : "38119" },
{ code : "38305", intitule : "LE PIN", cp : "38730" },
{ code : "38306", intitule : "PINSOT", cp : "38580" },
{ code : "38307", intitule : "PISIEU", cp : "38270" },
{ code : "38308", intitule : "PLAN", cp : "38590" },
{ code : "38309", intitule : "POISAT", cp : "38320" },
{ code : "38310", intitule : "POLIENAS", cp : "38210" },
{ code : "38311", intitule : "POMMIER DE BEAUREPAIRE", cp : "38260" },
{ code : "38312", intitule : "POMMIERS LA PLACETTE", cp : "38340" },
{ code : "38313", intitule : "PONSONNAS", cp : "38350" },
{ code : "38314", intitule : "PONTCHARRA", cp : "38530" },
{ code : "38315", intitule : "LE PONT DE BEAUVOISIN", cp : "38480" },
{ code : "38316", intitule : "PONT DE CHERUY", cp : "38230" },
{ code : "38317", intitule : "LE PONT DE CLAIX", cp : "38800" },
{ code : "38318", intitule : "PONT EVEQUE", cp : "38780" },
{ code : "38319", intitule : "PONT EN ROYANS", cp : "38680" },
{ code : "38320", intitule : "PORCIEU AMBLAGNIEU", cp : "38390" },
{ code : "38321", intitule : "PREBOIS", cp : "38710" },
{ code : "38322", intitule : "PRESLES", cp : "38680" },
{ code : "38323", intitule : "PRESSINS", cp : "38480" },
{ code : "38324", intitule : "PRIMARETTE", cp : "38270" },
{ code : "38325", intitule : "PROVEYSIEUX", cp : "38120" },
{ code : "38326", intitule : "PRUNIERES", cp : "38350" },
{ code : "38328", intitule : "QUAIX EN CHARTREUSE", cp : "38950" },
{ code : "38329", intitule : "QUET EN BEAUMONT", cp : "38970" },
{ code : "38330", intitule : "QUINCIEU", cp : "38470" },
{ code : "38331", intitule : "REAUMONT", cp : "38140" },
{ code : "38332", intitule : "RENAGE", cp : "38140" },
{ code : "38333", intitule : "RENCUREL", cp : "38680" },
{ code : "38334", intitule : "REVEL", cp : "38420" },
{ code : "38335", intitule : "REVEL TOURDAN", cp : "38270" },
{ code : "38336", intitule : "REVENTIN VAUGRIS", cp : "38121" },
{ code : "38337", intitule : "RIVES", cp : "38140" },
{ code : "38338", intitule : "LA RIVIERE", cp : "38210" },
{ code : "38339", intitule : "ROCHE", cp : "38090" },
{ code : "38339", intitule : "ROCHE", cp : "38090" },
{ code : "38340", intitule : "LES ROCHES DE CONDRIEU", cp : "38370" },
{ code : "38341", intitule : "ROCHETOIRIN", cp : "38110" },
{ code : "38342", intitule : "ROISSARD", cp : "38650" },
{ code : "38343", intitule : "ROMAGNIEU", cp : "38480" },
{ code : "38344", intitule : "ROUSSILLON", cp : "38150" },
{ code : "38345", intitule : "ROVON", cp : "38470" },
{ code : "38346", intitule : "ROYAS", cp : "38440" },
{ code : "38347", intitule : "ROYBON", cp : "38940" },
{ code : "38348", intitule : "RUY", cp : "38300" },
{ code : "38348", intitule : "RUY", cp : "38300" },
{ code : "38349", intitule : "SABLONS", cp : "38550" },
{ code : "38350", intitule : "STE AGNES", cp : "38190" },
{ code : "38351", intitule : "ST AGNIN SUR BION", cp : "38300" },
{ code : "38352", intitule : "ST ALBAN DE ROCHE", cp : "38080" },
{ code : "38353", intitule : "ST ALBAN DU RHONE", cp : "38370" },
{ code : "38354", intitule : "ST ALBIN DE VAULSERRE", cp : "38480" },
{ code : "38355", intitule : "ST ANDEOL", cp : "38650" },
{ code : "38356", intitule : "ST ANDRE EN ROYANS", cp : "38680" },
{ code : "38357", intitule : "ST ANDRE LE GAZ", cp : "38490" },
{ code : "38358", intitule : "STE ANNE SUR GERVONDE", cp : "38440" },
{ code : "38359", intitule : "ST ANTOINE L ABBAYE", cp : "38160" },
{ code : "38359", intitule : "ST ANTOINE L ABBAYE", cp : "38160" },
{ code : "38360", intitule : "ST APPOLINARD", cp : "38160" },
{ code : "38361", intitule : "ST AREY", cp : "38350" },
{ code : "38362", intitule : "ST AUPRE", cp : "38960" },
{ code : "38363", intitule : "ST BARTHELEMY", cp : "38270" },
{ code : "38364", intitule : "ST BARTHELEMY DE SECHILIENNE", cp : "38220" },
{ code : "38365", intitule : "ST BAUDILLE DE LA TOUR", cp : "38118" },
{ code : "38366", intitule : "ST BAUDILLE ET PIPET", cp : "38710" },
{ code : "38367", intitule : "ST BERNARD", cp : "38660" },
{ code : "38368", intitule : "ST BLAISE DU BUIS", cp : "38140" },
{ code : "38369", intitule : "STE BLANDINE", cp : "38110" },
{ code : "38370", intitule : "ST BONNET DE CHAVAGNE", cp : "38840" },
{ code : "38372", intitule : "ST BUEIL", cp : "38620" },
{ code : "38373", intitule : "ST CASSIEN", cp : "38500" },
{ code : "38374", intitule : "ST CHEF", cp : "38890" },
{ code : "38375", intitule : "ST CHRISTOPHE EN OISANS", cp : "38520" },
{ code : "38376", intitule : "ST CHRISTOPHE SUR GUIERS", cp : "38380" },
{ code : "38377", intitule : "ST CLAIR DE LA TOUR", cp : "38110" },
{ code : "38378", intitule : "ST CLAIR DU RHONE", cp : "38370" },
{ code : "38379", intitule : "ST CLAIR SUR GALAURE", cp : "38940" },
{ code : "38380", intitule : "ST DIDIER DE BIZONNES", cp : "38690" },
{ code : "38381", intitule : "ST DIDIER DE LA TOUR", cp : "38110" },
{ code : "38382", intitule : "ST EGREVE", cp : "38120" },
{ code : "38383", intitule : "ST ETIENNE DE CROSSEY", cp : "38960" },
{ code : "38384", intitule : "ST ETIENNE DE ST GEOIRS", cp : "38590" },
{ code : "38386", intitule : "ST GEOIRE EN VALDAINE", cp : "38620" },
{ code : "38387", intitule : "ST GEOIRS", cp : "38590" },
{ code : "38388", intitule : "ST GEORGES DE COMMIERS", cp : "38450" },
{ code : "38389", intitule : "ST GEORGES D ESPERANCHE", cp : "38790" },
{ code : "38389", intitule : "ST GEORGES D ESPERANCHE", cp : "38790" },
{ code : "38390", intitule : "ST GERVAIS", cp : "38470" },
{ code : "38391", intitule : "ST GUILLAUME", cp : "38650" },
{ code : "38392", intitule : "ST HILAIRE DE BRENS", cp : "38460" },
{ code : "38393", intitule : "ST HILAIRE DE LA COTE", cp : "38260" },
{ code : "38394", intitule : "ST HILAIRE DU ROSIER", cp : "38840" },
{ code : "38395", intitule : "ST HILAIRE", cp : "38660" },
{ code : "38396", intitule : "ST HONORE", cp : "38350" },
{ code : "38397", intitule : "ST ISMIER", cp : "38330" },
{ code : "38398", intitule : "ST JEAN D AVELANNE", cp : "38480" },
{ code : "38399", intitule : "ST JEAN DE BOURNAY", cp : "38440" },
{ code : "38400", intitule : "ST JEAN DE MOIRANS", cp : "38430" },
{ code : "38401", intitule : "ST JEAN DE SOUDAIN", cp : "38110" },
{ code : "38402", intitule : "ST JEAN DE VAULX", cp : "38220" },
{ code : "38403", intitule : "ST JEAN D HERANS", cp : "38710" },
{ code : "38404", intitule : "ST JEAN LE VIEUX", cp : "38420" },
{ code : "38405", intitule : "ST JOSEPH DE RIVIERE", cp : "38134" },
{ code : "38406", intitule : "ST JULIEN DE L HERMS", cp : "38122" },
{ code : "38407", intitule : "ST JULIEN DE RAZ", cp : "38134" },
{ code : "38408", intitule : "ST JUST CHALEYSSIN", cp : "38540" },
{ code : "38409", intitule : "ST JUST DE CLAIX", cp : "38680" },
{ code : "38410", intitule : "ST LATTIER", cp : "38840" },
{ code : "38412", intitule : "ST LAURENT DU PONT", cp : "38380" },
{ code : "38413", intitule : "ST LAURENT EN BEAUMONT", cp : "38350" },
{ code : "38414", intitule : "STE LUCE", cp : "38970" },
{ code : "38415", intitule : "ST MARCEL BEL ACCUEIL", cp : "38080" },
{ code : "38416", intitule : "ST MARCELLIN", cp : "38160" },
{ code : "38417", intitule : "STE MARIE D ALLOIX", cp : "38660" },
{ code : "38418", intitule : "STE MARIE DU MONT", cp : "38660" },
{ code : "38419", intitule : "ST MARTIN DE CLELLES", cp : "38930" },
{ code : "38420", intitule : "ST MARTIN DE VAULSERRE", cp : "38480" },
{ code : "38421", intitule : "ST MARTIN D HERES", cp : "38400" },
{ code : "38422", intitule : "ST MARTIN D URIAGE", cp : "38410" },
{ code : "38423", intitule : "ST MARTIN LE VINOUX", cp : "38950" },
{ code : "38424", intitule : "ST MAURICE EN TRIEVES", cp : "38930" },
{ code : "38425", intitule : "ST MAURICE L EXIL", cp : "38550" },
{ code : "38426", intitule : "ST MAXIMIN", cp : "38530" },
{ code : "38427", intitule : "ST MICHEL DE ST GEOIRS", cp : "38590" },
{ code : "38428", intitule : "ST MICHEL EN BEAUMONT", cp : "38350" },
{ code : "38429", intitule : "ST MICHEL LES PORTES", cp : "38650" },
{ code : "38430", intitule : "ST MURY MONTEYMOND", cp : "38190" },
{ code : "38431", intitule : "ST NAZAIRE LES EYMES", cp : "38330" },
{ code : "38432", intitule : "ST NICOLAS DE MACHERIN", cp : "38500" },
{ code : "38433", intitule : "ST NIZIER DU MOUCHEROTTE", cp : "38250" },
{ code : "38434", intitule : "ST ONDRAS", cp : "38490" },
{ code : "38435", intitule : "ST PANCRASSE", cp : "38660" },
{ code : "38436", intitule : "ST PAUL DE VARCES", cp : "38760" },
{ code : "38437", intitule : "ST PAUL D IZEAUX", cp : "38140" },
{ code : "38438", intitule : "ST PAUL LES MONESTIER", cp : "38650" },
{ code : "38439", intitule : "CRETS EN BELLEDONNE", cp : "38830" },
{ code : "38439", intitule : "CRETS EN BELLEDONNE", cp : "38570" },
{ code : "38440", intitule : "ST PIERRE DE BRESSIEUX", cp : "38870" },
{ code : "38442", intitule : "ST PIERRE DE CHARTREUSE", cp : "38380" },
{ code : "38443", intitule : "ST PIERRE DE CHERENNES", cp : "38160" },
{ code : "38444", intitule : "ST PIERRE DE MEAROZ", cp : "38350" },
{ code : "38445", intitule : "ST PIERRE DE MESAGE", cp : "38220" },
{ code : "38446", intitule : "ST PIERRE D ENTREMONT", cp : "38380" },
{ code : "38448", intitule : "ST PRIM", cp : "38370" },
{ code : "38449", intitule : "ST QUENTIN FALLAVIER", cp : "38070" },
{ code : "38449", intitule : "ST QUENTIN FALLAVIER", cp : "38070" },
{ code : "38450", intitule : "ST QUENTIN SUR ISERE", cp : "38210" },
{ code : "38451", intitule : "ST ROMAIN DE JALIONAS", cp : "38460" },
{ code : "38452", intitule : "ST ROMAIN DE SURIEU", cp : "38150" },
{ code : "38453", intitule : "ST ROMANS", cp : "38160" },
{ code : "38454", intitule : "ST SAUVEUR", cp : "38160" },
{ code : "38455", intitule : "ST SAVIN", cp : "38300" },
{ code : "38456", intitule : "ST SEBASTIEN", cp : "38710" },
{ code : "38457", intitule : "ST SIMEON DE BRESSIEUX", cp : "38870" },
{ code : "38458", intitule : "ST SORLIN DE MORESTEL", cp : "38510" },
{ code : "38459", intitule : "ST SORLIN DE VIENNE", cp : "38200" },
{ code : "38460", intitule : "ST SULPICE DES RIVOIRES", cp : "38620" },
{ code : "38462", intitule : "ST THEOFFREY", cp : "38119" },
{ code : "38463", intitule : "ST VERAND", cp : "38160" },
{ code : "38464", intitule : "ST VICTOR DE CESSIEU", cp : "38110" },
{ code : "38465", intitule : "ST VICTOR DE MORESTEL", cp : "38510" },
{ code : "38466", intitule : "ST VINCENT DE MERCUZE", cp : "38660" },
{ code : "38467", intitule : "SALAGNON", cp : "38890" },
{ code : "38468", intitule : "SALAISE SUR SANNE", cp : "38150" },
{ code : "38469", intitule : "LA SALETTE FALLAVAUX", cp : "38970" },
{ code : "38470", intitule : "LA SALLE EN BEAUMONT", cp : "38350" },
{ code : "38471", intitule : "LE SAPPEY EN CHARTREUSE", cp : "38700" },
{ code : "38472", intitule : "SARCENAS", cp : "38700" },
{ code : "38473", intitule : "SARDIEU", cp : "38260" },
{ code : "38474", intitule : "SASSENAGE", cp : "38360" },
{ code : "38475", intitule : "SATOLAS ET BONCE", cp : "38290" },
{ code : "38475", intitule : "SATOLAS ET BONCE", cp : "38290" },
{ code : "38475", intitule : "SATOLAS ET BONCE", cp : "38290" },
{ code : "38475", intitule : "SATOLAS ET BONCE", cp : "38290" },
{ code : "38475", intitule : "SATOLAS ET BONCE", cp : "38290" },
{ code : "38476", intitule : "SAVAS MEPIN", cp : "38440" },
{ code : "38478", intitule : "SECHILIENNE", cp : "38220" },
{ code : "38479", intitule : "SEMONS", cp : "38260" },
{ code : "38480", intitule : "SEPTEME", cp : "38780" },
{ code : "38480", intitule : "SEPTEME", cp : "38780" },
{ code : "38480", intitule : "SEPTEME", cp : "38780" },
{ code : "38481", intitule : "SEREZIN DE LA TOUR", cp : "38300" },
{ code : "38483", intitule : "SERMERIEU", cp : "38510" },
{ code : "38484", intitule : "SERPAIZE", cp : "38200" },
{ code : "38485", intitule : "SEYSSINET PARISET", cp : "38170" },
{ code : "38486", intitule : "SEYSSINS", cp : "38180" },
{ code : "38487", intitule : "SEYSSUEL", cp : "38200" },
{ code : "38488", intitule : "SICCIEU ST JULIEN ET CARISIEU", cp : "38460" },
{ code : "38489", intitule : "SIEVOZ", cp : "38350" },
{ code : "38490", intitule : "SILLANS", cp : "38590" },
{ code : "38492", intitule : "SINARD", cp : "38650" },
{ code : "38494", intitule : "SOLEYMIEU", cp : "38460" },
{ code : "38495", intitule : "LA SONE", cp : "38840" },
{ code : "38496", intitule : "SONNAY", cp : "38150" },
{ code : "38497", intitule : "SOUSVILLE", cp : "38350" },
{ code : "38498", intitule : "SUCCIEU", cp : "38300" },
{ code : "38499", intitule : "SUSVILLE", cp : "38350" },
{ code : "38500", intitule : "TECHE", cp : "38470" },
{ code : "38501", intitule : "TENCIN", cp : "38570" },
{ code : "38503", intitule : "LA TERRASSE", cp : "38660" },
{ code : "38504", intitule : "THEYS", cp : "38570" },
{ code : "38505", intitule : "THODURE", cp : "38260" },
{ code : "38507", intitule : "TIGNIEU JAMEYZIEU", cp : "38230" },
{ code : "38507", intitule : "TIGNIEU JAMEYZIEU", cp : "38230" },
{ code : "38508", intitule : "TORCHEFELON", cp : "38690" },
{ code : "38509", intitule : "LA TOUR DU PIN", cp : "38110" },
{ code : "38511", intitule : "LE TOUVET", cp : "38660" },
{ code : "38512", intitule : "TRAMOLE", cp : "38300" },
{ code : "38513", intitule : "TREFFORT", cp : "38650" },
{ code : "38514", intitule : "TREMINIS", cp : "38710" },
{ code : "38515", intitule : "TREPT", cp : "38460" },
{ code : "38516", intitule : "LA TRONCHE", cp : "38700" },
{ code : "38517", intitule : "TULLINS", cp : "38210" },
{ code : "38517", intitule : "TULLINS", cp : "38210" },
{ code : "38518", intitule : "VALBONNAIS", cp : "38740" },
{ code : "38519", intitule : "VALENCIN", cp : "38540" },
{ code : "38520", intitule : "VALENCOGNE", cp : "38730" },
{ code : "38521", intitule : "LA VALETTE", cp : "38350" },
{ code : "38522", intitule : "VALJOUFFREY", cp : "38740" },
{ code : "38523", intitule : "VARACIEUX", cp : "38470" },
{ code : "38524", intitule : "VARCES ALLIERES ET RISSET", cp : "38760" },
{ code : "38525", intitule : "VASSELIN", cp : "38890" },
{ code : "38526", intitule : "VATILIEU", cp : "38470" },
{ code : "38527", intitule : "VAUJANY", cp : "38114" },
{ code : "38528", intitule : "VAULNAVEYS LE BAS", cp : "38410" },
{ code : "38529", intitule : "VAULNAVEYS LE HAUT", cp : "38410" },
{ code : "38529", intitule : "VAULNAVEYS LE HAUT", cp : "38410" },
{ code : "38530", intitule : "VAULX MILIEU", cp : "38090" },
{ code : "38531", intitule : "VELANNE", cp : "38620" },
{ code : "38532", intitule : "VENERIEU", cp : "38460" },
{ code : "38533", intitule : "VENON", cp : "38610" },
{ code : "38534", intitule : "VENOSC", cp : "38860" },
{ code : "38534", intitule : "VENOSC", cp : "38860" },
{ code : "38534", intitule : "VENOSC", cp : "38520" },
{ code : "38535", intitule : "VERNAS", cp : "38460" },
{ code : "38536", intitule : "VERNIOZ", cp : "38150" },
{ code : "38537", intitule : "LA VERPILLIERE", cp : "38290" },
{ code : "38538", intitule : "LE VERSOUD", cp : "38420" },
{ code : "38539", intitule : "VERTRIEU", cp : "38390" },
{ code : "38540", intitule : "VEUREY VOROIZE", cp : "38113" },
{ code : "38542", intitule : "VEYSSILIEU", cp : "38460" },
{ code : "38543", intitule : "VEZERONCE CURTIN", cp : "38510" },
{ code : "38543", intitule : "VEZERONCE CURTIN", cp : "38510" },
{ code : "38544", intitule : "VIENNE", cp : "38200" },
{ code : "38545", intitule : "VIF", cp : "38450" },
{ code : "38546", intitule : "VIGNIEU", cp : "38890" },
{ code : "38547", intitule : "VILLARD BONNOT", cp : "38190" },
{ code : "38547", intitule : "VILLARD BONNOT", cp : "38190" },
{ code : "38547", intitule : "VILLARD BONNOT", cp : "38190" },
{ code : "38548", intitule : "VILLARD DE LANS", cp : "38250" },
{ code : "38549", intitule : "VILLARD NOTRE DAME", cp : "38520" },
{ code : "38550", intitule : "VILLARD RECULAS", cp : "38114" },
{ code : "38551", intitule : "VILLARD REYMOND", cp : "38520" },
{ code : "38552", intitule : "VILLARD ST CHRISTOPHE", cp : "38119" },
{ code : "38553", intitule : "VILLEFONTAINE", cp : "38090" },
{ code : "38554", intitule : "VILLEMOIRIEU", cp : "38460" },
{ code : "38555", intitule : "VILLENEUVE DE MARC", cp : "38440" },
{ code : "38556", intitule : "VILLE SOUS ANJOU", cp : "38150" },
{ code : "38557", intitule : "VILLETTE D ANTHON", cp : "38280" },
{ code : "38557", intitule : "VILLETTE D ANTHON", cp : "38280" },
{ code : "38557", intitule : "VILLETTE D ANTHON", cp : "38280" },
{ code : "38558", intitule : "VILLETTE DE VIENNE", cp : "38200" },
{ code : "38559", intitule : "VINAY", cp : "38470" },
{ code : "38560", intitule : "VIRIEU", cp : "38730" },
{ code : "38561", intitule : "VIRIVILLE", cp : "38980" },
{ code : "38562", intitule : "VIZILLE", cp : "38220" },
{ code : "38563", intitule : "VOIRON", cp : "38500" },
{ code : "38564", intitule : "VOISSANT", cp : "38620" },
{ code : "38565", intitule : "VOREPPE", cp : "38340" },
{ code : "38566", intitule : "VOUREY", cp : "38210" },
{ code : "38567", intitule : "CHAMROUSSE", cp : "38410" },
{ code : "39001", intitule : "ABERGEMENT LA RONCE", cp : "39500" },
{ code : "39002", intitule : "ABERGEMENT LE GRAND", cp : "39600" },
{ code : "39003", intitule : "ABERGEMENT LE PETIT", cp : "39800" },
{ code : "39004", intitule : "ABERGEMENT LES THESY", cp : "39110" },
{ code : "39006", intitule : "AIGLEPIERRE", cp : "39110" },
{ code : "39007", intitule : "ALIEZE", cp : "39270" },
{ code : "39008", intitule : "AMANGE", cp : "39700" },
{ code : "39009", intitule : "ANDELOT EN MONTAGNE", cp : "39110" },
{ code : "39010", intitule : "ANDELOT MORVAL", cp : "39320" },
{ code : "39010", intitule : "ANDELOT MORVAL", cp : "39320" },
{ code : "39011", intitule : "ANNOIRE", cp : "39120" },
{ code : "39013", intitule : "ARBOIS", cp : "39600" },
{ code : "39014", intitule : "ARCHELANGE", cp : "39290" },
{ code : "39015", intitule : "ARDON", cp : "39300" },
{ code : "39016", intitule : "ARINTHOD", cp : "39240" },
{ code : "39017", intitule : "ARLAY", cp : "39140" },
{ code : "39017", intitule : "ARLAY", cp : "39210" },
{ code : "39018", intitule : "AROMAS", cp : "39240" },
{ code : "39018", intitule : "AROMAS", cp : "39240" },
{ code : "39019", intitule : "LES ARSURES", cp : "39600" },
{ code : "39020", intitule : "ARSURE ARSURETTE", cp : "39250" },
{ code : "39021", intitule : "LA CHAILLEUSE", cp : "39270" },
{ code : "39021", intitule : "LA CHAILLEUSE", cp : "39270" },
{ code : "39021", intitule : "LA CHAILLEUSE", cp : "39570" },
{ code : "39021", intitule : "LA CHAILLEUSE", cp : "39270" },
{ code : "39022", intitule : "ASNANS BEAUVOISIN", cp : "39120" },
{ code : "39022", intitule : "ASNANS BEAUVOISIN", cp : "39120" },
{ code : "39024", intitule : "AUDELANGE", cp : "39700" },
{ code : "39025", intitule : "AUGEA", cp : "39190" },
{ code : "39026", intitule : "AUGERANS", cp : "39380" },
{ code : "39027", intitule : "AUGISEY", cp : "39270" },
{ code : "39028", intitule : "AUMONT", cp : "39800" },
{ code : "39029", intitule : "AUMUR", cp : "39410" },
{ code : "39030", intitule : "AUTHUME", cp : "39100" },
{ code : "39031", intitule : "AUXANGE", cp : "39700" },
{ code : "39032", intitule : "AVIGNON LES ST CLAUDE", cp : "39200" },
{ code : "39034", intitule : "BALAISEAUX", cp : "39120" },
{ code : "39035", intitule : "BALANOD", cp : "39160" },
{ code : "39036", intitule : "LA BALME D EPY", cp : "39320" },
{ code : "39037", intitule : "BANS", cp : "39380" },
{ code : "39038", intitule : "BARESIA SUR L AIN", cp : "39130" },
{ code : "39039", intitule : "LA BARRE", cp : "39700" },
{ code : "39040", intitule : "BARRETAINE", cp : "39800" },
{ code : "39041", intitule : "BAUME LES MESSIEURS", cp : "39210" },
{ code : "39041", intitule : "BAUME LES MESSIEURS", cp : "39570" },
{ code : "39042", intitule : "BAVERANS", cp : "39100" },
{ code : "39043", intitule : "BEAUFORT", cp : "39190" },
{ code : "39045", intitule : "BEFFIA", cp : "39270" },
{ code : "39046", intitule : "BELLECOMBE", cp : "39310" },
{ code : "39047", intitule : "BELLEFONTAINE", cp : "39400" },
{ code : "39048", intitule : "BELMONT", cp : "39380" },
{ code : "39049", intitule : "BERSAILLIN", cp : "39800" },
{ code : "39049", intitule : "BERSAILLIN", cp : "39800" },
{ code : "39049", intitule : "BERSAILLIN", cp : "39800" },
{ code : "39050", intitule : "BESAIN", cp : "39800" },
{ code : "39051", intitule : "BIARNE", cp : "39290" },
{ code : "39052", intitule : "BIEF DES MAISONS", cp : "39150" },
{ code : "39053", intitule : "BIEF DU FOURG", cp : "39250" },
{ code : "39054", intitule : "BIEFMORIN", cp : "39800" },
{ code : "39055", intitule : "BILLECUL", cp : "39250" },
{ code : "39056", intitule : "BLETTERANS", cp : "39140" },
{ code : "39057", intitule : "BLOIS SUR SEILLE", cp : "39210" },
{ code : "39058", intitule : "BLYE", cp : "39130" },
{ code : "39059", intitule : "BOIS D AMONT", cp : "39220" },
{ code : "39060", intitule : "BOIS DE GAND", cp : "39230" },
{ code : "39061", intitule : "BOISSIA", cp : "39130" },
{ code : "39062", intitule : "LA BOISSIERE", cp : "39240" },
{ code : "39063", intitule : "BONLIEU", cp : "39130" },
{ code : "39064", intitule : "BONNAUD", cp : "39190" },
{ code : "39065", intitule : "BONNEFONTAINE", cp : "39800" },
{ code : "39066", intitule : "BORNAY", cp : "39570" },
{ code : "39068", intitule : "LES BOUCHOUX", cp : "39370" },
{ code : "39069", intitule : "BOURCIA", cp : "39320" },
{ code : "39070", intitule : "BOURG DE SIROD", cp : "39300" },
{ code : "39072", intitule : "BRACON", cp : "39110" },
{ code : "39073", intitule : "BRAINANS", cp : "39800" },
{ code : "39074", intitule : "BRANS", cp : "39290" },
{ code : "39075", intitule : "BRERY", cp : "39230" },
{ code : "39076", intitule : "LA BRETENIERE", cp : "39700" },
{ code : "39077", intitule : "BRETENIERES", cp : "39120" },
{ code : "39078", intitule : "BREVANS", cp : "39100" },
{ code : "39079", intitule : "BRIOD", cp : "39570" },
{ code : "39080", intitule : "BROISSIA", cp : "39320" },
{ code : "39081", intitule : "BUVILLY", cp : "39800" },
{ code : "39083", intitule : "CENSEAU", cp : "39250" },
{ code : "39084", intitule : "CERNANS", cp : "39110" },
{ code : "39085", intitule : "CERNIEBAUD", cp : "39250" },
{ code : "39086", intitule : "CERNON", cp : "39240" },
{ code : "39086", intitule : "CERNON", cp : "39240" },
{ code : "39088", intitule : "CESANCEY", cp : "39570" },
{ code : "39089", intitule : "CEZIA", cp : "39240" },
{ code : "39090", intitule : "CHAINEE DES COUPIS", cp : "39120" },
{ code : "39091", intitule : "LES CHALESMES", cp : "39150" },
{ code : "39092", intitule : "CHAMBERIA", cp : "39270" },
{ code : "39093", intitule : "CHAMBLAY", cp : "39380" },
{ code : "39094", intitule : "CHAMOLE", cp : "39800" },
{ code : "39095", intitule : "CHAMPAGNE SUR LOUE", cp : "39600" },
{ code : "39096", intitule : "CHAMPAGNEY", cp : "39290" },
{ code : "39097", intitule : "CHAMPAGNOLE", cp : "39300" },
{ code : "39099", intitule : "CHAMPDIVERS", cp : "39500" },
{ code : "39100", intitule : "CHAMPROUGIER", cp : "39230" },
{ code : "39101", intitule : "CHAMPVANS", cp : "39100" },
{ code : "39102", intitule : "CHANCIA", cp : "01590" },
{ code : "39103", intitule : "LA CHAPELLE SUR FURIEUSE", cp : "39110" },
{ code : "39104", intitule : "CHAPELLE VOLAND", cp : "39140" },
{ code : "39105", intitule : "CHAPOIS", cp : "39300" },
{ code : "39106", intitule : "CHARCHILLA", cp : "39260" },
{ code : "39107", intitule : "CHARCIER", cp : "39130" },
{ code : "39108", intitule : "CHARENCY", cp : "39250" },
{ code : "39109", intitule : "CHAREZIER", cp : "39130" },
{ code : "39110", intitule : "LA CHARME", cp : "39230" },
{ code : "39111", intitule : "CHARNOD", cp : "39240" },
{ code : "39112", intitule : "LA CHASSAGNE", cp : "39230" },
{ code : "39113", intitule : "CHASSAL", cp : "39360" },
{ code : "39114", intitule : "CHATEAU CHALON", cp : "39210" },
{ code : "39115", intitule : "CHATEAU DES PRES", cp : "39150" },
{ code : "39116", intitule : "LA CHATELAINE", cp : "39600" },
{ code : "39117", intitule : "CHATELAY", cp : "39380" },
{ code : "39118", intitule : "CHATEL DE JOUX", cp : "39130" },
{ code : "39119", intitule : "LE CHATELEY", cp : "39230" },
{ code : "39120", intitule : "CHATELNEUF", cp : "39300" },
{ code : "39121", intitule : "CHATENOIS", cp : "39700" },
{ code : "39122", intitule : "CHATILLON", cp : "39130" },
{ code : "39123", intitule : "CHATONNAY", cp : "39240" },
{ code : "39124", intitule : "CHAUMERGY", cp : "39230" },
{ code : "39126", intitule : "LA CHAUMUSSE", cp : "39150" },
{ code : "39127", intitule : "CHAUSSENANS", cp : "39800" },
{ code : "39128", intitule : "CHAUSSIN", cp : "39120" },
{ code : "39129", intitule : "CHAUX DES CROTENAY", cp : "39150" },
{ code : "39130", intitule : "NANCHEZ", cp : "39150" },
{ code : "39130", intitule : "NANCHEZ", cp : "39150" },
{ code : "39131", intitule : "LA CHAUX DU DOMBIEF", cp : "39150" },
{ code : "39132", intitule : "LA CHAUX EN BRESSE", cp : "39230" },
{ code : "39133", intitule : "CHAUX CHAMPAGNY", cp : "39110" },
{ code : "39133", intitule : "CHAUX CHAMPAGNY", cp : "39110" },
{ code : "39134", intitule : "CHAVERIA", cp : "39270" },
{ code : "39136", intitule : "CHEMENOT", cp : "39230" },
{ code : "39137", intitule : "CHEMILLA", cp : "39240" },
{ code : "39138", intitule : "CHEMIN", cp : "39120" },
{ code : "39139", intitule : "CHENE BERNARD", cp : "39120" },
{ code : "39140", intitule : "CHENE SEC", cp : "39230" },
{ code : "39141", intitule : "CHEVIGNY", cp : "39290" },
{ code : "39142", intitule : "CHEVREAUX", cp : "39190" },
{ code : "39143", intitule : "CHEVROTAINE", cp : "39130" },
{ code : "39145", intitule : "CHILLE", cp : "39570" },
{ code : "39146", intitule : "CHILLY LE VIGNOBLE", cp : "39570" },
{ code : "39147", intitule : "CHILLY SUR SALINS", cp : "39110" },
{ code : "39148", intitule : "CHISSERIA", cp : "39240" },
{ code : "39149", intitule : "CHISSEY SUR LOUE", cp : "39380" },
{ code : "39150", intitule : "CHOISEY", cp : "39100" },
{ code : "39151", intitule : "CHOUX", cp : "39370" },
{ code : "39153", intitule : "CIZE", cp : "39300" },
{ code : "39154", intitule : "CLAIRVAUX LES LACS", cp : "39130" },
{ code : "39155", intitule : "CLUCY", cp : "39110" },
{ code : "39156", intitule : "COGNA", cp : "39130" },
{ code : "39157", intitule : "COISERETTE", cp : "39200" },
{ code : "39158", intitule : "COISIA", cp : "39240" },
{ code : "39159", intitule : "COLONNE", cp : "39800" },
{ code : "39160", intitule : "COMMENAILLES", cp : "39140" },
{ code : "39162", intitule : "CONDAMINE", cp : "39570" },
{ code : "39163", intitule : "CONDES", cp : "39240" },
{ code : "39164", intitule : "CONLIEGE", cp : "39570" },
{ code : "39165", intitule : "CONTE", cp : "39300" },
{ code : "39166", intitule : "CORNOD", cp : "39240" },
{ code : "39167", intitule : "COSGES", cp : "39140" },
{ code : "39168", intitule : "COURBETTE", cp : "39570" },
{ code : "39169", intitule : "COURBOUZON", cp : "39570" },
{ code : "39170", intitule : "COURLANS", cp : "39570" },
{ code : "39170", intitule : "COURLANS", cp : "39570" },
{ code : "39171", intitule : "COURLAOUX", cp : "39570" },
{ code : "39172", intitule : "COURTEFONTAINE", cp : "39700" },
{ code : "39173", intitule : "COUSANCE", cp : "39190" },
{ code : "39174", intitule : "COYRIERE", cp : "39200" },
{ code : "39175", intitule : "COYRON", cp : "39260" },
{ code : "39176", intitule : "CRAMANS", cp : "39600" },
{ code : "39177", intitule : "HAUTEROCHE", cp : "39570" },
{ code : "39177", intitule : "HAUTEROCHE", cp : "39570" },
{ code : "39177", intitule : "HAUTEROCHE", cp : "39210" },
{ code : "39178", intitule : "CRANS", cp : "39300" },
{ code : "39179", intitule : "CRENANS", cp : "39260" },
{ code : "39180", intitule : "CRESSIA", cp : "39270" },
{ code : "39182", intitule : "CRISSEY", cp : "39100" },
{ code : "39183", intitule : "CROTENAY", cp : "39300" },
{ code : "39184", intitule : "LES CROZETS", cp : "39260" },
{ code : "39185", intitule : "CUISIA", cp : "39190" },
{ code : "39186", intitule : "CUTTURA", cp : "39170" },
{ code : "39187", intitule : "CUVIER", cp : "39250" },
{ code : "39188", intitule : "DAMMARTIN MARPAIN", cp : "39290" },
{ code : "39188", intitule : "DAMMARTIN MARPAIN", cp : "39290" },
{ code : "39189", intitule : "DAMPARIS", cp : "39500" },
{ code : "39190", intitule : "DAMPIERRE", cp : "39700" },
{ code : "39190", intitule : "DAMPIERRE", cp : "39700" },
{ code : "39191", intitule : "DARBONNAY", cp : "39230" },
{ code : "39192", intitule : "DENEZIERES", cp : "39130" },
{ code : "39193", intitule : "LE DESCHAUX", cp : "39120" },
{ code : "39194", intitule : "DESNES", cp : "39140" },
{ code : "39195", intitule : "DESSIA", cp : "39320" },
{ code : "39196", intitule : "LES DEUX FAYS", cp : "39230" },
{ code : "39197", intitule : "DIGNA", cp : "39190" },
{ code : "39198", intitule : "DOLE", cp : "39100" },
{ code : "39198", intitule : "DOLE", cp : "39100" },
{ code : "39198", intitule : "DOLE", cp : "39100" },
{ code : "39199", intitule : "DOMBLANS", cp : "39210" },
{ code : "39200", intitule : "DOMPIERRE SUR MONT", cp : "39270" },
{ code : "39201", intitule : "DOUCIER", cp : "39130" },
{ code : "39202", intitule : "DOURNON", cp : "39110" },
{ code : "39203", intitule : "DOYE", cp : "39250" },
{ code : "39204", intitule : "DRAMELAY", cp : "39240" },
{ code : "39205", intitule : "ECLANS NENON", cp : "39700" },
{ code : "39205", intitule : "ECLANS NENON", cp : "39700" },
{ code : "39206", intitule : "ECLEUX", cp : "39600" },
{ code : "39207", intitule : "ECRILLE", cp : "39270" },
{ code : "39208", intitule : "ENTRE DEUX MONTS", cp : "39150" },
{ code : "39209", intitule : "VAL D EPY", cp : "39160" },
{ code : "39209", intitule : "VAL D EPY", cp : "39160" },
{ code : "39209", intitule : "VAL D EPY", cp : "39160" },
{ code : "39209", intitule : "VAL D EPY", cp : "39160" },
{ code : "39209", intitule : "VAL D EPY", cp : "39320" },
{ code : "39209", intitule : "VAL D EPY", cp : "39160" },
{ code : "39210", intitule : "EQUEVILLON", cp : "39300" },
{ code : "39211", intitule : "LES ESSARDS TAIGNEVAUX", cp : "39120" },
{ code : "39214", intitule : "ESSERVAL TARTRE", cp : "39250" },
{ code : "39216", intitule : "ETIVAL", cp : "39130" },
{ code : "39216", intitule : "ETIVAL", cp : "39130" },
{ code : "39217", intitule : "L ETOILE", cp : "39570" },
{ code : "39218", intitule : "ETREPIGNEY", cp : "39700" },
{ code : "39219", intitule : "EVANS", cp : "39700" },
{ code : "39220", intitule : "FALLETANS", cp : "39700" },
{ code : "39221", intitule : "LA FAVIERE", cp : "39250" },
{ code : "39222", intitule : "FAY EN MONTAGNE", cp : "39800" },
{ code : "39223", intitule : "LA FERTE", cp : "39600" },
{ code : "39224", intitule : "FETIGNY", cp : "39240" },
{ code : "39225", intitule : "LE FIED", cp : "39800" },
{ code : "39227", intitule : "FONCINE LE BAS", cp : "39520" },
{ code : "39228", intitule : "FONCINE LE HAUT", cp : "39460" },
{ code : "39229", intitule : "FONTAINEBRUX", cp : "39140" },
{ code : "39230", intitule : "FONTENU", cp : "39130" },
{ code : "39232", intitule : "FORT DU PLASNE", cp : "39150" },
{ code : "39233", intitule : "FOUCHERANS", cp : "39100" },
{ code : "39234", intitule : "FOULENAY", cp : "39230" },
{ code : "39235", intitule : "FRAISANS", cp : "39700" },
{ code : "39236", intitule : "FRANCHEVILLE", cp : "39230" },
{ code : "39237", intitule : "FRAROZ", cp : "39250" },
{ code : "39238", intitule : "FRASNE LES MEULIERES", cp : "39290" },
{ code : "39239", intitule : "LA FRASNEE", cp : "39130" },
{ code : "39240", intitule : "LE FRASNOIS", cp : "39130" },
{ code : "39241", intitule : "FREBUANS", cp : "39570" },
{ code : "39244", intitule : "FRONTENAY", cp : "39210" },
{ code : "39245", intitule : "GATEY", cp : "39120" },
{ code : "39246", intitule : "GENDREY", cp : "39350" },
{ code : "39247", intitule : "GENOD", cp : "39240" },
{ code : "39248", intitule : "GERAISE", cp : "39110" },
{ code : "39249", intitule : "GERMIGNEY", cp : "39380" },
{ code : "39250", intitule : "GERUGE", cp : "39570" },
{ code : "39251", intitule : "GEVINGEY", cp : "39570" },
{ code : "39252", intitule : "GEVRY", cp : "39100" },
{ code : "39253", intitule : "GIGNY", cp : "39320" },
{ code : "39254", intitule : "GILLOIS", cp : "39250" },
{ code : "39255", intitule : "GIZIA", cp : "39190" },
{ code : "39258", intitule : "GRANDE RIVIERE", cp : "39150" },
{ code : "39258", intitule : "GRANDE RIVIERE", cp : "39150" },
{ code : "39259", intitule : "GRANGE DE VAIVRE", cp : "39600" },
{ code : "39261", intitule : "GRAYE ET CHARNAY", cp : "39320" },
{ code : "39262", intitule : "GREDISANS", cp : "39290" },
{ code : "39263", intitule : "GROZON", cp : "39800" },
{ code : "39264", intitule : "GRUSSE", cp : "39190" },
{ code : "39265", intitule : "HAUTECOUR", cp : "39130" },
{ code : "39266", intitule : "LES HAYS", cp : "39120" },
{ code : "39267", intitule : "IVORY", cp : "39110" },
{ code : "39268", intitule : "IVREY", cp : "39110" },
{ code : "39269", intitule : "JEURRE", cp : "39360" },
{ code : "39270", intitule : "JOUHE", cp : "39100" },
{ code : "39271", intitule : "LAC DES ROUGES TRUITES", cp : "39150" },
{ code : "39272", intitule : "LADOYE SUR SEILLE", cp : "39210" },
{ code : "39273", intitule : "LAINS", cp : "39320" },
{ code : "39274", intitule : "LAJOUX", cp : "01410" },
{ code : "39274", intitule : "LAJOUX", cp : "39310" },
{ code : "39275", intitule : "LAMOURA", cp : "39310" },
{ code : "39277", intitule : "LE LARDERET", cp : "39300" },
{ code : "39278", intitule : "LARGILLAY MARSONNAY", cp : "39130" },
{ code : "39279", intitule : "LARNAUD", cp : "39140" },
{ code : "39280", intitule : "LARRIVOIRE", cp : "39360" },
{ code : "39281", intitule : "LE LATET", cp : "39300" },
{ code : "39282", intitule : "LA LATETTE", cp : "39250" },
{ code : "39283", intitule : "LAVANCIA EPERCY", cp : "01590" },
{ code : "39284", intitule : "LAVANGEOT", cp : "39700" },
{ code : "39285", intitule : "LAVANS LES DOLE", cp : "39700" },
{ code : "39286", intitule : "LAVANS LES ST CLAUDE", cp : "39170" },
{ code : "39286", intitule : "LAVANS LES ST CLAUDE", cp : "39170" },
{ code : "39286", intitule : "LAVANS LES ST CLAUDE", cp : "39170" },
{ code : "39287", intitule : "LAVANS SUR VALOUSE", cp : "39240" },
{ code : "39288", intitule : "LAVIGNY", cp : "39210" },
{ code : "39288", intitule : "LAVIGNY", cp : "39210" },
{ code : "39289", intitule : "LECT", cp : "39260" },
{ code : "39289", intitule : "LECT", cp : "39260" },
{ code : "39290", intitule : "LEGNA", cp : "39240" },
{ code : "39291", intitule : "LEMUY", cp : "39110" },
{ code : "39292", intitule : "LENT", cp : "39300" },
{ code : "39293", intitule : "LESCHERES", cp : "39170" },
{ code : "39295", intitule : "LOISIA", cp : "39320" },
{ code : "39296", intitule : "LOMBARD", cp : "39230" },
{ code : "39297", intitule : "LONGCHAUMOIS", cp : "39400" },
{ code : "39298", intitule : "LONGCOCHON", cp : "39250" },
{ code : "39299", intitule : "LONGWY SUR LE DOUBS", cp : "39120" },
{ code : "39300", intitule : "LONS LE SAUNIER", cp : "39000" },
{ code : "39301", intitule : "LOULLE", cp : "39300" },
{ code : "39302", intitule : "LOUVATANGE", cp : "39350" },
{ code : "39303", intitule : "LOUVENNE", cp : "39320" },
{ code : "39304", intitule : "LE LOUVEROT", cp : "39210" },
{ code : "39305", intitule : "LA LOYE", cp : "39380" },
{ code : "39306", intitule : "MACORNAY", cp : "39570" },
{ code : "39307", intitule : "MAISOD", cp : "39260" },
{ code : "39308", intitule : "MALANGE", cp : "39700" },
{ code : "39309", intitule : "MALLEREY", cp : "39190" },
{ code : "39310", intitule : "MANTRY", cp : "39230" },
{ code : "39312", intitule : "MARIGNA SUR VALOUSE", cp : "39240" },
{ code : "39313", intitule : "MARIGNY", cp : "39130" },
{ code : "39314", intitule : "MARNEZIA", cp : "39270" },
{ code : "39315", intitule : "MARNOZ", cp : "39110" },
{ code : "39317", intitule : "LA MARRE", cp : "39210" },
{ code : "39318", intitule : "MARTIGNA", cp : "39260" },
{ code : "39319", intitule : "MATHENAY", cp : "39600" },
{ code : "39320", intitule : "MAYNAL", cp : "39190" },
{ code : "39321", intitule : "MENETRU LE VIGNOBLE", cp : "39210" },
{ code : "39322", intitule : "MENETRUX EN JOUX", cp : "39130" },
{ code : "39323", intitule : "MENOTEY", cp : "39290" },
{ code : "39324", intitule : "MERONA", cp : "39270" },
{ code : "39325", intitule : "MESNAY", cp : "39600" },
{ code : "39326", intitule : "MESNOIS", cp : "39130" },
{ code : "39327", intitule : "MESSIA SUR SORNE", cp : "39570" },
{ code : "39328", intitule : "MEUSSIA", cp : "39260" },
{ code : "39329", intitule : "MIEGES", cp : "39250" },
{ code : "39329", intitule : "MIEGES", cp : "39250" },
{ code : "39329", intitule : "MIEGES", cp : "39250" },
{ code : "39330", intitule : "MIERY", cp : "39800" },
{ code : "39331", intitule : "MIGNOVILLARD", cp : "39250" },
{ code : "39331", intitule : "MIGNOVILLARD", cp : "39250" },
{ code : "39331", intitule : "MIGNOVILLARD", cp : "39250" },
{ code : "39331", intitule : "MIGNOVILLARD", cp : "39250" },
{ code : "39333", intitule : "MOIRANS EN MONTAGNE", cp : "39260" },
{ code : "39334", intitule : "MOIRON", cp : "39570" },
{ code : "39335", intitule : "MOISSEY", cp : "39290" },
{ code : "39336", intitule : "MOLAIN", cp : "39800" },
{ code : "39337", intitule : "MOLAMBOZ", cp : "39600" },
{ code : "39338", intitule : "MOLAY", cp : "39500" },
{ code : "39339", intitule : "MOLINGES", cp : "39360" },
{ code : "39341", intitule : "LES MOLUNES", cp : "39310" },
{ code : "39342", intitule : "MONAY", cp : "39230" },
{ code : "39343", intitule : "MONNETAY", cp : "39320" },
{ code : "39344", intitule : "MONNET LA VILLE", cp : "39300" },
{ code : "39345", intitule : "MONNIERES", cp : "39100" },
{ code : "39346", intitule : "MONTAGNA LE RECONDUIT", cp : "39160" },
{ code : "39347", intitule : "MONTAGNA LE TEMPLIER", cp : "39320" },
{ code : "39348", intitule : "MONTAIGU", cp : "39570" },
{ code : "39349", intitule : "MONTAIN", cp : "39210" },
{ code : "39350", intitule : "MONTBARREY", cp : "39380" },
{ code : "39351", intitule : "MONTCUSEL", cp : "39260" },
{ code : "39352", intitule : "MONTEPLAIN", cp : "39700" },
{ code : "39353", intitule : "MONTFLEUR", cp : "39320" },
{ code : "39354", intitule : "MONTHOLIER", cp : "39800" },
{ code : "39355", intitule : "MONTIGNY LES ARSURES", cp : "39600" },
{ code : "39356", intitule : "MONTIGNY SUR L AIN", cp : "39300" },
{ code : "39359", intitule : "MONTMARLON", cp : "39110" },
{ code : "39360", intitule : "MONTMIREY LA VILLE", cp : "39290" },
{ code : "39361", intitule : "MONTMIREY LE CHATEAU", cp : "39290" },
{ code : "39362", intitule : "MONTMOROT", cp : "39570" },
{ code : "39362", intitule : "MONTMOROT", cp : "39570" },
{ code : "39363", intitule : "MONTREVEL", cp : "39320" },
{ code : "39364", intitule : "MONTROND", cp : "39300" },
{ code : "39365", intitule : "MONT SOUS VAUDREY", cp : "39380" },
{ code : "39366", intitule : "MONT SUR MONNET", cp : "39300" },
{ code : "39367", intitule : "MORBIER", cp : "39400" },
{ code : "39367", intitule : "MORBIER", cp : "39400" },
{ code : "39368", intitule : "HAUTS DE BIENNE", cp : "39400" },
{ code : "39368", intitule : "HAUTS DE BIENNE", cp : "39400" },
{ code : "39368", intitule : "HAUTS DE BIENNE", cp : "39400" },
{ code : "39370", intitule : "MOUCHARD", cp : "39330" },
{ code : "39370", intitule : "MOUCHARD", cp : "39330" },
{ code : "39372", intitule : "MOURNANS CHARBONNY", cp : "39250" },
{ code : "39373", intitule : "LES MOUSSIERES", cp : "39310" },
{ code : "39375", intitule : "MOUTONNE", cp : "39270" },
{ code : "39376", intitule : "MOUTOUX", cp : "39300" },
{ code : "39377", intitule : "MUTIGNEY", cp : "39290" },
{ code : "39378", intitule : "LES TROIS CHATEAUX", cp : "39160" },
{ code : "39378", intitule : "LES TROIS CHATEAUX", cp : "39160" },
{ code : "39378", intitule : "LES TROIS CHATEAUX", cp : "39160" },
{ code : "39379", intitule : "NANCE", cp : "39140" },
{ code : "39380", intitule : "NANCUISE", cp : "39270" },
{ code : "39381", intitule : "LES NANS", cp : "39300" },
{ code : "39385", intitule : "NEUBLANS ABERGEMENT", cp : "39120" },
{ code : "39385", intitule : "NEUBLANS ABERGEMENT", cp : "39120" },
{ code : "39386", intitule : "NEUVILLEY", cp : "39800" },
{ code : "39387", intitule : "NEVY LES DOLE", cp : "39380" },
{ code : "39388", intitule : "NEVY SUR SEILLE", cp : "39210" },
{ code : "39389", intitule : "NEY", cp : "39300" },
{ code : "39390", intitule : "NOGNA", cp : "39570" },
{ code : "39391", intitule : "NOZEROY", cp : "39250" },
{ code : "39392", intitule : "OFFLANGES", cp : "39290" },
{ code : "39393", intitule : "ONGLIERES", cp : "39250" },
{ code : "39394", intitule : "ONOZ", cp : "39270" },
{ code : "39395", intitule : "ORBAGNA", cp : "39190" },
{ code : "39396", intitule : "ORCHAMPS", cp : "39700" },
{ code : "39397", intitule : "ORGELET", cp : "39270" },
{ code : "39397", intitule : "ORGELET", cp : "39270" },
{ code : "39398", intitule : "OUGNEY", cp : "39350" },
{ code : "39399", intitule : "OUNANS", cp : "39380" },
{ code : "39400", intitule : "OUR", cp : "39700" },
{ code : "39401", intitule : "OUSSIERES", cp : "39800" },
{ code : "39402", intitule : "PAGNEY", cp : "39350" },
{ code : "39403", intitule : "PAGNOZ", cp : "39330" },
{ code : "39404", intitule : "PANNESSIERES", cp : "39570" },
{ code : "39405", intitule : "PARCEY", cp : "39100" },
{ code : "39406", intitule : "LE PASQUIER", cp : "39300" },
{ code : "39407", intitule : "PASSENANS", cp : "39230" },
{ code : "39408", intitule : "PATORNAY", cp : "39130" },
{ code : "39409", intitule : "PEINTRE", cp : "39290" },
{ code : "39411", intitule : "PERRIGNY", cp : "39570" },
{ code : "39412", intitule : "PESEUX", cp : "39120" },
{ code : "39413", intitule : "LA PESSE", cp : "39370" },
{ code : "39414", intitule : "LE PETIT MERCEY", cp : "39350" },
{ code : "39415", intitule : "PETIT NOIR", cp : "39120" },
{ code : "39417", intitule : "LES PIARDS", cp : "39150" },
{ code : "39418", intitule : "PICARREAU", cp : "39800" },
{ code : "39419", intitule : "PILLEMOINE", cp : "39300" },
{ code : "39420", intitule : "PIMORIN", cp : "39270" },
{ code : "39421", intitule : "LE PIN", cp : "39210" },
{ code : "39422", intitule : "PLAINOISEAU", cp : "39210" },
{ code : "39423", intitule : "PLAISIA", cp : "39270" },
{ code : "39424", intitule : "LES PLANCHES EN MONTAGNE", cp : "39150" },
{ code : "39424", intitule : "LES PLANCHES EN MONTAGNE", cp : "39150" },
{ code : "39425", intitule : "LES PLANCHES PRES ARBOIS", cp : "39600" },
{ code : "39426", intitule : "PLASNE", cp : "39800" },
{ code : "39426", intitule : "PLASNE", cp : "39210" },
{ code : "39427", intitule : "PLENISE", cp : "39250" },
{ code : "39428", intitule : "PLENISETTE", cp : "39250" },
{ code : "39429", intitule : "PLEURE", cp : "39120" },
{ code : "39430", intitule : "PLUMONT", cp : "39700" },
{ code : "39431", intitule : "POIDS DE FIOLE", cp : "39570" },
{ code : "39432", intitule : "POINTRE", cp : "39290" },
{ code : "39434", intitule : "POLIGNY", cp : "39800" },
{ code : "39435", intitule : "PONT DE POITTE", cp : "39130" },
{ code : "39436", intitule : "PONT D HERY", cp : "39110" },
{ code : "39436", intitule : "PONT D HERY", cp : "39110" },
{ code : "39436", intitule : "PONT D HERY", cp : "39110" },
{ code : "39437", intitule : "PONT DU NAVOY", cp : "39300" },
{ code : "39439", intitule : "PORT LESNEY", cp : "39600" },
{ code : "39439", intitule : "PORT LESNEY", cp : "39330" },
{ code : "39440", intitule : "PRATZ", cp : "39170" },
{ code : "39441", intitule : "PREMANON", cp : "39220" },
{ code : "39441", intitule : "PREMANON", cp : "39400" },
{ code : "39441", intitule : "PREMANON", cp : "39400" },
{ code : "39443", intitule : "PRESILLY", cp : "39270" },
{ code : "39444", intitule : "PRETIN", cp : "39110" },
{ code : "39445", intitule : "PUBLY", cp : "39570" },
{ code : "39446", intitule : "PUPILLIN", cp : "39600" },
{ code : "39447", intitule : "QUINTIGNY", cp : "39570" },
{ code : "39448", intitule : "RAHON", cp : "39120" },
{ code : "39449", intitule : "RAINANS", cp : "39290" },
{ code : "39451", intitule : "RANCHOT", cp : "39700" },
{ code : "39452", intitule : "RANS", cp : "39700" },
{ code : "39453", intitule : "RAVILLOLES", cp : "39170" },
{ code : "39454", intitule : "RECANOZ", cp : "39230" },
{ code : "39455", intitule : "REITHOUSE", cp : "39270" },
{ code : "39456", intitule : "RELANS", cp : "39140" },
{ code : "39457", intitule : "LES REPOTS", cp : "39140" },
{ code : "39458", intitule : "REVIGNY", cp : "39570" },
{ code : "39460", intitule : "LA RIXOUSE", cp : "39200" },
{ code : "39461", intitule : "RIX", cp : "39250" },
{ code : "39462", intitule : "ROCHEFORT SUR NENON", cp : "39700" },
{ code : "39463", intitule : "ROGNA", cp : "39360" },
{ code : "39464", intitule : "ROMAIN", cp : "39350" },
{ code : "39465", intitule : "ROMANGE", cp : "39700" },
{ code : "39466", intitule : "ROSAY", cp : "39190" },
{ code : "39467", intitule : "ROTALIER", cp : "39190" },
{ code : "39468", intitule : "ROTHONAY", cp : "39270" },
{ code : "39469", intitule : "ROUFFANGE", cp : "39350" },
{ code : "39470", intitule : "LES ROUSSES", cp : "39400" },
{ code : "39470", intitule : "LES ROUSSES", cp : "39220" },
{ code : "39470", intitule : "LES ROUSSES", cp : "39400" },
{ code : "39470", intitule : "LES ROUSSES", cp : "39220" },
{ code : "39470", intitule : "LES ROUSSES", cp : "39400" },
{ code : "39471", intitule : "RUFFEY SUR SEILLE", cp : "39140" },
{ code : "39472", intitule : "RYE", cp : "39230" },
{ code : "39473", intitule : "SAFFLOZ", cp : "39130" },
{ code : "39474", intitule : "STE AGNES", cp : "39190" },
{ code : "39475", intitule : "ST AMOUR", cp : "39160" },
{ code : "39476", intitule : "ST AUBIN", cp : "39410" },
{ code : "39477", intitule : "ST BARAING", cp : "39120" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39478", intitule : "ST CLAUDE", cp : "39200" },
{ code : "39479", intitule : "ST CYR MONTMALIN", cp : "39600" },
{ code : "39479", intitule : "ST CYR MONTMALIN", cp : "39600" },
{ code : "39480", intitule : "ST DIDIER", cp : "39570" },
{ code : "39481", intitule : "ST GERMAIN EN MONTAGNE", cp : "39300" },
{ code : "39483", intitule : "ST HYMETIERE", cp : "39240" },
{ code : "39484", intitule : "ST JEAN D ETREUX", cp : "39160" },
{ code : "39485", intitule : "ST JULIEN", cp : "39320" },
{ code : "39486", intitule : "ST LAMAIN", cp : "39230" },
{ code : "39487", intitule : "ST LAURENT EN GRANDVAUX", cp : "39150" },
{ code : "39489", intitule : "ST LOTHAIN", cp : "39230" },
{ code : "39490", intitule : "ST LOUP", cp : "39120" },
{ code : "39491", intitule : "ST LUPICIN", cp : "39170" },
{ code : "39492", intitule : "ST MAUR", cp : "39570" },
{ code : "39493", intitule : "ST MAURICE CRILLAT", cp : "39130" },
{ code : "39493", intitule : "ST MAURICE CRILLAT", cp : "39130" },
{ code : "39494", intitule : "ST PIERRE", cp : "39150" },
{ code : "39495", intitule : "ST THIEBAUD", cp : "39110" },
{ code : "39497", intitule : "SAIZENAY", cp : "39110" },
{ code : "39498", intitule : "SALANS", cp : "39700" },
{ code : "39499", intitule : "SALIGNEY", cp : "39350" },
{ code : "39500", intitule : "SALINS LES BAINS", cp : "39110" },
{ code : "39501", intitule : "SAMPANS", cp : "39100" },
{ code : "39502", intitule : "SANTANS", cp : "39380" },
{ code : "39503", intitule : "SAPOIS", cp : "39300" },
{ code : "39504", intitule : "SARROGNA", cp : "39270" },
{ code : "39504", intitule : "SARROGNA", cp : "39270" },
{ code : "39504", intitule : "SARROGNA", cp : "39270" },
{ code : "39504", intitule : "SARROGNA", cp : "39270" },
{ code : "39505", intitule : "SAUGEOT", cp : "39130" },
{ code : "39506", intitule : "SAVIGNA", cp : "39240" },
{ code : "39507", intitule : "SELIGNEY", cp : "39120" },
{ code : "39508", intitule : "SELLIERES", cp : "39230" },
{ code : "39510", intitule : "SEPTMONCEL", cp : "39310" },
{ code : "39511", intitule : "SERGENAUX", cp : "39230" },
{ code : "39512", intitule : "SERGENON", cp : "39120" },
{ code : "39513", intitule : "SERMANGE", cp : "39700" },
{ code : "39514", intitule : "SERRE LES MOULIERES", cp : "39700" },
{ code : "39517", intitule : "SIROD", cp : "39300" },
{ code : "39517", intitule : "SIROD", cp : "39300" },
{ code : "39518", intitule : "SONGESON", cp : "39130" },
{ code : "39519", intitule : "SOUCIA", cp : "39130" },
{ code : "39520", intitule : "SOUVANS", cp : "39380" },
{ code : "39522", intitule : "SUPT", cp : "39300" },
{ code : "39523", intitule : "SYAM", cp : "39300" },
{ code : "39525", intitule : "TASSENIERES", cp : "39120" },
{ code : "39526", intitule : "TAVAUX", cp : "39500" },
{ code : "39527", intitule : "TAXENNE", cp : "39350" },
{ code : "39528", intitule : "THERVAY", cp : "39290" },
{ code : "39529", intitule : "THESY", cp : "39110" },
{ code : "39530", intitule : "THOIRETTE", cp : "39240" },
{ code : "39531", intitule : "THOIRIA", cp : "39130" },
{ code : "39532", intitule : "THOISSIA", cp : "39160" },
{ code : "39533", intitule : "TOULOUSE LE CHATEAU", cp : "39230" },
{ code : "39534", intitule : "LA TOUR DU MEIX", cp : "39270" },
{ code : "39535", intitule : "TOURMONT", cp : "39800" },
{ code : "39537", intitule : "TRENAL", cp : "39570" },
{ code : "39538", intitule : "UXELLES", cp : "39130" },
{ code : "39539", intitule : "VADANS", cp : "39600" },
{ code : "39540", intitule : "VALEMPOULIERES", cp : "39300" },
{ code : "39542", intitule : "VALFIN SUR VALOUSE", cp : "39240" },
{ code : "39543", intitule : "VANNOZ", cp : "39300" },
{ code : "39545", intitule : "LE VAUDIOUX", cp : "39300" },
{ code : "39546", intitule : "VAUDREY", cp : "39380" },
{ code : "39547", intitule : "VAUX LES ST CLAUDE", cp : "39360" },
{ code : "39548", intitule : "VAUX SUR POLIGNY", cp : "39800" },
{ code : "39549", intitule : "VERCIA", cp : "39190" },
{ code : "39550", intitule : "VERGES", cp : "39570" },
{ code : "39551", intitule : "VERIA", cp : "39160" },
{ code : "39552", intitule : "VERNANTOIS", cp : "39570" },
{ code : "39553", intitule : "LE VERNOIS", cp : "39210" },
{ code : "39554", intitule : "VERS EN MONTAGNE", cp : "39300" },
{ code : "39555", intitule : "VERS SOUS SELLIERES", cp : "39230" },
{ code : "39556", intitule : "VERTAMBOZ", cp : "39130" },
{ code : "39557", intitule : "VESCLES", cp : "39240" },
{ code : "39558", intitule : "VEVY", cp : "39570" },
{ code : "39559", intitule : "LA VIEILLE LOYE", cp : "39380" },
{ code : "39560", intitule : "VILLARD ST SAUVEUR", cp : "39200" },
{ code : "39561", intitule : "VILLARDS D HERIA", cp : "39260" },
{ code : "39562", intitule : "VILLARD SUR BIENNE", cp : "39150" },
{ code : "39562", intitule : "VILLARD SUR BIENNE", cp : "39200" },
{ code : "39564", intitule : "VILLECHANTRIA", cp : "39320" },
{ code : "39565", intitule : "VILLENEUVE D AVAL", cp : "39600" },
{ code : "39566", intitule : "VILLENEUVE LES CHARNOD", cp : "39240" },
{ code : "39567", intitule : "VILLENEUVE SOUS PYMONT", cp : "39570" },
{ code : "39568", intitule : "VILLERSERINE", cp : "39800" },
{ code : "39569", intitule : "VILLERS FARLAY", cp : "39600" },
{ code : "39570", intitule : "VILLERS LES BOIS", cp : "39120" },
{ code : "39570", intitule : "VILLERS LES BOIS", cp : "39800" },
{ code : "39571", intitule : "VILLERS ROBERT", cp : "39120" },
{ code : "39572", intitule : "VILLETTE LES ARBOIS", cp : "39600" },
{ code : "39573", intitule : "VILLETTE LES DOLE", cp : "39100" },
{ code : "39574", intitule : "VILLEVIEUX", cp : "39140" },
{ code : "39575", intitule : "LE VILLEY", cp : "39230" },
{ code : "39576", intitule : "VINCELLES", cp : "39190" },
{ code : "39577", intitule : "VINCENT FROIDEVILLE", cp : "39230" },
{ code : "39577", intitule : "VINCENT FROIDEVILLE", cp : "39230" },
{ code : "39579", intitule : "VIRY", cp : "39360" },
{ code : "39581", intitule : "VITREUX", cp : "39350" },
{ code : "39582", intitule : "VOITEUR", cp : "39210" },
{ code : "39583", intitule : "VOSBLES", cp : "39240" },
{ code : "39584", intitule : "VRIANGE", cp : "39700" },
{ code : "39585", intitule : "VULVOZ", cp : "39360" },
{ code : "39586", intitule : "ARESCHES", cp : "39110" },
{ code : "40001", intitule : "AIRE SUR L ADOUR", cp : "40800" },
{ code : "40002", intitule : "AMOU", cp : "40330" },
{ code : "40003", intitule : "ANGOUME", cp : "40990" },
{ code : "40004", intitule : "ANGRESSE", cp : "40150" },
{ code : "40005", intitule : "ARBOUCAVE", cp : "40320" },
{ code : "40006", intitule : "ARENGOSSE", cp : "40110" },
{ code : "40007", intitule : "ARGELOS", cp : "40700" },
{ code : "40008", intitule : "ARGELOUSE", cp : "40430" },
{ code : "40009", intitule : "ARJUZANX", cp : "40110" },
{ code : "40011", intitule : "ARSAGUE", cp : "40330" },
{ code : "40012", intitule : "ARTASSENX", cp : "40090" },
{ code : "40013", intitule : "ARTHEZ D ARMAGNAC", cp : "40190" },
{ code : "40014", intitule : "ARUE", cp : "40120" },
{ code : "40015", intitule : "ARX", cp : "40310" },
{ code : "40016", intitule : "AUBAGNAN", cp : "40700" },
{ code : "40017", intitule : "AUDIGNON", cp : "40500" },
{ code : "40018", intitule : "AUDON", cp : "40400" },
{ code : "40019", intitule : "AUREILHAN", cp : "40200" },
{ code : "40020", intitule : "AURICE", cp : "40500" },
{ code : "40021", intitule : "AZUR", cp : "40140" },
{ code : "40022", intitule : "BAHUS SOUBIRAN", cp : "40320" },
{ code : "40023", intitule : "BAIGTS", cp : "40380" },
{ code : "40024", intitule : "BANOS", cp : "40500" },
{ code : "40025", intitule : "BASCONS", cp : "40090" },
{ code : "40026", intitule : "BAS MAUCO", cp : "40500" },
{ code : "40027", intitule : "BASSERCLES", cp : "40700" },
{ code : "40028", intitule : "BASTENNES", cp : "40360" },
{ code : "40029", intitule : "BATS", cp : "40320" },
{ code : "40030", intitule : "BAUDIGNAN", cp : "40310" },
{ code : "40031", intitule : "BEGAAR", cp : "40400" },
{ code : "40032", intitule : "BELHADE", cp : "40410" },
{ code : "40033", intitule : "BELIS", cp : "40120" },
{ code : "40034", intitule : "BELUS", cp : "40300" },
{ code : "40035", intitule : "BENESSE LES DAX", cp : "40180" },
{ code : "40036", intitule : "BENESSE MAREMNE", cp : "40230" },
{ code : "40037", intitule : "BENQUET", cp : "40280" },
{ code : "40038", intitule : "BERGOUEY", cp : "40250" },
{ code : "40039", intitule : "BETBEZER D ARMAGNAC", cp : "40240" },
{ code : "40040", intitule : "BEYLONGUE", cp : "40370" },
{ code : "40041", intitule : "BEYRIES", cp : "40700" },
{ code : "40042", intitule : "BIARROTTE", cp : "40390" },
{ code : "40043", intitule : "BIAS", cp : "40170" },
{ code : "40044", intitule : "BIAUDOS", cp : "40390" },
{ code : "40046", intitule : "BISCARROSSE", cp : "40600" },
{ code : "40046", intitule : "BISCARROSSE", cp : "40600" },
{ code : "40047", intitule : "BONNEGARDE", cp : "40330" },
{ code : "40048", intitule : "BOOS", cp : "40370" },
{ code : "40049", intitule : "BORDERES ET LAMENSANS", cp : "40270" },
{ code : "40050", intitule : "BOSTENS", cp : "40090" },
{ code : "40051", intitule : "BOUGUE", cp : "40090" },
{ code : "40052", intitule : "BOURDALAT", cp : "40190" },
{ code : "40053", intitule : "BOURRIOT BERGONCE", cp : "40120" },
{ code : "40054", intitule : "BRASSEMPOUY", cp : "40330" },
{ code : "40055", intitule : "BRETAGNE DE MARSAN", cp : "40280" },
{ code : "40056", intitule : "BROCAS", cp : "40420" },
{ code : "40057", intitule : "BUANES", cp : "40320" },
{ code : "40058", intitule : "CACHEN", cp : "40120" },
{ code : "40059", intitule : "CAGNOTTE", cp : "40300" },
{ code : "40060", intitule : "CALLEN", cp : "40430" },
{ code : "40061", intitule : "CAMPAGNE", cp : "40090" },
{ code : "40062", intitule : "CAMPET ET LAMOLERE", cp : "40090" },
{ code : "40063", intitule : "CANDRESSE", cp : "40180" },
{ code : "40064", intitule : "CANENX ET REAUT", cp : "40090" },
{ code : "40065", intitule : "CAPBRETON", cp : "40130" },
{ code : "40066", intitule : "CARCARES STE CROIX", cp : "40400" },
{ code : "40067", intitule : "CARCEN PONSON", cp : "40400" },
{ code : "40068", intitule : "CASSEN", cp : "40380" },
{ code : "40069", intitule : "CASTAIGNOS SOUSLENS", cp : "40700" },
{ code : "40070", intitule : "CASTANDET", cp : "40270" },
{ code : "40071", intitule : "CASTELNAU CHALOSSE", cp : "40360" },
{ code : "40072", intitule : "CASTELNAU TURSAN", cp : "40320" },
{ code : "40073", intitule : "CASTELNER", cp : "40700" },
{ code : "40074", intitule : "CASTEL SARRAZIN", cp : "40330" },
{ code : "40075", intitule : "CASTETS", cp : "40260" },
{ code : "40076", intitule : "CAUNA", cp : "40500" },
{ code : "40077", intitule : "CAUNEILLE", cp : "40300" },
{ code : "40078", intitule : "CAUPENNE", cp : "40250" },
{ code : "40079", intitule : "CAZALIS", cp : "40700" },
{ code : "40080", intitule : "CAZERES SUR L ADOUR", cp : "40270" },
{ code : "40081", intitule : "CERE", cp : "40090" },
{ code : "40082", intitule : "CLASSUN", cp : "40320" },
{ code : "40083", intitule : "CLEDES", cp : "40320" },
{ code : "40084", intitule : "CLERMONT", cp : "40180" },
{ code : "40085", intitule : "COMMENSACQ", cp : "40210" },
{ code : "40086", intitule : "COUDURES", cp : "40500" },
{ code : "40087", intitule : "CREON D ARMAGNAC", cp : "40240" },
{ code : "40088", intitule : "DAX", cp : "40100" },
{ code : "40089", intitule : "DOAZIT", cp : "40700" },
{ code : "40090", intitule : "DONZACQ", cp : "40360" },
{ code : "40091", intitule : "DUHORT BACHEN", cp : "40800" },
{ code : "40092", intitule : "DUMES", cp : "40500" },
{ code : "40093", intitule : "ESCALANS", cp : "40310" },
{ code : "40094", intitule : "ESCOURCE", cp : "40210" },
{ code : "40095", intitule : "ESTIBEAUX", cp : "40290" },
{ code : "40096", intitule : "ESTIGARDE", cp : "40240" },
{ code : "40097", intitule : "EUGENIE LES BAINS", cp : "40320" },
{ code : "40098", intitule : "EYRES MONCUBE", cp : "40500" },
{ code : "40099", intitule : "FARGUES", cp : "40500" },
{ code : "40100", intitule : "LE FRECHE", cp : "40190" },
{ code : "40101", intitule : "GAAS", cp : "40350" },
{ code : "40102", intitule : "GABARRET", cp : "40310" },
{ code : "40103", intitule : "GAILLERES", cp : "40090" },
{ code : "40104", intitule : "GAMARDE LES BAINS", cp : "40380" },
{ code : "40105", intitule : "GAREIN", cp : "40420" },
{ code : "40106", intitule : "GARREY", cp : "40180" },
{ code : "40107", intitule : "GARROSSE", cp : "40110" },
{ code : "40108", intitule : "GASTES", cp : "40160" },
{ code : "40109", intitule : "GAUJACQ", cp : "40330" },
{ code : "40110", intitule : "GEAUNE", cp : "40320" },
{ code : "40111", intitule : "GELOUX", cp : "40090" },
{ code : "40112", intitule : "GIBRET", cp : "40380" },
{ code : "40113", intitule : "GOOS", cp : "40180" },
{ code : "40114", intitule : "GOURBERA", cp : "40990" },
{ code : "40115", intitule : "GOUSSE", cp : "40465" },
{ code : "40116", intitule : "GOUTS", cp : "40400" },
{ code : "40117", intitule : "GRENADE SUR L ADOUR", cp : "40270" },
{ code : "40118", intitule : "HABAS", cp : "40290" },
{ code : "40119", intitule : "HAGETMAU", cp : "40700" },
{ code : "40120", intitule : "HASTINGUES", cp : "40300" },
{ code : "40121", intitule : "HAURIET", cp : "40250" },
{ code : "40122", intitule : "HAUT MAUCO", cp : "40280" },
{ code : "40123", intitule : "HERM", cp : "40990" },
{ code : "40124", intitule : "HERRE", cp : "40310" },
{ code : "40125", intitule : "HEUGAS", cp : "40180" },
{ code : "40126", intitule : "HINX", cp : "40180" },
{ code : "40127", intitule : "HONTANX", cp : "40190" },
{ code : "40128", intitule : "HORSARRIEU", cp : "40700" },
{ code : "40129", intitule : "JOSSE", cp : "40230" },
{ code : "40130", intitule : "LABASTIDE CHALOSSE", cp : "40700" },
{ code : "40131", intitule : "LABASTIDE D ARMAGNAC", cp : "40240" },
{ code : "40132", intitule : "LABATUT", cp : "40300" },
{ code : "40133", intitule : "LABENNE", cp : "40530" },
{ code : "40134", intitule : "LABOUHEYRE", cp : "40210" },
{ code : "40135", intitule : "LABRIT", cp : "40420" },
{ code : "40136", intitule : "LACAJUNTE", cp : "40320" },
{ code : "40137", intitule : "LACQUY", cp : "40120" },
{ code : "40138", intitule : "LACRABE", cp : "40700" },
{ code : "40139", intitule : "LAGLORIEUSE", cp : "40090" },
{ code : "40140", intitule : "LAGRANGE", cp : "40240" },
{ code : "40141", intitule : "LAHOSSE", cp : "40250" },
{ code : "40142", intitule : "LALUQUE", cp : "40465" },
{ code : "40143", intitule : "LAMOTHE", cp : "40250" },
{ code : "40144", intitule : "LARBEY", cp : "40250" },
{ code : "40145", intitule : "LARRIVIERE ST SAVIN", cp : "40270" },
{ code : "40146", intitule : "LATRILLE", cp : "40800" },
{ code : "40147", intitule : "LAUREDE", cp : "40250" },
{ code : "40148", intitule : "LAURET", cp : "40320" },
{ code : "40149", intitule : "LENCOUACQ", cp : "40120" },
{ code : "40150", intitule : "LEON", cp : "40550" },
{ code : "40151", intitule : "LESGOR", cp : "40400" },
{ code : "40152", intitule : "LESPERON", cp : "40260" },
{ code : "40153", intitule : "LE LEUY", cp : "40250" },
{ code : "40154", intitule : "LEVIGNACQ", cp : "40170" },
{ code : "40155", intitule : "LINXE", cp : "40260" },
{ code : "40156", intitule : "LIPOSTHEY", cp : "40410" },
{ code : "40157", intitule : "LIT ET MIXE", cp : "40170" },
{ code : "40158", intitule : "LOSSE", cp : "40240" },
{ code : "40159", intitule : "LOUER", cp : "40380" },
{ code : "40160", intitule : "LOURQUEN", cp : "40250" },
{ code : "40161", intitule : "LUBBON", cp : "40240" },
{ code : "40162", intitule : "LUCBARDEZ ET BARGUES", cp : "40090" },
{ code : "40163", intitule : "LUE", cp : "40210" },
{ code : "40164", intitule : "RETJONS", cp : "40120" },
{ code : "40165", intitule : "LUGLON", cp : "40630" },
{ code : "40166", intitule : "LUSSAGNET", cp : "40270" },
{ code : "40167", intitule : "LUXEY", cp : "40430" },
{ code : "40168", intitule : "MAGESCQ", cp : "40140" },
{ code : "40169", intitule : "MAILLAS", cp : "40120" },
{ code : "40170", intitule : "MAILLERES", cp : "40120" },
{ code : "40171", intitule : "MANO", cp : "40410" },
{ code : "40172", intitule : "MANT", cp : "40700" },
{ code : "40173", intitule : "MARPAPS", cp : "40330" },
{ code : "40174", intitule : "MAURIES", cp : "40320" },
{ code : "40175", intitule : "MAURRIN", cp : "40270" },
{ code : "40176", intitule : "MAUVEZIN D ARMAGNAC", cp : "40240" },
{ code : "40177", intitule : "MAYLIS", cp : "40250" },
{ code : "40178", intitule : "MAZEROLLES", cp : "40090" },
{ code : "40179", intitule : "MEES", cp : "40990" },
{ code : "40180", intitule : "MEILHAN", cp : "40400" },
{ code : "40181", intitule : "MESSANGES", cp : "40660" },
{ code : "40182", intitule : "MEZOS", cp : "40170" },
{ code : "40183", intitule : "MIMBASTE", cp : "40350" },
{ code : "40184", intitule : "MIMIZAN", cp : "40200" },
{ code : "40185", intitule : "MIRAMONT SENSACQ", cp : "40320" },
{ code : "40186", intitule : "MISSON", cp : "40290" },
{ code : "40187", intitule : "MOLIETS ET MAA", cp : "40660" },
{ code : "40188", intitule : "MOMUY", cp : "40700" },
{ code : "40189", intitule : "MONGET", cp : "40700" },
{ code : "40190", intitule : "MONSEGUR", cp : "40700" },
{ code : "40191", intitule : "MONTAUT", cp : "40500" },
{ code : "40192", intitule : "MONT DE MARSAN", cp : "40000" },
{ code : "40193", intitule : "MONTEGUT", cp : "40190" },
{ code : "40194", intitule : "MONTFORT EN CHALOSSE", cp : "40380" },
{ code : "40195", intitule : "MONTGAILLARD", cp : "40500" },
{ code : "40196", intitule : "MONTSOUE", cp : "40500" },
{ code : "40197", intitule : "MORCENX", cp : "40110" },
{ code : "40198", intitule : "MORGANX", cp : "40700" },
{ code : "40199", intitule : "MOUSCARDES", cp : "40290" },
{ code : "40200", intitule : "MOUSTEY", cp : "40410" },
{ code : "40201", intitule : "MUGRON", cp : "40250" },
{ code : "40202", intitule : "NARROSSE", cp : "40180" },
{ code : "40203", intitule : "NASSIET", cp : "40330" },
{ code : "40204", intitule : "NERBIS", cp : "40250" },
{ code : "40205", intitule : "NOUSSE", cp : "40380" },
{ code : "40206", intitule : "OEYREGAVE", cp : "40300" },
{ code : "40207", intitule : "OEYRELUY", cp : "40180" },
{ code : "40208", intitule : "ONARD", cp : "40380" },
{ code : "40209", intitule : "ONDRES", cp : "40440" },
{ code : "40210", intitule : "ONESSE LAHARIE", cp : "40110" },
{ code : "40210", intitule : "ONESSE LAHARIE", cp : "40110" },
{ code : "40211", intitule : "ORIST", cp : "40300" },
{ code : "40212", intitule : "ORTHEVIELLE", cp : "40300" },
{ code : "40213", intitule : "ORX", cp : "40230" },
{ code : "40214", intitule : "OSSAGES", cp : "40290" },
{ code : "40215", intitule : "OUSSE SUZAN", cp : "40110" },
{ code : "40216", intitule : "OZOURT", cp : "40380" },
{ code : "40217", intitule : "PARENTIS EN BORN", cp : "40160" },
{ code : "40218", intitule : "PARLEBOSCQ", cp : "40310" },
{ code : "40219", intitule : "PAYROS CAZAUTETS", cp : "40320" },
{ code : "40220", intitule : "PECORADE", cp : "40320" },
{ code : "40221", intitule : "PERQUIE", cp : "40190" },
{ code : "40222", intitule : "PEY", cp : "40300" },
{ code : "40223", intitule : "PEYRE", cp : "40700" },
{ code : "40224", intitule : "PEYREHORADE", cp : "40300" },
{ code : "40225", intitule : "PHILONDENX", cp : "40320" },
{ code : "40226", intitule : "PIMBO", cp : "40320" },
{ code : "40227", intitule : "PISSOS", cp : "40410" },
{ code : "40227", intitule : "PISSOS", cp : "40410" },
{ code : "40228", intitule : "POMAREZ", cp : "40360" },
{ code : "40229", intitule : "PONTENX LES FORGES", cp : "40200" },
{ code : "40230", intitule : "PONTONX SUR L ADOUR", cp : "40465" },
{ code : "40231", intitule : "PORT DE LANNE", cp : "40300" },
{ code : "40232", intitule : "POUDENX", cp : "40700" },
{ code : "40233", intitule : "POUILLON", cp : "40350" },
{ code : "40234", intitule : "POUYDESSEAUX", cp : "40120" },
{ code : "40235", intitule : "POYANNE", cp : "40380" },
{ code : "40236", intitule : "POYARTIN", cp : "40380" },
{ code : "40237", intitule : "PRECHACQ LES BAINS", cp : "40465" },
{ code : "40238", intitule : "PUJO LE PLAN", cp : "40190" },
{ code : "40239", intitule : "PUYOL CAZALET", cp : "40320" },
{ code : "40240", intitule : "RENUNG", cp : "40270" },
{ code : "40242", intitule : "RIMBEZ ET BAUDIETS", cp : "40310" },
{ code : "40243", intitule : "RION DES LANDES", cp : "40370" },
{ code : "40244", intitule : "RIVIERE SAAS ET GOURBY", cp : "40180" },
{ code : "40245", intitule : "ROQUEFORT", cp : "40120" },
{ code : "40246", intitule : "SABRES", cp : "40630" },
{ code : "40247", intitule : "ST AGNET", cp : "40800" },
{ code : "40248", intitule : "ST ANDRE DE SEIGNANX", cp : "40390" },
{ code : "40249", intitule : "ST AUBIN", cp : "40250" },
{ code : "40250", intitule : "ST AVIT", cp : "40090" },
{ code : "40251", intitule : "ST BARTHELEMY", cp : "40390" },
{ code : "40252", intitule : "STE COLOMBE", cp : "40700" },
{ code : "40253", intitule : "ST CRICQ CHALOSSE", cp : "40700" },
{ code : "40254", intitule : "ST CRICQ DU GAVE", cp : "40300" },
{ code : "40255", intitule : "ST CRICQ VILLENEUVE", cp : "40190" },
{ code : "40256", intitule : "ST ETIENNE D ORTHE", cp : "40300" },
{ code : "40257", intitule : "STE EULALIE EN BORN", cp : "40200" },
{ code : "40258", intitule : "STE FOY", cp : "40190" },
{ code : "40259", intitule : "ST GEIN", cp : "40190" },
{ code : "40260", intitule : "ST GEOURS D AURIBAT", cp : "40380" },
{ code : "40261", intitule : "ST GEOURS DE MAREMNE", cp : "40230" },
{ code : "40262", intitule : "ST GOR", cp : "40120" },
{ code : "40263", intitule : "ST JEAN DE LIER", cp : "40380" },
{ code : "40264", intitule : "ST JEAN DE MARSACQ", cp : "40230" },
{ code : "40265", intitule : "ST JULIEN D ARMAGNAC", cp : "40240" },
{ code : "40266", intitule : "ST JULIEN EN BORN", cp : "40170" },
{ code : "40267", intitule : "ST JUSTIN", cp : "40240" },
{ code : "40268", intitule : "ST LAURENT DE GOSSE", cp : "40390" },
{ code : "40269", intitule : "ST LON LES MINES", cp : "40300" },
{ code : "40270", intitule : "ST LOUBOUER", cp : "40320" },
{ code : "40271", intitule : "STE MARIE DE GOSSE", cp : "40390" },
{ code : "40272", intitule : "ST MARTIN DE HINX", cp : "40390" },
{ code : "40273", intitule : "ST MARTIN DE SEIGNANX", cp : "40390" },
{ code : "40274", intitule : "ST MARTIN D ONEY", cp : "40090" },
{ code : "40275", intitule : "ST MAURICE SUR ADOUR", cp : "40270" },
{ code : "40276", intitule : "ST MICHEL ESCALUS", cp : "40550" },
{ code : "40277", intitule : "ST PANDELON", cp : "40180" },
{ code : "40278", intitule : "ST PAUL EN BORN", cp : "40200" },
{ code : "40279", intitule : "ST PAUL LES DAX", cp : "40990" },
{ code : "40280", intitule : "ST PERDON", cp : "40090" },
{ code : "40281", intitule : "ST PIERRE DU MONT", cp : "40280" },
{ code : "40282", intitule : "ST SEVER", cp : "40500" },
{ code : "40283", intitule : "ST VINCENT DE PAUL", cp : "40990" },
{ code : "40284", intitule : "ST VINCENT DE TYROSSE", cp : "40230" },
{ code : "40285", intitule : "ST YAGUEN", cp : "40400" },
{ code : "40286", intitule : "SAMADET", cp : "40320" },
{ code : "40287", intitule : "SANGUINET", cp : "40460" },
{ code : "40288", intitule : "SARBAZAN", cp : "40120" },
{ code : "40289", intitule : "SARRAZIET", cp : "40500" },
{ code : "40290", intitule : "SARRON", cp : "40800" },
{ code : "40291", intitule : "SAUBION", cp : "40230" },
{ code : "40292", intitule : "SAUBRIGUES", cp : "40230" },
{ code : "40293", intitule : "SAUBUSSE", cp : "40180" },
{ code : "40294", intitule : "SAUGNAC ET CAMBRAN", cp : "40180" },
{ code : "40295", intitule : "SAUGNACQ ET MURET", cp : "40410" },
{ code : "40296", intitule : "SEIGNOSSE", cp : "40510" },
{ code : "40297", intitule : "LE SEN", cp : "40420" },
{ code : "40298", intitule : "SERRES GASTON", cp : "40700" },
{ code : "40299", intitule : "SERRESLOUS ET ARRIBANS", cp : "40700" },
{ code : "40300", intitule : "SEYRESSE", cp : "40180" },
{ code : "40301", intitule : "SIEST", cp : "40180" },
{ code : "40302", intitule : "SINDERES", cp : "40110" },
{ code : "40303", intitule : "SOLFERINO", cp : "40210" },
{ code : "40304", intitule : "SOORTS HOSSEGOR", cp : "40150" },
{ code : "40305", intitule : "SORBETS", cp : "40320" },
{ code : "40306", intitule : "SORDE L ABBAYE", cp : "40300" },
{ code : "40307", intitule : "SORE", cp : "40430" },
{ code : "40308", intitule : "SORT EN CHALOSSE", cp : "40180" },
{ code : "40309", intitule : "SOUPROSSE", cp : "40250" },
{ code : "40310", intitule : "SOUSTONS", cp : "40140" },
{ code : "40311", intitule : "TALLER", cp : "40260" },
{ code : "40312", intitule : "TARNOS", cp : "40220" },
{ code : "40313", intitule : "TARTAS", cp : "40400" },
{ code : "40314", intitule : "TERCIS LES BAINS", cp : "40180" },
{ code : "40315", intitule : "TETHIEU", cp : "40990" },
{ code : "40316", intitule : "TILH", cp : "40360" },
{ code : "40317", intitule : "TOSSE", cp : "40230" },
{ code : "40318", intitule : "TOULOUZETTE", cp : "40250" },
{ code : "40319", intitule : "TRENSACQ", cp : "40630" },
{ code : "40320", intitule : "UCHACQ ET PARENTIS", cp : "40090" },
{ code : "40321", intitule : "URGONS", cp : "40320" },
{ code : "40322", intitule : "UZA", cp : "40170" },
{ code : "40323", intitule : "VERT", cp : "40420" },
{ code : "40324", intitule : "VICQ D AURIBAT", cp : "40380" },
{ code : "40325", intitule : "VIELLE TURSAN", cp : "40320" },
{ code : "40326", intitule : "VIELLE ST GIRONS", cp : "40560" },
{ code : "40326", intitule : "VIELLE ST GIRONS", cp : "40560" },
{ code : "40326", intitule : "VIELLE ST GIRONS", cp : "40560" },
{ code : "40327", intitule : "VIELLE SOUBIRAN", cp : "40240" },
{ code : "40328", intitule : "VIEUX BOUCAU LES BAINS", cp : "40480" },
{ code : "40329", intitule : "LE VIGNAU", cp : "40270" },
{ code : "40330", intitule : "VILLENAVE", cp : "40110" },
{ code : "40331", intitule : "VILLENEUVE DE MARSAN", cp : "40190" },
{ code : "40332", intitule : "YCHOUX", cp : "40160" },
{ code : "40333", intitule : "YGOS ST SATURNIN", cp : "40110" },
{ code : "40334", intitule : "YZOSSE", cp : "40180" },
{ code : "41001", intitule : "AMBLOY", cp : "41310" },
{ code : "41002", intitule : "ANGE", cp : "41400" },
{ code : "41003", intitule : "AREINES", cp : "41100" },
{ code : "41004", intitule : "ARTINS", cp : "41800" },
{ code : "41005", intitule : "ARVILLE", cp : "41170" },
{ code : "41006", intitule : "AUTAINVILLE", cp : "41240" },
{ code : "41007", intitule : "AUTHON", cp : "41310" },
{ code : "41008", intitule : "AVARAY", cp : "41500" },
{ code : "41009", intitule : "AVERDON", cp : "41330" },
{ code : "41010", intitule : "AZE", cp : "41100" },
{ code : "41011", intitule : "BAIGNEAUX", cp : "41290" },
{ code : "41012", intitule : "BAILLOU", cp : "41170" },
{ code : "41013", intitule : "BAUZY", cp : "41250" },
{ code : "41014", intitule : "BEAUCHENE", cp : "41170" },
{ code : "41015", intitule : "BEAUVILLIERS", cp : "41290" },
{ code : "41016", intitule : "BILLY", cp : "41130" },
{ code : "41017", intitule : "BINAS", cp : "41240" },
{ code : "41018", intitule : "BLOIS", cp : "41000" },
{ code : "41019", intitule : "BOISSEAU", cp : "41290" },
{ code : "41020", intitule : "BONNEVEAU", cp : "41800" },
{ code : "41022", intitule : "BOUFFRY", cp : "41270" },
{ code : "41024", intitule : "BOURSAY", cp : "41270" },
{ code : "41025", intitule : "BRACIEUX", cp : "41250" },
{ code : "41026", intitule : "BREVAINVILLE", cp : "41160" },
{ code : "41027", intitule : "BRIOU", cp : "41370" },
{ code : "41028", intitule : "BUSLOUP", cp : "41160" },
{ code : "41029", intitule : "CANDE SUR BEUVRON", cp : "41120" },
{ code : "41030", intitule : "CELLE", cp : "41360" },
{ code : "41031", intitule : "CELLETTES", cp : "41120" },
{ code : "41032", intitule : "CHAILLES", cp : "41120" },
{ code : "41033", intitule : "CHAMBON SUR CISSE", cp : "41190" },
{ code : "41034", intitule : "CHAMBORD", cp : "41250" },
{ code : "41035", intitule : "CHAMPIGNY EN BEAUCE", cp : "41330" },
{ code : "41036", intitule : "CHAON", cp : "41600" },
{ code : "41037", intitule : "LA CHAPELLE ENCHERIE", cp : "41290" },
{ code : "41038", intitule : "LA CHAPELLE MONTMARTIN", cp : "41320" },
{ code : "41039", intitule : "LA CHAPELLE ST MARTIN EN PLAINE", cp : "41500" },
{ code : "41040", intitule : "LA CHAPELLE VENDOMOISE", cp : "41330" },
{ code : "41041", intitule : "LA CHAPELLE VICOMTESSE", cp : "41270" },
{ code : "41042", intitule : "CHATEAUVIEUX", cp : "41110" },
{ code : "41043", intitule : "CHATILLON SUR CHER", cp : "41130" },
{ code : "41044", intitule : "CHATRES SUR CHER", cp : "41320" },
{ code : "41045", intitule : "CHAUMONT SUR LOIRE", cp : "41150" },
{ code : "41046", intitule : "CHAUMONT SUR THARONNE", cp : "41600" },
{ code : "41047", intitule : "LA CHAUSSEE ST VICTOR", cp : "41260" },
{ code : "41048", intitule : "CHAUVIGNY DU PERCHE", cp : "41270" },
{ code : "41049", intitule : "CHEMERY", cp : "41700" },
{ code : "41050", intitule : "CHEVERNY", cp : "41700" },
{ code : "41051", intitule : "CHISSAY EN TOURAINE", cp : "41400" },
{ code : "41052", intitule : "CHITENAY", cp : "41120" },
{ code : "41053", intitule : "CHOUE", cp : "41170" },
{ code : "41054", intitule : "CHOUSSY", cp : "41700" },
{ code : "41055", intitule : "CHOUZY SUR CISSE", cp : "41150" },
{ code : "41057", intitule : "CONAN", cp : "41290" },
{ code : "41058", intitule : "CONCRIERS", cp : "41370" },
{ code : "41059", intitule : "CONTRES", cp : "41700" },
{ code : "41060", intitule : "CORMENON", cp : "41170" },
{ code : "41061", intitule : "CORMERAY", cp : "41120" },
{ code : "41062", intitule : "COUDDES", cp : "41700" },
{ code : "41063", intitule : "COUFFY", cp : "41110" },
{ code : "41064", intitule : "COULANGES", cp : "41150" },
{ code : "41065", intitule : "COULOMMIERS LA TOUR", cp : "41100" },
{ code : "41066", intitule : "COURBOUZON", cp : "41500" },
{ code : "41067", intitule : "COUR CHEVERNY", cp : "41700" },
{ code : "41068", intitule : "COURMEMIN", cp : "41230" },
{ code : "41069", intitule : "COUR SUR LOIRE", cp : "41500" },
{ code : "41070", intitule : "COUTURE SUR LOIR", cp : "41800" },
{ code : "41071", intitule : "CROUY SUR COSSON", cp : "41220" },
{ code : "41072", intitule : "CRUCHERAY", cp : "41100" },
{ code : "41073", intitule : "DANZE", cp : "41160" },
{ code : "41074", intitule : "DHUIZON", cp : "41220" },
{ code : "41075", intitule : "DROUE", cp : "41270" },
{ code : "41077", intitule : "EPIAIS", cp : "41290" },
{ code : "41078", intitule : "EPUISAY", cp : "41360" },
{ code : "41079", intitule : "LES ESSARTS", cp : "41800" },
{ code : "41080", intitule : "FAVEROLLES SUR CHER", cp : "41400" },
{ code : "41081", intitule : "FAYE", cp : "41100" },
{ code : "41082", intitule : "FEINGS", cp : "41120" },
{ code : "41083", intitule : "LA FERTE BEAUHARNAIS", cp : "41210" },
{ code : "41084", intitule : "LA FERTE IMBAULT", cp : "41300" },
{ code : "41085", intitule : "LA FERTE ST CYR", cp : "41220" },
{ code : "41086", intitule : "FONTAINES EN SOLOGNE", cp : "41250" },
{ code : "41087", intitule : "FONTAINE LES COTEAUX", cp : "41800" },
{ code : "41088", intitule : "FONTAINE RAOUL", cp : "41270" },
{ code : "41089", intitule : "LA FONTENELLE", cp : "41270" },
{ code : "41090", intitule : "FORTAN", cp : "41360" },
{ code : "41091", intitule : "FOSSE", cp : "41330" },
{ code : "41092", intitule : "FOUGERES SUR BIEVRE", cp : "41120" },
{ code : "41093", intitule : "FRANCAY", cp : "41190" },
{ code : "41094", intitule : "FRESNES", cp : "41700" },
{ code : "41095", intitule : "FRETEVAL", cp : "41160" },
{ code : "41096", intitule : "LE GAULT PERCHE", cp : "41270" },
{ code : "41097", intitule : "GIEVRES", cp : "41130" },
{ code : "41098", intitule : "GOMBERGEAN", cp : "41310" },
{ code : "41099", intitule : "GY EN SOLOGNE", cp : "41230" },
{ code : "41100", intitule : "LES HAYES", cp : "41800" },
{ code : "41101", intitule : "HERBAULT", cp : "41190" },
{ code : "41102", intitule : "HOUSSAY", cp : "41800" },
{ code : "41103", intitule : "HUISSEAU EN BEAUCE", cp : "41310" },
{ code : "41104", intitule : "HUISSEAU SUR COSSON", cp : "41350" },
{ code : "41105", intitule : "JOSNES", cp : "41370" },
{ code : "41106", intitule : "LAMOTTE BEUVRON", cp : "41600" },
{ code : "41107", intitule : "LANCE", cp : "41310" },
{ code : "41108", intitule : "LANCOME", cp : "41190" },
{ code : "41109", intitule : "LANDES LE GAULOIS", cp : "41190" },
{ code : "41110", intitule : "LANGON", cp : "41320" },
{ code : "41112", intitule : "LASSAY SUR CROISNE", cp : "41230" },
{ code : "41113", intitule : "LAVARDIN", cp : "41800" },
{ code : "41114", intitule : "LESTIOU", cp : "41500" },
{ code : "41115", intitule : "LIGNIERES", cp : "41160" },
{ code : "41116", intitule : "LISLE", cp : "41100" },
{ code : "41118", intitule : "LOREUX", cp : "41200" },
{ code : "41119", intitule : "LORGES", cp : "41370" },
{ code : "41120", intitule : "LUNAY", cp : "41360" },
{ code : "41121", intitule : "LA MADELEINE VILLEFROUIN", cp : "41370" },
{ code : "41122", intitule : "MARAY", cp : "41320" },
{ code : "41123", intitule : "MARCHENOIR", cp : "41370" },
{ code : "41124", intitule : "MARCILLY EN BEAUCE", cp : "41100" },
{ code : "41125", intitule : "MARCILLY EN GAULT", cp : "41210" },
{ code : "41126", intitule : "MAREUIL SUR CHER", cp : "41110" },
{ code : "41127", intitule : "LA MAROLLE EN SOLOGNE", cp : "41210" },
{ code : "41128", intitule : "MAROLLES", cp : "41330" },
{ code : "41129", intitule : "MASLIVES", cp : "41250" },
{ code : "41130", intitule : "MAVES", cp : "41500" },
{ code : "41131", intitule : "MAZANGE", cp : "41100" },
{ code : "41132", intitule : "MEHERS", cp : "41140" },
{ code : "41134", intitule : "MENARS", cp : "41500" },
{ code : "41135", intitule : "MENNETOU SUR CHER", cp : "41320" },
{ code : "41136", intitule : "MER", cp : "41500" },
{ code : "41137", intitule : "MESLAND", cp : "41150" },
{ code : "41138", intitule : "MESLAY", cp : "41100" },
{ code : "41139", intitule : "MEUSNES", cp : "41130" },
{ code : "41140", intitule : "MILLANCAY", cp : "41200" },
{ code : "41141", intitule : "MOISY", cp : "41160" },
{ code : "41142", intitule : "VALENCISSE", cp : "41190" },
{ code : "41142", intitule : "VALENCISSE", cp : "41190" },
{ code : "41143", intitule : "MONDOUBLEAU", cp : "41170" },
{ code : "41144", intitule : "MONTEAUX", cp : "41150" },
{ code : "41145", intitule : "MONTHOU SUR BIEVRE", cp : "41120" },
{ code : "41146", intitule : "MONTHOU SUR CHER", cp : "41400" },
{ code : "41147", intitule : "LES MONTILS", cp : "41120" },
{ code : "41148", intitule : "MONTLIVAULT", cp : "41350" },
{ code : "41149", intitule : "MONTOIRE SUR LE LOIR", cp : "41800" },
{ code : "41149", intitule : "MONTOIRE SUR LE LOIR", cp : "41800" },
{ code : "41150", intitule : "MONT PRES CHAMBORD", cp : "41250" },
{ code : "41151", intitule : "MONTRICHARD VAL DE CHER", cp : "41400" },
{ code : "41151", intitule : "MONTRICHARD VAL DE CHER", cp : "41400" },
{ code : "41152", intitule : "MONTRIEUX EN SOLOGNE", cp : "41210" },
{ code : "41153", intitule : "MONTROUVEAU", cp : "41800" },
{ code : "41154", intitule : "MOREE", cp : "41160" },
{ code : "41155", intitule : "MUIDES SUR LOIRE", cp : "41500" },
{ code : "41156", intitule : "MULSANS", cp : "41500" },
{ code : "41157", intitule : "MUR DE SOLOGNE", cp : "41230" },
{ code : "41158", intitule : "NAVEIL", cp : "41100" },
{ code : "41159", intitule : "NEUNG SUR BEUVRON", cp : "41210" },
{ code : "41160", intitule : "NEUVY", cp : "41250" },
{ code : "41161", intitule : "NOUAN LE FUZELIER", cp : "41600" },
{ code : "41163", intitule : "NOURRAY", cp : "41310" },
{ code : "41164", intitule : "NOYERS SUR CHER", cp : "41140" },
{ code : "41165", intitule : "OIGNY", cp : "41170" },
{ code : "41166", intitule : "OISLY", cp : "41700" },
{ code : "41167", intitule : "ONZAIN", cp : "41150" },
{ code : "41168", intitule : "ORCAY", cp : "41300" },
{ code : "41170", intitule : "OUCHAMPS", cp : "41120" },
{ code : "41171", intitule : "OUCQUES", cp : "41290" },
{ code : "41172", intitule : "OUZOUER LE DOYEN", cp : "41160" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41240" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41240" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41240" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41160" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41240" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41160" },
{ code : "41173", intitule : "BEAUCE LA ROMAINE", cp : "41240" },
{ code : "41174", intitule : "PERIGNY", cp : "41100" },
{ code : "41175", intitule : "PEZOU", cp : "41100" },
{ code : "41176", intitule : "PIERREFITTE SUR SAULDRE", cp : "41300" },
{ code : "41177", intitule : "LE PLESSIS DORIN", cp : "41170" },
{ code : "41178", intitule : "LE PLESSIS L ECHELLE", cp : "41370" },
{ code : "41179", intitule : "LE POISLAY", cp : "41270" },
{ code : "41180", intitule : "PONTLEVOY", cp : "41400" },
{ code : "41181", intitule : "POUILLE", cp : "41110" },
{ code : "41182", intitule : "PRAY", cp : "41190" },
{ code : "41184", intitule : "PRUNAY CASSEREAU", cp : "41310" },
{ code : "41185", intitule : "PRUNIERS EN SOLOGNE", cp : "41200" },
{ code : "41186", intitule : "RAHART", cp : "41160" },
{ code : "41187", intitule : "RENAY", cp : "41100" },
{ code : "41188", intitule : "RHODON", cp : "41290" },
{ code : "41189", intitule : "RILLY SUR LOIRE", cp : "41150" },
{ code : "41190", intitule : "ROCE", cp : "41100" },
{ code : "41191", intitule : "ROCHES", cp : "41370" },
{ code : "41192", intitule : "LES ROCHES L EVEQUE", cp : "41800" },
{ code : "41193", intitule : "ROMILLY", cp : "41270" },
{ code : "41194", intitule : "ROMORANTIN LANTHENAY", cp : "41200" },
{ code : "41195", intitule : "ROUGEOU", cp : "41230" },
{ code : "41196", intitule : "RUAN SUR EGVONNE", cp : "41270" },
{ code : "41197", intitule : "ST AGIL", cp : "41170" },
{ code : "41198", intitule : "ST AIGNAN", cp : "41110" },
{ code : "41199", intitule : "ST AMAND LONGPRE", cp : "41310" },
{ code : "41200", intitule : "STE ANNE", cp : "41100" },
{ code : "41201", intitule : "ST ARNOULT", cp : "41800" },
{ code : "41202", intitule : "ST AVIT", cp : "41170" },
{ code : "41203", intitule : "ST BOHAIRE", cp : "41330" },
{ code : "41204", intitule : "ST CLAUDE DE DIRAY", cp : "41350" },
{ code : "41205", intitule : "ST CYR DU GAULT", cp : "41190" },
{ code : "41206", intitule : "ST DENIS SUR LOIRE", cp : "41000" },
{ code : "41207", intitule : "ST DYE SUR LOIRE", cp : "41500" },
{ code : "41208", intitule : "ST ETIENNE DES GUERETS", cp : "41190" },
{ code : "41209", intitule : "ST FIRMIN DES PRES", cp : "41100" },
{ code : "41210", intitule : "STE GEMMES", cp : "41290" },
{ code : "41211", intitule : "ST GEORGES SUR CHER", cp : "41400" },
{ code : "41212", intitule : "ST GERVAIS LA FORET", cp : "41350" },
{ code : "41213", intitule : "ST GOURGON", cp : "41310" },
{ code : "41214", intitule : "ST HILAIRE LA GRAVELLE", cp : "41160" },
{ code : "41215", intitule : "ST JACQUES DES GUERETS", cp : "41800" },
{ code : "41216", intitule : "ST JEAN FROIDMENTEL", cp : "41160" },
{ code : "41217", intitule : "ST JULIEN DE CHEDON", cp : "41400" },
{ code : "41218", intitule : "ST JULIEN SUR CHER", cp : "41320" },
{ code : "41219", intitule : "ST LAURENT DES BOIS", cp : "41240" },
{ code : "41220", intitule : "ST LAURENT NOUAN", cp : "41220" },
{ code : "41220", intitule : "ST LAURENT NOUAN", cp : "41220" },
{ code : "41221", intitule : "ST LEONARD EN BEAUCE", cp : "41370" },
{ code : "41222", intitule : "ST LOUP", cp : "41320" },
{ code : "41223", intitule : "ST LUBIN EN VERGONNOIS", cp : "41190" },
{ code : "41224", intitule : "ST MARC DU COR", cp : "41170" },
{ code : "41225", intitule : "ST MARTIN DES BOIS", cp : "41800" },
{ code : "41226", intitule : "ST OUEN", cp : "41100" },
{ code : "41228", intitule : "ST RIMAY", cp : "41800" },
{ code : "41229", intitule : "ST ROMAIN SUR CHER", cp : "41140" },
{ code : "41230", intitule : "ST SULPICE DE POMMERAY", cp : "41000" },
{ code : "41231", intitule : "ST VIATRE", cp : "41210" },
{ code : "41232", intitule : "SALBRIS", cp : "41300" },
{ code : "41233", intitule : "SAMBIN", cp : "41120" },
{ code : "41234", intitule : "SANTENAY", cp : "41190" },
{ code : "41235", intitule : "SARGE SUR BRAYE", cp : "41170" },
{ code : "41236", intitule : "SASNIERES", cp : "41310" },
{ code : "41237", intitule : "SASSAY", cp : "41700" },
{ code : "41238", intitule : "SAVIGNY SUR BRAYE", cp : "41360" },
{ code : "41239", intitule : "SEIGY", cp : "41110" },
{ code : "41240", intitule : "SEILLAC", cp : "41150" },
{ code : "41241", intitule : "SELLES ST DENIS", cp : "41300" },
{ code : "41242", intitule : "SELLES SUR CHER", cp : "41130" },
{ code : "41243", intitule : "SELOMMES", cp : "41100" },
{ code : "41245", intitule : "SERIS", cp : "41500" },
{ code : "41246", intitule : "SEUR", cp : "41120" },
{ code : "41247", intitule : "SOINGS EN SOLOGNE", cp : "41230" },
{ code : "41248", intitule : "SOUDAY", cp : "41170" },
{ code : "41249", intitule : "SOUESMES", cp : "41300" },
{ code : "41250", intitule : "SOUGE", cp : "41800" },
{ code : "41251", intitule : "SOUVIGNY EN SOLOGNE", cp : "41600" },
{ code : "41252", intitule : "SUEVRES", cp : "41500" },
{ code : "41253", intitule : "TALCY", cp : "41370" },
{ code : "41254", intitule : "LE TEMPLE", cp : "41170" },
{ code : "41255", intitule : "TERNAY", cp : "41800" },
{ code : "41256", intitule : "THEILLAY", cp : "41300" },
{ code : "41257", intitule : "THENAY", cp : "41400" },
{ code : "41258", intitule : "THESEE", cp : "41140" },
{ code : "41259", intitule : "THORE LA ROCHETTE", cp : "41100" },
{ code : "41260", intitule : "THOURY", cp : "41220" },
{ code : "41261", intitule : "TOURAILLES", cp : "41190" },
{ code : "41262", intitule : "TOUR EN SOLOGNE", cp : "41250" },
{ code : "41263", intitule : "TREHET", cp : "41800" },
{ code : "41265", intitule : "TROO", cp : "41800" },
{ code : "41266", intitule : "VALAIRE", cp : "41120" },
{ code : "41267", intitule : "VALLIERES LES GRANDES", cp : "41400" },
{ code : "41268", intitule : "VEILLEINS", cp : "41230" },
{ code : "41269", intitule : "VENDOME", cp : "41100" },
{ code : "41271", intitule : "VERNOU EN SOLOGNE", cp : "41230" },
{ code : "41272", intitule : "VEUVES", cp : "41150" },
{ code : "41273", intitule : "VIEVY LE RAYE", cp : "41290" },
{ code : "41273", intitule : "VIEVY LE RAYE", cp : "41290" },
{ code : "41273", intitule : "VIEVY LE RAYE", cp : "41290" },
{ code : "41274", intitule : "VILLAVARD", cp : "41800" },
{ code : "41275", intitule : "LA VILLE AUX CLERCS", cp : "41160" },
{ code : "41276", intitule : "VILLEBAROU", cp : "41000" },
{ code : "41277", intitule : "VILLEBOUT", cp : "41270" },
{ code : "41278", intitule : "VILLECHAUVE", cp : "41310" },
{ code : "41279", intitule : "VILLEDIEU LE CHATEAU", cp : "41800" },
{ code : "41280", intitule : "VILLEFRANCHE SUR CHER", cp : "41200" },
{ code : "41281", intitule : "VILLEFRANCOEUR", cp : "41330" },
{ code : "41282", intitule : "VILLEHERVIERS", cp : "41200" },
{ code : "41283", intitule : "VILLEMARDY", cp : "41100" },
{ code : "41284", intitule : "VILLENEUVE FROUVILLE", cp : "41290" },
{ code : "41285", intitule : "VILLENY", cp : "41220" },
{ code : "41286", intitule : "VILLEPORCHER", cp : "41310" },
{ code : "41287", intitule : "VILLERABLE", cp : "41100" },
{ code : "41288", intitule : "VILLERBON", cp : "41000" },
{ code : "41289", intitule : "VILLERMAIN", cp : "41240" },
{ code : "41290", intitule : "VILLEROMAIN", cp : "41100" },
{ code : "41291", intitule : "VILLETRUN", cp : "41100" },
{ code : "41292", intitule : "VILLEXANTON", cp : "41500" },
{ code : "41293", intitule : "VILLIERSFAUX", cp : "41100" },
{ code : "41294", intitule : "VILLIERS SUR LOIR", cp : "41100" },
{ code : "41295", intitule : "VINEUIL", cp : "41350" },
{ code : "41296", intitule : "VOUZON", cp : "41600" },
{ code : "41297", intitule : "YVOY LE MARRON", cp : "41600" },
{ code : "42001", intitule : "ABOEN", cp : "42380" },
{ code : "42002", intitule : "AILLEUX", cp : "42130" },
{ code : "42003", intitule : "AMBIERLE", cp : "42820" },
{ code : "42004", intitule : "AMIONS", cp : "42260" },
{ code : "42005", intitule : "ANDREZIEUX BOUTHEON", cp : "42160" },
{ code : "42006", intitule : "APINAC", cp : "42550" },
{ code : "42007", intitule : "ARCINGES", cp : "42460" },
{ code : "42008", intitule : "ARCON", cp : "42370" },
{ code : "42009", intitule : "ARTHUN", cp : "42130" },
{ code : "42010", intitule : "AVEIZIEUX", cp : "42330" },
{ code : "42011", intitule : "BALBIGNY", cp : "42510" },
{ code : "42012", intitule : "BARD", cp : "42600" },
{ code : "42013", intitule : "BELLEGARDE EN FOREZ", cp : "42210" },
{ code : "42014", intitule : "BELLEROCHE", cp : "42670" },
{ code : "42015", intitule : "BELMONT DE LA LOIRE", cp : "42670" },
{ code : "42016", intitule : "LA BENISSON DIEU", cp : "42720" },
{ code : "42017", intitule : "LE BESSAT", cp : "42660" },
{ code : "42018", intitule : "BESSEY", cp : "42520" },
{ code : "42019", intitule : "BOEN SUR LIGNON", cp : "42130" },
{ code : "42020", intitule : "BOISSET LES MONTROND", cp : "42210" },
{ code : "42021", intitule : "BOISSET ST PRIEST", cp : "42560" },
{ code : "42022", intitule : "BONSON", cp : "42160" },
{ code : "42023", intitule : "BOURG ARGENTAL", cp : "42220" },
{ code : "42025", intitule : "BOYER", cp : "42460" },
{ code : "42026", intitule : "BRIENNON", cp : "42720" },
{ code : "42027", intitule : "BULLY", cp : "42260" },
{ code : "42028", intitule : "BURDIGNES", cp : "42220" },
{ code : "42029", intitule : "BUSSIERES", cp : "42510" },
{ code : "42030", intitule : "BUSSY ALBIEUX", cp : "42260" },
{ code : "42031", intitule : "CALOIRE", cp : "42240" },
{ code : "42032", intitule : "CELLIEU", cp : "42320" },
{ code : "42033", intitule : "LE CERGNE", cp : "42460" },
{ code : "42034", intitule : "CERVIERES", cp : "42440" },
{ code : "42035", intitule : "CEZAY", cp : "42130" },
{ code : "42036", intitule : "CHAGNON", cp : "42800" },
{ code : "42037", intitule : "CHALAIN D UZORE", cp : "42600" },
{ code : "42038", intitule : "CHALAIN LE COMTAL", cp : "42600" },
{ code : "42039", intitule : "CHALMAZEL JEANSAGNIERE", cp : "42920" },
{ code : "42039", intitule : "CHALMAZEL JEANSAGNIERE", cp : "42920" },
{ code : "42040", intitule : "LA CHAMBA", cp : "42440" },
{ code : "42041", intitule : "CHAMBEON", cp : "42110" },
{ code : "42042", intitule : "CHAMBLES", cp : "42170" },
{ code : "42043", intitule : "CHAMBOEUF", cp : "42330" },
{ code : "42044", intitule : "LE CHAMBON FEUGEROLLES", cp : "42500" },
{ code : "42045", intitule : "LA CHAMBONIE", cp : "42440" },
{ code : "42046", intitule : "CHAMPDIEU", cp : "42600" },
{ code : "42047", intitule : "CHAMPOLY", cp : "42430" },
{ code : "42048", intitule : "CHANDON", cp : "42190" },
{ code : "42049", intitule : "CHANGY", cp : "42310" },
{ code : "42050", intitule : "LA CHAPELLE EN LAFAYE", cp : "42380" },
{ code : "42051", intitule : "LA CHAPELLE VILLARS", cp : "42410" },
{ code : "42052", intitule : "CHARLIEU", cp : "42190" },
{ code : "42053", intitule : "CHATEAUNEUF", cp : "42800" },
{ code : "42054", intitule : "CHATELNEUF", cp : "42940" },
{ code : "42055", intitule : "CHATELUS", cp : "42140" },
{ code : "42056", intitule : "CHAVANAY", cp : "42410" },
{ code : "42058", intitule : "CHAZELLES SUR LAVIEU", cp : "42560" },
{ code : "42059", intitule : "CHAZELLES SUR LYON", cp : "42140" },
{ code : "42060", intitule : "CHENEREILLES", cp : "42560" },
{ code : "42061", intitule : "CHERIER", cp : "42430" },
{ code : "42062", intitule : "CHEVRIERES", cp : "42140" },
{ code : "42063", intitule : "CHIRASSIMONT", cp : "42114" },
{ code : "42064", intitule : "CHUYER", cp : "42410" },
{ code : "42065", intitule : "CIVENS", cp : "42110" },
{ code : "42066", intitule : "CLEPPE", cp : "42110" },
{ code : "42067", intitule : "COLOMBIER", cp : "42220" },
{ code : "42068", intitule : "COMBRE", cp : "42840" },
{ code : "42069", intitule : "COMMELLE VERNAY", cp : "42120" },
{ code : "42070", intitule : "CORDELLE", cp : "42123" },
{ code : "42071", intitule : "LE COTEAU", cp : "42120" },
{ code : "42072", intitule : "LA COTE EN COUZAN", cp : "42111" },
{ code : "42073", intitule : "COTTANCE", cp : "42360" },
{ code : "42074", intitule : "COUTOUVRE", cp : "42460" },
{ code : "42075", intitule : "CRAINTILLEUX", cp : "42210" },
{ code : "42076", intitule : "CREMEAUX", cp : "42260" },
{ code : "42077", intitule : "CROIZET SUR GAND", cp : "42540" },
{ code : "42078", intitule : "LE CROZET", cp : "42310" },
{ code : "42079", intitule : "CUINZIER", cp : "42460" },
{ code : "42081", intitule : "CUZIEU", cp : "42330" },
{ code : "42082", intitule : "DANCE", cp : "42260" },
{ code : "42083", intitule : "DARGOIRE", cp : "42800" },
{ code : "42084", intitule : "DEBATS RIVIERE D ORPRA", cp : "42130" },
{ code : "42085", intitule : "DOIZIEUX", cp : "42740" },
{ code : "42086", intitule : "ECOCHE", cp : "42670" },
{ code : "42087", intitule : "ECOTAY L OLME", cp : "42600" },
{ code : "42088", intitule : "EPERCIEUX ST PAUL", cp : "42110" },
{ code : "42089", intitule : "ESSERTINES EN CHATELNEUF", cp : "42600" },
{ code : "42090", intitule : "ESSERTINES EN DONZY", cp : "42360" },
{ code : "42091", intitule : "ESTIVAREILLES", cp : "42380" },
{ code : "42092", intitule : "L ETRAT", cp : "42580" },
{ code : "42093", intitule : "FARNAY", cp : "42320" },
{ code : "42094", intitule : "FEURS", cp : "42110" },
{ code : "42095", intitule : "FIRMINY", cp : "42700" },
{ code : "42096", intitule : "FONTANES", cp : "42140" },
{ code : "42097", intitule : "LA FOUILLOUSE", cp : "42480" },
{ code : "42098", intitule : "FOURNEAUX", cp : "42470" },
{ code : "42099", intitule : "FRAISSES", cp : "42490" },
{ code : "42100", intitule : "LA GIMOND", cp : "42140" },
{ code : "42101", intitule : "GRAIX", cp : "42220" },
{ code : "42102", intitule : "GRAMMOND", cp : "42140" },
{ code : "42103", intitule : "LA GRAND CROIX", cp : "42320" },
{ code : "42104", intitule : "LA GRESLE", cp : "42460" },
{ code : "42105", intitule : "GREZIEUX LE FROMENTAL", cp : "42600" },
{ code : "42106", intitule : "GREZOLLES", cp : "42260" },
{ code : "42107", intitule : "GUMIERES", cp : "42560" },
{ code : "42108", intitule : "L HOPITAL LE GRAND", cp : "42210" },
{ code : "42109", intitule : "L HOPITAL SOUS ROCHEFORT", cp : "42130" },
{ code : "42110", intitule : "L HORME", cp : "42152" },
{ code : "42112", intitule : "JARNOSSE", cp : "42460" },
{ code : "42113", intitule : "JAS", cp : "42110" },
{ code : "42115", intitule : "JONZIEUX", cp : "42660" },
{ code : "42116", intitule : "JURE", cp : "42430" },
{ code : "42117", intitule : "LAVIEU", cp : "42560" },
{ code : "42118", intitule : "LAY", cp : "42470" },
{ code : "42119", intitule : "LEIGNEUX", cp : "42130" },
{ code : "42120", intitule : "LENTIGNY", cp : "42155" },
{ code : "42121", intitule : "LERIGNEUX", cp : "42600" },
{ code : "42122", intitule : "LEZIGNEUX", cp : "42600" },
{ code : "42123", intitule : "LORETTE", cp : "42420" },
{ code : "42124", intitule : "LUPE", cp : "42520" },
{ code : "42125", intitule : "LURE", cp : "42260" },
{ code : "42126", intitule : "LURIECQ", cp : "42380" },
{ code : "42127", intitule : "MABLY", cp : "42300" },
{ code : "42127", intitule : "MABLY", cp : "42300" },
{ code : "42128", intitule : "MACHEZAL", cp : "42114" },
{ code : "42129", intitule : "MACLAS", cp : "42520" },
{ code : "42130", intitule : "MAGNEUX HAUTE RIVE", cp : "42600" },
{ code : "42131", intitule : "MAIZILLY", cp : "42750" },
{ code : "42132", intitule : "MALLEVAL", cp : "42520" },
{ code : "42133", intitule : "MARCENOD", cp : "42140" },
{ code : "42134", intitule : "MARCILLY LE CHATEL", cp : "42130" },
{ code : "42135", intitule : "MARCLOPT", cp : "42210" },
{ code : "42136", intitule : "MARCOUX", cp : "42130" },
{ code : "42137", intitule : "MARGERIE CHANTAGRET", cp : "42560" },
{ code : "42138", intitule : "MARINGES", cp : "42140" },
{ code : "42139", intitule : "MARLHES", cp : "42660" },
{ code : "42140", intitule : "MAROLS", cp : "42560" },
{ code : "42141", intitule : "MARS", cp : "42750" },
{ code : "42142", intitule : "MERLE LEIGNEC", cp : "42380" },
{ code : "42143", intitule : "MIZERIEUX", cp : "42110" },
{ code : "42145", intitule : "MONTAGNY", cp : "42840" },
{ code : "42146", intitule : "MONTARCHER", cp : "42380" },
{ code : "42147", intitule : "MONTBRISON", cp : "42600" },
{ code : "42147", intitule : "MONTBRISON", cp : "42600" },
{ code : "42148", intitule : "MONTCHAL", cp : "42360" },
{ code : "42149", intitule : "MONTROND LES BAINS", cp : "42210" },
{ code : "42150", intitule : "MONTVERDUN", cp : "42130" },
{ code : "42151", intitule : "MORNAND EN FOREZ", cp : "42600" },
{ code : "42152", intitule : "NANDAX", cp : "42720" },
{ code : "42153", intitule : "NEAUX", cp : "42470" },
{ code : "42154", intitule : "NERONDE", cp : "42510" },
{ code : "42155", intitule : "NERVIEUX", cp : "42510" },
{ code : "42156", intitule : "NEULISE", cp : "42590" },
{ code : "42157", intitule : "NOAILLY", cp : "42640" },
{ code : "42158", intitule : "LES NOES", cp : "42370" },
{ code : "42159", intitule : "NOIRETABLE", cp : "42440" },
{ code : "42160", intitule : "NOLLIEUX", cp : "42260" },
{ code : "42161", intitule : "NOTRE DAME DE BOISSET", cp : "42120" },
{ code : "42162", intitule : "OUCHES", cp : "42155" },
{ code : "42163", intitule : "LA PACAUDIERE", cp : "42310" },
{ code : "42164", intitule : "PALOGNEUX", cp : "42990" },
{ code : "42165", intitule : "PANISSIERES", cp : "42360" },
{ code : "42166", intitule : "PARIGNY", cp : "42120" },
{ code : "42167", intitule : "PAVEZIN", cp : "42410" },
{ code : "42168", intitule : "PELUSSIN", cp : "42410" },
{ code : "42169", intitule : "PERIGNEUX", cp : "42380" },
{ code : "42170", intitule : "PERREUX", cp : "42120" },
{ code : "42171", intitule : "PINAY", cp : "42590" },
{ code : "42172", intitule : "PLANFOY", cp : "42660" },
{ code : "42173", intitule : "POMMIERS", cp : "42260" },
{ code : "42174", intitule : "PONCINS", cp : "42110" },
{ code : "42175", intitule : "POUILLY LES FEURS", cp : "42110" },
{ code : "42176", intitule : "POUILLY LES NONAINS", cp : "42155" },
{ code : "42177", intitule : "POUILLY SOUS CHARLIEU", cp : "42720" },
{ code : "42178", intitule : "PRADINES", cp : "42630" },
{ code : "42179", intitule : "PRALONG", cp : "42600" },
{ code : "42180", intitule : "PRECIEUX", cp : "42600" },
{ code : "42181", intitule : "REGNY", cp : "42630" },
{ code : "42182", intitule : "RENAISON", cp : "42370" },
{ code : "42183", intitule : "LA RICAMARIE", cp : "42150" },
{ code : "42184", intitule : "RIORGES", cp : "42153" },
{ code : "42185", intitule : "RIVAS", cp : "42340" },
{ code : "42186", intitule : "RIVE DE GIER", cp : "42800" },
{ code : "42187", intitule : "ROANNE", cp : "42300" },
{ code : "42187", intitule : "ROANNE", cp : "42300" },
{ code : "42188", intitule : "ROCHE", cp : "42600" },
{ code : "42189", intitule : "ROCHE LA MOLIERE", cp : "42230" },
{ code : "42191", intitule : "ROISEY", cp : "42520" },
{ code : "42192", intitule : "ROZIER COTES D AUREC", cp : "42380" },
{ code : "42193", intitule : "ROZIER EN DONZY", cp : "42810" },
{ code : "42194", intitule : "SAIL LES BAINS", cp : "42310" },
{ code : "42195", intitule : "SAIL SOUS COUZAN", cp : "42890" },
{ code : "42196", intitule : "STE AGATHE EN DONZY", cp : "42510" },
{ code : "42197", intitule : "STE AGATHE LA BOUTERESSE", cp : "42130" },
{ code : "42198", intitule : "ST ALBAN LES EAUX", cp : "42370" },
{ code : "42199", intitule : "ST ANDRE D APCHON", cp : "42370" },
{ code : "42200", intitule : "ST ANDRE LE PUY", cp : "42210" },
{ code : "42201", intitule : "ST APPOLINARD", cp : "42520" },
{ code : "42202", intitule : "ST BARTHELEMY LESTRA", cp : "42110" },
{ code : "42203", intitule : "ST BONNET DES QUARTS", cp : "42310" },
{ code : "42204", intitule : "ST BONNET LE CHATEAU", cp : "42380" },
{ code : "42205", intitule : "ST BONNET LE COURREAU", cp : "42940" },
{ code : "42206", intitule : "ST BONNET LES OULES", cp : "42330" },
{ code : "42207", intitule : "ST CHAMOND", cp : "42400" },
{ code : "42207", intitule : "ST CHAMOND", cp : "42400" },
{ code : "42207", intitule : "ST CHAMOND", cp : "42400" },
{ code : "42207", intitule : "ST CHAMOND", cp : "42400" },
{ code : "42208", intitule : "ST CHRISTO EN JAREZ", cp : "42320" },
{ code : "42209", intitule : "STE COLOMBE SUR GAND", cp : "42540" },
{ code : "42210", intitule : "STE CROIX EN JAREZ", cp : "42800" },
{ code : "42211", intitule : "ST CYPRIEN", cp : "42160" },
{ code : "42212", intitule : "ST CYR DE FAVIERES", cp : "42123" },
{ code : "42213", intitule : "ST CYR DE VALORGES", cp : "42114" },
{ code : "42214", intitule : "ST CYR LES VIGNES", cp : "42210" },
{ code : "42215", intitule : "ST DENIS DE CABANNE", cp : "42750" },
{ code : "42216", intitule : "ST DENIS SUR COISE", cp : "42140" },
{ code : "42217", intitule : "ST DIDIER SUR ROCHEFORT", cp : "42111" },
{ code : "42218", intitule : "ST ETIENNE", cp : "42000" },
{ code : "42218", intitule : "ST ETIENNE", cp : "42100" },
{ code : "42218", intitule : "ST ETIENNE", cp : "42230" },
{ code : "42218", intitule : "ST ETIENNE", cp : "42100" },
{ code : "42218", intitule : "ST ETIENNE", cp : "42100" },
{ code : "42219", intitule : "ST ETIENNE LE MOLARD", cp : "42130" },
{ code : "42220", intitule : "ST FORGEUX LESPINASSE", cp : "42640" },
{ code : "42221", intitule : "STE FOY ST SULPICE", cp : "42110" },
{ code : "42222", intitule : "ST GALMIER", cp : "42330" },
{ code : "42223", intitule : "ST GENEST LERPT", cp : "42530" },
{ code : "42224", intitule : "ST GENEST MALIFAUX", cp : "42660" },
{ code : "42225", intitule : "GENILAC", cp : "42800" },
{ code : "42225", intitule : "GENILAC", cp : "42800" },
{ code : "42226", intitule : "ST GEORGES DE BAROILLE", cp : "42510" },
{ code : "42227", intitule : "ST GEORGES EN COUZAN", cp : "42990" },
{ code : "42228", intitule : "ST GEORGES HAUTE VILLE", cp : "42610" },
{ code : "42229", intitule : "ST GERMAIN LA MONTAGNE", cp : "42670" },
{ code : "42230", intitule : "ST GERMAIN LAVAL", cp : "42260" },
{ code : "42231", intitule : "ST GERMAIN LESPINASSE", cp : "42640" },
{ code : "42232", intitule : "ST HAON LE CHATEL", cp : "42370" },
{ code : "42233", intitule : "ST HAON LE VIEUX", cp : "42370" },
{ code : "42234", intitule : "ST HEAND", cp : "42570" },
{ code : "42235", intitule : "ST HILAIRE CUSSON LA VALMITTE", cp : "42380" },
{ code : "42236", intitule : "ST HILAIRE SOUS CHARLIEU", cp : "42190" },
{ code : "42237", intitule : "ST JEAN BONNEFONDS", cp : "42650" },
{ code : "42238", intitule : "ST JEAN LA VETRE", cp : "42440" },
{ code : "42239", intitule : "ST JEAN ST MAURICE SUR LOIRE", cp : "42155" },
{ code : "42239", intitule : "ST JEAN ST MAURICE SUR LOIRE", cp : "42155" },
{ code : "42240", intitule : "ST JEAN SOLEYMIEUX", cp : "42560" },
{ code : "42241", intitule : "ST JODARD", cp : "42590" },
{ code : "42242", intitule : "ST JOSEPH", cp : "42800" },
{ code : "42243", intitule : "ST JULIEN D ODDES", cp : "42260" },
{ code : "42245", intitule : "ST JULIEN LA VETRE", cp : "42440" },
{ code : "42246", intitule : "ST JULIEN MOLIN MOLETTE", cp : "42220" },
{ code : "42247", intitule : "ST JUST EN BAS", cp : "42990" },
{ code : "42248", intitule : "ST JUST EN CHEVALET", cp : "42430" },
{ code : "42249", intitule : "ST JUST LA PENDUE", cp : "42540" },
{ code : "42251", intitule : "ST LAURENT LA CONCHE", cp : "42210" },
{ code : "42252", intitule : "ST LAURENT ROCHEFORT", cp : "42130" },
{ code : "42253", intitule : "ST LEGER SUR ROANNE", cp : "42155" },
{ code : "42254", intitule : "ST MARCEL DE FELINES", cp : "42122" },
{ code : "42255", intitule : "ST MARCEL D URFE", cp : "42430" },
{ code : "42256", intitule : "ST MARCELLIN EN FOREZ", cp : "42680" },
{ code : "42257", intitule : "ST MARTIN D ESTREAUX", cp : "42620" },
{ code : "42259", intitule : "ST MARTIN LA PLAINE", cp : "42800" },
{ code : "42260", intitule : "ST MARTIN LA SAUVETE", cp : "42260" },
{ code : "42261", intitule : "ST MARTIN LESTRA", cp : "42110" },
{ code : "42262", intitule : "ST MAURICE EN GOURGOIS", cp : "42240" },
{ code : "42264", intitule : "ST MEDARD EN FOREZ", cp : "42330" },
{ code : "42265", intitule : "ST MICHEL SUR RHONE", cp : "42410" },
{ code : "42266", intitule : "ST NIZIER DE FORNAS", cp : "42380" },
{ code : "42267", intitule : "ST NIZIER SOUS CHARLIEU", cp : "42190" },
{ code : "42268", intitule : "ST PAUL DE VEZELIN", cp : "42590" },
{ code : "42269", intitule : "ST PAUL D UZORE", cp : "42600" },
{ code : "42270", intitule : "ST PAUL EN CORNILLON", cp : "42240" },
{ code : "42271", intitule : "ST PAUL EN JAREZ", cp : "42740" },
{ code : "42272", intitule : "ST PIERRE DE BOEUF", cp : "42520" },
{ code : "42273", intitule : "ST PIERRE LA NOAILLE", cp : "42190" },
{ code : "42274", intitule : "ST POLGUES", cp : "42260" },
{ code : "42275", intitule : "ST PRIEST EN JAREZ", cp : "42270" },
{ code : "42276", intitule : "ST PRIEST LA PRUGNE", cp : "42830" },
{ code : "42277", intitule : "ST PRIEST LA ROCHE", cp : "42590" },
{ code : "42278", intitule : "ST PRIEST LA VETRE", cp : "42440" },
{ code : "42279", intitule : "ST JUST ST RAMBERT", cp : "42170" },
{ code : "42279", intitule : "ST JUST ST RAMBERT", cp : "42170" },
{ code : "42280", intitule : "ST REGIS DU COIN", cp : "42660" },
{ code : "42281", intitule : "ST RIRAND", cp : "42370" },
{ code : "42282", intitule : "ST ROMAIN D URFE", cp : "42430" },
{ code : "42283", intitule : "ST ROMAIN EN JAREZ", cp : "42800" },
{ code : "42284", intitule : "ST ROMAIN LA MOTTE", cp : "42640" },
{ code : "42285", intitule : "ST ROMAIN LE PUY", cp : "42610" },
{ code : "42286", intitule : "ST ROMAIN LES ATHEUX", cp : "42660" },
{ code : "42287", intitule : "ST SAUVEUR EN RUE", cp : "42220" },
{ code : "42288", intitule : "ST SIXTE", cp : "42130" },
{ code : "42289", intitule : "ST SYMPHORIEN DE LAY", cp : "42470" },
{ code : "42290", intitule : "ST THOMAS LA GARDE", cp : "42600" },
{ code : "42291", intitule : "ST THURIN", cp : "42111" },
{ code : "42293", intitule : "ST VICTOR SUR RHINS", cp : "42630" },
{ code : "42294", intitule : "ST VINCENT DE BOISSET", cp : "42120" },
{ code : "42295", intitule : "LES SALLES", cp : "42440" },
{ code : "42296", intitule : "SALT EN DONZY", cp : "42110" },
{ code : "42297", intitule : "SALVIZINET", cp : "42110" },
{ code : "42298", intitule : "SAUVAIN", cp : "42990" },
{ code : "42299", intitule : "SAVIGNEUX", cp : "42600" },
{ code : "42300", intitule : "SEVELINGES", cp : "42460" },
{ code : "42301", intitule : "SOLEYMIEUX", cp : "42560" },
{ code : "42302", intitule : "SORBIERS", cp : "42290" },
{ code : "42303", intitule : "SOUTERNON", cp : "42260" },
{ code : "42304", intitule : "SURY LE COMTAL", cp : "42450" },
{ code : "42305", intitule : "LA TALAUDIERE", cp : "42350" },
{ code : "42306", intitule : "TARENTAISE", cp : "42660" },
{ code : "42307", intitule : "TARTARAS", cp : "42800" },
{ code : "42308", intitule : "LA TERRASSE SUR DORLAY", cp : "42740" },
{ code : "42310", intitule : "THELIS LA COMBE", cp : "42220" },
{ code : "42311", intitule : "LA TOUR EN JAREZ", cp : "42580" },
{ code : "42312", intitule : "LA TOURETTE", cp : "42380" },
{ code : "42313", intitule : "TRELINS", cp : "42130" },
{ code : "42314", intitule : "LA TUILIERE", cp : "42830" },
{ code : "42315", intitule : "UNIAS", cp : "42210" },
{ code : "42316", intitule : "UNIEUX", cp : "42240" },
{ code : "42317", intitule : "URBISE", cp : "42310" },
{ code : "42318", intitule : "USSON EN FOREZ", cp : "42550" },
{ code : "42319", intitule : "VALEILLE", cp : "42110" },
{ code : "42320", intitule : "VALFLEURY", cp : "42320" },
{ code : "42321", intitule : "LA VALLA SUR ROCHEFORT", cp : "42111" },
{ code : "42322", intitule : "LA VALLA EN GIER", cp : "42131" },
{ code : "42323", intitule : "VEAUCHE", cp : "42340" },
{ code : "42324", intitule : "VEAUCHETTE", cp : "42340" },
{ code : "42325", intitule : "VENDRANGES", cp : "42590" },
{ code : "42326", intitule : "VERANNE", cp : "42520" },
{ code : "42327", intitule : "VERIN", cp : "42410" },
{ code : "42328", intitule : "VERRIERES EN FOREZ", cp : "42600" },
{ code : "42329", intitule : "LA VERSANNE", cp : "42220" },
{ code : "42330", intitule : "VILLARS", cp : "42390" },
{ code : "42331", intitule : "VILLEMONTAIS", cp : "42155" },
{ code : "42332", intitule : "VILLEREST", cp : "42300" },
{ code : "42333", intitule : "VILLERS", cp : "42460" },
{ code : "42334", intitule : "VIOLAY", cp : "42780" },
{ code : "42335", intitule : "VIRICELLES", cp : "42140" },
{ code : "42336", intitule : "VIRIGNEUX", cp : "42140" },
{ code : "42337", intitule : "VIVANS", cp : "42310" },
{ code : "42338", intitule : "VOUGY", cp : "42720" },
{ code : "42339", intitule : "CHAUSSETERRE", cp : "42430" },
{ code : "43001", intitule : "AGNAT", cp : "43100" },
{ code : "43002", intitule : "AIGUILHE", cp : "43000" },
{ code : "43003", intitule : "ALLEGRE", cp : "43270" },
{ code : "43004", intitule : "ALLEYRAC", cp : "43150" },
{ code : "43005", intitule : "ALLEYRAS", cp : "43580" },
{ code : "43006", intitule : "ALLY", cp : "43380" },
{ code : "43007", intitule : "ARAULES", cp : "43200" },
{ code : "43008", intitule : "ARLEMPDES", cp : "43490" },
{ code : "43009", intitule : "ARLET", cp : "43380" },
{ code : "43010", intitule : "ARSAC EN VELAY", cp : "43700" },
{ code : "43011", intitule : "AUBAZAT", cp : "43380" },
{ code : "43012", intitule : "AUREC SUR LOIRE", cp : "43110" },
{ code : "43013", intitule : "VISSAC AUTEYRAC", cp : "43300" },
{ code : "43013", intitule : "VISSAC AUTEYRAC", cp : "43300" },
{ code : "43014", intitule : "AUTRAC", cp : "43450" },
{ code : "43015", intitule : "AUVERS", cp : "43300" },
{ code : "43016", intitule : "AUZON", cp : "43390" },
{ code : "43017", intitule : "AZERAT", cp : "43390" },
{ code : "43018", intitule : "BAINS", cp : "43370" },
{ code : "43019", intitule : "BARGES", cp : "43340" },
{ code : "43020", intitule : "BAS EN BASSET", cp : "43210" },
{ code : "43021", intitule : "BEAULIEU", cp : "43800" },
{ code : "43022", intitule : "BEAUMONT", cp : "43100" },
{ code : "43023", intitule : "BEAUNE SUR ARZON", cp : "43500" },
{ code : "43024", intitule : "BEAUX", cp : "43200" },
{ code : "43025", intitule : "BEAUZAC", cp : "43590" },
{ code : "43026", intitule : "BELLEVUE LA MONTAGNE", cp : "43350" },
{ code : "43027", intitule : "BERBEZIT", cp : "43160" },
{ code : "43028", intitule : "BESSAMOREL", cp : "43200" },
{ code : "43029", intitule : "LA BESSEYRE ST MARY", cp : "43170" },
{ code : "43030", intitule : "BLANZAC", cp : "43350" },
{ code : "43031", intitule : "BLASSAC", cp : "43380" },
{ code : "43032", intitule : "BLAVOZY", cp : "43700" },
{ code : "43033", intitule : "BLESLE", cp : "43450" },
{ code : "43034", intitule : "BOISSET", cp : "43500" },
{ code : "43035", intitule : "BONNEVAL", cp : "43160" },
{ code : "43036", intitule : "BORNE", cp : "43350" },
{ code : "43037", intitule : "LE BOUCHET ST NICOLAS", cp : "43510" },
{ code : "43038", intitule : "BOURNONCLE ST PIERRE", cp : "43360" },
{ code : "43038", intitule : "BOURNONCLE ST PIERRE", cp : "43360" },
{ code : "43039", intitule : "LE BRIGNON", cp : "43370" },
{ code : "43040", intitule : "BRIOUDE", cp : "43100" },
{ code : "43041", intitule : "BRIVES CHARENSAC", cp : "43700" },
{ code : "43042", intitule : "CAYRES", cp : "43510" },
{ code : "43043", intitule : "CEAUX D ALLEGRE", cp : "43270" },
{ code : "43044", intitule : "CERZAT", cp : "43380" },
{ code : "43045", intitule : "CEYSSAC", cp : "43000" },
{ code : "43046", intitule : "CHADRAC", cp : "43770" },
{ code : "43047", intitule : "CHADRON", cp : "43150" },
{ code : "43048", intitule : "LA CHAISE DIEU", cp : "43160" },
{ code : "43049", intitule : "CHAMALIERES SUR LOIRE", cp : "43800" },
{ code : "43050", intitule : "CHAMBEZON", cp : "43410" },
{ code : "43051", intitule : "LE CHAMBON SUR LIGNON", cp : "43400" },
{ code : "43052", intitule : "CHAMPAGNAC LE VIEUX", cp : "43440" },
{ code : "43053", intitule : "CHAMPCLAUSE", cp : "43260" },
{ code : "43053", intitule : "CHAMPCLAUSE", cp : "43430" },
{ code : "43054", intitule : "CHANALEILLES", cp : "43170" },
{ code : "43055", intitule : "CHANIAT", cp : "43100" },
{ code : "43056", intitule : "CHANTEUGES", cp : "43300" },
{ code : "43057", intitule : "LA CHAPELLE BERTIN", cp : "43270" },
{ code : "43058", intitule : "LA CHAPELLE D AUREC", cp : "43120" },
{ code : "43059", intitule : "LA CHAPELLE GENESTE", cp : "43160" },
{ code : "43060", intitule : "CHARRAIX", cp : "43300" },
{ code : "43061", intitule : "CHASPINHAC", cp : "43700" },
{ code : "43062", intitule : "CHASPUZAC", cp : "43320" },
{ code : "43063", intitule : "CHASSAGNES", cp : "43230" },
{ code : "43064", intitule : "CHASSIGNOLLES", cp : "43440" },
{ code : "43065", intitule : "CHASTEL", cp : "43300" },
{ code : "43066", intitule : "CHAUDEYROLLES", cp : "43430" },
{ code : "43067", intitule : "CHAVANIAC LAFAYETTE", cp : "43230" },
{ code : "43068", intitule : "CHAZELLES", cp : "43300" },
{ code : "43069", intitule : "CHENEREILLES", cp : "43190" },
{ code : "43070", intitule : "CHILHAC", cp : "43380" },
{ code : "43071", intitule : "CHOMELIX", cp : "43500" },
{ code : "43072", intitule : "LA CHOMETTE", cp : "43230" },
{ code : "43073", intitule : "CISTRIERES", cp : "43160" },
{ code : "43074", intitule : "COHADE", cp : "43100" },
{ code : "43075", intitule : "COLLAT", cp : "43230" },
{ code : "43076", intitule : "CONNANGLES", cp : "43160" },
{ code : "43077", intitule : "COSTAROS", cp : "43490" },
{ code : "43078", intitule : "COUBON", cp : "43700" },
{ code : "43079", intitule : "COUTEUGES", cp : "43230" },
{ code : "43080", intitule : "CRAPONNE SUR ARZON", cp : "43500" },
{ code : "43082", intitule : "CRONCE", cp : "43300" },
{ code : "43083", intitule : "CUBELLES", cp : "43170" },
{ code : "43084", intitule : "CUSSAC SUR LOIRE", cp : "43370" },
{ code : "43085", intitule : "DESGES", cp : "43300" },
{ code : "43086", intitule : "DOMEYRAT", cp : "43230" },
{ code : "43087", intitule : "DUNIERES", cp : "43220" },
{ code : "43088", intitule : "ESPALEM", cp : "43450" },
{ code : "43089", intitule : "ESPALY ST MARCEL", cp : "43000" },
{ code : "43090", intitule : "ESPLANTAS VAZEILLES", cp : "43580" },
{ code : "43090", intitule : "ESPLANTAS VAZEILLES", cp : "43170" },
{ code : "43091", intitule : "LES ESTABLES", cp : "43150" },
{ code : "43092", intitule : "FAY SUR LIGNON", cp : "43430" },
{ code : "43093", intitule : "FELINES", cp : "43160" },
{ code : "43094", intitule : "FERRUSSAC", cp : "43300" },
{ code : "43095", intitule : "FIX ST GENEYS", cp : "43320" },
{ code : "43096", intitule : "FONTANNES", cp : "43100" },
{ code : "43097", intitule : "FREYCENET LA CUCHE", cp : "43150" },
{ code : "43098", intitule : "FREYCENET LA TOUR", cp : "43150" },
{ code : "43099", intitule : "FRUGERES LES MINES", cp : "43250" },
{ code : "43100", intitule : "FRUGIERES LE PIN", cp : "43230" },
{ code : "43101", intitule : "GOUDET", cp : "43150" },
{ code : "43102", intitule : "GRAZAC", cp : "43200" },
{ code : "43103", intitule : "GRENIER MONTGON", cp : "43450" },
{ code : "43104", intitule : "GREZES", cp : "43170" },
{ code : "43105", intitule : "JAVAUGUES", cp : "43100" },
{ code : "43106", intitule : "JAX", cp : "43230" },
{ code : "43107", intitule : "JOSAT", cp : "43230" },
{ code : "43108", intitule : "JULLIANGES", cp : "43500" },
{ code : "43109", intitule : "LAFARRE", cp : "43490" },
{ code : "43110", intitule : "LAMOTHE", cp : "43100" },
{ code : "43111", intitule : "LANDOS", cp : "43340" },
{ code : "43111", intitule : "LANDOS", cp : "43340" },
{ code : "43112", intitule : "LANGEAC", cp : "43300" },
{ code : "43113", intitule : "LANTRIAC", cp : "43260" },
{ code : "43114", intitule : "LAPTE", cp : "43200" },
{ code : "43115", intitule : "LAUSSONNE", cp : "43150" },
{ code : "43116", intitule : "LAVAL SUR DOULON", cp : "43440" },
{ code : "43117", intitule : "LAVAUDIEU", cp : "43100" },
{ code : "43118", intitule : "LAVOUTE CHILHAC", cp : "43380" },
{ code : "43119", intitule : "LAVOUTE SUR LOIRE", cp : "43800" },
{ code : "43120", intitule : "LEMPDES SUR ALLAGNON", cp : "43410" },
{ code : "43121", intitule : "LEOTOING", cp : "43410" },
{ code : "43122", intitule : "LISSAC", cp : "43350" },
{ code : "43123", intitule : "LORLANGES", cp : "43360" },
{ code : "43124", intitule : "LOUDES", cp : "43320" },
{ code : "43125", intitule : "LUBILHAC", cp : "43100" },
{ code : "43126", intitule : "MALREVERS", cp : "43800" },
{ code : "43127", intitule : "MALVALETTE", cp : "43210" },
{ code : "43128", intitule : "MALVIERES", cp : "43160" },
{ code : "43129", intitule : "LE MAS DE TENCE", cp : "43190" },
{ code : "43130", intitule : "MAZET ST VOY", cp : "43520" },
{ code : "43131", intitule : "MAZERAT AUROUZE", cp : "43230" },
{ code : "43132", intitule : "MAZEYRAT D ALLIER", cp : "43300" },
{ code : "43132", intitule : "MAZEYRAT D ALLIER", cp : "43300" },
{ code : "43132", intitule : "MAZEYRAT D ALLIER", cp : "43300" },
{ code : "43133", intitule : "MERCOEUR", cp : "43100" },
{ code : "43134", intitule : "MEZERES", cp : "43800" },
{ code : "43135", intitule : "LE MONASTIER SUR GAZEILLE", cp : "43150" },
{ code : "43136", intitule : "MONISTROL D ALLIER", cp : "43580" },
{ code : "43137", intitule : "MONISTROL SUR LOIRE", cp : "43120" },
{ code : "43138", intitule : "MONLET", cp : "43270" },
{ code : "43139", intitule : "MONTCLARD", cp : "43230" },
{ code : "43140", intitule : "LE MONTEIL", cp : "43700" },
{ code : "43141", intitule : "MONTFAUCON EN VELAY", cp : "43290" },
{ code : "43142", intitule : "MONTREGARD", cp : "43290" },
{ code : "43143", intitule : "MONTUSCLAT", cp : "43260" },
{ code : "43144", intitule : "MOUDEYRES", cp : "43150" },
{ code : "43145", intitule : "OUIDES", cp : "43510" },
{ code : "43147", intitule : "PAULHAC", cp : "43100" },
{ code : "43148", intitule : "PAULHAGUET", cp : "43230" },
{ code : "43149", intitule : "PEBRAC", cp : "43300" },
{ code : "43150", intitule : "LE PERTUIS", cp : "43200" },
{ code : "43151", intitule : "PINOLS", cp : "43300" },
{ code : "43152", intitule : "POLIGNAC", cp : "43000" },
{ code : "43153", intitule : "PONT SALOMON", cp : "43330" },
{ code : "43154", intitule : "PRADELLES", cp : "43420" },
{ code : "43155", intitule : "PRADES", cp : "43300" },
{ code : "43156", intitule : "PRESAILLES", cp : "43150" },
{ code : "43157", intitule : "LE PUY EN VELAY", cp : "43000" },
{ code : "43158", intitule : "QUEYRIERES", cp : "43260" },
{ code : "43159", intitule : "RAUCOULES", cp : "43290" },
{ code : "43160", intitule : "RAURET", cp : "43340" },
{ code : "43162", intitule : "RETOURNAC", cp : "43130" },
{ code : "43163", intitule : "RIOTORD", cp : "43220" },
{ code : "43164", intitule : "ROCHE EN REGNIER", cp : "43810" },
{ code : "43165", intitule : "ROSIERES", cp : "43800" },
{ code : "43166", intitule : "ST ANDRE DE CHALENCON", cp : "43130" },
{ code : "43167", intitule : "ST ARCONS D ALLIER", cp : "43300" },
{ code : "43168", intitule : "ST ARCONS DE BARGES", cp : "43420" },
{ code : "43169", intitule : "ST AUSTREMOINE", cp : "43380" },
{ code : "43170", intitule : "ST BEAUZIRE", cp : "43100" },
{ code : "43171", intitule : "ST BERAIN", cp : "43300" },
{ code : "43172", intitule : "ST BONNET LE FROID", cp : "43290" },
{ code : "43173", intitule : "ST CHRISTOPHE D ALLIER", cp : "43340" },
{ code : "43174", intitule : "ST CHRISTOPHE SUR DOLAISON", cp : "43370" },
{ code : "43175", intitule : "ST CIRGUES", cp : "43380" },
{ code : "43176", intitule : "ST DIDIER D ALLIER", cp : "43580" },
{ code : "43177", intitule : "ST DIDIER EN VELAY", cp : "43140" },
{ code : "43178", intitule : "ST DIDIER SUR DOULON", cp : "43440" },
{ code : "43180", intitule : "ST ETIENNE DU VIGAN", cp : "43420" },
{ code : "43181", intitule : "ST ETIENNE LARDEYROL", cp : "43260" },
{ code : "43182", intitule : "ST ETIENNE SUR BLESLE", cp : "43450" },
{ code : "43183", intitule : "STE EUGENIE DE VILLENEUVE", cp : "43230" },
{ code : "43184", intitule : "ST FERREOL D AUROURE", cp : "43330" },
{ code : "43185", intitule : "STE FLORINE", cp : "43250" },
{ code : "43186", intitule : "ST FRONT", cp : "43550" },
{ code : "43187", intitule : "ST GENEYS PRES ST PAULIEN", cp : "43350" },
{ code : "43188", intitule : "ST GEORGES D AURAC", cp : "43230" },
{ code : "43189", intitule : "ST GEORGES LAGRICOL", cp : "43500" },
{ code : "43190", intitule : "ST GERMAIN LAPRADE", cp : "43700" },
{ code : "43191", intitule : "ST GERON", cp : "43360" },
{ code : "43192", intitule : "ST HAON", cp : "43340" },
{ code : "43193", intitule : "ST HILAIRE", cp : "43390" },
{ code : "43194", intitule : "ST HOSTIEN", cp : "43260" },
{ code : "43195", intitule : "ST ILPIZE", cp : "43380" },
{ code : "43196", intitule : "ST JEAN D AUBRIGOUX", cp : "43500" },
{ code : "43197", intitule : "ST JEAN DE NAY", cp : "43320" },
{ code : "43198", intitule : "ST JEAN LACHALM", cp : "43510" },
{ code : "43199", intitule : "ST JEURES", cp : "43200" },
{ code : "43200", intitule : "ST JULIEN CHAPTEUIL", cp : "43260" },
{ code : "43201", intitule : "ST JULIEN D ANCE", cp : "43500" },
{ code : "43202", intitule : "ST JULIEN DES CHAZES", cp : "43300" },
{ code : "43203", intitule : "ST JULIEN DU PINET", cp : "43200" },
{ code : "43204", intitule : "ST JULIEN MOLHESABATE", cp : "43220" },
{ code : "43205", intitule : "ST JUST MALMONT", cp : "43240" },
{ code : "43206", intitule : "ST JUST PRES BRIOUDE", cp : "43100" },
{ code : "43207", intitule : "ST LAURENT CHABREUGES", cp : "43100" },
{ code : "43208", intitule : "STE MARGUERITE", cp : "43230" },
{ code : "43210", intitule : "ST MARTIN DE FUGERES", cp : "43150" },
{ code : "43211", intitule : "ST MAURICE DE LIGNON", cp : "43200" },
{ code : "43212", intitule : "ST PAL DE CHALENCON", cp : "43500" },
{ code : "43213", intitule : "ST PAL DE MONS", cp : "43620" },
{ code : "43214", intitule : "ST PAL DE SENOUIRE", cp : "43160" },
{ code : "43215", intitule : "ST PAUL DE TARTAS", cp : "43420" },
{ code : "43216", intitule : "ST PAULIEN", cp : "43350" },
{ code : "43217", intitule : "ST PIERRE DU CHAMP", cp : "43810" },
{ code : "43218", intitule : "ST PIERRE EYNAC", cp : "43260" },
{ code : "43219", intitule : "ST PREJET ARMANDON", cp : "43230" },
{ code : "43220", intitule : "ST PREJET D ALLIER", cp : "43580" },
{ code : "43221", intitule : "ST PRIVAT D ALLIER", cp : "43580" },
{ code : "43222", intitule : "ST PRIVAT DU DRAGON", cp : "43380" },
{ code : "43223", intitule : "ST ROMAIN LACHALM", cp : "43620" },
{ code : "43224", intitule : "STE SIGOLENE", cp : "43600" },
{ code : "43225", intitule : "ST VENERAND", cp : "43580" },
{ code : "43226", intitule : "ST VERT", cp : "43440" },
{ code : "43227", intitule : "ST VICTOR MALESCOURS", cp : "43140" },
{ code : "43228", intitule : "ST VICTOR SUR ARLANC", cp : "43500" },
{ code : "43229", intitule : "ST VIDAL", cp : "43320" },
{ code : "43230", intitule : "ST VINCENT", cp : "43800" },
{ code : "43231", intitule : "SALETTES", cp : "43150" },
{ code : "43232", intitule : "SALZUIT", cp : "43230" },
{ code : "43233", intitule : "SANSSAC L EGLISE", cp : "43320" },
{ code : "43234", intitule : "SAUGUES", cp : "43170" },
{ code : "43236", intitule : "LA SEAUVE SUR SEMENE", cp : "43140" },
{ code : "43237", intitule : "SEMBADEL", cp : "43160" },
{ code : "43238", intitule : "SENEUJOLS", cp : "43510" },
{ code : "43239", intitule : "SIAUGUES STE MARIE", cp : "43300" },
{ code : "43239", intitule : "SIAUGUES STE MARIE", cp : "43300" },
{ code : "43240", intitule : "SOLIGNAC SOUS ROCHE", cp : "43130" },
{ code : "43241", intitule : "SOLIGNAC SUR LOIRE", cp : "43370" },
{ code : "43242", intitule : "TAILHAC", cp : "43300" },
{ code : "43244", intitule : "TENCE", cp : "43190" },
{ code : "43245", intitule : "THORAS", cp : "43170" },
{ code : "43245", intitule : "THORAS", cp : "43580" },
{ code : "43246", intitule : "TIRANGES", cp : "43530" },
{ code : "43247", intitule : "TORSIAC", cp : "43450" },
{ code : "43249", intitule : "VALPRIVAS", cp : "43210" },
{ code : "43250", intitule : "VALS LE CHASTEL", cp : "43230" },
{ code : "43251", intitule : "VALS PRES LE PUY", cp : "43750" },
{ code : "43252", intitule : "VARENNES ST HONORAT", cp : "43270" },
{ code : "43253", intitule : "LES VASTRES", cp : "43430" },
{ code : "43254", intitule : "VAZEILLES LIMANDRE", cp : "43320" },
{ code : "43256", intitule : "VENTEUGES", cp : "43170" },
{ code : "43257", intitule : "VERGEZAC", cp : "43320" },
{ code : "43258", intitule : "VERGONGHEON", cp : "43360" },
{ code : "43259", intitule : "VERNASSAL", cp : "43270" },
{ code : "43260", intitule : "LE VERNET", cp : "43320" },
{ code : "43261", intitule : "VEZEZOUX", cp : "43390" },
{ code : "43262", intitule : "VIEILLE BRIOUDE", cp : "43100" },
{ code : "43263", intitule : "VIELPRAT", cp : "43490" },
{ code : "43264", intitule : "VILLENEUVE D ALLIER", cp : "43380" },
{ code : "43265", intitule : "LES VILLETTES", cp : "43600" },
{ code : "43267", intitule : "VOREY", cp : "43800" },
{ code : "43268", intitule : "YSSINGEAUX", cp : "43200" },
{ code : "44001", intitule : "ABBARETZ", cp : "44170" },
{ code : "44002", intitule : "AIGREFEUILLE SUR MAINE", cp : "44140" },
{ code : "44003", intitule : "ANCENIS", cp : "44150" },
{ code : "44005", intitule : "CHAUMES EN RETZ", cp : "44320" },
{ code : "44005", intitule : "CHAUMES EN RETZ", cp : "44680" },
{ code : "44005", intitule : "CHAUMES EN RETZ", cp : "44320" },
{ code : "44006", intitule : "ASSERAC", cp : "44410" },
{ code : "44006", intitule : "ASSERAC", cp : "44410" },
{ code : "44007", intitule : "AVESSAC", cp : "44460" },
{ code : "44009", intitule : "BASSE GOULAINE", cp : "44115" },
{ code : "44010", intitule : "BATZ SUR MER", cp : "44740" },
{ code : "44012", intitule : "LA BERNERIE EN RETZ", cp : "44760" },
{ code : "44013", intitule : "BESNE", cp : "44160" },
{ code : "44014", intitule : "LE BIGNON", cp : "44140" },
{ code : "44015", intitule : "BLAIN", cp : "44130" },
{ code : "44015", intitule : "BLAIN", cp : "44130" },
{ code : "44015", intitule : "BLAIN", cp : "44130" },
{ code : "44016", intitule : "LA BOISSIERE DU DORE", cp : "44430" },
{ code : "44017", intitule : "BONNOEUVRE", cp : "44540" },
{ code : "44018", intitule : "BOUAYE", cp : "44830" },
{ code : "44019", intitule : "BOUEE", cp : "44260" },
{ code : "44020", intitule : "BOUGUENAIS", cp : "44340" },
{ code : "44020", intitule : "BOUGUENAIS", cp : "44340" },
{ code : "44021", intitule : "VILLENEUVE EN RETZ", cp : "44580" },
{ code : "44021", intitule : "VILLENEUVE EN RETZ", cp : "44580" },
{ code : "44021", intitule : "VILLENEUVE EN RETZ", cp : "44580" },
{ code : "44022", intitule : "BOUSSAY", cp : "44190" },
{ code : "44023", intitule : "BOUVRON", cp : "44130" },
{ code : "44024", intitule : "BRAINS", cp : "44830" },
{ code : "44025", intitule : "CAMPBON", cp : "44750" },
{ code : "44026", intitule : "CARQUEFOU", cp : "44470" },
{ code : "44027", intitule : "CASSON", cp : "44390" },
{ code : "44028", intitule : "LE CELLIER", cp : "44850" },
{ code : "44029", intitule : "DIVATTE SUR LOIRE", cp : "44450" },
{ code : "44029", intitule : "DIVATTE SUR LOIRE", cp : "44450" },
{ code : "44030", intitule : "LA CHAPELLE DES MARAIS", cp : "44410" },
{ code : "44031", intitule : "LA CHAPELLE GLAIN", cp : "44670" },
{ code : "44032", intitule : "LA CHAPELLE HEULIN", cp : "44330" },
{ code : "44033", intitule : "LA CHAPELLE LAUNAY", cp : "44260" },
{ code : "44035", intitule : "LA CHAPELLE SUR ERDRE", cp : "44240" },
{ code : "44036", intitule : "CHATEAUBRIANT", cp : "44110" },
{ code : "44037", intitule : "CHATEAU THEBAUD", cp : "44690" },
{ code : "44038", intitule : "CHAUVE", cp : "44320" },
{ code : "44039", intitule : "CHEIX EN RETZ", cp : "44640" },
{ code : "44041", intitule : "LA CHEVROLIERE", cp : "44118" },
{ code : "44041", intitule : "LA CHEVROLIERE", cp : "44118" },
{ code : "44043", intitule : "CLISSON", cp : "44190" },
{ code : "44044", intitule : "CONQUEREUIL", cp : "44290" },
{ code : "44045", intitule : "CORDEMAIS", cp : "44360" },
{ code : "44046", intitule : "CORSEPT", cp : "44560" },
{ code : "44047", intitule : "COUERON", cp : "44220" },
{ code : "44047", intitule : "COUERON", cp : "44220" },
{ code : "44048", intitule : "COUFFE", cp : "44521" },
{ code : "44049", intitule : "LE CROISIC", cp : "44490" },
{ code : "44050", intitule : "CROSSAC", cp : "44160" },
{ code : "44051", intitule : "DERVAL", cp : "44590" },
{ code : "44052", intitule : "DONGES", cp : "44480" },
{ code : "44053", intitule : "DREFFEAC", cp : "44530" },
{ code : "44054", intitule : "ERBRAY", cp : "44110" },
{ code : "44055", intitule : "LA BAULE ESCOUBLAC", cp : "44500" },
{ code : "44056", intitule : "FAY DE BRETAGNE", cp : "44130" },
{ code : "44057", intitule : "FEGREAC", cp : "44460" },
{ code : "44058", intitule : "FERCE", cp : "44660" },
{ code : "44061", intitule : "FROSSAY", cp : "44320" },
{ code : "44062", intitule : "LE GAVRE", cp : "44130" },
{ code : "44063", intitule : "GETIGNE", cp : "44190" },
{ code : "44064", intitule : "GORGES", cp : "44190" },
{ code : "44065", intitule : "GRAND AUVERNE", cp : "44520" },
{ code : "44066", intitule : "GRANDCHAMPS DES FONTAINES", cp : "44119" },
{ code : "44067", intitule : "GUEMENE PENFAO", cp : "44290" },
{ code : "44067", intitule : "GUEMENE PENFAO", cp : "44290" },
{ code : "44067", intitule : "GUEMENE PENFAO", cp : "44290" },
{ code : "44068", intitule : "GUENROUET", cp : "44530" },
{ code : "44068", intitule : "GUENROUET", cp : "44530" },
{ code : "44069", intitule : "GUERANDE", cp : "44350" },
{ code : "44069", intitule : "GUERANDE", cp : "44350" },
{ code : "44069", intitule : "GUERANDE", cp : "44350" },
{ code : "44069", intitule : "GUERANDE", cp : "44350" },
{ code : "44070", intitule : "LA HAIE FOUASSIERE", cp : "44690" },
{ code : "44071", intitule : "HAUTE GOULAINE", cp : "44115" },
{ code : "44072", intitule : "HERBIGNAC", cp : "44410" },
{ code : "44072", intitule : "HERBIGNAC", cp : "44410" },
{ code : "44073", intitule : "HERIC", cp : "44810" },
{ code : "44074", intitule : "INDRE", cp : "44610" },
{ code : "44074", intitule : "INDRE", cp : "44610" },
{ code : "44074", intitule : "INDRE", cp : "44610" },
{ code : "44075", intitule : "ISSE", cp : "44520" },
{ code : "44076", intitule : "JANS", cp : "44170" },
{ code : "44077", intitule : "JOUE SUR ERDRE", cp : "44440" },
{ code : "44077", intitule : "JOUE SUR ERDRE", cp : "44440" },
{ code : "44078", intitule : "JUIGNE DES MOUTIERS", cp : "44670" },
{ code : "44079", intitule : "LE LANDREAU", cp : "44430" },
{ code : "44080", intitule : "LAVAU SUR LOIRE", cp : "44260" },
{ code : "44081", intitule : "LEGE", cp : "44650" },
{ code : "44082", intitule : "LIGNE", cp : "44850" },
{ code : "44083", intitule : "LA LIMOUZINIERE", cp : "44310" },
{ code : "44084", intitule : "LE LOROUX BOTTEREAU", cp : "44430" },
{ code : "44085", intitule : "LOUISFERT", cp : "44110" },
{ code : "44086", intitule : "LUSANGER", cp : "44590" },
{ code : "44087", intitule : "MACHECOUL ST MEME", cp : "44270" },
{ code : "44087", intitule : "MACHECOUL ST MEME", cp : "44270" },
{ code : "44088", intitule : "MAISDON SUR SEVRE", cp : "44690" },
{ code : "44089", intitule : "MALVILLE", cp : "44260" },
{ code : "44090", intitule : "LA MARNE", cp : "44270" },
{ code : "44091", intitule : "MARSAC SUR DON", cp : "44170" },
{ code : "44092", intitule : "MASSERAC", cp : "44290" },
{ code : "44093", intitule : "MAUMUSSON", cp : "44540" },
{ code : "44094", intitule : "MAUVES SUR LOIRE", cp : "44470" },
{ code : "44095", intitule : "LA MEILLERAYE DE BRETAGNE", cp : "44520" },
{ code : "44096", intitule : "MESANGER", cp : "44522" },
{ code : "44097", intitule : "MESQUER", cp : "44420" },
{ code : "44097", intitule : "MESQUER", cp : "44420" },
{ code : "44098", intitule : "MISSILLAC", cp : "44780" },
{ code : "44099", intitule : "MOISDON LA RIVIERE", cp : "44520" },
{ code : "44100", intitule : "MONNIERES", cp : "44690" },
{ code : "44101", intitule : "LA MONTAGNE", cp : "44620" },
{ code : "44102", intitule : "MONTBERT", cp : "44140" },
{ code : "44103", intitule : "MONTOIR DE BRETAGNE", cp : "44550" },
{ code : "44104", intitule : "MONTRELAIS", cp : "44370" },
{ code : "44105", intitule : "MOUAIS", cp : "44590" },
{ code : "44106", intitule : "LES MOUTIERS EN RETZ", cp : "44760" },
{ code : "44107", intitule : "MOUZEIL", cp : "44850" },
{ code : "44108", intitule : "MOUZILLON", cp : "44330" },
{ code : "44109", intitule : "NANTES", cp : "44000" },
{ code : "44109", intitule : "NANTES", cp : "44200" },
{ code : "44109", intitule : "NANTES", cp : "44100" },
{ code : "44109", intitule : "NANTES", cp : "44300" },
{ code : "44110", intitule : "NORT SUR ERDRE", cp : "44390" },
{ code : "44111", intitule : "NOTRE DAME DES LANDES", cp : "44130" },
{ code : "44112", intitule : "NOYAL SUR BRUTZ", cp : "44110" },
{ code : "44113", intitule : "NOZAY", cp : "44170" },
{ code : "44114", intitule : "ORVAULT", cp : "44700" },
{ code : "44115", intitule : "OUDON", cp : "44521" },
{ code : "44116", intitule : "PAIMBOEUF", cp : "44560" },
{ code : "44117", intitule : "LE PALLET", cp : "44330" },
{ code : "44118", intitule : "PANNECE", cp : "44440" },
{ code : "44119", intitule : "PAULX", cp : "44270" },
{ code : "44120", intitule : "LE PELLERIN", cp : "44640" },
{ code : "44121", intitule : "PETIT AUVERNE", cp : "44670" },
{ code : "44122", intitule : "PETIT MARS", cp : "44390" },
{ code : "44123", intitule : "PIERRIC", cp : "44290" },
{ code : "44124", intitule : "LE PIN", cp : "44540" },
{ code : "44125", intitule : "PIRIAC SUR MER", cp : "44420" },
{ code : "44126", intitule : "LA PLAINE SUR MER", cp : "44770" },
{ code : "44127", intitule : "LA PLANCHE", cp : "44140" },
{ code : "44128", intitule : "PLESSE", cp : "44630" },
{ code : "44128", intitule : "PLESSE", cp : "44630" },
{ code : "44128", intitule : "PLESSE", cp : "44630" },
{ code : "44129", intitule : "PONTCHATEAU", cp : "44160" },
{ code : "44129", intitule : "PONTCHATEAU", cp : "44160" },
{ code : "44129", intitule : "PONTCHATEAU", cp : "44160" },
{ code : "44130", intitule : "PONT ST MARTIN", cp : "44860" },
{ code : "44130", intitule : "PONT ST MARTIN", cp : "44860" },
{ code : "44131", intitule : "PORNIC", cp : "44210" },
{ code : "44131", intitule : "PORNIC", cp : "44210" },
{ code : "44131", intitule : "PORNIC", cp : "44210" },
{ code : "44132", intitule : "PORNICHET", cp : "44380" },
{ code : "44133", intitule : "PORT ST PERE", cp : "44710" },
{ code : "44134", intitule : "POUILLE LES COTEAUX", cp : "44522" },
{ code : "44135", intitule : "LE POULIGUEN", cp : "44510" },
{ code : "44136", intitule : "PREFAILLES", cp : "44770" },
{ code : "44137", intitule : "PRINQUIAU", cp : "44260" },
{ code : "44138", intitule : "PUCEUL", cp : "44390" },
{ code : "44139", intitule : "QUILLY", cp : "44750" },
{ code : "44140", intitule : "LA REGRIPPIERE", cp : "44330" },
{ code : "44141", intitule : "LA REMAUDIERE", cp : "44430" },
{ code : "44142", intitule : "REMOUILLE", cp : "44140" },
{ code : "44143", intitule : "REZE", cp : "44400" },
{ code : "44143", intitule : "REZE", cp : "44400" },
{ code : "44144", intitule : "RIAILLE", cp : "44440" },
{ code : "44145", intitule : "ROUANS", cp : "44640" },
{ code : "44146", intitule : "ROUGE", cp : "44660" },
{ code : "44148", intitule : "RUFFIGNE", cp : "44660" },
{ code : "44149", intitule : "SAFFRE", cp : "44390" },
{ code : "44150", intitule : "ST AIGNAN GRANDLIEU", cp : "44860" },
{ code : "44151", intitule : "ST ANDRE DES EAUX", cp : "44117" },
{ code : "44152", intitule : "STE ANNE SUR BRIVET", cp : "44160" },
{ code : "44153", intitule : "ST AUBIN DES CHATEAUX", cp : "44110" },
{ code : "44154", intitule : "ST BREVIN LES PINS", cp : "44250" },
{ code : "44154", intitule : "ST BREVIN LES PINS", cp : "44250" },
{ code : "44154", intitule : "ST BREVIN LES PINS", cp : "44250" },
{ code : "44155", intitule : "ST COLOMBAN", cp : "44310" },
{ code : "44155", intitule : "ST COLOMBAN", cp : "44310" },
{ code : "44156", intitule : "CORCOUE SUR LOGNE", cp : "44650" },
{ code : "44156", intitule : "CORCOUE SUR LOGNE", cp : "44650" },
{ code : "44156", intitule : "CORCOUE SUR LOGNE", cp : "44650" },
{ code : "44157", intitule : "ST ETIENNE DE MER MORTE", cp : "44270" },
{ code : "44158", intitule : "ST ETIENNE DE MONTLUC", cp : "44360" },
{ code : "44159", intitule : "ST FIACRE SUR MAINE", cp : "44690" },
{ code : "44160", intitule : "ST GEREON", cp : "44150" },
{ code : "44161", intitule : "ST GILDAS DES BOIS", cp : "44530" },
{ code : "44162", intitule : "ST HERBLAIN", cp : "44800" },
{ code : "44163", intitule : "VAIR SUR LOIRE", cp : "44150" },
{ code : "44163", intitule : "VAIR SUR LOIRE", cp : "44150" },
{ code : "44164", intitule : "ST HILAIRE DE CHALEONS", cp : "44680" },
{ code : "44165", intitule : "ST HILAIRE DE CLISSON", cp : "44190" },
{ code : "44166", intitule : "ST JEAN DE BOISEAU", cp : "44640" },
{ code : "44168", intitule : "ST JOACHIM", cp : "44720" },
{ code : "44169", intitule : "ST JULIEN DE CONCELLES", cp : "44450" },
{ code : "44170", intitule : "ST JULIEN DE VOUVANTES", cp : "44670" },
{ code : "44171", intitule : "ST LEGER LES VIGNES", cp : "44710" },
{ code : "44172", intitule : "STE LUCE SUR LOIRE", cp : "44980" },
{ code : "44173", intitule : "ST LUMINE DE CLISSON", cp : "44190" },
{ code : "44174", intitule : "ST LUMINE DE COUTAIS", cp : "44310" },
{ code : "44175", intitule : "ST LYPHARD", cp : "44410" },
{ code : "44176", intitule : "ST MALO DE GUERSAC", cp : "44550" },
{ code : "44178", intitule : "ST MARS DE COUTAIS", cp : "44680" },
{ code : "44179", intitule : "ST MARS DU DESERT", cp : "44850" },
{ code : "44180", intitule : "ST MARS LA JAILLE", cp : "44540" },
{ code : "44182", intitule : "ST MICHEL CHEF CHEF", cp : "44730" },
{ code : "44182", intitule : "ST MICHEL CHEF CHEF", cp : "44730" },
{ code : "44183", intitule : "ST MOLF", cp : "44350" },
{ code : "44184", intitule : "ST NAZAIRE", cp : "44600" },
{ code : "44184", intitule : "ST NAZAIRE", cp : "44600" },
{ code : "44185", intitule : "ST NICOLAS DE REDON", cp : "44460" },
{ code : "44186", intitule : "STE PAZANNE", cp : "44680" },
{ code : "44187", intitule : "ST PERE EN RETZ", cp : "44320" },
{ code : "44188", intitule : "ST PHILBERT DE GRAND LIEU", cp : "44310" },
{ code : "44189", intitule : "STE REINE DE BRETAGNE", cp : "44160" },
{ code : "44190", intitule : "ST SEBASTIEN SUR LOIRE", cp : "44230" },
{ code : "44191", intitule : "ST SULPICE DES LANDES", cp : "44540" },
{ code : "44192", intitule : "ST VIAUD", cp : "44320" },
{ code : "44193", intitule : "ST VINCENT DES LANDES", cp : "44590" },
{ code : "44194", intitule : "SAUTRON", cp : "44880" },
{ code : "44195", intitule : "SAVENAY", cp : "44260" },
{ code : "44196", intitule : "SEVERAC", cp : "44530" },
{ code : "44197", intitule : "SION LES MINES", cp : "44590" },
{ code : "44198", intitule : "LES SORINIERES", cp : "44840" },
{ code : "44199", intitule : "SOUDAN", cp : "44110" },
{ code : "44200", intitule : "SOULVACHE", cp : "44660" },
{ code : "44201", intitule : "SUCE SUR ERDRE", cp : "44240" },
{ code : "44202", intitule : "TEILLE", cp : "44440" },
{ code : "44203", intitule : "LE TEMPLE DE BRETAGNE", cp : "44360" },
{ code : "44204", intitule : "THOUARE SUR LOIRE", cp : "44470" },
{ code : "44205", intitule : "LES TOUCHES", cp : "44390" },
{ code : "44206", intitule : "TOUVOIS", cp : "44650" },
{ code : "44207", intitule : "TRANS SUR ERDRE", cp : "44440" },
{ code : "44208", intitule : "TREFFIEUX", cp : "44170" },
{ code : "44209", intitule : "TREILLIERES", cp : "44119" },
{ code : "44210", intitule : "TRIGNAC", cp : "44570" },
{ code : "44211", intitule : "LA TURBALLE", cp : "44420" },
{ code : "44211", intitule : "LA TURBALLE", cp : "44420" },
{ code : "44212", intitule : "VALLET", cp : "44330" },
{ code : "44213", intitule : "LOIREAUXENCE", cp : "44370" },
{ code : "44213", intitule : "LOIREAUXENCE", cp : "44370" },
{ code : "44213", intitule : "LOIREAUXENCE", cp : "44370" },
{ code : "44213", intitule : "LOIREAUXENCE", cp : "44370" },
{ code : "44214", intitule : "VAY", cp : "44170" },
{ code : "44215", intitule : "VERTOU", cp : "44120" },
{ code : "44215", intitule : "VERTOU", cp : "44120" },
{ code : "44216", intitule : "VIEILLEVIGNE", cp : "44116" },
{ code : "44217", intitule : "VIGNEUX DE BRETAGNE", cp : "44360" },
{ code : "44217", intitule : "VIGNEUX DE BRETAGNE", cp : "44360" },
{ code : "44218", intitule : "VILLEPOT", cp : "44110" },
{ code : "44219", intitule : "VRITZ", cp : "44540" },
{ code : "44220", intitule : "VUE", cp : "44640" },
{ code : "44221", intitule : "LA CHEVALLERAIS", cp : "44810" },
{ code : "44222", intitule : "LA ROCHE BLANCHE", cp : "44522" },
{ code : "44223", intitule : "GENESTON", cp : "44140" },
{ code : "44224", intitule : "LA GRIGONNAIS", cp : "44170" },
{ code : "45001", intitule : "ADON", cp : "45230" },
{ code : "45002", intitule : "AILLANT SUR MILLERON", cp : "45230" },
{ code : "45004", intitule : "AMILLY", cp : "45200" },
{ code : "45005", intitule : "ANDONVILLE", cp : "45480" },
{ code : "45006", intitule : "ARDON", cp : "45160" },
{ code : "45008", intitule : "ARTENAY", cp : "45410" },
{ code : "45009", intitule : "ASCHERES LE MARCHE", cp : "45170" },
{ code : "45010", intitule : "ASCOUX", cp : "45300" },
{ code : "45011", intitule : "ATTRAY", cp : "45170" },
{ code : "45012", intitule : "AUDEVILLE", cp : "45300" },
{ code : "45013", intitule : "AUGERVILLE LA RIVIERE", cp : "45330" },
{ code : "45014", intitule : "AULNAY LA RIVIERE", cp : "45390" },
{ code : "45015", intitule : "AUTRUY SUR JUINE", cp : "45480" },
{ code : "45016", intitule : "AUTRY LE CHATEL", cp : "45500" },
{ code : "45017", intitule : "AUVILLIERS EN GATINAIS", cp : "45270" },
{ code : "45018", intitule : "AUXY", cp : "45340" },
{ code : "45019", intitule : "BACCON", cp : "45130" },
{ code : "45020", intitule : "LE BARDON", cp : "45130" },
{ code : "45021", intitule : "BARVILLE EN GATINAIS", cp : "45340" },
{ code : "45022", intitule : "BATILLY EN GATINAIS", cp : "45340" },
{ code : "45023", intitule : "BATILLY EN PUISAYE", cp : "45420" },
{ code : "45024", intitule : "BAULE", cp : "45130" },
{ code : "45025", intitule : "BAZOCHES LES GALLERANDES", cp : "45480" },
{ code : "45025", intitule : "BAZOCHES LES GALLERANDES", cp : "45480" },
{ code : "45026", intitule : "BAZOCHES SUR LE BETZ", cp : "45210" },
{ code : "45027", intitule : "BEAUCHAMPS SUR HUILLARD", cp : "45270" },
{ code : "45028", intitule : "BEAUGENCY", cp : "45190" },
{ code : "45029", intitule : "BEAULIEU SUR LOIRE", cp : "45630" },
{ code : "45030", intitule : "BEAUNE LA ROLANDE", cp : "45340" },
{ code : "45031", intitule : "BELLEGARDE", cp : "45270" },
{ code : "45032", intitule : "LE BIGNON MIRABEAU", cp : "45210" },
{ code : "45033", intitule : "BOESSES", cp : "45390" },
{ code : "45034", intitule : "BOIGNY SUR BIONNE", cp : "45760" },
{ code : "45035", intitule : "BOISCOMMUN", cp : "45340" },
{ code : "45035", intitule : "BOISCOMMUN", cp : "45340" },
{ code : "45036", intitule : "BOISMORAND", cp : "45290" },
{ code : "45036", intitule : "BOISMORAND", cp : "45290" },
{ code : "45037", intitule : "BOISSEAUX", cp : "45480" },
{ code : "45038", intitule : "BONDAROY", cp : "45300" },
{ code : "45039", intitule : "BONNEE", cp : "45460" },
{ code : "45040", intitule : "BONNY SUR LOIRE", cp : "45420" },
{ code : "45041", intitule : "BORDEAUX EN GATINAIS", cp : "45340" },
{ code : "45042", intitule : "LES BORDES", cp : "45460" },
{ code : "45043", intitule : "BOU", cp : "45430" },
{ code : "45044", intitule : "BOUGY LEZ NEUVILLE", cp : "45170" },
{ code : "45045", intitule : "BOUILLY EN GATINAIS", cp : "45300" },
{ code : "45046", intitule : "BOULAY LES BARRES", cp : "45140" },
{ code : "45047", intitule : "BOUZONVILLE AUX BOIS", cp : "45300" },
{ code : "45049", intitule : "BOUZY LA FORET", cp : "45460" },
{ code : "45050", intitule : "BOYNES", cp : "45300" },
{ code : "45051", intitule : "BRAY EN VAL", cp : "45460" },
{ code : "45052", intitule : "BRETEAU", cp : "45250" },
{ code : "45053", intitule : "BRIARE", cp : "45250" },
{ code : "45054", intitule : "BRIARRES SUR ESSONNE", cp : "45390" },
{ code : "45055", intitule : "BRICY", cp : "45310" },
{ code : "45056", intitule : "BROMEILLES", cp : "45390" },
{ code : "45058", intitule : "BUCY LE ROI", cp : "45410" },
{ code : "45059", intitule : "BUCY ST LIPHARD", cp : "45140" },
{ code : "45060", intitule : "LA BUSSIERE", cp : "45230" },
{ code : "45061", intitule : "CEPOY", cp : "45120" },
{ code : "45062", intitule : "CERCOTTES", cp : "45520" },
{ code : "45063", intitule : "CERDON", cp : "45620" },
{ code : "45064", intitule : "CERNOY EN BERRY", cp : "45360" },
{ code : "45065", intitule : "CESARVILLE DOSSAINVILLE", cp : "45300" },
{ code : "45065", intitule : "CESARVILLE DOSSAINVILLE", cp : "45300" },
{ code : "45066", intitule : "CHAILLY EN GATINAIS", cp : "45260" },
{ code : "45067", intitule : "CHAINGY", cp : "45380" },
{ code : "45068", intitule : "CHALETTE SUR LOING", cp : "45120" },
{ code : "45069", intitule : "CHAMBON LA FORET", cp : "45340" },
{ code : "45070", intitule : "CHAMPOULET", cp : "45420" },
{ code : "45072", intitule : "CHANTEAU", cp : "45400" },
{ code : "45073", intitule : "CHANTECOQ", cp : "45320" },
{ code : "45074", intitule : "LA CHAPELLE ONZERAIN", cp : "45310" },
{ code : "45075", intitule : "LA CHAPELLE ST MESMIN", cp : "45380" },
{ code : "45076", intitule : "LA CHAPELLE ST SEPULCRE", cp : "45210" },
{ code : "45077", intitule : "LA CHAPELLE SUR AVEYRON", cp : "45230" },
{ code : "45078", intitule : "CHAPELON", cp : "45270" },
{ code : "45079", intitule : "LE CHARME", cp : "45230" },
{ code : "45080", intitule : "CHARMONT EN BEAUCE", cp : "45480" },
{ code : "45081", intitule : "CHARSONVILLE", cp : "45130" },
{ code : "45082", intitule : "CHATEAUNEUF SUR LOIRE", cp : "45110" },
{ code : "45083", intitule : "CHATEAU RENARD", cp : "45220" },
{ code : "45084", intitule : "CHATENOY", cp : "45260" },
{ code : "45085", intitule : "CHATILLON COLIGNY", cp : "45230" },
{ code : "45086", intitule : "CHATILLON LE ROI", cp : "45480" },
{ code : "45087", intitule : "CHATILLON SUR LOIRE", cp : "45360" },
{ code : "45088", intitule : "CHAUSSY", cp : "45480" },
{ code : "45089", intitule : "CHECY", cp : "45430" },
{ code : "45091", intitule : "CHEVANNES", cp : "45210" },
{ code : "45092", intitule : "CHEVILLON SUR HUILLARD", cp : "45700" },
{ code : "45093", intitule : "CHEVILLY", cp : "45520" },
{ code : "45094", intitule : "CHEVRY SOUS LE BIGNON", cp : "45210" },
{ code : "45095", intitule : "CHILLEURS AUX BOIS", cp : "45170" },
{ code : "45096", intitule : "LES CHOUX", cp : "45290" },
{ code : "45097", intitule : "CHUELLES", cp : "45220" },
{ code : "45098", intitule : "CLERY ST ANDRE", cp : "45370" },
{ code : "45099", intitule : "COINCES", cp : "45310" },
{ code : "45100", intitule : "COMBLEUX", cp : "45800" },
{ code : "45101", intitule : "COMBREUX", cp : "45530" },
{ code : "45102", intitule : "CONFLANS SUR LOING", cp : "45700" },
{ code : "45103", intitule : "CORBEILLES", cp : "45490" },
{ code : "45104", intitule : "CORQUILLEROY", cp : "45120" },
{ code : "45105", intitule : "CORTRAT", cp : "45700" },
{ code : "45107", intitule : "COUDROY", cp : "45260" },
{ code : "45108", intitule : "COULLONS", cp : "45720" },
{ code : "45109", intitule : "COULMIERS", cp : "45130" },
{ code : "45110", intitule : "COURCELLES", cp : "45300" },
{ code : "45111", intitule : "COURCY AUX LOGES", cp : "45300" },
{ code : "45112", intitule : "LA COUR MARIGNY", cp : "45260" },
{ code : "45113", intitule : "COURTEMAUX", cp : "45320" },
{ code : "45114", intitule : "COURTEMPIERRE", cp : "45490" },
{ code : "45115", intitule : "COURTENAY", cp : "45320" },
{ code : "45116", intitule : "CRAVANT", cp : "45190" },
{ code : "45118", intitule : "CROTTES EN PITHIVERAIS", cp : "45170" },
{ code : "45118", intitule : "CROTTES EN PITHIVERAIS", cp : "45170" },
{ code : "45119", intitule : "DADONVILLE", cp : "45300" },
{ code : "45120", intitule : "DAMMARIE EN PUISAYE", cp : "45420" },
{ code : "45121", intitule : "DAMMARIE SUR LOING", cp : "45230" },
{ code : "45122", intitule : "DAMPIERRE EN BURLY", cp : "45570" },
{ code : "45123", intitule : "DARVOY", cp : "45150" },
{ code : "45124", intitule : "DESMONTS", cp : "45390" },
{ code : "45125", intitule : "DIMANCHEVILLE", cp : "45390" },
{ code : "45126", intitule : "DONNERY", cp : "45450" },
{ code : "45127", intitule : "DORDIVES", cp : "45680" },
{ code : "45129", intitule : "DOUCHY MONTCORBON", cp : "45220" },
{ code : "45129", intitule : "DOUCHY MONTCORBON", cp : "45220" },
{ code : "45130", intitule : "DRY", cp : "45370" },
{ code : "45131", intitule : "ECHILLEUSES", cp : "45390" },
{ code : "45132", intitule : "EGRY", cp : "45340" },
{ code : "45133", intitule : "ENGENVILLE", cp : "45300" },
{ code : "45134", intitule : "EPIEDS EN BEAUCE", cp : "45130" },
{ code : "45135", intitule : "ERCEVILLE", cp : "45480" },
{ code : "45136", intitule : "ERVAUVILLE", cp : "45320" },
{ code : "45137", intitule : "ESCRENNES", cp : "45300" },
{ code : "45138", intitule : "ESCRIGNELLES", cp : "45250" },
{ code : "45139", intitule : "ESTOUY", cp : "45300" },
{ code : "45141", intitule : "FAVERELLES", cp : "45420" },
{ code : "45142", intitule : "FAY AUX LOGES", cp : "45450" },
{ code : "45143", intitule : "FEINS EN GATINAIS", cp : "45230" },
{ code : "45144", intitule : "FEROLLES", cp : "45150" },
{ code : "45145", intitule : "FERRIERES EN GATINAIS", cp : "45210" },
{ code : "45146", intitule : "LA FERTE ST AUBIN", cp : "45240" },
{ code : "45147", intitule : "FLEURY LES AUBRAIS", cp : "45400" },
{ code : "45148", intitule : "FONTENAY SUR LOING", cp : "45210" },
{ code : "45149", intitule : "FOUCHEROLLES", cp : "45320" },
{ code : "45150", intitule : "FREVILLE DU GATINAIS", cp : "45270" },
{ code : "45151", intitule : "GAUBERTIN", cp : "45340" },
{ code : "45152", intitule : "GEMIGNY", cp : "45310" },
{ code : "45153", intitule : "GERMIGNY DES PRES", cp : "45110" },
{ code : "45154", intitule : "GIDY", cp : "45520" },
{ code : "45155", intitule : "GIEN", cp : "45500" },
{ code : "45155", intitule : "GIEN", cp : "45500" },
{ code : "45156", intitule : "GIROLLES", cp : "45120" },
{ code : "45157", intitule : "GIVRAINES", cp : "45300" },
{ code : "45158", intitule : "GONDREVILLE", cp : "45490" },
{ code : "45159", intitule : "GRANGERMONT", cp : "45390" },
{ code : "45160", intitule : "GRENEVILLE EN BEAUCE", cp : "45480" },
{ code : "45160", intitule : "GRENEVILLE EN BEAUCE", cp : "45480" },
{ code : "45161", intitule : "GRISELLES", cp : "45210" },
{ code : "45162", intitule : "GUIGNEVILLE", cp : "45300" },
{ code : "45162", intitule : "GUIGNEVILLE", cp : "45300" },
{ code : "45164", intitule : "GUILLY", cp : "45600" },
{ code : "45165", intitule : "GY LES NONAINS", cp : "45220" },
{ code : "45166", intitule : "HUETRE", cp : "45520" },
{ code : "45167", intitule : "HUISSEAU SUR MAUVES", cp : "45130" },
{ code : "45168", intitule : "INGRANNES", cp : "45450" },
{ code : "45169", intitule : "INGRE", cp : "45140" },
{ code : "45170", intitule : "INTVILLE LA GUETARD", cp : "45300" },
{ code : "45171", intitule : "ISDES", cp : "45620" },
{ code : "45173", intitule : "JARGEAU", cp : "45150" },
{ code : "45174", intitule : "JOUY EN PITHIVERAIS", cp : "45480" },
{ code : "45175", intitule : "JOUY LE POTIER", cp : "45370" },
{ code : "45176", intitule : "JURANVILLE", cp : "45340" },
{ code : "45177", intitule : "LAAS", cp : "45300" },
{ code : "45178", intitule : "LADON", cp : "45270" },
{ code : "45179", intitule : "LAILLY EN VAL", cp : "45740" },
{ code : "45180", intitule : "LANGESSE", cp : "45290" },
{ code : "45181", intitule : "LEOUVILLE", cp : "45480" },
{ code : "45182", intitule : "LIGNY LE RIBAULT", cp : "45240" },
{ code : "45183", intitule : "LION EN BEAUCE", cp : "45410" },
{ code : "45184", intitule : "LION EN SULLIAS", cp : "45600" },
{ code : "45185", intitule : "LOMBREUIL", cp : "45700" },
{ code : "45186", intitule : "LORCY", cp : "45490" },
{ code : "45187", intitule : "LORRIS", cp : "45260" },
{ code : "45188", intitule : "LOURY", cp : "45470" },
{ code : "45189", intitule : "LOUZOUER", cp : "45210" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45300" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45191", intitule : "LE MALESHERBOIS", cp : "45330" },
{ code : "45193", intitule : "MARCILLY EN VILLETTE", cp : "45240" },
{ code : "45194", intitule : "MARDIE", cp : "45430" },
{ code : "45194", intitule : "MARDIE", cp : "45430" },
{ code : "45195", intitule : "MAREAU AUX BOIS", cp : "45300" },
{ code : "45196", intitule : "MAREAU AUX PRES", cp : "45370" },
{ code : "45197", intitule : "MARIGNY LES USAGES", cp : "45760" },
{ code : "45198", intitule : "MARSAINVILLIERS", cp : "45300" },
{ code : "45199", intitule : "MELLEROY", cp : "45220" },
{ code : "45200", intitule : "MENESTREAU EN VILLETTE", cp : "45240" },
{ code : "45201", intitule : "MERINVILLE", cp : "45210" },
{ code : "45202", intitule : "MESSAS", cp : "45190" },
{ code : "45203", intitule : "MEUNG SUR LOIRE", cp : "45130" },
{ code : "45204", intitule : "MEZIERES LEZ CLERY", cp : "45370" },
{ code : "45205", intitule : "MEZIERES EN GATINAIS", cp : "45270" },
{ code : "45206", intitule : "MIGNERES", cp : "45490" },
{ code : "45207", intitule : "MIGNERETTE", cp : "45490" },
{ code : "45208", intitule : "MONTARGIS", cp : "45200" },
{ code : "45209", intitule : "MONTBARROIS", cp : "45340" },
{ code : "45210", intitule : "MONTBOUY", cp : "45230" },
{ code : "45212", intitule : "MONTCRESSON", cp : "45700" },
{ code : "45213", intitule : "MONTEREAU", cp : "45260" },
{ code : "45214", intitule : "MONTIGNY", cp : "45170" },
{ code : "45215", intitule : "MONTLIARD", cp : "45340" },
{ code : "45216", intitule : "MORMANT SUR VERNISSON", cp : "45700" },
{ code : "45217", intitule : "MORVILLE EN BEAUCE", cp : "45300" },
{ code : "45218", intitule : "LE MOULINET SUR SOLIN", cp : "45290" },
{ code : "45219", intitule : "MOULON", cp : "45270" },
{ code : "45220", intitule : "NANCRAY SUR RIMARDE", cp : "45340" },
{ code : "45222", intitule : "NARGIS", cp : "45210" },
{ code : "45223", intitule : "NESPLOY", cp : "45270" },
{ code : "45224", intitule : "NEUVILLE AUX BOIS", cp : "45170" },
{ code : "45225", intitule : "LA NEUVILLE SUR ESSONNE", cp : "45390" },
{ code : "45226", intitule : "NEUVY EN SULLIAS", cp : "45510" },
{ code : "45227", intitule : "NEVOY", cp : "45500" },
{ code : "45228", intitule : "NIBELLE", cp : "45340" },
{ code : "45229", intitule : "NOGENT SUR VERNISSON", cp : "45290" },
{ code : "45230", intitule : "NOYERS", cp : "45260" },
{ code : "45231", intitule : "OISON", cp : "45170" },
{ code : "45232", intitule : "OLIVET", cp : "45160" },
{ code : "45233", intitule : "ONDREVILLE SUR ESSONNE", cp : "45390" },
{ code : "45234", intitule : "ORLEANS", cp : "45100" },
{ code : "45234", intitule : "ORLEANS", cp : "45000" },
{ code : "45234", intitule : "ORLEANS", cp : "45100" },
{ code : "45235", intitule : "ORMES", cp : "45140" },
{ code : "45237", intitule : "ORVILLE", cp : "45390" },
{ code : "45238", intitule : "OUSSON SUR LOIRE", cp : "45250" },
{ code : "45239", intitule : "OUSSOY EN GATINAIS", cp : "45290" },
{ code : "45240", intitule : "OUTARVILLE", cp : "45480" },
{ code : "45240", intitule : "OUTARVILLE", cp : "45480" },
{ code : "45240", intitule : "OUTARVILLE", cp : "45480" },
{ code : "45240", intitule : "OUTARVILLE", cp : "45480" },
{ code : "45240", intitule : "OUTARVILLE", cp : "45480" },
{ code : "45241", intitule : "OUVROUER LES CHAMPS", cp : "45150" },
{ code : "45242", intitule : "OUZOUER DES CHAMPS", cp : "45290" },
{ code : "45243", intitule : "OUZOUER SOUS BELLEGARDE", cp : "45270" },
{ code : "45244", intitule : "OUZOUER SUR LOIRE", cp : "45570" },
{ code : "45245", intitule : "OUZOUER SUR TREZEE", cp : "45250" },
{ code : "45246", intitule : "PANNECIERES", cp : "45300" },
{ code : "45247", intitule : "PANNES", cp : "45700" },
{ code : "45248", intitule : "PATAY", cp : "45310" },
{ code : "45249", intitule : "PAUCOURT", cp : "45200" },
{ code : "45250", intitule : "PERS EN GATINAIS", cp : "45210" },
{ code : "45251", intitule : "PIERREFITTE ES BOIS", cp : "45360" },
{ code : "45252", intitule : "PITHIVIERS", cp : "45300" },
{ code : "45253", intitule : "PITHIVIERS LE VIEIL", cp : "45300" },
{ code : "45253", intitule : "PITHIVIERS LE VIEIL", cp : "45300" },
{ code : "45254", intitule : "POILLY LEZ GIEN", cp : "45500" },
{ code : "45255", intitule : "PREFONTAINES", cp : "45490" },
{ code : "45256", intitule : "PRESNOY", cp : "45260" },
{ code : "45257", intitule : "PRESSIGNY LES PINS", cp : "45290" },
{ code : "45258", intitule : "PUISEAUX", cp : "45390" },
{ code : "45259", intitule : "QUIERS SUR BEZONDE", cp : "45270" },
{ code : "45260", intitule : "RAMOULU", cp : "45300" },
{ code : "45261", intitule : "REBRECHIEN", cp : "45470" },
{ code : "45262", intitule : "ROUVRAY STE CROIX", cp : "45310" },
{ code : "45263", intitule : "ROUVRES ST JEAN", cp : "45300" },
{ code : "45264", intitule : "ROZIERES EN BEAUCE", cp : "45130" },
{ code : "45265", intitule : "ROZOY LE VIEIL", cp : "45210" },
{ code : "45266", intitule : "RUAN", cp : "45410" },
{ code : "45267", intitule : "ST AIGNAN DES GUES", cp : "45460" },
{ code : "45268", intitule : "ST AIGNAN LE JAILLARD", cp : "45600" },
{ code : "45269", intitule : "ST AY", cp : "45130" },
{ code : "45270", intitule : "ST BENOIT SUR LOIRE", cp : "45730" },
{ code : "45271", intitule : "ST BRISSON SUR LOIRE", cp : "45500" },
{ code : "45272", intitule : "ST CYR EN VAL", cp : "45590" },
{ code : "45273", intitule : "ST DENIS DE L HOTEL", cp : "45550" },
{ code : "45274", intitule : "ST DENIS EN VAL", cp : "45560" },
{ code : "45275", intitule : "ST FIRMIN DES BOIS", cp : "45220" },
{ code : "45276", intitule : "ST FIRMIN SUR LOIRE", cp : "45360" },
{ code : "45277", intitule : "ST FLORENT", cp : "45600" },
{ code : "45278", intitule : "STE GENEVIEVE DES BOIS", cp : "45230" },
{ code : "45279", intitule : "ST GERMAIN DES PRES", cp : "45220" },
{ code : "45280", intitule : "ST GONDON", cp : "45500" },
{ code : "45281", intitule : "ST HILAIRE LES ANDRESIS", cp : "45320" },
{ code : "45282", intitule : "ST HILAIRE ST MESMIN", cp : "45160" },
{ code : "45283", intitule : "ST HILAIRE SUR PUISEAUX", cp : "45700" },
{ code : "45284", intitule : "ST JEAN DE BRAYE", cp : "45800" },
{ code : "45285", intitule : "ST JEAN DE LA RUELLE", cp : "45140" },
{ code : "45286", intitule : "ST JEAN LE BLANC", cp : "45650" },
{ code : "45287", intitule : "ST LOUP DE GONOIS", cp : "45210" },
{ code : "45288", intitule : "ST LOUP DES VIGNES", cp : "45340" },
{ code : "45289", intitule : "ST LYE LA FORET", cp : "45170" },
{ code : "45290", intitule : "ST MARTIN D ABBAT", cp : "45110" },
{ code : "45291", intitule : "ST MARTIN SUR OCRE", cp : "45500" },
{ code : "45292", intitule : "ST MAURICE SUR AVEYRON", cp : "45230" },
{ code : "45293", intitule : "ST MAURICE SUR FESSARD", cp : "45700" },
{ code : "45294", intitule : "ST MICHEL", cp : "45340" },
{ code : "45296", intitule : "ST PERAVY LA COLOMBE", cp : "45310" },
{ code : "45297", intitule : "ST PERE SUR LOIRE", cp : "45600" },
{ code : "45298", intitule : "ST PRYVE ST MESMIN", cp : "45750" },
{ code : "45299", intitule : "ST SIGISMOND", cp : "45310" },
{ code : "45300", intitule : "SANDILLON", cp : "45640" },
{ code : "45301", intitule : "SANTEAU", cp : "45170" },
{ code : "45302", intitule : "SARAN", cp : "45770" },
{ code : "45303", intitule : "SCEAUX DU GATINAIS", cp : "45490" },
{ code : "45305", intitule : "SEICHEBRIERES", cp : "45530" },
{ code : "45306", intitule : "LA SELLE EN HERMOY", cp : "45210" },
{ code : "45307", intitule : "LA SELLE SUR LE BIED", cp : "45210" },
{ code : "45308", intitule : "SEMOY", cp : "45400" },
{ code : "45309", intitule : "SENNELY", cp : "45240" },
{ code : "45310", intitule : "SERMAISES", cp : "45300" },
{ code : "45311", intitule : "SIGLOY", cp : "45110" },
{ code : "45312", intitule : "SOLTERRE", cp : "45700" },
{ code : "45313", intitule : "SOUGY", cp : "45410" },
{ code : "45314", intitule : "SULLY LA CHAPELLE", cp : "45450" },
{ code : "45315", intitule : "SULLY SUR LOIRE", cp : "45600" },
{ code : "45316", intitule : "SURY AUX BOIS", cp : "45530" },
{ code : "45317", intitule : "TAVERS", cp : "45190" },
{ code : "45320", intitule : "THIGNONVILLE", cp : "45300" },
{ code : "45321", intitule : "THIMORY", cp : "45260" },
{ code : "45322", intitule : "THORAILLES", cp : "45210" },
{ code : "45323", intitule : "THOU", cp : "45420" },
{ code : "45324", intitule : "TIGY", cp : "45510" },
{ code : "45325", intitule : "TIVERNON", cp : "45170" },
{ code : "45326", intitule : "TOURNOISIS", cp : "45310" },
{ code : "45327", intitule : "TRAINOU", cp : "45470" },
{ code : "45328", intitule : "TREILLES EN GATINAIS", cp : "45490" },
{ code : "45329", intitule : "TRIGUERES", cp : "45220" },
{ code : "45330", intitule : "TRINAY", cp : "45410" },
{ code : "45331", intitule : "VANNES SUR COSSON", cp : "45510" },
{ code : "45332", intitule : "VARENNES CHANGY", cp : "45290" },
{ code : "45333", intitule : "VENNECY", cp : "45760" },
{ code : "45334", intitule : "VIEILLES MAISONS SUR JOUDRY", cp : "45260" },
{ code : "45335", intitule : "VIENNE EN VAL", cp : "45510" },
{ code : "45336", intitule : "VIGLAIN", cp : "45600" },
{ code : "45337", intitule : "VILLAMBLAIN", cp : "45310" },
{ code : "45338", intitule : "VILLEMANDEUR", cp : "45700" },
{ code : "45339", intitule : "VILLEMOUTIERS", cp : "45270" },
{ code : "45340", intitule : "VILLEMURLIN", cp : "45600" },
{ code : "45341", intitule : "VILLENEUVE SUR CONIE", cp : "45310" },
{ code : "45342", intitule : "VILLEREAU", cp : "45170" },
{ code : "45343", intitule : "VILLEVOQUES", cp : "45700" },
{ code : "45344", intitule : "VILLORCEAU", cp : "45190" },
{ code : "45345", intitule : "VIMORY", cp : "45700" },
{ code : "45346", intitule : "VITRY AUX LOGES", cp : "45530" },
{ code : "45347", intitule : "VRIGNY", cp : "45300" },
{ code : "45348", intitule : "YEVRE LA VILLE", cp : "45300" },
{ code : "45348", intitule : "YEVRE LA VILLE", cp : "45300" },
{ code : "46001", intitule : "ALBAS", cp : "46140" },
{ code : "46002", intitule : "ALBIAC", cp : "46500" },
{ code : "46003", intitule : "ALVIGNAC", cp : "46500" },
{ code : "46004", intitule : "ANGLARS", cp : "46120" },
{ code : "46005", intitule : "ANGLARS JUILLAC", cp : "46140" },
{ code : "46006", intitule : "ANGLARS NOZAC", cp : "46300" },
{ code : "46007", intitule : "ARCAMBAL", cp : "46090" },
{ code : "46008", intitule : "LES ARQUES", cp : "46250" },
{ code : "46009", intitule : "ASSIER", cp : "46320" },
{ code : "46010", intitule : "AUJOLS", cp : "46090" },
{ code : "46011", intitule : "AUTOIRE", cp : "46400" },
{ code : "46012", intitule : "AYNAC", cp : "46120" },
{ code : "46013", intitule : "BACH", cp : "46230" },
{ code : "46014", intitule : "BAGAT EN QUERCY", cp : "46800" },
{ code : "46015", intitule : "BAGNAC SUR CELE", cp : "46270" },
{ code : "46016", intitule : "BALADOU", cp : "46600" },
{ code : "46017", intitule : "BANNES", cp : "46400" },
{ code : "46018", intitule : "LE BASTIT", cp : "46500" },
{ code : "46020", intitule : "BEAUREGARD", cp : "46260" },
{ code : "46021", intitule : "BEDUER", cp : "46100" },
{ code : "46022", intitule : "BELAYE", cp : "46140" },
{ code : "46023", intitule : "BELFORT DU QUERCY", cp : "46230" },
{ code : "46024", intitule : "BELMONT BRETENOUX", cp : "46130" },
{ code : "46026", intitule : "BELMONT STE FOI", cp : "46230" },
{ code : "46027", intitule : "BERGANTY", cp : "46090" },
{ code : "46028", intitule : "BETAILLE", cp : "46110" },
{ code : "46029", intitule : "BIARS SUR CERE", cp : "46130" },
{ code : "46030", intitule : "BIO", cp : "46500" },
{ code : "46031", intitule : "BLARS", cp : "46330" },
{ code : "46032", intitule : "BOISSIERES", cp : "46150" },
{ code : "46033", intitule : "LE BOULVE", cp : "46800" },
{ code : "46034", intitule : "LE BOURG", cp : "46120" },
{ code : "46035", intitule : "BOUSSAC", cp : "46100" },
{ code : "46036", intitule : "LE BOUYSSOU", cp : "46120" },
{ code : "46037", intitule : "BOUZIES", cp : "46330" },
{ code : "46038", intitule : "BRETENOUX", cp : "46130" },
{ code : "46039", intitule : "BRENGUES", cp : "46320" },
{ code : "46040", intitule : "CABRERETS", cp : "46330" },
{ code : "46041", intitule : "CADRIEU", cp : "46160" },
{ code : "46042", intitule : "CAHORS", cp : "46000" },
{ code : "46043", intitule : "CAHUS", cp : "46130" },
{ code : "46044", intitule : "CAILLAC", cp : "46140" },
{ code : "46045", intitule : "CAJARC", cp : "46160" },
{ code : "46046", intitule : "CALAMANE", cp : "46150" },
{ code : "46047", intitule : "CALES", cp : "46350" },
{ code : "46049", intitule : "CALVIGNAC", cp : "46160" },
{ code : "46050", intitule : "CAMBAYRAC", cp : "46140" },
{ code : "46051", intitule : "CAMBES", cp : "46100" },
{ code : "46052", intitule : "CAMBOULIT", cp : "46100" },
{ code : "46053", intitule : "CAMBURAT", cp : "46100" },
{ code : "46054", intitule : "CANIAC DU CAUSSE", cp : "46240" },
{ code : "46055", intitule : "CAPDENAC", cp : "46100" },
{ code : "46056", intitule : "CARAYAC", cp : "46160" },
{ code : "46057", intitule : "CARDAILLAC", cp : "46100" },
{ code : "46058", intitule : "CARENNAC", cp : "46110" },
{ code : "46059", intitule : "CARLUCET", cp : "46500" },
{ code : "46060", intitule : "CARNAC ROUFFIAC", cp : "46140" },
{ code : "46061", intitule : "CASSAGNES", cp : "46700" },
{ code : "46062", intitule : "CASTELFRANC", cp : "46140" },
{ code : "46063", intitule : "CASTELNAU MONTRATIER", cp : "46170" },
{ code : "46064", intitule : "CATUS", cp : "46150" },
{ code : "46065", intitule : "CAVAGNAC", cp : "46110" },
{ code : "46066", intitule : "CAZALS", cp : "46250" },
{ code : "46067", intitule : "CAZILLAC", cp : "46600" },
{ code : "46068", intitule : "CENEVIERES", cp : "46330" },
{ code : "46069", intitule : "CEZAC", cp : "46170" },
{ code : "46070", intitule : "CIEURAC", cp : "46230" },
{ code : "46072", intitule : "CONCORES", cp : "46310" },
{ code : "46073", intitule : "CONCOTS", cp : "46260" },
{ code : "46074", intitule : "CONDAT", cp : "46110" },
{ code : "46075", intitule : "CORN", cp : "46100" },
{ code : "46076", intitule : "CORNAC", cp : "46130" },
{ code : "46077", intitule : "COURS", cp : "46090" },
{ code : "46078", intitule : "COUZOU", cp : "46500" },
{ code : "46079", intitule : "CRAS", cp : "46360" },
{ code : "46080", intitule : "CRAYSSAC", cp : "46150" },
{ code : "46081", intitule : "CREGOLS", cp : "46330" },
{ code : "46082", intitule : "CREMPS", cp : "46230" },
{ code : "46083", intitule : "CRESSENSAC", cp : "46600" },
{ code : "46084", intitule : "CREYSSE", cp : "46600" },
{ code : "46085", intitule : "CUZAC", cp : "46270" },
{ code : "46086", intitule : "CUZANCE", cp : "46600" },
{ code : "46087", intitule : "DEGAGNAC", cp : "46340" },
{ code : "46088", intitule : "DOUELLE", cp : "46140" },
{ code : "46089", intitule : "DURAVEL", cp : "46700" },
{ code : "46090", intitule : "DURBANS", cp : "46320" },
{ code : "46091", intitule : "ESCAMPS", cp : "46230" },
{ code : "46092", intitule : "ESCLAUZELS", cp : "46090" },
{ code : "46093", intitule : "ESPAGNAC STE EULALIE", cp : "46320" },
{ code : "46094", intitule : "ESPEDAILLAC", cp : "46320" },
{ code : "46095", intitule : "ESPERE", cp : "46090" },
{ code : "46096", intitule : "ESPEYROUX", cp : "46120" },
{ code : "46097", intitule : "ESTAL", cp : "46130" },
{ code : "46098", intitule : "FAJOLES", cp : "46300" },
{ code : "46099", intitule : "FARGUES", cp : "46800" },
{ code : "46100", intitule : "FAYCELLES", cp : "46100" },
{ code : "46101", intitule : "FELZINS", cp : "46270" },
{ code : "46102", intitule : "FIGEAC", cp : "46100" },
{ code : "46103", intitule : "ST PAUL FLAUGNAC", cp : "46170" },
{ code : "46103", intitule : "ST PAUL FLAUGNAC", cp : "46170" },
{ code : "46104", intitule : "FLAUJAC GARE", cp : "46320" },
{ code : "46105", intitule : "FLAUJAC POUJOLS", cp : "46090" },
{ code : "46106", intitule : "FLOIRAC", cp : "46600" },
{ code : "46107", intitule : "FLORESSAS", cp : "46700" },
{ code : "46108", intitule : "FONS", cp : "46100" },
{ code : "46109", intitule : "FONTANES", cp : "46230" },
{ code : "46111", intitule : "FOURMAGNAC", cp : "46100" },
{ code : "46112", intitule : "FRANCOULES", cp : "46090" },
{ code : "46113", intitule : "FRAYSSINET", cp : "46310" },
{ code : "46114", intitule : "FRAYSSINET LE GELAT", cp : "46250" },
{ code : "46115", intitule : "FRAYSSINHES", cp : "46400" },
{ code : "46116", intitule : "FRONTENAC", cp : "46160" },
{ code : "46117", intitule : "GAGNAC SUR CERE", cp : "46130" },
{ code : "46118", intitule : "GIGNAC", cp : "46600" },
{ code : "46119", intitule : "GIGOUZAC", cp : "46150" },
{ code : "46120", intitule : "GINDOU", cp : "46250" },
{ code : "46121", intitule : "GINOUILLAC", cp : "46300" },
{ code : "46122", intitule : "GINTRAC", cp : "46130" },
{ code : "46123", intitule : "GIRAC", cp : "46130" },
{ code : "46124", intitule : "GLANES", cp : "46130" },
{ code : "46125", intitule : "GORSES", cp : "46210" },
{ code : "46126", intitule : "GOUJOUNAC", cp : "46250" },
{ code : "46127", intitule : "GOURDON", cp : "46300" },
{ code : "46128", intitule : "GRAMAT", cp : "46500" },
{ code : "46129", intitule : "GREALOU", cp : "46160" },
{ code : "46130", intitule : "GREZELS", cp : "46700" },
{ code : "46131", intitule : "GREZES", cp : "46320" },
{ code : "46132", intitule : "ISSENDOLUS", cp : "46500" },
{ code : "46133", intitule : "ISSEPTS", cp : "46320" },
{ code : "46134", intitule : "LES JUNIES", cp : "46150" },
{ code : "46135", intitule : "LABASTIDE DU HAUT MONT", cp : "46210" },
{ code : "46136", intitule : "LABASTIDE DU VERT", cp : "46150" },
{ code : "46137", intitule : "LABASTIDE MARNHAC", cp : "46090" },
{ code : "46138", intitule : "COEUR DE CAUSSE", cp : "46240" },
{ code : "46138", intitule : "COEUR DE CAUSSE", cp : "46240" },
{ code : "46138", intitule : "COEUR DE CAUSSE", cp : "46240" },
{ code : "46138", intitule : "COEUR DE CAUSSE", cp : "46240" },
{ code : "46138", intitule : "COEUR DE CAUSSE", cp : "46240" },
{ code : "46139", intitule : "LABATHUDE", cp : "46120" },
{ code : "46140", intitule : "LABURGADE", cp : "46230" },
{ code : "46142", intitule : "LACAPELLE CABANAC", cp : "46700" },
{ code : "46143", intitule : "LACAPELLE MARIVAL", cp : "46120" },
{ code : "46144", intitule : "LACAVE", cp : "46200" },
{ code : "46145", intitule : "LACHAPELLE AUZAC", cp : "46200" },
{ code : "46146", intitule : "LADIRAT", cp : "46400" },
{ code : "46147", intitule : "LAGARDELLE", cp : "46220" },
{ code : "46148", intitule : "LALBENQUE", cp : "46230" },
{ code : "46149", intitule : "LAMAGDELAINE", cp : "46090" },
{ code : "46151", intitule : "LAMOTHE CASSEL", cp : "46240" },
{ code : "46152", intitule : "LAMOTHE FENELON", cp : "46350" },
{ code : "46153", intitule : "LANZAC", cp : "46200" },
{ code : "46154", intitule : "LARAMIERE", cp : "46260" },
{ code : "46155", intitule : "LARNAGOL", cp : "46160" },
{ code : "46156", intitule : "LAROQUE DES ARCS", cp : "46090" },
{ code : "46157", intitule : "LARROQUE TOIRAC", cp : "46160" },
{ code : "46158", intitule : "LASCABANES", cp : "46800" },
{ code : "46159", intitule : "LATOUILLE LENTILLAC", cp : "46400" },
{ code : "46160", intitule : "LATRONQUIERE", cp : "46210" },
{ code : "46161", intitule : "LAURESSES", cp : "46210" },
{ code : "46162", intitule : "LAUZES", cp : "46360" },
{ code : "46163", intitule : "LAVAL DE CERE", cp : "46130" },
{ code : "46164", intitule : "LAVERCANTIERE", cp : "46340" },
{ code : "46165", intitule : "LAVERGNE", cp : "46500" },
{ code : "46167", intitule : "LENTILLAC DU CAUSSE", cp : "46330" },
{ code : "46168", intitule : "LENTILLAC ST BLAISE", cp : "46100" },
{ code : "46169", intitule : "LEOBARD", cp : "46300" },
{ code : "46170", intitule : "LEYME", cp : "46120" },
{ code : "46171", intitule : "LHERM", cp : "46150" },
{ code : "46172", intitule : "LHOSPITALET", cp : "46170" },
{ code : "46173", intitule : "LIMOGNE EN QUERCY", cp : "46260" },
{ code : "46174", intitule : "LINAC", cp : "46270" },
{ code : "46175", intitule : "LISSAC ET MOURET", cp : "46100" },
{ code : "46176", intitule : "LIVERNON", cp : "46320" },
{ code : "46177", intitule : "LOUBRESSAC", cp : "46130" },
{ code : "46178", intitule : "LOUPIAC", cp : "46350" },
{ code : "46179", intitule : "LUGAGNAC", cp : "46260" },
{ code : "46180", intitule : "LUNAN", cp : "46100" },
{ code : "46181", intitule : "LUNEGARDE", cp : "46240" },
{ code : "46182", intitule : "LUZECH", cp : "46140" },
{ code : "46183", intitule : "MARCILHAC SUR CELE", cp : "46160" },
{ code : "46184", intitule : "MARMINIAC", cp : "46250" },
{ code : "46185", intitule : "MARTEL", cp : "46600" },
{ code : "46186", intitule : "MASCLAT", cp : "46350" },
{ code : "46187", intitule : "MAUROUX", cp : "46700" },
{ code : "46188", intitule : "MAXOU", cp : "46090" },
{ code : "46189", intitule : "MAYRINHAC LENTOUR", cp : "46500" },
{ code : "46190", intitule : "MECHMONT", cp : "46150" },
{ code : "46191", intitule : "MERCUES", cp : "46090" },
{ code : "46192", intitule : "MEYRONNE", cp : "46200" },
{ code : "46193", intitule : "MIERS", cp : "46500" },
{ code : "46194", intitule : "MILHAC", cp : "46300" },
{ code : "46195", intitule : "MOLIERES", cp : "46120" },
{ code : "46196", intitule : "MONTAMEL", cp : "46310" },
{ code : "46197", intitule : "LE MONTAT", cp : "46090" },
{ code : "46198", intitule : "MONTBRUN", cp : "46160" },
{ code : "46199", intitule : "MONTCABRIER", cp : "46700" },
{ code : "46200", intitule : "MONTCLERA", cp : "46250" },
{ code : "46201", intitule : "MONTCUQ EN QUERCY BLANC", cp : "46800" },
{ code : "46201", intitule : "MONTCUQ EN QUERCY BLANC", cp : "46800" },
{ code : "46201", intitule : "MONTCUQ EN QUERCY BLANC", cp : "46800" },
{ code : "46201", intitule : "MONTCUQ EN QUERCY BLANC", cp : "46800" },
{ code : "46201", intitule : "MONTCUQ EN QUERCY BLANC", cp : "46800" },
{ code : "46202", intitule : "MONTDOUMERC", cp : "46230" },
{ code : "46203", intitule : "MONTET ET BOUXAL", cp : "46210" },
{ code : "46204", intitule : "MONTFAUCON", cp : "46240" },
{ code : "46205", intitule : "MONTGESTY", cp : "46150" },
{ code : "46206", intitule : "MONTLAUZUN", cp : "46800" },
{ code : "46207", intitule : "MONTREDON", cp : "46270" },
{ code : "46208", intitule : "MONTVALENT", cp : "46600" },
{ code : "46209", intitule : "NADAILLAC DE ROUGE", cp : "46350" },
{ code : "46210", intitule : "NADILLAC", cp : "46360" },
{ code : "46211", intitule : "NUZEJOULS", cp : "46150" },
{ code : "46212", intitule : "ORNIAC", cp : "46330" },
{ code : "46213", intitule : "PADIRAC", cp : "46500" },
{ code : "46214", intitule : "PARNAC", cp : "46140" },
{ code : "46215", intitule : "PAYRAC", cp : "46350" },
{ code : "46216", intitule : "PAYRIGNAC", cp : "46300" },
{ code : "46217", intitule : "PERN", cp : "46170" },
{ code : "46218", intitule : "PESCADOIRES", cp : "46220" },
{ code : "46219", intitule : "PEYRILLES", cp : "46310" },
{ code : "46220", intitule : "PINSAC", cp : "46200" },
{ code : "46221", intitule : "PLANIOLES", cp : "46100" },
{ code : "46222", intitule : "POMAREDE", cp : "46250" },
{ code : "46223", intitule : "PONTCIRQ", cp : "46150" },
{ code : "46224", intitule : "PRADINES", cp : "46090" },
{ code : "46225", intitule : "PRAYSSAC", cp : "46220" },
{ code : "46226", intitule : "PRENDEIGNES", cp : "46270" },
{ code : "46227", intitule : "PROMILHANES", cp : "46260" },
{ code : "46228", intitule : "PRUDHOMAT", cp : "46130" },
{ code : "46229", intitule : "PUYBRUN", cp : "46130" },
{ code : "46230", intitule : "PUYJOURDES", cp : "46260" },
{ code : "46231", intitule : "PUY L EVEQUE", cp : "46700" },
{ code : "46232", intitule : "LES QUATRE ROUTES DU LOT", cp : "46110" },
{ code : "46233", intitule : "QUISSAC", cp : "46320" },
{ code : "46234", intitule : "RAMPOUX", cp : "46340" },
{ code : "46235", intitule : "REILHAC", cp : "46500" },
{ code : "46236", intitule : "REILHAGUET", cp : "46350" },
{ code : "46237", intitule : "REYREVIGNES", cp : "46320" },
{ code : "46238", intitule : "RIGNAC", cp : "46500" },
{ code : "46239", intitule : "LE ROC", cp : "46200" },
{ code : "46240", intitule : "ROCAMADOUR", cp : "46500" },
{ code : "46241", intitule : "ROUFFILHAC", cp : "46300" },
{ code : "46242", intitule : "RUDELLE", cp : "46120" },
{ code : "46243", intitule : "RUEYRES", cp : "46120" },
{ code : "46244", intitule : "SABADEL LATRONQUIERE", cp : "46210" },
{ code : "46245", intitule : "SABADEL LAUZES", cp : "46360" },
{ code : "46246", intitule : "SAIGNES", cp : "46500" },
{ code : "46247", intitule : "SAILLAC", cp : "46260" },
{ code : "46248", intitule : "STE ALAUZIE", cp : "46170" },
{ code : "46249", intitule : "ST BRESSOU", cp : "46120" },
{ code : "46250", intitule : "ST CAPRAIS", cp : "46250" },
{ code : "46251", intitule : "ST CERE", cp : "46400" },
{ code : "46252", intitule : "LES PECHS DU VERS", cp : "46360" },
{ code : "46252", intitule : "LES PECHS DU VERS", cp : "46360" },
{ code : "46253", intitule : "ST CHAMARAND", cp : "46310" },
{ code : "46254", intitule : "ST CHELS", cp : "46160" },
{ code : "46255", intitule : "ST CIRGUES", cp : "46210" },
{ code : "46256", intitule : "ST CIRQ LAPOPIE", cp : "46330" },
{ code : "46257", intitule : "ST CIRQ MADELON", cp : "46300" },
{ code : "46258", intitule : "ST CIRQ SOUILLAGUET", cp : "46300" },
{ code : "46259", intitule : "ST CLAIR", cp : "46300" },
{ code : "46260", intitule : "STE COLOMBE", cp : "46120" },
{ code : "46262", intitule : "ST CYPRIEN", cp : "46800" },
{ code : "46263", intitule : "ST DAUNES", cp : "46800" },
{ code : "46264", intitule : "ST DENIS CATUS", cp : "46150" },
{ code : "46265", intitule : "ST DENIS LES MARTEL", cp : "46600" },
{ code : "46266", intitule : "ST FELIX", cp : "46100" },
{ code : "46267", intitule : "ST GERMAIN DU BEL AIR", cp : "46310" },
{ code : "46268", intitule : "ST GERY", cp : "46330" },
{ code : "46269", intitule : "ST HILAIRE", cp : "46210" },
{ code : "46270", intitule : "ST JEAN DE LAUR", cp : "46260" },
{ code : "46271", intitule : "ST JEAN LESPINASSE", cp : "46400" },
{ code : "46272", intitule : "ST JEAN MIRABEL", cp : "46270" },
{ code : "46273", intitule : "ST LAURENT LES TOURS", cp : "46400" },
{ code : "46274", intitule : "ST LAURENT LOLMIE", cp : "46800" },
{ code : "46276", intitule : "ST MARTIN LABOUVAL", cp : "46330" },
{ code : "46277", intitule : "ST MARTIN LE REDON", cp : "46700" },
{ code : "46278", intitule : "ST MATRE", cp : "46800" },
{ code : "46279", intitule : "ST MAURICE EN QUERCY", cp : "46120" },
{ code : "46280", intitule : "ST MEDARD", cp : "46150" },
{ code : "46281", intitule : "ST MEDARD DE PRESQUE", cp : "46400" },
{ code : "46282", intitule : "ST MEDARD NICOURBY", cp : "46210" },
{ code : "46283", intitule : "ST MICHEL DE BANNIERES", cp : "46110" },
{ code : "46284", intitule : "ST MICHEL LOUBEJOU", cp : "46130" },
{ code : "46285", intitule : "ST PANTALEON", cp : "46800" },
{ code : "46286", intitule : "ST PAUL DE VERN", cp : "46400" },
{ code : "46288", intitule : "ST PERDOUX", cp : "46100" },
{ code : "46289", intitule : "ST PIERRE TOIRAC", cp : "46160" },
{ code : "46290", intitule : "ST PROJET", cp : "46300" },
{ code : "46292", intitule : "ST SIMON", cp : "46320" },
{ code : "46293", intitule : "ST SOZY", cp : "46200" },
{ code : "46294", intitule : "ST SULPICE", cp : "46160" },
{ code : "46295", intitule : "ST VINCENT DU PENDIT", cp : "46400" },
{ code : "46296", intitule : "ST VINCENT RIVE D OLT", cp : "46140" },
{ code : "46297", intitule : "SALVIAC", cp : "46340" },
{ code : "46298", intitule : "SARRAZAC", cp : "46600" },
{ code : "46299", intitule : "SAULIAC SUR CELE", cp : "46330" },
{ code : "46300", intitule : "SAUX", cp : "46800" },
{ code : "46301", intitule : "SAUZET", cp : "46140" },
{ code : "46302", intitule : "SENAILLAC LATRONQUIERE", cp : "46210" },
{ code : "46303", intitule : "SENAILLAC LAUZES", cp : "46360" },
{ code : "46304", intitule : "SENIERGUES", cp : "46240" },
{ code : "46305", intitule : "SERIGNAC", cp : "46700" },
{ code : "46306", intitule : "SONAC", cp : "46320" },
{ code : "46307", intitule : "SOTURAC", cp : "46700" },
{ code : "46308", intitule : "SOUCIRAC", cp : "46300" },
{ code : "46309", intitule : "SOUILLAC", cp : "46200" },
{ code : "46310", intitule : "SOULOMES", cp : "46240" },
{ code : "46311", intitule : "SOUSCEYRAC EN QUERCY", cp : "46190" },
{ code : "46311", intitule : "SOUSCEYRAC EN QUERCY", cp : "46190" },
{ code : "46311", intitule : "SOUSCEYRAC EN QUERCY", cp : "46190" },
{ code : "46311", intitule : "SOUSCEYRAC EN QUERCY", cp : "46190" },
{ code : "46311", intitule : "SOUSCEYRAC EN QUERCY", cp : "46190" },
{ code : "46312", intitule : "STRENQUELS", cp : "46110" },
{ code : "46313", intitule : "TAURIAC", cp : "46130" },
{ code : "46314", intitule : "TERROU", cp : "46120" },
{ code : "46315", intitule : "TEYSSIEU", cp : "46190" },
{ code : "46316", intitule : "THEDIRAC", cp : "46150" },
{ code : "46317", intitule : "THEGRA", cp : "46500" },
{ code : "46318", intitule : "THEMINES", cp : "46120" },
{ code : "46319", intitule : "THEMINETTES", cp : "46120" },
{ code : "46320", intitule : "TOUR DE FAURE", cp : "46330" },
{ code : "46321", intitule : "TOUZAC", cp : "46700" },
{ code : "46322", intitule : "TRESPOUX RASSIELS", cp : "46090" },
{ code : "46323", intitule : "USSEL", cp : "46240" },
{ code : "46324", intitule : "UZECH", cp : "46310" },
{ code : "46327", intitule : "VALROUFIE", cp : "46090" },
{ code : "46328", intitule : "VARAIRE", cp : "46260" },
{ code : "46329", intitule : "VAYLATS", cp : "46230" },
{ code : "46330", intitule : "VAYRAC", cp : "46110" },
{ code : "46331", intitule : "VERS", cp : "46090" },
{ code : "46332", intitule : "VIAZAC", cp : "46100" },
{ code : "46333", intitule : "VIDAILLAC", cp : "46260" },
{ code : "46334", intitule : "LE VIGAN", cp : "46300" },
{ code : "46335", intitule : "VILLESEQUE", cp : "46090" },
{ code : "46336", intitule : "VIRE SUR LOT", cp : "46700" },
{ code : "46337", intitule : "MAYRAC", cp : "46200" },
{ code : "46338", intitule : "BESSONIES", cp : "46210" },
{ code : "46339", intitule : "ST JEAN LAGINESTE", cp : "46400" },
{ code : "46340", intitule : "ST PIERRE LAFEUILLE", cp : "46090" },
{ code : "47001", intitule : "AGEN", cp : "47000" },
{ code : "47002", intitule : "AGME", cp : "47350" },
{ code : "47003", intitule : "AGNAC", cp : "47800" },
{ code : "47004", intitule : "AIGUILLON", cp : "47190" },
{ code : "47005", intitule : "ALLEMANS DU DROPT", cp : "47800" },
{ code : "47006", intitule : "ALLEZ ET CAZENEUVE", cp : "47110" },
{ code : "47007", intitule : "ALLONS", cp : "47420" },
{ code : "47008", intitule : "AMBRUS", cp : "47160" },
{ code : "47009", intitule : "ANDIRAN", cp : "47170" },
{ code : "47010", intitule : "ANTAGNAC", cp : "47700" },
{ code : "47011", intitule : "ANTHE", cp : "47370" },
{ code : "47012", intitule : "ANZEX", cp : "47700" },
{ code : "47013", intitule : "ARGENTON", cp : "47250" },
{ code : "47014", intitule : "ARMILLAC", cp : "47800" },
{ code : "47015", intitule : "ASTAFFORT", cp : "47220" },
{ code : "47016", intitule : "AUBIAC", cp : "47310" },
{ code : "47017", intitule : "AURADOU", cp : "47140" },
{ code : "47018", intitule : "AURIAC SUR DROPT", cp : "47120" },
{ code : "47019", intitule : "BAJAMONT", cp : "47480" },
{ code : "47020", intitule : "BALEYSSAGUES", cp : "47120" },
{ code : "47021", intitule : "BARBASTE", cp : "47230" },
{ code : "47022", intitule : "BAZENS", cp : "47130" },
{ code : "47023", intitule : "BEAUGAS", cp : "47290" },
{ code : "47024", intitule : "BEAUPUY", cp : "47200" },
{ code : "47025", intitule : "BEAUVILLE", cp : "47470" },
{ code : "47026", intitule : "BEAUZIAC", cp : "47700" },
{ code : "47027", intitule : "BIAS", cp : "47300" },
{ code : "47028", intitule : "BIRAC SUR TREC", cp : "47200" },
{ code : "47029", intitule : "BLANQUEFORT SUR BRIOLANCE", cp : "47500" },
{ code : "47030", intitule : "BLAYMONT", cp : "47470" },
{ code : "47031", intitule : "BOE", cp : "47550" },
{ code : "47032", intitule : "BON ENCONTRE", cp : "47240" },
{ code : "47033", intitule : "BOUDY DE BEAUREGARD", cp : "47290" },
{ code : "47034", intitule : "BOUGLON", cp : "47250" },
{ code : "47035", intitule : "BOURGOUGNAGUE", cp : "47410" },
{ code : "47036", intitule : "BOURLENS", cp : "47370" },
{ code : "47037", intitule : "BOURNEL", cp : "47210" },
{ code : "47038", intitule : "BOURRAN", cp : "47320" },
{ code : "47039", intitule : "BOUSSES", cp : "47420" },
{ code : "47040", intitule : "BRAX", cp : "47310" },
{ code : "47041", intitule : "BRUCH", cp : "47130" },
{ code : "47042", intitule : "BRUGNAC", cp : "47260" },
{ code : "47043", intitule : "BUZET SUR BAISE", cp : "47160" },
{ code : "47044", intitule : "CAHUZAC", cp : "47330" },
{ code : "47045", intitule : "CALIGNAC", cp : "47600" },
{ code : "47046", intitule : "CALONGES", cp : "47430" },
{ code : "47047", intitule : "CAMBES", cp : "47350" },
{ code : "47048", intitule : "CANCON", cp : "47290" },
{ code : "47049", intitule : "CASSENEUIL", cp : "47440" },
{ code : "47050", intitule : "CASSIGNAS", cp : "47340" },
{ code : "47051", intitule : "CASTELCULIER", cp : "47240" },
{ code : "47052", intitule : "CASTELJALOUX", cp : "47700" },
{ code : "47053", intitule : "CASTELLA", cp : "47340" },
{ code : "47054", intitule : "CASTELMORON SUR LOT", cp : "47260" },
{ code : "47055", intitule : "CASTELNAUD DE GRATECAMBE", cp : "47290" },
{ code : "47056", intitule : "CASTELNAU SUR GUPIE", cp : "47180" },
{ code : "47057", intitule : "CASTILLONNES", cp : "47330" },
{ code : "47058", intitule : "CAUBEYRES", cp : "47160" },
{ code : "47059", intitule : "CAUBON ST SAUVEUR", cp : "47120" },
{ code : "47060", intitule : "CAUDECOSTE", cp : "47220" },
{ code : "47061", intitule : "CAUMONT SUR GARONNE", cp : "47430" },
{ code : "47062", intitule : "CAUZAC", cp : "47470" },
{ code : "47063", intitule : "CAVARC", cp : "47330" },
{ code : "47064", intitule : "CAZIDEROQUE", cp : "47370" },
{ code : "47065", intitule : "CLAIRAC", cp : "47320" },
{ code : "47066", intitule : "CLERMONT DESSOUS", cp : "47130" },
{ code : "47067", intitule : "CLERMONT SOUBIRAN", cp : "47270" },
{ code : "47068", intitule : "COCUMONT", cp : "47250" },
{ code : "47069", intitule : "COLAYRAC ST CIRQ", cp : "47450" },
{ code : "47070", intitule : "CONDEZAYGUES", cp : "47500" },
{ code : "47071", intitule : "COULX", cp : "47260" },
{ code : "47072", intitule : "COURBIAC", cp : "47370" },
{ code : "47073", intitule : "COURS", cp : "47360" },
{ code : "47074", intitule : "COUTHURES SUR GARONNE", cp : "47180" },
{ code : "47075", intitule : "LA CROIX BLANCHE", cp : "47340" },
{ code : "47076", intitule : "CUQ", cp : "47220" },
{ code : "47077", intitule : "CUZORN", cp : "47500" },
{ code : "47078", intitule : "DAMAZAN", cp : "47160" },
{ code : "47079", intitule : "DAUSSE", cp : "47140" },
{ code : "47080", intitule : "DEVILLAC", cp : "47210" },
{ code : "47081", intitule : "DOLMAYRAC", cp : "47110" },
{ code : "47082", intitule : "DONDAS", cp : "47470" },
{ code : "47083", intitule : "DOUDRAC", cp : "47210" },
{ code : "47084", intitule : "DOUZAINS", cp : "47330" },
{ code : "47085", intitule : "DURANCE", cp : "47420" },
{ code : "47086", intitule : "DURAS", cp : "47120" },
{ code : "47087", intitule : "ENGAYRAC", cp : "47470" },
{ code : "47088", intitule : "ESCASSEFORT", cp : "47350" },
{ code : "47089", intitule : "ESCLOTTES", cp : "47120" },
{ code : "47090", intitule : "ESPIENS", cp : "47600" },
{ code : "47091", intitule : "ESTILLAC", cp : "47310" },
{ code : "47092", intitule : "FALS", cp : "47220" },
{ code : "47093", intitule : "FARGUES SUR OURBISE", cp : "47700" },
{ code : "47094", intitule : "FAUGUEROLLES", cp : "47400" },
{ code : "47095", intitule : "FAUILLET", cp : "47400" },
{ code : "47096", intitule : "FERRENSAC", cp : "47330" },
{ code : "47097", intitule : "FEUGAROLLES", cp : "47230" },
{ code : "47098", intitule : "FIEUX", cp : "47600" },
{ code : "47099", intitule : "FONGRAVE", cp : "47260" },
{ code : "47100", intitule : "FOULAYRONNES", cp : "47510" },
{ code : "47101", intitule : "FOURQUES SUR GARONNE", cp : "47200" },
{ code : "47102", intitule : "FRANCESCAS", cp : "47600" },
{ code : "47103", intitule : "FRECHOU", cp : "47600" },
{ code : "47104", intitule : "FREGIMONT", cp : "47360" },
{ code : "47105", intitule : "FRESPECH", cp : "47140" },
{ code : "47106", intitule : "FUMEL", cp : "47500" },
{ code : "47107", intitule : "GALAPIAN", cp : "47190" },
{ code : "47108", intitule : "GAUJAC", cp : "47200" },
{ code : "47109", intitule : "GAVAUDUN", cp : "47150" },
{ code : "47110", intitule : "GONTAUD DE NOGARET", cp : "47400" },
{ code : "47111", intitule : "GRANGES SUR LOT", cp : "47260" },
{ code : "47112", intitule : "GRATELOUP ST GAYRAND", cp : "47400" },
{ code : "47112", intitule : "GRATELOUP ST GAYRAND", cp : "47400" },
{ code : "47113", intitule : "GRAYSSAS", cp : "47270" },
{ code : "47114", intitule : "GREZET CAVAGNAN", cp : "47250" },
{ code : "47115", intitule : "GUERIN", cp : "47250" },
{ code : "47117", intitule : "HAUTEFAGE LA TOUR", cp : "47340" },
{ code : "47118", intitule : "HAUTESVIGNES", cp : "47400" },
{ code : "47119", intitule : "HOUEILLES", cp : "47420" },
{ code : "47120", intitule : "JUSIX", cp : "47180" },
{ code : "47121", intitule : "LABASTIDE CASTEL AMOUROUX", cp : "47250" },
{ code : "47122", intitule : "LABRETONIE", cp : "47350" },
{ code : "47123", intitule : "LACAPELLE BIRON", cp : "47150" },
{ code : "47124", intitule : "LACAUSSADE", cp : "47150" },
{ code : "47125", intitule : "LACEPEDE", cp : "47360" },
{ code : "47126", intitule : "LACHAPELLE", cp : "47350" },
{ code : "47127", intitule : "LAFITTE SUR LOT", cp : "47320" },
{ code : "47128", intitule : "LAFOX", cp : "47240" },
{ code : "47129", intitule : "LAGARRIGUE", cp : "47190" },
{ code : "47130", intitule : "LAGRUERE", cp : "47400" },
{ code : "47131", intitule : "LAGUPIE", cp : "47180" },
{ code : "47132", intitule : "LALANDUSSE", cp : "47330" },
{ code : "47133", intitule : "LAMONTJOIE", cp : "47310" },
{ code : "47134", intitule : "LANNES", cp : "47170" },
{ code : "47134", intitule : "LANNES", cp : "47170" },
{ code : "47135", intitule : "LAPARADE", cp : "47260" },
{ code : "47136", intitule : "LAPERCHE", cp : "47800" },
{ code : "47137", intitule : "LAPLUME", cp : "47310" },
{ code : "47138", intitule : "LAROQUE TIMBAUT", cp : "47340" },
{ code : "47139", intitule : "LASSERRE", cp : "47600" },
{ code : "47140", intitule : "LAUGNAC", cp : "47360" },
{ code : "47141", intitule : "LAUSSOU", cp : "47150" },
{ code : "47142", intitule : "LAUZUN", cp : "47410" },
{ code : "47143", intitule : "LAVARDAC", cp : "47230" },
{ code : "47144", intitule : "LAVERGNE", cp : "47800" },
{ code : "47145", intitule : "LAYRAC", cp : "47390" },
{ code : "47146", intitule : "LEDAT", cp : "47300" },
{ code : "47147", intitule : "LEVIGNAC DE GUYENNE", cp : "47120" },
{ code : "47148", intitule : "LEYRITZ MONCASSIN", cp : "47700" },
{ code : "47150", intitule : "LONGUEVILLE", cp : "47200" },
{ code : "47151", intitule : "LOUBES BERNAC", cp : "47120" },
{ code : "47152", intitule : "LOUGRATTE", cp : "47290" },
{ code : "47154", intitule : "LUSIGNAN PETIT", cp : "47360" },
{ code : "47155", intitule : "MADAILLAN", cp : "47360" },
{ code : "47156", intitule : "MARCELLUS", cp : "47200" },
{ code : "47157", intitule : "MARMANDE", cp : "47200" },
{ code : "47158", intitule : "MARMONT PACHAS", cp : "47220" },
{ code : "47159", intitule : "LE MAS D AGENAIS", cp : "47430" },
{ code : "47160", intitule : "MASQUIERES", cp : "47370" },
{ code : "47161", intitule : "MASSELS", cp : "47140" },
{ code : "47162", intitule : "MASSOULES", cp : "47140" },
{ code : "47163", intitule : "MAUVEZIN SUR GUPIE", cp : "47200" },
{ code : "47164", intitule : "MAZIERES NARESSE", cp : "47210" },
{ code : "47165", intitule : "MEILHAN SUR GARONNE", cp : "47180" },
{ code : "47167", intitule : "MEZIN", cp : "47170" },
{ code : "47168", intitule : "MIRAMONT DE GUYENNE", cp : "47800" },
{ code : "47169", intitule : "MOIRAX", cp : "47310" },
{ code : "47170", intitule : "MONBAHUS", cp : "47290" },
{ code : "47171", intitule : "MONBALEN", cp : "47340" },
{ code : "47172", intitule : "MONCAUT", cp : "47310" },
{ code : "47173", intitule : "MONCLAR", cp : "47380" },
{ code : "47174", intitule : "MONCRABEAU", cp : "47600" },
{ code : "47175", intitule : "MONFLANQUIN", cp : "47150" },
{ code : "47176", intitule : "MONGAILLARD", cp : "47230" },
{ code : "47177", intitule : "MONHEURT", cp : "47160" },
{ code : "47178", intitule : "MONSEGUR", cp : "47150" },
{ code : "47179", intitule : "MONSEMPRON LIBOS", cp : "47500" },
{ code : "47180", intitule : "MONTAGNAC SUR AUVIGNON", cp : "47600" },
{ code : "47181", intitule : "MONTAGNAC SUR LEDE", cp : "47150" },
{ code : "47182", intitule : "MONTASTRUC", cp : "47380" },
{ code : "47183", intitule : "MONTAURIOL", cp : "47330" },
{ code : "47184", intitule : "MONTAUT", cp : "47210" },
{ code : "47185", intitule : "MONTAYRAL", cp : "47500" },
{ code : "47186", intitule : "MONTESQUIEU", cp : "47130" },
{ code : "47187", intitule : "MONTETON", cp : "47120" },
{ code : "47188", intitule : "MONTIGNAC DE LAUZUN", cp : "47800" },
{ code : "47189", intitule : "MONTIGNAC TOUPINERIE", cp : "47350" },
{ code : "47190", intitule : "MONTPEZAT", cp : "47360" },
{ code : "47191", intitule : "MONTPOUILLAN", cp : "47200" },
{ code : "47192", intitule : "MONVIEL", cp : "47290" },
{ code : "47193", intitule : "MOULINET", cp : "47290" },
{ code : "47194", intitule : "MOUSTIER", cp : "47800" },
{ code : "47195", intitule : "NERAC", cp : "47600" },
{ code : "47196", intitule : "NICOLE", cp : "47190" },
{ code : "47197", intitule : "NOMDIEU", cp : "47600" },
{ code : "47198", intitule : "PAILLOLES", cp : "47440" },
{ code : "47199", intitule : "PARDAILLAN", cp : "47120" },
{ code : "47200", intitule : "PARRANQUET", cp : "47210" },
{ code : "47201", intitule : "LE PASSAGE", cp : "47520" },
{ code : "47202", intitule : "PAULHIAC", cp : "47150" },
{ code : "47203", intitule : "PENNE D AGENAIS", cp : "47140" },
{ code : "47204", intitule : "PEYRIERE", cp : "47350" },
{ code : "47205", intitule : "PINDERES", cp : "47700" },
{ code : "47206", intitule : "PINEL HAUTERIVE", cp : "47380" },
{ code : "47206", intitule : "PINEL HAUTERIVE", cp : "47380" },
{ code : "47207", intitule : "POMPIEY", cp : "47230" },
{ code : "47208", intitule : "POMPOGNE", cp : "47420" },
{ code : "47209", intitule : "PONT DU CASSE", cp : "47480" },
{ code : "47210", intitule : "PORT STE MARIE", cp : "47130" },
{ code : "47211", intitule : "POUDENAS", cp : "47170" },
{ code : "47212", intitule : "POUSSIGNAC", cp : "47700" },
{ code : "47213", intitule : "PRAYSSAS", cp : "47360" },
{ code : "47214", intitule : "PUCH D AGENAIS", cp : "47160" },
{ code : "47215", intitule : "PUJOLS", cp : "47300" },
{ code : "47216", intitule : "PUYMICLAN", cp : "47350" },
{ code : "47217", intitule : "PUYMIROL", cp : "47270" },
{ code : "47218", intitule : "PUYSSERAMPION", cp : "47800" },
{ code : "47219", intitule : "RAYET", cp : "47210" },
{ code : "47220", intitule : "RAZIMET", cp : "47160" },
{ code : "47221", intitule : "REAUP LISSE", cp : "47170" },
{ code : "47221", intitule : "REAUP LISSE", cp : "47170" },
{ code : "47222", intitule : "LA REUNION", cp : "47700" },
{ code : "47223", intitule : "RIVES", cp : "47210" },
{ code : "47224", intitule : "ROMESTAING", cp : "47250" },
{ code : "47225", intitule : "ROQUEFORT", cp : "47310" },
{ code : "47226", intitule : "ROUMAGNE", cp : "47800" },
{ code : "47227", intitule : "RUFFIAC", cp : "47700" },
{ code : "47228", intitule : "ST ANTOINE DE FICALBA", cp : "47340" },
{ code : "47229", intitule : "ST ASTIER", cp : "47120" },
{ code : "47230", intitule : "ST AUBIN", cp : "47150" },
{ code : "47231", intitule : "ST AVIT", cp : "47350" },
{ code : "47232", intitule : "ST BARTHELEMY D AGENAIS", cp : "47350" },
{ code : "47233", intitule : "STE BAZEILLE", cp : "47180" },
{ code : "47234", intitule : "ST CAPRAIS DE LERM", cp : "47270" },
{ code : "47235", intitule : "ST COLOMB DE LAUZUN", cp : "47410" },
{ code : "47236", intitule : "STE COLOMBE DE DURAS", cp : "47120" },
{ code : "47237", intitule : "STE COLOMBE DE VILLENEUVE", cp : "47300" },
{ code : "47238", intitule : "STE COLOMBE EN BRUILHOIS", cp : "47310" },
{ code : "47239", intitule : "ST ETIENNE DE FOUGERES", cp : "47380" },
{ code : "47240", intitule : "ST ETIENNE DE VILLEREAL", cp : "47210" },
{ code : "47241", intitule : "ST EUTROPE DE BORN", cp : "47210" },
{ code : "47242", intitule : "ST FRONT SUR LEMANCE", cp : "47500" },
{ code : "47244", intitule : "STE GEMME MARTAILLAC", cp : "47250" },
{ code : "47245", intitule : "ST GERAUD", cp : "47120" },
{ code : "47246", intitule : "ST HILAIRE DE LUSIGNAN", cp : "47450" },
{ code : "47247", intitule : "ST JEAN DE DURAS", cp : "47120" },
{ code : "47248", intitule : "ST JEAN DE THURAC", cp : "47270" },
{ code : "47249", intitule : "ST LAURENT", cp : "47130" },
{ code : "47250", intitule : "ST LEGER", cp : "47160" },
{ code : "47251", intitule : "ST LEON", cp : "47160" },
{ code : "47252", intitule : "STE LIVRADE SUR LOT", cp : "47110" },
{ code : "47253", intitule : "STE MARTHE", cp : "47430" },
{ code : "47254", intitule : "ST MARTIN CURTON", cp : "47700" },
{ code : "47255", intitule : "ST MARTIN DE BEAUVILLE", cp : "47270" },
{ code : "47256", intitule : "ST MARTIN DE VILLEREAL", cp : "47210" },
{ code : "47257", intitule : "ST MARTIN PETIT", cp : "47180" },
{ code : "47258", intitule : "STE MAURE DE PEYRIAC", cp : "47170" },
{ code : "47259", intitule : "ST MAURICE DE LESTAPEL", cp : "47290" },
{ code : "47260", intitule : "ST MAURIN", cp : "47270" },
{ code : "47262", intitule : "ST NICOLAS DE LA BALERME", cp : "47220" },
{ code : "47263", intitule : "ST PARDOUX DU BREUIL", cp : "47200" },
{ code : "47264", intitule : "ST PARDOUX ISAAC", cp : "47800" },
{ code : "47265", intitule : "ST PASTOUR", cp : "47290" },
{ code : "47266", intitule : "ST PE ST SIMON", cp : "47170" },
{ code : "47267", intitule : "ST PIERRE DE BUZET", cp : "47160" },
{ code : "47269", intitule : "ST PIERRE DE CLAIRAC", cp : "47270" },
{ code : "47271", intitule : "ST PIERRE SUR DROPT", cp : "47120" },
{ code : "47272", intitule : "ST QUENTIN DU DROPT", cp : "47330" },
{ code : "47273", intitule : "ST ROBERT", cp : "47340" },
{ code : "47274", intitule : "ST ROMAIN LE NOBLE", cp : "47270" },
{ code : "47275", intitule : "ST SALVY", cp : "47360" },
{ code : "47276", intitule : "ST SARDOS", cp : "47360" },
{ code : "47277", intitule : "ST SAUVEUR DE MEILHAN", cp : "47180" },
{ code : "47278", intitule : "ST SERNIN", cp : "47120" },
{ code : "47279", intitule : "ST SIXTE", cp : "47220" },
{ code : "47280", intitule : "ST SYLVESTRE SUR LOT", cp : "47140" },
{ code : "47281", intitule : "ST URCISSE", cp : "47270" },
{ code : "47282", intitule : "ST VINCENT DE LAMONTJOIE", cp : "47310" },
{ code : "47283", intitule : "ST VITE", cp : "47500" },
{ code : "47284", intitule : "SALLES", cp : "47150" },
{ code : "47285", intitule : "SAMAZAN", cp : "47250" },
{ code : "47286", intitule : "SAUMEJAN", cp : "47420" },
{ code : "47287", intitule : "SAUMONT", cp : "47600" },
{ code : "47288", intitule : "SAUVAGNAS", cp : "47340" },
{ code : "47289", intitule : "LA SAUVETAT DE SAVERES", cp : "47270" },
{ code : "47290", intitule : "LA SAUVETAT DU DROPT", cp : "47800" },
{ code : "47291", intitule : "LA SAUVETAT SUR LEDE", cp : "47150" },
{ code : "47292", intitule : "SAUVETERRE LA LEMANCE", cp : "47500" },
{ code : "47293", intitule : "SAUVETERRE ST DENIS", cp : "47220" },
{ code : "47294", intitule : "SAVIGNAC DE DURAS", cp : "47120" },
{ code : "47295", intitule : "SAVIGNAC SUR LEYZE", cp : "47150" },
{ code : "47296", intitule : "SEGALAS", cp : "47410" },
{ code : "47297", intitule : "SEMBAS", cp : "47360" },
{ code : "47298", intitule : "SENESTIS", cp : "47430" },
{ code : "47299", intitule : "SERIGNAC PEBOUDOU", cp : "47410" },
{ code : "47300", intitule : "SERIGNAC SUR GARONNE", cp : "47310" },
{ code : "47301", intitule : "SEYCHES", cp : "47350" },
{ code : "47302", intitule : "SOS", cp : "47170" },
{ code : "47302", intitule : "SOS", cp : "47170" },
{ code : "47302", intitule : "SOS", cp : "47170" },
{ code : "47303", intitule : "SOUMENSAC", cp : "47120" },
{ code : "47304", intitule : "TAILLEBOURG", cp : "47200" },
{ code : "47305", intitule : "TAYRAC", cp : "47270" },
{ code : "47306", intitule : "LE TEMPLE SUR LOT", cp : "47110" },
{ code : "47307", intitule : "THEZAC", cp : "47370" },
{ code : "47308", intitule : "THOUARS SUR GARONNE", cp : "47230" },
{ code : "47309", intitule : "TOMBEBOEUF", cp : "47380" },
{ code : "47310", intitule : "TONNEINS", cp : "47400" },
{ code : "47311", intitule : "TOURLIAC", cp : "47210" },
{ code : "47312", intitule : "TOURNON D AGENAIS", cp : "47370" },
{ code : "47313", intitule : "TOURTRES", cp : "47380" },
{ code : "47314", intitule : "TREMONS", cp : "47140" },
{ code : "47315", intitule : "TRENTELS", cp : "47140" },
{ code : "47316", intitule : "VARES", cp : "47400" },
{ code : "47317", intitule : "VERTEUIL D AGENAIS", cp : "47260" },
{ code : "47318", intitule : "VIANNE", cp : "47230" },
{ code : "47319", intitule : "VILLEBRAMAR", cp : "47380" },
{ code : "47320", intitule : "VILLEFRANCHE DU QUEYRAN", cp : "47160" },
{ code : "47321", intitule : "VILLENEUVE DE DURAS", cp : "47120" },
{ code : "47323", intitule : "VILLENEUVE SUR LOT", cp : "47300" },
{ code : "47324", intitule : "VILLEREAL", cp : "47210" },
{ code : "47325", intitule : "VILLETON", cp : "47400" },
{ code : "47326", intitule : "VIRAZEIL", cp : "47200" },
{ code : "47327", intitule : "XAINTRAILLES", cp : "47230" },
{ code : "47328", intitule : "ST GEORGES", cp : "47370" },
{ code : "48001", intitule : "ALBARET LE COMTAL", cp : "48310" },
{ code : "48002", intitule : "ALBARET STE MARIE", cp : "48200" },
{ code : "48002", intitule : "ALBARET STE MARIE", cp : "48200" },
{ code : "48003", intitule : "ALLENC", cp : "48190" },
{ code : "48004", intitule : "ALTIER", cp : "48800" },
{ code : "48005", intitule : "ANTRENAS", cp : "48100" },
{ code : "48007", intitule : "ARZENC D APCHER", cp : "48310" },
{ code : "48008", intitule : "ARZENC DE RANDON", cp : "48170" },
{ code : "48009", intitule : "AUMONT AUBRAC", cp : "48130" },
{ code : "48010", intitule : "AUROUX", cp : "48600" },
{ code : "48012", intitule : "LES MONTS VERTS", cp : "48200" },
{ code : "48012", intitule : "LES MONTS VERTS", cp : "48200" },
{ code : "48012", intitule : "LES MONTS VERTS", cp : "48200" },
{ code : "48013", intitule : "BADAROUX", cp : "48000" },
{ code : "48014", intitule : "BAGNOLS LES BAINS", cp : "48190" },
{ code : "48015", intitule : "PIED DE BORNE", cp : "48800" },
{ code : "48016", intitule : "BALSIEGES", cp : "48000" },
{ code : "48017", intitule : "BANASSAC CANILHAC", cp : "48500" },
{ code : "48017", intitule : "BANASSAC CANILHAC", cp : "48500" },
{ code : "48018", intitule : "BARJAC", cp : "48000" },
{ code : "48019", intitule : "BARRE DES CEVENNES", cp : "48400" },
{ code : "48020", intitule : "BASSURELS", cp : "48400" },
{ code : "48021", intitule : "LA BASTIDE PUYLAURENT", cp : "48250" },
{ code : "48023", intitule : "BELVEZET", cp : "48170" },
{ code : "48025", intitule : "LES BESSONS", cp : "48200" },
{ code : "48026", intitule : "BLAVIGNAC", cp : "48200" },
{ code : "48027", intitule : "LE BLEYMARD", cp : "48190" },
{ code : "48028", intitule : "LES BONDONS", cp : "48400" },
{ code : "48029", intitule : "LE BORN", cp : "48000" },
{ code : "48030", intitule : "BRENOUX", cp : "48000" },
{ code : "48031", intitule : "BRION", cp : "48310" },
{ code : "48031", intitule : "BRION", cp : "48310" },
{ code : "48032", intitule : "LE BUISSON", cp : "48100" },
{ code : "48034", intitule : "LA CANOURGUE", cp : "48500" },
{ code : "48034", intitule : "LA CANOURGUE", cp : "48500" },
{ code : "48034", intitule : "LA CANOURGUE", cp : "48500" },
{ code : "48034", intitule : "LA CANOURGUE", cp : "48500" },
{ code : "48036", intitule : "CASSAGNAS", cp : "48400" },
{ code : "48037", intitule : "CHADENET", cp : "48190" },
{ code : "48038", intitule : "CHAMBON LE CHATEAU", cp : "48600" },
{ code : "48039", intitule : "CHANAC", cp : "48230" },
{ code : "48039", intitule : "CHANAC", cp : "48230" },
{ code : "48040", intitule : "CHASSERADES", cp : "48250" },
{ code : "48041", intitule : "CHASTANIER", cp : "48300" },
{ code : "48042", intitule : "CHASTEL NOUVEL", cp : "48000" },
{ code : "48043", intitule : "CHATEAUNEUF DE RANDON", cp : "48170" },
{ code : "48044", intitule : "CHAUCHAILLES", cp : "48310" },
{ code : "48045", intitule : "CHAUDEYRAC", cp : "48170" },
{ code : "48046", intitule : "CHAULHAC", cp : "48140" },
{ code : "48047", intitule : "LA CHAZE DE PEYRE", cp : "48130" },
{ code : "48048", intitule : "CHEYLARD L EVEQUE", cp : "48300" },
{ code : "48050", intitule : "BEDOUES COCURES", cp : "48400" },
{ code : "48050", intitule : "BEDOUES COCURES", cp : "48400" },
{ code : "48051", intitule : "LE COLLET DE DEZE", cp : "48160" },
{ code : "48053", intitule : "CUBIERES", cp : "48190" },
{ code : "48054", intitule : "CUBIERETTES", cp : "48190" },
{ code : "48055", intitule : "CULTURES", cp : "48230" },
{ code : "48056", intitule : "ESCLANEDES", cp : "48230" },
{ code : "48057", intitule : "ESTABLES", cp : "48700" },
{ code : "48058", intitule : "LA FAGE MONTIVERNOUX", cp : "48310" },
{ code : "48059", intitule : "LA FAGE ST JULIEN", cp : "48200" },
{ code : "48060", intitule : "FAU DE PEYRE", cp : "48130" },
{ code : "48061", intitule : "FLORAC TROIS RIVIERES", cp : "48400" },
{ code : "48061", intitule : "FLORAC TROIS RIVIERES", cp : "48400" },
{ code : "48063", intitule : "FONTANS", cp : "48700" },
{ code : "48064", intitule : "FOURNELS", cp : "48310" },
{ code : "48065", intitule : "FRAISSINET DE FOURQUES", cp : "48400" },
{ code : "48067", intitule : "GABRIAC", cp : "48110" },
{ code : "48068", intitule : "GABRIAS", cp : "48100" },
{ code : "48069", intitule : "GATUZIERES", cp : "48150" },
{ code : "48070", intitule : "GRANDRIEU", cp : "48600" },
{ code : "48071", intitule : "GRANDVALS", cp : "48260" },
{ code : "48072", intitule : "GREZES", cp : "48100" },
{ code : "48073", intitule : "LES HERMAUX", cp : "48340" },
{ code : "48074", intitule : "HURES LA PARADE", cp : "48150" },
{ code : "48075", intitule : "ISPAGNAC", cp : "48320" },
{ code : "48076", intitule : "JAVOLS", cp : "48130" },
{ code : "48077", intitule : "JULIANGES", cp : "48140" },
{ code : "48078", intitule : "LACHAMP", cp : "48100" },
{ code : "48079", intitule : "LAJO", cp : "48120" },
{ code : "48080", intitule : "LANGOGNE", cp : "48300" },
{ code : "48081", intitule : "LANUEJOLS", cp : "48000" },
{ code : "48082", intitule : "LAUBERT", cp : "48170" },
{ code : "48083", intitule : "LES LAUBIES", cp : "48700" },
{ code : "48084", intitule : "LAVAL ATGER", cp : "48600" },
{ code : "48085", intitule : "LAVAL DU TARN", cp : "48500" },
{ code : "48086", intitule : "LUC", cp : "48250" },
{ code : "48087", intitule : "MALBOUZON", cp : "48270" },
{ code : "48088", intitule : "LA MALENE", cp : "48210" },
{ code : "48089", intitule : "LE MALZIEU FORAIN", cp : "48140" },
{ code : "48090", intitule : "LE MALZIEU VILLE", cp : "48140" },
{ code : "48091", intitule : "MARCHASTEL", cp : "48260" },
{ code : "48092", intitule : "MARVEJOLS", cp : "48100" },
{ code : "48093", intitule : "MAS D ORCIERES", cp : "48190" },
{ code : "48094", intitule : "LE MASSEGROS", cp : "48500" },
{ code : "48095", intitule : "MENDE", cp : "48000" },
{ code : "48096", intitule : "MEYRUEIS", cp : "48150" },
{ code : "48097", intitule : "MOISSAC VALLEE FRANCAISE", cp : "48110" },
{ code : "48098", intitule : "MOLEZON", cp : "48110" },
{ code : "48099", intitule : "BOURGS SUR COLAGNE", cp : "48100" },
{ code : "48099", intitule : "BOURGS SUR COLAGNE", cp : "48100" },
{ code : "48099", intitule : "BOURGS SUR COLAGNE", cp : "48100" },
{ code : "48100", intitule : "MONTBEL", cp : "48170" },
{ code : "48101", intitule : "MONTBRUN", cp : "48210" },
{ code : "48103", intitule : "MONTRODAT", cp : "48100" },
{ code : "48104", intitule : "NASBINALS", cp : "48260" },
{ code : "48105", intitule : "NAUSSAC FONTANES", cp : "48300" },
{ code : "48105", intitule : "NAUSSAC FONTANES", cp : "48300" },
{ code : "48106", intitule : "NOALHAC", cp : "48310" },
{ code : "48107", intitule : "PALHERS", cp : "48100" },
{ code : "48108", intitule : "LA PANOUSE", cp : "48600" },
{ code : "48110", intitule : "PAULHAC EN MARGERIDE", cp : "48140" },
{ code : "48111", intitule : "PELOUSE", cp : "48000" },
{ code : "48112", intitule : "PIERREFICHE", cp : "48300" },
{ code : "48115", intitule : "LE POMPIDOU", cp : "48110" },
{ code : "48116", intitule : "PONT DE MONTVERT SUD MONT LOZERE", cp : "48220" },
{ code : "48116", intitule : "PONT DE MONTVERT SUD MONT LOZERE", cp : "48220" },
{ code : "48116", intitule : "PONT DE MONTVERT SUD MONT LOZERE", cp : "48220" },
{ code : "48117", intitule : "POURCHARESSES", cp : "48800" },
{ code : "48119", intitule : "PREVENCHERES", cp : "48800" },
{ code : "48120", intitule : "PRINSUEJOLS", cp : "48100" },
{ code : "48121", intitule : "PRUNIERES", cp : "48200" },
{ code : "48122", intitule : "QUEZAC", cp : "48320" },
{ code : "48122", intitule : "QUEZAC", cp : "48320" },
{ code : "48123", intitule : "RECOULES D AUBRAC", cp : "48260" },
{ code : "48124", intitule : "RECOULES DE FUMAS", cp : "48100" },
{ code : "48125", intitule : "LE RECOUX", cp : "48500" },
{ code : "48126", intitule : "RIBENNES", cp : "48700" },
{ code : "48127", intitule : "RIEUTORT DE RANDON", cp : "48700" },
{ code : "48128", intitule : "RIMEIZE", cp : "48200" },
{ code : "48129", intitule : "ROCLES", cp : "48300" },
{ code : "48130", intitule : "ROUSSES", cp : "48400" },
{ code : "48131", intitule : "LE ROZIER", cp : "48150" },
{ code : "48132", intitule : "ST ALBAN SUR LIMAGNOLE", cp : "48120" },
{ code : "48133", intitule : "ST AMANS", cp : "48700" },
{ code : "48135", intitule : "ST ANDRE CAPCEZE", cp : "48800" },
{ code : "48136", intitule : "ST ANDRE DE LANCIZE", cp : "48240" },
{ code : "48137", intitule : "ST BAUZILE", cp : "48000" },
{ code : "48138", intitule : "ST BONNET DE CHIRAC", cp : "48100" },
{ code : "48139", intitule : "ST BONNET DE MONTAUROUX", cp : "48600" },
{ code : "48139", intitule : "ST BONNET DE MONTAUROUX", cp : "48600" },
{ code : "48140", intitule : "ST CHELY D APCHER", cp : "48200" },
{ code : "48141", intitule : "MAS ST CHELY", cp : "48210" },
{ code : "48142", intitule : "STE COLOMBE DE PEYRE", cp : "48130" },
{ code : "48144", intitule : "STE CROIX VALLEE FRANCAISE", cp : "48110" },
{ code : "48145", intitule : "ST DENIS EN MARGERIDE", cp : "48700" },
{ code : "48146", intitule : "STE ENIMIE", cp : "48210" },
{ code : "48146", intitule : "STE ENIMIE", cp : "48210" },
{ code : "48147", intitule : "ST ETIENNE DU VALDONNEZ", cp : "48000" },
{ code : "48148", intitule : "ST ETIENNE VALLEE FRANCAISE", cp : "48330" },
{ code : "48149", intitule : "STE EULALIE", cp : "48120" },
{ code : "48150", intitule : "ST FLOUR DE MERCOIRE", cp : "48300" },
{ code : "48151", intitule : "ST FREZAL D ALBUGES", cp : "48170" },
{ code : "48152", intitule : "VENTALON EN CEVENNES", cp : "48240" },
{ code : "48152", intitule : "VENTALON EN CEVENNES", cp : "48160" },
{ code : "48153", intitule : "ST GAL", cp : "48700" },
{ code : "48154", intitule : "ST GEORGES DE LEVEJAC", cp : "48500" },
{ code : "48155", intitule : "ST GERMAIN DE CALBERTE", cp : "48370" },
{ code : "48156", intitule : "ST GERMAIN DU TEIL", cp : "48340" },
{ code : "48157", intitule : "STE HELENE", cp : "48190" },
{ code : "48158", intitule : "ST HILAIRE DE LAVIT", cp : "48160" },
{ code : "48160", intitule : "ST JEAN LA FOUILLOUSE", cp : "48170" },
{ code : "48161", intitule : "ST JUERY", cp : "48310" },
{ code : "48162", intitule : "CANS ET CEVENNES", cp : "48400" },
{ code : "48162", intitule : "CANS ET CEVENNES", cp : "48400" },
{ code : "48163", intitule : "ST JULIEN DES POINTS", cp : "48160" },
{ code : "48164", intitule : "ST JULIEN DU TOURNEL", cp : "48190" },
{ code : "48165", intitule : "ST LAURENT DE MURET", cp : "48100" },
{ code : "48167", intitule : "ST LAURENT DE VEYRES", cp : "48310" },
{ code : "48168", intitule : "ST LEGER DE PEYRE", cp : "48100" },
{ code : "48169", intitule : "ST LEGER DU MALZIEU", cp : "48140" },
{ code : "48170", intitule : "ST MARTIN DE BOUBAUX", cp : "48160" },
{ code : "48171", intitule : "ST MARTIN DE LANSUSCLE", cp : "48110" },
{ code : "48173", intitule : "ST MICHEL DE DEZE", cp : "48160" },
{ code : "48174", intitule : "ST PAUL LE FROID", cp : "48600" },
{ code : "48175", intitule : "ST PIERRE DE NOGARET", cp : "48340" },
{ code : "48176", intitule : "ST PIERRE DES TRIPIERS", cp : "48150" },
{ code : "48177", intitule : "ST PIERRE LE VIEUX", cp : "48200" },
{ code : "48178", intitule : "ST PRIVAT DE VALLONGUE", cp : "48240" },
{ code : "48179", intitule : "ST PRIVAT DU FAU", cp : "48140" },
{ code : "48180", intitule : "ST ROME DE DOLAN", cp : "48500" },
{ code : "48181", intitule : "ST SATURNIN", cp : "48500" },
{ code : "48182", intitule : "ST SAUVEUR DE GINESTOUX", cp : "48170" },
{ code : "48183", intitule : "ST SAUVEUR DE PEYRE", cp : "48130" },
{ code : "48184", intitule : "ST SYMPHORIEN", cp : "48600" },
{ code : "48185", intitule : "LES SALELLES", cp : "48230" },
{ code : "48187", intitule : "LES SALCES", cp : "48100" },
{ code : "48188", intitule : "SERVERETTE", cp : "48700" },
{ code : "48189", intitule : "SERVIERES", cp : "48000" },
{ code : "48190", intitule : "TERMES", cp : "48310" },
{ code : "48191", intitule : "LA TIEULE", cp : "48500" },
{ code : "48192", intitule : "TRELANS", cp : "48340" },
{ code : "48193", intitule : "VEBRON", cp : "48400" },
{ code : "48193", intitule : "VEBRON", cp : "48400" },
{ code : "48194", intitule : "VIALAS", cp : "48220" },
{ code : "48195", intitule : "LES VIGNES", cp : "48210" },
{ code : "48197", intitule : "LA VILLEDIEU", cp : "48700" },
{ code : "48198", intitule : "VILLEFORT", cp : "48800" },
{ code : "48198", intitule : "VILLEFORT", cp : "48800" },
{ code : "49001", intitule : "LES ALLEUDS", cp : "49320" },
{ code : "49002", intitule : "ALLONNES", cp : "49650" },
{ code : "49003", intitule : "TUFFALUN", cp : "49700" },
{ code : "49003", intitule : "TUFFALUN", cp : "49700" },
{ code : "49003", intitule : "TUFFALUN", cp : "49700" },
{ code : "49007", intitule : "ANGERS", cp : "49000" },
{ code : "49007", intitule : "ANGERS", cp : "49100" },
{ code : "49008", intitule : "ANGRIE", cp : "49440" },
{ code : "49009", intitule : "ANTOIGNE", cp : "49260" },
{ code : "49010", intitule : "ARMAILLE", cp : "49420" },
{ code : "49011", intitule : "ARTANNES SUR THOUET", cp : "49260" },
{ code : "49012", intitule : "AUBIGNE SUR LAYON", cp : "49540" },
{ code : "49013", intitule : "AUVERSE", cp : "49490" },
{ code : "49014", intitule : "AVIRE", cp : "49500" },
{ code : "49015", intitule : "AVRILLE", cp : "49240" },
{ code : "49017", intitule : "BARACE", cp : "49430" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49018", intitule : "BAUGE EN ANJOU", cp : "49150" },
{ code : "49020", intitule : "BEAUCOUZE", cp : "49070" },
{ code : "49021", intitule : "BEAUFORT EN ANJOU", cp : "49250" },
{ code : "49021", intitule : "BEAUFORT EN ANJOU", cp : "49250" },
{ code : "49022", intitule : "BEAULIEU SUR LAYON", cp : "49750" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49110" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49450" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49600" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49600" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49600" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49510" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49600" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49510" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49510" },
{ code : "49023", intitule : "BEAUPREAU EN MAUGES", cp : "49600" },
{ code : "49026", intitule : "BECON LES GRANITS", cp : "49370" },
{ code : "49027", intitule : "BEGROLLES EN MAUGES", cp : "49122" },
{ code : "49028", intitule : "BEHUARD", cp : "49170" },
{ code : "49029", intitule : "BLAISON ST SULPICE", cp : "49320" },
{ code : "49029", intitule : "BLAISON ST SULPICE", cp : "49320" },
{ code : "49029", intitule : "BLAISON ST SULPICE", cp : "49320" },
{ code : "49030", intitule : "BLOU", cp : "49160" },
{ code : "49035", intitule : "BOUCHEMAINE", cp : "49080" },
{ code : "49036", intitule : "BOUILLE MENARD", cp : "49520" },
{ code : "49037", intitule : "LE BOURG D IRE", cp : "49520" },
{ code : "49038", intitule : "BOURG L EVEQUE", cp : "49520" },
{ code : "49041", intitule : "BRAIN SUR ALLONNES", cp : "49650" },
{ code : "49044", intitule : "BREIL", cp : "49490" },
{ code : "49045", intitule : "LA BREILLE LES PINS", cp : "49390" },
{ code : "49046", intitule : "BREZE", cp : "49260" },
{ code : "49047", intitule : "BRIGNE", cp : "49700" },
{ code : "49048", intitule : "BRIOLLAY", cp : "49125" },
{ code : "49050", intitule : "BRISSAC QUINCE", cp : "49320" },
{ code : "49051", intitule : "BRISSARTHE", cp : "49330" },
{ code : "49052", intitule : "BROC", cp : "49490" },
{ code : "49053", intitule : "BROSSAY", cp : "49700" },
{ code : "49054", intitule : "CANDE", cp : "49440" },
{ code : "49055", intitule : "CANTENAY EPINARD", cp : "49460" },
{ code : "49056", intitule : "CARBAY", cp : "49420" },
{ code : "49057", intitule : "CERNUSSON", cp : "49310" },
{ code : "49058", intitule : "LES CERQUEUX", cp : "49360" },
{ code : "49060", intitule : "CHACE", cp : "49400" },
{ code : "49061", intitule : "CHALLAIN LA POTHERIE", cp : "49440" },
{ code : "49062", intitule : "CHALONNES SOUS LE LUDE", cp : "49490" },
{ code : "49063", intitule : "CHALONNES SUR LOIRE", cp : "49290" },
{ code : "49064", intitule : "CHAMBELLAY", cp : "49220" },
{ code : "49065", intitule : "CHAMPIGNE", cp : "49330" },
{ code : "49067", intitule : "CHENILLE CHAMPTEUSSE", cp : "49220" },
{ code : "49067", intitule : "CHENILLE CHAMPTEUSSE", cp : "49220" },
{ code : "49068", intitule : "CHAMPTOCE SUR LOIRE", cp : "49123" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49530" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49530" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49530" },
{ code : "49069", intitule : "OREE D ANJOU", cp : "49270" },
{ code : "49070", intitule : "CHANTELOUP LES BOIS", cp : "49340" },
{ code : "49073", intitule : "LA CHAPELLE HULLIN", cp : "49420" },
{ code : "49076", intitule : "LA CHAPELLE ST LAUD", cp : "49140" },
{ code : "49077", intitule : "LA CHAPELLE SUR OUDON", cp : "49500" },
{ code : "49078", intitule : "CHARCE ST ELLIER SUR AUBANCE", cp : "49320" },
{ code : "49078", intitule : "CHARCE ST ELLIER SUR AUBANCE", cp : "49320" },
{ code : "49080", intitule : "CHATEAUNEUF SUR SARTHE", cp : "49330" },
{ code : "49081", intitule : "CHATELAIS", cp : "49520" },
{ code : "49082", intitule : "CHAUDEFONDS SUR LAYON", cp : "49290" },
{ code : "49086", intitule : "CHAVAGNES", cp : "49380" },
{ code : "49087", intitule : "CHAVAIGNES", cp : "49490" },
{ code : "49088", intitule : "CHAZE HENRY", cp : "49420" },
{ code : "49089", intitule : "CHAZE SUR ARGOS", cp : "49500" },
{ code : "49090", intitule : "CHEFFES", cp : "49125" },
{ code : "49091", intitule : "CHEMELLIER", cp : "49320" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49310" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49750" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49670" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49670" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49092", intitule : "CHEMILLE EN ANJOU", cp : "49120" },
{ code : "49096", intitule : "CHERRE", cp : "49330" },
{ code : "49098", intitule : "CHIGNE", cp : "49490" },
{ code : "49099", intitule : "CHOLET", cp : "49300" },
{ code : "49099", intitule : "CHOLET", cp : "49300" },
{ code : "49100", intitule : "CIZAY LA MADELEINE", cp : "49700" },
{ code : "49102", intitule : "CLERE SUR LAYON", cp : "49560" },
{ code : "49103", intitule : "COMBREE", cp : "49520" },
{ code : "49103", intitule : "COMBREE", cp : "49520" },
{ code : "49104", intitule : "CONCOURSON SUR LAYON", cp : "49700" },
{ code : "49105", intitule : "CONTIGNE", cp : "49330" },
{ code : "49107", intitule : "CORNILLE LES CAVES", cp : "49140" },
{ code : "49108", intitule : "LA CORNUAILLE", cp : "49440" },
{ code : "49109", intitule : "CORON", cp : "49690" },
{ code : "49110", intitule : "CORZE", cp : "49140" },
{ code : "49112", intitule : "LE COUDRAY MACOUARD", cp : "49260" },
{ code : "49113", intitule : "COURCHAMPS", cp : "49260" },
{ code : "49114", intitule : "COURLEON", cp : "49390" },
{ code : "49115", intitule : "COUTURES", cp : "49320" },
{ code : "49119", intitule : "DAUMERAY", cp : "49640" },
{ code : "49120", intitule : "DENEE", cp : "49190" },
{ code : "49121", intitule : "DENEZE SOUS DOUE", cp : "49700" },
{ code : "49122", intitule : "DENEZE SOUS LE LUDE", cp : "49490" },
{ code : "49123", intitule : "DISTRE", cp : "49400" },
{ code : "49125", intitule : "DOUE LA FONTAINE", cp : "49700" },
{ code : "49127", intitule : "DURTAL", cp : "49430" },
{ code : "49129", intitule : "ECOUFLANT", cp : "49000" },
{ code : "49130", intitule : "ECUILLE", cp : "49460" },
{ code : "49131", intitule : "EPIEDS", cp : "49260" },
{ code : "49132", intitule : "ETRICHE", cp : "49330" },
{ code : "49135", intitule : "FENEU", cp : "49460" },
{ code : "49136", intitule : "LA FERRIERE DE FLEE", cp : "49500" },
{ code : "49138", intitule : "LES BOIS D ANJOU", cp : "49250" },
{ code : "49138", intitule : "LES BOIS D ANJOU", cp : "49250" },
{ code : "49138", intitule : "LES BOIS D ANJOU", cp : "49250" },
{ code : "49140", intitule : "FONTEVRAUD L ABBAYE", cp : "49590" },
{ code : "49141", intitule : "FORGES", cp : "49700" },
{ code : "49144", intitule : "FREIGNE", cp : "49440" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49350" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49350" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49320" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49350" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49350" },
{ code : "49149", intitule : "GENNES VAL DE LOIRE", cp : "49350" },
{ code : "49150", intitule : "GENNETEIL", cp : "49490" },
{ code : "49155", intitule : "GREZ NEUVILLE", cp : "49220" },
{ code : "49156", intitule : "GRUGE L HOPITAL", cp : "49520" },
{ code : "49158", intitule : "L HOTELLERIE DE FLEE", cp : "49500" },
{ code : "49159", intitule : "HUILLE", cp : "49430" },
{ code : "49160", intitule : "INGRANDES LE FRESNE SUR LOIRE", cp : "49123" },
{ code : "49160", intitule : "INGRANDES LE FRESNE SUR LOIRE", cp : "49123" },
{ code : "49161", intitule : "LA JAILLE YVON", cp : "49220" },
{ code : "49163", intitule : "JARZE VILLAGES", cp : "49140" },
{ code : "49163", intitule : "JARZE VILLAGES", cp : "49140" },
{ code : "49163", intitule : "JARZE VILLAGES", cp : "49140" },
{ code : "49163", intitule : "JARZE VILLAGES", cp : "49140" },
{ code : "49167", intitule : "JUIGNE SUR LOIRE", cp : "49610" },
{ code : "49170", intitule : "JUVARDEIL", cp : "49330" },
{ code : "49171", intitule : "LA LANDE CHASLES", cp : "49150" },
{ code : "49173", intitule : "LASSE", cp : "49490" },
{ code : "49174", intitule : "LEZIGNE", cp : "49430" },
{ code : "49175", intitule : "LINIERES BOUTON", cp : "49490" },
{ code : "49176", intitule : "LE LION D ANGERS", cp : "49220" },
{ code : "49176", intitule : "LE LION D ANGERS", cp : "49220" },
{ code : "49178", intitule : "LOIRE", cp : "49440" },
{ code : "49180", intitule : "LONGUE JUMELLES", cp : "49160" },
{ code : "49180", intitule : "LONGUE JUMELLES", cp : "49160" },
{ code : "49182", intitule : "LOURESSE ROCHEMENIER", cp : "49700" },
{ code : "49183", intitule : "LE LOUROUX BECONNAIS", cp : "49370" },
{ code : "49184", intitule : "LOUVAINES", cp : "49500" },
{ code : "49186", intitule : "LUIGNE", cp : "49320" },
{ code : "49187", intitule : "MARANS", cp : "49500" },
{ code : "49188", intitule : "MARCE", cp : "49140" },
{ code : "49189", intitule : "MARIGNE", cp : "49330" },
{ code : "49191", intitule : "MARTIGNE BRIAND", cp : "49540" },
{ code : "49192", intitule : "MAULEVRIER", cp : "49360" },
{ code : "49193", intitule : "LE MAY SUR EVRE", cp : "49122" },
{ code : "49194", intitule : "MAZE MILON", cp : "49140" },
{ code : "49194", intitule : "MAZE MILON", cp : "49630" },
{ code : "49195", intitule : "MAZIERES EN MAUGES", cp : "49280" },
{ code : "49197", intitule : "MEIGNE LE VICOMTE", cp : "49490" },
{ code : "49198", intitule : "MEIGNE", cp : "49700" },
{ code : "49200", intitule : "LONGUENEE EN ANJOU", cp : "49220" },
{ code : "49200", intitule : "LONGUENEE EN ANJOU", cp : "49770" },
{ code : "49200", intitule : "LONGUENEE EN ANJOU", cp : "49770" },
{ code : "49200", intitule : "LONGUENEE EN ANJOU", cp : "49770" },
{ code : "49201", intitule : "LA MENITRE", cp : "49250" },
{ code : "49202", intitule : "MEON", cp : "49490" },
{ code : "49205", intitule : "MIRE", cp : "49330" },
{ code : "49207", intitule : "MONTFORT", cp : "49700" },
{ code : "49208", intitule : "MONTGUILLON", cp : "49500" },
{ code : "49209", intitule : "MONTIGNE LES RAIRIES", cp : "49430" },
{ code : "49211", intitule : "MONTILLIERS", cp : "49310" },
{ code : "49214", intitule : "MONTREUIL JUIGNE", cp : "49460" },
{ code : "49214", intitule : "MONTREUIL JUIGNE", cp : "49460" },
{ code : "49215", intitule : "MONTREUIL BELLAY", cp : "49260" },
{ code : "49215", intitule : "MONTREUIL BELLAY", cp : "49260" },
{ code : "49216", intitule : "MONTREUIL SUR LOIR", cp : "49140" },
{ code : "49217", intitule : "MONTREUIL SUR MAINE", cp : "49220" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49600" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49600" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49600" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49110" },
{ code : "49218", intitule : "MONTREVAULT SUR EVRE", cp : "49270" },
{ code : "49219", intitule : "MONTSOREAU", cp : "49730" },
{ code : "49220", intitule : "MORANNES SUR SARTHE", cp : "49640" },
{ code : "49220", intitule : "MORANNES SUR SARTHE", cp : "49640" },
{ code : "49221", intitule : "MOULIHERNE", cp : "49390" },
{ code : "49222", intitule : "MOZE SUR LOUET", cp : "49610" },
{ code : "49223", intitule : "MURS ERIGNE", cp : "49610" },
{ code : "49224", intitule : "NEUILLE", cp : "49680" },
{ code : "49226", intitule : "NOELLET", cp : "49520" },
{ code : "49227", intitule : "NOTRE DAME D ALLENCON", cp : "49380" },
{ code : "49228", intitule : "NOYANT", cp : "49490" },
{ code : "49229", intitule : "NOYANT LA GRAVOYERE", cp : "49520" },
{ code : "49231", intitule : "NUAILLE", cp : "49340" },
{ code : "49233", intitule : "NYOISEAU", cp : "49500" },
{ code : "49234", intitule : "PARCAY LES PINS", cp : "49390" },
{ code : "49235", intitule : "PARNAY", cp : "49730" },
{ code : "49236", intitule : "PASSAVANT SUR LAYON", cp : "49560" },
{ code : "49237", intitule : "LA PELLERINE", cp : "49490" },
{ code : "49240", intitule : "LA PLAINE", cp : "49360" },
{ code : "49241", intitule : "LE PLESSIS GRAMMOIRE", cp : "49124" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49290" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49570" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49620" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49110" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49290" },
{ code : "49244", intitule : "MAUGES SUR LOIRE", cp : "49410" },
{ code : "49246", intitule : "LES PONTS DE CE", cp : "49130" },
{ code : "49246", intitule : "LES PONTS DE CE", cp : "49130" },
{ code : "49247", intitule : "LA POSSONNIERE", cp : "49170" },
{ code : "49248", intitule : "POUANCE", cp : "49420" },
{ code : "49250", intitule : "LA PREVIERE", cp : "49420" },
{ code : "49253", intitule : "LE PUY NOTRE DAME", cp : "49260" },
{ code : "49254", intitule : "QUERRE", cp : "49330" },
{ code : "49257", intitule : "LES RAIRIES", cp : "49430" },
{ code : "49259", intitule : "ROCHEFORT SUR LOIRE", cp : "49190" },
{ code : "49260", intitule : "LA ROMAGNE", cp : "49740" },
{ code : "49261", intitule : "LES ROSIERS SUR LOIRE", cp : "49350" },
{ code : "49262", intitule : "ROU MARSON", cp : "49400" },
{ code : "49266", intitule : "ST AUGUSTIN DES BOIS", cp : "49170" },
{ code : "49267", intitule : "ST BARTHELEMY D ANJOU", cp : "49124" },
{ code : "49269", intitule : "ST CHRISTOPHE DU BOIS", cp : "49280" },
{ code : "49271", intitule : "ST CLEMENT DE LA PLACE", cp : "49370" },
{ code : "49272", intitule : "ST CLEMENT DES LEVEES", cp : "49350" },
{ code : "49274", intitule : "ST CYR EN BOURG", cp : "49260" },
{ code : "49277", intitule : "STE GEMMES D ANDIGNE", cp : "49500" },
{ code : "49278", intitule : "STE GEMMES SUR LOIRE", cp : "49130" },
{ code : "49282", intitule : "ST GEORGES SUR LAYON", cp : "49700" },
{ code : "49283", intitule : "ST GEORGES SUR LOIRE", cp : "49170" },
{ code : "49284", intitule : "ST GERMAIN DES PRES", cp : "49170" },
{ code : "49288", intitule : "ST JEAN DE LA CROIX", cp : "49130" },
{ code : "49289", intitule : "ST JEAN DE LINIERES", cp : "49070" },
{ code : "49290", intitule : "ST JEAN DES MAUVRETS", cp : "49320" },
{ code : "49291", intitule : "ST JUST SUR DIVE", cp : "49260" },
{ code : "49292", intitule : "VAL DU LAYON", cp : "49750" },
{ code : "49292", intitule : "VAL DU LAYON", cp : "49190" },
{ code : "49294", intitule : "ST LAMBERT LA POTHERIE", cp : "49070" },
{ code : "49298", intitule : "ST LEGER DES BOIS", cp : "49170" },
{ code : "49299", intitule : "ST LEGER SOUS CHOLET", cp : "49280" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49230" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49230" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49230" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49230" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49450" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49450" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49710" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49230" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49450" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49450" },
{ code : "49301", intitule : "SEVREMOINE", cp : "49660" },
{ code : "49302", intitule : "ST MACAIRE DU BOIS", cp : "49260" },
{ code : "49304", intitule : "ST MARTIN DE LA PLACE", cp : "49160" },
{ code : "49305", intitule : "ST MARTIN DU BOIS", cp : "49500" },
{ code : "49306", intitule : "ST MARTIN DU FOUILLOUX", cp : "49170" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49800" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49250" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49800" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49800" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49140" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49630" },
{ code : "49307", intitule : "LOIRE AUTHION", cp : "49800" },
{ code : "49308", intitule : "ST MELAINE SUR AUBANCE", cp : "49610" },
{ code : "49309", intitule : "ST MICHEL ET CHANVEAUX", cp : "49420" },
{ code : "49310", intitule : "ST PAUL DU BOIS", cp : "49310" },
{ code : "49311", intitule : "ST PHILBERT DU PEUPLE", cp : "49160" },
{ code : "49317", intitule : "ST REMY LA VARENNE", cp : "49250" },
{ code : "49318", intitule : "ST SATURNIN SUR LOIRE", cp : "49320" },
{ code : "49319", intitule : "ST SAUVEUR DE FLEE", cp : "49500" },
{ code : "49321", intitule : "ST SIGISMOND", cp : "49123" },
{ code : "49323", intitule : "VERRIERES EN ANJOU", cp : "49480" },
{ code : "49323", intitule : "VERRIERES EN ANJOU", cp : "49112" },
{ code : "49326", intitule : "SARRIGNE", cp : "49800" },
{ code : "49327", intitule : "SAULGE L HOPITAL", cp : "49320" },
{ code : "49328", intitule : "SAUMUR", cp : "49400" },
{ code : "49328", intitule : "SAUMUR", cp : "49400" },
{ code : "49328", intitule : "SAUMUR", cp : "49400" },
{ code : "49328", intitule : "SAUMUR", cp : "49400" },
{ code : "49328", intitule : "SAUMUR", cp : "49400" },
{ code : "49329", intitule : "SAVENNIERES", cp : "49170" },
{ code : "49329", intitule : "SAVENNIERES", cp : "49170" },
{ code : "49330", intitule : "SCEAUX D ANJOU", cp : "49330" },
{ code : "49331", intitule : "SEGRE", cp : "49500" },
{ code : "49332", intitule : "LA SEGUINIERE", cp : "49280" },
{ code : "49333", intitule : "SEICHES SUR LE LOIR", cp : "49140" },
{ code : "49333", intitule : "SEICHES SUR LE LOIR", cp : "49140" },
{ code : "49334", intitule : "SERMAISE", cp : "49140" },
{ code : "49335", intitule : "SOEURDRES", cp : "49330" },
{ code : "49336", intitule : "SOMLOIRE", cp : "49360" },
{ code : "49337", intitule : "SOUCELLES", cp : "49140" },
{ code : "49338", intitule : "SOULAINES SUR AUBANCE", cp : "49610" },
{ code : "49339", intitule : "SOULAIRE ET BOURG", cp : "49460" },
{ code : "49341", intitule : "SOUZAY CHAMPIGNY", cp : "49400" },
{ code : "49343", intitule : "LA TESSOUALLE", cp : "49280" },
{ code : "49344", intitule : "THORIGNE D ANJOU", cp : "49220" },
{ code : "49345", intitule : "BELLEVIGNE EN LAYON", cp : "49380" },
{ code : "49345", intitule : "BELLEVIGNE EN LAYON", cp : "49750" },
{ code : "49345", intitule : "BELLEVIGNE EN LAYON", cp : "49380" },
{ code : "49345", intitule : "BELLEVIGNE EN LAYON", cp : "49380" },
{ code : "49345", intitule : "BELLEVIGNE EN LAYON", cp : "49380" },
{ code : "49347", intitule : "TIERCE", cp : "49125" },
{ code : "49352", intitule : "TOUTLEMONDE", cp : "49360" },
{ code : "49353", intitule : "TRELAZE", cp : "49800" },
{ code : "49354", intitule : "LE TREMBLAY", cp : "49520" },
{ code : "49355", intitule : "TREMENTINES", cp : "49340" },
{ code : "49358", intitule : "TURQUANT", cp : "49730" },
{ code : "49359", intitule : "LES ULMES", cp : "49700" },
{ code : "49361", intitule : "VARENNES SUR LOIRE", cp : "49730" },
{ code : "49362", intitule : "VARRAINS", cp : "49400" },
{ code : "49363", intitule : "VAUCHRETIEN", cp : "49320" },
{ code : "49364", intitule : "VAUDELNAY", cp : "49260" },
{ code : "49365", intitule : "LES VERCHERS SUR LAYON", cp : "49700" },
{ code : "49366", intitule : "VERGONNES", cp : "49420" },
{ code : "49367", intitule : "ERDRE EN ANJOU", cp : "49220" },
{ code : "49367", intitule : "ERDRE EN ANJOU", cp : "49220" },
{ code : "49367", intitule : "ERDRE EN ANJOU", cp : "49370" },
{ code : "49367", intitule : "ERDRE EN ANJOU", cp : "49220" },
{ code : "49368", intitule : "VERNANTES", cp : "49390" },
{ code : "49369", intitule : "VERNOIL LE FOURRIER", cp : "49390" },
{ code : "49370", intitule : "VERRIE", cp : "49400" },
{ code : "49371", intitule : "VEZINS", cp : "49340" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49540" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49540" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49310" },
{ code : "49373", intitule : "LYS HAUT LAYON", cp : "49560" },
{ code : "49374", intitule : "VILLEBERNIER", cp : "49400" },
{ code : "49376", intitule : "VILLEMOISAN", cp : "49370" },
{ code : "49377", intitule : "VILLEVEQUE", cp : "49140" },
{ code : "49378", intitule : "VIVY", cp : "49680" },
{ code : "49381", intitule : "YZERNAY", cp : "49360" },
{ code : "50001", intitule : "ACQUEVILLE", cp : "50440" },
{ code : "50002", intitule : "AGNEAUX", cp : "50180" },
{ code : "50003", intitule : "AGON COUTAINVILLE", cp : "50230" },
{ code : "50004", intitule : "AIREL", cp : "50680" },
{ code : "50006", intitule : "AMIGNY", cp : "50620" },
{ code : "50007", intitule : "ANCTEVILLE", cp : "50200" },
{ code : "50008", intitule : "ANCTOVILLE SUR BOSCQ", cp : "50400" },
{ code : "50013", intitule : "ANNEVILLE EN SAIRE", cp : "50760" },
{ code : "50014", intitule : "ANNEVILLE SUR MER", cp : "50560" },
{ code : "50015", intitule : "ANNOVILLE", cp : "50660" },
{ code : "50016", intitule : "APPEVILLE", cp : "50500" },
{ code : "50018", intitule : "ARGOUGES", cp : "50240" },
{ code : "50019", intitule : "AUCEY LA PLAINE", cp : "50170" },
{ code : "50020", intitule : "AUDERVILLE", cp : "50440" },
{ code : "50021", intitule : "AUDOUVILLE LA HUBERT", cp : "50480" },
{ code : "50022", intitule : "AUMEVILLE LESTRE", cp : "50630" },
{ code : "50023", intitule : "AUVERS", cp : "50500" },
{ code : "50024", intitule : "AUXAIS", cp : "50500" },
{ code : "50025", intitule : "AVRANCHES", cp : "50300" },
{ code : "50026", intitule : "AZEVILLE", cp : "50310" },
{ code : "50027", intitule : "BACILLY", cp : "50530" },
{ code : "50028", intitule : "LA BALEINE", cp : "50450" },
{ code : "50029", intitule : "BARENTON", cp : "50720" },
{ code : "50030", intitule : "BARFLEUR", cp : "50760" },
{ code : "50031", intitule : "BARNEVILLE CARTERET", cp : "50270" },
{ code : "50032", intitule : "LA BARRE DE SEMILLY", cp : "50810" },
{ code : "50033", intitule : "BAUBIGNY", cp : "50270" },
{ code : "50034", intitule : "BAUDRE", cp : "50000" },
{ code : "50036", intitule : "BAUPTE", cp : "50500" },
{ code : "50037", intitule : "LA BAZOGE", cp : "50520" },
{ code : "50038", intitule : "BEAUCHAMPS", cp : "50320" },
{ code : "50039", intitule : "BEAUCOUDRAY", cp : "50420" },
{ code : "50040", intitule : "BEAUFICEL", cp : "50150" },
{ code : "50041", intitule : "BEAUMONT HAGUE", cp : "50440" },
{ code : "50042", intitule : "BEAUVOIR", cp : "50170" },
{ code : "50043", intitule : "BELLEFONTAINE", cp : "50520" },
{ code : "50044", intitule : "BELVAL", cp : "50210" },
{ code : "50045", intitule : "BENOITVILLE", cp : "50340" },
{ code : "50046", intitule : "BERIGNY", cp : "50810" },
{ code : "50048", intitule : "BESLON", cp : "50800" },
{ code : "50049", intitule : "BESNEVILLE", cp : "50390" },
{ code : "50050", intitule : "BEUVRIGNY", cp : "50420" },
{ code : "50052", intitule : "BEUZEVILLE LA BASTILLE", cp : "50360" },
{ code : "50054", intitule : "BIEVILLE", cp : "50160" },
{ code : "50055", intitule : "BINIVILLE", cp : "50390" },
{ code : "50057", intitule : "BIVILLE", cp : "50440" },
{ code : "50058", intitule : "BLAINVILLE SUR MER", cp : "50560" },
{ code : "50059", intitule : "BLOSVILLE", cp : "50480" },
{ code : "50060", intitule : "LA BLOUTIERE", cp : "50800" },
{ code : "50062", intitule : "BOISYVON", cp : "50800" },
{ code : "50064", intitule : "LA BONNEVILLE", cp : "50360" },
{ code : "50066", intitule : "JULLOUVILLE", cp : "50610" },
{ code : "50066", intitule : "JULLOUVILLE", cp : "50610" },
{ code : "50069", intitule : "BOURGUENOLLES", cp : "50800" },
{ code : "50070", intitule : "BOUTTEVILLE", cp : "50480" },
{ code : "50072", intitule : "BRAINVILLE", cp : "50200" },
{ code : "50073", intitule : "BRANVILLE HAGUE", cp : "50440" },
{ code : "50074", intitule : "BRECEY", cp : "50370" },
{ code : "50076", intitule : "BREHAL", cp : "50290" },
{ code : "50076", intitule : "BREHAL", cp : "50290" },
{ code : "50077", intitule : "BRETTEVILLE", cp : "50110" },
{ code : "50078", intitule : "BRETTEVILLE SUR AY", cp : "50430" },
{ code : "50079", intitule : "BREUVILLE", cp : "50260" },
{ code : "50080", intitule : "BREVANDS", cp : "50500" },
{ code : "50081", intitule : "BREVILLE SUR MER", cp : "50290" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50082", intitule : "BRICQUEBEC EN COTENTIN", cp : "50260" },
{ code : "50083", intitule : "BRICQUEBOSQ", cp : "50340" },
{ code : "50084", intitule : "BRICQUEVILLE LA BLOUETTE", cp : "50200" },
{ code : "50085", intitule : "BRICQUEVILLE SUR MER", cp : "50290" },
{ code : "50086", intitule : "BRILLEVAST", cp : "50330" },
{ code : "50087", intitule : "BRIX", cp : "50700" },
{ code : "50088", intitule : "BROUAINS", cp : "50150" },
{ code : "50089", intitule : "BRUCHEVILLE", cp : "50480" },
{ code : "50090", intitule : "BUAIS LES MONTS", cp : "50640" },
{ code : "50090", intitule : "BUAIS LES MONTS", cp : "50640" },
{ code : "50092", intitule : "CAMBERNON", cp : "50200" },
{ code : "50093", intitule : "CAMETOURS", cp : "50570" },
{ code : "50094", intitule : "CAMPROND", cp : "50210" },
{ code : "50095", intitule : "CANISY", cp : "50750" },
{ code : "50096", intitule : "CANTELOUP", cp : "50330" },
{ code : "50097", intitule : "CANVILLE LA ROCQUE", cp : "50580" },
{ code : "50098", intitule : "CARANTILLY", cp : "50570" },
{ code : "50099", intitule : "CARENTAN LES MARAIS", cp : "50500" },
{ code : "50099", intitule : "CARENTAN LES MARAIS", cp : "50480" },
{ code : "50099", intitule : "CARENTAN LES MARAIS", cp : "50500" },
{ code : "50099", intitule : "CARENTAN LES MARAIS", cp : "50480" },
{ code : "50100", intitule : "CARNET", cp : "50240" },
{ code : "50101", intitule : "CARNEVILLE", cp : "50330" },
{ code : "50102", intitule : "CAROLLES", cp : "50740" },
{ code : "50103", intitule : "CARQUEBUT", cp : "50480" },
{ code : "50105", intitule : "CATTEVILLE", cp : "50390" },
{ code : "50106", intitule : "CAVIGNY", cp : "50620" },
{ code : "50107", intitule : "CATZ", cp : "50500" },
{ code : "50108", intitule : "CEAUX", cp : "50220" },
{ code : "50109", intitule : "CERENCES", cp : "50510" },
{ code : "50110", intitule : "CERISY LA FORET", cp : "50680" },
{ code : "50111", intitule : "CERISY LA SALLE", cp : "50210" },
{ code : "50112", intitule : "LA CHAISE BAUDOUIN", cp : "50370" },
{ code : "50115", intitule : "LE GRIPPON", cp : "50320" },
{ code : "50115", intitule : "LE GRIPPON", cp : "50320" },
{ code : "50117", intitule : "CHAMPEAUX", cp : "50530" },
{ code : "50118", intitule : "CHAMPREPUS", cp : "50800" },
{ code : "50119", intitule : "LES CHAMPS DE LOSQUE", cp : "50620" },
{ code : "50120", intitule : "CHANTELOUP", cp : "50510" },
{ code : "50121", intitule : "LA CHAPELLE CECELIN", cp : "50800" },
{ code : "50124", intitule : "LA CHAPELLE UREE", cp : "50370" },
{ code : "50125", intitule : "CHASSEGUEY", cp : "50520" },
{ code : "50126", intitule : "CHAVOY", cp : "50870" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50130" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50100" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50460" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50120" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50110" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50130" },
{ code : "50129", intitule : "CHERBOURG EN COTENTIN", cp : "50470" },
{ code : "50130", intitule : "CHERENCE LE HERON", cp : "50800" },
{ code : "50131", intitule : "CHERENCE LE ROUSSEL", cp : "50520" },
{ code : "50135", intitule : "CLITOURPS", cp : "50330" },
{ code : "50137", intitule : "LA COLOMBE", cp : "50800" },
{ code : "50138", intitule : "COLOMBY", cp : "50700" },
{ code : "50139", intitule : "CONDE SUR VIRE", cp : "50420" },
{ code : "50139", intitule : "CONDE SUR VIRE", cp : "50890" },
{ code : "50140", intitule : "CONTRIERES", cp : "50660" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50142", intitule : "VICQ SUR MER", cp : "50330" },
{ code : "50143", intitule : "COUDEVILLE SUR MER", cp : "50290" },
{ code : "50144", intitule : "COULOUVRAY BOISBENATRE", cp : "50670" },
{ code : "50145", intitule : "COURCY", cp : "50200" },
{ code : "50146", intitule : "COURTILS", cp : "50220" },
{ code : "50147", intitule : "COUTANCES", cp : "50200" },
{ code : "50148", intitule : "COUVAINS", cp : "50680" },
{ code : "50149", intitule : "COUVILLE", cp : "50690" },
{ code : "50150", intitule : "CRASVILLE", cp : "50630" },
{ code : "50151", intitule : "CREANCES", cp : "50710" },
{ code : "50152", intitule : "LES CRESNAYS", cp : "50370" },
{ code : "50154", intitule : "LA CROIX AVRANCHIN", cp : "50240" },
{ code : "50155", intitule : "CROLLON", cp : "50220" },
{ code : "50156", intitule : "CROSVILLE SUR DOUVE", cp : "50360" },
{ code : "50158", intitule : "CUVES", cp : "50670" },
{ code : "50159", intitule : "DANGY", cp : "50750" },
{ code : "50160", intitule : "DENNEVILLE", cp : "50580" },
{ code : "50161", intitule : "LE DEZERT", cp : "50620" },
{ code : "50162", intitule : "DIGOSVILLE", cp : "50110" },
{ code : "50163", intitule : "DIGULLEVILLE", cp : "50440" },
{ code : "50164", intitule : "DOMJEAN", cp : "50420" },
{ code : "50165", intitule : "DONVILLE LES BAINS", cp : "50350" },
{ code : "50166", intitule : "DOVILLE", cp : "50250" },
{ code : "50167", intitule : "DRAGEY RONTHON", cp : "50530" },
{ code : "50167", intitule : "DRAGEY RONTHON", cp : "50530" },
{ code : "50168", intitule : "DUCEY LES CHERIS", cp : "50220" },
{ code : "50168", intitule : "DUCEY LES CHERIS", cp : "50220" },
{ code : "50169", intitule : "ECAUSSEVILLE", cp : "50310" },
{ code : "50171", intitule : "ECULLEVILLE", cp : "50440" },
{ code : "50172", intitule : "EMONDEVILLE", cp : "50310" },
{ code : "50174", intitule : "EQUILLY", cp : "50320" },
{ code : "50175", intitule : "EROUDEVILLE", cp : "50310" },
{ code : "50176", intitule : "L ETANG BERTRAND", cp : "50260" },
{ code : "50177", intitule : "ETIENVILLE", cp : "50360" },
{ code : "50178", intitule : "FERMANVILLE", cp : "50840" },
{ code : "50181", intitule : "FEUGERES", cp : "50190" },
{ code : "50182", intitule : "LA FEUILLIE", cp : "50190" },
{ code : "50183", intitule : "FIERVILLE LES MINES", cp : "50580" },
{ code : "50184", intitule : "FLAMANVILLE", cp : "50340" },
{ code : "50185", intitule : "FLEURY", cp : "50800" },
{ code : "50186", intitule : "FLOTTEMANVILLE", cp : "50700" },
{ code : "50187", intitule : "FLOTTEMANVILLE HAGUE", cp : "50690" },
{ code : "50188", intitule : "FOLLIGNY", cp : "50320" },
{ code : "50188", intitule : "FOLLIGNY", cp : "50320" },
{ code : "50188", intitule : "FOLLIGNY", cp : "50320" },
{ code : "50190", intitule : "FONTENAY SUR MER", cp : "50310" },
{ code : "50192", intitule : "FOURNEAUX", cp : "50420" },
{ code : "50193", intitule : "LE FRESNE PORET", cp : "50850" },
{ code : "50194", intitule : "FRESVILLE", cp : "50310" },
{ code : "50195", intitule : "GATHEMO", cp : "50150" },
{ code : "50196", intitule : "GATTEVILLE LE PHARE", cp : "50760" },
{ code : "50197", intitule : "GAVRAY", cp : "50450" },
{ code : "50197", intitule : "GAVRAY", cp : "50450" },
{ code : "50197", intitule : "GAVRAY", cp : "50450" },
{ code : "50198", intitule : "GEFFOSSES", cp : "50560" },
{ code : "50199", intitule : "GENETS", cp : "50530" },
{ code : "50200", intitule : "GER", cp : "50850" },
{ code : "50205", intitule : "LA GODEFROY", cp : "50300" },
{ code : "50206", intitule : "LA GOHANNIERE", cp : "50300" },
{ code : "50207", intitule : "GOLLEVILLE", cp : "50390" },
{ code : "50208", intitule : "GONFREVILLE", cp : "50190" },
{ code : "50209", intitule : "GONNEVILLE LE THEIL", cp : "50330" },
{ code : "50209", intitule : "GONNEVILLE LE THEIL", cp : "50330" },
{ code : "50210", intitule : "GORGES", cp : "50190" },
{ code : "50214", intitule : "GOUVETS", cp : "50420" },
{ code : "50215", intitule : "GOUVILLE SUR MER", cp : "50560" },
{ code : "50215", intitule : "GOUVILLE SUR MER", cp : "50200" },
{ code : "50216", intitule : "GRAIGNES MESNIL ANGOT", cp : "50620" },
{ code : "50216", intitule : "GRAIGNES MESNIL ANGOT", cp : "50620" },
{ code : "50217", intitule : "LE GRAND CELLAND", cp : "50370" },
{ code : "50218", intitule : "GRANVILLE", cp : "50400" },
{ code : "50218", intitule : "GRANVILLE", cp : "50400" },
{ code : "50219", intitule : "GRATOT", cp : "50200" },
{ code : "50220", intitule : "GREVILLE HAGUE", cp : "50440" },
{ code : "50221", intitule : "GRIMESNIL", cp : "50450" },
{ code : "50222", intitule : "GROSVILLE", cp : "50340" },
{ code : "50223", intitule : "GUEHEBERT", cp : "50210" },
{ code : "50225", intitule : "LE GUISLAIN", cp : "50410" },
{ code : "50227", intitule : "LE HAM", cp : "50310" },
{ code : "50228", intitule : "HAMBYE", cp : "50450" },
{ code : "50229", intitule : "HAMELIN", cp : "50730" },
{ code : "50230", intitule : "HARDINVAST", cp : "50690" },
{ code : "50231", intitule : "HAUTEVILLE SUR MER", cp : "50590" },
{ code : "50232", intitule : "HAUTEVILLE LA GUICHARD", cp : "50570" },
{ code : "50233", intitule : "HAUTTEVILLE BOCAGE", cp : "50390" },
{ code : "50234", intitule : "LA HAYE BELLEFOND", cp : "50410" },
{ code : "50235", intitule : "LA HAYE D ECTOT", cp : "50270" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50580" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50236", intitule : "LA HAYE", cp : "50250" },
{ code : "50237", intitule : "LA HAYE PESNEL", cp : "50320" },
{ code : "50238", intitule : "HEAUVILLE", cp : "50340" },
{ code : "50239", intitule : "THEREVAL", cp : "50180" },
{ code : "50239", intitule : "THEREVAL", cp : "50570" },
{ code : "50240", intitule : "HELLEVILLE", cp : "50340" },
{ code : "50241", intitule : "HEMEVEZ", cp : "50700" },
{ code : "50242", intitule : "HERQUEVILLE", cp : "50440" },
{ code : "50243", intitule : "HEUGUEVILLE SUR SIENNE", cp : "50200" },
{ code : "50244", intitule : "HERENGUERVILLE", cp : "50660" },
{ code : "50246", intitule : "HIESVILLE", cp : "50480" },
{ code : "50247", intitule : "HOCQUIGNY", cp : "50320" },
{ code : "50248", intitule : "LE HOMMET D ARTHENAY", cp : "50620" },
{ code : "50251", intitule : "HUBERVILLE", cp : "50700" },
{ code : "50252", intitule : "HUDIMESNIL", cp : "50510" },
{ code : "50253", intitule : "HUISNES SUR MER", cp : "50170" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50256", intitule : "ISIGNY LE BUAT", cp : "50540" },
{ code : "50257", intitule : "JOBOURG", cp : "50440" },
{ code : "50258", intitule : "JOGANVILLE", cp : "50310" },
{ code : "50259", intitule : "JUILLEY", cp : "50220" },
{ code : "50260", intitule : "JUVIGNY LE TERTRE", cp : "50520" },
{ code : "50261", intitule : "LAMBERVILLE", cp : "50160" },
{ code : "50262", intitule : "LA LANDE D AIROU", cp : "50800" },
{ code : "50263", intitule : "LAPENTY", cp : "50600" },
{ code : "50265", intitule : "LAULNE", cp : "50430" },
{ code : "50266", intitule : "LENGRONNE", cp : "50450" },
{ code : "50267", intitule : "LESSAY", cp : "50430" },
{ code : "50267", intitule : "LESSAY", cp : "50430" },
{ code : "50268", intitule : "LESTRE", cp : "50310" },
{ code : "50269", intitule : "LIESVILLE SUR DOUVE", cp : "50480" },
{ code : "50270", intitule : "LIEUSAINT", cp : "50700" },
{ code : "50271", intitule : "LINGEARD", cp : "50670" },
{ code : "50272", intitule : "LINGREVILLE", cp : "50660" },
{ code : "50273", intitule : "MONTSENELLE", cp : "50250" },
{ code : "50273", intitule : "MONTSENELLE", cp : "50250" },
{ code : "50273", intitule : "MONTSENELLE", cp : "50250" },
{ code : "50273", intitule : "MONTSENELLE", cp : "50250" },
{ code : "50273", intitule : "MONTSENELLE", cp : "50250" },
{ code : "50274", intitule : "LES LOGES MARCHIS", cp : "50600" },
{ code : "50275", intitule : "LES LOGES SUR BRECEY", cp : "50370" },
{ code : "50276", intitule : "LOLIF", cp : "50530" },
{ code : "50277", intitule : "LONGUEVILLE", cp : "50290" },
{ code : "50278", intitule : "LE LOREUR", cp : "50510" },
{ code : "50279", intitule : "LE LOREY", cp : "50570" },
{ code : "50281", intitule : "LA LUCERNE D OUTREMER", cp : "50320" },
{ code : "50282", intitule : "LE LUOT", cp : "50870" },
{ code : "50283", intitule : "LA LUZERNE", cp : "50680" },
{ code : "50285", intitule : "MAGNEVILLE", cp : "50260" },
{ code : "50288", intitule : "MARCEY LES GREVES", cp : "50300" },
{ code : "50289", intitule : "MARCHESIEUX", cp : "50190" },
{ code : "50290", intitule : "MARCILLY", cp : "50220" },
{ code : "50291", intitule : "MARGUERAY", cp : "50410" },
{ code : "50292", intitule : "MARIGNY LE LOZON", cp : "50570" },
{ code : "50292", intitule : "MARIGNY LE LOZON", cp : "50570" },
{ code : "50294", intitule : "MARTINVAST", cp : "50690" },
{ code : "50295", intitule : "MAUPERTUIS", cp : "50410" },
{ code : "50296", intitule : "MAUPERTUS SUR MER", cp : "50330" },
{ code : "50297", intitule : "LA MEAUFFE", cp : "50880" },
{ code : "50298", intitule : "MEAUTIS", cp : "50500" },
{ code : "50299", intitule : "LE MESNIL", cp : "50580" },
{ code : "50300", intitule : "LE MESNIL ADELEE", cp : "50520" },
{ code : "50301", intitule : "LE MESNIL AMAND", cp : "50450" },
{ code : "50302", intitule : "LE MESNIL AMEY", cp : "50570" },
{ code : "50304", intitule : "LE MESNIL AUBERT", cp : "50510" },
{ code : "50305", intitule : "LE MESNIL AU VAL", cp : "50110" },
{ code : "50308", intitule : "LE MESNILBUS", cp : "50490" },
{ code : "50310", intitule : "LE MESNIL EURY", cp : "50570" },
{ code : "50311", intitule : "LE MESNIL GARNIER", cp : "50450" },
{ code : "50312", intitule : "LE MESNIL GILBERT", cp : "50670" },
{ code : "50313", intitule : "LE MESNIL HERMAN", cp : "50750" },
{ code : "50315", intitule : "LE MESNILLARD", cp : "50600" },
{ code : "50317", intitule : "LE MESNIL OZENNE", cp : "50220" },
{ code : "50318", intitule : "LE MESNIL RAINFRAY", cp : "50520" },
{ code : "50320", intitule : "LE MESNIL ROGUES", cp : "50450" },
{ code : "50321", intitule : "LE MESNIL ROUXELIN", cp : "50000" },
{ code : "50323", intitule : "LE MESNIL TOVE", cp : "50520" },
{ code : "50324", intitule : "LE MESNIL VENERON", cp : "50620" },
{ code : "50325", intitule : "LE MESNIL VIGOT", cp : "50570" },
{ code : "50326", intitule : "LE MESNIL VILLEMAN", cp : "50450" },
{ code : "50327", intitule : "LA MEURDRAQUIERE", cp : "50510" },
{ code : "50328", intitule : "MILLIERES", cp : "50190" },
{ code : "50332", intitule : "LES MOITIERS D ALLONNE", cp : "50270" },
{ code : "50333", intitule : "LES MOITIERS EN BAUPTOIS", cp : "50360" },
{ code : "50334", intitule : "MONTABOT", cp : "50410" },
{ code : "50335", intitule : "MONTAIGU LA BRISETTE", cp : "50700" },
{ code : "50336", intitule : "MONTAIGU LES BOIS", cp : "50450" },
{ code : "50337", intitule : "MONTANEL", cp : "50240" },
{ code : "50338", intitule : "MONTBRAY", cp : "50410" },
{ code : "50340", intitule : "MONTCUIT", cp : "50490" },
{ code : "50341", intitule : "MONTEBOURG", cp : "50310" },
{ code : "50342", intitule : "MONTFARVILLE", cp : "50760" },
{ code : "50345", intitule : "MONTHUCHON", cp : "50200" },
{ code : "50347", intitule : "MONTJOIE ST MARTIN", cp : "50240" },
{ code : "50348", intitule : "MONTMARTIN EN GRAIGNES", cp : "50620" },
{ code : "50349", intitule : "MONTMARTIN SUR MER", cp : "50590" },
{ code : "50350", intitule : "MONTPINCHON", cp : "50210" },
{ code : "50351", intitule : "MONTRABOT", cp : "50810" },
{ code : "50352", intitule : "MONTREUIL SUR LOZON", cp : "50570" },
{ code : "50353", intitule : "LE MONT ST MICHEL", cp : "50170" },
{ code : "50354", intitule : "MONTSURVENT", cp : "50200" },
{ code : "50356", intitule : "MOON SUR ELLE", cp : "50680" },
{ code : "50357", intitule : "MORIGNY", cp : "50410" },
{ code : "50358", intitule : "MORSALINES", cp : "50630" },
{ code : "50359", intitule : "MORTAIN BOCAGE", cp : "50140" },
{ code : "50359", intitule : "MORTAIN BOCAGE", cp : "50140" },
{ code : "50359", intitule : "MORTAIN BOCAGE", cp : "50140" },
{ code : "50359", intitule : "MORTAIN BOCAGE", cp : "50140" },
{ code : "50359", intitule : "MORTAIN BOCAGE", cp : "50140" },
{ code : "50360", intitule : "MORVILLE", cp : "50700" },
{ code : "50361", intitule : "LA MOUCHE", cp : "50320" },
{ code : "50362", intitule : "MOULINES", cp : "50600" },
{ code : "50363", intitule : "MOYON VILLAGES", cp : "50420" },
{ code : "50363", intitule : "MOYON VILLAGES", cp : "50860" },
{ code : "50363", intitule : "MOYON VILLAGES", cp : "50860" },
{ code : "50364", intitule : "MUNEVILLE LE BINGARD", cp : "50490" },
{ code : "50365", intitule : "MUNEVILLE SUR MER", cp : "50290" },
{ code : "50368", intitule : "NAY", cp : "50190" },
{ code : "50369", intitule : "NEGREVILLE", cp : "50260" },
{ code : "50370", intitule : "NEHOU", cp : "50390" },
{ code : "50371", intitule : "LE NEUFBOURG", cp : "50140" },
{ code : "50372", intitule : "NEUFMESNIL", cp : "50250" },
{ code : "50373", intitule : "NEUVILLE AU PLAIN", cp : "50480" },
{ code : "50374", intitule : "NEUVILLE EN BEAUMONT", cp : "50250" },
{ code : "50376", intitule : "NICORPS", cp : "50200" },
{ code : "50378", intitule : "NOTRE DAME DE CENILLY", cp : "50210" },
{ code : "50379", intitule : "NOTRE DAME DE LIVOYE", cp : "50370" },
{ code : "50382", intitule : "NOUAINVILLE", cp : "50690" },
{ code : "50384", intitule : "OCTEVILLE L AVENEL", cp : "50630" },
{ code : "50385", intitule : "OMONVILLE LA PETITE", cp : "50440" },
{ code : "50386", intitule : "OMONVILLE LA ROGUE", cp : "50440" },
{ code : "50387", intitule : "ORGLANDES", cp : "50390" },
{ code : "50388", intitule : "ORVAL SUR SIENNE", cp : "50660" },
{ code : "50388", intitule : "ORVAL SUR SIENNE", cp : "50660" },
{ code : "50389", intitule : "OUVILLE", cp : "50210" },
{ code : "50390", intitule : "OZEVILLE", cp : "50310" },
{ code : "50391", intitule : "GRANDPARIGNY", cp : "50600" },
{ code : "50391", intitule : "GRANDPARIGNY", cp : "50600" },
{ code : "50391", intitule : "GRANDPARIGNY", cp : "50600" },
{ code : "50391", intitule : "GRANDPARIGNY", cp : "50600" },
{ code : "50393", intitule : "PERCY EN NORMANDIE", cp : "50410" },
{ code : "50393", intitule : "PERCY EN NORMANDIE", cp : "50410" },
{ code : "50394", intitule : "PERIERS", cp : "50190" },
{ code : "50395", intitule : "LA PERNELLE", cp : "50630" },
{ code : "50397", intitule : "PERRIERS EN BEAUFICEL", cp : "50150" },
{ code : "50398", intitule : "LE PERRON", cp : "50160" },
{ code : "50399", intitule : "LE PETIT CELLAND", cp : "50370" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50480" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50250" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50480" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50250" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50250" },
{ code : "50400", intitule : "PICAUVILLE", cp : "50360" },
{ code : "50401", intitule : "PIERREVILLE", cp : "50340" },
{ code : "50402", intitule : "LES PIEUX", cp : "50340" },
{ code : "50403", intitule : "PIROU", cp : "50770" },
{ code : "50404", intitule : "PLACY MONTAIGU", cp : "50160" },
{ code : "50405", intitule : "LE PLESSIS LASTELLE", cp : "50250" },
{ code : "50407", intitule : "POILLEY", cp : "50220" },
{ code : "50408", intitule : "PONTAUBAULT", cp : "50220" },
{ code : "50409", intitule : "PONT HEBERT", cp : "50880" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50410", intitule : "PONTORSON", cp : "50170" },
{ code : "50411", intitule : "PONTS", cp : "50300" },
{ code : "50412", intitule : "PORTBAIL", cp : "50580" },
{ code : "50413", intitule : "PRECEY", cp : "50220" },
{ code : "50417", intitule : "QUETTEHOU", cp : "50630" },
{ code : "50419", intitule : "QUETTREVILLE SUR SIENNE", cp : "50660" },
{ code : "50419", intitule : "QUETTREVILLE SUR SIENNE", cp : "50660" },
{ code : "50420", intitule : "QUIBOU", cp : "50750" },
{ code : "50421", intitule : "QUINEVILLE", cp : "50310" },
{ code : "50422", intitule : "RAIDS", cp : "50500" },
{ code : "50423", intitule : "RAMPAN", cp : "50000" },
{ code : "50425", intitule : "RAUVILLE LA BIGOT", cp : "50260" },
{ code : "50426", intitule : "RAUVILLE LA PLACE", cp : "50390" },
{ code : "50427", intitule : "RAVENOVILLE", cp : "50480" },
{ code : "50428", intitule : "REFFUVEILLE", cp : "50520" },
{ code : "50429", intitule : "REGNEVILLE SUR MER", cp : "50590" },
{ code : "50430", intitule : "REIGNEVILLE BOCAGE", cp : "50390" },
{ code : "50431", intitule : "REMILLY SUR LOZON", cp : "50570" },
{ code : "50433", intitule : "REVILLE", cp : "50760" },
{ code : "50435", intitule : "ROCHEVILLE", cp : "50260" },
{ code : "50436", intitule : "ROMAGNY FONTENAY", cp : "50140" },
{ code : "50436", intitule : "ROMAGNY FONTENAY", cp : "50140" },
{ code : "50437", intitule : "RONCEY", cp : "50210" },
{ code : "50438", intitule : "LA RONDE HAYE", cp : "50490" },
{ code : "50442", intitule : "LE ROZEL", cp : "50340" },
{ code : "50443", intitule : "SACEY", cp : "50170" },
{ code : "50444", intitule : "ST AMAND", cp : "50160" },
{ code : "50444", intitule : "ST AMAND", cp : "50160" },
{ code : "50444", intitule : "ST AMAND", cp : "50160" },
{ code : "50445", intitule : "ST ANDRE DE BOHON", cp : "50500" },
{ code : "50446", intitule : "ST ANDRE DE L EPINE", cp : "50680" },
{ code : "50447", intitule : "ST AUBIN DES PREAUX", cp : "50380" },
{ code : "50448", intitule : "ST AUBIN DE TERREGATTE", cp : "50240" },
{ code : "50449", intitule : "ST AUBIN DU PERRON", cp : "50490" },
{ code : "50450", intitule : "ST BARTHELEMY", cp : "50140" },
{ code : "50451", intitule : "ST BRICE", cp : "50300" },
{ code : "50452", intitule : "ST BRICE DE LANDELLES", cp : "50730" },
{ code : "50453", intitule : "STE CECILE", cp : "50800" },
{ code : "50454", intitule : "ST CHRISTOPHE DU FOC", cp : "50340" },
{ code : "50455", intitule : "ST CLAIR SUR L ELLE", cp : "50680" },
{ code : "50456", intitule : "ST CLEMENT RANCOUDRAY", cp : "50140" },
{ code : "50456", intitule : "ST CLEMENT RANCOUDRAY", cp : "50140" },
{ code : "50457", intitule : "STE COLOMBE", cp : "50390" },
{ code : "50460", intitule : "STE CROIX HAGUE", cp : "50440" },
{ code : "50461", intitule : "ST CYR", cp : "50310" },
{ code : "50462", intitule : "ST CYR DU BAILLEUL", cp : "50720" },
{ code : "50463", intitule : "ST DENIS LE GAST", cp : "50450" },
{ code : "50464", intitule : "ST DENIS LE VETU", cp : "50210" },
{ code : "50465", intitule : "ST EBREMOND DE BONFOSSE", cp : "50750" },
{ code : "50467", intitule : "ST FLOXEL", cp : "50310" },
{ code : "50468", intitule : "ST FROMOND", cp : "50620" },
{ code : "50469", intitule : "STE GENEVIEVE", cp : "50760" },
{ code : "50471", intitule : "ST GEORGES DE LA RIVIERE", cp : "50270" },
{ code : "50472", intitule : "ST GEORGES DE LIVOYE", cp : "50370" },
{ code : "50473", intitule : "ST GEORGES D ELLE", cp : "50680" },
{ code : "50474", intitule : "ST GEORGES DE ROUELLEY", cp : "50720" },
{ code : "50475", intitule : "ST GEORGES MONTCOCQ", cp : "50000" },
{ code : "50476", intitule : "ST GERMAIN D ELLE", cp : "50810" },
{ code : "50477", intitule : "ST GERMAIN DES VAUX", cp : "50440" },
{ code : "50478", intitule : "ST GERMAIN DE TOURNEBUT", cp : "50700" },
{ code : "50479", intitule : "ST GERMAIN DE VARREVILLE", cp : "50480" },
{ code : "50480", intitule : "ST GERMAIN LE GAILLARD", cp : "50340" },
{ code : "50481", intitule : "ST GERMAIN SUR AY", cp : "50430" },
{ code : "50482", intitule : "ST GERMAIN SUR SEVES", cp : "50190" },
{ code : "50483", intitule : "ST GILLES", cp : "50180" },
{ code : "50484", intitule : "ST HILAIRE DU HARCOUET", cp : "50730" },
{ code : "50484", intitule : "ST HILAIRE DU HARCOUET", cp : "50600" },
{ code : "50484", intitule : "ST HILAIRE DU HARCOUET", cp : "50600" },
{ code : "50485", intitule : "ST HILAIRE PETITVILLE", cp : "50500" },
{ code : "50486", intitule : "ST JACQUES DE NEHOU", cp : "50390" },
{ code : "50487", intitule : "ST JAMES", cp : "50240" },
{ code : "50488", intitule : "ST JEAN DE DAYE", cp : "50620" },
{ code : "50489", intitule : "ST JEAN DE LA HAIZE", cp : "50300" },
{ code : "50490", intitule : "ST JEAN DE LA RIVIERE", cp : "50270" },
{ code : "50491", intitule : "ST JEAN DE SAVIGNY", cp : "50680" },
{ code : "50492", intitule : "ST JEAN D ELLE", cp : "50810" },
{ code : "50492", intitule : "ST JEAN D ELLE", cp : "50810" },
{ code : "50492", intitule : "ST JEAN D ELLE", cp : "50810" },
{ code : "50492", intitule : "ST JEAN D ELLE", cp : "50810" },
{ code : "50492", intitule : "ST JEAN D ELLE", cp : "50810" },
{ code : "50493", intitule : "ST JEAN DES CHAMPS", cp : "50320" },
{ code : "50493", intitule : "ST JEAN DES CHAMPS", cp : "50320" },
{ code : "50493", intitule : "ST JEAN DES CHAMPS", cp : "50320" },
{ code : "50495", intitule : "ST JEAN DU CORAIL DES BOIS", cp : "50370" },
{ code : "50496", intitule : "ST JEAN LE THOMAS", cp : "50530" },
{ code : "50498", intitule : "ST JOSEPH", cp : "50700" },
{ code : "50499", intitule : "ST LAURENT DE CUVES", cp : "50670" },
{ code : "50500", intitule : "ST LAURENT DE TERREGATTE", cp : "50240" },
{ code : "50502", intitule : "ST LO", cp : "50000" },
{ code : "50503", intitule : "ST LO D OURVILLE", cp : "50580" },
{ code : "50504", intitule : "ST LOUET SUR VIRE", cp : "50420" },
{ code : "50505", intitule : "ST LOUP", cp : "50300" },
{ code : "50506", intitule : "ST MALO DE LA LANDE", cp : "50200" },
{ code : "50507", intitule : "ST MARCOUF", cp : "50310" },
{ code : "50509", intitule : "STE MARIE DU MONT", cp : "50480" },
{ code : "50510", intitule : "ST MARTIN D AUBIGNY", cp : "50190" },
{ code : "50511", intitule : "ST MARTIN D AUDOUVILLE", cp : "50310" },
{ code : "50512", intitule : "ST MARTIN DE BONFOSSE", cp : "50750" },
{ code : "50513", intitule : "ST MARTIN DE CENILLY", cp : "50210" },
{ code : "50514", intitule : "CHAULIEU", cp : "50150" },
{ code : "50514", intitule : "CHAULIEU", cp : "50150" },
{ code : "50516", intitule : "ST MARTIN DES CHAMPS", cp : "50300" },
{ code : "50517", intitule : "ST MARTIN DE VARREVILLE", cp : "50480" },
{ code : "50518", intitule : "ST MARTIN LE BOUILLANT", cp : "50800" },
{ code : "50519", intitule : "ST MARTIN LE GREARD", cp : "50690" },
{ code : "50521", intitule : "ST MAUR DES BOIS", cp : "50800" },
{ code : "50522", intitule : "ST MAURICE EN COTENTIN", cp : "50270" },
{ code : "50523", intitule : "STE MERE EGLISE", cp : "50480" },
{ code : "50523", intitule : "STE MERE EGLISE", cp : "50480" },
{ code : "50523", intitule : "STE MERE EGLISE", cp : "50480" },
{ code : "50523", intitule : "STE MERE EGLISE", cp : "50480" },
{ code : "50523", intitule : "STE MERE EGLISE", cp : "50480" },
{ code : "50524", intitule : "ST MICHEL DE LA PIERRE", cp : "50490" },
{ code : "50525", intitule : "ST MICHEL DE MONTJOIE", cp : "50670" },
{ code : "50528", intitule : "ST NICOLAS DE PIERREPONT", cp : "50250" },
{ code : "50529", intitule : "ST NICOLAS DES BOIS", cp : "50370" },
{ code : "50531", intitule : "ST OVIN", cp : "50220" },
{ code : "50531", intitule : "ST OVIN", cp : "50300" },
{ code : "50532", intitule : "ST PAIR SUR MER", cp : "50380" },
{ code : "50533", intitule : "ST PATRICE DE CLAIDS", cp : "50190" },
{ code : "50534", intitule : "ST PELLERIN", cp : "50500" },
{ code : "50535", intitule : "LE PARC", cp : "50870" },
{ code : "50535", intitule : "LE PARC", cp : "50870" },
{ code : "50535", intitule : "LE PARC", cp : "50870" },
{ code : "50536", intitule : "ST PIERRE D ARTHEGLISE", cp : "50270" },
{ code : "50537", intitule : "ST PIERRE DE COUTANCES", cp : "50200" },
{ code : "50538", intitule : "ST PIERRE DE SEMILLY", cp : "50810" },
{ code : "50539", intitule : "ST PIERRE EGLISE", cp : "50330" },
{ code : "50540", intitule : "ST PIERRE LANGERS", cp : "50530" },
{ code : "50541", intitule : "ST PLANCHERS", cp : "50400" },
{ code : "50542", intitule : "ST POIS", cp : "50670" },
{ code : "50543", intitule : "ST QUENTIN SUR LE HOMME", cp : "50220" },
{ code : "50546", intitule : "BOURGVALLEES", cp : "50750" },
{ code : "50546", intitule : "BOURGVALLEES", cp : "50750" },
{ code : "50546", intitule : "BOURGVALLEES", cp : "50750" },
{ code : "50546", intitule : "BOURGVALLEES", cp : "50750" },
{ code : "50548", intitule : "ST SAUVEUR DE PIERREPONT", cp : "50250" },
{ code : "50549", intitule : "ST SAUVEUR LA POMMERAYE", cp : "50510" },
{ code : "50550", intitule : "ST SAUVEUR LENDELIN", cp : "50490" },
{ code : "50551", intitule : "ST SAUVEUR LE VICOMTE", cp : "50390" },
{ code : "50552", intitule : "ST SEBASTIEN DE RAIDS", cp : "50190" },
{ code : "50553", intitule : "ST SENIER DE BEUVRON", cp : "50240" },
{ code : "50554", intitule : "ST SENIER SOUS AVRANCHES", cp : "50300" },
{ code : "50556", intitule : "STE SUZANNE SUR VIRE", cp : "50750" },
{ code : "50562", intitule : "ST VAAST LA HOUGUE", cp : "50550" },
{ code : "50562", intitule : "ST VAAST LA HOUGUE", cp : "50550" },
{ code : "50563", intitule : "ST VIGOR DES MONTS", cp : "50420" },
{ code : "50564", intitule : "TERRE ET MARAIS", cp : "50500" },
{ code : "50564", intitule : "TERRE ET MARAIS", cp : "50500" },
{ code : "50565", intitule : "SARTILLY BAIE BOCAGE", cp : "50530" },
{ code : "50565", intitule : "SARTILLY BAIE BOCAGE", cp : "50530" },
{ code : "50565", intitule : "SARTILLY BAIE BOCAGE", cp : "50530" },
{ code : "50565", intitule : "SARTILLY BAIE BOCAGE", cp : "50530" },
{ code : "50565", intitule : "SARTILLY BAIE BOCAGE", cp : "50530" },
{ code : "50567", intitule : "SAUSSEMESNIL", cp : "50700" },
{ code : "50568", intitule : "SAUSSEY", cp : "50200" },
{ code : "50569", intitule : "SAVIGNY", cp : "50210" },
{ code : "50570", intitule : "SAVIGNY LE VIEUX", cp : "50640" },
{ code : "50571", intitule : "SEBEVILLE", cp : "50480" },
{ code : "50572", intitule : "SENOVILLE", cp : "50270" },
{ code : "50573", intitule : "SERVIGNY", cp : "50200" },
{ code : "50574", intitule : "SERVON", cp : "50170" },
{ code : "50575", intitule : "SIDEVILLE", cp : "50690" },
{ code : "50576", intitule : "SIOUVILLE HAGUE", cp : "50340" },
{ code : "50577", intitule : "SORTOSVILLE EN BEAUMONT", cp : "50270" },
{ code : "50578", intitule : "SORTOSVILLE", cp : "50310" },
{ code : "50579", intitule : "SOTTEVAST", cp : "50260" },
{ code : "50580", intitule : "SOTTEVILLE", cp : "50340" },
{ code : "50581", intitule : "SOULLES", cp : "50750" },
{ code : "50582", intitule : "SOURDEVAL", cp : "50150" },
{ code : "50582", intitule : "SOURDEVAL", cp : "50150" },
{ code : "50583", intitule : "SOURDEVAL LES BOIS", cp : "50450" },
{ code : "50584", intitule : "SUBLIGNY", cp : "50870" },
{ code : "50585", intitule : "SURTAINVILLE", cp : "50270" },
{ code : "50587", intitule : "TAILLEPIED", cp : "50390" },
{ code : "50588", intitule : "TAMERVILLE", cp : "50700" },
{ code : "50589", intitule : "TANIS", cp : "50170" },
{ code : "50590", intitule : "LE TANU", cp : "50320" },
{ code : "50590", intitule : "LE TANU", cp : "50320" },
{ code : "50591", intitule : "LE TEILLEUL", cp : "50640" },
{ code : "50591", intitule : "LE TEILLEUL", cp : "50640" },
{ code : "50591", intitule : "LE TEILLEUL", cp : "50640" },
{ code : "50591", intitule : "LE TEILLEUL", cp : "50640" },
{ code : "50591", intitule : "LE TEILLEUL", cp : "50640" },
{ code : "50592", intitule : "TESSY BOCAGE", cp : "50420" },
{ code : "50592", intitule : "TESSY BOCAGE", cp : "50420" },
{ code : "50593", intitule : "TEURTHEVILLE BOCAGE", cp : "50630" },
{ code : "50594", intitule : "TEURTHEVILLE HAGUE", cp : "50690" },
{ code : "50596", intitule : "THEVILLE", cp : "50330" },
{ code : "50597", intitule : "TIREPIED", cp : "50870" },
{ code : "50597", intitule : "TIREPIED", cp : "50870" },
{ code : "50598", intitule : "TOCQUEVILLE", cp : "50330" },
{ code : "50599", intitule : "TOLLEVAST", cp : "50470" },
{ code : "50600", intitule : "TONNEVILLE", cp : "50460" },
{ code : "50601", intitule : "TORIGNY LES VILLES", cp : "50160" },
{ code : "50601", intitule : "TORIGNY LES VILLES", cp : "50160" },
{ code : "50601", intitule : "TORIGNY LES VILLES", cp : "50160" },
{ code : "50601", intitule : "TORIGNY LES VILLES", cp : "50160" },
{ code : "50603", intitule : "TOURVILLE SUR SIENNE", cp : "50200" },
{ code : "50604", intitule : "TREAUVILLE", cp : "50340" },
{ code : "50605", intitule : "TRELLY", cp : "50660" },
{ code : "50606", intitule : "TRIBEHOU", cp : "50620" },
{ code : "50607", intitule : "LA TRINITE", cp : "50800" },
{ code : "50608", intitule : "TROISGOTS", cp : "50420" },
{ code : "50609", intitule : "TURQUEVILLE", cp : "50480" },
{ code : "50610", intitule : "URVILLE", cp : "50700" },
{ code : "50611", intitule : "URVILLE NACQUEVILLE", cp : "50460" },
{ code : "50612", intitule : "VAINS", cp : "50300" },
{ code : "50613", intitule : "VALCANVILLE", cp : "50760" },
{ code : "50615", intitule : "VALOGNES", cp : "50700" },
{ code : "50616", intitule : "LE VAL ST PERE", cp : "50300" },
{ code : "50617", intitule : "VARENGUEBEC", cp : "50250" },
{ code : "50618", intitule : "VAROUVILLE", cp : "50330" },
{ code : "50619", intitule : "LE VAST", cp : "50630" },
{ code : "50620", intitule : "VASTEVILLE", cp : "50440" },
{ code : "50621", intitule : "VAUDREVILLE", cp : "50310" },
{ code : "50622", intitule : "VAUDRIMESNIL", cp : "50490" },
{ code : "50623", intitule : "VAUVILLE", cp : "50440" },
{ code : "50624", intitule : "LA VENDELEE", cp : "50200" },
{ code : "50626", intitule : "VER", cp : "50450" },
{ code : "50627", intitule : "VERGONCEY", cp : "50240" },
{ code : "50628", intitule : "VERNIX", cp : "50370" },
{ code : "50629", intitule : "VESLY", cp : "50430" },
{ code : "50629", intitule : "VESLY", cp : "50430" },
{ code : "50631", intitule : "LES VEYS", cp : "50500" },
{ code : "50633", intitule : "LE VICEL", cp : "50760" },
{ code : "50634", intitule : "VIDECOSVILLE", cp : "50630" },
{ code : "50636", intitule : "VIERVILLE", cp : "50480" },
{ code : "50637", intitule : "VILLEBAUDON", cp : "50410" },
{ code : "50639", intitule : "VILLEDIEU LES POELES ROUFFIGNY", cp : "50800" },
{ code : "50639", intitule : "VILLEDIEU LES POELES ROUFFIGNY", cp : "50800" },
{ code : "50640", intitule : "VILLIERS LE PRE", cp : "50240" },
{ code : "50641", intitule : "VILLIERS FOSSARD", cp : "50680" },
{ code : "50643", intitule : "VIRANDEVILLE", cp : "50690" },
{ code : "50647", intitule : "YQUELON", cp : "50400" },
{ code : "50648", intitule : "YVETOT BOCAGE", cp : "50700" },
{ code : "51001", intitule : "ABLANCOURT", cp : "51240" },
{ code : "51002", intitule : "ST MARTIN D ABLOIS", cp : "51530" },
{ code : "51003", intitule : "AIGNY", cp : "51150" },
{ code : "51004", intitule : "ALLEMANCHE LAUNAY ET SOYER", cp : "51260" },
{ code : "51005", intitule : "ALLEMANT", cp : "51120" },
{ code : "51006", intitule : "ALLIANCELLES", cp : "51250" },
{ code : "51007", intitule : "AMBONNAY", cp : "51150" },
{ code : "51008", intitule : "AMBRIERES", cp : "51290" },
{ code : "51009", intitule : "ANGLURE", cp : "51260" },
{ code : "51010", intitule : "ANGLUZELLES ET COURCELLES", cp : "51230" },
{ code : "51012", intitule : "ANTHENAY", cp : "51700" },
{ code : "51013", intitule : "AOUGNY", cp : "51170" },
{ code : "51014", intitule : "ARCIS LE PONSART", cp : "51170" },
{ code : "51015", intitule : "ARGERS", cp : "51800" },
{ code : "51016", intitule : "ARRIGNY", cp : "51290" },
{ code : "51017", intitule : "ARZILLIERES NEUVILLE", cp : "51290" },
{ code : "51017", intitule : "ARZILLIERES NEUVILLE", cp : "51290" },
{ code : "51018", intitule : "ATHIS", cp : "51150" },
{ code : "51019", intitule : "AUBERIVE", cp : "51600" },
{ code : "51020", intitule : "AUBILLY", cp : "51170" },
{ code : "51022", intitule : "AULNAY L AITRE", cp : "51240" },
{ code : "51023", intitule : "AULNAY SUR MARNE", cp : "51150" },
{ code : "51025", intitule : "AUMENANCOURT", cp : "51110" },
{ code : "51027", intitule : "AUVE", cp : "51800" },
{ code : "51028", intitule : "AVENAY VAL D OR", cp : "51160" },
{ code : "51029", intitule : "AVIZE", cp : "51190" },
{ code : "51030", intitule : "AY CHAMPAGNE", cp : "51150" },
{ code : "51030", intitule : "AY CHAMPAGNE", cp : "51160" },
{ code : "51030", intitule : "AY CHAMPAGNE", cp : "51160" },
{ code : "51031", intitule : "BACONNES", cp : "51400" },
{ code : "51032", intitule : "BAGNEUX", cp : "51260" },
{ code : "51033", intitule : "LE BAIZIL", cp : "51270" },
{ code : "51034", intitule : "BANNAY", cp : "51270" },
{ code : "51035", intitule : "BANNES", cp : "51230" },
{ code : "51036", intitule : "BARBONNE FAYEL", cp : "51120" },
{ code : "51037", intitule : "BASLIEUX LES FISMES", cp : "51170" },
{ code : "51038", intitule : "BASLIEUX SOUS CHATILLON", cp : "51700" },
{ code : "51039", intitule : "BASSU", cp : "51300" },
{ code : "51040", intitule : "BASSUET", cp : "51300" },
{ code : "51041", intitule : "BAUDEMENT", cp : "51260" },
{ code : "51042", intitule : "BAYE", cp : "51270" },
{ code : "51043", intitule : "BAZANCOURT", cp : "51110" },
{ code : "51044", intitule : "BEAUMONT SUR VESLE", cp : "51360" },
{ code : "51045", intitule : "BEAUNAY", cp : "51270" },
{ code : "51046", intitule : "BEINE NAUROY", cp : "51490" },
{ code : "51047", intitule : "BELVAL EN ARGONNE", cp : "51330" },
{ code : "51048", intitule : "BELVAL SOUS CHATILLON", cp : "51480" },
{ code : "51049", intitule : "BERGERES LES VERTUS", cp : "51130" },
{ code : "51050", intitule : "BERGERES SOUS MONTMIRAIL", cp : "51210" },
{ code : "51051", intitule : "BERMERICOURT", cp : "51220" },
{ code : "51052", intitule : "BERRU", cp : "51420" },
{ code : "51053", intitule : "BERZIEUX", cp : "51800" },
{ code : "51054", intitule : "BETHENIVILLE", cp : "51490" },
{ code : "51055", intitule : "BETHENY", cp : "51450" },
{ code : "51056", intitule : "BETHON", cp : "51260" },
{ code : "51057", intitule : "BETTANCOURT LA LONGUE", cp : "51330" },
{ code : "51058", intitule : "BEZANNES", cp : "51430" },
{ code : "51059", intitule : "BIGNICOURT SUR MARNE", cp : "51300" },
{ code : "51060", intitule : "BIGNICOURT SUR SAULX", cp : "51340" },
{ code : "51061", intitule : "BILLY LE GRAND", cp : "51400" },
{ code : "51062", intitule : "BINARVILLE", cp : "51800" },
{ code : "51063", intitule : "BINSON ET ORQUIGNY", cp : "51700" },
{ code : "51065", intitule : "BLACY", cp : "51300" },
{ code : "51066", intitule : "BLAISE SOUS ARZILLIERES", cp : "51300" },
{ code : "51068", intitule : "BLESME", cp : "51340" },
{ code : "51069", intitule : "BLIGNY", cp : "51170" },
{ code : "51070", intitule : "BOISSY LE REPOS", cp : "51210" },
{ code : "51071", intitule : "BOUCHY ST GENEST", cp : "51310" },
{ code : "51072", intitule : "BOUILLY", cp : "51390" },
{ code : "51073", intitule : "BOULEUSE", cp : "51170" },
{ code : "51074", intitule : "BOULT SUR SUIPPE", cp : "51110" },
{ code : "51075", intitule : "BOURGOGNE", cp : "51110" },
{ code : "51076", intitule : "BOURSAULT", cp : "51480" },
{ code : "51077", intitule : "BOUVANCOURT", cp : "51140" },
{ code : "51078", intitule : "BOUY", cp : "51400" },
{ code : "51079", intitule : "BOUZY", cp : "51150" },
{ code : "51080", intitule : "BRANDONVILLERS", cp : "51290" },
{ code : "51081", intitule : "BRANSCOURT", cp : "51140" },
{ code : "51082", intitule : "BRAUX STE COHIERE", cp : "51800" },
{ code : "51083", intitule : "BRAUX ST REMY", cp : "51800" },
{ code : "51084", intitule : "BREBAN", cp : "51320" },
{ code : "51085", intitule : "LE BREUIL", cp : "51210" },
{ code : "51086", intitule : "BREUIL", cp : "51140" },
{ code : "51087", intitule : "BREUVERY SUR COOLE", cp : "51240" },
{ code : "51088", intitule : "BRIMONT", cp : "51220" },
{ code : "51089", intitule : "BROUILLET", cp : "51170" },
{ code : "51090", intitule : "BROUSSY LE GRAND", cp : "51230" },
{ code : "51091", intitule : "BROUSSY LE PETIT", cp : "51230" },
{ code : "51092", intitule : "BROYES", cp : "51120" },
{ code : "51093", intitule : "BRUGNY VAUDANCOURT", cp : "51530" },
{ code : "51094", intitule : "BRUSSON", cp : "51300" },
{ code : "51095", intitule : "LE BUISSON", cp : "51300" },
{ code : "51097", intitule : "BUSSY LE CHATEAU", cp : "51600" },
{ code : "51098", intitule : "BUSSY LE REPOS", cp : "51330" },
{ code : "51099", intitule : "BUSSY LETTREE", cp : "51320" },
{ code : "51100", intitule : "LA CAURE", cp : "51270" },
{ code : "51101", intitule : "CAUREL", cp : "51110" },
{ code : "51102", intitule : "CAUROY LES HERMONVILLE", cp : "51220" },
{ code : "51103", intitule : "LA CELLE SOUS CHANTEMERLE", cp : "51260" },
{ code : "51104", intitule : "CERNAY EN DORMOIS", cp : "51800" },
{ code : "51105", intitule : "CERNAY LES REIMS", cp : "51420" },
{ code : "51106", intitule : "CERNON", cp : "51240" },
{ code : "51107", intitule : "CHAINTRIX BIERGES", cp : "51130" },
{ code : "51108", intitule : "CHALONS EN CHAMPAGNE", cp : "51000" },
{ code : "51109", intitule : "CHALONS SUR VESLE", cp : "51140" },
{ code : "51110", intitule : "CHALTRAIT", cp : "51130" },
{ code : "51111", intitule : "CHAMBRECY", cp : "51170" },
{ code : "51112", intitule : "CHAMERY", cp : "51500" },
{ code : "51113", intitule : "CHAMPAUBERT", cp : "51270" },
{ code : "51115", intitule : "CHAMPFLEURY", cp : "51500" },
{ code : "51116", intitule : "CHAMPGUYON", cp : "51310" },
{ code : "51117", intitule : "CHAMPIGNEUL CHAMPAGNE", cp : "51150" },
{ code : "51118", intitule : "CHAMPIGNY", cp : "51370" },
{ code : "51119", intitule : "CHAMPILLON", cp : "51160" },
{ code : "51120", intitule : "CHAMPLAT ET BOUJACOURT", cp : "51480" },
{ code : "51121", intitule : "CHAMPVOISY", cp : "51700" },
{ code : "51122", intitule : "CHANGY", cp : "51300" },
{ code : "51124", intitule : "CHANTEMERLE", cp : "51260" },
{ code : "51125", intitule : "CHAPELAINE", cp : "51290" },
{ code : "51126", intitule : "LA CHAPELLE FELCOURT", cp : "51800" },
{ code : "51127", intitule : "LA CHAPELLE LASSON", cp : "51260" },
{ code : "51128", intitule : "LA CHAPELLE SOUS ORBAIS", cp : "51270" },
{ code : "51129", intitule : "CHARLEVILLE", cp : "51120" },
{ code : "51130", intitule : "CHARMONT", cp : "51330" },
{ code : "51132", intitule : "LES CHARMONTOIS", cp : "51330" },
{ code : "51133", intitule : "LE CHATELIER", cp : "51330" },
{ code : "51134", intitule : "CHATELRAOULD ST LOUVENT", cp : "51300" },
{ code : "51135", intitule : "CHATILLON SUR BROUE", cp : "51290" },
{ code : "51136", intitule : "CHATILLON SUR MARNE", cp : "51700" },
{ code : "51137", intitule : "CHATILLON SUR MORIN", cp : "51310" },
{ code : "51138", intitule : "CHATRICES", cp : "51800" },
{ code : "51139", intitule : "CHAUDEFONTAINE", cp : "51800" },
{ code : "51140", intitule : "CHAUMUZY", cp : "51170" },
{ code : "51141", intitule : "LA CHAUSSEE SUR MARNE", cp : "51240" },
{ code : "51142", intitule : "CHAVOT COURCOURT", cp : "51530" },
{ code : "51143", intitule : "LE CHEMIN", cp : "51800" },
{ code : "51144", intitule : "CHEMINON", cp : "51250" },
{ code : "51145", intitule : "CHENAY", cp : "51140" },
{ code : "51146", intitule : "CHENIERS", cp : "51510" },
{ code : "51147", intitule : "LA CHEPPE", cp : "51600" },
{ code : "51148", intitule : "CHEPPES LA PRAIRIE", cp : "51240" },
{ code : "51149", intitule : "CHEPY", cp : "51240" },
{ code : "51150", intitule : "CHERVILLE", cp : "51150" },
{ code : "51151", intitule : "CHICHEY", cp : "51120" },
{ code : "51152", intitule : "CHIGNY LES ROSES", cp : "51500" },
{ code : "51153", intitule : "CHOUILLY", cp : "51530" },
{ code : "51154", intitule : "CLAMANGES", cp : "51130" },
{ code : "51155", intitule : "CLESLES", cp : "51260" },
{ code : "51156", intitule : "CLOYES SUR MARNE", cp : "51300" },
{ code : "51157", intitule : "COIZARD JOCHES", cp : "51270" },
{ code : "51158", intitule : "VAL DES MARAIS", cp : "51130" },
{ code : "51158", intitule : "VAL DES MARAIS", cp : "51130" },
{ code : "51158", intitule : "VAL DES MARAIS", cp : "51130" },
{ code : "51158", intitule : "VAL DES MARAIS", cp : "51130" },
{ code : "51160", intitule : "COMPERTRIX", cp : "51510" },
{ code : "51161", intitule : "CONDE SUR MARNE", cp : "51150" },
{ code : "51162", intitule : "CONFLANS SUR SEINE", cp : "51260" },
{ code : "51163", intitule : "CONGY", cp : "51270" },
{ code : "51164", intitule : "CONNANTRAY VAUREFROY", cp : "51230" },
{ code : "51165", intitule : "CONNANTRE", cp : "51230" },
{ code : "51166", intitule : "CONTAULT", cp : "51330" },
{ code : "51167", intitule : "COOLE", cp : "51320" },
{ code : "51168", intitule : "COOLUS", cp : "51510" },
{ code : "51169", intitule : "CORBEIL", cp : "51320" },
{ code : "51170", intitule : "CORFELIX", cp : "51210" },
{ code : "51171", intitule : "CORMICY", cp : "51220" },
{ code : "51172", intitule : "CORMONTREUIL", cp : "51350" },
{ code : "51173", intitule : "CORMOYEUX", cp : "51480" },
{ code : "51174", intitule : "CORRIBERT", cp : "51270" },
{ code : "51175", intitule : "CORROBERT", cp : "51210" },
{ code : "51176", intitule : "CORROY", cp : "51230" },
{ code : "51177", intitule : "COULOMMES LA MONTAGNE", cp : "51390" },
{ code : "51178", intitule : "COUPETZ", cp : "51240" },
{ code : "51179", intitule : "COUPEVILLE", cp : "51240" },
{ code : "51181", intitule : "COURCELLES SAPICOURT", cp : "51140" },
{ code : "51182", intitule : "COURCEMAIN", cp : "51260" },
{ code : "51183", intitule : "COURCY", cp : "51220" },
{ code : "51184", intitule : "COURDEMANGES", cp : "51300" },
{ code : "51185", intitule : "COURGIVAUX", cp : "51310" },
{ code : "51186", intitule : "COURJEONNET", cp : "51270" },
{ code : "51187", intitule : "COURLANDON", cp : "51170" },
{ code : "51188", intitule : "COURMAS", cp : "51390" },
{ code : "51190", intitule : "COURTAGNON", cp : "51480" },
{ code : "51191", intitule : "COURTEMONT", cp : "51800" },
{ code : "51192", intitule : "COURTHIEZY", cp : "51700" },
{ code : "51193", intitule : "COURTISOLS", cp : "51460" },
{ code : "51194", intitule : "COURVILLE", cp : "51170" },
{ code : "51195", intitule : "COUVROT", cp : "51300" },
{ code : "51196", intitule : "CRAMANT", cp : "51530" },
{ code : "51197", intitule : "LA CROIX EN CHAMPAGNE", cp : "51600" },
{ code : "51198", intitule : "CRUGNY", cp : "51170" },
{ code : "51199", intitule : "CUCHERY", cp : "51480" },
{ code : "51200", intitule : "CUIS", cp : "51530" },
{ code : "51201", intitule : "CUISLES", cp : "51700" },
{ code : "51202", intitule : "CUMIERES", cp : "51480" },
{ code : "51203", intitule : "CUPERLY", cp : "51400" },
{ code : "51204", intitule : "DAMERY", cp : "51480" },
{ code : "51205", intitule : "DAMPIERRE AU TEMPLE", cp : "51400" },
{ code : "51206", intitule : "DAMPIERRE LE CHATEAU", cp : "51330" },
{ code : "51208", intitule : "DAMPIERRE SUR MOIVRE", cp : "51240" },
{ code : "51210", intitule : "DIZY", cp : "51530" },
{ code : "51211", intitule : "DOMMARTIN DAMPIERRE", cp : "51800" },
{ code : "51212", intitule : "DOMMARTIN LETTREE", cp : "51320" },
{ code : "51213", intitule : "DOMMARTIN SOUS HANS", cp : "51800" },
{ code : "51214", intitule : "DOMMARTIN VARIMONT", cp : "51330" },
{ code : "51215", intitule : "DOMPREMY", cp : "51300" },
{ code : "51216", intitule : "DONTRIEN", cp : "51490" },
{ code : "51217", intitule : "DORMANS", cp : "51700" },
{ code : "51217", intitule : "DORMANS", cp : "51700" },
{ code : "51218", intitule : "VAL DE VIERE", cp : "51340" },
{ code : "51219", intitule : "DROSNAY", cp : "51290" },
{ code : "51220", intitule : "DROUILLY", cp : "51300" },
{ code : "51222", intitule : "ECLAIRES", cp : "51800" },
{ code : "51223", intitule : "ECOLLEMONT", cp : "51290" },
{ code : "51224", intitule : "ECRIENNES", cp : "51300" },
{ code : "51225", intitule : "ECUEIL", cp : "51500" },
{ code : "51226", intitule : "ECURY LE REPOS", cp : "51230" },
{ code : "51227", intitule : "ECURY SUR COOLE", cp : "51240" },
{ code : "51228", intitule : "ELISE DAUCOURT", cp : "51800" },
{ code : "51229", intitule : "EPENSE", cp : "51330" },
{ code : "51230", intitule : "EPERNAY", cp : "51200" },
{ code : "51231", intitule : "L EPINE", cp : "51460" },
{ code : "51232", intitule : "EPOYE", cp : "51490" },
{ code : "51233", intitule : "ESCARDES", cp : "51310" },
{ code : "51234", intitule : "ESCLAVOLLES LUREY", cp : "51260" },
{ code : "51235", intitule : "LES ESSARTS LES SEZANNE", cp : "51120" },
{ code : "51236", intitule : "LES ESSARTS LE VICOMTE", cp : "51310" },
{ code : "51237", intitule : "ESTERNAY", cp : "51310" },
{ code : "51238", intitule : "ETOGES", cp : "51270" },
{ code : "51239", intitule : "ETRECHY", cp : "51130" },
{ code : "51240", intitule : "ETREPY", cp : "51340" },
{ code : "51241", intitule : "EUVY", cp : "51230" },
{ code : "51242", intitule : "FAGNIERES", cp : "51510" },
{ code : "51243", intitule : "FAUX FRESNAY", cp : "51230" },
{ code : "51244", intitule : "FAUX VESIGNEUL", cp : "51320" },
{ code : "51244", intitule : "FAUX VESIGNEUL", cp : "51320" },
{ code : "51245", intitule : "FAVEROLLES ET COEMY", cp : "51170" },
{ code : "51246", intitule : "FAVRESSE", cp : "51300" },
{ code : "51247", intitule : "FEREBRIANGES", cp : "51270" },
{ code : "51248", intitule : "FERE CHAMPENOISE", cp : "51230" },
{ code : "51248", intitule : "FERE CHAMPENOISE", cp : "51230" },
{ code : "51249", intitule : "FESTIGNY", cp : "51700" },
{ code : "51250", intitule : "FISMES", cp : "51170" },
{ code : "51251", intitule : "FLAVIGNY", cp : "51190" },
{ code : "51252", intitule : "FLEURY LA RIVIERE", cp : "51480" },
{ code : "51253", intitule : "FLORENT EN ARGONNE", cp : "51800" },
{ code : "51254", intitule : "FONTAINE DENIS NUISY", cp : "51120" },
{ code : "51255", intitule : "FONTAINE EN DORMOIS", cp : "51800" },
{ code : "51256", intitule : "FONTAINE SUR AY", cp : "51160" },
{ code : "51258", intitule : "LA FORESTIERE", cp : "51120" },
{ code : "51259", intitule : "FRANCHEVILLE", cp : "51240" },
{ code : "51260", intitule : "LE FRESNE", cp : "51240" },
{ code : "51261", intitule : "FRESNE LES REIMS", cp : "51110" },
{ code : "51262", intitule : "FRIGNICOURT", cp : "51300" },
{ code : "51263", intitule : "FROMENTIERES", cp : "51210" },
{ code : "51264", intitule : "LE GAULT SOIGNY", cp : "51210" },
{ code : "51265", intitule : "GAYE", cp : "51120" },
{ code : "51266", intitule : "GERMAINE", cp : "51160" },
{ code : "51267", intitule : "GERMIGNY", cp : "51390" },
{ code : "51268", intitule : "GERMINON", cp : "51130" },
{ code : "51269", intitule : "GIFFAUMONT CHAMPAUBERT", cp : "51290" },
{ code : "51270", intitule : "GIGNY BUSSY", cp : "51290" },
{ code : "51271", intitule : "GIONGES", cp : "51130" },
{ code : "51272", intitule : "GIVRY EN ARGONNE", cp : "51330" },
{ code : "51273", intitule : "GIVRY LES LOISY", cp : "51130" },
{ code : "51274", intitule : "GIZAUCOURT", cp : "51800" },
{ code : "51275", intitule : "GLANNES", cp : "51300" },
{ code : "51276", intitule : "GOURGANCON", cp : "51230" },
{ code : "51277", intitule : "STE MARIE DU LAC NUISEMENT", cp : "51290" },
{ code : "51278", intitule : "LES GRANDES LOGES", cp : "51400" },
{ code : "51279", intitule : "GRANGES SUR AUBE", cp : "51260" },
{ code : "51280", intitule : "GRATREUIL", cp : "51800" },
{ code : "51281", intitule : "GRAUVES", cp : "51190" },
{ code : "51282", intitule : "GUEUX", cp : "51390" },
{ code : "51283", intitule : "HANS", cp : "51800" },
{ code : "51284", intitule : "HAUSSIGNEMONT", cp : "51300" },
{ code : "51285", intitule : "HAUSSIMONT", cp : "51320" },
{ code : "51286", intitule : "HAUTEVILLE", cp : "51290" },
{ code : "51287", intitule : "HAUTVILLERS", cp : "51160" },
{ code : "51288", intitule : "HEILTZ LE HUTIER", cp : "51300" },
{ code : "51289", intitule : "HEILTZ LE MAURUPT", cp : "51340" },
{ code : "51290", intitule : "HEILTZ L EVEQUE", cp : "51340" },
{ code : "51291", intitule : "HERMONVILLE", cp : "51220" },
{ code : "51292", intitule : "HERPONT", cp : "51460" },
{ code : "51293", intitule : "HEUTREGIVILLE", cp : "51110" },
{ code : "51294", intitule : "HOURGES", cp : "51140" },
{ code : "51295", intitule : "HUIRON", cp : "51300" },
{ code : "51296", intitule : "HUMBAUVILLE", cp : "51320" },
{ code : "51298", intitule : "IGNY COMBLIZY", cp : "51700" },
{ code : "51299", intitule : "ISLES SUR SUIPPE", cp : "51110" },
{ code : "51300", intitule : "ISLE SUR MARNE", cp : "51290" },
{ code : "51301", intitule : "ISSE", cp : "51150" },
{ code : "51302", intitule : "LES ISTRES ET BURY", cp : "51190" },
{ code : "51303", intitule : "JALONS", cp : "51150" },
{ code : "51304", intitule : "JANVILLIERS", cp : "51210" },
{ code : "51305", intitule : "JANVRY", cp : "51390" },
{ code : "51306", intitule : "JOISELLE", cp : "51310" },
{ code : "51307", intitule : "JONCHERY SUR SUIPPE", cp : "51600" },
{ code : "51308", intitule : "JONCHERY SUR VESLE", cp : "51140" },
{ code : "51309", intitule : "JONQUERY", cp : "51700" },
{ code : "51310", intitule : "JOUY LES REIMS", cp : "51390" },
{ code : "51311", intitule : "JUSSECOURT MINECOURT", cp : "51340" },
{ code : "51312", intitule : "JUVIGNY", cp : "51150" },
{ code : "51313", intitule : "LACHY", cp : "51120" },
{ code : "51314", intitule : "LAGERY", cp : "51170" },
{ code : "51315", intitule : "LANDRICOURT", cp : "51290" },
{ code : "51316", intitule : "LARZICOURT", cp : "51290" },
{ code : "51317", intitule : "LAVAL SUR TOURBE", cp : "51600" },
{ code : "51318", intitule : "LAVANNES", cp : "51110" },
{ code : "51319", intitule : "LENHARREE", cp : "51230" },
{ code : "51320", intitule : "LEUVRIGNY", cp : "51700" },
{ code : "51321", intitule : "LHERY", cp : "51170" },
{ code : "51322", intitule : "LIGNON", cp : "51290" },
{ code : "51323", intitule : "LINTHELLES", cp : "51230" },
{ code : "51324", intitule : "LINTHES", cp : "51230" },
{ code : "51325", intitule : "LISSE EN CHAMPAGNE", cp : "51300" },
{ code : "51326", intitule : "LIVRY LOUVERCY", cp : "51400" },
{ code : "51327", intitule : "LOISY EN BRIE", cp : "51130" },
{ code : "51328", intitule : "LOISY SUR MARNE", cp : "51300" },
{ code : "51329", intitule : "LOIVRE", cp : "51220" },
{ code : "51333", intitule : "LUDES", cp : "51500" },
{ code : "51334", intitule : "LUXEMONT ET VILLOTTE", cp : "51300" },
{ code : "51336", intitule : "MAFFRECOURT", cp : "51800" },
{ code : "51337", intitule : "MAGNEUX", cp : "51170" },
{ code : "51338", intitule : "MAILLY CHAMPAGNE", cp : "51500" },
{ code : "51339", intitule : "MAIRY SUR MARNE", cp : "51240" },
{ code : "51340", intitule : "MAISONS EN CHAMPAGNE", cp : "51300" },
{ code : "51341", intitule : "MALMY", cp : "51800" },
{ code : "51342", intitule : "MANCY", cp : "51530" },
{ code : "51343", intitule : "MARCILLY SUR SEINE", cp : "51260" },
{ code : "51344", intitule : "MARDEUIL", cp : "51530" },
{ code : "51345", intitule : "MAREUIL EN BRIE", cp : "51270" },
{ code : "51346", intitule : "MAREUIL LE PORT", cp : "51700" },
{ code : "51346", intitule : "MAREUIL LE PORT", cp : "51700" },
{ code : "51348", intitule : "MARFAUX", cp : "51170" },
{ code : "51349", intitule : "MARGERIE HANCOURT", cp : "51290" },
{ code : "51350", intitule : "MARGNY", cp : "51210" },
{ code : "51351", intitule : "MARIGNY", cp : "51230" },
{ code : "51352", intitule : "MAROLLES", cp : "51300" },
{ code : "51353", intitule : "MARSANGIS", cp : "51260" },
{ code : "51354", intitule : "MARSON", cp : "51240" },
{ code : "51355", intitule : "MASSIGES", cp : "51800" },
{ code : "51356", intitule : "MATIGNICOURT GONCOURT", cp : "51300" },
{ code : "51357", intitule : "MATOUGUES", cp : "51510" },
{ code : "51358", intitule : "MAURUPT LE MONTOIS", cp : "51340" },
{ code : "51359", intitule : "MECRINGES", cp : "51210" },
{ code : "51360", intitule : "LE MEIX ST EPOING", cp : "51120" },
{ code : "51361", intitule : "LE MEIX TIERCELIN", cp : "51320" },
{ code : "51362", intitule : "MERFY", cp : "51220" },
{ code : "51363", intitule : "MERLAUT", cp : "51300" },
{ code : "51364", intitule : "MERY PREMECY", cp : "51390" },
{ code : "51365", intitule : "LES MESNEUX", cp : "51370" },
{ code : "51367", intitule : "LE MESNIL SUR OGER", cp : "51190" },
{ code : "51368", intitule : "MINAUCOURT LE MESNIL LES HURLUS", cp : "51800" },
{ code : "51369", intitule : "MOEURS VERDEY", cp : "51120" },
{ code : "51370", intitule : "MOIREMONT", cp : "51800" },
{ code : "51371", intitule : "MOIVRE", cp : "51240" },
{ code : "51372", intitule : "MONCETZ LONGEVAS", cp : "51470" },
{ code : "51373", intitule : "MONCETZ L ABBAYE", cp : "51290" },
{ code : "51374", intitule : "MONDEMENT MONTGIVROUX", cp : "51120" },
{ code : "51375", intitule : "MONTBRE", cp : "51500" },
{ code : "51376", intitule : "MONTGENOST", cp : "51260" },
{ code : "51377", intitule : "MONTEPREUX", cp : "51320" },
{ code : "51378", intitule : "MONTHELON", cp : "51530" },
{ code : "51379", intitule : "MONTIGNY SUR VESLE", cp : "51140" },
{ code : "51380", intitule : "MONTMIRAIL", cp : "51210" },
{ code : "51380", intitule : "MONTMIRAIL", cp : "51210" },
{ code : "51381", intitule : "MONTMORT LUCY", cp : "51270" },
{ code : "51381", intitule : "MONTMORT LUCY", cp : "51270" },
{ code : "51382", intitule : "MONT SUR COURVILLE", cp : "51170" },
{ code : "51384", intitule : "MORANGIS", cp : "51530" },
{ code : "51386", intitule : "MORSAINS", cp : "51210" },
{ code : "51387", intitule : "MOSLINS", cp : "51530" },
{ code : "51388", intitule : "MOURMELON LE GRAND", cp : "51400" },
{ code : "51389", intitule : "MOURMELON LE PETIT", cp : "51400" },
{ code : "51390", intitule : "MOUSSY", cp : "51530" },
{ code : "51391", intitule : "MUIZON", cp : "51140" },
{ code : "51392", intitule : "MUTIGNY", cp : "51160" },
{ code : "51393", intitule : "NANTEUIL LA FORET", cp : "51480" },
{ code : "51395", intitule : "NESLE LA REPOSTE", cp : "51120" },
{ code : "51396", intitule : "NESLE LE REPONS", cp : "51700" },
{ code : "51397", intitule : "LA NEUVILLE AUX BOIS", cp : "51330" },
{ code : "51398", intitule : "LA NEUVILLE AUX LARRIS", cp : "51480" },
{ code : "51399", intitule : "LA NEUVILLE AU PONT", cp : "51800" },
{ code : "51402", intitule : "NEUVY", cp : "51310" },
{ code : "51403", intitule : "NOGENT L ABBESSE", cp : "51420" },
{ code : "51404", intitule : "NOIRLIEU", cp : "51330" },
{ code : "51406", intitule : "NORROIS", cp : "51300" },
{ code : "51407", intitule : "LA NOUE", cp : "51310" },
{ code : "51409", intitule : "NUISEMENT SUR COOLE", cp : "51240" },
{ code : "51410", intitule : "OEUILLY", cp : "51480" },
{ code : "51411", intitule : "OGER", cp : "51190" },
{ code : "51412", intitule : "OGNES", cp : "51230" },
{ code : "51413", intitule : "OIRY", cp : "51530" },
{ code : "51414", intitule : "OLIZY", cp : "51700" },
{ code : "51415", intitule : "OMEY", cp : "51240" },
{ code : "51416", intitule : "ORBAIS L ABBAYE", cp : "51270" },
{ code : "51417", intitule : "ORCONTE", cp : "51300" },
{ code : "51418", intitule : "ORMES", cp : "51370" },
{ code : "51419", intitule : "OUTINES", cp : "51290" },
{ code : "51420", intitule : "OUTREPONT", cp : "51300" },
{ code : "51421", intitule : "OYES", cp : "51120" },
{ code : "51422", intitule : "PARGNY LES REIMS", cp : "51390" },
{ code : "51423", intitule : "PARGNY SUR SAULX", cp : "51340" },
{ code : "51424", intitule : "PASSAVANT EN ARGONNE", cp : "51800" },
{ code : "51425", intitule : "PASSY GRIGNY", cp : "51700" },
{ code : "51426", intitule : "PEAS", cp : "51120" },
{ code : "51428", intitule : "LES PETITES LOGES", cp : "51400" },
{ code : "51429", intitule : "PEVY", cp : "51140" },
{ code : "51430", intitule : "PIERRE MORAINS", cp : "51130" },
{ code : "51431", intitule : "PIERRY", cp : "51530" },
{ code : "51432", intitule : "PLEURS", cp : "51230" },
{ code : "51433", intitule : "PLICHANCOURT", cp : "51300" },
{ code : "51434", intitule : "PLIVOT", cp : "51150" },
{ code : "51435", intitule : "POCANCY", cp : "51130" },
{ code : "51436", intitule : "POGNY", cp : "51240" },
{ code : "51437", intitule : "POILLY", cp : "51170" },
{ code : "51438", intitule : "POIX", cp : "51460" },
{ code : "51439", intitule : "POMACLE", cp : "51110" },
{ code : "51440", intitule : "PONTFAVERGER MORONVILLIERS", cp : "51490" },
{ code : "51441", intitule : "PONTHION", cp : "51300" },
{ code : "51442", intitule : "POSSESSE", cp : "51330" },
{ code : "51443", intitule : "POTANGIS", cp : "51260" },
{ code : "51444", intitule : "POUILLON", cp : "51220" },
{ code : "51445", intitule : "POURCY", cp : "51480" },
{ code : "51446", intitule : "PRINGY", cp : "51300" },
{ code : "51447", intitule : "PROSNES", cp : "51400" },
{ code : "51448", intitule : "PROUILLY", cp : "51140" },
{ code : "51449", intitule : "PRUNAY", cp : "51360" },
{ code : "51450", intitule : "PUISIEULX", cp : "51500" },
{ code : "51451", intitule : "QUEUDES", cp : "51120" },
{ code : "51452", intitule : "RAPSECOURT", cp : "51330" },
{ code : "51453", intitule : "RECY", cp : "51520" },
{ code : "51454", intitule : "REIMS", cp : "51100" },
{ code : "51454", intitule : "REIMS", cp : "51100" },
{ code : "51455", intitule : "REIMS LA BRULEE", cp : "51300" },
{ code : "51456", intitule : "REMICOURT", cp : "51330" },
{ code : "51457", intitule : "REUIL", cp : "51480" },
{ code : "51458", intitule : "REUVES", cp : "51120" },
{ code : "51459", intitule : "REVEILLON", cp : "51310" },
{ code : "51460", intitule : "RIEUX", cp : "51210" },
{ code : "51461", intitule : "RILLY LA MONTAGNE", cp : "51500" },
{ code : "51463", intitule : "LES RIVIERES HENRUEL", cp : "51300" },
{ code : "51464", intitule : "ROMAIN", cp : "51140" },
{ code : "51465", intitule : "ROMERY", cp : "51480" },
{ code : "51466", intitule : "ROMIGNY", cp : "51170" },
{ code : "51468", intitule : "ROSNAY", cp : "51390" },
{ code : "51469", intitule : "ROUFFY", cp : "51130" },
{ code : "51470", intitule : "ROUVROY RIPONT", cp : "51800" },
{ code : "51471", intitule : "SACY", cp : "51500" },
{ code : "51472", intitule : "ST AMAND SUR FION", cp : "51300" },
{ code : "51473", intitule : "ST BON", cp : "51310" },
{ code : "51474", intitule : "ST BRICE COURCELLES", cp : "51370" },
{ code : "51475", intitule : "ST CHERON", cp : "51290" },
{ code : "51476", intitule : "ST ETIENNE AU TEMPLE", cp : "51460" },
{ code : "51477", intitule : "ST ETIENNE SUR SUIPPE", cp : "51110" },
{ code : "51478", intitule : "ST EULIEN", cp : "52100" },
{ code : "51479", intitule : "ST EUPHRAISE ET CLAIRIZET", cp : "51390" },
{ code : "51480", intitule : "STE GEMME", cp : "51700" },
{ code : "51482", intitule : "ST GERMAIN LA VILLE", cp : "51240" },
{ code : "51483", intitule : "ST GIBRIEN", cp : "51510" },
{ code : "51484", intitule : "ST GILLES", cp : "51170" },
{ code : "51485", intitule : "ST HILAIRE AU TEMPLE", cp : "51400" },
{ code : "51486", intitule : "ST HILAIRE LE GRAND", cp : "51600" },
{ code : "51487", intitule : "ST HILAIRE LE PETIT", cp : "51490" },
{ code : "51488", intitule : "ST IMOGES", cp : "51160" },
{ code : "51489", intitule : "ST JEAN DEVANT POSSESSE", cp : "51330" },
{ code : "51490", intitule : "ST JEAN SUR MOIVRE", cp : "51240" },
{ code : "51491", intitule : "ST JEAN SUR TOURBE", cp : "51600" },
{ code : "51492", intitule : "ST JUST SAUVAGE", cp : "51260" },
{ code : "51493", intitule : "ST LEONARD", cp : "51500" },
{ code : "51495", intitule : "ST LOUP", cp : "51120" },
{ code : "51496", intitule : "ST LUMIER EN CHAMPAGNE", cp : "51300" },
{ code : "51497", intitule : "ST LUMIER LA POPULEUSE", cp : "51340" },
{ code : "51498", intitule : "ST MARD SUR AUVE", cp : "51800" },
{ code : "51499", intitule : "ST MARD LES ROUFFY", cp : "51130" },
{ code : "51500", intitule : "ST MARD SUR LE MONT", cp : "51330" },
{ code : "51501", intitule : "STE MARIE A PY", cp : "51600" },
{ code : "51502", intitule : "ST MARTIN AUX CHAMPS", cp : "51240" },
{ code : "51503", intitule : "ST MARTIN L HEUREUX", cp : "51490" },
{ code : "51504", intitule : "ST MARTIN SUR LE PRE", cp : "51520" },
{ code : "51505", intitule : "ST MASMES", cp : "51490" },
{ code : "51506", intitule : "ST MEMMIE", cp : "51470" },
{ code : "51507", intitule : "STE MENEHOULD", cp : "51800" },
{ code : "51508", intitule : "ST OUEN DOMPROT", cp : "51320" },
{ code : "51509", intitule : "ST PIERRE", cp : "51510" },
{ code : "51510", intitule : "ST QUENTIN LES MARAIS", cp : "51300" },
{ code : "51511", intitule : "ST QUENTIN LE VERGER", cp : "51120" },
{ code : "51512", intitule : "ST QUENTIN SUR COOLE", cp : "51240" },
{ code : "51513", intitule : "ST REMY EN BOUZEMONT ST GENEST ISSON", cp : "51290" },
{ code : "51514", intitule : "ST REMY SOUS BROYES", cp : "51120" },
{ code : "51515", intitule : "ST REMY SUR BUSSY", cp : "51600" },
{ code : "51516", intitule : "ST SATURNIN", cp : "51260" },
{ code : "51517", intitule : "ST SOUPLET SUR PY", cp : "51600" },
{ code : "51518", intitule : "ST THIERRY", cp : "51220" },
{ code : "51519", intitule : "ST THOMAS EN ARGONNE", cp : "51800" },
{ code : "51520", intitule : "ST UTIN", cp : "51290" },
{ code : "51521", intitule : "ST VRAIN", cp : "51340" },
{ code : "51522", intitule : "SAPIGNICOURT", cp : "52100" },
{ code : "51523", intitule : "SARCY", cp : "51170" },
{ code : "51524", intitule : "SARON SUR AUBE", cp : "51260" },
{ code : "51525", intitule : "SARRY", cp : "51520" },
{ code : "51526", intitule : "SAUDOY", cp : "51120" },
{ code : "51527", intitule : "SAVIGNY SUR ARDRES", cp : "51170" },
{ code : "51528", intitule : "SCRUPT", cp : "51340" },
{ code : "51529", intitule : "SELLES", cp : "51490" },
{ code : "51530", intitule : "SEPT SAULX", cp : "51400" },
{ code : "51531", intitule : "SERMAIZE LES BAINS", cp : "51250" },
{ code : "51532", intitule : "SERMIERS", cp : "51500" },
{ code : "51533", intitule : "SERVON MELZICOURT", cp : "51800" },
{ code : "51534", intitule : "SERZY ET PRIN", cp : "51170" },
{ code : "51535", intitule : "SEZANNE", cp : "51120" },
{ code : "51536", intitule : "SILLERY", cp : "51500" },
{ code : "51537", intitule : "SIVRY ANTE", cp : "51800" },
{ code : "51538", intitule : "SOGNY AUX MOULINS", cp : "51520" },
{ code : "51539", intitule : "SOGNY EN L ANGLE", cp : "51340" },
{ code : "51542", intitule : "SOIZY AUX BOIS", cp : "51120" },
{ code : "51543", intitule : "SOMME BIONNE", cp : "51800" },
{ code : "51544", intitule : "SOMMEPY TAHURE", cp : "51600" },
{ code : "51545", intitule : "SOMMESOUS", cp : "51320" },
{ code : "51546", intitule : "SOMME SUIPPE", cp : "51600" },
{ code : "51547", intitule : "SOMME TOURBE", cp : "51600" },
{ code : "51548", intitule : "SOMME VESLE", cp : "51460" },
{ code : "51549", intitule : "SOMME YEVRE", cp : "51330" },
{ code : "51550", intitule : "SOMPUIS", cp : "51320" },
{ code : "51551", intitule : "SOMSOIS", cp : "51290" },
{ code : "51552", intitule : "SONGY", cp : "51240" },
{ code : "51553", intitule : "SOUAIN PERTHES LES HURLUS", cp : "51600" },
{ code : "51555", intitule : "SOUDE", cp : "51320" },
{ code : "51556", intitule : "SOUDRON", cp : "51320" },
{ code : "51557", intitule : "SOULANGES", cp : "51300" },
{ code : "51558", intitule : "SOULIERES", cp : "51130" },
{ code : "51559", intitule : "SUIPPES", cp : "51600" },
{ code : "51560", intitule : "SUIZY LE FRANC", cp : "51270" },
{ code : "51562", intitule : "TAISSY", cp : "51500" },
{ code : "51563", intitule : "TALUS ST PRIX", cp : "51270" },
{ code : "51564", intitule : "VAL DE LIVRE", cp : "51150" },
{ code : "51564", intitule : "VAL DE LIVRE", cp : "51150" },
{ code : "51565", intitule : "THAAS", cp : "51230" },
{ code : "51566", intitule : "THIBIE", cp : "51510" },
{ code : "51567", intitule : "THIEBLEMONT FAREMONT", cp : "51300" },
{ code : "51568", intitule : "THIL", cp : "51220" },
{ code : "51569", intitule : "THILLOIS", cp : "51370" },
{ code : "51570", intitule : "LE THOULT TROSNAY", cp : "51210" },
{ code : "51571", intitule : "VAL DE VESLE", cp : "51360" },
{ code : "51572", intitule : "TILLOY ET BELLAY", cp : "51460" },
{ code : "51573", intitule : "TINQUEUX", cp : "51430" },
{ code : "51574", intitule : "TOGNY AUX BOEUFS", cp : "51240" },
{ code : "51576", intitule : "TOURS SUR MARNE", cp : "51150" },
{ code : "51577", intitule : "TRAMERY", cp : "51170" },
{ code : "51578", intitule : "TRECON", cp : "51130" },
{ code : "51579", intitule : "TREFOLS", cp : "51210" },
{ code : "51580", intitule : "TREPAIL", cp : "51380" },
{ code : "51581", intitule : "TRESLON", cp : "51140" },
{ code : "51582", intitule : "TRIGNY", cp : "51140" },
{ code : "51583", intitule : "TROIS FONTAINES L ABBAYE", cp : "51340" },
{ code : "51584", intitule : "TROIS PUITS", cp : "51500" },
{ code : "51585", intitule : "TROISSY", cp : "51700" },
{ code : "51586", intitule : "UNCHAIR", cp : "51170" },
{ code : "51587", intitule : "VADENAY", cp : "51400" },
{ code : "51588", intitule : "VALMY", cp : "51800" },
{ code : "51589", intitule : "VANAULT LE CHATEL", cp : "51330" },
{ code : "51590", intitule : "VANAULT LES DAMES", cp : "51340" },
{ code : "51591", intitule : "VANDEUIL", cp : "51140" },
{ code : "51592", intitule : "VANDIERES", cp : "51700" },
{ code : "51594", intitule : "VASSIMONT ET CHAPELAINE", cp : "51320" },
{ code : "51595", intitule : "VATRY", cp : "51320" },
{ code : "51596", intitule : "VAUCHAMPS", cp : "51210" },
{ code : "51597", intitule : "VAUCIENNES", cp : "51480" },
{ code : "51598", intitule : "VAUCLERC", cp : "51300" },
{ code : "51599", intitule : "VAUDEMANGE", cp : "51380" },
{ code : "51600", intitule : "VAUDESINCOURT", cp : "51600" },
{ code : "51601", intitule : "VAVRAY LE GRAND", cp : "51300" },
{ code : "51602", intitule : "VAVRAY LE PETIT", cp : "51300" },
{ code : "51603", intitule : "VELYE", cp : "51130" },
{ code : "51604", intitule : "VENTELAY", cp : "51140" },
{ code : "51605", intitule : "VENTEUIL", cp : "51480" },
{ code : "51607", intitule : "VERDON", cp : "51210" },
{ code : "51608", intitule : "VERNANCOURT", cp : "51330" },
{ code : "51609", intitule : "VERNEUIL", cp : "51700" },
{ code : "51610", intitule : "VERRIERES", cp : "51800" },
{ code : "51611", intitule : "VERT TOULON", cp : "51130" },
{ code : "51611", intitule : "VERT TOULON", cp : "51130" },
{ code : "51612", intitule : "VERTUS", cp : "51130" },
{ code : "51613", intitule : "VERZENAY", cp : "51360" },
{ code : "51614", intitule : "VERZY", cp : "51380" },
{ code : "51616", intitule : "VESIGNEUL SUR MARNE", cp : "51240" },
{ code : "51617", intitule : "LA VEUVE", cp : "51520" },
{ code : "51618", intitule : "LE VEZIER", cp : "51210" },
{ code : "51619", intitule : "LE VIEIL DAMPIERRE", cp : "51330" },
{ code : "51620", intitule : "VIENNE LA VILLE", cp : "51800" },
{ code : "51621", intitule : "VIENNE LE CHATEAU", cp : "51800" },
{ code : "51622", intitule : "VILLE DOMMANGE", cp : "51390" },
{ code : "51623", intitule : "VILLE EN SELVE", cp : "51500" },
{ code : "51624", intitule : "VILLE EN TARDENOIS", cp : "51170" },
{ code : "51625", intitule : "VILLENEUVE LA LIONNE", cp : "51310" },
{ code : "51626", intitule : "LA VILLENEUVE LES CHARLEVILLE", cp : "51120" },
{ code : "51627", intitule : "VILLENEUVE RENNEVILLE CHEVIGNY", cp : "51130" },
{ code : "51628", intitule : "VILLENEUVE ST VISTRE ET VILLEVOTTE", cp : "51120" },
{ code : "51629", intitule : "VILLERS ALLERAND", cp : "51500" },
{ code : "51630", intitule : "VILLERS AUX BOIS", cp : "51130" },
{ code : "51631", intitule : "VILLERS AUX NOEUDS", cp : "51500" },
{ code : "51632", intitule : "VILLERS EN ARGONNE", cp : "51800" },
{ code : "51633", intitule : "VILLERS FRANQUEUX", cp : "51220" },
{ code : "51634", intitule : "VILLERS LE CHATEAU", cp : "51510" },
{ code : "51635", intitule : "VILLERS LE SEC", cp : "51250" },
{ code : "51636", intitule : "VILLERS MARMERY", cp : "51380" },
{ code : "51637", intitule : "VILLERS SOUS CHATILLON", cp : "51700" },
{ code : "51638", intitule : "VILLESENEUX", cp : "51130" },
{ code : "51639", intitule : "LA VILLE SOUS ORBAIS", cp : "51270" },
{ code : "51640", intitule : "VILLE SUR TOURBE", cp : "51800" },
{ code : "51641", intitule : "VILLEVENARD", cp : "51270" },
{ code : "51642", intitule : "VILLIERS AUX CORNEILLES", cp : "51260" },
{ code : "51643", intitule : "VINAY", cp : "51530" },
{ code : "51644", intitule : "VINCELLES", cp : "51700" },
{ code : "51645", intitule : "VINDEY", cp : "51120" },
{ code : "51646", intitule : "VIRGINY", cp : "51800" },
{ code : "51647", intitule : "VITRY EN PERTHOIS", cp : "51300" },
{ code : "51648", intitule : "VITRY LA VILLE", cp : "51240" },
{ code : "51648", intitule : "VITRY LA VILLE", cp : "51240" },
{ code : "51649", intitule : "VITRY LE FRANCOIS", cp : "51300" },
{ code : "51650", intitule : "VOILEMONT", cp : "51800" },
{ code : "51651", intitule : "VOIPREUX", cp : "51130" },
{ code : "51652", intitule : "VOUARCES", cp : "51260" },
{ code : "51654", intitule : "VOUILLERS", cp : "51340" },
{ code : "51655", intitule : "VOUZY", cp : "51130" },
{ code : "51656", intitule : "VRAUX", cp : "51150" },
{ code : "51657", intitule : "VRIGNY", cp : "51390" },
{ code : "51658", intitule : "VROIL", cp : "51330" },
{ code : "51659", intitule : "WARGEMOULIN HURLUS", cp : "51800" },
{ code : "51660", intitule : "WARMERIVILLE", cp : "51110" },
{ code : "51662", intitule : "WITRY LES REIMS", cp : "51420" },
{ code : "51663", intitule : "MAGENTA", cp : "51530" },
{ code : "52001", intitule : "AGEVILLE", cp : "52340" },
{ code : "52002", intitule : "AIGREMONT", cp : "52400" },
{ code : "52003", intitule : "AILLIANVILLE", cp : "52700" },
{ code : "52004", intitule : "AINGOULAINCOURT", cp : "52230" },
{ code : "52005", intitule : "AIZANVILLE", cp : "52120" },
{ code : "52006", intitule : "ALLICHAMPS", cp : "52130" },
{ code : "52007", intitule : "AMBONVILLE", cp : "52110" },
{ code : "52008", intitule : "ANDELOT BLANCHEVILLE", cp : "52700" },
{ code : "52008", intitule : "ANDELOT BLANCHEVILLE", cp : "52700" },
{ code : "52009", intitule : "ANDILLY EN BASSIGNY", cp : "52360" },
{ code : "52011", intitule : "ANNEVILLE LA PRAIRIE", cp : "52310" },
{ code : "52012", intitule : "ANNONVILLE", cp : "52230" },
{ code : "52013", intitule : "ANROSEY", cp : "52500" },
{ code : "52014", intitule : "APREY", cp : "52250" },
{ code : "52015", intitule : "ARBIGNY SOUS VARENNES", cp : "52500" },
{ code : "52016", intitule : "ARBOT", cp : "52160" },
{ code : "52017", intitule : "ARC EN BARROIS", cp : "52210" },
{ code : "52019", intitule : "ARNANCOURT", cp : "52110" },
{ code : "52021", intitule : "ATTANCOURT", cp : "52130" },
{ code : "52022", intitule : "AUBEPIERRE SUR AUBE", cp : "52210" },
{ code : "52023", intitule : "AUBERIVE", cp : "52160" },
{ code : "52025", intitule : "AUDELONCOURT", cp : "52240" },
{ code : "52027", intitule : "AUJEURRES", cp : "52190" },
{ code : "52028", intitule : "AULNOY SUR AUBE", cp : "52160" },
{ code : "52029", intitule : "AUTIGNY LE GRAND", cp : "52300" },
{ code : "52030", intitule : "AUTIGNY LE PETIT", cp : "52300" },
{ code : "52031", intitule : "AUTREVILLE SUR LA RENNE", cp : "52120" },
{ code : "52031", intitule : "AUTREVILLE SUR LA RENNE", cp : "52120" },
{ code : "52031", intitule : "AUTREVILLE SUR LA RENNE", cp : "52330" },
{ code : "52031", intitule : "AUTREVILLE SUR LA RENNE", cp : "52330" },
{ code : "52033", intitule : "AVRECOURT", cp : "52140" },
{ code : "52034", intitule : "BAILLY AUX FORGES", cp : "52130" },
{ code : "52035", intitule : "BAISSEY", cp : "52250" },
{ code : "52037", intitule : "BANNES", cp : "52360" },
{ code : "52038", intitule : "BASSONCOURT", cp : "52240" },
{ code : "52039", intitule : "BAUDRECOURT", cp : "52110" },
{ code : "52040", intitule : "BAY SUR AUBE", cp : "52160" },
{ code : "52042", intitule : "BEAUCHEMIN", cp : "52260" },
{ code : "52043", intitule : "BELMONT", cp : "52500" },
{ code : "52044", intitule : "ROCHES BETTAINCOURT", cp : "52270" },
{ code : "52044", intitule : "ROCHES BETTAINCOURT", cp : "52270" },
{ code : "52044", intitule : "ROCHES BETTAINCOURT", cp : "52270" },
{ code : "52045", intitule : "BETTANCOURT LA FERREE", cp : "52100" },
{ code : "52047", intitule : "BEURVILLE", cp : "52110" },
{ code : "52050", intitule : "BIESLES", cp : "52340" },
{ code : "52050", intitule : "BIESLES", cp : "52340" },
{ code : "52051", intitule : "BIZE", cp : "52500" },
{ code : "52053", intitule : "BLAISY", cp : "52330" },
{ code : "52055", intitule : "BLECOURT", cp : "52300" },
{ code : "52056", intitule : "BLESSONVILLE", cp : "52120" },
{ code : "52057", intitule : "BLUMERAY", cp : "52110" },
{ code : "52058", intitule : "BOLOGNE", cp : "52310" },
{ code : "52058", intitule : "BOLOGNE", cp : "52310" },
{ code : "52058", intitule : "BOLOGNE", cp : "52310" },
{ code : "52059", intitule : "BONNECOURT", cp : "52360" },
{ code : "52060", intitule : "BOURBONNE LES BAINS", cp : "52400" },
{ code : "52060", intitule : "BOURBONNE LES BAINS", cp : "52400" },
{ code : "52060", intitule : "BOURBONNE LES BAINS", cp : "52400" },
{ code : "52061", intitule : "BOURDONS SUR ROGNON", cp : "52700" },
{ code : "52062", intitule : "BOURG", cp : "52200" },
{ code : "52063", intitule : "BOURG STE MARIE", cp : "52150" },
{ code : "52064", intitule : "BOURMONT", cp : "52150" },
{ code : "52064", intitule : "BOURMONT", cp : "52150" },
{ code : "52065", intitule : "BOUZANCOURT", cp : "52110" },
{ code : "52066", intitule : "BRACHAY", cp : "52110" },
{ code : "52067", intitule : "BRAINVILLE SUR MEUSE", cp : "52150" },
{ code : "52069", intitule : "BRAUX LE CHATEL", cp : "52120" },
{ code : "52070", intitule : "BRENNES", cp : "52200" },
{ code : "52072", intitule : "BRETHENAY", cp : "52000" },
{ code : "52074", intitule : "BREUVANNES EN BASSIGNY", cp : "52240" },
{ code : "52074", intitule : "BREUVANNES EN BASSIGNY", cp : "52240" },
{ code : "52074", intitule : "BREUVANNES EN BASSIGNY", cp : "52240" },
{ code : "52075", intitule : "BRIAUCOURT", cp : "52700" },
{ code : "52076", intitule : "BRICON", cp : "52120" },
{ code : "52079", intitule : "BROUSSEVAL", cp : "52130" },
{ code : "52082", intitule : "BUGNIERES", cp : "52210" },
{ code : "52083", intitule : "CHAMPSEVRAINE", cp : "52500" },
{ code : "52083", intitule : "CHAMPSEVRAINE", cp : "52500" },
{ code : "52083", intitule : "CHAMPSEVRAINE", cp : "52500" },
{ code : "52084", intitule : "BUSSON", cp : "52700" },
{ code : "52085", intitule : "BUXIERES LES CLEFMONT", cp : "52240" },
{ code : "52087", intitule : "BUXIERES LES VILLIERS", cp : "52000" },
{ code : "52088", intitule : "CEFFONDS", cp : "52220" },
{ code : "52088", intitule : "CEFFONDS", cp : "52220" },
{ code : "52088", intitule : "CEFFONDS", cp : "52220" },
{ code : "52089", intitule : "CELLES EN BASSIGNY", cp : "52360" },
{ code : "52090", intitule : "CELSOY", cp : "52600" },
{ code : "52091", intitule : "CERISIERES", cp : "52320" },
{ code : "52092", intitule : "CHALANCEY", cp : "52160" },
{ code : "52093", intitule : "CHALINDREY", cp : "52600" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52094", intitule : "VALS DES TILLES", cp : "52160" },
{ code : "52095", intitule : "CHALVRAINES", cp : "52700" },
{ code : "52097", intitule : "CHAMBRONCOURT", cp : "52700" },
{ code : "52099", intitule : "CHAMOUILLEY", cp : "52410" },
{ code : "52101", intitule : "CHAMPIGNEULLES EN BASSIGNY", cp : "52150" },
{ code : "52102", intitule : "CHAMPIGNY LES LANGRES", cp : "52200" },
{ code : "52103", intitule : "CHAMPIGNY SOUS VARENNES", cp : "52400" },
{ code : "52104", intitule : "CHANCENAY", cp : "52100" },
{ code : "52105", intitule : "CHANGEY", cp : "52360" },
{ code : "52106", intitule : "CHANOY", cp : "52260" },
{ code : "52107", intitule : "CHANTRAINES", cp : "52700" },
{ code : "52108", intitule : "CHARMES", cp : "52360" },
{ code : "52109", intitule : "CHARMES EN L ANGLE", cp : "52110" },
{ code : "52110", intitule : "CHARMES LA GRANDE", cp : "52110" },
{ code : "52113", intitule : "CHASSIGNY", cp : "52190" },
{ code : "52114", intitule : "CHATEAUVILLAIN", cp : "52120" },
{ code : "52114", intitule : "CHATEAUVILLAIN", cp : "52120" },
{ code : "52114", intitule : "CHATEAUVILLAIN", cp : "52120" },
{ code : "52114", intitule : "CHATEAUVILLAIN", cp : "52120" },
{ code : "52115", intitule : "CHATENAY MACHERON", cp : "52200" },
{ code : "52116", intitule : "CHATENAY VAUDIN", cp : "52360" },
{ code : "52118", intitule : "CHATONRUPT SOMMERMONT", cp : "52300" },
{ code : "52118", intitule : "CHATONRUPT SOMMERMONT", cp : "52300" },
{ code : "52119", intitule : "CHAUDENAY", cp : "52600" },
{ code : "52120", intitule : "CHAUFFOURT", cp : "52140" },
{ code : "52121", intitule : "CHAUMONT", cp : "52000" },
{ code : "52121", intitule : "CHAUMONT", cp : "52000" },
{ code : "52122", intitule : "CHAUMONT LA VILLE", cp : "52150" },
{ code : "52123", intitule : "CHEVILLON", cp : "52170" },
{ code : "52123", intitule : "CHEVILLON", cp : "52170" },
{ code : "52123", intitule : "CHEVILLON", cp : "52170" },
{ code : "52124", intitule : "CHEZEAUX", cp : "52400" },
{ code : "52125", intitule : "CHAMARANDES CHOIGNES", cp : "52000" },
{ code : "52125", intitule : "CHAMARANDES CHOIGNES", cp : "52000" },
{ code : "52125", intitule : "CHAMARANDES CHOIGNES", cp : "52000" },
{ code : "52126", intitule : "CHOILLEY DARDENAY", cp : "52190" },
{ code : "52126", intitule : "CHOILLEY DARDENAY", cp : "52190" },
{ code : "52127", intitule : "CHOISEUL", cp : "52240" },
{ code : "52128", intitule : "CIREY LES MAREILLES", cp : "52700" },
{ code : "52129", intitule : "CIREY SUR BLAISE", cp : "52110" },
{ code : "52130", intitule : "CIRFONTAINES EN AZOIS", cp : "52370" },
{ code : "52131", intitule : "CIRFONTAINES EN ORNOIS", cp : "52230" },
{ code : "52132", intitule : "CLEFMONT", cp : "52240" },
{ code : "52133", intitule : "CLINCHAMP", cp : "52700" },
{ code : "52134", intitule : "COHONS", cp : "52600" },
{ code : "52135", intitule : "COIFFY LE BAS", cp : "52400" },
{ code : "52136", intitule : "COIFFY LE HAUT", cp : "52400" },
{ code : "52137", intitule : "COLMIER LE BAS", cp : "52160" },
{ code : "52138", intitule : "COLMIER LE HAUT", cp : "52160" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52140", intitule : "COLOMBEY LES DEUX EGLISES", cp : "52330" },
{ code : "52141", intitule : "CONDES", cp : "52000" },
{ code : "52142", intitule : "CONSIGNY", cp : "52700" },
{ code : "52145", intitule : "COUBLANC", cp : "52500" },
{ code : "52146", intitule : "COUPRAY", cp : "52210" },
{ code : "52147", intitule : "COURCELLES EN MONTAGNE", cp : "52200" },
{ code : "52149", intitule : "COURCELLES SUR BLAISE", cp : "52110" },
{ code : "52151", intitule : "COUR L EVEQUE", cp : "52210" },
{ code : "52155", intitule : "CULMONT", cp : "52600" },
{ code : "52156", intitule : "CUREL", cp : "52300" },
{ code : "52157", intitule : "CURMONT", cp : "52330" },
{ code : "52158", intitule : "CUSEY", cp : "52190" },
{ code : "52158", intitule : "CUSEY", cp : "52190" },
{ code : "52159", intitule : "CUVES", cp : "52240" },
{ code : "52160", intitule : "DAILLANCOURT", cp : "52110" },
{ code : "52161", intitule : "DAILLECOURT", cp : "52240" },
{ code : "52162", intitule : "DAMMARTIN SUR MEUSE", cp : "52140" },
{ code : "52163", intitule : "DAMPIERRE", cp : "52360" },
{ code : "52164", intitule : "DAMREMONT", cp : "52400" },
{ code : "52165", intitule : "DANCEVOIR", cp : "52210" },
{ code : "52167", intitule : "DARMANNES", cp : "52700" },
{ code : "52168", intitule : "DINTEVILLE", cp : "52120" },
{ code : "52169", intitule : "DOMBLAIN", cp : "52130" },
{ code : "52170", intitule : "DOMMARIEN", cp : "52190" },
{ code : "52171", intitule : "DOMMARTIN LE FRANC", cp : "52110" },
{ code : "52172", intitule : "DOMMARTIN LE ST PERE", cp : "52110" },
{ code : "52173", intitule : "DOMREMY LANDEVILLE", cp : "52270" },
{ code : "52173", intitule : "DOMREMY LANDEVILLE", cp : "52270" },
{ code : "52174", intitule : "DONCOURT SUR MEUSE", cp : "52150" },
{ code : "52175", intitule : "DONJEUX", cp : "52300" },
{ code : "52177", intitule : "DOULAINCOURT SAUCOURT", cp : "52270" },
{ code : "52177", intitule : "DOULAINCOURT SAUCOURT", cp : "52270" },
{ code : "52178", intitule : "DOULEVANT LE CHATEAU", cp : "52110" },
{ code : "52178", intitule : "DOULEVANT LE CHATEAU", cp : "52110" },
{ code : "52179", intitule : "DOULEVANT LE PETIT", cp : "52130" },
{ code : "52181", intitule : "ECHENAY", cp : "52230" },
{ code : "52182", intitule : "ECLARON BRAUCOURT STE LIVIERE", cp : "52290" },
{ code : "52182", intitule : "ECLARON BRAUCOURT STE LIVIERE", cp : "52290" },
{ code : "52182", intitule : "ECLARON BRAUCOURT STE LIVIERE", cp : "52290" },
{ code : "52183", intitule : "ECOT LA COMBE", cp : "52700" },
{ code : "52184", intitule : "EFFINCOURT", cp : "52300" },
{ code : "52185", intitule : "ENFONVELLE", cp : "52400" },
{ code : "52187", intitule : "EPIZON", cp : "52270" },
{ code : "52187", intitule : "EPIZON", cp : "52270" },
{ code : "52187", intitule : "EPIZON", cp : "52230" },
{ code : "52187", intitule : "EPIZON", cp : "52230" },
{ code : "52189", intitule : "LE VAL D ESNOMS", cp : "52190" },
{ code : "52189", intitule : "LE VAL D ESNOMS", cp : "52190" },
{ code : "52189", intitule : "LE VAL D ESNOMS", cp : "52190" },
{ code : "52189", intitule : "LE VAL D ESNOMS", cp : "52190" },
{ code : "52190", intitule : "ESNOUVEAUX", cp : "52340" },
{ code : "52193", intitule : "EUFFIGNEIX", cp : "52000" },
{ code : "52194", intitule : "EURVILLE BIENVILLE", cp : "52410" },
{ code : "52194", intitule : "EURVILLE BIENVILLE", cp : "52410" },
{ code : "52195", intitule : "FARINCOURT", cp : "52500" },
{ code : "52196", intitule : "FAVEROLLES", cp : "52260" },
{ code : "52197", intitule : "FAYL BILLOT", cp : "52500" },
{ code : "52197", intitule : "FAYL BILLOT", cp : "52500" },
{ code : "52197", intitule : "FAYL BILLOT", cp : "52500" },
{ code : "52198", intitule : "FAYS", cp : "52130" },
{ code : "52199", intitule : "FERRIERE ET LAFOLIE", cp : "52300" },
{ code : "52200", intitule : "FLAGEY", cp : "52250" },
{ code : "52201", intitule : "FLAMMERECOURT", cp : "52110" },
{ code : "52203", intitule : "FONTAINES SUR MARNE", cp : "52170" },
{ code : "52204", intitule : "FORCEY", cp : "52700" },
{ code : "52205", intitule : "FOULAIN", cp : "52800" },
{ code : "52205", intitule : "FOULAIN", cp : "52000" },
{ code : "52206", intitule : "FRAMPAS", cp : "52220" },
{ code : "52207", intitule : "FRECOURT", cp : "52360" },
{ code : "52208", intitule : "FRESNES SUR APANCE", cp : "52400" },
{ code : "52211", intitule : "FRONCLES", cp : "52320" },
{ code : "52211", intitule : "FRONCLES", cp : "52320" },
{ code : "52211", intitule : "FRONCLES", cp : "52320" },
{ code : "52212", intitule : "FRONVILLE", cp : "52300" },
{ code : "52213", intitule : "GENEVRIERES", cp : "52500" },
{ code : "52214", intitule : "LA GENEVROYE", cp : "52320" },
{ code : "52216", intitule : "GERMAINES", cp : "52160" },
{ code : "52217", intitule : "GERMAINVILLIERS", cp : "52150" },
{ code : "52218", intitule : "GERMAY", cp : "52230" },
{ code : "52219", intitule : "GERMISAY", cp : "52230" },
{ code : "52220", intitule : "GIEY SUR AUJON", cp : "52210" },
{ code : "52221", intitule : "GILLANCOURT", cp : "52330" },
{ code : "52222", intitule : "GILLAUME", cp : "52230" },
{ code : "52223", intitule : "GILLEY", cp : "52500" },
{ code : "52225", intitule : "GONCOURT", cp : "52150" },
{ code : "52227", intitule : "GRAFFIGNY CHEMIN", cp : "52150" },
{ code : "52228", intitule : "GRANDCHAMP", cp : "52600" },
{ code : "52229", intitule : "GRENANT", cp : "52500" },
{ code : "52230", intitule : "GUDMONT VILLIERS", cp : "52320" },
{ code : "52230", intitule : "GUDMONT VILLIERS", cp : "52320" },
{ code : "52231", intitule : "GUINDRECOURT AUX ORMES", cp : "52300" },
{ code : "52232", intitule : "GUINDRECOURT SUR BLAISE", cp : "52330" },
{ code : "52233", intitule : "GUYONVELLE", cp : "52400" },
{ code : "52234", intitule : "HACOURT", cp : "52150" },
{ code : "52235", intitule : "HALLIGNICOURT", cp : "52100" },
{ code : "52237", intitule : "HARREVILLE LES CHANTEURS", cp : "52150" },
{ code : "52240", intitule : "HEUILLEY LE GRAND", cp : "52600" },
{ code : "52242", intitule : "HAUTE AMANCE", cp : "52600" },
{ code : "52242", intitule : "HAUTE AMANCE", cp : "52600" },
{ code : "52242", intitule : "HAUTE AMANCE", cp : "52600" },
{ code : "52242", intitule : "HAUTE AMANCE", cp : "52600" },
{ code : "52242", intitule : "HAUTE AMANCE", cp : "52600" },
{ code : "52243", intitule : "HUILLIECOURT", cp : "52150" },
{ code : "52244", intitule : "HUMBECOURT", cp : "52290" },
{ code : "52245", intitule : "HUMBERVILLE", cp : "52700" },
{ code : "52246", intitule : "HUMES JORQUENAY", cp : "52200" },
{ code : "52246", intitule : "HUMES JORQUENAY", cp : "52200" },
{ code : "52247", intitule : "ILLOUD", cp : "52150" },
{ code : "52248", intitule : "IS EN BASSIGNY", cp : "52140" },
{ code : "52249", intitule : "ISOMES", cp : "52190" },
{ code : "52250", intitule : "JOINVILLE", cp : "52300" },
{ code : "52251", intitule : "JONCHERY", cp : "52000" },
{ code : "52251", intitule : "JONCHERY", cp : "52000" },
{ code : "52251", intitule : "JONCHERY", cp : "52000" },
{ code : "52253", intitule : "JUZENNECOURT", cp : "52330" },
{ code : "52254", intitule : "LACHAPELLE EN BLAISY", cp : "52330" },
{ code : "52256", intitule : "LAFAUCHE", cp : "52700" },
{ code : "52257", intitule : "LAFERTE SUR AMANCE", cp : "52500" },
{ code : "52258", intitule : "LAFERTE SUR AUBE", cp : "52120" },
{ code : "52260", intitule : "LAMANCINE", cp : "52310" },
{ code : "52262", intitule : "LAMOTHE EN BLAISY", cp : "52330" },
{ code : "52264", intitule : "LANEUVELLE", cp : "52400" },
{ code : "52265", intitule : "BAYARD SUR MARNE", cp : "52170" },
{ code : "52265", intitule : "BAYARD SUR MARNE", cp : "52170" },
{ code : "52265", intitule : "BAYARD SUR MARNE", cp : "52170" },
{ code : "52265", intitule : "BAYARD SUR MARNE", cp : "52170" },
{ code : "52266", intitule : "LANEUVILLE A REMY", cp : "52220" },
{ code : "52267", intitule : "LANEUVILLE AU PONT", cp : "52100" },
{ code : "52269", intitule : "LANGRES", cp : "52200" },
{ code : "52269", intitule : "LANGRES", cp : "52200" },
{ code : "52271", intitule : "LANQUES SUR ROGNON", cp : "52800" },
{ code : "52272", intitule : "LANTY SUR AUBE", cp : "52120" },
{ code : "52273", intitule : "LARIVIERE ARNONCOURT", cp : "52400" },
{ code : "52273", intitule : "LARIVIERE ARNONCOURT", cp : "52400" },
{ code : "52274", intitule : "LATRECEY ORMOY SUR AUBE", cp : "52120" },
{ code : "52274", intitule : "LATRECEY ORMOY SUR AUBE", cp : "52120" },
{ code : "52275", intitule : "LAVERNOY", cp : "52140" },
{ code : "52276", intitule : "LAVILLE AUX BOIS", cp : "52000" },
{ code : "52277", intitule : "LAVILLENEUVE", cp : "52140" },
{ code : "52278", intitule : "LAVILLENEUVE AU ROI", cp : "52330" },
{ code : "52280", intitule : "LECEY", cp : "52360" },
{ code : "52282", intitule : "LEFFONDS", cp : "52210" },
{ code : "52284", intitule : "LESCHERES SUR LE BLAISERON", cp : "52110" },
{ code : "52285", intitule : "LEUCHEY", cp : "52190" },
{ code : "52286", intitule : "LEURVILLE", cp : "52700" },
{ code : "52287", intitule : "LEVECOURT", cp : "52150" },
{ code : "52288", intitule : "LEZEVILLE", cp : "52230" },
{ code : "52288", intitule : "LEZEVILLE", cp : "52230" },
{ code : "52288", intitule : "LEZEVILLE", cp : "52230" },
{ code : "52289", intitule : "LIFFOL LE PETIT", cp : "52700" },
{ code : "52290", intitule : "LES LOGES", cp : "52500" },
{ code : "52291", intitule : "LONGCHAMP", cp : "52240" },
{ code : "52292", intitule : "LONGEAU PERCEY", cp : "52250" },
{ code : "52292", intitule : "LONGEAU PERCEY", cp : "52250" },
{ code : "52292", intitule : "LONGEAU PERCEY", cp : "52250" },
{ code : "52294", intitule : "LOUVEMONT", cp : "52130" },
{ code : "52295", intitule : "LOUVIERES", cp : "52800" },
{ code : "52297", intitule : "LUZY SUR MARNE", cp : "52000" },
{ code : "52298", intitule : "MAATZ", cp : "52500" },
{ code : "52300", intitule : "MAGNEUX", cp : "52130" },
{ code : "52301", intitule : "MAISONCELLES", cp : "52240" },
{ code : "52302", intitule : "MAIZIERES", cp : "52300" },
{ code : "52303", intitule : "MAIZIERES SUR AMANCE", cp : "52500" },
{ code : "52304", intitule : "MALAINCOURT SUR MEUSE", cp : "52150" },
{ code : "52305", intitule : "MANDRES LA COTE", cp : "52800" },
{ code : "52306", intitule : "MANOIS", cp : "52700" },
{ code : "52307", intitule : "MARAC", cp : "52260" },
{ code : "52308", intitule : "MARANVILLE", cp : "52370" },
{ code : "52310", intitule : "MARBEVILLE", cp : "52320" },
{ code : "52311", intitule : "MARCILLY EN BASSIGNY", cp : "52360" },
{ code : "52312", intitule : "MARDOR", cp : "52200" },
{ code : "52313", intitule : "MAREILLES", cp : "52700" },
{ code : "52315", intitule : "MARNAY SUR MARNE", cp : "52800" },
{ code : "52316", intitule : "MATHONS", cp : "52300" },
{ code : "52318", intitule : "MELAY", cp : "52400" },
{ code : "52319", intitule : "MENNOUVEAUX", cp : "52240" },
{ code : "52320", intitule : "MERREY", cp : "52240" },
{ code : "52321", intitule : "MERTRUD", cp : "52110" },
{ code : "52322", intitule : "MEURES", cp : "52310" },
{ code : "52325", intitule : "MILLIERES", cp : "52240" },
{ code : "52326", intitule : "MIRBEL", cp : "52320" },
{ code : "52327", intitule : "MOESLAINS", cp : "52100" },
{ code : "52328", intitule : "MONTCHARVOT", cp : "52400" },
{ code : "52330", intitule : "MONTHERIES", cp : "52330" },
{ code : "52331", intitule : "LA PORTE DU DER", cp : "52220" },
{ code : "52331", intitule : "LA PORTE DU DER", cp : "52220" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52240" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52332", intitule : "VAL DE MEUSE", cp : "52140" },
{ code : "52335", intitule : "MONTOT SUR ROGNON", cp : "52700" },
{ code : "52336", intitule : "MONTREUIL SUR BLAISE", cp : "52130" },
{ code : "52337", intitule : "MONTREUIL SUR THONNANCE", cp : "52230" },
{ code : "52341", intitule : "MORANCOURT", cp : "52110" },
{ code : "52342", intitule : "MORIONVILLIERS", cp : "52700" },
{ code : "52344", intitule : "MOUILLERON", cp : "52160" },
{ code : "52346", intitule : "MUSSEY SUR MARNE", cp : "52300" },
{ code : "52347", intitule : "NARCY", cp : "52170" },
{ code : "52348", intitule : "NEUILLY L EVEQUE", cp : "52360" },
{ code : "52349", intitule : "NEUILLY SUR SUIZE", cp : "52000" },
{ code : "52350", intitule : "NEUVELLE LES VOISEY", cp : "52400" },
{ code : "52351", intitule : "NIJON", cp : "52150" },
{ code : "52352", intitule : "NINVILLE", cp : "52800" },
{ code : "52353", intitule : "NOGENT", cp : "52800" },
{ code : "52353", intitule : "NOGENT", cp : "52800" },
{ code : "52353", intitule : "NOGENT", cp : "52800" },
{ code : "52353", intitule : "NOGENT", cp : "52800" },
{ code : "52354", intitule : "NOIDANT CHATENOY", cp : "52600" },
{ code : "52355", intitule : "NOIDANT LE ROCHEUX", cp : "52200" },
{ code : "52356", intitule : "NOMECOURT", cp : "52300" },
{ code : "52357", intitule : "NONCOURT SUR LE RONGEANT", cp : "52230" },
{ code : "52358", intitule : "NOYERS", cp : "52240" },
{ code : "52359", intitule : "NULLY", cp : "52110" },
{ code : "52360", intitule : "OCCEY", cp : "52190" },
{ code : "52362", intitule : "ORBIGNY AU MONT", cp : "52360" },
{ code : "52363", intitule : "ORBIGNY AU VAL", cp : "52360" },
{ code : "52364", intitule : "ORCEVAUX", cp : "52250" },
{ code : "52365", intitule : "ORGES", cp : "52120" },
{ code : "52366", intitule : "ORMANCEY", cp : "52200" },
{ code : "52367", intitule : "ORMOY LES SEXFONTAINES", cp : "52310" },
{ code : "52369", intitule : "ORQUEVAUX", cp : "52700" },
{ code : "52370", intitule : "OSNE LE VAL", cp : "52300" },
{ code : "52371", intitule : "OUDINCOURT", cp : "52310" },
{ code : "52372", intitule : "OUTREMECOURT", cp : "52150" },
{ code : "52373", intitule : "OZIERES", cp : "52700" },
{ code : "52374", intitule : "LE PAILLY", cp : "52600" },
{ code : "52375", intitule : "PALAISEUL", cp : "52600" },
{ code : "52376", intitule : "PANSEY", cp : "52230" },
{ code : "52377", intitule : "PARNOY EN BASSIGNY", cp : "52400" },
{ code : "52377", intitule : "PARNOY EN BASSIGNY", cp : "52400" },
{ code : "52377", intitule : "PARNOY EN BASSIGNY", cp : "52400" },
{ code : "52378", intitule : "PAROY SUR SAULX", cp : "52300" },
{ code : "52380", intitule : "PEIGNEY", cp : "52200" },
{ code : "52383", intitule : "PERRANCEY LES VIEUX MOULINS", cp : "52200" },
{ code : "52383", intitule : "PERRANCEY LES VIEUX MOULINS", cp : "52200" },
{ code : "52384", intitule : "PERROGNEY LES FONTAINES", cp : "52160" },
{ code : "52384", intitule : "PERROGNEY LES FONTAINES", cp : "52160" },
{ code : "52385", intitule : "PERRUSSE", cp : "52240" },
{ code : "52386", intitule : "PERTHES", cp : "52100" },
{ code : "52388", intitule : "PIERREMONT SUR AMANCE", cp : "52500" },
{ code : "52388", intitule : "PIERREMONT SUR AMANCE", cp : "52500" },
{ code : "52388", intitule : "PIERREMONT SUR AMANCE", cp : "52500" },
{ code : "52390", intitule : "PISSELOUP", cp : "52500" },
{ code : "52391", intitule : "PLANRUPT", cp : "52220" },
{ code : "52392", intitule : "PLESNOY", cp : "52360" },
{ code : "52393", intitule : "POINSENOT", cp : "52160" },
{ code : "52394", intitule : "POINSON LES FAYL", cp : "52500" },
{ code : "52395", intitule : "POINSON LES GRANCEY", cp : "52160" },
{ code : "52396", intitule : "POINSON LES NOGENT", cp : "52800" },
{ code : "52397", intitule : "POISEUL", cp : "52360" },
{ code : "52398", intitule : "POISSONS", cp : "52230" },
{ code : "52399", intitule : "PONT LA VILLE", cp : "52120" },
{ code : "52400", intitule : "LE CHATELET SUR MEUSE", cp : "52400" },
{ code : "52400", intitule : "LE CHATELET SUR MEUSE", cp : "52400" },
{ code : "52400", intitule : "LE CHATELET SUR MEUSE", cp : "52400" },
{ code : "52401", intitule : "POULANGY", cp : "52800" },
{ code : "52403", intitule : "PRASLAY", cp : "52160" },
{ code : "52405", intitule : "LE MONTSAUGEONNAIS", cp : "52190" },
{ code : "52405", intitule : "LE MONTSAUGEONNAIS", cp : "52190" },
{ code : "52405", intitule : "LE MONTSAUGEONNAIS", cp : "52190" },
{ code : "52406", intitule : "PRESSIGNY", cp : "52500" },
{ code : "52407", intitule : "PREZ SOUS LAFAUCHE", cp : "52700" },
{ code : "52411", intitule : "RIVES DERVOISES", cp : "52220" },
{ code : "52411", intitule : "RIVES DERVOISES", cp : "52220" },
{ code : "52411", intitule : "RIVES DERVOISES", cp : "52220" },
{ code : "52411", intitule : "RIVES DERVOISES", cp : "52220" },
{ code : "52413", intitule : "RACHECOURT SUZEMONT", cp : "52130" },
{ code : "52414", intitule : "RACHECOURT SUR MARNE", cp : "52170" },
{ code : "52415", intitule : "RANCONNIERES", cp : "52140" },
{ code : "52416", intitule : "RANGECOURT", cp : "52140" },
{ code : "52419", intitule : "RENNEPONT", cp : "52370" },
{ code : "52420", intitule : "REYNEL", cp : "52700" },
{ code : "52421", intitule : "RIAUCOURT", cp : "52000" },
{ code : "52422", intitule : "RICHEBOURG", cp : "52120" },
{ code : "52423", intitule : "RIMAUCOURT", cp : "52700" },
{ code : "52424", intitule : "RIVIERES LE BOIS", cp : "52600" },
{ code : "52425", intitule : "RIVIERE LES FOSSES", cp : "52190" },
{ code : "52426", intitule : "RIZAUCOURT BUCHEY", cp : "52330" },
{ code : "52426", intitule : "RIZAUCOURT BUCHEY", cp : "52330" },
{ code : "52428", intitule : "ROCHEFORT SUR LA COTE", cp : "52700" },
{ code : "52429", intitule : "ROCHES SUR MARNE", cp : "52410" },
{ code : "52431", intitule : "ROCHETAILLEE", cp : "52210" },
{ code : "52431", intitule : "ROCHETAILLEE", cp : "52210" },
{ code : "52432", intitule : "ROLAMPONT", cp : "52260" },
{ code : "52432", intitule : "ROLAMPONT", cp : "52260" },
{ code : "52432", intitule : "ROLAMPONT", cp : "52260" },
{ code : "52432", intitule : "ROLAMPONT", cp : "52260" },
{ code : "52433", intitule : "ROMAIN SUR MEUSE", cp : "52150" },
{ code : "52436", intitule : "ROUECOURT", cp : "52320" },
{ code : "52437", intitule : "ROUELLES", cp : "52160" },
{ code : "52438", intitule : "ROUGEUX", cp : "52500" },
{ code : "52439", intitule : "ROUVRES SUR AUBE", cp : "52160" },
{ code : "52440", intitule : "ROUVROY SUR MARNE", cp : "52300" },
{ code : "52442", intitule : "RUPT", cp : "52300" },
{ code : "52443", intitule : "SAILLY", cp : "52230" },
{ code : "52444", intitule : "ST BLIN", cp : "52700" },
{ code : "52445", intitule : "ST BROINGT LE BOIS", cp : "52190" },
{ code : "52446", intitule : "ST BROINGT LES FOSSES", cp : "52190" },
{ code : "52447", intitule : "ST CIERGUES", cp : "52200" },
{ code : "52448", intitule : "ST DIZIER", cp : "52100" },
{ code : "52449", intitule : "SAINTS GEOSMES", cp : "52200" },
{ code : "52449", intitule : "SAINTS GEOSMES", cp : "52200" },
{ code : "52450", intitule : "ST LOUP SUR AUJON", cp : "52210" },
{ code : "52450", intitule : "ST LOUP SUR AUJON", cp : "52210" },
{ code : "52450", intitule : "ST LOUP SUR AUJON", cp : "52210" },
{ code : "52452", intitule : "ST MARTIN LES LANGRES", cp : "52200" },
{ code : "52453", intitule : "ST MAURICE", cp : "52200" },
{ code : "52455", intitule : "ST THIEBAULT", cp : "52150" },
{ code : "52456", intitule : "ST URBAIN MACONCOURT", cp : "52300" },
{ code : "52456", intitule : "ST URBAIN MACONCOURT", cp : "52300" },
{ code : "52457", intitule : "ST VALLIER SUR MARNE", cp : "52200" },
{ code : "52459", intitule : "SARCEY", cp : "52800" },
{ code : "52461", intitule : "SARREY", cp : "52140" },
{ code : "52463", intitule : "SAUDRON", cp : "52230" },
{ code : "52464", intitule : "SAULLES", cp : "52500" },
{ code : "52465", intitule : "SAULXURES", cp : "52140" },
{ code : "52467", intitule : "SAVIGNY", cp : "52500" },
{ code : "52468", intitule : "SEMILLY", cp : "52700" },
{ code : "52469", intitule : "SEMOUTIERS MONTSAON", cp : "52000" },
{ code : "52469", intitule : "SEMOUTIERS MONTSAON", cp : "52000" },
{ code : "52470", intitule : "SERQUEUX", cp : "52400" },
{ code : "52472", intitule : "SEXFONTAINES", cp : "52330" },
{ code : "52473", intitule : "SIGNEVILLE", cp : "52700" },
{ code : "52474", intitule : "SILVAROUVRES", cp : "52120" },
{ code : "52475", intitule : "SOMMANCOURT", cp : "52130" },
{ code : "52476", intitule : "SOMMERECOURT", cp : "52150" },
{ code : "52479", intitule : "SOMMEVOIRE", cp : "52220" },
{ code : "52479", intitule : "SOMMEVOIRE", cp : "52220" },
{ code : "52480", intitule : "SONCOURT SUR MARNE", cp : "52320" },
{ code : "52482", intitule : "SOULAUCOURT SUR MOUZON", cp : "52150" },
{ code : "52483", intitule : "SOYERS", cp : "52400" },
{ code : "52484", intitule : "SUZANNECOURT", cp : "52300" },
{ code : "52486", intitule : "TERNAT", cp : "52210" },
{ code : "52487", intitule : "THILLEUX", cp : "52220" },
{ code : "52488", intitule : "THIVET", cp : "52800" },
{ code : "52489", intitule : "THOL LES MILLIERES", cp : "52240" },
{ code : "52490", intitule : "THONNANCE LES JOINVILLE", cp : "52300" },
{ code : "52491", intitule : "THONNANCE LES MOULINS", cp : "52230" },
{ code : "52491", intitule : "THONNANCE LES MOULINS", cp : "52230" },
{ code : "52491", intitule : "THONNANCE LES MOULINS", cp : "52230" },
{ code : "52491", intitule : "THONNANCE LES MOULINS", cp : "52230" },
{ code : "52492", intitule : "TORCENAY", cp : "52600" },
{ code : "52493", intitule : "TORNAY", cp : "52500" },
{ code : "52494", intitule : "TREIX", cp : "52000" },
{ code : "52495", intitule : "TREMILLY", cp : "52110" },
{ code : "52497", intitule : "TROISFONTAINES LA VILLE", cp : "52130" },
{ code : "52497", intitule : "TROISFONTAINES LA VILLE", cp : "52130" },
{ code : "52497", intitule : "TROISFONTAINES LA VILLE", cp : "52130" },
{ code : "52497", intitule : "TROISFONTAINES LA VILLE", cp : "52130" },
{ code : "52499", intitule : "VAILLANT", cp : "52160" },
{ code : "52500", intitule : "VALCOURT", cp : "52100" },
{ code : "52502", intitule : "VALLERET", cp : "52130" },
{ code : "52503", intitule : "VALLEROY", cp : "52500" },
{ code : "52504", intitule : "VARENNES SUR AMANCE", cp : "52400" },
{ code : "52505", intitule : "VAUDRECOURT", cp : "52150" },
{ code : "52506", intitule : "VAUDREMONT", cp : "52330" },
{ code : "52507", intitule : "VAUXBONS", cp : "52200" },
{ code : "52510", intitule : "VAUX SUR BLAISE", cp : "52130" },
{ code : "52511", intitule : "VAUX SUR ST URBAIN", cp : "52300" },
{ code : "52512", intitule : "VECQUEVILLE", cp : "52300" },
{ code : "52513", intitule : "VELLES", cp : "52500" },
{ code : "52514", intitule : "VERBIESLES", cp : "52000" },
{ code : "52515", intitule : "VERSEILLES LE BAS", cp : "52250" },
{ code : "52516", intitule : "VERSEILLES LE HAUT", cp : "52250" },
{ code : "52517", intitule : "VESAIGNES SOUS LAFAUCHE", cp : "52700" },
{ code : "52518", intitule : "VESAIGNES SUR MARNE", cp : "52800" },
{ code : "52519", intitule : "VESVRES SOUS CHALANCEY", cp : "52190" },
{ code : "52520", intitule : "VICQ", cp : "52400" },
{ code : "52522", intitule : "VIEVILLE", cp : "52310" },
{ code : "52523", intitule : "VIGNES LA COTE", cp : "52700" },
{ code : "52524", intitule : "VIGNORY", cp : "52320" },
{ code : "52525", intitule : "VILLARS EN AZOIS", cp : "52120" },
{ code : "52526", intitule : "VILLARS SANTENOGE", cp : "52160" },
{ code : "52526", intitule : "VILLARS SANTENOGE", cp : "52160" },
{ code : "52526", intitule : "VILLARS SANTENOGE", cp : "52160" },
{ code : "52528", intitule : "VILLE EN BLAISOIS", cp : "52130" },
{ code : "52529", intitule : "VILLEGUSIEN LE LAC", cp : "52190" },
{ code : "52529", intitule : "VILLEGUSIEN LE LAC", cp : "52190" },
{ code : "52529", intitule : "VILLEGUSIEN LE LAC", cp : "52600" },
{ code : "52529", intitule : "VILLEGUSIEN LE LAC", cp : "52190" },
{ code : "52529", intitule : "VILLEGUSIEN LE LAC", cp : "52190" },
{ code : "52534", intitule : "VILLIERS EN LIEU", cp : "52100" },
{ code : "52535", intitule : "VILLIERS LE SEC", cp : "52000" },
{ code : "52536", intitule : "VILLIERS LES APREY", cp : "52190" },
{ code : "52538", intitule : "VILLIERS SUR SUIZE", cp : "52210" },
{ code : "52539", intitule : "VIOLOT", cp : "52600" },
{ code : "52540", intitule : "VITRY EN MONTAGNE", cp : "52160" },
{ code : "52541", intitule : "VITRY LES NOGENT", cp : "52800" },
{ code : "52542", intitule : "VIVEY", cp : "52160" },
{ code : "52543", intitule : "VOILLECOMTE", cp : "52130" },
{ code : "52544", intitule : "VOISEY", cp : "52400" },
{ code : "52544", intitule : "VOISEY", cp : "52400" },
{ code : "52545", intitule : "VOISINES", cp : "52200" },
{ code : "52546", intitule : "VONCOURT", cp : "52500" },
{ code : "52547", intitule : "VOUECOURT", cp : "52320" },
{ code : "52548", intitule : "VRAINCOURT", cp : "52310" },
{ code : "52549", intitule : "VRONCOURT LA COTE", cp : "52240" },
{ code : "52550", intitule : "WASSY", cp : "52130" },
{ code : "53001", intitule : "AHUILLE", cp : "53940" },
{ code : "53002", intitule : "ALEXAIN", cp : "53240" },
{ code : "53003", intitule : "AMBRIERES LES VALLEES", cp : "53300" },
{ code : "53003", intitule : "AMBRIERES LES VALLEES", cp : "53300" },
{ code : "53004", intitule : "AMPOIGNE", cp : "53200" },
{ code : "53005", intitule : "ANDOUILLE", cp : "53240" },
{ code : "53006", intitule : "ARGENTON NOTRE DAME", cp : "53290" },
{ code : "53007", intitule : "ARGENTRE", cp : "53210" },
{ code : "53008", intitule : "ARON", cp : "53440" },
{ code : "53009", intitule : "ARQUENAY", cp : "53170" },
{ code : "53010", intitule : "ASSE LE BERENGER", cp : "53600" },
{ code : "53011", intitule : "ASTILLE", cp : "53230" },
{ code : "53012", intitule : "ATHEE", cp : "53400" },
{ code : "53013", intitule : "AVERTON", cp : "53700" },
{ code : "53014", intitule : "AZE", cp : "53200" },
{ code : "53015", intitule : "LA BACONNIERE", cp : "53240" },
{ code : "53016", intitule : "BAIS", cp : "53160" },
{ code : "53017", intitule : "BALLEE", cp : "53340" },
{ code : "53018", intitule : "BALLOTS", cp : "53350" },
{ code : "53019", intitule : "BANNES", cp : "53340" },
{ code : "53021", intitule : "LA BAZOGE MONTPINCON", cp : "53440" },
{ code : "53022", intitule : "LA BAZOUGE DE CHEMERE", cp : "53170" },
{ code : "53023", intitule : "LA BAZOUGE DES ALLEUX", cp : "53470" },
{ code : "53025", intitule : "BAZOUGERS", cp : "53170" },
{ code : "53026", intitule : "BEAULIEU SUR OUDON", cp : "53320" },
{ code : "53027", intitule : "BEAUMONT PIED DE BOEUF", cp : "53290" },
{ code : "53028", intitule : "BELGEARD", cp : "53440" },
{ code : "53029", intitule : "BIERNE", cp : "53290" },
{ code : "53030", intitule : "LE BIGNON DU MAINE", cp : "53170" },
{ code : "53031", intitule : "LA BIGOTTIERE", cp : "53240" },
{ code : "53032", intitule : "BLANDOUET", cp : "53270" },
{ code : "53033", intitule : "LA BOISSIERE", cp : "53800" },
{ code : "53034", intitule : "BONCHAMP LES LAVAL", cp : "53960" },
{ code : "53035", intitule : "BOUCHAMPS LES CRAON", cp : "53800" },
{ code : "53036", intitule : "BOUERE", cp : "53290" },
{ code : "53037", intitule : "BOUESSAY", cp : "53290" },
{ code : "53038", intitule : "BOULAY LES IFS", cp : "53370" },
{ code : "53039", intitule : "LE BOURGNEUF LA FORET", cp : "53410" },
{ code : "53040", intitule : "BOURGON", cp : "53410" },
{ code : "53041", intitule : "BRAINS SUR LES MARCHES", cp : "53350" },
{ code : "53042", intitule : "BRECE", cp : "53120" },
{ code : "53043", intitule : "BREE", cp : "53150" },
{ code : "53045", intitule : "LA BRULATTE", cp : "53410" },
{ code : "53046", intitule : "LE BURET", cp : "53170" },
{ code : "53047", intitule : "CARELLES", cp : "53120" },
{ code : "53048", intitule : "CHAILLAND", cp : "53420" },
{ code : "53049", intitule : "CHALONS DU MAINE", cp : "53470" },
{ code : "53051", intitule : "CHAMPEON", cp : "53640" },
{ code : "53052", intitule : "CHAMPFREMONT", cp : "53370" },
{ code : "53053", intitule : "CHAMPGENETEUX", cp : "53160" },
{ code : "53054", intitule : "CHANGE", cp : "53810" },
{ code : "53055", intitule : "CHANTRIGNE", cp : "53300" },
{ code : "53056", intitule : "LA CHAPELLE ANTHENAISE", cp : "53950" },
{ code : "53057", intitule : "LA CHAPELLE AU RIBOUL", cp : "53440" },
{ code : "53058", intitule : "LA CHAPELLE CRAONNAISE", cp : "53230" },
{ code : "53059", intitule : "LA CHAPELLE RAINSOUIN", cp : "53150" },
{ code : "53061", intitule : "CHARCHIGNE", cp : "53250" },
{ code : "53062", intitule : "CHATEAU GONTIER", cp : "53200" },
{ code : "53062", intitule : "CHATEAU GONTIER", cp : "53200" },
{ code : "53063", intitule : "CHATELAIN", cp : "53200" },
{ code : "53064", intitule : "CHATILLON SUR COLMONT", cp : "53100" },
{ code : "53065", intitule : "CHATRES LA FORET", cp : "53600" },
{ code : "53066", intitule : "CHEMAZE", cp : "53200" },
{ code : "53067", intitule : "CHEMERE LE ROI", cp : "53340" },
{ code : "53068", intitule : "CHERANCE", cp : "53400" },
{ code : "53069", intitule : "CHEVAIGNE DU MAINE", cp : "53250" },
{ code : "53071", intitule : "COLOMBIERS DU PLESSIS", cp : "53120" },
{ code : "53072", intitule : "COMMER", cp : "53470" },
{ code : "53073", intitule : "CONGRIER", cp : "53800" },
{ code : "53074", intitule : "CONTEST", cp : "53100" },
{ code : "53075", intitule : "COSMES", cp : "53230" },
{ code : "53076", intitule : "COSSE EN CHAMPAGNE", cp : "53340" },
{ code : "53077", intitule : "COSSE LE VIVIEN", cp : "53230" },
{ code : "53078", intitule : "COUDRAY", cp : "53200" },
{ code : "53079", intitule : "COUESMES VAUCE", cp : "53300" },
{ code : "53079", intitule : "COUESMES VAUCE", cp : "53300" },
{ code : "53080", intitule : "COUPTRAIN", cp : "53250" },
{ code : "53082", intitule : "COURBEVEILLE", cp : "53230" },
{ code : "53083", intitule : "COURCITE", cp : "53700" },
{ code : "53084", intitule : "CRAON", cp : "53400" },
{ code : "53085", intitule : "CRENNES SUR FRAUBEE", cp : "53700" },
{ code : "53086", intitule : "LA CROIXILLE", cp : "53380" },
{ code : "53087", intitule : "LA CROPTE", cp : "53170" },
{ code : "53088", intitule : "CUILLE", cp : "53540" },
{ code : "53089", intitule : "DAON", cp : "53200" },
{ code : "53090", intitule : "DENAZE", cp : "53400" },
{ code : "53091", intitule : "DESERTINES", cp : "53190" },
{ code : "53092", intitule : "DEUX EVAILLES", cp : "53150" },
{ code : "53093", intitule : "LA DOREE", cp : "53190" },
{ code : "53094", intitule : "ENTRAMMES", cp : "53260" },
{ code : "53095", intitule : "EPINEUX LE SEGUIN", cp : "53340" },
{ code : "53096", intitule : "ERNEE", cp : "53500" },
{ code : "53097", intitule : "EVRON", cp : "53600" },
{ code : "53098", intitule : "FONTAINE COUVERTE", cp : "53350" },
{ code : "53099", intitule : "FORCE", cp : "53260" },
{ code : "53100", intitule : "FOUGEROLLES DU PLESSIS", cp : "53190" },
{ code : "53101", intitule : "FROMENTIERES", cp : "53200" },
{ code : "53102", intitule : "GASTINES", cp : "53540" },
{ code : "53103", intitule : "LE GENEST ST ISLE", cp : "53940" },
{ code : "53103", intitule : "LE GENEST ST ISLE", cp : "53940" },
{ code : "53104", intitule : "GENNES SUR GLAIZE", cp : "53200" },
{ code : "53105", intitule : "GESNES", cp : "53150" },
{ code : "53106", intitule : "GESVRES", cp : "53370" },
{ code : "53107", intitule : "GORRON", cp : "53120" },
{ code : "53108", intitule : "LA GRAVELLE", cp : "53410" },
{ code : "53109", intitule : "GRAZAY", cp : "53440" },
{ code : "53110", intitule : "GREZ EN BOUERE", cp : "53290" },
{ code : "53111", intitule : "LA HAIE TRAVERSAINE", cp : "53300" },
{ code : "53112", intitule : "LE HAM", cp : "53250" },
{ code : "53113", intitule : "HAMBERS", cp : "53160" },
{ code : "53114", intitule : "HARDANGES", cp : "53640" },
{ code : "53115", intitule : "HERCE", cp : "53120" },
{ code : "53116", intitule : "LE HORPS", cp : "53640" },
{ code : "53117", intitule : "HOUSSAY", cp : "53360" },
{ code : "53118", intitule : "LE HOUSSEAU BRETIGNOLLES", cp : "53110" },
{ code : "53118", intitule : "LE HOUSSEAU BRETIGNOLLES", cp : "53110" },
{ code : "53119", intitule : "L HUISSERIE", cp : "53970" },
{ code : "53120", intitule : "IZE", cp : "53160" },
{ code : "53121", intitule : "JAVRON LES CHAPELLES", cp : "53250" },
{ code : "53121", intitule : "JAVRON LES CHAPELLES", cp : "53250" },
{ code : "53122", intitule : "JUBLAINS", cp : "53160" },
{ code : "53123", intitule : "JUVIGNE", cp : "53380" },
{ code : "53124", intitule : "LAIGNE", cp : "53200" },
{ code : "53125", intitule : "LANDIVY", cp : "53190" },
{ code : "53126", intitule : "LARCHAMP", cp : "53220" },
{ code : "53127", intitule : "LASSAY LES CHATEAUX", cp : "53110" },
{ code : "53127", intitule : "LASSAY LES CHATEAUX", cp : "53110" },
{ code : "53127", intitule : "LASSAY LES CHATEAUX", cp : "53110" },
{ code : "53127", intitule : "LASSAY LES CHATEAUX", cp : "53110" },
{ code : "53128", intitule : "LAUBRIERES", cp : "53540" },
{ code : "53129", intitule : "LAUNAY VILLIERS", cp : "53410" },
{ code : "53130", intitule : "LAVAL", cp : "53000" },
{ code : "53131", intitule : "LESBOIS", cp : "53120" },
{ code : "53132", intitule : "LEVARE", cp : "53120" },
{ code : "53133", intitule : "LIGNIERES ORGERES", cp : "53140" },
{ code : "53133", intitule : "LIGNIERES ORGERES", cp : "53140" },
{ code : "53134", intitule : "LIVET", cp : "53150" },
{ code : "53135", intitule : "LIVRE LA TOUCHE", cp : "53400" },
{ code : "53136", intitule : "LOIGNE SUR MAYENNE", cp : "53200" },
{ code : "53137", intitule : "LOIRON RUILLE", cp : "53320" },
{ code : "53137", intitule : "LOIRON RUILLE", cp : "53320" },
{ code : "53138", intitule : "LONGUEFUYE", cp : "53200" },
{ code : "53139", intitule : "LOUPFOUGERES", cp : "53700" },
{ code : "53140", intitule : "LOUVERNE", cp : "53950" },
{ code : "53141", intitule : "LOUVIGNE", cp : "53210" },
{ code : "53142", intitule : "MADRE", cp : "53250" },
{ code : "53143", intitule : "MAISONCELLES DU MAINE", cp : "53170" },
{ code : "53144", intitule : "MARCILLE LA VILLE", cp : "53440" },
{ code : "53145", intitule : "MARIGNE PEUTON", cp : "53200" },
{ code : "53146", intitule : "MARTIGNE SUR MAYENNE", cp : "53470" },
{ code : "53147", intitule : "MAYENNE", cp : "53100" },
{ code : "53148", intitule : "MEE", cp : "53400" },
{ code : "53150", intitule : "MENIL", cp : "53200" },
{ code : "53151", intitule : "MERAL", cp : "53230" },
{ code : "53152", intitule : "MESLAY DU MAINE", cp : "53170" },
{ code : "53153", intitule : "MEZANGERS", cp : "53600" },
{ code : "53154", intitule : "MONTAUDIN", cp : "53220" },
{ code : "53155", intitule : "MONTENAY", cp : "53500" },
{ code : "53156", intitule : "MONTFLOURS", cp : "53240" },
{ code : "53157", intitule : "MONTIGNE LE BRILLANT", cp : "53970" },
{ code : "53158", intitule : "MONTJEAN", cp : "53320" },
{ code : "53159", intitule : "MONTOURTIER", cp : "53150" },
{ code : "53160", intitule : "MONTREUIL POULAY", cp : "53640" },
{ code : "53160", intitule : "MONTREUIL POULAY", cp : "53640" },
{ code : "53161", intitule : "MONTSURS", cp : "53150" },
{ code : "53162", intitule : "MOULAY", cp : "53100" },
{ code : "53163", intitule : "NEAU", cp : "53150" },
{ code : "53164", intitule : "NEUILLY LE VENDIN", cp : "53250" },
{ code : "53165", intitule : "NIAFLES", cp : "53400" },
{ code : "53168", intitule : "NUILLE SUR VICOIN", cp : "53970" },
{ code : "53169", intitule : "OLIVET", cp : "53410" },
{ code : "53170", intitule : "OISSEAU", cp : "53300" },
{ code : "53172", intitule : "ORIGNE", cp : "53360" },
{ code : "53173", intitule : "LA PALLU", cp : "53140" },
{ code : "53174", intitule : "PARIGNE SUR BRAYE", cp : "53100" },
{ code : "53175", intitule : "PARNE SUR ROC", cp : "53260" },
{ code : "53176", intitule : "LE PAS", cp : "53300" },
{ code : "53177", intitule : "LA PELLERINE", cp : "53220" },
{ code : "53178", intitule : "PEUTON", cp : "53360" },
{ code : "53179", intitule : "PLACE", cp : "53240" },
{ code : "53180", intitule : "POMMERIEUX", cp : "53400" },
{ code : "53181", intitule : "PONTMAIN", cp : "53220" },
{ code : "53182", intitule : "PORT BRILLET", cp : "53410" },
{ code : "53184", intitule : "PREAUX", cp : "53340" },
{ code : "53185", intitule : "PRE EN PAIL ST SAMSON", cp : "53140" },
{ code : "53185", intitule : "PRE EN PAIL ST SAMSON", cp : "53140" },
{ code : "53186", intitule : "QUELAINES ST GAULT", cp : "53360" },
{ code : "53186", intitule : "QUELAINES ST GAULT", cp : "53360" },
{ code : "53187", intitule : "RAVIGNY", cp : "53370" },
{ code : "53188", intitule : "RENAZE", cp : "53800" },
{ code : "53189", intitule : "RENNES EN GRENOUILLES", cp : "53110" },
{ code : "53190", intitule : "LE RIBAY", cp : "53640" },
{ code : "53191", intitule : "LA ROE", cp : "53350" },
{ code : "53192", intitule : "LA ROUAUDIERE", cp : "53390" },
{ code : "53193", intitule : "RUILLE FROID FONDS", cp : "53170" },
{ code : "53195", intitule : "SACE", cp : "53470" },
{ code : "53196", intitule : "ST AIGNAN DE COUPTRAIN", cp : "53250" },
{ code : "53197", intitule : "ST AIGNAN SUR ROE", cp : "53390" },
{ code : "53198", intitule : "ST AUBIN DU DESERT", cp : "53700" },
{ code : "53199", intitule : "ST AUBIN FOSSE LOUVAIN", cp : "53120" },
{ code : "53200", intitule : "ST BAUDELLE", cp : "53100" },
{ code : "53201", intitule : "ST BERTHEVIN", cp : "53940" },
{ code : "53202", intitule : "ST BERTHEVIN LA TANNIERE", cp : "53220" },
{ code : "53203", intitule : "ST BRICE", cp : "53290" },
{ code : "53204", intitule : "ST CALAIS DU DESERT", cp : "53140" },
{ code : "53205", intitule : "ST CENERE", cp : "53150" },
{ code : "53206", intitule : "ST CHARLES LA FORET", cp : "53170" },
{ code : "53207", intitule : "ST CHRISTOPHE DU LUAT", cp : "53150" },
{ code : "53208", intitule : "ST CYR EN PAIL", cp : "53140" },
{ code : "53209", intitule : "ST CYR LE GRAVELAIS", cp : "53320" },
{ code : "53210", intitule : "ST DENIS D ANJOU", cp : "53290" },
{ code : "53211", intitule : "ST DENIS DE GASTINES", cp : "53500" },
{ code : "53212", intitule : "ST DENIS DU MAINE", cp : "53170" },
{ code : "53213", intitule : "ST ELLIER DU MAINE", cp : "53220" },
{ code : "53214", intitule : "ST ERBLON", cp : "53390" },
{ code : "53215", intitule : "ST FORT", cp : "53200" },
{ code : "53216", intitule : "ST FRAIMBAULT DE PRIERES", cp : "53300" },
{ code : "53218", intitule : "STE GEMMES LE ROBERT", cp : "53600" },
{ code : "53219", intitule : "ST GEORGES BUTTAVENT", cp : "53100" },
{ code : "53219", intitule : "ST GEORGES BUTTAVENT", cp : "53100" },
{ code : "53220", intitule : "ST GEORGES LE FLECHARD", cp : "53480" },
{ code : "53221", intitule : "ST GEORGES SUR ERVE", cp : "53600" },
{ code : "53222", intitule : "ST GERMAIN D ANXURE", cp : "53240" },
{ code : "53223", intitule : "ST GERMAIN DE COULAMER", cp : "53700" },
{ code : "53224", intitule : "ST GERMAIN LE FOUILLOUX", cp : "53240" },
{ code : "53225", intitule : "ST GERMAIN LE GUILLAUME", cp : "53240" },
{ code : "53226", intitule : "ST HILAIRE DU MAINE", cp : "53380" },
{ code : "53228", intitule : "ST JEAN SUR ERVE", cp : "53270" },
{ code : "53229", intitule : "ST JEAN SUR MAYENNE", cp : "53240" },
{ code : "53230", intitule : "ST JULIEN DU TERROUX", cp : "53110" },
{ code : "53231", intitule : "ST LAURENT DES MORTIERS", cp : "53290" },
{ code : "53232", intitule : "ST LEGER", cp : "53480" },
{ code : "53233", intitule : "ST LOUP DU DORAT", cp : "53290" },
{ code : "53234", intitule : "ST LOUP DU GAST", cp : "53300" },
{ code : "53235", intitule : "STE MARIE DU BOIS", cp : "53110" },
{ code : "53236", intitule : "ST MARS DU DESERT", cp : "53700" },
{ code : "53237", intitule : "ST MARS SUR COLMONT", cp : "53300" },
{ code : "53238", intitule : "ST MARS SUR LA FUTAIE", cp : "53220" },
{ code : "53239", intitule : "ST MARTIN DE CONNEE", cp : "53160" },
{ code : "53240", intitule : "ST MARTIN DU LIMET", cp : "53800" },
{ code : "53241", intitule : "ST MICHEL DE FEINS", cp : "53290" },
{ code : "53242", intitule : "ST MICHEL DE LA ROE", cp : "53350" },
{ code : "53243", intitule : "ST OUEN DES TOITS", cp : "53410" },
{ code : "53244", intitule : "ST OUEN DES VALLONS", cp : "53150" },
{ code : "53245", intitule : "ST PIERRE DES LANDES", cp : "53500" },
{ code : "53246", intitule : "ST PIERRE DES NIDS", cp : "53370" },
{ code : "53247", intitule : "ST PIERRE LA COUR", cp : "53410" },
{ code : "53248", intitule : "ST PIERRE SUR ERVE", cp : "53270" },
{ code : "53249", intitule : "ST PIERRE SUR ORTHE", cp : "53160" },
{ code : "53250", intitule : "ST POIX", cp : "53540" },
{ code : "53251", intitule : "ST QUENTIN LES ANGES", cp : "53400" },
{ code : "53253", intitule : "ST SATURNIN DU LIMET", cp : "53800" },
{ code : "53254", intitule : "ST SULPICE", cp : "53360" },
{ code : "53255", intitule : "STE SUZANNE ET CHAMMES", cp : "53270" },
{ code : "53255", intitule : "STE SUZANNE ET CHAMMES", cp : "53270" },
{ code : "53256", intitule : "ST THOMAS DE COURCERIERS", cp : "53160" },
{ code : "53257", intitule : "SAULGES", cp : "53340" },
{ code : "53258", intitule : "LA SELLE CRAONNAISE", cp : "53800" },
{ code : "53259", intitule : "SENONNES", cp : "53390" },
{ code : "53260", intitule : "SIMPLE", cp : "53360" },
{ code : "53261", intitule : "SOUCE", cp : "53300" },
{ code : "53262", intitule : "SOULGE SUR OUETTE", cp : "53210" },
{ code : "53262", intitule : "SOULGE SUR OUETTE", cp : "53210" },
{ code : "53263", intitule : "THUBOEUF", cp : "53110" },
{ code : "53264", intitule : "THORIGNE EN CHARNIE", cp : "53270" },
{ code : "53265", intitule : "TORCE VIVIERS EN CHARNIE", cp : "53270" },
{ code : "53265", intitule : "TORCE VIVIERS EN CHARNIE", cp : "53270" },
{ code : "53266", intitule : "TRANS", cp : "53160" },
{ code : "53267", intitule : "VAIGES", cp : "53480" },
{ code : "53269", intitule : "VAUTORTE", cp : "53500" },
{ code : "53270", intitule : "VIEUVY", cp : "53120" },
{ code : "53271", intitule : "VILLAINES LA JUHEL", cp : "53700" },
{ code : "53272", intitule : "VILLEPAIL", cp : "53250" },
{ code : "53273", intitule : "VILLIERS CHARLEMAGNE", cp : "53170" },
{ code : "53274", intitule : "VIMARCE", cp : "53160" },
{ code : "53276", intitule : "VOUTRE", cp : "53600" },
{ code : "54001", intitule : "ABAUCOURT", cp : "54610" },
{ code : "54002", intitule : "ABBEVILLE LES CONFLANS", cp : "54800" },
{ code : "54003", intitule : "ABONCOURT", cp : "54115" },
{ code : "54004", intitule : "AFFLEVILLE", cp : "54800" },
{ code : "54005", intitule : "AFFRACOURT", cp : "54740" },
{ code : "54006", intitule : "AGINCOURT", cp : "54770" },
{ code : "54007", intitule : "AINGERAY", cp : "54460" },
{ code : "54008", intitule : "ALLAIN", cp : "54170" },
{ code : "54009", intitule : "ALLAMONT", cp : "54800" },
{ code : "54010", intitule : "ALLAMPS", cp : "54112" },
{ code : "54011", intitule : "ALLONDRELLE LA MALMAISON", cp : "54260" },
{ code : "54012", intitule : "AMANCE", cp : "54770" },
{ code : "54013", intitule : "AMENONCOURT", cp : "54450" },
{ code : "54014", intitule : "ANCERVILLER", cp : "54450" },
{ code : "54015", intitule : "ANDERNY", cp : "54560" },
{ code : "54016", intitule : "ANDILLY", cp : "54200" },
{ code : "54017", intitule : "ANGOMONT", cp : "54540" },
{ code : "54018", intitule : "ANOUX", cp : "54150" },
{ code : "54019", intitule : "ANSAUVILLE", cp : "54470" },
{ code : "54020", intitule : "ANTHELUPT", cp : "54110" },
{ code : "54021", intitule : "ARMAUCOURT", cp : "54760" },
{ code : "54022", intitule : "ARNAVILLE", cp : "54530" },
{ code : "54023", intitule : "ARRACOURT", cp : "54370" },
{ code : "54024", intitule : "ARRAYE ET HAN", cp : "54760" },
{ code : "54025", intitule : "ART SUR MEURTHE", cp : "54510" },
{ code : "54025", intitule : "ART SUR MEURTHE", cp : "54510" },
{ code : "54026", intitule : "ATHIENVILLE", cp : "54370" },
{ code : "54027", intitule : "ATTON", cp : "54700" },
{ code : "54028", intitule : "AUBOUE", cp : "54580" },
{ code : "54029", intitule : "AUDUN LE ROMAN", cp : "54560" },
{ code : "54030", intitule : "AUTREPIERRE", cp : "54450" },
{ code : "54031", intitule : "AUTREVILLE SUR MOSELLE", cp : "54380" },
{ code : "54032", intitule : "AUTREY", cp : "54160" },
{ code : "54033", intitule : "AVILLERS", cp : "54490" },
{ code : "54034", intitule : "AVRAINVILLE", cp : "54385" },
{ code : "54035", intitule : "AVRICOURT", cp : "54450" },
{ code : "54036", intitule : "AVRIL", cp : "54150" },
{ code : "54037", intitule : "AZELOT", cp : "54210" },
{ code : "54038", intitule : "AZERAILLES", cp : "54122" },
{ code : "54039", intitule : "BACCARAT", cp : "54120" },
{ code : "54039", intitule : "BACCARAT", cp : "54120" },
{ code : "54039", intitule : "BACCARAT", cp : "54120" },
{ code : "54040", intitule : "BADONVILLER", cp : "54540" },
{ code : "54041", intitule : "BAGNEUX", cp : "54170" },
{ code : "54042", intitule : "BAINVILLE AUX MIROIRS", cp : "54290" },
{ code : "54043", intitule : "BAINVILLE SUR MADON", cp : "54550" },
{ code : "54044", intitule : "BARBAS", cp : "54450" },
{ code : "54045", intitule : "BARBONVILLE", cp : "54360" },
{ code : "54046", intitule : "BARISEY AU PLAIN", cp : "54170" },
{ code : "54047", intitule : "BARISEY LA COTE", cp : "54170" },
{ code : "54048", intitule : "LES BAROCHES", cp : "54150" },
{ code : "54048", intitule : "LES BAROCHES", cp : "54150" },
{ code : "54049", intitule : "BASLIEUX", cp : "54620" },
{ code : "54050", intitule : "BATHELEMONT", cp : "54370" },
{ code : "54051", intitule : "BATILLY", cp : "54980" },
{ code : "54052", intitule : "BATTIGNY", cp : "54115" },
{ code : "54053", intitule : "BAUZEMONT", cp : "54370" },
{ code : "54054", intitule : "BAYON", cp : "54290" },
{ code : "54055", intitule : "BAYONVILLE SUR MAD", cp : "54890" },
{ code : "54056", intitule : "BAZAILLES", cp : "54620" },
{ code : "54057", intitule : "BEAUMONT", cp : "54470" },
{ code : "54058", intitule : "BECHAMPS", cp : "54800" },
{ code : "54059", intitule : "BELLEAU", cp : "54610" },
{ code : "54059", intitule : "BELLEAU", cp : "54610" },
{ code : "54059", intitule : "BELLEAU", cp : "54610" },
{ code : "54059", intitule : "BELLEAU", cp : "54610" },
{ code : "54059", intitule : "BELLEAU", cp : "54610" },
{ code : "54060", intitule : "BELLEVILLE", cp : "54940" },
{ code : "54061", intitule : "BENAMENIL", cp : "54450" },
{ code : "54062", intitule : "BENNEY", cp : "54740" },
{ code : "54063", intitule : "BERNECOURT", cp : "54470" },
{ code : "54064", intitule : "BERTRAMBOIS", cp : "54480" },
{ code : "54065", intitule : "BERTRICHAMPS", cp : "54120" },
{ code : "54066", intitule : "BETTAINVILLERS", cp : "54640" },
{ code : "54067", intitule : "BEUVEILLE", cp : "54620" },
{ code : "54068", intitule : "BEUVEZIN", cp : "54115" },
{ code : "54069", intitule : "BEUVILLERS", cp : "54560" },
{ code : "54070", intitule : "BEY SUR SEILLE", cp : "54760" },
{ code : "54071", intitule : "BEZANGE LA GRANDE", cp : "54370" },
{ code : "54072", intitule : "BEZAUMONT", cp : "54380" },
{ code : "54073", intitule : "BICQUELEY", cp : "54200" },
{ code : "54074", intitule : "BIENVILLE LA PETITE", cp : "54300" },
{ code : "54075", intitule : "BIONVILLE", cp : "54540" },
{ code : "54076", intitule : "BLAINVILLE SUR L EAU", cp : "54360" },
{ code : "54077", intitule : "BLAMONT", cp : "54450" },
{ code : "54078", intitule : "BLEMEREY", cp : "54450" },
{ code : "54079", intitule : "BLENOD LES PONT A MOUSSON", cp : "54700" },
{ code : "54080", intitule : "BLENOD LES TOUL", cp : "54113" },
{ code : "54081", intitule : "BOISMONT", cp : "54620" },
{ code : "54082", intitule : "BONCOURT", cp : "54800" },
{ code : "54083", intitule : "BONVILLER", cp : "54300" },
{ code : "54084", intitule : "MONT BONVILLERS", cp : "54111" },
{ code : "54085", intitule : "BORVILLE", cp : "54290" },
{ code : "54086", intitule : "BOUCQ", cp : "54200" },
{ code : "54087", intitule : "BOUILLONVILLE", cp : "54470" },
{ code : "54088", intitule : "BOUVRON", cp : "54200" },
{ code : "54089", intitule : "BOUXIERES AUX CHENES", cp : "54770" },
{ code : "54089", intitule : "BOUXIERES AUX CHENES", cp : "54770" },
{ code : "54090", intitule : "BOUXIERES AUX DAMES", cp : "54136" },
{ code : "54091", intitule : "BOUXIERES SOUS FROIDMONT", cp : "54700" },
{ code : "54092", intitule : "BOUZANVILLE", cp : "54930" },
{ code : "54093", intitule : "BRAINVILLE", cp : "54800" },
{ code : "54094", intitule : "BRALLEVILLE", cp : "54740" },
{ code : "54095", intitule : "BRATTE", cp : "54610" },
{ code : "54096", intitule : "BREHAIN LA VILLE", cp : "54190" },
{ code : "54097", intitule : "BREMENIL", cp : "54540" },
{ code : "54098", intitule : "BREMONCOURT", cp : "54290" },
{ code : "54099", intitule : "BRIEY", cp : "54150" },
{ code : "54100", intitule : "BRIN SUR SEILLE", cp : "54280" },
{ code : "54101", intitule : "BROUVILLE", cp : "54120" },
{ code : "54102", intitule : "BRULEY", cp : "54200" },
{ code : "54103", intitule : "BRUVILLE", cp : "54800" },
{ code : "54104", intitule : "BUISSONCOURT", cp : "54110" },
{ code : "54105", intitule : "BULLIGNY", cp : "54113" },
{ code : "54106", intitule : "BURES", cp : "54370" },
{ code : "54107", intitule : "BURIVILLE", cp : "54450" },
{ code : "54108", intitule : "BURTHECOURT AUX CHENES", cp : "54210" },
{ code : "54109", intitule : "CEINTREY", cp : "54134" },
{ code : "54110", intitule : "CERVILLE", cp : "54420" },
{ code : "54111", intitule : "CHALIGNY", cp : "54230" },
{ code : "54112", intitule : "CHAMBLEY BUSSIERES", cp : "54890" },
{ code : "54113", intitule : "CHAMPENOUX", cp : "54280" },
{ code : "54114", intitule : "CHAMPEY SUR MOSELLE", cp : "54700" },
{ code : "54115", intitule : "CHAMPIGNEULLES", cp : "54250" },
{ code : "54116", intitule : "CHANTEHEUX", cp : "54300" },
{ code : "54117", intitule : "CHAOUILLEY", cp : "54330" },
{ code : "54118", intitule : "CHARENCY VEZIN", cp : "54260" },
{ code : "54119", intitule : "CHAREY", cp : "54470" },
{ code : "54120", intitule : "CHARMES LA COTE", cp : "54113" },
{ code : "54121", intitule : "CHARMOIS", cp : "54360" },
{ code : "54122", intitule : "CHAUDENEY SUR MOSELLE", cp : "54200" },
{ code : "54123", intitule : "CHAVIGNY", cp : "54230" },
{ code : "54124", intitule : "CHAZELLES SUR ALBE", cp : "54450" },
{ code : "54125", intitule : "CHENEVIERES", cp : "54122" },
{ code : "54126", intitule : "CHENICOURT", cp : "54610" },
{ code : "54127", intitule : "CHENIERES", cp : "54720" },
{ code : "54128", intitule : "CHOLOY MENILLOT", cp : "54200" },
{ code : "54128", intitule : "CHOLOY MENILLOT", cp : "54200" },
{ code : "54129", intitule : "CIREY SUR VEZOUZE", cp : "54480" },
{ code : "54130", intitule : "CLAYEURES", cp : "54290" },
{ code : "54131", intitule : "CLEMERY", cp : "54610" },
{ code : "54132", intitule : "CLEREY SUR BRENON", cp : "54330" },
{ code : "54133", intitule : "COINCOURT", cp : "54370" },
{ code : "54134", intitule : "COLMEY", cp : "54260" },
{ code : "54134", intitule : "COLMEY", cp : "54260" },
{ code : "54135", intitule : "COLOMBEY LES BELLES", cp : "54170" },
{ code : "54136", intitule : "CONFLANS EN JARNISY", cp : "54800" },
{ code : "54137", intitule : "CONS LA GRANDVILLE", cp : "54870" },
{ code : "54138", intitule : "COSNES ET ROMAIN", cp : "54400" },
{ code : "54138", intitule : "COSNES ET ROMAIN", cp : "54400" },
{ code : "54139", intitule : "COURBESSEAUX", cp : "54110" },
{ code : "54140", intitule : "COURCELLES", cp : "54930" },
{ code : "54141", intitule : "COYVILLER", cp : "54210" },
{ code : "54142", intitule : "CRANTENOY", cp : "54740" },
{ code : "54143", intitule : "CREPEY", cp : "54170" },
{ code : "54144", intitule : "CREVECHAMPS", cp : "54290" },
{ code : "54145", intitule : "CREVIC", cp : "54110" },
{ code : "54146", intitule : "CREZILLES", cp : "54113" },
{ code : "54147", intitule : "CRION", cp : "54300" },
{ code : "54148", intitule : "CROISMARE", cp : "54300" },
{ code : "54149", intitule : "CRUSNES", cp : "54680" },
{ code : "54150", intitule : "CUSTINES", cp : "54670" },
{ code : "54151", intitule : "CUTRY", cp : "54720" },
{ code : "54152", intitule : "DAMELEVIERES", cp : "54360" },
{ code : "54153", intitule : "DAMPVITOUX", cp : "54470" },
{ code : "54154", intitule : "DENEUVRE", cp : "54120" },
{ code : "54155", intitule : "DEUXVILLE", cp : "54370" },
{ code : "54156", intitule : "DIARVILLE", cp : "54930" },
{ code : "54157", intitule : "DIEULOUARD", cp : "54380" },
{ code : "54158", intitule : "DOLCOURT", cp : "54170" },
{ code : "54159", intitule : "DOMBASLE SUR MEURTHE", cp : "54110" },
{ code : "54160", intitule : "DOMEVRE EN HAYE", cp : "54385" },
{ code : "54161", intitule : "DOMEVRE SUR VEZOUZE", cp : "54450" },
{ code : "54162", intitule : "DOMGERMAIN", cp : "54119" },
{ code : "54163", intitule : "DOMJEVIN", cp : "54450" },
{ code : "54164", intitule : "DOMMARIE EULMONT", cp : "54115" },
{ code : "54165", intitule : "DOMMARTEMONT", cp : "54130" },
{ code : "54166", intitule : "DOMMARTIN LA CHAUSSEE", cp : "54470" },
{ code : "54167", intitule : "DOMMARTIN LES TOUL", cp : "54200" },
{ code : "54168", intitule : "DOMMARTIN SOUS AMANCE", cp : "54770" },
{ code : "54169", intitule : "DOMPRIX", cp : "54490" },
{ code : "54169", intitule : "DOMPRIX", cp : "54490" },
{ code : "54170", intitule : "DOMPTAIL EN L AIR", cp : "54290" },
{ code : "54171", intitule : "DONCOURT LES CONFLANS", cp : "54800" },
{ code : "54172", intitule : "DONCOURT LES LONGUYON", cp : "54620" },
{ code : "54173", intitule : "DROUVILLE", cp : "54370" },
{ code : "54174", intitule : "ECROUVES", cp : "54200" },
{ code : "54174", intitule : "ECROUVES", cp : "54200" },
{ code : "54175", intitule : "EINVAUX", cp : "54360" },
{ code : "54176", intitule : "EINVILLE AU JARD", cp : "54370" },
{ code : "54177", intitule : "EMBERMENIL", cp : "54370" },
{ code : "54178", intitule : "EPIEZ SUR CHIERS", cp : "54260" },
{ code : "54179", intitule : "EPLY", cp : "54610" },
{ code : "54180", intitule : "ERBEVILLER SUR AMEZULE", cp : "54280" },
{ code : "54181", intitule : "ERROUVILLE", cp : "54680" },
{ code : "54182", intitule : "ESSEY ET MAIZERAIS", cp : "54470" },
{ code : "54183", intitule : "ESSEY LA COTE", cp : "54830" },
{ code : "54184", intitule : "ESSEY LES NANCY", cp : "54270" },
{ code : "54185", intitule : "ETREVAL", cp : "54330" },
{ code : "54186", intitule : "EULMONT", cp : "54690" },
{ code : "54187", intitule : "EUVEZIN", cp : "54470" },
{ code : "54188", intitule : "FAULX", cp : "54760" },
{ code : "54189", intitule : "FAVIERES", cp : "54115" },
{ code : "54190", intitule : "FECOCOURT", cp : "54115" },
{ code : "54191", intitule : "FENNEVILLER", cp : "54540" },
{ code : "54192", intitule : "FERRIERES", cp : "54210" },
{ code : "54193", intitule : "FEY EN HAYE", cp : "54470" },
{ code : "54194", intitule : "FILLIERES", cp : "54560" },
{ code : "54195", intitule : "FLAINVAL", cp : "54110" },
{ code : "54196", intitule : "FLAVIGNY SUR MOSELLE", cp : "54630" },
{ code : "54197", intitule : "FLEVILLE DEVANT NANCY", cp : "54710" },
{ code : "54198", intitule : "FLEVILLE LIXIERES", cp : "54150" },
{ code : "54199", intitule : "FLIN", cp : "54122" },
{ code : "54200", intitule : "FLIREY", cp : "54470" },
{ code : "54201", intitule : "FONTENOY LA JOUTE", cp : "54122" },
{ code : "54202", intitule : "FONTENOY SUR MOSELLE", cp : "54840" },
{ code : "54203", intitule : "FORCELLES ST GORGON", cp : "54330" },
{ code : "54204", intitule : "FORCELLES SOUS GUGNEY", cp : "54930" },
{ code : "54205", intitule : "FOUG", cp : "54570" },
{ code : "54206", intitule : "FRAIMBOIS", cp : "54300" },
{ code : "54207", intitule : "FRAISNES EN SAINTOIS", cp : "54930" },
{ code : "54208", intitule : "FRANCHEVILLE", cp : "54200" },
{ code : "54209", intitule : "FRANCONVILLE", cp : "54830" },
{ code : "54210", intitule : "FREMENIL", cp : "54450" },
{ code : "54211", intitule : "FREMONVILLE", cp : "54450" },
{ code : "54212", intitule : "FRESNOIS LA MONTAGNE", cp : "54260" },
{ code : "54213", intitule : "FRIAUVILLE", cp : "54800" },
{ code : "54214", intitule : "FROLOIS", cp : "54160" },
{ code : "54215", intitule : "FROUARD", cp : "54390" },
{ code : "54216", intitule : "FROVILLE", cp : "54290" },
{ code : "54217", intitule : "GELACOURT", cp : "54120" },
{ code : "54218", intitule : "GELAUCOURT", cp : "54115" },
{ code : "54219", intitule : "GELLENONCOURT", cp : "54110" },
{ code : "54220", intitule : "GEMONVILLE", cp : "54115" },
{ code : "54221", intitule : "GERBECOURT ET HAPLEMONT", cp : "54740" },
{ code : "54222", intitule : "GERBEVILLER", cp : "54830" },
{ code : "54223", intitule : "GERMINY", cp : "54170" },
{ code : "54224", intitule : "GERMONVILLE", cp : "54740" },
{ code : "54225", intitule : "GEZONCOURT", cp : "54380" },
{ code : "54226", intitule : "GIBEAUMEIX", cp : "54112" },
{ code : "54227", intitule : "GIRAUMONT", cp : "54780" },
{ code : "54228", intitule : "GIRIVILLER", cp : "54830" },
{ code : "54229", intitule : "GLONVILLE", cp : "54122" },
{ code : "54230", intitule : "GOGNEY", cp : "54450" },
{ code : "54231", intitule : "GONDRECOURT AIX", cp : "54800" },
{ code : "54232", intitule : "GONDREVILLE", cp : "54840" },
{ code : "54233", intitule : "GONDREXON", cp : "54450" },
{ code : "54234", intitule : "GORCY", cp : "54730" },
{ code : "54235", intitule : "GOVILLER", cp : "54330" },
{ code : "54236", intitule : "GRAND FAILLY", cp : "54260" },
{ code : "54236", intitule : "GRAND FAILLY", cp : "54260" },
{ code : "54237", intitule : "GRIMONVILLER", cp : "54115" },
{ code : "54238", intitule : "GRIPPORT", cp : "54290" },
{ code : "54239", intitule : "GRISCOURT", cp : "54380" },
{ code : "54240", intitule : "GROSROUVRES", cp : "54470" },
{ code : "54241", intitule : "GUGNEY", cp : "54930" },
{ code : "54242", intitule : "GYE", cp : "54113" },
{ code : "54243", intitule : "HABLAINVILLE", cp : "54120" },
{ code : "54244", intitule : "HAGEVILLE", cp : "54470" },
{ code : "54245", intitule : "HAIGNEVILLE", cp : "54290" },
{ code : "54246", intitule : "HALLOVILLE", cp : "54450" },
{ code : "54247", intitule : "HAMMEVILLE", cp : "54330" },
{ code : "54248", intitule : "HAMONVILLE", cp : "54470" },
{ code : "54249", intitule : "HANNONVILLE SUZEMONT", cp : "54800" },
{ code : "54250", intitule : "HARAUCOURT", cp : "54110" },
{ code : "54251", intitule : "HARBOUEY", cp : "54450" },
{ code : "54252", intitule : "HAROUE", cp : "54740" },
{ code : "54253", intitule : "HATRIZE", cp : "54800" },
{ code : "54254", intitule : "HAUCOURT MOULAINE", cp : "54860" },
{ code : "54254", intitule : "HAUCOURT MOULAINE", cp : "54860" },
{ code : "54255", intitule : "HAUDONVILLE", cp : "54830" },
{ code : "54256", intitule : "HAUSSONVILLE", cp : "54290" },
{ code : "54257", intitule : "HEILLECOURT", cp : "54180" },
{ code : "54258", intitule : "HENAMENIL", cp : "54370" },
{ code : "54259", intitule : "HERBEVILLER", cp : "54450" },
{ code : "54260", intitule : "HERIMENIL", cp : "54300" },
{ code : "54261", intitule : "HERSERANGE", cp : "54440" },
{ code : "54262", intitule : "HOEVILLE", cp : "54370" },
{ code : "54263", intitule : "HOMECOURT", cp : "54310" },
{ code : "54264", intitule : "HOUDELMONT", cp : "54330" },
{ code : "54265", intitule : "HOUDEMONT", cp : "54180" },
{ code : "54266", intitule : "HOUDREVILLE", cp : "54330" },
{ code : "54268", intitule : "HOUSSEVILLE", cp : "54930" },
{ code : "54269", intitule : "HUDIVILLER", cp : "54110" },
{ code : "54270", intitule : "HUSSIGNY GODBRANGE", cp : "54590" },
{ code : "54271", intitule : "IGNEY", cp : "54450" },
{ code : "54272", intitule : "JAILLON", cp : "54200" },
{ code : "54273", intitule : "JARNY", cp : "54800" },
{ code : "54273", intitule : "JARNY", cp : "54800" },
{ code : "54274", intitule : "JARVILLE LA MALGRANGE", cp : "54140" },
{ code : "54275", intitule : "JAULNY", cp : "54470" },
{ code : "54276", intitule : "JEANDELAINCOURT", cp : "54114" },
{ code : "54277", intitule : "JEANDELIZE", cp : "54800" },
{ code : "54278", intitule : "JEVONCOURT", cp : "54740" },
{ code : "54279", intitule : "JEZAINVILLE", cp : "54700" },
{ code : "54280", intitule : "JOEUF", cp : "54240" },
{ code : "54281", intitule : "JOLIVET", cp : "54300" },
{ code : "54282", intitule : "JOPPECOURT", cp : "54620" },
{ code : "54283", intitule : "JOUAVILLE", cp : "54800" },
{ code : "54284", intitule : "JOUDREVILLE", cp : "54490" },
{ code : "54285", intitule : "JUVRECOURT", cp : "54370" },
{ code : "54286", intitule : "LABRY", cp : "54800" },
{ code : "54287", intitule : "LACHAPELLE", cp : "54120" },
{ code : "54288", intitule : "LAGNEY", cp : "54200" },
{ code : "54289", intitule : "LAITRE SOUS AMANCE", cp : "54770" },
{ code : "54290", intitule : "LAIX", cp : "54720" },
{ code : "54291", intitule : "LALOEUF", cp : "54115" },
{ code : "54292", intitule : "LAMATH", cp : "54300" },
{ code : "54293", intitule : "LANDECOURT", cp : "54360" },
{ code : "54294", intitule : "LANDREMONT", cp : "54380" },
{ code : "54295", intitule : "LANDRES", cp : "54970" },
{ code : "54296", intitule : "LANEUVELOTTE", cp : "54280" },
{ code : "54297", intitule : "LANEUVEVILLE AUX BOIS", cp : "54370" },
{ code : "54298", intitule : "LANEUVEVILLE DERRIERE FOUG", cp : "54570" },
{ code : "54299", intitule : "LANEUVEVILLE DEVANT BAYON", cp : "54740" },
{ code : "54300", intitule : "LANEUVEVILLE DEVANT NANCY", cp : "54410" },
{ code : "54300", intitule : "LANEUVEVILLE DEVANT NANCY", cp : "54410" },
{ code : "54301", intitule : "LANFROICOURT", cp : "54760" },
{ code : "54302", intitule : "LANTEFONTAINE", cp : "54150" },
{ code : "54302", intitule : "LANTEFONTAINE", cp : "54150" },
{ code : "54303", intitule : "LARONXE", cp : "54950" },
{ code : "54304", intitule : "LAXOU", cp : "54520" },
{ code : "54304", intitule : "LAXOU", cp : "54520" },
{ code : "54305", intitule : "LAY ST CHRISTOPHE", cp : "54690" },
{ code : "54306", intitule : "LAY ST REMY", cp : "54570" },
{ code : "54307", intitule : "LEBEUVILLE", cp : "54740" },
{ code : "54308", intitule : "LEINTREY", cp : "54450" },
{ code : "54309", intitule : "LEMAINVILLE", cp : "54740" },
{ code : "54310", intitule : "LEMENIL MITRY", cp : "54740" },
{ code : "54311", intitule : "LENONCOURT", cp : "54110" },
{ code : "54312", intitule : "LESMENILS", cp : "54700" },
{ code : "54313", intitule : "LETRICOURT", cp : "54610" },
{ code : "54314", intitule : "LEXY", cp : "54720" },
{ code : "54315", intitule : "LEYR", cp : "54760" },
{ code : "54316", intitule : "LIMEY REMENAUVILLE", cp : "54470" },
{ code : "54317", intitule : "LIRONVILLE", cp : "54470" },
{ code : "54318", intitule : "LIVERDUN", cp : "54460" },
{ code : "54320", intitule : "LOISY", cp : "54700" },
{ code : "54321", intitule : "LONGLAVILLE", cp : "54810" },
{ code : "54322", intitule : "LONGUYON", cp : "54260" },
{ code : "54322", intitule : "LONGUYON", cp : "54260" },
{ code : "54322", intitule : "LONGUYON", cp : "54260" },
{ code : "54323", intitule : "LONGWY", cp : "54400" },
{ code : "54324", intitule : "LOREY", cp : "54290" },
{ code : "54325", intitule : "LOROMONTZEY", cp : "54290" },
{ code : "54326", intitule : "LUBEY", cp : "54150" },
{ code : "54327", intitule : "LUCEY", cp : "54200" },
{ code : "54328", intitule : "LUDRES", cp : "54710" },
{ code : "54329", intitule : "LUNEVILLE", cp : "54300" },
{ code : "54330", intitule : "LUPCOURT", cp : "54210" },
{ code : "54331", intitule : "MAGNIERES", cp : "54129" },
{ code : "54332", intitule : "MAIDIERES", cp : "54700" },
{ code : "54333", intitule : "MAILLY SUR SEILLE", cp : "54610" },
{ code : "54334", intitule : "MAIRY MAINVILLE", cp : "54150" },
{ code : "54334", intitule : "MAIRY MAINVILLE", cp : "54150" },
{ code : "54335", intitule : "MAIXE", cp : "54370" },
{ code : "54336", intitule : "MAIZIERES", cp : "54550" },
{ code : "54337", intitule : "MALAVILLERS", cp : "54560" },
{ code : "54338", intitule : "MALLELOY", cp : "54670" },
{ code : "54339", intitule : "MALZEVILLE", cp : "54220" },
{ code : "54340", intitule : "MAMEY", cp : "54470" },
{ code : "54341", intitule : "MANCE", cp : "54150" },
{ code : "54342", intitule : "MANCIEULLES", cp : "54790" },
{ code : "54343", intitule : "MANDRES AUX QUATRE TOURS", cp : "54470" },
{ code : "54344", intitule : "MANGONVILLE", cp : "54290" },
{ code : "54345", intitule : "MANONCOURT EN VERMOIS", cp : "54210" },
{ code : "54346", intitule : "MANONCOURT EN WOEVRE", cp : "54385" },
{ code : "54348", intitule : "MANONVILLE", cp : "54385" },
{ code : "54349", intitule : "MANONVILLER", cp : "54300" },
{ code : "54350", intitule : "MARAINVILLER", cp : "54300" },
{ code : "54351", intitule : "MARBACHE", cp : "54820" },
{ code : "54352", intitule : "MARON", cp : "54230" },
{ code : "54353", intitule : "MARS LA TOUR", cp : "54800" },
{ code : "54354", intitule : "MARTHEMONT", cp : "54330" },
{ code : "54355", intitule : "MARTINCOURT", cp : "54380" },
{ code : "54356", intitule : "MATTEXEY", cp : "54830" },
{ code : "54357", intitule : "MAXEVILLE", cp : "54320" },
{ code : "54357", intitule : "MAXEVILLE", cp : "54320" },
{ code : "54358", intitule : "MAZERULLES", cp : "54280" },
{ code : "54359", intitule : "MEHONCOURT", cp : "54360" },
{ code : "54360", intitule : "MENIL LA TOUR", cp : "54200" },
{ code : "54362", intitule : "MERCY LE BAS", cp : "54960" },
{ code : "54363", intitule : "MERCY LE HAUT", cp : "54560" },
{ code : "54363", intitule : "MERCY LE HAUT", cp : "54560" },
{ code : "54364", intitule : "MEREVILLE", cp : "54850" },
{ code : "54365", intitule : "MERVILLER", cp : "54120" },
{ code : "54366", intitule : "MESSEIN", cp : "54850" },
{ code : "54367", intitule : "MEXY", cp : "54135" },
{ code : "54368", intitule : "MIGNEVILLE", cp : "54540" },
{ code : "54369", intitule : "MILLERY", cp : "54670" },
{ code : "54370", intitule : "MINORVILLE", cp : "54385" },
{ code : "54371", intitule : "MOINEVILLE", cp : "54580" },
{ code : "54372", intitule : "MOIVRONS", cp : "54760" },
{ code : "54373", intitule : "MONCEL LES LUNEVILLE", cp : "54300" },
{ code : "54374", intitule : "MONCEL SUR SEILLE", cp : "54280" },
{ code : "54375", intitule : "MONTAUVILLE", cp : "54700" },
{ code : "54376", intitule : "MONTENOY", cp : "54760" },
{ code : "54377", intitule : "MONTIGNY", cp : "54540" },
{ code : "54378", intitule : "MONTIGNY SUR CHIERS", cp : "54870" },
{ code : "54378", intitule : "MONTIGNY SUR CHIERS", cp : "54870" },
{ code : "54379", intitule : "MONT L ETROIT", cp : "54170" },
{ code : "54380", intitule : "MONT LE VIGNOBLE", cp : "54113" },
{ code : "54381", intitule : "MONTREUX", cp : "54450" },
{ code : "54382", intitule : "MONT ST MARTIN", cp : "54350" },
{ code : "54383", intitule : "MONT SUR MEURTHE", cp : "54360" },
{ code : "54385", intitule : "MORFONTAINE", cp : "54920" },
{ code : "54386", intitule : "MORIVILLER", cp : "54830" },
{ code : "54387", intitule : "MORVILLE SUR SEILLE", cp : "54700" },
{ code : "54388", intitule : "MOUACOURT", cp : "54370" },
{ code : "54389", intitule : "MOUAVILLE", cp : "54800" },
{ code : "54390", intitule : "MOUSSON", cp : "54700" },
{ code : "54391", intitule : "MOUTIERS", cp : "54660" },
{ code : "54392", intitule : "MOUTROT", cp : "54113" },
{ code : "54393", intitule : "MOYEN", cp : "54118" },
{ code : "54394", intitule : "MURVILLE", cp : "54490" },
{ code : "54395", intitule : "NANCY", cp : "54000" },
{ code : "54395", intitule : "NANCY", cp : "54100" },
{ code : "54396", intitule : "NEUFMAISONS", cp : "54540" },
{ code : "54397", intitule : "NEUVES MAISONS", cp : "54230" },
{ code : "54398", intitule : "NEUVILLER LES BADONVILLER", cp : "54540" },
{ code : "54399", intitule : "NEUVILLER SUR MOSELLE", cp : "54290" },
{ code : "54400", intitule : "NOMENY", cp : "54610" },
{ code : "54401", intitule : "NONHIGNY", cp : "54450" },
{ code : "54402", intitule : "NORROY LE SEC", cp : "54150" },
{ code : "54403", intitule : "NORROY LES PONT A MOUSSON", cp : "54700" },
{ code : "54404", intitule : "NOVIANT AUX PRES", cp : "54385" },
{ code : "54405", intitule : "OCHEY", cp : "54170" },
{ code : "54406", intitule : "OGEVILLER", cp : "54450" },
{ code : "54407", intitule : "OGNEVILLE", cp : "54330" },
{ code : "54408", intitule : "OLLEY", cp : "54800" },
{ code : "54409", intitule : "OMELMONT", cp : "54330" },
{ code : "54410", intitule : "ONVILLE", cp : "54890" },
{ code : "54411", intitule : "ORMES ET VILLE", cp : "54740" },
{ code : "54412", intitule : "OTHE", cp : "54260" },
{ code : "54413", intitule : "OZERAILLES", cp : "54150" },
{ code : "54414", intitule : "PAGNEY DERRIERE BARINE", cp : "54200" },
{ code : "54415", intitule : "PAGNY SUR MOSELLE", cp : "54530" },
{ code : "54416", intitule : "PANNES", cp : "54470" },
{ code : "54417", intitule : "PAREY ST CESAIRE", cp : "54330" },
{ code : "54418", intitule : "PARROY", cp : "54370" },
{ code : "54419", intitule : "PARUX", cp : "54480" },
{ code : "54420", intitule : "PETIT FAILLY", cp : "54260" },
{ code : "54421", intitule : "PETITMONT", cp : "54480" },
{ code : "54422", intitule : "PETTONVILLE", cp : "54120" },
{ code : "54423", intitule : "PEXONNE", cp : "54540" },
{ code : "54424", intitule : "PHLIN", cp : "54610" },
{ code : "54425", intitule : "PIENNES", cp : "54490" },
{ code : "54426", intitule : "PIERRE LA TREICHE", cp : "54200" },
{ code : "54427", intitule : "PIERRE PERCEE", cp : "54540" },
{ code : "54428", intitule : "PIERREPONT", cp : "54620" },
{ code : "54429", intitule : "PIERREVILLE", cp : "54160" },
{ code : "54430", intitule : "POMPEY", cp : "54340" },
{ code : "54431", intitule : "PONT A MOUSSON", cp : "54700" },
{ code : "54432", intitule : "PONT ST VINCENT", cp : "54550" },
{ code : "54433", intitule : "PORT SUR SEILLE", cp : "54700" },
{ code : "54434", intitule : "PRAYE", cp : "54116" },
{ code : "54435", intitule : "PRENY", cp : "54530" },
{ code : "54436", intitule : "PREUTIN HIGNY", cp : "54490" },
{ code : "54437", intitule : "PULLIGNY", cp : "54160" },
{ code : "54438", intitule : "PULNEY", cp : "54115" },
{ code : "54439", intitule : "PULNOY", cp : "54425" },
{ code : "54440", intitule : "PUXE", cp : "54800" },
{ code : "54441", intitule : "PUXIEUX", cp : "54800" },
{ code : "54442", intitule : "QUEVILLONCOURT", cp : "54330" },
{ code : "54443", intitule : "RAON LES LEAU", cp : "54540" },
{ code : "54444", intitule : "RAUCOURT", cp : "54610" },
{ code : "54445", intitule : "RAVILLE SUR SANON", cp : "54370" },
{ code : "54446", intitule : "RECHICOURT LA PETITE", cp : "54370" },
{ code : "54447", intitule : "RECLONVILLE", cp : "54450" },
{ code : "54449", intitule : "REHAINVILLER", cp : "54300" },
{ code : "54450", intitule : "REHERREY", cp : "54120" },
{ code : "54451", intitule : "REHON", cp : "54430" },
{ code : "54451", intitule : "REHON", cp : "54430" },
{ code : "54452", intitule : "REILLON", cp : "54450" },
{ code : "54453", intitule : "REMBERCOURT SUR MAD", cp : "54470" },
{ code : "54455", intitule : "REMENOVILLE", cp : "54830" },
{ code : "54456", intitule : "REMEREVILLE", cp : "54110" },
{ code : "54457", intitule : "REMONCOURT", cp : "54370" },
{ code : "54458", intitule : "REPAIX", cp : "54450" },
{ code : "54459", intitule : "RICHARDMENIL", cp : "54630" },
{ code : "54460", intitule : "ROGEVILLE", cp : "54380" },
{ code : "54461", intitule : "ROMAIN", cp : "54360" },
{ code : "54462", intitule : "ROSIERES AUX SALINES", cp : "54110" },
{ code : "54463", intitule : "ROSIERES EN HAYE", cp : "54385" },
{ code : "54464", intitule : "ROUVES", cp : "54610" },
{ code : "54465", intitule : "ROVILLE DEVANT BAYON", cp : "54290" },
{ code : "54466", intitule : "ROYAUMEIX", cp : "54200" },
{ code : "54467", intitule : "ROZELIEURES", cp : "54290" },
{ code : "54468", intitule : "SAFFAIS", cp : "54210" },
{ code : "54469", intitule : "ST AIL", cp : "54580" },
{ code : "54469", intitule : "ST AIL", cp : "54580" },
{ code : "54470", intitule : "ST BAUSSANT", cp : "54470" },
{ code : "54471", intitule : "ST BOINGT", cp : "54290" },
{ code : "54472", intitule : "ST CLEMENT", cp : "54950" },
{ code : "54473", intitule : "ST FIRMIN", cp : "54930" },
{ code : "54474", intitule : "STE GENEVIEVE", cp : "54700" },
{ code : "54475", intitule : "ST GERMAIN", cp : "54290" },
{ code : "54476", intitule : "ST JEAN LES LONGUYON", cp : "54260" },
{ code : "54476", intitule : "ST JEAN LES LONGUYON", cp : "54260" },
{ code : "54477", intitule : "ST JULIEN LES GORZE", cp : "54470" },
{ code : "54478", intitule : "ST MARCEL", cp : "54800" },
{ code : "54479", intitule : "ST MARD", cp : "54290" },
{ code : "54480", intitule : "ST MARTIN", cp : "54450" },
{ code : "54481", intitule : "ST MAURICE AUX FORGES", cp : "54540" },
{ code : "54482", intitule : "ST MAX", cp : "54130" },
{ code : "54483", intitule : "ST NICOLAS DE PORT", cp : "54210" },
{ code : "54484", intitule : "STE POLE", cp : "54540" },
{ code : "54485", intitule : "ST PANCRE", cp : "54730" },
{ code : "54486", intitule : "ST REMIMONT", cp : "54740" },
{ code : "54487", intitule : "ST REMY AUX BOIS", cp : "54290" },
{ code : "54488", intitule : "ST SAUVEUR", cp : "54480" },
{ code : "54489", intitule : "ST SUPPLET", cp : "54620" },
{ code : "54490", intitule : "SAIZERAIS", cp : "54380" },
{ code : "54491", intitule : "SANCY", cp : "54560" },
{ code : "54492", intitule : "SANZEY", cp : "54200" },
{ code : "54493", intitule : "SAULNES", cp : "54650" },
{ code : "54494", intitule : "SAULXEROTTE", cp : "54115" },
{ code : "54495", intitule : "SAULXURES LES NANCY", cp : "54420" },
{ code : "54496", intitule : "SAULXURES LES VANNES", cp : "54170" },
{ code : "54497", intitule : "SAXON SION", cp : "54330" },
{ code : "54498", intitule : "SEICHAMPS", cp : "54280" },
{ code : "54499", intitule : "SEICHEPREY", cp : "54470" },
{ code : "54500", intitule : "SELAINCOURT", cp : "54170" },
{ code : "54501", intitule : "SERANVILLE", cp : "54830" },
{ code : "54502", intitule : "SERRES", cp : "54370" },
{ code : "54504", intitule : "SERROUVILLE", cp : "54560" },
{ code : "54505", intitule : "SEXEY AUX FORGES", cp : "54550" },
{ code : "54506", intitule : "SEXEY LES BOIS", cp : "54840" },
{ code : "54507", intitule : "SIONVILLER", cp : "54300" },
{ code : "54508", intitule : "SIVRY", cp : "54610" },
{ code : "54509", intitule : "SOMMERVILLER", cp : "54110" },
{ code : "54510", intitule : "SORNEVILLE", cp : "54280" },
{ code : "54511", intitule : "SPONVILLE", cp : "54800" },
{ code : "54512", intitule : "TANCONVILLE", cp : "54480" },
{ code : "54513", intitule : "TANTONVILLE", cp : "54116" },
{ code : "54514", intitule : "TELLANCOURT", cp : "54260" },
{ code : "54515", intitule : "THELOD", cp : "54330" },
{ code : "54516", intitule : "THEY SOUS VAUDEMONT", cp : "54930" },
{ code : "54517", intitule : "THEZEY ST MARTIN", cp : "54610" },
{ code : "54518", intitule : "THIAUCOURT REGNIEVILLE", cp : "54470" },
{ code : "54519", intitule : "THIAVILLE SUR MEURTHE", cp : "54120" },
{ code : "54520", intitule : "THIEBAUMENIL", cp : "54300" },
{ code : "54521", intitule : "THIL", cp : "54880" },
{ code : "54522", intitule : "THOREY LYAUTEY", cp : "54115" },
{ code : "54523", intitule : "THUILLEY AUX GROSEILLES", cp : "54170" },
{ code : "54524", intitule : "THUMEREVILLE", cp : "54800" },
{ code : "54525", intitule : "TIERCELET", cp : "54190" },
{ code : "54526", intitule : "TOMBLAINE", cp : "54510" },
{ code : "54527", intitule : "TONNOY", cp : "54210" },
{ code : "54528", intitule : "TOUL", cp : "54200" },
{ code : "54529", intitule : "TRAMONT EMY", cp : "54115" },
{ code : "54530", intitule : "TRAMONT LASSUS", cp : "54115" },
{ code : "54531", intitule : "TRAMONT ST ANDRE", cp : "54115" },
{ code : "54532", intitule : "TREMBLECOURT", cp : "54385" },
{ code : "54533", intitule : "TRIEUX", cp : "54750" },
{ code : "54534", intitule : "TRONDES", cp : "54570" },
{ code : "54535", intitule : "TRONVILLE", cp : "54800" },
{ code : "54536", intitule : "TUCQUEGNIEUX", cp : "54640" },
{ code : "54537", intitule : "UGNY", cp : "54870" },
{ code : "54538", intitule : "URUFFE", cp : "54112" },
{ code : "54539", intitule : "VACQUEVILLE", cp : "54540" },
{ code : "54540", intitule : "VAL ET CHATILLON", cp : "54480" },
{ code : "54541", intitule : "VALHEY", cp : "54370" },
{ code : "54542", intitule : "VALLEROY", cp : "54910" },
{ code : "54543", intitule : "VALLOIS", cp : "54830" },
{ code : "54544", intitule : "VANDELAINVILLE", cp : "54890" },
{ code : "54545", intitule : "VANDELEVILLE", cp : "54115" },
{ code : "54546", intitule : "VANDIERES", cp : "54121" },
{ code : "54547", intitule : "VANDOEUVRE LES NANCY", cp : "54500" },
{ code : "54548", intitule : "VANNES LE CHATEL", cp : "54112" },
{ code : "54549", intitule : "VARANGEVILLE", cp : "54110" },
{ code : "54550", intitule : "VATHIMENIL", cp : "54122" },
{ code : "54551", intitule : "VAUCOURT", cp : "54370" },
{ code : "54552", intitule : "VAUDEMONT", cp : "54330" },
{ code : "54553", intitule : "VAUDEVILLE", cp : "54740" },
{ code : "54554", intitule : "VAUDIGNY", cp : "54740" },
{ code : "54555", intitule : "VAXAINVILLE", cp : "54120" },
{ code : "54556", intitule : "VEHO", cp : "54450" },
{ code : "54557", intitule : "VELAINE EN HAYE", cp : "54840" },
{ code : "54558", intitule : "VELAINE SOUS AMANCE", cp : "54280" },
{ code : "54559", intitule : "VELLE SUR MOSELLE", cp : "54290" },
{ code : "54560", intitule : "VENEY", cp : "54540" },
{ code : "54561", intitule : "VENNEZEY", cp : "54830" },
{ code : "54562", intitule : "VERDENAL", cp : "54450" },
{ code : "54563", intitule : "VEZELISE", cp : "54330" },
{ code : "54564", intitule : "VIEVILLE EN HAYE", cp : "54470" },
{ code : "54565", intitule : "VIGNEULLES", cp : "54360" },
{ code : "54566", intitule : "VILCEY SUR TREY", cp : "54700" },
{ code : "54567", intitule : "VILLACOURT", cp : "54290" },
{ code : "54568", intitule : "VILLE AU MONTOIS", cp : "54620" },
{ code : "54569", intitule : "VILLE AU VAL", cp : "54380" },
{ code : "54570", intitule : "VILLECEY SUR MAD", cp : "54890" },
{ code : "54571", intitule : "VILLE EN VERMOIS", cp : "54210" },
{ code : "54572", intitule : "VILLE HOUDLEMONT", cp : "54730" },
{ code : "54573", intitule : "VILLERS EN HAYE", cp : "54380" },
{ code : "54574", intitule : "VILLERS LA CHEVRE", cp : "54870" },
{ code : "54575", intitule : "VILLERS LA MONTAGNE", cp : "54920" },
{ code : "54576", intitule : "VILLERS LE ROND", cp : "54260" },
{ code : "54577", intitule : "VILLERS LES MOIVRONS", cp : "54760" },
{ code : "54578", intitule : "VILLERS LES NANCY", cp : "54600" },
{ code : "54579", intitule : "VILLERS SOUS PRENY", cp : "54700" },
{ code : "54580", intitule : "VILLERUPT", cp : "54190" },
{ code : "54580", intitule : "VILLERUPT", cp : "54190" },
{ code : "54581", intitule : "VILLE SUR YRON", cp : "54800" },
{ code : "54582", intitule : "VILLETTE", cp : "54260" },
{ code : "54583", intitule : "VILLEY LE SEC", cp : "54840" },
{ code : "54584", intitule : "VILLEY ST ETIENNE", cp : "54200" },
{ code : "54585", intitule : "VIRECOURT", cp : "54290" },
{ code : "54586", intitule : "VITERNE", cp : "54123" },
{ code : "54587", intitule : "VITREY", cp : "54330" },
{ code : "54588", intitule : "VITRIMONT", cp : "54300" },
{ code : "54589", intitule : "VITTONVILLE", cp : "54700" },
{ code : "54590", intitule : "VIVIERS SUR CHIERS", cp : "54260" },
{ code : "54590", intitule : "VIVIERS SUR CHIERS", cp : "54260" },
{ code : "54590", intitule : "VIVIERS SUR CHIERS", cp : "54260" },
{ code : "54591", intitule : "VOINEMONT", cp : "54134" },
{ code : "54592", intitule : "VRONCOURT", cp : "54330" },
{ code : "54593", intitule : "WAVILLE", cp : "54890" },
{ code : "54594", intitule : "XAMMES", cp : "54470" },
{ code : "54595", intitule : "XERMAMENIL", cp : "54300" },
{ code : "54596", intitule : "XEUILLEY", cp : "54990" },
{ code : "54597", intitule : "XIROCOURT", cp : "54740" },
{ code : "54598", intitule : "XIVRY CIRCOURT", cp : "54490" },
{ code : "54599", intitule : "XONVILLE", cp : "54800" },
{ code : "54600", intitule : "XOUSSE", cp : "54370" },
{ code : "54601", intitule : "XURES", cp : "54370" },
{ code : "54602", intitule : "HAN DEVANT PIERREPONT", cp : "54620" },
{ code : "55001", intitule : "ABAINVILLE", cp : "55130" },
{ code : "55002", intitule : "ABAUCOURT HAUTECOURT", cp : "55400" },
{ code : "55002", intitule : "ABAUCOURT HAUTECOURT", cp : "55400" },
{ code : "55004", intitule : "AINCREVILLE", cp : "55110" },
{ code : "55005", intitule : "AMANTY", cp : "55130" },
{ code : "55007", intitule : "AMBLY SUR MEUSE", cp : "55300" },
{ code : "55008", intitule : "AMEL SUR L ETANG", cp : "55230" },
{ code : "55009", intitule : "ANCEMONT", cp : "55320" },
{ code : "55010", intitule : "ANCERVILLE", cp : "55170" },
{ code : "55011", intitule : "ANDERNAY", cp : "55800" },
{ code : "55012", intitule : "APREMONT LA FORET", cp : "55300" },
{ code : "55012", intitule : "APREMONT LA FORET", cp : "55300" },
{ code : "55012", intitule : "APREMONT LA FORET", cp : "55300" },
{ code : "55012", intitule : "APREMONT LA FORET", cp : "55300" },
{ code : "55013", intitule : "ARRANCY SUR CRUSNE", cp : "55230" },
{ code : "55014", intitule : "AUBREVILLE", cp : "55120" },
{ code : "55015", intitule : "AULNOIS EN PERTHOIS", cp : "55170" },
{ code : "55017", intitule : "AUTRECOURT SUR AIRE", cp : "55120" },
{ code : "55018", intitule : "AUTREVILLE ST LAMBERT", cp : "55700" },
{ code : "55021", intitule : "AVILLERS STE CROIX", cp : "55210" },
{ code : "55022", intitule : "AVIOTH", cp : "55600" },
{ code : "55023", intitule : "AVOCOURT", cp : "55270" },
{ code : "55024", intitule : "AZANNES ET SOUMAZANNES", cp : "55150" },
{ code : "55025", intitule : "BAALON", cp : "55700" },
{ code : "55026", intitule : "BADONVILLIERS GERAUVILLIERS", cp : "55130" },
{ code : "55026", intitule : "BADONVILLIERS GERAUVILLIERS", cp : "55130" },
{ code : "55027", intitule : "BANNONCOURT", cp : "55300" },
{ code : "55028", intitule : "BANTHEVILLE", cp : "55110" },
{ code : "55029", intitule : "BAR LE DUC", cp : "55000" },
{ code : "55030", intitule : "BAUDIGNECOURT", cp : "55130" },
{ code : "55031", intitule : "BAUDONVILLIERS", cp : "55170" },
{ code : "55032", intitule : "BAUDREMONT", cp : "55260" },
{ code : "55033", intitule : "BAULNY", cp : "55270" },
{ code : "55034", intitule : "BAZEILLES SUR OTHAIN", cp : "55600" },
{ code : "55035", intitule : "BAZINCOURT SUR SAULX", cp : "55170" },
{ code : "55036", intitule : "BEAUCLAIR", cp : "55700" },
{ code : "55037", intitule : "BEAUFORT EN ARGONNE", cp : "55700" },
{ code : "55038", intitule : "BEAULIEU EN ARGONNE", cp : "55250" },
{ code : "55039", intitule : "BEAUMONT EN VERDUNOIS", cp : "55100" },
{ code : "55040", intitule : "BEAUSITE", cp : "55250" },
{ code : "55040", intitule : "BEAUSITE", cp : "55250" },
{ code : "55040", intitule : "BEAUSITE", cp : "55250" },
{ code : "55040", intitule : "BEAUSITE", cp : "55250" },
{ code : "55041", intitule : "BEHONNE", cp : "55000" },
{ code : "55042", intitule : "BELLERAY", cp : "55100" },
{ code : "55043", intitule : "BELLEVILLE SUR MEUSE", cp : "55430" },
{ code : "55044", intitule : "BELRAIN", cp : "55260" },
{ code : "55045", intitule : "BELRUPT EN VERDUNOIS", cp : "55100" },
{ code : "55046", intitule : "BENEY EN WOEVRE", cp : "55210" },
{ code : "55047", intitule : "BETHELAINVILLE", cp : "55100" },
{ code : "55048", intitule : "BETHINCOURT", cp : "55270" },
{ code : "55049", intitule : "BEUREY SUR SAULX", cp : "55000" },
{ code : "55050", intitule : "BEZONVAUX", cp : "55100" },
{ code : "55051", intitule : "BIENCOURT SUR ORGE", cp : "55290" },
{ code : "55053", intitule : "BILLY SOUS MANGIENNES", cp : "55230" },
{ code : "55054", intitule : "BISLEE", cp : "55300" },
{ code : "55055", intitule : "BLANZEE", cp : "55400" },
{ code : "55057", intitule : "BOINVILLE EN WOEVRE", cp : "55400" },
{ code : "55058", intitule : "BONCOURT SUR MEUSE", cp : "55200" },
{ code : "55059", intitule : "BONNET", cp : "55130" },
{ code : "55060", intitule : "BONZEE", cp : "55160" },
{ code : "55060", intitule : "BONZEE", cp : "55160" },
{ code : "55060", intitule : "BONZEE", cp : "55160" },
{ code : "55061", intitule : "LE BOUCHON SUR SAULX", cp : "55500" },
{ code : "55062", intitule : "BOUCONVILLE SUR MADT", cp : "55300" },
{ code : "55063", intitule : "BOULIGNY", cp : "55240" },
{ code : "55064", intitule : "BOUQUEMONT", cp : "55300" },
{ code : "55065", intitule : "BOUREUILLES", cp : "55270" },
{ code : "55066", intitule : "BOVEE SUR BARBOURE", cp : "55190" },
{ code : "55067", intitule : "BOVIOLLES", cp : "55500" },
{ code : "55068", intitule : "BRABANT EN ARGONNE", cp : "55120" },
{ code : "55069", intitule : "BRABANT LE ROI", cp : "55800" },
{ code : "55070", intitule : "BRABANT SUR MEUSE", cp : "55100" },
{ code : "55071", intitule : "BRANDEVILLE", cp : "55150" },
{ code : "55072", intitule : "BRAQUIS", cp : "55400" },
{ code : "55073", intitule : "BRAS SUR MEUSE", cp : "55100" },
{ code : "55075", intitule : "BRAUVILLIERS", cp : "55170" },
{ code : "55076", intitule : "BREHEVILLE", cp : "55150" },
{ code : "55077", intitule : "BREUX", cp : "55600" },
{ code : "55078", intitule : "BRIEULLES SUR MEUSE", cp : "55110" },
{ code : "55079", intitule : "BRILLON EN BARROIS", cp : "55000" },
{ code : "55080", intitule : "BRIXEY AUX CHANOINES", cp : "55140" },
{ code : "55081", intitule : "BRIZEAUX", cp : "55250" },
{ code : "55082", intitule : "BROCOURT EN ARGONNE", cp : "55120" },
{ code : "55083", intitule : "BROUENNES", cp : "55700" },
{ code : "55084", intitule : "BROUSSEY EN BLOIS", cp : "55190" },
{ code : "55085", intitule : "BROUSSEY RAULECOURT", cp : "55200" },
{ code : "55085", intitule : "BROUSSEY RAULECOURT", cp : "55200" },
{ code : "55087", intitule : "BURE", cp : "55290" },
{ code : "55088", intitule : "BUREY EN VAUX", cp : "55140" },
{ code : "55089", intitule : "BUREY LA COTE", cp : "55140" },
{ code : "55093", intitule : "BUXIERES SOUS LES COTES", cp : "55300" },
{ code : "55093", intitule : "BUXIERES SOUS LES COTES", cp : "55300" },
{ code : "55093", intitule : "BUXIERES SOUS LES COTES", cp : "55300" },
{ code : "55094", intitule : "BUZY DARMONT", cp : "55400" },
{ code : "55094", intitule : "BUZY DARMONT", cp : "55400" },
{ code : "55095", intitule : "CESSE", cp : "55700" },
{ code : "55096", intitule : "CHAILLON", cp : "55210" },
{ code : "55097", intitule : "CHALAINES", cp : "55140" },
{ code : "55099", intitule : "CHAMPNEUVILLE", cp : "55100" },
{ code : "55100", intitule : "CHAMPOUGNY", cp : "55140" },
{ code : "55101", intitule : "CHARDOGNE", cp : "55000" },
{ code : "55102", intitule : "CHARNY SUR MEUSE", cp : "55100" },
{ code : "55103", intitule : "CHARPENTRY", cp : "55270" },
{ code : "55104", intitule : "CHASSEY BEAUPRE", cp : "55130" },
{ code : "55105", intitule : "CHATILLON SOUS LES COTES", cp : "55400" },
{ code : "55106", intitule : "CHATTANCOURT", cp : "55100" },
{ code : "55107", intitule : "CHAUMONT DEVANT DAMVILLERS", cp : "55150" },
{ code : "55108", intitule : "CHAUMONT SUR AIRE", cp : "55260" },
{ code : "55109", intitule : "CHAUVENCY LE CHATEAU", cp : "55600" },
{ code : "55110", intitule : "CHAUVENCY ST HUBERT", cp : "55600" },
{ code : "55111", intitule : "CHAUVONCOURT", cp : "55300" },
{ code : "55113", intitule : "CHEPPY", cp : "55270" },
{ code : "55114", intitule : "CHONVILLE MALAUMONT", cp : "55200" },
{ code : "55114", intitule : "CHONVILLE MALAUMONT", cp : "55200" },
{ code : "55115", intitule : "CIERGES SOUS MONTFAUCON", cp : "55270" },
{ code : "55116", intitule : "LE CLAON", cp : "55120" },
{ code : "55117", intitule : "CLERMONT EN ARGONNE", cp : "55120" },
{ code : "55117", intitule : "CLERMONT EN ARGONNE", cp : "55120" },
{ code : "55117", intitule : "CLERMONT EN ARGONNE", cp : "55120" },
{ code : "55117", intitule : "CLERMONT EN ARGONNE", cp : "55120" },
{ code : "55118", intitule : "CLERY LE GRAND", cp : "55110" },
{ code : "55119", intitule : "CLERY LE PETIT", cp : "55110" },
{ code : "55120", intitule : "COMBLES EN BARROIS", cp : "55000" },
{ code : "55121", intitule : "COMBRES SOUS LES COTES", cp : "55160" },
{ code : "55122", intitule : "COMMERCY", cp : "55200" },
{ code : "55123", intitule : "LES HAUTS DE CHEE", cp : "55000" },
{ code : "55123", intitule : "LES HAUTS DE CHEE", cp : "55000" },
{ code : "55123", intitule : "LES HAUTS DE CHEE", cp : "55000" },
{ code : "55123", intitule : "LES HAUTS DE CHEE", cp : "55000" },
{ code : "55123", intitule : "LES HAUTS DE CHEE", cp : "55000" },
{ code : "55124", intitule : "CONSENVOYE", cp : "55110" },
{ code : "55125", intitule : "CONTRISSON", cp : "55800" },
{ code : "55127", intitule : "COURCELLES EN BARROIS", cp : "55260" },
{ code : "55128", intitule : "COURCELLES SUR AIRE", cp : "55260" },
{ code : "55129", intitule : "COUROUVRE", cp : "55260" },
{ code : "55132", intitule : "COUSANCES LES FORGES", cp : "55170" },
{ code : "55133", intitule : "COUVERTPUIS", cp : "55290" },
{ code : "55134", intitule : "COUVONGES", cp : "55800" },
{ code : "55137", intitule : "CUISY", cp : "55270" },
{ code : "55138", intitule : "CULEY", cp : "55000" },
{ code : "55139", intitule : "CUMIERES LE MORT HOMME", cp : "55100" },
{ code : "55140", intitule : "CUNEL", cp : "55110" },
{ code : "55141", intitule : "DAGONVILLE", cp : "55500" },
{ code : "55142", intitule : "DAINVILLE BERTHELEVILLE", cp : "55130" },
{ code : "55143", intitule : "DAMLOUP", cp : "55400" },
{ code : "55144", intitule : "DAMMARIE SUR SAULX", cp : "55500" },
{ code : "55145", intitule : "DAMVILLERS", cp : "55150" },
{ code : "55146", intitule : "DANNEVOUX", cp : "55110" },
{ code : "55148", intitule : "DELOUZE ROSIERES", cp : "55130" },
{ code : "55148", intitule : "DELOUZE ROSIERES", cp : "55130" },
{ code : "55149", intitule : "DELUT", cp : "55150" },
{ code : "55150", intitule : "DEMANGE AUX EAUX", cp : "55130" },
{ code : "55153", intitule : "DIEPPE SOUS DOUAUMONT", cp : "55400" },
{ code : "55154", intitule : "DIEUE SUR MEUSE", cp : "55320" },
{ code : "55155", intitule : "DOMBASLE EN ARGONNE", cp : "55120" },
{ code : "55156", intitule : "DOMBRAS", cp : "55150" },
{ code : "55157", intitule : "DOMMARTIN LA MONTAGNE", cp : "55160" },
{ code : "55158", intitule : "DOMMARY BARONCOURT", cp : "55240" },
{ code : "55159", intitule : "DOMPCEVRIN", cp : "55300" },
{ code : "55160", intitule : "DOMPIERRE AUX BOIS", cp : "55300" },
{ code : "55162", intitule : "DOMREMY LA CANNE", cp : "55240" },
{ code : "55163", intitule : "DONCOURT AUX TEMPLIERS", cp : "55160" },
{ code : "55164", intitule : "DOUAUMONT", cp : "55100" },
{ code : "55165", intitule : "DOULCON", cp : "55110" },
{ code : "55166", intitule : "DUGNY SUR MEUSE", cp : "55100" },
{ code : "55167", intitule : "DUN SUR MEUSE", cp : "55110" },
{ code : "55168", intitule : "DUZEY", cp : "55230" },
{ code : "55169", intitule : "ECOUVIEZ", cp : "55600" },
{ code : "55170", intitule : "ECUREY EN VERDUNOIS", cp : "55150" },
{ code : "55171", intitule : "EIX", cp : "55400" },
{ code : "55172", intitule : "LES EPARGES", cp : "55160" },
{ code : "55173", intitule : "EPIEZ SUR MEUSE", cp : "55140" },
{ code : "55174", intitule : "EPINONVILLE", cp : "55270" },
{ code : "55175", intitule : "ERIZE LA BRULEE", cp : "55260" },
{ code : "55177", intitule : "ERIZE LA PETITE", cp : "55260" },
{ code : "55178", intitule : "ERIZE ST DIZIER", cp : "55000" },
{ code : "55179", intitule : "ERNEVILLE AUX BOIS", cp : "55500" },
{ code : "55179", intitule : "ERNEVILLE AUX BOIS", cp : "55500" },
{ code : "55179", intitule : "ERNEVILLE AUX BOIS", cp : "55500" },
{ code : "55180", intitule : "ESNES EN ARGONNE", cp : "55100" },
{ code : "55181", intitule : "ETAIN", cp : "55400" },
{ code : "55182", intitule : "ETON", cp : "55240" },
{ code : "55183", intitule : "ETRAYE", cp : "55150" },
{ code : "55184", intitule : "EUVILLE", cp : "55200" },
{ code : "55184", intitule : "EUVILLE", cp : "55200" },
{ code : "55184", intitule : "EUVILLE", cp : "55200" },
{ code : "55184", intitule : "EUVILLE", cp : "55200" },
{ code : "55185", intitule : "EVRES", cp : "55250" },
{ code : "55186", intitule : "FAINS VEEL", cp : "55000" },
{ code : "55186", intitule : "FAINS VEEL", cp : "55000" },
{ code : "55188", intitule : "FLASSIGNY", cp : "55600" },
{ code : "55189", intitule : "FLEURY DEVANT DOUAUMONT", cp : "55100" },
{ code : "55191", intitule : "FOAMEIX ORNEL", cp : "55400" },
{ code : "55191", intitule : "FOAMEIX ORNEL", cp : "55400" },
{ code : "55192", intitule : "FONTAINES ST CLAIR", cp : "55110" },
{ code : "55193", intitule : "FORGES SUR MEUSE", cp : "55110" },
{ code : "55194", intitule : "FOUCAUCOURT SUR THABAS", cp : "55250" },
{ code : "55195", intitule : "FOUCHERES AUX BOIS", cp : "55500" },
{ code : "55196", intitule : "FREMEREVILLE SOUS LES COTES", cp : "55200" },
{ code : "55197", intitule : "FRESNES AU MONT", cp : "55260" },
{ code : "55198", intitule : "FRESNES EN WOEVRE", cp : "55160" },
{ code : "55199", intitule : "FROIDOS", cp : "55120" },
{ code : "55200", intitule : "FROMEREVILLE LES VALLONS", cp : "55100" },
{ code : "55201", intitule : "FROMEZEY", cp : "55400" },
{ code : "55202", intitule : "FUTEAU", cp : "55120" },
{ code : "55204", intitule : "GENICOURT SUR MEUSE", cp : "55320" },
{ code : "55206", intitule : "GERCOURT ET DRILLANCOURT", cp : "55110" },
{ code : "55207", intitule : "GERY", cp : "55000" },
{ code : "55208", intitule : "GESNES EN ARGONNE", cp : "55110" },
{ code : "55210", intitule : "GIMECOURT", cp : "55260" },
{ code : "55211", intitule : "GINCREY", cp : "55400" },
{ code : "55212", intitule : "GIRAUVOISIN", cp : "55200" },
{ code : "55214", intitule : "GIVRAUVAL", cp : "55500" },
{ code : "55215", intitule : "GONDRECOURT LE CHATEAU", cp : "55130" },
{ code : "55215", intitule : "GONDRECOURT LE CHATEAU", cp : "55130" },
{ code : "55215", intitule : "GONDRECOURT LE CHATEAU", cp : "55130" },
{ code : "55216", intitule : "GOURAINCOURT", cp : "55230" },
{ code : "55217", intitule : "GOUSSAINCOURT", cp : "55140" },
{ code : "55218", intitule : "GREMILLY", cp : "55150" },
{ code : "55219", intitule : "GRIMAUCOURT EN WOEVRE", cp : "55400" },
{ code : "55220", intitule : "GRIMAUCOURT PRES SAMPIGNY", cp : "55500" },
{ code : "55221", intitule : "GUERPONT", cp : "55000" },
{ code : "55222", intitule : "GUSSAINVILLE", cp : "55400" },
{ code : "55224", intitule : "HAIRONVILLE", cp : "55000" },
{ code : "55225", intitule : "HALLES SOUS LES COTES", cp : "55700" },
{ code : "55226", intitule : "HAN LES JUVIGNY", cp : "55600" },
{ code : "55228", intitule : "HANNONVILLE SOUS LES COTES", cp : "55210" },
{ code : "55229", intitule : "HAN SUR MEUSE", cp : "55300" },
{ code : "55229", intitule : "HAN SUR MEUSE", cp : "55300" },
{ code : "55229", intitule : "HAN SUR MEUSE", cp : "55300" },
{ code : "55232", intitule : "HARVILLE", cp : "55160" },
{ code : "55236", intitule : "HAUDAINVILLE", cp : "55100" },
{ code : "55237", intitule : "HAUDIOMONT", cp : "55160" },
{ code : "55239", intitule : "HAUMONT PRES SAMOGNEUX", cp : "55100" },
{ code : "55241", intitule : "HEIPPES", cp : "55220" },
{ code : "55242", intitule : "HENNEMONT", cp : "55160" },
{ code : "55243", intitule : "HERBEUVILLE", cp : "55210" },
{ code : "55244", intitule : "HERMEVILLE EN WOEVRE", cp : "55400" },
{ code : "55245", intitule : "HEUDICOURT SOUS LES COTES", cp : "55210" },
{ code : "55246", intitule : "HEVILLIERS", cp : "55290" },
{ code : "55247", intitule : "HORVILLE EN ORNOIS", cp : "55130" },
{ code : "55248", intitule : "HOUDELAINCOURT", cp : "55130" },
{ code : "55250", intitule : "INOR", cp : "55700" },
{ code : "55251", intitule : "IPPECOURT", cp : "55220" },
{ code : "55252", intitule : "IRE LE SEC", cp : "55600" },
{ code : "55253", intitule : "LES ISLETTES", cp : "55120" },
{ code : "55254", intitule : "LES TROIS DOMAINES", cp : "55220" },
{ code : "55254", intitule : "LES TROIS DOMAINES", cp : "55220" },
{ code : "55254", intitule : "LES TROIS DOMAINES", cp : "55220" },
{ code : "55254", intitule : "LES TROIS DOMAINES", cp : "55220" },
{ code : "55255", intitule : "JAMETZ", cp : "55600" },
{ code : "55256", intitule : "JONVILLE EN WOEVRE", cp : "55160" },
{ code : "55257", intitule : "JOUY EN ARGONNE", cp : "55120" },
{ code : "55258", intitule : "GEVILLE", cp : "55200" },
{ code : "55258", intitule : "GEVILLE", cp : "55200" },
{ code : "55258", intitule : "GEVILLE", cp : "55200" },
{ code : "55260", intitule : "JULVECOURT", cp : "55120" },
{ code : "55261", intitule : "JUVIGNY EN PERTHOIS", cp : "55170" },
{ code : "55262", intitule : "JUVIGNY SUR LOISON", cp : "55600" },
{ code : "55263", intitule : "KOEUR LA GRANDE", cp : "55300" },
{ code : "55264", intitule : "KOEUR LA PETITE", cp : "55300" },
{ code : "55265", intitule : "LABEUVILLE", cp : "55160" },
{ code : "55266", intitule : "LACHALADE", cp : "55120" },
{ code : "55267", intitule : "LACHAUSSEE", cp : "55210" },
{ code : "55267", intitule : "LACHAUSSEE", cp : "55210" },
{ code : "55267", intitule : "LACHAUSSEE", cp : "55210" },
{ code : "55268", intitule : "LACROIX SUR MEUSE", cp : "55300" },
{ code : "55269", intitule : "LAHAYMEIX", cp : "55260" },
{ code : "55270", intitule : "LAHAYVILLE", cp : "55300" },
{ code : "55271", intitule : "LAHEYCOURT", cp : "55800" },
{ code : "55272", intitule : "LAIMONT", cp : "55800" },
{ code : "55274", intitule : "LAMORVILLE", cp : "55300" },
{ code : "55274", intitule : "LAMORVILLE", cp : "55300" },
{ code : "55274", intitule : "LAMORVILLE", cp : "55300" },
{ code : "55274", intitule : "LAMORVILLE", cp : "55300" },
{ code : "55275", intitule : "LAMOUILLY", cp : "55700" },
{ code : "55276", intitule : "LANDRECOURT LEMPIRE", cp : "55100" },
{ code : "55276", intitule : "LANDRECOURT LEMPIRE", cp : "55100" },
{ code : "55278", intitule : "LANEUVILLE AU RUPT", cp : "55190" },
{ code : "55279", intitule : "LANEUVILLE SUR MEUSE", cp : "55700" },
{ code : "55280", intitule : "LANHERES", cp : "55400" },
{ code : "55281", intitule : "LATOUR EN WOEVRE", cp : "55160" },
{ code : "55282", intitule : "LAVALLEE", cp : "55260" },
{ code : "55284", intitule : "LAVINCOURT", cp : "55170" },
{ code : "55285", intitule : "LAVOYE", cp : "55120" },
{ code : "55286", intitule : "LEMMES", cp : "55220" },
{ code : "55288", intitule : "LEROUVILLE", cp : "55200" },
{ code : "55289", intitule : "LEVONCOURT", cp : "55260" },
{ code : "55290", intitule : "LIGNIERES SUR AIRE", cp : "55260" },
{ code : "55291", intitule : "LIGNY EN BARROIS", cp : "55500" },
{ code : "55292", intitule : "LINY DEVANT DUN", cp : "55110" },
{ code : "55293", intitule : "LION DEVANT DUN", cp : "55110" },
{ code : "55295", intitule : "LISLE EN BARROIS", cp : "55250" },
{ code : "55296", intitule : "LISLE EN RIGAULT", cp : "55000" },
{ code : "55297", intitule : "LISSEY", cp : "55150" },
{ code : "55298", intitule : "LOISEY", cp : "55000" },
{ code : "55299", intitule : "LOISON", cp : "55230" },
{ code : "55300", intitule : "LONGEAUX", cp : "55500" },
{ code : "55301", intitule : "LONGCHAMPS SUR AIRE", cp : "55260" },
{ code : "55302", intitule : "LONGEVILLE EN BARROIS", cp : "55000" },
{ code : "55303", intitule : "LOUPMONT", cp : "55300" },
{ code : "55304", intitule : "LOUPPY LE CHATEAU", cp : "55800" },
{ code : "55306", intitule : "LOUPPY SUR LOISON", cp : "55600" },
{ code : "55307", intitule : "LOUVEMONT COTE DU POIVRE", cp : "55100" },
{ code : "55310", intitule : "LUZY ST MARTIN", cp : "55700" },
{ code : "55311", intitule : "MAIZERAY", cp : "55160" },
{ code : "55312", intitule : "MAIZEY", cp : "55300" },
{ code : "55313", intitule : "MALANCOURT", cp : "55270" },
{ code : "55315", intitule : "MANDRES EN BARROIS", cp : "55290" },
{ code : "55316", intitule : "MANGIENNES", cp : "55150" },
{ code : "55317", intitule : "MANHEULLES", cp : "55160" },
{ code : "55320", intitule : "MARCHEVILLE EN WOEVRE", cp : "55160" },
{ code : "55321", intitule : "MARRE", cp : "55100" },
{ code : "55322", intitule : "MARSON SUR BARBOURE", cp : "55190" },
{ code : "55323", intitule : "MARTINCOURT SUR MEUSE", cp : "55700" },
{ code : "55324", intitule : "MARVILLE", cp : "55600" },
{ code : "55325", intitule : "MAUCOURT SUR ORNE", cp : "55400" },
{ code : "55326", intitule : "MAULAN", cp : "55500" },
{ code : "55327", intitule : "MAUVAGES", cp : "55190" },
{ code : "55328", intitule : "MAXEY SUR VAISE", cp : "55140" },
{ code : "55329", intitule : "MECRIN", cp : "55300" },
{ code : "55330", intitule : "MELIGNY LE GRAND", cp : "55190" },
{ code : "55331", intitule : "MELIGNY LE PETIT", cp : "55190" },
{ code : "55332", intitule : "MENAUCOURT", cp : "55500" },
{ code : "55333", intitule : "MENIL AUX BOIS", cp : "55260" },
{ code : "55334", intitule : "MENIL LA HORGNE", cp : "55190" },
{ code : "55335", intitule : "MENIL SUR SAULX", cp : "55500" },
{ code : "55336", intitule : "MERLES SUR LOISON", cp : "55150" },
{ code : "55338", intitule : "MILLY SUR BRADON", cp : "55110" },
{ code : "55339", intitule : "MOGEVILLE", cp : "55400" },
{ code : "55340", intitule : "MOGNEVILLE", cp : "55800" },
{ code : "55341", intitule : "MOIREY FLABAS CREPION", cp : "55150" },
{ code : "55341", intitule : "MOIREY FLABAS CREPION", cp : "55150" },
{ code : "55341", intitule : "MOIREY FLABAS CREPION", cp : "55150" },
{ code : "55343", intitule : "MONTBLAINVILLE", cp : "55270" },
{ code : "55344", intitule : "MONTBRAS", cp : "55140" },
{ code : "55345", intitule : "MONT DEVANT SASSEY", cp : "55110" },
{ code : "55346", intitule : "MONTFAUCON D ARGONNE", cp : "55270" },
{ code : "55347", intitule : "LES MONTHAIRONS", cp : "55320" },
{ code : "55348", intitule : "MONTIERS SUR SAULX", cp : "55290" },
{ code : "55349", intitule : "MONTIGNY DEVANT SASSEY", cp : "55110" },
{ code : "55350", intitule : "MONTIGNY LES VAUCOULEURS", cp : "55140" },
{ code : "55351", intitule : "MONTMEDY", cp : "55600" },
{ code : "55352", intitule : "MONTPLONNE", cp : "55000" },
{ code : "55353", intitule : "MONTSEC", cp : "55300" },
{ code : "55355", intitule : "MONTZEVILLE", cp : "55100" },
{ code : "55356", intitule : "MORANVILLE", cp : "55400" },
{ code : "55357", intitule : "MORGEMOULIN", cp : "55400" },
{ code : "55358", intitule : "CHANTERAINE", cp : "55500" },
{ code : "55358", intitule : "CHANTERAINE", cp : "55500" },
{ code : "55358", intitule : "CHANTERAINE", cp : "55500" },
{ code : "55359", intitule : "MORLEY", cp : "55290" },
{ code : "55360", intitule : "MOUILLY", cp : "55320" },
{ code : "55361", intitule : "MOULAINVILLE", cp : "55400" },
{ code : "55362", intitule : "MOULINS ST HUBERT", cp : "55700" },
{ code : "55363", intitule : "MOULOTTE", cp : "55160" },
{ code : "55364", intitule : "MOUZAY", cp : "55700" },
{ code : "55365", intitule : "MURVAUX", cp : "55110" },
{ code : "55366", intitule : "VAL D ORNAIN", cp : "55000" },
{ code : "55366", intitule : "VAL D ORNAIN", cp : "55000" },
{ code : "55366", intitule : "VAL D ORNAIN", cp : "55000" },
{ code : "55367", intitule : "MUZERAY", cp : "55230" },
{ code : "55368", intitule : "NAIVES EN BLOIS", cp : "55190" },
{ code : "55369", intitule : "NAIVES ROSIERES", cp : "55000" },
{ code : "55369", intitule : "NAIVES ROSIERES", cp : "55000" },
{ code : "55370", intitule : "NAIX AUX FORGES", cp : "55500" },
{ code : "55371", intitule : "NANCOIS LE GRAND", cp : "55500" },
{ code : "55372", intitule : "NANCOIS SUR ORNAIN", cp : "55500" },
{ code : "55373", intitule : "NANT LE GRAND", cp : "55500" },
{ code : "55374", intitule : "NANT LE PETIT", cp : "55500" },
{ code : "55375", intitule : "NANTILLOIS", cp : "55270" },
{ code : "55376", intitule : "NANTOIS", cp : "55500" },
{ code : "55377", intitule : "NEPVANT", cp : "55700" },
{ code : "55378", intitule : "NETTANCOURT", cp : "55800" },
{ code : "55379", intitule : "LE NEUFOUR", cp : "55120" },
{ code : "55380", intitule : "NEUVILLE EN VERDUNOIS", cp : "55260" },
{ code : "55381", intitule : "NEUVILLE LES VAUCOULEURS", cp : "55140" },
{ code : "55382", intitule : "NEUVILLE SUR ORNAIN", cp : "55800" },
{ code : "55383", intitule : "NEUVILLY EN ARGONNE", cp : "55120" },
{ code : "55384", intitule : "NICEY SUR AIRE", cp : "55260" },
{ code : "55385", intitule : "NIXEVILLE BLERCOURT", cp : "55120" },
{ code : "55385", intitule : "NIXEVILLE BLERCOURT", cp : "55120" },
{ code : "55386", intitule : "NONSARD LAMARCHE", cp : "55210" },
{ code : "55386", intitule : "NONSARD LAMARCHE", cp : "55210" },
{ code : "55387", intitule : "NOUILLONPONT", cp : "55230" },
{ code : "55388", intitule : "NOYERS AUZECOURT", cp : "55800" },
{ code : "55388", intitule : "NOYERS AUZECOURT", cp : "55800" },
{ code : "55389", intitule : "NUBECOURT", cp : "55250" },
{ code : "55389", intitule : "NUBECOURT", cp : "55250" },
{ code : "55389", intitule : "NUBECOURT", cp : "55250" },
{ code : "55391", intitule : "OLIZY SUR CHIERS", cp : "55700" },
{ code : "55394", intitule : "ORNES", cp : "55150" },
{ code : "55395", intitule : "OSCHES", cp : "55220" },
{ code : "55396", intitule : "OURCHES SUR MEUSE", cp : "55190" },
{ code : "55397", intitule : "PAGNY LA BLANCHE COTE", cp : "55140" },
{ code : "55398", intitule : "PAGNY SUR MEUSE", cp : "55190" },
{ code : "55399", intitule : "PAREID", cp : "55160" },
{ code : "55400", intitule : "PARFONDRUPT", cp : "55400" },
{ code : "55401", intitule : "LES PAROCHES", cp : "55300" },
{ code : "55403", intitule : "PEUVILLERS", cp : "55150" },
{ code : "55404", intitule : "PIERREFITTE SUR AIRE", cp : "55260" },
{ code : "55405", intitule : "PILLON", cp : "55230" },
{ code : "55406", intitule : "PINTHEVILLE", cp : "55160" },
{ code : "55407", intitule : "PONT SUR MEUSE", cp : "55200" },
{ code : "55408", intitule : "POUILLY SUR MEUSE", cp : "55700" },
{ code : "55409", intitule : "PRETZ EN ARGONNE", cp : "55250" },
{ code : "55410", intitule : "QUINCY LANDZECOURT", cp : "55600" },
{ code : "55411", intitule : "RAMBLUZIN ET BENOITE VAUX", cp : "55220" },
{ code : "55412", intitule : "RAMBUCOURT", cp : "55300" },
{ code : "55414", intitule : "RANCOURT SUR ORNAIN", cp : "55800" },
{ code : "55415", intitule : "RANZIERES", cp : "55300" },
{ code : "55416", intitule : "RARECOURT", cp : "55120" },
{ code : "55419", intitule : "RECICOURT", cp : "55120" },
{ code : "55420", intitule : "RECOURT LE CREUX", cp : "55220" },
{ code : "55421", intitule : "REFFROY", cp : "55190" },
{ code : "55422", intitule : "REGNEVILLE SUR MEUSE", cp : "55110" },
{ code : "55423", intitule : "REMBERCOURT SOMMAISNE", cp : "55250" },
{ code : "55423", intitule : "REMBERCOURT SOMMAISNE", cp : "55250" },
{ code : "55424", intitule : "REMENNECOURT", cp : "55800" },
{ code : "55425", intitule : "REMOIVILLE", cp : "55600" },
{ code : "55426", intitule : "RESSON", cp : "55000" },
{ code : "55427", intitule : "REVIGNY SUR ORNAIN", cp : "55800" },
{ code : "55428", intitule : "REVILLE AUX BOIS", cp : "55150" },
{ code : "55429", intitule : "RIAVILLE", cp : "55160" },
{ code : "55430", intitule : "RIBEAUCOURT", cp : "55290" },
{ code : "55431", intitule : "RICHECOURT", cp : "55300" },
{ code : "55433", intitule : "RIGNY LA SALLE", cp : "55140" },
{ code : "55434", intitule : "RIGNY ST MARTIN", cp : "55140" },
{ code : "55435", intitule : "ROBERT ESPAGNE", cp : "55000" },
{ code : "55436", intitule : "LES ROISES", cp : "55130" },
{ code : "55437", intitule : "ROMAGNE SOUS LES COTES", cp : "55150" },
{ code : "55438", intitule : "ROMAGNE SOUS MONTFAUCON", cp : "55110" },
{ code : "55439", intitule : "RONVAUX", cp : "55160" },
{ code : "55442", intitule : "RAIVAL", cp : "55260" },
{ code : "55442", intitule : "RAIVAL", cp : "55260" },
{ code : "55443", intitule : "ROUVRES EN WOEVRE", cp : "55400" },
{ code : "55444", intitule : "ROUVROIS SUR MEUSE", cp : "55300" },
{ code : "55445", intitule : "ROUVROIS SUR OTHAIN", cp : "55230" },
{ code : "55446", intitule : "RUMONT", cp : "55000" },
{ code : "55447", intitule : "RUPT AUX NONAINS", cp : "55170" },
{ code : "55448", intitule : "RUPT DEVANT ST MIHIEL", cp : "55260" },
{ code : "55449", intitule : "RUPT EN WOEVRE", cp : "55320" },
{ code : "55450", intitule : "RUPT SUR OTHAIN", cp : "55150" },
{ code : "55452", intitule : "ST AMAND SUR ORNAIN", cp : "55500" },
{ code : "55453", intitule : "ST ANDRE EN BARROIS", cp : "55220" },
{ code : "55454", intitule : "ST AUBIN SUR AIRE", cp : "55500" },
{ code : "55456", intitule : "ST GERMAIN SUR MEUSE", cp : "55140" },
{ code : "55457", intitule : "ST HILAIRE EN WOEVRE", cp : "55160" },
{ code : "55457", intitule : "ST HILAIRE EN WOEVRE", cp : "55160" },
{ code : "55457", intitule : "ST HILAIRE EN WOEVRE", cp : "55160" },
{ code : "55458", intitule : "ST JEAN LES BUZY", cp : "55400" },
{ code : "55459", intitule : "ST JOIRE", cp : "55130" },
{ code : "55460", intitule : "ST JULIEN SOUS LES COTES", cp : "55200" },
{ code : "55461", intitule : "ST LAURENT SUR OTHAIN", cp : "55150" },
{ code : "55462", intitule : "ST MAURICE SOUS LES COTES", cp : "55210" },
{ code : "55463", intitule : "ST MIHIEL", cp : "55300" },
{ code : "55464", intitule : "ST PIERREVILLERS", cp : "55230" },
{ code : "55465", intitule : "ST REMY LA CALONNE", cp : "55160" },
{ code : "55466", intitule : "SALMAGNE", cp : "55000" },
{ code : "55467", intitule : "SAMPIGNY", cp : "55300" },
{ code : "55468", intitule : "SAMOGNEUX", cp : "55100" },
{ code : "55469", intitule : "SASSEY SUR MEUSE", cp : "55110" },
{ code : "55470", intitule : "SAUDRUPT", cp : "55000" },
{ code : "55471", intitule : "SAULMORY ET VILLEFRANCHE", cp : "55110" },
{ code : "55472", intitule : "SAULVAUX", cp : "55500" },
{ code : "55472", intitule : "SAULVAUX", cp : "55500" },
{ code : "55472", intitule : "SAULVAUX", cp : "55500" },
{ code : "55473", intitule : "SAULX LES CHAMPLON", cp : "55160" },
{ code : "55473", intitule : "SAULX LES CHAMPLON", cp : "55160" },
{ code : "55474", intitule : "SAUVIGNY", cp : "55140" },
{ code : "55475", intitule : "SAUVOY", cp : "55190" },
{ code : "55476", intitule : "SAVONNIERES DEVANT BAR", cp : "55000" },
{ code : "55477", intitule : "SAVONNIERES EN PERTHOIS", cp : "55170" },
{ code : "55479", intitule : "SEIGNEULLES", cp : "55000" },
{ code : "55481", intitule : "SENON", cp : "55230" },
{ code : "55482", intitule : "SENONCOURT LES MAUJOUY", cp : "55220" },
{ code : "55484", intitule : "SEPTSARGES", cp : "55270" },
{ code : "55485", intitule : "SEPVIGNY", cp : "55140" },
{ code : "55487", intitule : "SEUZEY", cp : "55300" },
{ code : "55488", intitule : "SILMONT", cp : "55000" },
{ code : "55489", intitule : "SIVRY LA PERCHE", cp : "55100" },
{ code : "55490", intitule : "SIVRY SUR MEUSE", cp : "55110" },
{ code : "55492", intitule : "SOMMEDIEUE", cp : "55320" },
{ code : "55493", intitule : "SOMMEILLES", cp : "55800" },
{ code : "55494", intitule : "SOMMELONNE", cp : "55170" },
{ code : "55495", intitule : "SORBEY", cp : "55230" },
{ code : "55496", intitule : "SORCY ST MARTIN", cp : "55190" },
{ code : "55497", intitule : "LES SOUHESMES RAMPONT", cp : "55220" },
{ code : "55497", intitule : "LES SOUHESMES RAMPONT", cp : "55220" },
{ code : "55498", intitule : "SOUILLY", cp : "55220" },
{ code : "55500", intitule : "SPINCOURT", cp : "55230" },
{ code : "55500", intitule : "SPINCOURT", cp : "55230" },
{ code : "55500", intitule : "SPINCOURT", cp : "55230" },
{ code : "55500", intitule : "SPINCOURT", cp : "55230" },
{ code : "55500", intitule : "SPINCOURT", cp : "55230" },
{ code : "55501", intitule : "STAINVILLE", cp : "55500" },
{ code : "55502", intitule : "STENAY", cp : "55700" },
{ code : "55503", intitule : "TAILLANCOURT", cp : "55140" },
{ code : "55504", intitule : "TANNOIS", cp : "55000" },
{ code : "55505", intitule : "THIERVILLE SUR MEUSE", cp : "55840" },
{ code : "55506", intitule : "THILLOMBOIS", cp : "55260" },
{ code : "55507", intitule : "THILLOT", cp : "55210" },
{ code : "55508", intitule : "THONNE LA LONG", cp : "55600" },
{ code : "55509", intitule : "THONNE LE THIL", cp : "55600" },
{ code : "55510", intitule : "THONNE LES PRES", cp : "55600" },
{ code : "55511", intitule : "THONNELLE", cp : "55600" },
{ code : "55512", intitule : "TILLY SUR MEUSE", cp : "55220" },
{ code : "55514", intitule : "TREMONT SUR SAULX", cp : "55000" },
{ code : "55515", intitule : "TRESAUVAUX", cp : "55160" },
{ code : "55516", intitule : "TREVERAY", cp : "55130" },
{ code : "55517", intitule : "SEUIL D ARGONNE", cp : "55250" },
{ code : "55517", intitule : "SEUIL D ARGONNE", cp : "55250" },
{ code : "55517", intitule : "SEUIL D ARGONNE", cp : "55250" },
{ code : "55518", intitule : "COUSANCES LES TRICONVILLE", cp : "55500" },
{ code : "55518", intitule : "COUSANCES LES TRICONVILLE", cp : "55500" },
{ code : "55519", intitule : "TRONVILLE EN BARROIS", cp : "55310" },
{ code : "55520", intitule : "TROUSSEY", cp : "55190" },
{ code : "55521", intitule : "TROYON", cp : "55300" },
{ code : "55522", intitule : "UGNY SUR MEUSE", cp : "55140" },
{ code : "55523", intitule : "VACHERAUVILLE", cp : "55100" },
{ code : "55525", intitule : "VADELAINCOURT", cp : "55220" },
{ code : "55526", intitule : "VADONVILLE", cp : "55200" },
{ code : "55527", intitule : "VARENNES EN ARGONNE", cp : "55270" },
{ code : "55528", intitule : "VARNEVILLE", cp : "55300" },
{ code : "55530", intitule : "VALBOIS", cp : "55300" },
{ code : "55530", intitule : "VALBOIS", cp : "55300" },
{ code : "55530", intitule : "VALBOIS", cp : "55300" },
{ code : "55531", intitule : "VASSINCOURT", cp : "55800" },
{ code : "55532", intitule : "VAUBECOURT", cp : "55250" },
{ code : "55533", intitule : "VAUCOULEURS", cp : "55140" },
{ code : "55534", intitule : "VAUDEVILLE LE HAUT", cp : "55130" },
{ code : "55535", intitule : "VAUDONCOURT", cp : "55230" },
{ code : "55536", intitule : "VAUQUOIS", cp : "55270" },
{ code : "55537", intitule : "VAUX DEVANT DAMLOUP", cp : "55400" },
{ code : "55540", intitule : "VAUX LES PALAMEIX", cp : "55300" },
{ code : "55541", intitule : "VAVINCOURT", cp : "55000" },
{ code : "55543", intitule : "VELAINES", cp : "55500" },
{ code : "55544", intitule : "VELOSNES", cp : "55600" },
{ code : "55545", intitule : "VERDUN", cp : "55100" },
{ code : "55546", intitule : "VERNEUIL GRAND", cp : "55600" },
{ code : "55547", intitule : "VERNEUIL PETIT", cp : "55600" },
{ code : "55549", intitule : "VERY", cp : "55270" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55551", intitule : "VIGNEULLES LES HATTONCHATEL", cp : "55210" },
{ code : "55552", intitule : "VIGNEUL SOUS MONTMEDY", cp : "55600" },
{ code : "55553", intitule : "VIGNOT", cp : "55200" },
{ code : "55554", intitule : "VILLECLOYE", cp : "55600" },
{ code : "55555", intitule : "VILLE DEVANT BELRAIN", cp : "55260" },
{ code : "55556", intitule : "VILLE DEVANT CHAUMONT", cp : "55150" },
{ code : "55557", intitule : "VILLE EN WOEVRE", cp : "55160" },
{ code : "55559", intitule : "VILLEROY SUR MEHOLLE", cp : "55190" },
{ code : "55560", intitule : "VILLERS AUX VENTS", cp : "55800" },
{ code : "55561", intitule : "VILLERS DEVANT DUN", cp : "55110" },
{ code : "55562", intitule : "VILLERS LE SEC", cp : "55500" },
{ code : "55563", intitule : "VILLERS LES MANGIENNES", cp : "55150" },
{ code : "55565", intitule : "VILLERS SOUS PAREID", cp : "55160" },
{ code : "55566", intitule : "VILLERS SUR MEUSE", cp : "55220" },
{ code : "55567", intitule : "VILLE SUR COUSANCES", cp : "55120" },
{ code : "55568", intitule : "VILLE SUR SAULX", cp : "55000" },
{ code : "55569", intitule : "VILLOTTE DEVANT LOUPPY", cp : "55250" },
{ code : "55570", intitule : "VILLOTTE SUR AIRE", cp : "55260" },
{ code : "55571", intitule : "VILOSNES HARAUMONT", cp : "55110" },
{ code : "55571", intitule : "VILOSNES HARAUMONT", cp : "55110" },
{ code : "55572", intitule : "VITTARVILLE", cp : "55150" },
{ code : "55573", intitule : "VOID VACON", cp : "55190" },
{ code : "55573", intitule : "VOID VACON", cp : "55190" },
{ code : "55574", intitule : "VOUTHON BAS", cp : "55130" },
{ code : "55575", intitule : "VOUTHON HAUT", cp : "55130" },
{ code : "55577", intitule : "WALY", cp : "55250" },
{ code : "55578", intitule : "WARCQ", cp : "55400" },
{ code : "55579", intitule : "WATRONVILLE", cp : "55160" },
{ code : "55580", intitule : "WAVRILLE", cp : "55150" },
{ code : "55581", intitule : "WILLERONCOURT", cp : "55500" },
{ code : "55582", intitule : "WISEPPE", cp : "55700" },
{ code : "55583", intitule : "WOEL", cp : "55210" },
{ code : "55584", intitule : "WOIMBEY", cp : "55300" },
{ code : "55586", intitule : "XIVRAY ET MARVOISIN", cp : "55300" },
{ code : "56001", intitule : "ALLAIRE", cp : "56350" },
{ code : "56002", intitule : "AMBON", cp : "56190" },
{ code : "56003", intitule : "ARRADON", cp : "56610" },
{ code : "56004", intitule : "ARZAL", cp : "56190" },
{ code : "56005", intitule : "ARZON", cp : "56640" },
{ code : "56005", intitule : "ARZON", cp : "56640" },
{ code : "56006", intitule : "AUGAN", cp : "56800" },
{ code : "56007", intitule : "AURAY", cp : "56400" },
{ code : "56008", intitule : "BADEN", cp : "56870" },
{ code : "56009", intitule : "BANGOR", cp : "56360" },
{ code : "56010", intitule : "BAUD", cp : "56150" },
{ code : "56011", intitule : "BEGANNE", cp : "56350" },
{ code : "56012", intitule : "BEIGNON", cp : "56380" },
{ code : "56013", intitule : "BELZ", cp : "56550" },
{ code : "56014", intitule : "BERNE", cp : "56240" },
{ code : "56015", intitule : "BERRIC", cp : "56230" },
{ code : "56016", intitule : "BIEUZY", cp : "56310" },
{ code : "56017", intitule : "BIGNAN", cp : "56500" },
{ code : "56018", intitule : "BILLIERS", cp : "56190" },
{ code : "56019", intitule : "BILLIO", cp : "56420" },
{ code : "56020", intitule : "BOHAL", cp : "56140" },
{ code : "56021", intitule : "BRANDERION", cp : "56700" },
{ code : "56022", intitule : "BRANDIVY", cp : "56390" },
{ code : "56023", intitule : "BRECH", cp : "56400" },
{ code : "56024", intitule : "BREHAN", cp : "56580" },
{ code : "56025", intitule : "BRIGNAC", cp : "56430" },
{ code : "56026", intitule : "BUBRY", cp : "56310" },
{ code : "56027", intitule : "BULEON", cp : "56420" },
{ code : "56028", intitule : "CADEN", cp : "56220" },
{ code : "56029", intitule : "CALAN", cp : "56240" },
{ code : "56030", intitule : "CAMOEL", cp : "56130" },
{ code : "56031", intitule : "CAMORS", cp : "56330" },
{ code : "56032", intitule : "CAMPENEAC", cp : "56800" },
{ code : "56033", intitule : "CARENTOIR", cp : "56910" },
{ code : "56034", intitule : "CARNAC", cp : "56340" },
{ code : "56035", intitule : "CARO", cp : "56140" },
{ code : "56036", intitule : "CAUDAN", cp : "56850" },
{ code : "56038", intitule : "LA CHAPELLE GACELINE", cp : "56200" },
{ code : "56039", intitule : "LA CHAPELLE NEUVE", cp : "56500" },
{ code : "56040", intitule : "CLEGUER", cp : "56620" },
{ code : "56041", intitule : "CLEGUEREC", cp : "56480" },
{ code : "56042", intitule : "COLPO", cp : "56390" },
{ code : "56043", intitule : "CONCORET", cp : "56430" },
{ code : "56044", intitule : "COURNON", cp : "56200" },
{ code : "56045", intitule : "LE COURS", cp : "56230" },
{ code : "56046", intitule : "CRACH", cp : "56950" },
{ code : "56047", intitule : "CREDIN", cp : "56580" },
{ code : "56048", intitule : "LE CROISTY", cp : "56540" },
{ code : "56049", intitule : "CROIXANVEC", cp : "56920" },
{ code : "56050", intitule : "LA CROIX HELLEAN", cp : "56120" },
{ code : "56051", intitule : "CRUGUEL", cp : "56420" },
{ code : "56052", intitule : "DAMGAN", cp : "56750" },
{ code : "56053", intitule : "ELVEN", cp : "56250" },
{ code : "56054", intitule : "ERDEVEN", cp : "56410" },
{ code : "56055", intitule : "ETEL", cp : "56410" },
{ code : "56056", intitule : "EVRIGUET", cp : "56490" },
{ code : "56057", intitule : "LE FAOUET", cp : "56320" },
{ code : "56058", intitule : "FEREL", cp : "56130" },
{ code : "56059", intitule : "LES FORGES", cp : "56120" },
{ code : "56060", intitule : "LES FOUGERETS", cp : "56200" },
{ code : "56061", intitule : "LA GACILLY", cp : "56200" },
{ code : "56062", intitule : "GAVRES", cp : "56680" },
{ code : "56063", intitule : "GESTEL", cp : "56530" },
{ code : "56064", intitule : "GLENAC", cp : "56200" },
{ code : "56065", intitule : "GOURHEL", cp : "56800" },
{ code : "56066", intitule : "GOURIN", cp : "56110" },
{ code : "56067", intitule : "GRAND CHAMP", cp : "56390" },
{ code : "56068", intitule : "LA GREE ST LAURENT", cp : "56120" },
{ code : "56069", intitule : "GROIX", cp : "56590" },
{ code : "56070", intitule : "GUEGON", cp : "56120" },
{ code : "56071", intitule : "GUEHENNO", cp : "56420" },
{ code : "56072", intitule : "GUELTAS", cp : "56920" },
{ code : "56073", intitule : "GUEMENE SUR SCORFF", cp : "56160" },
{ code : "56074", intitule : "GUENIN", cp : "56150" },
{ code : "56075", intitule : "GUER", cp : "56380" },
{ code : "56075", intitule : "GUER", cp : "56380" },
{ code : "56076", intitule : "GUERN", cp : "56310" },
{ code : "56077", intitule : "LE GUERNO", cp : "56190" },
{ code : "56078", intitule : "GUIDEL", cp : "56520" },
{ code : "56079", intitule : "GUILLAC", cp : "56800" },
{ code : "56080", intitule : "GUILLIERS", cp : "56490" },
{ code : "56081", intitule : "GUISCRIFF", cp : "56560" },
{ code : "56082", intitule : "HELLEAN", cp : "56120" },
{ code : "56083", intitule : "HENNEBONT", cp : "56700" },
{ code : "56084", intitule : "LE HEZO", cp : "56450" },
{ code : "56085", intitule : "HOEDIC", cp : "56170" },
{ code : "56086", intitule : "ILE D HOUAT", cp : "56170" },
{ code : "56087", intitule : "ILE AUX MOINES", cp : "56780" },
{ code : "56088", intitule : "ILE D ARZ", cp : "56840" },
{ code : "56089", intitule : "INGUINIEL", cp : "56240" },
{ code : "56090", intitule : "INZINZAC LOCHRIST", cp : "56650" },
{ code : "56091", intitule : "JOSSELIN", cp : "56120" },
{ code : "56092", intitule : "KERFOURN", cp : "56920" },
{ code : "56093", intitule : "KERGRIST", cp : "56300" },
{ code : "56094", intitule : "KERVIGNAC", cp : "56700" },
{ code : "56096", intitule : "LANDAUL", cp : "56690" },
{ code : "56097", intitule : "LANDEVANT", cp : "56690" },
{ code : "56098", intitule : "LANESTER", cp : "56600" },
{ code : "56099", intitule : "LANGOELAN", cp : "56160" },
{ code : "56100", intitule : "LANGONNET", cp : "56630" },
{ code : "56101", intitule : "LANGUIDIC", cp : "56440" },
{ code : "56102", intitule : "LANOUEE", cp : "56120" },
{ code : "56103", intitule : "LANTILLAC", cp : "56120" },
{ code : "56104", intitule : "LANVAUDAN", cp : "56240" },
{ code : "56105", intitule : "LANVENEGEN", cp : "56320" },
{ code : "56106", intitule : "LARMOR BADEN", cp : "56870" },
{ code : "56107", intitule : "LARMOR PLAGE", cp : "56260" },
{ code : "56108", intitule : "LARRE", cp : "56230" },
{ code : "56109", intitule : "LAUZACH", cp : "56190" },
{ code : "56110", intitule : "LIGNOL", cp : "56160" },
{ code : "56111", intitule : "LIMERZEL", cp : "56220" },
{ code : "56112", intitule : "LIZIO", cp : "56460" },
{ code : "56113", intitule : "LOCMALO", cp : "56160" },
{ code : "56114", intitule : "LOCMARIA", cp : "56360" },
{ code : "56115", intitule : "LOCMARIA GRAND CHAMP", cp : "56390" },
{ code : "56116", intitule : "LOCMARIAQUER", cp : "56740" },
{ code : "56117", intitule : "LOCMINE", cp : "56500" },
{ code : "56118", intitule : "LOCMIQUELIC", cp : "56570" },
{ code : "56119", intitule : "LOCOAL MENDON", cp : "56550" },
{ code : "56120", intitule : "LOCQUELTAS", cp : "56390" },
{ code : "56121", intitule : "LORIENT", cp : "56100" },
{ code : "56122", intitule : "LOYAT", cp : "56800" },
{ code : "56123", intitule : "MALANSAC", cp : "56220" },
{ code : "56124", intitule : "MALESTROIT", cp : "56140" },
{ code : "56125", intitule : "MALGUENAC", cp : "56300" },
{ code : "56126", intitule : "MARZAN", cp : "56130" },
{ code : "56127", intitule : "MAURON", cp : "56430" },
{ code : "56128", intitule : "MELRAND", cp : "56310" },
{ code : "56129", intitule : "MENEAC", cp : "56490" },
{ code : "56130", intitule : "MERLEVENEZ", cp : "56700" },
{ code : "56131", intitule : "MESLAN", cp : "56320" },
{ code : "56132", intitule : "MEUCON", cp : "56890" },
{ code : "56133", intitule : "MISSIRIAC", cp : "56140" },
{ code : "56134", intitule : "MOHON", cp : "56490" },
{ code : "56135", intitule : "MOLAC", cp : "56230" },
{ code : "56136", intitule : "MONTENEUF", cp : "56380" },
{ code : "56137", intitule : "MONTERBLANC", cp : "56250" },
{ code : "56138", intitule : "MONTERREIN", cp : "56800" },
{ code : "56139", intitule : "MONTERTELOT", cp : "56800" },
{ code : "56140", intitule : "MOREAC", cp : "56500" },
{ code : "56141", intitule : "MOUSTOIR AC", cp : "56500" },
{ code : "56143", intitule : "MUZILLAC", cp : "56190" },
{ code : "56144", intitule : "EVELLYS", cp : "56500" },
{ code : "56144", intitule : "EVELLYS", cp : "56500" },
{ code : "56144", intitule : "EVELLYS", cp : "56500" },
{ code : "56145", intitule : "NEANT SUR YVEL", cp : "56430" },
{ code : "56146", intitule : "NEULLIAC", cp : "56300" },
{ code : "56147", intitule : "NIVILLAC", cp : "56130" },
{ code : "56148", intitule : "NOSTANG", cp : "56690" },
{ code : "56149", intitule : "NOYAL MUZILLAC", cp : "56190" },
{ code : "56151", intitule : "NOYAL PONTIVY", cp : "56920" },
{ code : "56152", intitule : "LE PALAIS", cp : "56360" },
{ code : "56153", intitule : "PEAULE", cp : "56130" },
{ code : "56154", intitule : "PEILLAC", cp : "56220" },
{ code : "56155", intitule : "PENESTIN", cp : "56760" },
{ code : "56156", intitule : "PERSQUEN", cp : "56160" },
{ code : "56157", intitule : "PLAUDREN", cp : "56420" },
{ code : "56158", intitule : "PLESCOP", cp : "56890" },
{ code : "56159", intitule : "PLEUCADEUC", cp : "56140" },
{ code : "56160", intitule : "PLEUGRIFFET", cp : "56120" },
{ code : "56161", intitule : "PLOEMEL", cp : "56400" },
{ code : "56162", intitule : "PLOEMEUR", cp : "56270" },
{ code : "56163", intitule : "PLOERDUT", cp : "56160" },
{ code : "56164", intitule : "PLOEREN", cp : "56880" },
{ code : "56165", intitule : "PLOERMEL", cp : "56800" },
{ code : "56166", intitule : "PLOUAY", cp : "56240" },
{ code : "56167", intitule : "PLOUGOUMELEN", cp : "56400" },
{ code : "56168", intitule : "PLOUHARNEL", cp : "56340" },
{ code : "56169", intitule : "PLOUHINEC", cp : "56680" },
{ code : "56170", intitule : "PLOURAY", cp : "56770" },
{ code : "56171", intitule : "PLUHERLIN", cp : "56220" },
{ code : "56172", intitule : "PLUMELEC", cp : "56420" },
{ code : "56173", intitule : "PLUMELIAU", cp : "56930" },
{ code : "56174", intitule : "PLUMELIN", cp : "56500" },
{ code : "56175", intitule : "PLUMERGAT", cp : "56400" },
{ code : "56176", intitule : "PLUNERET", cp : "56400" },
{ code : "56177", intitule : "PLUVIGNER", cp : "56330" },
{ code : "56177", intitule : "PLUVIGNER", cp : "56330" },
{ code : "56178", intitule : "PONTIVY", cp : "56300" },
{ code : "56179", intitule : "PONT SCORFF", cp : "56620" },
{ code : "56180", intitule : "PORCARO", cp : "56380" },
{ code : "56181", intitule : "PORT LOUIS", cp : "56290" },
{ code : "56182", intitule : "PRIZIAC", cp : "56320" },
{ code : "56183", intitule : "QUELNEUC", cp : "56910" },
{ code : "56184", intitule : "QUESTEMBERT", cp : "56230" },
{ code : "56185", intitule : "QUEVEN", cp : "56530" },
{ code : "56186", intitule : "QUIBERON", cp : "56170" },
{ code : "56188", intitule : "QUISTINIC", cp : "56310" },
{ code : "56189", intitule : "RADENAC", cp : "56500" },
{ code : "56190", intitule : "REGUINY", cp : "56500" },
{ code : "56191", intitule : "REMINIAC", cp : "56140" },
{ code : "56193", intitule : "RIANTEC", cp : "56670" },
{ code : "56194", intitule : "RIEUX", cp : "56350" },
{ code : "56195", intitule : "LA ROCHE BERNARD", cp : "56130" },
{ code : "56196", intitule : "ROCHEFORT EN TERRE", cp : "56220" },
{ code : "56197", intitule : "VAL D OUST", cp : "56800" },
{ code : "56197", intitule : "VAL D OUST", cp : "56460" },
{ code : "56197", intitule : "VAL D OUST", cp : "56460" },
{ code : "56198", intitule : "ROHAN", cp : "56580" },
{ code : "56198", intitule : "ROHAN", cp : "56580" },
{ code : "56198", intitule : "ROHAN", cp : "56580" },
{ code : "56199", intitule : "ROUDOUALLEC", cp : "56110" },
{ code : "56200", intitule : "RUFFIAC", cp : "56140" },
{ code : "56201", intitule : "LE SAINT", cp : "56110" },
{ code : "56202", intitule : "ST ABRAHAM", cp : "56140" },
{ code : "56203", intitule : "ST AIGNAN", cp : "56480" },
{ code : "56204", intitule : "ST ALLOUESTRE", cp : "56500" },
{ code : "56205", intitule : "ST ARMEL", cp : "56450" },
{ code : "56206", intitule : "ST AVE", cp : "56890" },
{ code : "56207", intitule : "ST BARTHELEMY", cp : "56150" },
{ code : "56208", intitule : "ST BRIEUC DE MAURON", cp : "56430" },
{ code : "56209", intitule : "STE BRIGITTE", cp : "56480" },
{ code : "56210", intitule : "ST CARADEC TREGOMEL", cp : "56540" },
{ code : "56211", intitule : "ST CONGARD", cp : "56140" },
{ code : "56212", intitule : "ST DOLAY", cp : "56130" },
{ code : "56213", intitule : "ST GERAND", cp : "56920" },
{ code : "56214", intitule : "ST GILDAS DE RHUYS", cp : "56730" },
{ code : "56215", intitule : "ST GONNERY", cp : "56920" },
{ code : "56216", intitule : "ST GORGON", cp : "56350" },
{ code : "56218", intitule : "ST GRAVE", cp : "56220" },
{ code : "56219", intitule : "ST GUYOMARD", cp : "56460" },
{ code : "56220", intitule : "STE HELENE", cp : "56700" },
{ code : "56221", intitule : "ST JACUT LES PINS", cp : "56220" },
{ code : "56222", intitule : "ST JEAN BREVELAY", cp : "56660" },
{ code : "56223", intitule : "ST JEAN LA POTERIE", cp : "56350" },
{ code : "56224", intitule : "ST LAURENT SUR OUST", cp : "56140" },
{ code : "56225", intitule : "ST LERY", cp : "56430" },
{ code : "56226", intitule : "ST MALO DE BEIGNON", cp : "56380" },
{ code : "56227", intitule : "ST MALO DES TROIS FONTAINES", cp : "56490" },
{ code : "56228", intitule : "ST MARCEL", cp : "56140" },
{ code : "56229", intitule : "ST MARTIN SUR OUST", cp : "56200" },
{ code : "56230", intitule : "ST NICOLAS DU TERTRE", cp : "56910" },
{ code : "56231", intitule : "ST NOLFF", cp : "56250" },
{ code : "56232", intitule : "ST PERREUX", cp : "56350" },
{ code : "56233", intitule : "ST PHILIBERT", cp : "56470" },
{ code : "56234", intitule : "ST PIERRE QUIBERON", cp : "56510" },
{ code : "56236", intitule : "ST SERVANT", cp : "56120" },
{ code : "56237", intitule : "ST THURIAU", cp : "56300" },
{ code : "56238", intitule : "ST TUGDUAL", cp : "56540" },
{ code : "56239", intitule : "ST VINCENT SUR OUST", cp : "56350" },
{ code : "56240", intitule : "SARZEAU", cp : "56370" },
{ code : "56241", intitule : "SAUZON", cp : "56360" },
{ code : "56242", intitule : "SEGLIEN", cp : "56160" },
{ code : "56243", intitule : "SENE", cp : "56860" },
{ code : "56244", intitule : "SERENT", cp : "56460" },
{ code : "56245", intitule : "SILFIAC", cp : "56480" },
{ code : "56246", intitule : "LE SOURN", cp : "56300" },
{ code : "56247", intitule : "SULNIAC", cp : "56250" },
{ code : "56248", intitule : "SURZUR", cp : "56450" },
{ code : "56249", intitule : "TAUPONT", cp : "56800" },
{ code : "56250", intitule : "THEHILLAC", cp : "56130" },
{ code : "56251", intitule : "THEIX NOYALO", cp : "56450" },
{ code : "56251", intitule : "THEIX NOYALO", cp : "56450" },
{ code : "56252", intitule : "LE TOUR DU PARC", cp : "56370" },
{ code : "56253", intitule : "TREAL", cp : "56140" },
{ code : "56254", intitule : "TREDION", cp : "56250" },
{ code : "56255", intitule : "TREFFLEAN", cp : "56250" },
{ code : "56256", intitule : "TREHORENTEUC", cp : "56430" },
{ code : "56257", intitule : "LA TRINITE PORHOET", cp : "56490" },
{ code : "56258", intitule : "LA TRINITE SUR MER", cp : "56470" },
{ code : "56259", intitule : "LA TRINITE SURZUR", cp : "56190" },
{ code : "56260", intitule : "VANNES", cp : "56000" },
{ code : "56261", intitule : "LA VRAIE CROIX", cp : "56250" },
{ code : "56262", intitule : "BONO", cp : "56400" },
{ code : "56263", intitule : "STE ANNE D AURAY", cp : "56400" },
{ code : "56264", intitule : "KERNASCLEDEN", cp : "56540" },
{ code : "57001", intitule : "ABONCOURT", cp : "57920" },
{ code : "57002", intitule : "ABONCOURT SUR SEILLE", cp : "57590" },
{ code : "57003", intitule : "ABRESCHVILLER", cp : "57560" },
{ code : "57004", intitule : "ACHAIN", cp : "57340" },
{ code : "57006", intitule : "ACHEN", cp : "57412" },
{ code : "57007", intitule : "ADAINCOURT", cp : "57580" },
{ code : "57008", intitule : "ADELANGE", cp : "57380" },
{ code : "57009", intitule : "AJONCOURT", cp : "57590" },
{ code : "57010", intitule : "ALAINCOURT LA COTE", cp : "57590" },
{ code : "57011", intitule : "ALBESTROFF", cp : "57670" },
{ code : "57012", intitule : "ALGRANGE", cp : "57440" },
{ code : "57013", intitule : "ALSTING", cp : "57515" },
{ code : "57014", intitule : "ALTRIPPE", cp : "57660" },
{ code : "57015", intitule : "ALTVILLER", cp : "57730" },
{ code : "57016", intitule : "ALZING", cp : "57320" },
{ code : "57017", intitule : "AMANVILLERS", cp : "57865" },
{ code : "57018", intitule : "AMELECOURT", cp : "57170" },
{ code : "57019", intitule : "AMNEVILLE", cp : "57360" },
{ code : "57019", intitule : "AMNEVILLE", cp : "57360" },
{ code : "57020", intitule : "ANCERVILLE", cp : "57580" },
{ code : "57021", intitule : "ANCY DORNOT", cp : "57130" },
{ code : "57021", intitule : "ANCY DORNOT", cp : "57130" },
{ code : "57022", intitule : "ANGEVILLERS", cp : "57440" },
{ code : "57024", intitule : "ANTILLY", cp : "57640" },
{ code : "57025", intitule : "ANZELING", cp : "57320" },
{ code : "57026", intitule : "APACH", cp : "57480" },
{ code : "57027", intitule : "ARRAINCOURT", cp : "57380" },
{ code : "57028", intitule : "ARGANCY", cp : "57640" },
{ code : "57029", intitule : "ARRIANCE", cp : "57580" },
{ code : "57030", intitule : "ARRY", cp : "57680" },
{ code : "57031", intitule : "ARS LAQUENEXY", cp : "57530" },
{ code : "57032", intitule : "ARS SUR MOSELLE", cp : "57130" },
{ code : "57033", intitule : "ARZVILLER", cp : "57405" },
{ code : "57034", intitule : "ASPACH", cp : "57790" },
{ code : "57035", intitule : "ASSENONCOURT", cp : "57260" },
{ code : "57036", intitule : "ATTILLONCOURT", cp : "57170" },
{ code : "57037", intitule : "AUBE", cp : "57580" },
{ code : "57038", intitule : "AUDUN LE TICHE", cp : "57390" },
{ code : "57039", intitule : "AUGNY", cp : "57685" },
{ code : "57040", intitule : "AULNOIS SUR SEILLE", cp : "57590" },
{ code : "57041", intitule : "AUMETZ", cp : "57710" },
{ code : "57042", intitule : "AVRICOURT", cp : "57810" },
{ code : "57043", intitule : "AY SUR MOSELLE", cp : "57300" },
{ code : "57044", intitule : "AZOUDANGE", cp : "57810" },
{ code : "57045", intitule : "BACOURT", cp : "57590" },
{ code : "57046", intitule : "BAERENTHAL", cp : "57230" },
{ code : "57047", intitule : "BAMBIDERSTROFF", cp : "57690" },
{ code : "57048", intitule : "BANNAY", cp : "57220" },
{ code : "57049", intitule : "LE BAN ST MARTIN", cp : "57050" },
{ code : "57050", intitule : "BARCHAIN", cp : "57830" },
{ code : "57051", intitule : "BARONVILLE", cp : "57340" },
{ code : "57052", intitule : "BARST", cp : "57450" },
{ code : "57053", intitule : "BASSING", cp : "57260" },
{ code : "57054", intitule : "BAUDRECOURT", cp : "57580" },
{ code : "57055", intitule : "BAZONCOURT", cp : "57530" },
{ code : "57056", intitule : "BEBING", cp : "57830" },
{ code : "57057", intitule : "BECHY", cp : "57580" },
{ code : "57058", intitule : "BEHREN LES FORBACH", cp : "57460" },
{ code : "57059", intitule : "BELLANGE", cp : "57340" },
{ code : "57060", intitule : "BENESTROFF", cp : "57670" },
{ code : "57061", intitule : "BENING LES ST AVOLD", cp : "57800" },
{ code : "57062", intitule : "BERG SUR MOSELLE", cp : "57570" },
{ code : "57063", intitule : "BERIG VINTRANGE", cp : "57660" },
{ code : "57064", intitule : "BERLING", cp : "57370" },
{ code : "57065", intitule : "BERMERING", cp : "57340" },
{ code : "57066", intitule : "BERTHELMING", cp : "57930" },
{ code : "57067", intitule : "BERTRANGE", cp : "57310" },
{ code : "57069", intitule : "BERVILLER EN MOSELLE", cp : "57550" },
{ code : "57070", intitule : "BETTANGE", cp : "57220" },
{ code : "57071", intitule : "BETTBORN", cp : "57930" },
{ code : "57072", intitule : "BETTELAINVILLE", cp : "57640" },
{ code : "57073", intitule : "BETTING", cp : "57800" },
{ code : "57074", intitule : "BETTVILLER", cp : "57410" },
{ code : "57075", intitule : "BEUX", cp : "57580" },
{ code : "57076", intitule : "BEYREN LES SIERCK", cp : "57570" },
{ code : "57077", intitule : "BEZANGE LA PETITE", cp : "57630" },
{ code : "57079", intitule : "BIBICHE", cp : "57320" },
{ code : "57080", intitule : "BICKENHOLTZ", cp : "57635" },
{ code : "57081", intitule : "BIDESTROFF", cp : "57260" },
{ code : "57082", intitule : "BIDING", cp : "57660" },
{ code : "57083", intitule : "BINING", cp : "57410" },
{ code : "57084", intitule : "BIONCOURT", cp : "57170" },
{ code : "57085", intitule : "BIONVILLE SUR NIED", cp : "57220" },
{ code : "57086", intitule : "BELLES FORETS", cp : "57930" },
{ code : "57086", intitule : "BELLES FORETS", cp : "57930" },
{ code : "57086", intitule : "BELLES FORETS", cp : "57930" },
{ code : "57087", intitule : "BISTEN EN LORRAINE", cp : "57220" },
{ code : "57088", intitule : "BISTROFF", cp : "57660" },
{ code : "57089", intitule : "BITCHE", cp : "57230" },
{ code : "57090", intitule : "BLANCHE EGLISE", cp : "57260" },
{ code : "57091", intitule : "BLIESBRUCK", cp : "57200" },
{ code : "57092", intitule : "BLIES EBERSING", cp : "57200" },
{ code : "57093", intitule : "BLIES GUERSVILLER", cp : "57200" },
{ code : "57095", intitule : "BOUCHEPORN", cp : "57220" },
{ code : "57096", intitule : "BOULANGE", cp : "57655" },
{ code : "57097", intitule : "BOULAY MOSELLE", cp : "57220" },
{ code : "57097", intitule : "BOULAY MOSELLE", cp : "57220" },
{ code : "57098", intitule : "BOURGALTROFF", cp : "57260" },
{ code : "57099", intitule : "BOURDONNAY", cp : "57810" },
{ code : "57100", intitule : "BOURSCHEID", cp : "57370" },
{ code : "57101", intitule : "BOUSBACH", cp : "57460" },
{ code : "57102", intitule : "BOUSSE", cp : "57310" },
{ code : "57103", intitule : "BOUSSEVILLER", cp : "57230" },
{ code : "57104", intitule : "BOUST", cp : "57570" },
{ code : "57105", intitule : "BOUSTROFF", cp : "57380" },
{ code : "57106", intitule : "BOUZONVILLE", cp : "57320" },
{ code : "57107", intitule : "BREHAIN", cp : "57340" },
{ code : "57108", intitule : "BREIDENBACH", cp : "57720" },
{ code : "57109", intitule : "BREISTROFF LA GRANDE", cp : "57570" },
{ code : "57110", intitule : "BRETTNACH", cp : "57320" },
{ code : "57111", intitule : "BRONVAUX", cp : "57535" },
{ code : "57112", intitule : "BROUCK", cp : "57220" },
{ code : "57113", intitule : "BROUDERDORFF", cp : "57565" },
{ code : "57114", intitule : "BROUVILLER", cp : "57635" },
{ code : "57115", intitule : "BRULANGE", cp : "57340" },
{ code : "57116", intitule : "BUCHY", cp : "57420" },
{ code : "57117", intitule : "BUDING", cp : "57920" },
{ code : "57118", intitule : "BUDLING", cp : "57970" },
{ code : "57119", intitule : "BUHL LORRAINE", cp : "57400" },
{ code : "57120", intitule : "BURLIONCOURT", cp : "57170" },
{ code : "57121", intitule : "BURTONCOURT", cp : "57220" },
{ code : "57122", intitule : "CAPPEL", cp : "57450" },
{ code : "57123", intitule : "CARLING", cp : "57490" },
{ code : "57124", intitule : "CATTENOM", cp : "57570" },
{ code : "57125", intitule : "CHAILLY LES ENNERY", cp : "57365" },
{ code : "57126", intitule : "CHAMBREY", cp : "57170" },
{ code : "57127", intitule : "CHANVILLE", cp : "57580" },
{ code : "57128", intitule : "CHARLEVILLE SOUS BOIS", cp : "57220" },
{ code : "57129", intitule : "CHARLY ORADOUR", cp : "57640" },
{ code : "57130", intitule : "CHATEAU BREHAIN", cp : "57340" },
{ code : "57131", intitule : "CHATEAU ROUGE", cp : "57320" },
{ code : "57132", intitule : "CHATEAU SALINS", cp : "57170" },
{ code : "57132", intitule : "CHATEAU SALINS", cp : "57170" },
{ code : "57133", intitule : "CHATEAU VOUE", cp : "57170" },
{ code : "57133", intitule : "CHATEAU VOUE", cp : "57170" },
{ code : "57134", intitule : "CHATEL ST GERMAIN", cp : "57160" },
{ code : "57136", intitule : "CHEMERY LES DEUX", cp : "57320" },
{ code : "57137", intitule : "CHEMINOT", cp : "57420" },
{ code : "57138", intitule : "CHENOIS", cp : "57580" },
{ code : "57139", intitule : "CHERISEY", cp : "57420" },
{ code : "57140", intitule : "CHESNY", cp : "57245" },
{ code : "57141", intitule : "CHICOURT", cp : "57590" },
{ code : "57142", intitule : "CHIEULLES", cp : "57070" },
{ code : "57143", intitule : "CLOUANGE", cp : "57185" },
{ code : "57144", intitule : "COCHEREN", cp : "57800" },
{ code : "57144", intitule : "COCHEREN", cp : "57800" },
{ code : "57145", intitule : "COINCY", cp : "57530" },
{ code : "57146", intitule : "COIN LES CUVRY", cp : "57420" },
{ code : "57147", intitule : "COIN SUR SEILLE", cp : "57420" },
{ code : "57148", intitule : "COLLIGNY", cp : "57530" },
{ code : "57149", intitule : "COLMEN", cp : "57320" },
{ code : "57150", intitule : "CONDE NORTHEN", cp : "57220" },
{ code : "57150", intitule : "CONDE NORTHEN", cp : "57220" },
{ code : "57151", intitule : "CONTHIL", cp : "57340" },
{ code : "57152", intitule : "CONTZ LES BAINS", cp : "57480" },
{ code : "57153", intitule : "CORNY SUR MOSELLE", cp : "57680" },
{ code : "57154", intitule : "COUME", cp : "57220" },
{ code : "57155", intitule : "COURCELLES CHAUSSY", cp : "57530" },
{ code : "57155", intitule : "COURCELLES CHAUSSY", cp : "57530" },
{ code : "57156", intitule : "COURCELLES SUR NIED", cp : "57530" },
{ code : "57158", intitule : "CRAINCOURT", cp : "57590" },
{ code : "57159", intitule : "CREHANGE", cp : "57690" },
{ code : "57160", intitule : "CREUTZWALD", cp : "57150" },
{ code : "57161", intitule : "CUTTING", cp : "57260" },
{ code : "57162", intitule : "CUVRY", cp : "57420" },
{ code : "57163", intitule : "DABO", cp : "57850" },
{ code : "57163", intitule : "DABO", cp : "57850" },
{ code : "57165", intitule : "DALEM", cp : "57550" },
{ code : "57166", intitule : "DALHAIN", cp : "57340" },
{ code : "57167", intitule : "DALSTEIN", cp : "57320" },
{ code : "57168", intitule : "DANNE ET QUATRE VENTS", cp : "57370" },
{ code : "57169", intitule : "DANNELBOURG", cp : "57820" },
{ code : "57171", intitule : "DELME", cp : "57590" },
{ code : "57172", intitule : "DENTING", cp : "57220" },
{ code : "57173", intitule : "DESSELING", cp : "57260" },
{ code : "57174", intitule : "DESTRY", cp : "57340" },
{ code : "57175", intitule : "DIANE CAPELLE", cp : "57830" },
{ code : "57176", intitule : "DIEBLING", cp : "57980" },
{ code : "57177", intitule : "DIEUZE", cp : "57260" },
{ code : "57178", intitule : "DIFFEMBACH LES HELLIMER", cp : "57660" },
{ code : "57179", intitule : "DISTROFF", cp : "57925" },
{ code : "57180", intitule : "DOLVING", cp : "57400" },
{ code : "57181", intitule : "DOMNOM LES DIEUZE", cp : "57260" },
{ code : "57182", intitule : "DONJEUX", cp : "57590" },
{ code : "57183", intitule : "DONNELAY", cp : "57810" },
{ code : "57186", intitule : "EBERSVILLER", cp : "57320" },
{ code : "57187", intitule : "EBLANGE", cp : "57220" },
{ code : "57188", intitule : "EGUELSHARDT", cp : "57230" },
{ code : "57189", intitule : "EINCHEVILLE", cp : "57340" },
{ code : "57190", intitule : "ELVANGE", cp : "57690" },
{ code : "57191", intitule : "ELZANGE", cp : "57970" },
{ code : "57192", intitule : "ENCHENBERG", cp : "57415" },
{ code : "57193", intitule : "ENNERY", cp : "57365" },
{ code : "57194", intitule : "ENTRANGE", cp : "57330" },
{ code : "57195", intitule : "EPPING", cp : "57720" },
{ code : "57196", intitule : "ERCHING", cp : "57720" },
{ code : "57197", intitule : "ERNESTVILLER", cp : "57510" },
{ code : "57198", intitule : "ERSTROFF", cp : "57660" },
{ code : "57199", intitule : "ESCHERANGE", cp : "57330" },
{ code : "57200", intitule : "LES ETANGS", cp : "57530" },
{ code : "57201", intitule : "ETTING", cp : "57412" },
{ code : "57202", intitule : "ETZLING", cp : "57460" },
{ code : "57203", intitule : "EVRANGE", cp : "57570" },
{ code : "57204", intitule : "FAILLY", cp : "57640" },
{ code : "57204", intitule : "FAILLY", cp : "57640" },
{ code : "57205", intitule : "FALCK", cp : "57550" },
{ code : "57206", intitule : "FAMECK", cp : "57290" },
{ code : "57206", intitule : "FAMECK", cp : "57290" },
{ code : "57206", intitule : "FAMECK", cp : "57290" },
{ code : "57207", intitule : "FAREBERSVILLER", cp : "57450" },
{ code : "57208", intitule : "FARSCHVILLER", cp : "57450" },
{ code : "57209", intitule : "FAULQUEMONT", cp : "57380" },
{ code : "57209", intitule : "FAULQUEMONT", cp : "57380" },
{ code : "57210", intitule : "FENETRANGE", cp : "57930" },
{ code : "57211", intitule : "FEVES", cp : "57280" },
{ code : "57212", intitule : "FEY", cp : "57420" },
{ code : "57213", intitule : "FILSTROFF", cp : "57320" },
{ code : "57214", intitule : "FIXEM", cp : "57570" },
{ code : "57215", intitule : "FLASTROFF", cp : "57320" },
{ code : "57216", intitule : "FLEISHEIM", cp : "57635" },
{ code : "57217", intitule : "FLETRANGE", cp : "57690" },
{ code : "57218", intitule : "FLEURY", cp : "57420" },
{ code : "57219", intitule : "FLEVY", cp : "57365" },
{ code : "57220", intitule : "FLOCOURT", cp : "57580" },
{ code : "57221", intitule : "FLORANGE", cp : "57190" },
{ code : "57221", intitule : "FLORANGE", cp : "57190" },
{ code : "57222", intitule : "FOLKLING", cp : "57600" },
{ code : "57224", intitule : "FOLSCHVILLER", cp : "57730" },
{ code : "57225", intitule : "FONTENY", cp : "57590" },
{ code : "57226", intitule : "FONTOY", cp : "57650" },
{ code : "57227", intitule : "FORBACH", cp : "57600" },
{ code : "57227", intitule : "FORBACH", cp : "57600" },
{ code : "57227", intitule : "FORBACH", cp : "57600" },
{ code : "57228", intitule : "FOSSIEUX", cp : "57590" },
{ code : "57229", intitule : "FOULCREY", cp : "57830" },
{ code : "57230", intitule : "FOULIGNY", cp : "57220" },
{ code : "57231", intitule : "FOVILLE", cp : "57420" },
{ code : "57232", intitule : "FRANCALTROFF", cp : "57670" },
{ code : "57233", intitule : "FRAQUELFING", cp : "57790" },
{ code : "57234", intitule : "FRAUENBERG", cp : "57200" },
{ code : "57235", intitule : "FREISTROFF", cp : "57320" },
{ code : "57236", intitule : "FREMERY", cp : "57590" },
{ code : "57237", intitule : "FREMESTROFF", cp : "57660" },
{ code : "57238", intitule : "FRESNES EN SAULNOIS", cp : "57170" },
{ code : "57239", intitule : "FREYBOUSE", cp : "57660" },
{ code : "57240", intitule : "FREYMING MERLEBACH", cp : "57800" },
{ code : "57240", intitule : "FREYMING MERLEBACH", cp : "57800" },
{ code : "57241", intitule : "FRIBOURG", cp : "57810" },
{ code : "57242", intitule : "GANDRANGE", cp : "57175" },
{ code : "57244", intitule : "GARREBOURG", cp : "57820" },
{ code : "57245", intitule : "GAVISSE", cp : "57570" },
{ code : "57246", intitule : "GELUCOURT", cp : "57260" },
{ code : "57247", intitule : "GERBECOURT", cp : "57170" },
{ code : "57248", intitule : "GIVRYCOURT", cp : "57670" },
{ code : "57249", intitule : "GLATIGNY", cp : "57530" },
{ code : "57250", intitule : "GOETZENBRUCK", cp : "57620" },
{ code : "57250", intitule : "GOETZENBRUCK", cp : "57620" },
{ code : "57251", intitule : "GOIN", cp : "57420" },
{ code : "57252", intitule : "GOMELANGE", cp : "57220" },
{ code : "57252", intitule : "GOMELANGE", cp : "57220" },
{ code : "57253", intitule : "GONDREXANGE", cp : "57815" },
{ code : "57254", intitule : "GORZE", cp : "57680" },
{ code : "57255", intitule : "GOSSELMING", cp : "57930" },
{ code : "57256", intitule : "GRAVELOTTE", cp : "57130" },
{ code : "57257", intitule : "GREMECEY", cp : "57170" },
{ code : "57258", intitule : "GRENING", cp : "57660" },
{ code : "57259", intitule : "GRINDORFF BIZING", cp : "57480" },
{ code : "57260", intitule : "GROSBLIEDERSTROFF", cp : "57520" },
{ code : "57261", intitule : "GROS REDERCHING", cp : "57410" },
{ code : "57262", intitule : "GROSTENQUIN", cp : "57660" },
{ code : "57263", intitule : "GRUNDVILLER", cp : "57510" },
{ code : "57264", intitule : "GUEBENHOUSE", cp : "57510" },
{ code : "57265", intitule : "GUEBESTROFF", cp : "57260" },
{ code : "57266", intitule : "GUEBLANGE LES DIEUZE", cp : "57260" },
{ code : "57267", intitule : "LE VAL DE GUEBLANGE", cp : "57430" },
{ code : "57268", intitule : "GUEBLING", cp : "57260" },
{ code : "57269", intitule : "GUENANGE", cp : "57310" },
{ code : "57270", intitule : "VAL DE BRIDE", cp : "57260" },
{ code : "57270", intitule : "VAL DE BRIDE", cp : "57260" },
{ code : "57271", intitule : "GUENVILLER", cp : "57470" },
{ code : "57272", intitule : "GUERMANGE", cp : "57260" },
{ code : "57273", intitule : "GUERSTLING", cp : "57320" },
{ code : "57274", intitule : "GUERTING", cp : "57880" },
{ code : "57275", intitule : "GUESSLING HEMERING", cp : "57380" },
{ code : "57276", intitule : "GUINGLANGE", cp : "57690" },
{ code : "57277", intitule : "GUINKIRCHEN", cp : "57220" },
{ code : "57278", intitule : "GUINZELING", cp : "57670" },
{ code : "57280", intitule : "GUNTZVILLER", cp : "57405" },
{ code : "57281", intitule : "HABOUDANGE", cp : "57340" },
{ code : "57282", intitule : "HAGEN", cp : "57570" },
{ code : "57283", intitule : "HAGONDANGE", cp : "57300" },
{ code : "57284", intitule : "HALLERING", cp : "57690" },
{ code : "57286", intitule : "HALSTROFF", cp : "57480" },
{ code : "57287", intitule : "BASSE HAM", cp : "57970" },
{ code : "57288", intitule : "HAM SOUS VARSBERG", cp : "57880" },
{ code : "57289", intitule : "HAMBACH", cp : "57910" },
{ code : "57290", intitule : "HAMPONT", cp : "57170" },
{ code : "57291", intitule : "HANGVILLER", cp : "57370" },
{ code : "57292", intitule : "HANNOCOURT", cp : "57590" },
{ code : "57293", intitule : "HAN SUR NIED", cp : "57580" },
{ code : "57294", intitule : "HANVILLER", cp : "57230" },
{ code : "57295", intitule : "HARAUCOURT SUR SEILLE", cp : "57630" },
{ code : "57296", intitule : "HARGARTEN AUX MINES", cp : "57550" },
{ code : "57297", intitule : "HARPRICH", cp : "57340" },
{ code : "57298", intitule : "HARREBERG", cp : "57870" },
{ code : "57299", intitule : "HARTZVILLER", cp : "57870" },
{ code : "57300", intitule : "HASELBOURG", cp : "57850" },
{ code : "57301", intitule : "HASPELSCHIEDT", cp : "57230" },
{ code : "57302", intitule : "HATTIGNY", cp : "57790" },
{ code : "57303", intitule : "HAUCONCOURT", cp : "57280" },
{ code : "57304", intitule : "HAUT CLOCHER", cp : "57400" },
{ code : "57305", intitule : "HAVANGE", cp : "57650" },
{ code : "57306", intitule : "HAYANGE", cp : "57700" },
{ code : "57306", intitule : "HAYANGE", cp : "57700" },
{ code : "57306", intitule : "HAYANGE", cp : "57700" },
{ code : "57307", intitule : "HAYES", cp : "57530" },
{ code : "57308", intitule : "HAZEMBOURG", cp : "57430" },
{ code : "57309", intitule : "HEINING LES BOUZONVILLE", cp : "57320" },
{ code : "57310", intitule : "HELLERING LES FENETRANGE", cp : "57930" },
{ code : "57311", intitule : "HELLIMER", cp : "57660" },
{ code : "57312", intitule : "HELSTROFF", cp : "57220" },
{ code : "57313", intitule : "HEMILLY", cp : "57690" },
{ code : "57314", intitule : "HEMING", cp : "57830" },
{ code : "57315", intitule : "HENRIDORFF", cp : "57820" },
{ code : "57316", intitule : "HENRIVILLE", cp : "57450" },
{ code : "57317", intitule : "HERANGE", cp : "57635" },
{ code : "57318", intitule : "HERMELANGE", cp : "57790" },
{ code : "57319", intitule : "HERNY", cp : "57580" },
{ code : "57320", intitule : "HERTZING", cp : "57830" },
{ code : "57321", intitule : "HESSE", cp : "57400" },
{ code : "57322", intitule : "HESTROFF", cp : "57320" },
{ code : "57323", intitule : "HETTANGE GRANDE", cp : "57330" },
{ code : "57324", intitule : "HILBESHEIM", cp : "57400" },
{ code : "57325", intitule : "HILSPRICH", cp : "57510" },
{ code : "57326", intitule : "HINCKANGE", cp : "57220" },
{ code : "57328", intitule : "HOLACOURT", cp : "57380" },
{ code : "57329", intitule : "HOLLING", cp : "57220" },
{ code : "57330", intitule : "HOLVING", cp : "57510" },
{ code : "57331", intitule : "HOMBOURG BUDANGE", cp : "57920" },
{ code : "57332", intitule : "HOMBOURG HAUT", cp : "57470" },
{ code : "57333", intitule : "HOMMARTING", cp : "57405" },
{ code : "57334", intitule : "HOMMERT", cp : "57870" },
{ code : "57335", intitule : "HONSKIRCH", cp : "57670" },
{ code : "57336", intitule : "L HOPITAL", cp : "57490" },
{ code : "57337", intitule : "HOSTE", cp : "57510" },
{ code : "57338", intitule : "HOTTVILLER", cp : "57720" },
{ code : "57339", intitule : "HULTEHOUSE", cp : "57820" },
{ code : "57340", intitule : "HUNDLING", cp : "57990" },
{ code : "57341", intitule : "HUNTING", cp : "57480" },
{ code : "57342", intitule : "IBIGNY", cp : "57830" },
{ code : "57343", intitule : "ILLANGE", cp : "57970" },
{ code : "57344", intitule : "IMLING", cp : "57400" },
{ code : "57345", intitule : "INGLANGE", cp : "57970" },
{ code : "57346", intitule : "INSMING", cp : "57670" },
{ code : "57347", intitule : "INSVILLER", cp : "57670" },
{ code : "57348", intitule : "IPPLING", cp : "57990" },
{ code : "57349", intitule : "JALLAUCOURT", cp : "57590" },
{ code : "57350", intitule : "JOUY AUX ARCHES", cp : "57130" },
{ code : "57351", intitule : "JURY", cp : "57245" },
{ code : "57352", intitule : "JUSSY", cp : "57130" },
{ code : "57353", intitule : "JUVELIZE", cp : "57630" },
{ code : "57354", intitule : "JUVILLE", cp : "57590" },
{ code : "57355", intitule : "KALHAUSEN", cp : "57412" },
{ code : "57356", intitule : "KANFEN", cp : "57330" },
{ code : "57357", intitule : "KAPPELKINGER", cp : "57430" },
{ code : "57358", intitule : "KEDANGE SUR CANNER", cp : "57920" },
{ code : "57359", intitule : "KEMPLICH", cp : "57920" },
{ code : "57360", intitule : "KERBACH", cp : "57460" },
{ code : "57361", intitule : "KERLING LES SIERCK", cp : "57480" },
{ code : "57362", intitule : "KERPRICH AUX BOIS", cp : "57830" },
{ code : "57364", intitule : "KIRSCH LES SIERCK", cp : "57480" },
{ code : "57365", intitule : "KIRSCHNAUMEN", cp : "57480" },
{ code : "57366", intitule : "KIRVILLER", cp : "57430" },
{ code : "57367", intitule : "KLANG", cp : "57920" },
{ code : "57368", intitule : "KNUTANGE", cp : "57240" },
{ code : "57370", intitule : "KOENIGSMACKER", cp : "57970" },
{ code : "57371", intitule : "HAUTE KONTZ", cp : "57480" },
{ code : "57372", intitule : "KUNTZIG", cp : "57970" },
{ code : "57373", intitule : "LACHAMBRE", cp : "57730" },
{ code : "57374", intitule : "LAFRIMBOLLE", cp : "57560" },
{ code : "57375", intitule : "LAGARDE", cp : "57810" },
{ code : "57376", intitule : "LAMBACH", cp : "57410" },
{ code : "57377", intitule : "LANDANGE", cp : "57830" },
{ code : "57379", intitule : "LANDROFF", cp : "57340" },
{ code : "57380", intitule : "LANEUVEVILLE LES LORQUIN", cp : "57790" },
{ code : "57381", intitule : "LANEUVEVILLE EN SAULNOIS", cp : "57590" },
{ code : "57382", intitule : "LANGATTE", cp : "57400" },
{ code : "57383", intitule : "LANGUIMBERG", cp : "57810" },
{ code : "57384", intitule : "LANING", cp : "57660" },
{ code : "57385", intitule : "LAQUENEXY", cp : "57530" },
{ code : "57386", intitule : "LAUDREFANG", cp : "57385" },
{ code : "57387", intitule : "LAUMESFELD", cp : "57480" },
{ code : "57388", intitule : "LAUNSTROFF", cp : "57480" },
{ code : "57389", intitule : "LELLING", cp : "57660" },
{ code : "57390", intitule : "LEMBERG", cp : "57620" },
{ code : "57391", intitule : "LEMONCOURT", cp : "57590" },
{ code : "57392", intitule : "LEMUD", cp : "57580" },
{ code : "57393", intitule : "LENGELSHEIM", cp : "57720" },
{ code : "57394", intitule : "LENING", cp : "57670" },
{ code : "57395", intitule : "LESSE", cp : "57580" },
{ code : "57396", intitule : "LESSY", cp : "57160" },
{ code : "57397", intitule : "LEY", cp : "57810" },
{ code : "57398", intitule : "LEYVILLER", cp : "57660" },
{ code : "57399", intitule : "LEZEY", cp : "57630" },
{ code : "57401", intitule : "LIDREZING", cp : "57340" },
{ code : "57402", intitule : "LIEDERSCHIEDT", cp : "57230" },
{ code : "57403", intitule : "LIEHON", cp : "57420" },
{ code : "57404", intitule : "LINDRE BASSE", cp : "57260" },
{ code : "57405", intitule : "LINDRE HAUTE", cp : "57260" },
{ code : "57406", intitule : "LIOCOURT", cp : "57590" },
{ code : "57407", intitule : "LIXHEIM", cp : "57635" },
{ code : "57408", intitule : "LIXING LES ROUHLING", cp : "57520" },
{ code : "57409", intitule : "LIXING LES ST AVOLD", cp : "57660" },
{ code : "57410", intitule : "LHOR", cp : "57670" },
{ code : "57411", intitule : "LOMMERANGE", cp : "57650" },
{ code : "57412", intitule : "LONGEVILLE LES METZ", cp : "57050" },
{ code : "57413", intitule : "LONGEVILLE LES ST AVOLD", cp : "57740" },
{ code : "57414", intitule : "LORQUIN", cp : "57790" },
{ code : "57415", intitule : "LORRY LES METZ", cp : "57050" },
{ code : "57416", intitule : "LORRY MARDIGNY", cp : "57420" },
{ code : "57417", intitule : "LOSTROFF", cp : "57670" },
{ code : "57418", intitule : "LOUDREFING", cp : "57670" },
{ code : "57419", intitule : "LOUPERSHOUSE", cp : "57510" },
{ code : "57421", intitule : "LOUTZVILLER", cp : "57720" },
{ code : "57422", intitule : "LOUVIGNY", cp : "57420" },
{ code : "57423", intitule : "LUBECOURT", cp : "57170" },
{ code : "57424", intitule : "LUCY", cp : "57590" },
{ code : "57425", intitule : "LUPPY", cp : "57580" },
{ code : "57426", intitule : "LUTTANGE", cp : "57935" },
{ code : "57427", intitule : "LUTZELBOURG", cp : "57820" },
{ code : "57428", intitule : "MACHEREN", cp : "57730" },
{ code : "57428", intitule : "MACHEREN", cp : "57730" },
{ code : "57430", intitule : "MAINVILLERS", cp : "57380" },
{ code : "57431", intitule : "MAIZEROY", cp : "57530" },
{ code : "57432", intitule : "MAIZERY", cp : "57530" },
{ code : "57433", intitule : "MAIZIERES LES METZ", cp : "57280" },
{ code : "57434", intitule : "MAIZIERES LES VIC", cp : "57810" },
{ code : "57436", intitule : "MALAUCOURT SUR SEILLE", cp : "57590" },
{ code : "57437", intitule : "MALLING", cp : "57480" },
{ code : "57438", intitule : "MALROY", cp : "57640" },
{ code : "57439", intitule : "MANDEREN", cp : "57480" },
{ code : "57440", intitule : "MANHOUE", cp : "57590" },
{ code : "57441", intitule : "MANOM", cp : "57100" },
{ code : "57442", intitule : "MANY", cp : "57380" },
{ code : "57443", intitule : "MARANGE SILVANGE", cp : "57535" },
{ code : "57444", intitule : "MARANGE ZONDRANGE", cp : "57690" },
{ code : "57445", intitule : "MARIEULLES", cp : "57420" },
{ code : "57446", intitule : "MARIMONT LES BENESTROFF", cp : "57670" },
{ code : "57447", intitule : "MARLY", cp : "57155" },
{ code : "57448", intitule : "MARSAL", cp : "57630" },
{ code : "57449", intitule : "MARSILLY", cp : "57530" },
{ code : "57451", intitule : "MARTHILLE", cp : "57340" },
{ code : "57452", intitule : "LA MAXE", cp : "57140" },
{ code : "57453", intitule : "MAXSTADT", cp : "57660" },
{ code : "57454", intitule : "MECLEUVES", cp : "57245" },
{ code : "57455", intitule : "MEGANGE", cp : "57220" },
{ code : "57456", intitule : "MEISENTHAL", cp : "57960" },
{ code : "57457", intitule : "MENSKIRCH", cp : "57320" },
{ code : "57459", intitule : "MERSCHWEILLER", cp : "57480" },
{ code : "57460", intitule : "MERTEN", cp : "57550" },
{ code : "57461", intitule : "METAIRIES ST QUIRIN", cp : "57560" },
{ code : "57462", intitule : "METTING", cp : "57370" },
{ code : "57463", intitule : "METZ", cp : "57050" },
{ code : "57463", intitule : "METZ", cp : "57070" },
{ code : "57463", intitule : "METZ", cp : "57000" },
{ code : "57464", intitule : "METZERESCHE", cp : "57920" },
{ code : "57465", intitule : "METZERVISSE", cp : "57940" },
{ code : "57466", intitule : "METZING", cp : "57980" },
{ code : "57467", intitule : "MEY", cp : "57070" },
{ code : "57468", intitule : "MITTELBRONN", cp : "57370" },
{ code : "57469", intitule : "MITTERSHEIM", cp : "57930" },
{ code : "57470", intitule : "MOLRING", cp : "57670" },
{ code : "57471", intitule : "MOMERSTROFF", cp : "57220" },
{ code : "57472", intitule : "MONCHEUX", cp : "57420" },
{ code : "57473", intitule : "MONCOURT", cp : "57810" },
{ code : "57474", intitule : "MONDELANGE", cp : "57300" },
{ code : "57475", intitule : "MONDORFF", cp : "57570" },
{ code : "57476", intitule : "MONNEREN", cp : "57920" },
{ code : "57477", intitule : "MONTBRONN", cp : "57415" },
{ code : "57478", intitule : "MONTDIDIER", cp : "57670" },
{ code : "57479", intitule : "MONTENACH", cp : "57480" },
{ code : "57480", intitule : "MONTIGNY LES METZ", cp : "57950" },
{ code : "57481", intitule : "MONTOIS LA MONTAGNE", cp : "57860" },
{ code : "57482", intitule : "MONTOY FLANVILLE", cp : "57645" },
{ code : "57483", intitule : "MORHANGE", cp : "57340" },
{ code : "57484", intitule : "MORSBACH", cp : "57600" },
{ code : "57485", intitule : "MORVILLE LES VIC", cp : "57170" },
{ code : "57486", intitule : "MORVILLE SUR NIED", cp : "57590" },
{ code : "57487", intitule : "MOULINS LES METZ", cp : "57160" },
{ code : "57487", intitule : "MOULINS LES METZ", cp : "57160" },
{ code : "57488", intitule : "MOUSSEY", cp : "57770" },
{ code : "57489", intitule : "MOUTERHOUSE", cp : "57620" },
{ code : "57490", intitule : "MOYENVIC", cp : "57630" },
{ code : "57491", intitule : "MOYEUVRE GRANDE", cp : "57250" },
{ code : "57491", intitule : "MOYEUVRE GRANDE", cp : "57250" },
{ code : "57492", intitule : "MOYEUVRE PETITE", cp : "57250" },
{ code : "57493", intitule : "MULCEY", cp : "57260" },
{ code : "57494", intitule : "MUNSTER", cp : "57670" },
{ code : "57495", intitule : "NARBEFONTAINE", cp : "57220" },
{ code : "57496", intitule : "NEBING", cp : "57670" },
{ code : "57497", intitule : "NELLING", cp : "57670" },
{ code : "57498", intitule : "NEUFCHEF", cp : "57700" },
{ code : "57499", intitule : "NEUFGRANGE", cp : "57910" },
{ code : "57500", intitule : "NEUFMOULINS", cp : "57830" },
{ code : "57501", intitule : "NEUFVILLAGE", cp : "57670" },
{ code : "57502", intitule : "NEUNKIRCHEN LES BOUZONVILLE", cp : "57320" },
{ code : "57504", intitule : "NIDERHOFF", cp : "57560" },
{ code : "57505", intitule : "NIDERVILLER", cp : "57565" },
{ code : "57506", intitule : "NIEDERSTINZEL", cp : "57930" },
{ code : "57507", intitule : "NIEDERVISSE", cp : "57220" },
{ code : "57508", intitule : "NILVANGE", cp : "57240" },
{ code : "57509", intitule : "NITTING", cp : "57790" },
{ code : "57510", intitule : "NOISSEVILLE", cp : "57645" },
{ code : "57511", intitule : "NORROY LE VENEUR", cp : "57140" },
{ code : "57512", intitule : "NOUILLY", cp : "57645" },
{ code : "57513", intitule : "NOUSSEVILLER LES BITCHE", cp : "57720" },
{ code : "57514", intitule : "NOUSSEVILLER ST NABOR", cp : "57990" },
{ code : "57515", intitule : "NOVEANT SUR MOSELLE", cp : "57680" },
{ code : "57516", intitule : "OBERDORFF", cp : "57320" },
{ code : "57517", intitule : "OBERGAILBACH", cp : "57720" },
{ code : "57518", intitule : "OBERSTINZEL", cp : "57930" },
{ code : "57519", intitule : "OBERVISSE", cp : "57220" },
{ code : "57520", intitule : "OBRECK", cp : "57170" },
{ code : "57521", intitule : "OETING", cp : "57600" },
{ code : "57523", intitule : "OGY", cp : "57530" },
{ code : "57524", intitule : "OMMERAY", cp : "57810" },
{ code : "57525", intitule : "ORIOCOURT", cp : "57590" },
{ code : "57526", intitule : "ORMERSVILLER", cp : "57720" },
{ code : "57527", intitule : "ORNY", cp : "57420" },
{ code : "57528", intitule : "ORON", cp : "57590" },
{ code : "57529", intitule : "OTTANGE", cp : "57840" },
{ code : "57530", intitule : "OTTONVILLE", cp : "57220" },
{ code : "57531", intitule : "OUDRENNE", cp : "57970" },
{ code : "57532", intitule : "PAGNY LES GOIN", cp : "57420" },
{ code : "57533", intitule : "PANGE", cp : "57530" },
{ code : "57534", intitule : "PELTRE", cp : "57245" },
{ code : "57535", intitule : "PETIT REDERCHING", cp : "57410" },
{ code : "57536", intitule : "PETIT TENQUIN", cp : "57660" },
{ code : "57537", intitule : "PETITE ROSSELLE", cp : "57540" },
{ code : "57538", intitule : "PETTONCOURT", cp : "57170" },
{ code : "57539", intitule : "PEVANGE", cp : "57340" },
{ code : "57540", intitule : "PHALSBOURG", cp : "57370" },
{ code : "57541", intitule : "PHILIPPSBOURG", cp : "57230" },
{ code : "57542", intitule : "PIBLANGE", cp : "57220" },
{ code : "57542", intitule : "PIBLANGE", cp : "57220" },
{ code : "57543", intitule : "PIERREVILLERS", cp : "57120" },
{ code : "57544", intitule : "PLAINE DE WALSCH", cp : "57870" },
{ code : "57545", intitule : "PLAPPEVILLE", cp : "57050" },
{ code : "57546", intitule : "PLESNOIS", cp : "57140" },
{ code : "57547", intitule : "POMMERIEUX", cp : "57420" },
{ code : "57548", intitule : "PONTOY", cp : "57420" },
{ code : "57549", intitule : "PONTPIERRE", cp : "57380" },
{ code : "57550", intitule : "PORCELETTE", cp : "57890" },
{ code : "57551", intitule : "POSTROFF", cp : "57930" },
{ code : "57552", intitule : "POUILLY", cp : "57420" },
{ code : "57553", intitule : "POURNOY LA CHETIVE", cp : "57420" },
{ code : "57554", intitule : "POURNOY LA GRASSE", cp : "57420" },
{ code : "57555", intitule : "PREVOCOURT", cp : "57590" },
{ code : "57556", intitule : "PUTTELANGE AUX LACS", cp : "57510" },
{ code : "57557", intitule : "PUTTELANGE LES THIONVILLE", cp : "57570" },
{ code : "57558", intitule : "PUTTIGNY", cp : "57170" },
{ code : "57559", intitule : "PUZIEUX", cp : "57590" },
{ code : "57560", intitule : "RACRANGE", cp : "57340" },
{ code : "57561", intitule : "RAHLING", cp : "57410" },
{ code : "57562", intitule : "RANGUEVAUX", cp : "57700" },
{ code : "57563", intitule : "RAVILLE", cp : "57530" },
{ code : "57564", intitule : "RECHICOURT LE CHATEAU", cp : "57810" },
{ code : "57565", intitule : "REDANGE", cp : "57390" },
{ code : "57566", intitule : "REDING", cp : "57445" },
{ code : "57567", intitule : "REMELFANG", cp : "57320" },
{ code : "57568", intitule : "REMELFING", cp : "57200" },
{ code : "57569", intitule : "REMELING", cp : "57480" },
{ code : "57570", intitule : "REMERING", cp : "57550" },
{ code : "57571", intitule : "REMERING LES PUTTELANGE", cp : "57510" },
{ code : "57572", intitule : "REMILLY", cp : "57580" },
{ code : "57573", intitule : "RENING", cp : "57670" },
{ code : "57574", intitule : "BASSE RENTGEN", cp : "57570" },
{ code : "57575", intitule : "RETONFEY", cp : "57645" },
{ code : "57576", intitule : "RETTEL", cp : "57480" },
{ code : "57577", intitule : "REYERSVILLER", cp : "57230" },
{ code : "57578", intitule : "REZONVILLE", cp : "57130" },
{ code : "57579", intitule : "RHODES", cp : "57810" },
{ code : "57580", intitule : "RICHE", cp : "57340" },
{ code : "57581", intitule : "RICHELING", cp : "57510" },
{ code : "57582", intitule : "RICHEMONT", cp : "57270" },
{ code : "57583", intitule : "RICHEVAL", cp : "57830" },
{ code : "57584", intitule : "RIMLING", cp : "57720" },
{ code : "57585", intitule : "RITZING", cp : "57480" },
{ code : "57586", intitule : "ROCHONVILLERS", cp : "57840" },
{ code : "57587", intitule : "RODALBE", cp : "57340" },
{ code : "57588", intitule : "RODEMACK", cp : "57570" },
{ code : "57589", intitule : "ROHRBACH LES BITCHE", cp : "57410" },
{ code : "57590", intitule : "ROLBING", cp : "57720" },
{ code : "57591", intitule : "ROMBAS", cp : "57120" },
{ code : "57592", intitule : "ROMELFING", cp : "57930" },
{ code : "57593", intitule : "RONCOURT", cp : "57860" },
{ code : "57594", intitule : "ROPPEVILLER", cp : "57230" },
{ code : "57595", intitule : "RORBACH LES DIEUZE", cp : "57260" },
{ code : "57596", intitule : "ROSBRUCK", cp : "57800" },
{ code : "57597", intitule : "ROSSELANGE", cp : "57780" },
{ code : "57598", intitule : "ROUHLING", cp : "57520" },
{ code : "57599", intitule : "ROUPELDANGE", cp : "57220" },
{ code : "57600", intitule : "ROUSSY LE VILLAGE", cp : "57330" },
{ code : "57601", intitule : "ROZERIEULLES", cp : "57160" },
{ code : "57602", intitule : "RURANGE LES THIONVILLE", cp : "57310" },
{ code : "57603", intitule : "RUSSANGE", cp : "57390" },
{ code : "57604", intitule : "RUSTROFF", cp : "57480" },
{ code : "57605", intitule : "SAILLY ACHATEL", cp : "57420" },
{ code : "57606", intitule : "ST AVOLD", cp : "57500" },
{ code : "57607", intitule : "STE BARBE", cp : "57640" },
{ code : "57609", intitule : "ST EPVRE", cp : "57580" },
{ code : "57610", intitule : "ST FRANCOIS LACROIX", cp : "57320" },
{ code : "57611", intitule : "ST GEORGES", cp : "57830" },
{ code : "57612", intitule : "ST HUBERT", cp : "57640" },
{ code : "57613", intitule : "ST JEAN DE BASSEL", cp : "57930" },
{ code : "57614", intitule : "ST JEAN KOURTZERODE", cp : "57370" },
{ code : "57615", intitule : "ST JEAN ROHRBACH", cp : "57510" },
{ code : "57616", intitule : "ST JULIEN LES METZ", cp : "57070" },
{ code : "57617", intitule : "ST JURE", cp : "57420" },
{ code : "57618", intitule : "ST LOUIS", cp : "57820" },
{ code : "57619", intitule : "ST LOUIS LES BITCHE", cp : "57620" },
{ code : "57620", intitule : "STE MARIE AUX CHENES", cp : "57255" },
{ code : "57621", intitule : "ST MEDARD", cp : "57260" },
{ code : "57622", intitule : "ST PRIVAT LA MONTAGNE", cp : "57855" },
{ code : "57623", intitule : "ST QUIRIN", cp : "57560" },
{ code : "57624", intitule : "STE RUFFINE", cp : "57130" },
{ code : "57625", intitule : "SALONNES", cp : "57170" },
{ code : "57626", intitule : "SANRY LES VIGY", cp : "57640" },
{ code : "57627", intitule : "SANRY SUR NIED", cp : "57530" },
{ code : "57628", intitule : "SARRALBE", cp : "57430" },
{ code : "57629", intitule : "SARRALTROFF", cp : "57400" },
{ code : "57630", intitule : "SARREBOURG", cp : "57400" },
{ code : "57631", intitule : "SARREGUEMINES", cp : "57200" },
{ code : "57631", intitule : "SARREGUEMINES", cp : "57200" },
{ code : "57633", intitule : "SARREINSMING", cp : "57905" },
{ code : "57634", intitule : "SAULNY", cp : "57140" },
{ code : "57635", intitule : "SCHALBACH", cp : "57370" },
{ code : "57636", intitule : "SCHMITTVILLER", cp : "57412" },
{ code : "57637", intitule : "SCHNECKENBUSCH", cp : "57400" },
{ code : "57638", intitule : "SCHOENECK", cp : "57350" },
{ code : "57639", intitule : "SCHORBACH", cp : "57230" },
{ code : "57640", intitule : "SCHWERDORFF", cp : "57320" },
{ code : "57641", intitule : "SCHWEYEN", cp : "57720" },
{ code : "57642", intitule : "SCY CHAZELLES", cp : "57160" },
{ code : "57643", intitule : "SECOURT", cp : "57420" },
{ code : "57644", intitule : "SEINGBOUSE", cp : "57455" },
{ code : "57645", intitule : "SEMECOURT", cp : "57280" },
{ code : "57647", intitule : "SEREMANGE ERZANGE", cp : "57290" },
{ code : "57648", intitule : "SERVIGNY LES RAVILLE", cp : "57530" },
{ code : "57649", intitule : "SERVIGNY LES STE BARBE", cp : "57640" },
{ code : "57650", intitule : "SIERCK LES BAINS", cp : "57480" },
{ code : "57651", intitule : "SIERSTHAL", cp : "57410" },
{ code : "57652", intitule : "SILLEGNY", cp : "57420" },
{ code : "57653", intitule : "SILLY EN SAULNOIS", cp : "57420" },
{ code : "57654", intitule : "SILLY SUR NIED", cp : "57530" },
{ code : "57655", intitule : "SOLGNE", cp : "57420" },
{ code : "57656", intitule : "SORBEY", cp : "57580" },
{ code : "57657", intitule : "SOTZELING", cp : "57170" },
{ code : "57658", intitule : "SOUCHT", cp : "57960" },
{ code : "57659", intitule : "SPICHEREN", cp : "57350" },
{ code : "57659", intitule : "SPICHEREN", cp : "57350" },
{ code : "57660", intitule : "STIRING WENDEL", cp : "57350" },
{ code : "57660", intitule : "STIRING WENDEL", cp : "57350" },
{ code : "57660", intitule : "STIRING WENDEL", cp : "57350" },
{ code : "57661", intitule : "STURZELBRONN", cp : "57230" },
{ code : "57662", intitule : "SUISSE", cp : "57340" },
{ code : "57663", intitule : "TALANGE", cp : "57525" },
{ code : "57664", intitule : "TARQUIMPOL", cp : "57260" },
{ code : "57665", intitule : "TENTELING", cp : "57980" },
{ code : "57666", intitule : "TERVILLE", cp : "57180" },
{ code : "57667", intitule : "TETERCHEN", cp : "57220" },
{ code : "57668", intitule : "TETING SUR NIED", cp : "57385" },
{ code : "57669", intitule : "THEDING", cp : "57450" },
{ code : "57670", intitule : "THICOURT", cp : "57380" },
{ code : "57671", intitule : "THIMONVILLE", cp : "57580" },
{ code : "57672", intitule : "THIONVILLE", cp : "57100" },
{ code : "57672", intitule : "THIONVILLE", cp : "57100" },
{ code : "57672", intitule : "THIONVILLE", cp : "57100" },
{ code : "57672", intitule : "THIONVILLE", cp : "57100" },
{ code : "57673", intitule : "THONVILLE", cp : "57380" },
{ code : "57674", intitule : "TINCRY", cp : "57590" },
{ code : "57675", intitule : "TORCHEVILLE", cp : "57670" },
{ code : "57676", intitule : "TRAGNY", cp : "57580" },
{ code : "57677", intitule : "TREMERY", cp : "57300" },
{ code : "57678", intitule : "TRESSANGE", cp : "57710" },
{ code : "57678", intitule : "TRESSANGE", cp : "57710" },
{ code : "57679", intitule : "TRITTELING REDLACH", cp : "57385" },
{ code : "57680", intitule : "TROISFONTAINES", cp : "57870" },
{ code : "57680", intitule : "TROISFONTAINES", cp : "57870" },
{ code : "57681", intitule : "TROMBORN", cp : "57320" },
{ code : "57682", intitule : "TURQUESTEIN BLANCRUPT", cp : "57560" },
{ code : "57683", intitule : "UCKANGE", cp : "57270" },
{ code : "57684", intitule : "VAHL EBERSING", cp : "57660" },
{ code : "57685", intitule : "VAHL LES BENESTROFF", cp : "57670" },
{ code : "57686", intitule : "VAHL LES FAULQUEMONT", cp : "57380" },
{ code : "57687", intitule : "VALLERANGE", cp : "57340" },
{ code : "57689", intitule : "VALMESTROFF", cp : "57970" },
{ code : "57690", intitule : "VALMONT", cp : "57730" },
{ code : "57691", intitule : "VALMUNSTER", cp : "57220" },
{ code : "57692", intitule : "VANNECOURT", cp : "57340" },
{ code : "57693", intitule : "VANTOUX", cp : "57070" },
{ code : "57694", intitule : "VANY", cp : "57070" },
{ code : "57695", intitule : "VARIZE", cp : "57220" },
{ code : "57695", intitule : "VARIZE", cp : "57220" },
{ code : "57696", intitule : "VARSBERG", cp : "57880" },
{ code : "57697", intitule : "VASPERVILLER", cp : "57560" },
{ code : "57698", intitule : "VATIMONT", cp : "57580" },
{ code : "57700", intitule : "VAUDRECHING", cp : "57320" },
{ code : "57701", intitule : "VAUX", cp : "57130" },
{ code : "57702", intitule : "VAXY", cp : "57170" },
{ code : "57703", intitule : "VECKERSVILLER", cp : "57370" },
{ code : "57704", intitule : "VECKRING", cp : "57920" },
{ code : "57705", intitule : "VELVING", cp : "57220" },
{ code : "57706", intitule : "VERGAVILLE", cp : "57260" },
{ code : "57707", intitule : "VERNEVILLE", cp : "57130" },
{ code : "57708", intitule : "VERNY", cp : "57420" },
{ code : "57709", intitule : "VESCHEIM", cp : "57370" },
{ code : "57711", intitule : "VIBERSVILLER", cp : "57670" },
{ code : "57712", intitule : "VIC SUR SEILLE", cp : "57630" },
{ code : "57713", intitule : "VIEUX LIXHEIM", cp : "57635" },
{ code : "57714", intitule : "HAUTE VIGNEULLES", cp : "57690" },
{ code : "57715", intitule : "VIGNY", cp : "57420" },
{ code : "57716", intitule : "VIGY", cp : "57640" },
{ code : "57717", intitule : "VILLER", cp : "57340" },
{ code : "57718", intitule : "VILLERS STONCOURT", cp : "57530" },
{ code : "57719", intitule : "VILLERS SUR NIED", cp : "57340" },
{ code : "57720", intitule : "VILLING", cp : "57550" },
{ code : "57721", intitule : "VILSBERG", cp : "57370" },
{ code : "57722", intitule : "VIONVILLE", cp : "57130" },
{ code : "57723", intitule : "VIRMING", cp : "57340" },
{ code : "57724", intitule : "VITRY SUR ORNE", cp : "57185" },
{ code : "57725", intitule : "VITTERSBOURG", cp : "57670" },
{ code : "57726", intitule : "VITTONCOURT", cp : "57580" },
{ code : "57727", intitule : "VIVIERS", cp : "57590" },
{ code : "57728", intitule : "VOIMHAUT", cp : "57580" },
{ code : "57730", intitule : "VOLMERANGE LES BOULAY", cp : "57220" },
{ code : "57731", intitule : "VOLMERANGE LES MINES", cp : "57330" },
{ code : "57732", intitule : "VOLMUNSTER", cp : "57720" },
{ code : "57733", intitule : "VOLSTROFF", cp : "57940" },
{ code : "57734", intitule : "VOYER", cp : "57560" },
{ code : "57736", intitule : "VRY", cp : "57640" },
{ code : "57737", intitule : "VULMONT", cp : "57420" },
{ code : "57738", intitule : "WALDHOUSE", cp : "57720" },
{ code : "57739", intitule : "WALDWEISTROFF", cp : "57320" },
{ code : "57740", intitule : "WALDWISSE", cp : "57480" },
{ code : "57741", intitule : "WALSCHBRONN", cp : "57720" },
{ code : "57742", intitule : "WALSCHEID", cp : "57870" },
{ code : "57743", intitule : "WALTEMBOURG", cp : "57370" },
{ code : "57745", intitule : "WIESVILLER", cp : "57200" },
{ code : "57746", intitule : "WILLERWALD", cp : "57430" },
{ code : "57747", intitule : "WINTERSBOURG", cp : "57635" },
{ code : "57748", intitule : "WITTRING", cp : "57905" },
{ code : "57749", intitule : "VOELFLING LES BOUZONVILLE", cp : "57320" },
{ code : "57750", intitule : "WOELFLING LES SARREGUEMINES", cp : "57200" },
{ code : "57751", intitule : "WOIPPY", cp : "57140" },
{ code : "57752", intitule : "WOUSTVILLER", cp : "57915" },
{ code : "57753", intitule : "WUISSE", cp : "57170" },
{ code : "57754", intitule : "XANREY", cp : "57630" },
{ code : "57755", intitule : "XOCOURT", cp : "57590" },
{ code : "57756", intitule : "XOUAXANGE", cp : "57830" },
{ code : "57757", intitule : "YUTZ", cp : "57970" },
{ code : "57759", intitule : "ZARBELING", cp : "57340" },
{ code : "57760", intitule : "ZETTING", cp : "57905" },
{ code : "57761", intitule : "ZILLING", cp : "57370" },
{ code : "57762", intitule : "ZIMMING", cp : "57690" },
{ code : "57763", intitule : "ZOMMANGE", cp : "57260" },
{ code : "57764", intitule : "ZOUFFTGEN", cp : "57330" },
{ code : "57765", intitule : "DIESEN", cp : "57890" },
{ code : "57767", intitule : "STUCKANGE", cp : "57970" },
{ code : "58001", intitule : "ACHUN", cp : "58110" },
{ code : "58002", intitule : "ALLIGNY COSNE", cp : "58200" },
{ code : "58003", intitule : "ALLIGNY EN MORVAN", cp : "58230" },
{ code : "58004", intitule : "ALLUY", cp : "58110" },
{ code : "58005", intitule : "AMAZY", cp : "58190" },
{ code : "58006", intitule : "ANLEZY", cp : "58270" },
{ code : "58007", intitule : "ANNAY", cp : "58450" },
{ code : "58008", intitule : "ANTHIEN", cp : "58800" },
{ code : "58009", intitule : "ARBOURSE", cp : "58350" },
{ code : "58010", intitule : "ARLEUF", cp : "58430" },
{ code : "58011", intitule : "ARMES", cp : "58500" },
{ code : "58012", intitule : "ARQUIAN", cp : "58310" },
{ code : "58013", intitule : "ARTHEL", cp : "58700" },
{ code : "58014", intitule : "ARZEMBOUY", cp : "58700" },
{ code : "58015", intitule : "ASNAN", cp : "58420" },
{ code : "58016", intitule : "ASNOIS", cp : "58190" },
{ code : "58017", intitule : "AUNAY EN BAZOIS", cp : "58110" },
{ code : "58018", intitule : "AUTHIOU", cp : "58700" },
{ code : "58019", intitule : "AVREE", cp : "58170" },
{ code : "58020", intitule : "AVRIL SUR LOIRE", cp : "58300" },
{ code : "58021", intitule : "AZY LE VIF", cp : "58240" },
{ code : "58022", intitule : "BALLERAY", cp : "58130" },
{ code : "58023", intitule : "BAZOCHES", cp : "58190" },
{ code : "58024", intitule : "BAZOLLES", cp : "58110" },
{ code : "58024", intitule : "BAZOLLES", cp : "58110" },
{ code : "58025", intitule : "BEARD", cp : "58160" },
{ code : "58026", intitule : "BEAULIEU", cp : "58420" },
{ code : "58026", intitule : "BEAULIEU", cp : "58420" },
{ code : "58026", intitule : "BEAULIEU", cp : "58420" },
{ code : "58027", intitule : "BEAUMONT LA FERRIERE", cp : "58700" },
{ code : "58028", intitule : "BEAUMONT SARDOLLES", cp : "58270" },
{ code : "58029", intitule : "BEUVRON", cp : "58210" },
{ code : "58030", intitule : "BICHES", cp : "58110" },
{ code : "58031", intitule : "BILLY CHEVANNES", cp : "58270" },
{ code : "58032", intitule : "BILLY SUR OISY", cp : "58500" },
{ code : "58033", intitule : "BITRY", cp : "58310" },
{ code : "58034", intitule : "BLISMES", cp : "58120" },
{ code : "58035", intitule : "BONA", cp : "58330" },
{ code : "58036", intitule : "BOUHY", cp : "58310" },
{ code : "58037", intitule : "BRASSY", cp : "58140" },
{ code : "58038", intitule : "BREUGNON", cp : "58460" },
{ code : "58039", intitule : "BREVES", cp : "58530" },
{ code : "58040", intitule : "BRINAY", cp : "58110" },
{ code : "58041", intitule : "BRINON SUR BEUVRON", cp : "58420" },
{ code : "58042", intitule : "BULCY", cp : "58400" },
{ code : "58043", intitule : "BUSSY LA PESLE", cp : "58420" },
{ code : "58044", intitule : "LA CELLE SUR LOIRE", cp : "58440" },
{ code : "58045", intitule : "LA CELLE SUR NIEVRE", cp : "58700" },
{ code : "58046", intitule : "CERCY LA TOUR", cp : "58340" },
{ code : "58047", intitule : "CERVON", cp : "58800" },
{ code : "58048", intitule : "CESSY LES BOIS", cp : "58220" },
{ code : "58049", intitule : "CHALAUX", cp : "58140" },
{ code : "58050", intitule : "CHALLEMENT", cp : "58420" },
{ code : "58051", intitule : "CHALLUY", cp : "58000" },
{ code : "58051", intitule : "CHALLUY", cp : "58000" },
{ code : "58052", intitule : "CHAMPALLEMENT", cp : "58420" },
{ code : "58053", intitule : "CHAMPLEMY", cp : "58210" },
{ code : "58054", intitule : "CHAMPLIN", cp : "58700" },
{ code : "58055", intitule : "CHAMPVERT", cp : "58300" },
{ code : "58056", intitule : "CHAMPVOUX", cp : "58400" },
{ code : "58057", intitule : "CHANTENAY ST IMBERT", cp : "58240" },
{ code : "58057", intitule : "CHANTENAY ST IMBERT", cp : "58240" },
{ code : "58058", intitule : "LA CHAPELLE ST ANDRE", cp : "58210" },
{ code : "58059", intitule : "LA CHARITE SUR LOIRE", cp : "58400" },
{ code : "58060", intitule : "CHARRIN", cp : "58300" },
{ code : "58061", intitule : "CHASNAY", cp : "58350" },
{ code : "58062", intitule : "CHATEAU CHINON VILLE", cp : "58120" },
{ code : "58063", intitule : "CHATEAU CHINON CAMPAGNE", cp : "58120" },
{ code : "58064", intitule : "CHATEAUNEUF VAL DE BARGIS", cp : "58350" },
{ code : "58065", intitule : "CHATILLON EN BAZOIS", cp : "58110" },
{ code : "58066", intitule : "CHATIN", cp : "58120" },
{ code : "58067", intitule : "CHAULGNES", cp : "58400" },
{ code : "58068", intitule : "CHAUMARD", cp : "58120" },
{ code : "58069", intitule : "CHAUMOT", cp : "58800" },
{ code : "58070", intitule : "CHAZEUIL", cp : "58700" },
{ code : "58071", intitule : "CHEVANNES CHANGY", cp : "58420" },
{ code : "58072", intitule : "CHEVENON", cp : "58160" },
{ code : "58073", intitule : "CHEVROCHES", cp : "58500" },
{ code : "58074", intitule : "CHIDDES", cp : "58170" },
{ code : "58075", intitule : "CHITRY LES MINES", cp : "58800" },
{ code : "58076", intitule : "CHOUGNY", cp : "58110" },
{ code : "58077", intitule : "CIEZ", cp : "58220" },
{ code : "58078", intitule : "CIZELY", cp : "58270" },
{ code : "58079", intitule : "CLAMECY", cp : "58500" },
{ code : "58079", intitule : "CLAMECY", cp : "58500" },
{ code : "58079", intitule : "CLAMECY", cp : "58500" },
{ code : "58080", intitule : "LA COLLANCELLE", cp : "58800" },
{ code : "58081", intitule : "COLMERY", cp : "58350" },
{ code : "58082", intitule : "CORANCY", cp : "58120" },
{ code : "58083", intitule : "CORBIGNY", cp : "58800" },
{ code : "58084", intitule : "CORVOL D EMBERNARD", cp : "58210" },
{ code : "58085", intitule : "CORVOL L ORGUEILLEUX", cp : "58460" },
{ code : "58086", intitule : "COSNE COURS SUR LOIRE", cp : "58200" },
{ code : "58086", intitule : "COSNE COURS SUR LOIRE", cp : "58200" },
{ code : "58087", intitule : "COSSAYE", cp : "58300" },
{ code : "58088", intitule : "COULANGES LES NEVERS", cp : "58660" },
{ code : "58089", intitule : "COULOUTRE", cp : "58220" },
{ code : "58090", intitule : "COURCELLES", cp : "58210" },
{ code : "58092", intitule : "CRUX LA VILLE", cp : "58330" },
{ code : "58093", intitule : "CUNCY LES VARZY", cp : "58210" },
{ code : "58094", intitule : "DAMPIERRE SOUS BOUHY", cp : "58310" },
{ code : "58095", intitule : "DECIZE", cp : "58300" },
{ code : "58096", intitule : "DEVAY", cp : "58300" },
{ code : "58097", intitule : "DIENNES AUBIGNY", cp : "58340" },
{ code : "58098", intitule : "DIROL", cp : "58190" },
{ code : "58099", intitule : "DOMMARTIN", cp : "58120" },
{ code : "58101", intitule : "DOMPIERRE SUR NIEVRE", cp : "58350" },
{ code : "58102", intitule : "DONZY", cp : "58220" },
{ code : "58103", intitule : "DORNECY", cp : "58530" },
{ code : "58104", intitule : "DORNES", cp : "58390" },
{ code : "58105", intitule : "DRUY PARIGNY", cp : "58160" },
{ code : "58106", intitule : "DUN LES PLACES", cp : "58230" },
{ code : "58107", intitule : "DUN SUR GRANDRY", cp : "58110" },
{ code : "58108", intitule : "EMPURY", cp : "58140" },
{ code : "58109", intitule : "ENTRAINS SUR NOHAIN", cp : "58410" },
{ code : "58110", intitule : "EPIRY", cp : "58800" },
{ code : "58111", intitule : "FACHIN", cp : "58430" },
{ code : "58112", intitule : "LA FERMETE", cp : "58160" },
{ code : "58113", intitule : "FERTREVE", cp : "58270" },
{ code : "58114", intitule : "FLETY", cp : "58170" },
{ code : "58115", intitule : "FLEURY SUR LOIRE", cp : "58240" },
{ code : "58116", intitule : "FLEZ CUZY", cp : "58190" },
{ code : "58117", intitule : "FOURCHAMBAULT", cp : "58600" },
{ code : "58118", intitule : "FOURS", cp : "58250" },
{ code : "58119", intitule : "FRASNAY REUGNY", cp : "58270" },
{ code : "58120", intitule : "GACOGNE", cp : "58140" },
{ code : "58121", intitule : "GARCHIZY", cp : "58600" },
{ code : "58122", intitule : "GARCHY", cp : "58150" },
{ code : "58122", intitule : "GARCHY", cp : "58150" },
{ code : "58123", intitule : "GERMENAY", cp : "58800" },
{ code : "58124", intitule : "GERMIGNY SUR LOIRE", cp : "58320" },
{ code : "58125", intitule : "GIEN SUR CURE", cp : "58230" },
{ code : "58126", intitule : "GIMOUILLE", cp : "58470" },
{ code : "58127", intitule : "GIRY", cp : "58700" },
{ code : "58128", intitule : "GLUX EN GLENNE", cp : "58370" },
{ code : "58129", intitule : "GOULOUX", cp : "58230" },
{ code : "58130", intitule : "GRENOIS", cp : "58420" },
{ code : "58131", intitule : "GUERIGNY", cp : "58130" },
{ code : "58132", intitule : "GUIPY", cp : "58420" },
{ code : "58133", intitule : "HERY", cp : "58800" },
{ code : "58134", intitule : "IMPHY", cp : "58160" },
{ code : "58135", intitule : "ISENAY", cp : "58290" },
{ code : "58136", intitule : "JAILLY", cp : "58330" },
{ code : "58137", intitule : "LAMENAY SUR LOIRE", cp : "58300" },
{ code : "58138", intitule : "LANGERON", cp : "58240" },
{ code : "58139", intitule : "LANTY", cp : "58250" },
{ code : "58140", intitule : "LAROCHEMILLAY", cp : "58370" },
{ code : "58141", intitule : "LAVAULT DE FRETOY", cp : "58230" },
{ code : "58142", intitule : "LIMANTON", cp : "58290" },
{ code : "58143", intitule : "LIMON", cp : "58270" },
{ code : "58144", intitule : "LIVRY", cp : "58240" },
{ code : "58145", intitule : "LORMES", cp : "58140" },
{ code : "58146", intitule : "LUCENAY LES AIX", cp : "58380" },
{ code : "58147", intitule : "LURCY LE BOURG", cp : "58700" },
{ code : "58148", intitule : "LUTHENAY UXELOUP", cp : "58240" },
{ code : "58149", intitule : "LUZY", cp : "58170" },
{ code : "58150", intitule : "LYS", cp : "58190" },
{ code : "58151", intitule : "LA MACHINE", cp : "58260" },
{ code : "58152", intitule : "MAGNY COURS", cp : "58470" },
{ code : "58153", intitule : "MAGNY LORMES", cp : "58800" },
{ code : "58154", intitule : "LA MAISON DIEU", cp : "58190" },
{ code : "58155", intitule : "LA MARCHE", cp : "58400" },
{ code : "58156", intitule : "MARCY", cp : "58210" },
{ code : "58157", intitule : "MARIGNY L EGLISE", cp : "58140" },
{ code : "58158", intitule : "MARS SUR ALLIER", cp : "58240" },
{ code : "58159", intitule : "MARIGNY SUR YONNE", cp : "58800" },
{ code : "58160", intitule : "MARZY", cp : "58180" },
{ code : "58160", intitule : "MARZY", cp : "58180" },
{ code : "58161", intitule : "MAUX", cp : "58290" },
{ code : "58162", intitule : "MENESTREAU", cp : "58410" },
{ code : "58163", intitule : "MENOU", cp : "58210" },
{ code : "58164", intitule : "MESVES SUR LOIRE", cp : "58400" },
{ code : "58165", intitule : "METZ LE COMTE", cp : "58190" },
{ code : "58166", intitule : "MHERE", cp : "58140" },
{ code : "58168", intitule : "MILLAY", cp : "58170" },
{ code : "58169", intitule : "MOISSY MOULINOT", cp : "58190" },
{ code : "58170", intitule : "MONCEAUX LE COMTE", cp : "58190" },
{ code : "58171", intitule : "MONTAPAS", cp : "58110" },
{ code : "58172", intitule : "MONTAMBERT", cp : "58250" },
{ code : "58173", intitule : "MONTARON", cp : "58250" },
{ code : "58174", intitule : "MONTENOISON", cp : "58700" },
{ code : "58175", intitule : "MONT ET MARRE", cp : "58110" },
{ code : "58176", intitule : "MONTIGNY AUX AMOGNES", cp : "58130" },
{ code : "58177", intitule : "MONTIGNY EN MORVAN", cp : "58120" },
{ code : "58178", intitule : "MONTIGNY SUR CANNE", cp : "58340" },
{ code : "58179", intitule : "MONTREUILLON", cp : "58800" },
{ code : "58180", intitule : "MONTSAUCHE LES SETTONS", cp : "58230" },
{ code : "58180", intitule : "MONTSAUCHE LES SETTONS", cp : "58230" },
{ code : "58181", intitule : "MORACHES", cp : "58420" },
{ code : "58182", intitule : "MOULINS ENGILBERT", cp : "58290" },
{ code : "58183", intitule : "MOURON SUR YONNE", cp : "58800" },
{ code : "58184", intitule : "MOUSSY", cp : "58700" },
{ code : "58185", intitule : "MOUX EN MORVAN", cp : "58230" },
{ code : "58186", intitule : "MURLIN", cp : "58700" },
{ code : "58187", intitule : "MYENNES", cp : "58440" },
{ code : "58188", intitule : "NANNAY", cp : "58350" },
{ code : "58189", intitule : "NARCY", cp : "58400" },
{ code : "58190", intitule : "NEUFFONTAINES", cp : "58190" },
{ code : "58191", intitule : "NEUILLY", cp : "58420" },
{ code : "58192", intitule : "NEUVILLE LES DECIZE", cp : "58300" },
{ code : "58193", intitule : "NEUVY SUR LOIRE", cp : "58450" },
{ code : "58194", intitule : "NEVERS", cp : "58000" },
{ code : "58195", intitule : "LA NOCLE MAULAIX", cp : "58250" },
{ code : "58196", intitule : "NOLAY", cp : "58700" },
{ code : "58197", intitule : "NUARS", cp : "58190" },
{ code : "58198", intitule : "OISY", cp : "58500" },
{ code : "58199", intitule : "ONLAY", cp : "58370" },
{ code : "58200", intitule : "OUAGNE", cp : "58500" },
{ code : "58201", intitule : "OUDAN", cp : "58210" },
{ code : "58202", intitule : "OUGNY", cp : "58110" },
{ code : "58203", intitule : "OULON", cp : "58700" },
{ code : "58204", intitule : "OUROUER", cp : "58130" },
{ code : "58205", intitule : "OUROUX EN MORVAN", cp : "58230" },
{ code : "58206", intitule : "PARIGNY LA ROSE", cp : "58210" },
{ code : "58207", intitule : "PARIGNY LES VAUX", cp : "58320" },
{ code : "58208", intitule : "PAZY", cp : "58800" },
{ code : "58209", intitule : "PERROY", cp : "58220" },
{ code : "58210", intitule : "PLANCHEZ", cp : "58230" },
{ code : "58211", intitule : "POIL", cp : "58170" },
{ code : "58212", intitule : "POISEUX", cp : "58130" },
{ code : "58213", intitule : "POUGNY", cp : "58200" },
{ code : "58214", intitule : "POUGUES LES EAUX", cp : "58320" },
{ code : "58215", intitule : "POUILLY SUR LOIRE", cp : "58150" },
{ code : "58216", intitule : "POUQUES LORMES", cp : "58140" },
{ code : "58217", intitule : "POUSSEAUX", cp : "58500" },
{ code : "58218", intitule : "PREMERY", cp : "58700" },
{ code : "58219", intitule : "PREPORCHE", cp : "58360" },
{ code : "58220", intitule : "RAVEAU", cp : "58400" },
{ code : "58221", intitule : "REMILLY", cp : "58250" },
{ code : "58222", intitule : "RIX", cp : "58500" },
{ code : "58223", intitule : "ROUY", cp : "58110" },
{ code : "58224", intitule : "RUAGES", cp : "58190" },
{ code : "58225", intitule : "SAINCAIZE MEAUCE", cp : "58470" },
{ code : "58226", intitule : "ST AGNAN", cp : "58230" },
{ code : "58227", intitule : "ST AMAND EN PUISAYE", cp : "58310" },
{ code : "58228", intitule : "ST ANDELAIN", cp : "58150" },
{ code : "58229", intitule : "ST ANDRE EN MORVAN", cp : "58140" },
{ code : "58230", intitule : "ST AUBIN DES CHAUMES", cp : "58190" },
{ code : "58231", intitule : "ST AUBIN LES FORGES", cp : "58130" },
{ code : "58232", intitule : "ST BENIN D AZY", cp : "58270" },
{ code : "58233", intitule : "ST BENIN DES BOIS", cp : "58330" },
{ code : "58234", intitule : "ST BONNOT", cp : "58700" },
{ code : "58235", intitule : "ST BRISSON", cp : "58230" },
{ code : "58236", intitule : "STE COLOMBE DES BOIS", cp : "58220" },
{ code : "58237", intitule : "ST DIDIER", cp : "58190" },
{ code : "58238", intitule : "ST ELOI", cp : "58000" },
{ code : "58239", intitule : "ST FIRMIN", cp : "58270" },
{ code : "58240", intitule : "ST FRANCHY", cp : "58330" },
{ code : "58241", intitule : "ST GERMAIN CHASSENAY", cp : "58300" },
{ code : "58242", intitule : "ST GERMAIN DES BOIS", cp : "58210" },
{ code : "58243", intitule : "ST GRATIEN SAVIGNY", cp : "58340" },
{ code : "58244", intitule : "ST HILAIRE EN MORVAN", cp : "58120" },
{ code : "58245", intitule : "ST HILAIRE FONTAINE", cp : "58300" },
{ code : "58246", intitule : "ST HONORE LES BAINS", cp : "58360" },
{ code : "58247", intitule : "ST JEAN AUX AMOGNES", cp : "58270" },
{ code : "58248", intitule : "ST LAURENT L ABBAYE", cp : "58150" },
{ code : "58249", intitule : "ST LEGER DE FOUGERET", cp : "58120" },
{ code : "58250", intitule : "ST LEGER DES VIGNES", cp : "58300" },
{ code : "58251", intitule : "ST LOUP", cp : "58200" },
{ code : "58252", intitule : "ST MALO EN DONZIOIS", cp : "58350" },
{ code : "58253", intitule : "STE MARIE", cp : "58330" },
{ code : "58254", intitule : "ST MARTIN D HEUILLE", cp : "58130" },
{ code : "58255", intitule : "ST MARTIN DU PUY", cp : "58140" },
{ code : "58256", intitule : "ST MARTIN SUR NOHAIN", cp : "58150" },
{ code : "58257", intitule : "ST MAURICE", cp : "58330" },
{ code : "58258", intitule : "ST OUEN SUR LOIRE", cp : "58160" },
{ code : "58259", intitule : "ST PARIZE EN VIRY", cp : "58300" },
{ code : "58260", intitule : "ST PARIZE LE CHATEL", cp : "58490" },
{ code : "58260", intitule : "ST PARIZE LE CHATEL", cp : "58490" },
{ code : "58261", intitule : "ST PERE", cp : "58200" },
{ code : "58262", intitule : "ST PEREUSE", cp : "58110" },
{ code : "58263", intitule : "ST PIERRE DU MONT", cp : "58210" },
{ code : "58264", intitule : "ST PIERRE LE MOUTIER", cp : "58240" },
{ code : "58265", intitule : "ST QUENTIN SUR NOHAIN", cp : "58150" },
{ code : "58266", intitule : "ST REVERIEN", cp : "58420" },
{ code : "58267", intitule : "ST SAULGE", cp : "58330" },
{ code : "58268", intitule : "ST SEINE", cp : "58250" },
{ code : "58269", intitule : "ST SULPICE", cp : "58270" },
{ code : "58270", intitule : "ST VERAIN", cp : "58310" },
{ code : "58271", intitule : "SAIZY", cp : "58190" },
{ code : "58272", intitule : "SARDY LES EPIRY", cp : "58800" },
{ code : "58273", intitule : "SAUVIGNY LES BOIS", cp : "58160" },
{ code : "58273", intitule : "SAUVIGNY LES BOIS", cp : "58160" },
{ code : "58274", intitule : "SAVIGNY POIL FOL", cp : "58170" },
{ code : "58275", intitule : "SAXI BOURDON", cp : "58330" },
{ code : "58276", intitule : "SEMELAY", cp : "58360" },
{ code : "58277", intitule : "SERMAGES", cp : "58290" },
{ code : "58278", intitule : "SERMOISE SUR LOIRE", cp : "58000" },
{ code : "58279", intitule : "SICHAMPS", cp : "58700" },
{ code : "58280", intitule : "SOUGY SUR LOIRE", cp : "58300" },
{ code : "58281", intitule : "SUILLY LA TOUR", cp : "58150" },
{ code : "58282", intitule : "SURGY", cp : "58500" },
{ code : "58283", intitule : "TACONNAY", cp : "58420" },
{ code : "58284", intitule : "TALON", cp : "58190" },
{ code : "58285", intitule : "TAMNAY EN BAZOIS", cp : "58110" },
{ code : "58286", intitule : "TANNAY", cp : "58190" },
{ code : "58287", intitule : "TAZILLY", cp : "58170" },
{ code : "58288", intitule : "TEIGNY", cp : "58190" },
{ code : "58289", intitule : "TERNANT", cp : "58250" },
{ code : "58290", intitule : "THAIX", cp : "58250" },
{ code : "58291", intitule : "THIANGES", cp : "58260" },
{ code : "58292", intitule : "TINTURY", cp : "58110" },
{ code : "58293", intitule : "TOURY LURCY", cp : "58300" },
{ code : "58294", intitule : "TOURY SUR JOUR", cp : "58240" },
{ code : "58295", intitule : "TRACY SUR LOIRE", cp : "58150" },
{ code : "58295", intitule : "TRACY SUR LOIRE", cp : "58150" },
{ code : "58296", intitule : "TRESNAY", cp : "58240" },
{ code : "58297", intitule : "TROIS VEVRES", cp : "58260" },
{ code : "58298", intitule : "TRONSANGES", cp : "58400" },
{ code : "58299", intitule : "TRUCY L ORGUEILLEUX", cp : "58460" },
{ code : "58300", intitule : "URZY", cp : "58130" },
{ code : "58301", intitule : "VANDENESSE", cp : "58290" },
{ code : "58302", intitule : "VARENNES LES NARCY", cp : "58400" },
{ code : "58303", intitule : "VARENNES VAUZELLES", cp : "58640" },
{ code : "58304", intitule : "VARZY", cp : "58210" },
{ code : "58305", intitule : "VAUCLAIX", cp : "58140" },
{ code : "58306", intitule : "VERNEUIL", cp : "58300" },
{ code : "58307", intitule : "VIELMANAY", cp : "58150" },
{ code : "58308", intitule : "VIGNOL", cp : "58190" },
{ code : "58309", intitule : "VILLAPOURCON", cp : "58370" },
{ code : "58310", intitule : "VILLIERS LE SEC", cp : "58210" },
{ code : "58311", intitule : "VILLE LANGY", cp : "58270" },
{ code : "58312", intitule : "VILLIERS SUR YONNE", cp : "58500" },
{ code : "58313", intitule : "VITRY LACHE", cp : "58420" },
{ code : "59001", intitule : "ABANCOURT", cp : "59268" },
{ code : "59002", intitule : "ABSCON", cp : "59215" },
{ code : "59003", intitule : "AIBES", cp : "59149" },
{ code : "59004", intitule : "AIX", cp : "59310" },
{ code : "59005", intitule : "ALLENNES LES MARAIS", cp : "59251" },
{ code : "59006", intitule : "AMFROIPRET", cp : "59144" },
{ code : "59007", intitule : "ANHIERS", cp : "59194" },
{ code : "59008", intitule : "ANICHE", cp : "59580" },
{ code : "59009", intitule : "VILLENEUVE D ASCQ", cp : "59493" },
{ code : "59009", intitule : "VILLENEUVE D ASCQ", cp : "59491" },
{ code : "59009", intitule : "VILLENEUVE D ASCQ", cp : "59650" },
{ code : "59010", intitule : "ANNEUX", cp : "59400" },
{ code : "59011", intitule : "ANNOEULLIN", cp : "59112" },
{ code : "59012", intitule : "ANOR", cp : "59186" },
{ code : "59013", intitule : "ANSTAING", cp : "59152" },
{ code : "59014", intitule : "ANZIN", cp : "59410" },
{ code : "59015", intitule : "ARLEUX", cp : "59151" },
{ code : "59016", intitule : "ARMBOUTS CAPPEL", cp : "59380" },
{ code : "59017", intitule : "ARMENTIERES", cp : "59280" },
{ code : "59017", intitule : "ARMENTIERES", cp : "59280" },
{ code : "59018", intitule : "ARNEKE", cp : "59285" },
{ code : "59019", intitule : "ARTRES", cp : "59269" },
{ code : "59021", intitule : "ASSEVENT", cp : "59600" },
{ code : "59022", intitule : "ATTICHES", cp : "59551" },
{ code : "59023", intitule : "AUBENCHEUL AU BAC", cp : "59265" },
{ code : "59024", intitule : "AUBERCHICOURT", cp : "59165" },
{ code : "59025", intitule : "AUBERS", cp : "59249" },
{ code : "59026", intitule : "AUBIGNY AU BAC", cp : "59265" },
{ code : "59027", intitule : "AUBRY DU HAINAUT", cp : "59494" },
{ code : "59028", intitule : "AUBY", cp : "59950" },
{ code : "59029", intitule : "AUCHY LEZ ORCHIES", cp : "59310" },
{ code : "59031", intitule : "AUDIGNIES", cp : "59570" },
{ code : "59032", intitule : "AULNOY LEZ VALENCIENNES", cp : "59300" },
{ code : "59033", intitule : "AULNOYE AYMERIES", cp : "59620" },
{ code : "59034", intitule : "AVELIN", cp : "59710" },
{ code : "59035", intitule : "AVESNELLES", cp : "59440" },
{ code : "59036", intitule : "AVESNES SUR HELPE", cp : "59440" },
{ code : "59037", intitule : "AVESNES LES AUBERT", cp : "59129" },
{ code : "59038", intitule : "AVESNES LE SEC", cp : "59296" },
{ code : "59039", intitule : "AWOINGT", cp : "59400" },
{ code : "59041", intitule : "BACHANT", cp : "59138" },
{ code : "59042", intitule : "BACHY", cp : "59830" },
{ code : "59043", intitule : "BAILLEUL", cp : "59270" },
{ code : "59043", intitule : "BAILLEUL", cp : "59270" },
{ code : "59044", intitule : "BAISIEUX", cp : "59780" },
{ code : "59045", intitule : "BAIVES", cp : "59132" },
{ code : "59046", intitule : "BAMBECQUE", cp : "59470" },
{ code : "59047", intitule : "BANTEUX", cp : "59266" },
{ code : "59048", intitule : "BANTIGNY", cp : "59554" },
{ code : "59049", intitule : "BANTOUZELLE", cp : "59266" },
{ code : "59050", intitule : "BAS LIEU", cp : "59440" },
{ code : "59051", intitule : "LA BASSEE", cp : "59480" },
{ code : "59052", intitule : "BAUVIN", cp : "59221" },
{ code : "59053", intitule : "BAVAY", cp : "59570" },
{ code : "59053", intitule : "BAVAY", cp : "59570" },
{ code : "59054", intitule : "BAVINCHOVE", cp : "59670" },
{ code : "59055", intitule : "BAZUEL", cp : "59360" },
{ code : "59056", intitule : "BEAUCAMPS LIGNY", cp : "59134" },
{ code : "59057", intitule : "BEAUDIGNIES", cp : "59530" },
{ code : "59058", intitule : "BEAUFORT", cp : "59330" },
{ code : "59059", intitule : "BEAUMONT EN CAMBRESIS", cp : "59540" },
{ code : "59060", intitule : "BEAURAIN", cp : "59730" },
{ code : "59061", intitule : "BEAUREPAIRE SUR SAMBRE", cp : "59550" },
{ code : "59062", intitule : "BEAURIEUX", cp : "59740" },
{ code : "59063", intitule : "BEAUVOIS EN CAMBRESIS", cp : "59157" },
{ code : "59064", intitule : "BELLAING", cp : "59135" },
{ code : "59065", intitule : "BELLIGNIES", cp : "59570" },
{ code : "59066", intitule : "BERELLES", cp : "59740" },
{ code : "59067", intitule : "BERGUES", cp : "59380" },
{ code : "59068", intitule : "BERLAIMONT", cp : "59145" },
{ code : "59069", intitule : "BERMERAIN", cp : "59213" },
{ code : "59070", intitule : "BERMERIES", cp : "59570" },
{ code : "59071", intitule : "BERSEE", cp : "59235" },
{ code : "59072", intitule : "BERSILLIES", cp : "59600" },
{ code : "59073", intitule : "BERTHEN", cp : "59270" },
{ code : "59073", intitule : "BERTHEN", cp : "59270" },
{ code : "59074", intitule : "BERTRY", cp : "59980" },
{ code : "59075", intitule : "BETHENCOURT", cp : "59540" },
{ code : "59076", intitule : "BETTIGNIES", cp : "59600" },
{ code : "59077", intitule : "BETTRECHIES", cp : "59570" },
{ code : "59078", intitule : "BEUGNIES", cp : "59216" },
{ code : "59079", intitule : "BEUVRAGES", cp : "59192" },
{ code : "59080", intitule : "BEUVRY LA FORET", cp : "59310" },
{ code : "59081", intitule : "BEVILLERS", cp : "59217" },
{ code : "59082", intitule : "BIERNE", cp : "59380" },
{ code : "59083", intitule : "BISSEZEELE", cp : "59380" },
{ code : "59084", intitule : "BLARINGHEM", cp : "59173" },
{ code : "59085", intitule : "BLECOURT", cp : "59268" },
{ code : "59086", intitule : "BOESCHEPE", cp : "59299" },
{ code : "59087", intitule : "BOESEGHEM", cp : "59189" },
{ code : "59088", intitule : "BOIS GRENIER", cp : "59280" },
{ code : "59089", intitule : "BOLLEZEELE", cp : "59470" },
{ code : "59090", intitule : "BONDUES", cp : "59910" },
{ code : "59091", intitule : "BORRE", cp : "59190" },
{ code : "59092", intitule : "BOUCHAIN", cp : "59111" },
{ code : "59092", intitule : "BOUCHAIN", cp : "59111" },
{ code : "59093", intitule : "BOULOGNE SUR HELPE", cp : "59440" },
{ code : "59094", intitule : "BOURBOURG", cp : "59630" },
{ code : "59096", intitule : "BOURGHELLES", cp : "59830" },
{ code : "59097", intitule : "BOURSIES", cp : "59400" },
{ code : "59098", intitule : "BOUSBECQUE", cp : "59166" },
{ code : "59099", intitule : "BOUSIES", cp : "59222" },
{ code : "59100", intitule : "BOUSIGNIES", cp : "59178" },
{ code : "59101", intitule : "BOUSIGNIES SUR ROC", cp : "59149" },
{ code : "59102", intitule : "BOUSSIERES EN CAMBRESIS", cp : "59217" },
{ code : "59103", intitule : "BOUSSIERES SUR SAMBRE", cp : "59330" },
{ code : "59104", intitule : "BOUSSOIS", cp : "59168" },
{ code : "59105", intitule : "BOUVIGNIES", cp : "59870" },
{ code : "59106", intitule : "BOUVINES", cp : "59830" },
{ code : "59107", intitule : "BRAY DUNES", cp : "59123" },
{ code : "59108", intitule : "BRIASTRE", cp : "59730" },
{ code : "59109", intitule : "BRILLON", cp : "59178" },
{ code : "59110", intitule : "BROUCKERQUE", cp : "59630" },
{ code : "59111", intitule : "BROXEELE", cp : "59470" },
{ code : "59112", intitule : "BRUAY SUR L ESCAUT", cp : "59860" },
{ code : "59113", intitule : "BRUILLE LEZ MARCHIENNES", cp : "59490" },
{ code : "59114", intitule : "BRUILLE ST AMAND", cp : "59199" },
{ code : "59115", intitule : "BRUNEMONT", cp : "59151" },
{ code : "59116", intitule : "BRY", cp : "59144" },
{ code : "59117", intitule : "BUGNICOURT", cp : "59151" },
{ code : "59118", intitule : "BUSIGNY", cp : "59137" },
{ code : "59119", intitule : "BUYSSCHEURE", cp : "59285" },
{ code : "59120", intitule : "CAESTRE", cp : "59190" },
{ code : "59121", intitule : "CAGNONCLES", cp : "59161" },
{ code : "59122", intitule : "CAMBRAI", cp : "59400" },
{ code : "59122", intitule : "CAMBRAI", cp : "59400" },
{ code : "59123", intitule : "CAMPHIN EN CAREMBAULT", cp : "59133" },
{ code : "59124", intitule : "CAMPHIN EN PEVELE", cp : "59780" },
{ code : "59125", intitule : "CANTAING SUR ESCAUT", cp : "59267" },
{ code : "59126", intitule : "CANTIN", cp : "59169" },
{ code : "59127", intitule : "CAPELLE", cp : "59213" },
{ code : "59128", intitule : "CAPINGHEM", cp : "59160" },
{ code : "59129", intitule : "CAPPELLE EN PEVELE", cp : "59242" },
{ code : "59130", intitule : "CAPPELLE BROUCK", cp : "59630" },
{ code : "59131", intitule : "CAPPELLE LA GRANDE", cp : "59180" },
{ code : "59132", intitule : "CARNIERES", cp : "59217" },
{ code : "59133", intitule : "CARNIN", cp : "59112" },
{ code : "59134", intitule : "CARTIGNIES", cp : "59244" },
{ code : "59135", intitule : "CASSEL", cp : "59670" },
{ code : "59136", intitule : "LE CATEAU CAMBRESIS", cp : "59360" },
{ code : "59137", intitule : "CATILLON SUR SAMBRE", cp : "59360" },
{ code : "59138", intitule : "CATTENIERES", cp : "59217" },
{ code : "59139", intitule : "CAUDRY", cp : "59540" },
{ code : "59139", intitule : "CAUDRY", cp : "59540" },
{ code : "59140", intitule : "CAULLERY", cp : "59191" },
{ code : "59141", intitule : "CAUROIR", cp : "59400" },
{ code : "59142", intitule : "CERFONTAINE", cp : "59680" },
{ code : "59143", intitule : "LA CHAPELLE D ARMENTIERES", cp : "59930" },
{ code : "59144", intitule : "CHATEAU L ABBAYE", cp : "59230" },
{ code : "59145", intitule : "CHEMY", cp : "59147" },
{ code : "59146", intitule : "CHERENG", cp : "59152" },
{ code : "59147", intitule : "CHOISIES", cp : "59740" },
{ code : "59148", intitule : "CLAIRFAYTS", cp : "59740" },
{ code : "59149", intitule : "CLARY", cp : "59225" },
{ code : "59150", intitule : "COBRIEUX", cp : "59830" },
{ code : "59151", intitule : "COLLERET", cp : "59680" },
{ code : "59152", intitule : "COMINES", cp : "59560" },
{ code : "59153", intitule : "CONDE SUR L ESCAUT", cp : "59163" },
{ code : "59155", intitule : "COUDEKERQUE BRANCHE", cp : "59210" },
{ code : "59156", intitule : "COURCHELETTES", cp : "59552" },
{ code : "59157", intitule : "COUSOLRE", cp : "59149" },
{ code : "59158", intitule : "COUTICHES", cp : "59310" },
{ code : "59159", intitule : "CRAYWICK", cp : "59279" },
{ code : "59160", intitule : "CRESPIN", cp : "59154" },
{ code : "59160", intitule : "CRESPIN", cp : "59154" },
{ code : "59161", intitule : "CREVECOEUR SUR L ESCAUT", cp : "59258" },
{ code : "59162", intitule : "CROCHTE", cp : "59380" },
{ code : "59163", intitule : "CROIX", cp : "59170" },
{ code : "59164", intitule : "CROIX CALUYAU", cp : "59222" },
{ code : "59165", intitule : "CUINCY", cp : "59553" },
{ code : "59166", intitule : "CURGIES", cp : "59990" },
{ code : "59167", intitule : "CUVILLERS", cp : "59268" },
{ code : "59168", intitule : "CYSOING", cp : "59830" },
{ code : "59169", intitule : "DAMOUSIES", cp : "59680" },
{ code : "59170", intitule : "DECHY", cp : "59187" },
{ code : "59171", intitule : "DEHERIES", cp : "59127" },
{ code : "59172", intitule : "DENAIN", cp : "59220" },
{ code : "59173", intitule : "DEULEMONT", cp : "59890" },
{ code : "59174", intitule : "DIMECHAUX", cp : "59740" },
{ code : "59175", intitule : "DIMONT", cp : "59216" },
{ code : "59176", intitule : "DOIGNIES", cp : "59400" },
{ code : "59177", intitule : "DOMPIERRE SUR HELPE", cp : "59440" },
{ code : "59178", intitule : "DOUAI", cp : "59500" },
{ code : "59178", intitule : "DOUAI", cp : "59500" },
{ code : "59178", intitule : "DOUAI", cp : "59500" },
{ code : "59179", intitule : "DOUCHY LES MINES", cp : "59282" },
{ code : "59180", intitule : "LE DOULIEU", cp : "59940" },
{ code : "59181", intitule : "DOURLERS", cp : "59440" },
{ code : "59182", intitule : "DRINCHAM", cp : "59630" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59279" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59430" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59240" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59240" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59240" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59430" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59140" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59640" },
{ code : "59183", intitule : "DUNKERQUE", cp : "59640" },
{ code : "59184", intitule : "EBBLINGHEM", cp : "59173" },
{ code : "59185", intitule : "ECAILLON", cp : "59176" },
{ code : "59186", intitule : "ECCLES", cp : "59740" },
{ code : "59187", intitule : "ECLAIBES", cp : "59330" },
{ code : "59188", intitule : "ECUELIN", cp : "59620" },
{ code : "59189", intitule : "EECKE", cp : "59114" },
{ code : "59190", intitule : "ELESMES", cp : "59600" },
{ code : "59191", intitule : "ELINCOURT", cp : "59127" },
{ code : "59192", intitule : "EMERCHICOURT", cp : "59580" },
{ code : "59193", intitule : "EMMERIN", cp : "59320" },
{ code : "59194", intitule : "ENGLEFONTAINE", cp : "59530" },
{ code : "59195", intitule : "ENGLOS", cp : "59320" },
{ code : "59196", intitule : "ENNETIERES EN WEPPES", cp : "59320" },
{ code : "59197", intitule : "ENNEVELIN", cp : "59710" },
{ code : "59198", intitule : "EPPE SAUVAGE", cp : "59132" },
{ code : "59199", intitule : "ERCHIN", cp : "59169" },
{ code : "59200", intitule : "ERINGHEM", cp : "59470" },
{ code : "59201", intitule : "ERQUINGHEM LE SEC", cp : "59320" },
{ code : "59202", intitule : "ERQUINGHEM LYS", cp : "59193" },
{ code : "59203", intitule : "ERRE", cp : "59171" },
{ code : "59204", intitule : "ESCARMAIN", cp : "59213" },
{ code : "59205", intitule : "ESCAUDAIN", cp : "59124" },
{ code : "59206", intitule : "ESCAUDOEUVRES", cp : "59161" },
{ code : "59207", intitule : "ESCAUTPONT", cp : "59278" },
{ code : "59208", intitule : "ESCOBECQUES", cp : "59320" },
{ code : "59209", intitule : "ESNES", cp : "59127" },
{ code : "59210", intitule : "ESQUELBECQ", cp : "59470" },
{ code : "59211", intitule : "ESQUERCHIN", cp : "59553" },
{ code : "59212", intitule : "ESTAIRES", cp : "59940" },
{ code : "59213", intitule : "ESTOURMEL", cp : "59400" },
{ code : "59214", intitule : "ESTREES", cp : "59151" },
{ code : "59215", intitule : "ESTREUX", cp : "59990" },
{ code : "59216", intitule : "ESWARS", cp : "59161" },
{ code : "59217", intitule : "ETH", cp : "59144" },
{ code : "59218", intitule : "ETROEUNGT", cp : "59219" },
{ code : "59219", intitule : "ESTRUN", cp : "59295" },
{ code : "59220", intitule : "FACHES THUMESNIL", cp : "59155" },
{ code : "59221", intitule : "FAMARS", cp : "59300" },
{ code : "59222", intitule : "FAUMONT", cp : "59310" },
{ code : "59223", intitule : "LE FAVRIL", cp : "59550" },
{ code : "59224", intitule : "FECHAIN", cp : "59247" },
{ code : "59225", intitule : "FEIGNIES", cp : "59750" },
{ code : "59225", intitule : "FEIGNIES", cp : "59750" },
{ code : "59226", intitule : "FELLERIES", cp : "59740" },
{ code : "59227", intitule : "FENAIN", cp : "59179" },
{ code : "59228", intitule : "FERIN", cp : "59169" },
{ code : "59229", intitule : "FERON", cp : "59610" },
{ code : "59230", intitule : "FERRIERE LA GRANDE", cp : "59680" },
{ code : "59231", intitule : "FERRIERE LA PETITE", cp : "59680" },
{ code : "59232", intitule : "LA FLAMENGRIE", cp : "59570" },
{ code : "59233", intitule : "FLAUMONT WAUDRECHIES", cp : "59440" },
{ code : "59234", intitule : "FLERS EN ESCREBIEUX", cp : "59128" },
{ code : "59236", intitule : "FLESQUIERES", cp : "59267" },
{ code : "59237", intitule : "FLETRE", cp : "59270" },
{ code : "59238", intitule : "FLINES LES MORTAGNE", cp : "59158" },
{ code : "59239", intitule : "FLINES LEZ RACHES", cp : "59148" },
{ code : "59240", intitule : "FLOURSIES", cp : "59440" },
{ code : "59241", intitule : "FLOYON", cp : "59219" },
{ code : "59242", intitule : "FONTAINE AU BOIS", cp : "59550" },
{ code : "59243", intitule : "FONTAINE AU PIRE", cp : "59157" },
{ code : "59244", intitule : "FONTAINE NOTRE DAME", cp : "59400" },
{ code : "59246", intitule : "FOREST EN CAMBRESIS", cp : "59222" },
{ code : "59247", intitule : "FOREST SUR MARQUE", cp : "59510" },
{ code : "59249", intitule : "FOURMIES", cp : "59610" },
{ code : "59250", intitule : "FOURNES EN WEPPES", cp : "59134" },
{ code : "59251", intitule : "FRASNOY", cp : "59530" },
{ code : "59252", intitule : "FRELINGHIEN", cp : "59236" },
{ code : "59253", intitule : "FRESNES SUR ESCAUT", cp : "59970" },
{ code : "59254", intitule : "FRESSAIN", cp : "59234" },
{ code : "59255", intitule : "FRESSIES", cp : "59268" },
{ code : "59256", intitule : "FRETIN", cp : "59273" },
{ code : "59257", intitule : "FROMELLES", cp : "59249" },
{ code : "59258", intitule : "GENECH", cp : "59242" },
{ code : "59259", intitule : "GHISSIGNIES", cp : "59530" },
{ code : "59260", intitule : "GHYVELDE", cp : "59122" },
{ code : "59260", intitule : "GHYVELDE", cp : "59254" },
{ code : "59261", intitule : "GLAGEON", cp : "59132" },
{ code : "59262", intitule : "GODEWAERSVELDE", cp : "59270" },
{ code : "59263", intitule : "GOEULZIN", cp : "59169" },
{ code : "59264", intitule : "GOGNIES CHAUSSEE", cp : "59600" },
{ code : "59265", intitule : "GOMMEGNIES", cp : "59144" },
{ code : "59266", intitule : "GONDECOURT", cp : "59147" },
{ code : "59267", intitule : "GONNELIEU", cp : "59231" },
{ code : "59268", intitule : "LA GORGUE", cp : "59253" },
{ code : "59269", intitule : "GOUZEAUCOURT", cp : "59231" },
{ code : "59270", intitule : "GRAND FAYT", cp : "59244" },
{ code : "59271", intitule : "GRANDE SYNTHE", cp : "59760" },
{ code : "59272", intitule : "GRAND FORT PHILIPPE", cp : "59153" },
{ code : "59273", intitule : "GRAVELINES", cp : "59820" },
{ code : "59274", intitule : "LA GROISE", cp : "59360" },
{ code : "59275", intitule : "GRUSON", cp : "59152" },
{ code : "59276", intitule : "GUESNAIN", cp : "59287" },
{ code : "59277", intitule : "GUSSIGNIES", cp : "59570" },
{ code : "59278", intitule : "HALLENNES LEZ HAUBOURDIN", cp : "59320" },
{ code : "59279", intitule : "HALLUIN", cp : "59250" },
{ code : "59280", intitule : "HAMEL", cp : "59151" },
{ code : "59281", intitule : "HANTAY", cp : "59496" },
{ code : "59282", intitule : "HARDIFORT", cp : "59670" },
{ code : "59283", intitule : "HARGNIES", cp : "59138" },
{ code : "59284", intitule : "HASNON", cp : "59178" },
{ code : "59285", intitule : "HASPRES", cp : "59198" },
{ code : "59286", intitule : "HAUBOURDIN", cp : "59320" },
{ code : "59287", intitule : "HAUCOURT EN CAMBRESIS", cp : "59191" },
{ code : "59288", intitule : "HAULCHIN", cp : "59121" },
{ code : "59289", intitule : "HAUSSY", cp : "59294" },
{ code : "59290", intitule : "HAUT LIEU", cp : "59440" },
{ code : "59291", intitule : "HAUTMONT", cp : "59330" },
{ code : "59292", intitule : "HAVELUY", cp : "59255" },
{ code : "59293", intitule : "HAVERSKERQUE", cp : "59660" },
{ code : "59294", intitule : "HAYNECOURT", cp : "59268" },
{ code : "59295", intitule : "HAZEBROUCK", cp : "59190" },
{ code : "59296", intitule : "HECQ", cp : "59530" },
{ code : "59297", intitule : "HELESMES", cp : "59171" },
{ code : "59299", intitule : "HEM", cp : "59510" },
{ code : "59300", intitule : "HEM LENGLET", cp : "59247" },
{ code : "59301", intitule : "HERGNIES", cp : "59199" },
{ code : "59302", intitule : "HERIN", cp : "59195" },
{ code : "59303", intitule : "HERLIES", cp : "59134" },
{ code : "59304", intitule : "HERRIN", cp : "59147" },
{ code : "59305", intitule : "HERZEELE", cp : "59470" },
{ code : "59306", intitule : "HESTRUD", cp : "59740" },
{ code : "59307", intitule : "HOLQUE", cp : "59143" },
{ code : "59308", intitule : "HONDEGHEM", cp : "59190" },
{ code : "59309", intitule : "HONDSCHOOTE", cp : "59122" },
{ code : "59310", intitule : "HON HERGIES", cp : "59570" },
{ code : "59311", intitule : "HONNECHY", cp : "59980" },
{ code : "59312", intitule : "HONNECOURT SUR ESCAUT", cp : "59266" },
{ code : "59313", intitule : "HORDAIN", cp : "59111" },
{ code : "59314", intitule : "HORNAING", cp : "59171" },
{ code : "59315", intitule : "HOUDAIN LEZ BAVAY", cp : "59570" },
{ code : "59316", intitule : "HOUPLIN ANCOISNE", cp : "59263" },
{ code : "59317", intitule : "HOUPLINES", cp : "59116" },
{ code : "59318", intitule : "HOUTKERQUE", cp : "59470" },
{ code : "59319", intitule : "HOYMILLE", cp : "59492" },
{ code : "59320", intitule : "ILLIES", cp : "59480" },
{ code : "59321", intitule : "INCHY", cp : "59540" },
{ code : "59322", intitule : "IWUY", cp : "59141" },
{ code : "59323", intitule : "JENLAIN", cp : "59144" },
{ code : "59324", intitule : "JEUMONT", cp : "59460" },
{ code : "59325", intitule : "JOLIMETZ", cp : "59530" },
{ code : "59326", intitule : "KILLEM", cp : "59122" },
{ code : "59327", intitule : "LALLAING", cp : "59167" },
{ code : "59328", intitule : "LAMBERSART", cp : "59130" },
{ code : "59329", intitule : "LAMBRES LEZ DOUAI", cp : "59552" },
{ code : "59330", intitule : "LANDAS", cp : "59310" },
{ code : "59331", intitule : "LANDRECIES", cp : "59550" },
{ code : "59332", intitule : "LANNOY", cp : "59390" },
{ code : "59333", intitule : "LAROUILLIES", cp : "59219" },
{ code : "59334", intitule : "LAUWIN PLANQUE", cp : "59553" },
{ code : "59335", intitule : "LECELLES", cp : "59226" },
{ code : "59336", intitule : "LECLUSE", cp : "59259" },
{ code : "59337", intitule : "LEDERZEELE", cp : "59143" },
{ code : "59338", intitule : "LEDRINGHEM", cp : "59470" },
{ code : "59339", intitule : "LEERS", cp : "59115" },
{ code : "59340", intitule : "LEFFRINCKOUCKE", cp : "59495" },
{ code : "59341", intitule : "LESDAIN", cp : "59258" },
{ code : "59342", intitule : "LEZ FONTAINE", cp : "59740" },
{ code : "59343", intitule : "LESQUIN", cp : "59810" },
{ code : "59344", intitule : "LEVAL", cp : "59620" },
{ code : "59345", intitule : "LEWARDE", cp : "59287" },
{ code : "59346", intitule : "LEZENNES", cp : "59260" },
{ code : "59347", intitule : "LIESSIES", cp : "59740" },
{ code : "59348", intitule : "LIEU ST AMAND", cp : "59111" },
{ code : "59349", intitule : "LIGNY EN CAMBRESIS", cp : "59191" },
{ code : "59350", intitule : "LILLE", cp : "59000" },
{ code : "59350", intitule : "LILLE", cp : "59260" },
{ code : "59350", intitule : "LILLE", cp : "59777" },
{ code : "59350", intitule : "LILLE", cp : "59800" },
{ code : "59350", intitule : "LILLE", cp : "59160" },
{ code : "59351", intitule : "LIMONT FONTAINE", cp : "59330" },
{ code : "59352", intitule : "LINSELLES", cp : "59126" },
{ code : "59353", intitule : "LOCQUIGNOL", cp : "59530" },
{ code : "59354", intitule : "LOFFRE", cp : "59182" },
{ code : "59356", intitule : "LOMPRET", cp : "59840" },
{ code : "59357", intitule : "LA LONGUEVILLE", cp : "59570" },
{ code : "59358", intitule : "LOOBERGHE", cp : "59630" },
{ code : "59359", intitule : "LOON PLAGE", cp : "59279" },
{ code : "59360", intitule : "LOOS", cp : "59120" },
{ code : "59361", intitule : "LOURCHES", cp : "59156" },
{ code : "59363", intitule : "LOUVIGNIES QUESNOY", cp : "59530" },
{ code : "59364", intitule : "LOUVIL", cp : "59830" },
{ code : "59365", intitule : "LOUVROIL", cp : "59720" },
{ code : "59366", intitule : "LYNDE", cp : "59173" },
{ code : "59367", intitule : "LYS LEZ LANNOY", cp : "59390" },
{ code : "59368", intitule : "LA MADELEINE", cp : "59110" },
{ code : "59369", intitule : "MAING", cp : "59233" },
{ code : "59370", intitule : "MAIRIEUX", cp : "59600" },
{ code : "59371", intitule : "LE MAISNIL", cp : "59134" },
{ code : "59372", intitule : "MALINCOURT", cp : "59127" },
{ code : "59374", intitule : "MARBAIX", cp : "59440" },
{ code : "59375", intitule : "MARCHIENNES", cp : "59870" },
{ code : "59377", intitule : "MARCOING", cp : "59159" },
{ code : "59378", intitule : "MARCQ EN BAROEUL", cp : "59700" },
{ code : "59379", intitule : "MARCQ EN OSTREVENT", cp : "59252" },
{ code : "59381", intitule : "MARESCHES", cp : "59990" },
{ code : "59382", intitule : "MARETZ", cp : "59238" },
{ code : "59383", intitule : "MARLY", cp : "59770" },
{ code : "59384", intitule : "MAROILLES", cp : "59550" },
{ code : "59385", intitule : "MARPENT", cp : "59164" },
{ code : "59386", intitule : "MARQUETTE LEZ LILLE", cp : "59520" },
{ code : "59387", intitule : "MARQUETTE EN OSTREVANT", cp : "59252" },
{ code : "59388", intitule : "MARQUILLIES", cp : "59274" },
{ code : "59389", intitule : "MASNIERES", cp : "59241" },
{ code : "59390", intitule : "MASNY", cp : "59176" },
{ code : "59391", intitule : "MASTAING", cp : "59172" },
{ code : "59392", intitule : "MAUBEUGE", cp : "59600" },
{ code : "59392", intitule : "MAUBEUGE", cp : "59600" },
{ code : "59393", intitule : "MAULDE", cp : "59158" },
{ code : "59394", intitule : "MAUROIS", cp : "59980" },
{ code : "59395", intitule : "MAZINGHIEN", cp : "59360" },
{ code : "59396", intitule : "MECQUIGNIES", cp : "59570" },
{ code : "59397", intitule : "MERCKEGHEM", cp : "59470" },
{ code : "59398", intitule : "MERIGNIES", cp : "59710" },
{ code : "59399", intitule : "MERRIS", cp : "59270" },
{ code : "59400", intitule : "MERVILLE", cp : "59660" },
{ code : "59401", intitule : "METEREN", cp : "59270" },
{ code : "59402", intitule : "MILLAM", cp : "59143" },
{ code : "59403", intitule : "MILLONFOSSE", cp : "59178" },
{ code : "59405", intitule : "MOEUVRES", cp : "59400" },
{ code : "59406", intitule : "MONCEAU ST WAAST", cp : "59620" },
{ code : "59407", intitule : "MONCHAUX SUR ECAILLON", cp : "59224" },
{ code : "59408", intitule : "MONCHEAUX", cp : "59283" },
{ code : "59409", intitule : "MONCHECOURT", cp : "59234" },
{ code : "59410", intitule : "MONS EN BAROEUL", cp : "59370" },
{ code : "59411", intitule : "MONS EN PEVELE", cp : "59246" },
{ code : "59412", intitule : "MONTAY", cp : "59360" },
{ code : "59413", intitule : "MONTIGNY EN CAMBRESIS", cp : "59225" },
{ code : "59414", intitule : "MONTIGNY EN OSTREVENT", cp : "59182" },
{ code : "59415", intitule : "MONTRECOURT", cp : "59227" },
{ code : "59416", intitule : "MORBECQUE", cp : "59190" },
{ code : "59418", intitule : "MORTAGNE DU NORD", cp : "59158" },
{ code : "59419", intitule : "MOUCHIN", cp : "59310" },
{ code : "59420", intitule : "MOUSTIER EN FAGNE", cp : "59132" },
{ code : "59421", intitule : "MOUVAUX", cp : "59420" },
{ code : "59422", intitule : "NAVES", cp : "59161" },
{ code : "59423", intitule : "NEUF BERQUIN", cp : "59940" },
{ code : "59424", intitule : "NEUF MESNIL", cp : "59330" },
{ code : "59425", intitule : "NEUVILLE EN AVESNOIS", cp : "59218" },
{ code : "59426", intitule : "NEUVILLE EN FERRAIN", cp : "59960" },
{ code : "59427", intitule : "LA NEUVILLE", cp : "59239" },
{ code : "59428", intitule : "NEUVILLE ST REMY", cp : "59554" },
{ code : "59429", intitule : "NEUVILLE SUR ESCAUT", cp : "59293" },
{ code : "59430", intitule : "NEUVILLY", cp : "59360" },
{ code : "59431", intitule : "NIEPPE", cp : "59850" },
{ code : "59432", intitule : "NIERGNIES", cp : "59400" },
{ code : "59433", intitule : "NIEURLET", cp : "59143" },
{ code : "59434", intitule : "NIVELLE", cp : "59230" },
{ code : "59435", intitule : "NOMAIN", cp : "59310" },
{ code : "59436", intitule : "NOORDPEENE", cp : "59670" },
{ code : "59437", intitule : "NOYELLES LES SECLIN", cp : "59139" },
{ code : "59438", intitule : "NOYELLES SUR ESCAUT", cp : "59159" },
{ code : "59439", intitule : "NOYELLES SUR SAMBRE", cp : "59550" },
{ code : "59440", intitule : "NOYELLES SUR SELLE", cp : "59282" },
{ code : "59441", intitule : "OBIES", cp : "59570" },
{ code : "59442", intitule : "OBRECHIES", cp : "59680" },
{ code : "59443", intitule : "OCHTEZEELE", cp : "59670" },
{ code : "59444", intitule : "ODOMEZ", cp : "59970" },
{ code : "59445", intitule : "OHAIN", cp : "59132" },
{ code : "59446", intitule : "OISY", cp : "59195" },
{ code : "59447", intitule : "ONNAING", cp : "59264" },
{ code : "59448", intitule : "OOST CAPPEL", cp : "59122" },
{ code : "59449", intitule : "ORCHIES", cp : "59310" },
{ code : "59450", intitule : "ORS", cp : "59360" },
{ code : "59451", intitule : "ORSINVAL", cp : "59530" },
{ code : "59452", intitule : "OSTRICOURT", cp : "59162" },
{ code : "59453", intitule : "OUDEZEELE", cp : "59670" },
{ code : "59454", intitule : "OXELAERE", cp : "59670" },
{ code : "59455", intitule : "PAILLENCOURT", cp : "59295" },
{ code : "59456", intitule : "PECQUENCOURT", cp : "59146" },
{ code : "59457", intitule : "PERENCHIES", cp : "59840" },
{ code : "59458", intitule : "PERONNE EN MELANTOIS", cp : "59273" },
{ code : "59459", intitule : "PETITE FORET", cp : "59494" },
{ code : "59461", intitule : "PETIT FAYT", cp : "59244" },
{ code : "59462", intitule : "PHALEMPIN", cp : "59133" },
{ code : "59463", intitule : "PITGAM", cp : "59284" },
{ code : "59464", intitule : "POIX DU NORD", cp : "59218" },
{ code : "59465", intitule : "POMMEREUIL", cp : "59360" },
{ code : "59466", intitule : "PONT A MARCQ", cp : "59710" },
{ code : "59467", intitule : "PONT SUR SAMBRE", cp : "59138" },
{ code : "59468", intitule : "POTELLE", cp : "59530" },
{ code : "59469", intitule : "PRADELLES", cp : "59190" },
{ code : "59470", intitule : "PREMESQUES", cp : "59840" },
{ code : "59470", intitule : "PREMESQUES", cp : "59840" },
{ code : "59471", intitule : "PRESEAU", cp : "59990" },
{ code : "59472", intitule : "PREUX AU BOIS", cp : "59288" },
{ code : "59473", intitule : "PREUX AU SART", cp : "59144" },
{ code : "59474", intitule : "PRISCHES", cp : "59550" },
{ code : "59475", intitule : "PROUVY", cp : "59121" },
{ code : "59476", intitule : "PROVILLE", cp : "59267" },
{ code : "59477", intitule : "PROVIN", cp : "59185" },
{ code : "59478", intitule : "QUAEDYPRE", cp : "59380" },
{ code : "59479", intitule : "QUAROUBLE", cp : "59243" },
{ code : "59480", intitule : "QUERENAING", cp : "59269" },
{ code : "59481", intitule : "LE QUESNOY", cp : "59530" },
{ code : "59482", intitule : "QUESNOY SUR DEULE", cp : "59890" },
{ code : "59483", intitule : "QUIEVELON", cp : "59680" },
{ code : "59484", intitule : "QUIEVRECHAIN", cp : "59920" },
{ code : "59484", intitule : "QUIEVRECHAIN", cp : "59920" },
{ code : "59485", intitule : "QUIEVY", cp : "59214" },
{ code : "59486", intitule : "RACHES", cp : "59194" },
{ code : "59487", intitule : "RADINGHEM EN WEPPES", cp : "59320" },
{ code : "59488", intitule : "RAILLENCOURT STE OLLE", cp : "59554" },
{ code : "59489", intitule : "RAIMBEAUCOURT", cp : "59283" },
{ code : "59490", intitule : "RAINSARS", cp : "59177" },
{ code : "59491", intitule : "RAISMES", cp : "59590" },
{ code : "59492", intitule : "RAMILLIES", cp : "59161" },
{ code : "59493", intitule : "RAMOUSIES", cp : "59177" },
{ code : "59494", intitule : "RAUCOURT AU BOIS", cp : "59530" },
{ code : "59495", intitule : "RECQUIGNIES", cp : "59245" },
{ code : "59496", intitule : "REJET DE BEAULIEU", cp : "59360" },
{ code : "59497", intitule : "RENESCURE", cp : "59173" },
{ code : "59498", intitule : "REUMONT", cp : "59980" },
{ code : "59499", intitule : "REXPOEDE", cp : "59122" },
{ code : "59500", intitule : "RIBECOURT LA TOUR", cp : "59159" },
{ code : "59501", intitule : "RIEULAY", cp : "59870" },
{ code : "59502", intitule : "RIEUX EN CAMBRESIS", cp : "59277" },
{ code : "59503", intitule : "ROBERSART", cp : "59550" },
{ code : "59504", intitule : "ROEULX", cp : "59172" },
{ code : "59505", intitule : "ROMBIES ET MARCHIPONT", cp : "59990" },
{ code : "59506", intitule : "ROMERIES", cp : "59730" },
{ code : "59507", intitule : "RONCHIN", cp : "59790" },
{ code : "59508", intitule : "RONCQ", cp : "59223" },
{ code : "59509", intitule : "ROOST WARENDIN", cp : "59286" },
{ code : "59511", intitule : "ROSULT", cp : "59230" },
{ code : "59512", intitule : "ROUBAIX", cp : "59100" },
{ code : "59513", intitule : "ROUCOURT", cp : "59169" },
{ code : "59514", intitule : "ROUSIES", cp : "59131" },
{ code : "59515", intitule : "ROUVIGNIES", cp : "59220" },
{ code : "59516", intitule : "RUBROUCK", cp : "59285" },
{ code : "59517", intitule : "LES RUES DES VIGNES", cp : "59258" },
{ code : "59518", intitule : "RUESNES", cp : "59530" },
{ code : "59519", intitule : "RUMEGIES", cp : "59226" },
{ code : "59520", intitule : "RUMILLY EN CAMBRESIS", cp : "59281" },
{ code : "59521", intitule : "SAILLY LEZ CAMBRAI", cp : "59554" },
{ code : "59522", intitule : "SAILLY LEZ LANNOY", cp : "59390" },
{ code : "59523", intitule : "SAINGHIN EN MELANTOIS", cp : "59262" },
{ code : "59524", intitule : "SAINGHIN EN WEPPES", cp : "59184" },
{ code : "59525", intitule : "SAINS DU NORD", cp : "59177" },
{ code : "59526", intitule : "ST AMAND LES EAUX", cp : "59230" },
{ code : "59527", intitule : "ST ANDRE LEZ LILLE", cp : "59350" },
{ code : "59528", intitule : "ST AUBERT", cp : "59188" },
{ code : "59529", intitule : "ST AUBIN", cp : "59440" },
{ code : "59530", intitule : "ST AYBERT", cp : "59163" },
{ code : "59531", intitule : "ST BENIN", cp : "59360" },
{ code : "59532", intitule : "ST GEORGES SUR L AA", cp : "59820" },
{ code : "59533", intitule : "ST HILAIRE LEZ CAMBRAI", cp : "59292" },
{ code : "59534", intitule : "ST HILAIRE SUR HELPE", cp : "59440" },
{ code : "59535", intitule : "ST JANS CAPPEL", cp : "59270" },
{ code : "59536", intitule : "STE MARIE CAPPEL", cp : "59670" },
{ code : "59537", intitule : "ST MARTIN SUR ECAILLON", cp : "59213" },
{ code : "59538", intitule : "ST MOMELIN", cp : "59143" },
{ code : "59539", intitule : "ST PIERRE BROUCK", cp : "59630" },
{ code : "59541", intitule : "ST PYTHON", cp : "59730" },
{ code : "59542", intitule : "ST REMY CHAUSSEE", cp : "59620" },
{ code : "59543", intitule : "ST REMY DU NORD", cp : "59330" },
{ code : "59544", intitule : "ST SAULVE", cp : "59880" },
{ code : "59545", intitule : "ST SOUPLET", cp : "59360" },
{ code : "59545", intitule : "ST SOUPLET", cp : "59360" },
{ code : "59546", intitule : "ST SYLVESTRE CAPPEL", cp : "59114" },
{ code : "59547", intitule : "ST VAAST EN CAMBRESIS", cp : "59188" },
{ code : "59548", intitule : "ST WAAST", cp : "59570" },
{ code : "59549", intitule : "SALESCHES", cp : "59218" },
{ code : "59550", intitule : "SALOME", cp : "59496" },
{ code : "59551", intitule : "SAMEON", cp : "59310" },
{ code : "59552", intitule : "SANCOURT", cp : "59268" },
{ code : "59553", intitule : "SANTES", cp : "59211" },
{ code : "59554", intitule : "SARS ET ROSIERES", cp : "59230" },
{ code : "59555", intitule : "SARS POTERIES", cp : "59216" },
{ code : "59556", intitule : "SASSEGNIES", cp : "59145" },
{ code : "59557", intitule : "SAULTAIN", cp : "59990" },
{ code : "59558", intitule : "SAULZOIR", cp : "59227" },
{ code : "59559", intitule : "SEBOURG", cp : "59990" },
{ code : "59560", intitule : "SECLIN", cp : "59113" },
{ code : "59562", intitule : "SEMERIES", cp : "59440" },
{ code : "59563", intitule : "SEMOUSIES", cp : "59440" },
{ code : "59564", intitule : "LA SENTINELLE", cp : "59174" },
{ code : "59565", intitule : "SEPMERIES", cp : "59269" },
{ code : "59566", intitule : "SEQUEDIN", cp : "59320" },
{ code : "59567", intitule : "SERANVILLERS FORENVILLE", cp : "59400" },
{ code : "59568", intitule : "SERCUS", cp : "59173" },
{ code : "59569", intitule : "SIN LE NOBLE", cp : "59450" },
{ code : "59570", intitule : "SOCX", cp : "59380" },
{ code : "59571", intitule : "SOLESMES", cp : "59730" },
{ code : "59572", intitule : "SOLRE LE CHATEAU", cp : "59740" },
{ code : "59573", intitule : "SOLRINNES", cp : "59740" },
{ code : "59574", intitule : "SOMAIN", cp : "59490" },
{ code : "59575", intitule : "SOMMAING", cp : "59213" },
{ code : "59576", intitule : "SPYCKER", cp : "59380" },
{ code : "59577", intitule : "STAPLE", cp : "59190" },
{ code : "59578", intitule : "STEENBECQUE", cp : "59189" },
{ code : "59579", intitule : "STEENE", cp : "59380" },
{ code : "59580", intitule : "STEENVOORDE", cp : "59114" },
{ code : "59581", intitule : "STEENWERCK", cp : "59181" },
{ code : "59581", intitule : "STEENWERCK", cp : "59181" },
{ code : "59582", intitule : "STRAZEELE", cp : "59270" },
{ code : "59583", intitule : "TAISNIERES EN THIERACHE", cp : "59550" },
{ code : "59584", intitule : "TAISNIERES SUR HON", cp : "59570" },
{ code : "59585", intitule : "TEMPLEMARS", cp : "59175" },
{ code : "59586", intitule : "TEMPLEUVE EN PEVELE", cp : "59242" },
{ code : "59587", intitule : "TERDEGHEM", cp : "59114" },
{ code : "59588", intitule : "TETEGHEM COUDEKERQUE VILLAGE", cp : "59229" },
{ code : "59588", intitule : "TETEGHEM COUDEKERQUE VILLAGE", cp : "59380" },
{ code : "59589", intitule : "THIANT", cp : "59224" },
{ code : "59590", intitule : "THIENNES", cp : "59189" },
{ code : "59591", intitule : "THIVENCELLE", cp : "59163" },
{ code : "59592", intitule : "THUMERIES", cp : "59239" },
{ code : "59593", intitule : "THUN L EVEQUE", cp : "59141" },
{ code : "59594", intitule : "THUN ST AMAND", cp : "59158" },
{ code : "59595", intitule : "THUN ST MARTIN", cp : "59141" },
{ code : "59596", intitule : "TILLOY LEZ MARCHIENNES", cp : "59870" },
{ code : "59597", intitule : "TILLOY LEZ CAMBRAI", cp : "59554" },
{ code : "59598", intitule : "TOUFFLERS", cp : "59390" },
{ code : "59599", intitule : "TOURCOING", cp : "59200" },
{ code : "59600", intitule : "TOURMIGNIES", cp : "59551" },
{ code : "59601", intitule : "TRELON", cp : "59132" },
{ code : "59602", intitule : "TRESSIN", cp : "59152" },
{ code : "59603", intitule : "TRITH ST LEGER", cp : "59125" },
{ code : "59604", intitule : "TROISVILLES", cp : "59980" },
{ code : "59605", intitule : "UXEM", cp : "59229" },
{ code : "59606", intitule : "VALENCIENNES", cp : "59300" },
{ code : "59607", intitule : "VENDEGIES AU BOIS", cp : "59218" },
{ code : "59608", intitule : "VENDEGIES SUR ECAILLON", cp : "59213" },
{ code : "59609", intitule : "VENDEVILLE", cp : "59175" },
{ code : "59610", intitule : "VERCHAIN MAUGRE", cp : "59227" },
{ code : "59611", intitule : "VERLINGHEM", cp : "59237" },
{ code : "59612", intitule : "VERTAIN", cp : "59730" },
{ code : "59613", intitule : "VICQ", cp : "59970" },
{ code : "59614", intitule : "VIESLY", cp : "59271" },
{ code : "59615", intitule : "VIEUX BERQUIN", cp : "59232" },
{ code : "59616", intitule : "VIEUX CONDE", cp : "59690" },
{ code : "59617", intitule : "VIEUX MESNIL", cp : "59138" },
{ code : "59618", intitule : "VIEUX RENG", cp : "59600" },
{ code : "59619", intitule : "VILLEREAU", cp : "59530" },
{ code : "59619", intitule : "VILLEREAU", cp : "59530" },
{ code : "59620", intitule : "VILLERS AU TERTRE", cp : "59234" },
{ code : "59622", intitule : "VILLERS EN CAUCHIES", cp : "59188" },
{ code : "59623", intitule : "VILLERS GUISLAIN", cp : "59297" },
{ code : "59624", intitule : "VILLERS OUTREAUX", cp : "59142" },
{ code : "59625", intitule : "VILLERS PLOUICH", cp : "59231" },
{ code : "59626", intitule : "VILLERS POL", cp : "59530" },
{ code : "59627", intitule : "VILLERS SIRE NICOLE", cp : "59600" },
{ code : "59628", intitule : "VOLCKERINCKHOVE", cp : "59470" },
{ code : "59629", intitule : "VRED", cp : "59870" },
{ code : "59630", intitule : "WAHAGNIES", cp : "59261" },
{ code : "59631", intitule : "WALINCOURT SELVIGNY", cp : "59127" },
{ code : "59631", intitule : "WALINCOURT SELVIGNY", cp : "59127" },
{ code : "59632", intitule : "WALLERS", cp : "59135" },
{ code : "59632", intitule : "WALLERS", cp : "59135" },
{ code : "59633", intitule : "WALLERS EN FAGNE", cp : "59132" },
{ code : "59634", intitule : "WALLON CAPPEL", cp : "59190" },
{ code : "59635", intitule : "WAMBAIX", cp : "59400" },
{ code : "59636", intitule : "WAMBRECHIES", cp : "59118" },
{ code : "59637", intitule : "WANDIGNIES HAMAGE", cp : "59870" },
{ code : "59638", intitule : "WANNEHAIN", cp : "59830" },
{ code : "59639", intitule : "WARGNIES LE GRAND", cp : "59144" },
{ code : "59640", intitule : "WARGNIES LE PETIT", cp : "59144" },
{ code : "59641", intitule : "WARHEM", cp : "59380" },
{ code : "59642", intitule : "WARLAING", cp : "59870" },
{ code : "59643", intitule : "WARNETON", cp : "59560" },
{ code : "59645", intitule : "WASNES AU BAC", cp : "59252" },
{ code : "59646", intitule : "WASQUEHAL", cp : "59290" },
{ code : "59647", intitule : "WATTEN", cp : "59143" },
{ code : "59648", intitule : "WATTIGNIES", cp : "59139" },
{ code : "59649", intitule : "WATTIGNIES LA VICTOIRE", cp : "59680" },
{ code : "59650", intitule : "WATTRELOS", cp : "59150" },
{ code : "59651", intitule : "WAVRECHAIN SOUS DENAIN", cp : "59220" },
{ code : "59652", intitule : "WAVRECHAIN SOUS FAULX", cp : "59111" },
{ code : "59653", intitule : "WAVRIN", cp : "59136" },
{ code : "59654", intitule : "WAZIERS", cp : "59119" },
{ code : "59655", intitule : "WEMAERS CAPPEL", cp : "59670" },
{ code : "59656", intitule : "WERVICQ SUD", cp : "59117" },
{ code : "59657", intitule : "WEST CAPPEL", cp : "59380" },
{ code : "59658", intitule : "WICRES", cp : "59134" },
{ code : "59659", intitule : "WIGNEHIES", cp : "59212" },
{ code : "59660", intitule : "WILLEMS", cp : "59780" },
{ code : "59661", intitule : "WILLIES", cp : "59740" },
{ code : "59662", intitule : "WINNEZEELE", cp : "59670" },
{ code : "59663", intitule : "WORMHOUT", cp : "59470" },
{ code : "59664", intitule : "WULVERDINGHE", cp : "59143" },
{ code : "59665", intitule : "WYLDER", cp : "59380" },
{ code : "59666", intitule : "ZEGERSCAPPEL", cp : "59470" },
{ code : "59667", intitule : "ZERMEZEELE", cp : "59670" },
{ code : "59668", intitule : "ZUYDCOOTE", cp : "59123" },
{ code : "59669", intitule : "ZUYTPEENE", cp : "59670" },
{ code : "59670", intitule : "DON", cp : "59272" },
{ code : "60001", intitule : "ABANCOURT", cp : "60220" },
{ code : "60002", intitule : "ABBECOURT", cp : "60430" },
{ code : "60003", intitule : "ABBEVILLE ST LUCIEN", cp : "60480" },
{ code : "60004", intitule : "ACHY", cp : "60690" },
{ code : "60005", intitule : "ACY EN MULTIEN", cp : "60620" },
{ code : "60006", intitule : "LES AGEUX", cp : "60700" },
{ code : "60007", intitule : "AGNETZ", cp : "60600" },
{ code : "60008", intitule : "AIRION", cp : "60600" },
{ code : "60009", intitule : "ALLONNE", cp : "60000" },
{ code : "60010", intitule : "AMBLAINVILLE", cp : "60110" },
{ code : "60011", intitule : "AMY", cp : "60310" },
{ code : "60012", intitule : "ANDEVILLE", cp : "60570" },
{ code : "60013", intitule : "ANGICOURT", cp : "60940" },
{ code : "60014", intitule : "ANGIVILLERS", cp : "60130" },
{ code : "60015", intitule : "ANGY", cp : "60250" },
{ code : "60016", intitule : "ANSACQ", cp : "60250" },
{ code : "60017", intitule : "ANSAUVILLERS", cp : "60120" },
{ code : "60019", intitule : "ANTHEUIL PORTES", cp : "60162" },
{ code : "60020", intitule : "ANTILLY", cp : "60620" },
{ code : "60021", intitule : "APPILLY", cp : "60400" },
{ code : "60022", intitule : "APREMONT", cp : "60300" },
{ code : "60023", intitule : "ARMANCOURT", cp : "60880" },
{ code : "60024", intitule : "ARSY", cp : "60190" },
{ code : "60025", intitule : "ATTICHY", cp : "60350" },
{ code : "60026", intitule : "AUCHY LA MONTAGNE", cp : "60360" },
{ code : "60027", intitule : "AUGER ST VINCENT", cp : "60800" },
{ code : "60028", intitule : "AUMONT EN HALATTE", cp : "60300" },
{ code : "60029", intitule : "AUNEUIL", cp : "60390" },
{ code : "60030", intitule : "AUTEUIL", cp : "60390" },
{ code : "60031", intitule : "AUTHEUIL EN VALOIS", cp : "60890" },
{ code : "60032", intitule : "AUTRECHES", cp : "60350" },
{ code : "60033", intitule : "AVILLY ST LEONARD", cp : "60300" },
{ code : "60034", intitule : "AVRECHY", cp : "60130" },
{ code : "60035", intitule : "AVRICOURT", cp : "60310" },
{ code : "60036", intitule : "AVRIGNY", cp : "60190" },
{ code : "60037", intitule : "BABOEUF", cp : "60400" },
{ code : "60038", intitule : "BACHIVILLERS", cp : "60240" },
{ code : "60039", intitule : "BACOUEL", cp : "60120" },
{ code : "60040", intitule : "BAILLEUL LE SOC", cp : "60190" },
{ code : "60041", intitule : "BAILLEUL SUR THERAIN", cp : "60930" },
{ code : "60042", intitule : "BAILLEVAL", cp : "60140" },
{ code : "60043", intitule : "BAILLY", cp : "60170" },
{ code : "60044", intitule : "BALAGNY SUR THERAIN", cp : "60250" },
{ code : "60045", intitule : "BARBERY", cp : "60810" },
{ code : "60046", intitule : "BARGNY", cp : "60620" },
{ code : "60047", intitule : "BARON", cp : "60300" },
{ code : "60048", intitule : "BAUGY", cp : "60113" },
{ code : "60048", intitule : "BAUGY", cp : "60190" },
{ code : "60049", intitule : "BAZANCOURT", cp : "60380" },
{ code : "60050", intitule : "BAZICOURT", cp : "60700" },
{ code : "60051", intitule : "BEAUDEDUIT", cp : "60210" },
{ code : "60052", intitule : "BEAUGIES SOUS BOIS", cp : "60640" },
{ code : "60053", intitule : "BEAULIEU LES FONTAINES", cp : "60310" },
{ code : "60054", intitule : "BEAUMONT LES NONAINS", cp : "60390" },
{ code : "60055", intitule : "BEAURAINS LES NOYON", cp : "60400" },
{ code : "60056", intitule : "BEAUREPAIRE", cp : "60700" },
{ code : "60057", intitule : "BEAUVAIS", cp : "60000" },
{ code : "60058", intitule : "BEAUVOIR", cp : "60120" },
{ code : "60059", intitule : "BEHERICOURT", cp : "60400" },
{ code : "60060", intitule : "BELLE EGLISE", cp : "60540" },
{ code : "60061", intitule : "BELLOY", cp : "60490" },
{ code : "60062", intitule : "BERLANCOURT", cp : "60640" },
{ code : "60063", intitule : "BERNEUIL EN BRAY", cp : "60390" },
{ code : "60064", intitule : "BERNEUIL SUR AISNE", cp : "60350" },
{ code : "60065", intitule : "BERTHECOURT", cp : "60370" },
{ code : "60066", intitule : "BETHANCOURT EN VALOIS", cp : "60129" },
{ code : "60067", intitule : "BETHISY ST MARTIN", cp : "60320" },
{ code : "60068", intitule : "BETHISY ST PIERRE", cp : "60320" },
{ code : "60069", intitule : "BETZ", cp : "60620" },
{ code : "60070", intitule : "BIENVILLE", cp : "60280" },
{ code : "60071", intitule : "BIERMONT", cp : "60490" },
{ code : "60072", intitule : "BITRY", cp : "60350" },
{ code : "60073", intitule : "BLACOURT", cp : "60650" },
{ code : "60074", intitule : "BLAINCOURT LES PRECY", cp : "60460" },
{ code : "60075", intitule : "BLANCFOSSE", cp : "60120" },
{ code : "60076", intitule : "BLARGIES", cp : "60220" },
{ code : "60077", intitule : "BLICOURT", cp : "60860" },
{ code : "60078", intitule : "BLINCOURT", cp : "60190" },
{ code : "60079", intitule : "BOISSY FRESNOY", cp : "60440" },
{ code : "60080", intitule : "BOISSY LE BOIS", cp : "60240" },
{ code : "60081", intitule : "BONLIER", cp : "60510" },
{ code : "60082", intitule : "BONNEUIL LES EAUX", cp : "60120" },
{ code : "60083", intitule : "BONNEUIL EN VALOIS", cp : "60123" },
{ code : "60084", intitule : "BONNIERES", cp : "60112" },
{ code : "60085", intitule : "BONVILLERS", cp : "60120" },
{ code : "60086", intitule : "BORAN SUR OISE", cp : "60820" },
{ code : "60087", intitule : "BOREST", cp : "60300" },
{ code : "60088", intitule : "BORNEL", cp : "60540" },
{ code : "60088", intitule : "BORNEL", cp : "60540" },
{ code : "60088", intitule : "BORNEL", cp : "60540" },
{ code : "60089", intitule : "BOUBIERS", cp : "60240" },
{ code : "60090", intitule : "BOUCONVILLERS", cp : "60240" },
{ code : "60091", intitule : "BOUILLANCY", cp : "60620" },
{ code : "60092", intitule : "BOULLARRE", cp : "60620" },
{ code : "60093", intitule : "BOULOGNE LA GRASSE", cp : "60490" },
{ code : "60094", intitule : "BOURSONNE", cp : "60141" },
{ code : "60095", intitule : "BOURY EN VEXIN", cp : "60240" },
{ code : "60096", intitule : "BOUTAVENT", cp : "60220" },
{ code : "60097", intitule : "BOUTENCOURT", cp : "60590" },
{ code : "60098", intitule : "BOUVRESSE", cp : "60220" },
{ code : "60099", intitule : "BRAISNES SUR ARONDE", cp : "60113" },
{ code : "60100", intitule : "BRASSEUSE", cp : "60810" },
{ code : "60101", intitule : "BREGY", cp : "60440" },
{ code : "60102", intitule : "BRENOUILLE", cp : "60870" },
{ code : "60103", intitule : "BRESLES", cp : "60510" },
{ code : "60104", intitule : "BRETEUIL", cp : "60120" },
{ code : "60105", intitule : "BRETIGNY", cp : "60400" },
{ code : "60106", intitule : "BREUIL LE SEC", cp : "60840" },
{ code : "60107", intitule : "BREUIL LE VERT", cp : "60600" },
{ code : "60108", intitule : "BRIOT", cp : "60210" },
{ code : "60109", intitule : "BROMBOS", cp : "60210" },
{ code : "60110", intitule : "BROQUIERS", cp : "60220" },
{ code : "60111", intitule : "BROYES", cp : "60120" },
{ code : "60112", intitule : "BRUNVILLERS LA MOTTE", cp : "60130" },
{ code : "60113", intitule : "BUCAMPS", cp : "60480" },
{ code : "60114", intitule : "BUICOURT", cp : "60380" },
{ code : "60115", intitule : "BULLES", cp : "60130" },
{ code : "60116", intitule : "BURY", cp : "60250" },
{ code : "60117", intitule : "BUSSY", cp : "60400" },
{ code : "60118", intitule : "CAISNES", cp : "60400" },
{ code : "60119", intitule : "CAMBRONNE LES RIBECOURT", cp : "60170" },
{ code : "60120", intitule : "CAMBRONNE LES CLERMONT", cp : "60290" },
{ code : "60121", intitule : "CAMPAGNE", cp : "60640" },
{ code : "60122", intitule : "CAMPEAUX", cp : "60220" },
{ code : "60123", intitule : "CAMPREMY", cp : "60480" },
{ code : "60124", intitule : "CANDOR", cp : "60310" },
{ code : "60125", intitule : "CANLY", cp : "60680" },
{ code : "60126", intitule : "CANNECTANCOURT", cp : "60310" },
{ code : "60127", intitule : "CANNY SUR MATZ", cp : "60310" },
{ code : "60128", intitule : "CANNY SUR THERAIN", cp : "60220" },
{ code : "60129", intitule : "CARLEPONT", cp : "60170" },
{ code : "60130", intitule : "CATENOY", cp : "60840" },
{ code : "60131", intitule : "CATHEUX", cp : "60360" },
{ code : "60132", intitule : "CATIGNY", cp : "60640" },
{ code : "60133", intitule : "CATILLON FUMECHON", cp : "60130" },
{ code : "60134", intitule : "CAUFFRY", cp : "60290" },
{ code : "60135", intitule : "CAUVIGNY", cp : "60730" },
{ code : "60136", intitule : "CEMPUIS", cp : "60210" },
{ code : "60137", intitule : "CERNOY", cp : "60190" },
{ code : "60138", intitule : "CHAMANT", cp : "60300" },
{ code : "60139", intitule : "CHAMBLY", cp : "60230" },
{ code : "60140", intitule : "CHAMBORS", cp : "60240" },
{ code : "60141", intitule : "CHANTILLY", cp : "60500" },
{ code : "60142", intitule : "LA CHAPELLE EN SERVAL", cp : "60520" },
{ code : "60143", intitule : "CHAUMONT EN VEXIN", cp : "60240" },
{ code : "60144", intitule : "CHAVENCON", cp : "60240" },
{ code : "60145", intitule : "CHELLES", cp : "60350" },
{ code : "60146", intitule : "CHEPOIX", cp : "60120" },
{ code : "60147", intitule : "CHEVINCOURT", cp : "60150" },
{ code : "60148", intitule : "CHEVREVILLE", cp : "60440" },
{ code : "60149", intitule : "CHEVRIERES", cp : "60710" },
{ code : "60150", intitule : "CHIRY OURSCAMP", cp : "60138" },
{ code : "60151", intitule : "CHOISY AU BAC", cp : "60750" },
{ code : "60152", intitule : "CHOISY LA VICTOIRE", cp : "60190" },
{ code : "60153", intitule : "CHOQUEUSE LES BENARDS", cp : "60360" },
{ code : "60154", intitule : "CINQUEUX", cp : "60940" },
{ code : "60155", intitule : "CIRES LES MELLO", cp : "60660" },
{ code : "60156", intitule : "CLAIROIX", cp : "60280" },
{ code : "60157", intitule : "CLERMONT", cp : "60600" },
{ code : "60158", intitule : "COIVREL", cp : "60420" },
{ code : "60159", intitule : "COMPIEGNE", cp : "60200" },
{ code : "60160", intitule : "CONCHY LES POTS", cp : "60490" },
{ code : "60161", intitule : "CONTEVILLE", cp : "60360" },
{ code : "60162", intitule : "CORBEIL CERF", cp : "60110" },
{ code : "60163", intitule : "CORMEILLES", cp : "60120" },
{ code : "60164", intitule : "LE COUDRAY ST GERMER", cp : "60850" },
{ code : "60165", intitule : "LE COUDRAY SUR THELLE", cp : "60430" },
{ code : "60166", intitule : "COUDUN", cp : "60150" },
{ code : "60167", intitule : "COULOISY", cp : "60350" },
{ code : "60168", intitule : "COURCELLES EPAYELLES", cp : "60420" },
{ code : "60169", intitule : "COURCELLES LES GISORS", cp : "60240" },
{ code : "60170", intitule : "COURTEUIL", cp : "60300" },
{ code : "60171", intitule : "COURTIEUX", cp : "60350" },
{ code : "60172", intitule : "COYE LA FORET", cp : "60580" },
{ code : "60173", intitule : "CRAMOISY", cp : "60660" },
{ code : "60174", intitule : "CRAPEAUMESNIL", cp : "60310" },
{ code : "60175", intitule : "CREIL", cp : "60100" },
{ code : "60176", intitule : "CREPY EN VALOIS", cp : "60800" },
{ code : "60177", intitule : "CRESSONSACQ", cp : "60190" },
{ code : "60178", intitule : "CREVECOEUR LE GRAND", cp : "60360" },
{ code : "60179", intitule : "CREVECOEUR LE PETIT", cp : "60420" },
{ code : "60180", intitule : "CRILLON", cp : "60112" },
{ code : "60181", intitule : "CRISOLLES", cp : "60400" },
{ code : "60182", intitule : "LE CROCQ", cp : "60120" },
{ code : "60183", intitule : "CROISSY SUR CELLE", cp : "60120" },
{ code : "60184", intitule : "CROUTOY", cp : "60350" },
{ code : "60185", intitule : "CROUY EN THELLE", cp : "60530" },
{ code : "60186", intitule : "CUIGNIERES", cp : "60130" },
{ code : "60187", intitule : "CUIGY EN BRAY", cp : "60850" },
{ code : "60188", intitule : "CUISE LA MOTTE", cp : "60350" },
{ code : "60189", intitule : "CUTS", cp : "60400" },
{ code : "60190", intitule : "CUVERGNON", cp : "60620" },
{ code : "60191", intitule : "CUVILLY", cp : "60490" },
{ code : "60192", intitule : "CUY", cp : "60310" },
{ code : "60193", intitule : "DAMERAUCOURT", cp : "60210" },
{ code : "60194", intitule : "DARGIES", cp : "60210" },
{ code : "60195", intitule : "DELINCOURT", cp : "60240" },
{ code : "60196", intitule : "LE DELUGE", cp : "60790" },
{ code : "60197", intitule : "DIEUDONNE", cp : "60530" },
{ code : "60198", intitule : "DIVES", cp : "60310" },
{ code : "60199", intitule : "DOMELIERS", cp : "60360" },
{ code : "60200", intitule : "DOMFRONT", cp : "60420" },
{ code : "60201", intitule : "DOMPIERRE", cp : "60420" },
{ code : "60203", intitule : "DUVY", cp : "60800" },
{ code : "60204", intitule : "ECUVILLY", cp : "60310" },
{ code : "60205", intitule : "ELENCOURT", cp : "60210" },
{ code : "60206", intitule : "ELINCOURT STE MARGUERITE", cp : "60157" },
{ code : "60207", intitule : "EMEVILLE", cp : "60123" },
{ code : "60208", intitule : "ENENCOURT LEAGE", cp : "60590" },
{ code : "60209", intitule : "ENENCOURT LE SEC", cp : "60240" },
{ code : "60210", intitule : "EPINEUSE", cp : "60190" },
{ code : "60211", intitule : "ERAGNY SUR EPTE", cp : "60590" },
{ code : "60212", intitule : "ERCUIS", cp : "60530" },
{ code : "60213", intitule : "ERMENONVILLE", cp : "60950" },
{ code : "60214", intitule : "ERNEMONT BOUTAVENT", cp : "60380" },
{ code : "60215", intitule : "ERQUERY", cp : "60600" },
{ code : "60216", intitule : "ERQUINVILLERS", cp : "60130" },
{ code : "60217", intitule : "ESCAMES", cp : "60380" },
{ code : "60218", intitule : "ESCHES", cp : "60110" },
{ code : "60219", intitule : "ESCLES ST PIERRE", cp : "60220" },
{ code : "60220", intitule : "ESPAUBOURG", cp : "60650" },
{ code : "60221", intitule : "ESQUENNOY", cp : "60120" },
{ code : "60222", intitule : "ESSUILES", cp : "60510" },
{ code : "60223", intitule : "ESTREES ST DENIS", cp : "60190" },
{ code : "60224", intitule : "ETAVIGNY", cp : "60620" },
{ code : "60225", intitule : "ETOUY", cp : "60600" },
{ code : "60226", intitule : "EVE", cp : "60330" },
{ code : "60227", intitule : "EVRICOURT", cp : "60310" },
{ code : "60228", intitule : "FAY LES ETANGS", cp : "60240" },
{ code : "60229", intitule : "LE FAYEL", cp : "60680" },
{ code : "60230", intitule : "LE FAY ST QUENTIN", cp : "60510" },
{ code : "60231", intitule : "FEIGNEUX", cp : "60800" },
{ code : "60232", intitule : "FERRIERES", cp : "60420" },
{ code : "60233", intitule : "FEUQUIERES", cp : "60960" },
{ code : "60234", intitule : "FITZ JAMES", cp : "60600" },
{ code : "60235", intitule : "FLAVACOURT", cp : "60590" },
{ code : "60236", intitule : "FLAVY LE MELDEUX", cp : "60640" },
{ code : "60237", intitule : "FLECHY", cp : "60120" },
{ code : "60238", intitule : "FLEURINES", cp : "60700" },
{ code : "60239", intitule : "FLEURY", cp : "60240" },
{ code : "60240", intitule : "FONTAINE BONNELEAU", cp : "60360" },
{ code : "60241", intitule : "FONTAINE CHAALIS", cp : "60300" },
{ code : "60242", intitule : "FONTAINE LAVAGANNE", cp : "60690" },
{ code : "60243", intitule : "FONTAINE ST LUCIEN", cp : "60480" },
{ code : "60244", intitule : "FONTENAY TORCY", cp : "60380" },
{ code : "60245", intitule : "FORMERIE", cp : "60220" },
{ code : "60247", intitule : "FOUILLEUSE", cp : "60190" },
{ code : "60248", intitule : "FOUILLOY", cp : "60220" },
{ code : "60249", intitule : "FOULANGUES", cp : "60250" },
{ code : "60250", intitule : "FOUQUENIES", cp : "60000" },
{ code : "60251", intitule : "FOUQUEROLLES", cp : "60510" },
{ code : "60252", intitule : "FOURNIVAL", cp : "60130" },
{ code : "60253", intitule : "FRANCASTEL", cp : "60480" },
{ code : "60254", intitule : "FRANCIERES", cp : "60190" },
{ code : "60255", intitule : "FRENICHES", cp : "60640" },
{ code : "60256", intitule : "FRESNEAUX MONTCHEVREUIL", cp : "60240" },
{ code : "60257", intitule : "FRESNE LEGUILLON", cp : "60240" },
{ code : "60258", intitule : "FRESNIERES", cp : "60310" },
{ code : "60259", intitule : "FRESNOY EN THELLE", cp : "60530" },
{ code : "60260", intitule : "FRESNOY LA RIVIERE", cp : "60127" },
{ code : "60261", intitule : "FRESNOY LE LUAT", cp : "60800" },
{ code : "60262", intitule : "LE FRESTOY VAUX", cp : "60420" },
{ code : "60263", intitule : "FRETOY LE CHATEAU", cp : "60640" },
{ code : "60264", intitule : "FROCOURT", cp : "60000" },
{ code : "60265", intitule : "FROISSY", cp : "60480" },
{ code : "60267", intitule : "LE GALLET", cp : "60360" },
{ code : "60268", intitule : "GANNES", cp : "60120" },
{ code : "60269", intitule : "GAUDECHART", cp : "60210" },
{ code : "60270", intitule : "GENVRY", cp : "60400" },
{ code : "60271", intitule : "GERBEROY", cp : "60380" },
{ code : "60272", intitule : "GILOCOURT", cp : "60129" },
{ code : "60273", intitule : "GIRAUMONT", cp : "60150" },
{ code : "60274", intitule : "GLAIGNES", cp : "60129" },
{ code : "60275", intitule : "GLATIGNY", cp : "60650" },
{ code : "60276", intitule : "GODENVILLERS", cp : "60420" },
{ code : "60277", intitule : "GOINCOURT", cp : "60000" },
{ code : "60278", intitule : "GOLANCOURT", cp : "60640" },
{ code : "60279", intitule : "GONDREVILLE", cp : "60117" },
{ code : "60280", intitule : "GOURCHELLES", cp : "60220" },
{ code : "60281", intitule : "GOURNAY SUR ARONDE", cp : "60190" },
{ code : "60282", intitule : "GOUVIEUX", cp : "60270" },
{ code : "60283", intitule : "GOUY LES GROSEILLERS", cp : "60120" },
{ code : "60284", intitule : "GRANDFRESNOY", cp : "60680" },
{ code : "60285", intitule : "GRANDVILLERS AUX BOIS", cp : "60190" },
{ code : "60286", intitule : "GRANDVILLIERS", cp : "60210" },
{ code : "60287", intitule : "GRANDRU", cp : "60400" },
{ code : "60288", intitule : "GREMEVILLERS", cp : "60380" },
{ code : "60289", intitule : "GREZ", cp : "60210" },
{ code : "60290", intitule : "GUIGNECOURT", cp : "60480" },
{ code : "60291", intitule : "GUISCARD", cp : "60640" },
{ code : "60292", intitule : "GURY", cp : "60310" },
{ code : "60293", intitule : "HADANCOURT LE HAUT CLOCHER", cp : "60240" },
{ code : "60294", intitule : "HAINVILLERS", cp : "60490" },
{ code : "60295", intitule : "HALLOY", cp : "60210" },
{ code : "60296", intitule : "HANNACHES", cp : "60650" },
{ code : "60297", intitule : "LE HAMEL", cp : "60210" },
{ code : "60298", intitule : "HANVOILE", cp : "60650" },
{ code : "60299", intitule : "HARDIVILLERS", cp : "60120" },
{ code : "60300", intitule : "HARDIVILLERS EN VEXIN", cp : "60240" },
{ code : "60301", intitule : "HAUCOURT", cp : "60112" },
{ code : "60302", intitule : "HAUDIVILLERS", cp : "60510" },
{ code : "60303", intitule : "HAUTBOS", cp : "60210" },
{ code : "60304", intitule : "HAUTE EPINE", cp : "60690" },
{ code : "60305", intitule : "HAUTEFONTAINE", cp : "60350" },
{ code : "60306", intitule : "HECOURT", cp : "60380" },
{ code : "60307", intitule : "HEILLES", cp : "60250" },
{ code : "60308", intitule : "HEMEVILLERS", cp : "60190" },
{ code : "60309", intitule : "HENONVILLE", cp : "60119" },
{ code : "60310", intitule : "HERCHIES", cp : "60112" },
{ code : "60311", intitule : "LA HERELLE", cp : "60120" },
{ code : "60312", intitule : "HERICOURT SUR THERAIN", cp : "60380" },
{ code : "60313", intitule : "HERMES", cp : "60370" },
{ code : "60314", intitule : "HETOMESNIL", cp : "60360" },
{ code : "60315", intitule : "HODENC EN BRAY", cp : "60650" },
{ code : "60316", intitule : "HODENC L EVEQUE", cp : "60430" },
{ code : "60317", intitule : "HONDAINVILLE", cp : "60250" },
{ code : "60318", intitule : "HOUDANCOURT", cp : "60710" },
{ code : "60319", intitule : "LA HOUSSOYE", cp : "60390" },
{ code : "60320", intitule : "IVORS", cp : "60141" },
{ code : "60321", intitule : "IVRY LE TEMPLE", cp : "60173" },
{ code : "60322", intitule : "JAMERICOURT", cp : "60240" },
{ code : "60323", intitule : "JANVILLE", cp : "60150" },
{ code : "60324", intitule : "JAULZY", cp : "60350" },
{ code : "60325", intitule : "JAUX", cp : "60880" },
{ code : "60326", intitule : "JONQUIERES", cp : "60680" },
{ code : "60327", intitule : "JOUY SOUS THELLE", cp : "60240" },
{ code : "60328", intitule : "JUVIGNIES", cp : "60112" },
{ code : "60329", intitule : "LABERLIERE", cp : "60310" },
{ code : "60330", intitule : "LABOISSIERE EN THELLE", cp : "60570" },
{ code : "60331", intitule : "LABOSSE", cp : "60590" },
{ code : "60332", intitule : "LABRUYERE", cp : "60140" },
{ code : "60333", intitule : "LACHAPELLE AUX POTS", cp : "60650" },
{ code : "60334", intitule : "LACHAPELLE ST PIERRE", cp : "60730" },
{ code : "60335", intitule : "LACHAPELLE SOUS GERBEROY", cp : "60380" },
{ code : "60336", intitule : "LACHAUSSEE DU BOIS D ECU", cp : "60480" },
{ code : "60337", intitule : "LACHELLE", cp : "60190" },
{ code : "60338", intitule : "LACROIX ST OUEN", cp : "60610" },
{ code : "60339", intitule : "LAFRAYE", cp : "60510" },
{ code : "60340", intitule : "LAGNY", cp : "60310" },
{ code : "60341", intitule : "LAGNY LE SEC", cp : "60330" },
{ code : "60342", intitule : "LAIGNEVILLE", cp : "60290" },
{ code : "60343", intitule : "LALANDE EN SON", cp : "60590" },
{ code : "60344", intitule : "LALANDELLE", cp : "60850" },
{ code : "60345", intitule : "LAMECOURT", cp : "60600" },
{ code : "60346", intitule : "LAMORLAYE", cp : "60260" },
{ code : "60347", intitule : "LANNOY CUILLERE", cp : "60220" },
{ code : "60348", intitule : "LARBROYE", cp : "60400" },
{ code : "60350", intitule : "LASSIGNY", cp : "60310" },
{ code : "60351", intitule : "LATAULE", cp : "60490" },
{ code : "60352", intitule : "LATTAINVILLE", cp : "60240" },
{ code : "60353", intitule : "LAVACQUERIE", cp : "60120" },
{ code : "60354", intitule : "LAVERRIERE", cp : "60210" },
{ code : "60355", intitule : "LAVERSINES", cp : "60510" },
{ code : "60356", intitule : "LAVILLETERTRE", cp : "60240" },
{ code : "60357", intitule : "LEGLANTIERS", cp : "60420" },
{ code : "60358", intitule : "LEVIGNEN", cp : "60800" },
{ code : "60359", intitule : "LHERAULE", cp : "60650" },
{ code : "60360", intitule : "LIANCOURT", cp : "60140" },
{ code : "60361", intitule : "LIANCOURT ST PIERRE", cp : "60240" },
{ code : "60362", intitule : "LIBERMONT", cp : "60640" },
{ code : "60363", intitule : "LIERVILLE", cp : "60240" },
{ code : "60364", intitule : "LIEUVILLERS", cp : "60130" },
{ code : "60365", intitule : "LIHUS", cp : "60360" },
{ code : "60366", intitule : "LITZ", cp : "60510" },
{ code : "60367", intitule : "LOCONVILLE", cp : "60240" },
{ code : "60368", intitule : "LONGUEIL ANNEL", cp : "60150" },
{ code : "60369", intitule : "LONGUEIL STE MARIE", cp : "60126" },
{ code : "60370", intitule : "LORMAISON", cp : "60110" },
{ code : "60371", intitule : "LOUEUSE", cp : "60380" },
{ code : "60372", intitule : "LUCHY", cp : "60360" },
{ code : "60373", intitule : "MACHEMONT", cp : "60150" },
{ code : "60374", intitule : "MAIGNELAY MONTIGNY", cp : "60420" },
{ code : "60375", intitule : "MAIMBEVILLE", cp : "60600" },
{ code : "60376", intitule : "MAISONCELLE ST PIERRE", cp : "60112" },
{ code : "60377", intitule : "MAISONCELLE TUILERIE", cp : "60480" },
{ code : "60378", intitule : "MAREST SUR MATZ", cp : "60490" },
{ code : "60379", intitule : "MAREUIL LA MOTTE", cp : "60490" },
{ code : "60380", intitule : "MAREUIL SUR OURCQ", cp : "60890" },
{ code : "60381", intitule : "MARGNY AUX CERISES", cp : "60310" },
{ code : "60382", intitule : "MARGNY LES COMPIEGNE", cp : "60280" },
{ code : "60383", intitule : "MARGNY SUR MATZ", cp : "60490" },
{ code : "60385", intitule : "MAROLLES", cp : "60890" },
{ code : "60386", intitule : "MARQUEGLISE", cp : "60490" },
{ code : "60387", intitule : "MARSEILLE EN BEAUVAISIS", cp : "60690" },
{ code : "60388", intitule : "MARTINCOURT", cp : "60112" },
{ code : "60389", intitule : "MAUCOURT", cp : "60640" },
{ code : "60390", intitule : "MAULERS", cp : "60480" },
{ code : "60391", intitule : "MAYSEL", cp : "60660" },
{ code : "60392", intitule : "MELICOCQ", cp : "60150" },
{ code : "60393", intitule : "MELLO", cp : "60660" },
{ code : "60394", intitule : "MENEVILLERS", cp : "60420" },
{ code : "60395", intitule : "MERU", cp : "60110" },
{ code : "60396", intitule : "MERY LA BATAILLE", cp : "60420" },
{ code : "60397", intitule : "LE MESNIL CONTEVILLE", cp : "60210" },
{ code : "60398", intitule : "LE MESNIL EN THELLE", cp : "60530" },
{ code : "60399", intitule : "LE MESNIL ST FIRMIN", cp : "60120" },
{ code : "60400", intitule : "LE MESNIL SUR BULLES", cp : "60130" },
{ code : "60401", intitule : "LE MESNIL THERIBUS", cp : "60240" },
{ code : "60402", intitule : "LE MEUX", cp : "60880" },
{ code : "60403", intitule : "MILLY SUR THERAIN", cp : "60112" },
{ code : "60404", intitule : "MOGNEVILLE", cp : "60140" },
{ code : "60405", intitule : "MOLIENS", cp : "60220" },
{ code : "60406", intitule : "MONCEAUX", cp : "60940" },
{ code : "60407", intitule : "MONCEAUX L ABBAYE", cp : "60220" },
{ code : "60408", intitule : "MONCHY HUMIERES", cp : "60113" },
{ code : "60409", intitule : "MONCHY ST ELOI", cp : "60290" },
{ code : "60410", intitule : "MONDESCOURT", cp : "60400" },
{ code : "60411", intitule : "MONNEVILLE", cp : "60240" },
{ code : "60412", intitule : "MONTAGNY EN VEXIN", cp : "60240" },
{ code : "60413", intitule : "MONTAGNY STE FELICITE", cp : "60950" },
{ code : "60414", intitule : "MONTATAIRE", cp : "60160" },
{ code : "60415", intitule : "MONTEPILLOY", cp : "60810" },
{ code : "60416", intitule : "MONTGERAIN", cp : "60420" },
{ code : "60418", intitule : "MONTIERS", cp : "60190" },
{ code : "60420", intitule : "MONTJAVOULT", cp : "60240" },
{ code : "60421", intitule : "MONT L EVEQUE", cp : "60300" },
{ code : "60422", intitule : "MONTLOGNON", cp : "60300" },
{ code : "60423", intitule : "MONTMACQ", cp : "60150" },
{ code : "60424", intitule : "MONTMARTIN", cp : "60190" },
{ code : "60425", intitule : "MONTREUIL SUR BRECHE", cp : "60480" },
{ code : "60426", intitule : "MONTREUIL SUR THERAIN", cp : "60134" },
{ code : "60427", intitule : "MONTS", cp : "60119" },
{ code : "60428", intitule : "LE MONT ST ADRIEN", cp : "60650" },
{ code : "60429", intitule : "MORANGLES", cp : "60530" },
{ code : "60430", intitule : "MORIENVAL", cp : "60127" },
{ code : "60431", intitule : "MORLINCOURT", cp : "60400" },
{ code : "60432", intitule : "MORTEFONTAINE", cp : "60128" },
{ code : "60433", intitule : "MORTEFONTAINE EN THELLE", cp : "60570" },
{ code : "60434", intitule : "MORTEMER", cp : "60490" },
{ code : "60435", intitule : "MORVILLERS", cp : "60380" },
{ code : "60436", intitule : "MORY MONTCRUX", cp : "60120" },
{ code : "60437", intitule : "MOUCHY LE CHATEL", cp : "60250" },
{ code : "60438", intitule : "MOULIN SOUS TOUVENT", cp : "60350" },
{ code : "60439", intitule : "MOUY", cp : "60250" },
{ code : "60440", intitule : "MOYENNEVILLE", cp : "60190" },
{ code : "60441", intitule : "MOYVILLERS", cp : "60190" },
{ code : "60442", intitule : "MUIDORGE", cp : "60480" },
{ code : "60443", intitule : "MUIRANCOURT", cp : "60640" },
{ code : "60444", intitule : "MUREAUMONT", cp : "60220" },
{ code : "60445", intitule : "NAMPCEL", cp : "60400" },
{ code : "60446", intitule : "NANTEUIL LE HAUDOUIN", cp : "60440" },
{ code : "60447", intitule : "NERY", cp : "60320" },
{ code : "60448", intitule : "NEUFCHELLES", cp : "60890" },
{ code : "60449", intitule : "NEUFVY SUR ARONDE", cp : "60190" },
{ code : "60450", intitule : "NEUILLY EN THELLE", cp : "60530" },
{ code : "60451", intitule : "NEUILLY SOUS CLERMONT", cp : "60290" },
{ code : "60452", intitule : "NEUVILLE BOSC", cp : "60119" },
{ code : "60453", intitule : "LA NEUVILLE D AUMONT", cp : "60790" },
{ code : "60454", intitule : "LA NEUVILLE EN HEZ", cp : "60510" },
{ code : "60455", intitule : "LA NEUVILLE GARNIER", cp : "60390" },
{ code : "60456", intitule : "LA NEUVILLE ROY", cp : "60190" },
{ code : "60457", intitule : "LA NEUVILLE ST PIERRE", cp : "60480" },
{ code : "60458", intitule : "LA NEUVILLE SUR OUDEUIL", cp : "60690" },
{ code : "60459", intitule : "LA NEUVILLE SUR RESSONS", cp : "60490" },
{ code : "60460", intitule : "LA NEUVILLE VAULT", cp : "60112" },
{ code : "60461", intitule : "NIVILLERS", cp : "60510" },
{ code : "60462", intitule : "NOAILLES", cp : "60430" },
{ code : "60463", intitule : "NOGENT SUR OISE", cp : "60180" },
{ code : "60464", intitule : "NOINTEL", cp : "60840" },
{ code : "60465", intitule : "NOIREMONT", cp : "60480" },
{ code : "60466", intitule : "NOROY", cp : "60130" },
{ code : "60468", intitule : "NOURARD LE FRANC", cp : "60130" },
{ code : "60469", intitule : "NOVILLERS", cp : "60730" },
{ code : "60470", intitule : "NOYERS ST MARTIN", cp : "60480" },
{ code : "60471", intitule : "NOYON", cp : "60400" },
{ code : "60472", intitule : "OFFOY", cp : "60210" },
{ code : "60473", intitule : "OGNES", cp : "60440" },
{ code : "60474", intitule : "OGNOLLES", cp : "60310" },
{ code : "60475", intitule : "OGNON", cp : "60810" },
{ code : "60476", intitule : "OMECOURT", cp : "60220" },
{ code : "60477", intitule : "ONS EN BRAY", cp : "60650" },
{ code : "60478", intitule : "ORMOY LE DAVIEN", cp : "60620" },
{ code : "60479", intitule : "ORMOY VILLERS", cp : "60800" },
{ code : "60480", intitule : "OROER", cp : "60510" },
{ code : "60481", intitule : "ORROUY", cp : "60129" },
{ code : "60482", intitule : "ORRY LA VILLE", cp : "60560" },
{ code : "60483", intitule : "ORVILLERS SOREL", cp : "60490" },
{ code : "60484", intitule : "OUDEUIL", cp : "60860" },
{ code : "60485", intitule : "OURSEL MAISON", cp : "60480" },
{ code : "60486", intitule : "PAILLART", cp : "60120" },
{ code : "60487", intitule : "PARNES", cp : "60240" },
{ code : "60488", intitule : "PASSEL", cp : "60400" },
{ code : "60489", intitule : "PEROY LES GOMBRIES", cp : "60440" },
{ code : "60490", intitule : "PIERREFITTE EN BEAUVAISIS", cp : "60112" },
{ code : "60491", intitule : "PIERREFONDS", cp : "60350" },
{ code : "60492", intitule : "PIMPREZ", cp : "60170" },
{ code : "60493", intitule : "PISSELEU", cp : "60860" },
{ code : "60494", intitule : "PLAILLY", cp : "60128" },
{ code : "60495", intitule : "PLAINVAL", cp : "60130" },
{ code : "60496", intitule : "PLAINVILLE", cp : "60120" },
{ code : "60497", intitule : "LE PLESSIER SUR BULLES", cp : "60130" },
{ code : "60498", intitule : "LE PLESSIER SUR ST JUST", cp : "60130" },
{ code : "60499", intitule : "PLESSIS DE ROYE", cp : "60310" },
{ code : "60500", intitule : "LE PLESSIS BELLEVILLE", cp : "60330" },
{ code : "60501", intitule : "LE PLESSIS BRION", cp : "60150" },
{ code : "60502", intitule : "LE PLESSIS PATTE D OIE", cp : "60640" },
{ code : "60503", intitule : "LE PLOYRON", cp : "60420" },
{ code : "60504", intitule : "PONCHON", cp : "60430" },
{ code : "60505", intitule : "PONTARME", cp : "60520" },
{ code : "60506", intitule : "PONT L EVEQUE", cp : "60400" },
{ code : "60507", intitule : "PONTOISE LES NOYON", cp : "60400" },
{ code : "60508", intitule : "PONTPOINT", cp : "60700" },
{ code : "60509", intitule : "PONT STE MAXENCE", cp : "60700" },
{ code : "60510", intitule : "PORCHEUX", cp : "60390" },
{ code : "60511", intitule : "PORQUERICOURT", cp : "60400" },
{ code : "60512", intitule : "POUILLY", cp : "60790" },
{ code : "60513", intitule : "PRECY SUR OISE", cp : "60460" },
{ code : "60514", intitule : "PREVILLERS", cp : "60360" },
{ code : "60515", intitule : "PRONLEROY", cp : "60190" },
{ code : "60516", intitule : "PUISEUX EN BRAY", cp : "60850" },
{ code : "60517", intitule : "PUISEUX LE HAUBERGER", cp : "60540" },
{ code : "60518", intitule : "PUITS LA VALLEE", cp : "60480" },
{ code : "60519", intitule : "QUESMY", cp : "60640" },
{ code : "60520", intitule : "LE QUESNEL AUBRY", cp : "60480" },
{ code : "60521", intitule : "QUINCAMPOIX FLEUZY", cp : "60220" },
{ code : "60522", intitule : "QUINQUEMPOIX", cp : "60130" },
{ code : "60523", intitule : "RAINVILLERS", cp : "60155" },
{ code : "60524", intitule : "RANTIGNY", cp : "60290" },
{ code : "60525", intitule : "RARAY", cp : "60810" },
{ code : "60526", intitule : "RAVENEL", cp : "60130" },
{ code : "60527", intitule : "REEZ FOSSE MARTIN", cp : "60620" },
{ code : "60528", intitule : "REILLY", cp : "60240" },
{ code : "60529", intitule : "REMECOURT", cp : "60600" },
{ code : "60530", intitule : "REMERANGLES", cp : "60510" },
{ code : "60531", intitule : "REMY", cp : "60190" },
{ code : "60532", intitule : "RESSONS L ABBAYE", cp : "60790" },
{ code : "60533", intitule : "RESSONS SUR MATZ", cp : "60490" },
{ code : "60534", intitule : "RETHONDES", cp : "60153" },
{ code : "60535", intitule : "REUIL SUR BRECHE", cp : "60480" },
{ code : "60536", intitule : "RHUIS", cp : "60410" },
{ code : "60537", intitule : "RIBECOURT DRESLINCOURT", cp : "60170" },
{ code : "60537", intitule : "RIBECOURT DRESLINCOURT", cp : "60170" },
{ code : "60538", intitule : "RICQUEBOURG", cp : "60490" },
{ code : "60539", intitule : "RIEUX", cp : "60870" },
{ code : "60540", intitule : "RIVECOURT", cp : "60126" },
{ code : "60541", intitule : "ROBERVAL", cp : "60410" },
{ code : "60542", intitule : "ROCHY CONDE", cp : "60510" },
{ code : "60543", intitule : "ROCQUEMONT", cp : "60800" },
{ code : "60544", intitule : "ROCQUENCOURT", cp : "60120" },
{ code : "60545", intitule : "ROMESCAMPS", cp : "60220" },
{ code : "60546", intitule : "ROSIERES", cp : "60440" },
{ code : "60547", intitule : "ROSOY", cp : "60140" },
{ code : "60548", intitule : "ROSOY EN MULTIEN", cp : "60620" },
{ code : "60549", intitule : "ROTANGY", cp : "60360" },
{ code : "60550", intitule : "ROTHOIS", cp : "60690" },
{ code : "60551", intitule : "ROUSSELOY", cp : "60660" },
{ code : "60552", intitule : "ROUVILLE", cp : "60800" },
{ code : "60553", intitule : "ROUVILLERS", cp : "60190" },
{ code : "60554", intitule : "ROUVRES EN MULTIEN", cp : "60620" },
{ code : "60555", intitule : "ROUVROY LES MERLES", cp : "60120" },
{ code : "60556", intitule : "ROYAUCOURT", cp : "60420" },
{ code : "60557", intitule : "ROY BOISSY", cp : "60690" },
{ code : "60558", intitule : "ROYE SUR MATZ", cp : "60310" },
{ code : "60559", intitule : "LA RUE ST PIERRE", cp : "60510" },
{ code : "60560", intitule : "RULLY", cp : "60810" },
{ code : "60561", intitule : "RUSSY BEMONT", cp : "60117" },
{ code : "60562", intitule : "SACY LE GRAND", cp : "60700" },
{ code : "60563", intitule : "SACY LE PETIT", cp : "60190" },
{ code : "60564", intitule : "SAINS MORAINVILLERS", cp : "60420" },
{ code : "60565", intitule : "ST ANDRE FARIVILLERS", cp : "60480" },
{ code : "60566", intitule : "ST ARNOULT", cp : "60220" },
{ code : "60567", intitule : "ST AUBIN EN BRAY", cp : "60650" },
{ code : "60567", intitule : "ST AUBIN EN BRAY", cp : "60650" },
{ code : "60568", intitule : "ST AUBIN SOUS ERQUERY", cp : "60600" },
{ code : "60569", intitule : "ST CREPIN AUX BOIS", cp : "60170" },
{ code : "60570", intitule : "ST CREPIN IBOUVILLERS", cp : "60149" },
{ code : "60570", intitule : "ST CREPIN IBOUVILLERS", cp : "60790" },
{ code : "60571", intitule : "ST DENISCOURT", cp : "60380" },
{ code : "60572", intitule : "ST ETIENNE ROILAYE", cp : "60350" },
{ code : "60573", intitule : "STE EUSOYE", cp : "60480" },
{ code : "60574", intitule : "ST FELIX", cp : "60370" },
{ code : "60575", intitule : "STE GENEVIEVE", cp : "60730" },
{ code : "60576", intitule : "ST GERMAIN LA POTERIE", cp : "60650" },
{ code : "60577", intitule : "ST GERMER DE FLY", cp : "60850" },
{ code : "60578", intitule : "SAINTINES", cp : "60410" },
{ code : "60579", intitule : "ST JEAN AUX BOIS", cp : "60350" },
{ code : "60581", intitule : "ST JUST EN CHAUSSEE", cp : "60130" },
{ code : "60582", intitule : "ST LEGER AUX BOIS", cp : "60170" },
{ code : "60583", intitule : "ST LEGER EN BRAY", cp : "60155" },
{ code : "60584", intitule : "ST LEU D ESSERENT", cp : "60340" },
{ code : "60585", intitule : "ST MARTIN AUX BOIS", cp : "60420" },
{ code : "60586", intitule : "ST MARTIN LE NOEUD", cp : "60000" },
{ code : "60587", intitule : "ST MARTIN LONGUEAU", cp : "60700" },
{ code : "60588", intitule : "ST MAUR", cp : "60210" },
{ code : "60589", intitule : "ST MAXIMIN", cp : "60740" },
{ code : "60590", intitule : "ST OMER EN CHAUSSEE", cp : "60860" },
{ code : "60591", intitule : "ST PAUL", cp : "60650" },
{ code : "60592", intitule : "ST PIERRE ES CHAMPS", cp : "60850" },
{ code : "60593", intitule : "ST PIERRE LES BITRY", cp : "60350" },
{ code : "60594", intitule : "ST QUENTIN DES PRES", cp : "60380" },
{ code : "60595", intitule : "ST REMY EN L EAU", cp : "60130" },
{ code : "60596", intitule : "ST SAMSON LA POTERIE", cp : "60220" },
{ code : "60597", intitule : "ST SAUVEUR", cp : "60320" },
{ code : "60598", intitule : "ST SULPICE", cp : "60430" },
{ code : "60599", intitule : "ST THIBAULT", cp : "60210" },
{ code : "60600", intitule : "ST VAAST DE LONGMONT", cp : "60410" },
{ code : "60601", intitule : "ST VAAST LES MELLO", cp : "60660" },
{ code : "60602", intitule : "ST VALERY", cp : "60220" },
{ code : "60603", intitule : "SALENCY", cp : "60400" },
{ code : "60604", intitule : "SARCUS", cp : "60210" },
{ code : "60605", intitule : "SARNOIS", cp : "60210" },
{ code : "60608", intitule : "LE SAULCHOY", cp : "60360" },
{ code : "60609", intitule : "SAVIGNIES", cp : "60650" },
{ code : "60610", intitule : "SEMPIGNY", cp : "60400" },
{ code : "60611", intitule : "SENANTES", cp : "60650" },
{ code : "60612", intitule : "SENLIS", cp : "60300" },
{ code : "60613", intitule : "SENOTS", cp : "60240" },
{ code : "60614", intitule : "SERANS", cp : "60240" },
{ code : "60615", intitule : "SEREVILLERS", cp : "60120" },
{ code : "60616", intitule : "SERIFONTAINE", cp : "60590" },
{ code : "60617", intitule : "SERMAIZE", cp : "60400" },
{ code : "60618", intitule : "SERY MAGNEVAL", cp : "60800" },
{ code : "60619", intitule : "SILLY LE LONG", cp : "60330" },
{ code : "60620", intitule : "SILLY TILLARD", cp : "60430" },
{ code : "60621", intitule : "SOLENTE", cp : "60310" },
{ code : "60622", intitule : "SOMMEREUX", cp : "60210" },
{ code : "60623", intitule : "SONGEONS", cp : "60380" },
{ code : "60624", intitule : "SULLY", cp : "60380" },
{ code : "60625", intitule : "SUZOY", cp : "60400" },
{ code : "60626", intitule : "TALMONTIERS", cp : "60590" },
{ code : "60627", intitule : "TARTIGNY", cp : "60120" },
{ code : "60628", intitule : "THERDONNE", cp : "60510" },
{ code : "60629", intitule : "THERINES", cp : "60380" },
{ code : "60630", intitule : "THIBIVILLERS", cp : "60240" },
{ code : "60631", intitule : "THIERS SUR THEVE", cp : "60520" },
{ code : "60632", intitule : "THIESCOURT", cp : "60310" },
{ code : "60633", intitule : "THIEULOY ST ANTOINE", cp : "60210" },
{ code : "60634", intitule : "THIEUX", cp : "60480" },
{ code : "60635", intitule : "THIVERNY", cp : "60160" },
{ code : "60636", intitule : "THOUROTTE", cp : "60150" },
{ code : "60637", intitule : "THURY EN VALOIS", cp : "60890" },
{ code : "60638", intitule : "THURY SOUS CLERMONT", cp : "60250" },
{ code : "60639", intitule : "TILLE", cp : "60000" },
{ code : "60640", intitule : "TOURLY", cp : "60240" },
{ code : "60641", intitule : "TRACY LE MONT", cp : "60170" },
{ code : "60641", intitule : "TRACY LE MONT", cp : "60170" },
{ code : "60642", intitule : "TRACY LE VAL", cp : "60170" },
{ code : "60643", intitule : "TRICOT", cp : "60420" },
{ code : "60644", intitule : "TRIE CHATEAU", cp : "60590" },
{ code : "60645", intitule : "TRIE LA VILLE", cp : "60590" },
{ code : "60646", intitule : "TROISSEREUX", cp : "60112" },
{ code : "60647", intitule : "TROSLY BREUIL", cp : "60350" },
{ code : "60648", intitule : "TROUSSENCOURT", cp : "60120" },
{ code : "60649", intitule : "TROUSSURES", cp : "60390" },
{ code : "60650", intitule : "TRUMILLY", cp : "60800" },
{ code : "60651", intitule : "ULLY ST GEORGES", cp : "60730" },
{ code : "60652", intitule : "VALDAMPIERRE", cp : "60790" },
{ code : "60653", intitule : "VALESCOURT", cp : "60130" },
{ code : "60654", intitule : "VANDELICOURT", cp : "60490" },
{ code : "60655", intitule : "VARESNES", cp : "60400" },
{ code : "60656", intitule : "VARINFROY", cp : "60890" },
{ code : "60657", intitule : "VAUCHELLES", cp : "60400" },
{ code : "60658", intitule : "VAUCIENNES", cp : "60117" },
{ code : "60659", intitule : "VAUDANCOURT", cp : "60240" },
{ code : "60660", intitule : "LE VAUMAIN", cp : "60590" },
{ code : "60661", intitule : "VAUMOISE", cp : "60117" },
{ code : "60662", intitule : "LE VAUROUX", cp : "60390" },
{ code : "60663", intitule : "VELENNES", cp : "60510" },
{ code : "60664", intitule : "VENDEUIL CAPLY", cp : "60120" },
{ code : "60665", intitule : "VENETTE", cp : "60280" },
{ code : "60666", intitule : "VER SUR LAUNETTE", cp : "60950" },
{ code : "60667", intitule : "VERBERIE", cp : "60410" },
{ code : "60668", intitule : "VERDEREL LES SAUQUEUSE", cp : "60112" },
{ code : "60668", intitule : "VERDEREL LES SAUQUEUSE", cp : "60112" },
{ code : "60669", intitule : "VERDERONNE", cp : "60140" },
{ code : "60670", intitule : "VERNEUIL EN HALATTE", cp : "60550" },
{ code : "60671", intitule : "VERSIGNY", cp : "60440" },
{ code : "60672", intitule : "VEZ", cp : "60117" },
{ code : "60673", intitule : "VIEFVILLERS", cp : "60360" },
{ code : "60674", intitule : "VIEUX MOULIN", cp : "60350" },
{ code : "60675", intitule : "VIGNEMONT", cp : "60162" },
{ code : "60676", intitule : "VILLE", cp : "60400" },
{ code : "60677", intitule : "VILLEMBRAY", cp : "60650" },
{ code : "60678", intitule : "VILLENEUVE LES SABLONS", cp : "60175" },
{ code : "60679", intitule : "LA VILLENEUVE SOUS THURY", cp : "60890" },
{ code : "60680", intitule : "VILLENEUVE SUR VERBERIE", cp : "60410" },
{ code : "60681", intitule : "VILLERS ST BARTHELEMY", cp : "60650" },
{ code : "60682", intitule : "VILLERS ST FRAMBOURG", cp : "60810" },
{ code : "60683", intitule : "VILLERS ST GENEST", cp : "60620" },
{ code : "60684", intitule : "VILLERS ST PAUL", cp : "60870" },
{ code : "60685", intitule : "VILLERS ST SEPULCRE", cp : "60134" },
{ code : "60686", intitule : "VILLERS SOUS ST LEU", cp : "60340" },
{ code : "60687", intitule : "VILLERS SUR AUCHY", cp : "60650" },
{ code : "60688", intitule : "VILLERS SUR BONNIERES", cp : "60860" },
{ code : "60689", intitule : "VILLERS SUR COUDUN", cp : "60150" },
{ code : "60690", intitule : "VILLERS SUR TRIE", cp : "60590" },
{ code : "60691", intitule : "VILLERS VERMONT", cp : "60380" },
{ code : "60692", intitule : "VILLERS VICOMTE", cp : "60120" },
{ code : "60693", intitule : "VILLESELVE", cp : "60640" },
{ code : "60694", intitule : "VILLOTRAN", cp : "60390" },
{ code : "60695", intitule : "VINEUIL ST FIRMIN", cp : "60500" },
{ code : "60697", intitule : "VROCOURT", cp : "60112" },
{ code : "60698", intitule : "WACQUEMOULIN", cp : "60420" },
{ code : "60699", intitule : "WAMBEZ", cp : "60380" },
{ code : "60700", intitule : "WARLUIS", cp : "60430" },
{ code : "60701", intitule : "WAVIGNIES", cp : "60130" },
{ code : "60702", intitule : "WELLES PERENNES", cp : "60420" },
{ code : "60703", intitule : "AUX MARAIS", cp : "60000" },
{ code : "61001", intitule : "ALENCON", cp : "61000" },
{ code : "61002", intitule : "ALMENECHES", cp : "61570" },
{ code : "61005", intitule : "APPENAI SOUS BELLEME", cp : "61130" },
{ code : "61006", intitule : "ARGENTAN", cp : "61200" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61430" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61007", intitule : "ATHIS VAL DE ROUVRE", cp : "61100" },
{ code : "61008", intitule : "AUBE", cp : "61270" },
{ code : "61009", intitule : "AUBRY EN EXMES", cp : "61160" },
{ code : "61010", intitule : "AUBRY LE PANTHOU", cp : "61120" },
{ code : "61011", intitule : "AUBUSSON", cp : "61100" },
{ code : "61012", intitule : "AUGUAISE", cp : "61270" },
{ code : "61013", intitule : "AUNAY LES BOIS", cp : "61500" },
{ code : "61014", intitule : "AUNOU LE FAUCON", cp : "61200" },
{ code : "61015", intitule : "AUNOU SUR ORNE", cp : "61500" },
{ code : "61017", intitule : "LES AUTHIEUX DU PUITS", cp : "61240" },
{ code : "61018", intitule : "AVERNES ST GOURGON", cp : "61470" },
{ code : "61019", intitule : "AVERNES SOUS EXMES", cp : "61310" },
{ code : "61020", intitule : "AVOINE", cp : "61150" },
{ code : "61021", intitule : "AVRILLY", cp : "61700" },
{ code : "61023", intitule : "BAILLEUL", cp : "61160" },
{ code : "61024", intitule : "BANVOU", cp : "61450" },
{ code : "61026", intitule : "BARVILLE", cp : "61170" },
{ code : "61028", intitule : "BAZOCHES AU HOULME", cp : "61210" },
{ code : "61029", intitule : "BAZOCHES SUR HOENE", cp : "61560" },
{ code : "61030", intitule : "LA BAZOQUE", cp : "61100" },
{ code : "61032", intitule : "BEAUFAI", cp : "61270" },
{ code : "61034", intitule : "BEAULIEU", cp : "61190" },
{ code : "61035", intitule : "BEAUVAIN", cp : "61600" },
{ code : "61036", intitule : "BELFONDS", cp : "61500" },
{ code : "61037", intitule : "BELLAVILLIERS", cp : "61360" },
{ code : "61038", intitule : "BELLEME", cp : "61130" },
{ code : "61039", intitule : "LA BELLIERE", cp : "61570" },
{ code : "61040", intitule : "BELLOU EN HOULME", cp : "61220" },
{ code : "61041", intitule : "BELLOU LE TRICHARD", cp : "61130" },
{ code : "61043", intitule : "BERD HUIS", cp : "61340" },
{ code : "61044", intitule : "BERJOU", cp : "61430" },
{ code : "61046", intitule : "BIZOU", cp : "61290" },
{ code : "61048", intitule : "BOECE", cp : "61560" },
{ code : "61049", intitule : "BOISSEI LA LANDE", cp : "61570" },
{ code : "61050", intitule : "COUR MAUGIS SUR HUISNE", cp : "61110" },
{ code : "61050", intitule : "COUR MAUGIS SUR HUISNE", cp : "61110" },
{ code : "61050", intitule : "COUR MAUGIS SUR HUISNE", cp : "61340" },
{ code : "61050", intitule : "COUR MAUGIS SUR HUISNE", cp : "61110" },
{ code : "61051", intitule : "BOITRON", cp : "61500" },
{ code : "61052", intitule : "BONNEFOI", cp : "61270" },
{ code : "61053", intitule : "BONSMOULINS", cp : "61380" },
{ code : "61054", intitule : "LE BOSC RENOULT", cp : "61470" },
{ code : "61055", intitule : "BOUCE", cp : "61570" },
{ code : "61056", intitule : "LE BOUILLON", cp : "61500" },
{ code : "61057", intitule : "LE BOURG ST LEONARD", cp : "61310" },
{ code : "61060", intitule : "BRETHEL", cp : "61270" },
{ code : "61061", intitule : "BRETONCELLES", cp : "61110" },
{ code : "61062", intitule : "BRIEUX", cp : "61160" },
{ code : "61063", intitule : "BRIOUZE", cp : "61220" },
{ code : "61064", intitule : "BRULLEMAIL", cp : "61390" },
{ code : "61066", intitule : "BURE", cp : "61170" },
{ code : "61067", intitule : "BURES", cp : "61170" },
{ code : "61068", intitule : "BURSARD", cp : "61500" },
{ code : "61069", intitule : "CAHAN", cp : "61430" },
{ code : "61070", intitule : "CALIGNY", cp : "61100" },
{ code : "61071", intitule : "CAMEMBERT", cp : "61120" },
{ code : "61072", intitule : "CANAPVILLE", cp : "61120" },
{ code : "61074", intitule : "CARROUGES", cp : "61320" },
{ code : "61075", intitule : "CEAUCE", cp : "61330" },
{ code : "61076", intitule : "LE CERCUEIL", cp : "61500" },
{ code : "61077", intitule : "CERISE", cp : "61000" },
{ code : "61078", intitule : "CERISY BELLE ETOILE", cp : "61100" },
{ code : "61079", intitule : "CETON", cp : "61260" },
{ code : "61080", intitule : "CHAHAINS", cp : "61320" },
{ code : "61081", intitule : "CHAILLOUE", cp : "61500" },
{ code : "61081", intitule : "CHAILLOUE", cp : "61240" },
{ code : "61081", intitule : "CHAILLOUE", cp : "61500" },
{ code : "61082", intitule : "LE CHALANGE", cp : "61390" },
{ code : "61083", intitule : "CHAMBOIS", cp : "61160" },
{ code : "61084", intitule : "CHAMPCERIE", cp : "61210" },
{ code : "61085", intitule : "LE CHAMP DE LA PIERRE", cp : "61320" },
{ code : "61086", intitule : "LES CHAMPEAUX", cp : "61120" },
{ code : "61087", intitule : "CHAMPEAUX SUR SARTHE", cp : "61560" },
{ code : "61088", intitule : "CHAMP HAUT", cp : "61240" },
{ code : "61089", intitule : "CHAMPOSOULT", cp : "61120" },
{ code : "61091", intitule : "CHAMPSECRET", cp : "61700" },
{ code : "61092", intitule : "CHANDAI", cp : "61300" },
{ code : "61093", intitule : "CHANU", cp : "61800" },
{ code : "61094", intitule : "LA CHAPELLE AU MOINE", cp : "61100" },
{ code : "61095", intitule : "LA CHAPELLE BICHE", cp : "61100" },
{ code : "61096", intitule : "RIVES D ANDAINE", cp : "61410" },
{ code : "61096", intitule : "RIVES D ANDAINE", cp : "61410" },
{ code : "61096", intitule : "RIVES D ANDAINE", cp : "61140" },
{ code : "61096", intitule : "RIVES D ANDAINE", cp : "61140" },
{ code : "61097", intitule : "LA CHAPELLE MONTLIGEON", cp : "61400" },
{ code : "61098", intitule : "LA CHAPELLE PRES SEES", cp : "61500" },
{ code : "61099", intitule : "LA CHAPELLE SOUEF", cp : "61130" },
{ code : "61100", intitule : "LA CHAPELLE VIEL", cp : "61270" },
{ code : "61101", intitule : "LE CHATEAU D ALMENECHES", cp : "61570" },
{ code : "61102", intitule : "LE CHATELLIER", cp : "61450" },
{ code : "61103", intitule : "CHAUMONT", cp : "61230" },
{ code : "61104", intitule : "LA CHAUX", cp : "61600" },
{ code : "61105", intitule : "CHEMILLI", cp : "61360" },
{ code : "61107", intitule : "CIRAL", cp : "61320" },
{ code : "61108", intitule : "CISAI ST AUBIN", cp : "61230" },
{ code : "61110", intitule : "LA COCHERE", cp : "61310" },
{ code : "61111", intitule : "COLOMBIERS", cp : "61250" },
{ code : "61113", intitule : "COMBLOT", cp : "61400" },
{ code : "61114", intitule : "COMMEAUX", cp : "61200" },
{ code : "61116", intitule : "SABLONS SUR HUISNE", cp : "61110" },
{ code : "61116", intitule : "SABLONS SUR HUISNE", cp : "61110" },
{ code : "61116", intitule : "SABLONS SUR HUISNE", cp : "61110" },
{ code : "61117", intitule : "CONDE SUR SARTHE", cp : "61250" },
{ code : "61118", intitule : "CORBON", cp : "61400" },
{ code : "61120", intitule : "COUDEHARD", cp : "61160" },
{ code : "61121", intitule : "COULIMER", cp : "61360" },
{ code : "61122", intitule : "COULMER", cp : "61230" },
{ code : "61123", intitule : "COULONCES", cp : "61160" },
{ code : "61124", intitule : "LA COULONCHE", cp : "61220" },
{ code : "61126", intitule : "COULONGES SUR SARTHE", cp : "61170" },
{ code : "61129", intitule : "COURGEON", cp : "61400" },
{ code : "61130", intitule : "COURGEOUT", cp : "61560" },
{ code : "61131", intitule : "COURMENIL", cp : "61310" },
{ code : "61133", intitule : "COURTOMER", cp : "61390" },
{ code : "61137", intitule : "CRAMENIL", cp : "61220" },
{ code : "61138", intitule : "CROISILLES", cp : "61230" },
{ code : "61139", intitule : "CROUTTES", cp : "61120" },
{ code : "61140", intitule : "CRULAI", cp : "61300" },
{ code : "61141", intitule : "CUISSAI", cp : "61250" },
{ code : "61142", intitule : "DAME MARIE", cp : "61130" },
{ code : "61143", intitule : "DAMIGNY", cp : "61250" },
{ code : "61145", intitule : "DOMFRONT EN POIRAIE", cp : "61700" },
{ code : "61145", intitule : "DOMFRONT EN POIRAIE", cp : "61700" },
{ code : "61145", intitule : "DOMFRONT EN POIRAIE", cp : "61700" },
{ code : "61146", intitule : "DOMPIERRE", cp : "61700" },
{ code : "61148", intitule : "DURCET", cp : "61100" },
{ code : "61149", intitule : "ECHALOU", cp : "61440" },
{ code : "61150", intitule : "ECHAUFFOUR", cp : "61370" },
{ code : "61151", intitule : "ECORCEI", cp : "61270" },
{ code : "61152", intitule : "ECORCHES", cp : "61160" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61153", intitule : "ECOUCHE LES VALLEES", cp : "61150" },
{ code : "61154", intitule : "EPERRAIS", cp : "61400" },
{ code : "61156", intitule : "ESSAY", cp : "61500" },
{ code : "61157", intitule : "EXMES", cp : "61310" },
{ code : "61158", intitule : "FAVEROLLES", cp : "61600" },
{ code : "61159", intitule : "FAY", cp : "61390" },
{ code : "61160", intitule : "FEINGS", cp : "61400" },
{ code : "61161", intitule : "FEL", cp : "61160" },
{ code : "61162", intitule : "LA FERRIERE AU DOYEN", cp : "61380" },
{ code : "61163", intitule : "LA FERRIERE AUX ETANGS", cp : "61450" },
{ code : "61164", intitule : "LA FERRIERE BECHET", cp : "61500" },
{ code : "61165", intitule : "LA FERRIERE BOCHARD", cp : "61420" },
{ code : "61166", intitule : "FERRIERES LA VERRERIE", cp : "61390" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61470" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61470" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61167", intitule : "LA FERTE EN OUCHE", cp : "61550" },
{ code : "61168", intitule : "LA FERTE MACE", cp : "61600" },
{ code : "61168", intitule : "LA FERTE MACE", cp : "61410" },
{ code : "61169", intitule : "FLERS", cp : "61100" },
{ code : "61170", intitule : "FLEURE", cp : "61200" },
{ code : "61171", intitule : "FONTAINE LES BASSETS", cp : "61160" },
{ code : "61172", intitule : "FONTENAI LES LOUVETS", cp : "61420" },
{ code : "61173", intitule : "FONTENAI SUR ORNE", cp : "61200" },
{ code : "61176", intitule : "FRANCHEVILLE", cp : "61570" },
{ code : "61178", intitule : "LA FRESNAIE FAYEL", cp : "61230" },
{ code : "61180", intitule : "FRESNAY LE SAMSON", cp : "61120" },
{ code : "61181", intitule : "GACE", cp : "61230" },
{ code : "61182", intitule : "GANDELAIN", cp : "61420" },
{ code : "61183", intitule : "GAPREE", cp : "61390" },
{ code : "61187", intitule : "LES GENETTES", cp : "61270" },
{ code : "61188", intitule : "LA GENEVRAIE", cp : "61240" },
{ code : "61189", intitule : "GIEL COURTEILLES", cp : "61210" },
{ code : "61190", intitule : "GINAI", cp : "61310" },
{ code : "61192", intitule : "GODISSON", cp : "61240" },
{ code : "61193", intitule : "LA GONFRIERE", cp : "61550" },
{ code : "61194", intitule : "GOULET", cp : "61150" },
{ code : "61195", intitule : "LE GRAIS", cp : "61600" },
{ code : "61196", intitule : "LE GUE DE LA CHAINE", cp : "61130" },
{ code : "61197", intitule : "GUEPREI", cp : "61160" },
{ code : "61198", intitule : "GUERQUESALLES", cp : "61120" },
{ code : "61199", intitule : "HABLOVILLE", cp : "61210" },
{ code : "61202", intitule : "HAUTERIVE", cp : "61250" },
{ code : "61203", intitule : "HELOUP", cp : "61250" },
{ code : "61206", intitule : "L HOME CHAMONDOT", cp : "61290" },
{ code : "61207", intitule : "IGE", cp : "61130" },
{ code : "61208", intitule : "IRAI", cp : "61190" },
{ code : "61209", intitule : "JOUE DU BOIS", cp : "61320" },
{ code : "61210", intitule : "JOUE DU PLAIN", cp : "61150" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61330" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61330" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61330" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61140" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61330" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61330" },
{ code : "61211", intitule : "JUVIGNY VAL D ANDAINE", cp : "61140" },
{ code : "61212", intitule : "JUVIGNY SUR ORNE", cp : "61200" },
{ code : "61213", intitule : "LALACELLE", cp : "61320" },
{ code : "61214", intitule : "L AIGLE", cp : "61300" },
{ code : "61215", intitule : "LALEU", cp : "61170" },
{ code : "61216", intitule : "LA LANDE DE GOULT", cp : "61320" },
{ code : "61217", intitule : "LA LANDE DE LOUGE", cp : "61210" },
{ code : "61218", intitule : "LA LANDE PATRY", cp : "61100" },
{ code : "61219", intitule : "LA LANDE ST SIMEON", cp : "61100" },
{ code : "61221", intitule : "LANDIGOU", cp : "61100" },
{ code : "61222", intitule : "LANDISACQ", cp : "61100" },
{ code : "61224", intitule : "LARRE", cp : "61250" },
{ code : "61225", intitule : "LIGNERES", cp : "61240" },
{ code : "61227", intitule : "LIGNOU", cp : "61220" },
{ code : "61228", intitule : "LIVAIE", cp : "61420" },
{ code : "61229", intitule : "LOISAIL", cp : "61400" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61230", intitule : "LONGNY LES VILLAGES", cp : "61290" },
{ code : "61231", intitule : "LONGUENOE", cp : "61320" },
{ code : "61232", intitule : "LONLAY L ABBAYE", cp : "61700" },
{ code : "61233", intitule : "LONLAY LE TESSON", cp : "61600" },
{ code : "61234", intitule : "LONRAI", cp : "61250" },
{ code : "61237", intitule : "LOUGE SUR MAIRE", cp : "61150" },
{ code : "61238", intitule : "LOUVIERES EN AUGE", cp : "61160" },
{ code : "61240", intitule : "MACE", cp : "61500" },
{ code : "61241", intitule : "LA MADELEINE BOUVET", cp : "61110" },
{ code : "61242", intitule : "LE MAGE", cp : "61290" },
{ code : "61243", intitule : "MAGNY LE DESERT", cp : "61600" },
{ code : "61244", intitule : "MAHERU", cp : "61380" },
{ code : "61248", intitule : "MANTILLY", cp : "61350" },
{ code : "61251", intitule : "MARCHEMAISONS", cp : "61170" },
{ code : "61252", intitule : "MARDILLY", cp : "61230" },
{ code : "61255", intitule : "MAUVES SUR HUISNE", cp : "61400" },
{ code : "61256", intitule : "MEDAVY", cp : "61570" },
{ code : "61257", intitule : "MEHOUDIN", cp : "61410" },
{ code : "61258", intitule : "LE MELE SUR SARTHE", cp : "61170" },
{ code : "61259", intitule : "LE MENIL BERARD", cp : "61270" },
{ code : "61260", intitule : "LE MENIL DE BRIOUZE", cp : "61220" },
{ code : "61261", intitule : "LE MENIL BROUT", cp : "61250" },
{ code : "61262", intitule : "LE MENIL CIBOULT", cp : "61800" },
{ code : "61263", intitule : "MENIL ERREUX", cp : "61250" },
{ code : "61264", intitule : "MENIL FROGER", cp : "61240" },
{ code : "61265", intitule : "MENIL GONDOUIN", cp : "61210" },
{ code : "61266", intitule : "LE MENIL GUYON", cp : "61170" },
{ code : "61267", intitule : "MENIL HERMEI", cp : "61210" },
{ code : "61268", intitule : "MENIL HUBERT EN EXMES", cp : "61230" },
{ code : "61269", intitule : "MENIL HUBERT SUR ORNE", cp : "61430" },
{ code : "61271", intitule : "LE MENIL SCELLEUR", cp : "61320" },
{ code : "61272", intitule : "LE MENIL VICOMTE", cp : "61240" },
{ code : "61273", intitule : "MENIL VIN", cp : "61210" },
{ code : "61274", intitule : "LES MENUS", cp : "61290" },
{ code : "61275", intitule : "LE MERLERAULT", cp : "61240" },
{ code : "61276", intitule : "MERRI", cp : "61160" },
{ code : "61277", intitule : "LA MESNIERE", cp : "61560" },
{ code : "61278", intitule : "MESSEI", cp : "61440" },
{ code : "61279", intitule : "MIEUXCE", cp : "61250" },
{ code : "61281", intitule : "MONCY", cp : "61800" },
{ code : "61283", intitule : "MONTABARD", cp : "61160" },
{ code : "61284", intitule : "MONTCHEVREL", cp : "61170" },
{ code : "61285", intitule : "MONTGAROULT", cp : "61150" },
{ code : "61286", intitule : "MONTGAUDRY", cp : "61360" },
{ code : "61287", intitule : "MONTILLY SUR NOIREAU", cp : "61100" },
{ code : "61288", intitule : "MONTMERREI", cp : "61570" },
{ code : "61289", intitule : "MONT ORMEL", cp : "61160" },
{ code : "61290", intitule : "MONTREUIL AU HOULME", cp : "61210" },
{ code : "61291", intitule : "MONTREUIL LA CAMBE", cp : "61160" },
{ code : "61292", intitule : "MONTSECRET CLAIREFOUGERE", cp : "61800" },
{ code : "61292", intitule : "MONTSECRET CLAIREFOUGERE", cp : "61800" },
{ code : "61293", intitule : "MORTAGNE AU PERCHE", cp : "61400" },
{ code : "61294", intitule : "MORTREE", cp : "61570" },
{ code : "61295", intitule : "LA MOTTE FOUQUET", cp : "61600" },
{ code : "61297", intitule : "MOULINS LA MARCHE", cp : "61380" },
{ code : "61298", intitule : "MOULINS SUR ORNE", cp : "61200" },
{ code : "61299", intitule : "MOUSSONVILLIERS", cp : "61190" },
{ code : "61300", intitule : "MOUTIERS AU PERCHE", cp : "61110" },
{ code : "61301", intitule : "NEAUPHE SOUS ESSAI", cp : "61500" },
{ code : "61302", intitule : "NEAUPHE SUR DIVE", cp : "61160" },
{ code : "61303", intitule : "NECY", cp : "61160" },
{ code : "61304", intitule : "NEUILLY LE BISSON", cp : "61250" },
{ code : "61307", intitule : "NEUVILLE SUR TOUQUES", cp : "61120" },
{ code : "61308", intitule : "NEUVY AU HOULME", cp : "61210" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61309", intitule : "PERCHE EN NOCE", cp : "61340" },
{ code : "61310", intitule : "NONANT LE PIN", cp : "61240" },
{ code : "61311", intitule : "NORMANDEL", cp : "61190" },
{ code : "61314", intitule : "OCCAGNES", cp : "61200" },
{ code : "61315", intitule : "OMMEEL", cp : "61160" },
{ code : "61316", intitule : "OMMOY", cp : "61160" },
{ code : "61317", intitule : "ORGERES", cp : "61230" },
{ code : "61318", intitule : "ORIGNY LE BUTIN", cp : "61130" },
{ code : "61319", intitule : "ORIGNY LE ROUX", cp : "61130" },
{ code : "61321", intitule : "PACE", cp : "61250" },
{ code : "61322", intitule : "PARFONDEVAL", cp : "61400" },
{ code : "61323", intitule : "LE PAS ST L HOMER", cp : "61290" },
{ code : "61324", intitule : "PASSAIS VILLAGES", cp : "61350" },
{ code : "61324", intitule : "PASSAIS VILLAGES", cp : "61350" },
{ code : "61324", intitule : "PASSAIS VILLAGES", cp : "61350" },
{ code : "61325", intitule : "LA PERRIERE", cp : "61360" },
{ code : "61326", intitule : "PERROU", cp : "61700" },
{ code : "61327", intitule : "PERVENCHERES", cp : "61360" },
{ code : "61328", intitule : "LE PIN AU HARAS", cp : "61310" },
{ code : "61329", intitule : "LE PIN LA GARENNE", cp : "61400" },
{ code : "61330", intitule : "PLANCHES", cp : "61370" },
{ code : "61331", intitule : "LE PLANTIS", cp : "61170" },
{ code : "61332", intitule : "POINTEL", cp : "61220" },
{ code : "61333", intitule : "PONTCHARDON", cp : "61120" },
{ code : "61336", intitule : "POUVRAI", cp : "61130" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61339", intitule : "PUTANGES LE LAC", cp : "61210" },
{ code : "61341", intitule : "ECOUVES", cp : "61250" },
{ code : "61341", intitule : "ECOUVES", cp : "61250" },
{ code : "61341", intitule : "ECOUVES", cp : "61250" },
{ code : "61342", intitule : "RAI", cp : "61270" },
{ code : "61344", intitule : "RANES", cp : "61150" },
{ code : "61345", intitule : "REMALARD EN PERCHE", cp : "61110" },
{ code : "61345", intitule : "REMALARD EN PERCHE", cp : "61110" },
{ code : "61345", intitule : "REMALARD EN PERCHE", cp : "61110" },
{ code : "61346", intitule : "LE RENOUARD", cp : "61120" },
{ code : "61347", intitule : "RESENLIEU", cp : "61230" },
{ code : "61348", intitule : "REVEILLON", cp : "61400" },
{ code : "61349", intitule : "RI", cp : "61210" },
{ code : "61350", intitule : "LA ROCHE MABILE", cp : "61420" },
{ code : "61351", intitule : "ROIVILLE", cp : "61120" },
{ code : "61352", intitule : "RONAI", cp : "61160" },
{ code : "61357", intitule : "ROUPERROUX", cp : "61320" },
{ code : "61358", intitule : "SAI", cp : "61200" },
{ code : "61360", intitule : "ST AGNAN SUR SARTHE", cp : "61170" },
{ code : "61361", intitule : "ST ANDRE DE BRIOUZE", cp : "61220" },
{ code : "61362", intitule : "ST ANDRE DE MESSEI", cp : "61440" },
{ code : "61363", intitule : "ST AQUILIN DE CORBION", cp : "61380" },
{ code : "61365", intitule : "ST AUBIN D APPENAI", cp : "61170" },
{ code : "61366", intitule : "ST AUBIN DE BONNEVAL", cp : "61470" },
{ code : "61367", intitule : "ST AUBIN DE COURTERAIE", cp : "61560" },
{ code : "61369", intitule : "ST BOMER LES FORGES", cp : "61700" },
{ code : "61370", intitule : "ST BRICE", cp : "61700" },
{ code : "61371", intitule : "ST BRICE SOUS RANES", cp : "61150" },
{ code : "61372", intitule : "ST CENERI LE GEREI", cp : "61250" },
{ code : "61373", intitule : "STE CERONNE LES MORTAGNE", cp : "61380" },
{ code : "61374", intitule : "ST CHRISTOPHE DE CHAULIEU", cp : "61800" },
{ code : "61375", intitule : "BOISCHAMPRE", cp : "61570" },
{ code : "61375", intitule : "BOISCHAMPRE", cp : "61570" },
{ code : "61375", intitule : "BOISCHAMPRE", cp : "61570" },
{ code : "61375", intitule : "BOISCHAMPRE", cp : "61570" },
{ code : "61376", intitule : "ST CLAIR DE HALOUZE", cp : "61490" },
{ code : "61379", intitule : "ST CYR LA ROSIERE", cp : "61130" },
{ code : "61381", intitule : "ST DENIS SUR HUISNE", cp : "61400" },
{ code : "61382", intitule : "ST DENIS SUR SARTHON", cp : "61420" },
{ code : "61383", intitule : "ST DIDIER SOUS ECOUVES", cp : "61320" },
{ code : "61384", intitule : "ST ELLIER LES BOIS", cp : "61320" },
{ code : "61385", intitule : "ST EVROULT DE MONTFORT", cp : "61230" },
{ code : "61386", intitule : "ST EVROULT NOTRE DAME DU BOIS", cp : "61550" },
{ code : "61387", intitule : "ST FRAIMBAULT", cp : "61350" },
{ code : "61388", intitule : "ST FULGENT DES ORMES", cp : "61130" },
{ code : "61389", intitule : "STE GAUBURGE STE COLOMBE", cp : "61370" },
{ code : "61390", intitule : "ST GEORGES D ANNEBECQ", cp : "61600" },
{ code : "61391", intitule : "ST GEORGES DES GROSEILLERS", cp : "61100" },
{ code : "61392", intitule : "ST GERMAIN D AUNAY", cp : "61470" },
{ code : "61393", intitule : "ST GERMAIN DE CLAIREFEUILLE", cp : "61240" },
{ code : "61394", intitule : "ST GERMAIN DE LA COUDRE", cp : "61130" },
{ code : "61395", intitule : "ST GERMAIN DES GROIS", cp : "61110" },
{ code : "61396", intitule : "ST GERMAIN DE MARTIGNY", cp : "61560" },
{ code : "61397", intitule : "ST GERMAIN DU CORBEIS", cp : "61000" },
{ code : "61398", intitule : "ST GERMAIN LE VIEUX", cp : "61390" },
{ code : "61399", intitule : "ST GERVAIS DES SABLONS", cp : "61160" },
{ code : "61400", intitule : "ST GERVAIS DU PERRON", cp : "61500" },
{ code : "61401", intitule : "ST GILLES DES MARAIS", cp : "61700" },
{ code : "61402", intitule : "ST HILAIRE DE BRIOUZE", cp : "61220" },
{ code : "61403", intitule : "ST HILAIRE LA GERARD", cp : "61500" },
{ code : "61404", intitule : "ST HILAIRE LE CHATEL", cp : "61400" },
{ code : "61405", intitule : "ST HILAIRE SUR ERRE", cp : "61340" },
{ code : "61406", intitule : "ST HILAIRE SUR RISLE", cp : "61270" },
{ code : "61407", intitule : "STE HONORINE LA CHARDONNE", cp : "61430" },
{ code : "61408", intitule : "STE HONORINE LA GUILLAUME", cp : "61210" },
{ code : "61411", intitule : "ST JOUIN DE BLAVOU", cp : "61360" },
{ code : "61412", intitule : "ST JULIEN SUR SARTHE", cp : "61170" },
{ code : "61413", intitule : "ST LAMBERT SUR DIVE", cp : "61160" },
{ code : "61414", intitule : "ST LANGIS LES MORTAGNE", cp : "61400" },
{ code : "61415", intitule : "ST LEGER SUR SARTHE", cp : "61170" },
{ code : "61416", intitule : "ST LEONARD DES PARCS", cp : "61390" },
{ code : "61418", intitule : "ST MARD DE RENO", cp : "61400" },
{ code : "61419", intitule : "STE MARGUERITE DE CARROUGES", cp : "61320" },
{ code : "61420", intitule : "STE MARIE LA ROBERT", cp : "61320" },
{ code : "61421", intitule : "ST MARS D EGRENNE", cp : "61350" },
{ code : "61422", intitule : "LES ASPRES", cp : "61270" },
{ code : "61423", intitule : "ST MARTIN D ECUBLEI", cp : "61300" },
{ code : "61424", intitule : "ST MARTIN DES LANDES", cp : "61320" },
{ code : "61425", intitule : "ST MARTIN DES PEZERITS", cp : "61380" },
{ code : "61426", intitule : "ST MARTIN DU VIEUX BELLEME", cp : "61130" },
{ code : "61427", intitule : "ST MARTIN L AIGUILLON", cp : "61320" },
{ code : "61429", intitule : "ST MAURICE LES CHARENCEY", cp : "61190" },
{ code : "61432", intitule : "ST MICHEL TUBOEUF", cp : "61300" },
{ code : "61433", intitule : "ST NICOLAS DES BOIS", cp : "61250" },
{ code : "61435", intitule : "ST NICOLAS DE SOMMAIRE", cp : "61550" },
{ code : "61436", intitule : "STE OPPORTUNE", cp : "61100" },
{ code : "61437", intitule : "ST OUEN DE LA COUR", cp : "61130" },
{ code : "61438", intitule : "ST OUEN DE SECHEROUVRE", cp : "61560" },
{ code : "61439", intitule : "ST OUEN LE BRISOULT", cp : "61410" },
{ code : "61440", intitule : "ST OUEN SUR ITON", cp : "61300" },
{ code : "61442", intitule : "ST PATRICE DU DESERT", cp : "61600" },
{ code : "61443", intitule : "ST PAUL", cp : "61100" },
{ code : "61444", intitule : "ST PHILBERT SUR ORNE", cp : "61430" },
{ code : "61445", intitule : "ST PIERRE D ENTREMONT", cp : "61800" },
{ code : "61446", intitule : "ST PIERRE DES LOGES", cp : "61370" },
{ code : "61447", intitule : "ST PIERRE DU REGARD", cp : "61790" },
{ code : "61448", intitule : "ST PIERRE LA BRUYERE", cp : "61340" },
{ code : "61449", intitule : "ST PIERRE LA RIVIERE", cp : "61310" },
{ code : "61450", intitule : "ST QUENTIN DE BLAVOU", cp : "61360" },
{ code : "61451", intitule : "ST QUENTIN LES CHARDONNETS", cp : "61800" },
{ code : "61452", intitule : "ST ROCH SUR EGRENNE", cp : "61350" },
{ code : "61453", intitule : "ST SAUVEUR DE CARROUGES", cp : "61320" },
{ code : "61454", intitule : "STE SCOLASSE SUR SARTHE", cp : "61170" },
{ code : "61456", intitule : "ST SULPICE SUR RISLE", cp : "61300" },
{ code : "61457", intitule : "ST SYMPHORIEN DES BRUYERES", cp : "61300" },
{ code : "61459", intitule : "SAIRES LA VERRERIE", cp : "61220" },
{ code : "61460", intitule : "SAP EN AUGE", cp : "61470" },
{ code : "61460", intitule : "SAP EN AUGE", cp : "61120" },
{ code : "61461", intitule : "LE SAP ANDRE", cp : "61230" },
{ code : "61462", intitule : "SARCEAUX", cp : "61200" },
{ code : "61463", intitule : "LES MONTS D ANDAINE", cp : "61600" },
{ code : "61463", intitule : "LES MONTS D ANDAINE", cp : "61600" },
{ code : "61464", intitule : "SEES", cp : "61500" },
{ code : "61466", intitule : "LA SELLE LA FORGE", cp : "61100" },
{ code : "61467", intitule : "SEMALLE", cp : "61250" },
{ code : "61468", intitule : "SENTILLY", cp : "61150" },
{ code : "61471", intitule : "SERIGNY", cp : "61130" },
{ code : "61472", intitule : "SEVIGNY", cp : "61200" },
{ code : "61473", intitule : "SEVRAI", cp : "61150" },
{ code : "61474", intitule : "SILLY EN GOUFFERN", cp : "61310" },
{ code : "61475", intitule : "SOLIGNY LA TRAPPE", cp : "61380" },
{ code : "61476", intitule : "SURE", cp : "61360" },
{ code : "61477", intitule : "SURVIE", cp : "61310" },
{ code : "61479", intitule : "TANQUES", cp : "61150" },
{ code : "61480", intitule : "TANVILLE", cp : "61500" },
{ code : "61481", intitule : "TELLIERES LE PLESSIS", cp : "61390" },
{ code : "61482", intitule : "TESSE FROULAY", cp : "61410" },
{ code : "61483", intitule : "BAGNOLES DE L ORNE NORMANDIE", cp : "61140" },
{ code : "61483", intitule : "BAGNOLES DE L ORNE NORMANDIE", cp : "61600" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61260" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61260" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61340" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61260" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61260" },
{ code : "61484", intitule : "VAL AU PERCHE", cp : "61130" },
{ code : "61485", intitule : "TICHEVILLE", cp : "61120" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61486", intitule : "TINCHEBRAY BOCAGE", cp : "61800" },
{ code : "61487", intitule : "TORCHAMP", cp : "61330" },
{ code : "61488", intitule : "TOUQUETTES", cp : "61550" },
{ code : "61490", intitule : "TOURNAI SUR DIVE", cp : "61160" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61491", intitule : "TOUROUVRE AU PERCHE", cp : "61190" },
{ code : "61492", intitule : "TREMONT", cp : "61390" },
{ code : "61493", intitule : "LA TRINITE DES LAITIERS", cp : "61230" },
{ code : "61494", intitule : "TRUN", cp : "61160" },
{ code : "61496", intitule : "UROU ET CRENNES", cp : "61200" },
{ code : "61497", intitule : "VALFRAMBERT", cp : "61250" },
{ code : "61498", intitule : "VAUNOISE", cp : "61130" },
{ code : "61499", intitule : "LES VENTES DE BOURSE", cp : "61170" },
{ code : "61500", intitule : "LA VENTROUZE", cp : "61190" },
{ code : "61501", intitule : "VERRIERES", cp : "61110" },
{ code : "61502", intitule : "VIDAI", cp : "61360" },
{ code : "61503", intitule : "VIEUX PONT", cp : "61150" },
{ code : "61504", intitule : "VILLEBADIN", cp : "61310" },
{ code : "61505", intitule : "VILLEDIEU LES BAILLEUL", cp : "61160" },
{ code : "61507", intitule : "VILLIERS SOUS MORTAGNE", cp : "61400" },
{ code : "61508", intitule : "VIMOUTIERS", cp : "61120" },
{ code : "61510", intitule : "VITRAI SOUS LAIGLE", cp : "61300" },
{ code : "61512", intitule : "LES YVETEAUX", cp : "61210" },
{ code : "62001", intitule : "ABLAIN ST NAZAIRE", cp : "62153" },
{ code : "62002", intitule : "ABLAINZEVELLE", cp : "62116" },
{ code : "62003", intitule : "ACHEVILLE", cp : "62320" },
{ code : "62004", intitule : "ACHICOURT", cp : "62217" },
{ code : "62005", intitule : "ACHIET LE GRAND", cp : "62121" },
{ code : "62006", intitule : "ACHIET LE PETIT", cp : "62121" },
{ code : "62007", intitule : "ACQ", cp : "62144" },
{ code : "62008", intitule : "ACQUIN WESTBECOURT", cp : "62380" },
{ code : "62008", intitule : "ACQUIN WESTBECOURT", cp : "62380" },
{ code : "62009", intitule : "ADINFER", cp : "62116" },
{ code : "62010", intitule : "AFFRINGUES", cp : "62380" },
{ code : "62011", intitule : "AGNEZ LES DUISANS", cp : "62161" },
{ code : "62012", intitule : "AGNIERES", cp : "62690" },
{ code : "62013", intitule : "AGNY", cp : "62217" },
{ code : "62014", intitule : "AIRE SUR LA LYS", cp : "62120" },
{ code : "62015", intitule : "AIRON NOTRE DAME", cp : "62180" },
{ code : "62016", intitule : "AIRON ST VAAST", cp : "62180" },
{ code : "62017", intitule : "AIX EN ERGNY", cp : "62650" },
{ code : "62018", intitule : "AIX EN ISSART", cp : "62170" },
{ code : "62019", intitule : "AIX NOULETTE", cp : "62160" },
{ code : "62020", intitule : "ALEMBON", cp : "62850" },
{ code : "62021", intitule : "ALETTE", cp : "62650" },
{ code : "62022", intitule : "ALINCTHUN", cp : "62142" },
{ code : "62023", intitule : "ALLOUAGNE", cp : "62157" },
{ code : "62024", intitule : "ALQUINES", cp : "62850" },
{ code : "62025", intitule : "AMBLETEUSE", cp : "62164" },
{ code : "62026", intitule : "AMBRICOURT", cp : "62310" },
{ code : "62027", intitule : "AMBRINES", cp : "62127" },
{ code : "62028", intitule : "AMES", cp : "62190" },
{ code : "62029", intitule : "AMETTES", cp : "62260" },
{ code : "62030", intitule : "AMPLIER", cp : "62760" },
{ code : "62031", intitule : "ANDRES", cp : "62340" },
{ code : "62032", intitule : "ANGRES", cp : "62143" },
{ code : "62033", intitule : "ANNAY", cp : "62880" },
{ code : "62034", intitule : "ANNEQUIN", cp : "62149" },
{ code : "62035", intitule : "ANNEZIN", cp : "62232" },
{ code : "62036", intitule : "ANVIN", cp : "62134" },
{ code : "62037", intitule : "ANZIN ST AUBIN", cp : "62223" },
{ code : "62038", intitule : "ARDRES", cp : "62610" },
{ code : "62038", intitule : "ARDRES", cp : "62610" },
{ code : "62038", intitule : "ARDRES", cp : "62610" },
{ code : "62039", intitule : "ARLEUX EN GOHELLE", cp : "62580" },
{ code : "62040", intitule : "ARQUES", cp : "62510" },
{ code : "62041", intitule : "ARRAS", cp : "62000" },
{ code : "62042", intitule : "ATHIES", cp : "62223" },
{ code : "62043", intitule : "LES ATTAQUES", cp : "62730" },
{ code : "62044", intitule : "ATTIN", cp : "62170" },
{ code : "62045", intitule : "AUBIGNY EN ARTOIS", cp : "62690" },
{ code : "62046", intitule : "AUBIN ST VAAST", cp : "62140" },
{ code : "62047", intitule : "AUBROMETZ", cp : "62390" },
{ code : "62048", intitule : "AUCHEL", cp : "62260" },
{ code : "62049", intitule : "AUCHY AU BOIS", cp : "62190" },
{ code : "62050", intitule : "AUCHY LES HESDIN", cp : "62770" },
{ code : "62051", intitule : "AUCHY LES MINES", cp : "62138" },
{ code : "62052", intitule : "AUDEMBERT", cp : "62250" },
{ code : "62053", intitule : "AUDINCTHUN", cp : "62560" },
{ code : "62054", intitule : "AUDINGHEN", cp : "62179" },
{ code : "62055", intitule : "AUDREHEM", cp : "62890" },
{ code : "62056", intitule : "AUDRESSELLES", cp : "62164" },
{ code : "62057", intitule : "AUDRUICQ", cp : "62370" },
{ code : "62058", intitule : "AUMERVAL", cp : "62550" },
{ code : "62059", intitule : "AUTINGUES", cp : "62610" },
{ code : "62060", intitule : "AUXI LE CHATEAU", cp : "62390" },
{ code : "62061", intitule : "AVERDOINGT", cp : "62127" },
{ code : "62062", intitule : "AVESNES", cp : "62650" },
{ code : "62063", intitule : "AVESNES LE COMTE", cp : "62810" },
{ code : "62064", intitule : "AVESNES LES BAPAUME", cp : "62450" },
{ code : "62065", intitule : "AVION", cp : "62210" },
{ code : "62066", intitule : "AVONDANCE", cp : "62310" },
{ code : "62067", intitule : "AVROULT", cp : "62560" },
{ code : "62068", intitule : "AYETTE", cp : "62116" },
{ code : "62069", intitule : "AZINCOURT", cp : "62310" },
{ code : "62070", intitule : "BAILLEUL AUX CORNAILLES", cp : "62127" },
{ code : "62071", intitule : "BAILLEUL LES PERNES", cp : "62550" },
{ code : "62072", intitule : "BAILLEULMONT", cp : "62123" },
{ code : "62073", intitule : "BAILLEUL SIR BERTHOULT", cp : "62580" },
{ code : "62074", intitule : "BAILLEULVAL", cp : "62123" },
{ code : "62075", intitule : "BAINCTHUN", cp : "62360" },
{ code : "62076", intitule : "BAINGHEN", cp : "62850" },
{ code : "62077", intitule : "BAJUS", cp : "62150" },
{ code : "62078", intitule : "BALINGHEM", cp : "62610" },
{ code : "62079", intitule : "BANCOURT", cp : "62450" },
{ code : "62080", intitule : "BAPAUME", cp : "62450" },
{ code : "62081", intitule : "BARALLE", cp : "62860" },
{ code : "62082", intitule : "BARASTRE", cp : "62124" },
{ code : "62083", intitule : "BARLIN", cp : "62620" },
{ code : "62084", intitule : "BARLY", cp : "62810" },
{ code : "62085", intitule : "BASSEUX", cp : "62123" },
{ code : "62086", intitule : "BAVINCOURT", cp : "62158" },
{ code : "62086", intitule : "BAVINCOURT", cp : "62158" },
{ code : "62087", intitule : "BAYENGHEM LES EPERLECQUES", cp : "62910" },
{ code : "62088", intitule : "BAYENGHEM LES SENINGHEM", cp : "62380" },
{ code : "62089", intitule : "BAZINGHEN", cp : "62250" },
{ code : "62090", intitule : "BEALENCOURT", cp : "62770" },
{ code : "62091", intitule : "BEAUDRICOURT", cp : "62810" },
{ code : "62092", intitule : "BEAUFORT BLAVINCOURT", cp : "62810" },
{ code : "62093", intitule : "BEAULENCOURT", cp : "62450" },
{ code : "62094", intitule : "BEAUMERIE ST MARTIN", cp : "62170" },
{ code : "62095", intitule : "BEAUMETZ LES AIRE", cp : "62960" },
{ code : "62096", intitule : "BEAUMETZ LES CAMBRAI", cp : "62124" },
{ code : "62097", intitule : "BEAUMETZ LES LOGES", cp : "62123" },
{ code : "62099", intitule : "BEAURAINS", cp : "62217" },
{ code : "62100", intitule : "BEAURAINVILLE", cp : "62990" },
{ code : "62101", intitule : "BEAUVOIS", cp : "62130" },
{ code : "62102", intitule : "BECOURT", cp : "62240" },
{ code : "62103", intitule : "BEHAGNIES", cp : "62121" },
{ code : "62104", intitule : "BELLEBRUNE", cp : "62142" },
{ code : "62105", intitule : "BELLE ET HOULLEFORT", cp : "62142" },
{ code : "62106", intitule : "BELLONNE", cp : "62490" },
{ code : "62107", intitule : "BENIFONTAINE", cp : "62410" },
{ code : "62108", intitule : "BERCK", cp : "62600" },
{ code : "62109", intitule : "BERGUENEUSE", cp : "62134" },
{ code : "62111", intitule : "BERLENCOURT LE CAUROY", cp : "62810" },
{ code : "62112", intitule : "BERLES AU BOIS", cp : "62123" },
{ code : "62113", intitule : "BERLES MONCHEL", cp : "62690" },
{ code : "62114", intitule : "BERMICOURT", cp : "62130" },
{ code : "62115", intitule : "BERNEVILLE", cp : "62123" },
{ code : "62116", intitule : "BERNIEULLES", cp : "62170" },
{ code : "62117", intitule : "BERTINCOURT", cp : "62124" },
{ code : "62118", intitule : "BETHONSART", cp : "62690" },
{ code : "62119", intitule : "BETHUNE", cp : "62400" },
{ code : "62120", intitule : "BEUGIN", cp : "62150" },
{ code : "62121", intitule : "BEUGNATRE", cp : "62450" },
{ code : "62122", intitule : "BEUGNY", cp : "62124" },
{ code : "62123", intitule : "BEUSSENT", cp : "62170" },
{ code : "62124", intitule : "BEUTIN", cp : "62170" },
{ code : "62125", intitule : "BEUVREQUEN", cp : "62250" },
{ code : "62126", intitule : "BEUVRY", cp : "62660" },
{ code : "62127", intitule : "BEZINGHEM", cp : "62650" },
{ code : "62128", intitule : "BIACHE ST VAAST", cp : "62118" },
{ code : "62129", intitule : "BIEFVILLERS LES BAPAUME", cp : "62450" },
{ code : "62130", intitule : "BIENVILLERS AU BOIS", cp : "62111" },
{ code : "62131", intitule : "BIHUCOURT", cp : "62121" },
{ code : "62132", intitule : "BILLY BERCLAU", cp : "62138" },
{ code : "62133", intitule : "BILLY MONTIGNY", cp : "62420" },
{ code : "62134", intitule : "BIMONT", cp : "62650" },
{ code : "62135", intitule : "BLAIRVILLE", cp : "62173" },
{ code : "62137", intitule : "BLANGERVAL BLANGERMONT", cp : "62270" },
{ code : "62137", intitule : "BLANGERVAL BLANGERMONT", cp : "62270" },
{ code : "62138", intitule : "BLANGY SUR TERNOISE", cp : "62770" },
{ code : "62139", intitule : "BLENDECQUES", cp : "62575" },
{ code : "62140", intitule : "BLEQUIN", cp : "62380" },
{ code : "62141", intitule : "BLESSY", cp : "62120" },
{ code : "62142", intitule : "BLINGEL", cp : "62770" },
{ code : "62143", intitule : "BOFFLES", cp : "62390" },
{ code : "62144", intitule : "BOIRY BECQUERELLE", cp : "62128" },
{ code : "62145", intitule : "BOIRY NOTRE DAME", cp : "62156" },
{ code : "62146", intitule : "BOIRY ST MARTIN", cp : "62175" },
{ code : "62147", intitule : "BOIRY STE RICTRUDE", cp : "62175" },
{ code : "62148", intitule : "BOIS BERNARD", cp : "62320" },
{ code : "62149", intitule : "BOISDINGHEM", cp : "62500" },
{ code : "62150", intitule : "BOISJEAN", cp : "62170" },
{ code : "62151", intitule : "BOISLEUX AU MONT", cp : "62175" },
{ code : "62152", intitule : "BOISLEUX ST MARC", cp : "62175" },
{ code : "62153", intitule : "BOMY", cp : "62960" },
{ code : "62154", intitule : "BONNIERES", cp : "62270" },
{ code : "62155", intitule : "BONNINGUES LES ARDRES", cp : "62890" },
{ code : "62156", intitule : "BONNINGUES LES CALAIS", cp : "62340" },
{ code : "62157", intitule : "BOUBERS LES HESMOND", cp : "62990" },
{ code : "62158", intitule : "BOUBERS SUR CANCHE", cp : "62270" },
{ code : "62160", intitule : "BOULOGNE SUR MER", cp : "62200" },
{ code : "62161", intitule : "BOUQUEHAULT", cp : "62340" },
{ code : "62162", intitule : "BOURECQ", cp : "62190" },
{ code : "62163", intitule : "BOURET SUR CANCHE", cp : "62270" },
{ code : "62164", intitule : "BOURLON", cp : "62860" },
{ code : "62165", intitule : "BOURNONVILLE", cp : "62240" },
{ code : "62166", intitule : "BOURS", cp : "62550" },
{ code : "62167", intitule : "BOURSIN", cp : "62132" },
{ code : "62168", intitule : "BOURTHES", cp : "62650" },
{ code : "62169", intitule : "BOUVELINGHEM", cp : "62380" },
{ code : "62170", intitule : "BOUVIGNY BOYEFFLES", cp : "62172" },
{ code : "62171", intitule : "BOYAVAL", cp : "62134" },
{ code : "62172", intitule : "BOYELLES", cp : "62128" },
{ code : "62173", intitule : "BREBIERES", cp : "62117" },
{ code : "62174", intitule : "BREMES", cp : "62610" },
{ code : "62175", intitule : "BREVILLERS", cp : "62140" },
{ code : "62176", intitule : "BREXENT ENOCQ", cp : "62170" },
{ code : "62177", intitule : "BRIMEUX", cp : "62170" },
{ code : "62178", intitule : "BRUAY LA BUISSIERE", cp : "62700" },
{ code : "62178", intitule : "BRUAY LA BUISSIERE", cp : "62700" },
{ code : "62179", intitule : "BRUNEMBERT", cp : "62240" },
{ code : "62180", intitule : "BRIAS", cp : "62130" },
{ code : "62181", intitule : "BUCQUOY", cp : "62116" },
{ code : "62182", intitule : "BUIRE AU BOIS", cp : "62390" },
{ code : "62183", intitule : "BUIRE LE SEC", cp : "62870" },
{ code : "62184", intitule : "BUISSY", cp : "62860" },
{ code : "62185", intitule : "BULLECOURT", cp : "62128" },
{ code : "62186", intitule : "BULLY LES MINES", cp : "62160" },
{ code : "62187", intitule : "BUNEVILLE", cp : "62130" },
{ code : "62188", intitule : "BURBURE", cp : "62151" },
{ code : "62189", intitule : "BUS", cp : "62124" },
{ code : "62190", intitule : "BUSNES", cp : "62350" },
{ code : "62191", intitule : "CAFFIERS", cp : "62132" },
{ code : "62192", intitule : "CAGNICOURT", cp : "62182" },
{ code : "62193", intitule : "CALAIS", cp : "62100" },
{ code : "62194", intitule : "CALONNE RICOUART", cp : "62470" },
{ code : "62195", intitule : "CALONNE SUR LA LYS", cp : "62350" },
{ code : "62196", intitule : "LA CALOTTERIE", cp : "62170" },
{ code : "62197", intitule : "CAMBLAIN CHATELAIN", cp : "62470" },
{ code : "62198", intitule : "CAMBLIGNEUL", cp : "62690" },
{ code : "62199", intitule : "CAMBLAIN L ABBE", cp : "62690" },
{ code : "62200", intitule : "CAMBRIN", cp : "62149" },
{ code : "62201", intitule : "CAMIERS", cp : "62176" },
{ code : "62201", intitule : "CAMIERS", cp : "62176" },
{ code : "62202", intitule : "CAMPAGNE LES BOULONNAIS", cp : "62650" },
{ code : "62203", intitule : "CAMPAGNE LES GUINES", cp : "62340" },
{ code : "62204", intitule : "CAMPAGNE LES HESDIN", cp : "62870" },
{ code : "62205", intitule : "CAMPAGNE LES WARDRECQUES", cp : "62120" },
{ code : "62206", intitule : "CAMPIGNEULLES LES GRANDES", cp : "62170" },
{ code : "62207", intitule : "CAMPIGNEULLES LES PETITES", cp : "62170" },
{ code : "62208", intitule : "CANETTEMONT", cp : "62270" },
{ code : "62209", intitule : "CANLERS", cp : "62310" },
{ code : "62210", intitule : "CANTELEUX", cp : "62270" },
{ code : "62211", intitule : "CAPELLE FERMONT", cp : "62690" },
{ code : "62212", intitule : "CAPELLE LES HESDIN", cp : "62140" },
{ code : "62213", intitule : "CARENCY", cp : "62144" },
{ code : "62214", intitule : "CARLY", cp : "62830" },
{ code : "62215", intitule : "CARVIN", cp : "62220" },
{ code : "62216", intitule : "LA CAUCHIE", cp : "62158" },
{ code : "62217", intitule : "CAUCHY A LA TOUR", cp : "62260" },
{ code : "62218", intitule : "CAUCOURT", cp : "62150" },
{ code : "62219", intitule : "CAUMONT", cp : "62140" },
{ code : "62220", intitule : "CAVRON ST MARTIN", cp : "62140" },
{ code : "62221", intitule : "CHELERS", cp : "62127" },
{ code : "62222", intitule : "CHERIENNES", cp : "62140" },
{ code : "62223", intitule : "CHERISY", cp : "62128" },
{ code : "62224", intitule : "CHOCQUES", cp : "62920" },
{ code : "62225", intitule : "CLAIRMARAIS", cp : "62500" },
{ code : "62227", intitule : "CLENLEU", cp : "62650" },
{ code : "62228", intitule : "CLERQUES", cp : "62890" },
{ code : "62229", intitule : "CLETY", cp : "62380" },
{ code : "62230", intitule : "COLEMBERT", cp : "62142" },
{ code : "62231", intitule : "COLLINE BEAUMONT", cp : "62180" },
{ code : "62232", intitule : "LA COMTE", cp : "62150" },
{ code : "62233", intitule : "CONCHIL LE TEMPLE", cp : "62180" },
{ code : "62234", intitule : "CONCHY SUR CANCHE", cp : "62270" },
{ code : "62235", intitule : "CONDETTE", cp : "62360" },
{ code : "62236", intitule : "CONTES", cp : "62990" },
{ code : "62237", intitule : "CONTEVILLE LES BOULOGNE", cp : "62126" },
{ code : "62238", intitule : "CONTEVILLE EN TERNOIS", cp : "62130" },
{ code : "62239", intitule : "COQUELLES", cp : "62231" },
{ code : "62240", intitule : "CORBEHEM", cp : "62112" },
{ code : "62241", intitule : "CORMONT", cp : "62630" },
{ code : "62242", intitule : "COUIN", cp : "62760" },
{ code : "62243", intitule : "COULLEMONT", cp : "62158" },
{ code : "62244", intitule : "COULOGNE", cp : "62137" },
{ code : "62245", intitule : "COULOMBY", cp : "62380" },
{ code : "62246", intitule : "COUPELLE NEUVE", cp : "62310" },
{ code : "62247", intitule : "COUPELLE VIEILLE", cp : "62310" },
{ code : "62248", intitule : "COURCELLES LE COMTE", cp : "62121" },
{ code : "62249", intitule : "COURCELLES LES LENS", cp : "62970" },
{ code : "62250", intitule : "COURRIERES", cp : "62710" },
{ code : "62251", intitule : "COURSET", cp : "62240" },
{ code : "62252", intitule : "LA COUTURE", cp : "62136" },
{ code : "62253", intitule : "COUTURELLE", cp : "62158" },
{ code : "62254", intitule : "COYECQUES", cp : "62560" },
{ code : "62255", intitule : "CREMAREST", cp : "62240" },
{ code : "62256", intitule : "CREPY", cp : "62310" },
{ code : "62257", intitule : "CREQUY", cp : "62310" },
{ code : "62258", intitule : "CROISETTE", cp : "62130" },
{ code : "62259", intitule : "CROISILLES", cp : "62128" },
{ code : "62260", intitule : "CROIX EN TERNOIS", cp : "62130" },
{ code : "62261", intitule : "CUCQ", cp : "62780" },
{ code : "62261", intitule : "CUCQ", cp : "62780" },
{ code : "62261", intitule : "CUCQ", cp : "62780" },
{ code : "62262", intitule : "CUINCHY", cp : "62149" },
{ code : "62263", intitule : "DAINVILLE", cp : "62000" },
{ code : "62264", intitule : "DANNES", cp : "62187" },
{ code : "62265", intitule : "DELETTES", cp : "62129" },
{ code : "62266", intitule : "DENIER", cp : "62810" },
{ code : "62267", intitule : "DENNEBROEUCQ", cp : "62560" },
{ code : "62268", intitule : "DESVRES", cp : "62240" },
{ code : "62269", intitule : "DIEVAL", cp : "62460" },
{ code : "62270", intitule : "DIVION", cp : "62460" },
{ code : "62271", intitule : "DOHEM", cp : "62380" },
{ code : "62272", intitule : "DOUCHY LES AYETTE", cp : "62116" },
{ code : "62273", intitule : "DOUDEAUVILLE", cp : "62830" },
{ code : "62274", intitule : "DOURGES", cp : "62119" },
{ code : "62275", intitule : "DOURIEZ", cp : "62870" },
{ code : "62276", intitule : "DOUVRIN", cp : "62138" },
{ code : "62277", intitule : "DROCOURT", cp : "62320" },
{ code : "62278", intitule : "DROUVIN LE MARAIS", cp : "62131" },
{ code : "62279", intitule : "DUISANS", cp : "62161" },
{ code : "62280", intitule : "DURY", cp : "62156" },
{ code : "62281", intitule : "ECHINGHEN", cp : "62360" },
{ code : "62282", intitule : "ECLIMEUX", cp : "62770" },
{ code : "62283", intitule : "ECOIVRES", cp : "62270" },
{ code : "62284", intitule : "ECOURT ST QUENTIN", cp : "62860" },
{ code : "62285", intitule : "ECOUST ST MEIN", cp : "62128" },
{ code : "62286", intitule : "ECQUEDECQUES", cp : "62190" },
{ code : "62288", intitule : "ECQUES", cp : "62129" },
{ code : "62289", intitule : "ECUIRES", cp : "62170" },
{ code : "62290", intitule : "ECURIE", cp : "62223" },
{ code : "62291", intitule : "ELEU DIT LEAUWETTE", cp : "62300" },
{ code : "62292", intitule : "ELNES", cp : "62380" },
{ code : "62293", intitule : "EMBRY", cp : "62990" },
{ code : "62294", intitule : "ENGUINEGATTE", cp : "62145" },
{ code : "62295", intitule : "ENQUIN LES MINES", cp : "62145" },
{ code : "62296", intitule : "ENQUIN SUR BAILLONS", cp : "62650" },
{ code : "62297", intitule : "EPERLECQUES", cp : "62910" },
{ code : "62298", intitule : "EPINOY", cp : "62860" },
{ code : "62299", intitule : "EPS", cp : "62134" },
{ code : "62300", intitule : "EQUIHEN PLAGE", cp : "62224" },
{ code : "62301", intitule : "EQUIRRE", cp : "62134" },
{ code : "62302", intitule : "ERGNY", cp : "62650" },
{ code : "62303", intitule : "ERIN", cp : "62134" },
{ code : "62304", intitule : "ERNY ST JULIEN", cp : "62960" },
{ code : "62306", intitule : "ERVILLERS", cp : "62121" },
{ code : "62307", intitule : "ESCALLES", cp : "62179" },
{ code : "62308", intitule : "ESCOEUILLES", cp : "62850" },
{ code : "62309", intitule : "ESQUERDES", cp : "62380" },
{ code : "62310", intitule : "ESSARS", cp : "62400" },
{ code : "62311", intitule : "ESTEVELLES", cp : "62880" },
{ code : "62312", intitule : "ESTREE", cp : "62170" },
{ code : "62313", intitule : "ESTREE BLANCHE", cp : "62145" },
{ code : "62314", intitule : "ESTREE CAUCHY", cp : "62690" },
{ code : "62315", intitule : "ESTREELLES", cp : "62170" },
{ code : "62316", intitule : "ESTREE WAMIN", cp : "62810" },
{ code : "62317", intitule : "ETAING", cp : "62156" },
{ code : "62318", intitule : "ETAPLES", cp : "62630" },
{ code : "62319", intitule : "ETERPIGNY", cp : "62156" },
{ code : "62320", intitule : "ETRUN", cp : "62161" },
{ code : "62321", intitule : "EVIN MALMAISON", cp : "62141" },
{ code : "62322", intitule : "FAMECHON", cp : "62760" },
{ code : "62323", intitule : "FAMPOUX", cp : "62118" },
{ code : "62324", intitule : "FARBUS", cp : "62580" },
{ code : "62325", intitule : "FAUQUEMBERGUES", cp : "62560" },
{ code : "62326", intitule : "FAVREUIL", cp : "62450" },
{ code : "62327", intitule : "FEBVIN PALFART", cp : "62960" },
{ code : "62328", intitule : "FERFAY", cp : "62260" },
{ code : "62329", intitule : "FERQUES", cp : "62250" },
{ code : "62330", intitule : "FESTUBERT", cp : "62149" },
{ code : "62331", intitule : "FEUCHY", cp : "62223" },
{ code : "62332", intitule : "FICHEUX", cp : "62173" },
{ code : "62333", intitule : "FIEFS", cp : "62134" },
{ code : "62334", intitule : "FIENNES", cp : "62132" },
{ code : "62335", intitule : "FILLIEVRES", cp : "62770" },
{ code : "62336", intitule : "FLECHIN", cp : "62960" },
{ code : "62337", intitule : "FLERS", cp : "62270" },
{ code : "62338", intitule : "FLEURBAIX", cp : "62840" },
{ code : "62339", intitule : "FLEURY", cp : "62134" },
{ code : "62340", intitule : "FLORINGHEM", cp : "62550" },
{ code : "62341", intitule : "FONCQUEVILLERS", cp : "62111" },
{ code : "62342", intitule : "FONTAINE LES BOULANS", cp : "62134" },
{ code : "62343", intitule : "FONTAINE LES CROISILLES", cp : "62128" },
{ code : "62344", intitule : "FONTAINE LES HERMANS", cp : "62550" },
{ code : "62345", intitule : "FONTAINE L ETALON", cp : "62390" },
{ code : "62346", intitule : "FORTEL EN ARTOIS", cp : "62270" },
{ code : "62347", intitule : "FOSSEUX", cp : "62810" },
{ code : "62348", intitule : "FOUFFLIN RICAMETZ", cp : "62130" },
{ code : "62349", intitule : "FOUQUEREUIL", cp : "62232" },
{ code : "62350", intitule : "FOUQUIERES LES BETHUNE", cp : "62232" },
{ code : "62351", intitule : "FOUQUIERES LES LENS", cp : "62740" },
{ code : "62352", intitule : "FRAMECOURT", cp : "62130" },
{ code : "62353", intitule : "FREMICOURT", cp : "62450" },
{ code : "62354", intitule : "FRENCQ", cp : "62630" },
{ code : "62355", intitule : "FRESNES LES MONTAUBAN", cp : "62490" },
{ code : "62356", intitule : "FRESNICOURT LE DOLMEN", cp : "62150" },
{ code : "62357", intitule : "FRESNOY", cp : "62770" },
{ code : "62358", intitule : "FRESNOY EN GOHELLE", cp : "62580" },
{ code : "62359", intitule : "FRESSIN", cp : "62140" },
{ code : "62360", intitule : "FRETHUN", cp : "62185" },
{ code : "62361", intitule : "FREVENT", cp : "62270" },
{ code : "62362", intitule : "FREVILLERS", cp : "62127" },
{ code : "62363", intitule : "FREVIN CAPELLE", cp : "62690" },
{ code : "62364", intitule : "FRUGES", cp : "62310" },
{ code : "62365", intitule : "GALAMETZ", cp : "62770" },
{ code : "62366", intitule : "GAUCHIN LEGAL", cp : "62150" },
{ code : "62367", intitule : "GAUCHIN VERLOINGT", cp : "62130" },
{ code : "62368", intitule : "GAUDIEMPRE", cp : "62760" },
{ code : "62369", intitule : "GAVRELLE", cp : "62580" },
{ code : "62370", intitule : "GENNES IVERGNY", cp : "62390" },
{ code : "62371", intitule : "GIVENCHY EN GOHELLE", cp : "62580" },
{ code : "62372", intitule : "GIVENCHY LE NOBLE", cp : "62810" },
{ code : "62373", intitule : "GIVENCHY LES LA BASSEE", cp : "62149" },
{ code : "62374", intitule : "GOMIECOURT", cp : "62121" },
{ code : "62375", intitule : "GOMMECOURT", cp : "62111" },
{ code : "62376", intitule : "GONNEHEM", cp : "62920" },
{ code : "62377", intitule : "GOSNAY", cp : "62199" },
{ code : "62378", intitule : "GOUVES", cp : "62123" },
{ code : "62379", intitule : "GOUY EN ARTOIS", cp : "62123" },
{ code : "62380", intitule : "GOUY SERVINS", cp : "62530" },
{ code : "62381", intitule : "GOUY EN TERNOIS", cp : "62127" },
{ code : "62382", intitule : "GOUY ST ANDRE", cp : "62870" },
{ code : "62383", intitule : "GOUY SOUS BELLONNE", cp : "62112" },
{ code : "62384", intitule : "GRAINCOURT LES HAVRINCOURT", cp : "62147" },
{ code : "62385", intitule : "GRAND RULLECOURT", cp : "62810" },
{ code : "62386", intitule : "GRENAY", cp : "62160" },
{ code : "62387", intitule : "GREVILLERS", cp : "62450" },
{ code : "62388", intitule : "GRIGNY", cp : "62140" },
{ code : "62389", intitule : "GRINCOURT LES PAS", cp : "62760" },
{ code : "62390", intitule : "GROFFLIERS", cp : "62600" },
{ code : "62391", intitule : "GUARBECQUE", cp : "62330" },
{ code : "62392", intitule : "GUEMAPPE", cp : "62128" },
{ code : "62393", intitule : "GUEMPS", cp : "62370" },
{ code : "62395", intitule : "GUIGNY", cp : "62140" },
{ code : "62396", intitule : "GUINECOURT", cp : "62130" },
{ code : "62397", intitule : "GUINES", cp : "62340" },
{ code : "62398", intitule : "GUISY", cp : "62140" },
{ code : "62399", intitule : "HABARCQ", cp : "62123" },
{ code : "62400", intitule : "HAILLICOURT", cp : "62940" },
{ code : "62401", intitule : "HAISNES", cp : "62138" },
{ code : "62402", intitule : "HALINGHEN", cp : "62830" },
{ code : "62403", intitule : "HALLINES", cp : "62570" },
{ code : "62404", intitule : "HALLOY", cp : "62760" },
{ code : "62405", intitule : "HAMBLAIN LES PRES", cp : "62118" },
{ code : "62406", intitule : "HAMELINCOURT", cp : "62121" },
{ code : "62407", intitule : "HAM EN ARTOIS", cp : "62190" },
{ code : "62408", intitule : "HAMES BOUCRES", cp : "62340" },
{ code : "62409", intitule : "HANNESCAMPS", cp : "62111" },
{ code : "62410", intitule : "HAPLINCOURT", cp : "62124" },
{ code : "62411", intitule : "HARAVESNES", cp : "62390" },
{ code : "62412", intitule : "HARDINGHEN", cp : "62132" },
{ code : "62413", intitule : "HARNES", cp : "62440" },
{ code : "62414", intitule : "HAUCOURT", cp : "62156" },
{ code : "62415", intitule : "HAUTE AVESNES", cp : "62144" },
{ code : "62416", intitule : "HAUTECLOQUE", cp : "62130" },
{ code : "62418", intitule : "HAUTEVILLE", cp : "62810" },
{ code : "62419", intitule : "HAUT LOQUIN", cp : "62850" },
{ code : "62421", intitule : "HAVRINCOURT", cp : "62147" },
{ code : "62422", intitule : "HEBUTERNE", cp : "62111" },
{ code : "62423", intitule : "HELFAUT", cp : "62570" },
{ code : "62424", intitule : "HENDECOURT LES CAGNICOURT", cp : "62182" },
{ code : "62425", intitule : "HENDECOURT LES RANSART", cp : "62175" },
{ code : "62426", intitule : "HENINEL", cp : "62128" },
{ code : "62427", intitule : "HENIN BEAUMONT", cp : "62110" },
{ code : "62428", intitule : "HENIN SUR COJEUL", cp : "62128" },
{ code : "62429", intitule : "HENNEVEUX", cp : "62142" },
{ code : "62430", intitule : "HENU", cp : "62760" },
{ code : "62431", intitule : "HERBELLES", cp : "62129" },
{ code : "62432", intitule : "HERBINGHEN", cp : "62850" },
{ code : "62433", intitule : "HERICOURT", cp : "62130" },
{ code : "62434", intitule : "LA HERLIERE", cp : "62158" },
{ code : "62435", intitule : "HERLINCOURT", cp : "62130" },
{ code : "62436", intitule : "HERLIN LE SEC", cp : "62130" },
{ code : "62437", intitule : "HERLY", cp : "62650" },
{ code : "62438", intitule : "HERMAVILLE", cp : "62690" },
{ code : "62439", intitule : "HERMELINGHEN", cp : "62132" },
{ code : "62440", intitule : "HERMIES", cp : "62147" },
{ code : "62441", intitule : "HERMIN", cp : "62150" },
{ code : "62442", intitule : "HERNICOURT", cp : "62130" },
{ code : "62443", intitule : "HERSIN COUPIGNY", cp : "62530" },
{ code : "62444", intitule : "HERVELINGHEN", cp : "62179" },
{ code : "62445", intitule : "HESDIGNEUL LES BETHUNE", cp : "62196" },
{ code : "62446", intitule : "HESDIGNEUL LES BOULOGNE", cp : "62360" },
{ code : "62447", intitule : "HESDIN", cp : "62140" },
{ code : "62448", intitule : "HESDIN L ABBE", cp : "62360" },
{ code : "62449", intitule : "HESMOND", cp : "62990" },
{ code : "62450", intitule : "HESTRUS", cp : "62550" },
{ code : "62451", intitule : "HEUCHIN", cp : "62134" },
{ code : "62452", intitule : "HEURINGHEM", cp : "62575" },
{ code : "62453", intitule : "HEZECQUES", cp : "62310" },
{ code : "62454", intitule : "HINGES", cp : "62232" },
{ code : "62455", intitule : "HOCQUINGHEN", cp : "62850" },
{ code : "62456", intitule : "HOUCHIN", cp : "62620" },
{ code : "62457", intitule : "HOUDAIN", cp : "62150" },
{ code : "62458", intitule : "HOULLE", cp : "62910" },
{ code : "62459", intitule : "HOUVIN HOUVIGNEUL", cp : "62270" },
{ code : "62460", intitule : "HUBERSENT", cp : "62630" },
{ code : "62461", intitule : "HUBY ST LEU", cp : "62140" },
{ code : "62462", intitule : "HUCLIER", cp : "62130" },
{ code : "62463", intitule : "HUCQUELIERS", cp : "62650" },
{ code : "62464", intitule : "HULLUCH", cp : "62410" },
{ code : "62465", intitule : "HUMBERCAMPS", cp : "62158" },
{ code : "62466", intitule : "HUMBERT", cp : "62650" },
{ code : "62467", intitule : "HUMEROEUILLE", cp : "62130" },
{ code : "62468", intitule : "HUMIERES", cp : "62130" },
{ code : "62469", intitule : "INCHY EN ARTOIS", cp : "62860" },
{ code : "62470", intitule : "INCOURT", cp : "62770" },
{ code : "62471", intitule : "INGHEM", cp : "62129" },
{ code : "62472", intitule : "INXENT", cp : "62170" },
{ code : "62473", intitule : "ISBERGUES", cp : "62330" },
{ code : "62473", intitule : "ISBERGUES", cp : "62330" },
{ code : "62473", intitule : "ISBERGUES", cp : "62330" },
{ code : "62474", intitule : "ISQUES", cp : "62360" },
{ code : "62475", intitule : "IVERGNY", cp : "62810" },
{ code : "62476", intitule : "IZEL LES EQUERCHIN", cp : "62490" },
{ code : "62477", intitule : "IZEL LES HAMEAU", cp : "62690" },
{ code : "62478", intitule : "JOURNY", cp : "62850" },
{ code : "62479", intitule : "LABEUVRIERE", cp : "62122" },
{ code : "62480", intitule : "LABOURSE", cp : "62113" },
{ code : "62481", intitule : "LABROYE", cp : "62140" },
{ code : "62483", intitule : "LACRES", cp : "62830" },
{ code : "62484", intitule : "LAGNICOURT MARCEL", cp : "62159" },
{ code : "62485", intitule : "LAIRES", cp : "62960" },
{ code : "62486", intitule : "LAMBRES", cp : "62120" },
{ code : "62487", intitule : "LANDRETHUN LE NORD", cp : "62250" },
{ code : "62488", intitule : "LANDRETHUN LES ARDRES", cp : "62610" },
{ code : "62489", intitule : "LAPUGNOY", cp : "62122" },
{ code : "62490", intitule : "LATTRE ST QUENTIN", cp : "62810" },
{ code : "62491", intitule : "LAVENTIE", cp : "62840" },
{ code : "62492", intitule : "LEBIEZ", cp : "62990" },
{ code : "62493", intitule : "LEBUCQUIERE", cp : "62124" },
{ code : "62494", intitule : "LECHELLE", cp : "62124" },
{ code : "62495", intitule : "LEDINGHEM", cp : "62380" },
{ code : "62496", intitule : "LEFAUX", cp : "62630" },
{ code : "62497", intitule : "LEFOREST", cp : "62790" },
{ code : "62498", intitule : "LENS", cp : "62300" },
{ code : "62499", intitule : "LEPINE", cp : "62170" },
{ code : "62500", intitule : "LESPESSES", cp : "62190" },
{ code : "62501", intitule : "LESPINOY", cp : "62990" },
{ code : "62502", intitule : "LESTREM", cp : "62136" },
{ code : "62503", intitule : "LEUBRINGHEN", cp : "62250" },
{ code : "62504", intitule : "LEULINGHEM", cp : "62500" },
{ code : "62505", intitule : "LEULINGHEN BERNES", cp : "62250" },
{ code : "62506", intitule : "LICQUES", cp : "62850" },
{ code : "62507", intitule : "LIENCOURT", cp : "62810" },
{ code : "62508", intitule : "LIERES", cp : "62190" },
{ code : "62509", intitule : "LIETTRES", cp : "62145" },
{ code : "62510", intitule : "LIEVIN", cp : "62800" },
{ code : "62511", intitule : "LIGNEREUIL", cp : "62810" },
{ code : "62512", intitule : "LIGNY LES AIRE", cp : "62960" },
{ code : "62513", intitule : "LIGNY SUR CANCHE", cp : "62270" },
{ code : "62514", intitule : "LIGNY ST FLOCHEL", cp : "62127" },
{ code : "62515", intitule : "LIGNY THILLOY", cp : "62450" },
{ code : "62516", intitule : "LILLERS", cp : "62190" },
{ code : "62517", intitule : "LINGHEM", cp : "62120" },
{ code : "62518", intitule : "LINZEUX", cp : "62270" },
{ code : "62519", intitule : "LISBOURG", cp : "62134" },
{ code : "62520", intitule : "LOCON", cp : "62400" },
{ code : "62521", intitule : "LA LOGE", cp : "62140" },
{ code : "62522", intitule : "LOISON SUR CREQUOISE", cp : "62990" },
{ code : "62523", intitule : "LOISON SOUS LENS", cp : "62218" },
{ code : "62524", intitule : "LONGFOSSE", cp : "62240" },
{ code : "62525", intitule : "LONGUENESSE", cp : "62219" },
{ code : "62526", intitule : "LONGUEVILLE", cp : "62142" },
{ code : "62527", intitule : "LONGVILLIERS", cp : "62630" },
{ code : "62528", intitule : "LOOS EN GOHELLE", cp : "62750" },
{ code : "62529", intitule : "LORGIES", cp : "62840" },
{ code : "62530", intitule : "LOTTINGHEN", cp : "62240" },
{ code : "62531", intitule : "LOUCHES", cp : "62610" },
{ code : "62532", intitule : "LOZINGHEM", cp : "62540" },
{ code : "62533", intitule : "LUGY", cp : "62310" },
{ code : "62534", intitule : "LUMBRES", cp : "62380" },
{ code : "62535", intitule : "LA MADELAINE SOUS MONTREUIL", cp : "62170" },
{ code : "62536", intitule : "MAGNICOURT EN COMTE", cp : "62127" },
{ code : "62537", intitule : "MAGNICOURT SUR CANCHE", cp : "62270" },
{ code : "62538", intitule : "MAINTENAY", cp : "62870" },
{ code : "62539", intitule : "MAISNIL", cp : "62130" },
{ code : "62540", intitule : "MAISNIL LES RUITZ", cp : "62620" },
{ code : "62541", intitule : "MAISONCELLE", cp : "62310" },
{ code : "62542", intitule : "MAIZIERES", cp : "62127" },
{ code : "62543", intitule : "MAMETZ", cp : "62120" },
{ code : "62544", intitule : "MANIN", cp : "62810" },
{ code : "62545", intitule : "MANINGHEM", cp : "62650" },
{ code : "62546", intitule : "MANINGHEN HENNE", cp : "62250" },
{ code : "62547", intitule : "MARANT", cp : "62170" },
{ code : "62548", intitule : "MARCK", cp : "62730" },
{ code : "62549", intitule : "MARCONNE", cp : "62140" },
{ code : "62550", intitule : "MARCONNELLE", cp : "62140" },
{ code : "62551", intitule : "MARENLA", cp : "62990" },
{ code : "62552", intitule : "MARESQUEL ECQUEMICOURT", cp : "62990" },
{ code : "62553", intitule : "MAREST", cp : "62550" },
{ code : "62554", intitule : "MARESVILLE", cp : "62630" },
{ code : "62555", intitule : "MARLES LES MINES", cp : "62540" },
{ code : "62556", intitule : "MARLES SUR CANCHE", cp : "62170" },
{ code : "62557", intitule : "MAROEUIL", cp : "62161" },
{ code : "62558", intitule : "MARQUAY", cp : "62127" },
{ code : "62559", intitule : "MARQUION", cp : "62860" },
{ code : "62560", intitule : "MARQUISE", cp : "62250" },
{ code : "62561", intitule : "MARTINPUICH", cp : "62450" },
{ code : "62562", intitule : "MATRINGHEM", cp : "62310" },
{ code : "62563", intitule : "MAZINGARBE", cp : "62670" },
{ code : "62564", intitule : "MAZINGHEM", cp : "62120" },
{ code : "62565", intitule : "MENCAS", cp : "62310" },
{ code : "62566", intitule : "MENNEVILLE", cp : "62240" },
{ code : "62567", intitule : "MENTQUE NORTBECOURT", cp : "62890" },
{ code : "62568", intitule : "MERCATEL", cp : "62217" },
{ code : "62569", intitule : "MERCK ST LIEVIN", cp : "62560" },
{ code : "62570", intitule : "MERICOURT", cp : "62680" },
{ code : "62571", intitule : "MERLIMONT", cp : "62155" },
{ code : "62572", intitule : "METZ EN COUTURE", cp : "62124" },
{ code : "62573", intitule : "MEURCHIN", cp : "62410" },
{ code : "62574", intitule : "MINGOVAL", cp : "62690" },
{ code : "62576", intitule : "MONCHEAUX LES FREVENT", cp : "62270" },
{ code : "62577", intitule : "MONCHEL SUR CANCHE", cp : "62270" },
{ code : "62578", intitule : "MONCHIET", cp : "62123" },
{ code : "62579", intitule : "MONCHY AU BOIS", cp : "62111" },
{ code : "62580", intitule : "MONCHY BRETON", cp : "62127" },
{ code : "62581", intitule : "MONCHY CAYEUX", cp : "62134" },
{ code : "62582", intitule : "MONCHY LE PREUX", cp : "62118" },
{ code : "62583", intitule : "MONDICOURT", cp : "62760" },
{ code : "62584", intitule : "MONT BERNANCHON", cp : "62350" },
{ code : "62585", intitule : "MONTCAVREL", cp : "62170" },
{ code : "62586", intitule : "MONTENESCOURT", cp : "62123" },
{ code : "62587", intitule : "MONTIGNY EN GOHELLE", cp : "62640" },
{ code : "62588", intitule : "MONTREUIL", cp : "62170" },
{ code : "62589", intitule : "MONT ST ELOI", cp : "62144" },
{ code : "62590", intitule : "MONTS EN TERNOIS", cp : "62130" },
{ code : "62591", intitule : "MORCHIES", cp : "62124" },
{ code : "62592", intitule : "MORINGHEM", cp : "62910" },
{ code : "62593", intitule : "MORVAL", cp : "62450" },
{ code : "62594", intitule : "MORY", cp : "62159" },
{ code : "62595", intitule : "MOULLE", cp : "62910" },
{ code : "62596", intitule : "MOURIEZ", cp : "62140" },
{ code : "62597", intitule : "MOYENNEVILLE", cp : "62121" },
{ code : "62598", intitule : "MUNCQ NIEURLET", cp : "62890" },
{ code : "62599", intitule : "NABRINGHEN", cp : "62142" },
{ code : "62600", intitule : "NEDON", cp : "62550" },
{ code : "62601", intitule : "NEDONCHEL", cp : "62550" },
{ code : "62602", intitule : "NEMPONT ST FIRMIN", cp : "62180" },
{ code : "62603", intitule : "NESLES", cp : "62152" },
{ code : "62604", intitule : "NEUFCHATEL HARDELOT", cp : "62152" },
{ code : "62604", intitule : "NEUFCHATEL HARDELOT", cp : "62152" },
{ code : "62605", intitule : "NEULETTE", cp : "62770" },
{ code : "62606", intitule : "NEUVE CHAPELLE", cp : "62840" },
{ code : "62607", intitule : "NEUVILLE AU CORNET", cp : "62130" },
{ code : "62608", intitule : "NEUVILLE BOURJONVAL", cp : "62124" },
{ code : "62609", intitule : "NEUVILLE ST VAAST", cp : "62580" },
{ code : "62610", intitule : "NEUVILLE SOUS MONTREUIL", cp : "62170" },
{ code : "62611", intitule : "NEUVILLE VITASSE", cp : "62217" },
{ code : "62612", intitule : "NEUVIREUIL", cp : "62580" },
{ code : "62613", intitule : "NIELLES LES BLEQUIN", cp : "62380" },
{ code : "62614", intitule : "NIELLES LES ARDRES", cp : "62610" },
{ code : "62615", intitule : "NIELLES LES CALAIS", cp : "62185" },
{ code : "62616", intitule : "NOEUX LES AUXI", cp : "62390" },
{ code : "62617", intitule : "NOEUX LES MINES", cp : "62290" },
{ code : "62618", intitule : "NORDAUSQUES", cp : "62890" },
{ code : "62619", intitule : "NOREUIL", cp : "62128" },
{ code : "62620", intitule : "NORRENT FONTES", cp : "62120" },
{ code : "62621", intitule : "NORTKERQUE", cp : "62370" },
{ code : "62622", intitule : "NORT LEULINGHEM", cp : "62890" },
{ code : "62623", intitule : "NOUVELLE EGLISE", cp : "62370" },
{ code : "62624", intitule : "NOYELLES GODAULT", cp : "62950" },
{ code : "62625", intitule : "NOYELLES LES HUMIERES", cp : "62770" },
{ code : "62626", intitule : "NOYELLES LES VERMELLES", cp : "62980" },
{ code : "62627", intitule : "NOYELLES SOUS BELLONNE", cp : "62490" },
{ code : "62628", intitule : "NOYELLES SOUS LENS", cp : "62221" },
{ code : "62629", intitule : "NOYELLETTE", cp : "62123" },
{ code : "62630", intitule : "NOYELLE VION", cp : "62810" },
{ code : "62631", intitule : "NUNCQ HAUTECOTE", cp : "62270" },
{ code : "62631", intitule : "NUNCQ HAUTECOTE", cp : "62270" },
{ code : "62632", intitule : "OBLINGHEM", cp : "62920" },
{ code : "62633", intitule : "OEUF EN TERNOIS", cp : "62130" },
{ code : "62634", intitule : "OFFEKERQUE", cp : "62370" },
{ code : "62635", intitule : "OFFIN", cp : "62990" },
{ code : "62636", intitule : "OFFRETHUN", cp : "62250" },
{ code : "62637", intitule : "OIGNIES", cp : "62590" },
{ code : "62638", intitule : "OISY LE VERGER", cp : "62860" },
{ code : "62639", intitule : "OPPY", cp : "62580" },
{ code : "62640", intitule : "ORVILLE", cp : "62760" },
{ code : "62641", intitule : "OSTREVILLE", cp : "62130" },
{ code : "62642", intitule : "OURTON", cp : "62460" },
{ code : "62643", intitule : "OUTREAU", cp : "62230" },
{ code : "62644", intitule : "OUVE WIRQUIN", cp : "62380" },
{ code : "62645", intitule : "OYE PLAGE", cp : "62215" },
{ code : "62646", intitule : "PALLUEL", cp : "62860" },
{ code : "62647", intitule : "LE PARCQ", cp : "62770" },
{ code : "62648", intitule : "PARENTY", cp : "62650" },
{ code : "62649", intitule : "PAS EN ARTOIS", cp : "62760" },
{ code : "62650", intitule : "PELVES", cp : "62118" },
{ code : "62651", intitule : "PENIN", cp : "62127" },
{ code : "62652", intitule : "PERNES", cp : "62550" },
{ code : "62653", intitule : "PERNES LES BOULOGNE", cp : "62126" },
{ code : "62654", intitule : "PEUPLINGUES", cp : "62231" },
{ code : "62655", intitule : "PIERREMONT", cp : "62130" },
{ code : "62656", intitule : "PIHEM", cp : "62570" },
{ code : "62657", intitule : "PIHEN LES GUINES", cp : "62340" },
{ code : "62658", intitule : "PITTEFAUX", cp : "62126" },
{ code : "62659", intitule : "PLANQUES", cp : "62310" },
{ code : "62660", intitule : "PLOUVAIN", cp : "62118" },
{ code : "62661", intitule : "BOUIN PLUMOISON", cp : "62140" },
{ code : "62661", intitule : "BOUIN PLUMOISON", cp : "62140" },
{ code : "62662", intitule : "POLINCOVE", cp : "62370" },
{ code : "62663", intitule : "POMMERA", cp : "62760" },
{ code : "62664", intitule : "POMMIER", cp : "62111" },
{ code : "62665", intitule : "LE PONCHEL", cp : "62390" },
{ code : "62666", intitule : "PONT A VENDIN", cp : "62880" },
{ code : "62667", intitule : "LE PORTEL", cp : "62480" },
{ code : "62668", intitule : "PREDEFIN", cp : "62134" },
{ code : "62669", intitule : "PRESSY", cp : "62550" },
{ code : "62670", intitule : "PREURES", cp : "62650" },
{ code : "62671", intitule : "PRONVILLE", cp : "62860" },
{ code : "62672", intitule : "PUISIEUX", cp : "62116" },
{ code : "62673", intitule : "QUEANT", cp : "62860" },
{ code : "62674", intitule : "QUELMES", cp : "62500" },
{ code : "62675", intitule : "QUERCAMPS", cp : "62380" },
{ code : "62676", intitule : "QUERNES", cp : "62120" },
{ code : "62677", intitule : "LE QUESNOY EN ARTOIS", cp : "62140" },
{ code : "62678", intitule : "QUESQUES", cp : "62240" },
{ code : "62679", intitule : "QUESTRECQUES", cp : "62830" },
{ code : "62680", intitule : "QUIERY LA MOTTE", cp : "62490" },
{ code : "62681", intitule : "QUIESTEDE", cp : "62120" },
{ code : "62682", intitule : "QUILEN", cp : "62650" },
{ code : "62683", intitule : "QUOEUX HAUT MAINIL", cp : "62390" },
{ code : "62683", intitule : "QUOEUX HAUT MAINIL", cp : "62390" },
{ code : "62684", intitule : "RACQUINGHEM", cp : "62120" },
{ code : "62685", intitule : "RADINGHEM", cp : "62310" },
{ code : "62686", intitule : "RAMECOURT", cp : "62130" },
{ code : "62688", intitule : "RANG DU FLIERS", cp : "62180" },
{ code : "62689", intitule : "RANSART", cp : "62173" },
{ code : "62690", intitule : "RAYE SUR AUTHIE", cp : "62140" },
{ code : "62691", intitule : "ST AUGUSTIN", cp : "62120" },
{ code : "62691", intitule : "ST AUGUSTIN", cp : "62129" },
{ code : "62692", intitule : "REBERGUES", cp : "62850" },
{ code : "62693", intitule : "REBREUVE RANCHICOURT", cp : "62150" },
{ code : "62693", intitule : "REBREUVE RANCHICOURT", cp : "62150" },
{ code : "62694", intitule : "REBREUVE SUR CANCHE", cp : "62270" },
{ code : "62695", intitule : "REBREUVIETTE", cp : "62270" },
{ code : "62696", intitule : "RECLINGHEM", cp : "62560" },
{ code : "62697", intitule : "RECOURT", cp : "62860" },
{ code : "62698", intitule : "RECQUES SUR COURSE", cp : "62170" },
{ code : "62699", intitule : "RECQUES SUR HEM", cp : "62890" },
{ code : "62700", intitule : "REGNAUVILLE", cp : "62140" },
{ code : "62701", intitule : "RELY", cp : "62120" },
{ code : "62702", intitule : "REMILLY WIRQUIN", cp : "62380" },
{ code : "62703", intitule : "REMY", cp : "62156" },
{ code : "62704", intitule : "RENTY", cp : "62560" },
{ code : "62705", intitule : "RETY", cp : "62720" },
{ code : "62706", intitule : "RICHEBOURG", cp : "62136" },
{ code : "62708", intitule : "RIENCOURT LES BAPAUME", cp : "62450" },
{ code : "62709", intitule : "RIENCOURT LES CAGNICOURT", cp : "62182" },
{ code : "62710", intitule : "RIMBOVAL", cp : "62990" },
{ code : "62711", intitule : "RINXENT", cp : "62720" },
{ code : "62712", intitule : "RIVIERE", cp : "62173" },
{ code : "62713", intitule : "ROBECQ", cp : "62350" },
{ code : "62714", intitule : "ROCLINCOURT", cp : "62223" },
{ code : "62715", intitule : "ROCQUIGNY", cp : "62450" },
{ code : "62716", intitule : "RODELINGHEM", cp : "62610" },
{ code : "62717", intitule : "ROELLECOURT", cp : "62130" },
{ code : "62718", intitule : "ROEUX", cp : "62118" },
{ code : "62719", intitule : "ROLLANCOURT", cp : "62770" },
{ code : "62720", intitule : "ROMBLY", cp : "62120" },
{ code : "62721", intitule : "ROQUETOIRE", cp : "62120" },
{ code : "62722", intitule : "ROUGEFAY", cp : "62390" },
{ code : "62723", intitule : "ROUSSENT", cp : "62870" },
{ code : "62724", intitule : "ROUVROY", cp : "62320" },
{ code : "62725", intitule : "ROYON", cp : "62990" },
{ code : "62726", intitule : "RUISSEAUVILLE", cp : "62310" },
{ code : "62727", intitule : "RUITZ", cp : "62620" },
{ code : "62728", intitule : "RUMAUCOURT", cp : "62860" },
{ code : "62729", intitule : "RUMILLY", cp : "62650" },
{ code : "62730", intitule : "RUMINGHEM", cp : "62370" },
{ code : "62731", intitule : "RUYAULCOURT", cp : "62124" },
{ code : "62732", intitule : "SACHIN", cp : "62550" },
{ code : "62733", intitule : "SAILLY AU BOIS", cp : "62111" },
{ code : "62734", intitule : "SAILLY EN OSTREVENT", cp : "62490" },
{ code : "62735", intitule : "SAILLY LABOURSE", cp : "62113" },
{ code : "62736", intitule : "SAILLY SUR LA LYS", cp : "62840" },
{ code : "62737", intitule : "SAINS EN GOHELLE", cp : "62114" },
{ code : "62738", intitule : "SAINS LES FRESSIN", cp : "62310" },
{ code : "62739", intitule : "SAINS LES MARQUION", cp : "62860" },
{ code : "62740", intitule : "SAINS LES PERNES", cp : "62550" },
{ code : "62741", intitule : "ST AMAND", cp : "62760" },
{ code : "62742", intitule : "ST AUBIN", cp : "62170" },
{ code : "62743", intitule : "STE AUSTREBERTHE", cp : "62140" },
{ code : "62744", intitule : "STE CATHERINE", cp : "62223" },
{ code : "62745", intitule : "ST DENOEUX", cp : "62990" },
{ code : "62746", intitule : "ST ETIENNE AU MONT", cp : "62360" },
{ code : "62746", intitule : "ST ETIENNE AU MONT", cp : "62360" },
{ code : "62747", intitule : "ST FLORIS", cp : "62350" },
{ code : "62748", intitule : "ST FOLQUIN", cp : "62370" },
{ code : "62749", intitule : "ST GEORGES", cp : "62770" },
{ code : "62750", intitule : "ST HILAIRE COTTES", cp : "62120" },
{ code : "62751", intitule : "ST INGLEVERT", cp : "62250" },
{ code : "62752", intitule : "ST JOSSE", cp : "62170" },
{ code : "62753", intitule : "ST LAURENT BLANGY", cp : "62223" },
{ code : "62754", intitule : "ST LEGER", cp : "62128" },
{ code : "62755", intitule : "ST LEONARD", cp : "62360" },
{ code : "62756", intitule : "STE MARIE KERQUE", cp : "62370" },
{ code : "62757", intitule : "ST MARTIN LEZ TATINGHEM", cp : "62500" },
{ code : "62757", intitule : "ST MARTIN LEZ TATINGHEM", cp : "62500" },
{ code : "62758", intitule : "ST MARTIN BOULOGNE", cp : "62280" },
{ code : "62759", intitule : "ST MARTIN CHOQUEL", cp : "62240" },
{ code : "62760", intitule : "ST MARTIN D HARDINGHEM", cp : "62560" },
{ code : "62761", intitule : "ST MARTIN SUR COJEUL", cp : "62128" },
{ code : "62762", intitule : "ST MICHEL SOUS BOIS", cp : "62650" },
{ code : "62763", intitule : "ST MICHEL SUR TERNOISE", cp : "62130" },
{ code : "62764", intitule : "ST NICOLAS", cp : "62223" },
{ code : "62765", intitule : "ST OMER", cp : "62500" },
{ code : "62766", intitule : "ST OMER CAPELLE", cp : "62162" },
{ code : "62767", intitule : "ST POL SUR TERNOISE", cp : "62130" },
{ code : "62768", intitule : "ST REMY AU BOIS", cp : "62870" },
{ code : "62769", intitule : "ST TRICAT", cp : "62185" },
{ code : "62770", intitule : "ST VENANT", cp : "62350" },
{ code : "62771", intitule : "SALLAUMINES", cp : "62430" },
{ code : "62772", intitule : "SALPERWICK", cp : "62500" },
{ code : "62773", intitule : "SAMER", cp : "62830" },
{ code : "62774", intitule : "SANGATTE", cp : "62231" },
{ code : "62774", intitule : "SANGATTE", cp : "62231" },
{ code : "62775", intitule : "SANGHEN", cp : "62850" },
{ code : "62776", intitule : "SAPIGNIES", cp : "62121" },
{ code : "62777", intitule : "LE SARS", cp : "62450" },
{ code : "62778", intitule : "SARS LE BOIS", cp : "62810" },
{ code : "62779", intitule : "SARTON", cp : "62760" },
{ code : "62780", intitule : "SAUCHY CAUCHY", cp : "62860" },
{ code : "62781", intitule : "SAUCHY LESTREE", cp : "62860" },
{ code : "62782", intitule : "SAUDEMONT", cp : "62860" },
{ code : "62783", intitule : "SAULCHOY", cp : "62870" },
{ code : "62784", intitule : "SAULTY", cp : "62158" },
{ code : "62785", intitule : "SAVY BERLETTE", cp : "62690" },
{ code : "62786", intitule : "SELLES", cp : "62240" },
{ code : "62787", intitule : "SEMPY", cp : "62170" },
{ code : "62788", intitule : "SENINGHEM", cp : "62380" },
{ code : "62789", intitule : "SENLECQUES", cp : "62240" },
{ code : "62790", intitule : "SENLIS", cp : "62310" },
{ code : "62791", intitule : "SERICOURT", cp : "62270" },
{ code : "62792", intitule : "SERQUES", cp : "62910" },
{ code : "62793", intitule : "SERVINS", cp : "62530" },
{ code : "62794", intitule : "SETQUES", cp : "62380" },
{ code : "62795", intitule : "SIBIVILLE", cp : "62270" },
{ code : "62796", intitule : "SIMENCOURT", cp : "62123" },
{ code : "62797", intitule : "SIRACOURT", cp : "62130" },
{ code : "62798", intitule : "SOMBRIN", cp : "62810" },
{ code : "62799", intitule : "SORRUS", cp : "62170" },
{ code : "62800", intitule : "SOUASTRE", cp : "62111" },
{ code : "62801", intitule : "SOUCHEZ", cp : "62153" },
{ code : "62802", intitule : "LE SOUICH", cp : "62810" },
{ code : "62803", intitule : "SURQUES", cp : "62850" },
{ code : "62804", intitule : "SUS ST LEGER", cp : "62810" },
{ code : "62805", intitule : "TANGRY", cp : "62550" },
{ code : "62806", intitule : "TARDINGHEN", cp : "62179" },
{ code : "62808", intitule : "TENEUR", cp : "62134" },
{ code : "62809", intitule : "TERNAS", cp : "62127" },
{ code : "62810", intitule : "THELUS", cp : "62580" },
{ code : "62811", intitule : "THEROUANNE", cp : "62129" },
{ code : "62812", intitule : "THIEMBRONNE", cp : "62560" },
{ code : "62813", intitule : "LA THIEULOYE", cp : "62130" },
{ code : "62814", intitule : "THIEVRES", cp : "62760" },
{ code : "62815", intitule : "TIGNY NOYELLE", cp : "62180" },
{ code : "62816", intitule : "TILLOY LES HERMAVILLE", cp : "62690" },
{ code : "62817", intitule : "TILLOY LES MOFFLAINES", cp : "62217" },
{ code : "62818", intitule : "TILLY CAPELLE", cp : "62134" },
{ code : "62819", intitule : "TILQUES", cp : "62500" },
{ code : "62820", intitule : "TINCQUES", cp : "62127" },
{ code : "62821", intitule : "TINGRY", cp : "62830" },
{ code : "62822", intitule : "TOLLENT", cp : "62390" },
{ code : "62823", intitule : "TORCY", cp : "62310" },
{ code : "62824", intitule : "TORTEFONTAINE", cp : "62140" },
{ code : "62825", intitule : "TORTEQUESNE", cp : "62490" },
{ code : "62826", intitule : "LE TOUQUET PARIS PLAGE", cp : "62520" },
{ code : "62827", intitule : "TOURNEHEM SUR LA HEM", cp : "62890" },
{ code : "62828", intitule : "TRAMECOURT", cp : "62310" },
{ code : "62829", intitule : "LE TRANSLOY", cp : "62450" },
{ code : "62830", intitule : "TRESCAULT", cp : "62147" },
{ code : "62831", intitule : "TROISVAUX", cp : "62130" },
{ code : "62832", intitule : "TUBERSENT", cp : "62630" },
{ code : "62833", intitule : "VACQUERIE LE BOUCQ", cp : "62270" },
{ code : "62834", intitule : "VACQUERIETTE ERQUIERES", cp : "62140" },
{ code : "62834", intitule : "VACQUERIETTE ERQUIERES", cp : "62140" },
{ code : "62835", intitule : "VALHUON", cp : "62550" },
{ code : "62836", intitule : "VAUDRICOURT", cp : "62131" },
{ code : "62837", intitule : "VAUDRINGHEM", cp : "62380" },
{ code : "62838", intitule : "VAULX", cp : "62390" },
{ code : "62839", intitule : "VAULX VRAUCOURT", cp : "62159" },
{ code : "62840", intitule : "VELU", cp : "62124" },
{ code : "62841", intitule : "VENDIN LES BETHUNE", cp : "62232" },
{ code : "62842", intitule : "VENDIN LE VIEIL", cp : "62880" },
{ code : "62843", intitule : "VERCHIN", cp : "62310" },
{ code : "62844", intitule : "VERCHOCQ", cp : "62560" },
{ code : "62845", intitule : "VERLINCTHUN", cp : "62830" },
{ code : "62846", intitule : "VERMELLES", cp : "62980" },
{ code : "62847", intitule : "VERQUIGNEUL", cp : "62113" },
{ code : "62848", intitule : "VERQUIN", cp : "62131" },
{ code : "62849", intitule : "VERTON", cp : "62180" },
{ code : "62850", intitule : "VIEIL HESDIN", cp : "62770" },
{ code : "62851", intitule : "VIEILLE CHAPELLE", cp : "62136" },
{ code : "62852", intitule : "VIEILLE EGLISE", cp : "62162" },
{ code : "62853", intitule : "VIEIL MOUTIER", cp : "62240" },
{ code : "62854", intitule : "VILLERS AU BOIS", cp : "62144" },
{ code : "62855", intitule : "VILLERS AU FLOS", cp : "62450" },
{ code : "62856", intitule : "VILLERS BRULIN", cp : "62690" },
{ code : "62857", intitule : "VILLERS CHATEL", cp : "62690" },
{ code : "62858", intitule : "VILLERS LES CAGNICOURT", cp : "62182" },
{ code : "62859", intitule : "VILLERS L HOPITAL", cp : "62390" },
{ code : "62860", intitule : "VILLERS SIR SIMON", cp : "62127" },
{ code : "62861", intitule : "VIMY", cp : "62580" },
{ code : "62862", intitule : "VINCLY", cp : "62310" },
{ code : "62863", intitule : "VIOLAINES", cp : "62138" },
{ code : "62864", intitule : "VIS EN ARTOIS", cp : "62156" },
{ code : "62865", intitule : "VITRY EN ARTOIS", cp : "62490" },
{ code : "62866", intitule : "WABEN", cp : "62180" },
{ code : "62867", intitule : "WACQUINGHEN", cp : "62250" },
{ code : "62868", intitule : "WAIL", cp : "62770" },
{ code : "62869", intitule : "WAILLY", cp : "62217" },
{ code : "62870", intitule : "WAILLY BEAUCAMP", cp : "62170" },
{ code : "62871", intitule : "WAMBERCOURT", cp : "62140" },
{ code : "62872", intitule : "WAMIN", cp : "62770" },
{ code : "62873", intitule : "WANCOURT", cp : "62128" },
{ code : "62874", intitule : "WANQUETIN", cp : "62123" },
{ code : "62875", intitule : "WARDRECQUES", cp : "62120" },
{ code : "62876", intitule : "WARLENCOURT EAUCOURT", cp : "62450" },
{ code : "62877", intitule : "WARLINCOURT LES PAS", cp : "62760" },
{ code : "62878", intitule : "WARLUS", cp : "62123" },
{ code : "62879", intitule : "WARLUZEL", cp : "62810" },
{ code : "62880", intitule : "LE WAST", cp : "62142" },
{ code : "62881", intitule : "BEAUVOIR WAVANS", cp : "62390" },
{ code : "62882", intitule : "WAVRANS SUR L AA", cp : "62380" },
{ code : "62883", intitule : "WAVRANS SUR TERNOISE", cp : "62130" },
{ code : "62885", intitule : "WESTREHEM", cp : "62960" },
{ code : "62886", intitule : "WICQUINGHEM", cp : "62650" },
{ code : "62887", intitule : "WIDEHEM", cp : "62630" },
{ code : "62888", intitule : "WIERRE AU BOIS", cp : "62830" },
{ code : "62889", intitule : "WIERRE EFFROY", cp : "62720" },
{ code : "62890", intitule : "WILLEMAN", cp : "62770" },
{ code : "62891", intitule : "WILLENCOURT", cp : "62390" },
{ code : "62892", intitule : "WILLERVAL", cp : "62580" },
{ code : "62893", intitule : "WIMEREUX", cp : "62930" },
{ code : "62894", intitule : "WIMILLE", cp : "62126" },
{ code : "62895", intitule : "WINGLES", cp : "62410" },
{ code : "62896", intitule : "WIRWIGNES", cp : "62240" },
{ code : "62897", intitule : "WISMES", cp : "62380" },
{ code : "62898", intitule : "WISQUES", cp : "62219" },
{ code : "62899", intitule : "WISSANT", cp : "62179" },
{ code : "62900", intitule : "WITTERNESSE", cp : "62120" },
{ code : "62901", intitule : "WITTES", cp : "62120" },
{ code : "62902", intitule : "WIZERNES", cp : "62570" },
{ code : "62903", intitule : "ZOTEUX", cp : "62650" },
{ code : "62904", intitule : "ZOUAFQUES", cp : "62890" },
{ code : "62904", intitule : "ZOUAFQUES", cp : "62890" },
{ code : "62905", intitule : "ZUDAUSQUES", cp : "62500" },
{ code : "62906", intitule : "ZUTKERQUE", cp : "62370" },
{ code : "62907", intitule : "LIBERCOURT", cp : "62820" },
{ code : "62908", intitule : "LA CAPELLE LES BOULOGNE", cp : "62360" },
{ code : "62909", intitule : "YTRES", cp : "62124" },
{ code : "63001", intitule : "AIGUEPERSE", cp : "63260" },
{ code : "63002", intitule : "AIX LA FAYETTE", cp : "63980" },
{ code : "63003", intitule : "AMBERT", cp : "63600" },
{ code : "63004", intitule : "LES ANCIZES COMPS", cp : "63770" },
{ code : "63005", intitule : "ANTOINGT", cp : "63340" },
{ code : "63006", intitule : "ANZAT LE LUGUET", cp : "63420" },
{ code : "63007", intitule : "APCHAT", cp : "63420" },
{ code : "63008", intitule : "ARCONSAT", cp : "63250" },
{ code : "63009", intitule : "ARDES", cp : "63420" },
{ code : "63010", intitule : "ARLANC", cp : "63220" },
{ code : "63011", intitule : "ARS LES FAVETS", cp : "63700" },
{ code : "63012", intitule : "ARTONNE", cp : "63460" },
{ code : "63013", intitule : "AUBIAT", cp : "63260" },
{ code : "63013", intitule : "AUBIAT", cp : "63260" },
{ code : "63014", intitule : "AUBIERE", cp : "63170" },
{ code : "63015", intitule : "AUBUSSON D AUVERGNE", cp : "63120" },
{ code : "63016", intitule : "AUGEROLLES", cp : "63930" },
{ code : "63017", intitule : "AUGNAT", cp : "63340" },
{ code : "63019", intitule : "AULNAT", cp : "63510" },
{ code : "63020", intitule : "AURIERES", cp : "63210" },
{ code : "63021", intitule : "AUTHEZAT", cp : "63114" },
{ code : "63022", intitule : "AUZAT LA COMBELLE", cp : "63570" },
{ code : "63022", intitule : "AUZAT LA COMBELLE", cp : "63570" },
{ code : "63023", intitule : "AUZELLES", cp : "63590" },
{ code : "63024", intitule : "AVEZE", cp : "63690" },
{ code : "63025", intitule : "AYAT SUR SIOULE", cp : "63390" },
{ code : "63026", intitule : "AYDAT", cp : "63970" },
{ code : "63027", intitule : "BAFFIE", cp : "63600" },
{ code : "63028", intitule : "BAGNOLS", cp : "63810" },
{ code : "63029", intitule : "BANSAT", cp : "63570" },
{ code : "63030", intitule : "BAS ET LEZAT", cp : "63310" },
{ code : "63031", intitule : "BEAULIEU", cp : "63570" },
{ code : "63032", intitule : "BEAUMONT", cp : "63110" },
{ code : "63033", intitule : "BEAUMONT LES RANDAN", cp : "63310" },
{ code : "63034", intitule : "BEAUREGARD L EVEQUE", cp : "63116" },
{ code : "63035", intitule : "BEAUREGARD VENDON", cp : "63460" },
{ code : "63036", intitule : "BERGONNE", cp : "63500" },
{ code : "63037", intitule : "BERTIGNAT", cp : "63480" },
{ code : "63038", intitule : "BESSE ET ST ANASTAISE", cp : "63610" },
{ code : "63038", intitule : "BESSE ET ST ANASTAISE", cp : "63610" },
{ code : "63038", intitule : "BESSE ET ST ANASTAISE", cp : "63610" },
{ code : "63039", intitule : "BEURIERES", cp : "63220" },
{ code : "63040", intitule : "BILLOM", cp : "63160" },
{ code : "63041", intitule : "BIOLLET", cp : "63640" },
{ code : "63042", intitule : "BLANZAT", cp : "63112" },
{ code : "63043", intitule : "BLOT L EGLISE", cp : "63440" },
{ code : "63044", intitule : "BONGHEAT", cp : "63160" },
{ code : "63045", intitule : "BORT L ETANG", cp : "63190" },
{ code : "63046", intitule : "BOUDES", cp : "63340" },
{ code : "63047", intitule : "LA BOURBOULE", cp : "63150" },
{ code : "63048", intitule : "BOURG LASTIC", cp : "63760" },
{ code : "63049", intitule : "BOUZEL", cp : "63910" },
{ code : "63050", intitule : "BRASSAC LES MINES", cp : "63570" },
{ code : "63051", intitule : "BRENAT", cp : "63500" },
{ code : "63052", intitule : "LE BREUIL SUR COUZE", cp : "63340" },
{ code : "63053", intitule : "BRIFFONS", cp : "63820" },
{ code : "63054", intitule : "LE BROC", cp : "63500" },
{ code : "63055", intitule : "BROMONT LAMOTHE", cp : "63230" },
{ code : "63056", intitule : "BROUSSE", cp : "63490" },
{ code : "63057", intitule : "LE BRUGERON", cp : "63880" },
{ code : "63058", intitule : "BULHON", cp : "63350" },
{ code : "63059", intitule : "BUSSEOL", cp : "63270" },
{ code : "63060", intitule : "BUSSIERES", cp : "63330" },
{ code : "63061", intitule : "BUSSIERES ET PRUNS", cp : "63260" },
{ code : "63062", intitule : "BUXIERES SOUS MONTAIGUT", cp : "63700" },
{ code : "63063", intitule : "CEBAZAT", cp : "63118" },
{ code : "63064", intitule : "LA CELLE", cp : "63620" },
{ code : "63065", intitule : "CEILLOUX", cp : "63520" },
{ code : "63066", intitule : "CELLES SUR DUROLLE", cp : "63250" },
{ code : "63066", intitule : "CELLES SUR DUROLLE", cp : "63250" },
{ code : "63067", intitule : "LA CELLETTE", cp : "63330" },
{ code : "63069", intitule : "LE CENDRE", cp : "63670" },
{ code : "63070", intitule : "CEYRAT", cp : "63122" },
{ code : "63070", intitule : "CEYRAT", cp : "63122" },
{ code : "63071", intitule : "CEYSSAT", cp : "63210" },
{ code : "63072", intitule : "CHABRELOCHE", cp : "63250" },
{ code : "63073", intitule : "CHADELEUF", cp : "63320" },
{ code : "63074", intitule : "CHALUS", cp : "63340" },
{ code : "63075", intitule : "CHAMALIERES", cp : "63400" },
{ code : "63076", intitule : "CHAMBON SUR DOLORE", cp : "63980" },
{ code : "63077", intitule : "CHAMBON SUR LAC", cp : "63790" },
{ code : "63078", intitule : "CHAMEANE", cp : "63580" },
{ code : "63079", intitule : "CHAMPAGNAT LE JEUNE", cp : "63580" },
{ code : "63080", intitule : "CHAMPEIX", cp : "63320" },
{ code : "63081", intitule : "CHAMPETIERES", cp : "63600" },
{ code : "63082", intitule : "CHAMPS", cp : "63440" },
{ code : "63083", intitule : "CHANAT LA MOUTEYRE", cp : "63530" },
{ code : "63084", intitule : "CHANONAT", cp : "63450" },
{ code : "63085", intitule : "CHAPDES BEAUFORT", cp : "63230" },
{ code : "63086", intitule : "LA CHAPELLE AGNON", cp : "63590" },
{ code : "63087", intitule : "LA CHAPELLE MARCOUSSE", cp : "63420" },
{ code : "63088", intitule : "LA CHAPELLE SUR USSON", cp : "63580" },
{ code : "63089", intitule : "CHAPPES", cp : "63720" },
{ code : "63090", intitule : "CHAPTUZAT", cp : "63260" },
{ code : "63091", intitule : "CHARBONNIER LES MINES", cp : "63340" },
{ code : "63092", intitule : "CHARBONNIERES LES VARENNES", cp : "63410" },
{ code : "63092", intitule : "CHARBONNIERES LES VARENNES", cp : "63410" },
{ code : "63093", intitule : "CHARBONNIERES LES VIEILLES", cp : "63410" },
{ code : "63094", intitule : "CHARENSAT", cp : "63640" },
{ code : "63095", intitule : "CHARNAT", cp : "63290" },
{ code : "63096", intitule : "CHAS", cp : "63160" },
{ code : "63097", intitule : "CHASSAGNE", cp : "63320" },
{ code : "63098", intitule : "CHASTREIX", cp : "63680" },
{ code : "63099", intitule : "CHATEAUGAY", cp : "63119" },
{ code : "63100", intitule : "CHATEAUNEUF LES BAINS", cp : "63390" },
{ code : "63101", intitule : "CHATEAU SUR CHER", cp : "63330" },
{ code : "63102", intitule : "CHATELDON", cp : "63290" },
{ code : "63103", intitule : "CHATEL GUYON", cp : "63140" },
{ code : "63103", intitule : "CHATEL GUYON", cp : "63140" },
{ code : "63104", intitule : "LA CHAULME", cp : "63660" },
{ code : "63105", intitule : "CHAUMONT LE BOURG", cp : "63220" },
{ code : "63106", intitule : "CHAURIAT", cp : "63117" },
{ code : "63107", intitule : "CHAVAROUX", cp : "63720" },
{ code : "63108", intitule : "LE CHEIX", cp : "63200" },
{ code : "63109", intitule : "CHIDRAC", cp : "63320" },
{ code : "63110", intitule : "CISTERNES LA FORET", cp : "63740" },
{ code : "63111", intitule : "CLEMENSAT", cp : "63320" },
{ code : "63112", intitule : "CLERLANDE", cp : "63720" },
{ code : "63113", intitule : "CLERMONT FERRAND", cp : "63000" },
{ code : "63113", intitule : "CLERMONT FERRAND", cp : "63100" },
{ code : "63114", intitule : "COLLANGES", cp : "63340" },
{ code : "63115", intitule : "COMBRAILLES", cp : "63380" },
{ code : "63116", intitule : "COMBRONDE", cp : "63460" },
{ code : "63117", intitule : "COMPAINS", cp : "63610" },
{ code : "63118", intitule : "CONDAT EN COMBRAILLE", cp : "63380" },
{ code : "63119", intitule : "CONDAT LES MONTBOISSIER", cp : "63490" },
{ code : "63120", intitule : "CORENT", cp : "63730" },
{ code : "63121", intitule : "COUDES", cp : "63114" },
{ code : "63122", intitule : "COURGOUL", cp : "63320" },
{ code : "63123", intitule : "COURNOLS", cp : "63450" },
{ code : "63124", intitule : "COURNON D AUVERGNE", cp : "63800" },
{ code : "63125", intitule : "COURPIERE", cp : "63120" },
{ code : "63126", intitule : "LE CREST", cp : "63450" },
{ code : "63127", intitule : "CRESTE", cp : "63320" },
{ code : "63128", intitule : "CREVANT LAVEINE", cp : "63350" },
{ code : "63129", intitule : "CROS", cp : "63810" },
{ code : "63130", intitule : "LA CROUZILLE", cp : "63700" },
{ code : "63131", intitule : "CULHAT", cp : "63350" },
{ code : "63132", intitule : "CUNLHAT", cp : "63590" },
{ code : "63133", intitule : "DALLET", cp : "63111" },
{ code : "63134", intitule : "DAUZAT SUR VODABLE", cp : "63340" },
{ code : "63135", intitule : "DAVAYAT", cp : "63200" },
{ code : "63136", intitule : "DOMAIZE", cp : "63520" },
{ code : "63137", intitule : "DORANGES", cp : "63220" },
{ code : "63138", intitule : "DORAT", cp : "63300" },
{ code : "63139", intitule : "DORE L EGLISE", cp : "63220" },
{ code : "63140", intitule : "DURMIGNAT", cp : "63700" },
{ code : "63141", intitule : "DURTOL", cp : "63830" },
{ code : "63142", intitule : "ECHANDELYS", cp : "63980" },
{ code : "63143", intitule : "EFFIAT", cp : "63260" },
{ code : "63144", intitule : "EGLISENEUVE D ENTRAIGUES", cp : "63850" },
{ code : "63145", intitule : "EGLISENEUVE DES LIARDS", cp : "63490" },
{ code : "63146", intitule : "EGLISENEUVE PRES BILLOM", cp : "63160" },
{ code : "63147", intitule : "EGLISOLLES", cp : "63840" },
{ code : "63148", intitule : "ENNEZAT", cp : "63720" },
{ code : "63149", intitule : "ENTRAIGUES", cp : "63720" },
{ code : "63150", intitule : "ENVAL", cp : "63530" },
{ code : "63151", intitule : "ESCOUTOUX", cp : "63300" },
{ code : "63152", intitule : "ESPINASSE", cp : "63390" },
{ code : "63153", intitule : "ESPINCHAL", cp : "63850" },
{ code : "63154", intitule : "ESPIRAT", cp : "63160" },
{ code : "63155", intitule : "ESTANDEUIL", cp : "63520" },
{ code : "63156", intitule : "ESTEIL", cp : "63570" },
{ code : "63157", intitule : "FAYET LE CHATEAU", cp : "63160" },
{ code : "63158", intitule : "FAYET RONAYE", cp : "63630" },
{ code : "63159", intitule : "FERNOEL", cp : "63620" },
{ code : "63160", intitule : "AULHAT FLAT", cp : "63500" },
{ code : "63160", intitule : "AULHAT FLAT", cp : "63500" },
{ code : "63161", intitule : "LA FORIE", cp : "63600" },
{ code : "63162", intitule : "FOURNOLS", cp : "63980" },
{ code : "63163", intitule : "GELLES", cp : "63740" },
{ code : "63164", intitule : "GERZAT", cp : "63360" },
{ code : "63165", intitule : "GIAT", cp : "63620" },
{ code : "63166", intitule : "GIGNAT", cp : "63340" },
{ code : "63167", intitule : "GIMEAUX", cp : "63200" },
{ code : "63168", intitule : "GLAINE MONTAIGUT", cp : "63160" },
{ code : "63169", intitule : "LA GODIVELLE", cp : "63850" },
{ code : "63170", intitule : "LA GOUTELLE", cp : "63230" },
{ code : "63171", intitule : "GOUTTIERES", cp : "63390" },
{ code : "63172", intitule : "GRANDEYROLLES", cp : "63320" },
{ code : "63173", intitule : "GRANDRIF", cp : "63600" },
{ code : "63174", intitule : "GRANDVAL", cp : "63890" },
{ code : "63175", intitule : "HERMENT", cp : "63470" },
{ code : "63176", intitule : "HEUME L EGLISE", cp : "63210" },
{ code : "63177", intitule : "ISSERTEAUX", cp : "63270" },
{ code : "63178", intitule : "ISSOIRE", cp : "63500" },
{ code : "63179", intitule : "JOB", cp : "63990" },
{ code : "63180", intitule : "JOZE", cp : "63350" },
{ code : "63181", intitule : "JOZERAND", cp : "63460" },
{ code : "63182", intitule : "JUMEAUX", cp : "63570" },
{ code : "63183", intitule : "LABESSETTE", cp : "63690" },
{ code : "63184", intitule : "LACHAUX", cp : "63290" },
{ code : "63185", intitule : "LAMONTGIE", cp : "63570" },
{ code : "63186", intitule : "LANDOGNE", cp : "63380" },
{ code : "63187", intitule : "LAPEYROUSE", cp : "63700" },
{ code : "63188", intitule : "LAPS", cp : "63270" },
{ code : "63189", intitule : "LAQUEUILLE", cp : "63820" },
{ code : "63190", intitule : "LARODDE", cp : "63690" },
{ code : "63191", intitule : "LASTIC", cp : "63760" },
{ code : "63192", intitule : "LA TOUR D AUVERGNE", cp : "63680" },
{ code : "63193", intitule : "LEMPDES", cp : "63370" },
{ code : "63194", intitule : "LEMPTY", cp : "63190" },
{ code : "63195", intitule : "LEZOUX", cp : "63190" },
{ code : "63196", intitule : "LIMONS", cp : "63290" },
{ code : "63197", intitule : "LISSEUIL", cp : "63440" },
{ code : "63198", intitule : "LOUBEYRAT", cp : "63410" },
{ code : "63199", intitule : "LUDESSE", cp : "63320" },
{ code : "63200", intitule : "LUSSAT", cp : "63360" },
{ code : "63201", intitule : "LUZILLAT", cp : "63350" },
{ code : "63202", intitule : "MADRIAT", cp : "63340" },
{ code : "63203", intitule : "MALAUZAT", cp : "63200" },
{ code : "63204", intitule : "MALINTRAT", cp : "63510" },
{ code : "63205", intitule : "MANGLIEU", cp : "63270" },
{ code : "63206", intitule : "MANZAT", cp : "63410" },
{ code : "63207", intitule : "MARAT", cp : "63480" },
{ code : "63208", intitule : "MARCILLAT", cp : "63440" },
{ code : "63209", intitule : "MAREUGHEOL", cp : "63340" },
{ code : "63210", intitule : "MARINGUES", cp : "63350" },
{ code : "63211", intitule : "MARSAC EN LIVRADOIS", cp : "63940" },
{ code : "63212", intitule : "MARSAT", cp : "63200" },
{ code : "63213", intitule : "LES MARTRES D ARTIERE", cp : "63430" },
{ code : "63214", intitule : "LES MARTRES DE VEYRE", cp : "63730" },
{ code : "63215", intitule : "MARTRES SUR MORGE", cp : "63720" },
{ code : "63216", intitule : "MAUZUN", cp : "63160" },
{ code : "63218", intitule : "MAYRES", cp : "63220" },
{ code : "63219", intitule : "MAZAYE", cp : "63230" },
{ code : "63220", intitule : "MAZOIRES", cp : "63420" },
{ code : "63221", intitule : "MEDEYROLLES", cp : "63220" },
{ code : "63222", intitule : "MEILHAUD", cp : "63320" },
{ code : "63223", intitule : "MENAT", cp : "63560" },
{ code : "63224", intitule : "MENETROL", cp : "63200" },
{ code : "63225", intitule : "MESSEIX", cp : "63750" },
{ code : "63225", intitule : "MESSEIX", cp : "63750" },
{ code : "63225", intitule : "MESSEIX", cp : "63750" },
{ code : "63226", intitule : "MEZEL", cp : "63115" },
{ code : "63227", intitule : "MIREFLEURS", cp : "63730" },
{ code : "63228", intitule : "MIREMONT", cp : "63380" },
{ code : "63229", intitule : "MOISSAT", cp : "63190" },
{ code : "63230", intitule : "LE MONESTIER", cp : "63890" },
{ code : "63231", intitule : "LA MONNERIE LE MONTEL", cp : "63650" },
{ code : "63232", intitule : "MONS", cp : "63310" },
{ code : "63233", intitule : "MONTAIGUT", cp : "63700" },
{ code : "63234", intitule : "MONTAIGUT LE BLANC", cp : "63320" },
{ code : "63235", intitule : "MONTCEL", cp : "63460" },
{ code : "63236", intitule : "MONT DORE", cp : "63240" },
{ code : "63237", intitule : "MONTEL DE GELAT", cp : "63380" },
{ code : "63238", intitule : "MONTFERMY", cp : "63230" },
{ code : "63239", intitule : "MONTMORIN", cp : "63160" },
{ code : "63240", intitule : "MONTPENSIER", cp : "63260" },
{ code : "63241", intitule : "MONTPEYROUX", cp : "63114" },
{ code : "63242", intitule : "MORIAT", cp : "63340" },
{ code : "63243", intitule : "MOUREUILLE", cp : "63700" },
{ code : "63244", intitule : "CHAMBARON SUR MORGE", cp : "63200" },
{ code : "63244", intitule : "CHAMBARON SUR MORGE", cp : "63200" },
{ code : "63245", intitule : "MOZAC", cp : "63200" },
{ code : "63246", intitule : "MURAT LE QUAIRE", cp : "63150" },
{ code : "63247", intitule : "MUROL", cp : "63790" },
{ code : "63248", intitule : "NEBOUZAT", cp : "63210" },
{ code : "63249", intitule : "NERONDE SUR DORE", cp : "63120" },
{ code : "63250", intitule : "NESCHERS", cp : "63320" },
{ code : "63251", intitule : "NEUF EGLISE", cp : "63560" },
{ code : "63252", intitule : "NEUVILLE", cp : "63160" },
{ code : "63253", intitule : "NOALHAT", cp : "63290" },
{ code : "63254", intitule : "NOHANENT", cp : "63830" },
{ code : "63255", intitule : "NONETTE ORSONNETTE", cp : "63340" },
{ code : "63255", intitule : "NONETTE ORSONNETTE", cp : "63340" },
{ code : "63256", intitule : "NOVACELLES", cp : "63220" },
{ code : "63257", intitule : "OLBY", cp : "63210" },
{ code : "63258", intitule : "OLLIERGUES", cp : "63880" },
{ code : "63259", intitule : "OLLOIX", cp : "63450" },
{ code : "63260", intitule : "OLMET", cp : "63880" },
{ code : "63261", intitule : "ORBEIL", cp : "63500" },
{ code : "63262", intitule : "ORCET", cp : "63670" },
{ code : "63263", intitule : "ORCINES", cp : "63870" },
{ code : "63264", intitule : "ORCIVAL", cp : "63210" },
{ code : "63265", intitule : "ORLEAT", cp : "63190" },
{ code : "63267", intitule : "PALLADUC", cp : "63550" },
{ code : "63268", intitule : "PARDINES", cp : "63500" },
{ code : "63269", intitule : "PARENT", cp : "63270" },
{ code : "63270", intitule : "PARENTIGNAT", cp : "63500" },
{ code : "63271", intitule : "PASLIERES", cp : "63290" },
{ code : "63272", intitule : "PERIGNAT LES SARLIEVE", cp : "63170" },
{ code : "63273", intitule : "PERIGNAT SUR ALLIER", cp : "63800" },
{ code : "63274", intitule : "PERPEZAT", cp : "63210" },
{ code : "63275", intitule : "PERRIER", cp : "63500" },
{ code : "63276", intitule : "PESCHADOIRES", cp : "63920" },
{ code : "63276", intitule : "PESCHADOIRES", cp : "63920" },
{ code : "63277", intitule : "PESLIERES", cp : "63580" },
{ code : "63278", intitule : "PESSAT VILLENEUVE", cp : "63200" },
{ code : "63279", intitule : "PICHERANDE", cp : "63113" },
{ code : "63280", intitule : "PIGNOLS", cp : "63270" },
{ code : "63281", intitule : "PIONSAT", cp : "63330" },
{ code : "63282", intitule : "PLAUZAT", cp : "63730" },
{ code : "63283", intitule : "PONTAUMUR", cp : "63380" },
{ code : "63284", intitule : "PONT DU CHATEAU", cp : "63430" },
{ code : "63285", intitule : "PONTGIBAUD", cp : "63230" },
{ code : "63286", intitule : "POUZOL", cp : "63440" },
{ code : "63287", intitule : "LES PRADEAUX", cp : "63500" },
{ code : "63288", intitule : "PROMPSAT", cp : "63200" },
{ code : "63289", intitule : "PRONDINES", cp : "63470" },
{ code : "63290", intitule : "PULVERIERES", cp : "63230" },
{ code : "63291", intitule : "PUY GUILLAUME", cp : "63290" },
{ code : "63292", intitule : "PUY ST GULMIER", cp : "63470" },
{ code : "63293", intitule : "LE QUARTIER", cp : "63330" },
{ code : "63294", intitule : "QUEUILLE", cp : "63780" },
{ code : "63295", intitule : "RANDAN", cp : "63310" },
{ code : "63296", intitule : "RAVEL", cp : "63190" },
{ code : "63297", intitule : "REIGNAT", cp : "63160" },
{ code : "63298", intitule : "LA RENAUDIE", cp : "63930" },
{ code : "63299", intitule : "RENTIERES", cp : "63420" },
{ code : "63300", intitule : "RIOM", cp : "63200" },
{ code : "63301", intitule : "RIS", cp : "63290" },
{ code : "63302", intitule : "LA ROCHE BLANCHE", cp : "63670" },
{ code : "63303", intitule : "ROCHE CHARLES LA MAYRAND", cp : "63420" },
{ code : "63303", intitule : "ROCHE CHARLES LA MAYRAND", cp : "63420" },
{ code : "63304", intitule : "ROCHE D AGOUX", cp : "63330" },
{ code : "63305", intitule : "ROCHEFORT MONTAGNE", cp : "63210" },
{ code : "63306", intitule : "LA ROCHE NOIRE", cp : "63800" },
{ code : "63307", intitule : "ROMAGNAT", cp : "63540" },
{ code : "63307", intitule : "ROMAGNAT", cp : "63540" },
{ code : "63307", intitule : "ROMAGNAT", cp : "63540" },
{ code : "63308", intitule : "ROYAT", cp : "63130" },
{ code : "63309", intitule : "SAILLANT", cp : "63840" },
{ code : "63310", intitule : "STE AGATHE", cp : "63120" },
{ code : "63311", intitule : "ST AGOULIN", cp : "63260" },
{ code : "63312", intitule : "ST ALYRE D ARLANC", cp : "63220" },
{ code : "63313", intitule : "ST ALYRE ES MONTAGNE", cp : "63420" },
{ code : "63314", intitule : "ST AMANT ROCHE SAVINE", cp : "63890" },
{ code : "63315", intitule : "ST AMANT TALLENDE", cp : "63450" },
{ code : "63317", intitule : "ST ANDRE LE COQ", cp : "63310" },
{ code : "63318", intitule : "ST ANGEL", cp : "63410" },
{ code : "63319", intitule : "ST ANTHEME", cp : "63660" },
{ code : "63320", intitule : "ST AVIT", cp : "63380" },
{ code : "63321", intitule : "ST BABEL", cp : "63500" },
{ code : "63322", intitule : "ST BEAUZIRE", cp : "63360" },
{ code : "63323", intitule : "ST BONNET LE BOURG", cp : "63630" },
{ code : "63324", intitule : "ST BONNET LE CHASTEL", cp : "63630" },
{ code : "63325", intitule : "ST BONNET LES ALLIER", cp : "63800" },
{ code : "63326", intitule : "ST BONNET PRES ORCIVAL", cp : "63210" },
{ code : "63327", intitule : "ST BONNET PRES RIOM", cp : "63200" },
{ code : "63328", intitule : "STE CATHERINE", cp : "63580" },
{ code : "63329", intitule : "STE CHRISTINE", cp : "63390" },
{ code : "63330", intitule : "ST CIRGUES SUR COUZE", cp : "63320" },
{ code : "63331", intitule : "ST CLEMENT DE VALORGUE", cp : "63660" },
{ code : "63332", intitule : "ST CLEMENT DE REGNAT", cp : "63310" },
{ code : "63333", intitule : "ST DENIS COMBARNAZAT", cp : "63310" },
{ code : "63334", intitule : "ST DIER D AUVERGNE", cp : "63520" },
{ code : "63335", intitule : "ST DIERY", cp : "63320" },
{ code : "63336", intitule : "ST DONAT", cp : "63680" },
{ code : "63337", intitule : "ST ELOY LA GLACIERE", cp : "63890" },
{ code : "63338", intitule : "ST ELOY LES MINES", cp : "63700" },
{ code : "63339", intitule : "ST ETIENNE DES CHAMPS", cp : "63380" },
{ code : "63340", intitule : "ST ETIENNE SUR USSON", cp : "63580" },
{ code : "63341", intitule : "ST FERREOL DES COTES", cp : "63600" },
{ code : "63342", intitule : "ST FLORET", cp : "63320" },
{ code : "63343", intitule : "ST FLOUR", cp : "63520" },
{ code : "63344", intitule : "ST GAL SUR SIOULE", cp : "63440" },
{ code : "63345", intitule : "ST GENES CHAMPANELLE", cp : "63122" },
{ code : "63345", intitule : "ST GENES CHAMPANELLE", cp : "63122" },
{ code : "63345", intitule : "ST GENES CHAMPANELLE", cp : "63122" },
{ code : "63345", intitule : "ST GENES CHAMPANELLE", cp : "63122" },
{ code : "63345", intitule : "ST GENES CHAMPANELLE", cp : "63122" },
{ code : "63346", intitule : "ST GENES CHAMPESPE", cp : "63850" },
{ code : "63347", intitule : "ST GENES DU RETZ", cp : "63260" },
{ code : "63348", intitule : "ST GENES LA TOURETTE", cp : "63580" },
{ code : "63349", intitule : "ST GEORGES DE MONS", cp : "63780" },
{ code : "63350", intitule : "ST GEORGES SUR ALLIER", cp : "63800" },
{ code : "63351", intitule : "ST GERMAIN PRES HERMENT", cp : "63470" },
{ code : "63352", intitule : "ST GERMAIN LEMBRON", cp : "63340" },
{ code : "63353", intitule : "ST GERMAIN L HERM", cp : "63630" },
{ code : "63354", intitule : "ST GERVAIS D AUVERGNE", cp : "63390" },
{ code : "63355", intitule : "ST GERVAIS SOUS MEYMONT", cp : "63880" },
{ code : "63356", intitule : "ST GERVAZY", cp : "63340" },
{ code : "63357", intitule : "ST HERENT", cp : "63340" },
{ code : "63358", intitule : "ST HILAIRE LA CROIX", cp : "63440" },
{ code : "63359", intitule : "ST HILAIRE LES MONGES", cp : "63380" },
{ code : "63360", intitule : "ST HILAIRE", cp : "63330" },
{ code : "63362", intitule : "ST IGNAT", cp : "63720" },
{ code : "63363", intitule : "ST JACQUES D AMBUR", cp : "63230" },
{ code : "63364", intitule : "ST JEAN D HEURS", cp : "63190" },
{ code : "63365", intitule : "ST JEAN DES OLLIERES", cp : "63520" },
{ code : "63366", intitule : "ST JEAN EN VAL", cp : "63490" },
{ code : "63367", intitule : "ST JEAN ST GERVAIS", cp : "63570" },
{ code : "63368", intitule : "ST JULIEN DE COPPEL", cp : "63160" },
{ code : "63369", intitule : "ST JULIEN LA GENESTE", cp : "63390" },
{ code : "63370", intitule : "ST JULIEN PUY LAVEZE", cp : "63820" },
{ code : "63371", intitule : "ST JUST", cp : "63600" },
{ code : "63372", intitule : "ST LAURE", cp : "63350" },
{ code : "63373", intitule : "ST MAIGNER", cp : "63330" },
{ code : "63374", intitule : "ST MARTIN DES OLMES", cp : "63600" },
{ code : "63375", intitule : "ST MARTIN DES PLAINS", cp : "63570" },
{ code : "63376", intitule : "ST MARTIN D OLLIERES", cp : "63580" },
{ code : "63377", intitule : "ST MAURICE PRES PIONSAT", cp : "63330" },
{ code : "63378", intitule : "ST MAURICE", cp : "63270" },
{ code : "63379", intitule : "ST MYON", cp : "63460" },
{ code : "63380", intitule : "ST NECTAIRE", cp : "63710" },
{ code : "63381", intitule : "ST OURS", cp : "63230" },
{ code : "63382", intitule : "ST PARDOUX", cp : "63440" },
{ code : "63383", intitule : "ST PIERRE COLAMINE", cp : "63610" },
{ code : "63384", intitule : "ST PIERRE LA BOURLHONNE", cp : "63480" },
{ code : "63385", intitule : "ST PIERRE LE CHASTEL", cp : "63230" },
{ code : "63386", intitule : "ST PIERRE ROCHE", cp : "63210" },
{ code : "63387", intitule : "ST PRIEST BRAMEFANT", cp : "63310" },
{ code : "63388", intitule : "ST PRIEST DES CHAMPS", cp : "63640" },
{ code : "63389", intitule : "ST QUENTIN SUR SAUXILLANGES", cp : "63490" },
{ code : "63390", intitule : "ST QUINTIN SUR SIOULE", cp : "63440" },
{ code : "63391", intitule : "ST REMY DE BLOT", cp : "63440" },
{ code : "63392", intitule : "ST REMY DE CHARGNAT", cp : "63500" },
{ code : "63393", intitule : "ST REMY SUR DUROLLE", cp : "63550" },
{ code : "63394", intitule : "ST ROMAIN", cp : "63660" },
{ code : "63395", intitule : "ST SANDOUX", cp : "63450" },
{ code : "63396", intitule : "ST SATURNIN", cp : "63450" },
{ code : "63397", intitule : "ST SAUVES D AUVERGNE", cp : "63950" },
{ code : "63398", intitule : "ST SAUVEUR LA SAGNE", cp : "63220" },
{ code : "63399", intitule : "ST SULPICE", cp : "63760" },
{ code : "63400", intitule : "ST SYLVESTRE PRAGOULIN", cp : "63310" },
{ code : "63401", intitule : "ST VICTOR LA RIVIERE", cp : "63790" },
{ code : "63402", intitule : "ST VICTOR MONTVIANEIX", cp : "63550" },
{ code : "63403", intitule : "ST VINCENT", cp : "63320" },
{ code : "63404", intitule : "ST YVOINE", cp : "63500" },
{ code : "63405", intitule : "SALLEDES", cp : "63270" },
{ code : "63406", intitule : "SARDON", cp : "63260" },
{ code : "63407", intitule : "SAULZET LE FROID", cp : "63970" },
{ code : "63408", intitule : "SAURET BESSERVE", cp : "63390" },
{ code : "63409", intitule : "SAURIER", cp : "63320" },
{ code : "63410", intitule : "SAUVAGNAT", cp : "63470" },
{ code : "63411", intitule : "SAUVAGNAT STE MARTHE", cp : "63500" },
{ code : "63412", intitule : "SAUVESSANGES", cp : "63840" },
{ code : "63413", intitule : "LA SAUVETAT", cp : "63730" },
{ code : "63414", intitule : "SAUVIAT", cp : "63120" },
{ code : "63415", intitule : "SAUXILLANGES", cp : "63490" },
{ code : "63416", intitule : "SAVENNES", cp : "63750" },
{ code : "63417", intitule : "SAYAT", cp : "63530" },
{ code : "63418", intitule : "SERMENTIZON", cp : "63120" },
{ code : "63419", intitule : "SERVANT", cp : "63560" },
{ code : "63420", intitule : "SEYCHALLES", cp : "63190" },
{ code : "63421", intitule : "SINGLES", cp : "63690" },
{ code : "63422", intitule : "SOLIGNAT", cp : "63500" },
{ code : "63423", intitule : "SUGERES", cp : "63490" },
{ code : "63424", intitule : "SURAT", cp : "63720" },
{ code : "63425", intitule : "TALLENDE", cp : "63450" },
{ code : "63426", intitule : "TAUVES", cp : "63690" },
{ code : "63427", intitule : "TEILHEDE", cp : "63460" },
{ code : "63428", intitule : "TEILHET", cp : "63560" },
{ code : "63429", intitule : "TERNANT LES EAUX", cp : "63340" },
{ code : "63430", intitule : "THIERS", cp : "63300" },
{ code : "63431", intitule : "THIOLIERES", cp : "63600" },
{ code : "63432", intitule : "THURET", cp : "63260" },
{ code : "63433", intitule : "TORTEBESSE", cp : "63470" },
{ code : "63434", intitule : "TOURS SUR MEYMONT", cp : "63590" },
{ code : "63435", intitule : "TOURZEL RONZIERES", cp : "63320" },
{ code : "63436", intitule : "TRALAIGUES", cp : "63380" },
{ code : "63437", intitule : "TREMOUILLE ST LOUP", cp : "63810" },
{ code : "63438", intitule : "TREZIOUX", cp : "63520" },
{ code : "63439", intitule : "USSON", cp : "63490" },
{ code : "63440", intitule : "VALBELEIX", cp : "63610" },
{ code : "63441", intitule : "VALCIVIERES", cp : "63600" },
{ code : "63442", intitule : "VALZ SOUS CHATEAUNEUF", cp : "63580" },
{ code : "63443", intitule : "VARENNES SUR MORGE", cp : "63720" },
{ code : "63444", intitule : "VARENNES SUR USSON", cp : "63500" },
{ code : "63445", intitule : "VASSEL", cp : "63910" },
{ code : "63446", intitule : "VENSAT", cp : "63260" },
{ code : "63447", intitule : "VERGHEAS", cp : "63330" },
{ code : "63448", intitule : "VERNET LA VARENNE", cp : "63580" },
{ code : "63449", intitule : "LE VERNET STE MARGUERITE", cp : "63710" },
{ code : "63450", intitule : "VERNEUGHEOL", cp : "63470" },
{ code : "63451", intitule : "VERNINES", cp : "63210" },
{ code : "63452", intitule : "VERRIERES", cp : "63320" },
{ code : "63453", intitule : "VERTAIZON", cp : "63910" },
{ code : "63454", intitule : "VERTOLAYE", cp : "63480" },
{ code : "63455", intitule : "VEYRE MONTON", cp : "63960" },
{ code : "63456", intitule : "VICHEL", cp : "63340" },
{ code : "63457", intitule : "VIC LE COMTE", cp : "63270" },
{ code : "63457", intitule : "VIC LE COMTE", cp : "63270" },
{ code : "63458", intitule : "VILLENEUVE", cp : "63340" },
{ code : "63459", intitule : "VILLENEUVE LES CERFS", cp : "63310" },
{ code : "63460", intitule : "VILLOSANGES", cp : "63380" },
{ code : "63461", intitule : "VINZELLES", cp : "63350" },
{ code : "63462", intitule : "VIRLET", cp : "63330" },
{ code : "63463", intitule : "VISCOMTAT", cp : "63250" },
{ code : "63464", intitule : "VITRAC", cp : "63410" },
{ code : "63465", intitule : "VIVEROLS", cp : "63840" },
{ code : "63466", intitule : "VODABLE", cp : "63500" },
{ code : "63467", intitule : "VOINGT", cp : "63620" },
{ code : "63468", intitule : "VOLLORE MONTAGNE", cp : "63120" },
{ code : "63469", intitule : "VOLLORE VILLE", cp : "63120" },
{ code : "63470", intitule : "VOLVIC", cp : "63530" },
{ code : "63471", intitule : "YOUX", cp : "63700" },
{ code : "63471", intitule : "YOUX", cp : "63700" },
{ code : "63472", intitule : "YRONDE ET BURON", cp : "63270" },
{ code : "63473", intitule : "YSSAC LA TOURETTE", cp : "63200" },
{ code : "64001", intitule : "AAST", cp : "64460" },
{ code : "64002", intitule : "ABERE", cp : "64160" },
{ code : "64003", intitule : "ABIDOS", cp : "64150" },
{ code : "64004", intitule : "ABITAIN", cp : "64390" },
{ code : "64005", intitule : "ABOS", cp : "64360" },
{ code : "64006", intitule : "ACCOUS", cp : "64490" },
{ code : "64007", intitule : "AGNOS", cp : "64400" },
{ code : "64008", intitule : "AHAXE ALCIETTE BASCASSAN", cp : "64220" },
{ code : "64009", intitule : "AHETZE", cp : "64210" },
{ code : "64010", intitule : "AICIRITS CAMOU SUHAST", cp : "64120" },
{ code : "64010", intitule : "AICIRITS CAMOU SUHAST", cp : "64120" },
{ code : "64011", intitule : "AINCILLE", cp : "64220" },
{ code : "64012", intitule : "AINHARP", cp : "64130" },
{ code : "64013", intitule : "AINHICE MONGELOS", cp : "64220" },
{ code : "64014", intitule : "AINHOA", cp : "64250" },
{ code : "64015", intitule : "ALCAY ALCABEHETY SUNHARETTE", cp : "64470" },
{ code : "64016", intitule : "ALDUDES", cp : "64430" },
{ code : "64017", intitule : "ALOS SIBAS ABENSE", cp : "64470" },
{ code : "64018", intitule : "AMENDEUIX ONEIX", cp : "64120" },
{ code : "64019", intitule : "AMOROTS SUCCOS", cp : "64120" },
{ code : "64020", intitule : "ANCE", cp : "64570" },
{ code : "64021", intitule : "ANDOINS", cp : "64420" },
{ code : "64022", intitule : "ANDREIN", cp : "64390" },
{ code : "64023", intitule : "ANGAIS", cp : "64510" },
{ code : "64024", intitule : "ANGLET", cp : "64600" },
{ code : "64025", intitule : "ANGOUS", cp : "64190" },
{ code : "64026", intitule : "ANHAUX", cp : "64220" },
{ code : "64027", intitule : "ANOS", cp : "64160" },
{ code : "64028", intitule : "ANOYE", cp : "64350" },
{ code : "64029", intitule : "ARAMITS", cp : "64570" },
{ code : "64031", intitule : "ARANCOU", cp : "64270" },
{ code : "64032", intitule : "ARAUJUZON", cp : "64190" },
{ code : "64033", intitule : "ARAUX", cp : "64190" },
{ code : "64034", intitule : "ARBERATS SILLEGUE", cp : "64120" },
{ code : "64035", intitule : "ARBONNE", cp : "64210" },
{ code : "64036", intitule : "ARBOUET SUSSAUTE", cp : "64120" },
{ code : "64037", intitule : "ARBUS", cp : "64230" },
{ code : "64038", intitule : "ARCANGUES", cp : "64200" },
{ code : "64039", intitule : "AREN", cp : "64400" },
{ code : "64040", intitule : "ARETTE", cp : "64570" },
{ code : "64041", intitule : "ARESSY", cp : "64320" },
{ code : "64042", intitule : "ARGAGNON", cp : "64300" },
{ code : "64043", intitule : "ARGELOS", cp : "64450" },
{ code : "64044", intitule : "ARGET", cp : "64410" },
{ code : "64045", intitule : "ARHANSUS", cp : "64120" },
{ code : "64046", intitule : "ARMENDARITS", cp : "64640" },
{ code : "64047", intitule : "ARNEGUY", cp : "64220" },
{ code : "64048", intitule : "ARNOS", cp : "64370" },
{ code : "64049", intitule : "AROUE ITHOROTS OLHAIBY", cp : "64120" },
{ code : "64049", intitule : "AROUE ITHOROTS OLHAIBY", cp : "64120" },
{ code : "64050", intitule : "ARRAST LARREBIEU", cp : "64130" },
{ code : "64051", intitule : "ARRAUTE CHARRITTE", cp : "64120" },
{ code : "64052", intitule : "ARRICAU BORDES", cp : "64350" },
{ code : "64053", intitule : "ARRIEN", cp : "64420" },
{ code : "64054", intitule : "ARROS DE NAY", cp : "64800" },
{ code : "64056", intitule : "ARROSES", cp : "64350" },
{ code : "64057", intitule : "ARTHEZ DE BEARN", cp : "64370" },
{ code : "64058", intitule : "ARTHEZ D ASSON", cp : "64800" },
{ code : "64059", intitule : "ARTIGUELOUTAN", cp : "64420" },
{ code : "64060", intitule : "ARTIGUELOUVE", cp : "64230" },
{ code : "64061", intitule : "ARTIX", cp : "64170" },
{ code : "64062", intitule : "ARUDY", cp : "64260" },
{ code : "64063", intitule : "ARZACQ ARRAZIGUET", cp : "64410" },
{ code : "64064", intitule : "ASASP ARROS", cp : "64660" },
{ code : "64064", intitule : "ASASP ARROS", cp : "64660" },
{ code : "64065", intitule : "ASCAIN", cp : "64310" },
{ code : "64066", intitule : "ASCARAT", cp : "64220" },
{ code : "64067", intitule : "ASSAT", cp : "64510" },
{ code : "64068", intitule : "ASSON", cp : "64800" },
{ code : "64069", intitule : "ASTE BEON", cp : "64260" },
{ code : "64070", intitule : "ASTIS", cp : "64450" },
{ code : "64071", intitule : "ATHOS ASPIS", cp : "64390" },
{ code : "64072", intitule : "AUBERTIN", cp : "64290" },
{ code : "64073", intitule : "AUBIN", cp : "64230" },
{ code : "64074", intitule : "AUBOUS", cp : "64330" },
{ code : "64075", intitule : "AUDAUX", cp : "64190" },
{ code : "64077", intitule : "AUGA", cp : "64450" },
{ code : "64078", intitule : "AURIAC", cp : "64450" },
{ code : "64079", intitule : "AURIONS IDERNES", cp : "64350" },
{ code : "64080", intitule : "AUSSEVIELLE", cp : "64230" },
{ code : "64081", intitule : "AUSSURUCQ", cp : "64130" },
{ code : "64082", intitule : "AUTERRIVE", cp : "64270" },
{ code : "64083", intitule : "AUTEVIELLE ST MARTIN BIDEREN", cp : "64390" },
{ code : "64084", intitule : "AYDIE", cp : "64330" },
{ code : "64085", intitule : "AYDIUS", cp : "64490" },
{ code : "64086", intitule : "AYHERRE", cp : "64240" },
{ code : "64087", intitule : "BAIGTS DE BEARN", cp : "64300" },
{ code : "64088", intitule : "BALANSUN", cp : "64300" },
{ code : "64089", intitule : "BALEIX", cp : "64460" },
{ code : "64090", intitule : "BALIRACQ MAUMUSSON", cp : "64330" },
{ code : "64091", intitule : "BALIROS", cp : "64510" },
{ code : "64092", intitule : "BANCA", cp : "64430" },
{ code : "64093", intitule : "BARCUS", cp : "64130" },
{ code : "64094", intitule : "BARDOS", cp : "64520" },
{ code : "64095", intitule : "BARINQUE", cp : "64160" },
{ code : "64096", intitule : "BARRAUTE CAMU", cp : "64390" },
{ code : "64097", intitule : "BARZUN", cp : "64530" },
{ code : "64098", intitule : "BASSILLON VAUZE", cp : "64350" },
{ code : "64099", intitule : "BASTANES", cp : "64190" },
{ code : "64100", intitule : "BASSUSSARRY", cp : "64200" },
{ code : "64101", intitule : "BAUDREIX", cp : "64800" },
{ code : "64102", intitule : "BAYONNE", cp : "64100" },
{ code : "64103", intitule : "BEDEILLE", cp : "64460" },
{ code : "64104", intitule : "BEDOUS", cp : "64490" },
{ code : "64105", intitule : "BEGUIOS", cp : "64120" },
{ code : "64106", intitule : "BEHASQUE LAPISTE", cp : "64120" },
{ code : "64107", intitule : "BEHORLEGUY", cp : "64220" },
{ code : "64108", intitule : "BELLOCQ", cp : "64270" },
{ code : "64109", intitule : "BENEJACQ", cp : "64800" },
{ code : "64110", intitule : "BEOST", cp : "64440" },
{ code : "64111", intitule : "BENTAYOU SEREE", cp : "64460" },
{ code : "64112", intitule : "BERENX", cp : "64300" },
{ code : "64113", intitule : "BERGOUEY VIELLENAVE", cp : "64270" },
{ code : "64113", intitule : "BERGOUEY VIELLENAVE", cp : "64270" },
{ code : "64114", intitule : "BERNADETS", cp : "64160" },
{ code : "64115", intitule : "BERROGAIN LARUNS", cp : "64130" },
{ code : "64116", intitule : "BESCAT", cp : "64260" },
{ code : "64117", intitule : "BESINGRAND", cp : "64150" },
{ code : "64118", intitule : "BETRACQ", cp : "64350" },
{ code : "64119", intitule : "BEUSTE", cp : "64800" },
{ code : "64120", intitule : "BEYRIE SUR JOYEUSE", cp : "64120" },
{ code : "64121", intitule : "BEYRIE EN BEARN", cp : "64230" },
{ code : "64122", intitule : "BIARRITZ", cp : "64200" },
{ code : "64123", intitule : "BIDACHE", cp : "64520" },
{ code : "64124", intitule : "BIDARRAY", cp : "64780" },
{ code : "64125", intitule : "BIDART", cp : "64210" },
{ code : "64126", intitule : "BIDOS", cp : "64400" },
{ code : "64127", intitule : "BIELLE", cp : "64260" },
{ code : "64128", intitule : "BILHERES", cp : "64260" },
{ code : "64129", intitule : "BILLERE", cp : "64140" },
{ code : "64130", intitule : "BIRIATOU", cp : "64700" },
{ code : "64131", intitule : "BIRON", cp : "64300" },
{ code : "64132", intitule : "BIZANOS", cp : "64320" },
{ code : "64133", intitule : "BOEIL BEZING", cp : "64510" },
{ code : "64134", intitule : "BONLOC", cp : "64240" },
{ code : "64135", intitule : "BONNUT", cp : "64300" },
{ code : "64136", intitule : "BORCE", cp : "64490" },
{ code : "64137", intitule : "BORDERES", cp : "64800" },
{ code : "64138", intitule : "BORDES", cp : "64510" },
{ code : "64139", intitule : "BOSDARROS", cp : "64290" },
{ code : "64140", intitule : "BOUCAU", cp : "64340" },
{ code : "64141", intitule : "BOUEILH BOUEILHO LASQUE", cp : "64330" },
{ code : "64142", intitule : "BOUGARBER", cp : "64230" },
{ code : "64143", intitule : "BOUILLON", cp : "64410" },
{ code : "64144", intitule : "BOUMOURT", cp : "64370" },
{ code : "64145", intitule : "BOURDETTES", cp : "64800" },
{ code : "64146", intitule : "BOURNOS", cp : "64450" },
{ code : "64147", intitule : "BRISCOUS", cp : "64240" },
{ code : "64148", intitule : "BRUGES CAPBIS MIFAGET", cp : "64800" },
{ code : "64148", intitule : "BRUGES CAPBIS MIFAGET", cp : "64800" },
{ code : "64148", intitule : "BRUGES CAPBIS MIFAGET", cp : "64800" },
{ code : "64149", intitule : "BUGNEIN", cp : "64190" },
{ code : "64150", intitule : "BUNUS", cp : "64120" },
{ code : "64151", intitule : "BURGARONNE", cp : "64390" },
{ code : "64152", intitule : "BUROS", cp : "64160" },
{ code : "64153", intitule : "BUROSSE MENDOUSSE", cp : "64330" },
{ code : "64154", intitule : "BUSSUNARITS SARRASQUETTE", cp : "64220" },
{ code : "64155", intitule : "BUSTINCE IRIBERRY", cp : "64220" },
{ code : "64156", intitule : "BUZIET", cp : "64680" },
{ code : "64157", intitule : "BUZY", cp : "64260" },
{ code : "64158", intitule : "CABIDOS", cp : "64410" },
{ code : "64159", intitule : "CADILLON", cp : "64330" },
{ code : "64160", intitule : "CAMBO LES BAINS", cp : "64250" },
{ code : "64161", intitule : "CAME", cp : "64520" },
{ code : "64162", intitule : "CAMOU CIHIGUE", cp : "64470" },
{ code : "64165", intitule : "CARDESSE", cp : "64360" },
{ code : "64166", intitule : "CARO", cp : "64220" },
{ code : "64167", intitule : "CARRERE", cp : "64160" },
{ code : "64168", intitule : "CARRESSE CASSABER", cp : "64270" },
{ code : "64168", intitule : "CARRESSE CASSABER", cp : "64270" },
{ code : "64170", intitule : "CASTAGNEDE", cp : "64270" },
{ code : "64171", intitule : "CASTEIDE CAMI", cp : "64170" },
{ code : "64172", intitule : "CASTEIDE CANDAU", cp : "64370" },
{ code : "64173", intitule : "CASTEIDE DOAT", cp : "64460" },
{ code : "64174", intitule : "CASTERA LOUBIX", cp : "64460" },
{ code : "64175", intitule : "CASTET", cp : "64260" },
{ code : "64176", intitule : "CASTETBON", cp : "64190" },
{ code : "64177", intitule : "CASTETIS", cp : "64300" },
{ code : "64178", intitule : "CASTETNAU CAMBLONG", cp : "64190" },
{ code : "64179", intitule : "CASTETNER", cp : "64300" },
{ code : "64180", intitule : "CASTETPUGON", cp : "64330" },
{ code : "64181", intitule : "CASTILLON D ARTHEZ", cp : "64370" },
{ code : "64182", intitule : "CASTILLON DE LEMBEYE", cp : "64350" },
{ code : "64183", intitule : "CAUBIOS LOOS", cp : "64230" },
{ code : "64184", intitule : "CESCAU", cp : "64170" },
{ code : "64185", intitule : "CETTE EYGUN", cp : "64490" },
{ code : "64186", intitule : "CHARRE", cp : "64190" },
{ code : "64187", intitule : "CHARRITTE DE BAS", cp : "64130" },
{ code : "64188", intitule : "CHERAUTE", cp : "64130" },
{ code : "64189", intitule : "CIBOURE", cp : "64500" },
{ code : "64190", intitule : "CLARACQ", cp : "64330" },
{ code : "64191", intitule : "COARRAZE", cp : "64800" },
{ code : "64192", intitule : "CONCHEZ DE BEARN", cp : "64330" },
{ code : "64193", intitule : "CORBERE ABERES", cp : "64350" },
{ code : "64194", intitule : "COSLEDAA LUBE BOAST", cp : "64160" },
{ code : "64195", intitule : "COUBLUCQ", cp : "64410" },
{ code : "64196", intitule : "CROUSEILLES", cp : "64350" },
{ code : "64197", intitule : "CUQUERON", cp : "64360" },
{ code : "64198", intitule : "DENGUIN", cp : "64230" },
{ code : "64199", intitule : "DIUSSE", cp : "64330" },
{ code : "64200", intitule : "DOAZON", cp : "64370" },
{ code : "64201", intitule : "DOGNEN", cp : "64190" },
{ code : "64202", intitule : "DOMEZAIN BERRAUTE", cp : "64120" },
{ code : "64203", intitule : "DOUMY", cp : "64450" },
{ code : "64204", intitule : "EAUX BONNES", cp : "64440" },
{ code : "64204", intitule : "EAUX BONNES", cp : "64440" },
{ code : "64205", intitule : "ESCOS", cp : "64270" },
{ code : "64206", intitule : "ESCOT", cp : "64490" },
{ code : "64207", intitule : "ESCOU", cp : "64870" },
{ code : "64208", intitule : "ESCOUBES", cp : "64160" },
{ code : "64209", intitule : "ESCOUT", cp : "64870" },
{ code : "64210", intitule : "ESCURES", cp : "64350" },
{ code : "64211", intitule : "ESLOURENTIES DABAN", cp : "64420" },
{ code : "64212", intitule : "ESPECHEDE", cp : "64160" },
{ code : "64213", intitule : "ESPELETTE", cp : "64250" },
{ code : "64214", intitule : "ESPES UNDUREIN", cp : "64130" },
{ code : "64215", intitule : "ESPIUTE", cp : "64390" },
{ code : "64216", intitule : "ESPOEY", cp : "64420" },
{ code : "64217", intitule : "ESQUIULE", cp : "64400" },
{ code : "64218", intitule : "ESTERENCUBY", cp : "64220" },
{ code : "64219", intitule : "ESTIALESCQ", cp : "64290" },
{ code : "64220", intitule : "ESTOS", cp : "64400" },
{ code : "64221", intitule : "ETCHARRY", cp : "64120" },
{ code : "64222", intitule : "ETCHEBAR", cp : "64470" },
{ code : "64223", intitule : "ETSAUT", cp : "64490" },
{ code : "64224", intitule : "EYSUS", cp : "64400" },
{ code : "64225", intitule : "FEAS", cp : "64570" },
{ code : "64226", intitule : "FICHOUS RIUMAYOU", cp : "64410" },
{ code : "64227", intitule : "GABASTON", cp : "64160" },
{ code : "64228", intitule : "GABAT", cp : "64120" },
{ code : "64229", intitule : "GAMARTHE", cp : "64220" },
{ code : "64230", intitule : "GAN", cp : "64290" },
{ code : "64231", intitule : "GARINDEIN", cp : "64130" },
{ code : "64232", intitule : "GARLEDE MONDEBAT", cp : "64450" },
{ code : "64233", intitule : "GARLIN", cp : "64330" },
{ code : "64234", intitule : "GAROS", cp : "64410" },
{ code : "64235", intitule : "GARRIS", cp : "64120" },
{ code : "64236", intitule : "GAYON", cp : "64350" },
{ code : "64237", intitule : "GELOS", cp : "64110" },
{ code : "64238", intitule : "GER", cp : "64530" },
{ code : "64239", intitule : "GERDEREST", cp : "64160" },
{ code : "64240", intitule : "GERE BELESTEN", cp : "64260" },
{ code : "64241", intitule : "GERONCE", cp : "64400" },
{ code : "64242", intitule : "GESTAS", cp : "64190" },
{ code : "64243", intitule : "GEUS D ARZACQ", cp : "64370" },
{ code : "64244", intitule : "GEUS D OLORON", cp : "64400" },
{ code : "64245", intitule : "GOES", cp : "64400" },
{ code : "64246", intitule : "GOMER", cp : "64420" },
{ code : "64247", intitule : "GOTEIN LIBARRENX", cp : "64130" },
{ code : "64249", intitule : "GUETHARY", cp : "64210" },
{ code : "64250", intitule : "GUICHE", cp : "64520" },
{ code : "64251", intitule : "GUINARTHE PARENTIES", cp : "64390" },
{ code : "64252", intitule : "GURMENCON", cp : "64400" },
{ code : "64253", intitule : "GURS", cp : "64190" },
{ code : "64254", intitule : "HAGETAUBIN", cp : "64370" },
{ code : "64255", intitule : "HALSOU", cp : "64480" },
{ code : "64256", intitule : "HASPARREN", cp : "64240" },
{ code : "64257", intitule : "HAUT DE BOSDARROS", cp : "64800" },
{ code : "64258", intitule : "HAUX", cp : "64470" },
{ code : "64259", intitule : "HELETTE", cp : "64640" },
{ code : "64260", intitule : "HENDAYE", cp : "64700" },
{ code : "64261", intitule : "HERRERE", cp : "64680" },
{ code : "64262", intitule : "HIGUERES SOUYE", cp : "64160" },
{ code : "64263", intitule : "L HOPITAL D ORION", cp : "64270" },
{ code : "64264", intitule : "L HOPITAL ST BLAISE", cp : "64130" },
{ code : "64265", intitule : "HOSTA", cp : "64120" },
{ code : "64266", intitule : "HOURS", cp : "64420" },
{ code : "64267", intitule : "IBARROLLE", cp : "64120" },
{ code : "64268", intitule : "IDAUX MENDY", cp : "64130" },
{ code : "64269", intitule : "IDRON", cp : "64320" },
{ code : "64270", intitule : "IGON", cp : "64800" },
{ code : "64271", intitule : "IHOLDY", cp : "64640" },
{ code : "64272", intitule : "ILHARRE", cp : "64120" },
{ code : "64273", intitule : "IRISSARRY", cp : "64780" },
{ code : "64274", intitule : "IROULEGUY", cp : "64220" },
{ code : "64275", intitule : "ISPOURE", cp : "64220" },
{ code : "64276", intitule : "ISSOR", cp : "64570" },
{ code : "64277", intitule : "ISTURITS", cp : "64240" },
{ code : "64279", intitule : "ITXASSOU", cp : "64250" },
{ code : "64280", intitule : "IZESTE", cp : "64260" },
{ code : "64281", intitule : "JASSES", cp : "64190" },
{ code : "64282", intitule : "JATXOU", cp : "64480" },
{ code : "64283", intitule : "JAXU", cp : "64220" },
{ code : "64284", intitule : "JURANCON", cp : "64110" },
{ code : "64285", intitule : "JUXUE", cp : "64120" },
{ code : "64286", intitule : "LAA MONDRANS", cp : "64300" },
{ code : "64287", intitule : "LAAS", cp : "64390" },
{ code : "64288", intitule : "LABASTIDE CEZERACQ", cp : "64170" },
{ code : "64289", intitule : "LA BASTIDE CLAIRENCE", cp : "64240" },
{ code : "64290", intitule : "LABASTIDE MONREJEAU", cp : "64170" },
{ code : "64291", intitule : "LABASTIDE VILLEFRANCHE", cp : "64270" },
{ code : "64292", intitule : "LABATMALE", cp : "64530" },
{ code : "64293", intitule : "LABATUT", cp : "64460" },
{ code : "64294", intitule : "LABETS BISCAY", cp : "64120" },
{ code : "64295", intitule : "LABEYRIE", cp : "64300" },
{ code : "64296", intitule : "LACADEE", cp : "64300" },
{ code : "64297", intitule : "LACARRE", cp : "64220" },
{ code : "64298", intitule : "LACARRY ARHAN CHARRITTE DE HAUT", cp : "64470" },
{ code : "64299", intitule : "LACOMMANDE", cp : "64360" },
{ code : "64300", intitule : "LACQ", cp : "64170" },
{ code : "64300", intitule : "LACQ", cp : "64170" },
{ code : "64301", intitule : "LAGOR", cp : "64150" },
{ code : "64302", intitule : "LAGOS", cp : "64800" },
{ code : "64303", intitule : "LAGUINGE RESTOUE", cp : "64470" },
{ code : "64304", intitule : "LAHONCE", cp : "64990" },
{ code : "64305", intitule : "LAHONTAN", cp : "64270" },
{ code : "64306", intitule : "LAHOURCADE", cp : "64150" },
{ code : "64307", intitule : "LALONGUE", cp : "64350" },
{ code : "64308", intitule : "LALONQUETTE", cp : "64450" },
{ code : "64309", intitule : "LAMAYOU", cp : "64460" },
{ code : "64310", intitule : "LANNE EN BARETOUS", cp : "64570" },
{ code : "64311", intitule : "LANNECAUBE", cp : "64350" },
{ code : "64312", intitule : "LANNEPLAA", cp : "64300" },
{ code : "64313", intitule : "LANTABAT", cp : "64640" },
{ code : "64314", intitule : "LARCEVEAU ARROS CIBITS", cp : "64120" },
{ code : "64315", intitule : "LAROIN", cp : "64110" },
{ code : "64316", intitule : "LARRAU", cp : "64560" },
{ code : "64317", intitule : "LARRESSORE", cp : "64480" },
{ code : "64318", intitule : "LARREULE", cp : "64410" },
{ code : "64319", intitule : "LARRIBAR SORHAPURU", cp : "64120" },
{ code : "64320", intitule : "LARUNS", cp : "64440" },
{ code : "64320", intitule : "LARUNS", cp : "64440" },
{ code : "64320", intitule : "LARUNS", cp : "64440" },
{ code : "64321", intitule : "LASCLAVERIES", cp : "64450" },
{ code : "64322", intitule : "LASSE", cp : "64220" },
{ code : "64323", intitule : "LASSERRE", cp : "64350" },
{ code : "64324", intitule : "LASSEUBE", cp : "64290" },
{ code : "64325", intitule : "LASSEUBETAT", cp : "64290" },
{ code : "64326", intitule : "LAY LAMIDOU", cp : "64190" },
{ code : "64327", intitule : "LECUMBERRY", cp : "64220" },
{ code : "64328", intitule : "LEDEUIX", cp : "64400" },
{ code : "64329", intitule : "LEE", cp : "64320" },
{ code : "64330", intitule : "LEES ATHAS", cp : "64490" },
{ code : "64331", intitule : "LEMBEYE", cp : "64350" },
{ code : "64332", intitule : "LEME", cp : "64450" },
{ code : "64334", intitule : "LEREN", cp : "64270" },
{ code : "64335", intitule : "LESCAR", cp : "64230" },
{ code : "64336", intitule : "LESCUN", cp : "64490" },
{ code : "64337", intitule : "LESPIELLE", cp : "64350" },
{ code : "64338", intitule : "LESPOURCY", cp : "64160" },
{ code : "64339", intitule : "LESTELLE BETHARRAM", cp : "64800" },
{ code : "64340", intitule : "LICHANS SUNHAR", cp : "64470" },
{ code : "64341", intitule : "LICHOS", cp : "64130" },
{ code : "64342", intitule : "LICQ ATHEREY", cp : "64560" },
{ code : "64343", intitule : "LIMENDOUS", cp : "64420" },
{ code : "64344", intitule : "LIVRON", cp : "64530" },
{ code : "64345", intitule : "LOHITZUN OYHERCQ", cp : "64120" },
{ code : "64346", intitule : "LOMBIA", cp : "64160" },
{ code : "64347", intitule : "LONCON", cp : "64410" },
{ code : "64348", intitule : "LONS", cp : "64140" },
{ code : "64349", intitule : "LOUBIENG", cp : "64300" },
{ code : "64350", intitule : "LOUHOSSOA", cp : "64250" },
{ code : "64351", intitule : "LOURDIOS ICHERE", cp : "64570" },
{ code : "64352", intitule : "LOURENTIES", cp : "64420" },
{ code : "64353", intitule : "LOUVIE JUZON", cp : "64260" },
{ code : "64354", intitule : "LOUVIE SOUBIRON", cp : "64440" },
{ code : "64355", intitule : "LOUVIGNY", cp : "64410" },
{ code : "64356", intitule : "LUC ARMAU", cp : "64350" },
{ code : "64357", intitule : "LUCARRE", cp : "64350" },
{ code : "64358", intitule : "LUCGARIER", cp : "64420" },
{ code : "64359", intitule : "LUCQ DE BEARN", cp : "64360" },
{ code : "64360", intitule : "LURBE ST CHRISTAU", cp : "64660" },
{ code : "64361", intitule : "LUSSAGNET LUSSON", cp : "64160" },
{ code : "64362", intitule : "LUXE SUMBERRAUTE", cp : "64120" },
{ code : "64363", intitule : "LYS", cp : "64260" },
{ code : "64364", intitule : "MACAYE", cp : "64240" },
{ code : "64365", intitule : "MALAUSSANNE", cp : "64410" },
{ code : "64366", intitule : "MASCARAAS HARON", cp : "64330" },
{ code : "64367", intitule : "MASLACQ", cp : "64300" },
{ code : "64368", intitule : "MASPARRAUTE", cp : "64120" },
{ code : "64369", intitule : "MASPIE LALONQUERE JUILLACQ", cp : "64350" },
{ code : "64370", intitule : "MAUCOR", cp : "64160" },
{ code : "64371", intitule : "MAULEON LICHARRE", cp : "64130" },
{ code : "64372", intitule : "MAURE", cp : "64460" },
{ code : "64373", intitule : "MAZERES LEZONS", cp : "64110" },
{ code : "64374", intitule : "MAZEROLLES", cp : "64230" },
{ code : "64375", intitule : "MEHARIN", cp : "64120" },
{ code : "64376", intitule : "MEILLON", cp : "64510" },
{ code : "64377", intitule : "MENDIONDE", cp : "64240" },
{ code : "64378", intitule : "MENDITTE", cp : "64130" },
{ code : "64379", intitule : "MENDIVE", cp : "64220" },
{ code : "64380", intitule : "MERACQ", cp : "64410" },
{ code : "64381", intitule : "MERITEIN", cp : "64190" },
{ code : "64382", intitule : "MESPLEDE", cp : "64370" },
{ code : "64383", intitule : "MIALOS", cp : "64410" },
{ code : "64385", intitule : "MIOSSENS LANUSSE", cp : "64450" },
{ code : "64386", intitule : "MIREPEIX", cp : "64800" },
{ code : "64387", intitule : "MOMAS", cp : "64230" },
{ code : "64388", intitule : "MOMY", cp : "64350" },
{ code : "64389", intitule : "MONASSUT AUDIRACQ", cp : "64160" },
{ code : "64390", intitule : "MONCAUP", cp : "64350" },
{ code : "64391", intitule : "MONCAYOLLE LARRORY MENDIBIEU", cp : "64130" },
{ code : "64392", intitule : "MONCLA", cp : "64330" },
{ code : "64393", intitule : "MONEIN", cp : "64360" },
{ code : "64394", intitule : "MONPEZAT", cp : "64350" },
{ code : "64395", intitule : "MONSEGUR", cp : "64460" },
{ code : "64396", intitule : "MONT", cp : "64300" },
{ code : "64396", intitule : "MONT", cp : "64300" },
{ code : "64396", intitule : "MONT", cp : "64300" },
{ code : "64396", intitule : "MONT", cp : "64300" },
{ code : "64397", intitule : "MONTAGUT", cp : "64410" },
{ code : "64398", intitule : "MONTANER", cp : "64460" },
{ code : "64399", intitule : "MONTARDON", cp : "64121" },
{ code : "64400", intitule : "MONTAUT", cp : "64800" },
{ code : "64401", intitule : "MONT DISSE", cp : "64330" },
{ code : "64403", intitule : "MONTFORT", cp : "64190" },
{ code : "64404", intitule : "MONTORY", cp : "64470" },
{ code : "64405", intitule : "MORLAAS", cp : "64160" },
{ code : "64406", intitule : "MORLANNE", cp : "64370" },
{ code : "64407", intitule : "MOUGUERRE", cp : "64990" },
{ code : "64408", intitule : "MOUHOUS", cp : "64330" },
{ code : "64409", intitule : "MOUMOUR", cp : "64400" },
{ code : "64410", intitule : "MOURENX", cp : "64150" },
{ code : "64411", intitule : "MUSCULDY", cp : "64130" },
{ code : "64412", intitule : "NABAS", cp : "64190" },
{ code : "64413", intitule : "NARCASTET", cp : "64510" },
{ code : "64414", intitule : "NARP", cp : "64190" },
{ code : "64415", intitule : "NAVAILLES ANGOS", cp : "64450" },
{ code : "64416", intitule : "NAVARRENX", cp : "64190" },
{ code : "64417", intitule : "NAY", cp : "64800" },
{ code : "64418", intitule : "NOGUERES", cp : "64150" },
{ code : "64419", intitule : "NOUSTY", cp : "64420" },
{ code : "64420", intitule : "OGENNE CAMPTORT", cp : "64190" },
{ code : "64421", intitule : "OGEU LES BAINS", cp : "64680" },
{ code : "64422", intitule : "OLORON STE MARIE", cp : "64400" },
{ code : "64423", intitule : "ORAAS", cp : "64390" },
{ code : "64424", intitule : "ORDIARP", cp : "64130" },
{ code : "64425", intitule : "OREGUE", cp : "64120" },
{ code : "64426", intitule : "ORIN", cp : "64400" },
{ code : "64427", intitule : "ORION", cp : "64390" },
{ code : "64428", intitule : "ORRIULE", cp : "64390" },
{ code : "64429", intitule : "ORSANCO", cp : "64120" },
{ code : "64430", intitule : "ORTHEZ", cp : "64300" },
{ code : "64430", intitule : "ORTHEZ", cp : "64300" },
{ code : "64431", intitule : "OS MARSILLON", cp : "64150" },
{ code : "64432", intitule : "OSSAS SUHARE", cp : "64470" },
{ code : "64433", intitule : "OSSE EN ASPE", cp : "64490" },
{ code : "64434", intitule : "OSSENX", cp : "64190" },
{ code : "64435", intitule : "OSSERAIN RIVAREYTE", cp : "64390" },
{ code : "64436", intitule : "OSSES", cp : "64780" },
{ code : "64437", intitule : "OSTABAT ASME", cp : "64120" },
{ code : "64438", intitule : "OUILLON", cp : "64160" },
{ code : "64439", intitule : "OUSSE", cp : "64320" },
{ code : "64440", intitule : "OZENX MONTESTRUCQ", cp : "64300" },
{ code : "64440", intitule : "OZENX MONTESTRUCQ", cp : "64300" },
{ code : "64441", intitule : "PAGOLLE", cp : "64120" },
{ code : "64442", intitule : "PARBAYSE", cp : "64360" },
{ code : "64443", intitule : "PARDIES", cp : "64150" },
{ code : "64444", intitule : "PARDIES PIETAT", cp : "64800" },
{ code : "64445", intitule : "PAU", cp : "64000" },
{ code : "64446", intitule : "PEYRELONGUE ABOS", cp : "64350" },
{ code : "64447", intitule : "PIETS PLASENCE MOUSTROU", cp : "64410" },
{ code : "64448", intitule : "POEY DE LESCAR", cp : "64230" },
{ code : "64449", intitule : "POEY D OLORON", cp : "64400" },
{ code : "64450", intitule : "POMPS", cp : "64370" },
{ code : "64451", intitule : "PONSON DEBAT POUTS", cp : "64460" },
{ code : "64452", intitule : "PONSON DESSUS", cp : "64460" },
{ code : "64453", intitule : "PONTACQ", cp : "64530" },
{ code : "64454", intitule : "PONTIACQ VIELLEPINTE", cp : "64460" },
{ code : "64455", intitule : "PORTET", cp : "64330" },
{ code : "64456", intitule : "POULIACQ", cp : "64410" },
{ code : "64457", intitule : "POURSIUGUES BOUCOUE", cp : "64410" },
{ code : "64458", intitule : "PRECHACQ JOSBAIG", cp : "64190" },
{ code : "64459", intitule : "PRECHACQ NAVARRENX", cp : "64190" },
{ code : "64460", intitule : "PRECILHON", cp : "64400" },
{ code : "64461", intitule : "PUYOO", cp : "64270" },
{ code : "64462", intitule : "RAMOUS", cp : "64270" },
{ code : "64463", intitule : "REBENACQ", cp : "64260" },
{ code : "64464", intitule : "RIBARROUY", cp : "64330" },
{ code : "64465", intitule : "RIUPEYROUS", cp : "64160" },
{ code : "64466", intitule : "RIVEHAUTE", cp : "64190" },
{ code : "64467", intitule : "RONTIGNON", cp : "64110" },
{ code : "64468", intitule : "ROQUIAGUE", cp : "64130" },
{ code : "64469", intitule : "ST ABIT", cp : "64800" },
{ code : "64470", intitule : "ST ARMOU", cp : "64160" },
{ code : "64471", intitule : "ST BOES", cp : "64300" },
{ code : "64472", intitule : "ST CASTIN", cp : "64160" },
{ code : "64473", intitule : "STE COLOME", cp : "64260" },
{ code : "64474", intitule : "ST DOS", cp : "64270" },
{ code : "64475", intitule : "STE ENGRACE", cp : "64560" },
{ code : "64476", intitule : "ST ESTEBEN", cp : "64640" },
{ code : "64477", intitule : "ST ETIENNE DE BAIGORRY", cp : "64430" },
{ code : "64478", intitule : "ST FAUST", cp : "64110" },
{ code : "64479", intitule : "ST GIRONS EN BEARN", cp : "64300" },
{ code : "64480", intitule : "ST GLADIE ARRIVE MUNEIN", cp : "64390" },
{ code : "64481", intitule : "ST GOIN", cp : "64400" },
{ code : "64482", intitule : "ST JAMMES", cp : "64160" },
{ code : "64483", intitule : "ST JEAN DE LUZ", cp : "64500" },
{ code : "64484", intitule : "ST JEAN LE VIEUX", cp : "64220" },
{ code : "64485", intitule : "ST JEAN PIED DE PORT", cp : "64220" },
{ code : "64486", intitule : "ST JEAN POUDGE", cp : "64330" },
{ code : "64487", intitule : "ST JUST IBARRE", cp : "64120" },
{ code : "64488", intitule : "ST LAURENT BRETAGNE", cp : "64160" },
{ code : "64489", intitule : "ST MARTIN D ARBEROUE", cp : "64640" },
{ code : "64490", intitule : "ST MARTIN D ARROSSA", cp : "64780" },
{ code : "64490", intitule : "ST MARTIN D ARROSSA", cp : "64780" },
{ code : "64491", intitule : "ST MEDARD", cp : "64370" },
{ code : "64492", intitule : "ST MICHEL", cp : "64220" },
{ code : "64493", intitule : "ST PALAIS", cp : "64120" },
{ code : "64494", intitule : "ST PE DE LEREN", cp : "64270" },
{ code : "64495", intitule : "ST PEE SUR NIVELLE", cp : "64310" },
{ code : "64496", intitule : "ST PIERRE D IRUBE", cp : "64990" },
{ code : "64498", intitule : "ST VINCENT", cp : "64800" },
{ code : "64499", intitule : "SALIES DE BEARN", cp : "64270" },
{ code : "64500", intitule : "SALLES MONGISCARD", cp : "64300" },
{ code : "64501", intitule : "SALLESPISSE", cp : "64300" },
{ code : "64502", intitule : "SAMES", cp : "64520" },
{ code : "64503", intitule : "SAMSONS LION", cp : "64350" },
{ code : "64504", intitule : "SARE", cp : "64310" },
{ code : "64505", intitule : "SARPOURENX", cp : "64300" },
{ code : "64506", intitule : "SARRANCE", cp : "64490" },
{ code : "64507", intitule : "SAUBOLE", cp : "64420" },
{ code : "64508", intitule : "SAUCEDE", cp : "64400" },
{ code : "64509", intitule : "SAUGUIS ST ETIENNE", cp : "64470" },
{ code : "64510", intitule : "SAULT DE NAVAILLES", cp : "64300" },
{ code : "64511", intitule : "SAUVAGNON", cp : "64230" },
{ code : "64512", intitule : "SAUVELADE", cp : "64150" },
{ code : "64513", intitule : "SAUVETERRE DE BEARN", cp : "64390" },
{ code : "64514", intitule : "SEBY", cp : "64410" },
{ code : "64515", intitule : "SEDZE MAUBECQ", cp : "64160" },
{ code : "64516", intitule : "SEDZERE", cp : "64160" },
{ code : "64517", intitule : "SEMEACQ BLACHON", cp : "64350" },
{ code : "64518", intitule : "SENDETS", cp : "64320" },
{ code : "64519", intitule : "SERRES CASTET", cp : "64121" },
{ code : "64520", intitule : "SERRES MORLAAS", cp : "64160" },
{ code : "64521", intitule : "SERRES STE MARIE", cp : "64170" },
{ code : "64522", intitule : "SEVIGNACQ MEYRACQ", cp : "64260" },
{ code : "64523", intitule : "SEVIGNACQ", cp : "64160" },
{ code : "64524", intitule : "SIMACOURBE", cp : "64350" },
{ code : "64525", intitule : "SIROS", cp : "64230" },
{ code : "64526", intitule : "SOUMOULOU", cp : "64420" },
{ code : "64527", intitule : "SOURAIDE", cp : "64250" },
{ code : "64528", intitule : "SUHESCUN", cp : "64780" },
{ code : "64529", intitule : "SUS", cp : "64190" },
{ code : "64530", intitule : "SUSMIOU", cp : "64190" },
{ code : "64531", intitule : "TABAILLE USQUAIN", cp : "64190" },
{ code : "64532", intitule : "TADOUSSE USSAU", cp : "64330" },
{ code : "64533", intitule : "TARDETS SORHOLUS", cp : "64470" },
{ code : "64534", intitule : "TARON SADIRAC VIELLENAVE", cp : "64330" },
{ code : "64535", intitule : "TARSACQ", cp : "64360" },
{ code : "64536", intitule : "THEZE", cp : "64450" },
{ code : "64537", intitule : "TROIS VILLES", cp : "64470" },
{ code : "64538", intitule : "UHART CIZE", cp : "64220" },
{ code : "64539", intitule : "UHART MIXE", cp : "64120" },
{ code : "64540", intitule : "URCUIT", cp : "64990" },
{ code : "64541", intitule : "URDES", cp : "64370" },
{ code : "64542", intitule : "URDOS", cp : "64490" },
{ code : "64543", intitule : "UREPEL", cp : "64430" },
{ code : "64544", intitule : "UROST", cp : "64160" },
{ code : "64545", intitule : "URRUGNE", cp : "64122" },
{ code : "64545", intitule : "URRUGNE", cp : "64700" },
{ code : "64546", intitule : "URT", cp : "64240" },
{ code : "64547", intitule : "USTARITZ", cp : "64480" },
{ code : "64548", intitule : "UZAN", cp : "64370" },
{ code : "64549", intitule : "UZEIN", cp : "64230" },
{ code : "64550", intitule : "UZOS", cp : "64110" },
{ code : "64551", intitule : "VERDETS", cp : "64400" },
{ code : "64552", intitule : "VIALER", cp : "64330" },
{ code : "64554", intitule : "VIELLENAVE D ARTHEZ", cp : "64170" },
{ code : "64555", intitule : "VIELLENAVE DE NAVARRENX", cp : "64190" },
{ code : "64556", intitule : "VIELLESEGURE", cp : "64150" },
{ code : "64557", intitule : "VIGNES", cp : "64410" },
{ code : "64558", intitule : "VILLEFRANQUE", cp : "64990" },
{ code : "64559", intitule : "VIODOS ABENSE DE BAS", cp : "64130" },
{ code : "64560", intitule : "VIVEN", cp : "64450" },
{ code : "65001", intitule : "ADAST", cp : "65260" },
{ code : "65002", intitule : "ADE", cp : "65100" },
{ code : "65003", intitule : "ADERVIELLE POUCHERGUES", cp : "65240" },
{ code : "65003", intitule : "ADERVIELLE POUCHERGUES", cp : "65240" },
{ code : "65004", intitule : "AGOS VIDALOS", cp : "65400" },
{ code : "65005", intitule : "ALLIER", cp : "65360" },
{ code : "65006", intitule : "ANCIZAN", cp : "65440" },
{ code : "65007", intitule : "ANDREST", cp : "65390" },
{ code : "65009", intitule : "ANERES", cp : "65150" },
{ code : "65010", intitule : "ANGOS", cp : "65690" },
{ code : "65011", intitule : "LES ANGLES", cp : "65100" },
{ code : "65012", intitule : "ANLA", cp : "65370" },
{ code : "65013", intitule : "ANSOST", cp : "65140" },
{ code : "65014", intitule : "ANTICHAN", cp : "65370" },
{ code : "65015", intitule : "ANTIN", cp : "65220" },
{ code : "65016", intitule : "ANTIST", cp : "65200" },
{ code : "65017", intitule : "ARAGNOUET", cp : "65170" },
{ code : "65018", intitule : "ARBEOST", cp : "65560" },
{ code : "65019", intitule : "ARCIZAC ADOUR", cp : "65360" },
{ code : "65020", intitule : "ARCIZAC EZ ANGLES", cp : "65100" },
{ code : "65021", intitule : "ARCIZANS AVANT", cp : "65400" },
{ code : "65022", intitule : "ARCIZANS DESSUS", cp : "65400" },
{ code : "65023", intitule : "ARDENGOST", cp : "65240" },
{ code : "65024", intitule : "ARGELES BAGNERES", cp : "65200" },
{ code : "65025", intitule : "ARGELES GAZOST", cp : "65400" },
{ code : "65026", intitule : "ARIES ESPENAN", cp : "65230" },
{ code : "65028", intitule : "ARNE", cp : "65670" },
{ code : "65029", intitule : "ARRAS EN LAVEDAN", cp : "65400" },
{ code : "65031", intitule : "ARREAU", cp : "65240" },
{ code : "65032", intitule : "ARRENS MARSOUS", cp : "65400" },
{ code : "65032", intitule : "ARRENS MARSOUS", cp : "65400" },
{ code : "65033", intitule : "ARRODETS EZ ANGLES", cp : "65100" },
{ code : "65034", intitule : "ARRODETS", cp : "65130" },
{ code : "65035", intitule : "ARTAGNAN", cp : "65500" },
{ code : "65036", intitule : "ARTALENS SOUIN", cp : "65400" },
{ code : "65037", intitule : "ARTIGUEMY", cp : "65130" },
{ code : "65038", intitule : "ARTIGUES", cp : "65100" },
{ code : "65039", intitule : "ASPIN AURE", cp : "65240" },
{ code : "65040", intitule : "ASPIN EN LAVEDAN", cp : "65100" },
{ code : "65041", intitule : "ASQUE", cp : "65130" },
{ code : "65042", intitule : "ASTE", cp : "65200" },
{ code : "65043", intitule : "ASTUGUE", cp : "65200" },
{ code : "65044", intitule : "AUBAREDE", cp : "65350" },
{ code : "65045", intitule : "AUCUN", cp : "65400" },
{ code : "65046", intitule : "AULON", cp : "65240" },
{ code : "65047", intitule : "AUREILHAN", cp : "65800" },
{ code : "65048", intitule : "AURENSAN", cp : "65390" },
{ code : "65049", intitule : "AURIEBAT", cp : "65700" },
{ code : "65050", intitule : "AVAJAN", cp : "65240" },
{ code : "65051", intitule : "AVENTIGNAN", cp : "65660" },
{ code : "65052", intitule : "AVERAN", cp : "65380" },
{ code : "65053", intitule : "AVEUX", cp : "65370" },
{ code : "65054", intitule : "AVEZAC PRAT LAHITTE", cp : "65130" },
{ code : "65054", intitule : "AVEZAC PRAT LAHITTE", cp : "65130" },
{ code : "65055", intitule : "AYROS ARBOUIX", cp : "65400" },
{ code : "65056", intitule : "AYZAC OST", cp : "65400" },
{ code : "65057", intitule : "AZEREIX", cp : "65380" },
{ code : "65058", intitule : "AZET", cp : "65170" },
{ code : "65059", intitule : "BAGNERES DE BIGORRE", cp : "65200" },
{ code : "65059", intitule : "BAGNERES DE BIGORRE", cp : "65710" },
{ code : "65059", intitule : "BAGNERES DE BIGORRE", cp : "65200" },
{ code : "65060", intitule : "BANIOS", cp : "65200" },
{ code : "65061", intitule : "BARBACHEN", cp : "65140" },
{ code : "65062", intitule : "BARBAZAN DEBAT", cp : "65690" },
{ code : "65063", intitule : "BARBAZAN DESSUS", cp : "65360" },
{ code : "65064", intitule : "BAREILLES", cp : "65240" },
{ code : "65065", intitule : "BARLEST", cp : "65100" },
{ code : "65066", intitule : "BARRANCOUEU", cp : "65240" },
{ code : "65067", intitule : "BARRY", cp : "65380" },
{ code : "65068", intitule : "BARTHE", cp : "65230" },
{ code : "65069", intitule : "LA BARTHE DE NESTE", cp : "65250" },
{ code : "65070", intitule : "BARTRES", cp : "65100" },
{ code : "65071", intitule : "BATSERE", cp : "65130" },
{ code : "65072", intitule : "BAZET", cp : "65460" },
{ code : "65073", intitule : "BAZILLAC", cp : "65140" },
{ code : "65074", intitule : "BAZORDAN", cp : "65670" },
{ code : "65075", intitule : "BAZUS AURE", cp : "65170" },
{ code : "65076", intitule : "BAZUS NESTE", cp : "65250" },
{ code : "65077", intitule : "BEAUCENS", cp : "65400" },
{ code : "65078", intitule : "BEAUDEAN", cp : "65710" },
{ code : "65079", intitule : "BEGOLE", cp : "65190" },
{ code : "65080", intitule : "BENAC", cp : "65380" },
{ code : "65081", intitule : "BENQUE", cp : "65130" },
{ code : "65082", intitule : "BERBERUST LIAS", cp : "65100" },
{ code : "65083", intitule : "BERNAC DEBAT", cp : "65360" },
{ code : "65084", intitule : "BERNAC DESSUS", cp : "65360" },
{ code : "65085", intitule : "BERNADETS DEBAT", cp : "65220" },
{ code : "65086", intitule : "BERNADETS DESSUS", cp : "65190" },
{ code : "65087", intitule : "BERTREN", cp : "65370" },
{ code : "65088", intitule : "BETBEZE", cp : "65230" },
{ code : "65089", intitule : "BETPOUEY", cp : "65120" },
{ code : "65090", intitule : "BETPOUY", cp : "65230" },
{ code : "65091", intitule : "BETTES", cp : "65130" },
{ code : "65092", intitule : "BEYREDE JUMET", cp : "65410" },
{ code : "65093", intitule : "BIZE", cp : "65150" },
{ code : "65094", intitule : "BIZOUS", cp : "65150" },
{ code : "65095", intitule : "BONNEFONT", cp : "65220" },
{ code : "65096", intitule : "BONNEMAZON", cp : "65130" },
{ code : "65097", intitule : "BONREPOS", cp : "65330" },
{ code : "65098", intitule : "BOO SILHEN", cp : "65400" },
{ code : "65099", intitule : "BORDERES LOURON", cp : "65590" },
{ code : "65099", intitule : "BORDERES LOURON", cp : "65590" },
{ code : "65100", intitule : "BORDERES SUR L ECHEZ", cp : "65320" },
{ code : "65101", intitule : "BORDES", cp : "65190" },
{ code : "65102", intitule : "BOUILH DEVANT", cp : "65140" },
{ code : "65103", intitule : "BOUILH PEREUILH", cp : "65350" },
{ code : "65104", intitule : "BOULIN", cp : "65350" },
{ code : "65105", intitule : "BOURG DE BIGORRE", cp : "65130" },
{ code : "65106", intitule : "BOURISP", cp : "65170" },
{ code : "65107", intitule : "BOURREAC", cp : "65100" },
{ code : "65108", intitule : "BOURS", cp : "65460" },
{ code : "65109", intitule : "BRAMEVAQUE", cp : "65370" },
{ code : "65110", intitule : "BUGARD", cp : "65220" },
{ code : "65111", intitule : "BULAN", cp : "65130" },
{ code : "65112", intitule : "BUN", cp : "65400" },
{ code : "65113", intitule : "BURG", cp : "65190" },
{ code : "65114", intitule : "BUZON", cp : "65140" },
{ code : "65115", intitule : "CABANAC", cp : "65350" },
{ code : "65116", intitule : "CADEAC", cp : "65240" },
{ code : "65117", intitule : "CADEILHAN TRACHERE", cp : "65170" },
{ code : "65118", intitule : "CAHARET", cp : "65190" },
{ code : "65119", intitule : "CAIXON", cp : "65500" },
{ code : "65120", intitule : "CALAVANTE", cp : "65190" },
{ code : "65121", intitule : "CAMALES", cp : "65500" },
{ code : "65122", intitule : "CAMOUS", cp : "65410" },
{ code : "65123", intitule : "CAMPAN", cp : "65710" },
{ code : "65123", intitule : "CAMPAN", cp : "65710" },
{ code : "65123", intitule : "CAMPAN", cp : "65710" },
{ code : "65123", intitule : "CAMPAN", cp : "65710" },
{ code : "65123", intitule : "CAMPAN", cp : "65710" },
{ code : "65124", intitule : "CAMPARAN", cp : "65170" },
{ code : "65125", intitule : "CAMPISTROUS", cp : "65300" },
{ code : "65126", intitule : "CAMPUZAN", cp : "65230" },
{ code : "65127", intitule : "CAPVERN", cp : "65130" },
{ code : "65128", intitule : "CASTELBAJAC", cp : "65330" },
{ code : "65129", intitule : "CASTELNAU MAGNOAC", cp : "65230" },
{ code : "65130", intitule : "CASTELNAU RIVIERE BASSE", cp : "65700" },
{ code : "65131", intitule : "CASTELVIEILH", cp : "65350" },
{ code : "65132", intitule : "CASTERA LANUSSE", cp : "65190" },
{ code : "65133", intitule : "CASTERA LOU", cp : "65350" },
{ code : "65134", intitule : "CASTERETS", cp : "65230" },
{ code : "65135", intitule : "CASTILLON", cp : "65130" },
{ code : "65136", intitule : "CAUBOUS", cp : "65230" },
{ code : "65137", intitule : "CAUSSADE RIVIERE", cp : "65700" },
{ code : "65138", intitule : "CAUTERETS", cp : "65110" },
{ code : "65139", intitule : "CAZARILH", cp : "65370" },
{ code : "65140", intitule : "CAZAUX DEBAT", cp : "65590" },
{ code : "65141", intitule : "CAZAUX FRECHET ANERAN CAMORS", cp : "65240" },
{ code : "65141", intitule : "CAZAUX FRECHET ANERAN CAMORS", cp : "65240" },
{ code : "65141", intitule : "CAZAUX FRECHET ANERAN CAMORS", cp : "65240" },
{ code : "65142", intitule : "CHELLE DEBAT", cp : "65350" },
{ code : "65143", intitule : "CHELLE SPOU", cp : "65130" },
{ code : "65144", intitule : "CHEUST", cp : "65100" },
{ code : "65145", intitule : "CHEZE", cp : "65120" },
{ code : "65146", intitule : "CHIS", cp : "65800" },
{ code : "65147", intitule : "CIEUTAT", cp : "65200" },
{ code : "65148", intitule : "CIZOS", cp : "65230" },
{ code : "65149", intitule : "CLARAC", cp : "65190" },
{ code : "65150", intitule : "CLARENS", cp : "65300" },
{ code : "65151", intitule : "COLLONGUES", cp : "65350" },
{ code : "65153", intitule : "COUSSAN", cp : "65350" },
{ code : "65154", intitule : "CRECHETS", cp : "65370" },
{ code : "65155", intitule : "DEVEZE", cp : "65230" },
{ code : "65156", intitule : "DOURS", cp : "65350" },
{ code : "65157", intitule : "ENS", cp : "65170" },
{ code : "65158", intitule : "ESBAREICH", cp : "65370" },
{ code : "65159", intitule : "ESCALA", cp : "65250" },
{ code : "65160", intitule : "ESCAUNETS", cp : "65500" },
{ code : "65161", intitule : "ESCONDEAUX", cp : "65140" },
{ code : "65162", intitule : "ESCONNETS", cp : "65130" },
{ code : "65163", intitule : "ESCOTS", cp : "65130" },
{ code : "65164", intitule : "ESCOUBES POUTS", cp : "65100" },
{ code : "65165", intitule : "ESPARROS", cp : "65130" },
{ code : "65166", intitule : "ESPECHE", cp : "65130" },
{ code : "65167", intitule : "ESPIEILH", cp : "65130" },
{ code : "65168", intitule : "ESQUIEZE SERE", cp : "65120" },
{ code : "65169", intitule : "ESTAING", cp : "65400" },
{ code : "65170", intitule : "ESTAMPURES", cp : "65220" },
{ code : "65171", intitule : "ESTARVIELLE", cp : "65240" },
{ code : "65172", intitule : "ESTENSAN", cp : "65170" },
{ code : "65173", intitule : "ESTERRE", cp : "65120" },
{ code : "65174", intitule : "ESTIRAC", cp : "65700" },
{ code : "65175", intitule : "FERRERE", cp : "65370" },
{ code : "65176", intitule : "FERRIERES", cp : "65560" },
{ code : "65177", intitule : "FONTRAILLES", cp : "65220" },
{ code : "65178", intitule : "FRECHEDE", cp : "65220" },
{ code : "65179", intitule : "FRECHENDETS", cp : "65130" },
{ code : "65180", intitule : "FRECHET AURE", cp : "65240" },
{ code : "65181", intitule : "FRECHOU FRECHET", cp : "65190" },
{ code : "65182", intitule : "GAILLAGOS", cp : "65400" },
{ code : "65183", intitule : "GALAN", cp : "65330" },
{ code : "65184", intitule : "GALEZ", cp : "65330" },
{ code : "65185", intitule : "GARDERES", cp : "65320" },
{ code : "65186", intitule : "GAUDENT", cp : "65370" },
{ code : "65187", intitule : "GAUSSAN", cp : "65670" },
{ code : "65189", intitule : "GAYAN", cp : "65320" },
{ code : "65190", intitule : "GAZAVE", cp : "65250" },
{ code : "65191", intitule : "GAZOST", cp : "65100" },
{ code : "65192", intitule : "GAVARNIE GEDRE", cp : "65120" },
{ code : "65192", intitule : "GAVARNIE GEDRE", cp : "65120" },
{ code : "65193", intitule : "GEMBRIE", cp : "65370" },
{ code : "65194", intitule : "GENEREST", cp : "65150" },
{ code : "65195", intitule : "GENOS", cp : "65240" },
{ code : "65196", intitule : "GENSAC", cp : "65140" },
{ code : "65197", intitule : "GER", cp : "65100" },
{ code : "65198", intitule : "GERDE", cp : "65200" },
{ code : "65199", intitule : "GERM", cp : "65240" },
{ code : "65200", intitule : "GERMS SUR L OUSSOUET", cp : "65200" },
{ code : "65201", intitule : "GEU", cp : "65100" },
{ code : "65202", intitule : "GEZ", cp : "65400" },
{ code : "65203", intitule : "GEZ EZ ANGLES", cp : "65100" },
{ code : "65204", intitule : "GONEZ", cp : "65350" },
{ code : "65205", intitule : "GOUAUX", cp : "65240" },
{ code : "65206", intitule : "GOUDON", cp : "65190" },
{ code : "65207", intitule : "GOURGUE", cp : "65130" },
{ code : "65208", intitule : "GRAILHEN", cp : "65170" },
{ code : "65209", intitule : "GREZIAN", cp : "65240" },
{ code : "65210", intitule : "GRUST", cp : "65120" },
{ code : "65211", intitule : "GUCHAN", cp : "65170" },
{ code : "65212", intitule : "GUCHEN", cp : "65240" },
{ code : "65213", intitule : "GUIZERIX", cp : "65230" },
{ code : "65214", intitule : "HACHAN", cp : "65230" },
{ code : "65215", intitule : "HAGEDET", cp : "65700" },
{ code : "65216", intitule : "HAUBAN", cp : "65200" },
{ code : "65217", intitule : "HAUTAGET", cp : "65150" },
{ code : "65218", intitule : "HECHES", cp : "65250" },
{ code : "65218", intitule : "HECHES", cp : "65250" },
{ code : "65219", intitule : "HERES", cp : "65700" },
{ code : "65220", intitule : "HIBARETTE", cp : "65380" },
{ code : "65221", intitule : "HIIS", cp : "65200" },
{ code : "65222", intitule : "HITTE", cp : "65190" },
{ code : "65223", intitule : "HORGUES", cp : "65310" },
{ code : "65224", intitule : "HOUEYDETS", cp : "65330" },
{ code : "65225", intitule : "HOURC", cp : "65350" },
{ code : "65226", intitule : "IBOS", cp : "65420" },
{ code : "65228", intitule : "ILHET", cp : "65410" },
{ code : "65229", intitule : "ILHEU", cp : "65370" },
{ code : "65230", intitule : "IZAOURT", cp : "65370" },
{ code : "65231", intitule : "IZAUX", cp : "65250" },
{ code : "65232", intitule : "JACQUE", cp : "65350" },
{ code : "65233", intitule : "JARRET", cp : "65100" },
{ code : "65234", intitule : "JEZEAU", cp : "65240" },
{ code : "65235", intitule : "JUILLAN", cp : "65290" },
{ code : "65236", intitule : "JULOS", cp : "65100" },
{ code : "65237", intitule : "JUNCALAS", cp : "65100" },
{ code : "65238", intitule : "LABASSERE", cp : "65200" },
{ code : "65239", intitule : "LABASTIDE", cp : "65130" },
{ code : "65240", intitule : "LABATUT RIVIERE", cp : "65700" },
{ code : "65241", intitule : "LABORDE", cp : "65130" },
{ code : "65242", intitule : "LACASSAGNE", cp : "65140" },
{ code : "65243", intitule : "LAFITOLE", cp : "65700" },
{ code : "65244", intitule : "LAGARDE", cp : "65320" },
{ code : "65245", intitule : "LAGRANGE", cp : "65300" },
{ code : "65247", intitule : "ARRAYOU LAHITTE", cp : "65100" },
{ code : "65248", intitule : "LAHITTE TOUPIERE", cp : "65700" },
{ code : "65249", intitule : "LALANNE", cp : "65230" },
{ code : "65250", intitule : "LALANNE TRIE", cp : "65220" },
{ code : "65251", intitule : "LALOUBERE", cp : "65310" },
{ code : "65252", intitule : "LAMARQUE PONTACQ", cp : "65380" },
{ code : "65253", intitule : "LAMARQUE RUSTAING", cp : "65220" },
{ code : "65254", intitule : "LAMEAC", cp : "65140" },
{ code : "65255", intitule : "LANCON", cp : "65240" },
{ code : "65256", intitule : "LANESPEDE", cp : "65190" },
{ code : "65257", intitule : "LANNE", cp : "65380" },
{ code : "65258", intitule : "LANNEMEZAN", cp : "65300" },
{ code : "65259", intitule : "LANSAC", cp : "65350" },
{ code : "65260", intitule : "LAPEYRE", cp : "65220" },
{ code : "65261", intitule : "LARAN", cp : "65670" },
{ code : "65262", intitule : "LARREULE", cp : "65700" },
{ code : "65263", intitule : "LARROQUE", cp : "65230" },
{ code : "65264", intitule : "LASCAZERES", cp : "65700" },
{ code : "65265", intitule : "LASLADES", cp : "65350" },
{ code : "65266", intitule : "LASSALES", cp : "65670" },
{ code : "65267", intitule : "LAU BALAGNAS", cp : "65400" },
{ code : "65268", intitule : "LAYRISSE", cp : "65380" },
{ code : "65269", intitule : "LESCURRY", cp : "65140" },
{ code : "65270", intitule : "LESPOUEY", cp : "65190" },
{ code : "65271", intitule : "LEZIGNAN", cp : "65100" },
{ code : "65272", intitule : "LHEZ", cp : "65190" },
{ code : "65273", intitule : "LIAC", cp : "65140" },
{ code : "65274", intitule : "LIBAROS", cp : "65330" },
{ code : "65275", intitule : "LIES", cp : "65200" },
{ code : "65276", intitule : "LIZOS", cp : "65350" },
{ code : "65277", intitule : "LOMBRES", cp : "65150" },
{ code : "65278", intitule : "LOMNE", cp : "65130" },
{ code : "65279", intitule : "LORTET", cp : "65250" },
{ code : "65280", intitule : "LOUBAJAC", cp : "65100" },
{ code : "65281", intitule : "LOUCRUP", cp : "65200" },
{ code : "65282", intitule : "LOUDENVIELLE", cp : "65510" },
{ code : "65282", intitule : "LOUDENVIELLE", cp : "65240" },
{ code : "65283", intitule : "LOUDERVIELLE", cp : "65240" },
{ code : "65284", intitule : "LOUEY", cp : "65290" },
{ code : "65285", intitule : "LOUIT", cp : "65350" },
{ code : "65286", intitule : "LOURDES", cp : "65100" },
{ code : "65287", intitule : "LOURES BAROUSSE", cp : "65370" },
{ code : "65288", intitule : "LUBRET ST LUC", cp : "65220" },
{ code : "65289", intitule : "LUBY BETMONT", cp : "65220" },
{ code : "65290", intitule : "LUC", cp : "65190" },
{ code : "65291", intitule : "LUGAGNAN", cp : "65100" },
{ code : "65292", intitule : "LUQUET", cp : "65320" },
{ code : "65293", intitule : "LUSTAR", cp : "65220" },
{ code : "65294", intitule : "LUTILHOUS", cp : "65300" },
{ code : "65295", intitule : "LUZ ST SAUVEUR", cp : "65120" },
{ code : "65295", intitule : "LUZ ST SAUVEUR", cp : "65120" },
{ code : "65296", intitule : "MADIRAN", cp : "65700" },
{ code : "65297", intitule : "MANSAN", cp : "65140" },
{ code : "65298", intitule : "MARQUERIE", cp : "65350" },
{ code : "65299", intitule : "MARSAC", cp : "65500" },
{ code : "65300", intitule : "MARSAS", cp : "65200" },
{ code : "65301", intitule : "MARSEILLAN", cp : "65350" },
{ code : "65303", intitule : "MASCARAS", cp : "65190" },
{ code : "65304", intitule : "MAUBOURGUET", cp : "65700" },
{ code : "65305", intitule : "MAULEON BAROUSSE", cp : "65370" },
{ code : "65306", intitule : "MAUVEZIN", cp : "65130" },
{ code : "65307", intitule : "MAZERES DE NESTE", cp : "65150" },
{ code : "65308", intitule : "MAZEROLLES", cp : "65220" },
{ code : "65309", intitule : "MAZOUAU", cp : "65250" },
{ code : "65310", intitule : "MERILHEU", cp : "65200" },
{ code : "65311", intitule : "MINGOT", cp : "65140" },
{ code : "65312", intitule : "MOLERE", cp : "65130" },
{ code : "65313", intitule : "MOMERES", cp : "65360" },
{ code : "65314", intitule : "MONFAUCON", cp : "65140" },
{ code : "65315", intitule : "MONLEON MAGNOAC", cp : "65670" },
{ code : "65316", intitule : "MONLONG", cp : "65670" },
{ code : "65317", intitule : "MONT", cp : "65240" },
{ code : "65318", intitule : "MONTASTRUC", cp : "65330" },
{ code : "65319", intitule : "MONTEGUT", cp : "65150" },
{ code : "65320", intitule : "MONTGAILLARD", cp : "65200" },
{ code : "65321", intitule : "MONTIGNAC", cp : "65690" },
{ code : "65322", intitule : "MONTOUSSE", cp : "65250" },
{ code : "65323", intitule : "MONTSERIE", cp : "65150" },
{ code : "65324", intitule : "MOULEDOUS", cp : "65190" },
{ code : "65325", intitule : "MOUMOULOUS", cp : "65140" },
{ code : "65326", intitule : "MUN", cp : "65350" },
{ code : "65327", intitule : "NESTIER", cp : "65150" },
{ code : "65328", intitule : "NEUILH", cp : "65200" },
{ code : "65329", intitule : "NISTOS", cp : "65150" },
{ code : "65330", intitule : "NOUILHAN", cp : "65500" },
{ code : "65331", intitule : "ODOS", cp : "65310" },
{ code : "65332", intitule : "OLEAC DEBAT", cp : "65350" },
{ code : "65333", intitule : "OLEAC DESSUS", cp : "65190" },
{ code : "65334", intitule : "OMEX", cp : "65100" },
{ code : "65335", intitule : "ORDIZAN", cp : "65200" },
{ code : "65336", intitule : "ORGAN", cp : "65230" },
{ code : "65337", intitule : "ORIEUX", cp : "65190" },
{ code : "65338", intitule : "ORIGNAC", cp : "65200" },
{ code : "65339", intitule : "ORINCLES", cp : "65380" },
{ code : "65340", intitule : "ORLEIX", cp : "65800" },
{ code : "65341", intitule : "OROIX", cp : "65320" },
{ code : "65342", intitule : "OSMETS", cp : "65350" },
{ code : "65343", intitule : "OSSEN", cp : "65100" },
{ code : "65344", intitule : "OSSUN", cp : "65380" },
{ code : "65345", intitule : "OSSUN EZ ANGLES", cp : "65100" },
{ code : "65346", intitule : "OUEILLOUX", cp : "65190" },
{ code : "65347", intitule : "OURDE", cp : "65370" },
{ code : "65348", intitule : "OURDIS COTDOUSSAN", cp : "65100" },
{ code : "65349", intitule : "OURDON", cp : "65100" },
{ code : "65350", intitule : "OURSBELILLE", cp : "65490" },
{ code : "65351", intitule : "OUSTE", cp : "65100" },
{ code : "65352", intitule : "OUZOUS", cp : "65400" },
{ code : "65353", intitule : "OZON", cp : "65190" },
{ code : "65354", intitule : "PAILHAC", cp : "65240" },
{ code : "65355", intitule : "PAREAC", cp : "65100" },
{ code : "65356", intitule : "PERE", cp : "65130" },
{ code : "65357", intitule : "PEYRAUBE", cp : "65190" },
{ code : "65358", intitule : "PEYRET ST ANDRE", cp : "65230" },
{ code : "65359", intitule : "PEYRIGUERE", cp : "65350" },
{ code : "65360", intitule : "PEYROUSE", cp : "65270" },
{ code : "65361", intitule : "PEYRUN", cp : "65140" },
{ code : "65362", intitule : "PIERREFITTE NESTALAS", cp : "65260" },
{ code : "65363", intitule : "PINAS", cp : "65300" },
{ code : "65364", intitule : "PINTAC", cp : "65320" },
{ code : "65366", intitule : "POUEYFERRE", cp : "65100" },
{ code : "65367", intitule : "POUMAROUS", cp : "65190" },
{ code : "65368", intitule : "POUY", cp : "65230" },
{ code : "65369", intitule : "POUYASTRUC", cp : "65350" },
{ code : "65370", intitule : "POUZAC", cp : "65200" },
{ code : "65371", intitule : "PRECHAC", cp : "65400" },
{ code : "65372", intitule : "PUJO", cp : "65500" },
{ code : "65373", intitule : "PUNTOUS", cp : "65230" },
{ code : "65374", intitule : "PUYDARRIEUX", cp : "65220" },
{ code : "65375", intitule : "RABASTENS DE BIGORRE", cp : "65140" },
{ code : "65376", intitule : "RECURT", cp : "65330" },
{ code : "65377", intitule : "REJAUMONT", cp : "65300" },
{ code : "65378", intitule : "RICAUD", cp : "65190" },
{ code : "65379", intitule : "RIS", cp : "65590" },
{ code : "65380", intitule : "SABALOS", cp : "65350" },
{ code : "65381", intitule : "SABARROS", cp : "65330" },
{ code : "65382", intitule : "SACOUE", cp : "65370" },
{ code : "65383", intitule : "SADOURNIN", cp : "65220" },
{ code : "65384", intitule : "SAILHAN", cp : "65170" },
{ code : "65385", intitule : "ST ARROMAN", cp : "65250" },
{ code : "65386", intitule : "ST CREAC", cp : "65100" },
{ code : "65387", intitule : "ST LANNE", cp : "65700" },
{ code : "65388", intitule : "ST LARY SOULAN", cp : "65170" },
{ code : "65389", intitule : "ST LAURENT DE NESTE", cp : "65150" },
{ code : "65390", intitule : "ST LEZER", cp : "65500" },
{ code : "65391", intitule : "STE MARIE", cp : "65370" },
{ code : "65392", intitule : "ST MARTIN", cp : "65360" },
{ code : "65393", intitule : "ST PASTOUS", cp : "65400" },
{ code : "65394", intitule : "ST PAUL", cp : "65150" },
{ code : "65395", intitule : "ST PE DE BIGORRE", cp : "65270" },
{ code : "65396", intitule : "ST SAVIN", cp : "65400" },
{ code : "65397", intitule : "ST SEVER DE RUSTAN", cp : "65140" },
{ code : "65398", intitule : "SALECHAN", cp : "65370" },
{ code : "65399", intitule : "SALIGOS", cp : "65120" },
{ code : "65400", intitule : "SALLES", cp : "65400" },
{ code : "65401", intitule : "SALLES ADOUR", cp : "65360" },
{ code : "65402", intitule : "SAMURAN", cp : "65370" },
{ code : "65403", intitule : "SANOUS", cp : "65500" },
{ code : "65404", intitule : "SARIAC MAGNOAC", cp : "65230" },
{ code : "65405", intitule : "SARLABOUS", cp : "65130" },
{ code : "65406", intitule : "SARNIGUET", cp : "65390" },
{ code : "65407", intitule : "SARP", cp : "65370" },
{ code : "65408", intitule : "SARRANCOLIN", cp : "65410" },
{ code : "65409", intitule : "SARRIAC BIGORRE", cp : "65140" },
{ code : "65410", intitule : "SARROUILLES", cp : "65600" },
{ code : "65411", intitule : "SASSIS", cp : "65120" },
{ code : "65412", intitule : "SAUVETERRE", cp : "65700" },
{ code : "65413", intitule : "SAZOS", cp : "65120" },
{ code : "65414", intitule : "SEGALAS", cp : "65140" },
{ code : "65415", intitule : "SEGUS", cp : "65100" },
{ code : "65416", intitule : "SEICH", cp : "65150" },
{ code : "65417", intitule : "SEMEAC", cp : "65600" },
{ code : "65418", intitule : "SENAC", cp : "65140" },
{ code : "65419", intitule : "SENTOUS", cp : "65330" },
{ code : "65420", intitule : "SERE EN LAVEDAN", cp : "65400" },
{ code : "65421", intitule : "SERE LANSO", cp : "65100" },
{ code : "65422", intitule : "SERON", cp : "65320" },
{ code : "65423", intitule : "SERE RUSTAING", cp : "65220" },
{ code : "65424", intitule : "SERS", cp : "65120" },
{ code : "65425", intitule : "SIARROUY", cp : "65500" },
{ code : "65426", intitule : "SINZOS", cp : "65190" },
{ code : "65427", intitule : "SIRADAN", cp : "65370" },
{ code : "65428", intitule : "SIREIX", cp : "65400" },
{ code : "65429", intitule : "SOMBRUN", cp : "65700" },
{ code : "65430", intitule : "SOREAC", cp : "65350" },
{ code : "65431", intitule : "SOST", cp : "65370" },
{ code : "65432", intitule : "SOUBLECAUSE", cp : "65700" },
{ code : "65433", intitule : "SOUES", cp : "65430" },
{ code : "65435", intitule : "SOULOM", cp : "65260" },
{ code : "65436", intitule : "SOUYEAUX", cp : "65350" },
{ code : "65437", intitule : "TAJAN", cp : "65300" },
{ code : "65438", intitule : "TALAZAC", cp : "65500" },
{ code : "65439", intitule : "TARASTEIX", cp : "65320" },
{ code : "65440", intitule : "TARBES", cp : "65000" },
{ code : "65441", intitule : "THEBE", cp : "65370" },
{ code : "65442", intitule : "THERMES MAGNOAC", cp : "65230" },
{ code : "65443", intitule : "THUY", cp : "65350" },
{ code : "65444", intitule : "TIBIRAN JAUNAC", cp : "65150" },
{ code : "65445", intitule : "TILHOUSE", cp : "65130" },
{ code : "65446", intitule : "TOSTAT", cp : "65140" },
{ code : "65447", intitule : "TOURNAY", cp : "65190" },
{ code : "65448", intitule : "TOURNOUS DARRE", cp : "65220" },
{ code : "65449", intitule : "TOURNOUS DEVANT", cp : "65330" },
{ code : "65450", intitule : "TRAMEZAIGUES", cp : "65170" },
{ code : "65451", intitule : "TREBONS", cp : "65200" },
{ code : "65452", intitule : "TRIE SUR BAISE", cp : "65220" },
{ code : "65453", intitule : "TROUBAT", cp : "65370" },
{ code : "65454", intitule : "TROULEY LABARTHE", cp : "65140" },
{ code : "65455", intitule : "TUZAGUET", cp : "65150" },
{ code : "65456", intitule : "UGLAS", cp : "65300" },
{ code : "65457", intitule : "UGNOUAS", cp : "65140" },
{ code : "65458", intitule : "UZ", cp : "65400" },
{ code : "65459", intitule : "UZER", cp : "65200" },
{ code : "65460", intitule : "VIC EN BIGORRE", cp : "65500" },
{ code : "65461", intitule : "VIDOU", cp : "65220" },
{ code : "65462", intitule : "VIDOUZE", cp : "65700" },
{ code : "65463", intitule : "VIELLA", cp : "65120" },
{ code : "65464", intitule : "VIELLE ADOUR", cp : "65360" },
{ code : "65465", intitule : "VIELLE AURE", cp : "65170" },
{ code : "65466", intitule : "VIELLE LOURON", cp : "65240" },
{ code : "65467", intitule : "VIER BORDES", cp : "65400" },
{ code : "65468", intitule : "VIEUZOS", cp : "65230" },
{ code : "65469", intitule : "VIEY", cp : "65120" },
{ code : "65470", intitule : "VIGER", cp : "65100" },
{ code : "65471", intitule : "VIGNEC", cp : "65170" },
{ code : "65472", intitule : "VILLEFRANQUE", cp : "65700" },
{ code : "65473", intitule : "VILLELONGUE", cp : "65260" },
{ code : "65474", intitule : "VILLEMBITS", cp : "65220" },
{ code : "65475", intitule : "VILLEMUR", cp : "65230" },
{ code : "65476", intitule : "VILLENAVE PRES BEARN", cp : "65500" },
{ code : "65477", intitule : "VILLENAVE PRES MARSAC", cp : "65500" },
{ code : "65478", intitule : "VISCOS", cp : "65120" },
{ code : "65479", intitule : "VISKER", cp : "65200" },
{ code : "65480", intitule : "VIZOS", cp : "65120" },
{ code : "65481", intitule : "BAREGES", cp : "65120" },
{ code : "65482", intitule : "CANTAOUS", cp : "65150" },
{ code : "66001", intitule : "L ALBERE", cp : "66480" },
{ code : "66002", intitule : "ALENYA", cp : "66200" },
{ code : "66003", intitule : "AMELIE LES BAINS PALALDA", cp : "66110" },
{ code : "66004", intitule : "LES ANGLES", cp : "66210" },
{ code : "66005", intitule : "ANGOUSTRINE VILLENEUVE DES ESCALDES", cp : "66760" },
{ code : "66005", intitule : "ANGOUSTRINE VILLENEUVE DES ESCALDES", cp : "66760" },
{ code : "66006", intitule : "ANSIGNAN", cp : "66220" },
{ code : "66007", intitule : "ARBOUSSOLS", cp : "66320" },
{ code : "66008", intitule : "ARGELES SUR MER", cp : "66700" },
{ code : "66008", intitule : "ARGELES SUR MER", cp : "66700" },
{ code : "66009", intitule : "ARLES SUR TECH", cp : "66150" },
{ code : "66010", intitule : "AYGUATEBIA TALAU", cp : "66360" },
{ code : "66010", intitule : "AYGUATEBIA TALAU", cp : "66360" },
{ code : "66011", intitule : "BAGES", cp : "66670" },
{ code : "66012", intitule : "BAHO", cp : "66540" },
{ code : "66013", intitule : "BAILLESTAVY", cp : "66320" },
{ code : "66014", intitule : "BAIXAS", cp : "66390" },
{ code : "66015", intitule : "BANYULS DELS ASPRES", cp : "66300" },
{ code : "66016", intitule : "BANYULS SUR MER", cp : "66650" },
{ code : "66017", intitule : "LE BARCARES", cp : "66420" },
{ code : "66017", intitule : "LE BARCARES", cp : "66420" },
{ code : "66018", intitule : "LA BASTIDE", cp : "66110" },
{ code : "66019", intitule : "BELESTA", cp : "66720" },
{ code : "66020", intitule : "BOLQUERE", cp : "66210" },
{ code : "66020", intitule : "BOLQUERE", cp : "66210" },
{ code : "66021", intitule : "BOMPAS", cp : "66430" },
{ code : "66022", intitule : "BOULE D AMONT", cp : "66130" },
{ code : "66023", intitule : "BOULETERNERE", cp : "66130" },
{ code : "66024", intitule : "LE BOULOU", cp : "66160" },
{ code : "66025", intitule : "BOURG MADAME", cp : "66760" },
{ code : "66025", intitule : "BOURG MADAME", cp : "66760" },
{ code : "66026", intitule : "BROUILLA", cp : "66620" },
{ code : "66027", intitule : "LA CABANASSE", cp : "66210" },
{ code : "66028", intitule : "CABESTANY", cp : "66330" },
{ code : "66029", intitule : "CAIXAS", cp : "66300" },
{ code : "66030", intitule : "CALCE", cp : "66600" },
{ code : "66032", intitule : "CALMEILLES", cp : "66400" },
{ code : "66033", intitule : "CAMELAS", cp : "66300" },
{ code : "66034", intitule : "CAMPOME", cp : "66500" },
{ code : "66035", intitule : "CAMPOUSSY", cp : "66730" },
{ code : "66036", intitule : "CANAVEILLES", cp : "66360" },
{ code : "66037", intitule : "CANET EN ROUSSILLON", cp : "66140" },
{ code : "66037", intitule : "CANET EN ROUSSILLON", cp : "66140" },
{ code : "66038", intitule : "CANOHES", cp : "66680" },
{ code : "66039", intitule : "CARAMANY", cp : "66720" },
{ code : "66040", intitule : "CASEFABRE", cp : "66130" },
{ code : "66041", intitule : "CASES DE PENE", cp : "66600" },
{ code : "66042", intitule : "CASSAGNES", cp : "66720" },
{ code : "66043", intitule : "CASTEIL", cp : "66820" },
{ code : "66044", intitule : "CASTELNOU", cp : "66300" },
{ code : "66045", intitule : "CATLLAR", cp : "66500" },
{ code : "66046", intitule : "CAUDIES DE FENOUILLEDES", cp : "66220" },
{ code : "66047", intitule : "CAUDIES DE CONFLENT", cp : "66360" },
{ code : "66048", intitule : "CERBERE", cp : "66290" },
{ code : "66049", intitule : "CERET", cp : "66400" },
{ code : "66050", intitule : "CLAIRA", cp : "66530" },
{ code : "66051", intitule : "CLARA", cp : "66500" },
{ code : "66052", intitule : "CODALET", cp : "66500" },
{ code : "66053", intitule : "COLLIOURE", cp : "66190" },
{ code : "66054", intitule : "CONAT", cp : "66500" },
{ code : "66055", intitule : "CORBERE", cp : "66130" },
{ code : "66056", intitule : "CORBERE LES CABANES", cp : "66130" },
{ code : "66057", intitule : "CORNEILLA DE CONFLENT", cp : "66820" },
{ code : "66058", intitule : "CORNEILLA LA RIVIERE", cp : "66550" },
{ code : "66059", intitule : "CORNEILLA DEL VERCOL", cp : "66200" },
{ code : "66060", intitule : "CORSAVY", cp : "66150" },
{ code : "66061", intitule : "COUSTOUGES", cp : "66260" },
{ code : "66062", intitule : "DORRES", cp : "66760" },
{ code : "66063", intitule : "LES CLUSES", cp : "66480" },
{ code : "66064", intitule : "EGAT", cp : "66120" },
{ code : "66065", intitule : "ELNE", cp : "66200" },
{ code : "66066", intitule : "ENVEITG", cp : "66760" },
{ code : "66067", intitule : "ERR", cp : "66800" },
{ code : "66068", intitule : "ESCARO", cp : "66360" },
{ code : "66069", intitule : "ESPIRA DE L AGLY", cp : "66600" },
{ code : "66070", intitule : "ESPIRA DE CONFLENT", cp : "66320" },
{ code : "66071", intitule : "ESTAGEL", cp : "66310" },
{ code : "66072", intitule : "ESTAVAR", cp : "66800" },
{ code : "66073", intitule : "ESTOHER", cp : "66320" },
{ code : "66074", intitule : "EUS", cp : "66500" },
{ code : "66075", intitule : "EYNE", cp : "66800" },
{ code : "66076", intitule : "FELLUNS", cp : "66730" },
{ code : "66077", intitule : "FENOUILLET", cp : "66220" },
{ code : "66078", intitule : "FILLOLS", cp : "66820" },
{ code : "66079", intitule : "FINESTRET", cp : "66320" },
{ code : "66080", intitule : "FONTPEDROUSE", cp : "66360" },
{ code : "66081", intitule : "FONTRABIOUSE", cp : "66210" },
{ code : "66082", intitule : "FORMIGUERES", cp : "66210" },
{ code : "66083", intitule : "FOSSE", cp : "66220" },
{ code : "66084", intitule : "FOURQUES", cp : "66300" },
{ code : "66085", intitule : "FUILLA", cp : "66820" },
{ code : "66086", intitule : "GLORIANES", cp : "66320" },
{ code : "66088", intitule : "ILLE SUR TET", cp : "66130" },
{ code : "66089", intitule : "JOCH", cp : "66320" },
{ code : "66090", intitule : "JUJOLS", cp : "66360" },
{ code : "66091", intitule : "LAMANERE", cp : "66230" },
{ code : "66092", intitule : "LANSAC", cp : "66720" },
{ code : "66093", intitule : "LAROQUE DES ALBERES", cp : "66740" },
{ code : "66094", intitule : "LATOUR BAS ELNE", cp : "66200" },
{ code : "66095", intitule : "LATOUR DE CAROL", cp : "66760" },
{ code : "66096", intitule : "LATOUR DE FRANCE", cp : "66720" },
{ code : "66097", intitule : "LESQUERDE", cp : "66220" },
{ code : "66098", intitule : "LA LLAGONNE", cp : "66210" },
{ code : "66099", intitule : "LLAURO", cp : "66300" },
{ code : "66100", intitule : "LLO", cp : "66800" },
{ code : "66101", intitule : "LLUPIA", cp : "66300" },
{ code : "66102", intitule : "MANTET", cp : "66360" },
{ code : "66103", intitule : "MARQUIXANES", cp : "66320" },
{ code : "66104", intitule : "LOS MASOS", cp : "66500" },
{ code : "66105", intitule : "MATEMALE", cp : "66210" },
{ code : "66106", intitule : "MAUREILLAS LAS ILLAS", cp : "66480" },
{ code : "66106", intitule : "MAUREILLAS LAS ILLAS", cp : "66480" },
{ code : "66106", intitule : "MAUREILLAS LAS ILLAS", cp : "66480" },
{ code : "66107", intitule : "MAURY", cp : "66460" },
{ code : "66108", intitule : "MILLAS", cp : "66170" },
{ code : "66109", intitule : "MOLITG LES BAINS", cp : "66500" },
{ code : "66111", intitule : "MONTALBA LE CHATEAU", cp : "66130" },
{ code : "66112", intitule : "MONTAURIOL", cp : "66300" },
{ code : "66113", intitule : "MONTBOLO", cp : "66110" },
{ code : "66114", intitule : "MONTESCOT", cp : "66200" },
{ code : "66115", intitule : "MONTESQUIEU DES ALBERES", cp : "66740" },
{ code : "66116", intitule : "MONTFERRER", cp : "66150" },
{ code : "66117", intitule : "MONT LOUIS", cp : "66210" },
{ code : "66118", intitule : "MONTNER", cp : "66720" },
{ code : "66119", intitule : "MOSSET", cp : "66500" },
{ code : "66120", intitule : "NAHUJA", cp : "66340" },
{ code : "66121", intitule : "NEFIACH", cp : "66170" },
{ code : "66122", intitule : "NOHEDES", cp : "66500" },
{ code : "66123", intitule : "NYER", cp : "66360" },
{ code : "66124", intitule : "FONT ROMEU ODEILLO VIA", cp : "66120" },
{ code : "66124", intitule : "FONT ROMEU ODEILLO VIA", cp : "66120" },
{ code : "66125", intitule : "OLETTE", cp : "66360" },
{ code : "66126", intitule : "OMS", cp : "66400" },
{ code : "66127", intitule : "OPOUL PERILLOS", cp : "66600" },
{ code : "66127", intitule : "OPOUL PERILLOS", cp : "66600" },
{ code : "66128", intitule : "OREILLA", cp : "66360" },
{ code : "66129", intitule : "ORTAFFA", cp : "66560" },
{ code : "66130", intitule : "OSSEJA", cp : "66340" },
{ code : "66132", intitule : "PALAU DE CERDAGNE", cp : "66340" },
{ code : "66133", intitule : "PALAU DEL VIDRE", cp : "66690" },
{ code : "66134", intitule : "PASSA", cp : "66300" },
{ code : "66136", intitule : "PERPIGNAN", cp : "66000" },
{ code : "66136", intitule : "PERPIGNAN", cp : "66100" },
{ code : "66137", intitule : "LE PERTHUS", cp : "66480" },
{ code : "66138", intitule : "PEYRESTORTES", cp : "66600" },
{ code : "66139", intitule : "PEZILLA DE CONFLENT", cp : "66730" },
{ code : "66140", intitule : "PEZILLA LA RIVIERE", cp : "66370" },
{ code : "66141", intitule : "PIA", cp : "66380" },
{ code : "66142", intitule : "PLANES", cp : "66210" },
{ code : "66143", intitule : "PLANEZES", cp : "66720" },
{ code : "66144", intitule : "POLLESTRES", cp : "66450" },
{ code : "66145", intitule : "PONTEILLA", cp : "66300" },
{ code : "66146", intitule : "PORTA", cp : "66760" },
{ code : "66147", intitule : "PORTE PUYMORENS", cp : "66760" },
{ code : "66148", intitule : "PORT VENDRES", cp : "66660" },
{ code : "66149", intitule : "PRADES", cp : "66500" },
{ code : "66150", intitule : "PRATS DE MOLLO LA PRESTE", cp : "66230" },
{ code : "66150", intitule : "PRATS DE MOLLO LA PRESTE", cp : "66230" },
{ code : "66151", intitule : "PRATS DE SOURNIA", cp : "66730" },
{ code : "66152", intitule : "PRUGNANES", cp : "66220" },
{ code : "66153", intitule : "PRUNET ET BELPUIG", cp : "66130" },
{ code : "66154", intitule : "PUYVALADOR", cp : "66210" },
{ code : "66155", intitule : "PY", cp : "66360" },
{ code : "66156", intitule : "RABOUILLET", cp : "66730" },
{ code : "66157", intitule : "RAILLEU", cp : "66360" },
{ code : "66158", intitule : "RASIGUERES", cp : "66720" },
{ code : "66159", intitule : "REAL", cp : "66210" },
{ code : "66160", intitule : "REYNES", cp : "66400" },
{ code : "66161", intitule : "RIA SIRACH", cp : "66500" },
{ code : "66162", intitule : "RIGARDA", cp : "66320" },
{ code : "66164", intitule : "RIVESALTES", cp : "66600" },
{ code : "66165", intitule : "RODES", cp : "66320" },
{ code : "66166", intitule : "SAHORRE", cp : "66360" },
{ code : "66167", intitule : "SAILLAGOUSE", cp : "66800" },
{ code : "66168", intitule : "ST ANDRE", cp : "66690" },
{ code : "66169", intitule : "ST ARNAC", cp : "66220" },
{ code : "66170", intitule : "STE COLOMBE DE LA COMMANDERIE", cp : "66300" },
{ code : "66171", intitule : "ST CYPRIEN", cp : "66750" },
{ code : "66171", intitule : "ST CYPRIEN", cp : "66750" },
{ code : "66172", intitule : "ST ESTEVE", cp : "66240" },
{ code : "66173", intitule : "ST FELIU D AMONT", cp : "66170" },
{ code : "66174", intitule : "ST FELIU D AVALL", cp : "66170" },
{ code : "66175", intitule : "ST GENIS DES FONTAINES", cp : "66740" },
{ code : "66176", intitule : "ST HIPPOLYTE", cp : "66510" },
{ code : "66177", intitule : "ST JEAN LASSEILLE", cp : "66300" },
{ code : "66178", intitule : "ST JEAN PLA DE CORTS", cp : "66490" },
{ code : "66179", intitule : "ST LAURENT DE CERDANS", cp : "66260" },
{ code : "66180", intitule : "ST LAURENT DE LA SALANQUE", cp : "66250" },
{ code : "66181", intitule : "STE LEOCADIE", cp : "66800" },
{ code : "66182", intitule : "STE MARIE", cp : "66470" },
{ code : "66183", intitule : "ST MARSAL", cp : "66110" },
{ code : "66184", intitule : "ST MARTIN DE FENOUILLET", cp : "66220" },
{ code : "66185", intitule : "ST MICHEL DE LLOTES", cp : "66130" },
{ code : "66186", intitule : "ST NAZAIRE", cp : "66570" },
{ code : "66187", intitule : "ST PAUL DE FENOUILLET", cp : "66220" },
{ code : "66188", intitule : "ST PIERRE DELS FORCATS", cp : "66210" },
{ code : "66189", intitule : "SALEILLES", cp : "66280" },
{ code : "66190", intitule : "SALSES LE CHATEAU", cp : "66600" },
{ code : "66191", intitule : "SANSA", cp : "66360" },
{ code : "66192", intitule : "SAUTO", cp : "66210" },
{ code : "66193", intitule : "SERDINYA", cp : "66360" },
{ code : "66194", intitule : "SERRALONGUE", cp : "66230" },
{ code : "66195", intitule : "LE SOLER", cp : "66270" },
{ code : "66196", intitule : "SOREDE", cp : "66690" },
{ code : "66197", intitule : "SOUANYAS", cp : "66360" },
{ code : "66198", intitule : "SOURNIA", cp : "66730" },
{ code : "66199", intitule : "TAILLET", cp : "66400" },
{ code : "66201", intitule : "TARERACH", cp : "66320" },
{ code : "66202", intitule : "TARGASSONNE", cp : "66120" },
{ code : "66203", intitule : "TAULIS", cp : "66110" },
{ code : "66204", intitule : "TAURINYA", cp : "66500" },
{ code : "66205", intitule : "TAUTAVEL", cp : "66720" },
{ code : "66206", intitule : "LE TECH", cp : "66230" },
{ code : "66207", intitule : "TERRATS", cp : "66300" },
{ code : "66208", intitule : "THEZA", cp : "66200" },
{ code : "66209", intitule : "THUES ENTRE VALLS", cp : "66360" },
{ code : "66210", intitule : "THUIR", cp : "66300" },
{ code : "66211", intitule : "TORDERES", cp : "66300" },
{ code : "66212", intitule : "TORREILLES", cp : "66440" },
{ code : "66213", intitule : "TOULOUGES", cp : "66350" },
{ code : "66214", intitule : "TRESSERRE", cp : "66300" },
{ code : "66215", intitule : "TREVILLACH", cp : "66130" },
{ code : "66216", intitule : "TRILLA", cp : "66220" },
{ code : "66217", intitule : "TROUILLAS", cp : "66300" },
{ code : "66218", intitule : "UR", cp : "66760" },
{ code : "66219", intitule : "URBANYA", cp : "66500" },
{ code : "66220", intitule : "VALCEBOLLERE", cp : "66340" },
{ code : "66221", intitule : "VALMANYA", cp : "66320" },
{ code : "66222", intitule : "VERNET LES BAINS", cp : "66820" },
{ code : "66223", intitule : "VILLEFRANCHE DE CONFLENT", cp : "66500" },
{ code : "66224", intitule : "VILLELONGUE DE LA SALANQUE", cp : "66410" },
{ code : "66225", intitule : "VILLELONGUE DELS MONTS", cp : "66740" },
{ code : "66226", intitule : "VILLEMOLAQUE", cp : "66300" },
{ code : "66227", intitule : "VILLENEUVE DE LA RAHO", cp : "66180" },
{ code : "66228", intitule : "VILLENEUVE LA RIVIERE", cp : "66610" },
{ code : "66230", intitule : "VINCA", cp : "66320" },
{ code : "66231", intitule : "VINGRAU", cp : "66600" },
{ code : "66232", intitule : "VIRA", cp : "66220" },
{ code : "66233", intitule : "VIVES", cp : "66490" },
{ code : "66234", intitule : "LE VIVIER", cp : "66730" },
{ code : "67001", intitule : "ACHENHEIM", cp : "67204" },
{ code : "67002", intitule : "ADAMSWILLER", cp : "67320" },
{ code : "67003", intitule : "ALBE", cp : "67220" },
{ code : "67004", intitule : "SOMMERAU", cp : "67440" },
{ code : "67004", intitule : "SOMMERAU", cp : "67440" },
{ code : "67004", intitule : "SOMMERAU", cp : "67310" },
{ code : "67004", intitule : "SOMMERAU", cp : "67440" },
{ code : "67005", intitule : "ALTECKENDORF", cp : "67270" },
{ code : "67006", intitule : "ALTENHEIM", cp : "67490" },
{ code : "67008", intitule : "ALTORF", cp : "67120" },
{ code : "67009", intitule : "ALTWILLER", cp : "67260" },
{ code : "67010", intitule : "ANDLAU", cp : "67140" },
{ code : "67011", intitule : "ARTOLSHEIM", cp : "67390" },
{ code : "67012", intitule : "ASCHBACH", cp : "67250" },
{ code : "67013", intitule : "ASSWILLER", cp : "67320" },
{ code : "67014", intitule : "AUENHEIM", cp : "67480" },
{ code : "67016", intitule : "AVOLSHEIM", cp : "67120" },
{ code : "67017", intitule : "BAERENDORF", cp : "67320" },
{ code : "67018", intitule : "BALBRONN", cp : "67310" },
{ code : "67019", intitule : "BALDENHEIM", cp : "67600" },
{ code : "67020", intitule : "BAREMBACH", cp : "67130" },
{ code : "67021", intitule : "BARR", cp : "67140" },
{ code : "67022", intitule : "BASSEMBERG", cp : "67220" },
{ code : "67023", intitule : "BATZENDORF", cp : "67500" },
{ code : "67025", intitule : "BEINHEIM", cp : "67930" },
{ code : "67026", intitule : "BELLEFOSSE", cp : "67130" },
{ code : "67027", intitule : "BELMONT", cp : "67130" },
{ code : "67028", intitule : "BENFELD", cp : "67230" },
{ code : "67029", intitule : "BERG", cp : "67320" },
{ code : "67030", intitule : "BERGBIETEN", cp : "67310" },
{ code : "67031", intitule : "BERNARDSWILLER", cp : "67210" },
{ code : "67032", intitule : "BERNARDVILLE", cp : "67140" },
{ code : "67033", intitule : "BERNOLSHEIM", cp : "67170" },
{ code : "67034", intitule : "BERSTETT", cp : "67370" },
{ code : "67034", intitule : "BERSTETT", cp : "67370" },
{ code : "67034", intitule : "BERSTETT", cp : "67370" },
{ code : "67034", intitule : "BERSTETT", cp : "67370" },
{ code : "67035", intitule : "BERSTHEIM", cp : "67170" },
{ code : "67036", intitule : "BETTWILLER", cp : "67320" },
{ code : "67037", intitule : "BIBLISHEIM", cp : "67360" },
{ code : "67038", intitule : "BIETLENHEIM", cp : "67720" },
{ code : "67039", intitule : "BILWISHEIM", cp : "67170" },
{ code : "67040", intitule : "BINDERNHEIM", cp : "67600" },
{ code : "67043", intitule : "BISCHHEIM", cp : "67800" },
{ code : "67044", intitule : "BISCHHOLTZ", cp : "67340" },
{ code : "67045", intitule : "BISCHOFFSHEIM", cp : "67870" },
{ code : "67046", intitule : "BISCHWILLER", cp : "67240" },
{ code : "67047", intitule : "BISSERT", cp : "67260" },
{ code : "67048", intitule : "BITSCHHOFFEN", cp : "67350" },
{ code : "67049", intitule : "BLAESHEIM", cp : "67113" },
{ code : "67050", intitule : "BLANCHERUPT", cp : "67130" },
{ code : "67051", intitule : "BLIENSCHWILLER", cp : "67650" },
{ code : "67052", intitule : "BOERSCH", cp : "67530" },
{ code : "67052", intitule : "BOERSCH", cp : "67530" },
{ code : "67053", intitule : "BOESENBIESEN", cp : "67390" },
{ code : "67054", intitule : "BOLSENHEIM", cp : "67150" },
{ code : "67055", intitule : "BOOFZHEIM", cp : "67860" },
{ code : "67056", intitule : "BOOTZHEIM", cp : "67390" },
{ code : "67057", intitule : "BOSSELSHAUSEN", cp : "67330" },
{ code : "67058", intitule : "BOSSENDORF", cp : "67270" },
{ code : "67059", intitule : "BOURG BRUCHE", cp : "67420" },
{ code : "67059", intitule : "BOURG BRUCHE", cp : "67420" },
{ code : "67060", intitule : "BOURGHEIM", cp : "67140" },
{ code : "67061", intitule : "BOUXWILLER", cp : "67330" },
{ code : "67061", intitule : "BOUXWILLER", cp : "67330" },
{ code : "67061", intitule : "BOUXWILLER", cp : "67330" },
{ code : "67061", intitule : "BOUXWILLER", cp : "67330" },
{ code : "67062", intitule : "BREITENAU", cp : "67220" },
{ code : "67063", intitule : "BREITENBACH", cp : "67220" },
{ code : "67065", intitule : "BREUSCHWICKERSHEIM", cp : "67112" },
{ code : "67066", intitule : "LA BROQUE", cp : "67130" },
{ code : "67066", intitule : "LA BROQUE", cp : "67130" },
{ code : "67066", intitule : "LA BROQUE", cp : "67130" },
{ code : "67066", intitule : "LA BROQUE", cp : "67570" },
{ code : "67066", intitule : "LA BROQUE", cp : "67570" },
{ code : "67067", intitule : "BRUMATH", cp : "67170" },
{ code : "67068", intitule : "BUSWILLER", cp : "67350" },
{ code : "67069", intitule : "BUHL", cp : "67470" },
{ code : "67070", intitule : "BURBACH", cp : "67260" },
{ code : "67071", intitule : "BUST", cp : "67320" },
{ code : "67072", intitule : "BUTTEN", cp : "67430" },
{ code : "67073", intitule : "CHATENOIS", cp : "67730" },
{ code : "67074", intitule : "CLEEBOURG", cp : "67160" },
{ code : "67074", intitule : "CLEEBOURG", cp : "67160" },
{ code : "67075", intitule : "CLIMBACH", cp : "67510" },
{ code : "67076", intitule : "COLROY LA ROCHE", cp : "67420" },
{ code : "67077", intitule : "COSSWILLER", cp : "67310" },
{ code : "67078", intitule : "CRASTATT", cp : "67310" },
{ code : "67079", intitule : "CROETTWILLER", cp : "67470" },
{ code : "67080", intitule : "DACHSTEIN", cp : "67120" },
{ code : "67081", intitule : "DAHLENHEIM", cp : "67310" },
{ code : "67082", intitule : "DALHUNDEN", cp : "67770" },
{ code : "67083", intitule : "DAMBACH", cp : "67110" },
{ code : "67083", intitule : "DAMBACH", cp : "67110" },
{ code : "67084", intitule : "DAMBACH LA VILLE", cp : "67650" },
{ code : "67085", intitule : "DANGOLSHEIM", cp : "67310" },
{ code : "67086", intitule : "DAUBENSAND", cp : "67150" },
{ code : "67087", intitule : "DAUENDORF", cp : "67350" },
{ code : "67087", intitule : "DAUENDORF", cp : "67350" },
{ code : "67088", intitule : "DEHLINGEN", cp : "67430" },
{ code : "67089", intitule : "DETTWILLER", cp : "67490" },
{ code : "67090", intitule : "DIEBOLSHEIM", cp : "67230" },
{ code : "67091", intitule : "DIEDENDORF", cp : "67260" },
{ code : "67092", intitule : "DIEFFENBACH AU VAL", cp : "67220" },
{ code : "67093", intitule : "DIEFFENBACH LES WOERTH", cp : "67360" },
{ code : "67094", intitule : "DIEFFENTHAL", cp : "67650" },
{ code : "67095", intitule : "DIEMERINGEN", cp : "67430" },
{ code : "67096", intitule : "DIMBSTHAL", cp : "67440" },
{ code : "67097", intitule : "DINGSHEIM", cp : "67370" },
{ code : "67098", intitule : "DINSHEIM SUR BRUCHE", cp : "67190" },
{ code : "67099", intitule : "DOMFESSEL", cp : "67430" },
{ code : "67100", intitule : "DONNENHEIM", cp : "67170" },
{ code : "67101", intitule : "DORLISHEIM", cp : "67120" },
{ code : "67102", intitule : "DOSSENHEIM KOCHERSBERG", cp : "67117" },
{ code : "67103", intitule : "DOSSENHEIM SUR ZINSEL", cp : "67330" },
{ code : "67104", intitule : "DRACHENBRONN BIRLENBACH", cp : "67160" },
{ code : "67104", intitule : "DRACHENBRONN BIRLENBACH", cp : "67160" },
{ code : "67105", intitule : "DRULINGEN", cp : "67320" },
{ code : "67106", intitule : "DRUSENHEIM", cp : "67410" },
{ code : "67107", intitule : "DUNTZENHEIM", cp : "67270" },
{ code : "67108", intitule : "DUPPIGHEIM", cp : "67120" },
{ code : "67109", intitule : "DURNINGEN", cp : "67270" },
{ code : "67110", intitule : "DURRENBACH", cp : "67360" },
{ code : "67111", intitule : "DURSTEL", cp : "67320" },
{ code : "67112", intitule : "DUTTLENHEIM", cp : "67120" },
{ code : "67113", intitule : "EBERBACH SELTZ", cp : "67470" },
{ code : "67115", intitule : "EBERSHEIM", cp : "67600" },
{ code : "67116", intitule : "EBERSMUNSTER", cp : "67600" },
{ code : "67117", intitule : "ECKARTSWILLER", cp : "67700" },
{ code : "67118", intitule : "ECKBOLSHEIM", cp : "67201" },
{ code : "67119", intitule : "ECKWERSHEIM", cp : "67550" },
{ code : "67120", intitule : "EICHHOFFEN", cp : "67140" },
{ code : "67121", intitule : "ELSENHEIM", cp : "67390" },
{ code : "67122", intitule : "WANGENBOURG ENGENTHAL", cp : "67710" },
{ code : "67122", intitule : "WANGENBOURG ENGENTHAL", cp : "67710" },
{ code : "67122", intitule : "WANGENBOURG ENGENTHAL", cp : "67710" },
{ code : "67122", intitule : "WANGENBOURG ENGENTHAL", cp : "67710" },
{ code : "67122", intitule : "WANGENBOURG ENGENTHAL", cp : "67710" },
{ code : "67123", intitule : "ENGWILLER", cp : "67350" },
{ code : "67124", intitule : "ENTZHEIM", cp : "67960" },
{ code : "67125", intitule : "EPFIG", cp : "67680" },
{ code : "67126", intitule : "ERCKARTSWILLER", cp : "67290" },
{ code : "67127", intitule : "ERGERSHEIM", cp : "67120" },
{ code : "67128", intitule : "ERNOLSHEIM BRUCHE", cp : "67120" },
{ code : "67129", intitule : "ERNOLSHEIM LES SAVERNE", cp : "67330" },
{ code : "67130", intitule : "ERSTEIN", cp : "67150" },
{ code : "67130", intitule : "ERSTEIN", cp : "67150" },
{ code : "67131", intitule : "ESCHAU", cp : "67114" },
{ code : "67132", intitule : "ESCHBACH", cp : "67360" },
{ code : "67133", intitule : "ESCHBOURG", cp : "67320" },
{ code : "67133", intitule : "ESCHBOURG", cp : "67320" },
{ code : "67134", intitule : "ESCHWILLER", cp : "67320" },
{ code : "67135", intitule : "ETTENDORF", cp : "67350" },
{ code : "67136", intitule : "EYWILLER", cp : "67320" },
{ code : "67137", intitule : "FEGERSHEIM", cp : "67640" },
{ code : "67137", intitule : "FEGERSHEIM", cp : "67640" },
{ code : "67138", intitule : "FESSENHEIM LE BAS", cp : "67117" },
{ code : "67139", intitule : "FLEXBOURG", cp : "67310" },
{ code : "67140", intitule : "FORSTFELD", cp : "67480" },
{ code : "67141", intitule : "FORSTHEIM", cp : "67580" },
{ code : "67142", intitule : "FORT LOUIS", cp : "67480" },
{ code : "67143", intitule : "FOUCHY", cp : "67220" },
{ code : "67144", intitule : "FOUDAY", cp : "67130" },
{ code : "67145", intitule : "FRIEDOLSHEIM", cp : "67490" },
{ code : "67146", intitule : "FRIESENHEIM", cp : "67860" },
{ code : "67147", intitule : "FROESCHWILLER", cp : "67360" },
{ code : "67148", intitule : "FROHMUHL", cp : "67290" },
{ code : "67149", intitule : "FURCHHAUSEN", cp : "67700" },
{ code : "67150", intitule : "FURDENHEIM", cp : "67117" },
{ code : "67151", intitule : "GAMBSHEIM", cp : "67760" },
{ code : "67152", intitule : "GEISPOLSHEIM", cp : "67118" },
{ code : "67153", intitule : "GEISWILLER", cp : "67270" },
{ code : "67154", intitule : "GERSTHEIM", cp : "67150" },
{ code : "67155", intitule : "GERTWILLER", cp : "67140" },
{ code : "67156", intitule : "GEUDERTHEIM", cp : "67170" },
{ code : "67159", intitule : "GOERLINGEN", cp : "67320" },
{ code : "67160", intitule : "GOERSDORF", cp : "67360" },
{ code : "67160", intitule : "GOERSDORF", cp : "67360" },
{ code : "67161", intitule : "GOTTENHOUSE", cp : "67700" },
{ code : "67162", intitule : "GOTTESHEIM", cp : "67490" },
{ code : "67163", intitule : "GOUGENHEIM", cp : "67270" },
{ code : "67164", intitule : "GOXWILLER", cp : "67210" },
{ code : "67165", intitule : "GRANDFONTAINE", cp : "67130" },
{ code : "67166", intitule : "GRASSENDORF", cp : "67350" },
{ code : "67167", intitule : "GRENDELBRUCH", cp : "67190" },
{ code : "67168", intitule : "GRESSWILLER", cp : "67190" },
{ code : "67169", intitule : "GRIES", cp : "67240" },
{ code : "67172", intitule : "GRIESHEIM PRES MOLSHEIM", cp : "67870" },
{ code : "67173", intitule : "GRIESHEIM SUR SOUFFEL", cp : "67370" },
{ code : "67174", intitule : "GUMBRECHTSHOFFEN", cp : "67110" },
{ code : "67176", intitule : "GUNDERSHOFFEN", cp : "67110" },
{ code : "67176", intitule : "GUNDERSHOFFEN", cp : "67110" },
{ code : "67176", intitule : "GUNDERSHOFFEN", cp : "67110" },
{ code : "67176", intitule : "GUNDERSHOFFEN", cp : "67110" },
{ code : "67177", intitule : "GUNSTETT", cp : "67360" },
{ code : "67178", intitule : "GUNGWILLER", cp : "67320" },
{ code : "67179", intitule : "HAEGEN", cp : "67700" },
{ code : "67180", intitule : "HAGUENAU", cp : "67500" },
{ code : "67180", intitule : "HAGUENAU", cp : "67500" },
{ code : "67180", intitule : "HAGUENAU", cp : "67500" },
{ code : "67181", intitule : "HANDSCHUHEIM", cp : "67117" },
{ code : "67182", intitule : "HANGENBIETEN", cp : "67980" },
{ code : "67183", intitule : "HARSKIRCHEN", cp : "67260" },
{ code : "67184", intitule : "HATTEN", cp : "67690" },
{ code : "67185", intitule : "HATTMATT", cp : "67330" },
{ code : "67186", intitule : "HEGENEY", cp : "67360" },
{ code : "67187", intitule : "HEIDOLSHEIM", cp : "67390" },
{ code : "67188", intitule : "HEILIGENBERG", cp : "67190" },
{ code : "67189", intitule : "HEILIGENSTEIN", cp : "67140" },
{ code : "67190", intitule : "HENGWILLER", cp : "67440" },
{ code : "67191", intitule : "HERBITZHEIM", cp : "67260" },
{ code : "67192", intitule : "HERBSHEIM", cp : "67230" },
{ code : "67194", intitule : "HERRLISHEIM", cp : "67850" },
{ code : "67195", intitule : "HESSENHEIM", cp : "67390" },
{ code : "67196", intitule : "HILSENHEIM", cp : "67600" },
{ code : "67197", intitule : "HINDISHEIM", cp : "67150" },
{ code : "67198", intitule : "HINSBOURG", cp : "67290" },
{ code : "67199", intitule : "HINSINGEN", cp : "67260" },
{ code : "67200", intitule : "HIPSHEIM", cp : "67150" },
{ code : "67201", intitule : "HIRSCHLAND", cp : "67320" },
{ code : "67202", intitule : "HOCHFELDEN", cp : "67270" },
{ code : "67203", intitule : "HOCHSTETT", cp : "67170" },
{ code : "67204", intitule : "HOENHEIM", cp : "67800" },
{ code : "67205", intitule : "HOERDT", cp : "67720" },
{ code : "67206", intitule : "HOFFEN", cp : "67250" },
{ code : "67206", intitule : "HOFFEN", cp : "67250" },
{ code : "67206", intitule : "HOFFEN", cp : "67250" },
{ code : "67208", intitule : "HOHENGOEFT", cp : "67310" },
{ code : "67209", intitule : "HOHFRANKENHEIM", cp : "67270" },
{ code : "67210", intitule : "LE HOHWALD", cp : "67140" },
{ code : "67212", intitule : "HOLTZHEIM", cp : "67810" },
{ code : "67213", intitule : "HUNSPACH", cp : "67250" },
{ code : "67214", intitule : "HURTIGHEIM", cp : "67117" },
{ code : "67215", intitule : "HUTTENDORF", cp : "67270" },
{ code : "67216", intitule : "HUTTENHEIM", cp : "67230" },
{ code : "67217", intitule : "ICHTRATZHEIM", cp : "67640" },
{ code : "67218", intitule : "ILLKIRCH GRAFFENSTADEN", cp : "67400" },
{ code : "67220", intitule : "INGENHEIM", cp : "67270" },
{ code : "67221", intitule : "INGOLSHEIM", cp : "67250" },
{ code : "67222", intitule : "INGWILLER", cp : "67340" },
{ code : "67223", intitule : "INNENHEIM", cp : "67880" },
{ code : "67225", intitule : "ISSENHAUSEN", cp : "67330" },
{ code : "67226", intitule : "ITTENHEIM", cp : "67117" },
{ code : "67227", intitule : "ITTERSWILLER", cp : "67140" },
{ code : "67228", intitule : "NEUGARTHEIM ITTLENHEIM", cp : "67370" },
{ code : "67228", intitule : "NEUGARTHEIM ITTLENHEIM", cp : "67370" },
{ code : "67228", intitule : "NEUGARTHEIM ITTLENHEIM", cp : "67370" },
{ code : "67229", intitule : "JETTERSWILLER", cp : "67440" },
{ code : "67230", intitule : "KALTENHOUSE", cp : "67240" },
{ code : "67231", intitule : "KAUFFENHEIM", cp : "67480" },
{ code : "67232", intitule : "KEFFENACH", cp : "67250" },
{ code : "67233", intitule : "KERTZFELD", cp : "67230" },
{ code : "67234", intitule : "KESKASTEL", cp : "67260" },
{ code : "67235", intitule : "KESSELDORF", cp : "67930" },
{ code : "67236", intitule : "KIENHEIM", cp : "67270" },
{ code : "67237", intitule : "KILSTETT", cp : "67840" },
{ code : "67238", intitule : "KINDWILLER", cp : "67350" },
{ code : "67239", intitule : "KINTZHEIM", cp : "67600" },
{ code : "67240", intitule : "KIRCHHEIM", cp : "67520" },
{ code : "67241", intitule : "KIRRBERG", cp : "67320" },
{ code : "67242", intitule : "KIRRWILLER", cp : "67330" },
{ code : "67244", intitule : "KLEINGOEFT", cp : "67440" },
{ code : "67245", intitule : "KNOERSHEIM", cp : "67310" },
{ code : "67246", intitule : "KOGENHEIM", cp : "67230" },
{ code : "67247", intitule : "KOLBSHEIM", cp : "67120" },
{ code : "67248", intitule : "KRAUTERGERSHEIM", cp : "67880" },
{ code : "67249", intitule : "KRAUTWILLER", cp : "67170" },
{ code : "67250", intitule : "KRIEGSHEIM", cp : "67170" },
{ code : "67252", intitule : "KURTZENHOUSE", cp : "67240" },
{ code : "67253", intitule : "KUTTOLSHEIM", cp : "67520" },
{ code : "67254", intitule : "KUTZENHAUSEN", cp : "67250" },
{ code : "67254", intitule : "KUTZENHAUSEN", cp : "67250" },
{ code : "67255", intitule : "LALAYE", cp : "67220" },
{ code : "67255", intitule : "LALAYE", cp : "67220" },
{ code : "67256", intitule : "LAMPERTHEIM", cp : "67450" },
{ code : "67257", intitule : "LAMPERTSLOCH", cp : "67250" },
{ code : "67258", intitule : "LANDERSHEIM", cp : "67700" },
{ code : "67259", intitule : "LANGENSOULTZBACH", cp : "67360" },
{ code : "67260", intitule : "LAUBACH", cp : "67580" },
{ code : "67261", intitule : "LAUTERBOURG", cp : "67630" },
{ code : "67263", intitule : "LEMBACH", cp : "67510" },
{ code : "67263", intitule : "LEMBACH", cp : "67510" },
{ code : "67264", intitule : "LEUTENHEIM", cp : "67480" },
{ code : "67265", intitule : "LICHTENBERG", cp : "67340" },
{ code : "67266", intitule : "LIMERSHEIM", cp : "67150" },
{ code : "67267", intitule : "LINGOLSHEIM", cp : "67380" },
{ code : "67268", intitule : "LIPSHEIM", cp : "67640" },
{ code : "67269", intitule : "LITTENHEIM", cp : "67490" },
{ code : "67270", intitule : "LIXHAUSEN", cp : "67270" },
{ code : "67271", intitule : "LOBSANN", cp : "67250" },
{ code : "67272", intitule : "LOCHWILLER", cp : "67440" },
{ code : "67273", intitule : "LOHR", cp : "67290" },
{ code : "67274", intitule : "LORENTZEN", cp : "67430" },
{ code : "67275", intitule : "LUPSTEIN", cp : "67490" },
{ code : "67276", intitule : "LUTZELHOUSE", cp : "67130" },
{ code : "67277", intitule : "MACKENHEIM", cp : "67390" },
{ code : "67278", intitule : "MACKWILLER", cp : "67430" },
{ code : "67279", intitule : "MAENNOLSHEIM", cp : "67700" },
{ code : "67280", intitule : "MAISONSGOUTTE", cp : "67220" },
{ code : "67280", intitule : "MAISONSGOUTTE", cp : "67220" },
{ code : "67281", intitule : "MARCKOLSHEIM", cp : "67390" },
{ code : "67282", intitule : "MARLENHEIM", cp : "67520" },
{ code : "67283", intitule : "MARMOUTIER", cp : "67440" },
{ code : "67285", intitule : "MATZENHEIM", cp : "67150" },
{ code : "67285", intitule : "MATZENHEIM", cp : "67150" },
{ code : "67286", intitule : "MEISTRATZHEIM", cp : "67210" },
{ code : "67287", intitule : "MELSHEIM", cp : "67270" },
{ code : "67288", intitule : "MEMMELSHOFFEN", cp : "67250" },
{ code : "67289", intitule : "MENCHHOFFEN", cp : "67340" },
{ code : "67290", intitule : "MERKWILLER PECHELBRONN", cp : "67250" },
{ code : "67290", intitule : "MERKWILLER PECHELBRONN", cp : "67250" },
{ code : "67291", intitule : "MERTZWILLER", cp : "67580" },
{ code : "67292", intitule : "MIETESHEIM", cp : "67580" },
{ code : "67293", intitule : "MINVERSHEIM", cp : "67270" },
{ code : "67295", intitule : "MITTELBERGHEIM", cp : "67140" },
{ code : "67296", intitule : "MITTELHAUSBERGEN", cp : "67206" },
{ code : "67298", intitule : "MITTELSCHAEFFOLSHEIM", cp : "67170" },
{ code : "67299", intitule : "MOLLKIRCH", cp : "67190" },
{ code : "67300", intitule : "MOLSHEIM", cp : "67120" },
{ code : "67301", intitule : "MOMMENHEIM", cp : "67670" },
{ code : "67302", intitule : "MONSWILLER", cp : "67700" },
{ code : "67303", intitule : "MORSBRONN LES BAINS", cp : "67360" },
{ code : "67304", intitule : "MORSCHWILLER", cp : "67350" },
{ code : "67305", intitule : "MOTHERN", cp : "67470" },
{ code : "67306", intitule : "MUHLBACH SUR BRUCHE", cp : "67130" },
{ code : "67307", intitule : "MULHAUSEN", cp : "67350" },
{ code : "67308", intitule : "MUNCHHAUSEN", cp : "67470" },
{ code : "67309", intitule : "MUNDOLSHEIM", cp : "67450" },
{ code : "67310", intitule : "MUSSIG", cp : "67600" },
{ code : "67311", intitule : "MUTTERSHOLTZ", cp : "67600" },
{ code : "67311", intitule : "MUTTERSHOLTZ", cp : "67600" },
{ code : "67312", intitule : "MUTZENHOUSE", cp : "67270" },
{ code : "67313", intitule : "MUTZIG", cp : "67190" },
{ code : "67314", intitule : "NATZWILLER", cp : "67130" },
{ code : "67315", intitule : "NEEWILLER PRES LAUTERBOURG", cp : "67630" },
{ code : "67317", intitule : "NEUBOIS", cp : "67220" },
{ code : "67319", intitule : "NEUHAEUSEL", cp : "67480" },
{ code : "67320", intitule : "NEUVE EGLISE", cp : "67220" },
{ code : "67321", intitule : "NEUVILLER LA ROCHE", cp : "67130" },
{ code : "67321", intitule : "NEUVILLER LA ROCHE", cp : "67130" },
{ code : "67322", intitule : "NEUWILLER LES SAVERNE", cp : "67330" },
{ code : "67324", intitule : "NIEDERBRONN LES BAINS", cp : "67110" },
{ code : "67325", intitule : "NIEDERHASLACH", cp : "67280" },
{ code : "67326", intitule : "NIEDERHAUSBERGEN", cp : "67207" },
{ code : "67327", intitule : "NIEDERLAUTERBACH", cp : "67630" },
{ code : "67328", intitule : "NIEDERMODERN", cp : "67350" },
{ code : "67329", intitule : "NIEDERNAI", cp : "67210" },
{ code : "67330", intitule : "NIEDERROEDERN", cp : "67470" },
{ code : "67331", intitule : "NIEDERSCHAEFFOLSHEIM", cp : "67500" },
{ code : "67333", intitule : "NIEDERSOULTZBACH", cp : "67330" },
{ code : "67334", intitule : "NIEDERSTEINBACH", cp : "67510" },
{ code : "67335", intitule : "NORDHEIM", cp : "67520" },
{ code : "67336", intitule : "NORDHOUSE", cp : "67150" },
{ code : "67337", intitule : "NOTHALTEN", cp : "67680" },
{ code : "67338", intitule : "OBENHEIM", cp : "67230" },
{ code : "67339", intitule : "BETSCHDORF", cp : "67660" },
{ code : "67339", intitule : "BETSCHDORF", cp : "67660" },
{ code : "67339", intitule : "BETSCHDORF", cp : "67660" },
{ code : "67339", intitule : "BETSCHDORF", cp : "67660" },
{ code : "67340", intitule : "OBERBRONN", cp : "67110" },
{ code : "67341", intitule : "OBERDORF SPACHBACH", cp : "67360" },
{ code : "67342", intitule : "OBERHASLACH", cp : "67280" },
{ code : "67343", intitule : "OBERHAUSBERGEN", cp : "67205" },
{ code : "67344", intitule : "OBERHOFFEN LES WISSEMBOURG", cp : "67160" },
{ code : "67345", intitule : "OBERHOFFEN SUR MODER", cp : "67240" },
{ code : "67345", intitule : "OBERHOFFEN SUR MODER", cp : "67240" },
{ code : "67346", intitule : "OBERLAUTERBACH", cp : "67160" },
{ code : "67347", intitule : "OBERMODERN ZUTZENDORF", cp : "67330" },
{ code : "67347", intitule : "OBERMODERN ZUTZENDORF", cp : "67330" },
{ code : "67347", intitule : "OBERMODERN ZUTZENDORF", cp : "67330" },
{ code : "67348", intitule : "OBERNAI", cp : "67210" },
{ code : "67349", intitule : "OBERROEDERN", cp : "67250" },
{ code : "67350", intitule : "OBERSCHAEFFOLSHEIM", cp : "67203" },
{ code : "67351", intitule : "SEEBACH", cp : "67160" },
{ code : "67351", intitule : "SEEBACH", cp : "67160" },
{ code : "67351", intitule : "SEEBACH", cp : "67160" },
{ code : "67352", intitule : "OBERSOULTZBACH", cp : "67330" },
{ code : "67353", intitule : "OBERSTEINBACH", cp : "67510" },
{ code : "67354", intitule : "ODRATZHEIM", cp : "67520" },
{ code : "67355", intitule : "OERMINGEN", cp : "67970" },
{ code : "67356", intitule : "OFFENDORF", cp : "67850" },
{ code : "67358", intitule : "OFFWILLER", cp : "67340" },
{ code : "67359", intitule : "OHLUNGEN", cp : "67170" },
{ code : "67359", intitule : "OHLUNGEN", cp : "67590" },
{ code : "67360", intitule : "OHNENHEIM", cp : "67390" },
{ code : "67361", intitule : "OLWISHEIM", cp : "67170" },
{ code : "67362", intitule : "ORSCHWILLER", cp : "67600" },
{ code : "67363", intitule : "OSTHOFFEN", cp : "67990" },
{ code : "67364", intitule : "OSTHOUSE", cp : "67150" },
{ code : "67365", intitule : "OSTWALD", cp : "67540" },
{ code : "67366", intitule : "OTTERSTHAL", cp : "67700" },
{ code : "67367", intitule : "OTTERSWILLER", cp : "67700" },
{ code : "67368", intitule : "OTTROTT", cp : "67530" },
{ code : "67369", intitule : "OTTWILLER", cp : "67320" },
{ code : "67370", intitule : "PETERSBACH", cp : "67290" },
{ code : "67371", intitule : "LA PETITE PIERRE", cp : "67290" },
{ code : "67372", intitule : "VAL DE MODER", cp : "67350" },
{ code : "67372", intitule : "VAL DE MODER", cp : "67350" },
{ code : "67372", intitule : "VAL DE MODER", cp : "67350" },
{ code : "67373", intitule : "PFALZWEYER", cp : "67320" },
{ code : "67375", intitule : "PFULGRIESHEIM", cp : "67370" },
{ code : "67377", intitule : "PLAINE", cp : "67420" },
{ code : "67377", intitule : "PLAINE", cp : "67420" },
{ code : "67377", intitule : "PLAINE", cp : "67420" },
{ code : "67378", intitule : "PLOBSHEIM", cp : "67115" },
{ code : "67379", intitule : "PREUSCHDORF", cp : "67250" },
{ code : "67380", intitule : "PRINTZHEIM", cp : "67490" },
{ code : "67381", intitule : "PUBERG", cp : "67290" },
{ code : "67382", intitule : "QUATZENHEIM", cp : "67117" },
{ code : "67383", intitule : "RANGEN", cp : "67310" },
{ code : "67384", intitule : "RANRUPT", cp : "67420" },
{ code : "67385", intitule : "RATZWILLER", cp : "67430" },
{ code : "67386", intitule : "RAUWILLER", cp : "67320" },
{ code : "67387", intitule : "REICHSFELD", cp : "67140" },
{ code : "67388", intitule : "REICHSHOFFEN", cp : "67110" },
{ code : "67388", intitule : "REICHSHOFFEN", cp : "67110" },
{ code : "67389", intitule : "REICHSTETT", cp : "67116" },
{ code : "67391", intitule : "REINHARDSMUNSTER", cp : "67440" },
{ code : "67392", intitule : "REIPERTSWILLER", cp : "67340" },
{ code : "67394", intitule : "RETSCHWILLER", cp : "67250" },
{ code : "67395", intitule : "REUTENBOURG", cp : "67440" },
{ code : "67396", intitule : "REXINGEN", cp : "67320" },
{ code : "67397", intitule : "RHINAU", cp : "67860" },
{ code : "67398", intitule : "RICHTOLSHEIM", cp : "67390" },
{ code : "67400", intitule : "RIEDSELTZ", cp : "67160" },
{ code : "67401", intitule : "RIMSDORF", cp : "67260" },
{ code : "67402", intitule : "RINGELDORF", cp : "67350" },
{ code : "67403", intitule : "RINGENDORF", cp : "67350" },
{ code : "67404", intitule : "RITTERSHOFFEN", cp : "67690" },
{ code : "67405", intitule : "ROESCHWOOG", cp : "67480" },
{ code : "67406", intitule : "ROHR", cp : "67270" },
{ code : "67407", intitule : "ROHRWILLER", cp : "67410" },
{ code : "67408", intitule : "ROMANSWILLER", cp : "67310" },
{ code : "67409", intitule : "ROPPENHEIM", cp : "67480" },
{ code : "67410", intitule : "ROSENWILLER", cp : "67560" },
{ code : "67411", intitule : "ROSHEIM", cp : "67560" },
{ code : "67412", intitule : "ROSSFELD", cp : "67230" },
{ code : "67413", intitule : "ROSTEIG", cp : "67290" },
{ code : "67414", intitule : "ROTHAU", cp : "67570" },
{ code : "67415", intitule : "ROTHBACH", cp : "67340" },
{ code : "67416", intitule : "ROTT", cp : "67160" },
{ code : "67417", intitule : "ROTTELSHEIM", cp : "67170" },
{ code : "67418", intitule : "ROUNTZENHEIM", cp : "67480" },
{ code : "67420", intitule : "RUSS", cp : "67130" },
{ code : "67420", intitule : "RUSS", cp : "67130" },
{ code : "67420", intitule : "RUSS", cp : "67130" },
{ code : "67421", intitule : "SAALES", cp : "67420" },
{ code : "67422", intitule : "SAASENHEIM", cp : "67390" },
{ code : "67423", intitule : "SAESSOLSHEIM", cp : "67270" },
{ code : "67424", intitule : "ST BLAISE LA ROCHE", cp : "67420" },
{ code : "67425", intitule : "ST JEAN SAVERNE", cp : "67700" },
{ code : "67426", intitule : "ST MARTIN", cp : "67220" },
{ code : "67427", intitule : "ST MAURICE", cp : "67220" },
{ code : "67428", intitule : "ST NABOR", cp : "67530" },
{ code : "67429", intitule : "ST PIERRE", cp : "67140" },
{ code : "67430", intitule : "ST PIERRE BOIS", cp : "67220" },
{ code : "67430", intitule : "ST PIERRE BOIS", cp : "67220" },
{ code : "67432", intitule : "SALMBACH", cp : "67160" },
{ code : "67433", intitule : "SAND", cp : "67230" },
{ code : "67434", intitule : "SARRE UNION", cp : "67260" },
{ code : "67435", intitule : "SARREWERDEN", cp : "67260" },
{ code : "67435", intitule : "SARREWERDEN", cp : "67260" },
{ code : "67435", intitule : "SARREWERDEN", cp : "67260" },
{ code : "67436", intitule : "SAULXURES", cp : "67420" },
{ code : "67437", intitule : "SAVERNE", cp : "67700" },
{ code : "67437", intitule : "SAVERNE", cp : "67700" },
{ code : "67437", intitule : "SAVERNE", cp : "67700" },
{ code : "67438", intitule : "SCHAEFFERSHEIM", cp : "67150" },
{ code : "67439", intitule : "SCHAFFHOUSE SUR ZORN", cp : "67270" },
{ code : "67440", intitule : "SCHAFFHOUSE PRES SELTZ", cp : "67470" },
{ code : "67441", intitule : "SCHALKENDORF", cp : "67350" },
{ code : "67442", intitule : "SCHARRACHBERGHEIM IRMSTETT", cp : "67310" },
{ code : "67442", intitule : "SCHARRACHBERGHEIM IRMSTETT", cp : "67310" },
{ code : "67443", intitule : "SCHEIBENHARD", cp : "67630" },
{ code : "67444", intitule : "SCHERLENHEIM", cp : "67270" },
{ code : "67445", intitule : "SCHERWILLER", cp : "67750" },
{ code : "67445", intitule : "SCHERWILLER", cp : "67750" },
{ code : "67446", intitule : "SCHILLERSDORF", cp : "67340" },
{ code : "67447", intitule : "SCHILTIGHEIM", cp : "67300" },
{ code : "67448", intitule : "SCHIRMECK", cp : "67130" },
{ code : "67448", intitule : "SCHIRMECK", cp : "67130" },
{ code : "67449", intitule : "SCHIRRHEIN", cp : "67240" },
{ code : "67450", intitule : "SCHIRRHOFFEN", cp : "67240" },
{ code : "67451", intitule : "SCHLEITHAL", cp : "67160" },
{ code : "67452", intitule : "SCHNERSHEIM", cp : "67370" },
{ code : "67452", intitule : "SCHNERSHEIM", cp : "67370" },
{ code : "67452", intitule : "SCHNERSHEIM", cp : "67370" },
{ code : "67453", intitule : "SCHOENAU", cp : "67390" },
{ code : "67454", intitule : "SCHOENBOURG", cp : "67320" },
{ code : "67455", intitule : "SCHOENENBOURG", cp : "67250" },
{ code : "67456", intitule : "SCHOPPERTEN", cp : "67260" },
{ code : "67458", intitule : "SCHWEIGHOUSE SUR MODER", cp : "67590" },
{ code : "67459", intitule : "SCHWENHEIM", cp : "67440" },
{ code : "67460", intitule : "SCHWINDRATZHEIM", cp : "67270" },
{ code : "67461", intitule : "SCHWOBSHEIM", cp : "67390" },
{ code : "67462", intitule : "SELESTAT", cp : "67600" },
{ code : "67463", intitule : "SELTZ", cp : "67470" },
{ code : "67464", intitule : "SERMERSHEIM", cp : "67230" },
{ code : "67465", intitule : "SESSENHEIM", cp : "67770" },
{ code : "67466", intitule : "SIEGEN", cp : "67160" },
{ code : "67467", intitule : "SIEWILLER", cp : "67320" },
{ code : "67468", intitule : "SILTZHEIM", cp : "67260" },
{ code : "67470", intitule : "SOLBACH", cp : "67130" },
{ code : "67471", intitule : "SOUFFELWEYERSHEIM", cp : "67460" },
{ code : "67472", intitule : "SOUFFLENHEIM", cp : "67620" },
{ code : "67473", intitule : "SOULTZ LES BAINS", cp : "67120" },
{ code : "67474", intitule : "SOULTZ SOUS FORETS", cp : "67250" },
{ code : "67474", intitule : "SOULTZ SOUS FORETS", cp : "67250" },
{ code : "67475", intitule : "SPARSBACH", cp : "67340" },
{ code : "67476", intitule : "STATTMATTEN", cp : "67770" },
{ code : "67477", intitule : "STEIGE", cp : "67220" },
{ code : "67478", intitule : "STEINBOURG", cp : "67790" },
{ code : "67479", intitule : "STEINSELTZ", cp : "67160" },
{ code : "67480", intitule : "STILL", cp : "67190" },
{ code : "67481", intitule : "STOTZHEIM", cp : "67140" },
{ code : "67482", intitule : "STRASBOURG", cp : "67100" },
{ code : "67482", intitule : "STRASBOURG", cp : "67200" },
{ code : "67482", intitule : "STRASBOURG", cp : "67000" },
{ code : "67483", intitule : "STRUTH", cp : "67290" },
{ code : "67484", intitule : "STUNDWILLER", cp : "67250" },
{ code : "67485", intitule : "STUTZHEIM OFFENHEIM", cp : "67370" },
{ code : "67485", intitule : "STUTZHEIM OFFENHEIM", cp : "67370" },
{ code : "67486", intitule : "SUNDHOUSE", cp : "67920" },
{ code : "67487", intitule : "SURBOURG", cp : "67250" },
{ code : "67488", intitule : "THAL DRULINGEN", cp : "67320" },
{ code : "67489", intitule : "THAL MARMOUTIER", cp : "67440" },
{ code : "67489", intitule : "THAL MARMOUTIER", cp : "67440" },
{ code : "67489", intitule : "THAL MARMOUTIER", cp : "67440" },
{ code : "67490", intitule : "THANVILLE", cp : "67220" },
{ code : "67491", intitule : "TIEFFENBACH", cp : "67290" },
{ code : "67492", intitule : "TRAENHEIM", cp : "67310" },
{ code : "67493", intitule : "TRIEMBACH AU VAL", cp : "67220" },
{ code : "67494", intitule : "TRIMBACH", cp : "67470" },
{ code : "67495", intitule : "TRUCHTERSHEIM", cp : "67370" },
{ code : "67495", intitule : "TRUCHTERSHEIM", cp : "67370" },
{ code : "67495", intitule : "TRUCHTERSHEIM", cp : "67370" },
{ code : "67497", intitule : "UHLWILLER", cp : "67350" },
{ code : "67497", intitule : "UHLWILLER", cp : "67350" },
{ code : "67498", intitule : "UHRWILLER", cp : "67350" },
{ code : "67499", intitule : "URBEIS", cp : "67220" },
{ code : "67500", intitule : "URMATT", cp : "67280" },
{ code : "67501", intitule : "UTTENHEIM", cp : "67150" },
{ code : "67502", intitule : "UTTENHOFFEN", cp : "67110" },
{ code : "67503", intitule : "UTTWILLER", cp : "67330" },
{ code : "67504", intitule : "VALFF", cp : "67210" },
{ code : "67505", intitule : "LA VANCELLE", cp : "67730" },
{ code : "67506", intitule : "VENDENHEIM", cp : "67550" },
{ code : "67507", intitule : "VILLE", cp : "67220" },
{ code : "67508", intitule : "VOELLERDINGEN", cp : "67430" },
{ code : "67509", intitule : "VOLKSBERG", cp : "67290" },
{ code : "67510", intitule : "WAHLENHEIM", cp : "67170" },
{ code : "67511", intitule : "WALBOURG", cp : "67360" },
{ code : "67513", intitule : "WALDERSBACH", cp : "67130" },
{ code : "67514", intitule : "WALDHAMBACH", cp : "67430" },
{ code : "67515", intitule : "WALDOLWISHEIM", cp : "67700" },
{ code : "67516", intitule : "WALTENHEIM SUR ZORN", cp : "67670" },
{ code : "67517", intitule : "WANGEN", cp : "67520" },
{ code : "67519", intitule : "LA WANTZENAU", cp : "67610" },
{ code : "67520", intitule : "WASSELONNE", cp : "67310" },
{ code : "67520", intitule : "WASSELONNE", cp : "67310" },
{ code : "67521", intitule : "WEINBOURG", cp : "67340" },
{ code : "67522", intitule : "WEISLINGEN", cp : "67290" },
{ code : "67523", intitule : "WEITBRUCH", cp : "67500" },
{ code : "67524", intitule : "WEITERSWILLER", cp : "67340" },
{ code : "67525", intitule : "WESTHOFFEN", cp : "67310" },
{ code : "67526", intitule : "WESTHOUSE", cp : "67230" },
{ code : "67527", intitule : "WESTHOUSE MARMOUTIER", cp : "67440" },
{ code : "67528", intitule : "WEYER", cp : "67320" },
{ code : "67529", intitule : "WEYERSHEIM", cp : "67720" },
{ code : "67530", intitule : "WICKERSHEIM WILSHAUSEN", cp : "67270" },
{ code : "67530", intitule : "WICKERSHEIM WILSHAUSEN", cp : "67270" },
{ code : "67531", intitule : "WILDERSBACH", cp : "67130" },
{ code : "67532", intitule : "WILLGOTTHEIM", cp : "67370" },
{ code : "67532", intitule : "WILLGOTTHEIM", cp : "67370" },
{ code : "67534", intitule : "WILWISHEIM", cp : "67270" },
{ code : "67535", intitule : "WIMMENAU", cp : "67290" },
{ code : "67536", intitule : "WINDSTEIN", cp : "67110" },
{ code : "67536", intitule : "WINDSTEIN", cp : "67110" },
{ code : "67537", intitule : "WINGEN", cp : "67510" },
{ code : "67537", intitule : "WINGEN", cp : "67510" },
{ code : "67538", intitule : "WINGEN SUR MODER", cp : "67290" },
{ code : "67539", intitule : "WINGERSHEIM LES QUATRE BANS", cp : "67170" },
{ code : "67539", intitule : "WINGERSHEIM LES QUATRE BANS", cp : "67170" },
{ code : "67539", intitule : "WINGERSHEIM LES QUATRE BANS", cp : "67270" },
{ code : "67539", intitule : "WINGERSHEIM LES QUATRE BANS", cp : "67170" },
{ code : "67540", intitule : "WINTERSHOUSE", cp : "67590" },
{ code : "67541", intitule : "WINTZENBACH", cp : "67470" },
{ code : "67542", intitule : "WINTZENHEIM KOCHERSBERG", cp : "67370" },
{ code : "67543", intitule : "WISCHES", cp : "67130" },
{ code : "67543", intitule : "WISCHES", cp : "67130" },
{ code : "67543", intitule : "WISCHES", cp : "67130" },
{ code : "67544", intitule : "WISSEMBOURG", cp : "67160" },
{ code : "67544", intitule : "WISSEMBOURG", cp : "67160" },
{ code : "67544", intitule : "WISSEMBOURG", cp : "67160" },
{ code : "67545", intitule : "WITTERNHEIM", cp : "67230" },
{ code : "67546", intitule : "WITTERSHEIM", cp : "67670" },
{ code : "67547", intitule : "WITTISHEIM", cp : "67820" },
{ code : "67548", intitule : "WIWERSHEIM", cp : "67370" },
{ code : "67550", intitule : "WOERTH", cp : "67360" },
{ code : "67551", intitule : "WOLFISHEIM", cp : "67202" },
{ code : "67552", intitule : "WOLFSKIRCHEN", cp : "67260" },
{ code : "67553", intitule : "WOLSCHHEIM", cp : "67700" },
{ code : "67554", intitule : "WOLXHEIM", cp : "67120" },
{ code : "67554", intitule : "WOLXHEIM", cp : "67120" },
{ code : "67555", intitule : "ZEHNACKER", cp : "67310" },
{ code : "67556", intitule : "ZEINHEIM", cp : "67310" },
{ code : "67557", intitule : "ZELLWILLER", cp : "67140" },
{ code : "67558", intitule : "ZINSWILLER", cp : "67110" },
{ code : "67559", intitule : "ZITTERSHEIM", cp : "67290" },
{ code : "67560", intitule : "ZOEBERSDORF", cp : "67270" },
{ code : "68001", intitule : "ALGOLSHEIM", cp : "68600" },
{ code : "68002", intitule : "ALTENACH", cp : "68210" },
{ code : "68004", intitule : "ALTKIRCH", cp : "68130" },
{ code : "68005", intitule : "AMMERSCHWIHR", cp : "68410" },
{ code : "68005", intitule : "AMMERSCHWIHR", cp : "68770" },
{ code : "68006", intitule : "AMMERTZWILLER", cp : "68210" },
{ code : "68007", intitule : "ANDOLSHEIM", cp : "68280" },
{ code : "68008", intitule : "APPENWIHR", cp : "68280" },
{ code : "68009", intitule : "ARTZENHEIM", cp : "68320" },
{ code : "68010", intitule : "ASPACH", cp : "68130" },
{ code : "68011", intitule : "ASPACH LE BAS", cp : "68700" },
{ code : "68012", intitule : "ASPACH MICHELBACH", cp : "68700" },
{ code : "68012", intitule : "ASPACH MICHELBACH", cp : "68700" },
{ code : "68013", intitule : "ATTENSCHWILLER", cp : "68220" },
{ code : "68014", intitule : "AUBURE", cp : "68150" },
{ code : "68015", intitule : "BALDERSHEIM", cp : "68390" },
{ code : "68016", intitule : "BALGAU", cp : "68740" },
{ code : "68017", intitule : "BALLERSDORF", cp : "68210" },
{ code : "68018", intitule : "BALSCHWILLER", cp : "68210" },
{ code : "68018", intitule : "BALSCHWILLER", cp : "68210" },
{ code : "68019", intitule : "BALTZENHEIM", cp : "68320" },
{ code : "68020", intitule : "BANTZENHEIM", cp : "68490" },
{ code : "68021", intitule : "BARTENHEIM", cp : "68870" },
{ code : "68021", intitule : "BARTENHEIM", cp : "68870" },
{ code : "68022", intitule : "BATTENHEIM", cp : "68390" },
{ code : "68023", intitule : "BEBLENHEIM", cp : "68980" },
{ code : "68024", intitule : "BELLEMAGNY", cp : "68210" },
{ code : "68025", intitule : "BENDORF", cp : "68480" },
{ code : "68026", intitule : "BENNWIHR", cp : "68630" },
{ code : "68026", intitule : "BENNWIHR", cp : "68126" },
{ code : "68027", intitule : "BERENTZWILLER", cp : "68130" },
{ code : "68028", intitule : "BERGHEIM", cp : "68750" },
{ code : "68029", intitule : "BERGHOLTZ", cp : "68500" },
{ code : "68030", intitule : "BERGHOLTZZELL", cp : "68500" },
{ code : "68031", intitule : "BERNWILLER", cp : "68210" },
{ code : "68032", intitule : "BERRWILLER", cp : "68500" },
{ code : "68033", intitule : "BETTENDORF", cp : "68560" },
{ code : "68034", intitule : "BETTLACH", cp : "68480" },
{ code : "68035", intitule : "BIEDERTHAL", cp : "68480" },
{ code : "68036", intitule : "BIESHEIM", cp : "68600" },
{ code : "68037", intitule : "BILTZHEIM", cp : "68127" },
{ code : "68038", intitule : "BISCHWIHR", cp : "68320" },
{ code : "68039", intitule : "BISEL", cp : "68580" },
{ code : "68040", intitule : "BITSCHWILLER LES THANN", cp : "68620" },
{ code : "68041", intitule : "BLODELSHEIM", cp : "68740" },
{ code : "68042", intitule : "BLOTZHEIM", cp : "68730" },
{ code : "68043", intitule : "BOLLWILLER", cp : "68540" },
{ code : "68044", intitule : "LE BONHOMME", cp : "68650" },
{ code : "68045", intitule : "BOURBACH LE BAS", cp : "68290" },
{ code : "68046", intitule : "BOURBACH LE HAUT", cp : "68290" },
{ code : "68049", intitule : "BOUXWILLER", cp : "68480" },
{ code : "68050", intitule : "BRECHAUMONT", cp : "68210" },
{ code : "68051", intitule : "BREITENBACH HAUT RHIN", cp : "68380" },
{ code : "68052", intitule : "BRETTEN", cp : "68780" },
{ code : "68054", intitule : "BRINCKHEIM", cp : "68870" },
{ code : "68055", intitule : "BRUEBACH", cp : "68440" },
{ code : "68056", intitule : "BRUNSTATT DIDENHEIM", cp : "68350" },
{ code : "68056", intitule : "BRUNSTATT DIDENHEIM", cp : "68350" },
{ code : "68057", intitule : "BUETHWILLER", cp : "68210" },
{ code : "68058", intitule : "BUHL", cp : "68530" },
{ code : "68059", intitule : "BURNHAUPT LE BAS", cp : "68520" },
{ code : "68060", intitule : "BURNHAUPT LE HAUT", cp : "68520" },
{ code : "68061", intitule : "BUSCHWILLER", cp : "68220" },
{ code : "68062", intitule : "CARSPACH", cp : "68130" },
{ code : "68063", intitule : "CERNAY", cp : "68700" },
{ code : "68064", intitule : "CHALAMPE", cp : "68490" },
{ code : "68065", intitule : "CHAVANNES SUR L ETANG", cp : "68210" },
{ code : "68066", intitule : "COLMAR", cp : "68000" },
{ code : "68067", intitule : "COURTAVON", cp : "68480" },
{ code : "68068", intitule : "DANNEMARIE", cp : "68210" },
{ code : "68069", intitule : "DESSENHEIM", cp : "68600" },
{ code : "68071", intitule : "DIEFMATTEN", cp : "68780" },
{ code : "68072", intitule : "DIETWILLER", cp : "68440" },
{ code : "68073", intitule : "DOLLEREN", cp : "68290" },
{ code : "68074", intitule : "DURLINSDORF", cp : "68480" },
{ code : "68075", intitule : "DURMENACH", cp : "68480" },
{ code : "68076", intitule : "DURRENENTZEN", cp : "68320" },
{ code : "68077", intitule : "EGLINGEN", cp : "68720" },
{ code : "68078", intitule : "EGUISHEIM", cp : "68420" },
{ code : "68079", intitule : "ELBACH", cp : "68210" },
{ code : "68080", intitule : "EMLINGEN", cp : "68130" },
{ code : "68081", intitule : "ST BERNARD", cp : "68720" },
{ code : "68081", intitule : "ST BERNARD", cp : "68720" },
{ code : "68082", intitule : "ENSISHEIM", cp : "68190" },
{ code : "68083", intitule : "ESCHBACH AU VAL", cp : "68140" },
{ code : "68084", intitule : "ESCHENTZWILLER", cp : "68440" },
{ code : "68085", intitule : "ETEIMBES", cp : "68210" },
{ code : "68086", intitule : "FALKWILLER", cp : "68210" },
{ code : "68087", intitule : "FELDBACH", cp : "68640" },
{ code : "68088", intitule : "FELDKIRCH", cp : "68540" },
{ code : "68089", intitule : "FELLERING", cp : "68470" },
{ code : "68089", intitule : "FELLERING", cp : "68470" },
{ code : "68090", intitule : "FERRETTE", cp : "68480" },
{ code : "68091", intitule : "FESSENHEIM", cp : "68740" },
{ code : "68092", intitule : "FISLIS", cp : "68480" },
{ code : "68093", intitule : "FLAXLANDEN", cp : "68720" },
{ code : "68094", intitule : "FOLGENSBOURG", cp : "68220" },
{ code : "68095", intitule : "FORTSCHWIHR", cp : "68320" },
{ code : "68096", intitule : "FRANKEN", cp : "68130" },
{ code : "68097", intitule : "FRELAND", cp : "68240" },
{ code : "68098", intitule : "FRIESEN", cp : "68580" },
{ code : "68099", intitule : "FROENINGEN", cp : "68720" },
{ code : "68100", intitule : "FULLEREN", cp : "68210" },
{ code : "68101", intitule : "GALFINGUE", cp : "68990" },
{ code : "68102", intitule : "GEISHOUSE", cp : "68690" },
{ code : "68103", intitule : "GEISPITZEN", cp : "68510" },
{ code : "68104", intitule : "GEISWASSER", cp : "68600" },
{ code : "68105", intitule : "GILDWILLER", cp : "68210" },
{ code : "68106", intitule : "GOLDBACH ALTENBACH", cp : "68760" },
{ code : "68106", intitule : "GOLDBACH ALTENBACH", cp : "68760" },
{ code : "68107", intitule : "GOMMERSDORF", cp : "68210" },
{ code : "68109", intitule : "GRIESBACH AU VAL", cp : "68140" },
{ code : "68110", intitule : "GRUSSENHEIM", cp : "68320" },
{ code : "68111", intitule : "GUEBERSCHWIHR", cp : "68420" },
{ code : "68112", intitule : "GUEBWILLER", cp : "68500" },
{ code : "68113", intitule : "GUEMAR", cp : "68970" },
{ code : "68114", intitule : "GUEVENATTEN", cp : "68210" },
{ code : "68115", intitule : "GUEWENHEIM", cp : "68116" },
{ code : "68116", intitule : "GUNDOLSHEIM", cp : "68250" },
{ code : "68117", intitule : "GUNSBACH", cp : "68140" },
{ code : "68118", intitule : "HABSHEIM", cp : "68440" },
{ code : "68119", intitule : "HAGENBACH", cp : "68210" },
{ code : "68120", intitule : "HAGENTHAL LE BAS", cp : "68220" },
{ code : "68121", intitule : "HAGENTHAL LE HAUT", cp : "68220" },
{ code : "68122", intitule : "HARTMANNSWILLER", cp : "68500" },
{ code : "68123", intitule : "HATTSTATT", cp : "68420" },
{ code : "68124", intitule : "HAUSGAUEN", cp : "68130" },
{ code : "68125", intitule : "HECKEN", cp : "68210" },
{ code : "68126", intitule : "HEGENHEIM", cp : "68220" },
{ code : "68127", intitule : "HEIDWILLER", cp : "68720" },
{ code : "68128", intitule : "HEIMERSDORF", cp : "68560" },
{ code : "68129", intitule : "HEIMSBRUNN", cp : "68990" },
{ code : "68130", intitule : "HEITEREN", cp : "68600" },
{ code : "68131", intitule : "HEIWILLER", cp : "68130" },
{ code : "68132", intitule : "HELFRANTZKIRCH", cp : "68510" },
{ code : "68134", intitule : "HERRLISHEIM PRES COLMAR", cp : "68420" },
{ code : "68135", intitule : "HESINGUE", cp : "68220" },
{ code : "68136", intitule : "HETTENSCHLAG", cp : "68600" },
{ code : "68137", intitule : "HINDLINGEN", cp : "68580" },
{ code : "68138", intitule : "HIRSINGUE", cp : "68560" },
{ code : "68139", intitule : "HIRTZBACH", cp : "68118" },
{ code : "68140", intitule : "HIRTZFELDEN", cp : "68740" },
{ code : "68141", intitule : "HOCHSTATT", cp : "68720" },
{ code : "68142", intitule : "HOHROD", cp : "68140" },
{ code : "68143", intitule : "PORTE DU RIED", cp : "68320" },
{ code : "68143", intitule : "PORTE DU RIED", cp : "68320" },
{ code : "68144", intitule : "HOMBOURG", cp : "68490" },
{ code : "68145", intitule : "HORBOURG WIHR", cp : "68180" },
{ code : "68145", intitule : "HORBOURG WIHR", cp : "68180" },
{ code : "68146", intitule : "HOUSSEN", cp : "68125" },
{ code : "68147", intitule : "HUNAWIHR", cp : "68150" },
{ code : "68148", intitule : "HUNDSBACH", cp : "68130" },
{ code : "68149", intitule : "HUNINGUE", cp : "68330" },
{ code : "68150", intitule : "HUSSEREN LES CHATEAUX", cp : "68420" },
{ code : "68151", intitule : "HUSSEREN WESSERLING", cp : "68470" },
{ code : "68152", intitule : "ILLFURTH", cp : "68720" },
{ code : "68153", intitule : "ILLHAEUSERN", cp : "68970" },
{ code : "68154", intitule : "ILLZACH", cp : "68110" },
{ code : "68154", intitule : "ILLZACH", cp : "68110" },
{ code : "68155", intitule : "INGERSHEIM", cp : "68040" },
{ code : "68156", intitule : "ISSENHEIM", cp : "68500" },
{ code : "68157", intitule : "JEBSHEIM", cp : "68320" },
{ code : "68158", intitule : "JETTINGEN", cp : "68130" },
{ code : "68159", intitule : "JUNGHOLTZ", cp : "68500" },
{ code : "68160", intitule : "KAPPELEN", cp : "68510" },
{ code : "68161", intitule : "KATZENTHAL", cp : "68230" },
{ code : "68162", intitule : "KAYSERSBERG VIGNOBLE", cp : "68240" },
{ code : "68162", intitule : "KAYSERSBERG VIGNOBLE", cp : "68240" },
{ code : "68162", intitule : "KAYSERSBERG VIGNOBLE", cp : "68240" },
{ code : "68163", intitule : "KEMBS", cp : "68680" },
{ code : "68163", intitule : "KEMBS", cp : "68680" },
{ code : "68165", intitule : "KIFFIS", cp : "68480" },
{ code : "68166", intitule : "KINGERSHEIM", cp : "68260" },
{ code : "68167", intitule : "KIRCHBERG", cp : "68290" },
{ code : "68168", intitule : "KNOERINGUE", cp : "68220" },
{ code : "68169", intitule : "KOESTLACH", cp : "68480" },
{ code : "68170", intitule : "KOETZINGUE", cp : "68510" },
{ code : "68171", intitule : "KRUTH", cp : "68820" },
{ code : "68172", intitule : "KUNHEIM", cp : "68320" },
{ code : "68173", intitule : "LABAROCHE", cp : "68910" },
{ code : "68174", intitule : "LANDSER", cp : "68440" },
{ code : "68175", intitule : "LAPOUTROIE", cp : "68650" },
{ code : "68176", intitule : "LARGITZEN", cp : "68580" },
{ code : "68177", intitule : "LAUTENBACH", cp : "68610" },
{ code : "68177", intitule : "LAUTENBACH", cp : "68610" },
{ code : "68177", intitule : "LAUTENBACH", cp : "68610" },
{ code : "68178", intitule : "LAUTENBACHZELL", cp : "68610" },
{ code : "68178", intitule : "LAUTENBACHZELL", cp : "68610" },
{ code : "68179", intitule : "LAUW", cp : "68290" },
{ code : "68180", intitule : "LEIMBACH", cp : "68800" },
{ code : "68181", intitule : "LEVONCOURT", cp : "68480" },
{ code : "68182", intitule : "LEYMEN", cp : "68220" },
{ code : "68183", intitule : "LIEBENSWILLER", cp : "68220" },
{ code : "68184", intitule : "LIEBSDORF", cp : "68480" },
{ code : "68185", intitule : "LIEPVRE", cp : "68660" },
{ code : "68186", intitule : "LIGSDORF", cp : "68480" },
{ code : "68187", intitule : "LINSDORF", cp : "68480" },
{ code : "68188", intitule : "LINTHAL", cp : "68610" },
{ code : "68189", intitule : "LOGELHEIM", cp : "68280" },
{ code : "68190", intitule : "LUCELLE", cp : "68480" },
{ code : "68191", intitule : "LUEMSCHWILLER", cp : "68720" },
{ code : "68192", intitule : "VALDIEU LUTRAN", cp : "68210" },
{ code : "68192", intitule : "VALDIEU LUTRAN", cp : "68210" },
{ code : "68193", intitule : "LUTTENBACH PRES MUNSTER", cp : "68140" },
{ code : "68194", intitule : "LUTTER", cp : "68480" },
{ code : "68195", intitule : "LUTTERBACH", cp : "68460" },
{ code : "68196", intitule : "MAGNY", cp : "68210" },
{ code : "68197", intitule : "MAGSTATT LE BAS", cp : "68510" },
{ code : "68198", intitule : "MAGSTATT LE HAUT", cp : "68510" },
{ code : "68199", intitule : "MALMERSPACH", cp : "68550" },
{ code : "68200", intitule : "MANSPACH", cp : "68210" },
{ code : "68201", intitule : "MASEVAUX NIEDERBRUCK", cp : "68290" },
{ code : "68201", intitule : "MASEVAUX NIEDERBRUCK", cp : "68290" },
{ code : "68202", intitule : "MERTZEN", cp : "68210" },
{ code : "68203", intitule : "MERXHEIM", cp : "68500" },
{ code : "68204", intitule : "METZERAL", cp : "68380" },
{ code : "68205", intitule : "MEYENHEIM", cp : "68890" },
{ code : "68207", intitule : "MICHELBACH LE BAS", cp : "68730" },
{ code : "68208", intitule : "MICHELBACH LE HAUT", cp : "68220" },
{ code : "68209", intitule : "MITTELWIHR", cp : "68630" },
{ code : "68210", intitule : "MITTLACH", cp : "68380" },
{ code : "68211", intitule : "MITZACH", cp : "68470" },
{ code : "68212", intitule : "MOERNACH", cp : "68480" },
{ code : "68213", intitule : "MOLLAU", cp : "68470" },
{ code : "68214", intitule : "MONTREUX JEUNE", cp : "68210" },
{ code : "68215", intitule : "MONTREUX VIEUX", cp : "68210" },
{ code : "68216", intitule : "MOOSLARGUE", cp : "68580" },
{ code : "68216", intitule : "MOOSLARGUE", cp : "68580" },
{ code : "68217", intitule : "MOOSCH", cp : "68690" },
{ code : "68218", intitule : "MORSCHWILLER LE BAS", cp : "68790" },
{ code : "68219", intitule : "LE HAUT SOULTZBACH", cp : "68780" },
{ code : "68219", intitule : "LE HAUT SOULTZBACH", cp : "68780" },
{ code : "68221", intitule : "MUESPACH", cp : "68640" },
{ code : "68221", intitule : "MUESPACH", cp : "68640" },
{ code : "68222", intitule : "MUESPACH LE HAUT", cp : "68640" },
{ code : "68223", intitule : "MUHLBACH SUR MUNSTER", cp : "68380" },
{ code : "68224", intitule : "MULHOUSE", cp : "68200" },
{ code : "68224", intitule : "MULHOUSE", cp : "68100" },
{ code : "68224", intitule : "MULHOUSE", cp : "68200" },
{ code : "68224", intitule : "MULHOUSE", cp : "68200" },
{ code : "68225", intitule : "MUNCHHOUSE", cp : "68740" },
{ code : "68226", intitule : "MUNSTER", cp : "68140" },
{ code : "68227", intitule : "MUNTZENHEIM", cp : "68320" },
{ code : "68228", intitule : "MUNWILLER", cp : "68250" },
{ code : "68229", intitule : "MURBACH", cp : "68530" },
{ code : "68230", intitule : "NAMBSHEIM", cp : "68740" },
{ code : "68231", intitule : "NEUF BRISACH", cp : "68600" },
{ code : "68232", intitule : "NEUWILLER", cp : "68220" },
{ code : "68234", intitule : "NIEDERENTZEN", cp : "68127" },
{ code : "68235", intitule : "NIEDERHERGHEIM", cp : "68127" },
{ code : "68237", intitule : "NIEDERMORSCHWIHR", cp : "68230" },
{ code : "68238", intitule : "NIFFER", cp : "68680" },
{ code : "68239", intitule : "OBERBRUCK", cp : "68290" },
{ code : "68240", intitule : "ILLTAL", cp : "68960" },
{ code : "68240", intitule : "ILLTAL", cp : "68960" },
{ code : "68240", intitule : "ILLTAL", cp : "68960" },
{ code : "68241", intitule : "OBERENTZEN", cp : "68127" },
{ code : "68242", intitule : "OBERHERGHEIM", cp : "68127" },
{ code : "68243", intitule : "OBERLARG", cp : "68480" },
{ code : "68244", intitule : "OBERMORSCHWIHR", cp : "68420" },
{ code : "68245", intitule : "OBERMORSCHWILLER", cp : "68130" },
{ code : "68246", intitule : "OBERSAASHEIM", cp : "68600" },
{ code : "68247", intitule : "ODEREN", cp : "68830" },
{ code : "68248", intitule : "OLTINGUE", cp : "68480" },
{ code : "68249", intitule : "ORBEY", cp : "68370" },
{ code : "68250", intitule : "ORSCHWIHR", cp : "68500" },
{ code : "68251", intitule : "OSENBACH", cp : "68570" },
{ code : "68252", intitule : "OSTHEIM", cp : "68150" },
{ code : "68253", intitule : "OTTMARSHEIM", cp : "68490" },
{ code : "68254", intitule : "PETIT LANDAU", cp : "68490" },
{ code : "68255", intitule : "PFAFFENHEIM", cp : "68250" },
{ code : "68256", intitule : "PFASTATT", cp : "68120" },
{ code : "68257", intitule : "PFETTERHOUSE", cp : "68480" },
{ code : "68258", intitule : "PULVERSHEIM", cp : "68840" },
{ code : "68259", intitule : "RAEDERSDORF", cp : "68480" },
{ code : "68260", intitule : "RAEDERSHEIM", cp : "68190" },
{ code : "68261", intitule : "RAMMERSMATT", cp : "68800" },
{ code : "68262", intitule : "RANSPACH", cp : "68470" },
{ code : "68263", intitule : "RANSPACH LE BAS", cp : "68730" },
{ code : "68264", intitule : "RANSPACH LE HAUT", cp : "68220" },
{ code : "68265", intitule : "RANTZWILLER", cp : "68510" },
{ code : "68266", intitule : "REGUISHEIM", cp : "68890" },
{ code : "68267", intitule : "REININGUE", cp : "68950" },
{ code : "68268", intitule : "RETZWILLER", cp : "68210" },
{ code : "68269", intitule : "RIBEAUVILLE", cp : "68150" },
{ code : "68270", intitule : "RICHWILLER", cp : "68120" },
{ code : "68271", intitule : "RIEDISHEIM", cp : "68400" },
{ code : "68273", intitule : "RIESPACH", cp : "68640" },
{ code : "68274", intitule : "RIMBACH PRES GUEBWILLER", cp : "68500" },
{ code : "68275", intitule : "RIMBACH PRES MASEVAUX", cp : "68290" },
{ code : "68276", intitule : "RIMBACHZELL", cp : "68500" },
{ code : "68277", intitule : "RIQUEWIHR", cp : "68340" },
{ code : "68278", intitule : "RIXHEIM", cp : "68170" },
{ code : "68279", intitule : "RODEREN", cp : "68800" },
{ code : "68280", intitule : "RODERN", cp : "68590" },
{ code : "68281", intitule : "ROGGENHOUSE", cp : "68740" },
{ code : "68282", intitule : "ROMAGNY", cp : "68210" },
{ code : "68283", intitule : "ROMBACH LE FRANC", cp : "68660" },
{ code : "68284", intitule : "ROPPENTZWILLER", cp : "68480" },
{ code : "68285", intitule : "RORSCHWIHR", cp : "68590" },
{ code : "68286", intitule : "ROSENAU", cp : "68128" },
{ code : "68287", intitule : "ROUFFACH", cp : "68250" },
{ code : "68288", intitule : "RUEDERBACH", cp : "68560" },
{ code : "68289", intitule : "RUELISHEIM", cp : "68270" },
{ code : "68290", intitule : "RUSTENHART", cp : "68740" },
{ code : "68291", intitule : "RUMERSHEIM LE HAUT", cp : "68740" },
{ code : "68292", intitule : "ST AMARIN", cp : "68550" },
{ code : "68293", intitule : "ST COSME", cp : "68210" },
{ code : "68294", intitule : "STE CROIX AUX MINES", cp : "68160" },
{ code : "68295", intitule : "STE CROIX EN PLAINE", cp : "68127" },
{ code : "68296", intitule : "ST HIPPOLYTE", cp : "68590" },
{ code : "68297", intitule : "ST LOUIS", cp : "68300" },
{ code : "68297", intitule : "ST LOUIS", cp : "68300" },
{ code : "68297", intitule : "ST LOUIS", cp : "68300" },
{ code : "68298", intitule : "STE MARIE AUX MINES", cp : "68160" },
{ code : "68298", intitule : "STE MARIE AUX MINES", cp : "68160" },
{ code : "68299", intitule : "ST ULRICH", cp : "68210" },
{ code : "68300", intitule : "SAUSHEIM", cp : "68390" },
{ code : "68301", intitule : "SCHLIERBACH", cp : "68440" },
{ code : "68302", intitule : "SCHWEIGHOUSE THANN", cp : "68520" },
{ code : "68303", intitule : "SCHWOBEN", cp : "68130" },
{ code : "68304", intitule : "SENTHEIM", cp : "68780" },
{ code : "68305", intitule : "SEPPOIS LE BAS", cp : "68580" },
{ code : "68306", intitule : "SEPPOIS LE HAUT", cp : "68580" },
{ code : "68307", intitule : "SEWEN", cp : "68290" },
{ code : "68308", intitule : "SICKERT", cp : "68290" },
{ code : "68309", intitule : "SIERENTZ", cp : "68510" },
{ code : "68311", intitule : "SONDERNACH", cp : "68380" },
{ code : "68312", intitule : "SONDERSDORF", cp : "68480" },
{ code : "68313", intitule : "SOPPE LE BAS", cp : "68780" },
{ code : "68315", intitule : "SOULTZ HAUT RHIN", cp : "68360" },
{ code : "68316", intitule : "SOULTZBACH LES BAINS", cp : "68230" },
{ code : "68317", intitule : "SOULTZEREN", cp : "68140" },
{ code : "68318", intitule : "SOULTZMATT", cp : "68570" },
{ code : "68318", intitule : "SOULTZMATT", cp : "68570" },
{ code : "68320", intitule : "SPECHBACH", cp : "68720" },
{ code : "68320", intitule : "SPECHBACH", cp : "68720" },
{ code : "68321", intitule : "STAFFELFELDEN", cp : "68850" },
{ code : "68322", intitule : "STEINBACH", cp : "68700" },
{ code : "68323", intitule : "STEINBRUNN LE BAS", cp : "68440" },
{ code : "68324", intitule : "STEINBRUNN LE HAUT", cp : "68440" },
{ code : "68325", intitule : "STEINSOULTZ", cp : "68640" },
{ code : "68326", intitule : "STERNENBERG", cp : "68780" },
{ code : "68327", intitule : "STETTEN", cp : "68510" },
{ code : "68328", intitule : "STORCKENSOHN", cp : "68470" },
{ code : "68329", intitule : "STOSSWIHR", cp : "68140" },
{ code : "68330", intitule : "STRUETH", cp : "68580" },
{ code : "68331", intitule : "SUNDHOFFEN", cp : "68280" },
{ code : "68332", intitule : "TAGOLSHEIM", cp : "68720" },
{ code : "68333", intitule : "TAGSDORF", cp : "68130" },
{ code : "68334", intitule : "THANN", cp : "68800" },
{ code : "68335", intitule : "THANNENKIRCH", cp : "68590" },
{ code : "68336", intitule : "TRAUBACH LE BAS", cp : "68210" },
{ code : "68337", intitule : "TRAUBACH LE HAUT", cp : "68210" },
{ code : "68338", intitule : "TURCKHEIM", cp : "68230" },
{ code : "68340", intitule : "UEBERSTRASS", cp : "68580" },
{ code : "68341", intitule : "UFFHEIM", cp : "68510" },
{ code : "68342", intitule : "UFFHOLTZ", cp : "68700" },
{ code : "68343", intitule : "UNGERSHEIM", cp : "68190" },
{ code : "68344", intitule : "URBES", cp : "68121" },
{ code : "68345", intitule : "URSCHENHEIM", cp : "68320" },
{ code : "68347", intitule : "VIEUX FERRETTE", cp : "68480" },
{ code : "68348", intitule : "VIEUX THANN", cp : "68800" },
{ code : "68349", intitule : "VILLAGE NEUF", cp : "68128" },
{ code : "68350", intitule : "VOEGTLINSHOFFEN", cp : "68420" },
{ code : "68351", intitule : "VOGELGRUN", cp : "68600" },
{ code : "68352", intitule : "VOLGELSHEIM", cp : "68600" },
{ code : "68353", intitule : "WAHLBACH", cp : "68130" },
{ code : "68354", intitule : "WALBACH", cp : "68230" },
{ code : "68355", intitule : "WALDIGHOFEN", cp : "68640" },
{ code : "68356", intitule : "WALHEIM", cp : "68130" },
{ code : "68357", intitule : "WALTENHEIM", cp : "68510" },
{ code : "68358", intitule : "WASSERBOURG", cp : "68230" },
{ code : "68359", intitule : "WATTWILLER", cp : "68700" },
{ code : "68360", intitule : "WECKOLSHEIM", cp : "68600" },
{ code : "68361", intitule : "WEGSCHEID", cp : "68290" },
{ code : "68362", intitule : "WENTZWILLER", cp : "68220" },
{ code : "68363", intitule : "WERENTZHOUSE", cp : "68480" },
{ code : "68364", intitule : "WESTHALTEN", cp : "68250" },
{ code : "68365", intitule : "WETTOLSHEIM", cp : "68920" },
{ code : "68366", intitule : "WICKERSCHWIHR", cp : "68320" },
{ code : "68367", intitule : "WIDENSOLEN", cp : "68320" },
{ code : "68368", intitule : "WIHR AU VAL", cp : "68230" },
{ code : "68370", intitule : "WILDENSTEIN", cp : "68820" },
{ code : "68371", intitule : "WILLER", cp : "68960" },
{ code : "68372", intitule : "WILLER SUR THUR", cp : "68760" },
{ code : "68373", intitule : "WINKEL", cp : "68480" },
{ code : "68374", intitule : "WINTZENHEIM", cp : "68124" },
{ code : "68374", intitule : "WINTZENHEIM", cp : "68920" },
{ code : "68375", intitule : "WITTELSHEIM", cp : "68310" },
{ code : "68376", intitule : "WITTENHEIM", cp : "68270" },
{ code : "68377", intitule : "WITTERSDORF", cp : "68130" },
{ code : "68378", intitule : "WOLFERSDORF", cp : "68210" },
{ code : "68379", intitule : "WOLFGANTZEN", cp : "68600" },
{ code : "68380", intitule : "WOLSCHWILLER", cp : "68480" },
{ code : "68381", intitule : "WUENHEIM", cp : "68500" },
{ code : "68382", intitule : "ZAESSINGUE", cp : "68130" },
{ code : "68383", intitule : "ZELLENBERG", cp : "68340" },
{ code : "68384", intitule : "ZILLISHEIM", cp : "68720" },
{ code : "68385", intitule : "ZIMMERBACH", cp : "68230" },
{ code : "68386", intitule : "ZIMMERSHEIM", cp : "68440" },
{ code : "69001", intitule : "AFFOUX", cp : "69170" },
{ code : "69002", intitule : "AIGUEPERSE", cp : "69790" },
{ code : "69003", intitule : "ALBIGNY SUR SAONE", cp : "69250" },
{ code : "69004", intitule : "ALIX", cp : "69380" },
{ code : "69005", intitule : "AMBERIEUX", cp : "69480" },
{ code : "69006", intitule : "AMPLEPUIS", cp : "69550" },
{ code : "69006", intitule : "AMPLEPUIS", cp : "69550" },
{ code : "69007", intitule : "AMPUIS", cp : "69420" },
{ code : "69008", intitule : "ANCY", cp : "69490" },
{ code : "69009", intitule : "ANSE", cp : "69480" },
{ code : "69010", intitule : "L ARBRESLE", cp : "69210" },
{ code : "69012", intitule : "LES ARDILLATS", cp : "69430" },
{ code : "69013", intitule : "ARNAS", cp : "69400" },
{ code : "69014", intitule : "AVEIZE", cp : "69610" },
{ code : "69015", intitule : "AVENAS", cp : "69430" },
{ code : "69016", intitule : "AZOLETTE", cp : "69790" },
{ code : "69017", intitule : "BAGNOLS", cp : "69620" },
{ code : "69018", intitule : "BEAUJEU", cp : "69430" },
{ code : "69018", intitule : "BEAUJEU", cp : "69430" },
{ code : "69019", intitule : "BELLEVILLE", cp : "69220" },
{ code : "69020", intitule : "BELMONT D AZERGUES", cp : "69380" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69021", intitule : "BESSENAY", cp : "69690" },
{ code : "69022", intitule : "BIBOST", cp : "69690" },
{ code : "69022", intitule : "BIBOST", cp : "69690" },
{ code : "69023", intitule : "BLACE", cp : "69460" },
{ code : "69024", intitule : "LE BOIS D OINGT", cp : "69620" },
{ code : "69026", intitule : "LE BREUIL", cp : "69620" },
{ code : "69027", intitule : "BRIGNAIS", cp : "69530" },
{ code : "69027", intitule : "BRIGNAIS", cp : "69530" },
{ code : "69028", intitule : "BRINDAS", cp : "69126" },
{ code : "69029", intitule : "BRON", cp : "69500" },
{ code : "69030", intitule : "BRULLIOLES", cp : "69690" },
{ code : "69031", intitule : "BRUSSIEU", cp : "69690" },
{ code : "69032", intitule : "BULLY", cp : "69210" },
{ code : "69033", intitule : "CAILLOUX SUR FONTAINES", cp : "69270" },
{ code : "69034", intitule : "CALUIRE ET CUIRE", cp : "69300" },
{ code : "69035", intitule : "CENVES", cp : "69840" },
{ code : "69036", intitule : "CERCIE", cp : "69220" },
{ code : "69037", intitule : "CHAMBOST ALLIERES", cp : "69870" },
{ code : "69038", intitule : "CHAMBOST LONGESSAIGNE", cp : "69770" },
{ code : "69039", intitule : "CHAMELET", cp : "69620" },
{ code : "69040", intitule : "CHAMPAGNE AU MONT D OR", cp : "69410" },
{ code : "69042", intitule : "LA CHAPELLE SUR COISE", cp : "69590" },
{ code : "69043", intitule : "CHAPONOST", cp : "69630" },
{ code : "69044", intitule : "CHARBONNIERES LES BAINS", cp : "69260" },
{ code : "69045", intitule : "CHARENTAY", cp : "69220" },
{ code : "69046", intitule : "CHARLY", cp : "69390" },
{ code : "69047", intitule : "CHARNAY", cp : "69380" },
{ code : "69048", intitule : "CHASSAGNY", cp : "69700" },
{ code : "69049", intitule : "CHASSELAY", cp : "69380" },
{ code : "69050", intitule : "CHATILLON", cp : "69380" },
{ code : "69051", intitule : "CHAUSSAN", cp : "69440" },
{ code : "69051", intitule : "CHAUSSAN", cp : "69440" },
{ code : "69052", intitule : "CHAZAY D AZERGUES", cp : "69380" },
{ code : "69053", intitule : "CHENAS", cp : "69840" },
{ code : "69054", intitule : "CHENELETTE", cp : "69430" },
{ code : "69055", intitule : "LES CHERES", cp : "69380" },
{ code : "69056", intitule : "CHESSY", cp : "69380" },
{ code : "69057", intitule : "CHEVINAY", cp : "69210" },
{ code : "69058", intitule : "CHIROUBLES", cp : "69115" },
{ code : "69059", intitule : "CIVRIEUX D AZERGUES", cp : "69380" },
{ code : "69060", intitule : "CLAVEISOLLES", cp : "69870" },
{ code : "69061", intitule : "COGNY", cp : "69640" },
{ code : "69062", intitule : "COISE", cp : "69590" },
{ code : "69063", intitule : "COLLONGES AU MONT D OR", cp : "69660" },
{ code : "69064", intitule : "CONDRIEU", cp : "69420" },
{ code : "69065", intitule : "CORCELLES EN BEAUJOLAIS", cp : "69220" },
{ code : "69066", intitule : "COURS", cp : "69470" },
{ code : "69066", intitule : "COURS", cp : "69240" },
{ code : "69066", intitule : "COURS", cp : "69470" },
{ code : "69066", intitule : "COURS", cp : "69470" },
{ code : "69067", intitule : "COURZIEU", cp : "69690" },
{ code : "69068", intitule : "COUZON AU MONT D OR", cp : "69270" },
{ code : "69069", intitule : "CRAPONNE", cp : "69290" },
{ code : "69070", intitule : "CUBLIZE", cp : "69550" },
{ code : "69071", intitule : "CURIS AU MONT D OR", cp : "69250" },
{ code : "69072", intitule : "DARDILLY", cp : "69570" },
{ code : "69073", intitule : "DAREIZE", cp : "69490" },
{ code : "69074", intitule : "DENICE", cp : "69640" },
{ code : "69075", intitule : "DIEME", cp : "69170" },
{ code : "69076", intitule : "DOMMARTIN", cp : "69380" },
{ code : "69077", intitule : "DRACE", cp : "69220" },
{ code : "69078", intitule : "DUERNE", cp : "69850" },
{ code : "69080", intitule : "ECHALAS", cp : "69700" },
{ code : "69080", intitule : "ECHALAS", cp : "69700" },
{ code : "69081", intitule : "ECULLY", cp : "69130" },
{ code : "69082", intitule : "EMERINGES", cp : "69840" },
{ code : "69083", intitule : "EVEUX", cp : "69210" },
{ code : "69084", intitule : "FLEURIE", cp : "69820" },
{ code : "69085", intitule : "FLEURIEU SUR SAONE", cp : "69250" },
{ code : "69086", intitule : "FLEURIEUX SUR L ARBRESLE", cp : "69210" },
{ code : "69087", intitule : "FONTAINES ST MARTIN", cp : "69270" },
{ code : "69088", intitule : "FONTAINES SUR SAONE", cp : "69270" },
{ code : "69089", intitule : "FRANCHEVILLE", cp : "69340" },
{ code : "69090", intitule : "FRONTENAS", cp : "69620" },
{ code : "69091", intitule : "GIVORS", cp : "69700" },
{ code : "69091", intitule : "GIVORS", cp : "69700" },
{ code : "69092", intitule : "GLEIZE", cp : "69400" },
{ code : "69093", intitule : "GRANDRIS", cp : "69870" },
{ code : "69094", intitule : "GREZIEU LA VARENNE", cp : "69290" },
{ code : "69095", intitule : "GREZIEU LE MARCHE", cp : "69610" },
{ code : "69096", intitule : "GRIGNY", cp : "69520" },
{ code : "69097", intitule : "LES HAIES", cp : "69420" },
{ code : "69098", intitule : "LES HALLES", cp : "69610" },
{ code : "69099", intitule : "HAUTE RIVOIRE", cp : "69610" },
{ code : "69100", intitule : "IRIGNY", cp : "69540" },
{ code : "69101", intitule : "JARNIOUX", cp : "69640" },
{ code : "69102", intitule : "JOUX", cp : "69170" },
{ code : "69103", intitule : "JULIENAS", cp : "69840" },
{ code : "69104", intitule : "JULLIE", cp : "69840" },
{ code : "69105", intitule : "LACENAS", cp : "69640" },
{ code : "69106", intitule : "LACHASSAGNE", cp : "69480" },
{ code : "69107", intitule : "LAMURE SUR AZERGUES", cp : "69870" },
{ code : "69108", intitule : "LANCIE", cp : "69220" },
{ code : "69109", intitule : "LANTIGNIE", cp : "69430" },
{ code : "69110", intitule : "LARAJASSE", cp : "69590" },
{ code : "69110", intitule : "LARAJASSE", cp : "69590" },
{ code : "69111", intitule : "LEGNY", cp : "69620" },
{ code : "69112", intitule : "LENTILLY", cp : "69210" },
{ code : "69113", intitule : "LETRA", cp : "69620" },
{ code : "69113", intitule : "LETRA", cp : "69620" },
{ code : "69114", intitule : "LIERGUES", cp : "69400" },
{ code : "69114", intitule : "LIERGUES", cp : "69400" },
{ code : "69115", intitule : "LIMAS", cp : "69400" },
{ code : "69116", intitule : "LIMONEST", cp : "69760" },
{ code : "69117", intitule : "LISSIEU", cp : "69380" },
{ code : "69118", intitule : "LOIRE SUR RHONE", cp : "69700" },
{ code : "69119", intitule : "LONGES", cp : "69420" },
{ code : "69120", intitule : "LONGESSAIGNE", cp : "69770" },
{ code : "69121", intitule : "LOZANNE", cp : "69380" },
{ code : "69122", intitule : "LUCENAY", cp : "69480" },
{ code : "69124", intitule : "MARCHAMPT", cp : "69430" },
{ code : "69125", intitule : "MARCILLY D AZERGUES", cp : "69380" },
{ code : "69126", intitule : "MARCY", cp : "69480" },
{ code : "69127", intitule : "MARCY L ETOILE", cp : "69280" },
{ code : "69130", intitule : "MEAUX LA MONTAGNE", cp : "69550" },
{ code : "69131", intitule : "MESSIMY", cp : "69510" },
{ code : "69132", intitule : "MEYS", cp : "69610" },
{ code : "69133", intitule : "MILLERY", cp : "69390" },
{ code : "69134", intitule : "MOIRE", cp : "69620" },
{ code : "69135", intitule : "MONSOLS", cp : "69860" },
{ code : "69136", intitule : "MONTAGNY", cp : "69700" },
{ code : "69136", intitule : "MONTAGNY", cp : "69700" },
{ code : "69137", intitule : "MONTMELAS ST SORLIN", cp : "69640" },
{ code : "69138", intitule : "MONTROMANT", cp : "69610" },
{ code : "69139", intitule : "MONTROTTIER", cp : "69770" },
{ code : "69140", intitule : "MORANCE", cp : "69480" },
{ code : "69140", intitule : "MORANCE", cp : "69480" },
{ code : "69141", intitule : "MORNANT", cp : "69440" },
{ code : "69141", intitule : "MORNANT", cp : "69440" },
{ code : "69142", intitule : "LA MULATIERE", cp : "69350" },
{ code : "69143", intitule : "NEUVILLE SUR SAONE", cp : "69250" },
{ code : "69145", intitule : "ODENAS", cp : "69460" },
{ code : "69146", intitule : "OINGT", cp : "69620" },
{ code : "69147", intitule : "LES OLMES", cp : "69490" },
{ code : "69148", intitule : "ORLIENAS", cp : "69530" },
{ code : "69148", intitule : "ORLIENAS", cp : "69530" },
{ code : "69149", intitule : "OULLINS", cp : "69600" },
{ code : "69150", intitule : "OUROUX", cp : "69860" },
{ code : "69151", intitule : "LE PERREON", cp : "69460" },
{ code : "69152", intitule : "PIERRE BENITE", cp : "69310" },
{ code : "69153", intitule : "POLEYMIEUX AU MONT D OR", cp : "69250" },
{ code : "69154", intitule : "POLLIONNAY", cp : "69290" },
{ code : "69155", intitule : "POMEYS", cp : "69590" },
{ code : "69156", intitule : "POMMIERS", cp : "69480" },
{ code : "69157", intitule : "PONTCHARRA SUR TURDINE", cp : "69490" },
{ code : "69159", intitule : "POUILLY LE MONIAL", cp : "69400" },
{ code : "69160", intitule : "POULE LES ECHARMEAUX", cp : "69870" },
{ code : "69160", intitule : "POULE LES ECHARMEAUX", cp : "69870" },
{ code : "69161", intitule : "PROPIERES", cp : "69790" },
{ code : "69162", intitule : "QUINCIE EN BEAUJOLAIS", cp : "69430" },
{ code : "69163", intitule : "QUINCIEUX", cp : "69650" },
{ code : "69163", intitule : "QUINCIEUX", cp : "69650" },
{ code : "69164", intitule : "RANCHAL", cp : "69470" },
{ code : "69165", intitule : "REGNIE DURETTE", cp : "69430" },
{ code : "69165", intitule : "REGNIE DURETTE", cp : "69430" },
{ code : "69166", intitule : "RIVERIE", cp : "69440" },
{ code : "69167", intitule : "RIVOLET", cp : "69640" },
{ code : "69167", intitule : "RIVOLET", cp : "69640" },
{ code : "69168", intitule : "ROCHETAILLEE SUR SAONE", cp : "69270" },
{ code : "69169", intitule : "RONNO", cp : "69550" },
{ code : "69170", intitule : "RONTALON", cp : "69510" },
{ code : "69171", intitule : "SAIN BEL", cp : "69210" },
{ code : "69172", intitule : "SALLES ARBUISSONNAS EN BEAUJOLAIS", cp : "69460" },
{ code : "69172", intitule : "SALLES ARBUISSONNAS EN BEAUJOLAIS", cp : "69460" },
{ code : "69173", intitule : "SARCEY", cp : "69490" },
{ code : "69174", intitule : "LES SAUVAGES", cp : "69170" },
{ code : "69175", intitule : "SAVIGNY", cp : "69210" },
{ code : "69176", intitule : "SOUCIEU EN JARREST", cp : "69510" },
{ code : "69177", intitule : "SOURCIEUX LES MINES", cp : "69210" },
{ code : "69178", intitule : "SOUZY", cp : "69610" },
{ code : "69179", intitule : "ST ANDEOL LE CHATEAU", cp : "69700" },
{ code : "69180", intitule : "ST ANDRE LA COTE", cp : "69440" },
{ code : "69181", intitule : "ST APPOLINAIRE", cp : "69170" },
{ code : "69182", intitule : "ST BONNET DES BRUYERES", cp : "69790" },
{ code : "69183", intitule : "ST BONNET LE TRONCY", cp : "69870" },
{ code : "69184", intitule : "STE CATHERINE", cp : "69440" },
{ code : "69184", intitule : "STE CATHERINE", cp : "69440" },
{ code : "69184", intitule : "STE CATHERINE", cp : "69440" },
{ code : "69185", intitule : "ST CHRISTOPHE", cp : "69860" },
{ code : "69186", intitule : "ST CLEMENT DE VERS", cp : "69790" },
{ code : "69187", intitule : "ST CLEMENT LES PLACES", cp : "69930" },
{ code : "69188", intitule : "ST CLEMENT SUR VALSONNE", cp : "69170" },
{ code : "69189", intitule : "STE COLOMBE", cp : "69560" },
{ code : "69190", intitule : "STE CONSORCE", cp : "69280" },
{ code : "69191", intitule : "ST CYR AU MONT D OR", cp : "69450" },
{ code : "69192", intitule : "ST CYR LE CHATOUX", cp : "69870" },
{ code : "69193", intitule : "ST CYR SUR LE RHONE", cp : "69560" },
{ code : "69194", intitule : "ST DIDIER AU MONT D OR", cp : "69370" },
{ code : "69195", intitule : "ST DIDIER SOUS RIVERIE", cp : "69440" },
{ code : "69195", intitule : "ST DIDIER SOUS RIVERIE", cp : "69440" },
{ code : "69196", intitule : "ST DIDIER SUR BEAUJEU", cp : "69430" },
{ code : "69197", intitule : "ST ETIENNE DES OULLIERES", cp : "69460" },
{ code : "69198", intitule : "ST ETIENNE LA VARENNE", cp : "69460" },
{ code : "69199", intitule : "ST FONS", cp : "69190" },
{ code : "69200", intitule : "ST FORGEUX", cp : "69490" },
{ code : "69201", intitule : "STE FOY L ARGENTIERE", cp : "69610" },
{ code : "69202", intitule : "STE FOY LES LYON", cp : "69110" },
{ code : "69203", intitule : "ST GENIS L ARGENTIERE", cp : "69610" },
{ code : "69204", intitule : "ST GENIS LAVAL", cp : "69230" },
{ code : "69205", intitule : "ST GENIS LES OLLIERES", cp : "69290" },
{ code : "69206", intitule : "ST GEORGES DE RENEINS", cp : "69830" },
{ code : "69207", intitule : "ST GERMAIN AU MONT D OR", cp : "69650" },
{ code : "69208", intitule : "ST GERMAIN NUELLES", cp : "69210" },
{ code : "69208", intitule : "ST GERMAIN NUELLES", cp : "69210" },
{ code : "69209", intitule : "ST IGNY DE VERS", cp : "69790" },
{ code : "69210", intitule : "ST JACQUES DES ARRETS", cp : "69860" },
{ code : "69211", intitule : "ST JEAN D ARDIERES", cp : "69220" },
{ code : "69212", intitule : "ST JEAN DES VIGNES", cp : "69380" },
{ code : "69213", intitule : "ST JEAN DE TOUSLAS", cp : "69700" },
{ code : "69214", intitule : "ST JEAN LA BUSSIERE", cp : "69550" },
{ code : "69215", intitule : "ST JULIEN", cp : "69640" },
{ code : "69215", intitule : "ST JULIEN", cp : "69640" },
{ code : "69216", intitule : "ST JULIEN SUR BIBOST", cp : "69690" },
{ code : "69217", intitule : "ST JUST D AVRAY", cp : "69870" },
{ code : "69218", intitule : "ST LAGER", cp : "69220" },
{ code : "69219", intitule : "ST LAURENT D AGNY", cp : "69440" },
{ code : "69219", intitule : "ST LAURENT D AGNY", cp : "69440" },
{ code : "69220", intitule : "ST LAURENT DE CHAMOUSSET", cp : "69930" },
{ code : "69222", intitule : "ST LAURENT D OINGT", cp : "69620" },
{ code : "69223", intitule : "ST LOUP", cp : "69490" },
{ code : "69224", intitule : "ST MAMERT", cp : "69860" },
{ code : "69225", intitule : "ST MARCEL L ECLAIRE", cp : "69170" },
{ code : "69227", intitule : "ST MARTIN EN HAUT", cp : "69850" },
{ code : "69227", intitule : "ST MARTIN EN HAUT", cp : "69850" },
{ code : "69228", intitule : "ST MAURICE SUR DARGOIRE", cp : "69440" },
{ code : "69228", intitule : "ST MAURICE SUR DARGOIRE", cp : "69440" },
{ code : "69229", intitule : "ST NIZIER D AZERGUES", cp : "69870" },
{ code : "69230", intitule : "STE PAULE", cp : "69620" },
{ code : "69231", intitule : "ST PIERRE LA PALUD", cp : "69210" },
{ code : "69233", intitule : "ST ROMAIN AU MONT D OR", cp : "69270" },
{ code : "69234", intitule : "ST ROMAIN DE POPEY", cp : "69490" },
{ code : "69235", intitule : "ST ROMAIN EN GAL", cp : "69560" },
{ code : "69235", intitule : "ST ROMAIN EN GAL", cp : "69560" },
{ code : "69236", intitule : "ST ROMAIN EN GIER", cp : "69700" },
{ code : "69237", intitule : "ST SORLIN", cp : "69440" },
{ code : "69238", intitule : "ST SYMPHORIEN SUR COISE", cp : "69590" },
{ code : "69239", intitule : "ST VERAND", cp : "69620" },
{ code : "69240", intitule : "ST VINCENT DE REINS", cp : "69240" },
{ code : "69241", intitule : "TALUYERS", cp : "69440" },
{ code : "69241", intitule : "TALUYERS", cp : "69440" },
{ code : "69242", intitule : "TAPONAS", cp : "69220" },
{ code : "69243", intitule : "TARARE", cp : "69170" },
{ code : "69244", intitule : "TASSIN LA DEMI LUNE", cp : "69160" },
{ code : "69245", intitule : "TERNAND", cp : "69620" },
{ code : "69246", intitule : "THEIZE", cp : "69620" },
{ code : "69248", intitule : "THIZY LES BOURGS", cp : "69240" },
{ code : "69248", intitule : "THIZY LES BOURGS", cp : "69240" },
{ code : "69248", intitule : "THIZY LES BOURGS", cp : "69240" },
{ code : "69248", intitule : "THIZY LES BOURGS", cp : "69240" },
{ code : "69248", intitule : "THIZY LES BOURGS", cp : "69240" },
{ code : "69249", intitule : "THURINS", cp : "69510" },
{ code : "69250", intitule : "LA TOUR DE SALVAGNY", cp : "69890" },
{ code : "69251", intitule : "TRADES", cp : "69860" },
{ code : "69252", intitule : "TREVES", cp : "69420" },
{ code : "69253", intitule : "TUPIN ET SEMONS", cp : "69420" },
{ code : "69254", intitule : "VALSONNE", cp : "69170" },
{ code : "69255", intitule : "VAUGNERAY", cp : "69670" },
{ code : "69255", intitule : "VAUGNERAY", cp : "69670" },
{ code : "69256", intitule : "VAULX EN VELIN", cp : "69120" },
{ code : "69257", intitule : "VAUX EN BEAUJOLAIS", cp : "69460" },
{ code : "69258", intitule : "VAUXRENARD", cp : "69820" },
{ code : "69259", intitule : "VENISSIEUX", cp : "69200" },
{ code : "69260", intitule : "VERNAISON", cp : "69390" },
{ code : "69261", intitule : "VERNAY", cp : "69430" },
{ code : "69263", intitule : "VILLECHENEVE", cp : "69770" },
{ code : "69264", intitule : "VILLEFRANCHE SUR SAONE", cp : "69400" },
{ code : "69265", intitule : "VILLE SUR JARNIOUX", cp : "69640" },
{ code : "69266", intitule : "VILLEURBANNE", cp : "69100" },
{ code : "69267", intitule : "VILLIE MORGON", cp : "69910" },
{ code : "69268", intitule : "VOURLES", cp : "69390" },
{ code : "69268", intitule : "VOURLES", cp : "69390" },
{ code : "69269", intitule : "YZERON", cp : "69510" },
{ code : "69270", intitule : "CHAPONNAY", cp : "69970" },
{ code : "69271", intitule : "CHASSIEU", cp : "69680" },
{ code : "69272", intitule : "COMMUNAY", cp : "69360" },
{ code : "69273", intitule : "CORBAS", cp : "69960" },
{ code : "69275", intitule : "DECINES CHARPIEU", cp : "69150" },
{ code : "69275", intitule : "DECINES CHARPIEU", cp : "69150" },
{ code : "69276", intitule : "FEYZIN", cp : "69320" },
{ code : "69277", intitule : "GENAS", cp : "69740" },
{ code : "69278", intitule : "GENAY", cp : "69730" },
{ code : "69279", intitule : "JONAGE", cp : "69330" },
{ code : "69280", intitule : "JONS", cp : "69330" },
{ code : "69281", intitule : "MARENNES", cp : "69970" },
{ code : "69282", intitule : "MEYZIEU", cp : "69330" },
{ code : "69283", intitule : "MIONS", cp : "69780" },
{ code : "69284", intitule : "MONTANAY", cp : "69250" },
{ code : "69285", intitule : "PUSIGNAN", cp : "69330" },
{ code : "69286", intitule : "RILLIEUX LA PAPE", cp : "69140" },
{ code : "69286", intitule : "RILLIEUX LA PAPE", cp : "69140" },
{ code : "69287", intitule : "ST BONNET DE MURE", cp : "69720" },
{ code : "69288", intitule : "ST LAURENT DE MURE", cp : "69720" },
{ code : "69289", intitule : "ST PIERRE DE CHANDIEU", cp : "69780" },
{ code : "69290", intitule : "ST PRIEST", cp : "69800" },
{ code : "69291", intitule : "ST SYMPHORIEN D OZON", cp : "69360" },
{ code : "69292", intitule : "SATHONAY CAMP", cp : "69580" },
{ code : "69293", intitule : "SATHONAY VILLAGE", cp : "69580" },
{ code : "69294", intitule : "SEREZIN DU RHONE", cp : "69360" },
{ code : "69295", intitule : "SIMANDRES", cp : "69360" },
{ code : "69296", intitule : "SOLAIZE", cp : "69360" },
{ code : "69297", intitule : "TERNAY", cp : "69360" },
{ code : "69298", intitule : "TOUSSIEU", cp : "69780" },
{ code : "69299", intitule : "COLOMBIER SAUGNIEU", cp : "69124" },
{ code : "69299", intitule : "COLOMBIER SAUGNIEU", cp : "69124" },
{ code : "69299", intitule : "COLOMBIER SAUGNIEU", cp : "69125" },
{ code : "69299", intitule : "COLOMBIER SAUGNIEU", cp : "69125" },
{ code : "69381", intitule : "LYON 01", cp : "69001" },
{ code : "69382", intitule : "LYON 02", cp : "69002" },
{ code : "69383", intitule : "LYON 03", cp : "69003" },
{ code : "69384", intitule : "LYON 04", cp : "69004" },
{ code : "69385", intitule : "LYON 05", cp : "69005" },
{ code : "69386", intitule : "LYON 06", cp : "69006" },
{ code : "69387", intitule : "LYON 07", cp : "69007" },
{ code : "69388", intitule : "LYON 08", cp : "69008" },
{ code : "69389", intitule : "LYON 09", cp : "69009" },
{ code : "70001", intitule : "ABELCOURT", cp : "70300" },
{ code : "70002", intitule : "ABONCOURT GESINCOURT", cp : "70500" },
{ code : "70003", intitule : "ACHEY", cp : "70180" },
{ code : "70004", intitule : "ADELANS ET LE VAL DE BITHAINE", cp : "70200" },
{ code : "70005", intitule : "AILLEVANS", cp : "70110" },
{ code : "70006", intitule : "AILLEVILLERS ET LYAUMONT", cp : "70320" },
{ code : "70007", intitule : "AILLONCOURT", cp : "70300" },
{ code : "70008", intitule : "AINVELLE", cp : "70800" },
{ code : "70009", intitule : "AISEY ET RICHECOURT", cp : "70500" },
{ code : "70010", intitule : "ALAINCOURT", cp : "70210" },
{ code : "70011", intitule : "AMAGE", cp : "70280" },
{ code : "70012", intitule : "AMANCE", cp : "70160" },
{ code : "70013", intitule : "AMBIEVILLERS", cp : "70210" },
{ code : "70014", intitule : "AMBLANS ET VELOTTE", cp : "70200" },
{ code : "70015", intitule : "AMONCOURT", cp : "70170" },
{ code : "70016", intitule : "AMONT ET EFFRENEY", cp : "70310" },
{ code : "70017", intitule : "ANCHENONCOURT ET CHAZEL", cp : "70210" },
{ code : "70018", intitule : "ANCIER", cp : "70100" },
{ code : "70019", intitule : "ANDELARRE", cp : "70000" },
{ code : "70020", intitule : "ANDELARROT", cp : "70000" },
{ code : "70021", intitule : "ANDORNAY", cp : "70200" },
{ code : "70022", intitule : "ANGIREY", cp : "70700" },
{ code : "70023", intitule : "ANJEUX", cp : "70800" },
{ code : "70024", intitule : "APREMONT", cp : "70100" },
{ code : "70025", intitule : "ARBECEY", cp : "70120" },
{ code : "70026", intitule : "ARC LES GRAY", cp : "70100" },
{ code : "70027", intitule : "ARGILLIERES", cp : "70600" },
{ code : "70028", intitule : "AROZ", cp : "70360" },
{ code : "70029", intitule : "ARPENANS", cp : "70200" },
{ code : "70030", intitule : "ARSANS", cp : "70100" },
{ code : "70031", intitule : "ATHESANS ETROITEFONTAINE", cp : "70110" },
{ code : "70031", intitule : "ATHESANS ETROITEFONTAINE", cp : "70110" },
{ code : "70032", intitule : "ATTRICOURT", cp : "70100" },
{ code : "70035", intitule : "AUGICOURT", cp : "70500" },
{ code : "70036", intitule : "AULX LES CROMARY", cp : "70190" },
{ code : "70037", intitule : "AUTET", cp : "70180" },
{ code : "70038", intitule : "AUTHOISON", cp : "70190" },
{ code : "70039", intitule : "AUTOREILLE", cp : "70700" },
{ code : "70040", intitule : "AUTREY LES CERRE", cp : "70110" },
{ code : "70041", intitule : "AUTREY LES GRAY", cp : "70100" },
{ code : "70042", intitule : "AUTREY LE VAY", cp : "70110" },
{ code : "70043", intitule : "AUVET ET LA CHAPELOTTE", cp : "70100" },
{ code : "70044", intitule : "AUXON", cp : "70000" },
{ code : "70045", intitule : "AVRIGNEY VIREY", cp : "70150" },
{ code : "70045", intitule : "AVRIGNEY VIREY", cp : "70150" },
{ code : "70046", intitule : "LES AYNANS", cp : "70200" },
{ code : "70047", intitule : "BAIGNES", cp : "70000" },
{ code : "70048", intitule : "BARD LES PESMES", cp : "70140" },
{ code : "70049", intitule : "BARGES", cp : "70500" },
{ code : "70050", intitule : "LA BARRE", cp : "70190" },
{ code : "70051", intitule : "LA BASSE VAIVRE", cp : "70210" },
{ code : "70052", intitule : "BASSIGNEY", cp : "70800" },
{ code : "70053", intitule : "LES BATIES", cp : "70130" },
{ code : "70054", intitule : "BATTRANS", cp : "70100" },
{ code : "70055", intitule : "BAUDONCOURT", cp : "70300" },
{ code : "70056", intitule : "BAULAY", cp : "70160" },
{ code : "70057", intitule : "BAY", cp : "70150" },
{ code : "70058", intitule : "BEAUJEU ET QUITTEUR", cp : "70100" },
{ code : "70058", intitule : "BEAUJEU ET QUITTEUR", cp : "70100" },
{ code : "70059", intitule : "BEAUMOTTE AUBERTANS", cp : "70190" },
{ code : "70059", intitule : "BEAUMOTTE AUBERTANS", cp : "70190" },
{ code : "70060", intitule : "BEAUMOTTE LES PIN", cp : "70150" },
{ code : "70061", intitule : "BELFAHY", cp : "70290" },
{ code : "70062", intitule : "BELMONT", cp : "70270" },
{ code : "70063", intitule : "BELONCHAMP", cp : "70270" },
{ code : "70064", intitule : "BELVERNE", cp : "70400" },
{ code : "70065", intitule : "BESNANS", cp : "70230" },
{ code : "70066", intitule : "BETAUCOURT", cp : "70500" },
{ code : "70067", intitule : "BETONCOURT LES BROTTE", cp : "70300" },
{ code : "70069", intitule : "BETONCOURT ST PANCRAS", cp : "70210" },
{ code : "70070", intitule : "BETONCOURT SUR MANCE", cp : "70500" },
{ code : "70071", intitule : "BEULOTTE ST LAURENT", cp : "70310" },
{ code : "70072", intitule : "BEVEUGE", cp : "70110" },
{ code : "70074", intitule : "BLONDEFONTAINE", cp : "70500" },
{ code : "70075", intitule : "BONBOILLON", cp : "70150" },
{ code : "70076", intitule : "BONNEVENT VELLOREILLE", cp : "70700" },
{ code : "70077", intitule : "BOREY", cp : "70110" },
{ code : "70078", intitule : "BOUGEY", cp : "70500" },
{ code : "70079", intitule : "BOUGNON", cp : "70170" },
{ code : "70080", intitule : "BOUHANS ET FEURG", cp : "70100" },
{ code : "70081", intitule : "BOUHANS LES LURE", cp : "70200" },
{ code : "70082", intitule : "BOUHANS LES MONTBOZON", cp : "70230" },
{ code : "70083", intitule : "BOULIGNEY", cp : "70800" },
{ code : "70084", intitule : "BOULOT", cp : "70190" },
{ code : "70085", intitule : "BOULT", cp : "70190" },
{ code : "70086", intitule : "BOURBEVELLE", cp : "70500" },
{ code : "70087", intitule : "BOURGUIGNON LES CONFLANS", cp : "70800" },
{ code : "70088", intitule : "BOURGUIGNON LES LA CHARITE", cp : "70190" },
{ code : "70089", intitule : "BOURGUIGNON LES MOREY", cp : "70120" },
{ code : "70090", intitule : "BOURSIERES", cp : "70000" },
{ code : "70091", intitule : "BOUSSERAUCOURT", cp : "70500" },
{ code : "70092", intitule : "BRESILLEY", cp : "70140" },
{ code : "70093", intitule : "BREUCHES", cp : "70300" },
{ code : "70094", intitule : "BREUCHOTTE", cp : "70280" },
{ code : "70095", intitule : "BREUREY LES FAVERNEY", cp : "70160" },
{ code : "70096", intitule : "BREVILLIERS", cp : "70400" },
{ code : "70097", intitule : "BRIAUCOURT", cp : "70800" },
{ code : "70098", intitule : "BROTTE LES LUXEUIL", cp : "70300" },
{ code : "70099", intitule : "BROTTE LES RAY", cp : "70180" },
{ code : "70100", intitule : "BROYE LES LOUPS ET VERFONTAINE", cp : "70100" },
{ code : "70101", intitule : "BROYE AUBIGNEY MONTSEUGNY", cp : "70140" },
{ code : "70101", intitule : "BROYE AUBIGNEY MONTSEUGNY", cp : "70140" },
{ code : "70101", intitule : "BROYE AUBIGNEY MONTSEUGNY", cp : "70140" },
{ code : "70102", intitule : "BRUSSEY", cp : "70150" },
{ code : "70103", intitule : "LA BRUYERE", cp : "70280" },
{ code : "70104", intitule : "BUCEY LES GY", cp : "70700" },
{ code : "70105", intitule : "BUCEY LES TRAVES", cp : "70360" },
{ code : "70106", intitule : "BUFFIGNECOURT", cp : "70500" },
{ code : "70107", intitule : "BUSSIERES", cp : "70190" },
{ code : "70109", intitule : "BUTHIERS", cp : "70190" },
{ code : "70111", intitule : "CALMOUTIER", cp : "70240" },
{ code : "70112", intitule : "CEMBOING", cp : "70500" },
{ code : "70113", intitule : "CENANS", cp : "70230" },
{ code : "70114", intitule : "CENDRECOURT", cp : "70500" },
{ code : "70115", intitule : "CERRE LES NOROY", cp : "70000" },
{ code : "70116", intitule : "CHAGEY", cp : "70400" },
{ code : "70117", intitule : "CHALONVILLARS", cp : "70400" },
{ code : "70118", intitule : "CHAMBORNAY LES BELLEVAUX", cp : "70190" },
{ code : "70119", intitule : "CHAMBORNAY LES PIN", cp : "70150" },
{ code : "70120", intitule : "CHAMPAGNEY", cp : "70290" },
{ code : "70121", intitule : "CHAMPEY", cp : "70400" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70122", intitule : "CHAMPLITTE", cp : "70600" },
{ code : "70124", intitule : "CHAMPTONNAY", cp : "70100" },
{ code : "70125", intitule : "CHAMPVANS", cp : "70100" },
{ code : "70126", intitule : "CHANCEY", cp : "70140" },
{ code : "70127", intitule : "CHANTES", cp : "70360" },
{ code : "70128", intitule : "LA CHAPELLE LES LUXEUIL", cp : "70300" },
{ code : "70129", intitule : "LA CHAPELLE ST QUILLAIN", cp : "70700" },
{ code : "70130", intitule : "CHARCENNE", cp : "70700" },
{ code : "70132", intitule : "CHARGEY LES GRAY", cp : "70100" },
{ code : "70133", intitule : "CHARGEY LES PORT", cp : "70170" },
{ code : "70134", intitule : "CHARIEZ", cp : "70000" },
{ code : "70135", intitule : "CHARMES ST VALBERT", cp : "70120" },
{ code : "70136", intitule : "CHARMOILLE", cp : "70000" },
{ code : "70137", intitule : "CHASSEY LES MONTBOZON", cp : "70230" },
{ code : "70138", intitule : "CHASSEY LES SCEY", cp : "70360" },
{ code : "70140", intitule : "CHATENEY", cp : "70240" },
{ code : "70141", intitule : "CHATENOIS", cp : "70240" },
{ code : "70142", intitule : "CHAUMERCENNE", cp : "70140" },
{ code : "70143", intitule : "CHAUVIREY LE CHATEL", cp : "70500" },
{ code : "70144", intitule : "CHAUVIREY LE VIEIL", cp : "70500" },
{ code : "70145", intitule : "CHAUX LA LOTIERE", cp : "70190" },
{ code : "70146", intitule : "CHAUX LES PORT", cp : "70170" },
{ code : "70147", intitule : "CHAVANNE", cp : "70400" },
{ code : "70148", intitule : "CHEMILLY", cp : "70360" },
{ code : "70149", intitule : "CHENEBIER", cp : "70400" },
{ code : "70150", intitule : "CHENEVREY ET MOROGNE", cp : "70150" },
{ code : "70151", intitule : "CHEVIGNEY", cp : "70140" },
{ code : "70152", intitule : "CHOYE", cp : "70700" },
{ code : "70153", intitule : "CINTREY", cp : "70120" },
{ code : "70154", intitule : "CIREY", cp : "70190" },
{ code : "70155", intitule : "CITERS", cp : "70300" },
{ code : "70156", intitule : "CITEY", cp : "70700" },
{ code : "70157", intitule : "CLAIREGOUTTE", cp : "70200" },
{ code : "70158", intitule : "CLANS", cp : "70000" },
{ code : "70159", intitule : "COGNIERES", cp : "70230" },
{ code : "70160", intitule : "COISEVAUX", cp : "70400" },
{ code : "70162", intitule : "COLOMBE LES VESOUL", cp : "70000" },
{ code : "70163", intitule : "COLOMBIER", cp : "70000" },
{ code : "70164", intitule : "COLOMBOTTE", cp : "70240" },
{ code : "70165", intitule : "COMBEAUFONTAINE", cp : "70120" },
{ code : "70166", intitule : "COMBERJON", cp : "70000" },
{ code : "70167", intitule : "CONFLANDEY", cp : "70170" },
{ code : "70168", intitule : "CONFLANS SUR LANTERNE", cp : "70800" },
{ code : "70169", intitule : "CONFRACOURT", cp : "70120" },
{ code : "70170", intitule : "CONTREGLISE", cp : "70160" },
{ code : "70171", intitule : "CORBENAY", cp : "70320" },
{ code : "70172", intitule : "LA CORBIERE", cp : "70300" },
{ code : "70174", intitule : "CORDONNET", cp : "70190" },
{ code : "70175", intitule : "CORNOT", cp : "70120" },
{ code : "70176", intitule : "CORRAVILLERS", cp : "70310" },
{ code : "70177", intitule : "CORRE", cp : "70500" },
{ code : "70178", intitule : "LA COTE", cp : "70200" },
{ code : "70179", intitule : "COULEVON", cp : "70000" },
{ code : "70180", intitule : "COURCHATON", cp : "70110" },
{ code : "70181", intitule : "COURCUIRE", cp : "70150" },
{ code : "70182", intitule : "COURMONT", cp : "70400" },
{ code : "70183", intitule : "COURTESOULT ET GATEY", cp : "70600" },
{ code : "70184", intitule : "COUTHENANS", cp : "70400" },
{ code : "70185", intitule : "CRESANCEY", cp : "70100" },
{ code : "70186", intitule : "LA CREUSE", cp : "70240" },
{ code : "70187", intitule : "CREVANS ET LA CHAPELLE LES GRANGES", cp : "70400" },
{ code : "70188", intitule : "CREVENEY", cp : "70240" },
{ code : "70189", intitule : "CROMARY", cp : "70190" },
{ code : "70190", intitule : "CUBRY LES FAVERNEY", cp : "70160" },
{ code : "70192", intitule : "CUGNEY", cp : "70700" },
{ code : "70193", intitule : "CULT", cp : "70150" },
{ code : "70194", intitule : "CUVE", cp : "70800" },
{ code : "70195", intitule : "DAMBENOIT LES COLOMBE", cp : "70200" },
{ code : "70195", intitule : "DAMBENOIT LES COLOMBE", cp : "70200" },
{ code : "70196", intitule : "DAMPIERRE LES CONFLANS", cp : "70800" },
{ code : "70197", intitule : "DAMPIERRE SUR LINOTTE", cp : "70230" },
{ code : "70197", intitule : "DAMPIERRE SUR LINOTTE", cp : "70230" },
{ code : "70197", intitule : "DAMPIERRE SUR LINOTTE", cp : "70230" },
{ code : "70198", intitule : "DAMPIERRE SUR SALON", cp : "70180" },
{ code : "70199", intitule : "DAMPVALLEY LES COLOMBE", cp : "70000" },
{ code : "70200", intitule : "DAMPVALLEY ST PANCRAS", cp : "70210" },
{ code : "70201", intitule : "DELAIN", cp : "70180" },
{ code : "70202", intitule : "DEMANGEVELLE", cp : "70210" },
{ code : "70203", intitule : "LA DEMIE", cp : "70000" },
{ code : "70204", intitule : "DENEVRE", cp : "70180" },
{ code : "70205", intitule : "ECHAVANNE", cp : "70400" },
{ code : "70206", intitule : "ECHENANS SOUS MONT VAUDOIS", cp : "70400" },
{ code : "70207", intitule : "ECHENOZ LA MELINE", cp : "70000" },
{ code : "70208", intitule : "ECHENOZ LE SEC", cp : "70000" },
{ code : "70210", intitule : "ECROMAGNY", cp : "70270" },
{ code : "70211", intitule : "ECUELLE", cp : "70600" },
{ code : "70213", intitule : "EHUNS", cp : "70300" },
{ code : "70214", intitule : "EQUEVILLEY", cp : "70160" },
{ code : "70215", intitule : "ERREVET", cp : "70400" },
{ code : "70216", intitule : "ESBOZ BREST", cp : "70300" },
{ code : "70217", intitule : "ESMOULIERES", cp : "70310" },
{ code : "70218", intitule : "ESMOULINS", cp : "70100" },
{ code : "70219", intitule : "ESPRELS", cp : "70110" },
{ code : "70220", intitule : "ESSERTENNE ET CECEY", cp : "70100" },
{ code : "70221", intitule : "ETOBON", cp : "70400" },
{ code : "70222", intitule : "ETRELLES ET LA MONTBLEUSE", cp : "70700" },
{ code : "70224", intitule : "ETUZ", cp : "70150" },
{ code : "70225", intitule : "FAHY LES AUTREY", cp : "70100" },
{ code : "70226", intitule : "FALLON", cp : "70110" },
{ code : "70227", intitule : "FAUCOGNEY ET LA MER", cp : "70310" },
{ code : "70228", intitule : "FAVERNEY", cp : "70160" },
{ code : "70228", intitule : "FAVERNEY", cp : "70160" },
{ code : "70229", intitule : "FAYMONT", cp : "70200" },
{ code : "70230", intitule : "FEDRY", cp : "70120" },
{ code : "70231", intitule : "FERRIERES LES RAY", cp : "70130" },
{ code : "70232", intitule : "FERRIERES LES SCEY", cp : "70360" },
{ code : "70233", intitule : "LES FESSEY", cp : "70310" },
{ code : "70234", intitule : "FILAIN", cp : "70230" },
{ code : "70235", intitule : "FLAGY", cp : "70000" },
{ code : "70236", intitule : "FLEUREY LES FAVERNEY", cp : "70160" },
{ code : "70237", intitule : "FLEUREY LES LAVONCOURT", cp : "70120" },
{ code : "70238", intitule : "FLEUREY LES ST LOUP", cp : "70800" },
{ code : "70239", intitule : "FONDREMAND", cp : "70190" },
{ code : "70240", intitule : "FONTAINE LES LUXEUIL", cp : "70800" },
{ code : "70242", intitule : "FONTENOIS LA VILLE", cp : "70210" },
{ code : "70243", intitule : "FONTENOIS LES MONTBOZON", cp : "70230" },
{ code : "70244", intitule : "FOUCHECOURT", cp : "70160" },
{ code : "70245", intitule : "FOUGEROLLES", cp : "70220" },
{ code : "70247", intitule : "FOUVENT ST ANDOCHE", cp : "70600" },
{ code : "70247", intitule : "FOUVENT ST ANDOCHE", cp : "70600" },
{ code : "70247", intitule : "FOUVENT ST ANDOCHE", cp : "70600" },
{ code : "70248", intitule : "FRAHIER ET CHATEBIER", cp : "70400" },
{ code : "70249", intitule : "FRANCALMONT", cp : "70800" },
{ code : "70250", intitule : "FRANCHEVELLE", cp : "70200" },
{ code : "70251", intitule : "FRANCOURT", cp : "70180" },
{ code : "70252", intitule : "FRAMONT", cp : "70600" },
{ code : "70252", intitule : "FRAMONT", cp : "70600" },
{ code : "70253", intitule : "FRASNE LE CHATEAU", cp : "70700" },
{ code : "70254", intitule : "FREDERIC FONTAINE", cp : "70200" },
{ code : "70255", intitule : "FRESNE ST MAMES", cp : "70130" },
{ code : "70256", intitule : "FRESSE", cp : "70270" },
{ code : "70257", intitule : "FRETIGNEY ET VELLOREILLE", cp : "70130" },
{ code : "70258", intitule : "FROIDECONCHE", cp : "70300" },
{ code : "70259", intitule : "FROIDETERRE", cp : "70200" },
{ code : "70260", intitule : "FROTEY LES LURE", cp : "70200" },
{ code : "70261", intitule : "FROTEY LES VESOUL", cp : "70000" },
{ code : "70262", intitule : "GENEVREUILLE", cp : "70240" },
{ code : "70263", intitule : "GENEVREY", cp : "70240" },
{ code : "70264", intitule : "GEORFANS", cp : "70110" },
{ code : "70265", intitule : "GERMIGNEY", cp : "70100" },
{ code : "70267", intitule : "GEVIGNEY ET MERCEY", cp : "70500" },
{ code : "70268", intitule : "GEZIER ET FONTENELAY", cp : "70700" },
{ code : "70269", intitule : "GIREFONTAINE", cp : "70210" },
{ code : "70271", intitule : "GOUHENANS", cp : "70110" },
{ code : "70272", intitule : "GOURGEON", cp : "70120" },
{ code : "70273", intitule : "GRAMMONT", cp : "70110" },
{ code : "70274", intitule : "GRANDECOURT", cp : "70120" },
{ code : "70275", intitule : "GRANDVELLE ET LE PERRENOT", cp : "70190" },
{ code : "70276", intitule : "GRANGES LA VILLE", cp : "70400" },
{ code : "70277", intitule : "GRANGES LE BOURG", cp : "70400" },
{ code : "70278", intitule : "GRATTERY", cp : "70170" },
{ code : "70279", intitule : "GRAY", cp : "70100" },
{ code : "70280", intitule : "GRAY LA VILLE", cp : "70100" },
{ code : "70282", intitule : "GY", cp : "70700" },
{ code : "70283", intitule : "HAUT DU THEM CHATEAU LAMBERT", cp : "70440" },
{ code : "70283", intitule : "HAUT DU THEM CHATEAU LAMBERT", cp : "70440" },
{ code : "70284", intitule : "HAUTEVELLE", cp : "70800" },
{ code : "70285", intitule : "HERICOURT", cp : "70400" },
{ code : "70285", intitule : "HERICOURT", cp : "70400" },
{ code : "70285", intitule : "HERICOURT", cp : "70400" },
{ code : "70286", intitule : "HUGIER", cp : "70150" },
{ code : "70287", intitule : "HURECOURT", cp : "70210" },
{ code : "70288", intitule : "HYET", cp : "70190" },
{ code : "70289", intitule : "IGNY", cp : "70700" },
{ code : "70290", intitule : "JASNEY", cp : "70800" },
{ code : "70291", intitule : "JONVELLE", cp : "70500" },
{ code : "70292", intitule : "JUSSEY", cp : "70500" },
{ code : "70292", intitule : "JUSSEY", cp : "70500" },
{ code : "70293", intitule : "LAMBREY", cp : "70500" },
{ code : "70294", intitule : "LANTENOT", cp : "70200" },
{ code : "70295", intitule : "LA LANTERNE ET LES ARMONTS", cp : "70270" },
{ code : "70296", intitule : "LARIANS ET MUNANS", cp : "70230" },
{ code : "70297", intitule : "LARRET", cp : "70600" },
{ code : "70298", intitule : "LAVIGNEY", cp : "70120" },
{ code : "70299", intitule : "LAVONCOURT", cp : "70120" },
{ code : "70301", intitule : "LIEFFRANS", cp : "70190" },
{ code : "70302", intitule : "LIEUCOURT", cp : "70140" },
{ code : "70303", intitule : "LIEVANS", cp : "70240" },
{ code : "70304", intitule : "LINEXERT", cp : "70200" },
{ code : "70305", intitule : "LOEUILLEY", cp : "70100" },
{ code : "70306", intitule : "LOMONT", cp : "70200" },
{ code : "70307", intitule : "LONGEVELLE", cp : "70110" },
{ code : "70308", intitule : "LA LONGINE", cp : "70310" },
{ code : "70309", intitule : "LOULANS VERCHAMP", cp : "70230" },
{ code : "70309", intitule : "LOULANS VERCHAMP", cp : "70230" },
{ code : "70310", intitule : "LURE", cp : "70200" },
{ code : "70311", intitule : "LUXEUIL LES BAINS", cp : "70300" },
{ code : "70312", intitule : "LUZE", cp : "70400" },
{ code : "70313", intitule : "LYOFFANS", cp : "70200" },
{ code : "70314", intitule : "MAGNIVRAY", cp : "70300" },
{ code : "70315", intitule : "MAGNONCOURT", cp : "70800" },
{ code : "70316", intitule : "LE MAGNORAY", cp : "70000" },
{ code : "70317", intitule : "LES MAGNY", cp : "70110" },
{ code : "70318", intitule : "MAGNY DANIGON", cp : "70200" },
{ code : "70319", intitule : "MAGNY JOBERT", cp : "70200" },
{ code : "70320", intitule : "MAGNY LES JUSSEY", cp : "70500" },
{ code : "70321", intitule : "MAGNY VERNOIS", cp : "70200" },
{ code : "70322", intitule : "MAILLERONCOURT CHARETTE", cp : "70240" },
{ code : "70323", intitule : "MAILLERONCOURT ST PANCRAS", cp : "70210" },
{ code : "70324", intitule : "MAILLEY ET CHAZELOT", cp : "70000" },
{ code : "70325", intitule : "MAIZIERES", cp : "70190" },
{ code : "70326", intitule : "LA MALACHERE", cp : "70190" },
{ code : "70327", intitule : "MALANS", cp : "70140" },
{ code : "70328", intitule : "MALBOUHANS", cp : "70200" },
{ code : "70329", intitule : "MALVILLERS", cp : "70120" },
{ code : "70330", intitule : "MANDREVILLARS", cp : "70400" },
{ code : "70331", intitule : "MANTOCHE", cp : "70100" },
{ code : "70332", intitule : "MARAST", cp : "70110" },
{ code : "70334", intitule : "MARNAY", cp : "70150" },
{ code : "70335", intitule : "MAUSSANS", cp : "70230" },
{ code : "70336", intitule : "MELECEY", cp : "70110" },
{ code : "70337", intitule : "MELIN", cp : "70120" },
{ code : "70338", intitule : "MELINCOURT", cp : "70210" },
{ code : "70339", intitule : "MELISEY", cp : "70270" },
{ code : "70340", intitule : "MEMBREY", cp : "70180" },
{ code : "70341", intitule : "MENOUX", cp : "70160" },
{ code : "70342", intitule : "MERCEY SUR SAONE", cp : "70130" },
{ code : "70343", intitule : "MERSUAY", cp : "70160" },
{ code : "70344", intitule : "MEURCOURT", cp : "70300" },
{ code : "70345", intitule : "MIELLIN", cp : "70440" },
{ code : "70347", intitule : "MIGNAVILLERS", cp : "70400" },
{ code : "70348", intitule : "MOFFANS ET VACHERESSE", cp : "70200" },
{ code : "70349", intitule : "MOIMAY", cp : "70110" },
{ code : "70350", intitule : "MOLAY", cp : "70120" },
{ code : "70351", intitule : "MOLLANS", cp : "70240" },
{ code : "70352", intitule : "LA MONTAGNE", cp : "70310" },
{ code : "70353", intitule : "MONTAGNEY", cp : "70140" },
{ code : "70355", intitule : "MONTARLOT LES RIOZ", cp : "70190" },
{ code : "70356", intitule : "MONTBOILLON", cp : "70700" },
{ code : "70357", intitule : "MONTBOZON", cp : "70230" },
{ code : "70358", intitule : "MONTCEY", cp : "70000" },
{ code : "70359", intitule : "MONTCOURT", cp : "70500" },
{ code : "70360", intitule : "MONTDORE", cp : "70210" },
{ code : "70361", intitule : "MONTESSAUX", cp : "70270" },
{ code : "70362", intitule : "MONTIGNY LES CHERLIEU", cp : "70500" },
{ code : "70363", intitule : "MONTIGNY LES VESOUL", cp : "70000" },
{ code : "70364", intitule : "MONTJUSTIN ET VELOTTE", cp : "70110" },
{ code : "70366", intitule : "VILLERS CHEMIN ET MONT LES ETRELLES", cp : "70700" },
{ code : "70367", intitule : "MONT LE VERNOIS", cp : "70000" },
{ code : "70368", intitule : "MONTOT", cp : "70180" },
{ code : "70369", intitule : "MONT ST LEGER", cp : "70120" },
{ code : "70371", intitule : "MONTUREUX ET PRANTIGNY", cp : "70100" },
{ code : "70372", intitule : "MONTUREUX LES BAULAY", cp : "70500" },
{ code : "70373", intitule : "LA ROCHE MOREY", cp : "70120" },
{ code : "70373", intitule : "LA ROCHE MOREY", cp : "70120" },
{ code : "70373", intitule : "LA ROCHE MOREY", cp : "70120" },
{ code : "70373", intitule : "LA ROCHE MOREY", cp : "70120" },
{ code : "70374", intitule : "MOTEY BESUCHE", cp : "70140" },
{ code : "70375", intitule : "MOTEY SUR SAONE", cp : "70130" },
{ code : "70376", intitule : "NANTILLY", cp : "70100" },
{ code : "70378", intitule : "NAVENNE", cp : "70000" },
{ code : "70380", intitule : "NEUREY EN VAUX", cp : "70160" },
{ code : "70381", intitule : "NEUREY LES LA DEMIE", cp : "70000" },
{ code : "70383", intitule : "NEUVELLE LES CROMARY", cp : "70190" },
{ code : "70384", intitule : "NEUVELLE LES LA CHARITE", cp : "70130" },
{ code : "70385", intitule : "LA NEUVELLE LES LURE", cp : "70200" },
{ code : "70386", intitule : "LA NEUVELLE LES SCEY", cp : "70360" },
{ code : "70387", intitule : "NOIDANS LE FERROUX", cp : "70130" },
{ code : "70388", intitule : "NOIDANS LES VESOUL", cp : "70000" },
{ code : "70389", intitule : "NOIRON", cp : "70100" },
{ code : "70390", intitule : "NOROY LE BOURG", cp : "70000" },
{ code : "70392", intitule : "OIGNEY", cp : "70120" },
{ code : "70393", intitule : "OISELAY ET GRACHAUX", cp : "70700" },
{ code : "70394", intitule : "ONAY", cp : "70100" },
{ code : "70395", intitule : "OPPENANS", cp : "70110" },
{ code : "70396", intitule : "ORICOURT", cp : "70110" },
{ code : "70397", intitule : "ORMENANS", cp : "70230" },
{ code : "70398", intitule : "ORMOICHE", cp : "70300" },
{ code : "70399", intitule : "ORMOY", cp : "70500" },
{ code : "70400", intitule : "OUGE", cp : "70500" },
{ code : "70401", intitule : "OVANCHES", cp : "70360" },
{ code : "70402", intitule : "OYRIERES", cp : "70600" },
{ code : "70403", intitule : "PALANTE", cp : "70200" },
{ code : "70404", intitule : "PASSAVANT LA ROCHERE", cp : "70210" },
{ code : "70405", intitule : "PENNESIERES", cp : "70190" },
{ code : "70406", intitule : "PERCEY LE GRAND", cp : "70600" },
{ code : "70407", intitule : "PERROUSE", cp : "70190" },
{ code : "70408", intitule : "PESMES", cp : "70140" },
{ code : "70409", intitule : "PIERRECOURT", cp : "70600" },
{ code : "70410", intitule : "PIN", cp : "70150" },
{ code : "70411", intitule : "LA PISSEURE", cp : "70800" },
{ code : "70412", intitule : "PLAINEMONT", cp : "70800" },
{ code : "70413", intitule : "PLANCHER BAS", cp : "70290" },
{ code : "70414", intitule : "PLANCHER LES MINES", cp : "70290" },
{ code : "70415", intitule : "POLAINCOURT ET CLAIREFONTAINE", cp : "70210" },
{ code : "70416", intitule : "POMOY", cp : "70240" },
{ code : "70417", intitule : "PONTCEY", cp : "70360" },
{ code : "70418", intitule : "LA ROMAINE", cp : "70130" },
{ code : "70418", intitule : "LA ROMAINE", cp : "70130" },
{ code : "70418", intitule : "LA ROMAINE", cp : "70130" },
{ code : "70419", intitule : "PONT DU BOIS", cp : "70210" },
{ code : "70420", intitule : "PONT SUR L OGNON", cp : "70110" },
{ code : "70421", intitule : "PORT SUR SAONE", cp : "70170" },
{ code : "70422", intitule : "POYANS", cp : "70100" },
{ code : "70423", intitule : "PREIGNEY", cp : "70120" },
{ code : "70425", intitule : "LA PROISELIERE ET LANGLE", cp : "70310" },
{ code : "70426", intitule : "PROVENCHERE", cp : "70170" },
{ code : "70427", intitule : "PURGEROT", cp : "70160" },
{ code : "70428", intitule : "PUSEY", cp : "70000" },
{ code : "70429", intitule : "PUSY ET EPENOUX", cp : "70000" },
{ code : "70430", intitule : "LA QUARTE", cp : "70120" },
{ code : "70431", intitule : "QUENOCHE", cp : "70190" },
{ code : "70432", intitule : "QUERS", cp : "70200" },
{ code : "70433", intitule : "QUINCEY", cp : "70000" },
{ code : "70435", intitule : "RADDON ET CHAPENDU", cp : "70280" },
{ code : "70436", intitule : "RAINCOURT", cp : "70500" },
{ code : "70437", intitule : "RANZEVELLE", cp : "70500" },
{ code : "70438", intitule : "RAY SUR SAONE", cp : "70130" },
{ code : "70439", intitule : "RAZE", cp : "70000" },
{ code : "70440", intitule : "RECOLOGNE", cp : "70130" },
{ code : "70441", intitule : "RECOLOGNE LES RIOZ", cp : "70190" },
{ code : "70442", intitule : "RENAUCOURT", cp : "70120" },
{ code : "70443", intitule : "LA GRANDE RESIE", cp : "70140" },
{ code : "70444", intitule : "LA RESIE ST MARTIN", cp : "70140" },
{ code : "70445", intitule : "RIGNOVELLE", cp : "70200" },
{ code : "70446", intitule : "RIGNY", cp : "70100" },
{ code : "70447", intitule : "RIOZ", cp : "70190" },
{ code : "70447", intitule : "RIOZ", cp : "70190" },
{ code : "70448", intitule : "ROCHE ET RAUCOURT", cp : "70180" },
{ code : "70449", intitule : "ROCHE LINOTTE ET SORANS CORDIERS", cp : "70230" },
{ code : "70450", intitule : "LA ROCHELLE", cp : "70120" },
{ code : "70451", intitule : "RONCHAMP", cp : "70250" },
{ code : "70452", intitule : "ROSEY", cp : "70000" },
{ code : "70453", intitule : "LA ROSIERE", cp : "70310" },
{ code : "70454", intitule : "ROSIERES SUR MANCE", cp : "70500" },
{ code : "70455", intitule : "ROYE", cp : "70200" },
{ code : "70456", intitule : "RUHANS", cp : "70190" },
{ code : "70457", intitule : "RUPT SUR SAONE", cp : "70360" },
{ code : "70459", intitule : "ST BARTHELEMY", cp : "70270" },
{ code : "70460", intitule : "ST BRESSON", cp : "70280" },
{ code : "70461", intitule : "ST BROING", cp : "70100" },
{ code : "70462", intitule : "ST FERJEUX", cp : "70110" },
{ code : "70463", intitule : "ST GAND", cp : "70130" },
{ code : "70464", intitule : "ST GERMAIN", cp : "70200" },
{ code : "70466", intitule : "ST LOUP NANTOUARD", cp : "70100" },
{ code : "70466", intitule : "ST LOUP NANTOUARD", cp : "70100" },
{ code : "70467", intitule : "ST LOUP SUR SEMOUSE", cp : "70800" },
{ code : "70468", intitule : "ST MARCEL", cp : "70500" },
{ code : "70469", intitule : "STE MARIE EN CHANOIS", cp : "70310" },
{ code : "70470", intitule : "STE MARIE EN CHAUX", cp : "70300" },
{ code : "70471", intitule : "STE REINE", cp : "70700" },
{ code : "70472", intitule : "ST REMY", cp : "70160" },
{ code : "70473", intitule : "ST SAUVEUR", cp : "70300" },
{ code : "70474", intitule : "ST SULPICE", cp : "70110" },
{ code : "70475", intitule : "ST VALBERT", cp : "70300" },
{ code : "70476", intitule : "SAPONCOURT", cp : "70210" },
{ code : "70477", intitule : "SAULNOT", cp : "70400" },
{ code : "70477", intitule : "SAULNOT", cp : "70400" },
{ code : "70477", intitule : "SAULNOT", cp : "70400" },
{ code : "70478", intitule : "SAULX", cp : "70240" },
{ code : "70479", intitule : "SAUVIGNEY LES GRAY", cp : "70100" },
{ code : "70480", intitule : "SAUVIGNEY LES PESMES", cp : "70140" },
{ code : "70481", intitule : "SAVOYEUX", cp : "70130" },
{ code : "70482", intitule : "SCEY SUR SAONE ET ST ALBIN", cp : "70360" },
{ code : "70483", intitule : "SCYE", cp : "70170" },
{ code : "70484", intitule : "SECENANS", cp : "70400" },
{ code : "70485", intitule : "SELLES", cp : "70210" },
{ code : "70486", intitule : "SEMMADON", cp : "70120" },
{ code : "70487", intitule : "SENARGENT MIGNAFANS", cp : "70110" },
{ code : "70487", intitule : "SENARGENT MIGNAFANS", cp : "70110" },
{ code : "70488", intitule : "SENONCOURT", cp : "70160" },
{ code : "70489", intitule : "SERVANCE", cp : "70440" },
{ code : "70490", intitule : "SERVIGNEY", cp : "70240" },
{ code : "70491", intitule : "SEVEUX", cp : "70130" },
{ code : "70492", intitule : "SOING CUBRY CHARENTENAY", cp : "70130" },
{ code : "70492", intitule : "SOING CUBRY CHARENTENAY", cp : "70130" },
{ code : "70492", intitule : "SOING CUBRY CHARENTENAY", cp : "70130" },
{ code : "70493", intitule : "SORANS LES BREUREY", cp : "70190" },
{ code : "70494", intitule : "SORNAY", cp : "70150" },
{ code : "70496", intitule : "TARTECOURT", cp : "70500" },
{ code : "70497", intitule : "TAVEY", cp : "70400" },
{ code : "70498", intitule : "TERNUAY MELAY ET ST HILAIRE", cp : "70270" },
{ code : "70499", intitule : "THEULEY", cp : "70120" },
{ code : "70500", intitule : "THIEFFRANS", cp : "70230" },
{ code : "70501", intitule : "THIENANS", cp : "70230" },
{ code : "70502", intitule : "TINCEY ET PONTREBEAU", cp : "70120" },
{ code : "70503", intitule : "TRAITIEFONTAINE", cp : "70190" },
{ code : "70504", intitule : "TRAVES", cp : "70360" },
{ code : "70505", intitule : "LE TREMBLOIS", cp : "70100" },
{ code : "70506", intitule : "TREMOINS", cp : "70400" },
{ code : "70507", intitule : "TRESILLEY", cp : "70190" },
{ code : "70509", intitule : "TROMAREY", cp : "70150" },
{ code : "70510", intitule : "VADANS", cp : "70140" },
{ code : "70511", intitule : "VAITE", cp : "70180" },
{ code : "70512", intitule : "LA VAIVRE", cp : "70320" },
{ code : "70513", intitule : "VAIVRE ET MONTOILLE", cp : "70000" },
{ code : "70514", intitule : "VALAY", cp : "70140" },
{ code : "70515", intitule : "LE VAL DE GOUHENANS", cp : "70200" },
{ code : "70516", intitule : "VALLEROIS LE BOIS", cp : "70000" },
{ code : "70517", intitule : "VALLEROIS LORIOZ", cp : "70000" },
{ code : "70518", intitule : "LE VAL ST ELOI", cp : "70160" },
{ code : "70519", intitule : "VANDELANS", cp : "70190" },
{ code : "70520", intitule : "VANNE", cp : "70130" },
{ code : "70521", intitule : "VANTOUX ET LONGEVELLE", cp : "70700" },
{ code : "70522", intitule : "VAROGNE", cp : "70240" },
{ code : "70523", intitule : "VARS", cp : "70600" },
{ code : "70524", intitule : "VAUCHOUX", cp : "70170" },
{ code : "70525", intitule : "VAUCONCOURT NERVEZAIN", cp : "70120" },
{ code : "70526", intitule : "VAUVILLERS", cp : "70210" },
{ code : "70527", intitule : "VAUX LE MONCELOT", cp : "70700" },
{ code : "70528", intitule : "VELESMES ECHEVANNE", cp : "70100" },
{ code : "70528", intitule : "VELESMES ECHEVANNE", cp : "70100" },
{ code : "70529", intitule : "VELET", cp : "70100" },
{ code : "70530", intitule : "VELLECHEVREUX ET COURBENANS", cp : "70110" },
{ code : "70531", intitule : "VELLECLAIRE", cp : "70700" },
{ code : "70532", intitule : "VELLEFAUX", cp : "70000" },
{ code : "70533", intitule : "VELLEFREY ET VELLEFRANGE", cp : "70700" },
{ code : "70534", intitule : "VELLEFRIE", cp : "70240" },
{ code : "70535", intitule : "VELLEGUINDRY ET LEVRECEY", cp : "70000" },
{ code : "70536", intitule : "VELLE LE CHATEL", cp : "70000" },
{ code : "70537", intitule : "VELLEMINFROY", cp : "70240" },
{ code : "70538", intitule : "VELLEMOZ", cp : "70700" },
{ code : "70539", intitule : "VELLEXON QUEUTREY ET VAUDEY", cp : "70130" },
{ code : "70540", intitule : "VELLOREILLE LES CHOYE", cp : "70700" },
{ code : "70541", intitule : "VELORCEY", cp : "70300" },
{ code : "70542", intitule : "VENERE", cp : "70100" },
{ code : "70544", intitule : "LA VERGENNE", cp : "70200" },
{ code : "70545", intitule : "VENISEY", cp : "70500" },
{ code : "70546", intitule : "VEREUX", cp : "70180" },
{ code : "70547", intitule : "VERLANS", cp : "70400" },
{ code : "70548", intitule : "VERNOIS SUR MANCE", cp : "70500" },
{ code : "70549", intitule : "LA VERNOTTE", cp : "70130" },
{ code : "70550", intitule : "VESOUL", cp : "70000" },
{ code : "70552", intitule : "VILLAFANS", cp : "70110" },
{ code : "70553", intitule : "VILLARGENT", cp : "70110" },
{ code : "70554", intitule : "VILLARS LE PAUTEL", cp : "70500" },
{ code : "70555", intitule : "LA VILLEDIEU EN FONTENETTE", cp : "70160" },
{ code : "70557", intitule : "VILLEFRANCON", cp : "70700" },
{ code : "70558", intitule : "LA VILLENEUVE BELLENOYE LA MAIZE", cp : "70240" },
{ code : "70559", intitule : "VILLEPAROIS", cp : "70000" },
{ code : "70560", intitule : "VILLERS BOUTON", cp : "70190" },
{ code : "70561", intitule : "VILLERSEXEL", cp : "70110" },
{ code : "70562", intitule : "VILLERS LA VILLE", cp : "70110" },
{ code : "70563", intitule : "VILLERS LE SEC", cp : "70000" },
{ code : "70564", intitule : "VILLERS LES LUXEUIL", cp : "70300" },
{ code : "70565", intitule : "VILLERS PATER", cp : "70190" },
{ code : "70566", intitule : "VILLERS SUR PORT", cp : "70170" },
{ code : "70567", intitule : "VILLERS SUR SAULNOT", cp : "70400" },
{ code : "70568", intitule : "VILLERS VAUDEY", cp : "70120" },
{ code : "70569", intitule : "VILORY", cp : "70240" },
{ code : "70571", intitule : "VISONCOURT", cp : "70300" },
{ code : "70572", intitule : "VITREY SUR MANCE", cp : "70500" },
{ code : "70573", intitule : "LA VOIVRE", cp : "70310" },
{ code : "70574", intitule : "VOLON", cp : "70180" },
{ code : "70575", intitule : "VORAY SUR L OGNON", cp : "70190" },
{ code : "70576", intitule : "VOUGECOURT", cp : "70500" },
{ code : "70577", intitule : "VOUHENANS", cp : "70200" },
{ code : "70578", intitule : "VREGILLE", cp : "70150" },
{ code : "70579", intitule : "VYANS LE VAL", cp : "70400" },
{ code : "70580", intitule : "VY LE FERROUX", cp : "70130" },
{ code : "70581", intitule : "VY LES LURE", cp : "70200" },
{ code : "70582", intitule : "VY LES RUPT", cp : "70120" },
{ code : "70583", intitule : "VY LES FILAIN", cp : "70230" },
{ code : "71001", intitule : "L ABERGEMENT DE CUISERY", cp : "71290" },
{ code : "71002", intitule : "L ABERGEMENT STE COLOMBE", cp : "71370" },
{ code : "71003", intitule : "ALLEREY SUR SAONE", cp : "71350" },
{ code : "71004", intitule : "ALLERIOT", cp : "71380" },
{ code : "71005", intitule : "ALUZE", cp : "71510" },
{ code : "71006", intitule : "AMANZE", cp : "71800" },
{ code : "71007", intitule : "AMEUGNY", cp : "71460" },
{ code : "71008", intitule : "ANGLURE SOUS DUN", cp : "71170" },
{ code : "71009", intitule : "ANOST", cp : "71550" },
{ code : "71010", intitule : "ANTULLY", cp : "71400" },
{ code : "71011", intitule : "ANZY LE DUC", cp : "71110" },
{ code : "71012", intitule : "ARTAIX", cp : "71110" },
{ code : "71013", intitule : "AUTHUMES", cp : "71270" },
{ code : "71014", intitule : "AUTUN", cp : "71400" },
{ code : "71014", intitule : "AUTUN", cp : "71400" },
{ code : "71015", intitule : "AUXY", cp : "71400" },
{ code : "71016", intitule : "AZE", cp : "71260" },
{ code : "71017", intitule : "BALLORE", cp : "71220" },
{ code : "71018", intitule : "BANTANGES", cp : "71500" },
{ code : "71019", intitule : "BARIZEY", cp : "71640" },
{ code : "71020", intitule : "BARNAY", cp : "71540" },
{ code : "71021", intitule : "BARON", cp : "71120" },
{ code : "71022", intitule : "BAUDEMONT", cp : "71800" },
{ code : "71023", intitule : "BAUDRIERES", cp : "71370" },
{ code : "71024", intitule : "BAUGY", cp : "71110" },
{ code : "71025", intitule : "BEAUBERY", cp : "71220" },
{ code : "71026", intitule : "BEAUMONT SUR GROSNE", cp : "71240" },
{ code : "71027", intitule : "BEAUREPAIRE EN BRESSE", cp : "71580" },
{ code : "71028", intitule : "BEAUVERNOIS", cp : "71270" },
{ code : "71029", intitule : "BELLEVESVRE", cp : "71270" },
{ code : "71030", intitule : "BERGESSERIN", cp : "71250" },
{ code : "71030", intitule : "BERGESSERIN", cp : "71250" },
{ code : "71031", intitule : "BERZE LE CHATEL", cp : "71960" },
{ code : "71032", intitule : "BERZE LA VILLE", cp : "71960" },
{ code : "71033", intitule : "BEY", cp : "71620" },
{ code : "71034", intitule : "BISSEY SOUS CRUCHAUD", cp : "71390" },
{ code : "71035", intitule : "BISSY LA MACONNAISE", cp : "71260" },
{ code : "71036", intitule : "BISSY SOUS UXELLES", cp : "71460" },
{ code : "71037", intitule : "BISSY SUR FLEY", cp : "71460" },
{ code : "71038", intitule : "LES BIZOTS", cp : "71710" },
{ code : "71039", intitule : "BLANOT", cp : "71250" },
{ code : "71040", intitule : "BLANZY", cp : "71450" },
{ code : "71041", intitule : "BOIS STE MARIE", cp : "71800" },
{ code : "71042", intitule : "BONNAY", cp : "71460" },
{ code : "71043", intitule : "LES BORDES", cp : "71350" },
{ code : "71044", intitule : "BOSJEAN", cp : "71330" },
{ code : "71045", intitule : "BOUHANS", cp : "71330" },
{ code : "71046", intitule : "LA BOULAYE", cp : "71320" },
{ code : "71047", intitule : "BOURBON LANCY", cp : "71140" },
{ code : "71048", intitule : "BOURG LE COMTE", cp : "71110" },
{ code : "71050", intitule : "BOURGVILAIN", cp : "71520" },
{ code : "71051", intitule : "BOUZERON", cp : "71150" },
{ code : "71052", intitule : "BOYER", cp : "71700" },
{ code : "71054", intitule : "BRAGNY SUR SAONE", cp : "71350" },
{ code : "71055", intitule : "BRANDON", cp : "71520" },
{ code : "71056", intitule : "BRANGES", cp : "71500" },
{ code : "71057", intitule : "BRAY", cp : "71250" },
{ code : "71058", intitule : "BRESSE SUR GROSNE", cp : "71460" },
{ code : "71059", intitule : "LE BREUIL", cp : "71670" },
{ code : "71060", intitule : "BRIANT", cp : "71110" },
{ code : "71061", intitule : "BRIENNE", cp : "71290" },
{ code : "71062", intitule : "BRION", cp : "71190" },
{ code : "71063", intitule : "BROYE", cp : "71190" },
{ code : "71064", intitule : "BRUAILLES", cp : "71500" },
{ code : "71065", intitule : "BUFFIERES", cp : "71250" },
{ code : "71066", intitule : "BURGY", cp : "71260" },
{ code : "71067", intitule : "BURNAND", cp : "71460" },
{ code : "71068", intitule : "BURZY", cp : "71460" },
{ code : "71069", intitule : "BUSSIERES", cp : "71960" },
{ code : "71070", intitule : "BUXY", cp : "71390" },
{ code : "71071", intitule : "CERON", cp : "71110" },
{ code : "71072", intitule : "CERSOT", cp : "71390" },
{ code : "71073", intitule : "CHAGNY", cp : "71150" },
{ code : "71074", intitule : "CHAINTRE", cp : "71570" },
{ code : "71075", intitule : "CHALMOUX", cp : "71140" },
{ code : "71076", intitule : "CHALON SUR SAONE", cp : "71100" },
{ code : "71077", intitule : "CHAMBILLY", cp : "71110" },
{ code : "71078", intitule : "CHAMILLY", cp : "71510" },
{ code : "71079", intitule : "CHAMPAGNAT", cp : "71480" },
{ code : "71080", intitule : "CHAMPAGNY SOUS UXELLES", cp : "71460" },
{ code : "71081", intitule : "CHAMPFORGEUIL", cp : "71530" },
{ code : "71082", intitule : "CHAMPLECY", cp : "71120" },
{ code : "71084", intitule : "CHANES", cp : "71570" },
{ code : "71085", intitule : "CHANGE", cp : "21340" },
{ code : "71086", intitule : "CHANGY", cp : "71120" },
{ code : "71087", intitule : "CHAPAIZE", cp : "71460" },
{ code : "71088", intitule : "LA CHAPELLE AU MANS", cp : "71130" },
{ code : "71089", intitule : "LA CHAPELLE DE BRAGNY", cp : "71240" },
{ code : "71090", intitule : "LA CHAPELLE DE GUINCHAY", cp : "71570" },
{ code : "71090", intitule : "LA CHAPELLE DE GUINCHAY", cp : "71570" },
{ code : "71090", intitule : "LA CHAPELLE DE GUINCHAY", cp : "71570" },
{ code : "71091", intitule : "LA CHAPELLE DU MONT DE FRANCE", cp : "71520" },
{ code : "71092", intitule : "LA CHAPELLE NAUDE", cp : "71500" },
{ code : "71093", intitule : "LA CHAPELLE ST SAUVEUR", cp : "71310" },
{ code : "71094", intitule : "LA CHAPELLE SOUS BRANCION", cp : "71700" },
{ code : "71095", intitule : "LA CHAPELLE SOUS DUN", cp : "71800" },
{ code : "71096", intitule : "LA CHAPELLE SOUS UCHON", cp : "71190" },
{ code : "71097", intitule : "LA CHAPELLE THECLE", cp : "71470" },
{ code : "71098", intitule : "CHARBONNAT", cp : "71320" },
{ code : "71099", intitule : "CHARBONNIERES", cp : "71260" },
{ code : "71100", intitule : "CHARDONNAY", cp : "71700" },
{ code : "71101", intitule : "CHARETTE VARENNES", cp : "71270" },
{ code : "71101", intitule : "CHARETTE VARENNES", cp : "71270" },
{ code : "71102", intitule : "LA CHARMEE", cp : "71100" },
{ code : "71103", intitule : "CHARMOY", cp : "71710" },
{ code : "71104", intitule : "CHARNAY LES CHALON", cp : "71350" },
{ code : "71105", intitule : "CHARNAY LES MACON", cp : "71850" },
{ code : "71106", intitule : "CHAROLLES", cp : "71120" },
{ code : "71107", intitule : "CHARRECEY", cp : "71510" },
{ code : "71108", intitule : "CHASSELAS", cp : "71570" },
{ code : "71109", intitule : "CHASSEY LE CAMP", cp : "71150" },
{ code : "71110", intitule : "CHASSIGNY SOUS DUN", cp : "71170" },
{ code : "71111", intitule : "CHASSY", cp : "71130" },
{ code : "71112", intitule : "CHATEAU", cp : "71250" },
{ code : "71113", intitule : "CHATEAUNEUF", cp : "71740" },
{ code : "71115", intitule : "CHATEL MORON", cp : "71510" },
{ code : "71116", intitule : "CHATENAY", cp : "71800" },
{ code : "71117", intitule : "CHATENOY EN BRESSE", cp : "71380" },
{ code : "71118", intitule : "CHATENOY LE ROYAL", cp : "71880" },
{ code : "71119", intitule : "CHAUDENAY", cp : "71150" },
{ code : "71120", intitule : "CHAUFFAILLES", cp : "71170" },
{ code : "71121", intitule : "LA CHAUX", cp : "71310" },
{ code : "71122", intitule : "CHEILLY LES MARANGES", cp : "71150" },
{ code : "71123", intitule : "CHENAY LE CHATEL", cp : "71340" },
{ code : "71124", intitule : "CHENOVES", cp : "71390" },
{ code : "71125", intitule : "CHERIZET", cp : "71250" },
{ code : "71126", intitule : "CHEVAGNY LES CHEVRIERES", cp : "71960" },
{ code : "71127", intitule : "CHEVAGNY SUR GUYE", cp : "71220" },
{ code : "71128", intitule : "CHIDDES", cp : "71220" },
{ code : "71129", intitule : "CHISSEY EN MORVAN", cp : "71540" },
{ code : "71130", intitule : "CHISSEY LES MACON", cp : "71460" },
{ code : "71131", intitule : "CIEL", cp : "71350" },
{ code : "71132", intitule : "CIRY LE NOBLE", cp : "71420" },
{ code : "71133", intitule : "LA CLAYETTE", cp : "71800" },
{ code : "71134", intitule : "CLERMAIN", cp : "71520" },
{ code : "71135", intitule : "CLESSE", cp : "71260" },
{ code : "71136", intitule : "CLESSY", cp : "71130" },
{ code : "71137", intitule : "CLUNY", cp : "71250" },
{ code : "71139", intitule : "COLLONGE EN CHAROLLAIS", cp : "71460" },
{ code : "71140", intitule : "COLLONGE LA MADELEINE", cp : "71360" },
{ code : "71141", intitule : "COLOMBIER EN BRIONNAIS", cp : "71800" },
{ code : "71142", intitule : "LA COMELLE", cp : "71990" },
{ code : "71143", intitule : "CONDAL", cp : "71480" },
{ code : "71144", intitule : "CORDESSE", cp : "71540" },
{ code : "71145", intitule : "CORMATIN", cp : "71460" },
{ code : "71146", intitule : "CORTAMBERT", cp : "71250" },
{ code : "71147", intitule : "CORTEVAIX", cp : "71460" },
{ code : "71148", intitule : "COUBLANC", cp : "71170" },
{ code : "71149", intitule : "COUCHES", cp : "71490" },
{ code : "71150", intitule : "CRECHES SUR SAONE", cp : "71680" },
{ code : "71151", intitule : "CREOT", cp : "71490" },
{ code : "71152", intitule : "CRESSY SUR SOMME", cp : "71760" },
{ code : "71153", intitule : "LE CREUSOT", cp : "71200" },
{ code : "71154", intitule : "CRISSEY", cp : "71530" },
{ code : "71155", intitule : "CRONAT", cp : "71140" },
{ code : "71156", intitule : "CRUZILLE", cp : "71260" },
{ code : "71157", intitule : "CUISEAUX", cp : "71480" },
{ code : "71158", intitule : "CUISERY", cp : "71290" },
{ code : "71159", intitule : "CULLES LES ROCHES", cp : "71460" },
{ code : "71160", intitule : "CURBIGNY", cp : "71800" },
{ code : "71161", intitule : "CURDIN", cp : "71130" },
{ code : "71162", intitule : "CURGY", cp : "71400" },
{ code : "71163", intitule : "CURTIL SOUS BUFFIERES", cp : "71520" },
{ code : "71164", intitule : "CURTIL SOUS BURNAND", cp : "71460" },
{ code : "71165", intitule : "CUSSY EN MORVAN", cp : "71550" },
{ code : "71166", intitule : "CUZY", cp : "71320" },
{ code : "71167", intitule : "DAMEREY", cp : "71620" },
{ code : "71168", intitule : "DAMPIERRE EN BRESSE", cp : "71310" },
{ code : "71169", intitule : "DAVAYE", cp : "71960" },
{ code : "71170", intitule : "DEMIGNY", cp : "71150" },
{ code : "71171", intitule : "DENNEVY", cp : "71510" },
{ code : "71172", intitule : "DETTEY", cp : "71190" },
{ code : "71173", intitule : "DEVROUZE", cp : "71330" },
{ code : "71174", intitule : "DEZIZE LES MARANGES", cp : "71150" },
{ code : "71175", intitule : "DICONNE", cp : "71330" },
{ code : "71176", intitule : "DIGOIN", cp : "71160" },
{ code : "71176", intitule : "DIGOIN", cp : "71160" },
{ code : "71177", intitule : "DOMMARTIN LES CUISEAUX", cp : "71480" },
{ code : "71178", intitule : "DOMPIERRE LES ORMES", cp : "71520" },
{ code : "71178", intitule : "DOMPIERRE LES ORMES", cp : "71520" },
{ code : "71179", intitule : "DOMPIERRE SOUS SANVIGNES", cp : "71420" },
{ code : "71180", intitule : "DONZY LE NATIONAL", cp : "71250" },
{ code : "71181", intitule : "DONZY LE PERTUIS", cp : "71250" },
{ code : "71182", intitule : "DRACY LE FORT", cp : "71640" },
{ code : "71183", intitule : "DRACY LES COUCHES", cp : "71490" },
{ code : "71184", intitule : "DRACY ST LOUP", cp : "71400" },
{ code : "71185", intitule : "DYO", cp : "71800" },
{ code : "71186", intitule : "ECUELLES", cp : "71350" },
{ code : "71187", intitule : "ECUISSES", cp : "71210" },
{ code : "71188", intitule : "EPERTULLY", cp : "71360" },
{ code : "71189", intitule : "EPERVANS", cp : "71380" },
{ code : "71190", intitule : "EPINAC", cp : "71360" },
{ code : "71191", intitule : "ESSERTENNE", cp : "71510" },
{ code : "71192", intitule : "ETANG SUR ARROUX", cp : "71190" },
{ code : "71193", intitule : "ETRIGNY", cp : "71240" },
{ code : "71193", intitule : "ETRIGNY", cp : "71240" },
{ code : "71194", intitule : "FARGES LES CHALON", cp : "71150" },
{ code : "71195", intitule : "FARGES LES MACON", cp : "71700" },
{ code : "71196", intitule : "LE FAY", cp : "71580" },
{ code : "71198", intitule : "FLACEY EN BRESSE", cp : "71580" },
{ code : "71199", intitule : "FLAGY", cp : "71250" },
{ code : "71200", intitule : "FLEURY LA MONTAGNE", cp : "71340" },
{ code : "71201", intitule : "FLEY", cp : "71390" },
{ code : "71202", intitule : "FONTAINES", cp : "71150" },
{ code : "71203", intitule : "FONTENAY", cp : "71120" },
{ code : "71204", intitule : "FRAGNES LA LOYERE", cp : "71530" },
{ code : "71204", intitule : "FRAGNES LA LOYERE", cp : "71530" },
{ code : "71205", intitule : "FRANGY EN BRESSE", cp : "71330" },
{ code : "71206", intitule : "LA FRETTE", cp : "71440" },
{ code : "71207", intitule : "FRETTERANS", cp : "71270" },
{ code : "71208", intitule : "FRONTENARD", cp : "71270" },
{ code : "71209", intitule : "FRONTENAUD", cp : "71580" },
{ code : "71210", intitule : "FUISSE", cp : "71960" },
{ code : "71212", intitule : "GENELARD", cp : "71420" },
{ code : "71213", intitule : "LA GENETE", cp : "71290" },
{ code : "71214", intitule : "GENOUILLY", cp : "71460" },
{ code : "71215", intitule : "GERGY", cp : "71590" },
{ code : "71216", intitule : "GERMAGNY", cp : "71460" },
{ code : "71217", intitule : "GERMOLLES SUR GROSNE", cp : "71520" },
{ code : "71218", intitule : "GIBLES", cp : "71800" },
{ code : "71219", intitule : "GIGNY SUR SAONE", cp : "71240" },
{ code : "71220", intitule : "GILLY SUR LOIRE", cp : "71160" },
{ code : "71221", intitule : "GIVRY", cp : "71640" },
{ code : "71222", intitule : "GOURDON", cp : "71300" },
{ code : "71223", intitule : "LA GRANDE VERRIERE", cp : "71990" },
{ code : "71224", intitule : "GRANDVAUX", cp : "71430" },
{ code : "71225", intitule : "GRANGES", cp : "71390" },
{ code : "71226", intitule : "GREVILLY", cp : "71700" },
{ code : "71227", intitule : "GRURY", cp : "71760" },
{ code : "71228", intitule : "GUERFAND", cp : "71620" },
{ code : "71229", intitule : "LES GUERREAUX", cp : "71160" },
{ code : "71230", intitule : "GUEUGNON", cp : "71130" },
{ code : "71231", intitule : "LA GUICHE", cp : "71220" },
{ code : "71232", intitule : "HAUTEFOND", cp : "71600" },
{ code : "71233", intitule : "L HOPITAL LE MERCIER", cp : "71600" },
{ code : "71234", intitule : "HUILLY SUR SEILLE", cp : "71290" },
{ code : "71235", intitule : "HURIGNY", cp : "71870" },
{ code : "71236", intitule : "IGE", cp : "71960" },
{ code : "71237", intitule : "IGORNAY", cp : "71540" },
{ code : "71238", intitule : "IGUERANDE", cp : "71340" },
{ code : "71239", intitule : "ISSY L EVEQUE", cp : "71760" },
{ code : "71240", intitule : "JALOGNY", cp : "71250" },
{ code : "71241", intitule : "JAMBLES", cp : "71640" },
{ code : "71242", intitule : "JONCY", cp : "71460" },
{ code : "71243", intitule : "JOUDES", cp : "71480" },
{ code : "71244", intitule : "JOUVENCON", cp : "71290" },
{ code : "71245", intitule : "JUGY", cp : "71240" },
{ code : "71246", intitule : "JUIF", cp : "71440" },
{ code : "71247", intitule : "JULLY LES BUXY", cp : "71390" },
{ code : "71248", intitule : "LACROST", cp : "71700" },
{ code : "71249", intitule : "LAIVES", cp : "71240" },
{ code : "71250", intitule : "LAIZE", cp : "71870" },
{ code : "71251", intitule : "LAIZY", cp : "71190" },
{ code : "71252", intitule : "LALHEUE", cp : "71240" },
{ code : "71253", intitule : "LANS", cp : "71380" },
{ code : "71254", intitule : "LAYS SUR LE DOUBS", cp : "71270" },
{ code : "71255", intitule : "LESME", cp : "71140" },
{ code : "71256", intitule : "LESSARD EN BRESSE", cp : "71440" },
{ code : "71257", intitule : "LESSARD LE NATIONAL", cp : "71530" },
{ code : "71258", intitule : "LEYNES", cp : "71570" },
{ code : "71259", intitule : "LIGNY EN BRIONNAIS", cp : "71110" },
{ code : "71261", intitule : "LOISY", cp : "71290" },
{ code : "71262", intitule : "LONGEPIERRE", cp : "71270" },
{ code : "71263", intitule : "LOUHANS", cp : "71500" },
{ code : "71263", intitule : "LOUHANS", cp : "71500" },
{ code : "71264", intitule : "LOURNAND", cp : "71250" },
{ code : "71266", intitule : "LUCENAY L EVEQUE", cp : "71540" },
{ code : "71267", intitule : "LUGNY", cp : "71260" },
{ code : "71268", intitule : "LUGNY LES CHAROLLES", cp : "71120" },
{ code : "71269", intitule : "LUX", cp : "71100" },
{ code : "71270", intitule : "MACON", cp : "71000" },
{ code : "71270", intitule : "MACON", cp : "71000" },
{ code : "71270", intitule : "MACON", cp : "71000" },
{ code : "71270", intitule : "MACON", cp : "71000" },
{ code : "71270", intitule : "MACON", cp : "71000" },
{ code : "71271", intitule : "MAILLY", cp : "71340" },
{ code : "71272", intitule : "MALAY", cp : "71460" },
{ code : "71273", intitule : "MALTAT", cp : "71140" },
{ code : "71274", intitule : "MANCEY", cp : "71240" },
{ code : "71275", intitule : "MARCIGNY", cp : "71110" },
{ code : "71276", intitule : "MARCILLY LA GUEURCE", cp : "71120" },
{ code : "71277", intitule : "MARCILLY LES BUXY", cp : "71390" },
{ code : "71278", intitule : "MARIGNY", cp : "71300" },
{ code : "71279", intitule : "LE ROUSSET MARIZY", cp : "71220" },
{ code : "71279", intitule : "LE ROUSSET MARIZY", cp : "71220" },
{ code : "71280", intitule : "MARLY SOUS ISSY", cp : "71760" },
{ code : "71281", intitule : "MARLY SUR ARROUX", cp : "71420" },
{ code : "71282", intitule : "MARMAGNE", cp : "71710" },
{ code : "71283", intitule : "MARNAY", cp : "71240" },
{ code : "71284", intitule : "MARTAILLY LES BRANCION", cp : "71700" },
{ code : "71285", intitule : "MARTIGNY LE COMTE", cp : "71220" },
{ code : "71286", intitule : "MARY", cp : "71300" },
{ code : "71287", intitule : "MASSILLY", cp : "71250" },
{ code : "71288", intitule : "MASSY", cp : "71250" },
{ code : "71289", intitule : "MATOUR", cp : "71520" },
{ code : "71290", intitule : "MAZILLE", cp : "71250" },
{ code : "71291", intitule : "MELAY", cp : "71340" },
{ code : "71292", intitule : "MELLECEY", cp : "71640" },
{ code : "71292", intitule : "MELLECEY", cp : "71640" },
{ code : "71293", intitule : "MENETREUIL", cp : "71470" },
{ code : "71294", intitule : "MERCUREY", cp : "71640" },
{ code : "71294", intitule : "MERCUREY", cp : "71640" },
{ code : "71295", intitule : "MERVANS", cp : "71310" },
{ code : "71296", intitule : "MESSEY SUR GROSNE", cp : "71390" },
{ code : "71297", intitule : "MESVRES", cp : "71190" },
{ code : "71299", intitule : "MILLY LAMARTINE", cp : "71960" },
{ code : "71300", intitule : "LE MIROIR", cp : "71480" },
{ code : "71301", intitule : "MONT", cp : "71140" },
{ code : "71302", intitule : "MONTAGNY LES BUXY", cp : "71390" },
{ code : "71303", intitule : "MONTAGNY PRES LOUHANS", cp : "71500" },
{ code : "71304", intitule : "MONTAGNY SUR GROSNE", cp : "71520" },
{ code : "71305", intitule : "MONTBELLET", cp : "71260" },
{ code : "71306", intitule : "MONTCEAU LES MINES", cp : "71300" },
{ code : "71307", intitule : "MONTCEAUX L ETOILE", cp : "71110" },
{ code : "71308", intitule : "MONTCEAUX RAGNY", cp : "71240" },
{ code : "71309", intitule : "MONTCENIS", cp : "71710" },
{ code : "71310", intitule : "MONTCHANIN", cp : "71210" },
{ code : "71311", intitule : "MONTCONY", cp : "71500" },
{ code : "71312", intitule : "MONTCOY", cp : "71620" },
{ code : "71313", intitule : "MONTHELON", cp : "71400" },
{ code : "71314", intitule : "MONTJAY", cp : "71310" },
{ code : "71315", intitule : "MONT LES SEURRE", cp : "71270" },
{ code : "71316", intitule : "MONTMELARD", cp : "71520" },
{ code : "71317", intitule : "MONTMORT", cp : "71320" },
{ code : "71318", intitule : "MONTPONT EN BRESSE", cp : "71470" },
{ code : "71319", intitule : "MONTRET", cp : "71440" },
{ code : "71320", intitule : "MONT ST VINCENT", cp : "71300" },
{ code : "71321", intitule : "MOREY", cp : "71510" },
{ code : "71322", intitule : "MORLET", cp : "71360" },
{ code : "71323", intitule : "MORNAY", cp : "71220" },
{ code : "71324", intitule : "MOROGES", cp : "71390" },
{ code : "71325", intitule : "LA MOTTE ST JEAN", cp : "71160" },
{ code : "71326", intitule : "MOUTHIER EN BRESSE", cp : "71270" },
{ code : "71327", intitule : "MUSSY SOUS DUN", cp : "71170" },
{ code : "71328", intitule : "NANTON", cp : "71240" },
{ code : "71329", intitule : "NAVILLY", cp : "71270" },
{ code : "71330", intitule : "NEUVY GRANDCHAMP", cp : "71130" },
{ code : "71331", intitule : "NOCHIZE", cp : "71600" },
{ code : "71332", intitule : "ORMES", cp : "71290" },
{ code : "71333", intitule : "OSLON", cp : "71380" },
{ code : "71334", intitule : "OUDRY", cp : "71420" },
{ code : "71335", intitule : "OUROUX SOUS LE BOIS STE MARIE", cp : "71800" },
{ code : "71336", intitule : "OUROUX SUR SAONE", cp : "71370" },
{ code : "71337", intitule : "OYE", cp : "71800" },
{ code : "71338", intitule : "OZENAY", cp : "71700" },
{ code : "71339", intitule : "OZOLLES", cp : "71120" },
{ code : "71340", intitule : "PALINGES", cp : "71430" },
{ code : "71341", intitule : "PALLEAU", cp : "71350" },
{ code : "71342", intitule : "PARAY LE MONIAL", cp : "71600" },
{ code : "71343", intitule : "PARIS L HOPITAL", cp : "71150" },
{ code : "71344", intitule : "PASSY", cp : "71220" },
{ code : "71345", intitule : "PERONNE", cp : "71260" },
{ code : "71346", intitule : "PERRECY LES FORGES", cp : "71420" },
{ code : "71347", intitule : "PERREUIL", cp : "71510" },
{ code : "71348", intitule : "PERRIGNY SUR LOIRE", cp : "71160" },
{ code : "71349", intitule : "LA PETITE VERRIERE", cp : "71400" },
{ code : "71350", intitule : "PIERRECLOS", cp : "71960" },
{ code : "71351", intitule : "PIERRE DE BRESSE", cp : "71270" },
{ code : "71351", intitule : "PIERRE DE BRESSE", cp : "71270" },
{ code : "71352", intitule : "LE PLANOIS", cp : "71330" },
{ code : "71353", intitule : "PLOTTES", cp : "71700" },
{ code : "71354", intitule : "POISSON", cp : "71600" },
{ code : "71355", intitule : "PONTOUX", cp : "71270" },
{ code : "71356", intitule : "POUILLOUX", cp : "71230" },
{ code : "71357", intitule : "POURLANS", cp : "71270" },
{ code : "71358", intitule : "PRESSY SOUS DONDIN", cp : "71220" },
{ code : "71359", intitule : "PRETY", cp : "71290" },
{ code : "71360", intitule : "PRISSE", cp : "71960" },
{ code : "71361", intitule : "PRIZY", cp : "71800" },
{ code : "71362", intitule : "PRUZILLY", cp : "71570" },
{ code : "71363", intitule : "LE PULEY", cp : "71460" },
{ code : "71364", intitule : "LA RACINEUSE", cp : "71310" },
{ code : "71365", intitule : "RANCY", cp : "71290" },
{ code : "71366", intitule : "RATENELLE", cp : "71290" },
{ code : "71367", intitule : "RATTE", cp : "71500" },
{ code : "71368", intitule : "RECLESNE", cp : "71540" },
{ code : "71369", intitule : "REMIGNY", cp : "71150" },
{ code : "71370", intitule : "RIGNY SUR ARROUX", cp : "71160" },
{ code : "71371", intitule : "LA ROCHE VINEUSE", cp : "71960" },
{ code : "71372", intitule : "ROMANECHE THORINS", cp : "71570" },
{ code : "71373", intitule : "ROMENAY", cp : "71470" },
{ code : "71374", intitule : "ROSEY", cp : "71390" },
{ code : "71376", intitule : "ROUSSILLON EN MORVAN", cp : "71550" },
{ code : "71377", intitule : "ROYER", cp : "71700" },
{ code : "71378", intitule : "RULLY", cp : "71150" },
{ code : "71379", intitule : "SAGY", cp : "71580" },
{ code : "71380", intitule : "SAILLENARD", cp : "71580" },
{ code : "71381", intitule : "SAILLY", cp : "71250" },
{ code : "71382", intitule : "ST AGNAN", cp : "71160" },
{ code : "71383", intitule : "ST ALBAIN", cp : "71260" },
{ code : "71384", intitule : "ST AMBREUIL", cp : "71240" },
{ code : "71385", intitule : "ST AMOUR BELLEVUE", cp : "71570" },
{ code : "71386", intitule : "ST ANDRE EN BRESSE", cp : "71440" },
{ code : "71387", intitule : "ST ANDRE LE DESERT", cp : "71220" },
{ code : "71388", intitule : "ST AUBIN EN CHAROLLAIS", cp : "71430" },
{ code : "71389", intitule : "ST AUBIN SUR LOIRE", cp : "71140" },
{ code : "71390", intitule : "ST BERAIN SOUS SANVIGNES", cp : "71300" },
{ code : "71391", intitule : "ST BERAIN SUR DHEUNE", cp : "71510" },
{ code : "71392", intitule : "ST BOIL", cp : "71390" },
{ code : "71393", intitule : "ST BONNET DE CRAY", cp : "71340" },
{ code : "71394", intitule : "ST BONNET DE JOUX", cp : "71220" },
{ code : "71395", intitule : "ST BONNET DE VIEILLE VIGNE", cp : "71430" },
{ code : "71396", intitule : "ST BONNET EN BRESSE", cp : "71310" },
{ code : "71397", intitule : "STE CECILE", cp : "71250" },
{ code : "71398", intitule : "ST CHRISTOPHE EN BRESSE", cp : "71370" },
{ code : "71399", intitule : "ST CHRISTOPHE EN BRIONNAIS", cp : "71800" },
{ code : "71400", intitule : "ST CLEMENT SUR GUYE", cp : "71460" },
{ code : "71401", intitule : "STE CROIX", cp : "71470" },
{ code : "71402", intitule : "ST CYR", cp : "71240" },
{ code : "71403", intitule : "ST DENIS DE VAUX", cp : "71640" },
{ code : "71404", intitule : "ST DESERT", cp : "71390" },
{ code : "71405", intitule : "ST DIDIER EN BRESSE", cp : "71620" },
{ code : "71406", intitule : "ST DIDIER EN BRIONNAIS", cp : "71110" },
{ code : "71407", intitule : "ST DIDIER SUR ARROUX", cp : "71190" },
{ code : "71408", intitule : "ST EDMOND", cp : "71740" },
{ code : "71409", intitule : "ST EMILAND", cp : "71490" },
{ code : "71410", intitule : "ST ETIENNE EN BRESSE", cp : "71370" },
{ code : "71411", intitule : "ST EUGENE", cp : "71320" },
{ code : "71412", intitule : "ST EUSEBE", cp : "71210" },
{ code : "71413", intitule : "ST FIRMIN", cp : "71670" },
{ code : "71414", intitule : "ST FORGEOT", cp : "71400" },
{ code : "71415", intitule : "STE FOY", cp : "71110" },
{ code : "71416", intitule : "ST GENGOUX DE SCISSE", cp : "71260" },
{ code : "71417", intitule : "ST GENGOUX LE NATIONAL", cp : "71460" },
{ code : "71419", intitule : "ST GERMAIN DU BOIS", cp : "71330" },
{ code : "71420", intitule : "ST GERMAIN DU PLAIN", cp : "71370" },
{ code : "71421", intitule : "ST GERMAIN EN BRIONNAIS", cp : "71800" },
{ code : "71422", intitule : "ST GERMAIN LES BUXY", cp : "71390" },
{ code : "71423", intitule : "ST GERVAIS EN VALLIERE", cp : "71350" },
{ code : "71424", intitule : "ST GERVAIS SUR COUCHES", cp : "71490" },
{ code : "71425", intitule : "ST GILLES", cp : "71510" },
{ code : "71426", intitule : "STE HELENE", cp : "71390" },
{ code : "71427", intitule : "ST HURUGE", cp : "71460" },
{ code : "71428", intitule : "ST IGNY DE ROCHE", cp : "71170" },
{ code : "71430", intitule : "ST JEAN DE VAUX", cp : "71640" },
{ code : "71431", intitule : "ST JEAN DE TREZY", cp : "71490" },
{ code : "71433", intitule : "ST JULIEN DE CIVRY", cp : "71800" },
{ code : "71434", intitule : "ST JULIEN DE JONZY", cp : "71110" },
{ code : "71435", intitule : "ST JULIEN SUR DHEUNE", cp : "71210" },
{ code : "71436", intitule : "ST LAURENT D ANDENAY", cp : "71210" },
{ code : "71437", intitule : "ST LAURENT EN BRIONNAIS", cp : "71800" },
{ code : "71438", intitule : "ST LEGER DU BOIS", cp : "71360" },
{ code : "71439", intitule : "ST LEGER LES PARAY", cp : "71600" },
{ code : "71440", intitule : "ST LEGER SOUS BEUVRAY", cp : "71990" },
{ code : "71441", intitule : "ST LEGER SOUS LA BUSSIERE", cp : "71520" },
{ code : "71442", intitule : "ST LEGER SUR DHEUNE", cp : "71510" },
{ code : "71443", intitule : "ST LOUP GEANGES", cp : "71350" },
{ code : "71443", intitule : "ST LOUP GEANGES", cp : "71350" },
{ code : "71444", intitule : "ST LOUP DE VARENNES", cp : "71240" },
{ code : "71445", intitule : "ST MARCEL", cp : "71380" },
{ code : "71446", intitule : "ST MARCELIN DE CRAY", cp : "71460" },
{ code : "71447", intitule : "ST MARD DE VAUX", cp : "71640" },
{ code : "71448", intitule : "ST MARTIN BELLE ROCHE", cp : "71118" },
{ code : "71449", intitule : "ST MARTIN D AUXY", cp : "71390" },
{ code : "71450", intitule : "ST MARTIN DE COMMUNE", cp : "71490" },
{ code : "71451", intitule : "ST MARTIN DE LIXY", cp : "71740" },
{ code : "71452", intitule : "ST MARTIN DE SALENCEY", cp : "71220" },
{ code : "71453", intitule : "ST MARTIN DU LAC", cp : "71110" },
{ code : "71454", intitule : "ST MARTIN DU MONT", cp : "71580" },
{ code : "71455", intitule : "ST MARTIN DU TARTRE", cp : "71460" },
{ code : "71456", intitule : "ST MARTIN EN BRESSE", cp : "71620" },
{ code : "71457", intitule : "ST MARTIN EN GATINOIS", cp : "71350" },
{ code : "71458", intitule : "ST MARTIN LA PATROUILLE", cp : "71460" },
{ code : "71459", intitule : "ST MARTIN SOUS MONTAIGU", cp : "71640" },
{ code : "71460", intitule : "ST MAURICE DE SATONNAY", cp : "71260" },
{ code : "71461", intitule : "ST MAURICE DES CHAMPS", cp : "71460" },
{ code : "71462", intitule : "ST MAURICE EN RIVIERE", cp : "71620" },
{ code : "71463", intitule : "ST MAURICE LES CHATEAUNEUF", cp : "71740" },
{ code : "71464", intitule : "ST MAURICE LES COUCHES", cp : "71490" },
{ code : "71465", intitule : "ST MICAUD", cp : "71460" },
{ code : "71466", intitule : "ST NIZIER SUR ARROUX", cp : "71190" },
{ code : "71468", intitule : "ST PIERRE DE VARENNES", cp : "71670" },
{ code : "71469", intitule : "ST PIERRE LE VIEUX", cp : "71520" },
{ code : "71470", intitule : "ST POINT", cp : "71520" },
{ code : "71471", intitule : "ST PRIVE", cp : "71390" },
{ code : "71472", intitule : "ST PRIX", cp : "71990" },
{ code : "71473", intitule : "ST RACHO", cp : "71800" },
{ code : "71474", intitule : "STE RADEGONDE", cp : "71320" },
{ code : "71475", intitule : "ST REMY", cp : "71100" },
{ code : "71477", intitule : "ST ROMAIN SOUS GOURDON", cp : "71230" },
{ code : "71478", intitule : "ST ROMAIN SOUS VERSIGNY", cp : "71420" },
{ code : "71479", intitule : "ST SERNIN DU BOIS", cp : "71200" },
{ code : "71480", intitule : "ST SERNIN DU PLAIN", cp : "71510" },
{ code : "71481", intitule : "ST SYMPHORIEN D ANCELLES", cp : "71570" },
{ code : "71481", intitule : "ST SYMPHORIEN D ANCELLES", cp : "71570" },
{ code : "71482", intitule : "ST SYMPHORIEN DE MARMAGNE", cp : "71710" },
{ code : "71483", intitule : "ST SYMPHORIEN DES BOIS", cp : "71800" },
{ code : "71484", intitule : "ST USUGE", cp : "71500" },
{ code : "71485", intitule : "ST VALLERIN", cp : "71390" },
{ code : "71486", intitule : "ST VALLIER", cp : "71230" },
{ code : "71486", intitule : "ST VALLIER", cp : "71230" },
{ code : "71487", intitule : "ST VERAND", cp : "71570" },
{ code : "71488", intitule : "ST VINCENT DES PRES", cp : "71250" },
{ code : "71489", intitule : "ST VINCENT EN BRESSE", cp : "71440" },
{ code : "71490", intitule : "ST VINCENT BRAGNY", cp : "71430" },
{ code : "71490", intitule : "ST VINCENT BRAGNY", cp : "71430" },
{ code : "71491", intitule : "ST YAN", cp : "71600" },
{ code : "71492", intitule : "ST YTHAIRE", cp : "71460" },
{ code : "71493", intitule : "SAISY", cp : "71360" },
{ code : "71494", intitule : "LA SALLE", cp : "71260" },
{ code : "71495", intitule : "SALORNAY SUR GUYE", cp : "71250" },
{ code : "71496", intitule : "SAMPIGNY LES MARANGES", cp : "71150" },
{ code : "71497", intitule : "SANCE", cp : "71000" },
{ code : "71498", intitule : "SANTILLY", cp : "71460" },
{ code : "71499", intitule : "SANVIGNES LES MINES", cp : "71410" },
{ code : "71500", intitule : "SARRY", cp : "71110" },
{ code : "71501", intitule : "SASSANGY", cp : "71390" },
{ code : "71502", intitule : "SASSENAY", cp : "71530" },
{ code : "71503", intitule : "SAULES", cp : "71390" },
{ code : "71504", intitule : "SAUNIERES", cp : "71350" },
{ code : "71505", intitule : "SAVIANGES", cp : "71460" },
{ code : "71506", intitule : "SAVIGNY EN REVERMONT", cp : "71580" },
{ code : "71507", intitule : "SAVIGNY SUR GROSNE", cp : "71460" },
{ code : "71508", intitule : "SAVIGNY SUR SEILLE", cp : "71440" },
{ code : "71509", intitule : "LA CELLE EN MORVAN", cp : "71400" },
{ code : "71510", intitule : "SEMUR EN BRIONNAIS", cp : "71110" },
{ code : "71512", intitule : "SENNECEY LE GRAND", cp : "71240" },
{ code : "71513", intitule : "SENOZAN", cp : "71260" },
{ code : "71514", intitule : "SENS SUR SEILLE", cp : "71330" },
{ code : "71515", intitule : "SERCY", cp : "71460" },
{ code : "71516", intitule : "SERLEY", cp : "71310" },
{ code : "71517", intitule : "SERMESSE", cp : "71350" },
{ code : "71518", intitule : "SERRIERES", cp : "71960" },
{ code : "71519", intitule : "SERRIGNY EN BRESSE", cp : "71310" },
{ code : "71520", intitule : "SEVREY", cp : "71100" },
{ code : "71521", intitule : "SIGY LE CHATEL", cp : "71250" },
{ code : "71522", intitule : "SIMANDRE", cp : "71290" },
{ code : "71523", intitule : "SIMARD", cp : "71330" },
{ code : "71524", intitule : "SIVIGNON", cp : "71220" },
{ code : "71525", intitule : "SOLOGNY", cp : "71960" },
{ code : "71526", intitule : "SOLUTRE POUILLY", cp : "71960" },
{ code : "71527", intitule : "SOMMANT", cp : "71540" },
{ code : "71528", intitule : "SORNAY", cp : "71500" },
{ code : "71529", intitule : "SUIN", cp : "71220" },
{ code : "71530", intitule : "SULLY", cp : "71360" },
{ code : "71531", intitule : "LA TAGNIERE", cp : "71190" },
{ code : "71532", intitule : "TAIZE", cp : "71250" },
{ code : "71532", intitule : "TAIZE", cp : "71250" },
{ code : "71533", intitule : "TANCON", cp : "71740" },
{ code : "71534", intitule : "LE TARTRE", cp : "71330" },
{ code : "71535", intitule : "TAVERNAY", cp : "71400" },
{ code : "71537", intitule : "THIL SUR ARROUX", cp : "71190" },
{ code : "71538", intitule : "THUREY", cp : "71440" },
{ code : "71539", intitule : "TINTRY", cp : "71490" },
{ code : "71540", intitule : "TORCY", cp : "71210" },
{ code : "71541", intitule : "TORPES", cp : "71270" },
{ code : "71542", intitule : "TOULON SUR ARROUX", cp : "71320" },
{ code : "71543", intitule : "TOURNUS", cp : "71700" },
{ code : "71544", intitule : "TOUTENANT", cp : "71350" },
{ code : "71545", intitule : "TRAMAYES", cp : "71520" },
{ code : "71546", intitule : "TRAMBLY", cp : "71520" },
{ code : "71547", intitule : "TRIVY", cp : "71520" },
{ code : "71548", intitule : "TRONCHY", cp : "71440" },
{ code : "71549", intitule : "LA TRUCHERE", cp : "71290" },
{ code : "71550", intitule : "UCHIZY", cp : "71700" },
{ code : "71551", intitule : "UCHON", cp : "71190" },
{ code : "71552", intitule : "UXEAU", cp : "71130" },
{ code : "71553", intitule : "VAREILLES", cp : "71800" },
{ code : "71554", intitule : "VARENNE L ARCONCE", cp : "71110" },
{ code : "71555", intitule : "VARENNES LE GRAND", cp : "71240" },
{ code : "71556", intitule : "VARENNES LES MACON", cp : "71000" },
{ code : "71557", intitule : "VARENNE ST GERMAIN", cp : "71600" },
{ code : "71557", intitule : "VARENNE ST GERMAIN", cp : "71600" },
{ code : "71558", intitule : "VARENNES ST SAUVEUR", cp : "71480" },
{ code : "71559", intitule : "VARENNES SOUS DUN", cp : "71800" },
{ code : "71561", intitule : "VAUBAN", cp : "71800" },
{ code : "71562", intitule : "VAUDEBARRIER", cp : "71120" },
{ code : "71563", intitule : "VAUX EN PRE", cp : "71460" },
{ code : "71564", intitule : "VENDENESSE LES CHAROLLES", cp : "71120" },
{ code : "71565", intitule : "VENDENESSE SUR ARROUX", cp : "71130" },
{ code : "71566", intitule : "VERDUN SUR LE DOUBS", cp : "71350" },
{ code : "71567", intitule : "VERGISSON", cp : "71960" },
{ code : "71568", intitule : "VERISSEY", cp : "71440" },
{ code : "71570", intitule : "VERJUX", cp : "71590" },
{ code : "71571", intitule : "VEROSVRES", cp : "71220" },
{ code : "71572", intitule : "VERS", cp : "71240" },
{ code : "71573", intitule : "VERSAUGUES", cp : "71110" },
{ code : "71574", intitule : "VERZE", cp : "71960" },
{ code : "71576", intitule : "LE VILLARS", cp : "71700" },
{ code : "71577", intitule : "VILLEGAUDIN", cp : "71620" },
{ code : "71578", intitule : "CLUX VILLENEUVE", cp : "71270" },
{ code : "71578", intitule : "CLUX VILLENEUVE", cp : "71270" },
{ code : "71579", intitule : "VILLENEUVE EN MONTAGNE", cp : "71390" },
{ code : "71580", intitule : "VINCELLES", cp : "71500" },
{ code : "71581", intitule : "VINDECY", cp : "71110" },
{ code : "71582", intitule : "LA VINEUSE", cp : "71250" },
{ code : "71583", intitule : "VINZELLES", cp : "71680" },
{ code : "71584", intitule : "VIRE", cp : "71260" },
{ code : "71584", intitule : "VIRE", cp : "71260" },
{ code : "71585", intitule : "VIREY LE GRAND", cp : "71530" },
{ code : "71586", intitule : "VIRY", cp : "71120" },
{ code : "71587", intitule : "VITRY LES CLUNY", cp : "71250" },
{ code : "71588", intitule : "VITRY EN CHAROLLAIS", cp : "71600" },
{ code : "71589", intitule : "VITRY SUR LOIRE", cp : "71140" },
{ code : "71590", intitule : "VOLESVRES", cp : "71600" },
{ code : "71591", intitule : "FLEURVILLE", cp : "71260" },
{ code : "72001", intitule : "AIGNE", cp : "72650" },
{ code : "72002", intitule : "AILLIERES BEAUVOIR", cp : "72600" },
{ code : "72003", intitule : "ALLONNES", cp : "72700" },
{ code : "72004", intitule : "AMNE", cp : "72540" },
{ code : "72005", intitule : "ANCINNES", cp : "72610" },
{ code : "72006", intitule : "ARCONNAY", cp : "72610" },
{ code : "72007", intitule : "ARDENAY SUR MERIZE", cp : "72370" },
{ code : "72008", intitule : "ARNAGE", cp : "72230" },
{ code : "72009", intitule : "ARTHEZE", cp : "72270" },
{ code : "72010", intitule : "ASNIERES SUR VEGRE", cp : "72430" },
{ code : "72011", intitule : "ASSE LE BOISNE", cp : "72130" },
{ code : "72012", intitule : "ASSE LE RIBOUL", cp : "72170" },
{ code : "72013", intitule : "AUBIGNE RACAN", cp : "72800" },
{ code : "72015", intitule : "LES AULNEAUX", cp : "72600" },
{ code : "72016", intitule : "AUVERS LE HAMON", cp : "72300" },
{ code : "72017", intitule : "AUVERS SOUS MONTFAUCON", cp : "72540" },
{ code : "72018", intitule : "AVESNES EN SAOSNOIS", cp : "72260" },
{ code : "72019", intitule : "AVESSE", cp : "72350" },
{ code : "72020", intitule : "AVEZE", cp : "72400" },
{ code : "72021", intitule : "AVOISE", cp : "72430" },
{ code : "72022", intitule : "LE BAILLEUL", cp : "72200" },
{ code : "72023", intitule : "BALLON ST MARS", cp : "72290" },
{ code : "72023", intitule : "BALLON ST MARS", cp : "72290" },
{ code : "72024", intitule : "LA BAZOGE", cp : "72650" },
{ code : "72025", intitule : "BAZOUGES SUR LE LOIR", cp : "72200" },
{ code : "72026", intitule : "BEAUFAY", cp : "72110" },
{ code : "72027", intitule : "BEAUMONT SUR DEME", cp : "72340" },
{ code : "72028", intitule : "BEAUMONT PIED DE BOEUF", cp : "72500" },
{ code : "72029", intitule : "BEAUMONT SUR SARTHE", cp : "72170" },
{ code : "72031", intitule : "BEILLE", cp : "72160" },
{ code : "72032", intitule : "BERFAY", cp : "72320" },
{ code : "72033", intitule : "BERNAY EN CHAMPAGNE", cp : "72240" },
{ code : "72034", intitule : "BERUS", cp : "72610" },
{ code : "72035", intitule : "BESSE SUR BRAYE", cp : "72310" },
{ code : "72036", intitule : "BETHON", cp : "72610" },
{ code : "72037", intitule : "BLEVES", cp : "72600" },
{ code : "72038", intitule : "BOESSE LE SEC", cp : "72400" },
{ code : "72039", intitule : "BONNETABLE", cp : "72110" },
{ code : "72040", intitule : "LA BOSSE", cp : "72400" },
{ code : "72041", intitule : "BOUER", cp : "72390" },
{ code : "72042", intitule : "BOULOIRE", cp : "72440" },
{ code : "72043", intitule : "BOURG LE ROI", cp : "72610" },
{ code : "72044", intitule : "BOUSSE", cp : "72270" },
{ code : "72045", intitule : "BRAINS SUR GEE", cp : "72550" },
{ code : "72046", intitule : "LE BREIL SUR MERIZE", cp : "72370" },
{ code : "72047", intitule : "BRETTE LES PINS", cp : "72250" },
{ code : "72048", intitule : "BRIOSNE LES SABLES", cp : "72110" },
{ code : "72049", intitule : "LA BRUERE SUR LOIR", cp : "72500" },
{ code : "72050", intitule : "BRULON", cp : "72350" },
{ code : "72051", intitule : "CERANS FOULLETOURTE", cp : "72330" },
{ code : "72052", intitule : "CHAHAIGNES", cp : "72340" },
{ code : "72053", intitule : "CHALLES", cp : "72250" },
{ code : "72054", intitule : "CHAMPAGNE", cp : "72470" },
{ code : "72056", intitule : "CHAMPFLEUR", cp : "72610" },
{ code : "72057", intitule : "CHAMPROND", cp : "72320" },
{ code : "72058", intitule : "CHANGE", cp : "72560" },
{ code : "72059", intitule : "CHANTENAY VILLEDIEU", cp : "72430" },
{ code : "72060", intitule : "LA CHAPELLE AUX CHOUX", cp : "72800" },
{ code : "72061", intitule : "LA CHAPELLE D ALIGNE", cp : "72300" },
{ code : "72062", intitule : "LA CHAPELLE DU BOIS", cp : "72400" },
{ code : "72063", intitule : "LA CHAPELLE GAUGAIN", cp : "72310" },
{ code : "72064", intitule : "LA CHAPELLE HUON", cp : "72310" },
{ code : "72065", intitule : "LA CHAPELLE ST AUBIN", cp : "72650" },
{ code : "72066", intitule : "LA CHAPELLE ST FRAY", cp : "72240" },
{ code : "72067", intitule : "LA CHAPELLE ST REMY", cp : "72160" },
{ code : "72068", intitule : "LA CHARTRE SUR LE LOIR", cp : "72340" },
{ code : "72070", intitule : "CHASSILLE", cp : "72540" },
{ code : "72071", intitule : "CHATEAU DU LOIR", cp : "72500" },
{ code : "72072", intitule : "CHATEAU L HERMITAGE", cp : "72510" },
{ code : "72073", intitule : "CHAUFOUR NOTRE DAME", cp : "72550" },
{ code : "72074", intitule : "CHEMIRE EN CHARNIE", cp : "72540" },
{ code : "72075", intitule : "CHEMIRE LE GAUDIN", cp : "72210" },
{ code : "72076", intitule : "CHENAY", cp : "72610" },
{ code : "72077", intitule : "CHENU", cp : "72500" },
{ code : "72078", intitule : "CHERANCE", cp : "72170" },
{ code : "72079", intitule : "CHERISAY", cp : "72610" },
{ code : "72080", intitule : "CHERRE", cp : "72400" },
{ code : "72081", intitule : "CHERREAU", cp : "72400" },
{ code : "72082", intitule : "LE CHEVAIN", cp : "72610" },
{ code : "72083", intitule : "CHEVILLE", cp : "72350" },
{ code : "72084", intitule : "CLERMONT CREANS", cp : "72200" },
{ code : "72085", intitule : "COGNERS", cp : "72310" },
{ code : "72086", intitule : "COMMERVEIL", cp : "72600" },
{ code : "72087", intitule : "CONFLANS SUR ANILLE", cp : "72120" },
{ code : "72088", intitule : "CONGE SUR ORNE", cp : "72290" },
{ code : "72089", intitule : "CONLIE", cp : "72240" },
{ code : "72090", intitule : "CONNERRE", cp : "72160" },
{ code : "72091", intitule : "CONTILLY", cp : "72600" },
{ code : "72093", intitule : "CORMES", cp : "72400" },
{ code : "72094", intitule : "COUDRECIEUX", cp : "72440" },
{ code : "72095", intitule : "COULAINES", cp : "72190" },
{ code : "72096", intitule : "COULANS SUR GEE", cp : "72550" },
{ code : "72097", intitule : "COULOMBIERS", cp : "72130" },
{ code : "72098", intitule : "COULONGE", cp : "72800" },
{ code : "72099", intitule : "COURCEBOEUFS", cp : "72290" },
{ code : "72100", intitule : "COURCELLES LA FORET", cp : "72270" },
{ code : "72101", intitule : "COURCEMONT", cp : "72110" },
{ code : "72102", intitule : "COURCIVAL", cp : "72110" },
{ code : "72103", intitule : "COURDEMANCHE", cp : "72150" },
{ code : "72103", intitule : "COURDEMANCHE", cp : "72150" },
{ code : "72104", intitule : "COURGAINS", cp : "72260" },
{ code : "72105", intitule : "COURGENARD", cp : "72320" },
{ code : "72106", intitule : "COURTILLERS", cp : "72300" },
{ code : "72107", intitule : "CRANNES EN CHAMPAGNE", cp : "72540" },
{ code : "72108", intitule : "CRE SUR LOIR", cp : "72200" },
{ code : "72109", intitule : "CRISSE", cp : "72140" },
{ code : "72110", intitule : "CROSMIERES", cp : "72200" },
{ code : "72111", intitule : "CURES", cp : "72240" },
{ code : "72112", intitule : "DANGEUL", cp : "72260" },
{ code : "72113", intitule : "DEGRE", cp : "72550" },
{ code : "72114", intitule : "DEHAULT", cp : "72400" },
{ code : "72115", intitule : "DISSAY SOUS COURCILLON", cp : "72500" },
{ code : "72116", intitule : "DISSE SOUS BALLON", cp : "72260" },
{ code : "72117", intitule : "DISSE SOUS LE LUDE", cp : "72800" },
{ code : "72118", intitule : "DOLLON", cp : "72390" },
{ code : "72119", intitule : "DOMFRONT EN CHAMPAGNE", cp : "72240" },
{ code : "72120", intitule : "DOUCELLES", cp : "72170" },
{ code : "72121", intitule : "DOUILLET", cp : "72130" },
{ code : "72122", intitule : "DUNEAU", cp : "72160" },
{ code : "72123", intitule : "DUREIL", cp : "72270" },
{ code : "72124", intitule : "ECOMMOY", cp : "72220" },
{ code : "72125", intitule : "ECORPAIN", cp : "72120" },
{ code : "72126", intitule : "EPINEU LE CHEVREUIL", cp : "72540" },
{ code : "72127", intitule : "ETIVAL LES LE MANS", cp : "72700" },
{ code : "72128", intitule : "EVAILLE", cp : "72120" },
{ code : "72129", intitule : "FATINES", cp : "72470" },
{ code : "72130", intitule : "FAY", cp : "72550" },
{ code : "72131", intitule : "FERCE SUR SARTHE", cp : "72430" },
{ code : "72132", intitule : "LA FERTE BERNARD", cp : "72400" },
{ code : "72133", intitule : "FILLE", cp : "72210" },
{ code : "72134", intitule : "FLEE", cp : "72500" },
{ code : "72135", intitule : "LA FONTAINE ST MARTIN", cp : "72330" },
{ code : "72136", intitule : "FONTENAY SUR VEGRE", cp : "72350" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72610" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72610" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72610" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72600" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72600" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72600" },
{ code : "72137", intitule : "VILLENEUVE EN PERSEIGNE", cp : "72600" },
{ code : "72138", intitule : "FRESNAY SUR SARTHE", cp : "72130" },
{ code : "72139", intitule : "FYE", cp : "72610" },
{ code : "72141", intitule : "GESNES LE GANDELIN", cp : "72130" },
{ code : "72142", intitule : "GRANDCHAMP", cp : "72610" },
{ code : "72143", intitule : "LE GRAND LUCE", cp : "72150" },
{ code : "72144", intitule : "GREEZ SUR ROC", cp : "72320" },
{ code : "72145", intitule : "LE GREZ", cp : "72140" },
{ code : "72146", intitule : "GUECELARD", cp : "72230" },
{ code : "72147", intitule : "LA GUIERCHE", cp : "72380" },
{ code : "72148", intitule : "JAUZE", cp : "72110" },
{ code : "72149", intitule : "JOUE EN CHARNIE", cp : "72540" },
{ code : "72150", intitule : "JOUE L ABBE", cp : "72380" },
{ code : "72151", intitule : "JUIGNE SUR SARTHE", cp : "72300" },
{ code : "72152", intitule : "JUILLE", cp : "72170" },
{ code : "72153", intitule : "JUPILLES", cp : "72500" },
{ code : "72154", intitule : "LA FLECHE", cp : "72200" },
{ code : "72155", intitule : "LAIGNE EN BELIN", cp : "72220" },
{ code : "72156", intitule : "LAMNAY", cp : "72320" },
{ code : "72157", intitule : "LAVARDIN", cp : "72240" },
{ code : "72158", intitule : "LAVARE", cp : "72390" },
{ code : "72159", intitule : "LAVENAY", cp : "72310" },
{ code : "72159", intitule : "LAVENAY", cp : "72310" },
{ code : "72160", intitule : "LAVERNAT", cp : "72500" },
{ code : "72161", intitule : "LHOMME", cp : "72340" },
{ code : "72163", intitule : "LIGRON", cp : "72270" },
{ code : "72164", intitule : "LIVET EN SAOSNOIS", cp : "72610" },
{ code : "72165", intitule : "LOMBRON", cp : "72450" },
{ code : "72166", intitule : "LONGNES", cp : "72540" },
{ code : "72167", intitule : "LOUAILLES", cp : "72300" },
{ code : "72168", intitule : "LOUE", cp : "72540" },
{ code : "72169", intitule : "LOUPLANDE", cp : "72210" },
{ code : "72170", intitule : "LOUVIGNY", cp : "72600" },
{ code : "72171", intitule : "LOUZES", cp : "72600" },
{ code : "72172", intitule : "LE LUART", cp : "72390" },
{ code : "72173", intitule : "LUCEAU", cp : "72500" },
{ code : "72174", intitule : "LUCE SOUS BALLON", cp : "72290" },
{ code : "72175", intitule : "LUCHE PRINGE", cp : "72800" },
{ code : "72176", intitule : "LE LUDE", cp : "72800" },
{ code : "72177", intitule : "MAIGNE", cp : "72210" },
{ code : "72178", intitule : "MAISONCELLES", cp : "72440" },
{ code : "72179", intitule : "MALICORNE SUR SARTHE", cp : "72270" },
{ code : "72180", intitule : "MAMERS", cp : "72600" },
{ code : "72181", intitule : "LE MANS", cp : "72000" },
{ code : "72181", intitule : "LE MANS", cp : "72100" },
{ code : "72182", intitule : "MANSIGNE", cp : "72510" },
{ code : "72183", intitule : "MARCON", cp : "72340" },
{ code : "72184", intitule : "MAREIL EN CHAMPAGNE", cp : "72540" },
{ code : "72185", intitule : "MAREIL SUR LOIR", cp : "72200" },
{ code : "72186", intitule : "MARESCHE", cp : "72170" },
{ code : "72187", intitule : "MARIGNE LAILLE", cp : "72220" },
{ code : "72188", intitule : "MAROLLETTE", cp : "72600" },
{ code : "72189", intitule : "MAROLLES LES BRAULTS", cp : "72260" },
{ code : "72190", intitule : "MAROLLES LES ST CALAIS", cp : "72120" },
{ code : "72191", intitule : "MAYET", cp : "72360" },
{ code : "72192", intitule : "LES MEES", cp : "72260" },
{ code : "72193", intitule : "MELLERAY", cp : "72320" },
{ code : "72194", intitule : "MEURCE", cp : "72170" },
{ code : "72195", intitule : "MEZERAY", cp : "72270" },
{ code : "72196", intitule : "MEZIERES SUR PONTHOUIN", cp : "72290" },
{ code : "72197", intitule : "MEZIERES SOUS LAVARDIN", cp : "72240" },
{ code : "72198", intitule : "LA MILESSE", cp : "72650" },
{ code : "72199", intitule : "MOITRON SUR SARTHE", cp : "72170" },
{ code : "72199", intitule : "MOITRON SUR SARTHE", cp : "72170" },
{ code : "72200", intitule : "MONCE EN BELIN", cp : "72230" },
{ code : "72201", intitule : "MONCE EN SAOSNOIS", cp : "72260" },
{ code : "72202", intitule : "MONHOUDOU", cp : "72260" },
{ code : "72203", intitule : "MONTABON", cp : "72500" },
{ code : "72204", intitule : "MONTAILLE", cp : "72120" },
{ code : "72205", intitule : "MONTBIZOT", cp : "72380" },
{ code : "72208", intitule : "MONTMIRAIL", cp : "72320" },
{ code : "72209", intitule : "MONTREUIL LE CHETIF", cp : "72130" },
{ code : "72210", intitule : "MONTREUIL LE HENRI", cp : "72150" },
{ code : "72211", intitule : "MONT ST JEAN", cp : "72140" },
{ code : "72212", intitule : "MOULINS LE CARBONNEL", cp : "72130" },
{ code : "72213", intitule : "MULSANNE", cp : "72230" },
{ code : "72214", intitule : "NAUVAY", cp : "72260" },
{ code : "72215", intitule : "NEUFCHATEL EN SAOSNOIS", cp : "72600" },
{ code : "72216", intitule : "NEUVILLALAIS", cp : "72240" },
{ code : "72217", intitule : "NEUVILLE SUR SARTHE", cp : "72190" },
{ code : "72218", intitule : "NEUVILLETTE EN CHARNIE", cp : "72140" },
{ code : "72219", intitule : "NEUVY EN CHAMPAGNE", cp : "72240" },
{ code : "72220", intitule : "NOGENT LE BERNARD", cp : "72110" },
{ code : "72221", intitule : "NOGENT SUR LOIR", cp : "72500" },
{ code : "72222", intitule : "NOUANS", cp : "72260" },
{ code : "72223", intitule : "NOYEN SUR SARTHE", cp : "72430" },
{ code : "72224", intitule : "NUILLE LE JALAIS", cp : "72370" },
{ code : "72225", intitule : "OISSEAU LE PETIT", cp : "72610" },
{ code : "72226", intitule : "OIZE", cp : "72330" },
{ code : "72227", intitule : "PANON", cp : "72600" },
{ code : "72228", intitule : "PARCE SUR SARTHE", cp : "72300" },
{ code : "72229", intitule : "PARENNES", cp : "72140" },
{ code : "72230", intitule : "PARIGNE LE POLIN", cp : "72330" },
{ code : "72231", intitule : "PARIGNE L EVEQUE", cp : "72250" },
{ code : "72232", intitule : "NOTRE DAME DU PE", cp : "72300" },
{ code : "72233", intitule : "PERAY", cp : "72260" },
{ code : "72234", intitule : "PEZE LE ROBERT", cp : "72140" },
{ code : "72235", intitule : "PIACE", cp : "72170" },
{ code : "72236", intitule : "PINCE", cp : "72300" },
{ code : "72237", intitule : "PIRMIL", cp : "72430" },
{ code : "72238", intitule : "PIZIEUX", cp : "72600" },
{ code : "72239", intitule : "POILLE SUR VEGRE", cp : "72350" },
{ code : "72240", intitule : "PONCE SUR LE LOIR", cp : "72340" },
{ code : "72241", intitule : "MONTFORT LE GESNOIS", cp : "72450" },
{ code : "72243", intitule : "PONTVALLAIN", cp : "72510" },
{ code : "72244", intitule : "PRECIGNE", cp : "72300" },
{ code : "72245", intitule : "PREVAL", cp : "72400" },
{ code : "72246", intitule : "PREVELLES", cp : "72110" },
{ code : "72247", intitule : "PRUILLE LE CHETIF", cp : "72700" },
{ code : "72248", intitule : "PRUILLE L EGUILLE", cp : "72150" },
{ code : "72249", intitule : "LA QUINTE", cp : "72550" },
{ code : "72250", intitule : "RAHAY", cp : "72120" },
{ code : "72251", intitule : "RENE", cp : "72260" },
{ code : "72252", intitule : "REQUEIL", cp : "72510" },
{ code : "72253", intitule : "ROEZE SUR SARTHE", cp : "72210" },
{ code : "72254", intitule : "ROUESSE FONTAINE", cp : "72610" },
{ code : "72255", intitule : "ROUESSE VASSE", cp : "72140" },
{ code : "72256", intitule : "ROUEZ", cp : "72140" },
{ code : "72257", intitule : "ROUILLON", cp : "72700" },
{ code : "72259", intitule : "ROUPERROUX LE COQUET", cp : "72110" },
{ code : "72260", intitule : "RUAUDIN", cp : "72230" },
{ code : "72261", intitule : "RUILLE EN CHAMPAGNE", cp : "72240" },
{ code : "72262", intitule : "RUILLE SUR LOIR", cp : "72340" },
{ code : "72264", intitule : "SABLE SUR SARTHE", cp : "72300" },
{ code : "72265", intitule : "ST AIGNAN", cp : "72110" },
{ code : "72266", intitule : "ST AUBIN DE LOCQUENAY", cp : "72130" },
{ code : "72267", intitule : "ST AUBIN DES COUDRAIS", cp : "72400" },
{ code : "72268", intitule : "ST BIEZ EN BELIN", cp : "72220" },
{ code : "72269", intitule : "ST CALAIS", cp : "72120" },
{ code : "72270", intitule : "ST CALEZ EN SAOSNOIS", cp : "72600" },
{ code : "72271", intitule : "ST CELERIN", cp : "72110" },
{ code : "72272", intitule : "STE CEROTTE", cp : "72120" },
{ code : "72273", intitule : "ST CHRISTOPHE DU JAMBET", cp : "72170" },
{ code : "72274", intitule : "ST CHRISTOPHE EN CHAMPAGNE", cp : "72540" },
{ code : "72275", intitule : "ST CORNEILLE", cp : "72460" },
{ code : "72276", intitule : "ST COSME EN VAIRAIS", cp : "72110" },
{ code : "72277", intitule : "ST DENIS DES COUDRAIS", cp : "72110" },
{ code : "72278", intitule : "ST DENIS D ORQUES", cp : "72350" },
{ code : "72279", intitule : "ST GEORGES DE LA COUEE", cp : "72150" },
{ code : "72280", intitule : "ST GEORGES DU BOIS", cp : "72700" },
{ code : "72281", intitule : "ST GEORGES DU ROSAY", cp : "72110" },
{ code : "72282", intitule : "ST GEORGES LE GAULTIER", cp : "72130" },
{ code : "72283", intitule : "ST GERMAIN D ARCE", cp : "72800" },
{ code : "72284", intitule : "ST GERMAIN SUR SARTHE", cp : "72130" },
{ code : "72284", intitule : "ST GERMAIN SUR SARTHE", cp : "72130" },
{ code : "72286", intitule : "ST GERVAIS DE VIC", cp : "72120" },
{ code : "72287", intitule : "ST GERVAIS EN BELIN", cp : "72220" },
{ code : "72289", intitule : "STE JAMME SUR SARTHE", cp : "72380" },
{ code : "72289", intitule : "STE JAMME SUR SARTHE", cp : "72380" },
{ code : "72290", intitule : "ST JEAN D ASSE", cp : "72380" },
{ code : "72291", intitule : "ST JEAN DE LA MOTTE", cp : "72510" },
{ code : "72292", intitule : "ST JEAN DES ECHELLES", cp : "72320" },
{ code : "72293", intitule : "ST JEAN DU BOIS", cp : "72430" },
{ code : "72294", intitule : "ST LEONARD DES BOIS", cp : "72130" },
{ code : "72295", intitule : "ST LONGIS", cp : "72600" },
{ code : "72296", intitule : "ST MAIXENT", cp : "72320" },
{ code : "72297", intitule : "ST MARCEAU", cp : "72170" },
{ code : "72298", intitule : "ST MARS DE LOCQUENAY", cp : "72440" },
{ code : "72299", intitule : "ST MARS D OUTILLE", cp : "72220" },
{ code : "72300", intitule : "ST MARS LA BRIERE", cp : "72470" },
{ code : "72302", intitule : "ST MARTIN DES MONTS", cp : "72400" },
{ code : "72303", intitule : "ST MICHEL DE CHAVAIGNES", cp : "72440" },
{ code : "72304", intitule : "STE OSMANE", cp : "72120" },
{ code : "72305", intitule : "ST OUEN DE MIMBRE", cp : "72130" },
{ code : "72306", intitule : "ST OUEN EN BELIN", cp : "72220" },
{ code : "72307", intitule : "ST OUEN EN CHAMPAGNE", cp : "72350" },
{ code : "72308", intitule : "ST PATERNE", cp : "72610" },
{ code : "72309", intitule : "ST PAUL LE GAULTIER", cp : "72130" },
{ code : "72310", intitule : "ST PAVACE", cp : "72190" },
{ code : "72311", intitule : "ST PIERRE DE CHEVILLE", cp : "72500" },
{ code : "72312", intitule : "ST PIERRE DES BOIS", cp : "72430" },
{ code : "72313", intitule : "ST PIERRE DES ORMES", cp : "72600" },
{ code : "72314", intitule : "ST PIERRE DU LOROUER", cp : "72150" },
{ code : "72315", intitule : "ST REMY DE SILLE", cp : "72140" },
{ code : "72316", intitule : "ST REMY DES MONTS", cp : "72600" },
{ code : "72317", intitule : "ST REMY DU VAL", cp : "72600" },
{ code : "72319", intitule : "STE SABINE SUR LONGEVE", cp : "72380" },
{ code : "72320", intitule : "ST SATURNIN", cp : "72650" },
{ code : "72321", intitule : "ST SYMPHORIEN", cp : "72240" },
{ code : "72322", intitule : "ST ULPHACE", cp : "72320" },
{ code : "72323", intitule : "ST VICTEUR", cp : "72130" },
{ code : "72324", intitule : "ST VINCENT DES PRES", cp : "72600" },
{ code : "72325", intitule : "ST VINCENT DU LOROUER", cp : "72150" },
{ code : "72326", intitule : "SAOSNES", cp : "72600" },
{ code : "72327", intitule : "SARCE", cp : "72360" },
{ code : "72328", intitule : "SARGE LES LE MANS", cp : "72190" },
{ code : "72329", intitule : "SAVIGNE L EVEQUE", cp : "72460" },
{ code : "72330", intitule : "SAVIGNE SOUS LE LUDE", cp : "72800" },
{ code : "72331", intitule : "SCEAUX SUR HUISNE", cp : "72160" },
{ code : "72332", intitule : "SEGRIE", cp : "72170" },
{ code : "72333", intitule : "SEMUR EN VALLON", cp : "72390" },
{ code : "72334", intitule : "SILLE LE GUILLAUME", cp : "72140" },
{ code : "72335", intitule : "SILLE LE PHILIPPE", cp : "72460" },
{ code : "72336", intitule : "SOLESMES", cp : "72300" },
{ code : "72337", intitule : "SOUGE LE GANELON", cp : "72130" },
{ code : "72338", intitule : "SOUILLE", cp : "72380" },
{ code : "72339", intitule : "SOULIGNE FLACE", cp : "72210" },
{ code : "72340", intitule : "SOULIGNE SOUS BALLON", cp : "72290" },
{ code : "72341", intitule : "SOULITRE", cp : "72370" },
{ code : "72342", intitule : "SOUVIGNE SUR MEME", cp : "72400" },
{ code : "72343", intitule : "SOUVIGNE SUR SARTHE", cp : "72300" },
{ code : "72344", intitule : "SPAY", cp : "72700" },
{ code : "72345", intitule : "SURFONDS", cp : "72370" },
{ code : "72346", intitule : "LA SUZE SUR SARTHE", cp : "72210" },
{ code : "72347", intitule : "TASSE", cp : "72430" },
{ code : "72348", intitule : "TASSILLE", cp : "72540" },
{ code : "72349", intitule : "TEILLE", cp : "72290" },
{ code : "72350", intitule : "TELOCHE", cp : "72220" },
{ code : "72351", intitule : "TENNIE", cp : "72240" },
{ code : "72352", intitule : "TERREHAULT", cp : "72110" },
{ code : "72353", intitule : "THELIGNY", cp : "72320" },
{ code : "72354", intitule : "THOIGNE", cp : "72260" },
{ code : "72355", intitule : "THOIRE SOUS CONTENSOR", cp : "72610" },
{ code : "72356", intitule : "THOIRE SUR DINAN", cp : "72500" },
{ code : "72357", intitule : "THOREE LES PINS", cp : "72800" },
{ code : "72358", intitule : "THORIGNE SUR DUE", cp : "72160" },
{ code : "72359", intitule : "TORCE EN VALLEE", cp : "72110" },
{ code : "72360", intitule : "TRANGE", cp : "72650" },
{ code : "72361", intitule : "TRESSON", cp : "72440" },
{ code : "72362", intitule : "LE TRONCHET", cp : "72170" },
{ code : "72363", intitule : "TUFFE VAL DE LA CHERONNE", cp : "72160" },
{ code : "72363", intitule : "TUFFE VAL DE LA CHERONNE", cp : "72160" },
{ code : "72364", intitule : "VAAS", cp : "72500" },
{ code : "72366", intitule : "VALENNES", cp : "72320" },
{ code : "72367", intitule : "VALLON SUR GEE", cp : "72540" },
{ code : "72368", intitule : "VANCE", cp : "72310" },
{ code : "72369", intitule : "VERNEIL LE CHETIF", cp : "72360" },
{ code : "72370", intitule : "VERNIE", cp : "72170" },
{ code : "72372", intitule : "VEZOT", cp : "72600" },
{ code : "72373", intitule : "VIBRAYE", cp : "72320" },
{ code : "72374", intitule : "VILLAINES LA CARELLE", cp : "72600" },
{ code : "72375", intitule : "VILLAINES LA GONAIS", cp : "72400" },
{ code : "72376", intitule : "VILLAINES SOUS LUCE", cp : "72150" },
{ code : "72377", intitule : "VILLAINES SOUS MALICORNE", cp : "72270" },
{ code : "72378", intitule : "VION", cp : "72300" },
{ code : "72379", intitule : "VIRE EN CHAMPAGNE", cp : "72350" },
{ code : "72380", intitule : "VIVOIN", cp : "72170" },
{ code : "72381", intitule : "VOIVRES LES LE MANS", cp : "72210" },
{ code : "72382", intitule : "VOLNAY", cp : "72440" },
{ code : "72383", intitule : "VOUVRAY SUR HUISNE", cp : "72160" },
{ code : "72384", intitule : "VOUVRAY SUR LOIR", cp : "72500" },
{ code : "72384", intitule : "VOUVRAY SUR LOIR", cp : "72500" },
{ code : "72385", intitule : "YVRE LE POLIN", cp : "72330" },
{ code : "72386", intitule : "YVRE L EVEQUE", cp : "72530" },
{ code : "73001", intitule : "AIGUEBELETTE LE LAC", cp : "73610" },
{ code : "73002", intitule : "AIGUEBELLE", cp : "73220" },
{ code : "73003", intitule : "AIGUEBLANCHE", cp : "73260" },
{ code : "73004", intitule : "AILLON LE JEUNE", cp : "73340" },
{ code : "73005", intitule : "AILLON LE VIEUX", cp : "73340" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73006", intitule : "AIME LA PLAGNE", cp : "73210" },
{ code : "73007", intitule : "AITON", cp : "73220" },
{ code : "73008", intitule : "AIX LES BAINS", cp : "73100" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73010", intitule : "ENTRELACS", cp : "73410" },
{ code : "73011", intitule : "ALBERTVILLE", cp : "73200" },
{ code : "73012", intitule : "ALBIEZ LE JEUNE", cp : "73300" },
{ code : "73013", intitule : "ALBIEZ MONTROND", cp : "73300" },
{ code : "73014", intitule : "ALLONDAZ", cp : "73200" },
{ code : "73015", intitule : "LES ALLUES", cp : "73550" },
{ code : "73015", intitule : "LES ALLUES", cp : "73550" },
{ code : "73017", intitule : "APREMONT", cp : "73190" },
{ code : "73018", intitule : "ARBIN", cp : "73800" },
{ code : "73019", intitule : "ARGENTINE", cp : "73220" },
{ code : "73020", intitule : "ARITH", cp : "73340" },
{ code : "73021", intitule : "ARVILLARD", cp : "73110" },
{ code : "73022", intitule : "ATTIGNAT ONCIN", cp : "73610" },
{ code : "73023", intitule : "AUSSOIS", cp : "73500" },
{ code : "73024", intitule : "LES AVANCHERS VALMOREL", cp : "73260" },
{ code : "73025", intitule : "AVRESSIEUX", cp : "73240" },
{ code : "73026", intitule : "AVRIEUX", cp : "73500" },
{ code : "73027", intitule : "AYN", cp : "73470" },
{ code : "73028", intitule : "LA BALME", cp : "73170" },
{ code : "73029", intitule : "BARBERAZ", cp : "73000" },
{ code : "73030", intitule : "BARBY", cp : "73230" },
{ code : "73031", intitule : "BASSENS", cp : "73000" },
{ code : "73032", intitule : "LA BATHIE", cp : "73540" },
{ code : "73033", intitule : "LA BAUCHE", cp : "73360" },
{ code : "73034", intitule : "BEAUFORT", cp : "73270" },
{ code : "73034", intitule : "BEAUFORT", cp : "73270" },
{ code : "73036", intitule : "BELLECOMBE EN BAUGES", cp : "73340" },
{ code : "73039", intitule : "BELMONT TRAMONET", cp : "73330" },
{ code : "73040", intitule : "BESSANS", cp : "73480" },
{ code : "73041", intitule : "BETTON BETTONET", cp : "73390" },
{ code : "73042", intitule : "BILLIEME", cp : "73170" },
{ code : "73043", intitule : "LA BIOLLE", cp : "73410" },
{ code : "73045", intitule : "LE BOIS", cp : "73260" },
{ code : "73046", intitule : "BONNEVAL", cp : "73260" },
{ code : "73047", intitule : "BONNEVAL SUR ARC", cp : "73480" },
{ code : "73048", intitule : "BONVILLARD", cp : "73460" },
{ code : "73049", intitule : "BONVILLARET", cp : "73220" },
{ code : "73050", intitule : "BOURDEAU", cp : "73370" },
{ code : "73051", intitule : "LE BOURGET DU LAC", cp : "73370" },
{ code : "73052", intitule : "BOURGET EN HUILE", cp : "73110" },
{ code : "73053", intitule : "BOURGNEUF", cp : "73390" },
{ code : "73054", intitule : "BOURG ST MAURICE", cp : "73700" },
{ code : "73054", intitule : "BOURG ST MAURICE", cp : "73700" },
{ code : "73054", intitule : "BOURG ST MAURICE", cp : "73700" },
{ code : "73055", intitule : "BOZEL", cp : "73350" },
{ code : "73056", intitule : "BRAMANS", cp : "73500" },
{ code : "73057", intitule : "BRIDES LES BAINS", cp : "73570" },
{ code : "73058", intitule : "LA BRIDOIRE", cp : "73520" },
{ code : "73059", intitule : "BRISON ST INNOCENT", cp : "73100" },
{ code : "73061", intitule : "CESARCHES", cp : "73200" },
{ code : "73063", intitule : "CEVINS", cp : "73730" },
{ code : "73064", intitule : "CHALLES LES EAUX", cp : "73190" },
{ code : "73065", intitule : "CHAMBERY", cp : "73000" },
{ code : "73067", intitule : "LA CHAMBRE", cp : "73130" },
{ code : "73068", intitule : "CHAMOUSSET", cp : "73390" },
{ code : "73069", intitule : "CHAMOUX SUR GELON", cp : "73390" },
{ code : "73070", intitule : "CHAMPAGNEUX", cp : "73240" },
{ code : "73071", intitule : "CHAMPAGNY EN VANOISE", cp : "73350" },
{ code : "73072", intitule : "CHAMP LAURENT", cp : "73390" },
{ code : "73073", intitule : "CHANAZ", cp : "73310" },
{ code : "73074", intitule : "LA CHAPELLE", cp : "73660" },
{ code : "73075", intitule : "LA CHAPELLE BLANCHE", cp : "73110" },
{ code : "73076", intitule : "LA CHAPELLE DU MONT DU CHAT", cp : "73370" },
{ code : "73077", intitule : "LES CHAPELLES", cp : "73700" },
{ code : "73078", intitule : "LA CHAPELLE ST MARTIN", cp : "73170" },
{ code : "73079", intitule : "CHATEAUNEUF", cp : "73390" },
{ code : "73080", intitule : "LE CHATEL", cp : "73300" },
{ code : "73081", intitule : "LE CHATELARD", cp : "73630" },
{ code : "73082", intitule : "LA CHAVANNE", cp : "73800" },
{ code : "73083", intitule : "LES CHAVANNES EN MAURIENNE", cp : "73660" },
{ code : "73084", intitule : "CHIGNIN", cp : "73800" },
{ code : "73085", intitule : "CHINDRIEUX", cp : "73310" },
{ code : "73086", intitule : "CLERY", cp : "73460" },
{ code : "73087", intitule : "COGNIN", cp : "73160" },
{ code : "73088", intitule : "COHENNOZ", cp : "73590" },
{ code : "73088", intitule : "COHENNOZ", cp : "73400" },
{ code : "73089", intitule : "COISE ST JEAN PIED GAUTHIER", cp : "73800" },
{ code : "73090", intitule : "LA COMPOTE", cp : "73630" },
{ code : "73091", intitule : "CONJUX", cp : "73310" },
{ code : "73092", intitule : "CORBEL", cp : "73160" },
{ code : "73094", intitule : "CREST VOLAND", cp : "73590" },
{ code : "73095", intitule : "LA CROIX DE LA ROCHETTE", cp : "73110" },
{ code : "73096", intitule : "CRUET", cp : "73800" },
{ code : "73097", intitule : "CURIENNE", cp : "73190" },
{ code : "73098", intitule : "LES DESERTS", cp : "73230" },
{ code : "73098", intitule : "LES DESERTS", cp : "73230" },
{ code : "73099", intitule : "DETRIER", cp : "73110" },
{ code : "73100", intitule : "DOMESSIN", cp : "73330" },
{ code : "73101", intitule : "DOUCY EN BAUGES", cp : "73630" },
{ code : "73103", intitule : "DRUMETTAZ CLARAFOND", cp : "73420" },
{ code : "73104", intitule : "DULLIN", cp : "73610" },
{ code : "73105", intitule : "LES ECHELLES", cp : "73360" },
{ code : "73106", intitule : "ECOLE", cp : "73630" },
{ code : "73107", intitule : "ENTREMONT LE VIEUX", cp : "73670" },
{ code : "73109", intitule : "EPIERRE", cp : "73220" },
{ code : "73110", intitule : "ESSERTS BLAY", cp : "73540" },
{ code : "73111", intitule : "ETABLE", cp : "73110" },
{ code : "73112", intitule : "FEISSONS SUR ISERE", cp : "73260" },
{ code : "73113", intitule : "FEISSONS SUR SALINS", cp : "73350" },
{ code : "73114", intitule : "FLUMET", cp : "73590" },
{ code : "73116", intitule : "FONTCOUVERTE LA TOUSSUIRE", cp : "73300" },
{ code : "73116", intitule : "FONTCOUVERTE LA TOUSSUIRE", cp : "73300" },
{ code : "73117", intitule : "FOURNEAUX", cp : "73500" },
{ code : "73118", intitule : "FRANCIN", cp : "73800" },
{ code : "73119", intitule : "FRENEY", cp : "73500" },
{ code : "73120", intitule : "FRETERIVE", cp : "73250" },
{ code : "73121", intitule : "FRONTENEX", cp : "73460" },
{ code : "73122", intitule : "GERBAIX", cp : "73470" },
{ code : "73123", intitule : "LA GIETTAZ", cp : "73590" },
{ code : "73124", intitule : "GILLY SUR ISERE", cp : "73200" },
{ code : "73127", intitule : "GRESIN", cp : "73240" },
{ code : "73128", intitule : "GRESY SUR AIX", cp : "73100" },
{ code : "73129", intitule : "GRESY SUR ISERE", cp : "73460" },
{ code : "73130", intitule : "GRIGNON", cp : "73200" },
{ code : "73131", intitule : "HAUTECOUR", cp : "73600" },
{ code : "73132", intitule : "HAUTELUCE", cp : "73620" },
{ code : "73133", intitule : "HAUTEVILLE", cp : "73390" },
{ code : "73135", intitule : "HERMILLON", cp : "73300" },
{ code : "73137", intitule : "JACOB BELLECOMBETTE", cp : "73000" },
{ code : "73138", intitule : "JARRIER", cp : "73300" },
{ code : "73139", intitule : "JARSY", cp : "73630" },
{ code : "73140", intitule : "JONGIEUX", cp : "73170" },
{ code : "73141", intitule : "LAISSAUD", cp : "73800" },
{ code : "73142", intitule : "LANDRY", cp : "73210" },
{ code : "73143", intitule : "LANSLEBOURG MONT CENIS", cp : "73480" },
{ code : "73144", intitule : "LANSLEVILLARD", cp : "73480" },
{ code : "73145", intitule : "LEPIN LE LAC", cp : "73610" },
{ code : "73146", intitule : "LESCHERAINES", cp : "73340" },
{ code : "73147", intitule : "LOISIEUX", cp : "73170" },
{ code : "73149", intitule : "LUCEY", cp : "73170" },
{ code : "73150", intitule : "LA PLAGNE TARENTAISE", cp : "73210" },
{ code : "73150", intitule : "LA PLAGNE TARENTAISE", cp : "73210" },
{ code : "73150", intitule : "LA PLAGNE TARENTAISE", cp : "73210" },
{ code : "73150", intitule : "LA PLAGNE TARENTAISE", cp : "73210" },
{ code : "73150", intitule : "LA PLAGNE TARENTAISE", cp : "73210" },
{ code : "73151", intitule : "LES MARCHES", cp : "73800" },
{ code : "73152", intitule : "MARCIEUX", cp : "73470" },
{ code : "73153", intitule : "MARTHOD", cp : "73400" },
{ code : "73154", intitule : "MERCURY", cp : "73200" },
{ code : "73155", intitule : "MERY", cp : "73420" },
{ code : "73156", intitule : "MEYRIEUX TROUET", cp : "73170" },
{ code : "73157", intitule : "MODANE", cp : "73500" },
{ code : "73159", intitule : "LES MOLLETTES", cp : "73800" },
{ code : "73160", intitule : "MONTAGNOLE", cp : "73000" },
{ code : "73161", intitule : "MONTAGNY", cp : "73350" },
{ code : "73162", intitule : "MONTAILLEUR", cp : "73460" },
{ code : "73163", intitule : "MONTAIMONT", cp : "73130" },
{ code : "73164", intitule : "MONTCEL", cp : "73100" },
{ code : "73166", intitule : "MONTENDRY", cp : "73390" },
{ code : "73167", intitule : "MONTGELLAFREY", cp : "73130" },
{ code : "73168", intitule : "MONTGILBERT", cp : "73220" },
{ code : "73170", intitule : "MONTHION", cp : "73200" },
{ code : "73171", intitule : "MONTMELIAN", cp : "73800" },
{ code : "73173", intitule : "MONTRICHER ALBANNE", cp : "73870" },
{ code : "73173", intitule : "MONTRICHER ALBANNE", cp : "73870" },
{ code : "73175", intitule : "MONTSAPEY", cp : "73220" },
{ code : "73176", intitule : "MONTVALEZAN", cp : "73700" },
{ code : "73177", intitule : "MONTVERNIER", cp : "73300" },
{ code : "73178", intitule : "LA MOTTE EN BAUGES", cp : "73340" },
{ code : "73179", intitule : "LA MOTTE SERVOLEX", cp : "73290" },
{ code : "73180", intitule : "MOTZ", cp : "73310" },
{ code : "73181", intitule : "MOUTIERS", cp : "73600" },
{ code : "73182", intitule : "MOUXY", cp : "73100" },
{ code : "73183", intitule : "MYANS", cp : "73800" },
{ code : "73184", intitule : "NANCES", cp : "73470" },
{ code : "73186", intitule : "NOTRE DAME DE BELLECOMBE", cp : "73590" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73187", intitule : "LA LECHERE", cp : "73260" },
{ code : "73188", intitule : "NOTRE DAME DES MILLIERES", cp : "73460" },
{ code : "73189", intitule : "NOTRE DAME DU CRUET", cp : "73130" },
{ code : "73190", intitule : "NOTRE DAME DU PRE", cp : "73600" },
{ code : "73191", intitule : "NOVALAISE", cp : "73470" },
{ code : "73192", intitule : "LE NOYER", cp : "73340" },
{ code : "73193", intitule : "ONTEX", cp : "73310" },
{ code : "73194", intitule : "ORELLE", cp : "73140" },
{ code : "73196", intitule : "PALLUD", cp : "73200" },
{ code : "73197", intitule : "PEISEY NANCROIX", cp : "73210" },
{ code : "73198", intitule : "LA PERRIERE", cp : "73600" },
{ code : "73198", intitule : "LA PERRIERE", cp : "73120" },
{ code : "73200", intitule : "PLANAISE", cp : "73800" },
{ code : "73201", intitule : "PLANAY", cp : "73350" },
{ code : "73202", intitule : "PLANCHERINE", cp : "73200" },
{ code : "73203", intitule : "PONTAMAFREY MONTPASCAL", cp : "73300" },
{ code : "73203", intitule : "PONTAMAFREY MONTPASCAL", cp : "73300" },
{ code : "73204", intitule : "LE PONT DE BEAUVOISIN", cp : "73330" },
{ code : "73205", intitule : "LE PONTET", cp : "73110" },
{ code : "73206", intitule : "PRALOGNAN LA VANOISE", cp : "73710" },
{ code : "73207", intitule : "PRESLE", cp : "73110" },
{ code : "73208", intitule : "PUGNY CHATENOD", cp : "73100" },
{ code : "73210", intitule : "PUYGROS", cp : "73190" },
{ code : "73211", intitule : "QUEIGE", cp : "73720" },
{ code : "73212", intitule : "RANDENS", cp : "73220" },
{ code : "73213", intitule : "LA RAVOIRE", cp : "73490" },
{ code : "73214", intitule : "ROCHEFORT", cp : "73240" },
{ code : "73215", intitule : "LA ROCHETTE", cp : "73110" },
{ code : "73216", intitule : "ROGNAIX", cp : "73730" },
{ code : "73217", intitule : "ROTHERENS", cp : "73110" },
{ code : "73218", intitule : "RUFFIEUX", cp : "73310" },
{ code : "73219", intitule : "ST ALBAN DE MONTBEL", cp : "73610" },
{ code : "73220", intitule : "ST ALBAN D HURTIERES", cp : "73220" },
{ code : "73221", intitule : "ST ALBAN DES VILLARDS", cp : "73130" },
{ code : "73222", intitule : "ST ALBAN LEYSSE", cp : "73230" },
{ code : "73223", intitule : "ST ANDRE", cp : "73500" },
{ code : "73224", intitule : "ST AVRE", cp : "73130" },
{ code : "73225", intitule : "ST BALDOPH", cp : "73190" },
{ code : "73226", intitule : "ST BERON", cp : "73520" },
{ code : "73227", intitule : "ST BON TARENTAISE", cp : "73120" },
{ code : "73227", intitule : "ST BON TARENTAISE", cp : "73120" },
{ code : "73228", intitule : "ST CASSIN", cp : "73160" },
{ code : "73229", intitule : "ST CHRISTOPHE", cp : "73360" },
{ code : "73230", intitule : "ST COLOMBAN DES VILLARDS", cp : "73130" },
{ code : "73231", intitule : "ST ETIENNE DE CUINES", cp : "73130" },
{ code : "73232", intitule : "STE FOY TARENTAISE", cp : "73640" },
{ code : "73233", intitule : "ST FRANC", cp : "73360" },
{ code : "73234", intitule : "ST FRANCOIS DE SALES", cp : "73340" },
{ code : "73235", intitule : "ST FRANCOIS LONGCHAMP", cp : "73130" },
{ code : "73236", intitule : "ST GENIX SUR GUIERS", cp : "73240" },
{ code : "73237", intitule : "ST GEORGES D HURTIERES", cp : "73220" },
{ code : "73240", intitule : "STE HELENE DU LAC", cp : "73800" },
{ code : "73241", intitule : "STE HELENE SUR ISERE", cp : "73460" },
{ code : "73242", intitule : "ST JEAN D ARVES", cp : "73530" },
{ code : "73243", intitule : "ST JEAN D ARVEY", cp : "73230" },
{ code : "73244", intitule : "ST JEAN DE BELLEVILLE", cp : "73440" },
{ code : "73245", intitule : "ST JEAN DE CHEVELU", cp : "73170" },
{ code : "73246", intitule : "ST JEAN DE COUZ", cp : "73160" },
{ code : "73247", intitule : "ST JEAN DE LA PORTE", cp : "73250" },
{ code : "73248", intitule : "ST JEAN DE MAURIENNE", cp : "73300" },
{ code : "73249", intitule : "ST JEOIRE PRIEURE", cp : "73190" },
{ code : "73250", intitule : "ST JULIEN MONT DENIS", cp : "73870" },
{ code : "73252", intitule : "ST LEGER", cp : "73220" },
{ code : "73253", intitule : "ST MARCEL", cp : "73600" },
{ code : "73254", intitule : "STE MARIE D ALVEY", cp : "73240" },
{ code : "73255", intitule : "STE MARIE DE CUINES", cp : "73130" },
{ code : "73256", intitule : "ST MARTIN D ARC", cp : "73140" },
{ code : "73257", intitule : "LES BELLEVILLE", cp : "73440" },
{ code : "73257", intitule : "LES BELLEVILLE", cp : "73440" },
{ code : "73257", intitule : "LES BELLEVILLE", cp : "73440" },
{ code : "73257", intitule : "LES BELLEVILLE", cp : "73600" },
{ code : "73258", intitule : "ST MARTIN DE LA PORTE", cp : "73140" },
{ code : "73259", intitule : "ST MARTIN SUR LA CHAMBRE", cp : "73130" },
{ code : "73260", intitule : "ST MAURICE DE ROTHERENS", cp : "73240" },
{ code : "73261", intitule : "ST MICHEL DE MAURIENNE", cp : "73140" },
{ code : "73261", intitule : "ST MICHEL DE MAURIENNE", cp : "73140" },
{ code : "73261", intitule : "ST MICHEL DE MAURIENNE", cp : "73140" },
{ code : "73262", intitule : "ST NICOLAS LA CHAPELLE", cp : "73590" },
{ code : "73263", intitule : "ST OFFENGE", cp : "73100" },
{ code : "73263", intitule : "ST OFFENGE", cp : "73100" },
{ code : "73265", intitule : "ST OURS", cp : "73410" },
{ code : "73266", intitule : "ST OYEN", cp : "73260" },
{ code : "73267", intitule : "ST PANCRACE", cp : "73300" },
{ code : "73268", intitule : "ST PAUL SUR ISERE", cp : "73730" },
{ code : "73269", intitule : "ST PAUL", cp : "73170" },
{ code : "73270", intitule : "ST PIERRE D ALBIGNY", cp : "73250" },
{ code : "73271", intitule : "ST PIERRE D ALVEY", cp : "73170" },
{ code : "73272", intitule : "ST PIERRE DE BELLEVILLE", cp : "73220" },
{ code : "73273", intitule : "ST PIERRE DE CURTILLE", cp : "73310" },
{ code : "73274", intitule : "ST PIERRE D ENTREMONT", cp : "73670" },
{ code : "73275", intitule : "ST PIERRE DE GENEBROZ", cp : "73360" },
{ code : "73276", intitule : "ST PIERRE DE SOUCY", cp : "73800" },
{ code : "73277", intitule : "STE REINE", cp : "73630" },
{ code : "73278", intitule : "ST REMY DE MAURIENNE", cp : "73660" },
{ code : "73280", intitule : "ST SORLIN D ARVES", cp : "73530" },
{ code : "73281", intitule : "ST SULPICE", cp : "73160" },
{ code : "73282", intitule : "ST THIBAUD DE COUZ", cp : "73160" },
{ code : "73283", intitule : "ST VITAL", cp : "73460" },
{ code : "73284", intitule : "SALINS FONTAINE", cp : "73600" },
{ code : "73284", intitule : "SALINS FONTAINE", cp : "73600" },
{ code : "73285", intitule : "SEEZ", cp : "73700" },
{ code : "73286", intitule : "SERRIERES EN CHAUTAGNE", cp : "73310" },
{ code : "73287", intitule : "SOLLIERES SARDIERES", cp : "73500" },
{ code : "73288", intitule : "SONNAZ", cp : "73000" },
{ code : "73289", intitule : "LA TABLE", cp : "73110" },
{ code : "73290", intitule : "TERMIGNON", cp : "73500" },
{ code : "73292", intitule : "THENESOL", cp : "73200" },
{ code : "73293", intitule : "THOIRY", cp : "73230" },
{ code : "73294", intitule : "LA THUILE", cp : "73190" },
{ code : "73296", intitule : "TIGNES", cp : "73320" },
{ code : "73297", intitule : "TOURNON", cp : "73460" },
{ code : "73298", intitule : "TOURS EN SAVOIE", cp : "73790" },
{ code : "73299", intitule : "TRAIZE", cp : "73170" },
{ code : "73300", intitule : "TRESSERVE", cp : "73100" },
{ code : "73301", intitule : "TREVIGNIN", cp : "73100" },
{ code : "73302", intitule : "LA TRINITE", cp : "73110" },
{ code : "73303", intitule : "UGINE", cp : "73400" },
{ code : "73304", intitule : "VAL D ISERE", cp : "73150" },
{ code : "73306", intitule : "VALLOIRE", cp : "73450" },
{ code : "73307", intitule : "VALMEINIER", cp : "73450" },
{ code : "73308", intitule : "VENTHON", cp : "73200" },
{ code : "73309", intitule : "VEREL DE MONTBEL", cp : "73330" },
{ code : "73310", intitule : "VEREL PRAGONDRAN", cp : "73230" },
{ code : "73311", intitule : "LE VERNEIL", cp : "73110" },
{ code : "73312", intitule : "VERRENS ARVEY", cp : "73460" },
{ code : "73313", intitule : "VERTHEMEX", cp : "73170" },
{ code : "73314", intitule : "VILLARD D HERY", cp : "73800" },
{ code : "73315", intitule : "VILLARD LEGER", cp : "73390" },
{ code : "73316", intitule : "VILLARD SALLET", cp : "73110" },
{ code : "73317", intitule : "VILLARD SUR DORON", cp : "73270" },
{ code : "73318", intitule : "VILLAREMBERT", cp : "73300" },
{ code : "73318", intitule : "VILLAREMBERT", cp : "73300" },
{ code : "73320", intitule : "VILLARGONDRAN", cp : "73300" },
{ code : "73322", intitule : "VILLARODIN BOURGET", cp : "73500" },
{ code : "73323", intitule : "VILLAROGER", cp : "73640" },
{ code : "73324", intitule : "VILLAROUX", cp : "73110" },
{ code : "73326", intitule : "VIMINES", cp : "73160" },
{ code : "73327", intitule : "VIONS", cp : "73310" },
{ code : "73328", intitule : "VIVIERS DU LAC", cp : "73420" },
{ code : "73329", intitule : "VOGLANS", cp : "73420" },
{ code : "73330", intitule : "YENNE", cp : "73170" },
{ code : "74001", intitule : "ABONDANCE", cp : "74360" },
{ code : "74002", intitule : "ALBY SUR CHERAN", cp : "74540" },
{ code : "74003", intitule : "ALEX", cp : "74290" },
{ code : "74004", intitule : "ALLEVES", cp : "74540" },
{ code : "74005", intitule : "ALLINGES", cp : "74200" },
{ code : "74006", intitule : "ALLONZIER LA CAILLE", cp : "74350" },
{ code : "74006", intitule : "ALLONZIER LA CAILLE", cp : "74350" },
{ code : "74007", intitule : "AMANCY", cp : "74800" },
{ code : "74008", intitule : "AMBILLY", cp : "74100" },
{ code : "74009", intitule : "ANDILLY", cp : "74350" },
{ code : "74009", intitule : "ANDILLY", cp : "74350" },
{ code : "74010", intitule : "ANNECY", cp : "74000" },
{ code : "74011", intitule : "ANNECY LE VIEUX", cp : "74940" },
{ code : "74012", intitule : "ANNEMASSE", cp : "74100" },
{ code : "74013", intitule : "ANTHY SUR LEMAN", cp : "74200" },
{ code : "74014", intitule : "ARACHES LA FRASSE", cp : "74300" },
{ code : "74014", intitule : "ARACHES LA FRASSE", cp : "74300" },
{ code : "74014", intitule : "ARACHES LA FRASSE", cp : "74300" },
{ code : "74015", intitule : "ARBUSIGNY", cp : "74930" },
{ code : "74016", intitule : "ARCHAMPS", cp : "74160" },
{ code : "74018", intitule : "ARENTHON", cp : "74800" },
{ code : "74019", intitule : "ARGONAY", cp : "74370" },
{ code : "74020", intitule : "ARMOY", cp : "74200" },
{ code : "74021", intitule : "ARTHAZ PONT NOTRE DAME", cp : "74380" },
{ code : "74022", intitule : "AVIERNOZ", cp : "74570" },
{ code : "74024", intitule : "AYSE", cp : "74130" },
{ code : "74025", intitule : "BALLAISON", cp : "74140" },
{ code : "74026", intitule : "LA BALME DE SILLINGY", cp : "74330" },
{ code : "74027", intitule : "LA BALME DE THUY", cp : "74230" },
{ code : "74029", intitule : "BASSY", cp : "74910" },
{ code : "74030", intitule : "LA BAUME", cp : "74430" },
{ code : "74031", intitule : "BEAUMONT", cp : "74160" },
{ code : "74032", intitule : "BELLEVAUX", cp : "74470" },
{ code : "74033", intitule : "BERNEX", cp : "74500" },
{ code : "74034", intitule : "LE BIOT", cp : "74430" },
{ code : "74035", intitule : "BLOYE", cp : "74150" },
{ code : "74036", intitule : "BLUFFY", cp : "74290" },
{ code : "74037", intitule : "BOEGE", cp : "74420" },
{ code : "74038", intitule : "BOGEVE", cp : "74250" },
{ code : "74040", intitule : "BONNE", cp : "74380" },
{ code : "74040", intitule : "BONNE", cp : "74380" },
{ code : "74041", intitule : "BONNEVAUX", cp : "74360" },
{ code : "74042", intitule : "BONNEVILLE", cp : "74130" },
{ code : "74043", intitule : "BONS EN CHABLAIS", cp : "74890" },
{ code : "74044", intitule : "BOSSEY", cp : "74160" },
{ code : "74045", intitule : "LE BOUCHET", cp : "74230" },
{ code : "74046", intitule : "BOUSSY", cp : "74150" },
{ code : "74048", intitule : "BRENTHONNE", cp : "74890" },
{ code : "74049", intitule : "BRIZON", cp : "74130" },
{ code : "74050", intitule : "BURDIGNIN", cp : "74420" },
{ code : "74051", intitule : "CERCIER", cp : "74350" },
{ code : "74052", intitule : "CERNEX", cp : "74350" },
{ code : "74053", intitule : "CERVENS", cp : "74550" },
{ code : "74054", intitule : "CHAINAZ LES FRASSES", cp : "74540" },
{ code : "74055", intitule : "CHALLONGES", cp : "74910" },
{ code : "74056", intitule : "CHAMONIX MONT BLANC", cp : "74400" },
{ code : "74056", intitule : "CHAMONIX MONT BLANC", cp : "74400" },
{ code : "74056", intitule : "CHAMONIX MONT BLANC", cp : "74400" },
{ code : "74056", intitule : "CHAMONIX MONT BLANC", cp : "74400" },
{ code : "74057", intitule : "CHAMPANGES", cp : "74500" },
{ code : "74058", intitule : "LA CHAPELLE D ABONDANCE", cp : "74360" },
{ code : "74059", intitule : "LA CHAPELLE RAMBAUD", cp : "74800" },
{ code : "74060", intitule : "LA CHAPELLE ST MAURICE", cp : "74410" },
{ code : "74061", intitule : "CHAPEIRY", cp : "74540" },
{ code : "74062", intitule : "CHARVONNEX", cp : "74370" },
{ code : "74063", intitule : "CHATEL", cp : "74390" },
{ code : "74064", intitule : "CHATILLON SUR CLUSES", cp : "74300" },
{ code : "74065", intitule : "CHAUMONT", cp : "74270" },
{ code : "74066", intitule : "CHAVANNAZ", cp : "74270" },
{ code : "74067", intitule : "CHAVANOD", cp : "74650" },
{ code : "74068", intitule : "CHENE EN SEMINE", cp : "74270" },
{ code : "74069", intitule : "CHENEX", cp : "74520" },
{ code : "74070", intitule : "CHENS SUR LEMAN", cp : "74140" },
{ code : "74071", intitule : "CHESSENAZ", cp : "74270" },
{ code : "74072", intitule : "CHEVALINE", cp : "74210" },
{ code : "74073", intitule : "CHEVENOZ", cp : "74500" },
{ code : "74074", intitule : "CHEVRIER", cp : "74520" },
{ code : "74075", intitule : "CHILLY", cp : "74270" },
{ code : "74076", intitule : "CHOISY", cp : "74330" },
{ code : "74077", intitule : "CLARAFOND ARCINE", cp : "74270" },
{ code : "74077", intitule : "CLARAFOND ARCINE", cp : "74270" },
{ code : "74078", intitule : "CLERMONT", cp : "74270" },
{ code : "74079", intitule : "LES CLEFS", cp : "74230" },
{ code : "74080", intitule : "LA CLUSAZ", cp : "74220" },
{ code : "74081", intitule : "CLUSES", cp : "74300" },
{ code : "74082", intitule : "COLLONGES SOUS SALEVE", cp : "74160" },
{ code : "74083", intitule : "COMBLOUX", cp : "74920" },
{ code : "74085", intitule : "LES CONTAMINES MONTJOIE", cp : "74170" },
{ code : "74086", intitule : "CONTAMINE SARZIN", cp : "74270" },
{ code : "74087", intitule : "CONTAMINE SUR ARVE", cp : "74130" },
{ code : "74088", intitule : "COPPONEX", cp : "74350" },
{ code : "74089", intitule : "CORDON", cp : "74700" },
{ code : "74090", intitule : "CORNIER", cp : "74800" },
{ code : "74091", intitule : "LA COTE D ARBROZ", cp : "74110" },
{ code : "74093", intitule : "CRAN GEVRIER", cp : "74960" },
{ code : "74094", intitule : "CRANVES SALES", cp : "74380" },
{ code : "74095", intitule : "CREMPIGNY BONNEGUETE", cp : "74150" },
{ code : "74095", intitule : "CREMPIGNY BONNEGUETE", cp : "74150" },
{ code : "74096", intitule : "CRUSEILLES", cp : "74350" },
{ code : "74097", intitule : "CUSY", cp : "74540" },
{ code : "74098", intitule : "CUVAT", cp : "74350" },
{ code : "74099", intitule : "DEMI QUARTIER", cp : "74120" },
{ code : "74100", intitule : "DESINGY", cp : "74270" },
{ code : "74101", intitule : "DINGY EN VUACHE", cp : "74520" },
{ code : "74102", intitule : "DINGY ST CLAIR", cp : "74230" },
{ code : "74103", intitule : "DOMANCY", cp : "74700" },
{ code : "74104", intitule : "DOUSSARD", cp : "74210" },
{ code : "74105", intitule : "DOUVAINE", cp : "74140" },
{ code : "74106", intitule : "DRAILLANT", cp : "74550" },
{ code : "74107", intitule : "DROISY", cp : "74270" },
{ code : "74108", intitule : "DUINGT", cp : "74410" },
{ code : "74109", intitule : "ELOISE", cp : "01200" },
{ code : "74110", intitule : "ENTREMONT", cp : "74130" },
{ code : "74111", intitule : "ENTREVERNES", cp : "74410" },
{ code : "74112", intitule : "EPAGNY METZ TESSY", cp : "74330" },
{ code : "74112", intitule : "EPAGNY METZ TESSY", cp : "74370" },
{ code : "74114", intitule : "ESSERT ROMAND", cp : "74110" },
{ code : "74116", intitule : "ETAUX", cp : "74800" },
{ code : "74117", intitule : "ETERCY", cp : "74150" },
{ code : "74118", intitule : "ETREMBIERES", cp : "74100" },
{ code : "74118", intitule : "ETREMBIERES", cp : "74100" },
{ code : "74119", intitule : "EVIAN LES BAINS", cp : "74500" },
{ code : "74120", intitule : "EVIRES", cp : "74570" },
{ code : "74121", intitule : "EXCENEVEX", cp : "74140" },
{ code : "74122", intitule : "FAUCIGNY", cp : "74130" },
{ code : "74123", intitule : "FAVERGES SEYTHENEX", cp : "74210" },
{ code : "74123", intitule : "FAVERGES SEYTHENEX", cp : "74210" },
{ code : "74124", intitule : "FEIGERES", cp : "74160" },
{ code : "74126", intitule : "FESSY", cp : "74890" },
{ code : "74127", intitule : "FETERNES", cp : "74500" },
{ code : "74128", intitule : "FILLINGES", cp : "74250" },
{ code : "74129", intitule : "LA FORCLAZ", cp : "74200" },
{ code : "74130", intitule : "FRANCLENS", cp : "74910" },
{ code : "74131", intitule : "FRANGY", cp : "74270" },
{ code : "74133", intitule : "GAILLARD", cp : "74240" },
{ code : "74134", intitule : "LES GETS", cp : "74260" },
{ code : "74135", intitule : "GIEZ", cp : "74210" },
{ code : "74136", intitule : "LE GRAND BORNAND", cp : "74450" },
{ code : "74137", intitule : "GROISY", cp : "74570" },
{ code : "74138", intitule : "GRUFFY", cp : "74540" },
{ code : "74139", intitule : "HABERE LULLIN", cp : "74420" },
{ code : "74140", intitule : "HABERE POCHE", cp : "74420" },
{ code : "74141", intitule : "HAUTEVILLE SUR FIER", cp : "74150" },
{ code : "74142", intitule : "HERY SUR ALBY", cp : "74540" },
{ code : "74143", intitule : "LES HOUCHES", cp : "74310" },
{ code : "74144", intitule : "JONZIER EPAGNY", cp : "74520" },
{ code : "74145", intitule : "JUVIGNY", cp : "74100" },
{ code : "74146", intitule : "LARRINGES", cp : "74500" },
{ code : "74147", intitule : "LATHUILE", cp : "74210" },
{ code : "74148", intitule : "LESCHAUX", cp : "74320" },
{ code : "74150", intitule : "LOISIN", cp : "74140" },
{ code : "74151", intitule : "LORNAY", cp : "74150" },
{ code : "74152", intitule : "LOVAGNY", cp : "74330" },
{ code : "74153", intitule : "LUCINGES", cp : "74380" },
{ code : "74154", intitule : "LUGRIN", cp : "74500" },
{ code : "74155", intitule : "LULLIN", cp : "74470" },
{ code : "74156", intitule : "LULLY", cp : "74890" },
{ code : "74157", intitule : "LYAUD", cp : "74200" },
{ code : "74158", intitule : "MACHILLY", cp : "74140" },
{ code : "74159", intitule : "MAGLAND", cp : "74300" },
{ code : "74159", intitule : "MAGLAND", cp : "74300" },
{ code : "74160", intitule : "MANIGOD", cp : "74230" },
{ code : "74161", intitule : "MARCELLAZ ALBANAIS", cp : "74150" },
{ code : "74162", intitule : "MARCELLAZ", cp : "74250" },
{ code : "74163", intitule : "MARGENCEL", cp : "74200" },
{ code : "74164", intitule : "MARIGNIER", cp : "74970" },
{ code : "74165", intitule : "MARIGNY ST MARCEL", cp : "74150" },
{ code : "74166", intitule : "MARIN", cp : "74200" },
{ code : "74167", intitule : "VAL DE CHAISE", cp : "74210" },
{ code : "74167", intitule : "VAL DE CHAISE", cp : "74210" },
{ code : "74168", intitule : "MARLIOZ", cp : "74270" },
{ code : "74169", intitule : "MARNAZ", cp : "74460" },
{ code : "74170", intitule : "MASSINGY", cp : "74150" },
{ code : "74171", intitule : "MASSONGY", cp : "74140" },
{ code : "74172", intitule : "MAXILLY SUR LEMAN", cp : "74500" },
{ code : "74173", intitule : "MEGEVE", cp : "74120" },
{ code : "74174", intitule : "MEGEVETTE", cp : "74490" },
{ code : "74175", intitule : "MEILLERIE", cp : "74500" },
{ code : "74176", intitule : "MENTHON ST BERNARD", cp : "74290" },
{ code : "74177", intitule : "MENTHONNEX EN BORNES", cp : "74350" },
{ code : "74178", intitule : "MENTHONNEX SOUS CLERMONT", cp : "74270" },
{ code : "74179", intitule : "MESIGNY", cp : "74330" },
{ code : "74180", intitule : "MESSERY", cp : "74140" },
{ code : "74182", intitule : "MEYTHET", cp : "74960" },
{ code : "74183", intitule : "MIEUSSY", cp : "74440" },
{ code : "74183", intitule : "MIEUSSY", cp : "74440" },
{ code : "74184", intitule : "MINZIER", cp : "74270" },
{ code : "74185", intitule : "MONNETIER MORNEX", cp : "74560" },
{ code : "74185", intitule : "MONNETIER MORNEX", cp : "74560" },
{ code : "74186", intitule : "MONTAGNY LES LANCHES", cp : "74600" },
{ code : "74188", intitule : "MONTRIOND", cp : "74110" },
{ code : "74189", intitule : "MONT SAXONNEX", cp : "74130" },
{ code : "74190", intitule : "MORILLON", cp : "74440" },
{ code : "74191", intitule : "MORZINE", cp : "74110" },
{ code : "74191", intitule : "MORZINE", cp : "74110" },
{ code : "74192", intitule : "MOYE", cp : "74150" },
{ code : "74193", intitule : "LA MURAZ", cp : "74560" },
{ code : "74194", intitule : "MURES", cp : "74540" },
{ code : "74195", intitule : "MUSIEGES", cp : "74270" },
{ code : "74196", intitule : "NANCY SUR CLUSES", cp : "74300" },
{ code : "74197", intitule : "NANGY", cp : "74380" },
{ code : "74198", intitule : "NAVES PARMELAN", cp : "74370" },
{ code : "74199", intitule : "NERNIER", cp : "74140" },
{ code : "74200", intitule : "NEUVECELLE", cp : "74500" },
{ code : "74201", intitule : "NEYDENS", cp : "74160" },
{ code : "74202", intitule : "NONGLARD", cp : "74330" },
{ code : "74203", intitule : "NOVEL", cp : "74500" },
{ code : "74204", intitule : "LES OLLIERES", cp : "74370" },
{ code : "74205", intitule : "ONNION", cp : "74490" },
{ code : "74206", intitule : "ORCIER", cp : "74550" },
{ code : "74208", intitule : "PASSY", cp : "74190" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74190" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74208", intitule : "PASSY", cp : "74480" },
{ code : "74209", intitule : "PEILLONNEX", cp : "74250" },
{ code : "74210", intitule : "PERRIGNIER", cp : "74550" },
{ code : "74211", intitule : "PERS JUSSY", cp : "74930" },
{ code : "74212", intitule : "LE PETIT BORNAND LES GLIERES", cp : "74130" },
{ code : "74213", intitule : "POISY", cp : "74330" },
{ code : "74215", intitule : "PRAZ SUR ARLY", cp : "74120" },
{ code : "74216", intitule : "PRESILLY", cp : "74160" },
{ code : "74217", intitule : "PRINGY", cp : "74370" },
{ code : "74217", intitule : "PRINGY", cp : "74370" },
{ code : "74218", intitule : "PUBLIER", cp : "74500" },
{ code : "74218", intitule : "PUBLIER", cp : "74500" },
{ code : "74219", intitule : "QUINTAL", cp : "74600" },
{ code : "74220", intitule : "REIGNIER ESERY", cp : "74930" },
{ code : "74220", intitule : "REIGNIER ESERY", cp : "74930" },
{ code : "74221", intitule : "LE REPOSOIR", cp : "74950" },
{ code : "74222", intitule : "REYVROZ", cp : "74200" },
{ code : "74223", intitule : "LA RIVIERE ENVERSE", cp : "74440" },
{ code : "74224", intitule : "LA ROCHE SUR FORON", cp : "74800" },
{ code : "74225", intitule : "RUMILLY", cp : "74150" },
{ code : "74226", intitule : "ST ANDRE DE BOEGE", cp : "74420" },
{ code : "74228", intitule : "ST BLAISE", cp : "74350" },
{ code : "74229", intitule : "ST CERGUES", cp : "74140" },
{ code : "74231", intitule : "ST EUSEBE", cp : "74150" },
{ code : "74232", intitule : "ST EUSTACHE", cp : "74410" },
{ code : "74233", intitule : "ST FELIX", cp : "74540" },
{ code : "74234", intitule : "ST FERREOL", cp : "74210" },
{ code : "74235", intitule : "ST GERMAIN SUR RHONE", cp : "74910" },
{ code : "74236", intitule : "ST GERVAIS LES BAINS", cp : "74170" },
{ code : "74236", intitule : "ST GERVAIS LES BAINS", cp : "74170" },
{ code : "74236", intitule : "ST GERVAIS LES BAINS", cp : "74170" },
{ code : "74237", intitule : "ST GINGOLPH", cp : "74500" },
{ code : "74238", intitule : "ST JEAN D AULPS", cp : "74430" },
{ code : "74239", intitule : "ST JEAN DE SIXT", cp : "74450" },
{ code : "74240", intitule : "ST JEAN DE THOLOME", cp : "74250" },
{ code : "74241", intitule : "ST JEOIRE", cp : "74490" },
{ code : "74242", intitule : "ST JORIOZ", cp : "74410" },
{ code : "74243", intitule : "ST JULIEN EN GENEVOIS", cp : "74160" },
{ code : "74244", intitule : "ST LAURENT", cp : "74800" },
{ code : "74245", intitule : "ST MARTIN BELLEVUE", cp : "74370" },
{ code : "74249", intitule : "ST PAUL EN CHABLAIS", cp : "74500" },
{ code : "74250", intitule : "ST PIERRE EN FAUCIGNY", cp : "74800" },
{ code : "74252", intitule : "ST SIGISMOND", cp : "74300" },
{ code : "74253", intitule : "ST SIXT", cp : "74800" },
{ code : "74254", intitule : "ST SYLVESTRE", cp : "74540" },
{ code : "74255", intitule : "SALES", cp : "74150" },
{ code : "74256", intitule : "SALLANCHES", cp : "74700" },
{ code : "74256", intitule : "SALLANCHES", cp : "74700" },
{ code : "74256", intitule : "SALLANCHES", cp : "74700" },
{ code : "74257", intitule : "SALLENOVES", cp : "74270" },
{ code : "74258", intitule : "SAMOENS", cp : "74340" },
{ code : "74259", intitule : "LE SAPPEY", cp : "74350" },
{ code : "74260", intitule : "SAVIGNY", cp : "74520" },
{ code : "74261", intitule : "SAXEL", cp : "74420" },
{ code : "74262", intitule : "SCIENTRIER", cp : "74930" },
{ code : "74263", intitule : "SCIEZ", cp : "74140" },
{ code : "74264", intitule : "SCIONZIER", cp : "74950" },
{ code : "74265", intitule : "SERRAVAL", cp : "74230" },
{ code : "74266", intitule : "SERVOZ", cp : "74310" },
{ code : "74267", intitule : "SEVRIER", cp : "74320" },
{ code : "74268", intitule : "SEYNOD", cp : "74600" },
{ code : "74268", intitule : "SEYNOD", cp : "74600" },
{ code : "74268", intitule : "SEYNOD", cp : "74600" },
{ code : "74269", intitule : "SEYSSEL", cp : "74910" },
{ code : "74271", intitule : "SEYTROUX", cp : "74430" },
{ code : "74272", intitule : "SILLINGY", cp : "74330" },
{ code : "74273", intitule : "SIXT FER A CHEVAL", cp : "74740" },
{ code : "74274", intitule : "VAL DE FIER", cp : "74150" },
{ code : "74274", intitule : "VAL DE FIER", cp : "74150" },
{ code : "74274", intitule : "VAL DE FIER", cp : "74150" },
{ code : "74275", intitule : "TALLOIRES MONTMIN", cp : "74210" },
{ code : "74275", intitule : "TALLOIRES MONTMIN", cp : "74290" },
{ code : "74276", intitule : "TANINGES", cp : "74440" },
{ code : "74276", intitule : "TANINGES", cp : "74440" },
{ code : "74278", intitule : "THYEZ", cp : "74300" },
{ code : "74279", intitule : "THOLLON LES MEMISES", cp : "74500" },
{ code : "74280", intitule : "THONES", cp : "74230" },
{ code : "74281", intitule : "THONON LES BAINS", cp : "74200" },
{ code : "74281", intitule : "THONON LES BAINS", cp : "74200" },
{ code : "74282", intitule : "THORENS GLIERES", cp : "74570" },
{ code : "74283", intitule : "THUSY", cp : "74150" },
{ code : "74284", intitule : "LA TOUR", cp : "74250" },
{ code : "74285", intitule : "USINENS", cp : "74910" },
{ code : "74286", intitule : "VACHERESSE", cp : "74360" },
{ code : "74287", intitule : "VAILLY", cp : "74470" },
{ code : "74288", intitule : "VALLEIRY", cp : "74520" },
{ code : "74289", intitule : "VALLIERES", cp : "74150" },
{ code : "74290", intitule : "VALLORCINE", cp : "74660" },
{ code : "74291", intitule : "VANZY", cp : "74270" },
{ code : "74292", intitule : "VAULX", cp : "74150" },
{ code : "74293", intitule : "VEIGY FONCENEX", cp : "74140" },
{ code : "74294", intitule : "VERCHAIX", cp : "74440" },
{ code : "74295", intitule : "LA VERNAZ", cp : "74200" },
{ code : "74296", intitule : "VERS", cp : "74160" },
{ code : "74297", intitule : "VERSONNEX", cp : "74150" },
{ code : "74298", intitule : "VETRAZ MONTHOUX", cp : "74100" },
{ code : "74299", intitule : "VEYRIER DU LAC", cp : "74290" },
{ code : "74301", intitule : "VILLARD", cp : "74420" },
{ code : "74302", intitule : "LES VILLARDS SUR THONES", cp : "74230" },
{ code : "74303", intitule : "VILLAZ", cp : "74370" },
{ code : "74304", intitule : "VILLE EN SALLAZ", cp : "74250" },
{ code : "74305", intitule : "VILLE LA GRAND", cp : "74100" },
{ code : "74306", intitule : "VILLY LE BOUVERET", cp : "74350" },
{ code : "74307", intitule : "VILLY LE PELLOUX", cp : "74350" },
{ code : "74308", intitule : "VINZIER", cp : "74500" },
{ code : "74309", intitule : "VIRY", cp : "74580" },
{ code : "74310", intitule : "VIUZ LA CHIESAZ", cp : "74540" },
{ code : "74311", intitule : "VIUZ EN SALLAZ", cp : "74250" },
{ code : "74312", intitule : "VOUGY", cp : "74130" },
{ code : "74313", intitule : "VOVRAY EN BORNES", cp : "74350" },
{ code : "74314", intitule : "VULBENS", cp : "74520" },
{ code : "74315", intitule : "YVOIRE", cp : "74140" },
{ code : "75101", intitule : "PARIS 01", cp : "75001" },
{ code : "75102", intitule : "PARIS 02", cp : "75002" },
{ code : "75103", intitule : "PARIS 03", cp : "75003" },
{ code : "75104", intitule : "PARIS 04", cp : "75004" },
{ code : "75105", intitule : "PARIS 05", cp : "75005" },
{ code : "75106", intitule : "PARIS 06", cp : "75006" },
{ code : "75107", intitule : "PARIS 07", cp : "75007" },
{ code : "75108", intitule : "PARIS 08", cp : "75008" },
{ code : "75109", intitule : "PARIS 09", cp : "75009" },
{ code : "75110", intitule : "PARIS 10", cp : "75010" },
{ code : "75111", intitule : "PARIS 11", cp : "75011" },
{ code : "75112", intitule : "PARIS 12", cp : "75012" },
{ code : "75113", intitule : "PARIS 13", cp : "75013" },
{ code : "75114", intitule : "PARIS 14", cp : "75014" },
{ code : "75115", intitule : "PARIS 15", cp : "75015" },
{ code : "75116", intitule : "PARIS 16", cp : "75016" },
{ code : "75116", intitule : "PARIS 16", cp : "75116" },
{ code : "75117", intitule : "PARIS 17", cp : "75017" },
{ code : "75118", intitule : "PARIS 18", cp : "75018" },
{ code : "75119", intitule : "PARIS 19", cp : "75019" },
{ code : "75120", intitule : "PARIS 20", cp : "75020" },
{ code : "76001", intitule : "ALLOUVILLE BELLEFOSSE", cp : "76190" },
{ code : "76002", intitule : "ALVIMARE", cp : "76640" },
{ code : "76004", intitule : "AMBRUMESNIL", cp : "76550" },
{ code : "76005", intitule : "AMFREVILLE LA MI VOIE", cp : "76920" },
{ code : "76006", intitule : "AMFREVILLE LES CHAMPS", cp : "76560" },
{ code : "76007", intitule : "ANCEAUMEVILLE", cp : "76710" },
{ code : "76008", intitule : "ANCOURT", cp : "76370" },
{ code : "76009", intitule : "ANCOURTEVILLE SUR HERICOURT", cp : "76560" },
{ code : "76010", intitule : "ANCRETIEVILLE ST VICTOR", cp : "76760" },
{ code : "76011", intitule : "ANCRETTEVILLE SUR MER", cp : "76540" },
{ code : "76012", intitule : "ANGERVILLE BAILLEUL", cp : "76110" },
{ code : "76013", intitule : "ANGERVILLE LA MARTEL", cp : "76540" },
{ code : "76014", intitule : "ANGERVILLE L ORCHER", cp : "76280" },
{ code : "76015", intitule : "ANGIENS", cp : "76740" },
{ code : "76016", intitule : "ANGLESQUEVILLE LA BRAS LONG", cp : "76740" },
{ code : "76017", intitule : "ANGLESQUEVILLE L ESNEVAL", cp : "76280" },
{ code : "76018", intitule : "VAL DE SAANE", cp : "76890" },
{ code : "76019", intitule : "ANNEVILLE SUR SCIE", cp : "76590" },
{ code : "76020", intitule : "ANNEVILLE AMBOURVILLE", cp : "76480" },
{ code : "76020", intitule : "ANNEVILLE AMBOURVILLE", cp : "76480" },
{ code : "76021", intitule : "ANNOUVILLE VILMESNIL", cp : "76110" },
{ code : "76022", intitule : "ANQUETIERVILLE", cp : "76490" },
{ code : "76023", intitule : "ANVEVILLE", cp : "76560" },
{ code : "76024", intitule : "ARDOUVAL", cp : "76680" },
{ code : "76025", intitule : "ARGUEIL", cp : "76780" },
{ code : "76026", intitule : "ARQUES LA BATAILLE", cp : "76880" },
{ code : "76028", intitule : "AUBEGUIMONT", cp : "76390" },
{ code : "76029", intitule : "AUBERMESNIL AUX ERABLES", cp : "76340" },
{ code : "76030", intitule : "AUBERMESNIL BEAUMAIS", cp : "76550" },
{ code : "76032", intitule : "AUBERVILLE LA MANUEL", cp : "76450" },
{ code : "76033", intitule : "AUBERVILLE LA RENAULT", cp : "76110" },
{ code : "76034", intitule : "AUFFAY", cp : "76720" },
{ code : "76035", intitule : "AUMALE", cp : "76390" },
{ code : "76036", intitule : "AUPPEGARD", cp : "76730" },
{ code : "76038", intitule : "AUTHIEUX RATIEVILLE", cp : "76690" },
{ code : "76039", intitule : "LES AUTHIEUX SUR LE PORT ST OUEN", cp : "76520" },
{ code : "76040", intitule : "AUTIGNY", cp : "76740" },
{ code : "76041", intitule : "AUTRETOT", cp : "76190" },
{ code : "76042", intitule : "AUVILLIERS", cp : "76270" },
{ code : "76043", intitule : "AUZEBOSC", cp : "76190" },
{ code : "76044", intitule : "AUZOUVILLE AUBERBOSC", cp : "76640" },
{ code : "76045", intitule : "AUZOUVILLE L ESNEVAL", cp : "76760" },
{ code : "76046", intitule : "AUZOUVILLE SUR RY", cp : "76116" },
{ code : "76047", intitule : "AUZOUVILLE SUR SAANE", cp : "76730" },
{ code : "76048", intitule : "AVESNES EN BRAY", cp : "76220" },
{ code : "76049", intitule : "AVESNES EN VAL", cp : "76630" },
{ code : "76050", intitule : "AVREMESNIL", cp : "76730" },
{ code : "76051", intitule : "BACQUEVILLE EN CAUX", cp : "76730" },
{ code : "76052", intitule : "BAILLEUL NEUVILLE", cp : "76660" },
{ code : "76053", intitule : "BAILLOLET", cp : "76660" },
{ code : "76054", intitule : "BAILLY EN RIVIERE", cp : "76630" },
{ code : "76055", intitule : "BAONS LE COMTE", cp : "76190" },
{ code : "76056", intitule : "BARDOUVILLE", cp : "76480" },
{ code : "76057", intitule : "BARENTIN", cp : "76360" },
{ code : "76058", intitule : "BAROMESNIL", cp : "76260" },
{ code : "76059", intitule : "BAZINVAL", cp : "76340" },
{ code : "76060", intitule : "BEAUBEC LA ROSIERE", cp : "76440" },
{ code : "76062", intitule : "BEAUMONT LE HARENG", cp : "76850" },
{ code : "76063", intitule : "BEAUVAL EN CAUX", cp : "76890" },
{ code : "76064", intitule : "BEAUREPAIRE", cp : "76280" },
{ code : "76065", intitule : "BEAUSSAULT", cp : "76870" },
{ code : "76066", intitule : "BEAUTOT", cp : "76890" },
{ code : "76067", intitule : "BEAUVOIR EN LYONS", cp : "76220" },
{ code : "76068", intitule : "BEC DE MORTAGNE", cp : "76110" },
{ code : "76069", intitule : "BELBEUF", cp : "76240" },
{ code : "76070", intitule : "BELLENCOMBRE", cp : "76680" },
{ code : "76071", intitule : "BELLENGREVILLE", cp : "76630" },
{ code : "76072", intitule : "BELLEVILLE EN CAUX", cp : "76890" },
{ code : "76074", intitule : "LA BELLIERE", cp : "76440" },
{ code : "76075", intitule : "BELMESNIL", cp : "76590" },
{ code : "76076", intitule : "BENARVILLE", cp : "76110" },
{ code : "76077", intitule : "BENESVILLE", cp : "76560" },
{ code : "76078", intitule : "BENNETOT", cp : "76640" },
{ code : "76079", intitule : "BENOUVILLE", cp : "76790" },
{ code : "76080", intitule : "BERMONVILLE", cp : "76640" },
{ code : "76082", intitule : "BERNIERES", cp : "76210" },
{ code : "76083", intitule : "BERTHEAUVILLE", cp : "76450" },
{ code : "76084", intitule : "BERTREVILLE", cp : "76450" },
{ code : "76085", intitule : "BERTREVILLE ST OUEN", cp : "76590" },
{ code : "76086", intitule : "BERTRIMONT", cp : "76890" },
{ code : "76087", intitule : "BERVILLE", cp : "76560" },
{ code : "76088", intitule : "BERVILLE SUR SEINE", cp : "76480" },
{ code : "76090", intitule : "BEUZEVILLE LA GRENIER", cp : "76210" },
{ code : "76091", intitule : "BEUZEVILLE LA GUERARD", cp : "76450" },
{ code : "76092", intitule : "BEUZEVILLETTE", cp : "76210" },
{ code : "76093", intitule : "BEZANCOURT", cp : "76220" },
{ code : "76094", intitule : "BIERVILLE", cp : "76750" },
{ code : "76095", intitule : "BIHOREL", cp : "76420" },
{ code : "76096", intitule : "BIVILLE LA BAIGNARDE", cp : "76890" },
{ code : "76097", intitule : "BIVILLE LA RIVIERE", cp : "76730" },
{ code : "76099", intitule : "BLACQUEVILLE", cp : "76190" },
{ code : "76100", intitule : "BLAINVILLE CREVON", cp : "76116" },
{ code : "76101", intitule : "BLANGY SUR BRESLE", cp : "76340" },
{ code : "76103", intitule : "BONSECOURS", cp : "76240" },
{ code : "76104", intitule : "BLOSSEVILLE", cp : "76460" },
{ code : "76105", intitule : "LE BOCASSE", cp : "76690" },
{ code : "76106", intitule : "BOIS D ENNEBOURG", cp : "76160" },
{ code : "76107", intitule : "BOIS GUILBERT", cp : "76750" },
{ code : "76108", intitule : "BOIS GUILLAUME", cp : "76230" },
{ code : "76109", intitule : "BOIS HEROULT", cp : "76750" },
{ code : "76110", intitule : "BOIS HIMONT", cp : "76190" },
{ code : "76111", intitule : "BOIS L EVEQUE", cp : "76160" },
{ code : "76112", intitule : "LE BOIS ROBERT", cp : "76590" },
{ code : "76113", intitule : "BOISSAY", cp : "76750" },
{ code : "76114", intitule : "BOLBEC", cp : "76210" },
{ code : "76115", intitule : "BOLLEVILLE", cp : "76210" },
{ code : "76116", intitule : "BOOS", cp : "76520" },
{ code : "76117", intitule : "BORDEAUX ST CLAIR", cp : "76790" },
{ code : "76118", intitule : "BORNAMBUSC", cp : "76110" },
{ code : "76119", intitule : "BOSC BERENGER", cp : "76680" },
{ code : "76120", intitule : "BOSC BORDEL", cp : "76750" },
{ code : "76121", intitule : "BOSC EDELINE", cp : "76750" },
{ code : "76122", intitule : "CALLENGEVILLE", cp : "76270" },
{ code : "76122", intitule : "CALLENGEVILLE", cp : "76270" },
{ code : "76123", intitule : "BOSC GUERARD ST ADRIEN", cp : "76710" },
{ code : "76124", intitule : "BOSC HYONS", cp : "76220" },
{ code : "76125", intitule : "BOSC LE HARD", cp : "76850" },
{ code : "76126", intitule : "BOSC MESNIL", cp : "76680" },
{ code : "76127", intitule : "BOSC ROGER SUR BUCHY", cp : "76750" },
{ code : "76128", intitule : "BOSVILLE", cp : "76450" },
{ code : "76129", intitule : "BOUDEVILLE", cp : "76560" },
{ code : "76130", intitule : "BOUELLES", cp : "76270" },
{ code : "76131", intitule : "LA BOUILLE", cp : "76530" },
{ code : "76132", intitule : "BOURDAINVILLE", cp : "76760" },
{ code : "76133", intitule : "LE BOURG DUN", cp : "76740" },
{ code : "76134", intitule : "BOURVILLE", cp : "76740" },
{ code : "76135", intitule : "BOUVILLE", cp : "76360" },
{ code : "76136", intitule : "BRACHY", cp : "76730" },
{ code : "76138", intitule : "BRACQUETUIT", cp : "76850" },
{ code : "76139", intitule : "BRADIANCOURT", cp : "76680" },
{ code : "76140", intitule : "BRAMETOT", cp : "76740" },
{ code : "76141", intitule : "BREAUTE", cp : "76110" },
{ code : "76142", intitule : "BREMONTIER MERVAL", cp : "76220" },
{ code : "76143", intitule : "BRETTEVILLE DU GRAND CAUX", cp : "76110" },
{ code : "76144", intitule : "BRETTEVILLE ST LAURENT", cp : "76560" },
{ code : "76146", intitule : "BUCHY", cp : "76750" },
{ code : "76147", intitule : "BULLY", cp : "76270" },
{ code : "76148", intitule : "BURES EN BRAY", cp : "76660" },
{ code : "76149", intitule : "BUTOT", cp : "76890" },
{ code : "76151", intitule : "CAILLEVILLE", cp : "76460" },
{ code : "76152", intitule : "CAILLY", cp : "76690" },
{ code : "76153", intitule : "CALLEVILLE LES DEUX EGLISES", cp : "76890" },
{ code : "76154", intitule : "CAMPNEUSEVILLE", cp : "76340" },
{ code : "76155", intitule : "CANEHAN", cp : "76260" },
{ code : "76156", intitule : "CANOUVILLE", cp : "76450" },
{ code : "76157", intitule : "CANTELEU", cp : "76380" },
{ code : "76157", intitule : "CANTELEU", cp : "76380" },
{ code : "76157", intitule : "CANTELEU", cp : "76380" },
{ code : "76158", intitule : "CANVILLE LES DEUX EGLISES", cp : "76560" },
{ code : "76159", intitule : "CANY BARVILLE", cp : "76450" },
{ code : "76160", intitule : "CARVILLE LA FOLLETIERE", cp : "76190" },
{ code : "76161", intitule : "CARVILLE POT DE FER", cp : "76560" },
{ code : "76162", intitule : "LE CATELIER", cp : "76590" },
{ code : "76163", intitule : "CATENAY", cp : "76116" },
{ code : "76164", intitule : "RIVES EN SEINE", cp : "76490" },
{ code : "76164", intitule : "RIVES EN SEINE", cp : "76490" },
{ code : "76164", intitule : "RIVES EN SEINE", cp : "76490" },
{ code : "76165", intitule : "CAUDEBEC LES ELBEUF", cp : "76320" },
{ code : "76166", intitule : "LE CAULE STE BEUVE", cp : "76390" },
{ code : "76167", intitule : "CAUVILLE SUR MER", cp : "76930" },
{ code : "76168", intitule : "LES CENT ACRES", cp : "76590" },
{ code : "76169", intitule : "LA CERLANGUE", cp : "76430" },
{ code : "76170", intitule : "LA CHAPELLE DU BOURGAY", cp : "76590" },
{ code : "76171", intitule : "LA CHAPELLE ST OUEN", cp : "76780" },
{ code : "76172", intitule : "LA CHAPELLE SUR DUN", cp : "76740" },
{ code : "76173", intitule : "LA CHAUSSEE", cp : "76590" },
{ code : "76174", intitule : "CIDEVILLE", cp : "76570" },
{ code : "76175", intitule : "CLAIS", cp : "76660" },
{ code : "76176", intitule : "CLASVILLE", cp : "76450" },
{ code : "76177", intitule : "CLAVILLE MOTTEVILLE", cp : "76690" },
{ code : "76178", intitule : "CLEON", cp : "76410" },
{ code : "76179", intitule : "CLERES", cp : "76690" },
{ code : "76180", intitule : "CLEUVILLE", cp : "76450" },
{ code : "76181", intitule : "CLEVILLE", cp : "76640" },
{ code : "76182", intitule : "CLIPONVILLE", cp : "76640" },
{ code : "76183", intitule : "COLLEVILLE", cp : "76400" },
{ code : "76184", intitule : "COLMESNIL MANNEVILLE", cp : "76550" },
{ code : "76185", intitule : "COMPAINVILLE", cp : "76440" },
{ code : "76186", intitule : "CONTEVILLE", cp : "76390" },
{ code : "76187", intitule : "CONTREMOULINS", cp : "76400" },
{ code : "76188", intitule : "COTTEVRARD", cp : "76850" },
{ code : "76189", intitule : "CRASVILLE LA MALLET", cp : "76450" },
{ code : "76190", intitule : "CRASVILLE LA ROCQUEFORT", cp : "76740" },
{ code : "76191", intitule : "CRESSY", cp : "76720" },
{ code : "76192", intitule : "CRIEL SUR MER", cp : "76910" },
{ code : "76192", intitule : "CRIEL SUR MER", cp : "76910" },
{ code : "76193", intitule : "LA CRIQUE", cp : "76850" },
{ code : "76194", intitule : "CRIQUEBEUF EN CAUX", cp : "76111" },
{ code : "76195", intitule : "CRIQUETOT LE MAUCONDUIT", cp : "76540" },
{ code : "76196", intitule : "CRIQUETOT L ESNEVAL", cp : "76280" },
{ code : "76197", intitule : "CRIQUETOT SUR LONGUEVILLE", cp : "76590" },
{ code : "76198", intitule : "CRIQUETOT SUR OUVILLE", cp : "76760" },
{ code : "76199", intitule : "CRIQUIERS", cp : "76390" },
{ code : "76200", intitule : "CRITOT", cp : "76680" },
{ code : "76201", intitule : "CROISY SUR ANDELLE", cp : "76780" },
{ code : "76202", intitule : "CROIXDALLE", cp : "76660" },
{ code : "76203", intitule : "CROIX MARE", cp : "76190" },
{ code : "76204", intitule : "CROPUS", cp : "76720" },
{ code : "76205", intitule : "CROSVILLE SUR SCIE", cp : "76590" },
{ code : "76206", intitule : "CUVERVILLE", cp : "76280" },
{ code : "76207", intitule : "CUVERVILLE SUR YERES", cp : "76260" },
{ code : "76208", intitule : "CUY ST FIACRE", cp : "76220" },
{ code : "76209", intitule : "DAMPIERRE EN BRAY", cp : "76220" },
{ code : "76210", intitule : "DAMPIERRE ST NICOLAS", cp : "76510" },
{ code : "76211", intitule : "DANCOURT", cp : "76340" },
{ code : "76212", intitule : "DARNETAL", cp : "76160" },
{ code : "76213", intitule : "DAUBEUF SERVILLE", cp : "76110" },
{ code : "76214", intitule : "DENESTANVILLE", cp : "76590" },
{ code : "76216", intitule : "DEVILLE LES ROUEN", cp : "76250" },
{ code : "76217", intitule : "DIEPPE", cp : "76200" },
{ code : "76217", intitule : "DIEPPE", cp : "76370" },
{ code : "76217", intitule : "DIEPPE", cp : "76370" },
{ code : "76218", intitule : "DOUDEAUVILLE", cp : "76220" },
{ code : "76219", intitule : "DOUDEVILLE", cp : "76560" },
{ code : "76220", intitule : "DOUVREND", cp : "76630" },
{ code : "76221", intitule : "DROSAY", cp : "76460" },
{ code : "76222", intitule : "DUCLAIR", cp : "76480" },
{ code : "76223", intitule : "ECALLES ALIX", cp : "76190" },
{ code : "76224", intitule : "ECRAINVILLE", cp : "76110" },
{ code : "76225", intitule : "ECRETTEVILLE LES BAONS", cp : "76190" },
{ code : "76226", intitule : "ECRETTEVILLE SUR MER", cp : "76540" },
{ code : "76227", intitule : "ECTOT L AUBER", cp : "76760" },
{ code : "76228", intitule : "ECTOT LES BAONS", cp : "76970" },
{ code : "76229", intitule : "ELBEUF EN BRAY", cp : "76220" },
{ code : "76230", intitule : "ELBEUF SUR ANDELLE", cp : "76780" },
{ code : "76231", intitule : "ELBEUF", cp : "76500" },
{ code : "76232", intitule : "ELETOT", cp : "76540" },
{ code : "76233", intitule : "ELLECOURT", cp : "76390" },
{ code : "76234", intitule : "EMANVILLE", cp : "76570" },
{ code : "76235", intitule : "ENVERMEU", cp : "76630" },
{ code : "76236", intitule : "ENVRONVILLE", cp : "76640" },
{ code : "76237", intitule : "EPINAY SUR DUCLAIR", cp : "76480" },
{ code : "76238", intitule : "EPOUVILLE", cp : "76133" },
{ code : "76239", intitule : "EPRETOT", cp : "76430" },
{ code : "76240", intitule : "EPREVILLE", cp : "76400" },
{ code : "76241", intitule : "ERMENOUVILLE", cp : "76740" },
{ code : "76242", intitule : "ERNEMONT LA VILLETTE", cp : "76220" },
{ code : "76243", intitule : "ERNEMONT SUR BUCHY", cp : "76750" },
{ code : "76244", intitule : "ESCLAVELLES", cp : "76270" },
{ code : "76245", intitule : "ESLETTES", cp : "76710" },
{ code : "76247", intitule : "ESTEVILLE", cp : "76690" },
{ code : "76248", intitule : "ESTOUTEVILLE ECALLES", cp : "76750" },
{ code : "76249", intitule : "ETAIMPUIS", cp : "76850" },
{ code : "76250", intitule : "ETAINHUS", cp : "76430" },
{ code : "76251", intitule : "ETALLEVILLE", cp : "76560" },
{ code : "76252", intitule : "ETALONDES", cp : "76260" },
{ code : "76253", intitule : "ETOUTTEVILLE", cp : "76190" },
{ code : "76254", intitule : "ETRETAT", cp : "76790" },
{ code : "76255", intitule : "EU", cp : "76260" },
{ code : "76257", intitule : "FALLENCOURT", cp : "76340" },
{ code : "76258", intitule : "FAUVILLE EN CAUX", cp : "76640" },
{ code : "76259", intitule : "FECAMP", cp : "76400" },
{ code : "76260", intitule : "FERRIERES EN BRAY", cp : "76220" },
{ code : "76261", intitule : "LA FERTE ST SAMSON", cp : "76440" },
{ code : "76262", intitule : "FESQUES", cp : "76270" },
{ code : "76263", intitule : "LA FEUILLIE", cp : "76220" },
{ code : "76264", intitule : "FLAMANVILLE", cp : "76970" },
{ code : "76265", intitule : "FLAMETS FRETILS", cp : "76270" },
{ code : "76266", intitule : "FLOCQUES", cp : "76260" },
{ code : "76268", intitule : "FONGUEUSEMARE", cp : "76280" },
{ code : "76269", intitule : "FONTAINE EN BRAY", cp : "76440" },
{ code : "76270", intitule : "FONTAINE LA MALLET", cp : "76290" },
{ code : "76271", intitule : "FONTAINE LE BOURG", cp : "76690" },
{ code : "76272", intitule : "FONTAINE LE DUN", cp : "76740" },
{ code : "76273", intitule : "FONTAINE SOUS PREAUX", cp : "76160" },
{ code : "76274", intitule : "LA FONTELAYE", cp : "76890" },
{ code : "76275", intitule : "FONTENAY", cp : "76290" },
{ code : "76276", intitule : "FORGES LES EAUX", cp : "76440" },
{ code : "76276", intitule : "FORGES LES EAUX", cp : "76440" },
{ code : "76278", intitule : "FOUCARMONT", cp : "76340" },
{ code : "76279", intitule : "FOUCART", cp : "76640" },
{ code : "76280", intitule : "FREAUVILLE", cp : "76660" },
{ code : "76281", intitule : "LA FRENAYE", cp : "76170" },
{ code : "76282", intitule : "FRENEUSE", cp : "76410" },
{ code : "76283", intitule : "FRESLES", cp : "76270" },
{ code : "76284", intitule : "FRESNAY LE LONG", cp : "76850" },
{ code : "76285", intitule : "FRESNE LE PLAN", cp : "76520" },
{ code : "76286", intitule : "FRESNOY FOLNY", cp : "76660" },
{ code : "76287", intitule : "FRESQUIENNES", cp : "76570" },
{ code : "76288", intitule : "FREULLEVILLE", cp : "76510" },
{ code : "76289", intitule : "ST MARTIN DE L IF", cp : "76190" },
{ code : "76289", intitule : "ST MARTIN DE L IF", cp : "76190" },
{ code : "76289", intitule : "ST MARTIN DE L IF", cp : "76190" },
{ code : "76289", intitule : "ST MARTIN DE L IF", cp : "76190" },
{ code : "76290", intitule : "FRICHEMESNIL", cp : "76690" },
{ code : "76291", intitule : "FROBERVILLE", cp : "76400" },
{ code : "76292", intitule : "FRY", cp : "76780" },
{ code : "76293", intitule : "FULTOT", cp : "76560" },
{ code : "76294", intitule : "LA GAILLARDE", cp : "76740" },
{ code : "76295", intitule : "GAILLEFONTAINE", cp : "76870" },
{ code : "76296", intitule : "GAINNEVILLE", cp : "76700" },
{ code : "76297", intitule : "GANCOURT ST ETIENNE", cp : "76220" },
{ code : "76298", intitule : "GANZEVILLE", cp : "76400" },
{ code : "76299", intitule : "GERPONVILLE", cp : "76540" },
{ code : "76300", intitule : "GERVILLE", cp : "76790" },
{ code : "76302", intitule : "GODERVILLE", cp : "76110" },
{ code : "76303", intitule : "GOMMERVILLE", cp : "76430" },
{ code : "76304", intitule : "GONFREVILLE CAILLOT", cp : "76110" },
{ code : "76305", intitule : "GONFREVILLE L ORCHER", cp : "76700" },
{ code : "76305", intitule : "GONFREVILLE L ORCHER", cp : "76700" },
{ code : "76305", intitule : "GONFREVILLE L ORCHER", cp : "76700" },
{ code : "76306", intitule : "GONNETOT", cp : "76730" },
{ code : "76307", intitule : "GONNEVILLE LA MALLET", cp : "76280" },
{ code : "76308", intitule : "GONNEVILLE SUR SCIE", cp : "76590" },
{ code : "76309", intitule : "GONZEVILLE", cp : "76560" },
{ code : "76311", intitule : "GOUPILLIERES", cp : "76570" },
{ code : "76312", intitule : "GOURNAY EN BRAY", cp : "76220" },
{ code : "76313", intitule : "GOUY", cp : "76520" },
{ code : "76314", intitule : "GRAIMBOUVILLE", cp : "76430" },
{ code : "76315", intitule : "GRAINVILLE LA TEINTURIERE", cp : "76450" },
{ code : "76316", intitule : "GRAINVILLE SUR RY", cp : "76116" },
{ code : "76317", intitule : "GRAINVILLE YMAUVILLE", cp : "76110" },
{ code : "76318", intitule : "GRAND CAMP", cp : "76170" },
{ code : "76319", intitule : "GRAND COURONNE", cp : "76530" },
{ code : "76319", intitule : "GRAND COURONNE", cp : "76530" },
{ code : "76320", intitule : "GRANDCOURT", cp : "76660" },
{ code : "76321", intitule : "LES GRANDES VENTES", cp : "76950" },
{ code : "76322", intitule : "LE GRAND QUEVILLY", cp : "76120" },
{ code : "76323", intitule : "GRAVAL", cp : "76270" },
{ code : "76324", intitule : "GREGES", cp : "76370" },
{ code : "76325", intitule : "GREMONVILLE", cp : "76970" },
{ code : "76327", intitule : "GREUVILLE", cp : "76810" },
{ code : "76328", intitule : "GRIGNEUSEVILLE", cp : "76850" },
{ code : "76329", intitule : "GRUCHET LE VALASSE", cp : "76210" },
{ code : "76330", intitule : "GRUCHET ST SIMEON", cp : "76810" },
{ code : "76331", intitule : "GRUGNY", cp : "76690" },
{ code : "76332", intitule : "GRUMESNIL", cp : "76440" },
{ code : "76333", intitule : "GUERVILLE", cp : "76340" },
{ code : "76334", intitule : "GUEURES", cp : "76730" },
{ code : "76335", intitule : "GUEUTTEVILLE", cp : "76890" },
{ code : "76336", intitule : "GUEUTTEVILLE LES GRES", cp : "76460" },
{ code : "76338", intitule : "LA HALLOTIERE", cp : "76780" },
{ code : "76339", intitule : "LE HANOUARD", cp : "76450" },
{ code : "76340", intitule : "HARCANVILLE", cp : "76560" },
{ code : "76341", intitule : "HARFLEUR", cp : "76700" },
{ code : "76342", intitule : "HATTENVILLE", cp : "76640" },
{ code : "76343", intitule : "HAUCOURT", cp : "76440" },
{ code : "76344", intitule : "HAUDRICOURT", cp : "76390" },
{ code : "76345", intitule : "HAUSSEZ", cp : "76440" },
{ code : "76346", intitule : "HAUTOT L AUVRAY", cp : "76450" },
{ code : "76347", intitule : "HAUTOT LE VATOIS", cp : "76190" },
{ code : "76348", intitule : "HAUTOT ST SULPICE", cp : "76190" },
{ code : "76349", intitule : "HAUTOT SUR MER", cp : "76550" },
{ code : "76349", intitule : "HAUTOT SUR MER", cp : "76550" },
{ code : "76350", intitule : "HAUTOT SUR SEINE", cp : "76113" },
{ code : "76351", intitule : "LE HAVRE", cp : "76600" },
{ code : "76351", intitule : "LE HAVRE", cp : "76610" },
{ code : "76351", intitule : "LE HAVRE", cp : "76610" },
{ code : "76351", intitule : "LE HAVRE", cp : "76620" },
{ code : "76352", intitule : "LA HAYE", cp : "76780" },
{ code : "76353", intitule : "HEBERVILLE", cp : "76740" },
{ code : "76354", intitule : "HENOUVILLE", cp : "76840" },
{ code : "76355", intitule : "HERICOURT EN CAUX", cp : "76560" },
{ code : "76356", intitule : "HERMANVILLE", cp : "76730" },
{ code : "76357", intitule : "HERMEVILLE", cp : "76280" },
{ code : "76358", intitule : "LE HERON", cp : "76780" },
{ code : "76359", intitule : "HERONCHELLES", cp : "76750" },
{ code : "76360", intitule : "HEUGLEVILLE SUR SCIE", cp : "76720" },
{ code : "76361", intitule : "HEUQUEVILLE", cp : "76280" },
{ code : "76362", intitule : "HEURTEAUVILLE", cp : "76940" },
{ code : "76363", intitule : "HODENG AU BOSC", cp : "76340" },
{ code : "76364", intitule : "HODENG HODENGER", cp : "76780" },
{ code : "76365", intitule : "HOUDETOT", cp : "76740" },
{ code : "76366", intitule : "LE HOULME", cp : "76770" },
{ code : "76367", intitule : "HOUPPEVILLE", cp : "76770" },
{ code : "76368", intitule : "HOUQUETOT", cp : "76110" },
{ code : "76369", intitule : "LA HOUSSAYE BERANGER", cp : "76690" },
{ code : "76370", intitule : "HUGLEVILLE EN CAUX", cp : "76570" },
{ code : "76371", intitule : "LES IFS", cp : "76630" },
{ code : "76372", intitule : "ILLOIS", cp : "76390" },
{ code : "76373", intitule : "IMBLEVILLE", cp : "76890" },
{ code : "76374", intitule : "INCHEVILLE", cp : "76117" },
{ code : "76375", intitule : "INGOUVILLE", cp : "76460" },
{ code : "76377", intitule : "ISNEAUVILLE", cp : "76230" },
{ code : "76378", intitule : "JUMIEGES", cp : "76480" },
{ code : "76379", intitule : "LAMBERVILLE", cp : "76730" },
{ code : "76380", intitule : "LAMMERVILLE", cp : "76730" },
{ code : "76381", intitule : "LANDES VIEILLES ET NEUVES", cp : "76390" },
{ code : "76382", intitule : "LANQUETOT", cp : "76210" },
{ code : "76383", intitule : "LESTANVILLE", cp : "76730" },
{ code : "76384", intitule : "LILLEBONNE", cp : "76170" },
{ code : "76385", intitule : "LIMESY", cp : "76570" },
{ code : "76386", intitule : "LIMPIVILLE", cp : "76540" },
{ code : "76387", intitule : "LINDEBEUF", cp : "76760" },
{ code : "76388", intitule : "LINTOT", cp : "76210" },
{ code : "76389", intitule : "LINTOT LES BOIS", cp : "76590" },
{ code : "76390", intitule : "LES LOGES", cp : "76790" },
{ code : "76391", intitule : "LA LONDE", cp : "76500" },
{ code : "76392", intitule : "LONDINIERES", cp : "76660" },
{ code : "76393", intitule : "LONGMESNIL", cp : "76440" },
{ code : "76394", intitule : "LONGROY", cp : "76260" },
{ code : "76395", intitule : "LONGUEIL", cp : "76860" },
{ code : "76396", intitule : "LONGUERUE", cp : "76750" },
{ code : "76397", intitule : "LONGUEVILLE SUR SCIE", cp : "76590" },
{ code : "76398", intitule : "LOUVETOT", cp : "76490" },
{ code : "76399", intitule : "LUCY", cp : "76270" },
{ code : "76400", intitule : "LUNERAY", cp : "76810" },
{ code : "76401", intitule : "ARELAUNE EN SEINE", cp : "76940" },
{ code : "76401", intitule : "ARELAUNE EN SEINE", cp : "76940" },
{ code : "76402", intitule : "MALAUNAY", cp : "76770" },
{ code : "76403", intitule : "MALLEVILLE LES GRES", cp : "76450" },
{ code : "76404", intitule : "MANEGLISE", cp : "76133" },
{ code : "76405", intitule : "MANEHOUVILLE", cp : "76590" },
{ code : "76406", intitule : "MANIQUERVILLE", cp : "76400" },
{ code : "76407", intitule : "MANNEVILLE ES PLAINS", cp : "76460" },
{ code : "76408", intitule : "MANNEVILLE LA GOUPIL", cp : "76110" },
{ code : "76409", intitule : "MANNEVILLETTE", cp : "76290" },
{ code : "76410", intitule : "MAROMME", cp : "76150" },
{ code : "76410", intitule : "MAROMME", cp : "76150" },
{ code : "76411", intitule : "MARQUES", cp : "76390" },
{ code : "76412", intitule : "MARTAINVILLE EPREVILLE", cp : "76116" },
{ code : "76413", intitule : "MARTIGNY", cp : "76880" },
{ code : "76414", intitule : "MARTIN EGLISE", cp : "76370" },
{ code : "76415", intitule : "MASSY", cp : "76270" },
{ code : "76416", intitule : "MATHONVILLE", cp : "76680" },
{ code : "76417", intitule : "MAUCOMBLE", cp : "76680" },
{ code : "76418", intitule : "MAULEVRIER STE GERTRUDE", cp : "76490" },
{ code : "76419", intitule : "MAUNY", cp : "76530" },
{ code : "76420", intitule : "MAUQUENCHY", cp : "76440" },
{ code : "76421", intitule : "MELAMARE", cp : "76170" },
{ code : "76422", intitule : "MELLEVILLE", cp : "76260" },
{ code : "76423", intitule : "MENERVAL", cp : "76220" },
{ code : "76424", intitule : "MENONVAL", cp : "76270" },
{ code : "76425", intitule : "MENTHEVILLE", cp : "76110" },
{ code : "76426", intitule : "MESANGUEVILLE", cp : "76780" },
{ code : "76427", intitule : "MESNIERES EN BRAY", cp : "76270" },
{ code : "76428", intitule : "LE MESNIL DURDENT", cp : "76460" },
{ code : "76429", intitule : "LE MESNIL ESNARD", cp : "76240" },
{ code : "76430", intitule : "MESNIL FOLLEMPRISE", cp : "76660" },
{ code : "76431", intitule : "LE MESNIL LIEUBRAY", cp : "76780" },
{ code : "76432", intitule : "MESNIL MAUGER", cp : "76440" },
{ code : "76433", intitule : "MESNIL PANNEVILLE", cp : "76570" },
{ code : "76434", intitule : "MESNIL RAOUL", cp : "76520" },
{ code : "76435", intitule : "LE MESNIL REAUME", cp : "76260" },
{ code : "76436", intitule : "LE MESNIL SOUS JUMIEGES", cp : "76480" },
{ code : "76437", intitule : "MEULERS", cp : "76510" },
{ code : "76438", intitule : "MILLEBOSC", cp : "76260" },
{ code : "76439", intitule : "MIRVILLE", cp : "76210" },
{ code : "76440", intitule : "MOLAGNIES", cp : "76220" },
{ code : "76441", intitule : "MONCHAUX SORENG", cp : "76340" },
{ code : "76442", intitule : "MONCHY SUR EU", cp : "76260" },
{ code : "76443", intitule : "MONT CAUVAIRE", cp : "76690" },
{ code : "76445", intitule : "MONTEROLIER", cp : "76680" },
{ code : "76446", intitule : "MONTIGNY", cp : "76380" },
{ code : "76447", intitule : "MONTIVILLIERS", cp : "76290" },
{ code : "76448", intitule : "MONTMAIN", cp : "76520" },
{ code : "76449", intitule : "MONTREUIL EN CAUX", cp : "76850" },
{ code : "76450", intitule : "MONTROTY", cp : "76220" },
{ code : "76451", intitule : "MONT ST AIGNAN", cp : "76130" },
{ code : "76452", intitule : "MONTVILLE", cp : "76710" },
{ code : "76453", intitule : "MORGNY LA POMMERAYE", cp : "76750" },
{ code : "76454", intitule : "MORTEMER", cp : "76270" },
{ code : "76455", intitule : "MORVILLE SUR ANDELLE", cp : "76780" },
{ code : "76456", intitule : "MOTTEVILLE", cp : "76970" },
{ code : "76457", intitule : "MOULINEAUX", cp : "76530" },
{ code : "76458", intitule : "MUCHEDENT", cp : "76590" },
{ code : "76459", intitule : "NESLE HODENG", cp : "76270" },
{ code : "76460", intitule : "NESLE NORMANDEUSE", cp : "76340" },
{ code : "76461", intitule : "NEUFBOSC", cp : "76680" },
{ code : "76462", intitule : "NEUFCHATEL EN BRAY", cp : "76270" },
{ code : "76463", intitule : "NEUF MARCHE", cp : "76220" },
{ code : "76464", intitule : "LA NEUVILLE CHANT D OISEL", cp : "76520" },
{ code : "76465", intitule : "NEUVILLE FERRIERES", cp : "76270" },
{ code : "76467", intitule : "NEVILLE", cp : "76460" },
{ code : "76468", intitule : "NOINTOT", cp : "76210" },
{ code : "76469", intitule : "NOLLEVAL", cp : "76780" },
{ code : "76470", intitule : "NORMANVILLE", cp : "76640" },
{ code : "76471", intitule : "NORVILLE", cp : "76330" },
{ code : "76472", intitule : "NOTRE DAME D ALIERMONT", cp : "76510" },
{ code : "76473", intitule : "NOTRE DAME DE BLIQUETUIT", cp : "76940" },
{ code : "76474", intitule : "NOTRE DAME DE BONDEVILLE", cp : "76960" },
{ code : "76475", intitule : "FRANQUEVILLE ST PIERRE", cp : "76520" },
{ code : "76476", intitule : "PORT JEROME SUR SEINE", cp : "76170" },
{ code : "76476", intitule : "PORT JEROME SUR SEINE", cp : "76170" },
{ code : "76476", intitule : "PORT JEROME SUR SEINE", cp : "76170" },
{ code : "76476", intitule : "PORT JEROME SUR SEINE", cp : "76330" },
{ code : "76477", intitule : "NOTRE DAME DU BEC", cp : "76133" },
{ code : "76478", intitule : "NOTRE DAME DU PARC", cp : "76590" },
{ code : "76479", intitule : "NULLEMONT", cp : "76390" },
{ code : "76480", intitule : "OCQUEVILLE", cp : "76450" },
{ code : "76481", intitule : "OCTEVILLE SUR MER", cp : "76930" },
{ code : "76482", intitule : "OFFRANVILLE", cp : "76550" },
{ code : "76483", intitule : "OHERVILLE", cp : "76560" },
{ code : "76484", intitule : "OISSEL", cp : "76350" },
{ code : "76485", intitule : "OMONVILLE", cp : "76730" },
{ code : "76486", intitule : "ORIVAL", cp : "76500" },
{ code : "76487", intitule : "OSMOY ST VALERY", cp : "76660" },
{ code : "76488", intitule : "OUAINVILLE", cp : "76450" },
{ code : "76489", intitule : "OUDALLE", cp : "76430" },
{ code : "76490", intitule : "OURVILLE EN CAUX", cp : "76450" },
{ code : "76491", intitule : "OUVILLE L ABBAYE", cp : "76760" },
{ code : "76492", intitule : "OUVILLE LA RIVIERE", cp : "76860" },
{ code : "76493", intitule : "PALUEL", cp : "76450" },
{ code : "76494", intitule : "PARC D ANXTOT", cp : "76210" },
{ code : "76495", intitule : "PAVILLY", cp : "76570" },
{ code : "76497", intitule : "PETIT COURONNE", cp : "76650" },
{ code : "76498", intitule : "LE PETIT QUEVILLY", cp : "76140" },
{ code : "76499", intitule : "PETIVILLE", cp : "76330" },
{ code : "76500", intitule : "PIERRECOURT", cp : "76340" },
{ code : "76501", intitule : "PIERREFIQUES", cp : "76280" },
{ code : "76502", intitule : "PIERREVAL", cp : "76750" },
{ code : "76503", intitule : "PISSY POVILLE", cp : "76360" },
{ code : "76504", intitule : "PLEINE SEVE", cp : "76460" },
{ code : "76505", intitule : "POMMEREUX", cp : "76440" },
{ code : "76506", intitule : "POMMEREVAL", cp : "76680" },
{ code : "76507", intitule : "PONTS ET MARAIS", cp : "76260" },
{ code : "76508", intitule : "LA POTERIE CAP D ANTIFER", cp : "76280" },
{ code : "76509", intitule : "PREAUX", cp : "76160" },
{ code : "76510", intitule : "PRETOT VICQUEMARE", cp : "76560" },
{ code : "76511", intitule : "PREUSEVILLE", cp : "76660" },
{ code : "76512", intitule : "PUISENVAL", cp : "76660" },
{ code : "76513", intitule : "QUEVILLON", cp : "76840" },
{ code : "76514", intitule : "QUEVREVILLE LA POTERIE", cp : "76520" },
{ code : "76515", intitule : "QUIBERVILLE", cp : "76860" },
{ code : "76516", intitule : "QUIEVRECOURT", cp : "76270" },
{ code : "76517", intitule : "QUINCAMPOIX", cp : "76230" },
{ code : "76518", intitule : "RAFFETOT", cp : "76210" },
{ code : "76519", intitule : "RAINFREVILLE", cp : "76730" },
{ code : "76520", intitule : "REALCAMP", cp : "76340" },
{ code : "76521", intitule : "REBETS", cp : "76750" },
{ code : "76522", intitule : "LA REMUEE", cp : "76430" },
{ code : "76523", intitule : "RETONVAL", cp : "76340" },
{ code : "76524", intitule : "REUVILLE", cp : "76560" },
{ code : "76525", intitule : "RICARVILLE", cp : "76640" },
{ code : "76526", intitule : "RICARVILLE DU VAL", cp : "76510" },
{ code : "76527", intitule : "RICHEMONT", cp : "76390" },
{ code : "76528", intitule : "RIEUX", cp : "76340" },
{ code : "76529", intitule : "RIVILLE", cp : "76540" },
{ code : "76530", intitule : "ROBERTOT", cp : "76560" },
{ code : "76531", intitule : "ROCQUEFORT", cp : "76640" },
{ code : "76532", intitule : "ROCQUEMONT", cp : "76680" },
{ code : "76533", intitule : "ROGERVILLE", cp : "76700" },
{ code : "76534", intitule : "ROLLEVILLE", cp : "76133" },
{ code : "76535", intitule : "RONCHEROLLES EN BRAY", cp : "76440" },
{ code : "76536", intitule : "RONCHEROLLES SUR LE VIVIER", cp : "76160" },
{ code : "76537", intitule : "RONCHOIS", cp : "76390" },
{ code : "76538", intitule : "ROSAY", cp : "76680" },
{ code : "76540", intitule : "ROUEN", cp : "76100" },
{ code : "76540", intitule : "ROUEN", cp : "76000" },
{ code : "76541", intitule : "ROUMARE", cp : "76480" },
{ code : "76542", intitule : "ROUTES", cp : "76560" },
{ code : "76543", intitule : "ROUVILLE", cp : "76210" },
{ code : "76544", intitule : "ROUVRAY CATILLON", cp : "76440" },
{ code : "76545", intitule : "ROUXMESNIL BOUTEILLES", cp : "76370" },
{ code : "76546", intitule : "ROYVILLE", cp : "76730" },
{ code : "76547", intitule : "LA RUE ST PIERRE", cp : "76690" },
{ code : "76548", intitule : "RY", cp : "76116" },
{ code : "76549", intitule : "SAANE ST JUST", cp : "76730" },
{ code : "76550", intitule : "SAHURS", cp : "76113" },
{ code : "76551", intitule : "SAINNEVILLE", cp : "76430" },
{ code : "76552", intitule : "STE ADRESSE", cp : "76310" },
{ code : "76553", intitule : "STE AGATHE D ALIERMONT", cp : "76660" },
{ code : "76554", intitule : "ST AIGNAN SUR RY", cp : "76116" },
{ code : "76555", intitule : "ST ANDRE SUR CAILLY", cp : "76690" },
{ code : "76556", intitule : "ST ANTOINE LA FORET", cp : "76170" },
{ code : "76557", intitule : "ST ARNOULT", cp : "76490" },
{ code : "76558", intitule : "ST AUBIN CELLOVILLE", cp : "76520" },
{ code : "76559", intitule : "ST AUBIN DE CRETOT", cp : "76190" },
{ code : "76560", intitule : "ST AUBIN EPINAY", cp : "76160" },
{ code : "76561", intitule : "ST AUBIN LES ELBEUF", cp : "76410" },
{ code : "76562", intitule : "ST AUBIN LE CAUF", cp : "76510" },
{ code : "76563", intitule : "ST AUBIN ROUTOT", cp : "76430" },
{ code : "76564", intitule : "ST AUBIN SUR MER", cp : "76740" },
{ code : "76565", intitule : "ST AUBIN SUR SCIE", cp : "76550" },
{ code : "76566", intitule : "STE AUSTREBERTHE", cp : "76570" },
{ code : "76567", intitule : "STE BEUVE EN RIVIERE", cp : "76270" },
{ code : "76568", intitule : "ST CLAIR SUR LES MONTS", cp : "76190" },
{ code : "76569", intitule : "STE COLOMBE", cp : "76460" },
{ code : "76570", intitule : "ST CRESPIN", cp : "76590" },
{ code : "76571", intitule : "STE CROIX SUR BUCHY", cp : "76750" },
{ code : "76572", intitule : "ST DENIS D ACLON", cp : "76860" },
{ code : "76573", intitule : "ST DENIS LE THIBOULT", cp : "76116" },
{ code : "76574", intitule : "ST DENIS SUR SCIE", cp : "76890" },
{ code : "76575", intitule : "ST ETIENNE DU ROUVRAY", cp : "76800" },
{ code : "76576", intitule : "ST EUSTACHE LA FORET", cp : "76210" },
{ code : "76577", intitule : "STE FOY", cp : "76590" },
{ code : "76578", intitule : "STE GENEVIEVE", cp : "76440" },
{ code : "76580", intitule : "ST GEORGES SUR FONTAINE", cp : "76690" },
{ code : "76581", intitule : "ST GERMAIN DES ESSOURTS", cp : "76750" },
{ code : "76582", intitule : "ST GERMAIN D ETABLES", cp : "76590" },
{ code : "76583", intitule : "ST GERMAIN SOUS CAILLY", cp : "76690" },
{ code : "76584", intitule : "ST GERMAIN SUR EAULNE", cp : "76270" },
{ code : "76585", intitule : "ST GILLES DE CRETOT", cp : "76490" },
{ code : "76586", intitule : "ST GILLES DE LA NEUVILLE", cp : "76430" },
{ code : "76587", intitule : "STE HELENE BONDEVILLE", cp : "76400" },
{ code : "76588", intitule : "ST HELLIER", cp : "76680" },
{ code : "76589", intitule : "ST HONORE", cp : "76590" },
{ code : "76590", intitule : "ST JACQUES D ALIERMONT", cp : "76510" },
{ code : "76591", intitule : "ST JACQUES SUR DARNETAL", cp : "76160" },
{ code : "76592", intitule : "ST JEAN DE FOLLEVILLE", cp : "76170" },
{ code : "76593", intitule : "ST JEAN DE LA NEUVILLE", cp : "76210" },
{ code : "76594", intitule : "ST JEAN DU CARDONNAY", cp : "76150" },
{ code : "76595", intitule : "ST JOUIN BRUNEVAL", cp : "76280" },
{ code : "76596", intitule : "ST LAURENT DE BREVEDENT", cp : "76700" },
{ code : "76597", intitule : "ST LAURENT EN CAUX", cp : "76560" },
{ code : "76598", intitule : "ST LEGER AUX BOIS", cp : "76340" },
{ code : "76599", intitule : "ST LEGER DU BOURG DENIS", cp : "76160" },
{ code : "76600", intitule : "ST LEONARD", cp : "76400" },
{ code : "76602", intitule : "ST MACLOU DE FOLLEVILLE", cp : "76890" },
{ code : "76603", intitule : "ST MACLOU LA BRIERE", cp : "76110" },
{ code : "76604", intitule : "ST MARDS", cp : "76730" },
{ code : "76605", intitule : "STE MARGUERITE SUR MER", cp : "76119" },
{ code : "76606", intitule : "MORIENNE", cp : "76390" },
{ code : "76607", intitule : "STE MARGUERITE SUR FAUVILLE", cp : "76640" },
{ code : "76608", intitule : "STE MARGUERITE SUR DUCLAIR", cp : "76480" },
{ code : "76609", intitule : "STE MARIE AU BOSC", cp : "76280" },
{ code : "76610", intitule : "STE MARIE DES CHAMPS", cp : "76190" },
{ code : "76611", intitule : "ST MARTIN AUX ARBRES", cp : "76760" },
{ code : "76612", intitule : "ST MARTIN AU BOSC", cp : "76340" },
{ code : "76613", intitule : "ST MARTIN AUX BUNEAUX", cp : "76450" },
{ code : "76613", intitule : "ST MARTIN AUX BUNEAUX", cp : "76540" },
{ code : "76614", intitule : "ST MARTIN DE BOSCHERVILLE", cp : "76840" },
{ code : "76615", intitule : "ST MARTIN DU BEC", cp : "76133" },
{ code : "76616", intitule : "ST MARTIN DU MANOIR", cp : "76290" },
{ code : "76617", intitule : "ST MARTIN DU VIVIER", cp : "76160" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76370" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76370" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76370" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76370" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76910" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76370" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76618", intitule : "PETIT CAUX", cp : "76630" },
{ code : "76619", intitule : "ST MARTIN LE GAILLARD", cp : "76260" },
{ code : "76620", intitule : "ST MARTIN L HORTIER", cp : "76270" },
{ code : "76621", intitule : "ST MARTIN OSMONVILLE", cp : "76680" },
{ code : "76622", intitule : "ST MAURICE D ETELAN", cp : "76330" },
{ code : "76623", intitule : "ST MICHEL D HALESCOURT", cp : "76440" },
{ code : "76624", intitule : "ST NICOLAS D ALIERMONT", cp : "76510" },
{ code : "76626", intitule : "ST NICOLAS DE LA HAIE", cp : "76490" },
{ code : "76627", intitule : "ST NICOLAS DE LA TAILLE", cp : "76170" },
{ code : "76628", intitule : "ST OUEN DU BREUIL", cp : "76890" },
{ code : "76629", intitule : "ST OUEN LE MAUGER", cp : "76730" },
{ code : "76630", intitule : "ST OUEN SOUS BAILLY", cp : "76630" },
{ code : "76631", intitule : "ST PAER", cp : "76480" },
{ code : "76632", intitule : "ST PIERRE BENOUVILLE", cp : "76890" },
{ code : "76634", intitule : "ST PIERRE DE MANNEVILLE", cp : "76113" },
{ code : "76635", intitule : "ST PIERRE DES JONQUIERES", cp : "76660" },
{ code : "76636", intitule : "ST PIERRE DE VARENGEVILLE", cp : "76480" },
{ code : "76637", intitule : "ST PIERRE EN PORT", cp : "76540" },
{ code : "76638", intitule : "ST PIERRE EN VAL", cp : "76260" },
{ code : "76639", intitule : "ST PIERRE LAVIS", cp : "76640" },
{ code : "76640", intitule : "ST PIERRE LES ELBEUF", cp : "76320" },
{ code : "76641", intitule : "ST PIERRE LE VIEUX", cp : "76740" },
{ code : "76642", intitule : "ST PIERRE LE VIGER", cp : "76740" },
{ code : "76644", intitule : "ST REMY BOSCROCOURT", cp : "76260" },
{ code : "76645", intitule : "ST RIQUIER EN RIVIERE", cp : "76340" },
{ code : "76646", intitule : "ST RIQUIER ES PLAINS", cp : "76460" },
{ code : "76647", intitule : "ST ROMAIN DE COLBOSC", cp : "76430" },
{ code : "76648", intitule : "ST SAENS", cp : "76680" },
{ code : "76649", intitule : "ST SAIRE", cp : "76270" },
{ code : "76650", intitule : "ST SAUVEUR D EMALLEVILLE", cp : "76110" },
{ code : "76651", intitule : "ST SYLVAIN", cp : "76460" },
{ code : "76652", intitule : "ST VAAST D EQUIQUEVILLE", cp : "76510" },
{ code : "76653", intitule : "ST VAAST DIEPPEDALLE", cp : "76450" },
{ code : "76654", intitule : "ST VAAST DU VAL", cp : "76890" },
{ code : "76655", intitule : "ST VALERY EN CAUX", cp : "76460" },
{ code : "76656", intitule : "ST VICTOR L ABBAYE", cp : "76890" },
{ code : "76657", intitule : "ST VIGOR D YMONVILLE", cp : "76430" },
{ code : "76658", intitule : "ST VINCENT CRAMESNIL", cp : "76430" },
{ code : "76660", intitule : "SANDOUVILLE", cp : "76430" },
{ code : "76662", intitule : "SASSETOT LE MALGARDE", cp : "76730" },
{ code : "76663", intitule : "SASSETOT LE MAUCONDUIT", cp : "76540" },
{ code : "76663", intitule : "SASSETOT LE MAUCONDUIT", cp : "76540" },
{ code : "76663", intitule : "SASSETOT LE MAUCONDUIT", cp : "76540" },
{ code : "76664", intitule : "SASSEVILLE", cp : "76450" },
{ code : "76665", intitule : "SAUCHAY", cp : "76630" },
{ code : "76666", intitule : "SAUMONT LA POTERIE", cp : "76440" },
{ code : "76667", intitule : "SAUQUEVILLE", cp : "76550" },
{ code : "76668", intitule : "SAUSSAY", cp : "76760" },
{ code : "76669", intitule : "SAUSSEUZEMARE EN CAUX", cp : "76110" },
{ code : "76670", intitule : "SENNEVILLE SUR FECAMP", cp : "76400" },
{ code : "76671", intitule : "SEPT MEULES", cp : "76260" },
{ code : "76672", intitule : "SERQUEUX", cp : "76440" },
{ code : "76673", intitule : "SERVAVILLE SALMONVILLE", cp : "76116" },
{ code : "76674", intitule : "SEVIS", cp : "76850" },
{ code : "76675", intitule : "SIERVILLE", cp : "76690" },
{ code : "76676", intitule : "SIGY EN BRAY", cp : "76780" },
{ code : "76676", intitule : "SIGY EN BRAY", cp : "76780" },
{ code : "76677", intitule : "SMERMESNIL", cp : "76660" },
{ code : "76678", intitule : "SOMMERY", cp : "76440" },
{ code : "76679", intitule : "SOMMESNIL", cp : "76560" },
{ code : "76680", intitule : "SORQUAINVILLE", cp : "76540" },
{ code : "76681", intitule : "SOTTEVILLE LES ROUEN", cp : "76300" },
{ code : "76682", intitule : "SOTTEVILLE SOUS LE VAL", cp : "76410" },
{ code : "76683", intitule : "SOTTEVILLE SUR MER", cp : "76740" },
{ code : "76684", intitule : "TANCARVILLE", cp : "76430" },
{ code : "76685", intitule : "THEROULDEVILLE", cp : "76540" },
{ code : "76686", intitule : "THEUVILLE AUX MAILLOTS", cp : "76540" },
{ code : "76688", intitule : "THIERGEVILLE", cp : "76540" },
{ code : "76689", intitule : "THIETREVILLE", cp : "76540" },
{ code : "76690", intitule : "THIL MANNEVILLE", cp : "76730" },
{ code : "76691", intitule : "LE THIL RIBERPRE", cp : "76440" },
{ code : "76692", intitule : "THIOUVILLE", cp : "76450" },
{ code : "76693", intitule : "LE TILLEUL", cp : "76790" },
{ code : "76694", intitule : "TOCQUEVILLE EN CAUX", cp : "76730" },
{ code : "76695", intitule : "TOCQUEVILLE LES MURS", cp : "76110" },
{ code : "76697", intitule : "TORCY LE GRAND", cp : "76590" },
{ code : "76698", intitule : "TORCY LE PETIT", cp : "76590" },
{ code : "76699", intitule : "LE TORP MESNIL", cp : "76560" },
{ code : "76700", intitule : "TOTES", cp : "76890" },
{ code : "76702", intitule : "TOUFFREVILLE LA CORBELINE", cp : "76190" },
{ code : "76703", intitule : "TOUFFREVILLE SUR EU", cp : "76910" },
{ code : "76705", intitule : "TOURVILLE LA RIVIERE", cp : "76410" },
{ code : "76706", intitule : "TOURVILLE LES IFS", cp : "76400" },
{ code : "76707", intitule : "TOURVILLE SUR ARQUES", cp : "76550" },
{ code : "76708", intitule : "TOUSSAINT", cp : "76400" },
{ code : "76709", intitule : "LE TRAIT", cp : "76580" },
{ code : "76710", intitule : "TREMAUVILLE", cp : "76640" },
{ code : "76711", intitule : "LE TREPORT", cp : "76470" },
{ code : "76712", intitule : "LA TRINITE DU MONT", cp : "76170" },
{ code : "76714", intitule : "LES TROIS PIERRES", cp : "76430" },
{ code : "76715", intitule : "TROUVILLE", cp : "76210" },
{ code : "76716", intitule : "TURRETOT", cp : "76280" },
{ code : "76717", intitule : "VAL DE LA HAYE", cp : "76380" },
{ code : "76718", intitule : "VALLIQUERVILLE", cp : "76190" },
{ code : "76719", intitule : "VALMONT", cp : "76540" },
{ code : "76720", intitule : "VARENGEVILLE SUR MER", cp : "76119" },
{ code : "76721", intitule : "VARNEVILLE BRETTEVILLE", cp : "76890" },
{ code : "76723", intitule : "VASSONVILLE", cp : "76890" },
{ code : "76724", intitule : "VATIERVILLE", cp : "76270" },
{ code : "76725", intitule : "VATTETOT SOUS BEAUMONT", cp : "76110" },
{ code : "76726", intitule : "VATTETOT SUR MER", cp : "76111" },
{ code : "76727", intitule : "VATTEVILLE LA RUE", cp : "76940" },
{ code : "76728", intitule : "LA VAUPALIERE", cp : "76150" },
{ code : "76729", intitule : "VEAUVILLE LES BAONS", cp : "76190" },
{ code : "76730", intitule : "VEAUVILLE LES QUELLES", cp : "76560" },
{ code : "76731", intitule : "VENESTANVILLE", cp : "76730" },
{ code : "76732", intitule : "BUTOT VENESVILLE", cp : "76450" },
{ code : "76732", intitule : "BUTOT VENESVILLE", cp : "76450" },
{ code : "76733", intitule : "VENTES ST REMY", cp : "76680" },
{ code : "76734", intitule : "VERGETOT", cp : "76280" },
{ code : "76735", intitule : "VEULES LES ROSES", cp : "76980" },
{ code : "76736", intitule : "VEULETTES SUR MER", cp : "76450" },
{ code : "76737", intitule : "VIBEUF", cp : "76760" },
{ code : "76738", intitule : "VIEUX MANOIR", cp : "76750" },
{ code : "76739", intitule : "VIEUX ROUEN SUR BRESLE", cp : "76390" },
{ code : "76740", intitule : "LA VIEUX RUE", cp : "76160" },
{ code : "76741", intitule : "VILLAINVILLE", cp : "76280" },
{ code : "76743", intitule : "VILLERS ECALLES", cp : "76360" },
{ code : "76744", intitule : "VILLERS SOUS FOUCARMONT", cp : "76340" },
{ code : "76745", intitule : "VILLY SUR YERES", cp : "76260" },
{ code : "76746", intitule : "VINNEMERVILLE", cp : "76540" },
{ code : "76747", intitule : "VIRVILLE", cp : "76110" },
{ code : "76748", intitule : "VITTEFLEUR", cp : "76450" },
{ code : "76749", intitule : "WANCHY CAPVAL", cp : "76660" },
{ code : "76750", intitule : "YAINVILLE", cp : "76480" },
{ code : "76751", intitule : "YEBLERON", cp : "76640" },
{ code : "76752", intitule : "YERVILLE", cp : "76760" },
{ code : "76753", intitule : "YMARE", cp : "76520" },
{ code : "76754", intitule : "YPORT", cp : "76111" },
{ code : "76755", intitule : "YPREVILLE BIVILLE", cp : "76540" },
{ code : "76755", intitule : "YPREVILLE BIVILLE", cp : "76540" },
{ code : "76756", intitule : "YQUEBEUF", cp : "76690" },
{ code : "76757", intitule : "YVECRIQUE", cp : "76560" },
{ code : "76758", intitule : "YVETOT", cp : "76190" },
{ code : "76759", intitule : "YVILLE SUR SEINE", cp : "76530" },
{ code : "77001", intitule : "ACHERES LA FORET", cp : "77760" },
{ code : "77002", intitule : "AMILLIS", cp : "77120" },
{ code : "77003", intitule : "AMPONVILLE", cp : "77760" },
{ code : "77004", intitule : "ANDREZEL", cp : "77390" },
{ code : "77005", intitule : "ANNET SUR MARNE", cp : "77410" },
{ code : "77006", intitule : "ARBONNE LA FORET", cp : "77630" },
{ code : "77007", intitule : "ARGENTIERES", cp : "77390" },
{ code : "77008", intitule : "ARMENTIERES EN BRIE", cp : "77440" },
{ code : "77009", intitule : "ARVILLE", cp : "77890" },
{ code : "77010", intitule : "AUBEPIERRE OZOUER LE REPOS", cp : "77720" },
{ code : "77010", intitule : "AUBEPIERRE OZOUER LE REPOS", cp : "77720" },
{ code : "77011", intitule : "AUFFERVILLE", cp : "77570" },
{ code : "77012", intitule : "AUGERS EN BRIE", cp : "77560" },
{ code : "77013", intitule : "AULNOY", cp : "77120" },
{ code : "77014", intitule : "AVON", cp : "77210" },
{ code : "77015", intitule : "BABY", cp : "77480" },
{ code : "77016", intitule : "BAGNEAUX SUR LOING", cp : "77167" },
{ code : "77018", intitule : "BAILLY ROMAINVILLIERS", cp : "77700" },
{ code : "77019", intitule : "BALLOY", cp : "77118" },
{ code : "77020", intitule : "BANNOST VILLEGAGNON", cp : "77970" },
{ code : "77020", intitule : "BANNOST VILLEGAGNON", cp : "77970" },
{ code : "77021", intitule : "BARBEY", cp : "77130" },
{ code : "77022", intitule : "BARBIZON", cp : "77630" },
{ code : "77023", intitule : "BARCY", cp : "77910" },
{ code : "77024", intitule : "BASSEVELLE", cp : "77750" },
{ code : "77025", intitule : "BAZOCHES LES BRAY", cp : "77118" },
{ code : "77026", intitule : "BEAUCHERY ST MARTIN", cp : "77560" },
{ code : "77026", intitule : "BEAUCHERY ST MARTIN", cp : "77560" },
{ code : "77027", intitule : "BEAUMONT DU GATINAIS", cp : "77890" },
{ code : "77028", intitule : "BEAUTHEIL", cp : "77120" },
{ code : "77029", intitule : "BEAUVOIR", cp : "77390" },
{ code : "77030", intitule : "BELLOT", cp : "77510" },
{ code : "77031", intitule : "BERNAY VILBERT", cp : "77540" },
{ code : "77031", intitule : "BERNAY VILBERT", cp : "77540" },
{ code : "77032", intitule : "BETON BAZOCHES", cp : "77320" },
{ code : "77033", intitule : "BEZALLES", cp : "77970" },
{ code : "77034", intitule : "BLANDY", cp : "77115" },
{ code : "77035", intitule : "BLENNES", cp : "77940" },
{ code : "77036", intitule : "BOISDON", cp : "77970" },
{ code : "77037", intitule : "BOIS LE ROI", cp : "77590" },
{ code : "77038", intitule : "BOISSETTES", cp : "77350" },
{ code : "77039", intitule : "BOISSISE LA BERTRAND", cp : "77350" },
{ code : "77040", intitule : "BOISSISE LE ROI", cp : "77310" },
{ code : "77041", intitule : "BOISSY AUX CAILLES", cp : "77760" },
{ code : "77042", intitule : "BOISSY LE CHATEL", cp : "77169" },
{ code : "77043", intitule : "BOITRON", cp : "77750" },
{ code : "77044", intitule : "BOMBON", cp : "77720" },
{ code : "77045", intitule : "BOUGLIGNY", cp : "77570" },
{ code : "77046", intitule : "BOULANCOURT", cp : "77760" },
{ code : "77047", intitule : "BOULEURS", cp : "77580" },
{ code : "77048", intitule : "BOURRON MARLOTTE", cp : "77780" },
{ code : "77049", intitule : "BOUTIGNY", cp : "77470" },
{ code : "77050", intitule : "BRANSLES", cp : "77620" },
{ code : "77051", intitule : "BRAY SUR SEINE", cp : "77480" },
{ code : "77052", intitule : "BREAU", cp : "77720" },
{ code : "77053", intitule : "BRIE COMTE ROBERT", cp : "77170" },
{ code : "77054", intitule : "LA BROSSE MONTCEAUX", cp : "77940" },
{ code : "77055", intitule : "BROU SUR CHANTEREINE", cp : "77177" },
{ code : "77056", intitule : "BURCY", cp : "77760" },
{ code : "77057", intitule : "BUSSIERES", cp : "77750" },
{ code : "77058", intitule : "BUSSY ST GEORGES", cp : "77600" },
{ code : "77059", intitule : "BUSSY ST MARTIN", cp : "77600" },
{ code : "77059", intitule : "BUSSY ST MARTIN", cp : "77600" },
{ code : "77060", intitule : "BUTHIERS", cp : "77760" },
{ code : "77061", intitule : "CANNES ECLUSE", cp : "77130" },
{ code : "77062", intitule : "CARNETIN", cp : "77400" },
{ code : "77063", intitule : "LA CELLE SUR MORIN", cp : "77515" },
{ code : "77065", intitule : "CELY", cp : "77930" },
{ code : "77066", intitule : "CERNEUX", cp : "77320" },
{ code : "77067", intitule : "CESSON", cp : "77240" },
{ code : "77068", intitule : "CESSOY EN MONTOIS", cp : "77520" },
{ code : "77069", intitule : "CHAILLY EN BIERE", cp : "77930" },
{ code : "77070", intitule : "CHAILLY EN BRIE", cp : "77120" },
{ code : "77071", intitule : "CHAINTREAUX", cp : "77460" },
{ code : "77072", intitule : "CHALAUTRE LA GRANDE", cp : "77171" },
{ code : "77073", intitule : "CHALAUTRE LA PETITE", cp : "77160" },
{ code : "77075", intitule : "CHALIFERT", cp : "77144" },
{ code : "77076", intitule : "CHALMAISON", cp : "77650" },
{ code : "77077", intitule : "CHAMBRY", cp : "77910" },
{ code : "77078", intitule : "CHAMIGNY", cp : "77260" },
{ code : "77079", intitule : "CHAMPAGNE SUR SEINE", cp : "77430" },
{ code : "77080", intitule : "CHAMPCENEST", cp : "77560" },
{ code : "77081", intitule : "CHAMPDEUIL", cp : "77390" },
{ code : "77082", intitule : "CHAMPEAUX", cp : "77720" },
{ code : "77083", intitule : "CHAMPS SUR MARNE", cp : "77420" },
{ code : "77084", intitule : "CHANGIS SUR MARNE", cp : "77660" },
{ code : "77085", intitule : "CHANTELOUP EN BRIE", cp : "77600" },
{ code : "77086", intitule : "LA CHAPELLE GAUTHIER", cp : "77720" },
{ code : "77087", intitule : "LA CHAPELLE IGER", cp : "77540" },
{ code : "77088", intitule : "LA CHAPELLE LA REINE", cp : "77760" },
{ code : "77089", intitule : "LA CHAPELLE RABLAIS", cp : "77370" },
{ code : "77090", intitule : "LA CHAPELLE ST SULPICE", cp : "77160" },
{ code : "77091", intitule : "LES CHAPELLES BOURBON", cp : "77610" },
{ code : "77093", intitule : "LA CHAPELLE MOUTILS", cp : "77320" },
{ code : "77093", intitule : "LA CHAPELLE MOUTILS", cp : "77320" },
{ code : "77094", intitule : "CHARMENTRAY", cp : "77410" },
{ code : "77095", intitule : "CHARNY", cp : "77410" },
{ code : "77096", intitule : "CHARTRETTES", cp : "77590" },
{ code : "77097", intitule : "CHARTRONGES", cp : "77320" },
{ code : "77098", intitule : "CHATEAUBLEAU", cp : "77370" },
{ code : "77099", intitule : "CHATEAU LANDON", cp : "77570" },
{ code : "77100", intitule : "LE CHATELET EN BRIE", cp : "77820" },
{ code : "77101", intitule : "CHATENAY SUR SEINE", cp : "77126" },
{ code : "77102", intitule : "CHATENOY", cp : "77167" },
{ code : "77103", intitule : "CHATILLON LA BORDE", cp : "77820" },
{ code : "77104", intitule : "CHATRES", cp : "77610" },
{ code : "77106", intitule : "CHAUFFRY", cp : "77169" },
{ code : "77107", intitule : "CHAUMES EN BRIE", cp : "77390" },
{ code : "77108", intitule : "CHELLES", cp : "77500" },
{ code : "77108", intitule : "CHELLES", cp : "77500" },
{ code : "77109", intitule : "CHENOISE", cp : "77160" },
{ code : "77110", intitule : "CHENOU", cp : "77570" },
{ code : "77111", intitule : "CHESSY", cp : "77700" },
{ code : "77112", intitule : "CHEVRAINVILLIERS", cp : "77760" },
{ code : "77113", intitule : "CHEVRU", cp : "77320" },
{ code : "77114", intitule : "CHEVRY COSSIGNY", cp : "77173" },
{ code : "77114", intitule : "CHEVRY COSSIGNY", cp : "77173" },
{ code : "77115", intitule : "CHEVRY EN SEREINE", cp : "77710" },
{ code : "77116", intitule : "CHOISY EN BRIE", cp : "77320" },
{ code : "77117", intitule : "CITRY", cp : "77730" },
{ code : "77118", intitule : "CLAYE SOUILLY", cp : "77410" },
{ code : "77118", intitule : "CLAYE SOUILLY", cp : "77410" },
{ code : "77119", intitule : "CLOS FONTAINE", cp : "77370" },
{ code : "77120", intitule : "COCHEREL", cp : "77440" },
{ code : "77121", intitule : "COLLEGIEN", cp : "77090" },
{ code : "77122", intitule : "COMBS LA VILLE", cp : "77380" },
{ code : "77123", intitule : "COMPANS", cp : "77290" },
{ code : "77124", intitule : "CONCHES SUR GONDOIRE", cp : "77600" },
{ code : "77125", intitule : "CONDE STE LIBIAIRE", cp : "77450" },
{ code : "77126", intitule : "CONGIS SUR THEROUANNE", cp : "77440" },
{ code : "77127", intitule : "COUBERT", cp : "77170" },
{ code : "77128", intitule : "COUILLY PONT AUX DAMES", cp : "77860" },
{ code : "77129", intitule : "COULOMBS EN VALOIS", cp : "77840" },
{ code : "77130", intitule : "COULOMMES", cp : "77580" },
{ code : "77131", intitule : "COULOMMIERS", cp : "77120" },
{ code : "77132", intitule : "COUPVRAY", cp : "77700" },
{ code : "77133", intitule : "COURCELLES EN BASSEE", cp : "77126" },
{ code : "77134", intitule : "COURCHAMP", cp : "77560" },
{ code : "77135", intitule : "COURPALAY", cp : "77540" },
{ code : "77136", intitule : "COURQUETAINE", cp : "77390" },
{ code : "77137", intitule : "COURTACON", cp : "77560" },
{ code : "77138", intitule : "COURTOMER", cp : "77390" },
{ code : "77139", intitule : "COURTRY", cp : "77181" },
{ code : "77140", intitule : "COUTENCON", cp : "77154" },
{ code : "77141", intitule : "COUTEVROULT", cp : "77580" },
{ code : "77142", intitule : "CRECY LA CHAPELLE", cp : "77580" },
{ code : "77142", intitule : "CRECY LA CHAPELLE", cp : "77580" },
{ code : "77143", intitule : "CREGY LES MEAUX", cp : "77124" },
{ code : "77144", intitule : "CREVECOEUR EN BRIE", cp : "77610" },
{ code : "77145", intitule : "CRISENOY", cp : "77390" },
{ code : "77146", intitule : "CROISSY BEAUBOURG", cp : "77183" },
{ code : "77146", intitule : "CROISSY BEAUBOURG", cp : "77183" },
{ code : "77147", intitule : "LA CROIX EN BRIE", cp : "77370" },
{ code : "77148", intitule : "CROUY SUR OURCQ", cp : "77840" },
{ code : "77149", intitule : "CUCHARMOY", cp : "77160" },
{ code : "77150", intitule : "CUISY", cp : "77165" },
{ code : "77151", intitule : "DAGNY", cp : "77320" },
{ code : "77152", intitule : "DAMMARIE LES LYS", cp : "77190" },
{ code : "77153", intitule : "DAMMARTIN EN GOELE", cp : "77230" },
{ code : "77154", intitule : "DAMMARTIN SUR TIGEAUX", cp : "77163" },
{ code : "77155", intitule : "DAMPMART", cp : "77400" },
{ code : "77156", intitule : "DARVAULT", cp : "77140" },
{ code : "77157", intitule : "DHUISY", cp : "77440" },
{ code : "77158", intitule : "DIANT", cp : "77940" },
{ code : "77159", intitule : "DONNEMARIE DONTILLY", cp : "77520" },
{ code : "77161", intitule : "DORMELLES", cp : "77130" },
{ code : "77162", intitule : "DOUE", cp : "77510" },
{ code : "77163", intitule : "DOUY LA RAMEE", cp : "77139" },
{ code : "77164", intitule : "ECHOUBOULAINS", cp : "77830" },
{ code : "77165", intitule : "LES ECRENNES", cp : "77820" },
{ code : "77167", intitule : "EGLIGNY", cp : "77126" },
{ code : "77168", intitule : "EGREVILLE", cp : "77620" },
{ code : "77169", intitule : "EMERAINVILLE", cp : "77184" },
{ code : "77169", intitule : "EMERAINVILLE", cp : "77184" },
{ code : "77171", intitule : "ESBLY", cp : "77450" },
{ code : "77172", intitule : "ESMANS", cp : "77940" },
{ code : "77173", intitule : "ETREPILLY", cp : "77139" },
{ code : "77174", intitule : "EVERLY", cp : "77157" },
{ code : "77175", intitule : "EVRY GREGY SUR YERRE", cp : "77166" },
{ code : "77175", intitule : "EVRY GREGY SUR YERRE", cp : "77166" },
{ code : "77176", intitule : "FAREMOUTIERS", cp : "77515" },
{ code : "77177", intitule : "FAVIERES", cp : "77220" },
{ code : "77178", intitule : "FAY LES NEMOURS", cp : "77167" },
{ code : "77179", intitule : "FERICY", cp : "77133" },
{ code : "77180", intitule : "FEROLLES ATTILLY", cp : "77150" },
{ code : "77181", intitule : "FERRIERES EN BRIE", cp : "77164" },
{ code : "77182", intitule : "LA FERTE GAUCHER", cp : "77320" },
{ code : "77183", intitule : "LA FERTE SOUS JOUARRE", cp : "77260" },
{ code : "77184", intitule : "FLAGY", cp : "77940" },
{ code : "77185", intitule : "FLEURY EN BIERE", cp : "77930" },
{ code : "77186", intitule : "FONTAINEBLEAU", cp : "77300" },
{ code : "77187", intitule : "FONTAINE FOURCHES", cp : "77480" },
{ code : "77188", intitule : "FONTAINE LE PORT", cp : "77590" },
{ code : "77190", intitule : "FONTAINS", cp : "77370" },
{ code : "77191", intitule : "FONTENAILLES", cp : "77370" },
{ code : "77192", intitule : "FONTENAY TRESIGNY", cp : "77610" },
{ code : "77193", intitule : "FORFRY", cp : "77165" },
{ code : "77194", intitule : "FORGES", cp : "77130" },
{ code : "77195", intitule : "FOUJU", cp : "77390" },
{ code : "77196", intitule : "FRESNES SUR MARNE", cp : "77410" },
{ code : "77197", intitule : "FRETOY", cp : "77320" },
{ code : "77198", intitule : "FROMONT", cp : "77760" },
{ code : "77199", intitule : "FUBLAINES", cp : "77470" },
{ code : "77200", intitule : "GARENTREVILLE", cp : "77890" },
{ code : "77201", intitule : "GASTINS", cp : "77370" },
{ code : "77202", intitule : "LA GENEVRAYE", cp : "77690" },
{ code : "77203", intitule : "GERMIGNY L EVEQUE", cp : "77910" },
{ code : "77204", intitule : "GERMIGNY SOUS COULOMBS", cp : "77840" },
{ code : "77205", intitule : "GESVRES LE CHAPITRE", cp : "77165" },
{ code : "77206", intitule : "GIREMOUTIERS", cp : "77120" },
{ code : "77207", intitule : "GIRONVILLE", cp : "77890" },
{ code : "77208", intitule : "GOUAIX", cp : "77114" },
{ code : "77209", intitule : "GOUVERNES", cp : "77400" },
{ code : "77210", intitule : "LA GRANDE PAROISSE", cp : "77130" },
{ code : "77211", intitule : "GRANDPUITS BAILLY CARROIS", cp : "77720" },
{ code : "77211", intitule : "GRANDPUITS BAILLY CARROIS", cp : "77720" },
{ code : "77212", intitule : "GRAVON", cp : "77118" },
{ code : "77214", intitule : "GRESSY", cp : "77410" },
{ code : "77215", intitule : "GRETZ ARMAINVILLIERS", cp : "77220" },
{ code : "77216", intitule : "GREZ SUR LOING", cp : "77880" },
{ code : "77217", intitule : "GRISY SUISNES", cp : "77166" },
{ code : "77217", intitule : "GRISY SUISNES", cp : "77166" },
{ code : "77218", intitule : "GRISY SUR SEINE", cp : "77480" },
{ code : "77219", intitule : "GUERARD", cp : "77580" },
{ code : "77220", intitule : "GUERCHEVILLE", cp : "77760" },
{ code : "77221", intitule : "GUERMANTES", cp : "77600" },
{ code : "77222", intitule : "GUIGNES", cp : "77390" },
{ code : "77223", intitule : "GURCY LE CHATEL", cp : "77520" },
{ code : "77223", intitule : "GURCY LE CHATEL", cp : "77520" },
{ code : "77224", intitule : "HAUTEFEUILLE", cp : "77515" },
{ code : "77225", intitule : "LA HAUTE MAISON", cp : "77580" },
{ code : "77226", intitule : "HERICY", cp : "77850" },
{ code : "77227", intitule : "HERME", cp : "77114" },
{ code : "77228", intitule : "HONDEVILLIERS", cp : "77510" },
{ code : "77229", intitule : "LA HOUSSAYE EN BRIE", cp : "77610" },
{ code : "77230", intitule : "ICHY", cp : "77890" },
{ code : "77231", intitule : "ISLES LES MELDEUSES", cp : "77440" },
{ code : "77232", intitule : "ISLES LES VILLENOY", cp : "77450" },
{ code : "77233", intitule : "IVERNY", cp : "77165" },
{ code : "77234", intitule : "JABLINES", cp : "77450" },
{ code : "77235", intitule : "JAIGNES", cp : "77440" },
{ code : "77236", intitule : "JAULNES", cp : "77480" },
{ code : "77237", intitule : "JOSSIGNY", cp : "77600" },
{ code : "77238", intitule : "JOUARRE", cp : "77640" },
{ code : "77239", intitule : "JOUY LE CHATEL", cp : "77970" },
{ code : "77240", intitule : "JOUY SUR MORIN", cp : "77320" },
{ code : "77241", intitule : "JUILLY", cp : "77230" },
{ code : "77242", intitule : "JUTIGNY", cp : "77650" },
{ code : "77243", intitule : "LAGNY SUR MARNE", cp : "77400" },
{ code : "77244", intitule : "LARCHANT", cp : "77760" },
{ code : "77245", intitule : "LAVAL EN BRIE", cp : "77148" },
{ code : "77246", intitule : "LECHELLE", cp : "77171" },
{ code : "77247", intitule : "LESCHEROLLES", cp : "77320" },
{ code : "77248", intitule : "LESCHES", cp : "77450" },
{ code : "77249", intitule : "LESIGNY", cp : "77150" },
{ code : "77250", intitule : "LEUDON EN BRIE", cp : "77320" },
{ code : "77251", intitule : "LIEUSAINT", cp : "77127" },
{ code : "77252", intitule : "LIMOGES FOURCHES", cp : "77550" },
{ code : "77253", intitule : "LISSY", cp : "77550" },
{ code : "77254", intitule : "LIVERDY EN BRIE", cp : "77220" },
{ code : "77255", intitule : "LIVRY SUR SEINE", cp : "77000" },
{ code : "77256", intitule : "LIZINES", cp : "77650" },
{ code : "77257", intitule : "LIZY SUR OURCQ", cp : "77440" },
{ code : "77258", intitule : "LOGNES", cp : "77185" },
{ code : "77259", intitule : "LONGPERRIER", cp : "77230" },
{ code : "77260", intitule : "LONGUEVILLE", cp : "77650" },
{ code : "77261", intitule : "LORREZ LE BOCAGE PREAUX", cp : "77710" },
{ code : "77261", intitule : "LORREZ LE BOCAGE PREAUX", cp : "77710" },
{ code : "77262", intitule : "LOUAN VILLEGRUIS FONTAINE", cp : "77560" },
{ code : "77262", intitule : "LOUAN VILLEGRUIS FONTAINE", cp : "77560" },
{ code : "77262", intitule : "LOUAN VILLEGRUIS FONTAINE", cp : "77560" },
{ code : "77263", intitule : "LUISETAINES", cp : "77520" },
{ code : "77264", intitule : "LUMIGNY NESLES ORMEAUX", cp : "77540" },
{ code : "77264", intitule : "LUMIGNY NESLES ORMEAUX", cp : "77540" },
{ code : "77264", intitule : "LUMIGNY NESLES ORMEAUX", cp : "77540" },
{ code : "77265", intitule : "LUZANCY", cp : "77138" },
{ code : "77266", intitule : "MACHAULT", cp : "77133" },
{ code : "77267", intitule : "LA MADELEINE SUR LOING", cp : "77570" },
{ code : "77268", intitule : "MAGNY LE HONGRE", cp : "77700" },
{ code : "77269", intitule : "MAINCY", cp : "77950" },
{ code : "77270", intitule : "MAISONCELLES EN BRIE", cp : "77580" },
{ code : "77271", intitule : "MAISONCELLES EN GATINAIS", cp : "77570" },
{ code : "77272", intitule : "MAISON ROUGE", cp : "77370" },
{ code : "77273", intitule : "MARCHEMORET", cp : "77230" },
{ code : "77274", intitule : "MARCILLY", cp : "77139" },
{ code : "77275", intitule : "LES MARETS", cp : "77560" },
{ code : "77276", intitule : "MAREUIL LES MEAUX", cp : "77100" },
{ code : "77277", intitule : "MARLES EN BRIE", cp : "77610" },
{ code : "77278", intitule : "MAROLLES EN BRIE", cp : "77120" },
{ code : "77279", intitule : "MAROLLES SUR SEINE", cp : "77130" },
{ code : "77280", intitule : "MARY SUR MARNE", cp : "77440" },
{ code : "77281", intitule : "MAUPERTHUIS", cp : "77120" },
{ code : "77282", intitule : "MAUREGARD", cp : "77990" },
{ code : "77283", intitule : "MAY EN MULTIEN", cp : "77145" },
{ code : "77284", intitule : "MEAUX", cp : "77100" },
{ code : "77285", intitule : "LE MEE SUR SEINE", cp : "77350" },
{ code : "77286", intitule : "MEIGNEUX", cp : "77520" },
{ code : "77287", intitule : "MEILLERAY", cp : "77320" },
{ code : "77288", intitule : "MELUN", cp : "77000" },
{ code : "77289", intitule : "MELZ SUR SEINE", cp : "77171" },
{ code : "77290", intitule : "MERY SUR MARNE", cp : "77730" },
{ code : "77291", intitule : "LE MESNIL AMELOT", cp : "77990" },
{ code : "77292", intitule : "MESSY", cp : "77410" },
{ code : "77293", intitule : "MISY SUR YONNE", cp : "77130" },
{ code : "77294", intitule : "MITRY MORY", cp : "77290" },
{ code : "77295", intitule : "MOISENAY", cp : "77950" },
{ code : "77296", intitule : "MOISSY CRAMAYEL", cp : "77550" },
{ code : "77297", intitule : "MONDREVILLE", cp : "77570" },
{ code : "77298", intitule : "MONS EN MONTOIS", cp : "77520" },
{ code : "77300", intitule : "MONTCEAUX LES MEAUX", cp : "77470" },
{ code : "77301", intitule : "MONTCEAUX LES PROVINS", cp : "77151" },
{ code : "77302", intitule : "MONTCOURT FROMONVILLE", cp : "77140" },
{ code : "77303", intitule : "MONTDAUPHIN", cp : "77320" },
{ code : "77304", intitule : "MONTENILS", cp : "77320" },
{ code : "77305", intitule : "MONTEREAU FAULT YONNE", cp : "77130" },
{ code : "77306", intitule : "MONTEREAU SUR LE JARD", cp : "77950" },
{ code : "77307", intitule : "MONTEVRAIN", cp : "77144" },
{ code : "77308", intitule : "MONTGE EN GOELE", cp : "77230" },
{ code : "77309", intitule : "MONTHYON", cp : "77122" },
{ code : "77310", intitule : "MONTIGNY LE GUESDIER", cp : "77480" },
{ code : "77311", intitule : "MONTIGNY LENCOUP", cp : "77520" },
{ code : "77312", intitule : "MONTIGNY SUR LOING", cp : "77690" },
{ code : "77313", intitule : "MONTMACHOUX", cp : "77940" },
{ code : "77314", intitule : "MONTOLIVET", cp : "77320" },
{ code : "77315", intitule : "MONTRY", cp : "77450" },
{ code : "77316", intitule : "MORET LOING ET ORVANNE", cp : "77250" },
{ code : "77316", intitule : "MORET LOING ET ORVANNE", cp : "77250" },
{ code : "77316", intitule : "MORET LOING ET ORVANNE", cp : "77250" },
{ code : "77316", intitule : "MORET LOING ET ORVANNE", cp : "77250" },
{ code : "77317", intitule : "MORMANT", cp : "77720" },
{ code : "77318", intitule : "MORTCERF", cp : "77163" },
{ code : "77319", intitule : "MORTERY", cp : "77160" },
{ code : "77320", intitule : "MOUROUX", cp : "77120" },
{ code : "77321", intitule : "MOUSSEAUX LES BRAY", cp : "77480" },
{ code : "77322", intitule : "MOUSSY LE NEUF", cp : "77230" },
{ code : "77323", intitule : "MOUSSY LE VIEUX", cp : "77230" },
{ code : "77325", intitule : "MOUY SUR SEINE", cp : "77480" },
{ code : "77326", intitule : "NANDY", cp : "77176" },
{ code : "77327", intitule : "NANGIS", cp : "77370" },
{ code : "77328", intitule : "NANTEAU SUR ESSONNE", cp : "77760" },
{ code : "77329", intitule : "NANTEAU SUR LUNAIN", cp : "77710" },
{ code : "77330", intitule : "NANTEUIL LES MEAUX", cp : "77100" },
{ code : "77331", intitule : "NANTEUIL SUR MARNE", cp : "77730" },
{ code : "77332", intitule : "NANTOUILLET", cp : "77230" },
{ code : "77333", intitule : "NEMOURS", cp : "77140" },
{ code : "77335", intitule : "CHAUCONIN NEUFMONTIERS", cp : "77124" },
{ code : "77335", intitule : "CHAUCONIN NEUFMONTIERS", cp : "77124" },
{ code : "77335", intitule : "CHAUCONIN NEUFMONTIERS", cp : "77124" },
{ code : "77336", intitule : "NEUFMOUTIERS EN BRIE", cp : "77610" },
{ code : "77337", intitule : "NOISIEL", cp : "77186" },
{ code : "77338", intitule : "NOISY RUDIGNON", cp : "77940" },
{ code : "77339", intitule : "NOISY SUR ECOLE", cp : "77123" },
{ code : "77340", intitule : "NONVILLE", cp : "77140" },
{ code : "77341", intitule : "NOYEN SUR SEINE", cp : "77114" },
{ code : "77342", intitule : "OBSONVILLE", cp : "77890" },
{ code : "77343", intitule : "OCQUERRE", cp : "77440" },
{ code : "77344", intitule : "OISSERY", cp : "77178" },
{ code : "77345", intitule : "ORLY SUR MORIN", cp : "77750" },
{ code : "77347", intitule : "LES ORMES SUR VOULZIE", cp : "77134" },
{ code : "77348", intitule : "ORMESSON", cp : "77167" },
{ code : "77349", intitule : "OTHIS", cp : "77280" },
{ code : "77350", intitule : "OZOIR LA FERRIERE", cp : "77330" },
{ code : "77352", intitule : "OZOUER LE VOULGIS", cp : "77390" },
{ code : "77353", intitule : "PALEY", cp : "77710" },
{ code : "77354", intitule : "PAMFOU", cp : "77830" },
{ code : "77355", intitule : "PAROY", cp : "77520" },
{ code : "77356", intitule : "PASSY SUR SEINE", cp : "77480" },
{ code : "77357", intitule : "PECY", cp : "77970" },
{ code : "77358", intitule : "PENCHARD", cp : "77124" },
{ code : "77359", intitule : "PERTHES", cp : "77930" },
{ code : "77360", intitule : "PEZARCHES", cp : "77131" },
{ code : "77361", intitule : "PIERRE LEVEE", cp : "77580" },
{ code : "77363", intitule : "LE PIN", cp : "77181" },
{ code : "77364", intitule : "LE PLESSIS AUX BOIS", cp : "77165" },
{ code : "77365", intitule : "LE PLESSIS FEU AUSSOUX", cp : "77540" },
{ code : "77366", intitule : "LE PLESSIS L EVEQUE", cp : "77165" },
{ code : "77367", intitule : "LE PLESSIS PLACY", cp : "77440" },
{ code : "77368", intitule : "POIGNY", cp : "77160" },
{ code : "77369", intitule : "POINCY", cp : "77470" },
{ code : "77370", intitule : "POLIGNY", cp : "77167" },
{ code : "77371", intitule : "POMMEUSE", cp : "77515" },
{ code : "77372", intitule : "POMPONNE", cp : "77400" },
{ code : "77372", intitule : "POMPONNE", cp : "77400" },
{ code : "77373", intitule : "PONTAULT COMBAULT", cp : "77340" },
{ code : "77373", intitule : "PONTAULT COMBAULT", cp : "77340" },
{ code : "77374", intitule : "PONTCARRE", cp : "77135" },
{ code : "77376", intitule : "PRECY SUR MARNE", cp : "77410" },
{ code : "77377", intitule : "PRESLES EN BRIE", cp : "77220" },
{ code : "77378", intitule : "PRINGY", cp : "77310" },
{ code : "77379", intitule : "PROVINS", cp : "77160" },
{ code : "77380", intitule : "PUISIEUX", cp : "77139" },
{ code : "77381", intitule : "QUIERS", cp : "77720" },
{ code : "77382", intitule : "QUINCY VOISINS", cp : "77860" },
{ code : "77383", intitule : "RAMPILLON", cp : "77370" },
{ code : "77384", intitule : "REAU", cp : "77550" },
{ code : "77385", intitule : "REBAIS", cp : "77510" },
{ code : "77386", intitule : "RECLOSES", cp : "77760" },
{ code : "77387", intitule : "REMAUVILLE", cp : "77710" },
{ code : "77388", intitule : "REUIL EN BRIE", cp : "77260" },
{ code : "77389", intitule : "LA ROCHETTE", cp : "77000" },
{ code : "77390", intitule : "ROISSY EN BRIE", cp : "77680" },
{ code : "77391", intitule : "ROUILLY", cp : "77160" },
{ code : "77392", intitule : "ROUVRES", cp : "77230" },
{ code : "77393", intitule : "ROZAY EN BRIE", cp : "77540" },
{ code : "77394", intitule : "RUBELLES", cp : "77950" },
{ code : "77395", intitule : "RUMONT", cp : "77760" },
{ code : "77396", intitule : "RUPEREUX", cp : "77560" },
{ code : "77397", intitule : "SAACY SUR MARNE", cp : "77730" },
{ code : "77398", intitule : "SABLONNIERES", cp : "77510" },
{ code : "77399", intitule : "ST ANGE LE VIEL", cp : "77710" },
{ code : "77400", intitule : "ST AUGUSTIN", cp : "77515" },
{ code : "77401", intitule : "STE AULDE", cp : "77260" },
{ code : "77402", intitule : "ST BARTHELEMY", cp : "77320" },
{ code : "77403", intitule : "ST BRICE", cp : "77160" },
{ code : "77404", intitule : "STE COLOMBE", cp : "77650" },
{ code : "77405", intitule : "ST CYR SUR MORIN", cp : "77750" },
{ code : "77406", intitule : "ST DENIS LES REBAIS", cp : "77510" },
{ code : "77407", intitule : "ST FARGEAU PONTHIERRY", cp : "77310" },
{ code : "77407", intitule : "ST FARGEAU PONTHIERRY", cp : "77310" },
{ code : "77408", intitule : "ST FIACRE", cp : "77470" },
{ code : "77409", intitule : "ST GERMAIN LAVAL", cp : "77130" },
{ code : "77410", intitule : "ST GERMAIN LAXIS", cp : "77950" },
{ code : "77411", intitule : "ST GERMAIN SOUS DOUE", cp : "77169" },
{ code : "77412", intitule : "ST GERMAIN SUR ECOLE", cp : "77930" },
{ code : "77413", intitule : "ST GERMAIN SUR MORIN", cp : "77860" },
{ code : "77414", intitule : "ST HILLIERS", cp : "77160" },
{ code : "77415", intitule : "ST JEAN LES DEUX JUMEAUX", cp : "77660" },
{ code : "77416", intitule : "ST JUST EN BRIE", cp : "77370" },
{ code : "77417", intitule : "ST LEGER", cp : "77510" },
{ code : "77418", intitule : "ST LOUP DE NAUD", cp : "77650" },
{ code : "77419", intitule : "ST MAMMES", cp : "77670" },
{ code : "77420", intitule : "ST MARD", cp : "77230" },
{ code : "77421", intitule : "ST MARS VIEUX MAISONS", cp : "77320" },
{ code : "77421", intitule : "ST MARS VIEUX MAISONS", cp : "77320" },
{ code : "77423", intitule : "ST MARTIN DES CHAMPS", cp : "77320" },
{ code : "77424", intitule : "ST MARTIN DU BOSCHET", cp : "77320" },
{ code : "77425", intitule : "ST MARTIN EN BIERE", cp : "77630" },
{ code : "77426", intitule : "ST MERY", cp : "77720" },
{ code : "77427", intitule : "ST MESMES", cp : "77410" },
{ code : "77428", intitule : "ST OUEN EN BRIE", cp : "77720" },
{ code : "77429", intitule : "ST OUEN SUR MORIN", cp : "77750" },
{ code : "77430", intitule : "ST PATHUS", cp : "77178" },
{ code : "77431", intitule : "ST PIERRE LES NEMOURS", cp : "77140" },
{ code : "77432", intitule : "ST REMY LA VANNE", cp : "77320" },
{ code : "77433", intitule : "SAINTS", cp : "77120" },
{ code : "77434", intitule : "ST SAUVEUR LES BRAY", cp : "77480" },
{ code : "77435", intitule : "ST SAUVEUR SUR ECOLE", cp : "77930" },
{ code : "77436", intitule : "ST SIMEON", cp : "77169" },
{ code : "77437", intitule : "ST SOUPPLETS", cp : "77165" },
{ code : "77438", intitule : "ST THIBAULT DES VIGNES", cp : "77400" },
{ code : "77439", intitule : "SALINS", cp : "77148" },
{ code : "77440", intitule : "SAMMERON", cp : "77260" },
{ code : "77441", intitule : "SAMOIS SUR SEINE", cp : "77920" },
{ code : "77442", intitule : "SAMOREAU", cp : "77210" },
{ code : "77443", intitule : "SANCY", cp : "77580" },
{ code : "77444", intitule : "SANCY LES PROVINS", cp : "77320" },
{ code : "77445", intitule : "SAVIGNY LE TEMPLE", cp : "77176" },
{ code : "77446", intitule : "SAVINS", cp : "77650" },
{ code : "77447", intitule : "SEINE PORT", cp : "77240" },
{ code : "77448", intitule : "SEPT SORTS", cp : "77260" },
{ code : "77449", intitule : "SERRIS", cp : "77700" },
{ code : "77450", intitule : "SERVON", cp : "77170" },
{ code : "77451", intitule : "SIGNY SIGNETS", cp : "77640" },
{ code : "77452", intitule : "SIGY", cp : "77520" },
{ code : "77453", intitule : "SIVRY COURTRY", cp : "77115" },
{ code : "77454", intitule : "SOGNOLLES EN MONTOIS", cp : "77520" },
{ code : "77455", intitule : "SOIGNOLLES EN BRIE", cp : "77111" },
{ code : "77456", intitule : "SOISY BOUY", cp : "77650" },
{ code : "77457", intitule : "SOLERS", cp : "77111" },
{ code : "77458", intitule : "SOUPPES SUR LOING", cp : "77460" },
{ code : "77459", intitule : "SOURDUN", cp : "77171" },
{ code : "77460", intitule : "TANCROU", cp : "77440" },
{ code : "77461", intitule : "THENISY", cp : "77520" },
{ code : "77462", intitule : "THIEUX", cp : "77230" },
{ code : "77463", intitule : "THOMERY", cp : "77810" },
{ code : "77464", intitule : "THORIGNY SUR MARNE", cp : "77400" },
{ code : "77465", intitule : "THOURY FEROTTES", cp : "77940" },
{ code : "77466", intitule : "TIGEAUX", cp : "77163" },
{ code : "77467", intitule : "LA TOMBE", cp : "77130" },
{ code : "77468", intitule : "TORCY", cp : "77200" },
{ code : "77469", intitule : "TOUQUIN", cp : "77131" },
{ code : "77470", intitule : "TOURNAN EN BRIE", cp : "77220" },
{ code : "77471", intitule : "TOUSSON", cp : "77123" },
{ code : "77472", intitule : "LA TRETOIRE", cp : "77510" },
{ code : "77473", intitule : "TREUZY LEVELAY", cp : "77710" },
{ code : "77474", intitule : "TRILBARDOU", cp : "77450" },
{ code : "77475", intitule : "TRILPORT", cp : "77470" },
{ code : "77476", intitule : "TROCY EN MULTIEN", cp : "77440" },
{ code : "77477", intitule : "URY", cp : "77760" },
{ code : "77478", intitule : "USSY SUR MARNE", cp : "77260" },
{ code : "77479", intitule : "VAIRES SUR MARNE", cp : "77360" },
{ code : "77480", intitule : "VALENCE EN BRIE", cp : "77830" },
{ code : "77481", intitule : "VANVILLE", cp : "77370" },
{ code : "77482", intitule : "VARENNES SUR SEINE", cp : "77130" },
{ code : "77483", intitule : "VARREDDES", cp : "77910" },
{ code : "77484", intitule : "VAUCOURTOIS", cp : "77580" },
{ code : "77485", intitule : "LE VAUDOUE", cp : "77123" },
{ code : "77486", intitule : "VAUDOY EN BRIE", cp : "77141" },
{ code : "77487", intitule : "VAUX LE PENIL", cp : "77000" },
{ code : "77489", intitule : "VAUX SUR LUNAIN", cp : "77710" },
{ code : "77490", intitule : "VENDREST", cp : "77440" },
{ code : "77491", intitule : "VENEUX LES SABLONS", cp : "77250" },
{ code : "77492", intitule : "VERDELOT", cp : "77510" },
{ code : "77493", intitule : "VERNEUIL L ETANG", cp : "77390" },
{ code : "77494", intitule : "VERNOU LA CELLE SUR SEINE", cp : "77670" },
{ code : "77494", intitule : "VERNOU LA CELLE SUR SEINE", cp : "77670" },
{ code : "77495", intitule : "VERT ST DENIS", cp : "77240" },
{ code : "77496", intitule : "VIEUX CHAMPAGNE", cp : "77370" },
{ code : "77498", intitule : "VIGNELY", cp : "77450" },
{ code : "77500", intitule : "VILLEBEON", cp : "77710" },
{ code : "77501", intitule : "VILLECERF", cp : "77250" },
{ code : "77504", intitule : "VILLEMARECHAL", cp : "77710" },
{ code : "77505", intitule : "VILLEMAREUIL", cp : "77470" },
{ code : "77506", intitule : "VILLEMER", cp : "77250" },
{ code : "77507", intitule : "VILLENAUXE LA PETITE", cp : "77480" },
{ code : "77508", intitule : "VILLENEUVE LE COMTE", cp : "77174" },
{ code : "77509", intitule : "VILLENEUVE LES BORDES", cp : "77154" },
{ code : "77510", intitule : "VILLENEUVE ST DENIS", cp : "77174" },
{ code : "77511", intitule : "VILLENEUVE SOUS DAMMARTIN", cp : "77230" },
{ code : "77512", intitule : "VILLENEUVE SUR BELLOT", cp : "77510" },
{ code : "77513", intitule : "VILLENOY", cp : "77124" },
{ code : "77514", intitule : "VILLEPARISIS", cp : "77270" },
{ code : "77515", intitule : "VILLEROY", cp : "77410" },
{ code : "77516", intitule : "VILLE ST JACQUES", cp : "77130" },
{ code : "77517", intitule : "VILLEVAUDE", cp : "77410" },
{ code : "77517", intitule : "VILLEVAUDE", cp : "77410" },
{ code : "77517", intitule : "VILLEVAUDE", cp : "77410" },
{ code : "77518", intitule : "VILLIERS EN BIERE", cp : "77190" },
{ code : "77519", intitule : "VILLIERS ST GEORGES", cp : "77560" },
{ code : "77520", intitule : "VILLIERS SOUS GREZ", cp : "77760" },
{ code : "77521", intitule : "VILLIERS SUR MORIN", cp : "77580" },
{ code : "77522", intitule : "VILLIERS SUR SEINE", cp : "77114" },
{ code : "77523", intitule : "VILLUIS", cp : "77480" },
{ code : "77524", intitule : "VIMPELLES", cp : "77520" },
{ code : "77525", intitule : "VINANTES", cp : "77230" },
{ code : "77526", intitule : "VINCY MANOEUVRE", cp : "77139" },
{ code : "77527", intitule : "VOINSLES", cp : "77540" },
{ code : "77528", intitule : "VOISENON", cp : "77950" },
{ code : "77529", intitule : "VOULANGIS", cp : "77580" },
{ code : "77530", intitule : "VOULTON", cp : "77560" },
{ code : "77531", intitule : "VOULX", cp : "77940" },
{ code : "77532", intitule : "VULAINES LES PROVINS", cp : "77160" },
{ code : "77533", intitule : "VULAINES SUR SEINE", cp : "77870" },
{ code : "77534", intitule : "YEBLES", cp : "77390" },
{ code : "78003", intitule : "ABLIS", cp : "78660" },
{ code : "78005", intitule : "ACHERES", cp : "78260" },
{ code : "78006", intitule : "ADAINVILLE", cp : "78113" },
{ code : "78007", intitule : "AIGREMONT", cp : "78240" },
{ code : "78009", intitule : "ALLAINVILLE", cp : "78660" },
{ code : "78010", intitule : "LES ALLUETS LE ROI", cp : "78580" },
{ code : "78013", intitule : "ANDELU", cp : "78770" },
{ code : "78015", intitule : "ANDRESY", cp : "78570" },
{ code : "78015", intitule : "ANDRESY", cp : "78570" },
{ code : "78020", intitule : "ARNOUVILLE LES MANTES", cp : "78790" },
{ code : "78029", intitule : "AUBERGENVILLE", cp : "78410" },
{ code : "78029", intitule : "AUBERGENVILLE", cp : "78410" },
{ code : "78030", intitule : "AUFFARGIS", cp : "78610" },
{ code : "78031", intitule : "AUFFREVILLE BRASSEUIL", cp : "78930" },
{ code : "78033", intitule : "AULNAY SUR MAULDRE", cp : "78126" },
{ code : "78034", intitule : "AUTEUIL", cp : "78770" },
{ code : "78036", intitule : "AUTOUILLET", cp : "78770" },
{ code : "78043", intitule : "BAILLY", cp : "78870" },
{ code : "78048", intitule : "BAZAINVILLE", cp : "78550" },
{ code : "78049", intitule : "BAZEMONT", cp : "78580" },
{ code : "78050", intitule : "BAZOCHES SUR GUYONNE", cp : "78490" },
{ code : "78053", intitule : "BEHOUST", cp : "78910" },
{ code : "78057", intitule : "BENNECOURT", cp : "78270" },
{ code : "78062", intitule : "BEYNES", cp : "78650" },
{ code : "78062", intitule : "BEYNES", cp : "78650" },
{ code : "78062", intitule : "BEYNES", cp : "78650" },
{ code : "78068", intitule : "BLARU", cp : "78270" },
{ code : "78070", intitule : "BOINVILLE EN MANTOIS", cp : "78930" },
{ code : "78071", intitule : "BOINVILLE LE GAILLARD", cp : "78660" },
{ code : "78072", intitule : "BOINVILLIERS", cp : "78200" },
{ code : "78073", intitule : "BOIS D ARCY", cp : "78390" },
{ code : "78076", intitule : "BOISSETS", cp : "78910" },
{ code : "78077", intitule : "LA BOISSIERE ECOLE", cp : "78125" },
{ code : "78082", intitule : "BOISSY MAUVOISIN", cp : "78200" },
{ code : "78084", intitule : "BOISSY SANS AVOIR", cp : "78490" },
{ code : "78087", intitule : "BONNELLES", cp : "78830" },
{ code : "78089", intitule : "BONNIERES SUR SEINE", cp : "78270" },
{ code : "78090", intitule : "BOUAFLE", cp : "78410" },
{ code : "78092", intitule : "BOUGIVAL", cp : "78380" },
{ code : "78096", intitule : "BOURDONNE", cp : "78113" },
{ code : "78104", intitule : "BREUIL BOIS ROBERT", cp : "78930" },
{ code : "78107", intitule : "BREVAL", cp : "78980" },
{ code : "78108", intitule : "LES BREVIAIRES", cp : "78610" },
{ code : "78113", intitule : "BRUEIL EN VEXIN", cp : "78440" },
{ code : "78117", intitule : "BUC", cp : "78530" },
{ code : "78118", intitule : "BUCHELAY", cp : "78200" },
{ code : "78120", intitule : "BULLION", cp : "78830" },
{ code : "78123", intitule : "CARRIERES SOUS POISSY", cp : "78955" },
{ code : "78123", intitule : "CARRIERES SOUS POISSY", cp : "78955" },
{ code : "78124", intitule : "CARRIERES SUR SEINE", cp : "78420" },
{ code : "78125", intitule : "LA CELLE LES BORDES", cp : "78720" },
{ code : "78126", intitule : "LA CELLE ST CLOUD", cp : "78170" },
{ code : "78128", intitule : "CERNAY LA VILLE", cp : "78720" },
{ code : "78133", intitule : "CHAMBOURCY", cp : "78240" },
{ code : "78138", intitule : "CHANTELOUP LES VIGNES", cp : "78570" },
{ code : "78140", intitule : "CHAPET", cp : "78130" },
{ code : "78143", intitule : "CHATEAUFORT", cp : "78117" },
{ code : "78146", intitule : "CHATOU", cp : "78400" },
{ code : "78147", intitule : "CHAUFOUR LES BONNIERES", cp : "78270" },
{ code : "78152", intitule : "CHAVENAY", cp : "78450" },
{ code : "78158", intitule : "LE CHESNAY", cp : "78150" },
{ code : "78158", intitule : "LE CHESNAY", cp : "78150" },
{ code : "78160", intitule : "CHEVREUSE", cp : "78460" },
{ code : "78162", intitule : "CHOISEL", cp : "78460" },
{ code : "78163", intitule : "CIVRY LA FORET", cp : "78910" },
{ code : "78164", intitule : "CLAIREFONTAINE EN YVELINES", cp : "78120" },
{ code : "78165", intitule : "LES CLAYES SOUS BOIS", cp : "78340" },
{ code : "78168", intitule : "COIGNIERES", cp : "78310" },
{ code : "78171", intitule : "CONDE SUR VESGRE", cp : "78113" },
{ code : "78172", intitule : "CONFLANS STE HONORINE", cp : "78700" },
{ code : "78185", intitule : "COURGENT", cp : "78790" },
{ code : "78188", intitule : "CRAVENT", cp : "78270" },
{ code : "78189", intitule : "CRESPIERES", cp : "78121" },
{ code : "78190", intitule : "CROISSY SUR SEINE", cp : "78290" },
{ code : "78192", intitule : "DAMMARTIN EN SERVE", cp : "78111" },
{ code : "78193", intitule : "DAMPIERRE EN YVELINES", cp : "78720" },
{ code : "78193", intitule : "DAMPIERRE EN YVELINES", cp : "78720" },
{ code : "78194", intitule : "DANNEMARIE", cp : "78550" },
{ code : "78196", intitule : "DAVRON", cp : "78810" },
{ code : "78202", intitule : "DROCOURT", cp : "78440" },
{ code : "78206", intitule : "ECQUEVILLY", cp : "78920" },
{ code : "78208", intitule : "ELANCOURT", cp : "78990" },
{ code : "78209", intitule : "EMANCE", cp : "78125" },
{ code : "78217", intitule : "EPONE", cp : "78680" },
{ code : "78220", intitule : "LES ESSARTS LE ROI", cp : "78690" },
{ code : "78220", intitule : "LES ESSARTS LE ROI", cp : "78690" },
{ code : "78224", intitule : "L ETANG LA VILLE", cp : "78620" },
{ code : "78227", intitule : "EVECQUEMONT", cp : "78740" },
{ code : "78230", intitule : "LA FALAISE", cp : "78410" },
{ code : "78231", intitule : "FAVRIEUX", cp : "78200" },
{ code : "78233", intitule : "FEUCHEROLLES", cp : "78810" },
{ code : "78234", intitule : "FLACOURT", cp : "78200" },
{ code : "78236", intitule : "FLEXANVILLE", cp : "78910" },
{ code : "78237", intitule : "FLINS NEUVE EGLISE", cp : "78790" },
{ code : "78238", intitule : "FLINS SUR SEINE", cp : "78410" },
{ code : "78239", intitule : "FOLLAINVILLE DENNEMONT", cp : "78520" },
{ code : "78242", intitule : "FONTENAY LE FLEURY", cp : "78330" },
{ code : "78245", intitule : "FONTENAY MAUVOISIN", cp : "78200" },
{ code : "78246", intitule : "FONTENAY ST PERE", cp : "78440" },
{ code : "78251", intitule : "FOURQUEUX", cp : "78112" },
{ code : "78255", intitule : "FRENEUSE", cp : "78840" },
{ code : "78261", intitule : "GAILLON SUR MONTCIENT", cp : "78250" },
{ code : "78262", intitule : "GALLUIS", cp : "78490" },
{ code : "78263", intitule : "GAMBAIS", cp : "78950" },
{ code : "78264", intitule : "GAMBAISEUIL", cp : "78490" },
{ code : "78265", intitule : "GARANCIERES", cp : "78890" },
{ code : "78267", intitule : "GARGENVILLE", cp : "78440" },
{ code : "78269", intitule : "GAZERAN", cp : "78125" },
{ code : "78276", intitule : "GOMMECOURT", cp : "78270" },
{ code : "78278", intitule : "GOUPILLIERES", cp : "78770" },
{ code : "78281", intitule : "GOUSSONVILLE", cp : "78930" },
{ code : "78283", intitule : "GRANDCHAMP", cp : "78113" },
{ code : "78285", intitule : "GRESSEY", cp : "78550" },
{ code : "78289", intitule : "GROSROUVRE", cp : "78490" },
{ code : "78290", intitule : "GUERNES", cp : "78520" },
{ code : "78291", intitule : "GUERVILLE", cp : "78930" },
{ code : "78296", intitule : "GUITRANCOURT", cp : "78440" },
{ code : "78297", intitule : "GUYANCOURT", cp : "78280" },
{ code : "78297", intitule : "GUYANCOURT", cp : "78280" },
{ code : "78297", intitule : "GUYANCOURT", cp : "78280" },
{ code : "78299", intitule : "HARDRICOURT", cp : "78250" },
{ code : "78300", intitule : "HARGEVILLE", cp : "78790" },
{ code : "78302", intitule : "LA HAUTEVILLE", cp : "78113" },
{ code : "78305", intitule : "HERBEVILLE", cp : "78580" },
{ code : "78307", intitule : "HERMERAY", cp : "78125" },
{ code : "78310", intitule : "HOUDAN", cp : "78550" },
{ code : "78311", intitule : "HOUILLES", cp : "78800" },
{ code : "78314", intitule : "ISSOU", cp : "78440" },
{ code : "78317", intitule : "JAMBVILLE", cp : "78440" },
{ code : "78320", intitule : "JEUFOSSE", cp : "78270" },
{ code : "78321", intitule : "JOUARS PONTCHARTRAIN", cp : "78760" },
{ code : "78322", intitule : "JOUY EN JOSAS", cp : "78350" },
{ code : "78324", intitule : "JOUY MAUVOISIN", cp : "78200" },
{ code : "78325", intitule : "JUMEAUVILLE", cp : "78580" },
{ code : "78327", intitule : "JUZIERS", cp : "78820" },
{ code : "78329", intitule : "LAINVILLE EN VEXIN", cp : "78440" },
{ code : "78334", intitule : "LEVIS ST NOM", cp : "78320" },
{ code : "78335", intitule : "LIMAY", cp : "78520" },
{ code : "78337", intitule : "LIMETZ VILLEZ", cp : "78270" },
{ code : "78343", intitule : "LES LOGES EN JOSAS", cp : "78350" },
{ code : "78344", intitule : "LOMMOYE", cp : "78270" },
{ code : "78346", intitule : "LONGNES", cp : "78980" },
{ code : "78349", intitule : "LONGVILLIERS", cp : "78730" },
{ code : "78350", intitule : "LOUVECIENNES", cp : "78430" },
{ code : "78354", intitule : "MAGNANVILLE", cp : "78200" },
{ code : "78356", intitule : "MAGNY LES HAMEAUX", cp : "78114" },
{ code : "78356", intitule : "MAGNY LES HAMEAUX", cp : "78114" },
{ code : "78358", intitule : "MAISONS LAFFITTE", cp : "78600" },
{ code : "78361", intitule : "MANTES LA JOLIE", cp : "78200" },
{ code : "78362", intitule : "MANTES LA VILLE", cp : "78711" },
{ code : "78364", intitule : "MARCQ", cp : "78770" },
{ code : "78366", intitule : "MAREIL LE GUYON", cp : "78490" },
{ code : "78367", intitule : "MAREIL MARLY", cp : "78750" },
{ code : "78368", intitule : "MAREIL SUR MAULDRE", cp : "78124" },
{ code : "78372", intitule : "MARLY LE ROI", cp : "78160" },
{ code : "78372", intitule : "MARLY LE ROI", cp : "78160" },
{ code : "78380", intitule : "MAULE", cp : "78580" },
{ code : "78381", intitule : "MAULETTE", cp : "78550" },
{ code : "78381", intitule : "MAULETTE", cp : "78550" },
{ code : "78382", intitule : "MAURECOURT", cp : "78780" },
{ code : "78383", intitule : "MAUREPAS", cp : "78310" },
{ code : "78384", intitule : "MEDAN", cp : "78670" },
{ code : "78385", intitule : "MENERVILLE", cp : "78200" },
{ code : "78389", intitule : "MERE", cp : "78490" },
{ code : "78391", intitule : "MERICOURT", cp : "78270" },
{ code : "78396", intitule : "LE MESNIL LE ROI", cp : "78600" },
{ code : "78396", intitule : "LE MESNIL LE ROI", cp : "78600" },
{ code : "78397", intitule : "LE MESNIL ST DENIS", cp : "78320" },
{ code : "78398", intitule : "LES MESNULS", cp : "78490" },
{ code : "78401", intitule : "MEULAN EN YVELINES", cp : "78250" },
{ code : "78402", intitule : "MEZIERES SUR SEINE", cp : "78970" },
{ code : "78403", intitule : "MEZY SUR SEINE", cp : "78250" },
{ code : "78404", intitule : "MILLEMONT", cp : "78940" },
{ code : "78406", intitule : "MILON LA CHAPELLE", cp : "78470" },
{ code : "78407", intitule : "MITTAINVILLE", cp : "78125" },
{ code : "78410", intitule : "MOISSON", cp : "78840" },
{ code : "78413", intitule : "MONDREVILLE", cp : "78980" },
{ code : "78415", intitule : "MONTAINVILLE", cp : "78124" },
{ code : "78416", intitule : "MONTALET LE BOIS", cp : "78440" },
{ code : "78417", intitule : "MONTCHAUVET", cp : "78790" },
{ code : "78418", intitule : "MONTESSON", cp : "78360" },
{ code : "78420", intitule : "MONTFORT L AMAURY", cp : "78490" },
{ code : "78423", intitule : "MONTIGNY LE BRETONNEUX", cp : "78180" },
{ code : "78431", intitule : "MORAINVILLIERS", cp : "78630" },
{ code : "78437", intitule : "MOUSSEAUX SUR SEINE", cp : "78270" },
{ code : "78439", intitule : "MULCENT", cp : "78790" },
{ code : "78440", intitule : "LES MUREAUX", cp : "78130" },
{ code : "78442", intitule : "NEAUPHLE LE CHATEAU", cp : "78640" },
{ code : "78443", intitule : "NEAUPHLE LE VIEUX", cp : "78640" },
{ code : "78444", intitule : "NEAUPHLETTE", cp : "78980" },
{ code : "78451", intitule : "NEZEL", cp : "78410" },
{ code : "78455", intitule : "NOISY LE ROI", cp : "78590" },
{ code : "78460", intitule : "OINVILLE SUR MONTCIENT", cp : "78250" },
{ code : "78464", intitule : "ORCEMONT", cp : "78125" },
{ code : "78465", intitule : "ORGERUS", cp : "78910" },
{ code : "78466", intitule : "ORGEVAL", cp : "78630" },
{ code : "78470", intitule : "ORPHIN", cp : "78125" },
{ code : "78472", intitule : "ORSONVILLE", cp : "78660" },
{ code : "78474", intitule : "ORVILLIERS", cp : "78910" },
{ code : "78475", intitule : "OSMOY", cp : "78910" },
{ code : "78478", intitule : "PARAY DOUAVILLE", cp : "78660" },
{ code : "78481", intitule : "LE PECQ", cp : "78230" },
{ code : "78484", intitule : "PERDREAUVILLE", cp : "78200" },
{ code : "78486", intitule : "LE PERRAY EN YVELINES", cp : "78610" },
{ code : "78490", intitule : "PLAISIR", cp : "78370" },
{ code : "78497", intitule : "POIGNY LA FORET", cp : "78125" },
{ code : "78498", intitule : "POISSY", cp : "78300" },
{ code : "78498", intitule : "POISSY", cp : "78300" },
{ code : "78499", intitule : "PONTHEVRARD", cp : "78730" },
{ code : "78501", intitule : "PORCHEVILLE", cp : "78440" },
{ code : "78502", intitule : "LE PORT MARLY", cp : "78560" },
{ code : "78503", intitule : "PORT VILLEZ", cp : "78270" },
{ code : "78505", intitule : "PRUNAY LE TEMPLE", cp : "78910" },
{ code : "78506", intitule : "PRUNAY EN YVELINES", cp : "78660" },
{ code : "78506", intitule : "PRUNAY EN YVELINES", cp : "78660" },
{ code : "78513", intitule : "LA QUEUE LES YVELINES", cp : "78940" },
{ code : "78516", intitule : "RAIZEUX", cp : "78125" },
{ code : "78517", intitule : "RAMBOUILLET", cp : "78120" },
{ code : "78518", intitule : "RENNEMOULIN", cp : "78590" },
{ code : "78520", intitule : "RICHEBOURG", cp : "78550" },
{ code : "78522", intitule : "ROCHEFORT EN YVELINES", cp : "78730" },
{ code : "78524", intitule : "ROCQUENCOURT", cp : "78150" },
{ code : "78528", intitule : "ROLLEBOISE", cp : "78270" },
{ code : "78530", intitule : "ROSAY", cp : "78790" },
{ code : "78531", intitule : "ROSNY SUR SEINE", cp : "78710" },
{ code : "78536", intitule : "SAILLY", cp : "78440" },
{ code : "78537", intitule : "ST ARNOULT EN YVELINES", cp : "78730" },
{ code : "78545", intitule : "ST CYR L ECOLE", cp : "78210" },
{ code : "78548", intitule : "ST FORGET", cp : "78720" },
{ code : "78550", intitule : "ST GERMAIN DE LA GRANGE", cp : "78640" },
{ code : "78551", intitule : "ST GERMAIN EN LAYE", cp : "78100" },
{ code : "78557", intitule : "ST HILARION", cp : "78125" },
{ code : "78558", intitule : "ST ILLIERS LA VILLE", cp : "78980" },
{ code : "78559", intitule : "ST ILLIERS LE BOIS", cp : "78980" },
{ code : "78561", intitule : "ST LAMBERT", cp : "78470" },
{ code : "78562", intitule : "ST LEGER EN YVELINES", cp : "78610" },
{ code : "78564", intitule : "ST MARTIN DE BRETHENCOURT", cp : "78660" },
{ code : "78565", intitule : "ST MARTIN DES CHAMPS", cp : "78790" },
{ code : "78567", intitule : "ST MARTIN LA GARENNE", cp : "78520" },
{ code : "78569", intitule : "STE MESME", cp : "78730" },
{ code : "78571", intitule : "ST NOM LA BRETECHE", cp : "78860" },
{ code : "78571", intitule : "ST NOM LA BRETECHE", cp : "78860" },
{ code : "78575", intitule : "ST REMY LES CHEVREUSE", cp : "78470" },
{ code : "78575", intitule : "ST REMY LES CHEVREUSE", cp : "78470" },
{ code : "78576", intitule : "ST REMY L HONORE", cp : "78690" },
{ code : "78586", intitule : "SARTROUVILLE", cp : "78500" },
{ code : "78588", intitule : "SAULX MARCHAIS", cp : "78650" },
{ code : "78590", intitule : "SENLISSE", cp : "78720" },
{ code : "78591", intitule : "SEPTEUIL", cp : "78790" },
{ code : "78597", intitule : "SOINDRES", cp : "78200" },
{ code : "78601", intitule : "SONCHAMP", cp : "78120" },
{ code : "78605", intitule : "TACOIGNIERES", cp : "78910" },
{ code : "78606", intitule : "LE TARTRE GAUDRAN", cp : "78113" },
{ code : "78608", intitule : "LE TERTRE ST DENIS", cp : "78980" },
{ code : "78609", intitule : "TESSANCOURT SUR AUBETTE", cp : "78250" },
{ code : "78615", intitule : "THIVERVAL GRIGNON", cp : "78850" },
{ code : "78616", intitule : "THOIRY", cp : "78770" },
{ code : "78618", intitule : "TILLY", cp : "78790" },
{ code : "78620", intitule : "TOUSSUS LE NOBLE", cp : "78117" },
{ code : "78621", intitule : "TRAPPES", cp : "78190" },
{ code : "78623", intitule : "LE TREMBLAY SUR MAULDRE", cp : "78490" },
{ code : "78624", intitule : "TRIEL SUR SEINE", cp : "78510" },
{ code : "78624", intitule : "TRIEL SUR SEINE", cp : "78510" },
{ code : "78624", intitule : "TRIEL SUR SEINE", cp : "78510" },
{ code : "78638", intitule : "VAUX SUR SEINE", cp : "78740" },
{ code : "78640", intitule : "VELIZY VILLACOUBLAY", cp : "78140" },
{ code : "78642", intitule : "VERNEUIL SUR SEINE", cp : "78480" },
{ code : "78643", intitule : "VERNOUILLET", cp : "78540" },
{ code : "78643", intitule : "VERNOUILLET", cp : "78540" },
{ code : "78644", intitule : "LA VERRIERE", cp : "78320" },
{ code : "78646", intitule : "VERSAILLES", cp : "78000" },
{ code : "78647", intitule : "VERT", cp : "78930" },
{ code : "78650", intitule : "LE VESINET", cp : "78110" },
{ code : "78653", intitule : "VICQ", cp : "78490" },
{ code : "78655", intitule : "VIEILLE EGLISE EN YVELINES", cp : "78125" },
{ code : "78668", intitule : "LA VILLENEUVE EN CHEVRIE", cp : "78270" },
{ code : "78672", intitule : "VILLENNES SUR SEINE", cp : "78670" },
{ code : "78674", intitule : "VILLEPREUX", cp : "78450" },
{ code : "78677", intitule : "VILLETTE", cp : "78930" },
{ code : "78681", intitule : "VILLIERS LE MAHIEU", cp : "78770" },
{ code : "78683", intitule : "VILLIERS ST FREDERIC", cp : "78640" },
{ code : "78686", intitule : "VIROFLAY", cp : "78220" },
{ code : "78688", intitule : "VOISINS LE BRETONNEUX", cp : "78960" },
{ code : "79001", intitule : "L ABSIE", cp : "79240" },
{ code : "79002", intitule : "ADILLY", cp : "79200" },
{ code : "79003", intitule : "AIFFRES", cp : "79230" },
{ code : "79004", intitule : "AIGONNAY", cp : "79370" },
{ code : "79005", intitule : "AIRVAULT", cp : "79600" },
{ code : "79005", intitule : "AIRVAULT", cp : "79600" },
{ code : "79005", intitule : "AIRVAULT", cp : "79600" },
{ code : "79006", intitule : "LES ALLEUDS", cp : "79190" },
{ code : "79007", intitule : "ALLONNE", cp : "79130" },
{ code : "79008", intitule : "AMAILLOUX", cp : "79350" },
{ code : "79009", intitule : "AMURE", cp : "79210" },
{ code : "79010", intitule : "ARCAIS", cp : "79210" },
{ code : "79011", intitule : "ARDILLEUX", cp : "79110" },
{ code : "79012", intitule : "ARDIN", cp : "79160" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79300" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79013", intitule : "ARGENTONAY", cp : "79150" },
{ code : "79014", intitule : "ARGENTON L EGLISE", cp : "79290" },
{ code : "79014", intitule : "ARGENTON L EGLISE", cp : "79290" },
{ code : "79015", intitule : "ASNIERES EN POITOU", cp : "79170" },
{ code : "79016", intitule : "ASSAIS LES JUMEAUX", cp : "79600" },
{ code : "79016", intitule : "ASSAIS LES JUMEAUX", cp : "79600" },
{ code : "79018", intitule : "AUBIGNE", cp : "79110" },
{ code : "79019", intitule : "AUBIGNY", cp : "79390" },
{ code : "79020", intitule : "AUGE", cp : "79400" },
{ code : "79022", intitule : "AVAILLES THOUARSAIS", cp : "79600" },
{ code : "79023", intitule : "AVON", cp : "79800" },
{ code : "79024", intitule : "AZAY LE BRULE", cp : "79400" },
{ code : "79025", intitule : "AZAY SUR THOUET", cp : "79130" },
{ code : "79027", intitule : "LA BATAILLE", cp : "79110" },
{ code : "79029", intitule : "BEAULIEU SOUS PARTHENAY", cp : "79420" },
{ code : "79030", intitule : "BEAUSSAIS VITRE", cp : "79370" },
{ code : "79030", intitule : "BEAUSSAIS VITRE", cp : "79370" },
{ code : "79031", intitule : "BEAUVOIR SUR NIORT", cp : "79360" },
{ code : "79031", intitule : "BEAUVOIR SUR NIORT", cp : "79360" },
{ code : "79031", intitule : "BEAUVOIR SUR NIORT", cp : "79360" },
{ code : "79032", intitule : "BECELEUF", cp : "79160" },
{ code : "79033", intitule : "BELLEVILLE", cp : "79360" },
{ code : "79034", intitule : "BESSINES", cp : "79000" },
{ code : "79035", intitule : "LE BEUGNON", cp : "79130" },
{ code : "79038", intitule : "BOISME", cp : "79300" },
{ code : "79039", intitule : "BOISSEROLLES", cp : "79360" },
{ code : "79040", intitule : "LA BOISSIERE EN GATINE", cp : "79310" },
{ code : "79042", intitule : "BOUGON", cp : "79800" },
{ code : "79043", intitule : "BOUILLE LORETZ", cp : "79290" },
{ code : "79044", intitule : "BOUILLE ST PAUL", cp : "79290" },
{ code : "79045", intitule : "BOUIN", cp : "79110" },
{ code : "79046", intitule : "LE BOURDET", cp : "79210" },
{ code : "79047", intitule : "BOUSSAIS", cp : "79600" },
{ code : "79048", intitule : "LA CRECHE", cp : "79260" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79049", intitule : "BRESSUIRE", cp : "79300" },
{ code : "79050", intitule : "BRETIGNOLLES", cp : "79140" },
{ code : "79051", intitule : "LE BREUIL BERNARD", cp : "79320" },
{ code : "79054", intitule : "BRIE", cp : "79100" },
{ code : "79055", intitule : "BRIEUIL SUR CHIZE", cp : "79170" },
{ code : "79056", intitule : "BRION PRES THOUET", cp : "79290" },
{ code : "79057", intitule : "BRIOUX SUR BOUTONNE", cp : "79170" },
{ code : "79058", intitule : "BRULAIN", cp : "79230" },
{ code : "79059", intitule : "LE BUSSEAU", cp : "79240" },
{ code : "79060", intitule : "CAUNAY", cp : "79190" },
{ code : "79061", intitule : "CELLES SUR BELLE", cp : "79370" },
{ code : "79061", intitule : "CELLES SUR BELLE", cp : "79370" },
{ code : "79061", intitule : "CELLES SUR BELLE", cp : "79370" },
{ code : "79062", intitule : "CERIZAY", cp : "79140" },
{ code : "79063", intitule : "CERSAY", cp : "79290" },
{ code : "79063", intitule : "CERSAY", cp : "79290" },
{ code : "79064", intitule : "CHAIL", cp : "79500" },
{ code : "79066", intitule : "CHAMPDENIERS ST DENIS", cp : "79220" },
{ code : "79066", intitule : "CHAMPDENIERS ST DENIS", cp : "79220" },
{ code : "79066", intitule : "CHAMPDENIERS ST DENIS", cp : "79220" },
{ code : "79068", intitule : "CHANTECORPS", cp : "79340" },
{ code : "79069", intitule : "CHANTELOUP", cp : "79320" },
{ code : "79070", intitule : "LA CHAPELLE BATON", cp : "79220" },
{ code : "79071", intitule : "LA CHAPELLE BERTRAND", cp : "79200" },
{ code : "79074", intitule : "LA CHAPELLE POUILLOUX", cp : "79190" },
{ code : "79075", intitule : "LA CHAPELLE ST ETIENNE", cp : "79240" },
{ code : "79076", intitule : "LA CHAPELLE ST LAURENT", cp : "79430" },
{ code : "79077", intitule : "LA CHAPELLE THIREUIL", cp : "79160" },
{ code : "79078", intitule : "PRISSE LA CHARRIERE", cp : "79360" },
{ code : "79078", intitule : "PRISSE LA CHARRIERE", cp : "79360" },
{ code : "79078", intitule : "PRISSE LA CHARRIERE", cp : "79360" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79079", intitule : "MAULEON", cp : "79700" },
{ code : "79080", intitule : "CHATILLON SUR THOUET", cp : "79200" },
{ code : "79081", intitule : "CHAURAY", cp : "79180" },
{ code : "79083", intitule : "CHEF BOUTONNE", cp : "79110" },
{ code : "79084", intitule : "CHENAY", cp : "79120" },
{ code : "79085", intitule : "CHERIGNE", cp : "79170" },
{ code : "79086", intitule : "CHERVEUX", cp : "79410" },
{ code : "79087", intitule : "CHEY", cp : "79120" },
{ code : "79088", intitule : "CHICHE", cp : "79350" },
{ code : "79089", intitule : "LE CHILLOU", cp : "79600" },
{ code : "79090", intitule : "CHIZE", cp : "79170" },
{ code : "79090", intitule : "CHIZE", cp : "79170" },
{ code : "79091", intitule : "CIRIERES", cp : "79140" },
{ code : "79092", intitule : "CLAVE", cp : "79420" },
{ code : "79094", intitule : "CLESSE", cp : "79350" },
{ code : "79095", intitule : "CLUSSAIS LA POMMERAIE", cp : "79190" },
{ code : "79096", intitule : "COMBRAND", cp : "79140" },
{ code : "79098", intitule : "LA COUARDE", cp : "79800" },
{ code : "79100", intitule : "COULON", cp : "79510" },
{ code : "79101", intitule : "COULONGES SUR L AUTIZE", cp : "79160" },
{ code : "79102", intitule : "COULONGES THOUARSAIS", cp : "79330" },
{ code : "79103", intitule : "COURLAY", cp : "79440" },
{ code : "79104", intitule : "COURS", cp : "79220" },
{ code : "79105", intitule : "COUTIERES", cp : "79340" },
{ code : "79106", intitule : "COUTURE D ARGENSON", cp : "79110" },
{ code : "79107", intitule : "CREZIERES", cp : "79110" },
{ code : "79108", intitule : "DOUX", cp : "79390" },
{ code : "79109", intitule : "ECHIRE", cp : "79410" },
{ code : "79111", intitule : "ENSIGNE", cp : "79170" },
{ code : "79112", intitule : "EPANNES", cp : "79270" },
{ code : "79114", intitule : "EXIREUIL", cp : "79400" },
{ code : "79115", intitule : "EXOUDUN", cp : "79800" },
{ code : "79116", intitule : "FAYE L ABBESSE", cp : "79350" },
{ code : "79117", intitule : "FAYE SUR ARDIN", cp : "79160" },
{ code : "79118", intitule : "FENERY", cp : "79450" },
{ code : "79119", intitule : "FENIOUX", cp : "79160" },
{ code : "79120", intitule : "LA FERRIERE EN PARTHENAY", cp : "79390" },
{ code : "79121", intitule : "FOMPERRON", cp : "79340" },
{ code : "79122", intitule : "FONTENILLE ST MARTIN D ENTRAIGUES", cp : "79110" },
{ code : "79122", intitule : "FONTENILLE ST MARTIN D ENTRAIGUES", cp : "79110" },
{ code : "79123", intitule : "LA FORET SUR SEVRE", cp : "79380" },
{ code : "79123", intitule : "LA FORET SUR SEVRE", cp : "79380" },
{ code : "79123", intitule : "LA FORET SUR SEVRE", cp : "79380" },
{ code : "79123", intitule : "LA FORET SUR SEVRE", cp : "79380" },
{ code : "79124", intitule : "LES FORGES", cp : "79340" },
{ code : "79125", intitule : "FORS", cp : "79230" },
{ code : "79126", intitule : "LES FOSSES", cp : "79360" },
{ code : "79127", intitule : "LA FOYE MONJAULT", cp : "79360" },
{ code : "79128", intitule : "FRANCOIS", cp : "79260" },
{ code : "79129", intitule : "FRESSINES", cp : "79370" },
{ code : "79130", intitule : "FRONTENAY ROHAN ROHAN", cp : "79270" },
{ code : "79131", intitule : "GEAY", cp : "79330" },
{ code : "79132", intitule : "GENNETON", cp : "79150" },
{ code : "79133", intitule : "GERMOND ROUVRE", cp : "79220" },
{ code : "79133", intitule : "GERMOND ROUVRE", cp : "79220" },
{ code : "79134", intitule : "GLENAY", cp : "79330" },
{ code : "79135", intitule : "GOURGE", cp : "79200" },
{ code : "79136", intitule : "GOURNAY LOIZE", cp : "79110" },
{ code : "79136", intitule : "GOURNAY LOIZE", cp : "79110" },
{ code : "79137", intitule : "GRANZAY GRIPT", cp : "79360" },
{ code : "79137", intitule : "GRANZAY GRIPT", cp : "79360" },
{ code : "79139", intitule : "LES GROSEILLERS", cp : "79220" },
{ code : "79140", intitule : "HANC", cp : "79110" },
{ code : "79141", intitule : "IRAIS", cp : "79600" },
{ code : "79142", intitule : "JUILLE", cp : "79170" },
{ code : "79144", intitule : "JUSCORPS", cp : "79230" },
{ code : "79145", intitule : "LAGEON", cp : "79200" },
{ code : "79147", intitule : "LARGEASSE", cp : "79240" },
{ code : "79148", intitule : "LEZAY", cp : "79120" },
{ code : "79149", intitule : "LHOUMOIS", cp : "79390" },
{ code : "79150", intitule : "LIMALONGES", cp : "79190" },
{ code : "79152", intitule : "LORIGNE", cp : "79190" },
{ code : "79153", intitule : "LOUBIGNE", cp : "79110" },
{ code : "79154", intitule : "LOUBILLE", cp : "79110" },
{ code : "79156", intitule : "LOUIN", cp : "79600" },
{ code : "79157", intitule : "LOUZY", cp : "79100" },
{ code : "79158", intitule : "LUCHE SUR BRIOUX", cp : "79170" },
{ code : "79159", intitule : "LUCHE THOUARSAIS", cp : "79330" },
{ code : "79160", intitule : "LUSSERAY", cp : "79170" },
{ code : "79161", intitule : "LUZAY", cp : "79100" },
{ code : "79162", intitule : "MAGNE", cp : "79460" },
{ code : "79163", intitule : "MAIRE LEVESCAULT", cp : "79190" },
{ code : "79164", intitule : "MAISONNAY", cp : "79500" },
{ code : "79165", intitule : "MAISONTIERS", cp : "79600" },
{ code : "79166", intitule : "MARIGNY", cp : "79360" },
{ code : "79167", intitule : "MARNES", cp : "79600" },
{ code : "79168", intitule : "MASSAIS", cp : "79150" },
{ code : "79170", intitule : "MAUZE SUR LE MIGNON", cp : "79210" },
{ code : "79171", intitule : "MAUZE THOUARSAIS", cp : "79100" },
{ code : "79171", intitule : "MAUZE THOUARSAIS", cp : "79100" },
{ code : "79172", intitule : "MAZIERES EN GATINE", cp : "79310" },
{ code : "79173", intitule : "MAZIERES SUR BERONNE", cp : "79500" },
{ code : "79174", intitule : "MELLE", cp : "79500" },
{ code : "79175", intitule : "MELLERAN", cp : "79190" },
{ code : "79176", intitule : "MENIGOUTE", cp : "79340" },
{ code : "79177", intitule : "MESSE", cp : "79120" },
{ code : "79178", intitule : "MISSE", cp : "79100" },
{ code : "79179", intitule : "MONCOUTANT", cp : "79320" },
{ code : "79180", intitule : "MONTALEMBERT", cp : "79190" },
{ code : "79183", intitule : "MONTRAVERS", cp : "79140" },
{ code : "79184", intitule : "LA MOTHE ST HERAY", cp : "79800" },
{ code : "79185", intitule : "MOUGON", cp : "79370" },
{ code : "79188", intitule : "MOUTIERS SOUS CHANTEMERLE", cp : "79320" },
{ code : "79189", intitule : "NANTEUIL", cp : "79400" },
{ code : "79190", intitule : "NEUVY BOUIN", cp : "79130" },
{ code : "79191", intitule : "NIORT", cp : "79000" },
{ code : "79191", intitule : "NIORT", cp : "79000" },
{ code : "79191", intitule : "NIORT", cp : "79000" },
{ code : "79191", intitule : "NIORT", cp : "79000" },
{ code : "79191", intitule : "NIORT", cp : "79000" },
{ code : "79195", intitule : "NUEIL LES AUBIERS", cp : "79250" },
{ code : "79195", intitule : "NUEIL LES AUBIERS", cp : "79250" },
{ code : "79196", intitule : "OIRON", cp : "79100" },
{ code : "79196", intitule : "OIRON", cp : "79100" },
{ code : "79196", intitule : "OIRON", cp : "79100" },
{ code : "79197", intitule : "OROUX", cp : "79390" },
{ code : "79198", intitule : "PAIZAY LE CHAPT", cp : "79170" },
{ code : "79199", intitule : "PAIZAY LE TORT", cp : "79500" },
{ code : "79200", intitule : "PAMPLIE", cp : "79220" },
{ code : "79201", intitule : "PAMPROUX", cp : "79800" },
{ code : "79202", intitule : "PARTHENAY", cp : "79200" },
{ code : "79203", intitule : "PAS DE JEU", cp : "79100" },
{ code : "79204", intitule : "PERIGNE", cp : "79170" },
{ code : "79205", intitule : "PERS", cp : "79190" },
{ code : "79207", intitule : "LA PETITE BOISSIERE", cp : "79700" },
{ code : "79208", intitule : "LA PEYRATTE", cp : "79200" },
{ code : "79209", intitule : "PIERREFITTE", cp : "79330" },
{ code : "79210", intitule : "LE PIN", cp : "79140" },
{ code : "79211", intitule : "PIOUSSAY", cp : "79110" },
{ code : "79212", intitule : "PLIBOUX", cp : "79190" },
{ code : "79213", intitule : "POMPAIRE", cp : "79200" },
{ code : "79214", intitule : "POUFFONDS", cp : "79500" },
{ code : "79215", intitule : "POUGNE HERISSON", cp : "79130" },
{ code : "79216", intitule : "PRAHECQ", cp : "79230" },
{ code : "79217", intitule : "PRAILLES", cp : "79370" },
{ code : "79218", intitule : "PRESSIGNY", cp : "79390" },
{ code : "79219", intitule : "PRIAIRES", cp : "79210" },
{ code : "79220", intitule : "PRIN DEYRANCON", cp : "79210" },
{ code : "79222", intitule : "PUGNY", cp : "79320" },
{ code : "79223", intitule : "PUIHARDY", cp : "79160" },
{ code : "79225", intitule : "REFFANNES", cp : "79420" },
{ code : "79226", intitule : "LE RETAIL", cp : "79130" },
{ code : "79229", intitule : "LA ROCHENARD", cp : "79270" },
{ code : "79230", intitule : "ROM", cp : "79120" },
{ code : "79231", intitule : "ROMANS", cp : "79260" },
{ code : "79235", intitule : "ST AMAND SUR SEVRE", cp : "79700" },
{ code : "79236", intitule : "ST ANDRE SUR SEVRE", cp : "79380" },
{ code : "79238", intitule : "ST AUBIN DU PLAIN", cp : "79300" },
{ code : "79239", intitule : "ST AUBIN LE CLOUD", cp : "79450" },
{ code : "79240", intitule : "STE BLANDINE", cp : "79370" },
{ code : "79241", intitule : "ST CHRISTOPHE SUR ROC", cp : "79220" },
{ code : "79242", intitule : "VOULMENTIN", cp : "79150" },
{ code : "79242", intitule : "VOULMENTIN", cp : "79150" },
{ code : "79243", intitule : "ST COUTANT", cp : "79120" },
{ code : "79244", intitule : "ST CYR LA LANDE", cp : "79100" },
{ code : "79246", intitule : "STE EANNE", cp : "79800" },
{ code : "79247", intitule : "ST ETIENNE LA CIGOGNE", cp : "79360" },
{ code : "79249", intitule : "ST GELAIS", cp : "79410" },
{ code : "79250", intitule : "STE GEMME", cp : "79330" },
{ code : "79251", intitule : "ST GENARD", cp : "79500" },
{ code : "79252", intitule : "ST GENEROUX", cp : "79600" },
{ code : "79253", intitule : "ST GEORGES DE NOISNE", cp : "79400" },
{ code : "79254", intitule : "ST GEORGES DE REX", cp : "79210" },
{ code : "79255", intitule : "ST GERMAIN DE LONGUE CHAUME", cp : "79200" },
{ code : "79256", intitule : "ST GERMIER", cp : "79340" },
{ code : "79257", intitule : "ST HILAIRE LA PALUD", cp : "79210" },
{ code : "79258", intitule : "ST JACQUES DE THOUARS", cp : "79100" },
{ code : "79259", intitule : "ST JEAN DE THOUARS", cp : "79100" },
{ code : "79260", intitule : "ST JOUIN DE MARNES", cp : "79600" },
{ code : "79261", intitule : "ST JOUIN DE MILLY", cp : "79380" },
{ code : "79263", intitule : "ST LAURS", cp : "79160" },
{ code : "79264", intitule : "ST LEGER DE LA MARTINIERE", cp : "79500" },
{ code : "79264", intitule : "ST LEGER DE LA MARTINIERE", cp : "79500" },
{ code : "79265", intitule : "ST LEGER DE MONTBRUN", cp : "79100" },
{ code : "79267", intitule : "ST LIN", cp : "79420" },
{ code : "79268", intitule : "ST LOUP LAMAIRE", cp : "79600" },
{ code : "79268", intitule : "ST LOUP LAMAIRE", cp : "79600" },
{ code : "79269", intitule : "ST MAIXENT DE BEUGNE", cp : "79160" },
{ code : "79270", intitule : "ST MAIXENT L ECOLE", cp : "79400" },
{ code : "79271", intitule : "ST MARC LA LANDE", cp : "79310" },
{ code : "79273", intitule : "ST MARTIN DE BERNEGOUE", cp : "79230" },
{ code : "79274", intitule : "ST MARTIN DE MACON", cp : "79100" },
{ code : "79276", intitule : "ST MARTIN DE ST MAIXENT", cp : "79400" },
{ code : "79277", intitule : "ST MARTIN DE SANZAY", cp : "79290" },
{ code : "79278", intitule : "ST MARTIN DU FOUILLOUX", cp : "79420" },
{ code : "79279", intitule : "ST MARTIN LES MELLE", cp : "79500" },
{ code : "79280", intitule : "ST MAURICE ETUSSON", cp : "79150" },
{ code : "79280", intitule : "ST MAURICE ETUSSON", cp : "79150" },
{ code : "79281", intitule : "ST MAXIRE", cp : "79410" },
{ code : "79282", intitule : "ST MEDARD", cp : "79370" },
{ code : "79283", intitule : "STE NEOMAYE", cp : "79260" },
{ code : "79284", intitule : "STE OUENNE", cp : "79220" },
{ code : "79285", intitule : "ST PARDOUX", cp : "79310" },
{ code : "79286", intitule : "ST PAUL EN GATINE", cp : "79240" },
{ code : "79289", intitule : "ST PIERRE DES ECHAUBROGNES", cp : "79700" },
{ code : "79290", intitule : "ST POMPAIN", cp : "79160" },
{ code : "79292", intitule : "STE RADEGONDE", cp : "79100" },
{ code : "79293", intitule : "ST REMY", cp : "79410" },
{ code : "79294", intitule : "ST ROMANS DES CHAMPS", cp : "79230" },
{ code : "79295", intitule : "ST ROMANS LES MELLE", cp : "79500" },
{ code : "79297", intitule : "STE SOLINE", cp : "79120" },
{ code : "79298", intitule : "ST SYMPHORIEN", cp : "79270" },
{ code : "79299", intitule : "ST VARENT", cp : "79330" },
{ code : "79300", intitule : "STE VERGE", cp : "79100" },
{ code : "79301", intitule : "ST VINCENT LA CHATRE", cp : "79500" },
{ code : "79302", intitule : "SAIVRES", cp : "79400" },
{ code : "79303", intitule : "SALLES", cp : "79800" },
{ code : "79304", intitule : "SANSAIS", cp : "79270" },
{ code : "79306", intitule : "SAURAIS", cp : "79200" },
{ code : "79307", intitule : "SAUZE VAUSSAIS", cp : "79190" },
{ code : "79308", intitule : "SCIECQ", cp : "79000" },
{ code : "79309", intitule : "SCILLE", cp : "79240" },
{ code : "79310", intitule : "SECONDIGNE SUR BELLE", cp : "79170" },
{ code : "79311", intitule : "SECONDIGNY", cp : "79130" },
{ code : "79312", intitule : "SELIGNE", cp : "79170" },
{ code : "79313", intitule : "SEPVRET", cp : "79120" },
{ code : "79314", intitule : "SOMPT", cp : "79110" },
{ code : "79316", intitule : "SOUDAN", cp : "79800" },
{ code : "79318", intitule : "SOUTIERS", cp : "79310" },
{ code : "79319", intitule : "SOUVIGNE", cp : "79800" },
{ code : "79320", intitule : "SURIN", cp : "79220" },
{ code : "79321", intitule : "TAIZE", cp : "79100" },
{ code : "79321", intitule : "TAIZE", cp : "79100" },
{ code : "79322", intitule : "LE TALLUD", cp : "79200" },
{ code : "79325", intitule : "TESSONNIERE", cp : "79600" },
{ code : "79326", intitule : "THENEZAY", cp : "79390" },
{ code : "79327", intitule : "THORIGNE", cp : "79370" },
{ code : "79328", intitule : "THORIGNY SUR LE MIGNON", cp : "79360" },
{ code : "79329", intitule : "THOUARS", cp : "79100" },
{ code : "79330", intitule : "TILLOU", cp : "79110" },
{ code : "79331", intitule : "TOURTENAY", cp : "79100" },
{ code : "79332", intitule : "TRAYES", cp : "79240" },
{ code : "79334", intitule : "USSEAU", cp : "79210" },
{ code : "79335", intitule : "VALLANS", cp : "79270" },
{ code : "79336", intitule : "VANCAIS", cp : "79120" },
{ code : "79337", intitule : "LE VANNEAU IRLEAU", cp : "79270" },
{ code : "79338", intitule : "VANZAY", cp : "79120" },
{ code : "79339", intitule : "VASLES", cp : "79340" },
{ code : "79340", intitule : "VAUSSEROUX", cp : "79420" },
{ code : "79341", intitule : "VAUTEBIS", cp : "79420" },
{ code : "79342", intitule : "VERNOUX EN GATINE", cp : "79240" },
{ code : "79343", intitule : "VERNOUX SUR BOUTONNE", cp : "79170" },
{ code : "79345", intitule : "VERRUYES", cp : "79310" },
{ code : "79346", intitule : "LE VERT", cp : "79170" },
{ code : "79347", intitule : "VIENNAY", cp : "79200" },
{ code : "79348", intitule : "VILLEFOLLET", cp : "79170" },
{ code : "79349", intitule : "VILLEMAIN", cp : "79110" },
{ code : "79350", intitule : "VILLIERS EN BOIS", cp : "79360" },
{ code : "79351", intitule : "VILLIERS EN PLAINE", cp : "79160" },
{ code : "79352", intitule : "VILLIERS SUR CHIZE", cp : "79170" },
{ code : "79354", intitule : "VOUHE", cp : "79310" },
{ code : "79355", intitule : "VOUILLE", cp : "79230" },
{ code : "79357", intitule : "XAINTRAY", cp : "79220" },
{ code : "80001", intitule : "ABBEVILLE", cp : "80100" },
{ code : "80002", intitule : "ABLAINCOURT PRESSOIR", cp : "80320" },
{ code : "80003", intitule : "ACHEUX EN AMIENOIS", cp : "80560" },
{ code : "80004", intitule : "ACHEUX EN VIMEU", cp : "80210" },
{ code : "80005", intitule : "AGENVILLE", cp : "80370" },
{ code : "80006", intitule : "AGENVILLERS", cp : "80150" },
{ code : "80008", intitule : "AIGNEVILLE", cp : "80210" },
{ code : "80009", intitule : "AILLY LE HAUT CLOCHER", cp : "80690" },
{ code : "80010", intitule : "AILLY SUR NOYE", cp : "80250" },
{ code : "80011", intitule : "AILLY SUR SOMME", cp : "80470" },
{ code : "80013", intitule : "AIRAINES", cp : "80270" },
{ code : "80013", intitule : "AIRAINES", cp : "80270" },
{ code : "80014", intitule : "AIZECOURT LE BAS", cp : "80240" },
{ code : "80015", intitule : "AIZECOURT LE HAUT", cp : "80200" },
{ code : "80016", intitule : "ALBERT", cp : "80300" },
{ code : "80017", intitule : "ALLAINES", cp : "80200" },
{ code : "80018", intitule : "ALLENAY", cp : "80130" },
{ code : "80019", intitule : "ALLERY", cp : "80270" },
{ code : "80020", intitule : "ALLONVILLE", cp : "80260" },
{ code : "80021", intitule : "AMIENS", cp : "80080" },
{ code : "80021", intitule : "AMIENS", cp : "80000" },
{ code : "80021", intitule : "AMIENS", cp : "80090" },
{ code : "80022", intitule : "ANDAINVILLE", cp : "80140" },
{ code : "80023", intitule : "ANDECHY", cp : "80700" },
{ code : "80024", intitule : "ARGOEUVES", cp : "80470" },
{ code : "80025", intitule : "ARGOULES", cp : "80120" },
{ code : "80026", intitule : "ARGUEL", cp : "80140" },
{ code : "80027", intitule : "ARMANCOURT", cp : "80700" },
{ code : "80028", intitule : "ARQUEVES", cp : "80560" },
{ code : "80029", intitule : "ARREST", cp : "80820" },
{ code : "80030", intitule : "ARRY", cp : "80120" },
{ code : "80031", intitule : "ARVILLERS", cp : "80910" },
{ code : "80032", intitule : "ASSAINVILLERS", cp : "80500" },
{ code : "80033", intitule : "ASSEVILLERS", cp : "80200" },
{ code : "80034", intitule : "ATHIES", cp : "80200" },
{ code : "80035", intitule : "AUBERCOURT", cp : "80110" },
{ code : "80036", intitule : "AUBIGNY", cp : "80800" },
{ code : "80037", intitule : "AUBVILLERS", cp : "80110" },
{ code : "80038", intitule : "AUCHONVILLERS", cp : "80560" },
{ code : "80039", intitule : "AULT", cp : "80460" },
{ code : "80040", intitule : "AUMATRE", cp : "80140" },
{ code : "80041", intitule : "AUMONT", cp : "80640" },
{ code : "80042", intitule : "AUTHEUX", cp : "80600" },
{ code : "80043", intitule : "AUTHIE", cp : "80560" },
{ code : "80044", intitule : "AUTHIEULE", cp : "80600" },
{ code : "80045", intitule : "AUTHUILLE", cp : "80300" },
{ code : "80046", intitule : "AVELESGES", cp : "80270" },
{ code : "80047", intitule : "AVELUY", cp : "80300" },
{ code : "80048", intitule : "AVESNES CHAUSSOY", cp : "80140" },
{ code : "80049", intitule : "AYENCOURT", cp : "80500" },
{ code : "80050", intitule : "BACOUEL SUR SELLE", cp : "80480" },
{ code : "80051", intitule : "BAILLEUL", cp : "80490" },
{ code : "80052", intitule : "BAIZIEUX", cp : "80300" },
{ code : "80053", intitule : "BALATRE", cp : "80700" },
{ code : "80054", intitule : "BARLEUX", cp : "80200" },
{ code : "80055", intitule : "BARLY", cp : "80600" },
{ code : "80056", intitule : "BAVELINCOURT", cp : "80260" },
{ code : "80057", intitule : "BAYENCOURT", cp : "80560" },
{ code : "80058", intitule : "BAYONVILLERS", cp : "80170" },
{ code : "80059", intitule : "BAZENTIN", cp : "80300" },
{ code : "80060", intitule : "BEALCOURT", cp : "80370" },
{ code : "80061", intitule : "BEAUCAMPS LE JEUNE", cp : "80430" },
{ code : "80062", intitule : "BEAUCAMPS LE VIEUX", cp : "80430" },
{ code : "80063", intitule : "BEAUCHAMPS", cp : "80770" },
{ code : "80064", intitule : "BEAUCOURT EN SANTERRE", cp : "80110" },
{ code : "80065", intitule : "BEAUCOURT SUR L ANCRE", cp : "80300" },
{ code : "80066", intitule : "BEAUCOURT SUR L HALLUE", cp : "80260" },
{ code : "80067", intitule : "BEAUFORT EN SANTERRE", cp : "80170" },
{ code : "80068", intitule : "BEAUMETZ", cp : "80370" },
{ code : "80069", intitule : "BEAUMONT HAMEL", cp : "80300" },
{ code : "80070", intitule : "BEAUQUESNE", cp : "80600" },
{ code : "80071", intitule : "BEAUVAL", cp : "80630" },
{ code : "80073", intitule : "BECORDEL BECOURT", cp : "80300" },
{ code : "80074", intitule : "BECQUIGNY", cp : "80500" },
{ code : "80076", intitule : "BEHEN", cp : "80870" },
{ code : "80077", intitule : "BEHENCOURT", cp : "80260" },
{ code : "80078", intitule : "BELLANCOURT", cp : "80132" },
{ code : "80079", intitule : "BELLEUSE", cp : "80160" },
{ code : "80080", intitule : "BELLOY EN SANTERRE", cp : "80200" },
{ code : "80081", intitule : "BELLOY ST LEONARD", cp : "80270" },
{ code : "80082", intitule : "BELLOY SUR SOMME", cp : "80310" },
{ code : "80083", intitule : "BERGICOURT", cp : "80290" },
{ code : "80084", intitule : "BERMESNIL", cp : "80140" },
{ code : "80084", intitule : "BERMESNIL", cp : "80140" },
{ code : "80085", intitule : "BERNATRE", cp : "80370" },
{ code : "80086", intitule : "BERNAVILLE", cp : "80370" },
{ code : "80086", intitule : "BERNAVILLE", cp : "80370" },
{ code : "80087", intitule : "BERNAY EN PONTHIEU", cp : "80120" },
{ code : "80088", intitule : "BERNES", cp : "80240" },
{ code : "80089", intitule : "BERNEUIL", cp : "80620" },
{ code : "80090", intitule : "BERNY EN SANTERRE", cp : "80200" },
{ code : "80092", intitule : "BERTANGLES", cp : "80260" },
{ code : "80093", intitule : "BERTEAUCOURT LES DAMES", cp : "80850" },
{ code : "80094", intitule : "BERTEAUCOURT LES THENNES", cp : "80110" },
{ code : "80095", intitule : "BERTRANCOURT", cp : "80560" },
{ code : "80096", intitule : "BETHENCOURT SUR MER", cp : "80130" },
{ code : "80097", intitule : "BETHENCOURT SUR SOMME", cp : "80190" },
{ code : "80098", intitule : "BETTEMBOS", cp : "80290" },
{ code : "80099", intitule : "BETTENCOURT RIVIERE", cp : "80270" },
{ code : "80100", intitule : "BETTENCOURT ST OUEN", cp : "80610" },
{ code : "80101", intitule : "BEUVRAIGNES", cp : "80700" },
{ code : "80102", intitule : "BIACHES", cp : "80200" },
{ code : "80103", intitule : "BIARRE", cp : "80190" },
{ code : "80104", intitule : "BIENCOURT", cp : "80140" },
{ code : "80105", intitule : "BILLANCOURT", cp : "80190" },
{ code : "80106", intitule : "BLANGY SOUS POIX", cp : "80290" },
{ code : "80107", intitule : "BLANGY TRONVILLE", cp : "80440" },
{ code : "80108", intitule : "BOISBERGUES", cp : "80600" },
{ code : "80109", intitule : "LE BOISLE", cp : "80150" },
{ code : "80110", intitule : "BOISMONT", cp : "80230" },
{ code : "80112", intitule : "BONNAY", cp : "80800" },
{ code : "80113", intitule : "BONNEVILLE", cp : "80670" },
{ code : "80114", intitule : "BOSQUEL", cp : "80160" },
{ code : "80115", intitule : "BOUCHAVESNES BERGEN", cp : "80200" },
{ code : "80116", intitule : "BOUCHOIR", cp : "80910" },
{ code : "80117", intitule : "BOUCHON", cp : "80830" },
{ code : "80118", intitule : "BOUFFLERS", cp : "80150" },
{ code : "80119", intitule : "BOUGAINVILLE", cp : "80540" },
{ code : "80120", intitule : "BOUILLANCOURT EN SERY", cp : "80220" },
{ code : "80121", intitule : "BOUILLANCOURT LA BATAILLE", cp : "80500" },
{ code : "80122", intitule : "BOUQUEMAISON", cp : "80600" },
{ code : "80123", intitule : "BOURDON", cp : "80310" },
{ code : "80124", intitule : "BOURSEVILLE", cp : "80130" },
{ code : "80125", intitule : "BOUSSICOURT", cp : "80500" },
{ code : "80126", intitule : "BOUTTENCOURT", cp : "80220" },
{ code : "80127", intitule : "BOUVAINCOURT SUR BRESLE", cp : "80220" },
{ code : "80128", intitule : "BOUVINCOURT EN VERMANDOIS", cp : "80200" },
{ code : "80129", intitule : "BOUZINCOURT", cp : "80300" },
{ code : "80130", intitule : "BOVELLES", cp : "80540" },
{ code : "80131", intitule : "BOVES", cp : "80440" },
{ code : "80132", intitule : "BRACHES", cp : "80110" },
{ code : "80133", intitule : "BRAILLY CORNEHOTTE", cp : "80150" },
{ code : "80134", intitule : "BRASSY", cp : "80160" },
{ code : "80135", intitule : "BRAY LES MAREUIL", cp : "80580" },
{ code : "80136", intitule : "BRAY SUR SOMME", cp : "80340" },
{ code : "80137", intitule : "BREILLY", cp : "80470" },
{ code : "80138", intitule : "BRESLE", cp : "80300" },
{ code : "80139", intitule : "BREUIL", cp : "80400" },
{ code : "80140", intitule : "BREVILLERS", cp : "80600" },
{ code : "80141", intitule : "BRIE", cp : "80200" },
{ code : "80142", intitule : "BRIQUEMESNIL FLOXICOURT", cp : "80540" },
{ code : "80143", intitule : "BROCOURT", cp : "80430" },
{ code : "80144", intitule : "BROUCHY", cp : "80400" },
{ code : "80145", intitule : "BRUCAMPS", cp : "80690" },
{ code : "80146", intitule : "BRUTELLES", cp : "80230" },
{ code : "80147", intitule : "BUIGNY L ABBE", cp : "80132" },
{ code : "80148", intitule : "BUIGNY LES GAMACHES", cp : "80220" },
{ code : "80149", intitule : "BUIGNY ST MACLOU", cp : "80132" },
{ code : "80150", intitule : "BUIRE COURCELLES", cp : "80200" },
{ code : "80151", intitule : "BUIRE SUR L ANCRE", cp : "80300" },
{ code : "80152", intitule : "BUS LA MESIERE", cp : "80700" },
{ code : "80153", intitule : "BUS LES ARTOIS", cp : "80560" },
{ code : "80154", intitule : "BUSSU", cp : "80200" },
{ code : "80155", intitule : "BUSSUS BUSSUEL", cp : "80135" },
{ code : "80156", intitule : "BUSSY LES DAOURS", cp : "80800" },
{ code : "80157", intitule : "BUSSY LES POIX", cp : "80290" },
{ code : "80158", intitule : "BUVERCHY", cp : "80400" },
{ code : "80159", intitule : "CACHY", cp : "80800" },
{ code : "80160", intitule : "CAGNY", cp : "80330" },
{ code : "80161", intitule : "CAHON", cp : "80132" },
{ code : "80162", intitule : "CAIX", cp : "80170" },
{ code : "80163", intitule : "CAMBRON", cp : "80132" },
{ code : "80164", intitule : "CAMON", cp : "80450" },
{ code : "80165", intitule : "CAMPS EN AMIENOIS", cp : "80540" },
{ code : "80166", intitule : "CANAPLES", cp : "80670" },
{ code : "80167", intitule : "CANCHY", cp : "80150" },
{ code : "80168", intitule : "CANDAS", cp : "80750" },
{ code : "80169", intitule : "CANNESSIERES", cp : "80140" },
{ code : "80170", intitule : "CANTIGNY", cp : "80500" },
{ code : "80171", intitule : "CAOURS", cp : "80132" },
{ code : "80172", intitule : "CAPPY", cp : "80340" },
{ code : "80173", intitule : "CARDONNETTE", cp : "80260" },
{ code : "80174", intitule : "LE CARDONNOIS", cp : "80500" },
{ code : "80175", intitule : "CARNOY", cp : "80300" },
{ code : "80176", intitule : "CARREPUIS", cp : "80700" },
{ code : "80177", intitule : "CARTIGNY", cp : "80200" },
{ code : "80179", intitule : "CAULIERES", cp : "80290" },
{ code : "80180", intitule : "CAVILLON", cp : "80310" },
{ code : "80181", intitule : "CAYEUX EN SANTERRE", cp : "80800" },
{ code : "80182", intitule : "CAYEUX SUR MER", cp : "80410" },
{ code : "80183", intitule : "CERISY BULEUX", cp : "80140" },
{ code : "80184", intitule : "CERISY", cp : "80800" },
{ code : "80185", intitule : "CHAMPIEN", cp : "80700" },
{ code : "80186", intitule : "CHAULNES", cp : "80320" },
{ code : "80187", intitule : "LA CHAUSSEE TIRANCOURT", cp : "80310" },
{ code : "80188", intitule : "CHAUSSOY EPAGNY", cp : "80250" },
{ code : "80189", intitule : "LA CHAVATTE", cp : "80700" },
{ code : "80190", intitule : "CHEPY", cp : "80210" },
{ code : "80191", intitule : "CHILLY", cp : "80170" },
{ code : "80192", intitule : "CHIPILLY", cp : "80800" },
{ code : "80193", intitule : "CHIRMONT", cp : "80250" },
{ code : "80194", intitule : "CHUIGNES", cp : "80340" },
{ code : "80195", intitule : "CHUIGNOLLES", cp : "80340" },
{ code : "80196", intitule : "CITERNE", cp : "80490" },
{ code : "80197", intitule : "CIZANCOURT", cp : "80200" },
{ code : "80198", intitule : "CLAIRY SAULCHOIX", cp : "80540" },
{ code : "80199", intitule : "CLERY SUR SOMME", cp : "80200" },
{ code : "80200", intitule : "COCQUEREL", cp : "80510" },
{ code : "80201", intitule : "COIGNEUX", cp : "80560" },
{ code : "80202", intitule : "COISY", cp : "80260" },
{ code : "80203", intitule : "COLINCAMPS", cp : "80560" },
{ code : "80204", intitule : "COMBLES", cp : "80360" },
{ code : "80205", intitule : "CONDE FOLIE", cp : "80890" },
{ code : "80206", intitule : "CONTALMAISON", cp : "80300" },
{ code : "80207", intitule : "CONTAY", cp : "80560" },
{ code : "80208", intitule : "CONTEVILLE", cp : "80370" },
{ code : "80209", intitule : "CONTOIRE", cp : "80500" },
{ code : "80210", intitule : "CONTRE", cp : "80160" },
{ code : "80211", intitule : "CONTY", cp : "80160" },
{ code : "80211", intitule : "CONTY", cp : "80160" },
{ code : "80212", intitule : "CORBIE", cp : "80800" },
{ code : "80213", intitule : "COTTENCHY", cp : "80440" },
{ code : "80214", intitule : "COULLEMELLE", cp : "80250" },
{ code : "80215", intitule : "COULONVILLERS", cp : "80135" },
{ code : "80216", intitule : "COURCELETTE", cp : "80300" },
{ code : "80217", intitule : "COURCELLES AU BOIS", cp : "80560" },
{ code : "80218", intitule : "COURCELLES SOUS MOYENCOURT", cp : "80290" },
{ code : "80219", intitule : "COURCELLES SOUS THOIX", cp : "80160" },
{ code : "80220", intitule : "COURTEMANCHE", cp : "80500" },
{ code : "80221", intitule : "CRAMONT", cp : "80370" },
{ code : "80222", intitule : "CRECY EN PONTHIEU", cp : "80150" },
{ code : "80222", intitule : "CRECY EN PONTHIEU", cp : "80150" },
{ code : "80223", intitule : "CREMERY", cp : "80700" },
{ code : "80224", intitule : "CRESSY OMENCOURT", cp : "80190" },
{ code : "80225", intitule : "CREUSE", cp : "80480" },
{ code : "80226", intitule : "CROIX MOLIGNEAUX", cp : "80400" },
{ code : "80227", intitule : "CROIXRAULT", cp : "80290" },
{ code : "80228", intitule : "LE CROTOY", cp : "80550" },
{ code : "80229", intitule : "CROUY ST PIERRE", cp : "80310" },
{ code : "80229", intitule : "CROUY ST PIERRE", cp : "80310" },
{ code : "80230", intitule : "CURCHY", cp : "80190" },
{ code : "80230", intitule : "CURCHY", cp : "80190" },
{ code : "80231", intitule : "CURLU", cp : "80360" },
{ code : "80232", intitule : "DAMERY", cp : "80700" },
{ code : "80233", intitule : "DANCOURT POPINCOURT", cp : "80700" },
{ code : "80234", intitule : "DAOURS", cp : "80800" },
{ code : "80235", intitule : "DARGNIES", cp : "80570" },
{ code : "80236", intitule : "DAVENESCOURT", cp : "80500" },
{ code : "80237", intitule : "DEMUIN", cp : "80110" },
{ code : "80238", intitule : "DERNANCOURT", cp : "80300" },
{ code : "80239", intitule : "DEVISE", cp : "80200" },
{ code : "80240", intitule : "DOINGT", cp : "80200" },
{ code : "80241", intitule : "DOMART EN PONTHIEU", cp : "80620" },
{ code : "80242", intitule : "DOMART SUR LA LUCE", cp : "80110" },
{ code : "80243", intitule : "DOMESMONT", cp : "80370" },
{ code : "80244", intitule : "DOMINOIS", cp : "80120" },
{ code : "80245", intitule : "DOMLEGER LONGVILLERS", cp : "80370" },
{ code : "80245", intitule : "DOMLEGER LONGVILLERS", cp : "80370" },
{ code : "80246", intitule : "DOMMARTIN", cp : "80440" },
{ code : "80247", intitule : "DOMPIERRE BECQUINCOURT", cp : "80980" },
{ code : "80248", intitule : "DOMPIERRE SUR AUTHIE", cp : "80150" },
{ code : "80249", intitule : "DOMQUEUR", cp : "80620" },
{ code : "80250", intitule : "DOMVAST", cp : "80150" },
{ code : "80251", intitule : "DOUDELAINVILLE", cp : "80140" },
{ code : "80252", intitule : "DOUILLY", cp : "80400" },
{ code : "80253", intitule : "DOULLENS", cp : "80600" },
{ code : "80256", intitule : "DREUIL LES AMIENS", cp : "80470" },
{ code : "80258", intitule : "DRIENCOURT", cp : "80240" },
{ code : "80259", intitule : "DROMESNIL", cp : "80640" },
{ code : "80260", intitule : "DRUCAT", cp : "80132" },
{ code : "80261", intitule : "DURY", cp : "80480" },
{ code : "80262", intitule : "EAUCOURT SUR SOMME", cp : "80580" },
{ code : "80263", intitule : "L ECHELLE ST AURIN", cp : "80700" },
{ code : "80264", intitule : "ECLUSIER VAUX", cp : "80340" },
{ code : "80265", intitule : "EMBREVILLE", cp : "80570" },
{ code : "80266", intitule : "ENGLEBELMER", cp : "80300" },
{ code : "80267", intitule : "ENNEMAIN", cp : "80200" },
{ code : "80268", intitule : "EPAGNE EPAGNETTE", cp : "80580" },
{ code : "80269", intitule : "EPAUMESNIL", cp : "80140" },
{ code : "80270", intitule : "EPECAMPS", cp : "80370" },
{ code : "80271", intitule : "EPEHY", cp : "80740" },
{ code : "80272", intitule : "EPENANCOURT", cp : "80190" },
{ code : "80273", intitule : "EPLESSIER", cp : "80290" },
{ code : "80274", intitule : "EPPEVILLE", cp : "80400" },
{ code : "80275", intitule : "EQUANCOURT", cp : "80360" },
{ code : "80276", intitule : "EQUENNES ERAMECOURT", cp : "80290" },
{ code : "80276", intitule : "EQUENNES ERAMECOURT", cp : "80290" },
{ code : "80278", intitule : "ERCHES", cp : "80500" },
{ code : "80279", intitule : "ERCHEU", cp : "80400" },
{ code : "80280", intitule : "ERCOURT", cp : "80210" },
{ code : "80281", intitule : "ERGNIES", cp : "80690" },
{ code : "80282", intitule : "ERONDELLE", cp : "80580" },
{ code : "80283", intitule : "ESCLAINVILLERS", cp : "80250" },
{ code : "80284", intitule : "ESMERY HALLON", cp : "80400" },
{ code : "80285", intitule : "ESSERTAUX", cp : "80160" },
{ code : "80287", intitule : "ESTREBOEUF", cp : "80230" },
{ code : "80288", intitule : "ESTREES DENIECOURT", cp : "80200" },
{ code : "80290", intitule : "ESTREES LES CRECY", cp : "80150" },
{ code : "80291", intitule : "ESTREES SUR NOYE", cp : "80250" },
{ code : "80292", intitule : "ETALON", cp : "80190" },
{ code : "80293", intitule : "ETELFAY", cp : "80500" },
{ code : "80294", intitule : "ETERPIGNY", cp : "80200" },
{ code : "80295", intitule : "ETINEHEM", cp : "80340" },
{ code : "80296", intitule : "L ETOILE", cp : "80830" },
{ code : "80297", intitule : "ETREJUST", cp : "80140" },
{ code : "80298", intitule : "ETRICOURT MANANCOURT", cp : "80360" },
{ code : "80299", intitule : "LA FALOISE", cp : "80250" },
{ code : "80300", intitule : "FALVY", cp : "80190" },
{ code : "80301", intitule : "FAMECHON", cp : "80290" },
{ code : "80302", intitule : "FAVEROLLES", cp : "80500" },
{ code : "80303", intitule : "FAVIERES", cp : "80120" },
{ code : "80304", intitule : "FAY", cp : "80200" },
{ code : "80305", intitule : "FERRIERES", cp : "80470" },
{ code : "80306", intitule : "FESCAMPS", cp : "80500" },
{ code : "80307", intitule : "FEUILLERES", cp : "80200" },
{ code : "80308", intitule : "FEUQUIERES EN VIMEU", cp : "80210" },
{ code : "80310", intitule : "FIENVILLERS", cp : "80750" },
{ code : "80311", intitule : "FIGNIERES", cp : "80500" },
{ code : "80312", intitule : "FINS", cp : "80360" },
{ code : "80313", intitule : "FLAUCOURT", cp : "80200" },
{ code : "80314", intitule : "FLERS", cp : "80360" },
{ code : "80315", intitule : "FLERS SUR NOYE", cp : "80160" },
{ code : "80316", intitule : "FLESSELLES", cp : "80260" },
{ code : "80317", intitule : "FLEURY", cp : "80160" },
{ code : "80318", intitule : "FLIXECOURT", cp : "80420" },
{ code : "80319", intitule : "FLUY", cp : "80540" },
{ code : "80320", intitule : "FOLIES", cp : "80170" },
{ code : "80321", intitule : "FOLLEVILLE", cp : "80250" },
{ code : "80322", intitule : "FONCHES FONCHETTE", cp : "80700" },
{ code : "80324", intitule : "FONTAINE LE SEC", cp : "80140" },
{ code : "80325", intitule : "FONTAINE LES CAPPY", cp : "80340" },
{ code : "80326", intitule : "FONTAINE SOUS MONTDIDIER", cp : "80500" },
{ code : "80327", intitule : "FONTAINE SUR MAYE", cp : "80150" },
{ code : "80328", intitule : "FONTAINE SUR SOMME", cp : "80510" },
{ code : "80329", intitule : "FORCEVILLE", cp : "80560" },
{ code : "80330", intitule : "FORCEVILLE EN VIMEU", cp : "80140" },
{ code : "80331", intitule : "FOREST L ABBAYE", cp : "80150" },
{ code : "80332", intitule : "FOREST MONTIERS", cp : "80120" },
{ code : "80333", intitule : "FORT MAHON PLAGE", cp : "80120" },
{ code : "80334", intitule : "FOSSEMANANT", cp : "80160" },
{ code : "80335", intitule : "FOUCAUCOURT EN SANTERRE", cp : "80340" },
{ code : "80336", intitule : "FOUCAUCOURT HORS NESLE", cp : "80140" },
{ code : "80337", intitule : "FOUENCAMPS", cp : "80440" },
{ code : "80338", intitule : "FOUILLOY", cp : "80800" },
{ code : "80339", intitule : "FOUQUESCOURT", cp : "80170" },
{ code : "80340", intitule : "FOURCIGNY", cp : "80290" },
{ code : "80341", intitule : "FOURDRINOY", cp : "80310" },
{ code : "80342", intitule : "FRAMERVILLE RAINECOURT", cp : "80131" },
{ code : "80343", intitule : "FRAMICOURT", cp : "80140" },
{ code : "80344", intitule : "FRANCIERES", cp : "80690" },
{ code : "80345", intitule : "FRANLEU", cp : "80210" },
{ code : "80346", intitule : "FRANQUEVILLE", cp : "80620" },
{ code : "80347", intitule : "FRANSART", cp : "80700" },
{ code : "80348", intitule : "FRANSU", cp : "80620" },
{ code : "80349", intitule : "FRANSURES", cp : "80160" },
{ code : "80350", intitule : "FRANVILLERS", cp : "80800" },
{ code : "80351", intitule : "FRECHENCOURT", cp : "80260" },
{ code : "80352", intitule : "FREMONTIERS", cp : "80160" },
{ code : "80353", intitule : "FRESNES MAZANCOURT", cp : "80320" },
{ code : "80354", intitule : "FRESNES TILLOLOY", cp : "80140" },
{ code : "80355", intitule : "FRESNEVILLE", cp : "80140" },
{ code : "80356", intitule : "FRESNOY ANDAINVILLE", cp : "80140" },
{ code : "80357", intitule : "FRESNOY AU VAL", cp : "80290" },
{ code : "80358", intitule : "FRESNOY EN CHAUSSEE", cp : "80110" },
{ code : "80359", intitule : "FRESNOY LES ROYE", cp : "80700" },
{ code : "80360", intitule : "FRESSENNEVILLE", cp : "80390" },
{ code : "80361", intitule : "FRETTECUISSE", cp : "80140" },
{ code : "80362", intitule : "FRETTEMEULE", cp : "80220" },
{ code : "80364", intitule : "FRIAUCOURT", cp : "80460" },
{ code : "80365", intitule : "FRICAMPS", cp : "80290" },
{ code : "80366", intitule : "FRICOURT", cp : "80300" },
{ code : "80367", intitule : "FRISE", cp : "80340" },
{ code : "80368", intitule : "FRIVILLE ESCARBOTIN", cp : "80130" },
{ code : "80369", intitule : "FROHEN SUR AUTHIE", cp : "80370" },
{ code : "80369", intitule : "FROHEN SUR AUTHIE", cp : "80370" },
{ code : "80371", intitule : "FROYELLES", cp : "80150" },
{ code : "80372", intitule : "FRUCOURT", cp : "80490" },
{ code : "80373", intitule : "GAMACHES", cp : "80220" },
{ code : "80374", intitule : "GAPENNES", cp : "80150" },
{ code : "80375", intitule : "GAUVILLE", cp : "80290" },
{ code : "80376", intitule : "GENTELLES", cp : "80800" },
{ code : "80377", intitule : "GEZAINCOURT", cp : "80600" },
{ code : "80378", intitule : "GINCHY", cp : "80360" },
{ code : "80379", intitule : "GLISY", cp : "80440" },
{ code : "80380", intitule : "GORENFLOS", cp : "80690" },
{ code : "80381", intitule : "GORGES", cp : "80370" },
{ code : "80383", intitule : "GOYENCOURT", cp : "80700" },
{ code : "80384", intitule : "GRANDCOURT", cp : "80300" },
{ code : "80385", intitule : "GRAND LAVIERS", cp : "80132" },
{ code : "80386", intitule : "GRATIBUS", cp : "80500" },
{ code : "80387", intitule : "GRATTEPANCHE", cp : "80680" },
{ code : "80388", intitule : "GREBAULT MESNIL", cp : "80140" },
{ code : "80389", intitule : "GRECOURT", cp : "80400" },
{ code : "80390", intitule : "GRIVESNES", cp : "80250" },
{ code : "80391", intitule : "GRIVILLERS", cp : "80700" },
{ code : "80392", intitule : "GROUCHES LUCHUEL", cp : "80600" },
{ code : "80393", intitule : "GRUNY", cp : "80700" },
{ code : "80395", intitule : "GUERBIGNY", cp : "80500" },
{ code : "80396", intitule : "GUESCHART", cp : "80150" },
{ code : "80397", intitule : "GUEUDECOURT", cp : "80360" },
{ code : "80399", intitule : "GUIGNEMICOURT", cp : "80540" },
{ code : "80400", intitule : "GUILLAUCOURT", cp : "80170" },
{ code : "80401", intitule : "GUILLEMONT", cp : "80360" },
{ code : "80402", intitule : "GUIZANCOURT", cp : "80290" },
{ code : "80403", intitule : "GUYENCOURT SUR NOYE", cp : "80250" },
{ code : "80404", intitule : "GUYENCOURT SAULCOURT", cp : "80240" },
{ code : "80405", intitule : "HAILLES", cp : "80440" },
{ code : "80406", intitule : "HALLENCOURT", cp : "80490" },
{ code : "80406", intitule : "HALLENCOURT", cp : "80490" },
{ code : "80406", intitule : "HALLENCOURT", cp : "80490" },
{ code : "80407", intitule : "HALLIVILLERS", cp : "80250" },
{ code : "80408", intitule : "HALLOY LES PERNOIS", cp : "80670" },
{ code : "80409", intitule : "HALLU", cp : "80320" },
{ code : "80410", intitule : "HAM", cp : "80400" },
{ code : "80411", intitule : "LE HAMEL", cp : "80800" },
{ code : "80412", intitule : "HAMELET", cp : "80800" },
{ code : "80413", intitule : "HANCOURT", cp : "80240" },
{ code : "80414", intitule : "HANGARD", cp : "80110" },
{ code : "80415", intitule : "HANGEST EN SANTERRE", cp : "80134" },
{ code : "80416", intitule : "HANGEST SUR SOMME", cp : "80310" },
{ code : "80417", intitule : "HARBONNIERES", cp : "80131" },
{ code : "80418", intitule : "HARDECOURT AUX BOIS", cp : "80360" },
{ code : "80419", intitule : "HARGICOURT", cp : "80500" },
{ code : "80420", intitule : "HARPONVILLE", cp : "80560" },
{ code : "80421", intitule : "HATTENCOURT", cp : "80700" },
{ code : "80422", intitule : "HAUTVILLERS OUVILLE", cp : "80132" },
{ code : "80423", intitule : "HAVERNAS", cp : "80670" },
{ code : "80424", intitule : "HEBECOURT", cp : "80680" },
{ code : "80425", intitule : "HEDAUVILLE", cp : "80560" },
{ code : "80426", intitule : "HEILLY", cp : "80800" },
{ code : "80427", intitule : "HEM HARDINVAL", cp : "80600" },
{ code : "80428", intitule : "HEM MONACU", cp : "80360" },
{ code : "80429", intitule : "HENENCOURT", cp : "80300" },
{ code : "80430", intitule : "HERBECOURT", cp : "80200" },
{ code : "80431", intitule : "HERISSART", cp : "80260" },
{ code : "80432", intitule : "HERLEVILLE", cp : "80340" },
{ code : "80433", intitule : "HERLY", cp : "80190" },
{ code : "80434", intitule : "HERVILLY", cp : "80240" },
{ code : "80435", intitule : "HESBECOURT", cp : "80240" },
{ code : "80436", intitule : "HESCAMPS", cp : "80290" },
{ code : "80436", intitule : "HESCAMPS", cp : "80290" },
{ code : "80436", intitule : "HESCAMPS", cp : "80290" },
{ code : "80436", intitule : "HESCAMPS", cp : "80290" },
{ code : "80437", intitule : "HEUCOURT CROQUOISON", cp : "80270" },
{ code : "80438", intitule : "HEUDICOURT", cp : "80122" },
{ code : "80439", intitule : "HEUZECOURT", cp : "80370" },
{ code : "80440", intitule : "HIERMONT", cp : "80370" },
{ code : "80442", intitule : "HOMBLEUX", cp : "80400" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80443", intitule : "HORNOY LE BOURG", cp : "80640" },
{ code : "80444", intitule : "HUCHENNEVILLE", cp : "80132" },
{ code : "80445", intitule : "HUMBERCOURT", cp : "80600" },
{ code : "80446", intitule : "HUPPY", cp : "80140" },
{ code : "80447", intitule : "HYENCOURT LE GRAND", cp : "80320" },
{ code : "80449", intitule : "IGNAUCOURT", cp : "80800" },
{ code : "80450", intitule : "INVAL BOIRON", cp : "80430" },
{ code : "80451", intitule : "IRLES", cp : "80300" },
{ code : "80452", intitule : "JUMEL", cp : "80250" },
{ code : "80453", intitule : "LABOISSIERE EN SANTERRE", cp : "80500" },
{ code : "80455", intitule : "LACHAPELLE", cp : "80290" },
{ code : "80456", intitule : "LAFRESGUIMONT ST MARTIN", cp : "80430" },
{ code : "80456", intitule : "LAFRESGUIMONT ST MARTIN", cp : "80430" },
{ code : "80456", intitule : "LAFRESGUIMONT ST MARTIN", cp : "80430" },
{ code : "80456", intitule : "LAFRESGUIMONT ST MARTIN", cp : "80430" },
{ code : "80458", intitule : "LAHOUSSOYE", cp : "80800" },
{ code : "80459", intitule : "LALEU", cp : "80270" },
{ code : "80460", intitule : "LAMARONDE", cp : "80290" },
{ code : "80461", intitule : "LAMOTTE BREBIERE", cp : "80450" },
{ code : "80462", intitule : "LAMOTTE BULEUX", cp : "80150" },
{ code : "80463", intitule : "LAMOTTE WARFUSEE", cp : "80800" },
{ code : "80463", intitule : "LAMOTTE WARFUSEE", cp : "80800" },
{ code : "80464", intitule : "LANCHERES", cp : "80230" },
{ code : "80465", intitule : "LANGUEVOISIN QUIQUERY", cp : "80190" },
{ code : "80466", intitule : "LANCHES ST HILAIRE", cp : "80620" },
{ code : "80467", intitule : "LAUCOURT", cp : "80700" },
{ code : "80468", intitule : "LAVIEVILLE", cp : "80300" },
{ code : "80469", intitule : "LAWARDE MAUGER L HORTOY", cp : "80250" },
{ code : "80470", intitule : "LEALVILLERS", cp : "80560" },
{ code : "80472", intitule : "LESBOEUFS", cp : "80360" },
{ code : "80473", intitule : "LIANCOURT FOSSE", cp : "80700" },
{ code : "80474", intitule : "LICOURT", cp : "80320" },
{ code : "80475", intitule : "LIERAMONT", cp : "80240" },
{ code : "80476", intitule : "LIERCOURT", cp : "80580" },
{ code : "80477", intitule : "LIGESCOURT", cp : "80150" },
{ code : "80478", intitule : "LIGNIERES", cp : "80500" },
{ code : "80479", intitule : "LIGNIERES CHATELAIN", cp : "80290" },
{ code : "80480", intitule : "LIGNIERES EN VIMEU", cp : "80140" },
{ code : "80481", intitule : "LIHONS", cp : "80320" },
{ code : "80482", intitule : "LIMEUX", cp : "80490" },
{ code : "80484", intitule : "LIOMER", cp : "80430" },
{ code : "80485", intitule : "LOEUILLY", cp : "80160" },
{ code : "80486", intitule : "LONG", cp : "80510" },
{ code : "80487", intitule : "LONGAVESNES", cp : "80240" },
{ code : "80488", intitule : "LONGPRE LES CORPS SAINTS", cp : "80510" },
{ code : "80489", intitule : "LONGUEAU", cp : "80330" },
{ code : "80490", intitule : "LONGUEVAL", cp : "80360" },
{ code : "80491", intitule : "LONGUEVILLETTE", cp : "80600" },
{ code : "80493", intitule : "LOUVENCOURT", cp : "80560" },
{ code : "80494", intitule : "LOUVRECHY", cp : "80250" },
{ code : "80495", intitule : "LUCHEUX", cp : "80600" },
{ code : "80496", intitule : "MACHIEL", cp : "80150" },
{ code : "80497", intitule : "MACHY", cp : "80150" },
{ code : "80498", intitule : "MAILLY MAILLET", cp : "80560" },
{ code : "80499", intitule : "MAILLY RAINEVAL", cp : "80110" },
{ code : "80500", intitule : "MAISNIERES", cp : "80220" },
{ code : "80501", intitule : "MAISON PONTHIEU", cp : "80150" },
{ code : "80502", intitule : "MAISON ROLAND", cp : "80135" },
{ code : "80503", intitule : "MAIZICOURT", cp : "80370" },
{ code : "80504", intitule : "MALPART", cp : "80250" },
{ code : "80505", intitule : "MAMETZ", cp : "80300" },
{ code : "80507", intitule : "MARCELCAVE", cp : "80800" },
{ code : "80508", intitule : "MARCHE ALLOUARDE", cp : "80700" },
{ code : "80509", intitule : "MARCHELEPOT", cp : "80200" },
{ code : "80511", intitule : "MARESTMONTIERS", cp : "80500" },
{ code : "80512", intitule : "MAREUIL CAUBERT", cp : "80132" },
{ code : "80513", intitule : "MARICOURT", cp : "80360" },
{ code : "80514", intitule : "MARIEUX", cp : "80560" },
{ code : "80515", intitule : "MARLERS", cp : "80290" },
{ code : "80516", intitule : "MARQUAIX", cp : "80240" },
{ code : "80517", intitule : "MARQUIVILLERS", cp : "80700" },
{ code : "80518", intitule : "MARTAINNEVILLE", cp : "80140" },
{ code : "80519", intitule : "MATIGNY", cp : "80400" },
{ code : "80520", intitule : "MAUCOURT", cp : "80170" },
{ code : "80521", intitule : "MAUREPAS", cp : "80360" },
{ code : "80522", intitule : "LE MAZIS", cp : "80430" },
{ code : "80523", intitule : "MEAULTE", cp : "80300" },
{ code : "80524", intitule : "MEHARICOURT", cp : "80170" },
{ code : "80525", intitule : "MEIGNEUX", cp : "80290" },
{ code : "80526", intitule : "LE MEILLARD", cp : "80370" },
{ code : "80527", intitule : "MENESLIES", cp : "80520" },
{ code : "80528", intitule : "MEREAUCOURT", cp : "80290" },
{ code : "80529", intitule : "MERELESSART", cp : "80490" },
{ code : "80530", intitule : "MERICOURT L ABBE", cp : "80800" },
{ code : "80531", intitule : "MERICOURT EN VIMEU", cp : "80640" },
{ code : "80532", intitule : "MERICOURT SUR SOMME", cp : "80340" },
{ code : "80533", intitule : "MERS LES BAINS", cp : "80350" },
{ code : "80535", intitule : "LE MESGE", cp : "80310" },
{ code : "80536", intitule : "MESNIL BRUNTEL", cp : "80200" },
{ code : "80537", intitule : "MESNIL DOMQUEUR", cp : "80620" },
{ code : "80538", intitule : "MESNIL EN ARROUAISE", cp : "80360" },
{ code : "80540", intitule : "MESNIL MARTINSART", cp : "80300" },
{ code : "80541", intitule : "MESNIL ST GEORGES", cp : "80500" },
{ code : "80542", intitule : "MESNIL ST NICAISE", cp : "80190" },
{ code : "80543", intitule : "METIGNY", cp : "80270" },
{ code : "80544", intitule : "MEZEROLLES", cp : "80600" },
{ code : "80545", intitule : "MEZIERES EN SANTERRE", cp : "80110" },
{ code : "80546", intitule : "MIANNAY", cp : "80132" },
{ code : "80547", intitule : "MILLENCOURT", cp : "80300" },
{ code : "80548", intitule : "MILLENCOURT EN PONTHIEU", cp : "80135" },
{ code : "80549", intitule : "MIRAUMONT", cp : "80300" },
{ code : "80550", intitule : "MIRVAUX", cp : "80260" },
{ code : "80551", intitule : "MISERY", cp : "80320" },
{ code : "80552", intitule : "MOISLAINS", cp : "80200" },
{ code : "80553", intitule : "MOLLIENS AU BOIS", cp : "80260" },
{ code : "80554", intitule : "MOLLIENS DREUIL", cp : "80540" },
{ code : "80554", intitule : "MOLLIENS DREUIL", cp : "80540" },
{ code : "80555", intitule : "MONCHY LAGACHE", cp : "80200" },
{ code : "80555", intitule : "MONCHY LAGACHE", cp : "80200" },
{ code : "80556", intitule : "MONS BOUBERT", cp : "80210" },
{ code : "80557", intitule : "ESTREES MONS", cp : "80200" },
{ code : "80557", intitule : "ESTREES MONS", cp : "80200" },
{ code : "80558", intitule : "MONSURES", cp : "80160" },
{ code : "80559", intitule : "MONTAGNE FAYEL", cp : "80540" },
{ code : "80560", intitule : "MONTAUBAN DE PICARDIE", cp : "80300" },
{ code : "80561", intitule : "MONTDIDIER", cp : "80500" },
{ code : "80562", intitule : "MONTIGNY SUR L HALLUE", cp : "80260" },
{ code : "80563", intitule : "MONTIGNY LES JONGLEURS", cp : "80370" },
{ code : "80565", intitule : "MONTONVILLERS", cp : "80260" },
{ code : "80566", intitule : "FIEFFES MONTRELET", cp : "80670" },
{ code : "80566", intitule : "FIEFFES MONTRELET", cp : "80670" },
{ code : "80568", intitule : "MORCHAIN", cp : "80190" },
{ code : "80569", intitule : "MORCOURT", cp : "80340" },
{ code : "80570", intitule : "MOREUIL", cp : "80110" },
{ code : "80571", intitule : "MORISEL", cp : "80110" },
{ code : "80572", intitule : "MORLANCOURT", cp : "80300" },
{ code : "80573", intitule : "MORVILLERS ST SATURNIN", cp : "80290" },
{ code : "80573", intitule : "MORVILLERS ST SATURNIN", cp : "80290" },
{ code : "80574", intitule : "MOUFLERS", cp : "80690" },
{ code : "80575", intitule : "MOUFLIERES", cp : "80140" },
{ code : "80576", intitule : "MOYENCOURT", cp : "80400" },
{ code : "80577", intitule : "MOYENCOURT LES POIX", cp : "80290" },
{ code : "80578", intitule : "MOYENNEVILLE", cp : "80870" },
{ code : "80579", intitule : "MUILLE VILLETTE", cp : "80400" },
{ code : "80580", intitule : "NAMPONT", cp : "80120" },
{ code : "80582", intitule : "NAMPS MAISNIL", cp : "80290" },
{ code : "80582", intitule : "NAMPS MAISNIL", cp : "80290" },
{ code : "80582", intitule : "NAMPS MAISNIL", cp : "80290" },
{ code : "80582", intitule : "NAMPS MAISNIL", cp : "80290" },
{ code : "80583", intitule : "NAMPTY", cp : "80160" },
{ code : "80584", intitule : "NAOURS", cp : "80260" },
{ code : "80585", intitule : "NESLE", cp : "80190" },
{ code : "80586", intitule : "NESLE L HOPITAL", cp : "80140" },
{ code : "80587", intitule : "NESLETTE", cp : "80140" },
{ code : "80588", intitule : "NEUFMOULIN", cp : "80132" },
{ code : "80589", intitule : "NEUILLY LE DIEN", cp : "80150" },
{ code : "80590", intitule : "NEUILLY L HOPITAL", cp : "80132" },
{ code : "80591", intitule : "NEUVILLE AU BOIS", cp : "80140" },
{ code : "80592", intitule : "NEUVILLE COPPEGUEULE", cp : "80430" },
{ code : "80593", intitule : "LA NEUVILLE LES BRAY", cp : "80340" },
{ code : "80594", intitule : "NEUVILLE LES LOEUILLY", cp : "80160" },
{ code : "80595", intitule : "LA NEUVILLE SIRE BERNARD", cp : "80110" },
{ code : "80596", intitule : "NEUVILLETTE", cp : "80600" },
{ code : "80597", intitule : "NIBAS", cp : "80390" },
{ code : "80598", intitule : "NOUVION", cp : "80860" },
{ code : "80599", intitule : "NOYELLES EN CHAUSSEE", cp : "80150" },
{ code : "80600", intitule : "NOYELLES SUR MER", cp : "80860" },
{ code : "80601", intitule : "NURLU", cp : "80240" },
{ code : "80602", intitule : "OCCOCHES", cp : "80600" },
{ code : "80603", intitule : "OCHANCOURT", cp : "80210" },
{ code : "80604", intitule : "OFFIGNIES", cp : "80290" },
{ code : "80605", intitule : "OFFOY", cp : "80400" },
{ code : "80606", intitule : "OISEMONT", cp : "80140" },
{ code : "80607", intitule : "OISSY", cp : "80540" },
{ code : "80608", intitule : "OMIECOURT", cp : "80320" },
{ code : "80609", intitule : "ONEUX", cp : "80135" },
{ code : "80611", intitule : "ORESMAUX", cp : "80160" },
{ code : "80613", intitule : "OUST MAREST", cp : "80460" },
{ code : "80614", intitule : "OUTREBOIS", cp : "80600" },
{ code : "80615", intitule : "OVILLERS LA BOISSELLE", cp : "80300" },
{ code : "80616", intitule : "PARGNY", cp : "80190" },
{ code : "80617", intitule : "PARVILLERS LE QUESNOY", cp : "80700" },
{ code : "80617", intitule : "PARVILLERS LE QUESNOY", cp : "80700" },
{ code : "80618", intitule : "PENDE", cp : "80230" },
{ code : "80619", intitule : "PERNOIS", cp : "80670" },
{ code : "80620", intitule : "PERONNE", cp : "80200" },
{ code : "80621", intitule : "PERTAIN", cp : "80320" },
{ code : "80622", intitule : "PICQUIGNY", cp : "80310" },
{ code : "80623", intitule : "PIENNES ONVILLERS", cp : "80500" },
{ code : "80623", intitule : "PIENNES ONVILLERS", cp : "80500" },
{ code : "80624", intitule : "PIERREGOT", cp : "80260" },
{ code : "80625", intitule : "PIERREPONT SUR AVRE", cp : "80500" },
{ code : "80626", intitule : "PISSY", cp : "80540" },
{ code : "80627", intitule : "PLACHY BUYON", cp : "80160" },
{ code : "80628", intitule : "LE PLESSIER ROZAINVILLERS", cp : "80110" },
{ code : "80629", intitule : "POEUILLY", cp : "80240" },
{ code : "80630", intitule : "POIX DE PICARDIE", cp : "80290" },
{ code : "80630", intitule : "POIX DE PICARDIE", cp : "80290" },
{ code : "80631", intitule : "PONCHES ESTRUVAL", cp : "80150" },
{ code : "80632", intitule : "PONT DE METZ", cp : "80480" },
{ code : "80633", intitule : "PONTHOILE", cp : "80860" },
{ code : "80634", intitule : "PONT NOYELLES", cp : "80115" },
{ code : "80635", intitule : "PONT REMY", cp : "80580" },
{ code : "80637", intitule : "PORT LE GRAND", cp : "80132" },
{ code : "80638", intitule : "POTTE", cp : "80190" },
{ code : "80639", intitule : "POULAINVILLE", cp : "80260" },
{ code : "80640", intitule : "POZIERES", cp : "80300" },
{ code : "80642", intitule : "PROUVILLE", cp : "80370" },
{ code : "80643", intitule : "PROUZEL", cp : "80160" },
{ code : "80644", intitule : "PROYART", cp : "80340" },
{ code : "80645", intitule : "PUCHEVILLERS", cp : "80560" },
{ code : "80646", intitule : "PUNCHY", cp : "80320" },
{ code : "80647", intitule : "PUZEAUX", cp : "80320" },
{ code : "80648", intitule : "PYS", cp : "80300" },
{ code : "80649", intitule : "QUEND", cp : "80120" },
{ code : "80650", intitule : "QUERRIEU", cp : "80115" },
{ code : "80651", intitule : "LE QUESNE", cp : "80430" },
{ code : "80652", intitule : "LE QUESNEL", cp : "80118" },
{ code : "80654", intitule : "QUESNOY LE MONTANT", cp : "80132" },
{ code : "80655", intitule : "QUESNOY SUR AIRAINES", cp : "80270" },
{ code : "80656", intitule : "QUEVAUVILLERS", cp : "80710" },
{ code : "80657", intitule : "QUIRY LE SEC", cp : "80250" },
{ code : "80658", intitule : "QUIVIERES", cp : "80400" },
{ code : "80659", intitule : "RAINCHEVAL", cp : "80600" },
{ code : "80661", intitule : "RAINNEVILLE", cp : "80260" },
{ code : "80662", intitule : "RAMBURELLES", cp : "80140" },
{ code : "80663", intitule : "RAMBURES", cp : "80140" },
{ code : "80664", intitule : "RANCOURT", cp : "80360" },
{ code : "80665", intitule : "REGNIERE ECLUSE", cp : "80120" },
{ code : "80666", intitule : "REMAISNIL", cp : "80600" },
{ code : "80667", intitule : "REMAUGIES", cp : "80500" },
{ code : "80668", intitule : "REMIENCOURT", cp : "80250" },
{ code : "80669", intitule : "RETHONVILLERS", cp : "80700" },
{ code : "80670", intitule : "REVELLES", cp : "80540" },
{ code : "80671", intitule : "RIBEAUCOURT", cp : "80620" },
{ code : "80672", intitule : "RIBEMONT SUR ANCRE", cp : "80800" },
{ code : "80673", intitule : "RIENCOURT", cp : "80310" },
{ code : "80674", intitule : "RIVERY", cp : "80136" },
{ code : "80675", intitule : "ROGY", cp : "80160" },
{ code : "80676", intitule : "ROIGLISE", cp : "80700" },
{ code : "80677", intitule : "ROISEL", cp : "80240" },
{ code : "80678", intitule : "ROLLOT", cp : "80500" },
{ code : "80679", intitule : "RONSSOY", cp : "80740" },
{ code : "80680", intitule : "ROSIERES EN SANTERRE", cp : "80170" },
{ code : "80681", intitule : "ROUVREL", cp : "80250" },
{ code : "80682", intitule : "ROUVROY EN SANTERRE", cp : "80170" },
{ code : "80683", intitule : "ROUY LE GRAND", cp : "80190" },
{ code : "80684", intitule : "ROUY LE PETIT", cp : "80190" },
{ code : "80685", intitule : "ROYE", cp : "80700" },
{ code : "80686", intitule : "RUBEMPRE", cp : "80260" },
{ code : "80687", intitule : "RUBESCOURT", cp : "80500" },
{ code : "80688", intitule : "RUE", cp : "80120" },
{ code : "80690", intitule : "RUMIGNY", cp : "80680" },
{ code : "80691", intitule : "SAIGNEVILLE", cp : "80230" },
{ code : "80692", intitule : "SAILLY FLIBEAUCOURT", cp : "80970" },
{ code : "80693", intitule : "SAILLY LAURETTE", cp : "80800" },
{ code : "80694", intitule : "SAILLY LE SEC", cp : "80800" },
{ code : "80695", intitule : "SAILLY SAILLISEL", cp : "80360" },
{ code : "80696", intitule : "SAINS EN AMIENOIS", cp : "80680" },
{ code : "80697", intitule : "ST ACHEUL", cp : "80370" },
{ code : "80698", intitule : "ST AUBIN MONTENOY", cp : "80540" },
{ code : "80699", intitule : "ST AUBIN RIVIERE", cp : "80430" },
{ code : "80700", intitule : "ST BLIMONT", cp : "80960" },
{ code : "80701", intitule : "ST CHRIST BRIOST", cp : "80200" },
{ code : "80702", intitule : "ST FUSCIEN", cp : "80680" },
{ code : "80703", intitule : "ST GERMAIN SUR BRESLE", cp : "80430" },
{ code : "80703", intitule : "ST GERMAIN SUR BRESLE", cp : "80430" },
{ code : "80704", intitule : "ST GRATIEN", cp : "80260" },
{ code : "80705", intitule : "ST LEGER LES AUTHIE", cp : "80560" },
{ code : "80706", intitule : "ST LEGER LES DOMART", cp : "80780" },
{ code : "80707", intitule : "ST LEGER SUR BRESLE", cp : "80140" },
{ code : "80708", intitule : "ST MARD", cp : "80700" },
{ code : "80709", intitule : "ST MAULVIS", cp : "80140" },
{ code : "80710", intitule : "ST MAXENT", cp : "80140" },
{ code : "80711", intitule : "ST OUEN", cp : "80610" },
{ code : "80713", intitule : "ST QUENTIN EN TOURMONT", cp : "80120" },
{ code : "80714", intitule : "ST QUENTIN LA MOTTE CROIX AU BAILLY", cp : "80880" },
{ code : "80716", intitule : "ST RIQUIER", cp : "80135" },
{ code : "80717", intitule : "ST SAUFLIEU", cp : "80160" },
{ code : "80718", intitule : "ST SAUVEUR", cp : "80470" },
{ code : "80719", intitule : "STE SEGREE", cp : "80290" },
{ code : "80721", intitule : "ST VALERY SUR SOMME", cp : "80230" },
{ code : "80722", intitule : "ST VAAST EN CHAUSSEE", cp : "80310" },
{ code : "80723", intitule : "SAISSEVAL", cp : "80540" },
{ code : "80724", intitule : "SALEUX", cp : "80480" },
{ code : "80725", intitule : "SALOUEL", cp : "80480" },
{ code : "80726", intitule : "SANCOURT", cp : "80400" },
{ code : "80728", intitule : "SAULCHOY SOUS POIX", cp : "80290" },
{ code : "80729", intitule : "SAUVILLERS MONGIVAL", cp : "80110" },
{ code : "80730", intitule : "SAVEUSE", cp : "80470" },
{ code : "80732", intitule : "SENARPONT", cp : "80140" },
{ code : "80733", intitule : "SENLIS LE SEC", cp : "80300" },
{ code : "80734", intitule : "SENTELIE", cp : "80160" },
{ code : "80735", intitule : "SEUX", cp : "80540" },
{ code : "80736", intitule : "SOREL EN VIMEU", cp : "80490" },
{ code : "80737", intitule : "SOREL", cp : "80240" },
{ code : "80738", intitule : "SOUES", cp : "80310" },
{ code : "80740", intitule : "SOURDON", cp : "80250" },
{ code : "80741", intitule : "SOYECOURT", cp : "80200" },
{ code : "80742", intitule : "SURCAMPS", cp : "80620" },
{ code : "80743", intitule : "SUZANNE", cp : "80340" },
{ code : "80744", intitule : "TAILLY", cp : "80270" },
{ code : "80746", intitule : "TALMAS", cp : "80260" },
{ code : "80747", intitule : "TEMPLEUX LA FOSSE", cp : "80240" },
{ code : "80748", intitule : "TEMPLEUX LE GUERARD", cp : "80240" },
{ code : "80749", intitule : "TERRAMESNIL", cp : "80600" },
{ code : "80750", intitule : "TERTRY", cp : "80200" },
{ code : "80751", intitule : "THENNES", cp : "80110" },
{ code : "80752", intitule : "THEZY GLIMONT", cp : "80440" },
{ code : "80753", intitule : "THIEPVAL", cp : "80300" },
{ code : "80754", intitule : "THIEULLOY L ABBAYE", cp : "80640" },
{ code : "80755", intitule : "THIEULLOY LA VILLE", cp : "80290" },
{ code : "80756", intitule : "THIEVRES", cp : "62760" },
{ code : "80757", intitule : "THOIX", cp : "80160" },
{ code : "80758", intitule : "THORY", cp : "80250" },
{ code : "80759", intitule : "TILLOLOY", cp : "80700" },
{ code : "80760", intitule : "TILLOY FLORIVILLE", cp : "80220" },
{ code : "80761", intitule : "TILLOY LES CONTY", cp : "80160" },
{ code : "80762", intitule : "TINCOURT BOUCLY", cp : "80240" },
{ code : "80763", intitule : "LE TITRE", cp : "80132" },
{ code : "80764", intitule : "TOEUFLES", cp : "80870" },
{ code : "80765", intitule : "TOURS EN VIMEU", cp : "80210" },
{ code : "80766", intitule : "TOUTENCOURT", cp : "80560" },
{ code : "80767", intitule : "LE TRANSLAY", cp : "80140" },
{ code : "80769", intitule : "TREUX", cp : "80300" },
{ code : "80770", intitule : "TULLY", cp : "80130" },
{ code : "80771", intitule : "UGNY L EQUIPEE", cp : "80400" },
{ code : "80773", intitule : "VADENCOURT", cp : "80560" },
{ code : "80774", intitule : "VAIRE SOUS CORBIE", cp : "80800" },
{ code : "80775", intitule : "VALINES", cp : "80210" },
{ code : "80776", intitule : "VARENNES", cp : "80560" },
{ code : "80777", intitule : "VAUCHELLES LES AUTHIE", cp : "80560" },
{ code : "80778", intitule : "VAUCHELLES LES DOMART", cp : "80620" },
{ code : "80779", intitule : "VAUCHELLES LES QUESNOY", cp : "80132" },
{ code : "80780", intitule : "VAUDRICOURT", cp : "80230" },
{ code : "80781", intitule : "VAUVILLERS", cp : "80131" },
{ code : "80782", intitule : "VAUX EN AMIENOIS", cp : "80260" },
{ code : "80783", intitule : "VAUX MARQUENNEVILLE", cp : "80140" },
{ code : "80784", intitule : "VAUX SUR SOMME", cp : "80800" },
{ code : "80785", intitule : "VECQUEMONT", cp : "80800" },
{ code : "80786", intitule : "VELENNES", cp : "80160" },
{ code : "80787", intitule : "VERCOURT", cp : "80120" },
{ code : "80788", intitule : "VERGIES", cp : "80270" },
{ code : "80789", intitule : "VERMANDOVILLERS", cp : "80320" },
{ code : "80790", intitule : "VERPILLIERES", cp : "80700" },
{ code : "80791", intitule : "VERS SUR SELLES", cp : "80480" },
{ code : "80792", intitule : "LA VICOGNE", cp : "80260" },
{ code : "80793", intitule : "VIGNACOURT", cp : "80650" },
{ code : "80794", intitule : "VILLECOURT", cp : "80190" },
{ code : "80795", intitule : "VILLE LE MARCLET", cp : "80420" },
{ code : "80796", intitule : "VILLEROY", cp : "80140" },
{ code : "80797", intitule : "VILLERS AUX ERABLES", cp : "80110" },
{ code : "80798", intitule : "VILLERS BOCAGE", cp : "80260" },
{ code : "80799", intitule : "VILLERS BRETONNEUX", cp : "80800" },
{ code : "80800", intitule : "VILLERS CAMPSART", cp : "80140" },
{ code : "80801", intitule : "VILLERS CARBONNEL", cp : "80200" },
{ code : "80802", intitule : "VILLERS FAUCON", cp : "80240" },
{ code : "80803", intitule : "VILLERS LES ROYE", cp : "80700" },
{ code : "80804", intitule : "VILLERS SOUS AILLY", cp : "80690" },
{ code : "80805", intitule : "VILLERS TOURNELLE", cp : "80500" },
{ code : "80806", intitule : "VILLERS SUR AUTHIE", cp : "80120" },
{ code : "80807", intitule : "VILLE SUR ANCRE", cp : "80300" },
{ code : "80808", intitule : "VIRONCHAUX", cp : "80150" },
{ code : "80809", intitule : "VISMES", cp : "80140" },
{ code : "80810", intitule : "VITZ SUR AUTHIE", cp : "80150" },
{ code : "80811", intitule : "VOYENNES", cp : "80400" },
{ code : "80812", intitule : "VRAIGNES EN VERMANDOIS", cp : "80240" },
{ code : "80813", intitule : "VRAIGNES LES HORNOY", cp : "80640" },
{ code : "80814", intitule : "VRELY", cp : "80170" },
{ code : "80815", intitule : "VRON", cp : "80120" },
{ code : "80819", intitule : "WARGNIES", cp : "80670" },
{ code : "80820", intitule : "WARLOY BAILLON", cp : "80300" },
{ code : "80821", intitule : "WARLUS", cp : "80270" },
{ code : "80822", intitule : "WARSY", cp : "80500" },
{ code : "80823", intitule : "WARVILLERS", cp : "80170" },
{ code : "80824", intitule : "WIENCOURT L EQUIPEE", cp : "80170" },
{ code : "80825", intitule : "WIRY AU MONT", cp : "80270" },
{ code : "80826", intitule : "WOIGNARUE", cp : "80460" },
{ code : "80827", intitule : "WOINCOURT", cp : "80520" },
{ code : "80828", intitule : "WOIREL", cp : "80140" },
{ code : "80829", intitule : "Y", cp : "80190" },
{ code : "80830", intitule : "YAUCOURT BUSSUS", cp : "80135" },
{ code : "80832", intitule : "YVRENCH", cp : "80150" },
{ code : "80833", intitule : "YVRENCHEUX", cp : "80150" },
{ code : "80834", intitule : "YZENGREMER", cp : "80520" },
{ code : "80835", intitule : "YZEUX", cp : "80310" },
{ code : "80836", intitule : "YONVAL", cp : "80132" },
{ code : "81001", intitule : "AGUTS", cp : "81470" },
{ code : "81002", intitule : "AIGUEFONDE", cp : "81200" },
{ code : "81003", intitule : "ALBAN", cp : "81250" },
{ code : "81004", intitule : "ALBI", cp : "81000" },
{ code : "81005", intitule : "ALBINE", cp : "81240" },
{ code : "81006", intitule : "ALGANS", cp : "81470" },
{ code : "81007", intitule : "ALOS", cp : "81140" },
{ code : "81008", intitule : "ALMAYRAC", cp : "81190" },
{ code : "81009", intitule : "AMARENS", cp : "81170" },
{ code : "81010", intitule : "AMBIALET", cp : "81430" },
{ code : "81011", intitule : "AMBRES", cp : "81500" },
{ code : "81012", intitule : "ANDILLAC", cp : "81140" },
{ code : "81013", intitule : "ANDOUQUE", cp : "81350" },
{ code : "81014", intitule : "ANGLES", cp : "81260" },
{ code : "81015", intitule : "APPELLE", cp : "81700" },
{ code : "81016", intitule : "ARFONS", cp : "81110" },
{ code : "81017", intitule : "ARIFAT", cp : "81360" },
{ code : "81018", intitule : "ARTHES", cp : "81160" },
{ code : "81019", intitule : "ASSAC", cp : "81340" },
{ code : "81020", intitule : "AUSSAC", cp : "81600" },
{ code : "81021", intitule : "AUSSILLON", cp : "81200" },
{ code : "81022", intitule : "BANNIERES", cp : "81500" },
{ code : "81023", intitule : "BARRE", cp : "81320" },
{ code : "81024", intitule : "BEAUVAIS SUR TESCOU", cp : "81630" },
{ code : "81025", intitule : "BELCASTEL", cp : "81500" },
{ code : "81026", intitule : "BELLEGARDE MARSAL", cp : "81430" },
{ code : "81026", intitule : "BELLEGARDE MARSAL", cp : "81430" },
{ code : "81027", intitule : "BELLESERRE", cp : "81540" },
{ code : "81028", intitule : "BERLATS", cp : "81260" },
{ code : "81029", intitule : "BERNAC", cp : "81150" },
{ code : "81030", intitule : "BERTRE", cp : "81700" },
{ code : "81031", intitule : "LE BEZ", cp : "81260" },
{ code : "81032", intitule : "BLAN", cp : "81700" },
{ code : "81033", intitule : "BLAYE LES MINES", cp : "81400" },
{ code : "81034", intitule : "BOISSEZON", cp : "81490" },
{ code : "81035", intitule : "BOURNAZEL", cp : "81170" },
{ code : "81036", intitule : "BOUT DU PONT DE LARN", cp : "81660" },
{ code : "81037", intitule : "BRASSAC", cp : "81260" },
{ code : "81038", intitule : "BRENS", cp : "81600" },
{ code : "81039", intitule : "BRIATEXTE", cp : "81390" },
{ code : "81040", intitule : "BROUSSE", cp : "81440" },
{ code : "81041", intitule : "BROZE", cp : "81600" },
{ code : "81042", intitule : "BURLATS", cp : "81100" },
{ code : "81043", intitule : "BUSQUE", cp : "81300" },
{ code : "81044", intitule : "CABANES", cp : "81500" },
{ code : "81045", intitule : "LES CABANNES", cp : "81170" },
{ code : "81046", intitule : "CADALEN", cp : "81600" },
{ code : "81047", intitule : "CADIX", cp : "81340" },
{ code : "81048", intitule : "CAGNAC LES MINES", cp : "81130" },
{ code : "81049", intitule : "CAHUZAC", cp : "81540" },
{ code : "81050", intitule : "CAMBON LES LAVAUR", cp : "81470" },
{ code : "81051", intitule : "CAHUZAC SUR VERE", cp : "81140" },
{ code : "81052", intitule : "CAMBON", cp : "81990" },
{ code : "81053", intitule : "CAMBOUNES", cp : "81260" },
{ code : "81054", intitule : "CAMBOUNET SUR LE SOR", cp : "81580" },
{ code : "81055", intitule : "LES CAMMAZES", cp : "81540" },
{ code : "81056", intitule : "CAMPAGNAC", cp : "81140" },
{ code : "81058", intitule : "CARBES", cp : "81570" },
{ code : "81059", intitule : "CARLUS", cp : "81990" },
{ code : "81060", intitule : "CARMAUX", cp : "81400" },
{ code : "81061", intitule : "CASTANET", cp : "81150" },
{ code : "81062", intitule : "FONTRIEU", cp : "81260" },
{ code : "81062", intitule : "FONTRIEU", cp : "81260" },
{ code : "81062", intitule : "FONTRIEU", cp : "81260" },
{ code : "81063", intitule : "CASTELNAU DE LEVIS", cp : "81150" },
{ code : "81064", intitule : "CASTELNAU DE MONTMIRAL", cp : "81140" },
{ code : "81065", intitule : "CASTRES", cp : "81100" },
{ code : "81066", intitule : "CAUCALIERES", cp : "81200" },
{ code : "81067", intitule : "CESTAYROLS", cp : "81150" },
{ code : "81068", intitule : "COMBEFA", cp : "81640" },
{ code : "81069", intitule : "CORDES SUR CIEL", cp : "81170" },
{ code : "81070", intitule : "COUFOULEUX", cp : "81800" },
{ code : "81071", intitule : "COURRIS", cp : "81340" },
{ code : "81072", intitule : "CRESPIN", cp : "81350" },
{ code : "81073", intitule : "CRESPINET", cp : "81350" },
{ code : "81074", intitule : "CUNAC", cp : "81990" },
{ code : "81075", intitule : "CUQ", cp : "81570" },
{ code : "81076", intitule : "CUQ TOULZA", cp : "81470" },
{ code : "81077", intitule : "CURVALLE", cp : "81250" },
{ code : "81078", intitule : "DAMIATTE", cp : "81220" },
{ code : "81079", intitule : "DENAT", cp : "81120" },
{ code : "81080", intitule : "DONNAZAC", cp : "81170" },
{ code : "81081", intitule : "DOURGNE", cp : "81110" },
{ code : "81082", intitule : "LE DOURN", cp : "81340" },
{ code : "81083", intitule : "DURFORT", cp : "81540" },
{ code : "81084", intitule : "ESCOUSSENS", cp : "81290" },
{ code : "81085", intitule : "ESCROUX", cp : "81530" },
{ code : "81086", intitule : "ESPERAUSSES", cp : "81260" },
{ code : "81087", intitule : "FAYSSAC", cp : "81150" },
{ code : "81088", intitule : "FAUCH", cp : "81120" },
{ code : "81089", intitule : "FAUSSERGUES", cp : "81340" },
{ code : "81090", intitule : "FENOLS", cp : "81600" },
{ code : "81092", intitule : "FIAC", cp : "81500" },
{ code : "81093", intitule : "FLORENTIN", cp : "81150" },
{ code : "81094", intitule : "FRAISSINES", cp : "81340" },
{ code : "81095", intitule : "FRAUSSEILLES", cp : "81170" },
{ code : "81096", intitule : "LE FRAYSSE", cp : "81430" },
{ code : "81097", intitule : "FREJAIROLLES", cp : "81990" },
{ code : "81098", intitule : "FREJEVILLE", cp : "81570" },
{ code : "81099", intitule : "GAILLAC", cp : "81600" },
{ code : "81100", intitule : "GARREVAQUES", cp : "81700" },
{ code : "81101", intitule : "LE GARRIC", cp : "81450" },
{ code : "81102", intitule : "GARRIGUES", cp : "81500" },
{ code : "81103", intitule : "GIJOUNET", cp : "81530" },
{ code : "81104", intitule : "GIROUSSENS", cp : "81500" },
{ code : "81105", intitule : "GRAULHET", cp : "81300" },
{ code : "81106", intitule : "GRAZAC", cp : "81800" },
{ code : "81108", intitule : "ITZAC", cp : "81170" },
{ code : "81109", intitule : "JONQUIERES", cp : "81440" },
{ code : "81110", intitule : "JOUQUEVIEL", cp : "81190" },
{ code : "81111", intitule : "LABARTHE BLEYS", cp : "81170" },
{ code : "81112", intitule : "LABASTIDE DE LEVIS", cp : "81150" },
{ code : "81113", intitule : "LABASTIDE DENAT", cp : "81120" },
{ code : "81114", intitule : "LABASTIDE GABAUSSE", cp : "81400" },
{ code : "81115", intitule : "LABASTIDE ROUAIROUX", cp : "81270" },
{ code : "81116", intitule : "LABASTIDE ST GEORGES", cp : "81500" },
{ code : "81117", intitule : "LABESSIERE CANDEIL", cp : "81300" },
{ code : "81118", intitule : "LABOULBENE", cp : "81100" },
{ code : "81119", intitule : "LABOUTARIE", cp : "81120" },
{ code : "81120", intitule : "LABRUGUIERE", cp : "81290" },
{ code : "81121", intitule : "LACABAREDE", cp : "81240" },
{ code : "81122", intitule : "LACAPELLE PINET", cp : "81340" },
{ code : "81123", intitule : "LACAPELLE SEGALAR", cp : "81170" },
{ code : "81124", intitule : "LACAUNE", cp : "81230" },
{ code : "81125", intitule : "LACAZE", cp : "81330" },
{ code : "81126", intitule : "LACOUGOTTE CADOUL", cp : "81500" },
{ code : "81127", intitule : "LACROISILLE", cp : "81470" },
{ code : "81128", intitule : "LACROUZETTE", cp : "81210" },
{ code : "81129", intitule : "LAGARDIOLLE", cp : "81110" },
{ code : "81130", intitule : "LAGARRIGUE", cp : "81090" },
{ code : "81131", intitule : "LAGRAVE", cp : "81150" },
{ code : "81132", intitule : "GUITALENS L ALBAREDE", cp : "81220" },
{ code : "81132", intitule : "GUITALENS L ALBAREDE", cp : "81220" },
{ code : "81133", intitule : "LAMILLARIE", cp : "81120" },
{ code : "81134", intitule : "LAMONTELARIE", cp : "81260" },
{ code : "81135", intitule : "LAPARROUQUIAL", cp : "81640" },
{ code : "81136", intitule : "LARROQUE", cp : "81140" },
{ code : "81137", intitule : "LASFAILLADES", cp : "81260" },
{ code : "81138", intitule : "LASGRAISSES", cp : "81300" },
{ code : "81139", intitule : "LAUTREC", cp : "81440" },
{ code : "81140", intitule : "LAVAUR", cp : "81500" },
{ code : "81141", intitule : "LEDAS ET PENTHIES", cp : "81340" },
{ code : "81142", intitule : "LEMPAUT", cp : "81700" },
{ code : "81143", intitule : "LESCOUT", cp : "81110" },
{ code : "81144", intitule : "LESCURE D ALBIGEOIS", cp : "81380" },
{ code : "81145", intitule : "LISLE SUR TARN", cp : "81310" },
{ code : "81146", intitule : "LIVERS CAZELLES", cp : "81170" },
{ code : "81147", intitule : "LOMBERS", cp : "81120" },
{ code : "81148", intitule : "LOUBERS", cp : "81170" },
{ code : "81149", intitule : "LOUPIAC", cp : "81800" },
{ code : "81150", intitule : "LUGAN", cp : "81500" },
{ code : "81151", intitule : "MAGRIN", cp : "81220" },
{ code : "81152", intitule : "MAILHOC", cp : "81130" },
{ code : "81154", intitule : "MARNAVES", cp : "81170" },
{ code : "81156", intitule : "MARSSAC SUR TARN", cp : "81150" },
{ code : "81157", intitule : "MARZENS", cp : "81500" },
{ code : "81158", intitule : "LE MASNAU MASSUGUIES", cp : "81530" },
{ code : "81159", intitule : "MASSAC SERAN", cp : "81500" },
{ code : "81160", intitule : "MASSAGUEL", cp : "81110" },
{ code : "81161", intitule : "MASSALS", cp : "81250" },
{ code : "81162", intitule : "MAURENS SCOPONT", cp : "81470" },
{ code : "81163", intitule : "MAZAMET", cp : "81200" },
{ code : "81164", intitule : "MEZENS", cp : "81800" },
{ code : "81165", intitule : "MILHARS", cp : "81170" },
{ code : "81166", intitule : "MILHAVET", cp : "81130" },
{ code : "81167", intitule : "MIOLLES", cp : "81250" },
{ code : "81168", intitule : "MIRANDOL BOURGNOUNAC", cp : "81190" },
{ code : "81169", intitule : "MISSECLE", cp : "81300" },
{ code : "81170", intitule : "MONESTIES", cp : "81640" },
{ code : "81171", intitule : "MONTANS", cp : "81600" },
{ code : "81172", intitule : "MONTAURIOL", cp : "81190" },
{ code : "81173", intitule : "MONTCABRIER", cp : "81500" },
{ code : "81174", intitule : "MONTDRAGON", cp : "81440" },
{ code : "81175", intitule : "MONTDURAUSSE", cp : "81630" },
{ code : "81176", intitule : "MONTELS", cp : "81140" },
{ code : "81177", intitule : "MONTFA", cp : "81210" },
{ code : "81178", intitule : "MONTGAILLARD", cp : "81630" },
{ code : "81179", intitule : "MONTGEY", cp : "81470" },
{ code : "81180", intitule : "MONTIRAT", cp : "81190" },
{ code : "81181", intitule : "MONTPINIER", cp : "81440" },
{ code : "81182", intitule : "MONTREDON LABESSONNIE", cp : "81360" },
{ code : "81183", intitule : "MONT ROC", cp : "81120" },
{ code : "81184", intitule : "MONTROSIER", cp : "81170" },
{ code : "81185", intitule : "MONTVALEN", cp : "81630" },
{ code : "81186", intitule : "MOULARES", cp : "81190" },
{ code : "81187", intitule : "MOULAYRES", cp : "81300" },
{ code : "81188", intitule : "MOULIN MAGE", cp : "81320" },
{ code : "81189", intitule : "MOUZENS", cp : "81470" },
{ code : "81190", intitule : "MOUZIEYS TEULET", cp : "81430" },
{ code : "81191", intitule : "MOUZIEYS PANENS", cp : "81170" },
{ code : "81192", intitule : "MURAT SUR VEBRE", cp : "81320" },
{ code : "81193", intitule : "NAGES", cp : "81320" },
{ code : "81195", intitule : "NAVES", cp : "81710" },
{ code : "81196", intitule : "NOAILHAC", cp : "81490" },
{ code : "81197", intitule : "NOAILLES", cp : "81170" },
{ code : "81198", intitule : "ORBAN", cp : "81120" },
{ code : "81199", intitule : "PADIES", cp : "81340" },
{ code : "81200", intitule : "PALLEVILLE", cp : "81700" },
{ code : "81201", intitule : "PAMPELONNE", cp : "81190" },
{ code : "81202", intitule : "PARISOT", cp : "81310" },
{ code : "81203", intitule : "PAULINET", cp : "81250" },
{ code : "81204", intitule : "PAYRIN AUGMONTEL", cp : "81660" },
{ code : "81205", intitule : "PECHAUDIER", cp : "81470" },
{ code : "81206", intitule : "PENNE", cp : "81140" },
{ code : "81207", intitule : "PEYREGOUX", cp : "81440" },
{ code : "81208", intitule : "PEYROLE", cp : "81310" },
{ code : "81209", intitule : "PONT DE LARN", cp : "81660" },
{ code : "81210", intitule : "POUDIS", cp : "81700" },
{ code : "81211", intitule : "POULAN POUZOLS", cp : "81120" },
{ code : "81212", intitule : "PRADES", cp : "81220" },
{ code : "81213", intitule : "PRATVIEL", cp : "81500" },
{ code : "81214", intitule : "PUECHOURSI", cp : "81470" },
{ code : "81215", intitule : "PUYBEGON", cp : "81390" },
{ code : "81216", intitule : "PUYCALVEL", cp : "81440" },
{ code : "81217", intitule : "PUYCELSI", cp : "81140" },
{ code : "81218", intitule : "PUYGOUZON", cp : "81990" },
{ code : "81219", intitule : "PUYLAURENS", cp : "81700" },
{ code : "81220", intitule : "RABASTENS", cp : "81800" },
{ code : "81221", intitule : "RAYSSAC", cp : "81330" },
{ code : "81222", intitule : "REALMONT", cp : "81120" },
{ code : "81223", intitule : "LE RIALET", cp : "81240" },
{ code : "81224", intitule : "LE RIOLS", cp : "81170" },
{ code : "81225", intitule : "RIVIERES", cp : "81600" },
{ code : "81226", intitule : "RONEL", cp : "81120" },
{ code : "81227", intitule : "ROQUECOURBE", cp : "81210" },
{ code : "81228", intitule : "ROQUEMAURE", cp : "81800" },
{ code : "81229", intitule : "ROQUEVIDAL", cp : "81470" },
{ code : "81230", intitule : "ROSIERES", cp : "81400" },
{ code : "81231", intitule : "ROUAIROUX", cp : "81240" },
{ code : "81232", intitule : "ROUFFIAC", cp : "81150" },
{ code : "81233", intitule : "ROUMEGOUX", cp : "81120" },
{ code : "81234", intitule : "ROUSSAYROLLES", cp : "81140" },
{ code : "81235", intitule : "ST AFFRIQUE LES MONTAGNES", cp : "81290" },
{ code : "81236", intitule : "ST AGNAN", cp : "81500" },
{ code : "81237", intitule : "ST AMANCET", cp : "81110" },
{ code : "81238", intitule : "ST AMANS SOULT", cp : "81240" },
{ code : "81239", intitule : "ST AMANS VALTORET", cp : "81240" },
{ code : "81240", intitule : "ST ANDRE", cp : "81250" },
{ code : "81241", intitule : "ST ANTONIN DE LACALM", cp : "81120" },
{ code : "81242", intitule : "ST AVIT", cp : "81110" },
{ code : "81243", intitule : "ST BEAUZILE", cp : "81140" },
{ code : "81244", intitule : "ST BENOIT DE CARMAUX", cp : "81400" },
{ code : "81245", intitule : "ST CHRISTOPHE", cp : "81190" },
{ code : "81245", intitule : "ST CHRISTOPHE", cp : "81190" },
{ code : "81246", intitule : "STE CECILE DU CAYROU", cp : "81140" },
{ code : "81247", intitule : "ST CIRGUE", cp : "81340" },
{ code : "81248", intitule : "ST GAUZENS", cp : "81390" },
{ code : "81249", intitule : "STE GEMME", cp : "81190" },
{ code : "81250", intitule : "ST GENEST DE CONTEST", cp : "81440" },
{ code : "81251", intitule : "ST GERMAIN DES PRES", cp : "81700" },
{ code : "81252", intitule : "ST GERMIER", cp : "81210" },
{ code : "81253", intitule : "ST GREGOIRE", cp : "81350" },
{ code : "81254", intitule : "ST JEAN DE MARCEL", cp : "81350" },
{ code : "81255", intitule : "ST JEAN DE RIVES", cp : "81500" },
{ code : "81256", intitule : "ST JEAN DE VALS", cp : "81210" },
{ code : "81257", intitule : "ST JUERY", cp : "81160" },
{ code : "81258", intitule : "ST JULIEN DU PUY", cp : "81440" },
{ code : "81259", intitule : "ST JULIEN GAULENE", cp : "81340" },
{ code : "81260", intitule : "ST LIEUX LAFENASSE", cp : "81120" },
{ code : "81261", intitule : "ST LIEUX LES LAVAUR", cp : "81500" },
{ code : "81262", intitule : "ST MARCEL CAMPES", cp : "81170" },
{ code : "81262", intitule : "ST MARCEL CAMPES", cp : "81170" },
{ code : "81263", intitule : "ST MARTIN LAGUEPIE", cp : "81170" },
{ code : "81264", intitule : "ST MICHEL LABADIE", cp : "81340" },
{ code : "81265", intitule : "ST MICHEL DE VAX", cp : "81140" },
{ code : "81266", intitule : "ST PAUL CAP DE JOUX", cp : "81220" },
{ code : "81267", intitule : "ST PIERRE DE TRIVISY", cp : "81330" },
{ code : "81268", intitule : "ST SALVI DE CARCAVES", cp : "81530" },
{ code : "81269", intitule : "ST SALVY DE LA BALME", cp : "81490" },
{ code : "81270", intitule : "ST SERNIN LES LAVAUR", cp : "81700" },
{ code : "81271", intitule : "ST SULPICE LA POINTE", cp : "81370" },
{ code : "81272", intitule : "ST URCISSE", cp : "81630" },
{ code : "81273", intitule : "SAIX", cp : "81710" },
{ code : "81274", intitule : "SALIES", cp : "81990" },
{ code : "81275", intitule : "SALLES", cp : "81640" },
{ code : "81276", intitule : "SALVAGNAC", cp : "81630" },
{ code : "81277", intitule : "SAUSSENAC", cp : "81350" },
{ code : "81278", intitule : "SAUVETERRE", cp : "81240" },
{ code : "81279", intitule : "LA SAUZIERE ST JEAN", cp : "81630" },
{ code : "81280", intitule : "LE SEGUR", cp : "81640" },
{ code : "81281", intitule : "SEMALENS", cp : "81570" },
{ code : "81282", intitule : "SENAUX", cp : "81530" },
{ code : "81283", intitule : "SENOUILLAC", cp : "81600" },
{ code : "81284", intitule : "LE SEQUESTRE", cp : "81990" },
{ code : "81285", intitule : "SERENAC", cp : "81350" },
{ code : "81286", intitule : "SERVIES", cp : "81220" },
{ code : "81287", intitule : "SIEURAC", cp : "81120" },
{ code : "81288", intitule : "SOREZE", cp : "81540" },
{ code : "81289", intitule : "SOUAL", cp : "81580" },
{ code : "81290", intitule : "SOUEL", cp : "81170" },
{ code : "81291", intitule : "TAIX", cp : "81130" },
{ code : "81292", intitule : "TANUS", cp : "81190" },
{ code : "81293", intitule : "TAURIAC", cp : "81630" },
{ code : "81294", intitule : "TECOU", cp : "81600" },
{ code : "81295", intitule : "TEILLET", cp : "81120" },
{ code : "81296", intitule : "TERRE CLAPIER", cp : "81120" },
{ code : "81297", intitule : "TERSSAC", cp : "81150" },
{ code : "81298", intitule : "TEULAT", cp : "81500" },
{ code : "81299", intitule : "TEYSSODE", cp : "81220" },
{ code : "81300", intitule : "TONNAC", cp : "81170" },
{ code : "81301", intitule : "LE TRAVET", cp : "81120" },
{ code : "81302", intitule : "TREBAN", cp : "81190" },
{ code : "81303", intitule : "TREBAS", cp : "81340" },
{ code : "81304", intitule : "TREVIEN", cp : "81190" },
{ code : "81305", intitule : "VABRE", cp : "81330" },
{ code : "81306", intitule : "VALDERIES", cp : "81350" },
{ code : "81307", intitule : "VALDURENQUE", cp : "81090" },
{ code : "81308", intitule : "VALENCE D ALBIGEOIS", cp : "81340" },
{ code : "81309", intitule : "VAOUR", cp : "81140" },
{ code : "81310", intitule : "VEILHES", cp : "81500" },
{ code : "81311", intitule : "VENES", cp : "81440" },
{ code : "81312", intitule : "VERDALLE", cp : "81110" },
{ code : "81313", intitule : "LE VERDIER", cp : "81140" },
{ code : "81314", intitule : "VIANE", cp : "81530" },
{ code : "81315", intitule : "VIELMUR SUR AGOUT", cp : "81570" },
{ code : "81316", intitule : "VIEUX", cp : "81140" },
{ code : "81317", intitule : "VILLEFRANCHE D ALBIGEOIS", cp : "81430" },
{ code : "81318", intitule : "VILLENEUVE LES LAVAUR", cp : "81500" },
{ code : "81319", intitule : "VILLENEUVE SUR VERE", cp : "81130" },
{ code : "81320", intitule : "VINDRAC ALAYRAC", cp : "81170" },
{ code : "81321", intitule : "LE VINTROU", cp : "81240" },
{ code : "81322", intitule : "VIRAC", cp : "81640" },
{ code : "81323", intitule : "VITERBE", cp : "81220" },
{ code : "81324", intitule : "VIVIERS LES LAVAUR", cp : "81500" },
{ code : "81325", intitule : "VIVIERS LES MONTAGNES", cp : "81290" },
{ code : "81326", intitule : "STE CROIX", cp : "81150" },
{ code : "82001", intitule : "ALBEFEUILLE LAGARDE", cp : "82290" },
{ code : "82002", intitule : "ALBIAS", cp : "82350" },
{ code : "82003", intitule : "ANGEVILLE", cp : "82210" },
{ code : "82004", intitule : "ASQUES", cp : "82120" },
{ code : "82005", intitule : "AUCAMVILLE", cp : "82600" },
{ code : "82006", intitule : "AUTERIVE", cp : "82500" },
{ code : "82007", intitule : "AUTY", cp : "82220" },
{ code : "82008", intitule : "AUVILLAR", cp : "82340" },
{ code : "82009", intitule : "BALIGNAC", cp : "82120" },
{ code : "82010", intitule : "BARDIGUES", cp : "82340" },
{ code : "82011", intitule : "BARRY D ISLEMADE", cp : "82290" },
{ code : "82012", intitule : "LES BARTHES", cp : "82100" },
{ code : "82013", intitule : "BEAUMONT DE LOMAGNE", cp : "82500" },
{ code : "82014", intitule : "BEAUPUY", cp : "82600" },
{ code : "82015", intitule : "BELBEZE EN LOMAGNE", cp : "82500" },
{ code : "82016", intitule : "BELVEZE", cp : "82150" },
{ code : "82017", intitule : "BESSENS", cp : "82170" },
{ code : "82018", intitule : "BIOULE", cp : "82800" },
{ code : "82019", intitule : "BOUDOU", cp : "82200" },
{ code : "82020", intitule : "BOUILLAC", cp : "82600" },
{ code : "82021", intitule : "BOULOC", cp : "82110" },
{ code : "82022", intitule : "BOURG DE VISA", cp : "82190" },
{ code : "82023", intitule : "BOURRET", cp : "82700" },
{ code : "82024", intitule : "BRASSAC", cp : "82190" },
{ code : "82025", intitule : "BRESSOLS", cp : "82710" },
{ code : "82026", intitule : "BRUNIQUEL", cp : "82800" },
{ code : "82027", intitule : "CAMPSAS", cp : "82370" },
{ code : "82028", intitule : "CANALS", cp : "82170" },
{ code : "82029", intitule : "CASTANET", cp : "82160" },
{ code : "82030", intitule : "CASTELFERRUS", cp : "82100" },
{ code : "82031", intitule : "CASTELMAYRAN", cp : "82210" },
{ code : "82032", intitule : "CASTELSAGRAT", cp : "82400" },
{ code : "82033", intitule : "CASTELSARRASIN", cp : "82100" },
{ code : "82034", intitule : "CASTERA BOUZET", cp : "82120" },
{ code : "82035", intitule : "CAUMONT", cp : "82210" },
{ code : "82036", intitule : "LE CAUSE", cp : "82500" },
{ code : "82037", intitule : "CAUSSADE", cp : "82300" },
{ code : "82038", intitule : "CAYLUS", cp : "82160" },
{ code : "82039", intitule : "CAYRAC", cp : "82440" },
{ code : "82040", intitule : "CAYRIECH", cp : "82240" },
{ code : "82041", intitule : "CAZALS", cp : "82140" },
{ code : "82042", intitule : "CAZES MONDENARD", cp : "82110" },
{ code : "82043", intitule : "COMBEROUGER", cp : "82600" },
{ code : "82044", intitule : "CORBARIEU", cp : "82370" },
{ code : "82045", intitule : "CORDES TOLOSANNES", cp : "82700" },
{ code : "82046", intitule : "COUTURES", cp : "82210" },
{ code : "82047", intitule : "CUMONT", cp : "82500" },
{ code : "82048", intitule : "DIEUPENTALE", cp : "82170" },
{ code : "82049", intitule : "DONZAC", cp : "82340" },
{ code : "82050", intitule : "DUNES", cp : "82340" },
{ code : "82051", intitule : "DURFORT LACAPELETTE", cp : "82390" },
{ code : "82052", intitule : "ESCATALENS", cp : "82700" },
{ code : "82053", intitule : "ESCAZEAUX", cp : "82500" },
{ code : "82054", intitule : "ESPALAIS", cp : "82400" },
{ code : "82055", intitule : "ESPARSAC", cp : "82500" },
{ code : "82056", intitule : "ESPINAS", cp : "82160" },
{ code : "82057", intitule : "FABAS", cp : "82170" },
{ code : "82058", intitule : "FAJOLLES", cp : "82210" },
{ code : "82059", intitule : "FAUDOAS", cp : "82500" },
{ code : "82060", intitule : "FAUROUX", cp : "82190" },
{ code : "82061", intitule : "FENEYROLS", cp : "82140" },
{ code : "82062", intitule : "FINHAN", cp : "82700" },
{ code : "82063", intitule : "GARGANVILLAR", cp : "82100" },
{ code : "82064", intitule : "GARIES", cp : "82500" },
{ code : "82065", intitule : "GASQUES", cp : "82400" },
{ code : "82066", intitule : "GENEBRIERES", cp : "82230" },
{ code : "82067", intitule : "GENSAC", cp : "82120" },
{ code : "82068", intitule : "GIMAT", cp : "82500" },
{ code : "82069", intitule : "GINALS", cp : "82330" },
{ code : "82070", intitule : "GLATENS", cp : "82500" },
{ code : "82071", intitule : "GOAS", cp : "82500" },
{ code : "82072", intitule : "GOLFECH", cp : "82400" },
{ code : "82073", intitule : "GOUDOURVILLE", cp : "82400" },
{ code : "82074", intitule : "GRAMONT", cp : "82120" },
{ code : "82075", intitule : "GRISOLLES", cp : "82170" },
{ code : "82076", intitule : "L HONOR DE COS", cp : "82130" },
{ code : "82077", intitule : "LABARTHE", cp : "82220" },
{ code : "82078", intitule : "LABASTIDE DE PENNE", cp : "82240" },
{ code : "82079", intitule : "LABASTIDE ST PIERRE", cp : "82370" },
{ code : "82080", intitule : "LABASTIDE DU TEMPLE", cp : "82100" },
{ code : "82081", intitule : "LABOURGADE", cp : "82100" },
{ code : "82082", intitule : "LACAPELLE LIVRON", cp : "82160" },
{ code : "82083", intitule : "LACHAPELLE", cp : "82120" },
{ code : "82084", intitule : "LACOUR", cp : "82190" },
{ code : "82085", intitule : "LACOURT ST PIERRE", cp : "82290" },
{ code : "82086", intitule : "LAFITTE", cp : "82100" },
{ code : "82087", intitule : "LAFRANCAISE", cp : "82130" },
{ code : "82088", intitule : "LAGUEPIE", cp : "82250" },
{ code : "82089", intitule : "LAMAGISTERE", cp : "82360" },
{ code : "82090", intitule : "LAMOTHE CAPDEVILLE", cp : "82130" },
{ code : "82091", intitule : "LAMOTHE CUMONT", cp : "82500" },
{ code : "82092", intitule : "LAPENCHE", cp : "82240" },
{ code : "82093", intitule : "LARRAZET", cp : "82500" },
{ code : "82094", intitule : "LAUZERTE", cp : "82110" },
{ code : "82095", intitule : "LAVAURETTE", cp : "82240" },
{ code : "82096", intitule : "LA VILLE DIEU DU TEMPLE", cp : "82290" },
{ code : "82097", intitule : "LAVIT", cp : "82120" },
{ code : "82098", intitule : "LEOJAC", cp : "82230" },
{ code : "82099", intitule : "LIZAC", cp : "82200" },
{ code : "82100", intitule : "LOZE", cp : "82160" },
{ code : "82101", intitule : "MALAUSE", cp : "82200" },
{ code : "82102", intitule : "MANSONVILLE", cp : "82120" },
{ code : "82103", intitule : "MARIGNAC", cp : "82500" },
{ code : "82104", intitule : "MARSAC", cp : "82120" },
{ code : "82105", intitule : "MAS GRENIER", cp : "82600" },
{ code : "82106", intitule : "MAUBEC", cp : "82500" },
{ code : "82107", intitule : "MAUMUSSON", cp : "82120" },
{ code : "82108", intitule : "MEAUZAC", cp : "82290" },
{ code : "82109", intitule : "MERLES", cp : "82210" },
{ code : "82110", intitule : "MIRABEL", cp : "82440" },
{ code : "82111", intitule : "MIRAMONT DE QUERCY", cp : "82190" },
{ code : "82112", intitule : "MOISSAC", cp : "82200" },
{ code : "82113", intitule : "MOLIERES", cp : "82220" },
{ code : "82114", intitule : "MONBEQUI", cp : "82170" },
{ code : "82115", intitule : "MONCLAR DE QUERCY", cp : "82230" },
{ code : "82116", intitule : "MONTAGUDET", cp : "82110" },
{ code : "82117", intitule : "MONTAIGU DE QUERCY", cp : "82150" },
{ code : "82118", intitule : "MONTAIN", cp : "82100" },
{ code : "82119", intitule : "MONTALZAT", cp : "82270" },
{ code : "82120", intitule : "MONTASTRUC", cp : "82130" },
{ code : "82121", intitule : "MONTAUBAN", cp : "82000" },
{ code : "82122", intitule : "MONTBARLA", cp : "82110" },
{ code : "82123", intitule : "MONTBARTIER", cp : "82700" },
{ code : "82124", intitule : "MONTBETON", cp : "82290" },
{ code : "82125", intitule : "MONTECH", cp : "82700" },
{ code : "82126", intitule : "MONTEILS", cp : "82300" },
{ code : "82127", intitule : "MONTESQUIEU", cp : "82200" },
{ code : "82128", intitule : "MONTFERMIER", cp : "82270" },
{ code : "82129", intitule : "MONTGAILLARD", cp : "82120" },
{ code : "82130", intitule : "MONTJOI", cp : "82400" },
{ code : "82131", intitule : "MONTPEZAT DE QUERCY", cp : "82270" },
{ code : "82132", intitule : "MONTRICOUX", cp : "82800" },
{ code : "82133", intitule : "MOUILLAC", cp : "82160" },
{ code : "82134", intitule : "NEGREPELISSE", cp : "82800" },
{ code : "82135", intitule : "NOHIC", cp : "82370" },
{ code : "82136", intitule : "ORGUEIL", cp : "82370" },
{ code : "82137", intitule : "PARISOT", cp : "82160" },
{ code : "82138", intitule : "PERVILLE", cp : "82400" },
{ code : "82139", intitule : "LE PIN", cp : "82340" },
{ code : "82140", intitule : "PIQUECOS", cp : "82130" },
{ code : "82141", intitule : "POMMEVIC", cp : "82400" },
{ code : "82142", intitule : "POMPIGNAN", cp : "82170" },
{ code : "82143", intitule : "POUPAS", cp : "82120" },
{ code : "82144", intitule : "PUYCORNET", cp : "82220" },
{ code : "82145", intitule : "PUYGAILLARD DE QUERCY", cp : "82800" },
{ code : "82146", intitule : "PUYGAILLARD DE LOMAGNE", cp : "82120" },
{ code : "82147", intitule : "PUYLAGARDE", cp : "82160" },
{ code : "82148", intitule : "PUYLAROQUE", cp : "82240" },
{ code : "82149", intitule : "REALVILLE", cp : "82440" },
{ code : "82150", intitule : "REYNIES", cp : "82370" },
{ code : "82151", intitule : "ROQUECOR", cp : "82150" },
{ code : "82152", intitule : "ST AIGNAN", cp : "82100" },
{ code : "82153", intitule : "ST AMANS DU PECH", cp : "82150" },
{ code : "82154", intitule : "ST AMANS DE PELLAGAL", cp : "82110" },
{ code : "82155", intitule : "ST ANTONIN NOBLE VAL", cp : "82140" },
{ code : "82156", intitule : "ST ARROUMEX", cp : "82210" },
{ code : "82157", intitule : "ST BEAUZEIL", cp : "82150" },
{ code : "82158", intitule : "ST CIRICE", cp : "82340" },
{ code : "82159", intitule : "ST CIRQ", cp : "82300" },
{ code : "82160", intitule : "ST CLAIR", cp : "82400" },
{ code : "82161", intitule : "ST ETIENNE DE TULMONT", cp : "82410" },
{ code : "82162", intitule : "ST GEORGES", cp : "82240" },
{ code : "82163", intitule : "ST JEAN DU BOUZET", cp : "82120" },
{ code : "82164", intitule : "STE JULIETTE", cp : "82110" },
{ code : "82165", intitule : "ST LOUP", cp : "82340" },
{ code : "82166", intitule : "ST MICHEL", cp : "82340" },
{ code : "82167", intitule : "ST NAUPHARY", cp : "82370" },
{ code : "82168", intitule : "ST NAZAIRE DE VALENTANE", cp : "82190" },
{ code : "82169", intitule : "ST NICOLAS DE LA GRAVE", cp : "82210" },
{ code : "82170", intitule : "ST PAUL D ESPIS", cp : "82400" },
{ code : "82171", intitule : "ST PORQUIER", cp : "82700" },
{ code : "82172", intitule : "ST PROJET", cp : "82160" },
{ code : "82173", intitule : "ST SARDOS", cp : "82600" },
{ code : "82174", intitule : "ST VINCENT D AUTEJAC", cp : "82300" },
{ code : "82175", intitule : "ST VINCENT LESPINASSE", cp : "82400" },
{ code : "82176", intitule : "LA SALVETAT BELMONTET", cp : "82230" },
{ code : "82177", intitule : "SAUVETERRE", cp : "82110" },
{ code : "82178", intitule : "SAVENES", cp : "82600" },
{ code : "82179", intitule : "SEPTFONDS", cp : "82240" },
{ code : "82180", intitule : "SERIGNAC", cp : "82500" },
{ code : "82181", intitule : "SISTELS", cp : "82340" },
{ code : "82182", intitule : "TOUFFAILLES", cp : "82190" },
{ code : "82183", intitule : "TREJOULS", cp : "82110" },
{ code : "82184", intitule : "VAISSAC", cp : "82800" },
{ code : "82185", intitule : "VALEILLES", cp : "82150" },
{ code : "82186", intitule : "VALENCE", cp : "82400" },
{ code : "82187", intitule : "VAREN", cp : "82330" },
{ code : "82188", intitule : "VARENNES", cp : "82370" },
{ code : "82189", intitule : "VAZERAC", cp : "82220" },
{ code : "82190", intitule : "VERDUN SUR GARONNE", cp : "82600" },
{ code : "82191", intitule : "VERFEIL", cp : "82330" },
{ code : "82192", intitule : "VERLHAC TESCOU", cp : "82230" },
{ code : "82193", intitule : "VIGUERON", cp : "82500" },
{ code : "82194", intitule : "VILLEBRUMIER", cp : "82370" },
{ code : "82195", intitule : "VILLEMADE", cp : "82130" },
{ code : "83001", intitule : "LES ADRETS DE L ESTEREL", cp : "83600" },
{ code : "83002", intitule : "AIGUINES", cp : "83630" },
{ code : "83003", intitule : "AMPUS", cp : "83111" },
{ code : "83004", intitule : "LES ARCS", cp : "83460" },
{ code : "83005", intitule : "ARTIGNOSC SUR VERDON", cp : "83630" },
{ code : "83006", intitule : "ARTIGUES", cp : "83560" },
{ code : "83007", intitule : "AUPS", cp : "83630" },
{ code : "83008", intitule : "BAGNOLS EN FORET", cp : "83600" },
{ code : "83009", intitule : "BANDOL", cp : "83150" },
{ code : "83010", intitule : "BARGEME", cp : "83840" },
{ code : "83011", intitule : "BARGEMON", cp : "83830" },
{ code : "83012", intitule : "BARJOLS", cp : "83670" },
{ code : "83013", intitule : "LA BASTIDE", cp : "83840" },
{ code : "83014", intitule : "BAUDINARD SUR VERDON", cp : "83630" },
{ code : "83015", intitule : "BAUDUEN", cp : "83630" },
{ code : "83016", intitule : "LE BEAUSSET", cp : "83330" },
{ code : "83017", intitule : "BELGENTIER", cp : "83210" },
{ code : "83018", intitule : "BESSE SUR ISSOLE", cp : "83890" },
{ code : "83019", intitule : "BORMES LES MIMOSAS", cp : "83230" },
{ code : "83020", intitule : "LE BOURGUET", cp : "83840" },
{ code : "83021", intitule : "BRAS", cp : "83149" },
{ code : "83022", intitule : "BRENON", cp : "83840" },
{ code : "83023", intitule : "BRIGNOLES", cp : "83170" },
{ code : "83025", intitule : "BRUE AURIAC", cp : "83119" },
{ code : "83026", intitule : "CABASSE", cp : "83340" },
{ code : "83027", intitule : "LA CADIERE D AZUR", cp : "83740" },
{ code : "83027", intitule : "LA CADIERE D AZUR", cp : "83740" },
{ code : "83028", intitule : "CALLAS", cp : "83830" },
{ code : "83029", intitule : "CALLIAN", cp : "83440" },
{ code : "83030", intitule : "CAMPS LA SOURCE", cp : "83170" },
{ code : "83031", intitule : "LE CANNET DES MAURES", cp : "83340" },
{ code : "83032", intitule : "CARCES", cp : "83570" },
{ code : "83033", intitule : "CARNOULES", cp : "83660" },
{ code : "83034", intitule : "CARQUEIRANNE", cp : "83320" },
{ code : "83035", intitule : "LE CASTELLET", cp : "83330" },
{ code : "83035", intitule : "LE CASTELLET", cp : "83330" },
{ code : "83035", intitule : "LE CASTELLET", cp : "83330" },
{ code : "83035", intitule : "LE CASTELLET", cp : "83330" },
{ code : "83035", intitule : "LE CASTELLET", cp : "83330" },
{ code : "83036", intitule : "CAVALAIRE SUR MER", cp : "83240" },
{ code : "83037", intitule : "LA CELLE", cp : "83170" },
{ code : "83038", intitule : "CHATEAUDOUBLE", cp : "83300" },
{ code : "83038", intitule : "CHATEAUDOUBLE", cp : "83300" },
{ code : "83039", intitule : "CHATEAUVERT", cp : "83670" },
{ code : "83040", intitule : "CHATEAUVIEUX", cp : "83840" },
{ code : "83041", intitule : "CLAVIERS", cp : "83830" },
{ code : "83042", intitule : "COGOLIN", cp : "83310" },
{ code : "83043", intitule : "COLLOBRIERES", cp : "83610" },
{ code : "83044", intitule : "COMPS SUR ARTUBY", cp : "83840" },
{ code : "83045", intitule : "CORRENS", cp : "83570" },
{ code : "83046", intitule : "COTIGNAC", cp : "83570" },
{ code : "83047", intitule : "LA CRAU", cp : "83260" },
{ code : "83047", intitule : "LA CRAU", cp : "83260" },
{ code : "83048", intitule : "LA CROIX VALMER", cp : "83420" },
{ code : "83049", intitule : "CUERS", cp : "83390" },
{ code : "83050", intitule : "DRAGUIGNAN", cp : "83300" },
{ code : "83051", intitule : "ENTRECASTEAUX", cp : "83570" },
{ code : "83052", intitule : "ESPARRON", cp : "83560" },
{ code : "83053", intitule : "EVENOS", cp : "83330" },
{ code : "83054", intitule : "LA FARLEDE", cp : "83210" },
{ code : "83055", intitule : "FAYENCE", cp : "83440" },
{ code : "83056", intitule : "FIGANIERES", cp : "83830" },
{ code : "83057", intitule : "FLASSANS SUR ISSOLE", cp : "83340" },
{ code : "83058", intitule : "FLAYOSC", cp : "83780" },
{ code : "83059", intitule : "FORCALQUEIRET", cp : "83136" },
{ code : "83060", intitule : "FOX AMPHOUX", cp : "83670" },
{ code : "83061", intitule : "FREJUS", cp : "83370" },
{ code : "83061", intitule : "FREJUS", cp : "83600" },
{ code : "83062", intitule : "LA GARDE", cp : "83130" },
{ code : "83063", intitule : "LA GARDE FREINET", cp : "83680" },
{ code : "83064", intitule : "GAREOULT", cp : "83136" },
{ code : "83065", intitule : "GASSIN", cp : "83580" },
{ code : "83066", intitule : "GINASSERVIS", cp : "83560" },
{ code : "83067", intitule : "GONFARON", cp : "83590" },
{ code : "83068", intitule : "GRIMAUD", cp : "83310" },
{ code : "83068", intitule : "GRIMAUD", cp : "83310" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83069", intitule : "HYERES", cp : "83400" },
{ code : "83070", intitule : "LE LAVANDOU", cp : "83980" },
{ code : "83070", intitule : "LE LAVANDOU", cp : "83980" },
{ code : "83071", intitule : "LA LONDE LES MAURES", cp : "83250" },
{ code : "83072", intitule : "LORGUES", cp : "83510" },
{ code : "83073", intitule : "LE LUC", cp : "83340" },
{ code : "83074", intitule : "LA MARTRE", cp : "83840" },
{ code : "83075", intitule : "LES MAYONS", cp : "83340" },
{ code : "83076", intitule : "MAZAUGUES", cp : "83136" },
{ code : "83077", intitule : "MEOUNES LES MONTRIEUX", cp : "83136" },
{ code : "83078", intitule : "MOISSAC BELLEVUE", cp : "83630" },
{ code : "83079", intitule : "LA MOLE", cp : "83310" },
{ code : "83080", intitule : "MONS", cp : "83440" },
{ code : "83081", intitule : "MONTAUROUX", cp : "83440" },
{ code : "83082", intitule : "MONTFERRAT", cp : "83131" },
{ code : "83083", intitule : "MONTFORT SUR ARGENS", cp : "83570" },
{ code : "83084", intitule : "MONTMEYAN", cp : "83670" },
{ code : "83085", intitule : "LA MOTTE", cp : "83920" },
{ code : "83086", intitule : "LE MUY", cp : "83490" },
{ code : "83087", intitule : "NANS LES PINS", cp : "83860" },
{ code : "83088", intitule : "NEOULES", cp : "83136" },
{ code : "83089", intitule : "OLLIERES", cp : "83470" },
{ code : "83090", intitule : "OLLIOULES", cp : "83190" },
{ code : "83091", intitule : "PIERREFEU DU VAR", cp : "83390" },
{ code : "83092", intitule : "PIGNANS", cp : "83790" },
{ code : "83093", intitule : "PLAN D AUPS STE BAUME", cp : "83640" },
{ code : "83094", intitule : "LE PLAN DE LA TOUR", cp : "83120" },
{ code : "83095", intitule : "PONTEVES", cp : "83670" },
{ code : "83096", intitule : "POURCIEUX", cp : "83470" },
{ code : "83097", intitule : "POURRIERES", cp : "83910" },
{ code : "83098", intitule : "LE PRADET", cp : "83220" },
{ code : "83099", intitule : "PUGET SUR ARGENS", cp : "83480" },
{ code : "83100", intitule : "PUGET VILLE", cp : "83390" },
{ code : "83101", intitule : "RAMATUELLE", cp : "83350" },
{ code : "83102", intitule : "REGUSSE", cp : "83630" },
{ code : "83103", intitule : "LE REVEST LES EAUX", cp : "83200" },
{ code : "83104", intitule : "RIANS", cp : "83560" },
{ code : "83105", intitule : "RIBOUX", cp : "13780" },
{ code : "83106", intitule : "ROCBARON", cp : "83136" },
{ code : "83107", intitule : "ROQUEBRUNE SUR ARGENS", cp : "83380" },
{ code : "83107", intitule : "ROQUEBRUNE SUR ARGENS", cp : "83520" },
{ code : "83108", intitule : "LA ROQUEBRUSSANNE", cp : "83136" },
{ code : "83109", intitule : "LA ROQUE ESCLAPON", cp : "83840" },
{ code : "83110", intitule : "ROUGIERS", cp : "83170" },
{ code : "83111", intitule : "STE ANASTASIE SUR ISSOLE", cp : "83136" },
{ code : "83112", intitule : "ST CYR SUR MER", cp : "83270" },
{ code : "83112", intitule : "ST CYR SUR MER", cp : "83270" },
{ code : "83112", intitule : "ST CYR SUR MER", cp : "83270" },
{ code : "83112", intitule : "ST CYR SUR MER", cp : "83270" },
{ code : "83113", intitule : "ST JULIEN", cp : "83560" },
{ code : "83114", intitule : "ST MARTIN DE PALLIERES", cp : "83560" },
{ code : "83115", intitule : "STE MAXIME", cp : "83120" },
{ code : "83116", intitule : "ST MAXIMIN LA STE BAUME", cp : "83470" },
{ code : "83117", intitule : "ST PAUL EN FORET", cp : "83440" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83530" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83700" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83530" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83530" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83530" },
{ code : "83118", intitule : "ST RAPHAEL", cp : "83700" },
{ code : "83119", intitule : "ST TROPEZ", cp : "83990" },
{ code : "83120", intitule : "ST ZACHARIE", cp : "83640" },
{ code : "83121", intitule : "SALERNES", cp : "83690" },
{ code : "83122", intitule : "LES SALLES SUR VERDON", cp : "83630" },
{ code : "83123", intitule : "SANARY SUR MER", cp : "83110" },
{ code : "83124", intitule : "SEILLANS", cp : "83440" },
{ code : "83124", intitule : "SEILLANS", cp : "83440" },
{ code : "83125", intitule : "SEILLONS SOURCE D ARGENS", cp : "83470" },
{ code : "83126", intitule : "LA SEYNE SUR MER", cp : "83500" },
{ code : "83126", intitule : "LA SEYNE SUR MER", cp : "83500" },
{ code : "83126", intitule : "LA SEYNE SUR MER", cp : "83500" },
{ code : "83127", intitule : "SIGNES", cp : "83870" },
{ code : "83128", intitule : "SILLANS LA CASCADE", cp : "83690" },
{ code : "83129", intitule : "SIX FOURS LES PLAGES", cp : "83140" },
{ code : "83129", intitule : "SIX FOURS LES PLAGES", cp : "83140" },
{ code : "83129", intitule : "SIX FOURS LES PLAGES", cp : "83140" },
{ code : "83130", intitule : "SOLLIES PONT", cp : "83210" },
{ code : "83131", intitule : "SOLLIES TOUCAS", cp : "83210" },
{ code : "83132", intitule : "SOLLIES VILLE", cp : "83210" },
{ code : "83133", intitule : "TANNERON", cp : "83440" },
{ code : "83134", intitule : "TARADEAU", cp : "83460" },
{ code : "83135", intitule : "TAVERNES", cp : "83670" },
{ code : "83136", intitule : "LE THORONET", cp : "83340" },
{ code : "83137", intitule : "TOULON", cp : "83000" },
{ code : "83137", intitule : "TOULON", cp : "83100" },
{ code : "83137", intitule : "TOULON", cp : "83200" },
{ code : "83138", intitule : "TOURRETTES", cp : "83440" },
{ code : "83139", intitule : "TOURTOUR", cp : "83690" },
{ code : "83140", intitule : "TOURVES", cp : "83170" },
{ code : "83141", intitule : "TRANS EN PROVENCE", cp : "83720" },
{ code : "83142", intitule : "TRIGANCE", cp : "83840" },
{ code : "83143", intitule : "LE VAL", cp : "83143" },
{ code : "83144", intitule : "LA VALETTE DU VAR", cp : "83160" },
{ code : "83145", intitule : "VARAGES", cp : "83670" },
{ code : "83146", intitule : "LA VERDIERE", cp : "83560" },
{ code : "83147", intitule : "VERIGNON", cp : "83630" },
{ code : "83148", intitule : "VIDAUBAN", cp : "83550" },
{ code : "83149", intitule : "VILLECROZE", cp : "83690" },
{ code : "83150", intitule : "VINON SUR VERDON", cp : "83560" },
{ code : "83151", intitule : "VINS SUR CARAMY", cp : "83170" },
{ code : "83152", intitule : "RAYOL CANADEL SUR MER", cp : "83820" },
{ code : "83152", intitule : "RAYOL CANADEL SUR MER", cp : "83820" },
{ code : "83153", intitule : "ST MANDRIER SUR MER", cp : "83430" },
{ code : "83154", intitule : "ST ANTONIN DU VAR", cp : "83510" },
{ code : "84001", intitule : "ALTHEN DES PALUDS", cp : "84210" },
{ code : "84002", intitule : "ANSOUIS", cp : "84240" },
{ code : "84003", intitule : "APT", cp : "84400" },
{ code : "84004", intitule : "AUBIGNAN", cp : "84810" },
{ code : "84005", intitule : "AUREL", cp : "84390" },
{ code : "84006", intitule : "AURIBEAU", cp : "84400" },
{ code : "84007", intitule : "AVIGNON", cp : "84000" },
{ code : "84007", intitule : "AVIGNON", cp : "84140" },
{ code : "84008", intitule : "LE BARROUX", cp : "84330" },
{ code : "84009", intitule : "LA BASTIDE DES JOURDANS", cp : "84240" },
{ code : "84010", intitule : "LA BASTIDONNE", cp : "84120" },
{ code : "84011", intitule : "LE BEAUCET", cp : "84210" },
{ code : "84012", intitule : "BEAUMES DE VENISE", cp : "84190" },
{ code : "84013", intitule : "BEAUMETTES", cp : "84220" },
{ code : "84014", intitule : "BEAUMONT DE PERTUIS", cp : "84120" },
{ code : "84015", intitule : "BEAUMONT DU VENTOUX", cp : "84340" },
{ code : "84016", intitule : "BEDARRIDES", cp : "84370" },
{ code : "84017", intitule : "BEDOIN", cp : "84410" },
{ code : "84018", intitule : "BLAUVAC", cp : "84570" },
{ code : "84019", intitule : "BOLLENE", cp : "84500" },
{ code : "84020", intitule : "BONNIEUX", cp : "84480" },
{ code : "84021", intitule : "BRANTES", cp : "84390" },
{ code : "84022", intitule : "BUISSON", cp : "84110" },
{ code : "84023", intitule : "BUOUX", cp : "84480" },
{ code : "84024", intitule : "CABRIERES D AIGUES", cp : "84240" },
{ code : "84025", intitule : "CABRIERES D AVIGNON", cp : "84220" },
{ code : "84026", intitule : "CADENET", cp : "84160" },
{ code : "84027", intitule : "CADEROUSSE", cp : "84860" },
{ code : "84028", intitule : "CAIRANNE", cp : "84290" },
{ code : "84029", intitule : "CAMARET SUR AIGUES", cp : "84850" },
{ code : "84030", intitule : "CAROMB", cp : "84330" },
{ code : "84031", intitule : "CARPENTRAS", cp : "84200" },
{ code : "84032", intitule : "CASENEUVE", cp : "84750" },
{ code : "84033", intitule : "CASTELLET", cp : "84400" },
{ code : "84034", intitule : "CAUMONT SUR DURANCE", cp : "84510" },
{ code : "84035", intitule : "CAVAILLON", cp : "84300" },
{ code : "84035", intitule : "CAVAILLON", cp : "84300" },
{ code : "84036", intitule : "CHATEAUNEUF DE GADAGNE", cp : "84470" },
{ code : "84037", intitule : "CHATEAUNEUF DU PAPE", cp : "84230" },
{ code : "84038", intitule : "CHEVAL BLANC", cp : "84460" },
{ code : "84039", intitule : "COURTHEZON", cp : "84350" },
{ code : "84040", intitule : "CRESTET", cp : "84110" },
{ code : "84041", intitule : "CRILLON LE BRAVE", cp : "84410" },
{ code : "84042", intitule : "CUCURON", cp : "84160" },
{ code : "84043", intitule : "ENTRAIGUES SUR LA SORGUE", cp : "84320" },
{ code : "84044", intitule : "ENTRECHAUX", cp : "84340" },
{ code : "84045", intitule : "FAUCON", cp : "84110" },
{ code : "84046", intitule : "FLASSAN", cp : "84410" },
{ code : "84047", intitule : "GARGAS", cp : "84400" },
{ code : "84048", intitule : "GIGNAC", cp : "84400" },
{ code : "84049", intitule : "GIGONDAS", cp : "84190" },
{ code : "84050", intitule : "GORDES", cp : "84220" },
{ code : "84051", intitule : "GOULT", cp : "84220" },
{ code : "84052", intitule : "GRAMBOIS", cp : "84240" },
{ code : "84053", intitule : "GRILLON", cp : "84600" },
{ code : "84054", intitule : "L ISLE SUR LA SORGUE", cp : "84800" },
{ code : "84055", intitule : "JONQUERETTES", cp : "84450" },
{ code : "84056", intitule : "JONQUIERES", cp : "84150" },
{ code : "84057", intitule : "JOUCAS", cp : "84220" },
{ code : "84058", intitule : "LACOSTE", cp : "84480" },
{ code : "84059", intitule : "LAFARE", cp : "84190" },
{ code : "84060", intitule : "LAGARDE D APT", cp : "84400" },
{ code : "84061", intitule : "LAGARDE PAREOL", cp : "84290" },
{ code : "84062", intitule : "LAGNES", cp : "84800" },
{ code : "84063", intitule : "LAMOTTE DU RHONE", cp : "84840" },
{ code : "84064", intitule : "LAPALUD", cp : "84840" },
{ code : "84065", intitule : "LAURIS", cp : "84360" },
{ code : "84066", intitule : "LIOUX", cp : "84220" },
{ code : "84067", intitule : "LORIOL DU COMTAT", cp : "84870" },
{ code : "84068", intitule : "LOURMARIN", cp : "84160" },
{ code : "84069", intitule : "MALAUCENE", cp : "84340" },
{ code : "84070", intitule : "MALEMORT DU COMTAT", cp : "84570" },
{ code : "84071", intitule : "MAUBEC", cp : "84660" },
{ code : "84072", intitule : "MAZAN", cp : "84380" },
{ code : "84073", intitule : "MENERBES", cp : "84560" },
{ code : "84074", intitule : "MERINDOL", cp : "84360" },
{ code : "84075", intitule : "METHAMIS", cp : "84570" },
{ code : "84076", intitule : "MIRABEAU", cp : "84120" },
{ code : "84077", intitule : "MODENE", cp : "84330" },
{ code : "84078", intitule : "MONDRAGON", cp : "84430" },
{ code : "84079", intitule : "MONIEUX", cp : "84390" },
{ code : "84080", intitule : "MONTEUX", cp : "84170" },
{ code : "84081", intitule : "MORIERES LES AVIGNON", cp : "84310" },
{ code : "84082", intitule : "MORMOIRON", cp : "84570" },
{ code : "84083", intitule : "MORNAS", cp : "84550" },
{ code : "84084", intitule : "LA MOTTE D AIGUES", cp : "84240" },
{ code : "84085", intitule : "MURS", cp : "84220" },
{ code : "84086", intitule : "OPPEDE", cp : "84580" },
{ code : "84087", intitule : "ORANGE", cp : "84100" },
{ code : "84088", intitule : "PERNES LES FONTAINES", cp : "84210" },
{ code : "84089", intitule : "PERTUIS", cp : "84120" },
{ code : "84090", intitule : "PEYPIN D AIGUES", cp : "84240" },
{ code : "84091", intitule : "PIOLENC", cp : "84420" },
{ code : "84092", intitule : "LE PONTET", cp : "84130" },
{ code : "84093", intitule : "PUGET", cp : "84360" },
{ code : "84094", intitule : "PUYMERAS", cp : "84110" },
{ code : "84095", intitule : "PUYVERT", cp : "84160" },
{ code : "84096", intitule : "RASTEAU", cp : "84110" },
{ code : "84097", intitule : "RICHERENCHES", cp : "84600" },
{ code : "84098", intitule : "ROAIX", cp : "84110" },
{ code : "84099", intitule : "ROBION", cp : "84440" },
{ code : "84100", intitule : "LA ROQUE ALRIC", cp : "84190" },
{ code : "84101", intitule : "LA ROQUE SUR PERNES", cp : "84210" },
{ code : "84102", intitule : "ROUSSILLON", cp : "84220" },
{ code : "84103", intitule : "RUSTREL", cp : "84400" },
{ code : "84104", intitule : "SABLET", cp : "84110" },
{ code : "84105", intitule : "SAIGNON", cp : "84400" },
{ code : "84106", intitule : "STE CECILE LES VIGNES", cp : "84290" },
{ code : "84107", intitule : "ST CHRISTOL", cp : "84390" },
{ code : "84108", intitule : "ST DIDIER", cp : "84210" },
{ code : "84109", intitule : "ST HIPPOLYTE LE GRAVEYRON", cp : "84330" },
{ code : "84110", intitule : "ST LEGER DU VENTOUX", cp : "84390" },
{ code : "84111", intitule : "ST MARCELLIN LES VAISON", cp : "84110" },
{ code : "84112", intitule : "ST MARTIN DE CASTILLON", cp : "84750" },
{ code : "84113", intitule : "ST MARTIN DE LA BRASQUE", cp : "84760" },
{ code : "84114", intitule : "ST PANTALEON", cp : "84220" },
{ code : "84115", intitule : "ST PIERRE DE VASSOLS", cp : "84330" },
{ code : "84116", intitule : "ST ROMAIN EN VIENNOIS", cp : "84110" },
{ code : "84117", intitule : "ST ROMAN DE MALEGARDE", cp : "84290" },
{ code : "84118", intitule : "ST SATURNIN LES APT", cp : "84490" },
{ code : "84119", intitule : "ST SATURNIN LES AVIGNON", cp : "84450" },
{ code : "84120", intitule : "ST TRINIT", cp : "84390" },
{ code : "84121", intitule : "SANNES", cp : "84240" },
{ code : "84122", intitule : "SARRIANS", cp : "84260" },
{ code : "84123", intitule : "SAULT", cp : "84390" },
{ code : "84124", intitule : "SAUMANE DE VAUCLUSE", cp : "84800" },
{ code : "84125", intitule : "SAVOILLAN", cp : "84390" },
{ code : "84126", intitule : "SEGURET", cp : "84110" },
{ code : "84127", intitule : "SERIGNAN DU COMTAT", cp : "84830" },
{ code : "84128", intitule : "SIVERGUES", cp : "84400" },
{ code : "84129", intitule : "SORGUES", cp : "84700" },
{ code : "84130", intitule : "SUZETTE", cp : "84190" },
{ code : "84131", intitule : "TAILLADES", cp : "84300" },
{ code : "84132", intitule : "LE THOR", cp : "84250" },
{ code : "84133", intitule : "LA TOUR D AIGUES", cp : "84240" },
{ code : "84134", intitule : "TRAVAILLAN", cp : "84850" },
{ code : "84135", intitule : "UCHAUX", cp : "84100" },
{ code : "84136", intitule : "VACQUEYRAS", cp : "84190" },
{ code : "84137", intitule : "VAISON LA ROMAINE", cp : "84110" },
{ code : "84138", intitule : "VALREAS", cp : "84600" },
{ code : "84139", intitule : "FONTAINE DE VAUCLUSE", cp : "84800" },
{ code : "84140", intitule : "VAUGINES", cp : "84160" },
{ code : "84141", intitule : "VEDENE", cp : "84270" },
{ code : "84142", intitule : "VELLERON", cp : "84740" },
{ code : "84143", intitule : "VENASQUE", cp : "84210" },
{ code : "84144", intitule : "VIENS", cp : "84750" },
{ code : "84145", intitule : "VILLARS", cp : "84400" },
{ code : "84146", intitule : "VILLEDIEU", cp : "84110" },
{ code : "84147", intitule : "VILLELAURE", cp : "84530" },
{ code : "84148", intitule : "VILLES SUR AUZON", cp : "84570" },
{ code : "84149", intitule : "VIOLES", cp : "84150" },
{ code : "84150", intitule : "VISAN", cp : "84820" },
{ code : "84151", intitule : "VITROLLES EN LUBERON", cp : "84240" },
{ code : "85001", intitule : "L AIGUILLON SUR MER", cp : "85460" },
{ code : "85002", intitule : "L AIGUILLON SUR VIE", cp : "85220" },
{ code : "85003", intitule : "AIZENAY", cp : "85190" },
{ code : "85004", intitule : "ANGLES", cp : "85750" },
{ code : "85005", intitule : "ANTIGNY", cp : "85120" },
{ code : "85006", intitule : "APREMONT", cp : "85220" },
{ code : "85008", intitule : "AUBIGNY LES CLOUZEAUX", cp : "85430" },
{ code : "85008", intitule : "AUBIGNY LES CLOUZEAUX", cp : "85430" },
{ code : "85009", intitule : "AUZAY", cp : "85200" },
{ code : "85010", intitule : "AVRILLE", cp : "85440" },
{ code : "85011", intitule : "BARBATRE", cp : "85630" },
{ code : "85012", intitule : "LA BARRE DE MONTS", cp : "85550" },
{ code : "85012", intitule : "LA BARRE DE MONTS", cp : "85550" },
{ code : "85013", intitule : "BAZOGES EN PAILLERS", cp : "85130" },
{ code : "85014", intitule : "BAZOGES EN PAREDS", cp : "85390" },
{ code : "85015", intitule : "BEAUFOU", cp : "85170" },
{ code : "85016", intitule : "BEAULIEU SOUS LA ROCHE", cp : "85190" },
{ code : "85017", intitule : "BEAUREPAIRE", cp : "85500" },
{ code : "85018", intitule : "BEAUVOIR SUR MER", cp : "85230" },
{ code : "85019", intitule : "BELLEVIGNY", cp : "85170" },
{ code : "85019", intitule : "BELLEVIGNY", cp : "85170" },
{ code : "85020", intitule : "BENET", cp : "85490" },
{ code : "85020", intitule : "BENET", cp : "85490" },
{ code : "85020", intitule : "BENET", cp : "85490" },
{ code : "85021", intitule : "LA BERNARDIERE", cp : "85610" },
{ code : "85022", intitule : "LE BERNARD", cp : "85560" },
{ code : "85023", intitule : "BESSAY", cp : "85320" },
{ code : "85024", intitule : "BOIS DE CENE", cp : "85710" },
{ code : "85025", intitule : "LA BOISSIERE DE MONTAIGU", cp : "85600" },
{ code : "85026", intitule : "LA BOISSIERE DES LANDES", cp : "85430" },
{ code : "85027", intitule : "BOUFFERE", cp : "85600" },
{ code : "85028", intitule : "BOUILLE COURDAULT", cp : "85420" },
{ code : "85029", intitule : "BOUIN", cp : "85230" },
{ code : "85031", intitule : "LE BOUPERE", cp : "85510" },
{ code : "85033", intitule : "BOURNEAU", cp : "85200" },
{ code : "85034", intitule : "BOURNEZEAU", cp : "85480" },
{ code : "85034", intitule : "BOURNEZEAU", cp : "85480" },
{ code : "85035", intitule : "BRETIGNOLLES SUR MER", cp : "85470" },
{ code : "85036", intitule : "LA BRETONNIERE LA CLAYE", cp : "85320" },
{ code : "85036", intitule : "LA BRETONNIERE LA CLAYE", cp : "85320" },
{ code : "85037", intitule : "BREUIL BARRET", cp : "85120" },
{ code : "85038", intitule : "LES BROUZILS", cp : "85260" },
{ code : "85039", intitule : "LA BRUFFIERE", cp : "85530" },
{ code : "85040", intitule : "LA CAILLERE ST HILAIRE", cp : "85410" },
{ code : "85040", intitule : "LA CAILLERE ST HILAIRE", cp : "85410" },
{ code : "85041", intitule : "CEZAIS", cp : "85410" },
{ code : "85042", intitule : "CHAILLE LES MARAIS", cp : "85450" },
{ code : "85042", intitule : "CHAILLE LES MARAIS", cp : "85450" },
{ code : "85042", intitule : "CHAILLE LES MARAIS", cp : "85450" },
{ code : "85044", intitule : "CHAIX", cp : "85200" },
{ code : "85045", intitule : "LA CHAIZE GIRAUD", cp : "85220" },
{ code : "85046", intitule : "LA CHAIZE LE VICOMTE", cp : "85310" },
{ code : "85046", intitule : "LA CHAIZE LE VICOMTE", cp : "85310" },
{ code : "85047", intitule : "CHALLANS", cp : "85300" },
{ code : "85048", intitule : "CHAMBRETAUD", cp : "85500" },
{ code : "85049", intitule : "CHAMPAGNE LES MARAIS", cp : "85450" },
{ code : "85050", intitule : "LE CHAMP ST PERE", cp : "85540" },
{ code : "85051", intitule : "CHANTONNAY", cp : "85110" },
{ code : "85051", intitule : "CHANTONNAY", cp : "85110" },
{ code : "85052", intitule : "LA CHAPELLE ACHARD", cp : "85150" },
{ code : "85053", intitule : "LA CHAPELLE AUX LYS", cp : "85120" },
{ code : "85054", intitule : "LA CHAPELLE HERMIER", cp : "85220" },
{ code : "85055", intitule : "LA CHAPELLE PALLUAU", cp : "85670" },
{ code : "85056", intitule : "LA CHAPELLE THEMER", cp : "85210" },
{ code : "85058", intitule : "CHASNAIS", cp : "85400" },
{ code : "85059", intitule : "LA CHATAIGNERAIE", cp : "85120" },
{ code : "85060", intitule : "CHATEAU D OLONNE", cp : "85180" },
{ code : "85061", intitule : "CHATEAU GUIBERT", cp : "85320" },
{ code : "85062", intitule : "CHATEAUNEUF", cp : "85710" },
{ code : "85064", intitule : "CHAUCHE", cp : "85140" },
{ code : "85065", intitule : "CHAVAGNES EN PAILLERS", cp : "85250" },
{ code : "85066", intitule : "CHAVAGNES LES REDOUX", cp : "85390" },
{ code : "85067", intitule : "CHEFFOIS", cp : "85390" },
{ code : "85070", intitule : "COEX", cp : "85220" },
{ code : "85071", intitule : "COMMEQUIERS", cp : "85220" },
{ code : "85072", intitule : "LA COPECHAGNIERE", cp : "85260" },
{ code : "85073", intitule : "CORPE", cp : "85320" },
{ code : "85074", intitule : "LA COUTURE", cp : "85320" },
{ code : "85076", intitule : "CUGAND", cp : "85610" },
{ code : "85077", intitule : "CURZON", cp : "85540" },
{ code : "85078", intitule : "DAMVIX", cp : "85420" },
{ code : "85080", intitule : "DOIX LES FONTAINES", cp : "85200" },
{ code : "85080", intitule : "DOIX LES FONTAINES", cp : "85200" },
{ code : "85081", intitule : "DOMPIERRE SUR YON", cp : "85170" },
{ code : "85082", intitule : "LES EPESSES", cp : "85590" },
{ code : "85083", intitule : "L EPINE", cp : "85740" },
{ code : "85084", intitule : "ESSARTS EN BOCAGE", cp : "85140" },
{ code : "85084", intitule : "ESSARTS EN BOCAGE", cp : "85140" },
{ code : "85084", intitule : "ESSARTS EN BOCAGE", cp : "85140" },
{ code : "85084", intitule : "ESSARTS EN BOCAGE", cp : "85140" },
{ code : "85086", intitule : "FALLERON", cp : "85670" },
{ code : "85087", intitule : "FAYMOREAU", cp : "85240" },
{ code : "85088", intitule : "LE FENOUILLER", cp : "85800" },
{ code : "85089", intitule : "LA FERRIERE", cp : "85280" },
{ code : "85090", intitule : "SEVREMONT", cp : "85700" },
{ code : "85090", intitule : "SEVREMONT", cp : "85700" },
{ code : "85090", intitule : "SEVREMONT", cp : "85700" },
{ code : "85090", intitule : "SEVREMONT", cp : "85700" },
{ code : "85092", intitule : "FONTENAY LE COMTE", cp : "85200" },
{ code : "85092", intitule : "FONTENAY LE COMTE", cp : "85200" },
{ code : "85093", intitule : "FOUGERE", cp : "85480" },
{ code : "85094", intitule : "FOUSSAIS PAYRE", cp : "85240" },
{ code : "85095", intitule : "FROIDFOND", cp : "85300" },
{ code : "85096", intitule : "LA GARNACHE", cp : "85710" },
{ code : "85097", intitule : "LA GAUBRETIERE", cp : "85130" },
{ code : "85098", intitule : "LA GENETOUZE", cp : "85190" },
{ code : "85099", intitule : "LE GIROUARD", cp : "85150" },
{ code : "85100", intitule : "GIVRAND", cp : "85800" },
{ code : "85101", intitule : "LE GIVRE", cp : "85540" },
{ code : "85102", intitule : "GRAND LANDES", cp : "85670" },
{ code : "85103", intitule : "GROSBREUIL", cp : "85440" },
{ code : "85104", intitule : "GRUES", cp : "85580" },
{ code : "85105", intitule : "LE GUE DE VELLUIRE", cp : "85770" },
{ code : "85106", intitule : "LA GUERINIERE", cp : "85680" },
{ code : "85107", intitule : "LA GUYONNIERE", cp : "85600" },
{ code : "85108", intitule : "L HERBERGEMENT", cp : "85260" },
{ code : "85109", intitule : "LES HERBIERS", cp : "85500" },
{ code : "85110", intitule : "L HERMENAULT", cp : "85570" },
{ code : "85111", intitule : "L ILE D ELLE", cp : "85770" },
{ code : "85112", intitule : "L ILE D OLONNE", cp : "85340" },
{ code : "85113", intitule : "L ILE D YEU", cp : "85350" },
{ code : "85113", intitule : "L ILE D YEU", cp : "85350" },
{ code : "85114", intitule : "JARD SUR MER", cp : "85520" },
{ code : "85115", intitule : "LA JAUDONNIERE", cp : "85110" },
{ code : "85116", intitule : "LA JONCHERE", cp : "85540" },
{ code : "85117", intitule : "LAIROUX", cp : "85400" },
{ code : "85118", intitule : "LANDERONDE", cp : "85150" },
{ code : "85119", intitule : "LES LANDES GENUSSON", cp : "85130" },
{ code : "85120", intitule : "LANDEVIEILLE", cp : "85220" },
{ code : "85121", intitule : "LE LANGON", cp : "85370" },
{ code : "85123", intitule : "LIEZ", cp : "85420" },
{ code : "85125", intitule : "LOGE FOUGEREUSE", cp : "85120" },
{ code : "85126", intitule : "LONGEVES", cp : "85200" },
{ code : "85127", intitule : "LONGEVILLE SUR MER", cp : "85560" },
{ code : "85128", intitule : "LUCON", cp : "85400" },
{ code : "85129", intitule : "LES LUCS SUR BOULOGNE", cp : "85170" },
{ code : "85130", intitule : "MACHE", cp : "85190" },
{ code : "85131", intitule : "LES MAGNILS REIGNIERS", cp : "85400" },
{ code : "85132", intitule : "MAILLE", cp : "85420" },
{ code : "85133", intitule : "MAILLEZAIS", cp : "85420" },
{ code : "85134", intitule : "MALLIEVRE", cp : "85590" },
{ code : "85135", intitule : "MAREUIL SUR LAY DISSAIS", cp : "85320" },
{ code : "85135", intitule : "MAREUIL SUR LAY DISSAIS", cp : "85320" },
{ code : "85136", intitule : "MARILLET", cp : "85240" },
{ code : "85137", intitule : "MARSAIS STE RADEGONDE", cp : "85570" },
{ code : "85138", intitule : "MARTINET", cp : "85150" },
{ code : "85139", intitule : "LE MAZEAU", cp : "85420" },
{ code : "85140", intitule : "LA MEILLERAIE TILLAY", cp : "85700" },
{ code : "85141", intitule : "MENOMBLET", cp : "85700" },
{ code : "85142", intitule : "LA MERLATIERE", cp : "85140" },
{ code : "85143", intitule : "MERVENT", cp : "85200" },
{ code : "85144", intitule : "MESNARD LA BAROTIERE", cp : "85500" },
{ code : "85145", intitule : "MONSIREIGNE", cp : "85110" },
{ code : "85146", intitule : "MONTAIGU", cp : "85600" },
{ code : "85147", intitule : "MONTOURNAIS", cp : "85700" },
{ code : "85148", intitule : "MONTREUIL", cp : "85200" },
{ code : "85149", intitule : "MOREILLES", cp : "85450" },
{ code : "85151", intitule : "MORTAGNE SUR SEVRE", cp : "85290" },
{ code : "85152", intitule : "LA MOTHE ACHARD", cp : "85150" },
{ code : "85153", intitule : "MOUCHAMPS", cp : "85640" },
{ code : "85154", intitule : "MOUILLERON ST GERMAIN", cp : "85390" },
{ code : "85154", intitule : "MOUILLERON ST GERMAIN", cp : "85390" },
{ code : "85155", intitule : "MOUILLERON LE CAPTIF", cp : "85000" },
{ code : "85156", intitule : "MOUTIERS LES MAUXFAITS", cp : "85540" },
{ code : "85157", intitule : "MOUTIERS SUR LE LAY", cp : "85320" },
{ code : "85158", intitule : "MOUZEUIL ST MARTIN", cp : "85370" },
{ code : "85159", intitule : "NALLIERS", cp : "85370" },
{ code : "85160", intitule : "NESMY", cp : "85310" },
{ code : "85161", intitule : "NIEUL LE DOLENT", cp : "85430" },
{ code : "85162", intitule : "NIEUL SUR L AUTISE", cp : "85240" },
{ code : "85163", intitule : "NOIRMOUTIER EN L ILE", cp : "85330" },
{ code : "85164", intitule : "NOTRE DAME DE MONTS", cp : "85690" },
{ code : "85166", intitule : "OLONNE SUR MER", cp : "85340" },
{ code : "85167", intitule : "L ORBRIE", cp : "85200" },
{ code : "85168", intitule : "OULMES", cp : "85420" },
{ code : "85169", intitule : "PALLUAU", cp : "85670" },
{ code : "85171", intitule : "PEAULT", cp : "85320" },
{ code : "85172", intitule : "LE PERRIER", cp : "85300" },
{ code : "85174", intitule : "PETOSSE", cp : "85570" },
{ code : "85175", intitule : "LES PINEAUX", cp : "85320" },
{ code : "85176", intitule : "PISSOTTE", cp : "85200" },
{ code : "85177", intitule : "LE POIRE SUR VELLUIRE", cp : "85770" },
{ code : "85178", intitule : "LE POIRE SUR VIE", cp : "85170" },
{ code : "85179", intitule : "POIROUX", cp : "85440" },
{ code : "85181", intitule : "POUILLE", cp : "85570" },
{ code : "85182", intitule : "POUZAUGES", cp : "85700" },
{ code : "85184", intitule : "PUY DE SERRE", cp : "85240" },
{ code : "85185", intitule : "PUYRAVAULT", cp : "85450" },
{ code : "85186", intitule : "LA RABATELIERE", cp : "85250" },
{ code : "85187", intitule : "REAUMUR", cp : "85700" },
{ code : "85188", intitule : "LA REORTHE", cp : "85210" },
{ code : "85189", intitule : "NOTRE DAME DE RIEZ", cp : "85270" },
{ code : "85190", intitule : "ROCHESERVIERE", cp : "85620" },
{ code : "85191", intitule : "LA ROCHE SUR YON", cp : "85000" },
{ code : "85192", intitule : "ROCHETREJOUX", cp : "85510" },
{ code : "85193", intitule : "ROSNAY", cp : "85320" },
{ code : "85194", intitule : "LES SABLES D OLONNE", cp : "85100" },
{ code : "85196", intitule : "ST ANDRE GOULE D OIE", cp : "85250" },
{ code : "85197", intitule : "MONTREVERD", cp : "85260" },
{ code : "85197", intitule : "MONTREVERD", cp : "85260" },
{ code : "85197", intitule : "MONTREVERD", cp : "85260" },
{ code : "85198", intitule : "ST AUBIN DES ORMEAUX", cp : "85130" },
{ code : "85199", intitule : "ST AUBIN LA PLAINE", cp : "85210" },
{ code : "85200", intitule : "ST AVAUGOURD DES LANDES", cp : "85540" },
{ code : "85201", intitule : "ST BENOIST SUR MER", cp : "85540" },
{ code : "85202", intitule : "STE CECILE", cp : "85110" },
{ code : "85204", intitule : "ST CHRISTOPHE DU LIGNERON", cp : "85670" },
{ code : "85205", intitule : "ST CYR DES GATS", cp : "85410" },
{ code : "85206", intitule : "ST CYR EN TALMONDAIS", cp : "85540" },
{ code : "85207", intitule : "ST DENIS DU PAYRE", cp : "85580" },
{ code : "85208", intitule : "ST DENIS LA CHEVASSE", cp : "85170" },
{ code : "85209", intitule : "ST ETIENNE DE BRILLOUET", cp : "85210" },
{ code : "85210", intitule : "ST ETIENNE DU BOIS", cp : "85670" },
{ code : "85211", intitule : "STE FLAIVE DES LOUPS", cp : "85150" },
{ code : "85213", intitule : "RIVES DE L YON", cp : "85310" },
{ code : "85213", intitule : "RIVES DE L YON", cp : "85310" },
{ code : "85214", intitule : "STE FOY", cp : "85150" },
{ code : "85215", intitule : "ST FULGENT", cp : "85250" },
{ code : "85216", intitule : "STE GEMME LA PLAINE", cp : "85400" },
{ code : "85217", intitule : "ST GEORGES DE MONTAIGU", cp : "85600" },
{ code : "85218", intitule : "ST GEORGES DE POINTINDOUX", cp : "85150" },
{ code : "85220", intitule : "ST GERMAIN DE PRINCAY", cp : "85110" },
{ code : "85221", intitule : "ST GERVAIS", cp : "85230" },
{ code : "85222", intitule : "ST GILLES CROIX DE VIE", cp : "85800" },
{ code : "85223", intitule : "STE HERMINE", cp : "85210" },
{ code : "85224", intitule : "ST HILAIRE DE LOULAY", cp : "85600" },
{ code : "85226", intitule : "ST HILAIRE DE RIEZ", cp : "85270" },
{ code : "85226", intitule : "ST HILAIRE DE RIEZ", cp : "85270" },
{ code : "85227", intitule : "ST HILAIRE DES LOGES", cp : "85240" },
{ code : "85229", intitule : "ST HILAIRE DE VOUST", cp : "85120" },
{ code : "85231", intitule : "ST HILAIRE LA FORET", cp : "85440" },
{ code : "85232", intitule : "ST HILAIRE LE VOUHIS", cp : "85480" },
{ code : "85233", intitule : "ST JEAN DE BEUGNE", cp : "85210" },
{ code : "85234", intitule : "ST JEAN DE MONTS", cp : "85160" },
{ code : "85235", intitule : "ST JUIRE CHAMPGILLON", cp : "85210" },
{ code : "85236", intitule : "ST JULIEN DES LANDES", cp : "85150" },
{ code : "85237", intitule : "ST LAURENT DE LA SALLE", cp : "85410" },
{ code : "85238", intitule : "ST LAURENT SUR SEVRE", cp : "85290" },
{ code : "85239", intitule : "ST MAIXENT SUR VIE", cp : "85220" },
{ code : "85240", intitule : "ST MALO DU BOIS", cp : "85590" },
{ code : "85242", intitule : "ST MARS LA REORTHE", cp : "85590" },
{ code : "85243", intitule : "BREM SUR MER", cp : "85470" },
{ code : "85243", intitule : "BREM SUR MER", cp : "85470" },
{ code : "85244", intitule : "ST MARTIN DE FRAIGNEAU", cp : "85200" },
{ code : "85245", intitule : "ST MARTIN DES FONTAINES", cp : "85570" },
{ code : "85246", intitule : "ST MARTIN DES NOYERS", cp : "85140" },
{ code : "85247", intitule : "ST MARTIN DES TILLEULS", cp : "85130" },
{ code : "85248", intitule : "ST MARTIN LARS EN STE HERMINE", cp : "85210" },
{ code : "85250", intitule : "ST MATHURIN", cp : "85150" },
{ code : "85251", intitule : "ST MAURICE DES NOUES", cp : "85120" },
{ code : "85252", intitule : "ST MAURICE LE GIRARD", cp : "85390" },
{ code : "85254", intitule : "ST MESMIN", cp : "85700" },
{ code : "85255", intitule : "ST MICHEL EN L HERM", cp : "85580" },
{ code : "85256", intitule : "ST MICHEL LE CLOUCQ", cp : "85200" },
{ code : "85259", intitule : "ST PAUL EN PAREDS", cp : "85500" },
{ code : "85260", intitule : "ST PAUL MONT PENIT", cp : "85670" },
{ code : "85261", intitule : "STE PEXINE", cp : "85320" },
{ code : "85262", intitule : "ST PHILBERT DE BOUAINE", cp : "85660" },
{ code : "85264", intitule : "ST PIERRE DU CHEMIN", cp : "85120" },
{ code : "85265", intitule : "ST PIERRE LE VIEUX", cp : "85420" },
{ code : "85266", intitule : "ST PROUANT", cp : "85110" },
{ code : "85267", intitule : "STE RADEGONDE DES NOYERS", cp : "85450" },
{ code : "85268", intitule : "ST REVEREND", cp : "85220" },
{ code : "85269", intitule : "ST SIGISMOND", cp : "85420" },
{ code : "85271", intitule : "ST SULPICE EN PAREDS", cp : "85410" },
{ code : "85273", intitule : "ST URBAIN", cp : "85230" },
{ code : "85274", intitule : "ST VALERIEN", cp : "85570" },
{ code : "85276", intitule : "ST VINCENT STERLANGES", cp : "85110" },
{ code : "85277", intitule : "ST VINCENT SUR GRAON", cp : "85540" },
{ code : "85277", intitule : "ST VINCENT SUR GRAON", cp : "85540" },
{ code : "85278", intitule : "ST VINCENT SUR JARD", cp : "85520" },
{ code : "85280", intitule : "SALLERTAINE", cp : "85300" },
{ code : "85281", intitule : "SERIGNE", cp : "85200" },
{ code : "85282", intitule : "SIGOURNAIS", cp : "85110" },
{ code : "85284", intitule : "SOULLANS", cp : "85300" },
{ code : "85285", intitule : "LE TABLIER", cp : "85310" },
{ code : "85286", intitule : "LA TAILLEE", cp : "85450" },
{ code : "85287", intitule : "TALLUD STE GEMME", cp : "85390" },
{ code : "85288", intitule : "TALMONT ST HILAIRE", cp : "85440" },
{ code : "85288", intitule : "TALMONT ST HILAIRE", cp : "85440" },
{ code : "85289", intitule : "LA TARDIERE", cp : "85120" },
{ code : "85290", intitule : "THIRE", cp : "85210" },
{ code : "85291", intitule : "THORIGNY", cp : "85480" },
{ code : "85292", intitule : "THOUARSAIS BOUILDROUX", cp : "85410" },
{ code : "85293", intitule : "TIFFAUGES", cp : "85130" },
{ code : "85294", intitule : "LA TRANCHE SUR MER", cp : "85360" },
{ code : "85295", intitule : "TREIZE SEPTIERS", cp : "85600" },
{ code : "85296", intitule : "TREIZE VENTS", cp : "85590" },
{ code : "85297", intitule : "TRIAIZE", cp : "85580" },
{ code : "85298", intitule : "VAIRE", cp : "85150" },
{ code : "85299", intitule : "VELLUIRE", cp : "85770" },
{ code : "85300", intitule : "VENANSAULT", cp : "85190" },
{ code : "85301", intitule : "VENDRENNES", cp : "85250" },
{ code : "85302", intitule : "LA VERRIE", cp : "85130" },
{ code : "85303", intitule : "VIX", cp : "85770" },
{ code : "85304", intitule : "VOUILLE LES MARAIS", cp : "85450" },
{ code : "85305", intitule : "VOUVANT", cp : "85120" },
{ code : "85306", intitule : "XANTON CHASSENON", cp : "85240" },
{ code : "85307", intitule : "LA FAUTE SUR MER", cp : "85460" },
{ code : "86001", intitule : "ADRIERS", cp : "86430" },
{ code : "86002", intitule : "AMBERRE", cp : "86110" },
{ code : "86003", intitule : "ANCHE", cp : "86700" },
{ code : "86004", intitule : "ANGLES SUR L ANGLIN", cp : "86260" },
{ code : "86005", intitule : "ANGLIERS", cp : "86330" },
{ code : "86006", intitule : "ANTIGNY", cp : "86310" },
{ code : "86007", intitule : "ANTRAN", cp : "86100" },
{ code : "86008", intitule : "ARCAY", cp : "86200" },
{ code : "86009", intitule : "ARCHIGNY", cp : "86210" },
{ code : "86010", intitule : "ASLONNES", cp : "86340" },
{ code : "86011", intitule : "ASNIERES SUR BLOUR", cp : "86430" },
{ code : "86012", intitule : "ASNOIS", cp : "86250" },
{ code : "86013", intitule : "AULNAY", cp : "86330" },
{ code : "86014", intitule : "AVAILLES EN CHATELLERAULT", cp : "86530" },
{ code : "86015", intitule : "AVAILLES LIMOUZINE", cp : "86460" },
{ code : "86016", intitule : "AVANTON", cp : "86170" },
{ code : "86017", intitule : "AYRON", cp : "86190" },
{ code : "86018", intitule : "BASSES", cp : "86200" },
{ code : "86019", intitule : "BEAUMONT", cp : "86490" },
{ code : "86019", intitule : "BEAUMONT", cp : "86490" },
{ code : "86020", intitule : "BELLEFONDS", cp : "86210" },
{ code : "86021", intitule : "BENASSAY", cp : "86470" },
{ code : "86022", intitule : "BERRIE", cp : "86120" },
{ code : "86023", intitule : "BERTHEGON", cp : "86420" },
{ code : "86024", intitule : "BERUGES", cp : "86190" },
{ code : "86025", intitule : "BETHINES", cp : "86310" },
{ code : "86026", intitule : "BEUXES", cp : "86120" },
{ code : "86027", intitule : "BIARD", cp : "86580" },
{ code : "86028", intitule : "BIGNOUX", cp : "86800" },
{ code : "86029", intitule : "BLANZAY", cp : "86400" },
{ code : "86030", intitule : "BLASLAY", cp : "86170" },
{ code : "86031", intitule : "BONNES", cp : "86300" },
{ code : "86032", intitule : "BONNEUIL MATOURS", cp : "86210" },
{ code : "86034", intitule : "BOURESSE", cp : "86410" },
{ code : "86035", intitule : "BOURG ARCHAMBAULT", cp : "86390" },
{ code : "86036", intitule : "BOURNAND", cp : "86120" },
{ code : "86037", intitule : "BRIGUEIL LE CHANTRE", cp : "86290" },
{ code : "86038", intitule : "BRION", cp : "86160" },
{ code : "86039", intitule : "BRUX", cp : "86510" },
{ code : "86040", intitule : "LA BUSSIERE", cp : "86310" },
{ code : "86041", intitule : "BUXEROLLES", cp : "86180" },
{ code : "86042", intitule : "BUXEUIL", cp : "37160" },
{ code : "86043", intitule : "CEAUX EN COUHE", cp : "86700" },
{ code : "86044", intitule : "CEAUX EN LOUDUN", cp : "86200" },
{ code : "86045", intitule : "CELLE LEVESCAULT", cp : "86600" },
{ code : "86046", intitule : "CENON SUR VIENNE", cp : "86530" },
{ code : "86047", intitule : "CERNAY", cp : "86140" },
{ code : "86048", intitule : "CHABOURNAY", cp : "86380" },
{ code : "86049", intitule : "CHALAIS", cp : "86200" },
{ code : "86050", intitule : "CHALANDRAY", cp : "86190" },
{ code : "86051", intitule : "CHAMPAGNE LE SEC", cp : "86510" },
{ code : "86052", intitule : "CHAMPAGNE ST HILAIRE", cp : "86160" },
{ code : "86053", intitule : "CHAMPIGNY LE SEC", cp : "86170" },
{ code : "86054", intitule : "CHAMPNIERS", cp : "86400" },
{ code : "86055", intitule : "LA CHAPELLE BATON", cp : "86250" },
{ code : "86056", intitule : "LA CHAPELLE MONTREUIL", cp : "86470" },
{ code : "86058", intitule : "LA CHAPELLE MOULIERE", cp : "86210" },
{ code : "86059", intitule : "CHAPELLE VIVIERS", cp : "86300" },
{ code : "86060", intitule : "CHARRAIS", cp : "86170" },
{ code : "86061", intitule : "CHARROUX", cp : "86250" },
{ code : "86062", intitule : "CHASSENEUIL DU POITOU", cp : "86360" },
{ code : "86063", intitule : "CHATAIN", cp : "86250" },
{ code : "86064", intitule : "CHATEAU GARNIER", cp : "86350" },
{ code : "86065", intitule : "CHATEAU LARCHER", cp : "86370" },
{ code : "86066", intitule : "CHATELLERAULT", cp : "86100" },
{ code : "86066", intitule : "CHATELLERAULT", cp : "86100" },
{ code : "86067", intitule : "CHATILLON", cp : "86700" },
{ code : "86068", intitule : "CHAUNAY", cp : "86510" },
{ code : "86069", intitule : "LA CHAUSSEE", cp : "86330" },
{ code : "86070", intitule : "CHAUVIGNY", cp : "86300" },
{ code : "86070", intitule : "CHAUVIGNY", cp : "86300" },
{ code : "86071", intitule : "CHENECHE", cp : "86380" },
{ code : "86072", intitule : "CHENEVELLES", cp : "86450" },
{ code : "86073", intitule : "CHERVES", cp : "86170" },
{ code : "86074", intitule : "CHIRE EN MONTREUIL", cp : "86190" },
{ code : "86075", intitule : "CHOUPPES", cp : "86110" },
{ code : "86076", intitule : "CISSE", cp : "86170" },
{ code : "86077", intitule : "CIVAUX", cp : "86320" },
{ code : "86078", intitule : "CIVRAY", cp : "86400" },
{ code : "86079", intitule : "LA ROCHE RIGAULT", cp : "86200" },
{ code : "86079", intitule : "LA ROCHE RIGAULT", cp : "86200" },
{ code : "86079", intitule : "LA ROCHE RIGAULT", cp : "86200" },
{ code : "86080", intitule : "CLOUE", cp : "86600" },
{ code : "86081", intitule : "COLOMBIERS", cp : "86490" },
{ code : "86082", intitule : "COUHE", cp : "86700" },
{ code : "86083", intitule : "COULOMBIERS", cp : "86600" },
{ code : "86084", intitule : "COULONGES", cp : "86290" },
{ code : "86085", intitule : "COUSSAY", cp : "86110" },
{ code : "86086", intitule : "COUSSAY LES BOIS", cp : "86270" },
{ code : "86087", intitule : "CRAON", cp : "86110" },
{ code : "86088", intitule : "CROUTELLE", cp : "86240" },
{ code : "86089", intitule : "CUHON", cp : "86110" },
{ code : "86090", intitule : "CURCAY SUR DIVE", cp : "86120" },
{ code : "86091", intitule : "CURZAY SUR VONNE", cp : "86600" },
{ code : "86092", intitule : "DANGE ST ROMAIN", cp : "86220" },
{ code : "86093", intitule : "DERCE", cp : "86420" },
{ code : "86094", intitule : "DIENNE", cp : "86410" },
{ code : "86095", intitule : "DISSAY", cp : "86130" },
{ code : "86096", intitule : "DOUSSAY", cp : "86140" },
{ code : "86097", intitule : "LA FERRIERE AIROUX", cp : "86160" },
{ code : "86098", intitule : "FLEIX", cp : "86300" },
{ code : "86099", intitule : "FLEURE", cp : "86340" },
{ code : "86100", intitule : "FONTAINE LE COMTE", cp : "86240" },
{ code : "86102", intitule : "FROZES", cp : "86190" },
{ code : "86103", intitule : "GENCAY", cp : "86160" },
{ code : "86104", intitule : "GENOUILLE", cp : "86250" },
{ code : "86105", intitule : "GIZAY", cp : "86340" },
{ code : "86106", intitule : "GLENOUZE", cp : "86200" },
{ code : "86107", intitule : "GOUEX", cp : "86320" },
{ code : "86108", intitule : "LA GRIMAUDIERE", cp : "86110" },
{ code : "86108", intitule : "LA GRIMAUDIERE", cp : "86330" },
{ code : "86108", intitule : "LA GRIMAUDIERE", cp : "86330" },
{ code : "86109", intitule : "GUESNES", cp : "86420" },
{ code : "86110", intitule : "HAIMS", cp : "86310" },
{ code : "86111", intitule : "INGRANDES", cp : "86220" },
{ code : "86112", intitule : "L ISLE JOURDAIN", cp : "86150" },
{ code : "86113", intitule : "ITEUIL", cp : "86240" },
{ code : "86114", intitule : "JARDRES", cp : "86800" },
{ code : "86115", intitule : "JAUNAY CLAN", cp : "86130" },
{ code : "86116", intitule : "JAZENEUIL", cp : "86600" },
{ code : "86117", intitule : "JOUHET", cp : "86500" },
{ code : "86118", intitule : "JOURNET", cp : "86290" },
{ code : "86119", intitule : "JOUSSE", cp : "86350" },
{ code : "86120", intitule : "LATHUS ST REMY", cp : "86390" },
{ code : "86120", intitule : "LATHUS ST REMY", cp : "86390" },
{ code : "86121", intitule : "LATILLE", cp : "86190" },
{ code : "86122", intitule : "LAUTHIERS", cp : "86300" },
{ code : "86123", intitule : "LAVAUSSEAU", cp : "86470" },
{ code : "86124", intitule : "LAVOUX", cp : "86800" },
{ code : "86125", intitule : "LEIGNE LES BOIS", cp : "86450" },
{ code : "86126", intitule : "LEIGNES SUR FONTAINE", cp : "86300" },
{ code : "86127", intitule : "LEIGNE SUR USSEAU", cp : "86230" },
{ code : "86128", intitule : "LENCLOITRE", cp : "86140" },
{ code : "86129", intitule : "LESIGNY", cp : "86270" },
{ code : "86130", intitule : "LEUGNY", cp : "86220" },
{ code : "86131", intitule : "LHOMMAIZE", cp : "86410" },
{ code : "86132", intitule : "LIGLET", cp : "86290" },
{ code : "86133", intitule : "LIGUGE", cp : "86240" },
{ code : "86134", intitule : "LINAZAY", cp : "86400" },
{ code : "86135", intitule : "LINIERS", cp : "86800" },
{ code : "86136", intitule : "LIZANT", cp : "86400" },
{ code : "86137", intitule : "LOUDUN", cp : "86200" },
{ code : "86137", intitule : "LOUDUN", cp : "86200" },
{ code : "86138", intitule : "LUCHAPT", cp : "86430" },
{ code : "86139", intitule : "LUSIGNAN", cp : "86600" },
{ code : "86140", intitule : "LUSSAC LES CHATEAUX", cp : "86320" },
{ code : "86141", intitule : "MAGNE", cp : "86160" },
{ code : "86142", intitule : "MAILLE", cp : "86190" },
{ code : "86143", intitule : "MAIRE", cp : "86270" },
{ code : "86144", intitule : "MAISONNEUVE", cp : "86170" },
{ code : "86145", intitule : "MARCAY", cp : "86370" },
{ code : "86146", intitule : "MARIGNY BRIZAY", cp : "86380" },
{ code : "86147", intitule : "MARIGNY CHEMEREAU", cp : "86370" },
{ code : "86148", intitule : "MARNAY", cp : "86160" },
{ code : "86149", intitule : "MARTAIZE", cp : "86330" },
{ code : "86150", intitule : "MASSOGNES", cp : "86170" },
{ code : "86151", intitule : "MAULAY", cp : "86200" },
{ code : "86152", intitule : "MAUPREVOIR", cp : "86460" },
{ code : "86153", intitule : "MAZEROLLES", cp : "86320" },
{ code : "86154", intitule : "MAZEUIL", cp : "86110" },
{ code : "86156", intitule : "MESSEME", cp : "86200" },
{ code : "86157", intitule : "MIGNALOUX BEAUVOIR", cp : "86550" },
{ code : "86158", intitule : "MIGNE AUXANCES", cp : "86440" },
{ code : "86159", intitule : "MILLAC", cp : "86150" },
{ code : "86160", intitule : "MIREBEAU", cp : "86110" },
{ code : "86161", intitule : "MONCONTOUR", cp : "86330" },
{ code : "86161", intitule : "MONCONTOUR", cp : "86330" },
{ code : "86161", intitule : "MONCONTOUR", cp : "86330" },
{ code : "86161", intitule : "MONCONTOUR", cp : "86330" },
{ code : "86162", intitule : "MONDION", cp : "86230" },
{ code : "86163", intitule : "MONTAMISE", cp : "86360" },
{ code : "86164", intitule : "MONTHOIRON", cp : "86210" },
{ code : "86165", intitule : "MONTMORILLON", cp : "86500" },
{ code : "86166", intitule : "MONTREUIL BONNIN", cp : "86470" },
{ code : "86167", intitule : "MONTS SUR GUESNES", cp : "86420" },
{ code : "86169", intitule : "MORTON", cp : "86120" },
{ code : "86170", intitule : "MOULISMES", cp : "86500" },
{ code : "86171", intitule : "MOUSSAC", cp : "86150" },
{ code : "86172", intitule : "MOUTERRE SUR BLOURDE", cp : "86430" },
{ code : "86173", intitule : "MOUTERRE SILLY", cp : "86200" },
{ code : "86174", intitule : "NAINTRE", cp : "86530" },
{ code : "86175", intitule : "NALLIERS", cp : "86310" },
{ code : "86176", intitule : "NERIGNAC", cp : "86150" },
{ code : "86177", intitule : "NEUVILLE DE POITOU", cp : "86170" },
{ code : "86178", intitule : "NIEUIL L ESPOIR", cp : "86340" },
{ code : "86180", intitule : "NOUAILLE MAUPERTUIS", cp : "86340" },
{ code : "86181", intitule : "NUEIL SOUS FAYE", cp : "86200" },
{ code : "86182", intitule : "ORCHES", cp : "86230" },
{ code : "86183", intitule : "LES ORMES", cp : "86220" },
{ code : "86184", intitule : "OUZILLY", cp : "86380" },
{ code : "86186", intitule : "OYRE", cp : "86220" },
{ code : "86187", intitule : "PAIZAY LE SEC", cp : "86300" },
{ code : "86188", intitule : "PAYRE", cp : "86700" },
{ code : "86189", intitule : "PAYROUX", cp : "86350" },
{ code : "86190", intitule : "PERSAC", cp : "86320" },
{ code : "86191", intitule : "PINDRAY", cp : "86500" },
{ code : "86192", intitule : "PLAISANCE", cp : "86500" },
{ code : "86193", intitule : "PLEUMARTIN", cp : "86450" },
{ code : "86194", intitule : "POITIERS", cp : "86000" },
{ code : "86195", intitule : "PORT DE PILES", cp : "86220" },
{ code : "86196", intitule : "POUANCAY", cp : "86120" },
{ code : "86197", intitule : "POUANT", cp : "86200" },
{ code : "86198", intitule : "POUILLE", cp : "86800" },
{ code : "86200", intitule : "PRESSAC", cp : "86460" },
{ code : "86201", intitule : "PRINCAY", cp : "86420" },
{ code : "86202", intitule : "LA PUYE", cp : "86260" },
{ code : "86203", intitule : "QUEAUX", cp : "86150" },
{ code : "86204", intitule : "QUINCAY", cp : "86190" },
{ code : "86205", intitule : "RANTON", cp : "86200" },
{ code : "86206", intitule : "RASLAY", cp : "86120" },
{ code : "86207", intitule : "LA ROCHE POSAY", cp : "86270" },
{ code : "86208", intitule : "LE ROCHEREAU", cp : "86170" },
{ code : "86209", intitule : "ROCHES PREMARIE ANDILLE", cp : "86340" },
{ code : "86210", intitule : "ROIFFE", cp : "86120" },
{ code : "86211", intitule : "ROMAGNE", cp : "86700" },
{ code : "86213", intitule : "ROUILLE", cp : "86480" },
{ code : "86214", intitule : "ST BENOIT", cp : "86280" },
{ code : "86217", intitule : "ST CHRISTOPHE", cp : "86230" },
{ code : "86218", intitule : "ST CLAIR", cp : "86330" },
{ code : "86219", intitule : "ST CYR", cp : "86130" },
{ code : "86220", intitule : "ST GAUDENT", cp : "86400" },
{ code : "86221", intitule : "ST GENEST D AMBIERE", cp : "86140" },
{ code : "86222", intitule : "ST GEORGES LES BAILLARGEAUX", cp : "86130" },
{ code : "86223", intitule : "ST GERMAIN", cp : "86310" },
{ code : "86224", intitule : "ST GERVAIS LES TROIS CLOCHERS", cp : "86230" },
{ code : "86225", intitule : "ST JEAN DE SAUVES", cp : "86330" },
{ code : "86225", intitule : "ST JEAN DE SAUVES", cp : "86330" },
{ code : "86226", intitule : "ST JULIEN L ARS", cp : "86800" },
{ code : "86227", intitule : "ST LAON", cp : "86200" },
{ code : "86228", intitule : "ST LAURENT DE JOURDES", cp : "86410" },
{ code : "86229", intitule : "ST LEGER DE MONTBRILLAIS", cp : "86120" },
{ code : "86230", intitule : "ST LEOMER", cp : "86290" },
{ code : "86231", intitule : "ST MACOUX", cp : "86400" },
{ code : "86233", intitule : "VALDIVIENNE", cp : "86300" },
{ code : "86233", intitule : "VALDIVIENNE", cp : "86300" },
{ code : "86233", intitule : "VALDIVIENNE", cp : "86300" },
{ code : "86233", intitule : "VALDIVIENNE", cp : "86300" },
{ code : "86233", intitule : "VALDIVIENNE", cp : "86300" },
{ code : "86234", intitule : "ST MARTIN L ARS", cp : "86350" },
{ code : "86235", intitule : "ST MAURICE LA CLOUERE", cp : "86160" },
{ code : "86236", intitule : "ST PIERRE DE MAILLE", cp : "86260" },
{ code : "86237", intitule : "ST PIERRE D EXIDEUIL", cp : "86400" },
{ code : "86239", intitule : "STE RADEGONDE", cp : "86300" },
{ code : "86241", intitule : "ST REMY SUR CREUSE", cp : "86220" },
{ code : "86242", intitule : "ST ROMAIN", cp : "86250" },
{ code : "86244", intitule : "ST SAUVANT", cp : "86600" },
{ code : "86245", intitule : "SENILLE ST SAUVEUR", cp : "86100" },
{ code : "86245", intitule : "SENILLE ST SAUVEUR", cp : "86100" },
{ code : "86246", intitule : "ST SAVIN", cp : "86310" },
{ code : "86247", intitule : "ST SAVIOL", cp : "86400" },
{ code : "86248", intitule : "ST SECONDIN", cp : "86350" },
{ code : "86249", intitule : "SAIRES", cp : "86420" },
{ code : "86250", intitule : "SAIX", cp : "86120" },
{ code : "86252", intitule : "SAMMARCOLLES", cp : "86200" },
{ code : "86253", intitule : "SANXAY", cp : "86600" },
{ code : "86254", intitule : "SAULGE", cp : "86500" },
{ code : "86255", intitule : "SAVIGNE", cp : "86400" },
{ code : "86256", intitule : "SAVIGNY LEVESCAULT", cp : "86800" },
{ code : "86257", intitule : "SAVIGNY SOUS FAYE", cp : "86140" },
{ code : "86258", intitule : "SCORBE CLAIRVAUX", cp : "86140" },
{ code : "86260", intitule : "SERIGNY", cp : "86230" },
{ code : "86261", intitule : "SEVRES ANXAUMONT", cp : "86800" },
{ code : "86262", intitule : "SILLARS", cp : "86320" },
{ code : "86263", intitule : "SMARVES", cp : "86240" },
{ code : "86264", intitule : "SOMMIERES DU CLAIN", cp : "86160" },
{ code : "86265", intitule : "SOSSAIS", cp : "86230" },
{ code : "86266", intitule : "SURIN", cp : "86250" },
{ code : "86268", intitule : "TERCE", cp : "86800" },
{ code : "86269", intitule : "TERNAY", cp : "86120" },
{ code : "86270", intitule : "THOLLET", cp : "86290" },
{ code : "86271", intitule : "THURAGEAU", cp : "86110" },
{ code : "86272", intitule : "THURE", cp : "86540" },
{ code : "86273", intitule : "LA TRIMOUILLE", cp : "86290" },
{ code : "86274", intitule : "LES TROIS MOUTIERS", cp : "86120" },
{ code : "86275", intitule : "USSEAU", cp : "86230" },
{ code : "86276", intitule : "USSON DU POITOU", cp : "86350" },
{ code : "86277", intitule : "VARENNES", cp : "86110" },
{ code : "86278", intitule : "VAUX", cp : "86700" },
{ code : "86279", intitule : "VAUX SUR VIENNE", cp : "86220" },
{ code : "86280", intitule : "VELLECHES", cp : "86230" },
{ code : "86281", intitule : "VENDEUVRE DU POITOU", cp : "86380" },
{ code : "86284", intitule : "VERNON", cp : "86340" },
{ code : "86285", intitule : "VERRIERES", cp : "86410" },
{ code : "86286", intitule : "VERRUE", cp : "86420" },
{ code : "86287", intitule : "VEZIERES", cp : "86120" },
{ code : "86288", intitule : "VICQ SUR GARTEMPE", cp : "86260" },
{ code : "86289", intitule : "LE VIGEANT", cp : "86150" },
{ code : "86290", intitule : "LA VILLEDIEU DU CLAIN", cp : "86340" },
{ code : "86291", intitule : "VILLEMORT", cp : "86310" },
{ code : "86292", intitule : "VILLIERS", cp : "86190" },
{ code : "86293", intitule : "VIVONNE", cp : "86370" },
{ code : "86294", intitule : "VOUILLE", cp : "86190" },
{ code : "86295", intitule : "VOULEME", cp : "86400" },
{ code : "86296", intitule : "VOULON", cp : "86700" },
{ code : "86297", intitule : "VOUNEUIL SOUS BIARD", cp : "86580" },
{ code : "86297", intitule : "VOUNEUIL SOUS BIARD", cp : "86580" },
{ code : "86298", intitule : "VOUNEUIL SUR VIENNE", cp : "86210" },
{ code : "86299", intitule : "VOUZAILLES", cp : "86170" },
{ code : "86300", intitule : "YVERSAY", cp : "86170" },
{ code : "87001", intitule : "AIXE SUR VIENNE", cp : "87700" },
{ code : "87002", intitule : "AMBAZAC", cp : "87240" },
{ code : "87003", intitule : "ARNAC LA POSTE", cp : "87160" },
{ code : "87004", intitule : "AUGNE", cp : "87120" },
{ code : "87005", intitule : "AUREIL", cp : "87220" },
{ code : "87006", intitule : "AZAT LE RIS", cp : "87360" },
{ code : "87007", intitule : "BALLEDENT", cp : "87290" },
{ code : "87008", intitule : "LA BAZEUGE", cp : "87210" },
{ code : "87009", intitule : "BEAUMONT DU LAC", cp : "87120" },
{ code : "87011", intitule : "BELLAC", cp : "87300" },
{ code : "87012", intitule : "BERNEUIL", cp : "87300" },
{ code : "87013", intitule : "BERSAC SUR RIVALIER", cp : "87370" },
{ code : "87014", intitule : "BESSINES SUR GARTEMPE", cp : "87250" },
{ code : "87014", intitule : "BESSINES SUR GARTEMPE", cp : "87250" },
{ code : "87015", intitule : "BEYNAC", cp : "87700" },
{ code : "87016", intitule : "LES BILLANGES", cp : "87340" },
{ code : "87017", intitule : "BLANZAC", cp : "87300" },
{ code : "87018", intitule : "BLOND", cp : "87300" },
{ code : "87019", intitule : "BOISSEUIL", cp : "87220" },
{ code : "87020", intitule : "BONNAC LA COTE", cp : "87270" },
{ code : "87021", intitule : "BOSMIE L AIGUILLE", cp : "87110" },
{ code : "87022", intitule : "BREUILAUFA", cp : "87300" },
{ code : "87023", intitule : "LE BUIS", cp : "87140" },
{ code : "87024", intitule : "BUJALEUF", cp : "87460" },
{ code : "87025", intitule : "BURGNAC", cp : "87800" },
{ code : "87027", intitule : "BUSSIERE GALANT", cp : "87230" },
{ code : "87027", intitule : "BUSSIERE GALANT", cp : "87230" },
{ code : "87028", intitule : "BUSSIERE POITEVINE", cp : "87320" },
{ code : "87029", intitule : "LES CARS", cp : "87230" },
{ code : "87030", intitule : "CHAILLAC SUR VIENNE", cp : "87200" },
{ code : "87031", intitule : "LE CHALARD", cp : "87500" },
{ code : "87032", intitule : "CHALUS", cp : "87230" },
{ code : "87033", intitule : "CHAMBORET", cp : "87140" },
{ code : "87034", intitule : "CHAMPAGNAC LA RIVIERE", cp : "87150" },
{ code : "87035", intitule : "CHAMPNETERY", cp : "87400" },
{ code : "87036", intitule : "CHAMPSAC", cp : "87230" },
{ code : "87037", intitule : "LA CHAPELLE MONTBRANDEIX", cp : "87440" },
{ code : "87038", intitule : "CHAPTELAT", cp : "87270" },
{ code : "87039", intitule : "CHATEAU CHERVIX", cp : "87380" },
{ code : "87040", intitule : "CHATEAUNEUF LA FORET", cp : "87130" },
{ code : "87041", intitule : "CHATEAUPONSAC", cp : "87290" },
{ code : "87042", intitule : "LE CHATENET EN DOGNON", cp : "87400" },
{ code : "87043", intitule : "CHEISSOUX", cp : "87460" },
{ code : "87044", intitule : "CHERONNAC", cp : "87600" },
{ code : "87045", intitule : "CIEUX", cp : "87520" },
{ code : "87046", intitule : "COGNAC LA FORET", cp : "87310" },
{ code : "87047", intitule : "COMPREIGNAC", cp : "87140" },
{ code : "87048", intitule : "CONDAT SUR VIENNE", cp : "87920" },
{ code : "87049", intitule : "COUSSAC BONNEVAL", cp : "87500" },
{ code : "87050", intitule : "COUZEIX", cp : "87270" },
{ code : "87051", intitule : "LA CROISILLE SUR BRIANCE", cp : "87130" },
{ code : "87052", intitule : "LA CROIX SUR GARTEMPE", cp : "87210" },
{ code : "87053", intitule : "CROMAC", cp : "87160" },
{ code : "87054", intitule : "CUSSAC", cp : "87150" },
{ code : "87055", intitule : "DARNAC", cp : "87320" },
{ code : "87056", intitule : "DINSAC", cp : "87210" },
{ code : "87057", intitule : "DOMPIERRE LES EGLISES", cp : "87190" },
{ code : "87058", intitule : "DOMPS", cp : "87120" },
{ code : "87059", intitule : "LE DORAT", cp : "87210" },
{ code : "87060", intitule : "DOURNAZAC", cp : "87230" },
{ code : "87061", intitule : "DROUX", cp : "87190" },
{ code : "87062", intitule : "EYBOULEUF", cp : "87400" },
{ code : "87063", intitule : "EYJEAUX", cp : "87220" },
{ code : "87064", intitule : "EYMOUTIERS", cp : "87120" },
{ code : "87065", intitule : "FEYTIAT", cp : "87220" },
{ code : "87066", intitule : "FLAVIGNAC", cp : "87230" },
{ code : "87067", intitule : "FOLLES", cp : "87250" },
{ code : "87068", intitule : "FROMENTAL", cp : "87250" },
{ code : "87069", intitule : "GAJOUBERT", cp : "87330" },
{ code : "87070", intitule : "LA GENEYTOUSE", cp : "87400" },
{ code : "87071", intitule : "GLANDON", cp : "87500" },
{ code : "87072", intitule : "GLANGES", cp : "87380" },
{ code : "87073", intitule : "GORRE", cp : "87310" },
{ code : "87074", intitule : "LES GRANDS CHEZEAUX", cp : "87160" },
{ code : "87075", intitule : "ISLE", cp : "87170" },
{ code : "87076", intitule : "JABREILLES LES BORDES", cp : "87370" },
{ code : "87077", intitule : "JANAILHAC", cp : "87800" },
{ code : "87078", intitule : "JAVERDAT", cp : "87520" },
{ code : "87079", intitule : "LA JONCHERE ST MAURICE", cp : "87340" },
{ code : "87080", intitule : "JOUAC", cp : "87890" },
{ code : "87081", intitule : "JOURGNAC", cp : "87800" },
{ code : "87082", intitule : "LADIGNAC LE LONG", cp : "87500" },
{ code : "87083", intitule : "LAURIERE", cp : "87370" },
{ code : "87084", intitule : "LAVIGNAC", cp : "87230" },
{ code : "87085", intitule : "LIMOGES", cp : "87100" },
{ code : "87085", intitule : "LIMOGES", cp : "87000" },
{ code : "87085", intitule : "LIMOGES", cp : "87100" },
{ code : "87085", intitule : "LIMOGES", cp : "87280" },
{ code : "87085", intitule : "LIMOGES", cp : "87280" },
{ code : "87086", intitule : "LINARDS", cp : "87130" },
{ code : "87087", intitule : "LUSSAC LES EGLISES", cp : "87360" },
{ code : "87088", intitule : "MAGNAC BOURG", cp : "87380" },
{ code : "87089", intitule : "MAGNAC LAVAL", cp : "87190" },
{ code : "87090", intitule : "MAILHAC SUR BENAIZE", cp : "87160" },
{ code : "87091", intitule : "MAISONNAIS SUR TARDOIRE", cp : "87440" },
{ code : "87092", intitule : "MARVAL", cp : "87440" },
{ code : "87092", intitule : "MARVAL", cp : "87440" },
{ code : "87093", intitule : "MASLEON", cp : "87130" },
{ code : "87094", intitule : "MEILHAC", cp : "87800" },
{ code : "87095", intitule : "MEUZAC", cp : "87380" },
{ code : "87096", intitule : "LA MEYZE", cp : "87800" },
{ code : "87097", intitule : "VAL D ISSOIRE", cp : "87330" },
{ code : "87097", intitule : "VAL D ISSOIRE", cp : "87330" },
{ code : "87099", intitule : "MOISSANNES", cp : "87400" },
{ code : "87100", intitule : "MONTROL SENARD", cp : "87330" },
{ code : "87101", intitule : "MORTEMART", cp : "87330" },
{ code : "87103", intitule : "NANTIAT", cp : "87140" },
{ code : "87104", intitule : "NEDDE", cp : "87120" },
{ code : "87105", intitule : "NEUVIC ENTIER", cp : "87130" },
{ code : "87106", intitule : "NEXON", cp : "87800" },
{ code : "87107", intitule : "NIEUL", cp : "87510" },
{ code : "87108", intitule : "NOUIC", cp : "87330" },
{ code : "87109", intitule : "ORADOUR ST GENEST", cp : "87210" },
{ code : "87110", intitule : "ORADOUR SUR GLANE", cp : "87520" },
{ code : "87111", intitule : "ORADOUR SUR VAYRES", cp : "87150" },
{ code : "87112", intitule : "PAGEAS", cp : "87230" },
{ code : "87113", intitule : "LE PALAIS SUR VIENNE", cp : "87410" },
{ code : "87114", intitule : "PANAZOL", cp : "87350" },
{ code : "87115", intitule : "PENSOL", cp : "87440" },
{ code : "87116", intitule : "PEYRAT DE BELLAC", cp : "87300" },
{ code : "87117", intitule : "PEYRAT LE CHATEAU", cp : "87470" },
{ code : "87118", intitule : "PEYRILHAC", cp : "87510" },
{ code : "87119", intitule : "PIERRE BUFFIERE", cp : "87260" },
{ code : "87120", intitule : "LA PORCHERIE", cp : "87380" },
{ code : "87121", intitule : "RANCON", cp : "87290" },
{ code : "87122", intitule : "RAZES", cp : "87640" },
{ code : "87123", intitule : "REMPNAT", cp : "87120" },
{ code : "87124", intitule : "RILHAC LASTOURS", cp : "87800" },
{ code : "87125", intitule : "RILHAC RANCON", cp : "87570" },
{ code : "87126", intitule : "ROCHECHOUART", cp : "87600" },
{ code : "87127", intitule : "LA ROCHE L ABEILLE", cp : "87800" },
{ code : "87128", intitule : "ROUSSAC", cp : "87140" },
{ code : "87129", intitule : "ROYERES", cp : "87400" },
{ code : "87130", intitule : "ROZIERS ST GEORGES", cp : "87130" },
{ code : "87131", intitule : "SAILLAT SUR VIENNE", cp : "87720" },
{ code : "87132", intitule : "ST AMAND LE PETIT", cp : "87120" },
{ code : "87133", intitule : "ST AMAND MAGNAZEIX", cp : "87290" },
{ code : "87134", intitule : "STE ANNE ST PRIEST", cp : "87120" },
{ code : "87135", intitule : "ST AUVENT", cp : "87310" },
{ code : "87136", intitule : "ST BARBANT", cp : "87330" },
{ code : "87137", intitule : "ST BAZILE", cp : "87150" },
{ code : "87138", intitule : "ST BONNET BRIANCE", cp : "87260" },
{ code : "87139", intitule : "ST BONNET DE BELLAC", cp : "87300" },
{ code : "87140", intitule : "ST BRICE SUR VIENNE", cp : "87200" },
{ code : "87141", intitule : "ST CYR", cp : "87310" },
{ code : "87142", intitule : "ST DENIS DES MURS", cp : "87400" },
{ code : "87143", intitule : "ST GENCE", cp : "87510" },
{ code : "87144", intitule : "ST GENEST SUR ROSELLE", cp : "87260" },
{ code : "87145", intitule : "ST GEORGES LES LANDES", cp : "87160" },
{ code : "87146", intitule : "ST GERMAIN LES BELLES", cp : "87380" },
{ code : "87147", intitule : "ST GILLES LES FORETS", cp : "87130" },
{ code : "87148", intitule : "ST HILAIRE BONNEVAL", cp : "87260" },
{ code : "87149", intitule : "ST HILAIRE LA TREILLE", cp : "87190" },
{ code : "87150", intitule : "ST HILAIRE LES PLACES", cp : "87800" },
{ code : "87151", intitule : "ST JEAN LIGOURE", cp : "87260" },
{ code : "87152", intitule : "ST JOUVENT", cp : "87510" },
{ code : "87153", intitule : "ST JULIEN LE PETIT", cp : "87460" },
{ code : "87154", intitule : "ST JUNIEN", cp : "87200" },
{ code : "87155", intitule : "ST JUNIEN LES COMBES", cp : "87300" },
{ code : "87156", intitule : "ST JUST LE MARTEL", cp : "87590" },
{ code : "87157", intitule : "ST LAURENT LES EGLISES", cp : "87240" },
{ code : "87158", intitule : "ST LAURENT SUR GORRE", cp : "87310" },
{ code : "87159", intitule : "ST LEGER LA MONTAGNE", cp : "87340" },
{ code : "87160", intitule : "ST LEGER MAGNAZEIX", cp : "87190" },
{ code : "87161", intitule : "ST LEONARD DE NOBLAT", cp : "87400" },
{ code : "87162", intitule : "STE MARIE DE VAUX", cp : "87420" },
{ code : "87163", intitule : "ST MARTIAL SUR ISOP", cp : "87330" },
{ code : "87164", intitule : "ST MARTIN DE JUSSAC", cp : "87200" },
{ code : "87165", intitule : "ST MARTIN LE MAULT", cp : "87360" },
{ code : "87166", intitule : "ST MARTIN LE VIEUX", cp : "87700" },
{ code : "87167", intitule : "ST MARTIN TERRESSUS", cp : "87400" },
{ code : "87168", intitule : "ST MATHIEU", cp : "87440" },
{ code : "87169", intitule : "ST MAURICE LES BROUSSES", cp : "87800" },
{ code : "87170", intitule : "ST MEARD", cp : "87130" },
{ code : "87172", intitule : "ST OUEN SUR GARTEMPE", cp : "87300" },
{ code : "87173", intitule : "ST PARDOUX", cp : "87250" },
{ code : "87174", intitule : "ST PAUL", cp : "87260" },
{ code : "87176", intitule : "ST PRIEST LIGOURE", cp : "87800" },
{ code : "87177", intitule : "ST PRIEST SOUS AIXE", cp : "87700" },
{ code : "87178", intitule : "ST PRIEST TAURION", cp : "87480" },
{ code : "87179", intitule : "ST SORNIN LA MARCHE", cp : "87210" },
{ code : "87180", intitule : "ST SORNIN LEULAC", cp : "87290" },
{ code : "87180", intitule : "ST SORNIN LEULAC", cp : "87290" },
{ code : "87181", intitule : "ST SULPICE LAURIERE", cp : "87370" },
{ code : "87182", intitule : "ST SULPICE LES FEUILLES", cp : "87160" },
{ code : "87183", intitule : "ST SYLVESTRE", cp : "87240" },
{ code : "87184", intitule : "ST SYMPHORIEN SUR COUZE", cp : "87140" },
{ code : "87185", intitule : "ST VICTURNIEN", cp : "87420" },
{ code : "87186", intitule : "ST VITTE SUR BRIANCE", cp : "87380" },
{ code : "87187", intitule : "ST YRIEIX LA PERCHE", cp : "87500" },
{ code : "87188", intitule : "ST YRIEIX SOUS AIXE", cp : "87700" },
{ code : "87189", intitule : "LES SALLES LAVAUGUYON", cp : "87440" },
{ code : "87190", intitule : "SAUVIAT SUR VIGE", cp : "87400" },
{ code : "87191", intitule : "SEREILHAC", cp : "87620" },
{ code : "87192", intitule : "SOLIGNAC", cp : "87110" },
{ code : "87193", intitule : "SURDOUX", cp : "87130" },
{ code : "87194", intitule : "SUSSAC", cp : "87130" },
{ code : "87195", intitule : "TERSANNES", cp : "87360" },
{ code : "87196", intitule : "THIAT", cp : "87320" },
{ code : "87197", intitule : "THOURON", cp : "87140" },
{ code : "87198", intitule : "VAULRY", cp : "87140" },
{ code : "87199", intitule : "VAYRES", cp : "87600" },
{ code : "87200", intitule : "VERNEUIL MOUSTIERS", cp : "87360" },
{ code : "87201", intitule : "VERNEUIL SUR VIENNE", cp : "87430" },
{ code : "87202", intitule : "VEYRAC", cp : "87520" },
{ code : "87202", intitule : "VEYRAC", cp : "87520" },
{ code : "87203", intitule : "VICQ SUR BREUILH", cp : "87260" },
{ code : "87204", intitule : "VIDEIX", cp : "87600" },
{ code : "87205", intitule : "LE VIGEN", cp : "87110" },
{ code : "87206", intitule : "VILLEFAVARD", cp : "87190" },
{ code : "88001", intitule : "LES ABLEUVENETTES", cp : "88270" },
{ code : "88002", intitule : "AHEVILLE", cp : "88500" },
{ code : "88003", intitule : "AINGEVILLE", cp : "88140" },
{ code : "88004", intitule : "AINVELLE", cp : "88320" },
{ code : "88005", intitule : "ALLARMONT", cp : "88110" },
{ code : "88006", intitule : "AMBACOURT", cp : "88500" },
{ code : "88007", intitule : "AMEUVELLE", cp : "88410" },
{ code : "88008", intitule : "ANGLEMONT", cp : "88700" },
{ code : "88009", intitule : "ANOULD", cp : "88650" },
{ code : "88010", intitule : "AOUZE", cp : "88170" },
{ code : "88011", intitule : "ARCHES", cp : "88380" },
{ code : "88012", intitule : "ARCHETTES", cp : "88380" },
{ code : "88013", intitule : "AROFFE", cp : "88170" },
{ code : "88014", intitule : "ARRENTES DE CORCIEUX", cp : "88430" },
{ code : "88015", intitule : "ATTIGNEVILLE", cp : "88300" },
{ code : "88016", intitule : "ATTIGNY", cp : "88260" },
{ code : "88017", intitule : "AULNOIS", cp : "88300" },
{ code : "88019", intitule : "AUTIGNY LA TOUR", cp : "88300" },
{ code : "88020", intitule : "AUTREVILLE", cp : "88300" },
{ code : "88021", intitule : "AUTREY", cp : "88700" },
{ code : "88022", intitule : "AUZAINVILLIERS", cp : "88140" },
{ code : "88023", intitule : "AVILLERS", cp : "88500" },
{ code : "88024", intitule : "AVRAINVILLE", cp : "88130" },
{ code : "88025", intitule : "AVRANVILLE", cp : "88630" },
{ code : "88026", intitule : "AYDOILLES", cp : "88600" },
{ code : "88027", intitule : "BADMENIL AUX BOIS", cp : "88330" },
{ code : "88028", intitule : "LA BAFFE", cp : "88460" },
{ code : "88029", intitule : "BAINS LES BAINS", cp : "88240" },
{ code : "88030", intitule : "BAINVILLE AUX SAULES", cp : "88270" },
{ code : "88031", intitule : "BALLEVILLE", cp : "88170" },
{ code : "88032", intitule : "BAN DE LAVELINE", cp : "88520" },
{ code : "88033", intitule : "BAN DE SAPT", cp : "88210" },
{ code : "88035", intitule : "BARBEY SEROUX", cp : "88640" },
{ code : "88036", intitule : "BARVILLE", cp : "88300" },
{ code : "88037", intitule : "BASSE SUR LE RUPT", cp : "88120" },
{ code : "88038", intitule : "BATTEXEY", cp : "88130" },
{ code : "88039", intitule : "BAUDRICOURT", cp : "88500" },
{ code : "88040", intitule : "BAYECOURT", cp : "88150" },
{ code : "88041", intitule : "BAZEGNEY", cp : "88270" },
{ code : "88042", intitule : "BAZIEN", cp : "88700" },
{ code : "88043", intitule : "BAZOILLES ET MENIL", cp : "88500" },
{ code : "88044", intitule : "BAZOILLES SUR MEUSE", cp : "88300" },
{ code : "88045", intitule : "BEAUFREMONT", cp : "88300" },
{ code : "88046", intitule : "BEAUMENIL", cp : "88600" },
{ code : "88047", intitule : "BEGNECOURT", cp : "88270" },
{ code : "88048", intitule : "BELLEFONTAINE", cp : "88370" },
{ code : "88049", intitule : "BELMONT LES DARNEY", cp : "88260" },
{ code : "88050", intitule : "BELMONT SUR BUTTANT", cp : "88600" },
{ code : "88051", intitule : "BELMONT SUR VAIR", cp : "88800" },
{ code : "88052", intitule : "BELRUPT", cp : "88260" },
{ code : "88053", intitule : "BELVAL", cp : "88210" },
{ code : "88054", intitule : "BERTRIMOUTIER", cp : "88520" },
{ code : "88055", intitule : "BETTEGNEY ST BRICE", cp : "88450" },
{ code : "88056", intitule : "BETTONCOURT", cp : "88500" },
{ code : "88057", intitule : "LE BEULAY", cp : "88490" },
{ code : "88058", intitule : "BIECOURT", cp : "88170" },
{ code : "88059", intitule : "BIFFONTAINE", cp : "88430" },
{ code : "88060", intitule : "BLEMEREY", cp : "88500" },
{ code : "88061", intitule : "BLEURVILLE", cp : "88410" },
{ code : "88062", intitule : "BLEVAINCOURT", cp : "88320" },
{ code : "88063", intitule : "BOCQUEGNEY", cp : "88270" },
{ code : "88064", intitule : "BOIS DE CHAMP", cp : "88600" },
{ code : "88065", intitule : "BONVILLET", cp : "88260" },
{ code : "88066", intitule : "BOULAINCOURT", cp : "88500" },
{ code : "88068", intitule : "LA BOURGONCE", cp : "88470" },
{ code : "88069", intitule : "BOUXIERES AUX BOIS", cp : "88270" },
{ code : "88070", intitule : "BOUXURULLES", cp : "88130" },
{ code : "88071", intitule : "BOUZEMONT", cp : "88270" },
{ code : "88073", intitule : "BRANTIGNY", cp : "88130" },
{ code : "88074", intitule : "BRECHAINVILLE", cp : "88350" },
{ code : "88075", intitule : "LA BRESSE", cp : "88250" },
{ code : "88076", intitule : "BROUVELIEURES", cp : "88600" },
{ code : "88077", intitule : "BRU", cp : "88700" },
{ code : "88078", intitule : "BRUYERES", cp : "88600" },
{ code : "88079", intitule : "BULGNEVILLE", cp : "88140" },
{ code : "88080", intitule : "BULT", cp : "88700" },
{ code : "88081", intitule : "BUSSANG", cp : "88540" },
{ code : "88082", intitule : "CELLES SUR PLAINE", cp : "88110" },
{ code : "88083", intitule : "CERTILLEUX", cp : "88300" },
{ code : "88084", intitule : "CHAMAGNE", cp : "88130" },
{ code : "88085", intitule : "CHAMPDRAY", cp : "88640" },
{ code : "88086", intitule : "CHAMP LE DUC", cp : "88600" },
{ code : "88087", intitule : "CHANTRAINE", cp : "88000" },
{ code : "88088", intitule : "LA CHAPELLE AUX BOIS", cp : "88240" },
{ code : "88088", intitule : "LA CHAPELLE AUX BOIS", cp : "88240" },
{ code : "88089", intitule : "LA CHAPELLE DEVANT BRUYERES", cp : "88600" },
{ code : "88090", intitule : "CHARMES", cp : "88130" },
{ code : "88091", intitule : "CHARMOIS DEVANT BRUYERES", cp : "88460" },
{ code : "88092", intitule : "CHARMOIS L ORGUEILLEUX", cp : "88270" },
{ code : "88093", intitule : "CHATAS", cp : "88210" },
{ code : "88094", intitule : "CHATEL SUR MOSELLE", cp : "88330" },
{ code : "88095", intitule : "CHATENOIS", cp : "88170" },
{ code : "88095", intitule : "CHATENOIS", cp : "88170" },
{ code : "88096", intitule : "CHATILLON SUR SAONE", cp : "88410" },
{ code : "88097", intitule : "CHAUFFECOURT", cp : "88500" },
{ code : "88098", intitule : "CHAUMOUSEY", cp : "88390" },
{ code : "88099", intitule : "CHAVELOT", cp : "88150" },
{ code : "88100", intitule : "CHEF HAUT", cp : "88500" },
{ code : "88101", intitule : "CHENIMENIL", cp : "88460" },
{ code : "88102", intitule : "CHERMISEY", cp : "88630" },
{ code : "88103", intitule : "CIRCOURT", cp : "88270" },
{ code : "88104", intitule : "CIRCOURT SUR MOUZON", cp : "88300" },
{ code : "88105", intitule : "CLAUDON", cp : "88410" },
{ code : "88106", intitule : "BAN SUR MEURTHE CLEFCY", cp : "88230" },
{ code : "88106", intitule : "BAN SUR MEURTHE CLEFCY", cp : "88230" },
{ code : "88107", intitule : "CLEREY LA COTE", cp : "88630" },
{ code : "88108", intitule : "LE CLERJUS", cp : "88240" },
{ code : "88109", intitule : "CLEURIE", cp : "88120" },
{ code : "88110", intitule : "CLEZENTAINE", cp : "88700" },
{ code : "88111", intitule : "COINCHES", cp : "88100" },
{ code : "88113", intitule : "COMBRIMONT", cp : "88490" },
{ code : "88114", intitule : "CONTREXEVILLE", cp : "88140" },
{ code : "88115", intitule : "CORCIEUX", cp : "88430" },
{ code : "88116", intitule : "CORNIMONT", cp : "88310" },
{ code : "88117", intitule : "COURCELLES SOUS CHATENOIS", cp : "88170" },
{ code : "88118", intitule : "COUSSEY", cp : "88630" },
{ code : "88119", intitule : "CRAINVILLIERS", cp : "88140" },
{ code : "88120", intitule : "LA CROIX AUX MINES", cp : "88520" },
{ code : "88121", intitule : "DAMAS AUX BOIS", cp : "88330" },
{ code : "88122", intitule : "DAMAS ET BETTEGNEY", cp : "88270" },
{ code : "88123", intitule : "DAMBLAIN", cp : "88320" },
{ code : "88124", intitule : "DARNEY", cp : "88260" },
{ code : "88125", intitule : "DARNEY AUX CHENES", cp : "88170" },
{ code : "88126", intitule : "DARNIEULLES", cp : "88390" },
{ code : "88127", intitule : "DEINVILLERS", cp : "88700" },
{ code : "88128", intitule : "DENIPAIRE", cp : "88210" },
{ code : "88129", intitule : "DERBAMONT", cp : "88270" },
{ code : "88130", intitule : "DESTORD", cp : "88600" },
{ code : "88131", intitule : "DEYCIMONT", cp : "88600" },
{ code : "88132", intitule : "DEYVILLERS", cp : "88000" },
{ code : "88133", intitule : "DIGNONVILLE", cp : "88000" },
{ code : "88134", intitule : "DINOZE", cp : "88000" },
{ code : "88135", intitule : "DOCELLES", cp : "88460" },
{ code : "88136", intitule : "DOGNEVILLE", cp : "88000" },
{ code : "88137", intitule : "DOLAINCOURT", cp : "88170" },
{ code : "88138", intitule : "DOMBASLE DEVANT DARNEY", cp : "88260" },
{ code : "88139", intitule : "DOMBASLE EN XAINTOIS", cp : "88500" },
{ code : "88140", intitule : "DOMBROT LE SEC", cp : "88140" },
{ code : "88141", intitule : "DOMBROT SUR VAIR", cp : "88170" },
{ code : "88142", intitule : "DOMEVRE SUR AVIERE", cp : "88390" },
{ code : "88143", intitule : "DOMEVRE SUR DURBION", cp : "88330" },
{ code : "88144", intitule : "DOMEVRE SOUS MONTFORT", cp : "88500" },
{ code : "88145", intitule : "DOMFAING", cp : "88600" },
{ code : "88146", intitule : "DOMJULIEN", cp : "88800" },
{ code : "88146", intitule : "DOMJULIEN", cp : "88800" },
{ code : "88147", intitule : "DOMMARTIN AUX BOIS", cp : "88390" },
{ code : "88148", intitule : "DOMMARTIN LES REMIREMONT", cp : "88200" },
{ code : "88149", intitule : "DOMMARTIN LES VALLOIS", cp : "88260" },
{ code : "88150", intitule : "DOMMARTIN SUR VRAINE", cp : "88170" },
{ code : "88151", intitule : "DOMPAIRE", cp : "88270" },
{ code : "88152", intitule : "DOMPIERRE", cp : "88600" },
{ code : "88153", intitule : "DOMPTAIL", cp : "88700" },
{ code : "88154", intitule : "DOMREMY LA PUCELLE", cp : "88630" },
{ code : "88155", intitule : "DOMVALLIER", cp : "88500" },
{ code : "88156", intitule : "DONCIERES", cp : "88700" },
{ code : "88157", intitule : "DOUNOUX", cp : "88220" },
{ code : "88158", intitule : "ELOYES", cp : "88510" },
{ code : "88159", intitule : "ENTRE DEUX EAUX", cp : "88650" },
{ code : "88160", intitule : "EPINAL", cp : "88000" },
{ code : "88161", intitule : "ESCLES", cp : "88260" },
{ code : "88162", intitule : "ESLEY", cp : "88260" },
{ code : "88163", intitule : "ESSEGNEY", cp : "88130" },
{ code : "88164", intitule : "ESTRENNES", cp : "88500" },
{ code : "88165", intitule : "ETIVAL CLAIREFONTAINE", cp : "88480" },
{ code : "88166", intitule : "EVAUX ET MENIL", cp : "88450" },
{ code : "88167", intitule : "FAUCOMPIERRE", cp : "88460" },
{ code : "88168", intitule : "FAUCONCOURT", cp : "88700" },
{ code : "88169", intitule : "FAYS", cp : "88600" },
{ code : "88170", intitule : "FERDRUPT", cp : "88360" },
{ code : "88171", intitule : "FIGNEVELLE", cp : "88410" },
{ code : "88172", intitule : "FIMENIL", cp : "88600" },
{ code : "88173", intitule : "FLOREMONT", cp : "88130" },
{ code : "88174", intitule : "FOMEREY", cp : "88390" },
{ code : "88175", intitule : "FONTENAY", cp : "88600" },
{ code : "88176", intitule : "FONTENOY LE CHATEAU", cp : "88240" },
{ code : "88176", intitule : "FONTENOY LE CHATEAU", cp : "88240" },
{ code : "88177", intitule : "LA FORGE", cp : "88530" },
{ code : "88178", intitule : "LES FORGES", cp : "88390" },
{ code : "88179", intitule : "FOUCHECOURT", cp : "88320" },
{ code : "88180", intitule : "FRAIN", cp : "88320" },
{ code : "88181", intitule : "FRAIZE", cp : "88230" },
{ code : "88182", intitule : "FRAPELLE", cp : "88490" },
{ code : "88183", intitule : "FREBECOURT", cp : "88630" },
{ code : "88184", intitule : "FREMIFONTAINE", cp : "88600" },
{ code : "88185", intitule : "FRENELLE LA GRANDE", cp : "88500" },
{ code : "88186", intitule : "FRENELLE LA PETITE", cp : "88500" },
{ code : "88187", intitule : "FRENOIS", cp : "88270" },
{ code : "88188", intitule : "FRESSE SUR MOSELLE", cp : "88160" },
{ code : "88189", intitule : "FREVILLE", cp : "88350" },
{ code : "88190", intitule : "FRIZON", cp : "88440" },
{ code : "88192", intitule : "GELVECOURT ET ADOMPT", cp : "88270" },
{ code : "88192", intitule : "GELVECOURT ET ADOMPT", cp : "88270" },
{ code : "88193", intitule : "GEMAINGOUTTE", cp : "88520" },
{ code : "88194", intitule : "GEMMELAINCOURT", cp : "88170" },
{ code : "88195", intitule : "GENDREVILLE", cp : "88140" },
{ code : "88196", intitule : "GERARDMER", cp : "88400" },
{ code : "88197", intitule : "GERBAMONT", cp : "88120" },
{ code : "88198", intitule : "GERBEPAL", cp : "88430" },
{ code : "88199", intitule : "GIGNEVILLE", cp : "88320" },
{ code : "88200", intitule : "GIGNEY", cp : "88390" },
{ code : "88201", intitule : "GIRANCOURT", cp : "88390" },
{ code : "88202", intitule : "GIRCOURT LES VIEVILLE", cp : "88500" },
{ code : "88203", intitule : "GIRECOURT SUR DURBION", cp : "88600" },
{ code : "88205", intitule : "GIRMONT VAL D AJOL", cp : "88340" },
{ code : "88206", intitule : "GIRONCOURT SUR VRAINE", cp : "88170" },
{ code : "88208", intitule : "GODONCOURT", cp : "88410" },
{ code : "88209", intitule : "GOLBEY", cp : "88190" },
{ code : "88210", intitule : "GORHEY", cp : "88270" },
{ code : "88212", intitule : "GRAND", cp : "88350" },
{ code : "88213", intitule : "LA GRANDE FOSSE", cp : "88490" },
{ code : "88214", intitule : "GRANDRUPT DE BAINS", cp : "88240" },
{ code : "88215", intitule : "GRANDRUPT", cp : "88210" },
{ code : "88216", intitule : "GRANDVILLERS", cp : "88600" },
{ code : "88218", intitule : "GRANGES AUMONTZEY", cp : "88640" },
{ code : "88218", intitule : "GRANGES AUMONTZEY", cp : "88640" },
{ code : "88219", intitule : "GREUX", cp : "88630" },
{ code : "88220", intitule : "GRIGNONCOURT", cp : "88410" },
{ code : "88221", intitule : "GRUEY LES SURANCE", cp : "88240" },
{ code : "88222", intitule : "GUGNECOURT", cp : "88600" },
{ code : "88223", intitule : "GUGNEY AUX AULX", cp : "88450" },
{ code : "88224", intitule : "HADIGNY LES VERRIERES", cp : "88330" },
{ code : "88225", intitule : "HADOL", cp : "88220" },
{ code : "88226", intitule : "HAGECOURT", cp : "88270" },
{ code : "88227", intitule : "HAGNEVILLE ET RONCOURT", cp : "88300" },
{ code : "88227", intitule : "HAGNEVILLE ET RONCOURT", cp : "88300" },
{ code : "88228", intitule : "HAILLAINVILLE", cp : "88330" },
{ code : "88229", intitule : "HARCHECHAMP", cp : "88300" },
{ code : "88230", intitule : "HARDANCOURT", cp : "88700" },
{ code : "88231", intitule : "HAREVILLE", cp : "88800" },
{ code : "88232", intitule : "HARMONVILLE", cp : "88300" },
{ code : "88233", intitule : "HAROL", cp : "88270" },
{ code : "88234", intitule : "HARSAULT", cp : "88240" },
{ code : "88234", intitule : "HARSAULT", cp : "88240" },
{ code : "88234", intitule : "HARSAULT", cp : "88240" },
{ code : "88235", intitule : "HAUTMOUGEY", cp : "88240" },
{ code : "88236", intitule : "LA HAYE", cp : "88240" },
{ code : "88237", intitule : "HENNECOURT", cp : "88270" },
{ code : "88238", intitule : "HENNEZEL", cp : "88260" },
{ code : "88239", intitule : "HERGUGNEY", cp : "88130" },
{ code : "88240", intitule : "HERPELMONT", cp : "88600" },
{ code : "88241", intitule : "HOUECOURT", cp : "88170" },
{ code : "88242", intitule : "HOUEVILLE", cp : "88300" },
{ code : "88243", intitule : "HOUSSERAS", cp : "88700" },
{ code : "88244", intitule : "LA HOUSSIERE", cp : "88430" },
{ code : "88245", intitule : "HURBACHE", cp : "88210" },
{ code : "88246", intitule : "HYMONT", cp : "88500" },
{ code : "88247", intitule : "IGNEY", cp : "88150" },
{ code : "88248", intitule : "ISCHES", cp : "88320" },
{ code : "88249", intitule : "JAINVILLOTTE", cp : "88300" },
{ code : "88250", intitule : "JARMENIL", cp : "88550" },
{ code : "88251", intitule : "JEANMENIL", cp : "88700" },
{ code : "88252", intitule : "JESONVILLE", cp : "88260" },
{ code : "88253", intitule : "JEUXEY", cp : "88000" },
{ code : "88254", intitule : "JORXEY", cp : "88500" },
{ code : "88255", intitule : "JUBAINVILLE", cp : "88630" },
{ code : "88256", intitule : "JUSSARUPT", cp : "88640" },
{ code : "88257", intitule : "JUVAINCOURT", cp : "88500" },
{ code : "88258", intitule : "LAMARCHE", cp : "88320" },
{ code : "88259", intitule : "LANDAVILLE", cp : "88300" },
{ code : "88260", intitule : "LANGLEY", cp : "88130" },
{ code : "88261", intitule : "LAVAL SUR VOLOGNE", cp : "88600" },
{ code : "88262", intitule : "LAVELINE DEVANT BRUYERES", cp : "88600" },
{ code : "88263", intitule : "LAVELINE DU HOUX", cp : "88640" },
{ code : "88264", intitule : "LEGEVILLE ET BONFAYS", cp : "88270" },
{ code : "88265", intitule : "LEMMECOURT", cp : "88300" },
{ code : "88266", intitule : "LEPANGES SUR VOLOGNE", cp : "88600" },
{ code : "88267", intitule : "LERRAIN", cp : "88260" },
{ code : "88268", intitule : "LESSEUX", cp : "88490" },
{ code : "88269", intitule : "LIEZEY", cp : "88400" },
{ code : "88270", intitule : "LIFFOL LE GRAND", cp : "88350" },
{ code : "88271", intitule : "LIGNEVILLE", cp : "88800" },
{ code : "88272", intitule : "LIRONCOURT", cp : "88410" },
{ code : "88273", intitule : "LONGCHAMP", cp : "88000" },
{ code : "88274", intitule : "LONGCHAMP SOUS CHATENOIS", cp : "88170" },
{ code : "88275", intitule : "LUBINE", cp : "88490" },
{ code : "88276", intitule : "LUSSE", cp : "88490" },
{ code : "88277", intitule : "LUVIGNY", cp : "88110" },
{ code : "88278", intitule : "MACONCOURT", cp : "88170" },
{ code : "88279", intitule : "MADECOURT", cp : "88270" },
{ code : "88280", intitule : "MADEGNEY", cp : "88450" },
{ code : "88281", intitule : "MADONNE ET LAMEREY", cp : "88270" },
{ code : "88283", intitule : "MALAINCOURT", cp : "88140" },
{ code : "88284", intitule : "MANDRAY", cp : "88650" },
{ code : "88285", intitule : "MANDRES SUR VAIR", cp : "88800" },
{ code : "88286", intitule : "MARAINVILLE SUR MADON", cp : "88130" },
{ code : "88287", intitule : "MAREY", cp : "88320" },
{ code : "88288", intitule : "MARONCOURT", cp : "88270" },
{ code : "88289", intitule : "MARTIGNY LES BAINS", cp : "88320" },
{ code : "88290", intitule : "MARTIGNY LES GERBONVAUX", cp : "88300" },
{ code : "88291", intitule : "MARTINVELLE", cp : "88410" },
{ code : "88292", intitule : "MATTAINCOURT", cp : "88500" },
{ code : "88293", intitule : "MAXEY SUR MEUSE", cp : "88630" },
{ code : "88294", intitule : "MAZELEY", cp : "88150" },
{ code : "88295", intitule : "MAZIROT", cp : "88500" },
{ code : "88296", intitule : "MEDONVILLE", cp : "88140" },
{ code : "88297", intitule : "MEMENIL", cp : "88600" },
{ code : "88298", intitule : "MENARMONT", cp : "88700" },
{ code : "88299", intitule : "MENIL EN XAINTOIS", cp : "88500" },
{ code : "88300", intitule : "MENIL DE SENONES", cp : "88210" },
{ code : "88301", intitule : "MENIL SUR BELVITTE", cp : "88700" },
{ code : "88302", intitule : "LE MENIL", cp : "88160" },
{ code : "88303", intitule : "MIDREVAUX", cp : "88630" },
{ code : "88304", intitule : "MIRECOURT", cp : "88500" },
{ code : "88305", intitule : "MONCEL SUR VAIR", cp : "88630" },
{ code : "88306", intitule : "LE MONT", cp : "88210" },
{ code : "88307", intitule : "MONT LES LAMARCHE", cp : "88320" },
{ code : "88308", intitule : "MONT LES NEUFCHATEAU", cp : "88300" },
{ code : "88309", intitule : "MONTHUREUX LE SEC", cp : "88800" },
{ code : "88310", intitule : "MONTHUREUX SUR SAONE", cp : "88410" },
{ code : "88311", intitule : "MONTMOTIER", cp : "88240" },
{ code : "88312", intitule : "MORELMAISON", cp : "88170" },
{ code : "88313", intitule : "MORIVILLE", cp : "88330" },
{ code : "88314", intitule : "MORIZECOURT", cp : "88320" },
{ code : "88315", intitule : "MORTAGNE", cp : "88600" },
{ code : "88316", intitule : "MORVILLE", cp : "88140" },
{ code : "88317", intitule : "MOUSSEY", cp : "88210" },
{ code : "88318", intitule : "MOYEMONT", cp : "88700" },
{ code : "88319", intitule : "MOYENMOUTIER", cp : "88420" },
{ code : "88320", intitule : "NAYEMONT LES FOSSES", cp : "88100" },
{ code : "88321", intitule : "NEUFCHATEAU", cp : "88300" },
{ code : "88322", intitule : "LA NEUVEVILLE DEVANT LEPANGES", cp : "88600" },
{ code : "88322", intitule : "LA NEUVEVILLE DEVANT LEPANGES", cp : "88600" },
{ code : "88322", intitule : "LA NEUVEVILLE DEVANT LEPANGES", cp : "88600" },
{ code : "88324", intitule : "LA NEUVEVILLE SOUS CHATENOIS", cp : "88170" },
{ code : "88325", intitule : "LA NEUVEVILLE SOUS MONTFORT", cp : "88800" },
{ code : "88326", intitule : "NEUVILLERS SUR FAVE", cp : "88100" },
{ code : "88327", intitule : "NOMEXY", cp : "88440" },
{ code : "88328", intitule : "NOMPATELIZE", cp : "88470" },
{ code : "88330", intitule : "NONVILLE", cp : "88260" },
{ code : "88331", intitule : "NONZEVILLE", cp : "88600" },
{ code : "88332", intitule : "NORROY", cp : "88800" },
{ code : "88333", intitule : "NOSSONCOURT", cp : "88700" },
{ code : "88334", intitule : "OELLEVILLE", cp : "88500" },
{ code : "88335", intitule : "OFFROICOURT", cp : "88500" },
{ code : "88336", intitule : "OLLAINVILLE", cp : "88170" },
{ code : "88338", intitule : "ORTONCOURT", cp : "88700" },
{ code : "88340", intitule : "PADOUX", cp : "88700" },
{ code : "88341", intitule : "PAIR ET GRANDRUPT", cp : "88100" },
{ code : "88342", intitule : "PALLEGNEY", cp : "88330" },
{ code : "88343", intitule : "PAREY SOUS MONTFORT", cp : "88800" },
{ code : "88344", intitule : "PARGNY SOUS MUREAU", cp : "88350" },
{ code : "88345", intitule : "LA PETITE FOSSE", cp : "88490" },
{ code : "88346", intitule : "LA PETITE RAON", cp : "88210" },
{ code : "88347", intitule : "PIERREFITTE", cp : "88270" },
{ code : "88348", intitule : "PIERREPONT SUR L ARENTELE", cp : "88600" },
{ code : "88349", intitule : "PLAINFAING", cp : "88230" },
{ code : "88350", intitule : "PLEUVEZAIN", cp : "88170" },
{ code : "88351", intitule : "PLOMBIERES LES BAINS", cp : "88370" },
{ code : "88351", intitule : "PLOMBIERES LES BAINS", cp : "88370" },
{ code : "88351", intitule : "PLOMBIERES LES BAINS", cp : "88370" },
{ code : "88352", intitule : "POMPIERRE", cp : "88300" },
{ code : "88353", intitule : "PONT LES BONFAYS", cp : "88260" },
{ code : "88354", intitule : "PONT SUR MADON", cp : "88500" },
{ code : "88355", intitule : "PORTIEUX", cp : "88330" },
{ code : "88356", intitule : "LES POULIERES", cp : "88600" },
{ code : "88357", intitule : "POUSSAY", cp : "88500" },
{ code : "88358", intitule : "POUXEUX", cp : "88550" },
{ code : "88359", intitule : "PREY", cp : "88600" },
{ code : "88360", intitule : "PROVENCHERES LES DARNEY", cp : "88260" },
{ code : "88361", intitule : "PROVENCHERES ET COLROY", cp : "88490" },
{ code : "88361", intitule : "PROVENCHERES ET COLROY", cp : "88490" },
{ code : "88362", intitule : "LE PUID", cp : "88210" },
{ code : "88363", intitule : "PUNEROT", cp : "88630" },
{ code : "88364", intitule : "PUZIEUX", cp : "88500" },
{ code : "88365", intitule : "RACECOURT", cp : "88270" },
{ code : "88366", intitule : "RAINVILLE", cp : "88170" },
{ code : "88367", intitule : "RAMBERVILLERS", cp : "88700" },
{ code : "88368", intitule : "RAMECOURT", cp : "88500" },
{ code : "88369", intitule : "RAMONCHAMP", cp : "88160" },
{ code : "88370", intitule : "RANCOURT", cp : "88270" },
{ code : "88371", intitule : "RAON AUX BOIS", cp : "88220" },
{ code : "88372", intitule : "RAON L ETAPE", cp : "88110" },
{ code : "88372", intitule : "RAON L ETAPE", cp : "88110" },
{ code : "88373", intitule : "RAON SUR PLAINE", cp : "88110" },
{ code : "88374", intitule : "RAPEY", cp : "88130" },
{ code : "88375", intitule : "RAVES", cp : "88520" },
{ code : "88376", intitule : "REBEUVILLE", cp : "88300" },
{ code : "88377", intitule : "REGNEVELLE", cp : "88410" },
{ code : "88378", intitule : "REGNEY", cp : "88450" },
{ code : "88379", intitule : "REHAINCOURT", cp : "88330" },
{ code : "88380", intitule : "REHAUPAL", cp : "88640" },
{ code : "88381", intitule : "RELANGES", cp : "88260" },
{ code : "88382", intitule : "REMICOURT", cp : "88500" },
{ code : "88383", intitule : "REMIREMONT", cp : "88200" },
{ code : "88385", intitule : "REMONCOURT", cp : "88800" },
{ code : "88386", intitule : "REMOMEIX", cp : "88100" },
{ code : "88387", intitule : "REMOVILLE", cp : "88170" },
{ code : "88388", intitule : "RENAUVOID", cp : "88390" },
{ code : "88389", intitule : "REPEL", cp : "88500" },
{ code : "88390", intitule : "ROBECOURT", cp : "88320" },
{ code : "88391", intitule : "ROCHESSON", cp : "88120" },
{ code : "88392", intitule : "ROCOURT", cp : "88320" },
{ code : "88393", intitule : "ROLLAINVILLE", cp : "88300" },
{ code : "88394", intitule : "ROMAIN AUX BOIS", cp : "88320" },
{ code : "88395", intitule : "ROMONT", cp : "88700" },
{ code : "88398", intitule : "LES ROUGES EAUX", cp : "88600" },
{ code : "88399", intitule : "LE ROULIER", cp : "88460" },
{ code : "88400", intitule : "ROUVRES EN XAINTOIS", cp : "88500" },
{ code : "88401", intitule : "ROUVRES LA CHETIVE", cp : "88170" },
{ code : "88402", intitule : "ROVILLE AUX CHENES", cp : "88700" },
{ code : "88403", intitule : "ROZEROTTE", cp : "88500" },
{ code : "88404", intitule : "ROZIERES SUR MOUZON", cp : "88320" },
{ code : "88406", intitule : "RUGNEY", cp : "88130" },
{ code : "88407", intitule : "RUPPES", cp : "88630" },
{ code : "88408", intitule : "RUPT SUR MOSELLE", cp : "88360" },
{ code : "88409", intitule : "ST AME", cp : "88120" },
{ code : "88410", intitule : "STE BARBE", cp : "88700" },
{ code : "88411", intitule : "ST BASLEMONT", cp : "88260" },
{ code : "88412", intitule : "ST BENOIT LA CHIPOTTE", cp : "88700" },
{ code : "88413", intitule : "ST DIE DES VOSGES", cp : "88100" },
{ code : "88415", intitule : "ST ETIENNE LES REMIREMONT", cp : "88200" },
{ code : "88416", intitule : "ST GENEST", cp : "88700" },
{ code : "88417", intitule : "ST GORGON", cp : "88700" },
{ code : "88418", intitule : "STE HELENE", cp : "88700" },
{ code : "88419", intitule : "ST JEAN D ORMONT", cp : "88210" },
{ code : "88421", intitule : "ST JULIEN", cp : "88410" },
{ code : "88423", intitule : "ST LEONARD", cp : "88650" },
{ code : "88424", intitule : "STE MARGUERITE", cp : "88100" },
{ code : "88425", intitule : "ST MAURICE SUR MORTAGNE", cp : "88700" },
{ code : "88426", intitule : "ST MAURICE SUR MOSELLE", cp : "88560" },
{ code : "88427", intitule : "ST MENGE", cp : "88170" },
{ code : "88428", intitule : "ST MICHEL SUR MEURTHE", cp : "88470" },
{ code : "88429", intitule : "ST NABORD", cp : "88200" },
{ code : "88430", intitule : "ST OUEN LES PAREY", cp : "88140" },
{ code : "88431", intitule : "ST PAUL", cp : "88170" },
{ code : "88432", intitule : "ST PIERREMONT", cp : "88700" },
{ code : "88433", intitule : "ST PRANCHER", cp : "88500" },
{ code : "88434", intitule : "ST REMIMONT", cp : "88800" },
{ code : "88435", intitule : "ST REMY", cp : "88480" },
{ code : "88436", intitule : "ST STAIL", cp : "88210" },
{ code : "88437", intitule : "ST VALLIER", cp : "88270" },
{ code : "88438", intitule : "LA SALLE", cp : "88470" },
{ code : "88439", intitule : "SANCHEY", cp : "88390" },
{ code : "88440", intitule : "SANDAUCOURT", cp : "88170" },
{ code : "88441", intitule : "SANS VALLOIS", cp : "88260" },
{ code : "88442", intitule : "SAPOIS", cp : "88120" },
{ code : "88443", intitule : "SARTES", cp : "88300" },
{ code : "88444", intitule : "LE SAULCY", cp : "88210" },
{ code : "88445", intitule : "SAULCY SUR MEURTHE", cp : "88580" },
{ code : "88446", intitule : "SAULXURES LES BULGNEVILLE", cp : "88140" },
{ code : "88447", intitule : "SAULXURES SUR MOSELOTTE", cp : "88290" },
{ code : "88448", intitule : "SAUVILLE", cp : "88140" },
{ code : "88449", intitule : "SAVIGNY", cp : "88130" },
{ code : "88450", intitule : "SENAIDE", cp : "88320" },
{ code : "88451", intitule : "SENONES", cp : "88210" },
{ code : "88452", intitule : "SENONGES", cp : "88260" },
{ code : "88453", intitule : "SERAUMONT", cp : "88630" },
{ code : "88454", intitule : "SERCOEUR", cp : "88600" },
{ code : "88455", intitule : "SERECOURT", cp : "88320" },
{ code : "88456", intitule : "SEROCOURT", cp : "88320" },
{ code : "88457", intitule : "SIONNE", cp : "88630" },
{ code : "88458", intitule : "SOCOURT", cp : "88130" },
{ code : "88459", intitule : "SONCOURT", cp : "88170" },
{ code : "88460", intitule : "SOULOSSE SOUS ST ELOPHE", cp : "88630" },
{ code : "88461", intitule : "SURIAUVILLE", cp : "88140" },
{ code : "88462", intitule : "LE SYNDICAT", cp : "88120" },
{ code : "88462", intitule : "LE SYNDICAT", cp : "88120" },
{ code : "88463", intitule : "TAINTRUX", cp : "88100" },
{ code : "88464", intitule : "TENDON", cp : "88460" },
{ code : "88465", intitule : "CAPAVENIR VOSGES", cp : "88150" },
{ code : "88465", intitule : "CAPAVENIR VOSGES", cp : "88150" },
{ code : "88465", intitule : "CAPAVENIR VOSGES", cp : "88150" },
{ code : "88466", intitule : "THEY SOUS MONTFORT", cp : "88800" },
{ code : "88467", intitule : "THIEFOSSE", cp : "88290" },
{ code : "88468", intitule : "LE THILLOT", cp : "88160" },
{ code : "88469", intitule : "THIRAUCOURT", cp : "88500" },
{ code : "88470", intitule : "LE THOLY", cp : "88530" },
{ code : "88470", intitule : "LE THOLY", cp : "88530" },
{ code : "88471", intitule : "LES THONS", cp : "88410" },
{ code : "88472", intitule : "THUILLIERES", cp : "88260" },
{ code : "88473", intitule : "TIGNECOURT", cp : "88320" },
{ code : "88474", intitule : "TILLEUX", cp : "88300" },
{ code : "88475", intitule : "TOLLAINCOURT", cp : "88320" },
{ code : "88476", intitule : "TOTAINVILLE", cp : "88500" },
{ code : "88477", intitule : "TRAMPOT", cp : "88350" },
{ code : "88478", intitule : "TRANQUEVILLE GRAUX", cp : "88300" },
{ code : "88479", intitule : "TREMONZEY", cp : "88240" },
{ code : "88480", intitule : "UBEXY", cp : "88130" },
{ code : "88481", intitule : "URIMENIL", cp : "88220" },
{ code : "88482", intitule : "URVILLE", cp : "88140" },
{ code : "88483", intitule : "UXEGNEY", cp : "88390" },
{ code : "88484", intitule : "UZEMAIN", cp : "88220" },
{ code : "88485", intitule : "LA VACHERESSE ET LA ROUILLIE", cp : "88140" },
{ code : "88486", intitule : "VAGNEY", cp : "88120" },
{ code : "88487", intitule : "LE VAL D AJOL", cp : "88340" },
{ code : "88488", intitule : "VALFROICOURT", cp : "88270" },
{ code : "88489", intitule : "VALLEROY AUX SAULES", cp : "88270" },
{ code : "88490", intitule : "VALLEROY LE SEC", cp : "88800" },
{ code : "88491", intitule : "LES VALLOIS", cp : "88260" },
{ code : "88492", intitule : "LE VALTIN", cp : "88230" },
{ code : "88493", intitule : "VARMONZEY", cp : "88450" },
{ code : "88494", intitule : "VAUBEXY", cp : "88500" },
{ code : "88495", intitule : "VAUDEVILLE", cp : "88000" },
{ code : "88496", intitule : "VAUDONCOURT", cp : "88140" },
{ code : "88497", intitule : "VAXONCOURT", cp : "88330" },
{ code : "88498", intitule : "VECOUX", cp : "88200" },
{ code : "88499", intitule : "VELOTTE ET TATIGNECOURT", cp : "88270" },
{ code : "88500", intitule : "VENTRON", cp : "88310" },
{ code : "88501", intitule : "LE VERMONT", cp : "88210" },
{ code : "88502", intitule : "VERVEZELLE", cp : "88600" },
{ code : "88503", intitule : "VEXAINCOURT", cp : "88110" },
{ code : "88504", intitule : "VICHEREY", cp : "88170" },
{ code : "88505", intitule : "VIENVILLE", cp : "88430" },
{ code : "88506", intitule : "VIEUX MOULIN", cp : "88210" },
{ code : "88507", intitule : "VILLERS", cp : "88500" },
{ code : "88508", intitule : "VILLE SUR ILLON", cp : "88270" },
{ code : "88509", intitule : "VILLONCOURT", cp : "88150" },
{ code : "88510", intitule : "VILLOTTE", cp : "88320" },
{ code : "88511", intitule : "VILLOUXEL", cp : "88350" },
{ code : "88512", intitule : "VIMENIL", cp : "88600" },
{ code : "88513", intitule : "VINCEY", cp : "88450" },
{ code : "88514", intitule : "VIOCOURT", cp : "88170" },
{ code : "88515", intitule : "VIOMENIL", cp : "88260" },
{ code : "88516", intitule : "VITTEL", cp : "88800" },
{ code : "88517", intitule : "VIVIERS LE GRAS", cp : "88260" },
{ code : "88518", intitule : "VIVIERS LES OFFROICOURT", cp : "88500" },
{ code : "88519", intitule : "LA VOIVRE", cp : "88470" },
{ code : "88520", intitule : "LES VOIVRES", cp : "88240" },
{ code : "88521", intitule : "VOMECOURT", cp : "88700" },
{ code : "88522", intitule : "VOMECOURT SUR MADON", cp : "88500" },
{ code : "88523", intitule : "VOUXEY", cp : "88170" },
{ code : "88523", intitule : "VOUXEY", cp : "88170" },
{ code : "88524", intitule : "VRECOURT", cp : "88140" },
{ code : "88525", intitule : "VROVILLE", cp : "88500" },
{ code : "88526", intitule : "WISEMBACH", cp : "88520" },
{ code : "88527", intitule : "XAFFEVILLERS", cp : "88700" },
{ code : "88528", intitule : "XAMONTARUPT", cp : "88460" },
{ code : "88529", intitule : "XARONVAL", cp : "88130" },
{ code : "88530", intitule : "XERTIGNY", cp : "88220" },
{ code : "88530", intitule : "XERTIGNY", cp : "88220" },
{ code : "88531", intitule : "XONRUPT LONGEMER", cp : "88400" },
{ code : "88532", intitule : "ZINCOURT", cp : "88330" },
{ code : "89001", intitule : "ACCOLAY", cp : "89460" },
{ code : "89002", intitule : "AIGREMONT", cp : "89800" },
{ code : "89003", intitule : "AILLANT SUR THOLON", cp : "89110" },
{ code : "89004", intitule : "AISY SUR ARMANCON", cp : "89390" },
{ code : "89005", intitule : "ANCY LE FRANC", cp : "89160" },
{ code : "89005", intitule : "ANCY LE FRANC", cp : "89160" },
{ code : "89006", intitule : "ANCY LE LIBRE", cp : "89160" },
{ code : "89007", intitule : "ANDRYES", cp : "89480" },
{ code : "89008", intitule : "ANGELY", cp : "89440" },
{ code : "89009", intitule : "ANNAY LA COTE", cp : "89200" },
{ code : "89010", intitule : "ANNAY SUR SEREIN", cp : "89310" },
{ code : "89011", intitule : "ANNEOT", cp : "89200" },
{ code : "89012", intitule : "ANNOUX", cp : "89440" },
{ code : "89013", intitule : "APPOIGNY", cp : "89380" },
{ code : "89014", intitule : "ARCES DILO", cp : "89320" },
{ code : "89014", intitule : "ARCES DILO", cp : "89320" },
{ code : "89015", intitule : "ARCY SUR CURE", cp : "89270" },
{ code : "89016", intitule : "ARGENTENAY", cp : "89160" },
{ code : "89017", intitule : "ARGENTEUIL SUR ARMANCON", cp : "89160" },
{ code : "89018", intitule : "ARMEAU", cp : "89500" },
{ code : "89019", intitule : "ARTHONNAY", cp : "89740" },
{ code : "89020", intitule : "ASNIERES SOUS BOIS", cp : "89660" },
{ code : "89021", intitule : "ASQUINS", cp : "89450" },
{ code : "89022", intitule : "ATHIE", cp : "89440" },
{ code : "89023", intitule : "AUGY", cp : "89290" },
{ code : "89024", intitule : "AUXERRE", cp : "89000" },
{ code : "89024", intitule : "AUXERRE", cp : "89290" },
{ code : "89025", intitule : "AVALLON", cp : "89200" },
{ code : "89027", intitule : "BAGNEAUX", cp : "89190" },
{ code : "89028", intitule : "BAON", cp : "89430" },
{ code : "89029", intitule : "BASSOU", cp : "89400" },
{ code : "89030", intitule : "BAZARNES", cp : "89460" },
{ code : "89031", intitule : "BEAUMONT", cp : "89250" },
{ code : "89032", intitule : "BEAUVILLIERS", cp : "89630" },
{ code : "89033", intitule : "BEAUVOIR", cp : "89240" },
{ code : "89034", intitule : "BEINE", cp : "89800" },
{ code : "89035", intitule : "BELLECHAUME", cp : "89210" },
{ code : "89036", intitule : "LA BELLIOLE", cp : "89150" },
{ code : "89037", intitule : "BEON", cp : "89410" },
{ code : "89038", intitule : "BERNOUIL", cp : "89360" },
{ code : "89039", intitule : "BERU", cp : "89700" },
{ code : "89040", intitule : "BESSY SUR CURE", cp : "89270" },
{ code : "89041", intitule : "BEUGNON", cp : "89570" },
{ code : "89042", intitule : "BIERRY LES BELLES FONTAINES", cp : "89420" },
{ code : "89043", intitule : "BLACY", cp : "89440" },
{ code : "89044", intitule : "BLANNAY", cp : "89200" },
{ code : "89045", intitule : "BLEIGNY LE CARREAU", cp : "89230" },
{ code : "89046", intitule : "BLENEAU", cp : "89220" },
{ code : "89048", intitule : "BOEURS EN OTHE", cp : "89770" },
{ code : "89049", intitule : "BOIS D ARCY", cp : "89660" },
{ code : "89050", intitule : "BONNARD", cp : "89400" },
{ code : "89051", intitule : "LES BORDES", cp : "89500" },
{ code : "89053", intitule : "BRANCHES", cp : "89113" },
{ code : "89054", intitule : "BRANNAY", cp : "89150" },
{ code : "89055", intitule : "BRIENON SUR ARMANCON", cp : "89210" },
{ code : "89055", intitule : "BRIENON SUR ARMANCON", cp : "89210" },
{ code : "89056", intitule : "BRION", cp : "89400" },
{ code : "89057", intitule : "BROSSES", cp : "89660" },
{ code : "89058", intitule : "BUSSIERES", cp : "89630" },
{ code : "89059", intitule : "BUSSY EN OTHE", cp : "89400" },
{ code : "89060", intitule : "BUSSY LE REPOS", cp : "89500" },
{ code : "89061", intitule : "BUTTEAUX", cp : "89360" },
{ code : "89062", intitule : "CARISEY", cp : "89360" },
{ code : "89063", intitule : "LA CELLE ST CYR", cp : "89116" },
{ code : "89064", intitule : "CENSY", cp : "89310" },
{ code : "89065", intitule : "CERILLY", cp : "89320" },
{ code : "89066", intitule : "CERISIERS", cp : "89320" },
{ code : "89067", intitule : "CEZY", cp : "89410" },
{ code : "89068", intitule : "CHABLIS", cp : "89800" },
{ code : "89068", intitule : "CHABLIS", cp : "89800" },
{ code : "89068", intitule : "CHABLIS", cp : "89800" },
{ code : "89068", intitule : "CHABLIS", cp : "89800" },
{ code : "89069", intitule : "CHAILLEY", cp : "89770" },
{ code : "89071", intitule : "CHAMOUX", cp : "89660" },
{ code : "89072", intitule : "CHAMPCEVRAIS", cp : "89220" },
{ code : "89073", intitule : "CHAMPIGNELLES", cp : "89350" },
{ code : "89073", intitule : "CHAMPIGNELLES", cp : "89350" },
{ code : "89074", intitule : "CHAMPIGNY", cp : "89340" },
{ code : "89075", intitule : "CHAMPLAY", cp : "89300" },
{ code : "89076", intitule : "CHAMPLOST", cp : "89210" },
{ code : "89077", intitule : "CHAMPS SUR YONNE", cp : "89290" },
{ code : "89078", intitule : "CHAMPVALLON", cp : "89710" },
{ code : "89079", intitule : "CHAMVRES", cp : "89300" },
{ code : "89080", intitule : "LA CHAPELLE SUR OREUSE", cp : "89260" },
{ code : "89081", intitule : "LA CHAPELLE VAUPELTEIGNE", cp : "89800" },
{ code : "89083", intitule : "CHARBUY", cp : "89113" },
{ code : "89084", intitule : "CHARENTENAY", cp : "89580" },
{ code : "89085", intitule : "CHARMOY", cp : "89400" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89350" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89086", intitule : "CHARNY OREE DE PUISAYE", cp : "89120" },
{ code : "89087", intitule : "CHASSIGNELLES", cp : "89160" },
{ code : "89088", intitule : "CHASSY", cp : "89110" },
{ code : "89089", intitule : "CHASTELLUX SUR CURE", cp : "89630" },
{ code : "89091", intitule : "CHATEL CENSOIR", cp : "89660" },
{ code : "89092", intitule : "CHATEL GERARD", cp : "89310" },
{ code : "89093", intitule : "CHAUMONT", cp : "89340" },
{ code : "89094", intitule : "CHAUMOT", cp : "89500" },
{ code : "89095", intitule : "CHEMILLY SUR SEREIN", cp : "89800" },
{ code : "89096", intitule : "CHEMILLY SUR YONNE", cp : "89250" },
{ code : "89098", intitule : "CHENEY", cp : "89700" },
{ code : "89099", intitule : "CHENY", cp : "89400" },
{ code : "89100", intitule : "CHEROY", cp : "89690" },
{ code : "89101", intitule : "CHEU", cp : "89600" },
{ code : "89102", intitule : "CHEVANNES", cp : "89240" },
{ code : "89104", intitule : "CHICHEE", cp : "89800" },
{ code : "89105", intitule : "CHICHERY", cp : "89400" },
{ code : "89108", intitule : "CHITRY", cp : "89530" },
{ code : "89109", intitule : "CISERY", cp : "89420" },
{ code : "89111", intitule : "LES CLERIMOIS", cp : "89190" },
{ code : "89112", intitule : "COLLAN", cp : "89700" },
{ code : "89113", intitule : "COLLEMIERS", cp : "89100" },
{ code : "89115", intitule : "COMPIGNY", cp : "89140" },
{ code : "89116", intitule : "CORNANT", cp : "89500" },
{ code : "89117", intitule : "COULANGERON", cp : "89580" },
{ code : "89118", intitule : "COULANGES LA VINEUSE", cp : "89580" },
{ code : "89119", intitule : "COULANGES SUR YONNE", cp : "89480" },
{ code : "89120", intitule : "COULOURS", cp : "89320" },
{ code : "89122", intitule : "COURGENAY", cp : "89190" },
{ code : "89123", intitule : "COURGIS", cp : "89800" },
{ code : "89124", intitule : "COURLON SUR YONNE", cp : "89140" },
{ code : "89125", intitule : "COURSON LES CARRIERES", cp : "89560" },
{ code : "89126", intitule : "COURTOIN", cp : "89150" },
{ code : "89127", intitule : "COURTOIS SUR YONNE", cp : "89100" },
{ code : "89128", intitule : "COUTARNOUX", cp : "89440" },
{ code : "89129", intitule : "CRAIN", cp : "89480" },
{ code : "89130", intitule : "CRAVANT", cp : "89460" },
{ code : "89131", intitule : "CRUZY LE CHATEL", cp : "89740" },
{ code : "89132", intitule : "CRY", cp : "89390" },
{ code : "89133", intitule : "CUDOT", cp : "89116" },
{ code : "89134", intitule : "CUSSY LES FORGES", cp : "89420" },
{ code : "89136", intitule : "CUY", cp : "89140" },
{ code : "89137", intitule : "DANNEMOINE", cp : "89700" },
{ code : "89139", intitule : "DIGES", cp : "89240" },
{ code : "89141", intitule : "DISSANGIS", cp : "89440" },
{ code : "89142", intitule : "DIXMONT", cp : "89500" },
{ code : "89143", intitule : "DOLLOT", cp : "89150" },
{ code : "89144", intitule : "DOMATS", cp : "89150" },
{ code : "89145", intitule : "DOMECY SUR CURE", cp : "89450" },
{ code : "89146", intitule : "DOMECY SUR LE VAULT", cp : "89200" },
{ code : "89147", intitule : "DRACY", cp : "89130" },
{ code : "89148", intitule : "DRUYES LES BELLES FONTAINES", cp : "89560" },
{ code : "89149", intitule : "DYE", cp : "89360" },
{ code : "89150", intitule : "EGLENY", cp : "89240" },
{ code : "89151", intitule : "EGRISELLES LE BOCAGE", cp : "89500" },
{ code : "89152", intitule : "EPINEAU LES VOVES", cp : "89400" },
{ code : "89153", intitule : "EPINEUIL", cp : "89700" },
{ code : "89154", intitule : "ESCAMPS", cp : "89240" },
{ code : "89155", intitule : "ESCOLIVES STE CAMILLE", cp : "89290" },
{ code : "89156", intitule : "ESNON", cp : "89210" },
{ code : "89158", intitule : "ETAIS LA SAUVIN", cp : "89480" },
{ code : "89159", intitule : "ETAULE", cp : "89200" },
{ code : "89160", intitule : "ETIGNY", cp : "89510" },
{ code : "89161", intitule : "ETIVEY", cp : "89310" },
{ code : "89162", intitule : "EVRY", cp : "89140" },
{ code : "89163", intitule : "LA FERTE LOUPIERE", cp : "89110" },
{ code : "89164", intitule : "FESTIGNY", cp : "89480" },
{ code : "89165", intitule : "FLACY", cp : "89190" },
{ code : "89167", intitule : "FLEURY LA VALLEE", cp : "89113" },
{ code : "89168", intitule : "FLEYS", cp : "89800" },
{ code : "89169", intitule : "FLOGNY LA CHAPELLE", cp : "89360" },
{ code : "89170", intitule : "FOISSY LES VEZELAY", cp : "89450" },
{ code : "89171", intitule : "FOISSY SUR VANNE", cp : "89190" },
{ code : "89172", intitule : "FONTAINE LA GAILLARDE", cp : "89100" },
{ code : "89173", intitule : "FONTAINES", cp : "89130" },
{ code : "89174", intitule : "FONTENAILLES", cp : "89560" },
{ code : "89175", intitule : "FONTENAY PRES CHABLIS", cp : "89800" },
{ code : "89176", intitule : "FONTENAY PRES VEZELAY", cp : "89450" },
{ code : "89177", intitule : "FONTENAY SOUS FOURONNES", cp : "89660" },
{ code : "89179", intitule : "FONTENOY", cp : "89520" },
{ code : "89180", intitule : "FOUCHERES", cp : "89150" },
{ code : "89181", intitule : "FOURNAUDIN", cp : "89320" },
{ code : "89182", intitule : "FOURONNES", cp : "89560" },
{ code : "89183", intitule : "FRESNES", cp : "89310" },
{ code : "89184", intitule : "FULVY", cp : "89160" },
{ code : "89186", intitule : "GERMIGNY", cp : "89600" },
{ code : "89187", intitule : "GIGNY", cp : "89160" },
{ code : "89188", intitule : "GIROLLES", cp : "89200" },
{ code : "89189", intitule : "GISY LES NOBLES", cp : "89140" },
{ code : "89190", intitule : "GIVRY", cp : "89200" },
{ code : "89191", intitule : "GLAND", cp : "89740" },
{ code : "89194", intitule : "GRIMAULT", cp : "89310" },
{ code : "89195", intitule : "GRON", cp : "89100" },
{ code : "89196", intitule : "VALRAVILLON", cp : "89110" },
{ code : "89196", intitule : "VALRAVILLON", cp : "89113" },
{ code : "89196", intitule : "VALRAVILLON", cp : "89113" },
{ code : "89196", intitule : "VALRAVILLON", cp : "89113" },
{ code : "89197", intitule : "GUILLON", cp : "89420" },
{ code : "89198", intitule : "GURGY", cp : "89250" },
{ code : "89199", intitule : "GY L EVEQUE", cp : "89580" },
{ code : "89200", intitule : "HAUTERIVE", cp : "89250" },
{ code : "89201", intitule : "HERY", cp : "89550" },
{ code : "89202", intitule : "IRANCY", cp : "89290" },
{ code : "89203", intitule : "ISLAND", cp : "89200" },
{ code : "89204", intitule : "L ISLE SUR SEREIN", cp : "89440" },
{ code : "89205", intitule : "JAULGES", cp : "89360" },
{ code : "89206", intitule : "JOIGNY", cp : "89300" },
{ code : "89207", intitule : "JOUANCY", cp : "89310" },
{ code : "89208", intitule : "JOUX LA VILLE", cp : "89440" },
{ code : "89209", intitule : "JOUY", cp : "89150" },
{ code : "89210", intitule : "JULLY", cp : "89160" },
{ code : "89211", intitule : "JUNAY", cp : "89700" },
{ code : "89212", intitule : "JUSSY", cp : "89290" },
{ code : "89214", intitule : "LAILLY", cp : "89190" },
{ code : "89215", intitule : "LAIN", cp : "89560" },
{ code : "89216", intitule : "LAINSECQ", cp : "89520" },
{ code : "89217", intitule : "LALANDE", cp : "89130" },
{ code : "89218", intitule : "LAROCHE ST CYDROINE", cp : "89400" },
{ code : "89219", intitule : "LASSON", cp : "89570" },
{ code : "89220", intitule : "LAVAU", cp : "89170" },
{ code : "89221", intitule : "LEUGNY", cp : "89130" },
{ code : "89222", intitule : "LEVIS", cp : "89520" },
{ code : "89223", intitule : "LEZINNES", cp : "89160" },
{ code : "89224", intitule : "LICHERES PRES AIGREMONT", cp : "89800" },
{ code : "89225", intitule : "LICHERES SUR YONNE", cp : "89660" },
{ code : "89226", intitule : "LIGNORELLES", cp : "89800" },
{ code : "89227", intitule : "LIGNY LE CHATEL", cp : "89144" },
{ code : "89228", intitule : "LINDRY", cp : "89240" },
{ code : "89229", intitule : "LIXY", cp : "89140" },
{ code : "89230", intitule : "LOOZE", cp : "89300" },
{ code : "89232", intitule : "LUCY LE BOIS", cp : "89200" },
{ code : "89233", intitule : "LUCY SUR CURE", cp : "89270" },
{ code : "89233", intitule : "LUCY SUR CURE", cp : "89270" },
{ code : "89234", intitule : "LUCY SUR YONNE", cp : "89480" },
{ code : "89235", intitule : "MAGNY", cp : "89200" },
{ code : "89236", intitule : "MAILLOT", cp : "89100" },
{ code : "89237", intitule : "MAILLY LA VILLE", cp : "89270" },
{ code : "89238", intitule : "MAILLY LE CHATEAU", cp : "89660" },
{ code : "89239", intitule : "MALAY LE GRAND", cp : "89100" },
{ code : "89240", intitule : "MALAY LE PETIT", cp : "89100" },
{ code : "89242", intitule : "MALIGNY", cp : "89800" },
{ code : "89244", intitule : "MARMEAUX", cp : "89420" },
{ code : "89245", intitule : "MARSANGY", cp : "89500" },
{ code : "89246", intitule : "MASSANGIS", cp : "89440" },
{ code : "89246", intitule : "MASSANGIS", cp : "89440" },
{ code : "89247", intitule : "MELISEY", cp : "89430" },
{ code : "89248", intitule : "MENADES", cp : "89450" },
{ code : "89249", intitule : "MERCY", cp : "89210" },
{ code : "89250", intitule : "MERE", cp : "89144" },
{ code : "89251", intitule : "MERRY LA VALLEE", cp : "89110" },
{ code : "89252", intitule : "MERRY SEC", cp : "89560" },
{ code : "89253", intitule : "MERRY SUR YONNE", cp : "89660" },
{ code : "89254", intitule : "MEZILLES", cp : "89130" },
{ code : "89255", intitule : "MICHERY", cp : "89140" },
{ code : "89256", intitule : "MIGE", cp : "89580" },
{ code : "89257", intitule : "MIGENNES", cp : "89400" },
{ code : "89259", intitule : "MOLAY", cp : "89310" },
{ code : "89260", intitule : "MOLESMES", cp : "89560" },
{ code : "89261", intitule : "MOLINONS", cp : "89190" },
{ code : "89262", intitule : "MOLOSMES", cp : "89700" },
{ code : "89263", intitule : "MONETEAU", cp : "89470" },
{ code : "89263", intitule : "MONETEAU", cp : "89470" },
{ code : "89264", intitule : "MONTACHER VILLEGARDIN", cp : "89150" },
{ code : "89265", intitule : "MONTIGNY LA RESLE", cp : "89230" },
{ code : "89266", intitule : "MONTILLOT", cp : "89660" },
{ code : "89267", intitule : "MONTREAL", cp : "89420" },
{ code : "89268", intitule : "MONT ST SULPICE", cp : "89250" },
{ code : "89270", intitule : "MOUFFY", cp : "89560" },
{ code : "89271", intitule : "MOULINS EN TONNERROIS", cp : "89310" },
{ code : "89272", intitule : "MOULINS SUR OUANNE", cp : "89130" },
{ code : "89273", intitule : "MOUTIERS EN PUISAYE", cp : "89520" },
{ code : "89274", intitule : "NAILLY", cp : "89100" },
{ code : "89276", intitule : "NEUVY SAUTOUR", cp : "89570" },
{ code : "89277", intitule : "NITRY", cp : "89310" },
{ code : "89278", intitule : "NOE", cp : "89320" },
{ code : "89279", intitule : "NOYERS", cp : "89310" },
{ code : "89280", intitule : "NUITS", cp : "89390" },
{ code : "89281", intitule : "LES ORMES", cp : "89110" },
{ code : "89282", intitule : "ORMOY", cp : "89400" },
{ code : "89283", intitule : "OUANNE", cp : "89560" },
{ code : "89283", intitule : "OUANNE", cp : "89560" },
{ code : "89284", intitule : "PACY SUR ARMANCON", cp : "89160" },
{ code : "89285", intitule : "PAILLY", cp : "89140" },
{ code : "89286", intitule : "PARLY", cp : "89240" },
{ code : "89287", intitule : "PARON", cp : "89100" },
{ code : "89288", intitule : "PAROY EN OTHE", cp : "89210" },
{ code : "89289", intitule : "PAROY SUR THOLON", cp : "89300" },
{ code : "89290", intitule : "PASILLY", cp : "89310" },
{ code : "89291", intitule : "PASSY", cp : "89510" },
{ code : "89292", intitule : "PERCEY", cp : "89360" },
{ code : "89295", intitule : "PERRIGNY", cp : "89000" },
{ code : "89296", intitule : "PERRIGNY SUR ARMANCON", cp : "89390" },
{ code : "89297", intitule : "PIERRE PERTHUIS", cp : "89450" },
{ code : "89298", intitule : "PIFFONDS", cp : "89330" },
{ code : "89299", intitule : "PIMELLES", cp : "89740" },
{ code : "89300", intitule : "PISY", cp : "89420" },
{ code : "89302", intitule : "PLESSIS ST JEAN", cp : "89140" },
{ code : "89303", intitule : "POILLY SUR SEREIN", cp : "89310" },
{ code : "89304", intitule : "POILLY SUR THOLON", cp : "89110" },
{ code : "89306", intitule : "PONTAUBERT", cp : "89200" },
{ code : "89307", intitule : "PONTIGNY", cp : "89230" },
{ code : "89308", intitule : "PONT SUR VANNE", cp : "89190" },
{ code : "89309", intitule : "PONT SUR YONNE", cp : "89140" },
{ code : "89310", intitule : "LA POSTOLLE", cp : "89260" },
{ code : "89311", intitule : "POURRAIN", cp : "89240" },
{ code : "89312", intitule : "PRECY LE SEC", cp : "89440" },
{ code : "89313", intitule : "PRECY SUR VRIN", cp : "89116" },
{ code : "89314", intitule : "PREGILBERT", cp : "89460" },
{ code : "89315", intitule : "PREHY", cp : "89800" },
{ code : "89316", intitule : "PROVENCY", cp : "89200" },
{ code : "89318", intitule : "QUARRE LES TOMBES", cp : "89630" },
{ code : "89319", intitule : "QUENNE", cp : "89290" },
{ code : "89320", intitule : "QUINCEROT", cp : "89740" },
{ code : "89321", intitule : "RAVIERES", cp : "89390" },
{ code : "89323", intitule : "ROFFEY", cp : "89700" },
{ code : "89324", intitule : "ROGNY LES SEPT ECLUSES", cp : "89220" },
{ code : "89325", intitule : "RONCHERES", cp : "89170" },
{ code : "89326", intitule : "ROSOY", cp : "89100" },
{ code : "89327", intitule : "ROUSSON", cp : "89500" },
{ code : "89328", intitule : "ROUVRAY", cp : "89230" },
{ code : "89329", intitule : "RUGNY", cp : "89430" },
{ code : "89331", intitule : "SAINPUITS", cp : "89520" },
{ code : "89332", intitule : "ST AGNAN", cp : "89340" },
{ code : "89333", intitule : "ST ANDRE EN TERRE PLAINE", cp : "89420" },
{ code : "89334", intitule : "LE VAL D OCRE", cp : "89110" },
{ code : "89334", intitule : "LE VAL D OCRE", cp : "89110" },
{ code : "89335", intitule : "ST AUBIN SUR YONNE", cp : "89300" },
{ code : "89336", intitule : "ST BRANCHER", cp : "89630" },
{ code : "89337", intitule : "ST BRIS LE VINEUX", cp : "89530" },
{ code : "89338", intitule : "ST CLEMENT", cp : "89100" },
{ code : "89339", intitule : "STE COLOMBE", cp : "89440" },
{ code : "89340", intitule : "STE COLOMBE SUR LOING", cp : "89520" },
{ code : "89341", intitule : "ST CYR LES COLONS", cp : "89800" },
{ code : "89342", intitule : "ST DENIS LES SENS", cp : "89100" },
{ code : "89344", intitule : "ST FARGEAU", cp : "89170" },
{ code : "89344", intitule : "ST FARGEAU", cp : "89170" },
{ code : "89345", intitule : "ST FLORENTIN", cp : "89600" },
{ code : "89345", intitule : "ST FLORENTIN", cp : "89600" },
{ code : "89346", intitule : "ST GEORGES SUR BAULCHE", cp : "89000" },
{ code : "89347", intitule : "ST GERMAIN DES CHAMPS", cp : "89630" },
{ code : "89348", intitule : "ST JULIEN DU SAULT", cp : "89330" },
{ code : "89349", intitule : "ST LEGER VAUBAN", cp : "89630" },
{ code : "89350", intitule : "ST LOUP D ORDON", cp : "89330" },
{ code : "89351", intitule : "STE MAGNANCE", cp : "89420" },
{ code : "89352", intitule : "ST MARTIN DES CHAMPS", cp : "89170" },
{ code : "89353", intitule : "ST MARTIN D ORDON", cp : "89330" },
{ code : "89354", intitule : "ST MARTIN DU TERTRE", cp : "89100" },
{ code : "89355", intitule : "ST MARTIN SUR ARMANCON", cp : "89700" },
{ code : "89359", intitule : "ST MAURICE AUX RICHES HOMMES", cp : "89190" },
{ code : "89360", intitule : "ST MAURICE LE VIEIL", cp : "89110" },
{ code : "89361", intitule : "ST MAURICE THIZOUAILLE", cp : "89110" },
{ code : "89362", intitule : "ST MORE", cp : "89270" },
{ code : "89363", intitule : "STE PALLAYE", cp : "89460" },
{ code : "89364", intitule : "ST PERE", cp : "89450" },
{ code : "89365", intitule : "ST PRIVE", cp : "89220" },
{ code : "89367", intitule : "SAINTS EN PUISAYE", cp : "89520" },
{ code : "89368", intitule : "ST SAUVEUR EN PUISAYE", cp : "89520" },
{ code : "89369", intitule : "ST SEROTIN", cp : "89140" },
{ code : "89370", intitule : "ST VALERIEN", cp : "89150" },
{ code : "89371", intitule : "STE VERTU", cp : "89310" },
{ code : "89373", intitule : "SALIGNY", cp : "89100" },
{ code : "89374", intitule : "SAMBOURG", cp : "89160" },
{ code : "89375", intitule : "SANTIGNY", cp : "89420" },
{ code : "89376", intitule : "SARRY", cp : "89310" },
{ code : "89377", intitule : "SAUVIGNY LE BEUREAL", cp : "89420" },
{ code : "89378", intitule : "SAUVIGNY LE BOIS", cp : "89200" },
{ code : "89379", intitule : "SAVIGNY EN TERRE PLAINE", cp : "89420" },
{ code : "89380", intitule : "SAVIGNY SUR CLAIRIS", cp : "89150" },
{ code : "89381", intitule : "SCEAUX", cp : "89420" },
{ code : "89382", intitule : "SEIGNELAY", cp : "89250" },
{ code : "89383", intitule : "SEMENTRON", cp : "89560" },
{ code : "89384", intitule : "SENAN", cp : "89710" },
{ code : "89385", intitule : "SENNEVOY LE BAS", cp : "89160" },
{ code : "89386", intitule : "SENNEVOY LE HAUT", cp : "89160" },
{ code : "89387", intitule : "SENS", cp : "89100" },
{ code : "89388", intitule : "SEPEAUX ST ROMAIN", cp : "89116" },
{ code : "89388", intitule : "SEPEAUX ST ROMAIN", cp : "89116" },
{ code : "89390", intitule : "SERBONNES", cp : "89140" },
{ code : "89391", intitule : "SERGINES", cp : "89140" },
{ code : "89392", intitule : "SERMIZELLES", cp : "89200" },
{ code : "89393", intitule : "SERRIGNY", cp : "89700" },
{ code : "89394", intitule : "SERY", cp : "89270" },
{ code : "89395", intitule : "LES SIEGES", cp : "89190" },
{ code : "89397", intitule : "SOMMECAISE", cp : "89110" },
{ code : "89398", intitule : "SORMERY", cp : "89570" },
{ code : "89399", intitule : "SOUCY", cp : "89100" },
{ code : "89400", intitule : "SOUGERES EN PUISAYE", cp : "89520" },
{ code : "89402", intitule : "SOUMAINTRAIN", cp : "89570" },
{ code : "89403", intitule : "STIGNY", cp : "89160" },
{ code : "89404", intitule : "SUBLIGNY", cp : "89100" },
{ code : "89405", intitule : "TAINGY", cp : "89560" },
{ code : "89406", intitule : "TALCY", cp : "89420" },
{ code : "89407", intitule : "TANLAY", cp : "89430" },
{ code : "89407", intitule : "TANLAY", cp : "89430" },
{ code : "89407", intitule : "TANLAY", cp : "89430" },
{ code : "89408", intitule : "TANNERRE EN PUISAYE", cp : "89350" },
{ code : "89409", intitule : "THAROISEAU", cp : "89450" },
{ code : "89410", intitule : "THAROT", cp : "89200" },
{ code : "89411", intitule : "LES VALLEES DE LA VANNE", cp : "89320" },
{ code : "89411", intitule : "LES VALLEES DE LA VANNE", cp : "89190" },
{ code : "89411", intitule : "LES VALLEES DE LA VANNE", cp : "89320" },
{ code : "89412", intitule : "THIZY", cp : "89420" },
{ code : "89413", intitule : "THOREY", cp : "89430" },
{ code : "89414", intitule : "THORIGNY SUR OREUSE", cp : "89260" },
{ code : "89414", intitule : "THORIGNY SUR OREUSE", cp : "89260" },
{ code : "89414", intitule : "THORIGNY SUR OREUSE", cp : "89260" },
{ code : "89415", intitule : "THORY", cp : "89200" },
{ code : "89416", intitule : "THURY", cp : "89520" },
{ code : "89417", intitule : "TISSEY", cp : "89700" },
{ code : "89418", intitule : "TONNERRE", cp : "89700" },
{ code : "89419", intitule : "TOUCY", cp : "89130" },
{ code : "89420", intitule : "TREIGNY", cp : "89520" },
{ code : "89420", intitule : "TREIGNY", cp : "89520" },
{ code : "89421", intitule : "TREVILLY", cp : "89420" },
{ code : "89422", intitule : "TRICHEY", cp : "89430" },
{ code : "89423", intitule : "TRONCHOY", cp : "89700" },
{ code : "89424", intitule : "TRUCY SUR YONNE", cp : "89460" },
{ code : "89425", intitule : "TURNY", cp : "89570" },
{ code : "89426", intitule : "VAL DE MERCY", cp : "89580" },
{ code : "89427", intitule : "VALLAN", cp : "89580" },
{ code : "89428", intitule : "VALLERY", cp : "89150" },
{ code : "89430", intitule : "VARENNES", cp : "89144" },
{ code : "89431", intitule : "VASSY SOUS PISY", cp : "89420" },
{ code : "89432", intitule : "VAUDEURS", cp : "89320" },
{ code : "89433", intitule : "VAULT DE LUGNY", cp : "89200" },
{ code : "89434", intitule : "VAUMORT", cp : "89320" },
{ code : "89436", intitule : "VENIZY", cp : "89210" },
{ code : "89437", intitule : "VENOUSE", cp : "89230" },
{ code : "89438", intitule : "VENOY", cp : "89290" },
{ code : "89439", intitule : "VERGIGNY", cp : "89600" },
{ code : "89439", intitule : "VERGIGNY", cp : "89600" },
{ code : "89439", intitule : "VERGIGNY", cp : "89600" },
{ code : "89440", intitule : "VERLIN", cp : "89330" },
{ code : "89441", intitule : "VERMENTON", cp : "89270" },
{ code : "89441", intitule : "VERMENTON", cp : "89270" },
{ code : "89442", intitule : "VERNOY", cp : "89150" },
{ code : "89443", intitule : "VERON", cp : "89510" },
{ code : "89445", intitule : "VEZANNES", cp : "89700" },
{ code : "89446", intitule : "VEZELAY", cp : "89450" },
{ code : "89447", intitule : "VEZINNES", cp : "89700" },
{ code : "89448", intitule : "VIGNES", cp : "89420" },
{ code : "89449", intitule : "VILLEBLEVIN", cp : "89340" },
{ code : "89450", intitule : "VILLEBOUGIS", cp : "89150" },
{ code : "89451", intitule : "VILLECHETIVE", cp : "89320" },
{ code : "89452", intitule : "VILLECIEN", cp : "89300" },
{ code : "89453", intitule : "VILLEFARGEAU", cp : "89240" },
{ code : "89456", intitule : "VILLEMANOCHE", cp : "89140" },
{ code : "89458", intitule : "VILLENAVOTTE", cp : "89140" },
{ code : "89459", intitule : "VILLENEUVE LA DONDAGRE", cp : "89150" },
{ code : "89460", intitule : "VILLENEUVE LA GUYARD", cp : "89340" },
{ code : "89461", intitule : "VILLENEUVE L ARCHEVEQUE", cp : "89190" },
{ code : "89462", intitule : "VILLENEUVE LES GENETS", cp : "89350" },
{ code : "89463", intitule : "VILLENEUVE ST SALVES", cp : "89230" },
{ code : "89464", intitule : "VILLENEUVE SUR YONNE", cp : "89500" },
{ code : "89465", intitule : "VILLEPERROT", cp : "89140" },
{ code : "89466", intitule : "VILLEROY", cp : "89100" },
{ code : "89467", intitule : "VILLETHIERRY", cp : "89140" },
{ code : "89468", intitule : "VILLEVALLIER", cp : "89330" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89469", intitule : "PERCENEIGE", cp : "89260" },
{ code : "89470", intitule : "VILLIERS LES HAUTS", cp : "89160" },
{ code : "89471", intitule : "VILLIERS LOUIS", cp : "89320" },
{ code : "89472", intitule : "VILLIERS ST BENOIT", cp : "89130" },
{ code : "89472", intitule : "VILLIERS ST BENOIT", cp : "89130" },
{ code : "89473", intitule : "VILLIERS SUR THOLON", cp : "89110" },
{ code : "89474", intitule : "VILLIERS VINEUX", cp : "89360" },
{ code : "89475", intitule : "VILLON", cp : "89740" },
{ code : "89477", intitule : "VILLY", cp : "89800" },
{ code : "89478", intitule : "VINCELLES", cp : "89290" },
{ code : "89479", intitule : "VINCELOTTES", cp : "89290" },
{ code : "89480", intitule : "VINNEUF", cp : "89140" },
{ code : "89481", intitule : "VIREAUX", cp : "89160" },
{ code : "89482", intitule : "VIVIERS", cp : "89700" },
{ code : "89483", intitule : "VOISINES", cp : "89260" },
{ code : "89484", intitule : "VOLGRE", cp : "89710" },
{ code : "89485", intitule : "VOUTENAY SUR CURE", cp : "89270" },
{ code : "89486", intitule : "YROUERRE", cp : "89700" },
{ code : "90001", intitule : "ANDELNANS", cp : "90400" },
{ code : "90001", intitule : "ANDELNANS", cp : "90400" },
{ code : "90002", intitule : "ANGEOT", cp : "90150" },
{ code : "90003", intitule : "ANJOUTEY", cp : "90170" },
{ code : "90004", intitule : "ARGIESANS", cp : "90800" },
{ code : "90005", intitule : "AUXELLES BAS", cp : "90200" },
{ code : "90006", intitule : "AUXELLES HAUT", cp : "90200" },
{ code : "90007", intitule : "BANVILLARS", cp : "90800" },
{ code : "90008", intitule : "BAVILLIERS", cp : "90800" },
{ code : "90009", intitule : "BEAUCOURT", cp : "90500" },
{ code : "90010", intitule : "BELFORT", cp : "90000" },
{ code : "90011", intitule : "BERMONT", cp : "90400" },
{ code : "90012", intitule : "BESSONCOURT", cp : "90160" },
{ code : "90013", intitule : "BETHONVILLIERS", cp : "90150" },
{ code : "90014", intitule : "BORON", cp : "90100" },
{ code : "90015", intitule : "BOTANS", cp : "90400" },
{ code : "90016", intitule : "BOURG SOUS CHATELET", cp : "90110" },
{ code : "90017", intitule : "BOUROGNE", cp : "90140" },
{ code : "90018", intitule : "BREBOTTE", cp : "90140" },
{ code : "90019", intitule : "BRETAGNE", cp : "90130" },
{ code : "90020", intitule : "BUC", cp : "90800" },
{ code : "90021", intitule : "CHARMOIS", cp : "90140" },
{ code : "90022", intitule : "CHATENOIS LES FORGES", cp : "90700" },
{ code : "90023", intitule : "CHAUX", cp : "90330" },
{ code : "90024", intitule : "CHAVANATTE", cp : "90100" },
{ code : "90025", intitule : "CHAVANNES LES GRANDS", cp : "90100" },
{ code : "90026", intitule : "CHEVREMONT", cp : "90340" },
{ code : "90027", intitule : "COURCELLES", cp : "90100" },
{ code : "90028", intitule : "COURTELEVANT", cp : "90100" },
{ code : "90029", intitule : "CRAVANCHE", cp : "90300" },
{ code : "90030", intitule : "CROIX", cp : "90100" },
{ code : "90031", intitule : "CUNELIERES", cp : "90150" },
{ code : "90032", intitule : "DANJOUTIN", cp : "90400" },
{ code : "90033", intitule : "DELLE", cp : "90100" },
{ code : "90034", intitule : "DENNEY", cp : "90160" },
{ code : "90035", intitule : "DORANS", cp : "90400" },
{ code : "90036", intitule : "EGUENIGUE", cp : "90150" },
{ code : "90037", intitule : "ELOIE", cp : "90300" },
{ code : "90039", intitule : "ESSERT", cp : "90850" },
{ code : "90041", intitule : "ETUEFFONT", cp : "90170" },
{ code : "90042", intitule : "EVETTE SALBERT", cp : "90350" },
{ code : "90043", intitule : "FAVEROIS", cp : "90100" },
{ code : "90044", intitule : "FELON", cp : "90110" },
{ code : "90045", intitule : "FECHE L EGLISE", cp : "90100" },
{ code : "90046", intitule : "FLORIMONT", cp : "90100" },
{ code : "90047", intitule : "FONTAINE", cp : "90150" },
{ code : "90048", intitule : "FONTENELLE", cp : "90340" },
{ code : "90049", intitule : "FOUSSEMAGNE", cp : "90150" },
{ code : "90050", intitule : "FRAIS", cp : "90150" },
{ code : "90051", intitule : "FROIDEFONTAINE", cp : "90140" },
{ code : "90052", intitule : "GIROMAGNY", cp : "90200" },
{ code : "90053", intitule : "GRANDVILLARS", cp : "90600" },
{ code : "90054", intitule : "GROSMAGNY", cp : "90200" },
{ code : "90055", intitule : "GROSNE", cp : "90100" },
{ code : "90056", intitule : "JONCHEREY", cp : "90100" },
{ code : "90057", intitule : "LACHAPELLE SOUS CHAUX", cp : "90300" },
{ code : "90058", intitule : "LACHAPELLE SOUS ROUGEMONT", cp : "90360" },
{ code : "90059", intitule : "LACOLLONGE", cp : "90150" },
{ code : "90060", intitule : "LAGRANGE", cp : "90150" },
{ code : "90061", intitule : "LAMADELEINE VAL DES ANGES", cp : "90170" },
{ code : "90062", intitule : "LARIVIERE", cp : "90150" },
{ code : "90063", intitule : "LEBETAIN", cp : "90100" },
{ code : "90064", intitule : "LEPUIX NEUF", cp : "90100" },
{ code : "90065", intitule : "LEPUIX", cp : "90200" },
{ code : "90066", intitule : "LEVAL", cp : "90110" },
{ code : "90067", intitule : "MENONCOURT", cp : "90150" },
{ code : "90068", intitule : "MEROUX", cp : "90400" },
{ code : "90069", intitule : "MEZIRE", cp : "90120" },
{ code : "90070", intitule : "MONTBOUTON", cp : "90500" },
{ code : "90071", intitule : "MONTREUX CHATEAU", cp : "90130" },
{ code : "90072", intitule : "MORVILLARS", cp : "90120" },
{ code : "90073", intitule : "MOVAL", cp : "90400" },
{ code : "90074", intitule : "NOVILLARD", cp : "90340" },
{ code : "90075", intitule : "OFFEMONT", cp : "90300" },
{ code : "90076", intitule : "PEROUSE", cp : "90160" },
{ code : "90077", intitule : "PETIT CROIX", cp : "90130" },
{ code : "90078", intitule : "PETITEFONTAINE", cp : "90360" },
{ code : "90079", intitule : "PETITMAGNY", cp : "90170" },
{ code : "90080", intitule : "PHAFFANS", cp : "90150" },
{ code : "90081", intitule : "RECHESY", cp : "90370" },
{ code : "90082", intitule : "AUTRECHENE", cp : "90140" },
{ code : "90083", intitule : "RECOUVRANCE", cp : "90140" },
{ code : "90084", intitule : "REPPE", cp : "90150" },
{ code : "90085", intitule : "RIERVESCEMONT", cp : "90200" },
{ code : "90086", intitule : "ROMAGNY SOUS ROUGEMONT", cp : "90110" },
{ code : "90087", intitule : "ROPPE", cp : "90380" },
{ code : "90088", intitule : "ROUGEGOUTTE", cp : "90200" },
{ code : "90089", intitule : "ROUGEMONT LE CHATEAU", cp : "90110" },
{ code : "90090", intitule : "ST DIZIER L EVEQUE", cp : "90100" },
{ code : "90091", intitule : "ST GERMAIN LE CHATELET", cp : "90110" },
{ code : "90093", intitule : "SERMAMAGNY", cp : "90300" },
{ code : "90094", intitule : "SEVENANS", cp : "90400" },
{ code : "90095", intitule : "SUARCE", cp : "90100" },
{ code : "90096", intitule : "THIANCOURT", cp : "90100" },
{ code : "90097", intitule : "TREVENANS", cp : "90400" },
{ code : "90098", intitule : "URCEREY", cp : "90800" },
{ code : "90099", intitule : "VALDOIE", cp : "90300" },
{ code : "90100", intitule : "VAUTHIERMONT", cp : "90150" },
{ code : "90101", intitule : "VELLESCOT", cp : "90100" },
{ code : "90102", intitule : "VESCEMONT", cp : "90200" },
{ code : "90103", intitule : "VETRIGNE", cp : "90300" },
{ code : "90104", intitule : "VEZELOIS", cp : "90400" },
{ code : "90105", intitule : "VILLARS LE SEC", cp : "90100" },
{ code : "91001", intitule : "ABBEVILLE LA RIVIERE", cp : "91150" },
{ code : "91016", intitule : "ANGERVILLE", cp : "91670" },
{ code : "91016", intitule : "ANGERVILLE", cp : "91670" },
{ code : "91017", intitule : "ANGERVILLIERS", cp : "91470" },
{ code : "91021", intitule : "ARPAJON", cp : "91290" },
{ code : "91022", intitule : "ARRANCOURT", cp : "91690" },
{ code : "91027", intitule : "ATHIS MONS", cp : "91200" },
{ code : "91035", intitule : "AUTHON LA PLAINE", cp : "91410" },
{ code : "91037", intitule : "AUVERNAUX", cp : "91830" },
{ code : "91038", intitule : "AUVERS ST GEORGES", cp : "91580" },
{ code : "91041", intitule : "AVRAINVILLE", cp : "91630" },
{ code : "91044", intitule : "BALLAINVILLIERS", cp : "91160" },
{ code : "91045", intitule : "BALLANCOURT SUR ESSONNE", cp : "91610" },
{ code : "91047", intitule : "BAULNE", cp : "91590" },
{ code : "91064", intitule : "BIEVRES", cp : "91570" },
{ code : "91067", intitule : "BLANDY", cp : "91150" },
{ code : "91069", intitule : "BOIGNEVILLE", cp : "91720" },
{ code : "91075", intitule : "BOIS HERPIN", cp : "91150" },
{ code : "91079", intitule : "BOISSY LA RIVIERE", cp : "91690" },
{ code : "91080", intitule : "BOISSY LE CUTTE", cp : "91590" },
{ code : "91081", intitule : "BOISSY LE SEC", cp : "91870" },
{ code : "91085", intitule : "BOISSY SOUS ST YON", cp : "91790" },
{ code : "91086", intitule : "BONDOUFLE", cp : "91070" },
{ code : "91093", intitule : "BOULLAY LES TROUX", cp : "91470" },
{ code : "91095", intitule : "BOURAY SUR JUINE", cp : "91850" },
{ code : "91097", intitule : "BOUSSY ST ANTOINE", cp : "91800" },
{ code : "91098", intitule : "BOUTERVILLIERS", cp : "91150" },
{ code : "91099", intitule : "BOUTIGNY SUR ESSONNE", cp : "91820" },
{ code : "91100", intitule : "BOUVILLE", cp : "91880" },
{ code : "91103", intitule : "BRETIGNY SUR ORGE", cp : "91220" },
{ code : "91105", intitule : "BREUILLET", cp : "91650" },
{ code : "91106", intitule : "BREUX JOUY", cp : "91650" },
{ code : "91109", intitule : "BRIERES LES SCELLES", cp : "91150" },
{ code : "91111", intitule : "BRIIS SOUS FORGES", cp : "91640" },
{ code : "91112", intitule : "BROUY", cp : "91150" },
{ code : "91114", intitule : "BRUNOY", cp : "91800" },
{ code : "91115", intitule : "BRUYERES LE CHATEL", cp : "91680" },
{ code : "91121", intitule : "BUNO BONNEVAUX", cp : "91720" },
{ code : "91122", intitule : "BURES SUR YVETTE", cp : "91440" },
{ code : "91122", intitule : "BURES SUR YVETTE", cp : "91440" },
{ code : "91129", intitule : "CERNY", cp : "91590" },
{ code : "91130", intitule : "CHALO ST MARS", cp : "91780" },
{ code : "91131", intitule : "CHALOU MOULINEUX", cp : "91740" },
{ code : "91132", intitule : "CHAMARANDE", cp : "91730" },
{ code : "91135", intitule : "CHAMPCUEIL", cp : "91750" },
{ code : "91136", intitule : "CHAMPLAN", cp : "91160" },
{ code : "91137", intitule : "CHAMPMOTTEUX", cp : "91150" },
{ code : "91145", intitule : "CHATIGNONVILLE", cp : "91410" },
{ code : "91148", intitule : "CHAUFFOUR LES ETRECHY", cp : "91580" },
{ code : "91156", intitule : "CHEPTAINVILLE", cp : "91630" },
{ code : "91159", intitule : "CHEVANNES", cp : "91750" },
{ code : "91161", intitule : "CHILLY MAZARIN", cp : "91380" },
{ code : "91174", intitule : "CORBEIL ESSONNES", cp : "91100" },
{ code : "91175", intitule : "CORBREUSE", cp : "91410" },
{ code : "91179", intitule : "LE COUDRAY MONTCEAUX", cp : "91830" },
{ code : "91179", intitule : "LE COUDRAY MONTCEAUX", cp : "91830" },
{ code : "91180", intitule : "COURANCES", cp : "91490" },
{ code : "91182", intitule : "COURCOURONNES", cp : "91080" },
{ code : "91184", intitule : "COURDIMANCHE SUR ESSONNE", cp : "91720" },
{ code : "91186", intitule : "COURSON MONTELOUP", cp : "91680" },
{ code : "91191", intitule : "CROSNE", cp : "91560" },
{ code : "91195", intitule : "DANNEMOIS", cp : "91490" },
{ code : "91198", intitule : "D HUISON LONGUEVILLE", cp : "91590" },
{ code : "91200", intitule : "DOURDAN", cp : "91410" },
{ code : "91201", intitule : "DRAVEIL", cp : "91210" },
{ code : "91201", intitule : "DRAVEIL", cp : "91210" },
{ code : "91204", intitule : "ECHARCON", cp : "91540" },
{ code : "91207", intitule : "EGLY", cp : "91520" },
{ code : "91215", intitule : "EPINAY SOUS SENART", cp : "91860" },
{ code : "91216", intitule : "EPINAY SUR ORGE", cp : "91360" },
{ code : "91222", intitule : "ESTOUCHES", cp : "91660" },
{ code : "91223", intitule : "ETAMPES", cp : "91150" },
{ code : "91225", intitule : "ETIOLLES", cp : "91450" },
{ code : "91226", intitule : "ETRECHY", cp : "91580" },
{ code : "91228", intitule : "EVRY", cp : "91000" },
{ code : "91232", intitule : "LA FERTE ALAIS", cp : "91590" },
{ code : "91235", intitule : "FLEURY MEROGIS", cp : "91700" },
{ code : "91240", intitule : "FONTAINE LA RIVIERE", cp : "91690" },
{ code : "91243", intitule : "FONTENAY LES BRIIS", cp : "91640" },
{ code : "91244", intitule : "FONTENAY LE VICOMTE", cp : "91540" },
{ code : "91247", intitule : "LA FORET LE ROI", cp : "91410" },
{ code : "91248", intitule : "LA FORET STE CROIX", cp : "91150" },
{ code : "91249", intitule : "FORGES LES BAINS", cp : "91470" },
{ code : "91272", intitule : "GIF SUR YVETTE", cp : "91190" },
{ code : "91273", intitule : "GIRONVILLE SUR ESSONNE", cp : "91720" },
{ code : "91274", intitule : "GOMETZ LA VILLE", cp : "91400" },
{ code : "91275", intitule : "GOMETZ LE CHATEL", cp : "91940" },
{ code : "91284", intitule : "LES GRANGES LE ROI", cp : "91410" },
{ code : "91286", intitule : "GRIGNY", cp : "91350" },
{ code : "91292", intitule : "GUIBEVILLE", cp : "91630" },
{ code : "91293", intitule : "GUIGNEVILLE SUR ESSONNE", cp : "91590" },
{ code : "91294", intitule : "GUILLERVAL", cp : "91690" },
{ code : "91312", intitule : "IGNY", cp : "91430" },
{ code : "91312", intitule : "IGNY", cp : "91430" },
{ code : "91315", intitule : "ITTEVILLE", cp : "91760" },
{ code : "91318", intitule : "JANVILLE SUR JUINE", cp : "91510" },
{ code : "91319", intitule : "JANVRY", cp : "91640" },
{ code : "91326", intitule : "JUVISY SUR ORGE", cp : "91260" },
{ code : "91330", intitule : "LARDY", cp : "91510" },
{ code : "91332", intitule : "LEUDEVILLE", cp : "91630" },
{ code : "91333", intitule : "LEUVILLE SUR ORGE", cp : "91310" },
{ code : "91338", intitule : "LIMOURS", cp : "91470" },
{ code : "91339", intitule : "LINAS", cp : "91310" },
{ code : "91340", intitule : "LISSES", cp : "91090" },
{ code : "91345", intitule : "LONGJUMEAU", cp : "91160" },
{ code : "91345", intitule : "LONGJUMEAU", cp : "91160" },
{ code : "91347", intitule : "LONGPONT SUR ORGE", cp : "91310" },
{ code : "91359", intitule : "MAISSE", cp : "91720" },
{ code : "91363", intitule : "MARCOUSSIS", cp : "91460" },
{ code : "91374", intitule : "MAROLLES EN BEAUCE", cp : "91150" },
{ code : "91376", intitule : "MAROLLES EN HUREPOIX", cp : "91630" },
{ code : "91377", intitule : "MASSY", cp : "91300" },
{ code : "91378", intitule : "MAUCHAMPS", cp : "91730" },
{ code : "91386", intitule : "MENNECY", cp : "91540" },
{ code : "91390", intitule : "MEREVILLE", cp : "91660" },
{ code : "91393", intitule : "MEROBERT", cp : "91780" },
{ code : "91399", intitule : "MESPUITS", cp : "91150" },
{ code : "91405", intitule : "MILLY LA FORET", cp : "91490" },
{ code : "91408", intitule : "MOIGNY SUR ECOLE", cp : "91490" },
{ code : "91411", intitule : "LES MOLIERES", cp : "91470" },
{ code : "91412", intitule : "MONDEVILLE", cp : "91590" },
{ code : "91414", intitule : "MONNERVILLE", cp : "91930" },
{ code : "91421", intitule : "MONTGERON", cp : "91230" },
{ code : "91425", intitule : "MONTLHERY", cp : "91310" },
{ code : "91432", intitule : "MORANGIS", cp : "91420" },
{ code : "91433", intitule : "MORIGNY CHAMPIGNY", cp : "91150" },
{ code : "91434", intitule : "MORSANG SUR ORGE", cp : "91390" },
{ code : "91435", intitule : "MORSANG SUR SEINE", cp : "91250" },
{ code : "91441", intitule : "NAINVILLE LES ROCHES", cp : "91750" },
{ code : "91457", intitule : "LA NORVILLE", cp : "91290" },
{ code : "91458", intitule : "NOZAY", cp : "91620" },
{ code : "91461", intitule : "OLLAINVILLE", cp : "91340" },
{ code : "91463", intitule : "ONCY SUR ECOLE", cp : "91490" },
{ code : "91468", intitule : "ORMOY", cp : "91540" },
{ code : "91469", intitule : "ORMOY LA RIVIERE", cp : "91150" },
{ code : "91471", intitule : "ORSAY", cp : "91400" },
{ code : "91471", intitule : "ORSAY", cp : "91400" },
{ code : "91473", intitule : "ORVEAU", cp : "91590" },
{ code : "91477", intitule : "PALAISEAU", cp : "91120" },
{ code : "91479", intitule : "PARAY VIEILLE POSTE", cp : "94390" },
{ code : "91479", intitule : "PARAY VIEILLE POSTE", cp : "91550" },
{ code : "91482", intitule : "PECQUEUSE", cp : "91470" },
{ code : "91494", intitule : "LE PLESSIS PATE", cp : "91220" },
{ code : "91495", intitule : "PLESSIS ST BENOIST", cp : "91410" },
{ code : "91507", intitule : "PRUNAY SUR ESSONNE", cp : "91720" },
{ code : "91508", intitule : "PUISELET LE MARAIS", cp : "91150" },
{ code : "91511", intitule : "PUSSAY", cp : "91740" },
{ code : "91514", intitule : "QUINCY SOUS SENART", cp : "91480" },
{ code : "91519", intitule : "RICHARVILLE", cp : "91410" },
{ code : "91521", intitule : "RIS ORANGIS", cp : "91130" },
{ code : "91525", intitule : "ROINVILLE", cp : "91410" },
{ code : "91526", intitule : "ROINVILLIERS", cp : "91150" },
{ code : "91533", intitule : "SACLAS", cp : "91690" },
{ code : "91534", intitule : "SACLAY", cp : "91400" },
{ code : "91534", intitule : "SACLAY", cp : "91400" },
{ code : "91538", intitule : "ST AUBIN", cp : "91190" },
{ code : "91538", intitule : "ST AUBIN", cp : "91190" },
{ code : "91540", intitule : "ST CHERON", cp : "91530" },
{ code : "91544", intitule : "ST CYR LA RIVIERE", cp : "91690" },
{ code : "91546", intitule : "ST CYR SOUS DOURDAN", cp : "91410" },
{ code : "91547", intitule : "ST ESCOBILLE", cp : "91410" },
{ code : "91549", intitule : "STE GENEVIEVE DES BOIS", cp : "91700" },
{ code : "91552", intitule : "ST GERMAIN LES ARPAJON", cp : "91180" },
{ code : "91553", intitule : "ST GERMAIN LES CORBEIL", cp : "91250" },
{ code : "91556", intitule : "ST HILAIRE", cp : "91780" },
{ code : "91560", intitule : "ST JEAN DE BEAUREGARD", cp : "91940" },
{ code : "91560", intitule : "ST JEAN DE BEAUREGARD", cp : "91940" },
{ code : "91568", intitule : "ST MAURICE MONTCOURONNE", cp : "91530" },
{ code : "91570", intitule : "ST MICHEL SUR ORGE", cp : "91240" },
{ code : "91573", intitule : "ST PIERRE DU PERRAY", cp : "91280" },
{ code : "91577", intitule : "SAINTRY SUR SEINE", cp : "91250" },
{ code : "91578", intitule : "ST SULPICE DE FAVIERES", cp : "91910" },
{ code : "91579", intitule : "ST VRAIN", cp : "91770" },
{ code : "91581", intitule : "ST YON", cp : "91650" },
{ code : "91587", intitule : "SAULX LES CHARTREUX", cp : "91160" },
{ code : "91589", intitule : "SAVIGNY SUR ORGE", cp : "91600" },
{ code : "91593", intitule : "SERMAISE", cp : "91530" },
{ code : "91599", intitule : "SOISY SUR ECOLE", cp : "91840" },
{ code : "91600", intitule : "SOISY SUR SEINE", cp : "91450" },
{ code : "91602", intitule : "SOUZY LA BRICHE", cp : "91580" },
{ code : "91613", intitule : "CONGERVILLE THIONVILLE", cp : "91740" },
{ code : "91613", intitule : "CONGERVILLE THIONVILLE", cp : "91740" },
{ code : "91617", intitule : "TIGERY", cp : "91250" },
{ code : "91619", intitule : "TORFOU", cp : "91730" },
{ code : "91629", intitule : "VALPUISEAUX", cp : "91720" },
{ code : "91630", intitule : "LE VAL ST GERMAIN", cp : "91530" },
{ code : "91631", intitule : "VARENNES JARCY", cp : "91480" },
{ code : "91634", intitule : "VAUGRIGNEUSE", cp : "91640" },
{ code : "91635", intitule : "VAUHALLAN", cp : "91430" },
{ code : "91639", intitule : "VAYRES SUR ESSONNE", cp : "91820" },
{ code : "91645", intitule : "VERRIERES LE BUISSON", cp : "91370" },
{ code : "91648", intitule : "VERT LE GRAND", cp : "91810" },
{ code : "91649", intitule : "VERT LE PETIT", cp : "91710" },
{ code : "91654", intitule : "VIDELLES", cp : "91890" },
{ code : "91657", intitule : "VIGNEUX SUR SEINE", cp : "91270" },
{ code : "91659", intitule : "VILLABE", cp : "91100" },
{ code : "91661", intitule : "VILLEBON SUR YVETTE", cp : "91140" },
{ code : "91662", intitule : "VILLECONIN", cp : "91580" },
{ code : "91665", intitule : "LA VILLE DU BOIS", cp : "91620" },
{ code : "91666", intitule : "VILLEJUST", cp : "91140" },
{ code : "91667", intitule : "VILLEMOISSON SUR ORGE", cp : "91360" },
{ code : "91671", intitule : "VILLENEUVE SUR AUVERS", cp : "91580" },
{ code : "91679", intitule : "VILLIERS LE BACLE", cp : "91190" },
{ code : "91685", intitule : "VILLIERS SUR ORGE", cp : "91700" },
{ code : "91687", intitule : "VIRY CHATILLON", cp : "91170" },
{ code : "91689", intitule : "WISSOUS", cp : "91320" },
{ code : "91691", intitule : "YERRES", cp : "91330" },
{ code : "91692", intitule : "LES ULIS", cp : "91940" },
{ code : "92002", intitule : "ANTONY", cp : "92160" },
{ code : "92004", intitule : "ASNIERES SUR SEINE", cp : "92600" },
{ code : "92007", intitule : "BAGNEUX", cp : "92220" },
{ code : "92009", intitule : "BOIS COLOMBES", cp : "92270" },
{ code : "92012", intitule : "BOULOGNE BILLANCOURT", cp : "92100" },
{ code : "92014", intitule : "BOURG LA REINE", cp : "92340" },
{ code : "92019", intitule : "CHATENAY MALABRY", cp : "92290" },
{ code : "92019", intitule : "CHATENAY MALABRY", cp : "92290" },
{ code : "92020", intitule : "CHATILLON", cp : "92320" },
{ code : "92022", intitule : "CHAVILLE", cp : "92370" },
{ code : "92023", intitule : "CLAMART", cp : "92140" },
{ code : "92023", intitule : "CLAMART", cp : "92140" },
{ code : "92024", intitule : "CLICHY", cp : "92110" },
{ code : "92025", intitule : "COLOMBES", cp : "92700" },
{ code : "92026", intitule : "COURBEVOIE", cp : "92400" },
{ code : "92032", intitule : "FONTENAY AUX ROSES", cp : "92260" },
{ code : "92033", intitule : "GARCHES", cp : "92380" },
{ code : "92035", intitule : "LA GARENNE COLOMBES", cp : "92250" },
{ code : "92036", intitule : "GENNEVILLIERS", cp : "92230" },
{ code : "92040", intitule : "ISSY LES MOULINEAUX", cp : "92130" },
{ code : "92044", intitule : "LEVALLOIS PERRET", cp : "92300" },
{ code : "92046", intitule : "MALAKOFF", cp : "92240" },
{ code : "92047", intitule : "MARNES LA COQUETTE", cp : "92430" },
{ code : "92048", intitule : "MEUDON", cp : "92190" },
{ code : "92048", intitule : "MEUDON", cp : "92360" },
{ code : "92049", intitule : "MONTROUGE", cp : "92120" },
{ code : "92050", intitule : "NANTERRE", cp : "92000" },
{ code : "92051", intitule : "NEUILLY SUR SEINE", cp : "92200" },
{ code : "92060", intitule : "LE PLESSIS ROBINSON", cp : "92350" },
{ code : "92060", intitule : "LE PLESSIS ROBINSON", cp : "92350" },
{ code : "92062", intitule : "PUTEAUX", cp : "92800" },
{ code : "92063", intitule : "RUEIL MALMAISON", cp : "92500" },
{ code : "92063", intitule : "RUEIL MALMAISON", cp : "92500" },
{ code : "92064", intitule : "ST CLOUD", cp : "92210" },
{ code : "92071", intitule : "SCEAUX", cp : "92330" },
{ code : "92072", intitule : "SEVRES", cp : "92310" },
{ code : "92073", intitule : "SURESNES", cp : "92150" },
{ code : "92075", intitule : "VANVES", cp : "92170" },
{ code : "92076", intitule : "VAUCRESSON", cp : "92420" },
{ code : "92077", intitule : "VILLE D AVRAY", cp : "92410" },
{ code : "92078", intitule : "VILLENEUVE LA GARENNE", cp : "92390" },
{ code : "93001", intitule : "AUBERVILLIERS", cp : "93300" },
{ code : "93005", intitule : "AULNAY SOUS BOIS", cp : "93600" },
{ code : "93006", intitule : "BAGNOLET", cp : "93170" },
{ code : "93007", intitule : "LE BLANC MESNIL", cp : "93150" },
{ code : "93008", intitule : "BOBIGNY", cp : "93000" },
{ code : "93010", intitule : "BONDY", cp : "93140" },
{ code : "93013", intitule : "LE BOURGET", cp : "93350" },
{ code : "93014", intitule : "CLICHY SOUS BOIS", cp : "93390" },
{ code : "93015", intitule : "COUBRON", cp : "93470" },
{ code : "93027", intitule : "LA COURNEUVE", cp : "93120" },
{ code : "93029", intitule : "DRANCY", cp : "93700" },
{ code : "93030", intitule : "DUGNY", cp : "93440" },
{ code : "93031", intitule : "EPINAY SUR SEINE", cp : "93800" },
{ code : "93032", intitule : "GAGNY", cp : "93220" },
{ code : "93033", intitule : "GOURNAY SUR MARNE", cp : "93460" },
{ code : "93039", intitule : "L ILE ST DENIS", cp : "93450" },
{ code : "93045", intitule : "LES LILAS", cp : "93260" },
{ code : "93046", intitule : "LIVRY GARGAN", cp : "93190" },
{ code : "93047", intitule : "MONTFERMEIL", cp : "93370" },
{ code : "93048", intitule : "MONTREUIL", cp : "93100" },
{ code : "93049", intitule : "NEUILLY PLAISANCE", cp : "93360" },
{ code : "93050", intitule : "NEUILLY SUR MARNE", cp : "93330" },
{ code : "93051", intitule : "NOISY LE GRAND", cp : "93160" },
{ code : "93053", intitule : "NOISY LE SEC", cp : "93130" },
{ code : "93055", intitule : "PANTIN", cp : "93500" },
{ code : "93057", intitule : "LES PAVILLONS SOUS BOIS", cp : "93320" },
{ code : "93059", intitule : "PIERREFITTE SUR SEINE", cp : "93380" },
{ code : "93061", intitule : "LE PRE ST GERVAIS", cp : "93310" },
{ code : "93062", intitule : "LE RAINCY", cp : "93340" },
{ code : "93063", intitule : "ROMAINVILLE", cp : "93230" },
{ code : "93064", intitule : "ROSNY SOUS BOIS", cp : "93110" },
{ code : "93066", intitule : "ST DENIS", cp : "93200" },
{ code : "93066", intitule : "ST DENIS", cp : "93210" },
{ code : "93070", intitule : "ST OUEN", cp : "93400" },
{ code : "93071", intitule : "SEVRAN", cp : "93270" },
{ code : "93072", intitule : "STAINS", cp : "93240" },
{ code : "93073", intitule : "TREMBLAY EN FRANCE", cp : "93290" },
{ code : "93074", intitule : "VAUJOURS", cp : "93410" },
{ code : "93077", intitule : "VILLEMOMBLE", cp : "93250" },
{ code : "93078", intitule : "VILLEPINTE", cp : "93420" },
{ code : "93079", intitule : "VILLETANEUSE", cp : "93430" },
{ code : "94001", intitule : "ABLON SUR SEINE", cp : "94480" },
{ code : "94002", intitule : "ALFORTVILLE", cp : "94140" },
{ code : "94003", intitule : "ARCUEIL", cp : "94110" },
{ code : "94004", intitule : "BOISSY ST LEGER", cp : "94470" },
{ code : "94011", intitule : "BONNEUIL SUR MARNE", cp : "94380" },
{ code : "94015", intitule : "BRY SUR MARNE", cp : "94360" },
{ code : "94016", intitule : "CACHAN", cp : "94230" },
{ code : "94017", intitule : "CHAMPIGNY SUR MARNE", cp : "94500" },
{ code : "94017", intitule : "CHAMPIGNY SUR MARNE", cp : "94500" },
{ code : "94018", intitule : "CHARENTON LE PONT", cp : "94220" },
{ code : "94019", intitule : "CHENNEVIERES SUR MARNE", cp : "94430" },
{ code : "94021", intitule : "CHEVILLY LARUE", cp : "94550" },
{ code : "94022", intitule : "CHOISY LE ROI", cp : "94600" },
{ code : "94028", intitule : "CRETEIL", cp : "94000" },
{ code : "94033", intitule : "FONTENAY SOUS BOIS", cp : "94120" },
{ code : "94034", intitule : "FRESNES", cp : "94260" },
{ code : "94037", intitule : "GENTILLY", cp : "94250" },
{ code : "94038", intitule : "L HAY LES ROSES", cp : "94240" },
{ code : "94041", intitule : "IVRY SUR SEINE", cp : "94200" },
{ code : "94042", intitule : "JOINVILLE LE PONT", cp : "94340" },
{ code : "94043", intitule : "LE KREMLIN BICETRE", cp : "94270" },
{ code : "94044", intitule : "LIMEIL BREVANNES", cp : "94450" },
{ code : "94046", intitule : "MAISONS ALFORT", cp : "94700" },
{ code : "94047", intitule : "MANDRES LES ROSES", cp : "94520" },
{ code : "94048", intitule : "MAROLLES EN BRIE", cp : "94440" },
{ code : "94052", intitule : "NOGENT SUR MARNE", cp : "94130" },
{ code : "94053", intitule : "NOISEAU", cp : "94880" },
{ code : "94054", intitule : "ORLY", cp : "94310" },
{ code : "94055", intitule : "ORMESSON SUR MARNE", cp : "94490" },
{ code : "94056", intitule : "PERIGNY", cp : "94520" },
{ code : "94058", intitule : "LE PERREUX SUR MARNE", cp : "94170" },
{ code : "94059", intitule : "LE PLESSIS TREVISE", cp : "94420" },
{ code : "94060", intitule : "LA QUEUE EN BRIE", cp : "94510" },
{ code : "94065", intitule : "RUNGIS", cp : "94150" },
{ code : "94067", intitule : "ST MANDE", cp : "94160" },
{ code : "94068", intitule : "ST MAUR DES FOSSES", cp : "94100" },
{ code : "94068", intitule : "ST MAUR DES FOSSES", cp : "94210" },
{ code : "94069", intitule : "ST MAURICE", cp : "94410" },
{ code : "94070", intitule : "SANTENY", cp : "94440" },
{ code : "94071", intitule : "SUCY EN BRIE", cp : "94370" },
{ code : "94071", intitule : "SUCY EN BRIE", cp : "94370" },
{ code : "94073", intitule : "THIAIS", cp : "94320" },
{ code : "94074", intitule : "VALENTON", cp : "94460" },
{ code : "94074", intitule : "VALENTON", cp : "94460" },
{ code : "94075", intitule : "VILLECRESNES", cp : "94440" },
{ code : "94076", intitule : "VILLEJUIF", cp : "94800" },
{ code : "94077", intitule : "VILLENEUVE LE ROI", cp : "94290" },
{ code : "94078", intitule : "VILLENEUVE ST GEORGES", cp : "94190" },
{ code : "94079", intitule : "VILLIERS SUR MARNE", cp : "94350" },
{ code : "94080", intitule : "VINCENNES", cp : "94300" },
{ code : "94081", intitule : "VITRY SUR SEINE", cp : "94400" },
{ code : "95002", intitule : "ABLEIGES", cp : "95450" },
{ code : "95008", intitule : "AINCOURT", cp : "95510" },
{ code : "95011", intitule : "AMBLEVILLE", cp : "95710" },
{ code : "95011", intitule : "AMBLEVILLE", cp : "95420" },
{ code : "95012", intitule : "AMENUCOURT", cp : "95510" },
{ code : "95014", intitule : "ANDILLY", cp : "95580" },
{ code : "95018", intitule : "ARGENTEUIL", cp : "95100" },
{ code : "95019", intitule : "ARNOUVILLE", cp : "95400" },
{ code : "95023", intitule : "ARRONVILLE", cp : "95810" },
{ code : "95024", intitule : "ARTHIES", cp : "95420" },
{ code : "95026", intitule : "ASNIERES SUR OISE", cp : "95270" },
{ code : "95028", intitule : "ATTAINVILLE", cp : "95570" },
{ code : "95039", intitule : "AUVERS SUR OISE", cp : "95430" },
{ code : "95040", intitule : "AVERNES", cp : "95450" },
{ code : "95042", intitule : "BAILLET EN FRANCE", cp : "95560" },
{ code : "95046", intitule : "BANTHELU", cp : "95420" },
{ code : "95051", intitule : "BEAUCHAMP", cp : "95250" },
{ code : "95052", intitule : "BEAUMONT SUR OISE", cp : "95260" },
{ code : "95054", intitule : "LE BELLAY EN VEXIN", cp : "95750" },
{ code : "95055", intitule : "BELLEFONTAINE", cp : "95270" },
{ code : "95056", intitule : "BELLOY EN FRANCE", cp : "95270" },
{ code : "95058", intitule : "BERNES SUR OISE", cp : "95340" },
{ code : "95059", intitule : "BERVILLE", cp : "95810" },
{ code : "95060", intitule : "BESSANCOURT", cp : "95550" },
{ code : "95061", intitule : "BETHEMONT LA FORET", cp : "95840" },
{ code : "95063", intitule : "BEZONS", cp : "95870" },
{ code : "95074", intitule : "BOISEMONT", cp : "95000" },
{ code : "95078", intitule : "BOISSY L AILLERIE", cp : "95650" },
{ code : "95088", intitule : "BONNEUIL EN FRANCE", cp : "95500" },
{ code : "95091", intitule : "BOUFFEMONT", cp : "95570" },
{ code : "95094", intitule : "BOUQUEVAL", cp : "95720" },
{ code : "95101", intitule : "BRAY ET LU", cp : "95710" },
{ code : "95102", intitule : "BREANCON", cp : "95640" },
{ code : "95110", intitule : "BRIGNANCOURT", cp : "95640" },
{ code : "95116", intitule : "BRUYERES SUR OISE", cp : "95820" },
{ code : "95119", intitule : "BUHY", cp : "95770" },
{ code : "95120", intitule : "BUTRY SUR OISE", cp : "95430" },
{ code : "95127", intitule : "CERGY", cp : "95000" },
{ code : "95127", intitule : "CERGY", cp : "95800" },
{ code : "95127", intitule : "CERGY", cp : "95000" },
{ code : "95134", intitule : "CHAMPAGNE SUR OISE", cp : "95660" },
{ code : "95139", intitule : "LA CHAPELLE EN VEXIN", cp : "95420" },
{ code : "95141", intitule : "CHARMONT", cp : "95420" },
{ code : "95142", intitule : "CHARS", cp : "95750" },
{ code : "95144", intitule : "CHATENAY EN FRANCE", cp : "95190" },
{ code : "95149", intitule : "CHAUMONTEL", cp : "95270" },
{ code : "95150", intitule : "CHAUSSY", cp : "95710" },
{ code : "95151", intitule : "CHAUVRY", cp : "95560" },
{ code : "95154", intitule : "CHENNEVIERES LES LOUVRES", cp : "95380" },
{ code : "95157", intitule : "CHERENCE", cp : "95510" },
{ code : "95166", intitule : "CLERY EN VEXIN", cp : "95420" },
{ code : "95169", intitule : "COMMENY", cp : "95450" },
{ code : "95170", intitule : "CONDECOURT", cp : "95450" },
{ code : "95176", intitule : "CORMEILLES EN PARISIS", cp : "95240" },
{ code : "95177", intitule : "CORMEILLES EN VEXIN", cp : "95830" },
{ code : "95181", intitule : "COURCELLES SUR VIOSNE", cp : "95650" },
{ code : "95183", intitule : "COURDIMANCHE", cp : "95800" },
{ code : "95197", intitule : "DEUIL LA BARRE", cp : "95170" },
{ code : "95199", intitule : "DOMONT", cp : "95330" },
{ code : "95203", intitule : "EAUBONNE", cp : "95600" },
{ code : "95205", intitule : "ECOUEN", cp : "95440" },
{ code : "95210", intitule : "ENGHIEN LES BAINS", cp : "95880" },
{ code : "95211", intitule : "ENNERY", cp : "95300" },
{ code : "95212", intitule : "EPIAIS LES LOUVRES", cp : "95380" },
{ code : "95213", intitule : "EPIAIS RHUS", cp : "95810" },
{ code : "95214", intitule : "EPINAY CHAMPLATREUX", cp : "95270" },
{ code : "95218", intitule : "ERAGNY", cp : "95610" },
{ code : "95219", intitule : "ERMONT", cp : "95120" },
{ code : "95229", intitule : "EZANVILLE", cp : "95460" },
{ code : "95241", intitule : "FONTENAY EN PARISIS", cp : "95190" },
{ code : "95250", intitule : "FOSSES", cp : "95470" },
{ code : "95252", intitule : "FRANCONVILLE", cp : "95130" },
{ code : "95253", intitule : "FREMAINVILLE", cp : "95450" },
{ code : "95254", intitule : "FREMECOURT", cp : "95830" },
{ code : "95256", intitule : "FREPILLON", cp : "95740" },
{ code : "95257", intitule : "LA FRETTE SUR SEINE", cp : "95530" },
{ code : "95258", intitule : "FROUVILLE", cp : "95690" },
{ code : "95259", intitule : "GADANCOURT", cp : "95450" },
{ code : "95268", intitule : "GARGES LES GONESSE", cp : "95140" },
{ code : "95270", intitule : "GENAINVILLE", cp : "95420" },
{ code : "95271", intitule : "GENICOURT", cp : "95650" },
{ code : "95277", intitule : "GONESSE", cp : "95500" },
{ code : "95280", intitule : "GOUSSAINVILLE", cp : "95190" },
{ code : "95282", intitule : "GOUZANGREZ", cp : "95450" },
{ code : "95287", intitule : "GRISY LES PLATRES", cp : "95810" },
{ code : "95288", intitule : "GROSLAY", cp : "95410" },
{ code : "95295", intitule : "GUIRY EN VEXIN", cp : "95450" },
{ code : "95298", intitule : "HARAVILLIERS", cp : "95640" },
{ code : "95301", intitule : "HAUTE ISLE", cp : "95780" },
{ code : "95303", intitule : "LE HEAULME", cp : "95640" },
{ code : "95304", intitule : "HEDOUVILLE", cp : "95690" },
{ code : "95306", intitule : "HERBLAY", cp : "95220" },
{ code : "95306", intitule : "HERBLAY", cp : "95220" },
{ code : "95308", intitule : "HEROUVILLE", cp : "95300" },
{ code : "95309", intitule : "HODENT", cp : "95420" },
{ code : "95313", intitule : "L ISLE ADAM", cp : "95290" },
{ code : "95316", intitule : "JAGNY SOUS BOIS", cp : "95850" },
{ code : "95323", intitule : "JOUY LE MOUTIER", cp : "95280" },
{ code : "95323", intitule : "JOUY LE MOUTIER", cp : "95280" },
{ code : "95323", intitule : "JOUY LE MOUTIER", cp : "95280" },
{ code : "95328", intitule : "LABBEVILLE", cp : "95690" },
{ code : "95331", intitule : "LASSY", cp : "95270" },
{ code : "95341", intitule : "LIVILLIERS", cp : "95300" },
{ code : "95348", intitule : "LONGUESSE", cp : "95450" },
{ code : "95351", intitule : "LOUVRES", cp : "95380" },
{ code : "95352", intitule : "LUZARCHES", cp : "95270" },
{ code : "95353", intitule : "MAFFLIERS", cp : "95560" },
{ code : "95355", intitule : "MAGNY EN VEXIN", cp : "95420" },
{ code : "95365", intitule : "MAREIL EN FRANCE", cp : "95850" },
{ code : "95369", intitule : "MARGENCY", cp : "95580" },
{ code : "95370", intitule : "MARINES", cp : "95640" },
{ code : "95371", intitule : "MARLY LA VILLE", cp : "95670" },
{ code : "95379", intitule : "MAUDETOUR EN VEXIN", cp : "95420" },
{ code : "95387", intitule : "MENOUVILLE", cp : "95810" },
{ code : "95388", intitule : "MENUCOURT", cp : "95180" },
{ code : "95392", intitule : "MERIEL", cp : "95630" },
{ code : "95394", intitule : "MERY SUR OISE", cp : "95540" },
{ code : "95395", intitule : "LE MESNIL AUBRY", cp : "95720" },
{ code : "95409", intitule : "MOISSELLES", cp : "95570" },
{ code : "95422", intitule : "MONTGEROULT", cp : "95650" },
{ code : "95424", intitule : "MONTIGNY LES CORMEILLES", cp : "95370" },
{ code : "95426", intitule : "MONTLIGNON", cp : "95680" },
{ code : "95427", intitule : "MONTMAGNY", cp : "95360" },
{ code : "95428", intitule : "MONTMORENCY", cp : "95160" },
{ code : "95429", intitule : "MONTREUIL SUR EPTE", cp : "95770" },
{ code : "95430", intitule : "MONTSOULT", cp : "95560" },
{ code : "95436", intitule : "MOURS", cp : "95260" },
{ code : "95438", intitule : "MOUSSY", cp : "95640" },
{ code : "95445", intitule : "NERVILLE LA FORET", cp : "95590" },
{ code : "95446", intitule : "NESLES LA VALLEE", cp : "95690" },
{ code : "95447", intitule : "NEUILLY EN VEXIN", cp : "95640" },
{ code : "95450", intitule : "NEUVILLE SUR OISE", cp : "95000" },
{ code : "95452", intitule : "NOINTEL", cp : "95590" },
{ code : "95456", intitule : "NOISY SUR OISE", cp : "95270" },
{ code : "95459", intitule : "NUCOURT", cp : "95420" },
{ code : "95462", intitule : "OMERVILLE", cp : "95420" },
{ code : "95476", intitule : "OSNY", cp : "95520" },
{ code : "95480", intitule : "PARMAIN", cp : "95620" },
{ code : "95483", intitule : "LE PERCHAY", cp : "95450" },
{ code : "95487", intitule : "PERSAN", cp : "95340" },
{ code : "95488", intitule : "PIERRELAYE", cp : "95220" },
{ code : "95488", intitule : "PIERRELAYE", cp : "95480" },
{ code : "95489", intitule : "PISCOP", cp : "95350" },
{ code : "95491", intitule : "LE PLESSIS BOUCHARD", cp : "95130" },
{ code : "95492", intitule : "LE PLESSIS GASSOT", cp : "95720" },
{ code : "95493", intitule : "LE PLESSIS LUZARCHES", cp : "95270" },
{ code : "95500", intitule : "PONTOISE", cp : "95300" },
{ code : "95500", intitule : "PONTOISE", cp : "95000" },
{ code : "95504", intitule : "PRESLES", cp : "95590" },
{ code : "95509", intitule : "PUISEUX EN FRANCE", cp : "95380" },
{ code : "95510", intitule : "PUISEUX PONTOISE", cp : "95650" },
{ code : "95523", intitule : "LA ROCHE GUYON", cp : "95780" },
{ code : "95527", intitule : "ROISSY EN FRANCE", cp : "95700" },
{ code : "95527", intitule : "ROISSY EN FRANCE", cp : "95700" },
{ code : "95529", intitule : "RONQUEROLLES", cp : "95340" },
{ code : "95535", intitule : "SAGY", cp : "95450" },
{ code : "95539", intitule : "ST BRICE SOUS FORET", cp : "95350" },
{ code : "95541", intitule : "ST CLAIR SUR EPTE", cp : "95770" },
{ code : "95543", intitule : "ST CYR EN ARTHIES", cp : "95510" },
{ code : "95554", intitule : "ST GERVAIS", cp : "95420" },
{ code : "95555", intitule : "ST GRATIEN", cp : "95210" },
{ code : "95563", intitule : "ST LEU LA FORET", cp : "95320" },
{ code : "95566", intitule : "ST MARTIN DU TERTRE", cp : "95270" },
{ code : "95572", intitule : "ST OUEN L AUMONE", cp : "95310" },
{ code : "95574", intitule : "ST PRIX", cp : "95390" },
{ code : "95580", intitule : "ST WITZ", cp : "95470" },
{ code : "95582", intitule : "SANNOIS", cp : "95110" },
{ code : "95584", intitule : "SANTEUIL", cp : "95640" },
{ code : "95585", intitule : "SARCELLES", cp : "95200" },
{ code : "95592", intitule : "SERAINCOURT", cp : "95450" },
{ code : "95594", intitule : "SEUGY", cp : "95270" },
{ code : "95598", intitule : "SOISY SOUS MONTMORENCY", cp : "95230" },
{ code : "95604", intitule : "SURVILLIERS", cp : "95470" },
{ code : "95607", intitule : "TAVERNY", cp : "95150" },
{ code : "95610", intitule : "THEMERICOURT", cp : "95450" },
{ code : "95611", intitule : "THEUVILLE", cp : "95810" },
{ code : "95612", intitule : "LE THILLAY", cp : "95500" },
{ code : "95625", intitule : "US", cp : "95450" },
{ code : "95627", intitule : "VALLANGOUJARD", cp : "95810" },
{ code : "95628", intitule : "VALMONDOIS", cp : "95760" },
{ code : "95633", intitule : "VAUDHERLAND", cp : "95500" },
{ code : "95637", intitule : "VAUREAL", cp : "95490" },
{ code : "95641", intitule : "VEMARS", cp : "95470" },
{ code : "95651", intitule : "VETHEUIL", cp : "95510" },
{ code : "95652", intitule : "VIARMES", cp : "95270" },
{ code : "95656", intitule : "VIENNE EN ARTHIES", cp : "95510" },
{ code : "95658", intitule : "VIGNY", cp : "95450" },
{ code : "95660", intitule : "VILLAINES SOUS BOIS", cp : "95570" },
{ code : "95675", intitule : "VILLERON", cp : "95380" },
{ code : "95676", intitule : "VILLERS EN ARTHIES", cp : "95510" },
{ code : "95678", intitule : "VILLIERS ADAM", cp : "95840" },
{ code : "95680", intitule : "VILLIERS LE BEL", cp : "95400" },
{ code : "95682", intitule : "VILLIERS LE SEC", cp : "95720" },
{ code : "95690", intitule : "WY DIT JOLI VILLAGE", cp : "95420" },
{ code : "97101", intitule : "LES ABYMES", cp : "97142" },
{ code : "97101", intitule : "LES ABYMES", cp : "97139" },
{ code : "97102", intitule : "ANSE BERTRAND", cp : "97121" },
{ code : "97103", intitule : "BAIE MAHAULT", cp : "97122" },
{ code : "97104", intitule : "BAILLIF", cp : "97123" },
{ code : "97105", intitule : "BASSE TERRE", cp : "97100" },
{ code : "97106", intitule : "BOUILLANTE", cp : "97125" },
{ code : "97106", intitule : "BOUILLANTE", cp : "97125" },
{ code : "97107", intitule : "CAPESTERRE BELLE EAU", cp : "97130" },
{ code : "97107", intitule : "CAPESTERRE BELLE EAU", cp : "97130" },
{ code : "97107", intitule : "CAPESTERRE BELLE EAU", cp : "97130" },
{ code : "97108", intitule : "CAPESTERRE DE MARIE GALANTE", cp : "97140" },
{ code : "97109", intitule : "GOURBEYRE", cp : "97113" },
{ code : "97110", intitule : "LA DESIRADE", cp : "97127" },
{ code : "97111", intitule : "DESHAIES", cp : "97126" },
{ code : "97112", intitule : "GRAND BOURG", cp : "97112" },
{ code : "97113", intitule : "LE GOSIER", cp : "97190" },
{ code : "97114", intitule : "GOYAVE", cp : "97128" },
{ code : "97115", intitule : "LAMENTIN", cp : "97129" },
{ code : "97116", intitule : "MORNE A L EAU", cp : "97111" },
{ code : "97117", intitule : "LE MOULE", cp : "97160" },
{ code : "97118", intitule : "PETIT BOURG", cp : "97170" },
{ code : "97119", intitule : "PETIT CANAL", cp : "97131" },
{ code : "97119", intitule : "PETIT CANAL", cp : "97131" },
{ code : "97120", intitule : "POINTE A PITRE", cp : "97110" },
{ code : "97121", intitule : "POINTE NOIRE", cp : "97116" },
{ code : "97122", intitule : "PORT LOUIS", cp : "97117" },
{ code : "97124", intitule : "ST CLAUDE", cp : "97120" },
{ code : "97125", intitule : "ST FRANCOIS", cp : "97118" },
{ code : "97126", intitule : "ST LOUIS", cp : "97134" },
{ code : "97128", intitule : "STE ANNE", cp : "97180" },
{ code : "97128", intitule : "STE ANNE", cp : "97180" },
{ code : "97129", intitule : "STE ROSE", cp : "97115" },
{ code : "97130", intitule : "TERRE DE BAS", cp : "97136" },
{ code : "97131", intitule : "TERRE DE HAUT", cp : "97137" },
{ code : "97132", intitule : "TROIS RIVIERES", cp : "97114" },
{ code : "97133", intitule : "VIEUX FORT", cp : "97141" },
{ code : "97134", intitule : "VIEUX HABITANTS", cp : "97119" },
{ code : "97201", intitule : "L AJOUPA BOUILLON", cp : "97216" },
{ code : "97202", intitule : "LES ANSES D ARLET", cp : "97217" },
{ code : "97203", intitule : "BASSE POINTE", cp : "97218" },
{ code : "97204", intitule : "LE CARBET", cp : "97221" },
{ code : "97205", intitule : "CASE PILOTE", cp : "97222" },
{ code : "97206", intitule : "LE DIAMANT", cp : "97223" },
{ code : "97207", intitule : "DUCOS", cp : "97224" },
{ code : "97208", intitule : "FONDS ST DENIS", cp : "97250" },
{ code : "97209", intitule : "FORT DE FRANCE", cp : "97234" },
{ code : "97209", intitule : "FORT DE FRANCE", cp : "97200" },
{ code : "97210", intitule : "LE FRANCOIS", cp : "97240" },
{ code : "97211", intitule : "GRAND RIVIERE", cp : "97218" },
{ code : "97212", intitule : "GROS MORNE", cp : "97213" },
{ code : "97213", intitule : "LE LAMENTIN", cp : "97232" },
{ code : "97214", intitule : "LE LORRAIN", cp : "97214" },
{ code : "97215", intitule : "MACOUBA", cp : "97218" },
{ code : "97216", intitule : "LE MARIGOT", cp : "97225" },
{ code : "97217", intitule : "LE MARIN", cp : "97290" },
{ code : "97218", intitule : "LE MORNE ROUGE", cp : "97260" },
{ code : "97219", intitule : "LE PRECHEUR", cp : "97250" },
{ code : "97220", intitule : "RIVIERE PILOTE", cp : "97211" },
{ code : "97221", intitule : "RIVIERE SALEE", cp : "97215" },
{ code : "97221", intitule : "RIVIERE SALEE", cp : "97215" },
{ code : "97222", intitule : "LE ROBERT", cp : "97231" },
{ code : "97222", intitule : "LE ROBERT", cp : "97231" },
{ code : "97223", intitule : "ST ESPRIT", cp : "97270" },
{ code : "97224", intitule : "ST JOSEPH", cp : "97212" },
{ code : "97225", intitule : "ST PIERRE", cp : "97250" },
{ code : "97226", intitule : "STE ANNE", cp : "97227" },
{ code : "97227", intitule : "STE LUCE", cp : "97228" },
{ code : "97228", intitule : "STE MARIE", cp : "97230" },
{ code : "97228", intitule : "STE MARIE", cp : "97230" },
{ code : "97229", intitule : "SCHOELCHER", cp : "97233" },
{ code : "97230", intitule : "LA TRINITE", cp : "97220" },
{ code : "97231", intitule : "LES TROIS ILETS", cp : "97229" },
{ code : "97232", intitule : "LE VAUCLIN", cp : "97280" },
{ code : "97233", intitule : "LE MORNE VERT", cp : "97226" },
{ code : "97234", intitule : "BELLEFONTAINE", cp : "97222" },
{ code : "97301", intitule : "REGINA", cp : "97353" },
{ code : "97301", intitule : "REGINA", cp : "97390" },
{ code : "97302", intitule : "CAYENNE", cp : "97300" },
{ code : "97303", intitule : "IRACOUBO", cp : "97350" },
{ code : "97304", intitule : "KOUROU", cp : "97310" },
{ code : "97305", intitule : "MACOURIA", cp : "97355" },
{ code : "97306", intitule : "MANA", cp : "97318" },
{ code : "97306", intitule : "MANA", cp : "97360" },
{ code : "97307", intitule : "MATOURY", cp : "97351" },
{ code : "97308", intitule : "ST GEORGES", cp : "97313" },
{ code : "97309", intitule : "REMIRE MONTJOLY", cp : "97354" },
{ code : "97310", intitule : "ROURA", cp : "97352" },
{ code : "97310", intitule : "ROURA", cp : "97311" },
{ code : "97311", intitule : "ST LAURENT DU MARONI", cp : "97320" },
{ code : "97312", intitule : "SINNAMARY", cp : "97315" },
{ code : "97313", intitule : "MONTSINERY TONNEGRANDE", cp : "97356" },
{ code : "97314", intitule : "OUANARY", cp : "97380" },
{ code : "97352", intitule : "SAUL", cp : "97314" },
{ code : "97353", intitule : "MARIPASOULA", cp : "97370" },
{ code : "97356", intitule : "CAMOPI", cp : "97330" },
{ code : "97357", intitule : "GRAND SANTI", cp : "97340" },
{ code : "97358", intitule : "ST ELIE", cp : "97312" },
{ code : "97360", intitule : "APATOU", cp : "97317" },
{ code : "97361", intitule : "AWALA YALIMAPO", cp : "97319" },
{ code : "97362", intitule : "PAPAICHTON", cp : "97316" },
{ code : "97401", intitule : "LES AVIRONS", cp : "97425" },
{ code : "97401", intitule : "LES AVIRONS", cp : "97425" },
{ code : "97402", intitule : "BRAS PANON", cp : "97412" },
{ code : "97402", intitule : "BRAS PANON", cp : "97412" },
{ code : "97403", intitule : "ENTRE DEUX", cp : "97414" },
{ code : "97404", intitule : "L ETANG SALE", cp : "97427" },
{ code : "97404", intitule : "L ETANG SALE", cp : "97427" },
{ code : "97405", intitule : "PETITE ILE", cp : "97429" },
{ code : "97405", intitule : "PETITE ILE", cp : "97429" },
{ code : "97406", intitule : "LA PLAINE DES PALMISTES", cp : "97431" },
{ code : "97407", intitule : "LE PORT", cp : "97420" },
{ code : "97407", intitule : "LE PORT", cp : "97420" },
{ code : "97407", intitule : "LE PORT", cp : "97420" },
{ code : "97408", intitule : "LA POSSESSION", cp : "97419" },
{ code : "97408", intitule : "LA POSSESSION", cp : "97419" },
{ code : "97408", intitule : "LA POSSESSION", cp : "97419" },
{ code : "97408", intitule : "LA POSSESSION", cp : "97419" },
{ code : "97409", intitule : "ST ANDRE", cp : "97440" },
{ code : "97409", intitule : "ST ANDRE", cp : "97440" },
{ code : "97409", intitule : "ST ANDRE", cp : "97440" },
{ code : "97410", intitule : "ST BENOIT", cp : "97470" },
{ code : "97410", intitule : "ST BENOIT", cp : "97437" },
{ code : "97410", intitule : "ST BENOIT", cp : "97470" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97411", intitule : "ST DENIS", cp : "97490" },
{ code : "97411", intitule : "ST DENIS", cp : "97490" },
{ code : "97411", intitule : "ST DENIS", cp : "97490" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97411", intitule : "ST DENIS", cp : "97490" },
{ code : "97411", intitule : "ST DENIS", cp : "97417" },
{ code : "97411", intitule : "ST DENIS", cp : "97417" },
{ code : "97411", intitule : "ST DENIS", cp : "97490" },
{ code : "97411", intitule : "ST DENIS", cp : "97400" },
{ code : "97412", intitule : "ST JOSEPH", cp : "97480" },
{ code : "97412", intitule : "ST JOSEPH", cp : "97480" },
{ code : "97412", intitule : "ST JOSEPH", cp : "97480" },
{ code : "97412", intitule : "ST JOSEPH", cp : "97480" },
{ code : "97413", intitule : "ST LEU", cp : "97424" },
{ code : "97413", intitule : "ST LEU", cp : "97416" },
{ code : "97413", intitule : "ST LEU", cp : "97436" },
{ code : "97413", intitule : "ST LEU", cp : "97424" },
{ code : "97414", intitule : "ST LOUIS", cp : "97450" },
{ code : "97414", intitule : "ST LOUIS", cp : "97421" },
{ code : "97414", intitule : "ST LOUIS", cp : "97421" },
{ code : "97415", intitule : "ST PAUL", cp : "97460" },
{ code : "97415", intitule : "ST PAUL", cp : "97411" },
{ code : "97415", intitule : "ST PAUL", cp : "97435" },
{ code : "97415", intitule : "ST PAUL", cp : "97460" },
{ code : "97415", intitule : "ST PAUL", cp : "97460" },
{ code : "97415", intitule : "ST PAUL", cp : "97422" },
{ code : "97415", intitule : "ST PAUL", cp : "97423" },
{ code : "97415", intitule : "ST PAUL", cp : "97435" },
{ code : "97415", intitule : "ST PAUL", cp : "97434" },
{ code : "97415", intitule : "ST PAUL", cp : "97434" },
{ code : "97415", intitule : "ST PAUL", cp : "97435" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97410" },
{ code : "97416", intitule : "ST PIERRE", cp : "97432" },
{ code : "97417", intitule : "ST PHILIPPE", cp : "97442" },
{ code : "97417", intitule : "ST PHILIPPE", cp : "97442" },
{ code : "97418", intitule : "STE MARIE", cp : "97438" },
{ code : "97418", intitule : "STE MARIE", cp : "97438" },
{ code : "97418", intitule : "STE MARIE", cp : "97438" },
{ code : "97418", intitule : "STE MARIE", cp : "97438" },
{ code : "97419", intitule : "STE ROSE", cp : "97439" },
{ code : "97419", intitule : "STE ROSE", cp : "97439" },
{ code : "97420", intitule : "STE SUZANNE", cp : "97441" },
{ code : "97421", intitule : "SALAZIE", cp : "97433" },
{ code : "97421", intitule : "SALAZIE", cp : "97433" },
{ code : "97422", intitule : "LE TAMPON", cp : "97430" },
{ code : "97422", intitule : "LE TAMPON", cp : "97418" },
{ code : "97422", intitule : "LE TAMPON", cp : "97430" },
{ code : "97422", intitule : "LE TAMPON", cp : "97430" },
{ code : "97422", intitule : "LE TAMPON", cp : "97430" },
{ code : "97422", intitule : "LE TAMPON", cp : "97418" },
{ code : "97423", intitule : "LES TROIS BASSINS", cp : "97426" },
{ code : "97424", intitule : "CILAOS", cp : "97413" },
{ code : "97424", intitule : "CILAOS", cp : "97413" },
{ code : "97501", intitule : "MIQUELON LANGLADE", cp : "97500" },
{ code : "97501", intitule : "MIQUELON LANGLADE", cp : "97500" },
{ code : "97502", intitule : "ST PIERRE", cp : "97500" },
{ code : "97601", intitule : "ACOUA", cp : "97630" },
{ code : "97602", intitule : "BANDRABOUA", cp : "97650" },
{ code : "97603", intitule : "BANDRELE", cp : "97660" },
{ code : "97604", intitule : "BOUENI", cp : "97620" },
{ code : "97605", intitule : "CHICONI", cp : "97670" },
{ code : "97606", intitule : "CHIRONGUI", cp : "97620" },
{ code : "97607", intitule : "DEMBENI", cp : "97660" },
{ code : "97608", intitule : "DZAOUDZI", cp : "97615" },
{ code : "97608", intitule : "DZAOUDZI", cp : "97615" },
{ code : "97609", intitule : "KANI KELI", cp : "97625" },
{ code : "97610", intitule : "KOUNGOU", cp : "97600" },
{ code : "97611", intitule : "MAMOUDZOU", cp : "97600" },
{ code : "97611", intitule : "MAMOUDZOU", cp : "97600" },
{ code : "97611", intitule : "MAMOUDZOU", cp : "97680" },
{ code : "97611", intitule : "MAMOUDZOU", cp : "97650" },
{ code : "97611", intitule : "MAMOUDZOU", cp : "97605" },
{ code : "97612", intitule : "MTSAMBORO", cp : "97630" },
{ code : "97613", intitule : "M TSANGAMOUJI", cp : "97650" },
{ code : "97614", intitule : "OUANGANI", cp : "97670" },
{ code : "97614", intitule : "OUANGANI", cp : "97670" },
{ code : "97615", intitule : "PAMANDZI", cp : "97615" },
{ code : "97616", intitule : "SADA", cp : "97640" },
{ code : "97617", intitule : "TSINGONI", cp : "97680" },
{ code : "97701", intitule : "ST BARTHELEMY", cp : "97133" },
{ code : "97801", intitule : "ST MARTIN", cp : "97150" },
{ code : "98611", intitule : "ALO", cp : "98610" },
{ code : "98612", intitule : "SIGAVE", cp : "98620" },
{ code : "98613", intitule : "UVEA", cp : "98600" },
{ code : "98711", intitule : "ANAA", cp : "98786" },
{ code : "98711", intitule : "ANAA", cp : "98760" },
{ code : "98711", intitule : "ANAA", cp : "98790" },
{ code : "98712", intitule : "ARUE", cp : "98701" },
{ code : "98713", intitule : "ARUTUA", cp : "98761" },
{ code : "98713", intitule : "ARUTUA", cp : "98785" },
{ code : "98713", intitule : "ARUTUA", cp : "98762" },
{ code : "98714", intitule : "BORA BORA", cp : "98730" },
{ code : "98714", intitule : "BORA BORA", cp : "98730" },
{ code : "98714", intitule : "BORA BORA", cp : "98730" },
{ code : "98714", intitule : "BORA BORA", cp : "98730" },
{ code : "98715", intitule : "FAAA", cp : "98704" },
{ code : "98716", intitule : "FAKARAVA", cp : "98790" },
{ code : "98716", intitule : "FAKARAVA", cp : "98787" },
{ code : "98716", intitule : "FAKARAVA", cp : "98764" },
{ code : "98716", intitule : "FAKARAVA", cp : "98790" },
{ code : "98716", intitule : "FAKARAVA", cp : "98790" },
{ code : "98716", intitule : "FAKARAVA", cp : "98763" },
{ code : "98717", intitule : "FANGATAU", cp : "98765" },
{ code : "98717", intitule : "FANGATAU", cp : "98766" },
{ code : "98718", intitule : "FATU HIVA", cp : "98740" },
{ code : "98718", intitule : "FATU HIVA", cp : "98740" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98793" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98755" },
{ code : "98719", intitule : "GAMBIER", cp : "98792" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98767" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98720", intitule : "HAO", cp : "98790" },
{ code : "98721", intitule : "HIKUERU", cp : "98790" },
{ code : "98721", intitule : "HIKUERU", cp : "98790" },
{ code : "98721", intitule : "HIKUERU", cp : "98768" },
{ code : "98721", intitule : "HIKUERU", cp : "98790" },
{ code : "98721", intitule : "HIKUERU", cp : "98790" },
{ code : "98722", intitule : "HITIAA O TE RA", cp : "98706" },
{ code : "98722", intitule : "HITIAA O TE RA", cp : "98708" },
{ code : "98722", intitule : "HITIAA O TE RA", cp : "98705" },
{ code : "98722", intitule : "HITIAA O TE RA", cp : "98707" },
{ code : "98723", intitule : "HIVA OA", cp : "98796" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98741" },
{ code : "98723", intitule : "HIVA OA", cp : "98749" },
{ code : "98723", intitule : "HIVA OA", cp : "98796" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98731" },
{ code : "98724", intitule : "HUAHINE", cp : "98732" },
{ code : "98725", intitule : "MAHINA", cp : "98709" },
{ code : "98725", intitule : "MAHINA", cp : "98710" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98769" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98790" },
{ code : "98726", intitule : "MAKEMO", cp : "98789" },
{ code : "98727", intitule : "MANIHI", cp : "98770" },
{ code : "98727", intitule : "MANIHI", cp : "98771" },
{ code : "98728", intitule : "MAUPITI", cp : "98732" },
{ code : "98728", intitule : "MAUPITI", cp : "98732" },
{ code : "98728", intitule : "MAUPITI", cp : "98732" },
{ code : "98728", intitule : "MAUPITI", cp : "98732" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98729" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98729", intitule : "MOOREA MAIAO", cp : "98728" },
{ code : "98730", intitule : "NAPUKA", cp : "98772" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98742" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98796" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98742" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98742" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98796" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98796" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98742" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98742" },
{ code : "98731", intitule : "NUKU HIVA", cp : "98748" },
{ code : "98732", intitule : "NUKUTAVAKE", cp : "98788" },
{ code : "98732", intitule : "NUKUTAVAKE", cp : "98773" },
{ code : "98733", intitule : "PAEA", cp : "98711" },
{ code : "98734", intitule : "PAPARA", cp : "98712" },
{ code : "98735", intitule : "PAPEETE", cp : "98714" },
{ code : "98736", intitule : "PIRAE", cp : "98716" },
{ code : "98737", intitule : "PUKAPUKA", cp : "98774" },
{ code : "98738", intitule : "PUNAAUIA", cp : "98718" },
{ code : "98738", intitule : "PUNAAUIA", cp : "98703" },
{ code : "98739", intitule : "RAIVAVAE", cp : "98750" },
{ code : "98739", intitule : "RAIVAVAE", cp : "98750" },
{ code : "98739", intitule : "RAIVAVAE", cp : "98750" },
{ code : "98739", intitule : "RAIVAVAE", cp : "98750" },
{ code : "98740", intitule : "RANGIROA", cp : "98777" },
{ code : "98740", intitule : "RANGIROA", cp : "98775" },
{ code : "98740", intitule : "RANGIROA", cp : "98776" },
{ code : "98740", intitule : "RANGIROA", cp : "98778" },
{ code : "98740", intitule : "RANGIROA", cp : "98790" },
{ code : "98741", intitule : "RAPA", cp : "98751" },
{ code : "98741", intitule : "RAPA", cp : "98794" },
{ code : "98742", intitule : "REAO", cp : "98780" },
{ code : "98742", intitule : "REAO", cp : "98779" },
{ code : "98743", intitule : "RIMATARA", cp : "98752" },
{ code : "98743", intitule : "RIMATARA", cp : "98795" },
{ code : "98743", intitule : "RIMATARA", cp : "98752" },
{ code : "98743", intitule : "RIMATARA", cp : "98752" },
{ code : "98743", intitule : "RIMATARA", cp : "98752" },
{ code : "98744", intitule : "RURUTU", cp : "98753" },
{ code : "98744", intitule : "RURUTU", cp : "98753" },
{ code : "98744", intitule : "RURUTU", cp : "98753" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98734" },
{ code : "98745", intitule : "TAHAA", cp : "98734" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98734" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98733" },
{ code : "98745", intitule : "TAHAA", cp : "98734" },
{ code : "98745", intitule : "TAHAA", cp : "98734" },
{ code : "98746", intitule : "TAHUATA", cp : "98743" },
{ code : "98746", intitule : "TAHUATA", cp : "98743" },
{ code : "98746", intitule : "TAHUATA", cp : "98743" },
{ code : "98746", intitule : "TAHUATA", cp : "98743" },
{ code : "98747", intitule : "TAIARAPU EST", cp : "98721" },
{ code : "98747", intitule : "TAIARAPU EST", cp : "98719" },
{ code : "98747", intitule : "TAIARAPU EST", cp : "98719" },
{ code : "98747", intitule : "TAIARAPU EST", cp : "98720" },
{ code : "98747", intitule : "TAIARAPU EST", cp : "98722" },
{ code : "98748", intitule : "TAIARAPU OUEST", cp : "98723" },
{ code : "98748", intitule : "TAIARAPU OUEST", cp : "98724" },
{ code : "98748", intitule : "TAIARAPU OUEST", cp : "98725" },
{ code : "98749", intitule : "TAKAROA", cp : "98790" },
{ code : "98749", intitule : "TAKAROA", cp : "98781" },
{ code : "98749", intitule : "TAKAROA", cp : "98782" },
{ code : "98750", intitule : "TAPUTAPUATEA", cp : "98735" },
{ code : "98750", intitule : "TAPUTAPUATEA", cp : "98735" },
{ code : "98750", intitule : "TAPUTAPUATEA", cp : "98735" },
{ code : "98750", intitule : "TAPUTAPUATEA", cp : "98735" },
{ code : "98751", intitule : "TATAKOTO", cp : "98783" },
{ code : "98752", intitule : "TEVA I UTA", cp : "98726" },
{ code : "98752", intitule : "TEVA I UTA", cp : "98727" },
{ code : "98753", intitule : "TUBUAI", cp : "98754" },
{ code : "98753", intitule : "TUBUAI", cp : "98754" },
{ code : "98753", intitule : "TUBUAI", cp : "98754" },
{ code : "98754", intitule : "TUMARAA", cp : "98735" },
{ code : "98754", intitule : "TUMARAA", cp : "98735" },
{ code : "98754", intitule : "TUMARAA", cp : "98735" },
{ code : "98754", intitule : "TUMARAA", cp : "98735" },
{ code : "98755", intitule : "TUREIA", cp : "98784" },
{ code : "98756", intitule : "UA HUKA", cp : "98744" },
{ code : "98756", intitule : "UA HUKA", cp : "98747" },
{ code : "98757", intitule : "UA POU", cp : "98745" },
{ code : "98757", intitule : "UA POU", cp : "98745" },
{ code : "98757", intitule : "UA POU", cp : "98746" },
{ code : "98757", intitule : "UA POU", cp : "98745" },
{ code : "98757", intitule : "UA POU", cp : "98745" },
{ code : "98757", intitule : "UA POU", cp : "98746" },
{ code : "98758", intitule : "UTUROA", cp : "98735" },
{ code : "98801", intitule : "BELEP", cp : "98811" },
{ code : "98802", intitule : "BOULOUPARI", cp : "98812" },
{ code : "98803", intitule : "BOURAIL", cp : "98870" },
{ code : "98804", intitule : "CANALA", cp : "98813" },
{ code : "98805", intitule : "DUMBEA", cp : "98839" },
{ code : "98805", intitule : "DUMBEA", cp : "98836" },
{ code : "98805", intitule : "DUMBEA", cp : "98830" },
{ code : "98805", intitule : "DUMBEA", cp : "98835" },
{ code : "98805", intitule : "DUMBEA", cp : "98837" },
{ code : "98806", intitule : "FARINO", cp : "98881" },
{ code : "98807", intitule : "HIENGHENE", cp : "98815" },
{ code : "98808", intitule : "HOUAILOU", cp : "98816" },
{ code : "98808", intitule : "HOUAILOU", cp : "98838" },
{ code : "98809", intitule : "L ILE DES PINS", cp : "98832" },
{ code : "98810", intitule : "KAALA GOMEN", cp : "98817" },
{ code : "98811", intitule : "KONE", cp : "98860" },
{ code : "98811", intitule : "KONE", cp : "98859" },
{ code : "98812", intitule : "KOUMAC", cp : "98850" },
{ code : "98813", intitule : "LA FOA", cp : "98880" },
{ code : "98814", intitule : "LIFOU", cp : "98820" },
{ code : "98814", intitule : "LIFOU", cp : "98884" },
{ code : "98814", intitule : "LIFOU", cp : "98885" },
{ code : "98815", intitule : "MARE", cp : "98828" },
{ code : "98815", intitule : "MARE", cp : "98878" },
{ code : "98816", intitule : "MOINDOU", cp : "98819" },
{ code : "98817", intitule : "LE MONT DORE", cp : "98810" },
{ code : "98817", intitule : "LE MONT DORE", cp : "98809" },
{ code : "98817", intitule : "LE MONT DORE", cp : "98874" },
{ code : "98817", intitule : "LE MONT DORE", cp : "98875" },
{ code : "98817", intitule : "LE MONT DORE", cp : "98876" },
{ code : "98818", intitule : "NOUMEA", cp : "98800" },
{ code : "98819", intitule : "OUEGOA", cp : "98821" },
{ code : "98820", intitule : "OUVEA", cp : "98814" },
{ code : "98821", intitule : "PAITA", cp : "98890" },
{ code : "98821", intitule : "PAITA", cp : "98840" },
{ code : "98821", intitule : "PAITA", cp : "98889" },
{ code : "98822", intitule : "POINDIMIE", cp : "98822" },
{ code : "98823", intitule : "PONERIHOUEN", cp : "98823" },
{ code : "98824", intitule : "POUEBO", cp : "98824" },
{ code : "98825", intitule : "POUEMBOUT", cp : "98825" },
{ code : "98826", intitule : "POUM", cp : "98826" },
{ code : "98827", intitule : "POYA", cp : "98877" },
{ code : "98827", intitule : "POYA", cp : "98827" },
{ code : "98828", intitule : "SARRAMEA", cp : "98882" },
{ code : "98829", intitule : "THIO", cp : "98829" },
{ code : "98830", intitule : "TOUHO", cp : "98831" },
{ code : "98831", intitule : "VOH", cp : "98883" },
{ code : "98831", intitule : "VOH", cp : "98833" },
{ code : "98832", intitule : "YATE", cp : "98834" },
{ code : "98833", intitule : "KOUAOUA", cp : "98818" },
{ code : "98901", intitule : "ILE DE CLIPPERTON", cp : "98799" },
{ code : "99138", intitule : "MONACO", cp : "98000" },
{ code : "2A001", intitule : "AFA", cp : "20167" },
{ code : "2A004", intitule : "AJACCIO", cp : "20167" },
{ code : "2A004", intitule : "AJACCIO", cp : "20090" },
{ code : "2A004", intitule : "AJACCIO", cp : "20000" },
{ code : "2A006", intitule : "ALATA", cp : "20167" },
{ code : "2A008", intitule : "ALBITRECCIA", cp : "20166" },
{ code : "2A008", intitule : "ALBITRECCIA", cp : "20128" },
{ code : "2A011", intitule : "ALTAGENE", cp : "20112" },
{ code : "2A014", intitule : "AMBIEGNA", cp : "20151" },
{ code : "2A017", intitule : "APPIETTO", cp : "20167" },
{ code : "2A018", intitule : "ARBELLARA", cp : "20110" },
{ code : "2A019", intitule : "ARBORI", cp : "20160" },
{ code : "2A021", intitule : "ARGIUSTA MORICCIO", cp : "20140" },
{ code : "2A022", intitule : "ARRO", cp : "20151" },
{ code : "2A024", intitule : "AULLENE", cp : "20116" },
{ code : "2A026", intitule : "AZILONE AMPAZA", cp : "20190" },
{ code : "2A027", intitule : "AZZANA", cp : "20121" },
{ code : "2A028", intitule : "BALOGNA", cp : "20160" },
{ code : "2A031", intitule : "BASTELICA", cp : "20119" },
{ code : "2A032", intitule : "BASTELICACCIA", cp : "20129" },
{ code : "2A035", intitule : "BELVEDERE CAMPOMORO", cp : "20110" },
{ code : "2A038", intitule : "BILIA", cp : "20100" },
{ code : "2A040", intitule : "BOCOGNANO", cp : "20136" },
{ code : "2A041", intitule : "BONIFACIO", cp : "20169" },
{ code : "2A048", intitule : "CALCATOGGIO", cp : "20111" },
{ code : "2A056", intitule : "CAMPO", cp : "20142" },
{ code : "2A060", intitule : "CANNELLE", cp : "20151" },
{ code : "2A061", intitule : "CARBINI", cp : "20170" },
{ code : "2A062", intitule : "CARBUCCIA", cp : "20133" },
{ code : "2A064", intitule : "CARDO TORGIA", cp : "20190" },
{ code : "2A065", intitule : "CARGESE", cp : "20130" },
{ code : "2A066", intitule : "CARGIACA", cp : "20164" },
{ code : "2A070", intitule : "CASAGLIONE", cp : "20111" },
{ code : "2A071", intitule : "CASALABRIVA", cp : "20140" },
{ code : "2A085", intitule : "CAURO", cp : "20117" },
{ code : "2A089", intitule : "CIAMANNACCE", cp : "20134" },
{ code : "2A090", intitule : "COGGIA", cp : "20160" },
{ code : "2A090", intitule : "COGGIA", cp : "20118" },
{ code : "2A091", intitule : "COGNOCOLI MONTICCHI", cp : "20166" },
{ code : "2A091", intitule : "COGNOCOLI MONTICCHI", cp : "20123" },
{ code : "2A092", intitule : "CONCA", cp : "20135" },
{ code : "2A094", intitule : "CORRANO", cp : "20168" },
{ code : "2A098", intitule : "COTI CHIAVARI", cp : "20138" },
{ code : "2A099", intitule : "COZZANO", cp : "20148" },
{ code : "2A100", intitule : "CRISTINACCE", cp : "20126" },
{ code : "2A103", intitule : "CUTTOLI CORTICCHIATO", cp : "20167" },
{ code : "2A104", intitule : "ECCICA SUARELLA", cp : "20117" },
{ code : "2A108", intitule : "EVISA", cp : "20126" },
{ code : "2A114", intitule : "FIGARI", cp : "20114" },
{ code : "2A115", intitule : "FOCE", cp : "20100" },
{ code : "2A117", intitule : "FORCIOLO", cp : "20190" },
{ code : "2A118", intitule : "FOZZANO", cp : "20143" },
{ code : "2A119", intitule : "FRASSETO", cp : "20157" },
{ code : "2A127", intitule : "GIUNCHETO", cp : "20100" },
{ code : "2A128", intitule : "GRANACE", cp : "20100" },
{ code : "2A129", intitule : "GROSSA", cp : "20100" },
{ code : "2A130", intitule : "GROSSETO PRUGNA", cp : "20166" },
{ code : "2A130", intitule : "GROSSETO PRUGNA", cp : "20128" },
{ code : "2A131", intitule : "GUAGNO", cp : "20160" },
{ code : "2A132", intitule : "GUARGUALE", cp : "20128" },
{ code : "2A133", intitule : "GUITERA LES BAINS", cp : "20153" },
{ code : "2A139", intitule : "LECCI", cp : "20137" },
{ code : "2A141", intitule : "LETIA", cp : "20160" },
{ code : "2A142", intitule : "LEVIE", cp : "20170" },
{ code : "2A144", intitule : "LOPIGNA", cp : "20139" },
{ code : "2A146", intitule : "LORETO DI TALLANO", cp : "20165" },
{ code : "2A154", intitule : "MARIGNANA", cp : "20141" },
{ code : "2A158", intitule : "MELA", cp : "20112" },
{ code : "2A160", intitule : "MOCA CROCE", cp : "20140" },
{ code : "2A163", intitule : "MONACIA D AULLENE", cp : "20171" },
{ code : "2A174", intitule : "MURZO", cp : "20160" },
{ code : "2A181", intitule : "OCANA", cp : "20117" },
{ code : "2A186", intitule : "OLIVESE", cp : "20140" },
{ code : "2A189", intitule : "OLMETO", cp : "20113" },
{ code : "2A191", intitule : "OLMICCIA", cp : "20112" },
{ code : "2A196", intitule : "ORTO", cp : "20125" },
{ code : "2A197", intitule : "OSANI", cp : "20147" },
{ code : "2A198", intitule : "OTA", cp : "20150" },
{ code : "2A198", intitule : "OTA", cp : "20150" },
{ code : "2A200", intitule : "PALNECA", cp : "20134" },
{ code : "2A203", intitule : "PARTINELLO", cp : "20147" },
{ code : "2A204", intitule : "PASTRICCIOLA", cp : "20121" },
{ code : "2A209", intitule : "PERI", cp : "20167" },
{ code : "2A211", intitule : "PETRETO BICCHISANO", cp : "20140" },
{ code : "2A212", intitule : "PIANA", cp : "20115" },
{ code : "2A215", intitule : "PIANOTTOLI CALDARELLO", cp : "20131" },
{ code : "2A228", intitule : "PIETROSELLA", cp : "20166" },
{ code : "2A232", intitule : "PILA CANALE", cp : "20123" },
{ code : "2A240", intitule : "POGGIOLO", cp : "20125" },
{ code : "2A240", intitule : "POGGIOLO", cp : "20160" },
{ code : "2A247", intitule : "PORTO VECCHIO", cp : "20137" },
{ code : "2A249", intitule : "PROPRIANO", cp : "20110" },
{ code : "2A253", intitule : "QUASQUARA", cp : "20142" },
{ code : "2A254", intitule : "QUENZA", cp : "20122" },
{ code : "2A258", intitule : "RENNO", cp : "20160" },
{ code : "2A259", intitule : "REZZA", cp : "20121" },
{ code : "2A262", intitule : "ROSAZIA", cp : "20121" },
{ code : "2A266", intitule : "SALICE", cp : "20121" },
{ code : "2A268", intitule : "SAMPOLO", cp : "20134" },
{ code : "2A269", intitule : "SARI SOLENZARA", cp : "20145" },
{ code : "2A269", intitule : "SARI SOLENZARA", cp : "20145" },
{ code : "2A270", intitule : "SARI D ORCINO", cp : "20151" },
{ code : "2A271", intitule : "SARROLA CARCOPINO", cp : "20167" },
{ code : "2A272", intitule : "SARTENE", cp : "20100" },
{ code : "2A276", intitule : "SERRA DI FERRO", cp : "20140" },
{ code : "2A276", intitule : "SERRA DI FERRO", cp : "20140" },
{ code : "2A278", intitule : "SERRA DI SCOPAMENE", cp : "20127" },
{ code : "2A279", intitule : "SERRIERA", cp : "20147" },
{ code : "2A282", intitule : "SOCCIA", cp : "20125" },
{ code : "2A284", intitule : "SOLLACARO", cp : "20140" },
{ code : "2A285", intitule : "SORBOLLANO", cp : "20152" },
{ code : "2A288", intitule : "SOTTA", cp : "20146" },
{ code : "2A295", intitule : "SANT ANDREA D ORCINO", cp : "20151" },
{ code : "2A300", intitule : "SAN GAVINO DI CARBINI", cp : "20170" },
{ code : "2A308", intitule : "STE LUCIE DE TALLANO", cp : "20112" },
{ code : "2A310", intitule : "SANTA MARIA FIGANIELLA", cp : "20143" },
{ code : "2A312", intitule : "SANTA MARIA SICHE", cp : "20190" },
{ code : "2A322", intitule : "TASSO", cp : "20134" },
{ code : "2A323", intitule : "TAVACO", cp : "20167" },
{ code : "2A324", intitule : "TAVERA", cp : "20163" },
{ code : "2A326", intitule : "TOLLA", cp : "20117" },
{ code : "2A330", intitule : "UCCIANI", cp : "20133" },
{ code : "2A331", intitule : "URBALACONE", cp : "20128" },
{ code : "2A336", intitule : "VALLE DI MEZZANA", cp : "20167" },
{ code : "2A345", intitule : "VERO", cp : "20172" },
{ code : "2A348", intitule : "VICO", cp : "20160" },
{ code : "2A348", intitule : "VICO", cp : "20118" },
{ code : "2A349", intitule : "VIGGIANELLO", cp : "20110" },
{ code : "2A351", intitule : "VILLANOVA", cp : "20167" },
{ code : "2A357", intitule : "ZERUBIA", cp : "20116" },
{ code : "2A358", intitule : "ZEVACO", cp : "20173" },
{ code : "2A359", intitule : "ZICAVO", cp : "20132" },
{ code : "2A360", intitule : "ZIGLIARA", cp : "20190" },
{ code : "2A362", intitule : "ZONZA", cp : "20124" },
{ code : "2A362", intitule : "ZONZA", cp : "20144" },
{ code : "2A363", intitule : "ZOZA", cp : "20112" },
{ code : "2B002", intitule : "AGHIONE", cp : "20270" },
{ code : "2B003", intitule : "AITI", cp : "20244" },
{ code : "2B005", intitule : "ALANDO", cp : "20212" },
{ code : "2B007", intitule : "ALBERTACCE", cp : "20224" },
{ code : "2B007", intitule : "ALBERTACCE", cp : "20224" },
{ code : "2B009", intitule : "ALERIA", cp : "20270" },
{ code : "2B009", intitule : "ALERIA", cp : "20270" },
{ code : "2B010", intitule : "ALGAJOLA", cp : "20220" },
{ code : "2B012", intitule : "ALTIANI", cp : "20251" },
{ code : "2B013", intitule : "ALZI", cp : "20212" },
{ code : "2B015", intitule : "AMPRIANI", cp : "20272" },
{ code : "2B016", intitule : "ANTISANTI", cp : "20270" },
{ code : "2B020", intitule : "AREGNO", cp : "20220" },
{ code : "2B023", intitule : "ASCO", cp : "20276" },
{ code : "2B025", intitule : "AVAPESSA", cp : "20225" },
{ code : "2B029", intitule : "BARBAGGIO", cp : "20253" },
{ code : "2B030", intitule : "BARRETTALI", cp : "20228" },
{ code : "2B033", intitule : "BASTIA", cp : "20200" },
{ code : "2B033", intitule : "BASTIA", cp : "20200" },
{ code : "2B033", intitule : "BASTIA", cp : "20600" },
{ code : "2B034", intitule : "BELGODERE", cp : "20226" },
{ code : "2B034", intitule : "BELGODERE", cp : "20226" },
{ code : "2B036", intitule : "BIGORNO", cp : "20252" },
{ code : "2B037", intitule : "BIGUGLIA", cp : "20620" },
{ code : "2B037", intitule : "BIGUGLIA", cp : "20620" },
{ code : "2B039", intitule : "BISINCHI", cp : "20235" },
{ code : "2B042", intitule : "BORGO", cp : "20290" },
{ code : "2B042", intitule : "BORGO", cp : "20290" },
{ code : "2B043", intitule : "BRANDO", cp : "20222" },
{ code : "2B043", intitule : "BRANDO", cp : "20222" },
{ code : "2B043", intitule : "BRANDO", cp : "20222" },
{ code : "2B045", intitule : "BUSTANICO", cp : "20212" },
{ code : "2B046", intitule : "CAGNANO", cp : "20228" },
{ code : "2B047", intitule : "CALACUCCIA", cp : "20224" },
{ code : "2B049", intitule : "CALENZANA", cp : "20260" },
{ code : "2B049", intitule : "CALENZANA", cp : "20214" },
{ code : "2B049", intitule : "CALENZANA", cp : "20214" },
{ code : "2B050", intitule : "CALVI", cp : "20260" },
{ code : "2B051", intitule : "CAMBIA", cp : "20244" },
{ code : "2B052", intitule : "CAMPANA", cp : "20229" },
{ code : "2B053", intitule : "CAMPI", cp : "20270" },
{ code : "2B054", intitule : "CAMPILE", cp : "20290" },
{ code : "2B055", intitule : "CAMPITELLO", cp : "20252" },
{ code : "2B057", intitule : "CANALE DI VERDE", cp : "20230" },
{ code : "2B058", intitule : "CANARI", cp : "20217" },
{ code : "2B059", intitule : "CANAVAGGIA", cp : "20235" },
{ code : "2B063", intitule : "CARCHETO BRUSTICO", cp : "20229" },
{ code : "2B067", intitule : "CARPINETO", cp : "20229" },
{ code : "2B068", intitule : "CARTICASI", cp : "20244" },
{ code : "2B069", intitule : "CASABIANCA", cp : "20237" },
{ code : "2B072", intitule : "CASALTA", cp : "20215" },
{ code : "2B073", intitule : "CASAMACCIOLI", cp : "20224" },
{ code : "2B074", intitule : "CASANOVA", cp : "20250" },
{ code : "2B075", intitule : "CASEVECCHIE", cp : "20270" },
{ code : "2B077", intitule : "CASTELLARE DI CASINCA", cp : "20213" },
{ code : "2B078", intitule : "CASTELLARE DI MERCURIO", cp : "20212" },
{ code : "2B079", intitule : "CASTELLO DI ROSTINO", cp : "20235" },
{ code : "2B080", intitule : "CASTIFAO", cp : "20218" },
{ code : "2B081", intitule : "CASTIGLIONE", cp : "20218" },
{ code : "2B082", intitule : "CASTINETA", cp : "20218" },
{ code : "2B083", intitule : "CASTIRLA", cp : "20236" },
{ code : "2B084", intitule : "CATERI", cp : "20225" },
{ code : "2B086", intitule : "CENTURI", cp : "20238" },
{ code : "2B087", intitule : "CERVIONE", cp : "20221" },
{ code : "2B087", intitule : "CERVIONE", cp : "20221" },
{ code : "2B088", intitule : "CHIATRA", cp : "20230" },
{ code : "2B093", intitule : "CORBARA", cp : "20220" },
{ code : "2B093", intitule : "CORBARA", cp : "20256" },
{ code : "2B095", intitule : "CORSCIA", cp : "20224" },
{ code : "2B096", intitule : "CORTE", cp : "20250" },
{ code : "2B097", intitule : "COSTA", cp : "20226" },
{ code : "2B101", intitule : "CROCE", cp : "20237" },
{ code : "2B102", intitule : "CROCICCHIA", cp : "20290" },
{ code : "2B105", intitule : "ERBAJOLO", cp : "20212" },
{ code : "2B106", intitule : "ERONE", cp : "20244" },
{ code : "2B107", intitule : "ERSA", cp : "20275" },
{ code : "2B109", intitule : "FARINOLE", cp : "20253" },
{ code : "2B110", intitule : "FAVALELLO", cp : "20212" },
{ code : "2B111", intitule : "FELCE", cp : "20234" },
{ code : "2B112", intitule : "FELICETO", cp : "20225" },
{ code : "2B113", intitule : "FICAJA", cp : "20237" },
{ code : "2B116", intitule : "FOCICCHIA", cp : "20212" },
{ code : "2B120", intitule : "FURIANI", cp : "20600" },
{ code : "2B121", intitule : "GALERIA", cp : "20245" },
{ code : "2B122", intitule : "GAVIGNANO", cp : "20218" },
{ code : "2B123", intitule : "GHISONACCIA", cp : "20240" },
{ code : "2B123", intitule : "GHISONACCIA", cp : "20240" },
{ code : "2B123", intitule : "GHISONACCIA", cp : "20240" },
{ code : "2B124", intitule : "GHISONI", cp : "20227" },
{ code : "2B125", intitule : "GIOCATOJO", cp : "20237" },
{ code : "2B126", intitule : "GIUNCAGGIO", cp : "20251" },
{ code : "2B134", intitule : "L ILE ROUSSE", cp : "20220" },
{ code : "2B135", intitule : "ISOLACCIO DI FIUMORBO", cp : "20243" },
{ code : "2B135", intitule : "ISOLACCIO DI FIUMORBO", cp : "20243" },
{ code : "2B135", intitule : "ISOLACCIO DI FIUMORBO", cp : "20243" },
{ code : "2B136", intitule : "LAMA", cp : "20218" },
{ code : "2B137", intitule : "LANO", cp : "20244" },
{ code : "2B138", intitule : "LAVATOGGIO", cp : "20225" },
{ code : "2B140", intitule : "LENTO", cp : "20252" },
{ code : "2B143", intitule : "LINGUIZZETTA", cp : "20230" },
{ code : "2B143", intitule : "LINGUIZZETTA", cp : "20230" },
{ code : "2B145", intitule : "LORETO DI CASINCA", cp : "20215" },
{ code : "2B147", intitule : "LOZZI", cp : "20224" },
{ code : "2B148", intitule : "LUCCIANA", cp : "20290" },
{ code : "2B148", intitule : "LUCCIANA", cp : "20290" },
{ code : "2B148", intitule : "LUCCIANA", cp : "20290" },
{ code : "2B149", intitule : "LUGO DI NAZZA", cp : "20240" },
{ code : "2B150", intitule : "LUMIO", cp : "20260" },
{ code : "2B152", intitule : "LURI", cp : "20228" },
{ code : "2B152", intitule : "LURI", cp : "20228" },
{ code : "2B153", intitule : "MANSO", cp : "20245" },
{ code : "2B155", intitule : "MATRA", cp : "20270" },
{ code : "2B156", intitule : "MAUSOLEO", cp : "20259" },
{ code : "2B157", intitule : "MAZZOLA", cp : "20212" },
{ code : "2B159", intitule : "MERIA", cp : "20287" },
{ code : "2B161", intitule : "MOITA", cp : "20270" },
{ code : "2B162", intitule : "MOLTIFAO", cp : "20218" },
{ code : "2B164", intitule : "MONACIA D OREZZA", cp : "20229" },
{ code : "2B165", intitule : "MONCALE", cp : "20214" },
{ code : "2B166", intitule : "MONTE", cp : "20290" },
{ code : "2B167", intitule : "MONTEGROSSO", cp : "20214" },
{ code : "2B167", intitule : "MONTEGROSSO", cp : "20214" },
{ code : "2B167", intitule : "MONTEGROSSO", cp : "20214" },
{ code : "2B167", intitule : "MONTEGROSSO", cp : "20214" },
{ code : "2B168", intitule : "MONTICELLO", cp : "20220" },
{ code : "2B169", intitule : "MOROSAGLIA", cp : "20218" },
{ code : "2B169", intitule : "MOROSAGLIA", cp : "20218" },
{ code : "2B170", intitule : "MORSIGLIA", cp : "20238" },
{ code : "2B171", intitule : "MURACCIOLE", cp : "20219" },
{ code : "2B172", intitule : "MURATO", cp : "20239" },
{ code : "2B173", intitule : "MURO", cp : "20225" },
{ code : "2B175", intitule : "NESSA", cp : "20225" },
{ code : "2B176", intitule : "NOCARIO", cp : "20229" },
{ code : "2B177", intitule : "NOCETA", cp : "20242" },
{ code : "2B178", intitule : "NONZA", cp : "20217" },
{ code : "2B179", intitule : "NOVALE", cp : "20234" },
{ code : "2B180", intitule : "NOVELLA", cp : "20226" },
{ code : "2B182", intitule : "OCCHIATANA", cp : "20226" },
{ code : "2B183", intitule : "OGLIASTRO", cp : "20217" },
{ code : "2B184", intitule : "OLCANI", cp : "20217" },
{ code : "2B185", intitule : "OLETTA", cp : "20232" },
{ code : "2B187", intitule : "OLMETA DI CAPOCORSO", cp : "20217" },
{ code : "2B188", intitule : "OLMETA DI TUDA", cp : "20232" },
{ code : "2B190", intitule : "OLMI CAPPELLA", cp : "20259" },
{ code : "2B192", intitule : "OLMO", cp : "20290" },
{ code : "2B193", intitule : "OMESSA", cp : "20236" },
{ code : "2B193", intitule : "OMESSA", cp : "20236" },
{ code : "2B194", intitule : "ORTALE", cp : "20234" },
{ code : "2B195", intitule : "ORTIPORIO", cp : "20290" },
{ code : "2B199", intitule : "PALASCA", cp : "20226" },
{ code : "2B201", intitule : "PANCHERACCIA", cp : "20270" },
{ code : "2B201", intitule : "PANCHERACCIA", cp : "20251" },
{ code : "2B202", intitule : "PARATA", cp : "20229" },
{ code : "2B205", intitule : "PATRIMONIO", cp : "20253" },
{ code : "2B206", intitule : "PENTA ACQUATELLA", cp : "20290" },
{ code : "2B207", intitule : "PENTA DI CASINCA", cp : "20213" },
{ code : "2B207", intitule : "PENTA DI CASINCA", cp : "20213" },
{ code : "2B208", intitule : "PERELLI", cp : "20234" },
{ code : "2B210", intitule : "PERO CASEVECCHIE", cp : "20230" },
{ code : "2B213", intitule : "PIANELLO", cp : "20272" },
{ code : "2B214", intitule : "PIANO", cp : "20215" },
{ code : "2B216", intitule : "PIAZZALI", cp : "20234" },
{ code : "2B217", intitule : "PIAZZOLE", cp : "20229" },
{ code : "2B218", intitule : "PIEDICORTE DI GAGGIO", cp : "20251" },
{ code : "2B219", intitule : "PIEDICROCE", cp : "20229" },
{ code : "2B220", intitule : "PIEDIGRIGGIO", cp : "20218" },
{ code : "2B221", intitule : "PIEDIPARTINO", cp : "20229" },
{ code : "2B222", intitule : "PIE D OREZZA", cp : "20229" },
{ code : "2B223", intitule : "PIETRALBA", cp : "20218" },
{ code : "2B224", intitule : "PIETRACORBARA", cp : "20233" },
{ code : "2B225", intitule : "PIETRA DI VERDE", cp : "20230" },
{ code : "2B226", intitule : "PIETRASERENA", cp : "20251" },
{ code : "2B227", intitule : "PIETRICAGGIO", cp : "20234" },
{ code : "2B229", intitule : "PIETROSO", cp : "20242" },
{ code : "2B230", intitule : "PIEVE", cp : "20246" },
{ code : "2B231", intitule : "PIGNA", cp : "20220" },
{ code : "2B233", intitule : "PINO", cp : "20228" },
{ code : "2B234", intitule : "PIOBETTA", cp : "20234" },
{ code : "2B235", intitule : "PIOGGIOLA", cp : "20259" },
{ code : "2B236", intitule : "POGGIO DI NAZZA", cp : "20240" },
{ code : "2B238", intitule : "POGGIO DI VENACO", cp : "20250" },
{ code : "2B239", intitule : "POGGIO D OLETTA", cp : "20232" },
{ code : "2B241", intitule : "POGGIO MARINACCIO", cp : "20237" },
{ code : "2B242", intitule : "POGGIO MEZZANA", cp : "20230" },
{ code : "2B243", intitule : "POLVEROSO", cp : "20229" },
{ code : "2B244", intitule : "POPOLASCA", cp : "20218" },
{ code : "2B245", intitule : "PORRI", cp : "20215" },
{ code : "2B246", intitule : "LA PORTA", cp : "20237" },
{ code : "2B248", intitule : "PRATO DI GIOVELLINA", cp : "20218" },
{ code : "2B250", intitule : "PRUNELLI DI CASACCONI", cp : "20290" },
{ code : "2B251", intitule : "PRUNELLI DI FIUMORBO", cp : "20243" },
{ code : "2B251", intitule : "PRUNELLI DI FIUMORBO", cp : "20243" },
{ code : "2B251", intitule : "PRUNELLI DI FIUMORBO", cp : "20243" },
{ code : "2B252", intitule : "PRUNO", cp : "20213" },
{ code : "2B252", intitule : "PRUNO", cp : "20213" },
{ code : "2B255", intitule : "QUERCITELLO", cp : "20237" },
{ code : "2B256", intitule : "RAPAGGIO", cp : "20229" },
{ code : "2B257", intitule : "RAPALE", cp : "20246" },
{ code : "2B260", intitule : "RIVENTOSA", cp : "20250" },
{ code : "2B261", intitule : "ROGLIANO", cp : "20247" },
{ code : "2B261", intitule : "ROGLIANO", cp : "20248" },
{ code : "2B263", intitule : "ROSPIGLIANI", cp : "20242" },
{ code : "2B264", intitule : "RUSIO", cp : "20244" },
{ code : "2B265", intitule : "RUTALI", cp : "20239" },
{ code : "2B267", intitule : "SALICETO", cp : "20218" },
{ code : "2B273", intitule : "SCATA", cp : "20213" },
{ code : "2B274", intitule : "SCOLCA", cp : "20290" },
{ code : "2B275", intitule : "SERMANO", cp : "20212" },
{ code : "2B277", intitule : "SERRA DI FIUMORBO", cp : "20243" },
{ code : "2B280", intitule : "SILVARECCIO", cp : "20215" },
{ code : "2B281", intitule : "SISCO", cp : "20233" },
{ code : "2B283", intitule : "SOLARO", cp : "20240" },
{ code : "2B286", intitule : "SORBO OCAGNANO", cp : "20213" },
{ code : "2B286", intitule : "SORBO OCAGNANO", cp : "20213" },
{ code : "2B287", intitule : "SORIO", cp : "20246" },
{ code : "2B289", intitule : "SOVERIA", cp : "20250" },
{ code : "2B290", intitule : "SPELONCATO", cp : "20226" },
{ code : "2B291", intitule : "STAZZONA", cp : "20229" },
{ code : "2B292", intitule : "SANT ANDREA DI BOZIO", cp : "20212" },
{ code : "2B293", intitule : "SANT ANDREA DI COTONE", cp : "20221" },
{ code : "2B296", intitule : "SANT ANTONINO", cp : "20220" },
{ code : "2B297", intitule : "SAN DAMIANO", cp : "20213" },
{ code : "2B298", intitule : "ST FLORENT", cp : "20217" },
{ code : "2B299", intitule : "SAN GAVINO D AMPUGNANI", cp : "20213" },
{ code : "2B301", intitule : "SAN GAVINO DI TENDA", cp : "20246" },
{ code : "2B302", intitule : "SAN GIOVANNI DI MORIANI", cp : "20230" },
{ code : "2B303", intitule : "SAN GIULIANO", cp : "20230" },
{ code : "2B303", intitule : "SAN GIULIANO", cp : "20230" },
{ code : "2B304", intitule : "SAN LORENZO", cp : "20244" },
{ code : "2B305", intitule : "SAN MARTINO DI LOTA", cp : "20200" },
{ code : "2B305", intitule : "SAN MARTINO DI LOTA", cp : "20200" },
{ code : "2B306", intitule : "SANTA LUCIA DI MERCURIO", cp : "20250" },
{ code : "2B307", intitule : "SANTA LUCIA DI MORIANI", cp : "20230" },
{ code : "2B309", intitule : "SANTA MARIA DI LOTA", cp : "20200" },
{ code : "2B309", intitule : "SANTA MARIA DI LOTA", cp : "20200" },
{ code : "2B311", intitule : "SANTA MARIA POGGIO", cp : "20221" },
{ code : "2B313", intitule : "SAN NICOLAO", cp : "20230" },
{ code : "2B313", intitule : "SAN NICOLAO", cp : "20230" },
{ code : "2B313", intitule : "SAN NICOLAO", cp : "20230" },
{ code : "2B314", intitule : "SANTO PIETRO DI TENDA", cp : "20246" },
{ code : "2B314", intitule : "SANTO PIETRO DI TENDA", cp : "20217" },
{ code : "2B315", intitule : "SANTO PIETRO DI VENACO", cp : "20250" },
{ code : "2B316", intitule : "SANTA REPARATA DI BALAGNA", cp : "20220" },
{ code : "2B317", intitule : "SANTA REPARATA DI MORIANI", cp : "20230" },
{ code : "2B318", intitule : "TAGLIO ISOLACCIO", cp : "20230" },
{ code : "2B319", intitule : "TALASANI", cp : "20230" },
{ code : "2B319", intitule : "TALASANI", cp : "20230" },
{ code : "2B320", intitule : "TALLONE", cp : "20270" },
{ code : "2B320", intitule : "TALLONE", cp : "20270" },
{ code : "2B321", intitule : "TARRANO", cp : "20234" },
{ code : "2B327", intitule : "TOMINO", cp : "20248" },
{ code : "2B328", intitule : "TOX", cp : "20270" },
{ code : "2B329", intitule : "TRALONCA", cp : "20250" },
{ code : "2B332", intitule : "URTACA", cp : "20218" },
{ code : "2B333", intitule : "VALLECALLE", cp : "20232" },
{ code : "2B334", intitule : "VALLE D ALESANI", cp : "20234" },
{ code : "2B335", intitule : "VALLE DI CAMPOLORO", cp : "20221" },
{ code : "2B337", intitule : "VALLE DI ROSTINO", cp : "20235" },
{ code : "2B338", intitule : "VALLE D OREZZA", cp : "20229" },
{ code : "2B339", intitule : "VALLICA", cp : "20259" },
{ code : "2B340", intitule : "VELONE ORNETO", cp : "20230" },
{ code : "2B341", intitule : "VENACO", cp : "20231" },
{ code : "2B342", intitule : "VENTISERI", cp : "20240" },
{ code : "2B342", intitule : "VENTISERI", cp : "20240" },
{ code : "2B342", intitule : "VENTISERI", cp : "20240" },
{ code : "2B343", intitule : "VENZOLASCA", cp : "20215" },
{ code : "2B344", intitule : "VERDESE", cp : "20229" },
{ code : "2B346", intitule : "VESCOVATO", cp : "20215" },
{ code : "2B347", intitule : "VEZZANI", cp : "20242" },
{ code : "2B350", intitule : "VIGNALE", cp : "20290" },
{ code : "2B352", intitule : "VILLE DI PARASO", cp : "20279" },
{ code : "2B353", intitule : "VILLE DI PIETRABUGNO", cp : "20200" },
{ code : "2B354", intitule : "VIVARIO", cp : "20219" },
{ code : "2B354", intitule : "VIVARIO", cp : "20219" },
{ code : "2B354", intitule : "VIVARIO", cp : "20219" },
{ code : "2B355", intitule : "VOLPAJOLA", cp : "20290" },
{ code : "2B355", intitule : "VOLPAJOLA", cp : "20290" },
{ code : "2B356", intitule : "ZALANA", cp : "20272" },
{ code : "2B361", intitule : "ZILIA", cp : "20214" },
{ code : "2B364", intitule : "ZUANI", cp : "20272" },
{ code : "2B365", intitule : "SAN GAVINO DI FIUMORBO", cp : "20243" },
{ code : "2B366", intitule : "CHISA", cp : "20240" }
];